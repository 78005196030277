export const AGGR_OPTIONS = [
    {
        name: 'count',
        value: 'count',
        label: 'Count of records',
    },
    {
        name: 'sum',
        value: 'sum',
        label: 'Sum of field',
    },
    {
        name: 'avg',
        value: 'avg',
        label: 'Average of field',
    },
]
