//@ts-nocheck

import * as chevrotain from 'chevrotain'
import { createToken, CstParser, Lexer } from 'chevrotain'

import { FIELD_NAME_REGEX, FUNCTION_NAME_REGEX } from '../constants/formulaRegexConstants'

// N.B. Having a '(' in FunctionName is a hack to avoid the tokeniser confusing FunctionName and FieldName/BooleanLiteral, which are otherwise overlapping.
const FunctionName = createToken({
    name: 'FunctionName',
    pattern: new RegExp(FUNCTION_NAME_REGEX.source + '\\('),
})
const LeftParenthesis = createToken({ name: 'LeftParenthesis', pattern: /\(/ })
const RightParenthesis = createToken({ name: 'RightParenthesis', pattern: /\)/ })
const LeftCurly = createToken({ name: 'LeftCurly', pattern: /\{/ })
const RightCurly = createToken({ name: 'RightCurly', pattern: /\}/ })
const Ampersand = createToken({ name: 'Ampersand', pattern: /&/ })
const Plus = createToken({ name: 'Plus', pattern: /\+/ })
const Minus = createToken({ name: 'Minus', pattern: /\-/ })
const Multiply = createToken({ name: 'Multiply', pattern: /\*/ })
const Divide = createToken({ name: 'Divide', pattern: /\// })
const NotEquals = createToken({ name: 'NotEqual', pattern: /\!\=/ })
const Equals = createToken({ name: 'Equal', pattern: /\=/ })
const LessThan = createToken({ name: 'LessThan', pattern: /\</ })
const GreaterThan = createToken({ name: 'GreaterThan', pattern: /\>/ })
const LessThanEqual = createToken({ name: 'LessThanEqual', pattern: /\<\=/ })
const GreaterThanEqual = createToken({ name: 'GreaterThanEqual', pattern: /\>\=/ })
const Comma = createToken({ name: 'Comma', pattern: /,/ })
const And = createToken({ name: 'And', pattern: /AND/i })
const Or = createToken({ name: 'Or', pattern: /OR/i })
const Not = createToken({ name: 'Not', pattern: /NOT/i })

const buildStringLiteralRegex = () => {
    const notASpecialCharDouble = /[^\\"]+/m
    const escapedCharDouble = /\\[bfnrtv"\\/]/

    const rawUTF8 = /\\u[0-9a-fA-F]{4}/

    const doubleQuote = new RegExp(
        `"(${notASpecialCharDouble.source}|${escapedCharDouble.source}|${rawUTF8.source})*"`
    )

    const notASpecialCharSingle = /[^\\']+/m
    const escapedCharSingle = /\\[bfnrtv'\\/]/

    const singleQuote = new RegExp(
        `'(${notASpecialCharSingle.source}|${escapedCharSingle.source}|${rawUTF8.source})*'`
    )

    return new RegExp(`${doubleQuote.source}|${singleQuote.source}`)
}

const StringLiteral = createToken({
    name: 'StringLiteral',
    pattern: buildStringLiteralRegex(),
})
const OpenStringLiteral = createToken({
    name: 'OpenStringLiteral',
    pattern: /("|').*/,
})

const NumberLiteral = createToken({
    name: 'NumberLiteral',
    pattern: /\d+(\.\d+)?/,
})
const BooleanLiteral = createToken({
    name: 'BooleanLiteral',
    pattern: Lexer.NA,
})
export const TrueLiteral = createToken({
    name: 'TrueLiteral',
    pattern: /TRUE/i,
    categories: BooleanLiteral,
})
const FalseLiteral = createToken({
    name: 'FalseLiteral',
    pattern: /FALSE/i,
    categories: BooleanLiteral,
})
// Anything inside a {} except for:  { } ( ) "
const FieldName = createToken({
    name: 'FieldName',
    pattern: FIELD_NAME_REGEX,
})
const WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED,
})

const jsonTokens = [
    WhiteSpace,
    TrueLiteral,
    FalseLiteral,
    BooleanLiteral,
    FunctionName,
    StringLiteral,
    OpenStringLiteral,
    NumberLiteral,
    FieldName,
    RightParenthesis,
    LeftParenthesis,
    Comma,
    Ampersand,
    Plus,
    Minus,
    Multiply,
    Divide,
    NotEquals,
    Equals,
    LessThanEqual,
    GreaterThanEqual,
    LessThan,
    GreaterThan,
    And,
    Or,
    Not,
    LeftCurly,
    RightCurly,
]

export const FormulaLexer = new Lexer(jsonTokens, {
    // Less position info tracked, reduces verbosity of the playground output.
    positionTracking: 'onlyStart',
    ensureOptimizations: true,
})

// Labels only affect error messages and Diagrams.
LeftParenthesis.LABEL = "'('"
RightParenthesis.LABEL = "')'"
LeftCurly.LABEL = "'{'"
RightCurly.LABEL = "'}'"
Comma.LABEL = "','"
Ampersand.LABEL = "'&'"
Plus.LABEL = "'+'"
Minus.LABEL = "'-'"
Multiply.LABEL = "'*'"
Divide.LABEL = "'/'"
NotEquals.LABEL = "'!='"
Equals.LABEL = "'='"
LessThan.LABEL = "'<'"
GreaterThan.LABEL = "'>'"
LessThanEqual.LABEL = "'<='"
And.LABEL = "'AND'"
Or.LABEL = "'OR'"
Not.LABEL = "'NOT'"

class StackerFormulaParser extends CstParser {
    constructor() {
        super(jsonTokens, {
            recoveryEnabled: true,
        })

        const $ = this

        // The most loosely associated operator is +, so eventually we'll start with that. For now though, start with &
        $.RULE('formula', () => {
            $.SUBRULE($.orExpression)
        })

        $.RULE('orExpression', () => {
            $.SUBRULE($.andExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Or)
                $.SUBRULE2($.andExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('andExpression', () => {
            $.SUBRULE($.negationExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(And)
                $.SUBRULE2($.negationExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('negationExpression', () => {
            $.OPTION(() => {
                $.CONSUME(Not)
            })
            $.SUBRULE($.comparisonExpression, { LABEL: 'rhs' })
        })

        $.RULE('comparisonExpression', () => {
            $.SUBRULE($.ampersandExpression, { LABEL: 'lhs' })
            $.OPTION(() => {
                $.OR([
                    { ALT: () => $.CONSUME(Equals) },
                    { ALT: () => $.CONSUME(NotEquals) },
                    { ALT: () => $.CONSUME(LessThan) },
                    { ALT: () => $.CONSUME(GreaterThan) },
                    { ALT: () => $.CONSUME(LessThanEqual) },
                    { ALT: () => $.CONSUME(GreaterThanEqual) },
                ])
                $.SUBRULE2($.ampersandExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('ampersandExpression', () => {
            $.SUBRULE($.additionExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Ampersand)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.additionExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('additionExpression', () => {
            $.SUBRULE($.subtractionExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Plus)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.subtractionExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('subtractionExpression', () => {
            $.SUBRULE($.multiplicationExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Minus)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.multiplicationExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('multiplicationExpression', () => {
            $.SUBRULE($.divisionExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Multiply)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.divisionExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('divisionExpression', () => {
            $.SUBRULE($.atomicExpression, { LABEL: 'lhs' })
            $.MANY(() => {
                $.CONSUME(Divide)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.atomicExpression, { LABEL: 'rhs' })
            })
        })

        $.RULE('atomicExpression', () => {
            $.OR([
                { ALT: () => $.SUBRULE($.parentheses) },
                { ALT: () => $.SUBRULE($.fieldItem) },
                { ALT: () => $.CONSUME(StringLiteral) },
                // This needs to be after StringLiteral, otherwise it will
                // consume the string literal (or a field item definition).
                { ALT: () => $.CONSUME(OpenStringLiteral) },
                { ALT: () => $.SUBRULE($.numberExpression) },
                { ALT: () => $.CONSUME(BooleanLiteral) },
                { ALT: () => $.SUBRULE($.functionExpression) },
            ])
        })

        $.RULE('numberExpression', () => {
            $.OPTION(() => {
                $.CONSUME(Minus)
            })
            $.CONSUME(NumberLiteral)
        })

        $.RULE('functionExpression', () => {
            $.CONSUME(FunctionName) // N.B. FunctionName includes the left paren
            $.MANY_SEP({
                SEP: Comma,
                DEF: () => {
                    $.SUBRULE($.formula)
                },
            })
            $.CONSUME(RightParenthesis)
        })

        $.RULE('fieldItem', () => {
            $.CONSUME(FieldName)
        })

        $.RULE('parentheses', () => {
            $.CONSUME(LeftParenthesis)
            $.SUBRULE($.formula)
            $.CONSUME(RightParenthesis)
        })

        // very important to call this after all the rules have been setup.
        // otherwise the parser may not work correctly as it will lack information
        // derived from the self analysis.
        this.performSelfAnalysis()
    }
}

export const parser: chevrotain.CstParser = new StackerFormulaParser()
