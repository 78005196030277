/**
 * Context keys
 *
 * Suggested syntax for keys:
 *    "record-<name>" for lists of objects
 *    "attribute-<name>" for context keys used as helpers
 * Documentation for records vs. attributes: https://app.commandbar.com/docs/context/records
 */

export enum ContextAttributeKeys {
    AppBarVisible = 'attribute-appBarVisible',
    CanViewStackerSupport = 'attribute-canViewStackerSupport',
    CanViewWorkspaceSettings = 'attribute-canViewWorkspaceSettings',
    IsAdmin = 'attribute-isAdmin',
    IsImpersonating = 'attribute-isImpersonating',
    IsPreviewing = 'attribute-isPreviewing',
    IsStudioUser = 'attribute-isStudioUser',
    Rights = 'attribute-rights',
    Role = 'attribute-role',
    SupportEnabled = 'attribute-supportEnabled',
    Version = 'attribute-version',
}

export enum ContextRecordKeys {
    Apps = 'record-apps',
    AppSettingsPages = 'record-appSettingsPages',
    Lists = 'record-lists',
    ListsWithSettings = 'record-listsWithSettings',
    ListSettingsSections = 'record-listSettingsSections',
    Records = 'record-records',
    User = 'record-user',
    Workspaces = 'record-workspaces',
}

export enum GlobalCallbackKeys {
    Cancel = 'global-cancel',
    CreateNewWorkspace = 'global-createNewWorkspace',
    DisableSupportLogin = 'global-disableSupportLogin',
    DuplicateLayout = 'global-duplicateLayout',
    EditLayout = 'global-editLayout',
    EditRecord = 'global-editRecord',
    EnableSupportLogin = 'global-enableSupportLogin',
    ExitEditLayout = 'global-exitEditLayout',
    GoToRecord = 'global-goToRecord',
    ImpersonateUser = 'global-impersonateUser',
    NewRecord = 'global-newRecord',
    NextPage = 'global-nextPage',
    NextRecord = 'global-nextRecord',
    OpenAppSettings = 'global-openAppSettings',
    OpenAppUsers = 'global-openAppUsers',
    OpenListSettings = 'global-openListSettings',
    OpenPreviewAsModal = 'global-openPreviewAsModal',
    OpenWorkspaceSettings = 'global-openWorkspaceSettings',
    PreviousPage = 'global-previousPage',
    PreviousRecord = 'global-previousRecord',
    Save = 'global-save',
    StopImpersonatingUser = 'global-stopImpersonatingUser',
}

export enum AppCallbackKeys {
    GetAppSettingPages = 'apps-getAppSettingPages',
}

export enum ListCallbackKeys {
    GetListSettingPages = 'lists-getListSettingPages',
}

export enum RecordCallbackKeys {
    FetchRecords = 'records-fetchRecords',
}
