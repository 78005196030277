/* Code Quality: Good */

import React from 'react'

import PropTypes from 'prop-types'

import Filters, { formatFilters } from 'features/records/components/RecordFilters'
import { Button } from 'legacy/v1/ui/index'

export default class DataFilterEditor extends React.Component {
    state = { rawFilters: null, updated: false }

    constructor(props) {
        super(props)
        if (props.object && props.object.connection_options) {
            this.state.rawFilters = props.object.connection_options.data_mapping_filters_raw
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.object && props.object.connection_options) {
            this.setFilters(props.object.connection_options.data_mapping_filters_raw)
        }
    }

    setFilters = (rawFilters) => {
        this.setState({ rawFilters, updated: true })
    }

    saveFilters = () => {
        this.props.onChange({
            connection_options: {
                ...this.props.object.connection_options,
                data_mapping_filters_raw: this.state.rawFilters,
                data_mapping_filters: formatFilters(this.state.rawFilters),
            },
        })
        this.setState({ updated: false })
    }

    render() {
        const { object } = this.props
        return (
            <>
                <Filters
                    getShouldShowField={(field) => !field.is_stacker_augmented_field}
                    hideTheRecordFilter
                    object={object}
                    fields={object.fields}
                    value={this.state.rawFilters || []}
                    onChange={this.setFilters}
                    hideCurrentUserOption
                />
                <Button
                    size="small"
                    color="success"
                    style={{ width: 70 }}
                    onClick={this.saveFilters}
                    disabled={!this.state.updated}
                >
                    Save
                </Button>
            </>
        )
    }
}
DataFilterEditor.propTypes = {
    object: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}
