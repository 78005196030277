import * as React from 'react'
import { useEffect, useState } from 'react'

import { ModalFooter } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { useViewFromId } from 'data/hooks/views'
import { useObjectFromId } from 'features/actions/helpers'
import Filters, { formatFilters } from 'features/records/components/RecordFilters'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { Dropdown, Input, Modal, Text } from 'v2/ui'
import { Checkbox } from 'v2/ui/components/Checkbox'
import stackerTheme from 'v2/ui/theme/styles/default'

import Button from 'ui/deprecated/atoms/Button'
import Flex from 'ui/deprecated/atoms/Flex'
import Icon from 'ui/deprecated/atoms/Icon'

import { AggrTypeSelector } from './forms/AggrTypeSelector'
import {
    CHART_TYPE_GROUP_TYPE_MAP,
    CHART_TYPE_OPTIONS,
    ChartTypeSelector,
} from './forms/ChartTypeSelector'
import { useDashboardContext } from './context'

const colors = stackerTheme().colors

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const scaleInAnimation = keyframes`
  0% { height: 0; transform: scaleY(0); transform-origin: top; }
  100% { height: auto; transform: scaleY(1) }
`

const AddAChartButtonContainer = styled.div`
    justify-content: center;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed ${colors.gray[200]};
    cursor: pointer;
    transition: border-color 0.1s linear, background 0.2s linear;
    animation: ${fadeInAnimation} 0.1s linear;
    padding: ${(p) => (p.emptyState ? 24 : 12)}px;
    margin-bottom: 32px;

    .action {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .empty-state-content {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 48px;
        font-size: 16px;
        color: ${colors.gray[500]};
        font-weight: 500;
    }

    .icon-background {
        background-color: ${colors.gray[200]};
        transition: background-color 0.1s linear;
    }

    .text {
        color: ${colors.gray[500]};
        transition: color 0.1s linear;
    }

    &:hover {
        border-color: ${colors.gray[300]};
        background: rgba(0, 0, 0, 0.01);

        .icon-background {
            background-color: ${colors.gray[300]};
        }

        .text {
            color: ${colors.gray[600]};
        }
    }
`

export default function AddAChart({ emptyState, EmptyStateContent }) {
    const [modalOpen, setModalOpen] = React.useState(false)

    return (
        <>
            <AddAChartButtonContainer
                onClick={() => setModalOpen(!modalOpen)}
                emptyState={!!emptyState}
            >
                {emptyState && EmptyStateContent ? (
                    <div className="empty-state-content">{EmptyStateContent}</div>
                ) : null}

                <div className="action">
                    <Flex
                        className="icon-background"
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Icon
                            icon="add"
                            style={{
                                fontSize: 12,
                                // color of the page background,
                                // makes icon look like a see through hole in the containing circle
                                color: '#f5f7fa',
                                marginTop: 2,
                            }}
                        />
                    </Flex>

                    <div
                        className="text"
                        style={{
                            fontSize: 14,
                            marginLeft: 10,
                        }}
                    >
                        Add a chart
                    </div>
                </div>
            </AddAChartButtonContainer>

            {modalOpen && (
                <AddAChartModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} />
            )}
        </>
    )
}

// required to override styles set by variant in Text
// (adding as props does not override the variant styles)
const FieldLabelText = styled(Text)`
    && {
        margin-bottom: 0;

        i {
            display: block;
            margin-top: 8px;
            font-weight: 400;
        }
    }
`

const FieldLabel = ({ children, ...props }) => (
    <FieldLabelText variant="fieldLabel" {...props}>
        {children}
    </FieldLabelText>
)

const AdvancedOptionFieldLabelContainer = styled(Flex)`
    margin-bottom: 6px;
`

const AdvancedOptionFieldLabel = ({ title, info }) => (
    <AdvancedOptionFieldLabelContainer>
        <FieldLabel>
            {title}
            <i>{info}</i>
        </FieldLabel>
    </AdvancedOptionFieldLabelContainer>
)

const AdvancedOptionsToggleContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    color: ${colors.gray[300]};
    border: 1px solid ${colors.gray[300]};
    animation: ${fadeInAnimation} 0.2s linear;
    transition: background-color 0.1s linear, color 0.1s linear;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding-top: 2px;
    cursor: pointer;

    &:hover {
        color: ${colors.gray[500]};
        background-color: ${colors.gray[300]};
    }
`

const AdvancedOptionsToggle = ({ showAdvancedOptionsSection, onClick }) => (
    <AdvancedOptionsToggleContainer role="button" onClick={onClick}>
        <Icon icon={showAdvancedOptionsSection ? 'minus' : 'add'} size={14} />
    </AdvancedOptionsToggleContainer>
)

const BasicOptionsSection = styled.div`
    margin-top: 16px;
    margin-bottom: 32px;
`

const AdvancedOptionsSectionContainer = styled.div`
    position: relative;

    .title {
        align-items: center;
        ${(p) =>
            p.showAdvancedOptionsSection
                ? 'margin-top: 16px;'
                : 'position: absolute; left: 0; top: 11px;'}
    }

    .content {
        padding-top: 24px;
        padding-bottom: 24px;
        animation: ${fadeInAnimation} 0.1s linear;

        .formatting-controls {
            width: 100%;
            margin-bottom: 32px;

            .formatting-control {
                flex-grow: 1;
                margin-right: 32px;
            }

            .formatting-control:last-of-type {
                margin-right: 0;
            }
        }
    }
`

/**
 * @type Array<{ label: string, value: CHART_DECIMAL_PLACES }>
 */
export const chartDecimalPlacesOptions = [
    { label: 'Max 2 decimal places', value: 'max2' },
    { label: 'Whole number', value: '0' },
    { label: '1 decimal place', value: '1' },
    { label: '2 decimal places', value: '2' },
    { label: '3 decimal places', value: '3' },
    { label: '4 decimal places', value: '4' },
    { label: '5 decimal places', value: '5' },
]

/**
 * @type Array<{ label: string, value: CHART_ABBREVIATION }>
 */
export const chartAbbreviationOptions = [
    { label: 'No Abbreviation', value: 'none' },
    { label: 'Thousand (k)', value: 'k' },
    { label: 'Million (m)', value: 'm' },
]

const DEFAULT_INHERITED_FILTERS = true
function AddAChartModal({ isOpen, onRequestClose }) {
    const { createChart, mainObjectId, viewId, hasStartedEditingRef } = useDashboardContext()
    const [objectId, setObjectId] = useState(mainObjectId)
    const [chartType, setChartType] = useState('Number')

    const [selectedGroupField, setSelectedGroupField] = useState()
    const [aggrType, setAggrType] = useState('count')
    const [selectedAggrField, setSelectedAggrField] = useState()
    const [label, onLabelChange] = useState('')
    const [filters, setFilters] = useState([])
    const [inheritFilters, setInheritFilters] = useState(DEFAULT_INHERITED_FILTERS)
    const [abbreviation, setAbbreviation] = useState('none')
    const [decimalPlaces, setDecimalPlaces] = useState('max2')
    const [showAdvancedOptionsSection, setShowAdvancedOptionsSection] = useState(false)

    const view = useViewFromId(viewId)
    const { object } = useObjectFromId(objectId)

    useEffect(() => {
        hasStartedEditingRef.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // reset filters and aggr field if changing table
    function _setObjectId(table) {
        if (
            filters.length > 0 ||
            (aggrType !== 'count' && selectedAggrField) ||
            DEFAULT_INHERITED_FILTERS !== inheritFilters
        ) {
            if (
                window.confirm(
                    'Changing the table will clear aggregation and filters settings for this chart. Are you sure?'
                )
            ) {
                setSelectedAggrField(null)
                setFilters([])
                setInheritFilters(DEFAULT_INHERITED_FILTERS)
            } else {
                return
            }
        }
        setObjectId(table)
    }

    function createChartButtonIsDisabled() {
        if (!objectId) {
            return true
        }
        if (!aggrType) {
            return true
        }
        if (aggrType !== 'count' && !selectedAggrField) {
            return true
        }

        return false
    }

    const aggrFieldPlaceholder =
        `Select field` + (aggrType === 'sum' ? ' to sum' : aggrType === 'avg' ? ' to average' : '')

    // we will need a picker and state for this eventually, but for now it maps from chart type
    const groupType = CHART_TYPE_GROUP_TYPE_MAP[chartType]

    const onListViewAndSameTableSelected = view?.type === 'list' && mainObjectId === objectId

    return (
        <Modal
            isOpen={isOpen}
            onClose={onRequestClose}
            showCloseButton={true}
            title="Create Chart"
            size="760px"
        >
            <BasicOptionsSection>
                <ChartControl label="Label">
                    <Input value={label} onChange={(event) => onLabelChange(event.target.value)} />
                </ChartControl>

                <ChartControl label="Table">
                    <ObjectPicker
                        onChange={_setObjectId}
                        placeholder="Select a table"
                        value={objectId}
                    />
                </ChartControl>

                <ChartControl label="Chart type">
                    <ChartTypeSelector value={chartType} onChange={setChartType} />
                </ChartControl>

                <ChartControl
                    label="Group by"
                    hidden={chartType === 'Number'}
                    style={{ paddingBottom: 16 }}
                >
                    <FieldPicker
                        objectId={objectId}
                        onChange={setSelectedGroupField}
                        value={selectedGroupField?._sid}
                        filter={getFieldGroupTypeFilter(groupType)}
                        placeholder={`Select field to group by for ${getChartTypeLabel(chartType)}`}
                        returnField
                    />
                </ChartControl>

                <ChartControl label="Aggregate">
                    <AggrTypeSelector value={aggrType} onChange={setAggrType} />
                </ChartControl>

                {/* need to pick a field if aggregation is anything other than count */}
                <ChartControl
                    label="Field"
                    hidden={aggrType === 'count'}
                    style={{ paddingBottom: 16 }}
                >
                    <FieldPicker
                        objectId={objectId}
                        onChange={setSelectedAggrField}
                        value={selectedAggrField?._sid}
                        filter={getFieldAggrTypeFilter(aggrType)}
                        placeholder={aggrFieldPlaceholder}
                        returnField
                    />
                </ChartControl>
            </BasicOptionsSection>

            <AdvancedOptionsSectionContainer
                showAdvancedOptionsSection={showAdvancedOptionsSection}
            >
                <Flex className="title">
                    <FieldLabel mr={4}>Advanced Options</FieldLabel>
                    {objectId ? (
                        <AdvancedOptionsToggle
                            showAdvancedOptionsSection={showAdvancedOptionsSection}
                            onClick={() => setShowAdvancedOptionsSection((value) => !value)}
                        />
                    ) : null}
                </Flex>

                {showAdvancedOptionsSection && objectId && (
                    <div className="content">
                        <Flex className="formatting-controls">
                            <div className="formatting-control">
                                <AdvancedOptionFieldLabel
                                    title="Rounding"
                                    info={'Round fractional results, e.g. show 2.499 as 2.5'}
                                />
                                <Dropdown
                                    options={chartDecimalPlacesOptions}
                                    value={decimalPlaces}
                                    onChange={setDecimalPlaces}
                                />
                            </div>

                            <div className="formatting-control">
                                <AdvancedOptionFieldLabel
                                    title="Abbreviation"
                                    info={'Abbreviate large results, e.g. show 1200 as 1.2k'}
                                />
                                <Dropdown
                                    options={chartAbbreviationOptions}
                                    value={abbreviation}
                                    onChange={setAbbreviation}
                                />
                            </div>
                        </Flex>

                        <div>
                            <AdvancedOptionFieldLabel
                                title="Filters"
                                info={'Only include records in the result which pass these filters'}
                            />

                            {onListViewAndSameTableSelected && (
                                <label style={{ cursor: 'pointer' }}>
                                    <Flex style={{ margin: '5px 0', alignItems: 'center' }}>
                                        <Checkbox
                                            isChecked={inheritFilters}
                                            onChange={() => {
                                                setInheritFilters(
                                                    (inheritFilters) => !inheritFilters
                                                )
                                            }}
                                        />
                                        <FieldLabelText as="span" variant="fieldLabel" ml={4}>
                                            Use the filters from the list?
                                        </FieldLabelText>
                                    </Flex>
                                </label>
                            )}

                            <Filters
                                key={
                                    objectId /* this reset the component whenever the objectId changes */
                                }
                                object={object}
                                fields={object?.fields}
                                value={[]}
                                onChange={(filters) => {
                                    const filtersFormattedForQuery = formatFilters(filters)

                                    setFilters(filtersFormattedForQuery)
                                }}
                            />
                        </div>
                    </div>
                )}
            </AdvancedOptionsSectionContainer>

            <ModalFooter px={0} pt={2} pb={2}>
                <Button type="secondary" onClick={onRequestClose} mr={4}>
                    Cancel
                </Button>

                <Button
                    disabled={createChartButtonIsDisabled()}
                    onClick={() => {
                        createChart({
                            widget_type: 'chart',
                            // TODO add chart type selector
                            chart_type: chartType,
                            config: {
                                object_sid: objectId,
                                group: {
                                    type: groupType,
                                    ...(selectedGroupField && {
                                        field_sid: selectedGroupField._sid,
                                    }),
                                },
                                aggr: {
                                    type: aggrType,
                                    ...(selectedAggrField && {
                                        field_sid: selectedAggrField._sid,
                                    }),
                                },
                                inherit_filters_from_view_id:
                                    onListViewAndSameTableSelected && inheritFilters
                                        ? view?._sid
                                        : undefined,
                                filters,
                            },
                            display: {
                                label,
                                abbreviation,
                                decimalPlaces,
                            },
                        })

                        onRequestClose()
                    }}
                >
                    Create Chart
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const ChartControlContainer = styled(Flex)`
    ${(p) => (p.hidden ? ' display: none;' : '')}

    margin-top: 16px;
    align-items: center;
    animation: ${fadeInAnimation} 0.2s linear, ${scaleInAnimation} 0.15s ease-out;

    .chart-control-label {
        min-width: 80px;
        padding-right: 8px;
    }

    .chart-control {
        flex-grow: 1;
    }
`

const ChartControl = ({ label, hidden, style, children }) => (
    <ChartControlContainer hidden={hidden} style={style}>
        <FieldLabel className="chart-control-label">{label}</FieldLabel>
        <div className="chart-control">{children}</div>
    </ChartControlContainer>
)

export const getChartTypeLabel = (chartType) => {
    const option = CHART_TYPE_OPTIONS.find((option) => option.name === chartType)

    // should never get here, but just in case, return a default
    return option?.label ?? 'chart'
}

export const getFieldAggrTypeFilter = (aggrType) => (field) => {
    return !field.connection_options.is_disabled && FIELD_AGGR_TYPE_FILTERS[aggrType](field)
}

// filter which field types show for an aggregation type
const FIELD_AGGR_TYPE_FILTERS = {
    count: (field) => field,
    avg: ({ type }) => ['number', 'currency'].includes(type),
    sum: ({ type }) => ['number', 'currency'].includes(type),
}

export const getFieldGroupTypeFilter = (groupType) => (field) => {
    return !field.connection_options.is_disabled && FIELD_GROUP_TYPE_FILTERS[groupType](field)
}

// filter which field types show for a group type
const FIELD_GROUP_TYPE_FILTERS = {
    all: (field) => field,
    value: ({ type }) =>
        [
            'string',
            'dropdown',
            /* 'lookup', lookup works but ID is used as label which is not helpful. Some backend work is needed to allow ID -> display name*/
            ,
        ].includes(type),
}
