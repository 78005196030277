import settings from 'app/settings'

export const updateAccountUrl = (
    onUpdateAccount: any,
    account: Pick<Account, '_sid' | 'base_url'> | Account | null,
    values: {
        name: string
        subdomain: string
        custom_base_url: string
    },
    apiToken: string
): Record<string, string> => {
    const nextBaseUrl = `${values.subdomain}.${settings.WORKSPACE_DOMAIN}`

    return onUpdateAccount({
        id: account?._sid,
        patch: {
            name: values.name,
            base_url: nextBaseUrl,
            custom_base_url: values.custom_base_url === '' ? null : values.custom_base_url,
        },
    }).then((resp: Record<string, string>) => {
        // if workspace base url has changed we need to redirect to the new hostname
        // otherwise all subsequent requests will fail
        if (resp.base_url !== account?.base_url) {
            window.location.href = `//${resp.base_url}/auth?token=${encodeURIComponent(apiToken)}`
        }
        return resp
    })
}
