// @ts-nocheck
import React from 'react'

import { Icon, Text } from 'v2/ui'

export const LinkFormEmptyState = ({ children }) => {
    return (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                    <Icon color="grey.300" icon="cog" />
                </div>
                <div style={{ marginTop: 10 }}>
                    <Text color="grey.300">{children}</Text>
                </div>
            </div>
        </div>
    )
}
