import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import WithMarginAndPadding, { WithMarginAndPaddingPropTypes } from '../utils/WithMarginAndPadding'

type SectionProps = WithMarginAndPaddingPropTypes & {
    maxSize?: number
    spaced?: boolean
    wrapItems?: boolean
    centered?: boolean
}

const Section = styled(WithMarginAndPadding)<SectionProps>`
    // Basically, a section is a vertical stacking (column-based) flex container

    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    // Custom margin and padding defaults
    margin: ${(props) => props.theme[props.margin || 'medium']};
    ${(props) => !props.marginRight && `margin-right: 0px;`}
    ${(props) => !props.marginLeft && `margin-left: 0px;`}
    padding: ${(props) => props.theme[props.padding || 'medium']};

    ${(props) =>
        props.maxSize &&
        `
        flex-grow: ${props.maxSize};
    `}
    ${(props) =>
        props.spaced &&
        `
        justify-content: space-between;
    `}
    ${(props) =>
        props.wrapItems &&
        `
        flex-wrap: wrap;
    `}
    ${(props) =>
        props.centered &&
        `
        align-items: center;
    `}
`

Section.propTypes = {
    ...WithMarginAndPadding.propTypes,
    maxSize: PropTypes.number,
    spaced: PropTypes.bool,
    wrapItems: PropTypes.bool,
    centered: PropTypes.bool,
}
export default Section
