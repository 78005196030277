import React, { FC } from 'react'

import { FiltersPanel as AggregationFiltersPanel } from '../../common/panels/FiltersPanel'

type FiltersPanelProps = {
    metric: MetricsBlockAttributes
    onChange: (
        metric: Pick<MetricsBlockAttributes, 'objectId'> & Partial<MetricsBlockAttributes>
    ) => void
}

export const FiltersPanel: FC<FiltersPanelProps> = ({ metric, onChange }) => (
    <AggregationFiltersPanel
        aggregationWidget={metric}
        onChange={onChange}
        emptyStateTitle="Add filters to your big numbers"
    />
)
