import * as React from 'react'
import { useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import {
    openWorkspaceSettingsModal,
    planModalState,
} from 'features/utils/__hackyGlobalModalControls'
import CustomPlanPicker from 'features/workspace/CustomPlanPicker'

import { Button, Flex, Text } from 'v2/ui'
import SimpleModal from 'v2/ui/components/SimpleModal'

export const disablePlanPickerFocusLock = () => {
    planModalState.subscribers.map((s) => {
        s.setDisableFocusLock(true)
    })
}

export const enablePlanPickerFocusLock = () => {
    planModalState.subscribers.map((s) => {
        s.setDisableFocusLock(false)
    })
}

// handle the open / closed state of the modal
export default function PlanPickerModal() {
    const [openWorkspaceSettingsAfterClose, setOpenWorkspaceSettingsAfterClose] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [requiredTier, setRequiredTier] = useState(false)
    const [disableFocusLock, setDisableFocusLock] = useState(false)

    useEffect(() => {
        planModalState.subscribers.push({
            setIsOpen,
            setOpenWorkspaceSettingsAfterClose,
            setDisableFocusLock,
            setRequiredTier,
        })

        return () => {
            planModalState.subscribers = []
        }
    }, [])

    return isOpen ? (
        <PlanPickerModalContent
            onRequestClose={() => {
                setIsOpen(false)
                if (openWorkspaceSettingsAfterClose) {
                    openWorkspaceSettingsModal({ page: 'general' })
                }
                setOpenWorkspaceSettingsAfterClose(false)
            }}
            defaultState={isOpen}
            disableFocusLock={disableFocusLock}
            requiredTier={requiredTier}
            openWorkspaceSettingsAfterClose={openWorkspaceSettingsAfterClose}
        />
    ) : null
}

/**
 *
 * @param {{onRequestClose: () => void , disableFocusLock: void, openWorkspaceSettingsAfterClose:void, requiredTier:void}} param0
 */
function PlanPickerModalContent({
    onRequestClose,
    disableFocusLock,
    openWorkspaceSettingsAfterClose,
    requiredTier,
}) {
    const { workspaceAccount } = useAppContext()

    return (
        <SimpleModal
            title="Choose a plan"
            height="780px"
            zIndex={1500}
            showCloseButton={true}
            open={true}
            onClose={onRequestClose}
            disableFocusLock={disableFocusLock}
            padding="20px 20px 0 20px"
        >
            {workspaceAccount?.options?.uses_v3_subscription ? (
                // Until we have automatic subscription migration, just direct them to contact support,
                // if this account comes from v3.
                <Flex column height="100%" justify="center">
                    <Text size="md" textAlign="center">
                        This account was migrated from Stacker Classic.
                        <br /> Please contact support to upgrade your subscription.
                    </Text>
                    <Button
                        variant="Primary"
                        buttonSize="extraLarge"
                        mt={6}
                        onClick={() => {
                            onRequestClose()
                            window.Intercom('showNewMessage')
                        }}
                    >
                        Contact Support
                    </Button>
                </Flex>
            ) : (
                <CustomPlanPicker
                    onClose={onRequestClose}
                    openWorkspaceSettingsAfterClose={openWorkspaceSettingsAfterClose}
                    requiredTier={requiredTier}
                />
            )}
        </SimpleModal>
    )
}
