import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

export function getEditableBlockStyle(isActive: boolean): any {
    return {
        borderColor: isActive
            ? colors.userInterface.accent[1000]
            : colors.userInterface.neutral[700],
        borderStyle: 'dashed',
        borderWidth: 1,
    }
}
