import React, { useMemo } from 'react'

import { InlineFilter } from '../InlineFilter'
import { useInlineFilter } from '../useInlineFilter'
import { getInlineFilterValueLabels } from '../valueUtils'

import type { InlineFilterComponent } from './types'

export const AttachmentInlineFilter: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { filter, ...inlineFilterProps } = useInlineFilter({ field })

    const valueLabels = useMemo(() => getInlineFilterValueLabels(filter, []), [filter])

    return (
        <InlineFilter
            closeOnSelect={true}
            valueLabels={valueLabels}
            field={field}
            columns={columns}
            options={[]}
            {...inlineFilterProps}
        />
    )
}
