import { invalidateDataConnections } from 'data/hooks/dataConnections'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { AirtableRepairResult } from './airtableRepairTypes'

export const tryServerRepair = async ({
    dataConnection,
    startCacheFill,
}: {
    dataConnection: DataConnectionDto
    startCacheFill: (dataConnectionSid: string) => void
}): Promise<AirtableRepairResult> => {
    try {
        const response = await fetchWithAuth(`data-connections/${dataConnection._sid}/repair/`, {
            method: 'POST',
        })
        const data: DataConnectionDto = await response.json()
        if (data.status === 'valid') {
            startCacheFill(dataConnection._sid)
            invalidateDataConnections()
            return 'valid'
        } else if (data.status === 'invalid_token') {
            return 'invalid_token'
        } else if (data.status === 'missing_permissions') {
            return 'missing_permissions'
        } else {
            return 'error'
        }
    } catch (e) {
        console.error(e)
        return 'error'
    }
}
