// @ts-strict-ignore
import { useContext } from 'react'

import { useNavigation } from 'data/hooks/navigation'
import { PathContext } from 'features/utils/PathContext'

type ViewType = {
    stack_id: string
    [key: string]: any
}

const useViewBreadcrumbTitle = (view: ViewType): string => {
    const { data: navigation } = useNavigation()
    const pathContext = useContext(PathContext)
    const routeUrl = pathContext?.pageUrl
    const navItem = navigation?.find(
        (nav) =>
            nav.url === routeUrl &&
            view?.stack_id === nav.stack_id &&
            nav.enabled &&
            !nav.hidden &&
            nav.parent_id
    )
    const viewTitle = view?.options?.title || view?.name
    const breadcrumbTitle = navItem?.label || viewTitle

    return breadcrumbTitle
}

export default useViewBreadcrumbTitle
