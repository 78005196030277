import * as React from 'react'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { SidebarItem } from '../../../v2/ui'

/**
 * list of sidebar items
 * @type {{id: WorkspaceSettingsPage, name: string, icon: string}[]}
 */
const menu = [
    {
        name: 'General',
        id: 'general',
        icon: 'cog',
    },
    {
        name: 'Appearance',
        id: 'appearance',
        icon: 'brush',
    },
]
export default function Sidebar({ onChange, active, userLists }) {
    return (
        <Flex
            column
            style={{
                width: 200,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                overflowY: 'auto',
                padding: 8,
                flexShrink: 0,
            }}
        >
            <div style={{ ...V4DesignSystem.label, paddingLeft: 10 }}>Settings</div>
            <div style={{ height: 5 }} />
            {menu.map(({ icon, name, id }) => (
                <>
                    <SidebarItem active={active === id} onChange={() => onChange(id)} icon={icon}>
                        {name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </>
            ))}

            <div
                style={{
                    ...V4DesignSystem.label,
                    paddingLeft: 10,
                    paddingTop: 12,
                    paddingBottom: 6,
                }}
            >
                Customers
            </div>
            {userLists?.length > 0 && (
                <SidebarItem
                    active={active === 'all_users'}
                    onChange={() => onChange('all_users')}
                    icon="users"
                >
                    All Customers
                </SidebarItem>
            )}
            {userLists?.map((list) => (
                <>
                    <SidebarItem
                        active={active === list._sid}
                        onChange={() => onChange(list._sid)}
                        icon="list"
                    >
                        {list.name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </>
            ))}

            <SidebarItem
                active={active === 'add_customer_list'}
                onChange={() => onChange('add_customer_list')}
                icon={'add'}
            >
                Add customer list
            </SidebarItem>
        </Flex>
    )
}
