import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidDownloadProps = {
    color?: string
    size?: number
}

const SolidDrag: React.VFC<SolidDownloadProps> = ({
    color = colors.userInterface.neutral[700],
    size = 24,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.333 1a1.667 1.667 0 1 1-.597.11A1.245 1.245 0 0 1 5.289 1h.044zm5.334 0a1.667 1.667 0 1 1 0 3.333 1.667 1.667 0 0 1 0-3.333zM7 8a1.667 1.667 0 1 0-3.333 0A1.667 1.667 0 0 0 7 8zm3.667-1.667a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334zm-3.667 7a1.667 1.667 0 1 0-3.333 0 1.667 1.667 0 0 0 3.333 0zm3.667-1.666a1.667 1.667 0 1 1 0 3.333 1.667 1.667 0 0 1 0-3.333z"
            fill={color}
        />
    </svg>
)

export default SolidDrag
