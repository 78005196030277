/* Code Quality: Not audited */

import React from 'react'

import { css, cx } from 'emotion'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Button from './DeprecatedButtonForIcon'
import { withTooltip } from './Tooltip'

const withButton = (Component) => {
    return (props) => {
        const { onClick, isButton, ...childProps } = props

        if (!onClick || !isButton) return <Component onClick={onClick} {...childProps} />

        return (
            <Button
                style={{ background: 'none', border: 'none', padding: '0', margin: 0 }}
                onClick={onClick}
            >
                <Component {...childProps} />
            </Button>
        )
    }
}

class Icon extends React.Component {
    render() {
        const style = css`
            margin: 0px ${this.props.theme[this.props.margin || 'small']};
            vertical-align: middle;
        `

        let weightIndicator = ''
        if (this.props.weight === 'heavy') weightIndicator = ''
        if (this.props.weight === 'regular') weightIndicator = 'r'
        if (this.props.weight === 'light') weightIndicator = 'l'
        const className = cx(
            `fa${weightIndicator} fa-${this.props.icon}`,
            style,
            this.props.className
        )

        return <i {...this.props} className={className} />
    }
}
export default withButton(withTooltip(withTheme(Icon)))
