import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StreamContext } from 'react-activity-feed'
import {
    FilePreviewer,
    FileUploadButton,
    ImagePreviewer,
    ImageUploadButton,
} from 'react-file-utils'

import * as Sentry from '@sentry/react'
import isEqual from 'lodash/isEqual'
import shortid from 'shortid'
import { Transforms } from 'slate'

import { useAppUsers } from 'data/hooks/users/main'
import { withUser } from 'data/wrappers/WithUser'
import useMentions from 'features/collaboration/Mentions'

import { Button, Container, Flex, Progress, RichTextarea, Text, Tooltip } from 'v2/ui'
import useDebounce from 'v2/ui/utils/useDebounce'
import usePlainTextSerializer from 'v2/ui/utils/usePlainTextSerializer'

import { checkIsCollaborationActivityDisabled } from './checkIsCollaborationActivityDisabled'

const defaultState = {
    text: '',
    message: '',
    imageUploads: {},
    imageOrder: [],
    fileUploads: {},
    fileOrder: [],
    ogUrlOrder: [],
    ogStateByUrl: {},
    ogActiveUrl: null,
}

// Stream says the have an activity size limit of 4kb. However
// In my tests, anything over 3900 failed. Not sure how they're calculating it
const MAX_POST_SIZE = 3850

const CreatePostForm = ({
    activityVerb = 'post',
    modifyActivityData,
    relatedTo,
    user,
    ...props
}) => {
    const [messageState, setMessageState] = useState(defaultState)
    const [error, setError] = useState()
    const [submitting, setSubmitting] = useState()
    const editor = useRef()

    const { data: users } = useAppUsers()
    const mentions = useMentions(users || [])
    const plainText = usePlainTextSerializer()
    const streamContext = useContext(StreamContext)
    const [postLength, setPostLength] = useState()
    const plugins = useMemo(() => [mentions, plainText], [mentions, plainText])
    const [uploadedFileTimestamp, setUploadedFileTimestamp] = useState(new Date().getTime())

    // Calculates the post length as a percentage of maximum. Used for
    // displaying the progress/size indicator
    const updatePostLength = useDebounce(() => {
        const activity = getActivity()
        const textLength = JSON.stringify(activity.object).length
        const totalLength = JSON.stringify(activity).length

        // Overhead is how much data is used by the JSON structure and any
        // attachment objects
        const overhead = totalLength - textLength

        // Unless it's an extremely long post where just the overhead is larger than the
        // max size, we calculate our post length % without the overhead included.
        // This makes for a more accurate prepresentation to the user (ie., when the
        // the indicator says 50%, visually if they add about the same amount of content
        // they'll hit the limit)
        if (overhead < MAX_POST_SIZE) {
            setPostLength((totalLength - overhead) / (MAX_POST_SIZE - overhead))
        } else {
            setPostLength(totalLength / MAX_POST_SIZE)
        }
    })
    const handleMessageChange = (value) => {
        if (!isEqual(value, messageState.message)) {
            setMessageState((x) => ({ ...x, message: value }))
        }
    }

    const getObject = () => {
        return plainText.text ? messageState.message : {}
    }

    const getPlaintextMessage = () => {
        return plainText?.text
    }

    useEffect(() => {
        updatePostLength()
        setError(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageState])

    const getActivity = () => {
        const activity = {
            actor: 'SU:' + streamContext.client.currentUser.id,
            author: user._sid,
            verb: activityVerb,
            object: getObject(),
            discard_rule_actor_with_colon: true, // people will not get notified of their own posts

            // this is not required by getstream (the message content in the correct structure is sent in 'object')
            // but just allows us to send the plaintext of the message through to email notifications in a simple way
            // so we don't have to reconstruct the plaintext from 'object' server side
            plaintext_message_for_emails: getPlaintextMessage(),
        }

        const uploadedImages = _uploadedImages()
        const uploadedFiles = _uploadedFiles()

        const attachments = {}
        // const attachments: Attachments = {
        //   og: _activeOg(),
        // };

        if (uploadedImages) {
            attachments.images = uploadedImages.map((image) => image.url).filter(Boolean)
        }
        if (uploadedFiles) {
            attachments.files = uploadedFiles.map((upload) => ({
                // url will never actually be empty string because _uploadedFiles
                // filters those out.
                url: upload.url || '',
                name: upload.file.name,
                mimeType: upload.file.type,
            }))
        }

        if (Object.keys(attachments).length > 0) {
            activity.attachments = attachments
        }

        const mentionItems = mentions.getMentions().map((m) => m.id)
        activity.to = mentionItems.map((id) => 'notification:' + id.replace('.', '_'))
        activity.mentions = mentionItems
        activity.relatedTo = relatedTo

        const modifiedActivity = modifyActivityData ? modifyActivityData(activity) : activity

        return modifiedActivity
    }

    const addActivity = async () => {
        const activity = getActivity()

        if (props.doRequest) {
            return await props.doRequest(activity)
        } else {
            return await streamContext.client
                .feed(props.feedGroup, props.userId)
                .addActivity(activity)
        }
    }

    const clearEditor = () => {
        // This deselects the editor text before clearing it
        // The Slate editor will throw an error if we don't do this
        if (editor.current) {
            Transforms.deselect(editor.current)
        }
        setMessageState(defaultState)
    }

    const _orderedImages = () => messageState.imageOrder.map((id) => messageState.imageUploads[id])

    const _uploadedImages = () => _orderedImages().filter((upload) => upload.url)

    const _orderedFiles = () => messageState.fileOrder.map((id) => messageState.fileUploads[id])

    const _uploadedFiles = () => _orderedFiles().filter((upload) => upload.url)

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (checkIsCollaborationActivityDisabled()) return

        setError(false)
        setSubmitting(true)

        let response
        try {
            response = await addActivity()
        } catch (e) {
            setSubmitting(false)
            setError(true)
            Sentry.captureException(e)
            return
        }
        clearEditor()
        setSubmitting(false)
        if (props.onSuccess) {
            props.onSuccess(response)
        }
    }

    const _uploadNewFiles = (files) => {
        for (const file of files) {
            if (file.type.startsWith('image/')) {
                _uploadNewImage(file)
            } else if (file instanceof File) {
                _uploadNewFile(file)
            }
        }

        // We use this timestamp as a key on the upload buttons so that they re-create after
        // each upload, otherwise, they have internal state that prevents uploading the same
        // file again even if you have removed it from your list of uploads.
        setUploadedFileTimestamp(new Date().getTime())
    }

    const _uploadNewImage = async (file) => {
        const id = shortid.generate()

        await setMessageState((prevState) => {
            prevState.imageUploads[id] = {
                id,
                file,
                state: 'uploading',
            }
            return {
                ...prevState,
                imageOrder: prevState.imageOrder.concat(id),
                imageUploads: prevState.imageUploads,
            }
        })
        if (FileReader) {
            // TODO: Possibly use URL.createObjectURL instead. However, then we need
            // to release the previews when not used anymore though.
            const reader = new FileReader()
            reader.onload = (event) => {
                setMessageState((prevState) => {
                    prevState.imageUploads[id].previewUri = event.target.result
                    return { ...prevState, imageUploads: prevState.imageUploads }
                })
            }
            reader.readAsDataURL(file)
        }
        return _uploadImage(id)
    }

    const _uploadNewFile = async (file) => {
        const id = shortid.generate()

        await setMessageState((prevState) => {
            prevState.fileUploads[id] = {
                id,
                file,
                state: 'uploading',
            }
            return {
                ...prevState,
                fileOrder: prevState.fileOrder.concat(id),
                fileUploads: prevState.fileUploads,
            }
        })

        return _uploadFile(id)
    }

    // Shorten filename as getStream has a filename limit of 100.
    const shortenFilename = (file) => {
        if (file.name.length >= 100) {
            // Split filename into its name and file extension. ['test', 'png']
            let filenameParts = file.name.split('.')

            let extLength = 0
            // Handles filename with an extension.
            if (filenameParts.length > 1) {
                extLength = filenameParts[1].length + 1
            }

            // Shorten filename.
            filenameParts[0] = filenameParts[0].slice(0, 100 - extLength)
            const newFilename = filenameParts.join('.')

            return new File([file], newFilename, { type: file.type })
        }
        return file
    }

    const _uploadImage = async (id) => {
        const img = messageState.imageUploads[id]
        if (!img) {
            return
        }

        const file = shortenFilename(img.file)

        await setMessageState((prevState) => {
            prevState.imageUploads[id].state = 'uploading'
            return { ...prevState, imageUploads: prevState.imageUploads }
        })

        let response = {}
        response = {}
        try {
            response = await streamContext.client.images.upload(file)
        } catch (e) {
            console.warn(e)
            let alreadyRemoved = false
            await setMessageState((prevState) => {
                const image = prevState.imageUploads[id]
                if (!image) {
                    alreadyRemoved = true
                    return {}
                }
                image.state = 'failed'
                return { ...prevState, imageUploads: prevState.imageUploads }
            })

            if (!alreadyRemoved) {
                Sentry.captureException(e)
            }
            return
        }
        await setMessageState((prevState) => {
            img.state = 'finished'
            img.url = response.file
            return { ...prevState, imageUploads: prevState.imageUploads }
        })
    }

    const _uploadFile = async (id) => {
        const upload = messageState.fileUploads[id]
        if (!upload) {
            return
        }

        const file = shortenFilename(upload.file)

        await setMessageState((prevState) => {
            prevState.fileUploads[id].state = 'uploading'
            return { ...prevState, fileUploads: prevState.fileUploads }
        })

        let response = {}
        response = {}
        try {
            response = await streamContext.client.files.upload(file)
        } catch (e) {
            console.warn(e)
            await setMessageState((prevState) => {
                if (prevState.fileUploads[id]) {
                    prevState.fileUploads[id].state = 'failed'
                    return { ...prevState, fileUploads: prevState.fileUploads }
                }
                return prevState
            })

            Sentry.captureException(e)
            return
        }
        await setMessageState((prevState) => {
            if (prevState.fileUploads[id]) {
                prevState.fileUploads[id].state = 'finished'
                prevState.fileUploads[id].url = response.file
                return { ...prevState, fileUploads: prevState.fileUploads }
            }
            return {}
        })
    }

    const _removeImage = (id) => {
        setMessageState((prevState) => {
            const img = prevState.imageUploads[id]
            if (!img) {
                return {}
            }
            delete prevState.imageUploads[id]
            return {
                ...prevState,
                imageUploads: prevState.imageUploads,
                imageOrder: prevState.imageOrder.filter((_id) => id !== _id),
            }
        })
    }

    const _removeFile = (id) => {
        setMessageState((prevState) => {
            const upload = prevState.fileUploads[id]
            if (!upload) {
                return {}
            }
            delete prevState.fileUploads[id]
            return {
                ...prevState,
                fileUploads: prevState.fileUploads,
                fileOrder: prevState.fileOrder.filter((_id) => id !== _id),
            }
        })
    }

    const _isOgScraping = () => false

    const _canSubmit = () =>
        postLength <= 1 &&
        _orderedImages().every((upload) => upload.state !== 'uploading') &&
        _orderedFiles().every((upload) => upload.state !== 'uploading') &&
        !_isOgScraping() &&
        !submitting &&
        (plainText.text || _orderedImages().length || _orderedFiles().length)

    return (
        <Container pb={[2, 2, 3, 5]} px={[2, 2, 3, 5]} pt={[1, 1, 1, 2]}>
            <form onSubmit={handleSubmit}>
                <RichTextarea
                    ref={editor}
                    value={messageState.message}
                    onChange={handleMessageChange}
                    plugins={plugins}
                    placeholder="Enter a message…"
                    readOnly={submitting}
                />
                {messageState.imageOrder.length > 0 && (
                    <ImagePreviewer
                        imageUploads={messageState.imageOrder.map(
                            (id) => messageState.imageUploads[id]
                        )}
                        handleRemove={_removeImage}
                        handleRetry={_uploadImage}
                        handleFiles={_uploadNewFiles}
                    />
                )}{' '}
                {messageState.fileOrder.length > 0 && (
                    <FilePreviewer
                        uploads={messageState.fileOrder.map((id) => messageState.fileUploads[id])}
                        handleRemove={_removeFile}
                        handleRetry={_uploadFile}
                        handleFiles={_uploadNewFiles}
                    />
                )}
                <Flex mt={2}>
                    <Flex flexGrow={1} key={uploadedFileTimestamp}>
                        <ImageUploadButton handleFiles={_uploadNewFiles} multiple />
                        <FileUploadButton handleFiles={_uploadNewFiles} multiple />
                    </Flex>
                    {error && (
                        <Text variant="error" mr={4}>
                            Unable to post message. Please try again.
                        </Text>
                    )}
                    <Tooltip disabled={postLength <= 1} label="Your post is too long to submit">
                        <Flex wrap="nowrap">
                            {postLength > 0.6 && (
                                <Progress
                                    width="50px"
                                    colorScheme="green"
                                    bg="gray.100"
                                    value={postLength * 100}
                                    borderRadius={postLength <= 1 ? '5px' : '5px 0px 0px 5px'}
                                    mr={postLength <= 1 ? 10 : null}
                                />
                            )}
                            {postLength > 1 && (
                                <Progress
                                    width={8}
                                    colorScheme="pink"
                                    bg={null}
                                    mr={2}
                                    borderLeft="2px solid black"
                                    value={(postLength - 1) * 100 + 10}
                                    borderRadius={'0px 5px 5px 0px'}
                                />
                            )}
                        </Flex>
                    </Tooltip>
                    <Button
                        type="submit"
                        size="md"
                        variant="secondary"
                        isLoading={submitting}
                        disabled={!_canSubmit()}
                    >
                        Post
                    </Button>
                </Flex>
            </form>
        </Container>
    )
}

export default withUser(CreatePostForm)
