if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-undef
    module.exports = require('../node_modules/@sentry/react')
} else {
    // eslint-disable-next-line no-undef
    module.exports = {
        init: noop,
        configureScope: noop,
        withScope: (fn) =>
            fn({
                setExtra: noop,
                setTags: noop,
                setLevel: noop,
            }),
        captureMessage: (...args) => console.log('[Sentry]', ...args),
        captureException: (...args) => console.log('[Sentry]', ...args),
    }
}

function noop() {}
