// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { withStack } from 'data/wrappers/WithStacks'
import UpgradeButton from 'features/billing/UpgradeButton'

import Box from 'v2/ui/components/Box'
import Button from 'v2/ui/components/Button'
import ConditionalWrapper from 'v2/ui/components/ConditionalWrapper'
import Flex from 'v2/ui/components/Flex'
import Heading from 'v2/ui/components/Heading'
import Modal from 'v2/ui/components/Modal'
import Text from 'v2/ui/components/Text'
import Tooltip from 'v2/ui/components/Tooltip'
import { AllPlansUpgradeIcon } from 'v2/ui/svgs'
export enum Plans {
    starter = 'Starter',
    plus = 'Plus',
    pro = 'Pro',
    team = 'Team',
    enterprise = 'Enterprise',
}
export type Feature = {
    /**
     * This must be match to backend
     */
    plan?: string
    legacyPlan?: string
    key: string
    description: string
    allowPreview?: boolean
    name: string
    /**
     *if you need custom logic to check if feature enabled - add this property to Feature
     */
    getHasAccess?: () => boolean
    getPlan?: () => Plans

    /**
     * If true,
     * Will not be shown to trial users
     */
    excludeFromTrial?: boolean
}

type protectedFeatures = {
    [key: string]: Feature
}

export const FEATURES: protectedFeatures = {
    duplicateLists: {
        plan: Plans.starter,
        legacyPlan: Plans.plus,
        key: 'combined_optional_features.multiple_list_views',
        description: 'multiple list views per table',
        allowPreview: true,
        name: 'multiple_list_views',
    },
    multiplePermissionRules: {
        plan: Plans.pro,
        key: 'combined_optional_features.multiple_permission_rules',
        description: 'unlimited permission rules',
        allowPreview: true,
        name: 'multiple_permission_rules',
    },
    multipleRoles: {
        plan: Plans.pro,
        key: 'combined_optional_features.multiple_roles',
        description: 'unlimited roles',
        allowPreview: true,
        name: 'multiple_roles',
    },
    kanban: {
        plan: Plans.plus,
        key: 'combined_optional_features.kanban',
        description: 'kanban board view',
        allowPreview: true,
        name: 'kanban',
    },
    customDomain: {
        plan: Plans.pro,
        key: 'combined_optional_features.custom_domain',
        description: 'custom domains',
        allowPreview: false,
        name: 'custom_domain',
    },
    customScripts: {
        plan: Plans.pro,
        key: 'combined_optional_features.custom_scripts',
        description: 'custom scripts',
        allowPreview: false,
        name: 'custom_scripts',
    },
    clientLogos: {
        plan: Plans.enterprise,
        key: 'combined_optional_features.client_logos',
        description: 'client logos',
        allowPreview: false,
        name: 'client_logos',
    },
    customCss: {
        plan: Plans.pro,
        key: 'combined_optional_features.custom_css',
        description: 'custom CSS',
        allowPreview: false,
        name: 'custom_css',
        excludeFromTrial: true,
    },
    collaboration: {
        plan: Plans.plus,
        key: 'combined_optional_features.collaboration',
        description: 'collaboration comments',
        name: 'collaboration',
    },
    charts: {
        key: 'combined_optional_features.charts',
        description: 'Charts',
        name: 'charts',
        excludeFromTrial: true,
    },
    tabs: {
        key: 'combined_optional_features.multi_tabs_detailview',
        description: 'detail view tabs',
        name: 'multi_tabs_detailview',
    },
    fivetranConnector: {
        key: 'combined_optional_features.fivetran_connector',
        description: 'fivetran connector',
        name: 'fivetran_connector',
        excludeFromTrial: true,
    },
    inboxView: {
        plan: Plans.plus,
        key: 'combined_optional_features.inbox_view',
        description: 'Inbox view',
        name: 'inbox_view',
    },
    workspaceCustomDomain: {
        plan: Plans.pro,
        key: 'combined_optional_features.workspace_custom_domain',
        description: 'Workspace custom domain',
        name: 'workspace_custom_domain',
    },
    globalCustomCss: {
        plan: Plans.pro,
        key: 'combined_optional_features.global_custom_css',
        description: 'Global custom css',
        name: 'global_custom_css',
    },
    unlimitedApps: {
        key: 'combined_optional_features.unlimited_apps',
        description: 'Unlimited apps',
        name: 'unlimited_apps',
    },
    bundles: {
        key: 'combined_optional_features.bundles',
        description: 'Bundles',
        name: 'bundles',
        excludeFromTrial: true,
    },
    installable_features: {
        key: 'combined_optional_features.installable_features',
        description: 'Installable Features',
        name: 'installable_features',
        excludeFromTrial: true,
    },
    thirdPartyTags: {
        plan: Plans.pro,
        key: 'combined_optional_features.third_party_tags',
        description: 'Third party tags',
        name: 'third_party_tags',
    },
    selfServeCustomScripts: {
        plan: Plans.enterprise,
        key: 'combined_optional_features.self_serve_custom_scripts',
        description: 'Self serve custom scripts',
        name: 'self_serve_custom_scripts',
        excludeFromTrial: true,
    },
}

export enum IndicatorPositions {
    left = 'left',
    right = 'right',
}

export const isFeatureLocked = (feature: Feature, stack?: StackDto | null) => {
    if (!stack) return true

    // allow access if in a trial
    if (stack.is_using_trial && !feature?.excludeFromTrial) {
        return false
    }

    // disable if the trial has expired or the subscription has been cancelled
    if (stack.is_trial_expired || stack.is_cancelled) {
        return true
    }

    const hasAccess = feature.getHasAccess ? feature.getHasAccess() : get(stack, feature.key, false)
    return !hasAccess
}

export const getPlan = (stack: any, feature: Feature): string | undefined => {
    return (
        (!stack?.options?.workspace_app && feature.legacyPlan) ||
        (feature.getPlan && feature.getPlan()) ||
        feature.plan
    )
}

export const StyledPlanBadge = styled(Box)`
    background: ${(props) => get(props.theme, 'colors.admin.upgradeButton.background')};
    color: ${(props) => get(props.theme, 'colors.admin.upgradeButton.color')};
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: bold;
`

type ProtectedFeatureIndicatorProps = {
    feature: Feature
    inUse?: boolean
    tooltipOpen?: boolean
    isLaunched?: boolean
    stack: any
    onClick?: any
    style?: any
    theme?: any
}

/** Displays a small indicator badge listing the plan (pro, plus, etc)
 * under which the specified feature is available. Shows a tooltip describing
 * the state of access to that feature depending on the launched status
 * of the stack.
 */
export const ProtectedFeatureIndicator = withStack(
    ({
        feature,
        inUse,
        tooltipOpen,
        isLaunched,
        stack,
        onClick,
        style,
        theme,
        ...props
    }: ProtectedFeatureIndicatorProps) => {
        const hasAccess = !isFeatureLocked(feature, stack)
        const allowPreview = feature.allowPreview
        const isUsingTrial = stack.is_using_trial
        const { workspaceAccount } = useAppContext()
        const plan = getPlan(stack, feature)
        if (!(isUsingTrial && plan !== 'Starter') && hasAccess && isLaunched) return null

        //don't show indicators for silent trials
        if (!workspaceAccount?.trial_ui_enabled) return null

        let tooltipText
        if (!isLaunched) {
            tooltipText = inUse
                ? `Previewing. Launch with ${plan} to use this feature live.`
                : `Available in Stacker ${plan}. ${allowPreview ? 'Preview it before launch.' : ''}`
        } else {
            tooltipText = `Available in Stacker ${plan}. Upgrade to use it.`
        }
        return (
            <Tooltip
                isOpen={tooltipOpen}
                label={tooltipText}
                placement="top"
                wrapperStyle={{}}
                hasArrow
            >
                <ConditionalWrapper
                    condition={onClick}
                    wrapper={(children) => (
                        <Button style={style} onClick={onClick} variant="clear" p={0}>
                            {children}
                        </Button>
                    )}
                >
                    <StyledPlanBadge style={!onClick ? style : null} theme={theme} {...props}>
                        {plan}
                    </StyledPlanBadge>
                </ConditionalWrapper>
            </Tooltip>
        )
    }
)

export const UpgradeModal = ({ isOpen, onClose, featurePlan, featureDescription, stack }) => {
    AllPlansUpgradeIcon
    let header = ''
    let content: string[] = []

    switch (featurePlan) {
        case Plans.pro:
            header = 'Upgrade to Pro (or higher)'
            if (stack?.options?.workspace_app)
                content = [
                    'Upgrade for unlimited apps, roles & advanced permissions, more admin seats, ' +
                        "custom scripts & CSS, and a custom domain for your workspace. You'll also get priority support.",
                ]
            else
                content = [
                    'Unlimited records',
                    FEATURES.duplicateLists.description,
                    FEATURES.multiplePermissionRules.description,
                    FEATURES.multipleRoles.description,
                    'whitelabel',
                    FEATURES.customDomain.description,
                    FEATURES.customScripts.description,
                    FEATURES.customCss.description,
                    'guided setup',
                    'Stacker branding will disappear',
                    "and you'll be our top priority in support.",
                ]
            break
        case Plans.team:
            header = 'Upgrade to Team'
            if (stack?.options?.workspace_app)
                content = [
                    "You're all in. Integrate Stacker deeply with Enterprise SSO and Whitelabel" +
                        " and enough admins for your whole organization. You'll also get our enterprise support.",
                ]
            else
                content = [
                    'Unlimited apps',
                    'Enterprise SLAs',
                    'Enterprise Support',
                    'Unlimited records',
                    FEATURES.clientLogos.description,
                    FEATURES.duplicateLists.description,
                    FEATURES.multiplePermissionRules.description,
                    FEATURES.multipleRoles.description,
                    'whitelabel',
                    FEATURES.customDomain.description,
                    FEATURES.customScripts.description,
                    FEATURES.customCss.description,
                    'guided setup',
                    'Stacker branding will disappear',
                    "and you'll be our top priority in support.",
                ]
            break
        case Plans.enterprise:
            header = 'Upgrade to Enterprise'
            if (stack?.options?.workspace_app)
                content = [
                    'For large companies with complex data needs. Unlimited workspaces, whitelabelling, Enterprise SSO, additional data connectors and a dedicated account manager.',
                ]
            else
                content = [
                    'Unlimited apps',
                    'Enterprise SLAs',
                    'Enterprise Support',
                    'Unlimited records',
                    FEATURES.clientLogos.description,
                    FEATURES.duplicateLists.description,
                    FEATURES.multiplePermissionRules.description,
                    FEATURES.multipleRoles.description,
                    'whitelabel',
                    FEATURES.customDomain.description,
                    FEATURES.customScripts.description,
                    FEATURES.customCss.description,
                    'guided setup',
                    'Stacker branding will disappear',
                    "and you'll be our top priority in support.",
                ]
            break
        default:
            header = 'Upgrade to Plus (or higher)'
            if (stack?.options?.workspace_app)
                content = [
                    'Do more with unlimited records, more apps and admin seats, ' +
                        "and new capabilities with Kanban, Inbox and Comments & Notifications. You'll also " +
                        'get access to our chat support.',
                ]
            else
                content = [
                    'Unlimited records',
                    FEATURES.duplicateLists.description,
                    FEATURES.kanban.description,
                    FEATURES.collaboration.description,
                    ' and Stacker branding will be much more subtle.',
                ]
    }

    return (
        <Modal
            padding="12px 20px 20px 20px"
            size="450px"
            showCloseButton={false}
            isOpen={isOpen}
            onClose={onClose}
            body={
                <Flex column align="Center">
                    <Box display="inline-block" mb={4}>
                        <AllPlansUpgradeIcon />
                    </Box>

                    <Heading style={{ marginBottom: '10px' }} variant="modal" value={header} />
                    <Text variant="modalBody">
                        {content.map((x, idx) => (
                            <React.Fragment key={idx}>
                                {x === featureDescription ? <strong>{x}</strong> : <span>{x}</span>}
                                {idx < content.length - 1 ? ', ' : ''}
                            </React.Fragment>
                        ))}
                    </Text>
                </Flex>
            }
            actionsHorizontal
            actions={[
                {
                    Component: Button,
                    marginRight: '12px',
                    marginLeft: 'auto',
                    height: '38px',
                    label: 'Cancel',
                    variant: 'Secondary',
                    onClick: onClose,
                    flex: 1,
                },
                {
                    Component: UpgradeButton,
                    marginLeft: '12px',
                    height: '38px',
                    label: 'Upgrade now',
                    onClick: onClose,
                    featurePlan: featurePlan,
                    width: 'auto',
                    flex: 1,
                    variant: 'Primary',
                },
            ]}
        />
    )
}

type ProtectedFeatureProps = {
    feature: Feature
    indicatorPosition?: IndicatorPositions
    inUse?: boolean
    showModal?: boolean
    onModalClosed?: () => void
    isLaunched?: boolean
    children: any
    stack: any
    indicatorTop?: string
    indicatorLeft?: string
    indicatorRight?: string
    indicatorBottom?: string
    hideIfAvailable?: string
}

/**
 * Handles preventing interaction with the children elements if the specified
 * feature is unavailable. Also, optionally, displays a ProtectedFeatureIndicator
 * next to the content if the feature is locked, or the stack is in builder mode.
 */
export const ProtectedFeature = withStack(
    ({
        feature,
        inUse,
        isLaunched,
        children,
        stack,
        indicatorPosition,
        indicatorTop,
        indicatorLeft,
        indicatorRight,
        indicatorBottom,
        hideIfAvailable,
        showModal,
        onModalClosed,
        ...props
    }: ProtectedFeatureProps) => {
        const [modalOpen, setModalOpen] = useState(false)
        const [showToolTip, setShowToolTip] = useState(false)
        const hasAccess = !isFeatureLocked(feature, stack)
        const featurePlan = getPlan(stack, feature)

        if (hideIfAvailable && hasAccess) {
            return null
        }

        useEffect(() => {
            setModalOpen(showModal ?? false)
        }, [showModal])

        const handleClick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            setShowToolTip(false)
            setModalOpen(true)
        }

        const handleKeyDown = (e) => {
            if (e.keyCode !== 9) {
                e.stopPropagation()
                e.preventDefault()
                setShowToolTip(false)
                setModalOpen(true)
            }
        }

        const handleCloseModal = () => {
            setModalOpen(false)
            if (onModalClosed) onModalClosed()
        }
        const handleMouseOver = () => setShowToolTip(true)
        const handleMouseOut = () => setShowToolTip(false)

        const indicatorAbsolute = indicatorTop || indicatorLeft || indicatorRight || indicatorBottom
        const indicatorPositionStyle = {
            position: indicatorAbsolute ? 'absolute' : 'relative',
            top: indicatorTop,
            left: indicatorLeft,
            right: indicatorRight,
            bottom: indicatorBottom,
        }

        const indicator = (
            <ProtectedFeatureIndicator
                tooltipOpen={showToolTip}
                feature={feature}
                inUse={inUse}
                hasAccess={hasAccess}
                mx={3}
                style={indicatorPositionStyle}
                onClick={handleClick}
            />
        )

        const left =
            (indicatorPosition === IndicatorPositions.left || indicatorAbsolute) && indicator
        const right = indicatorPosition === IndicatorPositions.right && indicator
        return (
            <Box
                onMouseDownCapture={!hasAccess && isLaunched ? handleClick : undefined}
                onMouseDown={!hasAccess && isLaunched ? handleClick : undefined}
                onKeyDownCapture={!hasAccess && isLaunched ? handleKeyDown : undefined}
                alignItems="center"
                display={indicatorPosition ? 'flex' : 'block'}
                position="relative"
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                {...props}
            >
                {left}
                {children}
                <UpgradeModal
                    isOpen={modalOpen}
                    onClose={handleCloseModal}
                    featurePlan={featurePlan}
                    featureDescription={feature.description}
                    stack={stack}
                />
                {right}
            </Box>
        )
    }
)

export default ProtectedFeature

export const isCollaborationEnabled = (stack) => {
    return !isFeatureLocked(FEATURES.collaboration, stack) && stack?.options?.enable_comment_feed
}
