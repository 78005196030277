/* Code Quality: Not audited */

import React from 'react'
import { toast } from 'react-toastify'

import { getUrl, Urls } from 'app/UrlService'
import { fetchInfo } from 'data/githubFeatures'
import { fetchWithAuth } from 'data/utils/utils'
import WithStacks from 'data/wrappers/WithStacks'
import FeatureStudio, { CenteredContent } from 'features/studio/FeatureStudio'
import { Button, Card, Container, Heading, Icon8, Text } from 'legacy/v1/ui'

export default class AddFeaturePage extends React.Component {
    state = { features: [], inProgress: false }

    UNSAFE_componentWillMount() {
        console.log('will mount')
    }
    fetchFeaturesInfo(stack) {
        if (
            stack._sid !== undefined && //Stack is loaded
            !this.state.inProgress &&
            this.state.features.length === 0
        ) {
            this.setState({ inProgress: true })
            fetchInfo(stack.options.enable_test_features).then((features) => {
                this.setState({ features: features })
            })
        }
    }
    render() {
        return (
            <FeatureStudio>
                <WithStacks>
                    {({ selectedStack: stack }) => {
                        this.fetchFeaturesInfo(stack)
                        return (
                            <CenteredContent>
                                <Heading size="medium" style={{ marginTop: 40 }}>
                                    Feature Library
                                </Heading>
                                <Container wrapItems style={{ width: '120%' }}>
                                    {this.state.features.map((feature, index) => (
                                        <FeatureCard key={index} {...feature} />
                                    ))}
                                </Container>
                            </CenteredContent>
                        )
                    }}
                </WithStacks>
            </FeatureStudio>
        )
    }
}

export const FeatureCard = ({ cover, icon, name, description, package_url }) => {
    return (
        <Card style={{ width: 340, height: 320, margin: 10, overflow: 'hidden' }}>
            <div
                style={{
                    margin: '-20px -20px 10px',
                    width: 'calc(100% + 40px)',
                    height: 200,
                    backgroundImage: `url(${cover})`,
                    backgroundSize: 'cover',
                }}
            />
            <Container>
                <Icon8 icon={`${icon}`} iconStyle="color" size={100} displaySize="35" />
                <Text style={{ fontWeight: 'bold', margin: 0, marginRight: 'auto' }}>
                    {name}
                </Text>{' '}
                <Button
                    padding="small"
                    color="success"
                    margin="none"
                    onClick={() => install_feature_and_redirect(package_url)}
                >
                    Add
                </Button>
            </Container>
            <Container>
                <Text>{description}</Text>
            </Container>
        </Card>
    )
}

export function install_feature(package_url) {
    console.info('Installing ' + package_url)

    return fetchWithAuth('package/install', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            package_url: package_url,
        }),
    })
}

export function update_feature(
    feature_sid,
    package_url,
    repo_branch = undefined,
    ignore_local_modifications = false
) {
    console.info(
        'Updating ' +
            feature_sid +
            ' from ' +
            package_url +
            (repo_branch ? ' @ ' + repo_branch : '')
    )

    var data = {
        feature: feature_sid,
        package_url: package_url,
    }
    if (repo_branch) {
        data['repo_branch'] = repo_branch
    }
    if (ignore_local_modifications) {
        data['ignore_local_modifications'] = ignore_local_modifications
    }

    return fetchWithAuth('package/update', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}

export function install_feature_and_redirect(package_url) {
    _redirect_or_error(install_feature(package_url))
}

export function update_feature_and_redirect(feature_sid, package_url, repo_branch = undefined) {
    _redirect_or_error(update_feature(feature_sid, package_url, repo_branch))
}

function _redirect_or_error(promise) {
    promise
        .then((response) => {
            if (response.status >= 400) {
                toast.error('An error occurred while trying to install this feature.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2500,
                    hideProgressBar: true,
                })
            } else {
                response.json().then((json) => {
                    console.info(json)
                    if (json._sid) {
                        window.location = `${getUrl(Urls.StudioFeatures)}${json._sid}/settings`
                    }
                })
            }
        })
        .catch(() => {
            toast.error('An error occurred while trying to install this feature.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2500,
                hideProgressBar: true,
            })
        })
}

// Allow the installer to be called manually from the browser console.
window.stacker = window.stacker || {}
window.stacker.install_feature = install_feature
window.stacker.update_feature = update_feature
