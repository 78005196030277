import React, {
    ComponentType,
    FC,
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'

import { ButtonProps, useDisclosure } from '@chakra-ui/react'
import type { Placement } from '@popperjs/core'

import Button from './Button'
import Popper from './Popper'

export type PopoverButtonChildrenProps = {
    isOpen: boolean
    onToggle: () => void
    buttonRef: MutableRefObject<HTMLElement>
    close: () => void
}

export type Props = ButtonProps & {
    defaultIsOpen: boolean
    label?: string | ReactNode
    ButtonComponent?: ComponentType
    onOpen?: () => void
    onClose?: () => void
    place?: Placement
    hideOnScroll?: boolean
    closeOnOuterAction?: boolean
    disabled?: boolean
    icon?: string | Element
    usePortal?: boolean
}

const PopoverButton: FC<Props> = ({
    defaultIsOpen,
    children,
    ButtonComponent = Button,
    label,
    onOpen,
    onClose,
    place = 'bottom',
    hideOnScroll = false,
    closeOnOuterAction,
    usePortal = true,
    disabled,
    ...props
}) => {
    const { isOpen, onToggle, onClose: close, onOpen: open } = useDisclosure()
    const lastOpenValue = useRef(false)
    const buttonRef = useRef<HTMLElement | null>(null)

    // if we're supposed to default to open, set a timeout and
    // open after the component has had a chance to mount, otherwise
    // the popover will appear in the upper left of the screen
    useEffect(() => {
        if (defaultIsOpen) {
            setTimeout(() => open(), 0)
        }
    }, [defaultIsOpen, open])

    const isPopoverOpen = isOpen && !disabled

    const content = useMemo(
        () =>
            typeof children === 'function'
                ? children({ isOpen, onToggle, buttonRef, close })
                : children,
        [buttonRef, children, close, isOpen, onToggle]
    )

    const handleClick = (event: MouseEvent) => {
        onToggle()

        event.preventDefault()
        event.stopPropagation()
    }

    const handleClosePopover = useCallback(() => {
        close()
        onClose?.()
    }, [close, onClose])

    useEffect(() => {
        if (isOpen === lastOpenValue.current) {
            return
        }

        lastOpenValue.current = isOpen
        if (isOpen) {
            onOpen?.()
        } else {
            onClose?.()
        }
    }, [isOpen, onOpen, onClose])

    useEffect(() => {
        if (isPopoverOpen && hideOnScroll) {
            window.addEventListener('scroll', handleClosePopover, true)
        }

        return () => {
            window.removeEventListener('scroll', handleClosePopover, true)
        }
    }, [handleClosePopover, hideOnScroll, isPopoverOpen])

    return (
        <>
            <ButtonComponent disabled={disabled} onClick={handleClick} ref={buttonRef} {...props}>
                {label}
            </ButtonComponent>
            {isOpen && (
                <Popper
                    referenceElement={buttonRef.current}
                    placement={place}
                    closeOnOuterAction={closeOnOuterAction}
                    onClose={handleClosePopover}
                    usePortal={usePortal}
                >
                    {content}
                </Popper>
            )}
        </>
    )
}
export default PopoverButton
