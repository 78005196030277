import without from 'lodash/without'

import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    ACCEPTED_TRANSLATIONS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
    TRANSLATION_CREATED,
    TRANSLATION_REMOVED,
    TRANSLATION_UPDATED,
    TRANSLATIONS_FETCHED,
} from '../utils/constants'

// STATE
export const initialState = {
    translations: [],
    acceptedTranslations: {},
    entities: {},
    fetching: [],
}

// REDUCERS
export function translationReducer(state = initialState, action) {
    let translations
    let translation
    let fetching
    switch (action.type) {
        case TRANSLATIONS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                translations: unique([...state.translations, ...action.payload.map((t) => t._sid)]),
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case TRANSLATION_CREATED:
            if (!action.payload) return state
            translations = unique([...state.translations, action.payload._sid])
            return {
                ...state,
                translations,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case TRANSLATION_UPDATED:
            if (!action.payload) return state
            translation = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [translation._sid]: translation },
            }
        case TRANSLATION_REMOVED:
            translations = state.translations.map((ts) => without(ts, action.id))
            return {
                ...state,
                translations,
            }
        case ACCEPTED_TRANSLATIONS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                acceptedTranslations: action.payload,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
