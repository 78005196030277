import React, { forwardRef, useCallback } from 'react'
import { Link as InternalLink, useHistory } from 'react-router-dom'

import { Link as ChakraLink } from '@chakra-ui/react'
import styled from '@emotion/styled'
import type { LocationDescriptor } from 'history'
import { variant } from 'styled-system'

import { isExternal } from 'utils/utils'

import Text from 'v2/ui/components/Text'

type LinkStyleProps = {
    variant?: string
    colorMode?: 'dark' | 'light' | 'normal' | null
    hoverMode?: string
    to?: LocationDescriptor
}

const StyledLink = styled(ChakraLink)<LinkStyleProps>`
    ${(props) =>
        props.variant == 'breadcrumb'
            ? variant({
                  prop: 'colorMode',
                  variants: {
                      dark: {
                          borderColor: `breadcrumbs.normal.linkBorderColor`,
                          color: `breadcrumbs.dark.linkColor`,
                      },
                      light: {
                          borderColor: `breadcrumbs.light.linkBorderColor`,
                          color: `breadcrumbs.light.linkColor`,
                      },
                      normal: {
                          borderColor: `breadcrumbs.normal.linkBorderColor`,
                          color: `breadcrumbs.normal.linkColor`,
                      },
                  },
              })
            : ''}
    ${variant({
        variants: {
            breadcrumb: {
                fontWeight: 600,
                fontSize: ['breadcrumb.linkTextSm', null, 'breadcrumb.linkTextLg'],
                borderBottom: '1px solid',
                '&:hover': {
                    textDecoration: 'none',
                },
                '&:focus': {
                    boxShadow: 'none',
                },
            },
            card: {
                '&:hover': {
                    textDecoration: 'none',
                },
                display: 'block',
                '&:focus': {
                    'box-shadow': 'none',
                },
                '&.focus-visible': {
                    'box-shadow': '0 0 0 3px rgba(66, 153, 225, 0.6)',
                },
            },
            row: {
                display: 'block',
                '&:hover': {
                    textDecoration: 'none',
                },
            },
            noHover: {
                '&:hover': {
                    textDecoration: 'none',
                },
                '&:focus': {
                    'box-shadow': 'none',
                },
            },
        },
    })}

    ${variant({
        prop: 'hoverMode',
        variants: {
            none: {
                cursor: 'initial',
                borderBottom: 'none',
            },
        },
    })}
`

function generateInternalTarget(internalTarget: string, state: unknown): LocationDescriptor {
    try {
        const { pathname, search, hash } = new URL(internalTarget, window.location.href)
        return {
            pathname,
            search,
            hash,
            state,
        }
    } catch (ex) {
        console.log(`Failed to parse URL: ${internalTarget}`)
        console.error(ex)
    }
    return internalTarget
}

type LinkProps = React.ComponentPropsWithoutRef<typeof StyledLink> & {
    to?: LocationDescriptor
    openInNewTab?: boolean
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
    disabled?: boolean
    historyTitle?: string
    historyType?: string
    simplifiedComponent?: boolean
    variant?: string
    textOverflow?: string
    colorMode?: 'dark' | 'light' | 'normal' | null
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            href,
            children,
            to,
            openInNewTab,
            onClick,
            disabled,
            historyTitle,
            historyType,
            simplifiedComponent,
            className,
            ...props
        },
        ref
    ) => {
        let as: React.ComponentPropsWithoutRef<typeof StyledLink>['as'] =
            InternalLink as React.ComponentPropsWithoutRef<typeof StyledLink>['as']
        let internalTarget = to || href
        let windowTarget
        const history = useHistory()
        const isStringLink = typeof internalTarget === 'string'
        if (isStringLink && isExternal(href)) {
            as = StyledLink as unknown as typeof as
            internalTarget = ''
            windowTarget = '_blank'
        }

        // If openInNewTab is set either true or false (as opposed to undefined/null), this overrides the smart behaviour above
        if (typeof openInNewTab === 'boolean') {
            windowTarget = openInNewTab ? '_blank' : '_self'
        }

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLAnchorElement>) => {
                if (onClick) {
                    onClick(e)
                } else {
                    e.stopPropagation()
                }
            },
            [onClick]
        )

        if (disabled) {
            if (simplifiedComponent) {
                return (
                    <a
                        className={className === undefined ? 'default-link-disabled' : className}
                        ref={ref}
                        {...props}
                    >
                        {children}
                    </a>
                )
            } else {
                return (
                    <StyledLink
                        ref={ref}
                        hoverMode="none"
                        as={Text as React.ComponentPropsWithoutRef<typeof StyledLink>['as']}
                        className={className}
                        {...props}
                    >
                        {children}
                    </StyledLink>
                )
            }
        }

        if (isStringLink && internalTarget) {
            internalTarget = generateInternalTarget(internalTarget as string, {
                prev: history.location,
                title: historyTitle,
                type: historyType,
            })
        }

        // The simplifiedComponent property tells us to render the link as a simple component
        // (InternalLink or <a> tag) and not use the StyledLink component. This is much cheaper to render
        // and so is useful in scenarios where the link may be rendered many times (field values, for instance).
        // The downside is that style props such as p, m, bg, etc will not work. Styles must be passed in via the style
        // or className props
        if (simplifiedComponent) {
            if (internalTarget) {
                return (
                    <InternalLink
                        ref={ref}
                        to={internalTarget}
                        target={windowTarget}
                        className={className === undefined ? 'default-link' : className}
                        {...props}
                        onClick={handleClick}
                    >
                        {children}
                    </InternalLink>
                )
            } else {
                return (
                    <a
                        ref={ref}
                        href={href}
                        target={windowTarget}
                        className={className === undefined ? 'default-link' : className}
                        {...props}
                        onClick={handleClick}
                    >
                        {children}
                    </a>
                )
            }
        }
        return (
            <StyledLink
                as={as}
                to={internalTarget}
                href={href}
                ref={ref}
                target={windowTarget}
                className={className}
                {...props}
                onClick={handleClick}
            >
                {children}
            </StyledLink>
        )
    }
)

export default React.memo(Link)
