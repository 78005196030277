/* Code Quality: Not audited */

import React from 'react'

import { boolean, text } from 'features/pages/blocks/settings/attributes/items/primitives'

import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'

const AttributeBlock = (props) => {
    return (
        <AttributeDisplay
            objectId={props.attrs.objectId}
            fieldId={props.attrs.fieldId}
            record={props.context.record}
            required={props.attrs.required}
            fullWidth={props.attrs.fullwidth}
            readOnly={props.attrs.readOnly}
            editing={props.context.view.editing}
            showErrors={props.context.view.showErrors}
            setValue={props.context.view.actions.setValue}
            setValid={props.context.view.actions.setValid}
            valid={props.context.view.valid}
            isLoading={props.context.view?.isLoading}
        />
    )
}

AttributeBlock.attributes = [
    text({ field: 'objectId', label: 'Object' }),
    text({ field: 'fieldId', label: 'Field' }),
    // This read only flag is removed with update/create permissions
    // boolean({ field: "readOnly", label: "Read Only", simple: true }),
    boolean({ field: 'required', label: 'Required', simple: true }),
    boolean({ field: 'fullWidth', label: 'Full Width', simple: true }),
]

export default AttributeBlock
