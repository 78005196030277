import get from 'lodash/get'

import { getCurrentStack } from 'app/AppContextStore'
import store from 'app/store'
import queryClient from 'data/reactQueryCache'
import { isImpersonating, isImpersonatingEndUser } from 'data/utils/isAdmin'

import { getPreviewingRoleId } from './roleUtils'

export const getInternalUserRole = () => {
    const state = store.getState()
    const endUser = state.user.user
    const studioUser = state.user.studioUser
    const stack = getCurrentStack()
    const user = endUser || studioUser

    if (!user || !stack) return

    // If we're previewing as a specific role, use that role
    const previewingAsRole = queryClient
        .getQueryData('useRoles')
        ?.find((x) => x._sid === getPreviewingRoleId() && x.stack_id === stack._sid)
    if (previewingAsRole) {
        return previewingAsRole
    } else if (!isImpersonatingEndUser()) {
        const stackOptions = get(stack, 'options')
        const role = get(user, 'role_id')
        const defaultRoleId = get(stackOptions, 'roles__default_user_role')
        const assignedRole = role || defaultRoleId
        return queryClient
            .getQueryData('useRoles')
            ?.find((x) => x._sid === assignedRole && x.stack_id === stack._sid)
    }
    return null
}

export const getUserRoleId = () => {
    const state = store.getState()
    const endUser = state.user.user
    const studioUser = state.user.studioUser
    const stack = getCurrentStack()
    const user = endUser || studioUser

    if (!user || !stack) return

    if (!isImpersonating()) {
        return getPreviewingRoleId() || getInternalUserRole()?._sid
    } else if (!isImpersonatingEndUser()) {
        return getInternalUserRole()?._sid
    } else {
        const stackOptions = get(stack, 'options')
        const role = get(user, 'role_id')
        const defaultRoleId = get(stackOptions, 'roles__default_user_role')

        return role || defaultRoleId
    }
}
