// @ts-strict-ignore
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { truncate } from 'lodash'

import { useObject } from 'data/hooks/objects'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { Dropdown } from 'v2/ui'

import {
    getIsMultiLookupRelationship,
    getIsMultiLookupSymmetricRelationship,
    getIsSyntheticField,
} from '../../../utils/fieldUtils'

import { ConfigurationComponentProps, DATE_TYPES, NUMERIC_TYPES } from './common'
import { ConfigurationField } from './ui'

const OperatorOptions = [
    {
        label: 'Number of related records',
        value: 'count',
    },
    { label: 'Count values...', value: 'counta' },
    { label: 'Sum...', value: 'sum' },
    { label: 'Average...', value: 'avg' },
    { label: 'Min...', value: 'min' },
    { label: 'Max...', value: 'max' },
]
const OperatorSupportedTypes = {
    sum: NUMERIC_TYPES,
    avg: NUMERIC_TYPES,
    min: NUMERIC_TYPES.concat(DATE_TYPES),
    max: NUMERIC_TYPES.concat(DATE_TYPES),
}

export const RollupFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({ object }) => {
    const { setValue } = useFormContext()
    const filterObjects = (obj) =>
        obj.fields.find((field) => {
            const isLookup = field?.type === 'lookup'
            const isMultiLookupRelationship = getIsMultiLookupRelationship(field)
            const isMultiLookupSymmetricRelationship = getIsMultiLookupSymmetricRelationship(field)
            return (
                field?.options?.lookup_target === object?._sid &&
                (isLookup || isMultiLookupRelationship || isMultiLookupSymmetricRelationship)
            )
        })

    const connection_options: FieldConnectionOptions =
        useWatch({ name: 'connection_options' }) ?? {}
    const { object: targetObject } = useObject(connection_options?.rollup_target_object)
    const showSourceFields = connection_options.rollup_operator !== 'count'
    const targetFields =
        targetObject?.fields?.filter((x) => x.options?.lookup_target === object?._sid) || []
    const operator = connection_options.rollup_operator
    const filterSourceField = (field) =>
        !getIsSyntheticField(field) &&
        operator &&
        (!OperatorSupportedTypes[operator] || OperatorSupportedTypes[operator].includes(field.type))
    useEffect(() => {
        if (targetFields?.length === 1) {
            setValue('connection_options.rollup_relationship_field', targetFields[0]._sid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetObject])

    return (
        <>
            <ConfigurationField
                label="Summarize related records from"
                as={ObjectPicker}
                objectId={object?._sid}
                name="connection_options.rollup_target_object"
                placeholder="Select a table"
                filter={filterObjects}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                show={targetFields.length > 1}
                label={
                    <>
                        where the <strong>{object?.name}</strong> record is
                    </>
                }
                as={FieldPicker}
                objectId={targetObject?._sid}
                name="connection_options.rollup_relationship_field"
                placeholder="Select a field"
                provideOptionLabel={(field) =>
                    `${truncate(targetObject?.name, { length: 12 })} → ${field.label}`
                }
                filter={(field) => field?.options?.lookup_target === object?._sid}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />

            <ConfigurationField
                show={!!connection_options.rollup_relationship_field}
                label="Display the"
                as={Dropdown}
                name="connection_options.rollup_operator"
                placeholder="Select"
                options={OperatorOptions}
                controlled
                errorMessages={{ required: 'Select a roll-up operator' }}
                usePortal={false}
            />
            <ConfigurationField
                show={!!connection_options?.rollup_operator && showSourceFields}
                label={
                    <>
                        of the <strong>{targetObject?.name}</strong>
                    </>
                }
                as={FieldPicker}
                objectId={targetObject?._sid}
                name="connection_options.rollup_field"
                placeholder="Select a field"
                filter={filterSourceField}
                controlled
                required={!!connection_options?.rollup_operator && showSourceFields}
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
        </>
    )
}
