import { useLocation } from 'react-router-dom'

import { isEnabledField } from './isEnabledField'

type CreateQueryParamsConfig = {
    relatedFieldMayCreateNewRecords?: boolean
    isRecordList?: boolean
    isInbox?: boolean
    redirectToList?: boolean
    relatedListType?: string
    isRecordListOnCreate?: boolean
    relatedListSymmetricColumnName?: string
    autoFilledRelatedListRecord?: string
}

export const shouldAddViaRelatedList = (
    object: ObjectDto | undefined,
    config: CreateQueryParamsConfig
) => {
    if (config.isRecordList && config.relatedFieldMayCreateNewRecords) {
        if (config.relatedListType === 'all') {
            return true
        } else if (
            !config.isRecordListOnCreate &&
            isEnabledField(object?.fields, config.relatedListSymmetricColumnName)
        ) {
            return true
        }
    }
    return false
}

export const useCreateQueryParams = (
    object: ObjectDto | undefined,
    config: CreateQueryParamsConfig
) => {
    const location = useLocation()
    let queryParams = ''

    if (!object?._permissions?.may_create || object?.connection_options?.read_only) {
        return queryParams
    }

    const previousUrl = location
        ? `previous=${encodeURIComponent(location.pathname + location.search + location.hash)}`
        : ''

    if (config.isRecordList && config.relatedFieldMayCreateNewRecords) {
        if (config.relatedListType === 'all') {
            queryParams = `?${previousUrl}`
        } else if (
            !config.isRecordListOnCreate &&
            isEnabledField(object?.fields, config.relatedListSymmetricColumnName)
        ) {
            queryParams = `?autofill__${config.relatedListSymmetricColumnName}=${config.autoFilledRelatedListRecord}&${previousUrl}`
        }
    }

    // Support for 'redirect to list option'
    // If it's an inbox, we need to pass a separate query param to format the url properly
    if (!queryParams && config.redirectToList && !config.isRecordList) {
        queryParams = config.isInbox ? `?redirectToInbox=${location?.pathname}` : `?${previousUrl}`
    }

    return queryParams
}
