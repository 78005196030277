// @ts-strict-ignore
import color from 'color'

import { darken, lighten } from 'v2/ui/utils/colors'

import { ColorBaseConfigType } from '../types/colorBase'
import { ColorConfigCustom } from '../types/colorConfigCustom'

export const colorBaseConfig: ColorBaseConfigType = {
    userInterface: {
        neutral: {
            0: '#FFFFFF',
            100: '#F9F9FA',
            150: '#F5F6F6',
            200: '#EEF1F3',
            300: '#E8EAEC',
            400: '#E5E7EA',
            500: '#D7DBE1',
            600: '#C6CCD4',
            700: '#B6BEC5',
            800: '#9099A0',
            850: '#606B74',
            900: '#454E55',
            950: '#333C43',
            1000: '#1F2123',
            1100: '#15181D',
        },
        accent: {
            100: '#F0F3FF',
            200: '#DADFF7',
            400: '#B5BEEF',
            600: '#7E8EE4',
            800: '#6B7DE0',
            900: '#07124f',
            1000: '#465DD8',
            1200: '#384AAD',
            1400: '#2A3882',
            1600: '#1C2556',
        },
        error: {
            100: '#FBEDEC',
            200: '#F9D5D2',
            300: '#F4ABA4',
            400: '#EE8177',
            500: '#E9594C',
            600: '#E43120',
            700: '#BA2417',
        },
        success: {
            100: '#EBF8E5',
            200: '#CEE9C1',
            300: '#A7D68F',
            400: '#7FC35C',
            500: '#43B02A',
            600: '#4A9724',
            700: '#3E7D1E',
        },
        warning: { 200: '#ffe6a6', 1000: '#FFC021', 1200: '#cc9a1a' },
        darkGreen: '#2F6043',
        labelLightGreen: '#D5F3CD',
        formulaFunction: '#008000',
    },
    appColors: {
        blue: {
            100: '#D1E6F1',
            200: '#A2CCE4',
            300: '#74B3D6',
            400: '#4599C9',
            500: '#1780BB',
            600: '#1271A6',
            700: '#0E6291',
            800: '#09537D',
            900: '#054468',
            1000: '#003553',
        },
        gold: {
            100: '#F3EBD6',
            200: '#E7D7AD',
            300: '#DAC285',
            400: '#CEAE5C',
            500: '#C29A33',
            600: '#AB872A',
            700: '#937320',
            800: '#7C6017',
            900: '#644C0D',
            1000: '#4D3904',
        },
        green: {
            100: '#D7EED2',
            200: '#AFDDA5',
            300: '#88CB78',
            400: '#60BA4B',
            500: '#38A91E',
            600: '#319819',
            700: '#2A8715',
            800: '#247610',
            900: '#1D650C',
            1000: '#165407',
        },
        indigo: {
            100: '#DBDDEF',
            200: '#B7BBDF',
            300: '#9399D0',
            400: '#6F77C0',
            500: '#4B55B0',
            600: '#414A9A',
            700: '#383F85',
            800: '#2E356F',
            900: '#252A5A',
            1000: '#1B1F44',
        },
        mauve: {
            100: '#E8E0E2',
            200: '#D2C1C5',
            300: '#BBA3A9',
            400: '#A5848C',
            500: '#8E656F',
            600: '#7E5962',
            700: '#6E4D55',
            800: '#5E4249',
            900: '#4E363C',
            1000: '#3E2A2F',
        },
        moss: {
            100: '#E7EBCF',
            200: '#CFD79E',
            300: '#B8C46E',
            400: '#A0B03D',
            500: '#889C0D',
            600: '#76880A',
            700: '#647308',
            800: '#535F05',
            900: '#414A03',
            1000: '#2F3600',
        },
        mulberry: {
            100: '#EBD8E7',
            200: '#D8B1CF',
            300: '#C48AB8',
            400: '#B163A0',
            500: '#9D3C88',
            600: '#8D3079',
            700: '#7D246A',
            800: '#6E185B',
            900: '#5E0C4C',
            1000: '#4E003D',
        },
        orange: {
            100: '#F9E4D0',
            200: '#F3C9A2',
            300: '#EEAE73',
            400: '#E89345',
            500: '#E27816',
            600: '#C56812',
            700: '#AF5C0E',
            800: '#964D0B',
            900: '#7C3F07',
            1000: '#633103',
        },
        pine: {
            100: '#D3ECE0',
            200: '#A6D9C1',
            300: '#7AC7A2',
            400: '#4DB483',
            500: '#4E9F69',
            600: '#3E8C58',
            700: '#2F7948',
            800: '#1F6737',
            900: '#105427',
            1000: '#004116',
        },
        poppy: {
            100: '#FBDECE',
            200: '#F7BE9E',
            300: '#F29D6D',
            400: '#EE7D3D',
            500: '#EA5C0C',
            600: '#C84E0A',
            700: '#A64108',
            800: '#833307',
            900: '#612605',
            1000: '#3F1803',
        },
        purple: {
            100: '#CEB1EA',
            200: '#B894DC',
            300: '#A078C8',
            400: '#8E64B8',
            500: '#7A44AF',
            600: '#6D389F',
            700: '#5F2C8E',
            800: '#52217E',
            900: '#44156D',
            1000: '#37095D',
        },
        raspberry: {
            100: '#F1D6E0',
            200: '#E3ADC1',
            300: '#D685A2',
            400: '#C85C83',
            500: '#BA3364',
            600: '#A82B58',
            700: '#96234C',
            800: '#841B41',
            900: '#721335',
            1000: '#600B29',
        },
        ruby: {
            100: '#F4D3D1',
            200: '#E9A8A4',
            300: '#DF7C76',
            400: '#D45149',
            500: '#C9251B',
            600: '#B21F16',
            700: '#9A1911',
            800: '#83120B',
            900: '#6B0C06',
            1000: '#540601',
        },
        slateGrey: {
            100: '#DEE4EA',
            200: '#BDC8D6',
            300: '#9CADC1',
            400: '#7B91AD',
            500: '#5A7698',
            600: '#516A89',
            700: '#485F7A',
            800: '#3F536C',
            900: '#36485D',
            1000: '#2D3C4E',
        },
        lightGrey: {
            100: '#F9F9F9',
            200: '#E4E4E4',
            300: '#F1F1F1',
            400: '#E4E4E4',
            500: '#CECFD4',
            600: '#ACAEB7',
            700: '#878A99',
            800: '#656A7D',
            900: '#565D75',
            1000: '#3F465C',
        },
        teal: {
            100: '#D3EDE8',
            200: '#A6DBD1',
            300: '#64C0B0',
            400: '#4DB7A4',
            500: '#21A58D',
            600: '#1A927C',
            700: '#147F6B',
            800: '#0D6B5B',
            900: '#07584A',
            1000: '#004539',
        },
    },
    brandColors: {
        stacker: {
            aqua: '#00C7D9',
            black: '#121111',
            blue: '#3333FF',
            lightGrey: '#F8F8F6',
            mediumGrey: '#8F9FBE',
            darkGrey: '#6B7B99',
            navy: '#01163F',
            orange: '#FF7700',
            purple: '#7000Bf',
            red: '#FF4546',
            yellow: '#F3C32E',
        },
    },
    // @deprecated The colors declared from this point must not be used any more.
    white: '#FFFFFF',
    grey: {
        100: '#F4F5F8',
        200: '#DBDDDF',
        300: '#9FA4B5',
        400: '#737684',
        500: '#2C303B',
        600: '#ACAEB7',
        700: '#878E95',
        admin: '#555555',
    },
    brand: '#465DD8',
    error: '#FF0000',
    success: '#00BB00',
    alertBackground: '#fff8db',
    alertForeground: '#af9114',
    alertIcon: '#F5B371',
    adminBrand: '#465DD8',
    v4Blue: '#465DD8',
    // Chakra adds a 500 to the switch background colour, so this is a bit of a hack to get the right colour to show
    adminSwitch: {
        500: '#465DD8',
    },
    /* These are for the V4-era design system */
    neutral: {
        0: '#FFFFFF',
        100: '#F9F9FA',
        150: '#F5F6F6',
        200: '#EEF1F3',
        400: '#E5E7EA',
        500: '#D7DBE1',
        600: '#C6CCD4',
        700: '#B6BEC5',
        800: '#9099A0',
        850: '#606b74',
        900: '#454E55',
        950: '#333C43',
        980: '#252A32',
        1000: '#1F2123',
        1100: '#15181D',
    },
}

// NOTE currently unused but could be a nice feature to implement for *new* stacks
// (we should leave existing ones as-is, which is that brand color buttons always display white text)
//
// leaving here for easy inclusion later as implementation is not super trivial
// eslint-disable-next-line unused-imports/no-unused-vars
const shouldShowLightTextOnBrandColorButtons = (brand) => {
    // using plain .isDark() doesn't work well for button text contrast
    // it shows dark text on colors which are too dark
    //
    // to get the contrast better, we have to shift the line where we switch to using light text
    // by calling isDark on a darker version of the actual color
    //
    // This line is purely subjective, can be tweaked if we see issues in practice
    // make 'buttonTextContrast' bigger to shift the point where we show dark text towards lighter colors
    const buttonTextContrast = 0.23
    let showLightTextOnButtons = false

    try {
        showLightTextOnButtons = color(brand[300]).mix(color('black'), buttonTextContrast).isDark()
    } catch {
        // if brand color cannot be parsed, just assume show dark text
        // (as the color will probably resolve to nothing and end up being white/light colored)
    }

    return showLightTextOnButtons
}

const colors = (config): ColorConfigCustom => {
    const { brand, colors, brandAppColors } = config

    let isDark = true

    try {
        isDark = color(brand[300]).isDark()
    } catch {
        // if brand color cannot be parsed, just assume dark
    }

    // to implement this we can sub in `= shouldShowLightTextOnBrandColorButtons(brand)`
    const showLightTextOnBrandColorButtons = true

    return {
        brandAppColors,
        backgroundColor: '#f5f7fa',
        imageBg: colors.grey[200],
        table: {
            wrapperBackground: colors.white,
            borderColor: '#F2F3F7',
            label: colors.grey[400],
            fieldText: colors.grey[500],
        },
        fields: {
            label: '#737681',
        },
        container: {
            bgColor: colors.white,
            labelBorder: colors.grey[100],
            icon: colors.white,
            iconBg: brand[300],
        },
        onboarding: {
            title: colors.grey[500],
            subtitle: colors.grey[300],
            text: colors.grey[500],
            errorDetails: colors.grey[300],
        },
        text: {
            error: colors.error,
            success: colors.success,
            body: colors.grey[500],
            heading: colors.grey[500],
            hint: colors.grey[400],
            brand: isDark ? colors.white : colors.grey[500],
        },
        pagination: {
            text: colors.grey[400],
            textArrow: colors.grey[400],
            arrow: colors.grey[400],
        },
        button: {
            background: brand[300],
            backgroundHover: brand[400],
            text: showLightTextOnBrandColorButtons ? colors.white : colors.grey[500],
            clear: {
                text: colors.grey[400],
            },
        },
        input: {
            border: colors.grey[200],
            borderHover: colors.grey[300],
            borderActive: colors.grey[300],
            borderError: colors.error,
        },
        brand,
        emptyState: {
            icon: colors.grey[400],
            text: colors.grey[400],
            background: colors.grey[100],
        },
        modal: {
            body: '#555555',
            heading: '#555555',
            button: '#555555',
            buttonBorder: colors.grey[200],
        },
        endUser: {
            button: {
                primary: {
                    background: brand[300],
                    backgroundHover: brand[400],
                    color: colors.white,
                    borderColor: 'transparent',
                },
                secondary: {
                    background: colors.white,
                    backgroundHover: colors.white,
                    color: '#555555',
                    borderColor: colors.grey[200],
                },
            },
        },
        admin: {
            label: '#555555',
            upgradeButton: {
                background: '#02A77D',
                backgroundHover: '#009b75',
                outlineColor: '#009b75',
                color: colors.white,
            },
            button: {
                primary: {
                    background: colors.adminBrand,
                    backgroundHover: colors.adminBrand,
                    color: colors.white,
                },
                primaryV4: {
                    background: colors.v4Blue,
                    backgroundHover: darken(colors.v4Blue, 0.18),
                    color: colors.white,
                },
                secondary: {
                    background: colors.white,
                    backgroundHover: colors.white,
                    color: '#555555',
                    borderColor: colors.grey[200],
                },
                secondaryV4: {
                    background: colors.white,
                    backgroundHover: colors.userInterface.neutral[100],
                    color: '#444346',
                    borderColor: colors.grey[200],
                },
                smallBanner: {
                    background: '#008BF5',
                    backgroundHover: '#008BF5',
                    color: colors.white,
                },
            },
            input: {
                background: colors.grey[100],
                border: colors.grey[200],
                borderActive: colors.grey[300],
            },
            sideMenu: {
                button: colors.adminBrand,
                buttonHover: darken(colors.adminBrand, 0.18),
                buttonDisabled: '#B7B9BB',
                heading: colors.grey.admin,
                subHeading: colors.grey.admin,
                text: colors.grey.admin,
            },
            palette: {
                sectionLabel: colors.userInterface.neutral[1000],
                search: {
                    background: colors.grey[100],
                },
                widget: {
                    label: colors.grey.admin,
                    description: colors.grey.admin,
                    sectionLabel: colors.grey.admin,
                    icon: lighten(colors.black, 0.6),
                },
            },
            menuToggle: {
                text: colors.neutral[1000],
                icon: lighten(colors.black, 0.6),
                border: colors.grey[200],
                borderHover: colors.grey[300],
            },
        },
        authentication: {
            label: '#555555',
            button: {
                primary: {
                    background: colors.adminBrand,
                    backgroundHover: colors.adminBrand,
                    color: colors.white,
                },
                secondary: {
                    background: colors.grey[100],
                    backgroundHover: colors.grey[200],
                    color: '#555555',
                    borderWidth: '2px',
                    borderColor: colors.grey[200],
                },
            },
            input: {
                background: colors.white,
                borderActive: colors.grey[300],
            },
        },
        filters: {
            button: {
                background: lighten(colors.grey[200], 0.3),
                backgroundHover: lighten(colors.grey[200], 0.4),
                text: colors.grey[500],
                icon: lighten(colors.grey[400], 0.2),
            },
            filterTypeButton: {
                background: colors.grey[100],
                backgroundHover: darken(colors.grey[100], 0.05),
            },
        },
        internal: {
            nav: {
                label: colors.white,
                labelActive: '#434648',
            },
        },
        accordion: {
            header: {
                background: colors.grey[200],
                backgroundHover: darken(colors.grey[200], 0.025),
                color: colors.grey.admin,
                borderColor: colors.grey[200],
            },
        },
        rows: {
            backgroundHover: darken(colors.white, 0.012),
            fieldText: colors.grey[400],
        },
        appBar: {
            background: 'rgba(0, 0, 0, 1)',
            backgroundHover: 'rgba(255, 255, 255, 0.2)',
            backgroundActive: 'rgba(255, 255, 255, 0.3)',
            text: 'rgba(255, 255, 255, 0.7)',
            textActive: 'rgba(255, 255, 255, 1)',
        },
        form: {
            helperText: colors.grey[400],
        },
        breadcrumbs: {
            dark: {
                linkColor: colors.white,
                arrowColor: colors.white,
                linkBorderColor: 'rgba(255,255,255,0.7)',
            },
            light: {
                linkColor: 'rgba(0, 0, 0, 0.5)',
                arrowColor: 'rgba(0, 0, 0, 0.5)',
                linkBorderColor: 'rgba(0, 0, 0, 0.4)',
            },
            normal: {
                linkColor: colors.grey[300],
                arrowColor: colors.grey[200],
                linkBorderColor: colors.grey[200],
            },
        },
        kanban: {
            header: {
                bg: '#FFFFFF',
                countBg: '#E1E8F0',
                countText: '#A4A8B4',
            },
        },
        progress: {
            admin: colors.v4Blue,
            appBar: {
                track: '#49494b',
                bar: '#bfc0c5',
            },
            trackColor: '#D7DBE1',
        },
        progressBar: brand[300],
    }
}

export default colors
