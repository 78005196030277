// Using innerHTML or clone will not allow the scripts to execute
// this method will add the html to a temp element and then
// find all of the scripts and create/insert them one by one
// allowing them to execute too
export const insertCustomScripts = (scripts, attribute, extra_info) => {
    if (!scripts) return
    const tempElement = document.createElement('div')
    tempElement.innerHTML = scripts
    const scriptElements = tempElement.getElementsByTagName('script')
    Array.prototype.forEach.call(scriptElements, (element) => {
        const script = document.createElement('script')
        script.setAttribute(attribute, 'true')
        copyAttrs(script, element, extra_info)
        document.body.appendChild(script)
    })
}

// Remove all custom scripts that are not set to only load once so that they can be reloaded
export const removeCustomScripts = (attribute) => {
    const customScripts = document.body.querySelectorAll(`[${attribute}]`)
    Array.prototype.forEach.call(customScripts, (node) => node.parentNode.removeChild(node))
}

// Copy the script attributes and text
const copyAttrs = (target, source, extra_info) => {
    ;[...source.attributes].forEach((attr) => {
        target.setAttribute(attr.nodeName, attr.nodeValue)
    })
    if (source.src) target.src = source.src
    if (source.text) {
        const text = `try{var extra_info = ${JSON.stringify(extra_info)}; ${
            source.text
        }}catch(e){ console.log(e) }`
        target.appendChild(document.createTextNode(text))
    }
}
