import React, { useCallback } from 'react'

import { Modal as ModalComponent } from 'v2/ui'
import { Modal, useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

import { DuplicateViewModalContent } from './DuplicateViewModalContent'

const MODAL_KEY = 'DuplicateView'

export type DuplicateViewModalData = {
    view: ViewDto
    onConfirm: (modal: Modal<DuplicateViewModalData>) => Promise<void>
}

export const useDuplicateViewModal = () => {
    const { show } = useModalDeclaration<DuplicateViewModalData>(MODAL_KEY, DuplicateViewModal)

    return useCallback(
        (params: { view: ViewDto }) => {
            show({ view: params.view })
        },
        [show]
    )
}

const DuplicateViewModal: React.FC = () => {
    const modal = useModalToggle<DuplicateViewModalData>(MODAL_KEY)
    const { isOpen, toggle, data } = modal

    const onConfirm = async () => {
        toggle()
    }

    const onCancel = () => {
        toggle()
    }

    return (
        <ModalComponent
            isOpen={isOpen}
            onClose={onCancel}
            body={
                <DuplicateViewModalContent
                    view={data?.view}
                    onConfirm={onConfirm}
                    isOpen={isOpen}
                />
            }
        />
    )
}
