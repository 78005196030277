/* Code Quality: Good */

import React, { Component } from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import STYLE_CLASSES from 'v2/ui/styleClasses'

class UserMenuContainer extends Component {
    state = { open: false }

    toggle = () => {
        this.setState((state) => ({ open: !state.open }))
    }

    render() {
        const Wrapper = this.props.renderWrapper || UserMenuWrapper
        return (
            <>
                <Wrapper
                    className={STYLE_CLASSES.USER_MENU}
                    onClick={this.toggle}
                    style={this.props.style}
                >
                    {this.props.children}
                    {this.state.open && <ToggleMenu>{this.props.menu}</ToggleMenu>}
                </Wrapper>
                {this.state.open && <Cover onClick={this.toggle} />}
            </>
        )
    }
}

UserMenuContainer.propTypes = {
    children: PropTypes.node.isRequired,
    menu: PropTypes.node.isRequired,
}

export default UserMenuContainer

const ToggleMenu = styled('div')`
    position: absolute;
    right: 10px;
    z-index: 20;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: white;
    width: max-content;
    min-width: 150px;
    max-width: 600rem;
    padding: 12px;
`
const Cover = styled('div')`
    display: inline-flex;
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 1;
`

const UserMenuWrapper = styled('div')`
    padding-left: 23px;

    @media (max-width: 768px) {
        margin-right: -20px;
        margin-left: auto;

        padding-left: 8px;
        padding-top: 8px;
    }
`
