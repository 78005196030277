import { useEffect } from 'react'

import Cohere from 'cohere-js'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'
import { withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'

const environmentPrefix = () => {
    return settings.IS_PROD ? '' : `${settings.DOMAIN}#`
}

// We are setting up Cohere globally for the current browser session, so if the component re-mounts we want to remember that we have started recording
let setupFinished = false

const CohereIdentifier_ = ({ studioUser }) => {
    const { workspaceAccount, selectedStack, initialLoadComplete } = useAppContext()
    const { hasRight: hasWorkspaceRight } = useAccountUserContext()
    const { isAdmin: isAppAdmin } = useAppUserContext()

    useEffect(() => {
        if (setupFinished) return // we've already started recording
        if (settings.IS_LOCAL_INSTANCE) return // don't record on localhost
        if (!initialLoadComplete) return // wait for account and stacks to be loaded

        const isNewStackerDataSignup =
            studioUser?.options.signup_params?.for == 'stacker_data' && !workspaceAccount
        const hasStackerData = workspaceAccount?.optional_features?.stacker_data_onboarding
        const emptyWorkspaceAdmin = !selectedStack && hasWorkspaceRight(Rights.CreateApps)

        // We want to record Cohere sessions for stacker data user flows but only for app admins
        if (isNewStackerDataSignup || (hasStackerData && (isAppAdmin || emptyWorkspaceAdmin))) {
            Cohere.init(settings.COHERE_KEY)

            Cohere.identify(
                `${environmentPrefix()}${studioUser._sid}`, // Required: can be any unique ID
                {
                    displayName: studioUser.name,
                    email: studioUser.email,
                }
            )

            setupFinished = true
        }
    }, [
        hasWorkspaceRight,
        initialLoadComplete,
        isAppAdmin,
        selectedStack,
        studioUser,
        workspaceAccount,
    ])

    return null
}
export const CohereIdentifier = withUser(withStacks(CohereIdentifier_))
