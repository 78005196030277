import React from 'react'

import { useAppContext } from 'app/AppContext'
import AppBar from 'features/AppBar/AppBar'

import { Flex } from 'v2/ui'

import useNavigationColor from '../../../v2/ui/hooks/useNavigationColor'

const PopoutNavBar = () => {
    const { selectedStack: stack } = useAppContext()

    const theme = stack?.options?.theme ?? {}

    const navTheme = useNavigationColor(theme)

    return (
        <Flex
            wrap="nowrap"
            align="stretch"
            height="100%"
            maxHeight="100%"
            maxWidth="100%"
            position="fixed"
            zIndex="502"
        >
            <AppBar navTheme={navTheme} userMenuOnly={true} />
        </Flex>
    )
}

export default PopoutNavBar
