import React from 'react'

import { CustomLoadingState } from 'v2/views/List/types'

import { LoadingState } from 'features/views/List/ui/utils'

import { Box } from 'v2/ui'

import { DataListBlockHeader } from './DataListBlockHeader'
import { DataListBlockWrapper } from './DataListBlockWrapper'

type DataListBlockLoadingStateProps = {}

export const DataListBlockLoadingState: CustomLoadingState<DataListBlockLoadingStateProps> = ({
    feature,
    failed,
    loadingTimedOut,
    title,
}) => {
    return (
        <DataListBlockWrapper>
            <DataListBlockHeader
                title={title}
                showDivider={true}
                hideSearch={true}
                hideCreateButton={true}
            />
            <Box px={3} py={32}>
                <LoadingState
                    loadingError={failed}
                    feature={feature}
                    loadingTimedOut={loadingTimedOut}
                    errorCode={1}
                />
            </Box>
        </DataListBlockWrapper>
    )
}
