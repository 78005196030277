import React from 'react'

import { Alert, Button, Flex, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { AirtableUpgradePermissionsImage } from './utils/AirtableUpgradePermissionsImage'
import { AirtableOAuthLearnMoreLink } from './AirtableOAuthLearnMoreLink'
import { AIRTABLE_OAUTH_INSTRUCTIONS_MODAL_TEST_ID } from './airtableTestIds'
import { AirtableUpgradeInstructionsImage } from './AirtableUpgradeInstructionsImage'
import { StyledFlex } from './StyledComponents'
export type InstructionsModalProps = {
    isOpen: boolean
    bounceUrl?: string
    onClose: () => void
}

export const InstructionsModal: React.FC<InstructionsModalProps> = ({
    isOpen,
    bounceUrl,
    onClose,
}) => {
    return (
        <SimpleModalCompat
            size="502px"
            height="613px"
            noDividers
            padding="20px 40px 20px 40px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            showCloseButton={false}
            data-testid={AIRTABLE_OAUTH_INSTRUCTIONS_MODAL_TEST_ID}
        >
            <AirtableUpgradePermissionsImage />
            <StyledFlex>
                <Text variant="heading" fontWeight="bold">
                    Permission needed
                </Text>
                <Text fontSize="14px" textAlign="center">
                    Stacker needs your permission to access your Airtable bases. The next step will
                    redirect you to Airtable to grant this permission.
                </Text>
                <AirtableUpgradeInstructionsImage />
                <Alert
                    border="1px solid"
                    borderColor="userInterface.neutral.800"
                    description={
                        <>
                            <Text as="span">In the next screen, we suggest you select</Text>
                            <Text fontWeight={600} fontStyle="italic" as="span">
                                <strong> ‘All current and future bases‘</strong>
                            </Text>
                        </>
                    }
                    status="info"
                    iconStyle={{
                        size: 4,
                        color: V4DesignSystem.colors.gray[300],
                    }}
                    background="#F8F9FA"
                    m={0}
                    mb={2}
                    w="100%"
                    fontSize={12}
                    boxShadow="none"
                ></Alert>
                <Flex justify="center">
                    <Flex mt={2} w="100%" flexWrap="nowrap">
                        <Button
                            flexGrow={1}
                            variant="Secondary"
                            width={205}
                            mr={5}
                            buttonSize="medium"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            flexGrow={1}
                            variant="Primary"
                            width={205}
                            buttonSize="medium"
                            onClick={() => {
                                if (bounceUrl) {
                                    window.location.assign(bounceUrl)
                                }
                            }}
                        >
                            Continue
                        </Button>
                    </Flex>
                    <AirtableOAuthLearnMoreLink />
                </Flex>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
