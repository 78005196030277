// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import useWindowSize from 'v2/views/utils/useWindowSize'

import { useAppContext } from 'app/AppContext'
import { useLDFlags } from 'data/hooks/useLDFlags'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

//@ts-ignore
import Flex from 'ui/deprecated/atoms/Flex'
//@ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { withDataConnections } from '../../data/wrappers/WithDataConnections'
import { withFeatures } from '../../data/wrappers/WithFeatures'
import { withStack } from '../../data/wrappers/WithStacks'
import { SidebarItem } from '../../v2/ui'

import { LinkMenuItem } from './links/LinkMenuItem'

/**
 * list of sidebar items
 * @type {{page: WorkspaceSettingsPage, name: string, icon: string, Component?: React.ReactElement, disabled?: (stack: any, workspaceAccount: any) => boolean }[]}
 */
export const appSettingsMenuItems = [
    {
        name: 'Data Sources',
        page: 'data_sources',
        icon: 'data',
    },
    {
        name: 'Permissions',
        page: 'permissions',
        icon: 'lock',
        class: ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_MENU_ITEM,
    },
    {
        name: 'General',
        page: 'general',
        icon: 'cog',
    },
    {
        name: 'Customer Access',
        page: 'customer_access',
        icon: 'world',
        disabled: (stack, workspaceAccount) => {
            return workspaceAccount.optional_features?.sync_stacker_users
        },
    },
    {
        name: 'Links',
        page: 'links',
        icon: 'lookup',
        Component: <LinkMenuItem />,
        disabled: (stack) => {
            return !stack?.options?.enable_linked_tables_app_settings
        },
    },
    {
        name: 'User Profiles',
        page: 'user_profiles',
        icon: 'userAddress',
        disabled: (stack, workspaceAccount) => {
            return workspaceAccount.optional_features?.sync_stacker_users
        },
    },
    {
        name: 'Roles',
        page: 'roles',
        icon: 'userLock',
    },
    {
        name: 'Navigation',
        page: 'navigation',
        icon: 'list',
    },
    {
        name: 'Appearance',
        page: 'appearance',
        icon: 'brush',
    },
]

const InstallableFeaturesMenuItem = {
    name: 'Features',
    page: 'installable_features',
    icon: 'cog',
}

const BORDER_COLOR = V4DesignSystem.colors.gray[100]

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const slideInLeftAnimation = keyframes`
  0% { transform: translateX(-250px) }
  100% { transform: translateX(0px) }
`

const Container = styled(Flex)`
    position: relative;

    & > div {
        position: relative;
    }

    .fadeIn {
        animation: ${fadeInAnimation} 0.2s linear;
    }

    ${
        '' /* .slideInLeft {
        animation: ${slideInLeftAnimation} 0.2s ease-out;
    } */
    }
`

type SecondaryMenuItems = {
    name: string
    section: string
    icon: string
    shouldShow?: (
        connectionType: string | undefined,
        object: ObjectDto,
        stack?: StackDto | null,
        account?: Account | null,
        ldflags?: LDFlagSet
    ) => boolean
}
/**
 * list of sidebar items
 */
const dataSourcesSecondaryMenuItems: SecondaryMenuItems[] = [
    {
        name: 'Data',
        section: 'data',
        icon: 'table',
        shouldShow: (connectionType, object, stack, account, ldflags) => ldflags?.dataGridEnabled,
    },
    {
        name: 'Fields',
        section: 'fields',
        icon: 'table',
    },
    {
        name: 'Settings',
        section: 'settings',
        icon: 'cog',
        shouldShow: (connectionType) => connectionType !== 'stacker_users',
    },
    {
        name: 'Permissions',
        section: 'permissions',
        icon: 'lock',
    },
    {
        name: 'Layouts',
        section: 'layouts',
        icon: 'columns',
    },
    {
        name: 'Actions',
        section: 'actions',
        icon: 'bolt',
    },
]

export const getAvailableSecondarySources = (
    connectionType: string | undefined,
    object: ObjectDto,
    stack?: StackDto | null,
    workspaceAccount?: Account | null,
    ldflags?: LDFlagSet
): SecondaryMenuItems[] => {
    return dataSourcesSecondaryMenuItems.filter((item) =>
        item.shouldShow
            ? item.shouldShow(connectionType, object, stack, workspaceAccount, ldflags)
            : true
    )
}

function DataSourcesSecondaryMenu({ dataSourcesMenuState, dataSourcesMenuStateFns }) {
    return (
        // @ts-expect-error
        <Flex
            column
            className="slideInLeft"
            style={{
                width: 160,
                borderRight: `1px solid ${BORDER_COLOR}`,
                overflowY: 'auto',
                padding: '20px 10px',
                flexShrink: 0,
                zIndex: 1,
            }}
        >
            {dataSourcesMenuState.secondaryMenuSectionState.available.map((menuItem) => (
                <>
                    <SidebarItem
                        key={menuItem.section}
                        icon={menuItem.icon}
                        active={
                            dataSourcesMenuState.secondaryMenuSectionState.selected ===
                            menuItem.section
                        }
                        onChange={() => {
                            dataSourcesMenuStateFns.setSelectedSecondaryMenuSection(
                                dataSourcesMenuState.selectedObject,
                                menuItem.section
                            )
                        }}
                        classes={
                            menuItem.section === 'permissions'
                                ? ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.PERMISSIONS_ITEM
                                : ''
                        }
                    >
                        {menuItem.name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </>
            ))}
        </Flex>
    )
}

const cleanDataSourcesMenuState = (
    state: { object?: any; dataConnection?: any; section?: any } = {},
    stack?: StackDto | null,
    workspaceAccount?: Account | null,
    ldflags?: LDFlagSet
) => ({
    selectedDataConnection: state.dataConnection,
    selectedObject: state.object,
    secondaryMenuSectionState: {
        selected: state.section,
        available: getAvailableSecondarySources(
            state.dataConnection?.type,
            state.object,
            stack,
            workspaceAccount,
            ldflags
        ),
    },
})

/**
 *
 * @param {{onChangePage: (page: AppSettingsPage) => Promise<any>, [keyof: string]: any}} param0
 * @returns
 */
function _Sidebar({
    onChangePage,
    pageName,
    dataConnections,
    features,
    stack,
    pageOptions = undefined,
}) {
    const { workspaceAccount, selectedStack } = useAppContext()
    const { flags: ldflags } = useLDFlags()
    const { width: windowWidth } = useWindowSize()

    const [dataSourcesMenuState, _setDataSourcesMenuState] = useState(
        cleanDataSourcesMenuState(pageOptions, selectedStack, workspaceAccount, ldflags)
    )
    const isInstallableFeaturesLocked = isFeatureLocked(
        FEATURES.installable_features,
        selectedStack
    )

    // when page options change, we need to update our menu state
    // so the correct nav items are selected
    useEffect(() => {
        _setDataSourcesMenuState(
            cleanDataSourcesMenuState(pageOptions, selectedStack, workspaceAccount, ldflags)
        )
    }, [pageOptions, workspaceAccount, selectedStack, ldflags])

    const dataSourcesMenuStateFns = {
        resetState() {
            _setDataSourcesMenuState(
                cleanDataSourcesMenuState(undefined, selectedStack, workspaceAccount, ldflags)
            )
        },
        setSelectedDataConnection(selectedDataConnection) {
            _setDataSourcesMenuState((state) => ({ ...state, selectedDataConnection }))
        },
        setSelectedObject(selectedObject, defaultSecondaryNav) {
            // get the corresponding feature for the object
            const feature = features.find((_feature) => {
                return _feature.options.object_id === selectedObject._sid
            })
            const dataConnection = dataConnections.find(
                (dc) => dc._sid === selectedObject.data_connection
            )

            const availableSecondarySources: SecondaryMenuItems[] = getAvailableSecondarySources(
                dataConnection?.type,
                selectedObject,
                selectedStack,
                workspaceAccount,
                ldflags
            )

            // Fallback to the first availabe section if the selected one doesn't exist
            let section: string =
                dataSourcesMenuState.secondaryMenuSectionState.selected ?? defaultSecondaryNav
            if (!availableSecondarySources.some((src) => src.section === section)) {
                section = availableSecondarySources[0].section
            }

            onChangePage({
                name: 'object',
                meta: {
                    dataConnection,
                    object: selectedObject,
                    feature,
                    section,
                },
            })
        },
        setSelectedSecondaryMenuSection(object, selectedSecondaryMenuSection) {
            // get the corresponding feature for the object
            const feature = features.find((_feature) => {
                return _feature.options.object_id === object._sid
            })

            const dataConnection = dataConnections.find((dc) => dc._sid === object.data_connection)
            onChangePage({
                name: 'object',
                meta: {
                    dataConnection,
                    object,
                    feature,
                    section: selectedSecondaryMenuSection,
                },
            }).then(() => {
                _setDataSourcesMenuState((state) => ({ ...state, selectedSecondaryMenuSection }))
            })
        },
    }

    return (
        // @ts-expect-error
        <Container>
            {/* @ts-expect-error */}
            <Flex
                column
                className="first-col"
                style={{
                    width: (windowWidth ?? 0) < 1366 ? 180 : 220,
                    borderRight: `1px solid ${BORDER_COLOR}`,
                    flexShrink: 0,
                    zIndex: 2,
                    background: 'white',
                    borderBottomLeftRadius: '0.375rem',
                    borderTopLeftRadius: '5px',
                }}
            >
                {!isInstallableFeaturesLocked ? (
                    <div
                        style={{
                            minHeight: 0,
                            padding: 16,
                            paddingBottom: 20,
                            overflowY: 'auto',
                        }}
                    >
                        <div style={{ ...V4DesignSystem.label, marginBottom: 8 }}>Features</div>
                        <>
                            <SidebarItem
                                key={InstallableFeaturesMenuItem.page}
                                icon={InstallableFeaturesMenuItem.icon}
                                active={
                                    !dataSourcesMenuState.selectedObject &&
                                    pageName === InstallableFeaturesMenuItem.page
                                }
                                onChange={() => {
                                    // reset state in data sources menu when selecting a new top level page
                                    dataSourcesMenuStateFns.resetState()

                                    onChangePage({
                                        name: InstallableFeaturesMenuItem.page,
                                    })
                                }}
                            >
                                Features Library
                            </SidebarItem>
                            <div style={{ height: 1 }} />
                        </>
                    </div>
                ) : null}

                <div
                    style={{
                        padding: 16,
                        paddingTop: 12,
                        borderTop: 0,
                    }}
                >
                    {appSettingsMenuItems
                        .filter((menuItem) => {
                            if (typeof menuItem.disabled === 'function') {
                                return !menuItem.disabled(stack, workspaceAccount)
                            }
                            return true
                        })
                        .map((menuItem, index) => (
                            <React.Fragment key={index}>
                                <SidebarItem
                                    key={menuItem.page}
                                    icon={menuItem.icon}
                                    active={
                                        !dataSourcesMenuState.selectedObject &&
                                        pageName === menuItem.page
                                    }
                                    onChange={() => {
                                        // reset state in data sources menu when selecting a new top level page
                                        dataSourcesMenuStateFns.resetState()

                                        onChangePage({
                                            name: menuItem.page,
                                        })
                                    }}
                                    classes={menuItem.class || ''}
                                >
                                    {menuItem.Component ?? menuItem.name}
                                </SidebarItem>
                                <div style={{ height: 1 }} />
                            </React.Fragment>
                        ))}
                </div>
            </Flex>

            {!!dataSourcesMenuState.selectedObject && !ldflags?.dataGridSidePane && (
                <DataSourcesSecondaryMenu
                    dataSourcesMenuState={dataSourcesMenuState}
                    dataSourcesMenuStateFns={dataSourcesMenuStateFns}
                />
            )}
        </Container>
    )
}

export const Sidebar = withStack(withDataConnections(withFeatures(_Sidebar)))
