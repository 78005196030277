import React from 'react'

import { useUpgradeFlow } from './useUpgradeFlow'

// This continues the upgrade flow after the user has approved permissions for Airtable
// It will show the correct modal depending on the state of the upgrade
export const UpgradeAirtableV2Observer: React.VFC = () => {
    const { modal } = useUpgradeFlow({ continueFlow: true })
    return modal
}
