import React, { VFC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type Props = { color?: string; size?: number }

const SolidView: VFC<Props> = ({ color = colors.userInterface.accent[1000], size = 16 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#4li9htiaoa)" fill={color}>
            <path d="M8.043 10.25a2.406 2.406 0 1 0 0-4.813 2.406 2.406 0 0 0 0 4.813z" />
            <path d="M15.968 7.685A8.902 8.902 0 0 0 8 2 8.902 8.902 0 0 0 .032 7.685a.533.533 0 0 0 0 .363A8.901 8.901 0 0 0 8 13.734a8.902 8.902 0 0 0 7.968-5.686.533.533 0 0 0 0-.363zM8 11.334A3.466 3.466 0 1 1 8 4.4a3.466 3.466 0 0 1 0 6.933z" />
        </g>
        <defs>
            <clipPath id="4li9htiaoa">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default SolidView
