import React, { useMemo, useState } from 'react'
export const DataGridContext = React.createContext<dataGridContext>({} as dataGridContext)

type dataGridContext = {
    readOnly: boolean
    setReadOnly: (readOnly: boolean) => void
}

export const DataGridContextProvider: React.FC = ({ children }) => {
    const [readOnly, setReadOnly] = useState(false)

    const state = useMemo<dataGridContext>(
        () => ({
            readOnly,
            setReadOnly,
        }),
        [readOnly, setReadOnly]
    )

    return <DataGridContext.Provider value={state}>{children}</DataGridContext.Provider>
}
