import React from 'react'
import { useFormContext } from 'react-hook-form'

import moment from 'moment'

import AppUserContext from 'app/AppUserContext'
import { useUpdateUser } from 'data/hooks/users/main'
import { isV4SupportLoginPermitted } from 'utils/supportLogin'

import { Flex } from 'v2/ui'

import CheckboxForm from 'ui/forms/CheckboxForm'
import DropdownForm from 'ui/forms/DropdownForm'
import Form from 'ui/forms/Form'

import { Divider, FormFrame } from '../workspace/WorkspaceSettingsModalUi'

type FormData = {
    support_login: boolean
    support_login_time_interval: string | null
}

type ProfileSupportLoginAccessProps = {
    formRef: any
    children?: React.ReactNode
}

const SupportLoginFlags = () => {
    const { watch, formState, reset } = useFormContext()
    const supportLogin = watch('support_login')
    if (
        !supportLogin &&
        formState.dirtyFields.support_login_time_interval &&
        !formState.dirtyFields.support_login
    ) {
        reset({ support_login_time_interval: null })
    }

    // Support login time intervals with values expressed in minutes
    const supportLoginTimeIntervals = [
        { value: '4320', label: '3 days' },
        { value: '10080', label: '1 week' },
        { value: '43800', label: '1 month' },
        { value: '-1', label: 'Until I turn it off' },
    ]

    return (
        <Flex>
            <CheckboxForm
                name="support_login"
                label="Enable Stacker to inspect your apps to help troubleshoot if needed"
            />

            <DropdownForm
                isSearchable={false}
                isClearable={false}
                name="support_login_time_interval"
                label="How long would you like to enable access for?"
                style={{ width: 190 }}
                disabled={!supportLogin}
                options={supportLoginTimeIntervals}
                defaultValue={supportLogin ? '-1' : null}
            />
        </Flex>
    )
}

const ProfileSupportLoginAccess = ({ formRef, children }: ProfileSupportLoginAccessProps) => {
    const { studioUser: user } = React.useContext(AppUserContext)
    const { mutateAsync: updateUser } = useUpdateUser()

    const onSubmit = ({ support_login, support_login_time_interval }: FormData) => {
        if (!user) return
        const supportPermitted = support_login && support_login_time_interval

        updateUser({
            id: user._sid,
            patch: {
                // Remove once useUpdateUser has been converted to TS
                // @ts-expect-error
                options: {
                    ...user.options,
                    permit_support_login: support_login,
                    support_login_start_date: support_login ? moment() : null,
                    support_login_expiration_date:
                        supportPermitted && support_login_time_interval !== '-1'
                            ? moment().add(support_login_time_interval, 'minutes')
                            : null,
                },
            },
        })
    }

    const getSupportLoginTimeInterval = () =>
        user?.options.support_login_expiration_date
            ? moment(user?.options.support_login_expiration_date)
                  .diff(user?.options.support_login_start_date, 'minutes')
                  .toString()
            : '-1'

    const defaultValues: FormData = {
        support_login: isV4SupportLoginPermitted(user),
        support_login_time_interval: isV4SupportLoginPermitted(user)
            ? getSupportLoginTimeInterval()
            : null,
    }

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues,
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame
                title="Grant Support Login"
                formRef={formRef}
                padding={null}
                paddingFooter={null}
            >
                <Divider />
                <SupportLoginFlags />
            </FormFrame>
            {children}
        </Form>
    )
}

export default React.memo(ProfileSupportLoginAccess)
