/* Code Quality: OK */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import queryString from 'qs'

import { getWorkspaceAccount } from 'app/AppContextStore'
import { AppUserContext, Rights } from 'app/AppUserContext'
import settings from 'app/settings'
import { buildUrl } from 'data/utils/utils'
import { withUser } from 'data/wrappers/WithUser'
import ImpersonatingWarningPage from 'features/auth/ImpersonatingWarningPage'
import NoAdminRightsWarningPage from 'features/auth/NoAdminRightsWarningPage'
import PrivateRouteAuthLoadingPage from 'features/auth/PrivateRouteAuthLoadingPage'
import { useSessionContext } from 'features/supertokens/supertokens'
import { getRedirectParam } from 'utils/utils'

import AppContext from './AppContext'
import { getUrl, trimRootPathFromUrl, Urls } from './UrlService'

export const isProd =
    settings.NODE_ENV !== 'development' &&
    window.location.host !== 'stacker.local' &&
    window.location.host !== 'stacker.net'

const FailedRedirect = ({ to }) => {
    const workspaceAccount = getWorkspaceAccount()
    const redirectTo = getRedirectParam()

    // if we are running under a workspace account, redirect to the workspace login page
    if (workspaceAccount) {
        return <Redirect to={buildUrl(getUrl(Urls.Login), { r: redirectTo })} />
    } else {
        return <Redirect to={to} />
    }
}

const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <PrivateComponent
                        component={Component}
                        redirectTo={redirectTo}
                        loginRedirect={() => {
                            return (
                                <FailedRedirect
                                    to={{
                                        pathname: '/login',
                                    }}
                                />
                            )
                        }}
                        permitAuthToken
                        {...props}
                    />
                )
            }}
        />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    redirectTo: PropTypes.string,
}

PrivateRoute.defaultProps = {
    component: undefined,
    redirectTo: null,
}

export default PrivateRoute

export const StackerDomainPrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <PrivateComponent
                        component={Component}
                        loginRedirect={() => {
                            return (
                                <FailedRedirect
                                    to={{
                                        pathname: '/login',
                                        state: { from: props.location },
                                    }}
                                />
                            )
                        }}
                        permitAuthToken={false}
                        {...props}
                    />
                )
            }}
        />
    )
}

StackerDomainPrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    location: PropTypes.string.isRequired,
}

// A functional component wrapper to be able to use context hooks
const RenderPrivatePageIfUserHasAdminRights = ({ Component, ...rest }) => {
    const { selectedStack } = useContext(AppContext)
    const { studioUser, role, hasRight } = useContext(AppUserContext)

    // if there is no currently selected app,
    // no need to check admin rights -- just show the page
    if (!selectedStack) {
        return <Component {...rest} />
    }

    // if there is no user or role in state,
    // wait until they load before doing permissions check, just displaying a loading page in the meantime
    //
    // if we don't wait, hasRight will return false if either are undefined
    // and we'll get a flash of the <NoAdminRightsWarningPage> before they load in
    if (!studioUser || !role) {
        return <PrivateRouteAuthLoadingPage />
    }

    // otherwise, check the user has admin rights for the current app
    // if not, show a warning
    if (!hasRight(Rights.Admin.All)) {
        return <NoAdminRightsWarningPage />
    }

    // if they do, show the page
    return <Component {...rest} />
}

function _PrivateComponent(props) {
    const { isAuthenticated: isAuth0Authenticated, isLoading: isAuth0Loading } = useAuth0()
    const supertokensContext = useSessionContext()

    const [state, setState] = useState({
        userChecked: false,
        userAuthenticated: false,
    })

    const checkAuthentication = useCallback(
        async (searchString, permitAuthToken = true) => {
            // By default, we are authenticated if there is a token in localStorage
            let isAuthenticated = localStorage.getItem('studio_token')
            // Check if we passed the API token in the query string (overrides the localStorage token)
            if (searchString && searchString.api_token) {
                isAuthenticated = false
                const token = searchString.api_token
                // Fetch the user details using this token (and therefore check it is valid).

                try {
                    const user = await props.userActions.logInStudioUserByApiToken(token)
                    isAuthenticated = !!user.api_token

                    if (!isAuthenticated) {
                        const endUser = await props.userActions.logInEndUserByApiToken(token)
                        isAuthenticated = !!endUser.api_token
                    }
                } catch {
                    isAuthenticated = false
                }
            }
            // Otherwise, if we passed an auth_token to be redeemed, then do that (overrides the localStorage token)
            if (
                permitAuthToken &&
                searchString &&
                searchString.auth_token &&
                !searchString.api_token
            ) {
                isAuthenticated = false
                const token = searchString.auth_token
                try {
                    const user = await props.userActions.logInStudioUserByTemporaryAuthToken(token)
                    isAuthenticated = !!user.api_token
                    if (!isAuthenticated) {
                        const endUser = await props.userActions.logInEndUserByApiToken(token)
                        isAuthenticated = isAuthenticated || !!endUser.api_token
                    }
                } catch {
                    isAuthenticated = false
                }
            }
            return isAuthenticated
        },
        [props.userActions]
    )

    useEffect(() => {
        if (isAuth0Loading || state.userChecked) return

        if (supertokensContext.userId) {
            setState({
                userChecked: true,
                userAuthenticated: true,
            })
            return
        }

        if (isAuth0Authenticated) {
            // If we have an Auth0 session, then succeed here
            setState({
                userChecked: true,
                userAuthenticated: true,
            })
            return
        }

        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        checkAuthentication(searchString, props.permitAuthToken)
            .then((isAuthenticated) => {
                setState({
                    userChecked: true,
                    userAuthenticated: isAuthenticated,
                })

                const searchString = queryString.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                })

                // Clear the QS of auth_token and api_token
                const filteredQuery = { ...searchString }
                if (filteredQuery.auth_token || filteredQuery.api_token) {
                    delete filteredQuery.auth_token
                    delete filteredQuery.api_token
                    const qs = queryString.stringify(filteredQuery, {
                        addQueryPrefix: true,
                    })
                    window.history.replaceState(
                        null,
                        '',
                        `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${qs}`
                    )
                }
            })
            .catch(() => {
                setState({
                    userChecked: true,
                    userAuthenticated: false,
                })
            })
    }, [
        isAuth0Loading,
        props.permitAuthToken,
        isAuth0Authenticated,
        state.userChecked,
        checkAuthentication,
        supertokensContext.userId,
    ])

    const searchString = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    if (isAuth0Loading) {
        return <div />
    }

    const workspaceAccount = getWorkspaceAccount()

    const ADMIN_WORKSPACE_WHITELIST = [
        Urls.AdminSupportLogin,
        Urls.AdminLogin,
        Urls.AdminAuth,
        Urls.AdminModal,
        Urls.AdminConnectionDebugger,
    ]

    const route = trimRootPathFromUrl(window.location.pathname)

    if (
        route.startsWith(Urls.AdminHome) &&
        workspaceAccount &&
        !localStorage.getItem('support_login') &&
        !ADMIN_WORKSPACE_WHITELIST.includes(route) &&
        settings.IS_PROD
    ) {
        window.location.assign(getUrl(Urls.Home))
        return null
    }

    if (state.userChecked && state.userAuthenticated) {
        if (searchString.redirect) {
            window.location.pathname = '/'
            return <div />
        } else if (props.redirectTo || searchString.r) {
            return <Redirect to={props.redirectTo || searchString.r} {...props} />
        } else if (props.isImpersonating) {
            // If we're impersonating, then show the impersonation page
            return <ImpersonatingWarningPage />
        }

        return <RenderPrivatePageIfUserHasAdminRights Component={props.component} {...props} />
    } else if (state.userChecked) {
        return props.loginRedirect()
    } else {
        return <div />
    }
}

_PrivateComponent.propTypes = {
    component: PropTypes.elementType.isRequired,
    loginRedirect: PropTypes.func.isRequired,
    permitAuthToken: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string,
    userActions: PropTypes.object.isRequired, // From withUser
    isImpersonating: PropTypes.bool.isRequired, // From withUser
}

_PrivateComponent.defaultProps = {
    redirectTo: null,
}

const PrivateComponent = withUser(_PrivateComponent)
