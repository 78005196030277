/* eslint-disable react/prop-types */
/* Code Quality: Good */

import React from 'react'

import { injectGlobal } from 'emotion'
import { ThemeProvider } from 'emotion-theming'

import WithStacks from 'data/wrappers/WithStacks'
import { evaluateThemeFormulas } from 'features/studio/theme/utils'
import { theme as themeProvider } from 'features/utils/ContextProviders'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

const ThemeWrapper = (props) => {
    // When not authenticated as a studio user, the theme
    // is accessed via App (the backend copies it there from Stack)
    // This is so that a non-studio user doesn't need to access Stack

    return (
        <WithStacks>
            {({ selectedStack }) => {
                const stackTheme =
                    (selectedStack && selectedStack.options && selectedStack.options.theme) || {}

                const siteTheme = evaluateThemeFormulas({
                    ...stackerTheme,
                    ...stackTheme,
                })

                return (
                    <ThemeProvider theme={siteTheme}>
                        <themeProvider.Provider value={siteTheme}>
                            {props.children}
                            {injectGlobal`
                page > a {
                color: ${siteTheme.linkTextColor};
                }
                `}
                        </themeProvider.Provider>
                    </ThemeProvider>
                )
            }}
        </WithStacks>
    )
}

export default React.memo(ThemeWrapper)
