const filterBlockTree = (tree = [], allowed) => {
    let newTree = []

    newTree = tree.filter((block) => allowed.includes(block.type))

    //recursively filter for childblocks
    newTree.forEach((block) => (block.childBlocks = filterBlockTree(block.childBlocks, allowed)))

    return newTree
}

export default filterBlockTree
