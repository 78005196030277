import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { Checkbox } from 'ui/components/Checkbox'

import { ErrorMessage } from './ui'

/**
 *
 * @param {{name: string, label?: string, defaultValue?: any, errorMessages?: {[keyof: string]: string}} & Partial<import('react-hook-form').RegisterOptions>} param0
 */
export default function CheckboxForm({
    name,
    label = name,
    defaultValue = null,
    required = false,
    pattern = undefined,
    errorMessages = {},
    ...props
}) {
    const {
        setValue,
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <div>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ pattern, required }}
                render={({ field }) => (
                    <Checkbox
                        id={'checkbox-' + name} // fragile
                        {...props}
                        {...field}
                        onCheckedChange={(checked) =>
                            // checkbox doesn't expose an onChange event which
                            // fires when the check state changes, so we have to
                            // use onCheckedChange instead and call setValue directly
                            setValue(name, checked, {
                                shouldValidate: true,
                                shouldDirty: true,
                            })
                        }
                        className={error ? 'invalid' : ''}
                        defaultChecked={!!defaultValue}
                        checked={!!field.value}
                    >
                        {label}
                    </Checkbox>
                )}
            />

            <ErrorMessage error={error} errorMessages={errorMessages} />
        </div>
    )
}
