import { useDispatch, useSelector } from 'react-redux'

import { useAppContext } from 'app/AppContext'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'
import { getCurrentUserRecordId } from 'data/wrappers/withUserUtils'

import { STUDIO_USER_UPDATED } from '../../utils/constants'
import {
    buildQueryKey,
    queryClient,
    useBatchUpdate,
    useCreateItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from '../_helpers'
import { invalidateAccounts } from '../accounts'

import {
    fetchAppUserDisabled,
    fetchAppUserListDisabled,
    fetchWorkspaceUsersDisabled,
} from './usersFetchMethods'

const ENDPOINT = 'users/'
const USER_PROFILES_RECORDS_ENDPOINT = 'user-profiles/'
const WORKSPACE_USERS_KEY = 'workspace_users&workspace=1'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useAppUsers(options = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    const queryKey = useQueryKeyBuilder('useAppUsers', {
        includeAuthKeys: true,
        includeStackId: true,
    })
    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })
    return useQuery(queryKey, 'list-app-users/', options, {
        disabledFn: () => fetchAppUserListDisabled(selectedStack, workspaceAccount),
    })
}

export function useFetchAuthenticatedUser(isThirdPartyAuthenticated) {
    // need the user Id in the deps, as sometimes when we first run this hook we haven't
    // got a user yet
    const queryKey = useQueryKeyBuilder(['useFetchAuthenticatedUser', isThirdPartyAuthenticated])
    const dispatch = useDispatch()
    const internalOptions = {
        onSuccess: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                dispatch({
                    type: STUDIO_USER_UPDATED,
                    payload: user,
                })
            }
        },
    }
    return useQuery(queryKey, `get-authenticated-user/`, internalOptions, {
        disabledFn: () => {
            // Don't run this query if we haven't got an Auth0 token or we already have the studio user,
            // in which case we don't need to hit this endpoint again.
            return !isThirdPartyAuthenticated
        },
        disabledValue: null,
        bypassMatchingStackCheck: true,
    })
}

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useWorkspaceUsers(options = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    return useQuery(WORKSPACE_USERS_KEY, 'list-account-users/', options, {
        disabledFn: () => fetchWorkspaceUsersDisabled(selectedStack, workspaceAccount),
        bypassMatchingStackCheck: true,
        // Submit this request using the studio user's token
        // and ignore any impersonation or role previewing.
        bypassPreviewAndImpersonation: true,
    })
}

export function useAddWorkspaceUser() {
    return useCreateItem(
        WORKSPACE_USERS_KEY,
        'account/add-user/',
        {
            onSuccess: () => {
                invalidateAccounts()
            },
        },
        {
            disableOptimisticUpdates: true,
        }
    )
}
/**
 * Fetches the latest version of the logged in Stacker User's info
 * @param {import('react-query').UseQueryOptions } options
 */
export function useFetchAppUser(options = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    const userId = useSelector((state) => state.user?.studioUser?._sid)
    const queryKey = useQueryKeyBuilder('useFetchAppUser', {
        includeAuthKeys: true,
        includeStackId: true,
    })
    const dispatch = useDispatch()

    const extraOptions = {
        onSuccess: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                if (options?.onSuccess) {
                    options.onSuccess(user)
                }
            }
        },
    }

    const internalOptions = {
        disabledFn: () => fetchAppUserDisabled(selectedStack?._sid, workspaceAccount),
        disabledValue: null,
        bypassMatchingStackCheck: true,
        afterFetch: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                dispatch({
                    type: STUDIO_USER_UPDATED,
                    payload: user,
                })
            }
        },
    }

    // need the user Id in the deps, as sometimes when we first run this hook we haven't
    // got a user yet
    return useQuery(
        queryKey,
        `${ENDPOINT}${userId}/`,
        {
            ...options,
            ...extraOptions,
        },
        internalOptions
    )
}

/**
 * Invalidates the useFetchAppUser and useUserRecord queries
 */
export const refechWorkspaceUsersList = () => {
    return queryClient.refetchQueries(WORKSPACE_USERS_KEY)
}
export const refetchAppUsersForAdmin = () => {
    return queryClient
        .refetchQueries(
            buildQueryKey('useAppUsersForAdmin', {
                includeStackId: true,
            })
        )
        .then(() => {
            queryClient.invalidateQueries(
                buildQueryKey('useAppUsers', {
                    includeAuthKeys: true,
                    includeStackId: true,
                })
            )
        })
}

export const invalidateAppUserQuery = () => {
    queryClient.invalidateQueries(
        buildQueryKey('useFetchAppUser', {
            includeAuthKeys: true,
            includeStackId: true,
        })
    )
}
export const resetUserRecordQuery = () => {
    queryClient.invalidateQueries(
        buildQueryKey('useUserRecord', {
            includeAuthKeys: true,
            includeStackId: true,
        })
    )
}
/**
 * Fetches the current user's record (their record from the data context, not their stacker user record)
 * @param {import('react-query').UseQueryOptions} [options]
 */
export function useUserRecord(options) {
    const userId = getCurrentUserRecordId()
    const { selectedStack } = useAppContext()
    const { isFetching } = useFetchAppUser()

    const internalOptions = {
        onSuccess: (result) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (result) {
                if (options?.onSuccess) {
                    options.onSuccess(result)
                }
            }
        },
        // If we don't pass anything, assume that the query is enabled
        enabled: !!(
            (options?.enabled === undefined ? true : options?.enabled) &&
            userId &&
            !isFetching
        ),
    }

    const queryKey = useQueryKeyBuilder('useUserRecord', {
        includeAuthKeys: true,
        includeStackId: true,
    })

    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })

    // NOTE: can't guarantee that we have perms to read this record,
    // so ignore 40x errors
    return useQuery(
        queryKey,
        `${USER_PROFILES_RECORDS_ENDPOINT}${userId}/`,
        { ...options, ...internalOptions, keepPreviousData: true },
        { ignore40x: true }
    )
}

export function useUpdateUser(options) {
    const dispatch = useDispatch()
    return useUpdateItem('users', ENDPOINT, {
        onSuccess: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                dispatch({
                    type: STUDIO_USER_UPDATED,
                    payload: user,
                })
            }
            if (options?.onSuccess) {
                options.onSuccess(user)
            }
        },
    })
}

export function useUpdateMemberships() {
    return useBatchUpdate(
        WORKSPACE_USERS_KEY,
        'account/update-memberships',
        (x, y) => x._sid === y._sid,
        {
            onSuccess: () => {
                invalidateAccounts()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}
