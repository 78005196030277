import React, { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'

import {
    Button as ButtonBase,
    Collapse,
    Input as InputBase,
    Link as LinkBase,
    Text as TextBase,
} from 'v2/ui'

import useWorkspaceTheme from '../../workspace/useWorkspaceTheme'

const isDarkTheme = (navTheme) => navTheme?.theme === 'dark' || navTheme?.theme === 'brand'

/*
The components in this file adjust their theming to match the effective
workspace theme (if any). These compponents are used on the various auth pages.
*/

export const Input = forwardRef(({ ...props }, ref) => {
    const navTheme = useWorkspaceTheme()
    const darkTheme = isDarkTheme(navTheme)

    return (
        <InputBase
            ref={ref}
            {...props}
            variant={darkTheme ? 'authenticationDark' : 'authentication'}
        />
    )
})

export const ErrorMessage = ({ message }) => {
    const navTheme = useWorkspaceTheme()
    const darkTheme = isDarkTheme(navTheme)
    return (
        <Collapse isOpen={!!message}>
            <TextBase
                textAlign="left"
                variant={darkTheme ? 'errorDark' : 'errorLight'}
                borderRadius="5px"
                px={3}
                py={1}
                mb={2}
            >
                {message}
            </TextBase>
        </Collapse>
    )
}

export const InputForm = ({
    name,
    required,
    pattern,
    defaultValue,
    errorMessages,
    minLength,
    maxLength,
    validate,
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const error = errors?.[name]
    const message = errorMessages?.[error?.type] || error?.message
    return (
        <>
            <Input
                defaultValue={defaultValue}
                {...register(name, { required, pattern, minLength, maxLength, validate })}
                {...props}
                className={error ? 'invalid' : ''}
            />
            <ErrorMessage message={message} />
        </>
    )
}
export function Text({ ...props }) {
    const navTheme = useWorkspaceTheme()

    const style = isDarkTheme(navTheme)
        ? { color: navTheme?.text }
        : { color: navTheme?.textBright }
    return <TextBase {...props} style={style} />
}

export function Link({ ...props }) {
    const navTheme = useWorkspaceTheme()
    const style = { color: navTheme?.text, textDecoration: 'underline' }
    return <LinkBase {...props} style={style} />
}
export function Button({ variant = undefined, buttonSize = 'md', ...props }) {
    return (
        <ButtonBase
            buttonSize={buttonSize}
            {...props}
            variant={variant}
            // variant={navTheme ? 'endUserPrimary' : variant}
        />
    )
}
