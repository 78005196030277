import React from 'react'

import { Icon } from 'legacy/v1/ui'

import { ViewCellBaseProps } from './types'

type RemoveRecordTableCellProps = ViewCellBaseProps & {
    onRemoveItem: (sid: string) => void
}

export const RemoveRecordTableCell: React.FC<RemoveRecordTableCellProps> = ({
    cell,
    onRemoveItem,
}) => (
    <div
        style={{
            color: '#ccc',
            paddingTop: 10,
        }}
    >
        <Icon
            icon="minus-square"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault() // Prevents clicking the row to view the record's detail page as well.
                onRemoveItem(cell.row.original._sid)
            }}
            style={{ cursor: 'pointer' }}
        />
    </div>
)
