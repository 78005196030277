// @ts-strict-ignore
import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { SlidingPaneState } from 'data/reducers/slidingPaneReducer'
import {
    SLIDING_PANE_BLOCK_CONTENT,
    SLIDING_PANE_CLEAR_BLOCKED_CONTENT,
    SLIDING_PANE_CLEAR_CONTENT,
    SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK,
    SLIDING_PANE_CLOSE,
    SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL,
    SLIDING_PANE_OPEN,
    SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL,
    SLIDING_PANE_SET_ANIMATION_COMPLETE,
    SLIDING_PANE_SET_CONTENT_DIRTY,
    SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO,
    SLIDING_PANE_TRANSITION_TO_WIDTH,
    SLIDING_PANE_UNSET_CONTENT_DIRTY,
} from 'data/utils/constants'
import useEditMode from 'features/admin/edit-mode/useEditMode'

import { SIDE_PANE_ANIMATION_DURATION, SIDE_PANE_DEFAULT_WIDTH } from '../constants'
import type { SlidingPaneContextInfo, SlidingPaneKey } from '../types'

import { usePreviewAs } from './usePreviewAs'

function useSlidingPane(): {
    state: SlidingPaneState
    openPane: (
        key: SlidingPaneKey,
        contentProps?: any,
        contentWidth?: number,
        ignoreIsDirty?: boolean
    ) => Promise<void>
    transitionToPane: (
        key: SlidingPaneKey,
        contentWidth: number,
        onTransitionEnded: () => void
    ) => void
    showManageData: (objectId?: string, dataConnectionId?: string) => void
    showEditLayout: () => void
    showManageUsers: () => void
    showAppSettings: (page?: any) => void
    showPreviewAs: () => void
    openBlockedPane: () => void
    close: (discardChanges?: boolean) => void
    clearContent: () => void
    setDirty: (dirty: boolean) => void
    openUnsavedChangesModal: (onContinueWithoutSaving?: () => void) => void
    closeUnsavedChangesModal: () => void
    clearBlockedContent: () => void
    clearOnContinueCallback: () => void
    setContextInfo: (value: SlidingPaneContextInfo) => void
    setAnimationComplete: () => void
    handleClickOutside: () => void
} {
    const { isOpen: isEditModeOpen, close: closeEditMode, open: openEditMode } = useEditMode()
    const { open: openPreviewAs } = usePreviewAs()

    const slidingPaneState: SlidingPaneState = useSelector((state) => state.slidingPane)
    const dispatch = useDispatch()
    const transitioningToKey = useRef<SlidingPaneKey | undefined>()

    const transitionToPane = useCallback(
        (key: SlidingPaneKey, contentWidth: number | string, onTransitionEnded: () => void) => {
            // Set a local value holding the key we're transitioning to
            transitioningToKey.current = key
            dispatch({
                type: SLIDING_PANE_TRANSITION_TO_WIDTH,
                payload: { key, contentWidth },
            })
            // Set a timer and perform the switch after the animation has finished
            setTimeout(() => {
                transitioningToKey.current = undefined
                onTransitionEnded()
            }, SIDE_PANE_ANIMATION_DURATION)
        },
        [dispatch]
    )

    const openPane = useCallback(
        async (
            key: SlidingPaneKey,
            contentProps?: any,
            contentWidth?: number | string,
            ignoreIsDirty?: boolean,
            isNotAnimating?: boolean
        ) => {
            if (key !== 'edit-layout' && isEditModeOpen) {
                if (!(await closeEditMode({ leaveSlidingPaneOpen: true }))) return
            }

            // if we already have a pane open and is in a dirty state, do not close it
            // and save the blocked content
            if (slidingPaneState.key && slidingPaneState.isDirty && !ignoreIsDirty) {
                dispatch({
                    type: SLIDING_PANE_BLOCK_CONTENT,
                    payload: { key, contentProps, contentWidth },
                })

                // close the edit mode if the panel to block is the edit layout
                if (key === 'edit-layout') {
                    await closeEditMode()
                }

                return
            }

            // if we enter preview mode, close the sliding pane and open the preview mode
            if (key === 'preview-as') {
                openPreviewAs()
                return
            }

            // if we already have a pane open, and we're going from a wider pane to a smaller one,
            // we want to transition to the target width first, and then switch the content.
            if (
                slidingPaneState.key &&
                contentWidth &&
                contentWidth < (slidingPaneState.contentWidth ?? Number.POSITIVE_INFINITY)
            ) {
                transitionToPane(key, contentWidth, () =>
                    dispatch({
                        type: SLIDING_PANE_OPEN,
                        payload: { key, contentProps, contentWidth },
                    })
                )
            } else {
                dispatch({
                    type: SLIDING_PANE_OPEN,
                    payload: { key, contentProps, contentWidth, isNotAnimating },
                })
            }
        },
        [
            isEditModeOpen,
            slidingPaneState.key,
            slidingPaneState.isDirty,
            slidingPaneState.contentWidth,
            closeEditMode,
            dispatch,
            openPreviewAs,
            transitionToPane,
        ]
    )

    const openBlockedPane = useCallback(async () => {
        const { blockedContent } = slidingPaneState
        if (!blockedContent) {
            return
        }

        // reopen the edit mode as it was closed when the edit layout pane was blocked
        if (blockedContent.key === 'edit-layout') {
            await openEditMode()
        }

        openPane(blockedContent.key, blockedContent.contentProps, blockedContent.contentWidth, true)
    }, [openPane, openEditMode, slidingPaneState])

    const setDirty = useCallback(
        (dirty: boolean) => {
            dispatch({
                type: dirty ? SLIDING_PANE_SET_CONTENT_DIRTY : SLIDING_PANE_UNSET_CONTENT_DIRTY,
            })
        },
        [dispatch]
    )

    const openUnsavedChangesModal = useCallback(
        (onContinueWithoutSaving?: () => void) => {
            dispatch({
                type: SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL,
                payload: { onContinueWithoutSaving },
            })
        },
        [dispatch]
    )

    const closeUnsavedChangesModal = useCallback(() => {
        dispatch({ type: SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL })
    }, [dispatch])

    const clearBlockedContent = useCallback(() => {
        dispatch({ type: SLIDING_PANE_CLEAR_BLOCKED_CONTENT })
    }, [dispatch])

    const clearOnContinueCallback = useCallback(() => {
        dispatch({ type: SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK })
    }, [dispatch])

    const showManageData = useCallback(
        (objectId?: string, dataConnectionId?: string): void => {
            openPane('data-grid', { objectId, dataConnectionId })
        },
        [openPane]
    )

    const showPreviewAs = useCallback(() => {
        openPane('preview-as')
    }, [openPane])

    const close = useCallback(
        async (discardChanges?: boolean) => {
            if (slidingPaneState.key === 'edit-layout') {
                if (await closeEditMode({ leaveSlidingPaneOpen: true })) {
                    transitionToPane(slidingPaneState.key as SlidingPaneKey, 0, () => {
                        dispatch({
                            type: SLIDING_PANE_CLOSE,
                        })
                    })
                }

                return
            }

            // Prevent closing if the sliding pane is in a dirty state and the user does not want to discard
            if (slidingPaneState.isDirty && !discardChanges) {
                dispatch({ type: SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL })
                return
            }

            transitionToPane(slidingPaneState.key as SlidingPaneKey, 0, () => {
                dispatch({
                    type: SLIDING_PANE_CLOSE,
                })
            })
        },
        [slidingPaneState.key, slidingPaneState.isDirty, transitionToPane, closeEditMode, dispatch]
    )

    const handleClickOutside = useCallback(() => {
        // If we have an open pane, and it's not edit layout, then close on any click outside
        // the frame (this handler is called by various places such as the nav bar and app bar).
        // We don't close the edit layout pane as it is a different class of pane altogether and
        // it should stay open until the user explicitly wants to exit edit mode.
        if (!!slidingPaneState.key && slidingPaneState.key !== 'edit-layout') {
            close()
        }
    }, [close, slidingPaneState.key])

    const showManageUsers = useCallback(() => {
        openPane('users')
    }, [openPane])

    const showAppSettings = useCallback(
        (paneState: any = { page: { name: 'data_sources' } }) => {
            openPane('app-settings', { paneState })
        },
        [openPane]
    )

    const clearContent = useCallback(() => {
        dispatch({ type: SLIDING_PANE_CLEAR_CONTENT })
    }, [dispatch])

    const setAnimationComplete = useCallback(() => {
        dispatch({ type: SLIDING_PANE_SET_ANIMATION_COMPLETE })
    }, [dispatch])

    const showEditLayout = useCallback(() => {
        // If edit mode is open and we aren't already in the edit-layout pane
        // (and also not in the process of transitioning to that pane), then
        // open that pane now.
        if (
            slidingPaneState.key !== 'edit-layout' &&
            transitioningToKey.current !== 'edit-layout'
        ) {
            openPane('edit-layout', null, SIDE_PANE_DEFAULT_WIDTH)
        }
    }, [openPane, slidingPaneState.key])

    const setContextInfo = useCallback(
        (value: SlidingPaneContextInfo) => {
            dispatch({ type: SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO, payload: value })
        },
        [dispatch]
    )

    return useMemo(
        () => ({
            state: slidingPaneState,
            openPane,
            transitionToPane,
            showManageData,
            showEditLayout,
            showManageUsers,
            showAppSettings,
            showPreviewAs,
            openBlockedPane,
            close,
            clearContent,
            clearOnContinueCallback,
            setDirty,
            setContextInfo,
            setAnimationComplete,
            handleClickOutside,
            openUnsavedChangesModal,
            closeUnsavedChangesModal,
            clearBlockedContent,
        }),
        [
            slidingPaneState,
            openPane,
            transitionToPane,
            showManageData,
            showEditLayout,
            showManageUsers,
            showAppSettings,
            showPreviewAs,
            openBlockedPane,
            close,
            clearContent,
            clearOnContinueCallback,
            setDirty,
            setContextInfo,
            setAnimationComplete,
            handleClickOutside,
            openUnsavedChangesModal,
            closeUnsavedChangesModal,
            clearBlockedContent,
        ]
    )
}

export default useSlidingPane
