// @ts-strict-ignore
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { faMailBulk } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalWithHint } from 'features/AppSettings/DataSources/ModalWithHint'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import publicAsset from 'utils/publicAsset'

// @ts-ignore
import { Button, Collapse, Flex, Heading, Text } from 'v2/ui'

import Form from '../../../ui/forms/Form'
import { FormField } from '../../../ui/forms/FormField'
// @ts-ignore
import SubmitButton from '../../../ui/forms/SubmitButton'

type OnboardingUsersInviteProps = {
    onClose: (patch: { object_id: string; email_field_id: string } | undefined) => void
}

export const OnboardingUsersInvite: React.FC<OnboardingUsersInviteProps> = (props) => {
    const [step, setStep] = React.useState(0)
    return (
        <ModalWithHint
            onClose={() => null}
            isHidden={false}
            isOpen={true}
            showCloseButton={false}
            hintImageUrl={publicAsset('/static/media/userTableHint1.png')}
            headerText={'User table'}
        >
            <Text variant="modal" style={{ marginTop: 8, marginBottom: 32 }}>
                Do you have a table that contains a list of users who you want to be able to access
                the app?
            </Text>

            <Collapse isOpen={step === 0}>
                <Button buttonSize="md" variant="adminPrimaryV4" onClick={() => setStep(1)}>
                    Yes
                </Button>
                <Button
                    style={{ marginLeft: 18 }}
                    buttonSize="md"
                    variant="adminSecondaryV4"
                    onClick={() => props.onClose(undefined)}
                >
                    Skip for now
                </Button>
            </Collapse>
            <Collapse isOpen={step === 1}>
                <Form
                    options={{
                        mode: 'onSubmit',
                        defaultValues: {},
                    }}
                    onSubmit={(data) => props.onClose(data)}
                >
                    <div style={{ marginBottom: 12 }}>
                        <Heading variant="h200" value="Table for users" />
                    </div>
                    {/* @ts-ignore */}
                    <FormField
                        style={{ height: 40, maxWidth: '350px' }}
                        as={ObjectPicker}
                        name="object_id"
                        placeholder="select a table"
                        variant="settings"
                        filter={(obj) => {
                            return obj.connection_options?.simpleconn_object !== 'STACKER_USERS'
                        }}
                        required={true}
                        controlled={true}
                    />
                    <Collapsed onClose={props.onClose} />
                </Form>
            </Collapse>
        </ModalWithHint>
    )
}

const Collapsed: React.FC<OnboardingUsersInviteProps> = (props) => {
    const context = useFormContext()

    const objectId = context.watch('object_id')
    return (
        <>
            <Collapse isOpen={!!objectId} style={{ marginTop: 36, marginBottom: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Heading variant="h200" value="Email addresses are in field" />
                </div>
                {/* @ts-ignore */}
                <FormField
                    style={{ height: 40, width: 350 }}
                    as={FieldPicker}
                    name="email_field_id"
                    variant="settings"
                    errorMessages={{ required: 'Please select the email field' }}
                    required
                    controlled
                    filter={(field) => ['string', 'long_text', 'copy'].includes(field.type)}
                    placeholder="Select email field"
                    objectId={objectId}
                />
                <Flex style={{ marginTop: 36, alignItems: 'flex-start', flexWrap: 'unset' }}>
                    <FontAwesomeIcon
                        /* @ts-ignore */
                        icon={faMailBulk}
                        style={{ color: '#b6bdc3', fontSize: 20, width: 20, marginRight: 12 }}
                    />
                    <Text>
                        Users will not receive any invitations to sign up when added. You can invite
                        users later when you configure the app.
                    </Text>
                </Flex>
                <Flex style={{ marginTop: 32 }}>
                    <SubmitButton buttonSize={'md'} disabled={false} variant="adminPrimaryV4">
                        Add users
                    </SubmitButton>

                    <Button
                        style={{ marginLeft: 18 }}
                        buttonSize="md"
                        variant="adminSecondaryV4"
                        onClick={() => props.onClose(undefined)}
                    >
                        Skip for now
                    </Button>
                </Flex>
            </Collapse>
            {!objectId && (
                <Button
                    style={{ marginTop: 32 }}
                    buttonSize="md"
                    variant="adminSecondaryV4"
                    onClick={() => props.onClose(undefined)}
                >
                    Skip for now
                </Button>
            )}
        </>
    )
}
