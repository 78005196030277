import React from 'react'

import { HStack, useNumberInput } from '@chakra-ui/react'

import { Button } from 'v2/ui'

import { InlineFilterInput } from './InlineFilterInput'

type InlineFilterNumberValueInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof InlineFilterInput>,
    'value' | 'onChange'
> & {
    value?: string | number
    onChange?: (valueAsString: string, valueAsNumber: number) => void
    min?: number
    max?: number
    precision?: number
    step?: number
    defaultValue?: number
}

export const InlineFilterNumberValueInput: React.FC<InlineFilterNumberValueInputProps> = ({
    placeholder = 'Enter a value',
    ...props
}) => {
    const {
        getInputProps,
        getIncrementButtonProps,
        getDecrementButtonProps,
        htmlProps,
        isDisabled,
        isFocused,
        isReadOnly,
    } = useNumberInput(props)

    const incrementProps = getIncrementButtonProps()
    const decrementProps = getDecrementButtonProps()
    const inputProps = getInputProps()

    return (
        <InlineFilterInput
            {...inputProps}
            {...htmlProps}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isReadOnly={isReadOnly}
            placeholder={placeholder}
            rightAdorner={
                <HStack spacing={1}>
                    <Button
                        color="userInterface.neutral.900"
                        bg="userInterface.neutral.200"
                        borderRadius="3px"
                        size="xs"
                        icon="minus"
                        iconColor="userInterface.neutral.900"
                        border={0}
                        height="1.3rem"
                        width="1.3rem"
                        px={0}
                        py={0}
                        _hover={{
                            bg: 'userInterface.neutral.400',
                        }}
                        _active={{
                            bg: 'userInterface.neutral.500',
                        }}
                        aria-label="Decrement value"
                        {...decrementProps}
                    />
                    <Button
                        color="userInterface.neutral.900"
                        bg="userInterface.neutral.200"
                        borderRadius="3px"
                        size="xs"
                        icon="add"
                        iconColor="userInterface.neutral.900"
                        border={0}
                        height="1.3rem"
                        width="1.3rem"
                        px={0}
                        py={0}
                        _hover={{
                            bg: 'userInterface.neutral.400',
                        }}
                        _active={{
                            bg: 'userInterface.neutral.500',
                        }}
                        aria-label="Increment value"
                        {...incrementProps}
                    />
                </HStack>
            }
            rightAdornerProps={{
                width: 'auto',
                px: 1.5,
            }}
            style={{
                paddingRight: '3.5rem',
            }}
            groupProps={{
                role: 'group',
                ref: undefined,
                onBlur: undefined,
                onChange: undefined,
                onFocus: undefined,
                onKeyDown: undefined,
                autoFocus: undefined,
                name: undefined,
                id: undefined,
                'aria-label': undefined,
                'aria-describedby': undefined,
            }}
        />
    )
}
