import React from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { Box, Collapse, Icon, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { GroupListItemType } from './groupListItemTypes'
import UserListItem from './UserListItem'

const colors = stackerTheme().colors
export type GroupListItemProps = {
    group: any
    visible: boolean
    filterUser: (user: any) => boolean
    hasSearchValue: boolean
    markAsRemoved?: boolean
    rowContents?: React.ReactNode
    rowContainerStyle?: React.CSSProperties
    UserComponent?: React.ElementType
}
export default function GroupListItem(props: GroupListItemProps) {
    const { group, visible, filterUser, hasSearchValue } = props
    const { isOpen: isExpanded, onToggle: toggleExpanded } = useDisclosure({ defaultIsOpen: false })
    const canToggle = !hasSearchValue && group.users.length > 0
    return (
        <>
            <GroupRowContainer
                style={{ display: visible ? 'flex' : 'none', ...props.rowContainerStyle }}
            >
                <GroupExpander
                    isExpanded={isExpanded}
                    onToggle={toggleExpanded}
                    hasSearchValue={hasSearchValue}
                    canToggle={canToggle}
                />
                <GroupDisplayName group={group} />
                {props.rowContents}
            </GroupRowContainer>
            <GroupUsersList
                users={group.users}
                isExpanded={isExpanded}
                hasSearchValue={hasSearchValue}
                filterUser={filterUser}
                visible={visible}
                UserComponent={props.UserComponent}
            />
        </>
    )
}

export const GroupRowContainer: React.FC<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ style, ...props }) => (
    <div
        style={{
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            alignItems: 'center',
            fontSize: '14px',
            ...style,
        }}
        {...props}
    />
)

type GroupExpanderProps = {
    canToggle: boolean
    isExpanded: boolean
    hasSearchValue: boolean
    onToggle: () => void
}
export const GroupExpander: React.FC<GroupExpanderProps> = ({
    canToggle,
    onToggle,
    isExpanded,
    hasSearchValue,
}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            marginRight: '8px',
        }}
    >
        {/* @ts-ignore */}
        <Icon
            button={canToggle}
            icon={isExpanded || hasSearchValue ? 'caretDown' : 'caretRight'}
            onClick={canToggle ? onToggle : () => {}}
            size="md"
            p={0}
            width="24px"
            height="24px"
            color="gray.400"
        />
    </div>
)

type GroupDisplayNameProps = {
    group: GroupListItemType
}
export const GroupDisplayName: React.FC<GroupDisplayNameProps> = ({ group }) => (
    <div
        style={{
            flexShrink: 1,
            minWidth: 0,
        }}
    >
        <div
            style={{
                color: colors.neutral[1000],
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
        >
            {group.name}
            <Text ml={1} color="neutral.800" display="inline">
                ({group.users.length})
            </Text>
        </div>
        <div
            style={{
                color: colors.neutral[800],
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
        ></div>
    </div>
)
type GroupUsersListProps = {
    users: any[]
    filterUser: (user: any) => boolean
    visible: boolean
    isExpanded: boolean
    hasSearchValue: boolean
    UserComponent?: React.ElementType
}
export const GroupUsersList: React.FC<GroupUsersListProps> = ({
    isExpanded,
    hasSearchValue,
    visible,
    users,
    filterUser,
    UserComponent = UserListItem,
}) => (
    <Collapse isOpen={(isExpanded || hasSearchValue) && visible}>
        <Box bg="neutral.100" py={1} pl={2}>
            {users.map((user) => {
                const isVisible = filterUser(user)
                return <UserComponent user={user} key={user._sid} visible={isVisible} />
            })}
        </Box>
    </Collapse>
)
