import { useCallback } from 'react'

import {
    DrawCustomCellCallback,
    DrawHeaderCallback,
    GridCellKind,
    GridColumn,
    Rectangle,
} from '@glideapps/glide-data-grid'
import { isEqual } from 'lodash'

import { useAppErrors } from 'data/hooks/appErrors'

import stackerTheme from 'v2/ui/theme/styles/default'

import type { FieldGridColumn, RequiredFields } from '../types'

import { getAddFieldColumn } from './useDataGridColumns'
import useFieldCells from './useFieldCells'
import type { FailedCommit } from './useRecordEditManager'

const { colors } = stackerTheme()

function useDataGridDrawUtils(object: ObjectDto) {
    const { data: errors } = useAppErrors()
    const { drawCell: drawCustomCell } = useFieldCells()

    const drawHeader = useCallback(
        (args: Parameters<DrawHeaderCallback>[0]) => {
            const { ctx, rect, spriteManager, theme, column } = args
            const { field }: { field: FieldDto } = column as FieldGridColumn
            const erroredFields = errors?.synthetic_field_errors.filter(
                // TODO: remove when data/hooks/appErrors has been converted to TS
                // @ts-ignore
                (e) => e.object_id === object._sid
            )

            // TODO: remove when data/hooks/appErrors has been converted to TS
            // @ts-ignore
            if (erroredFields?.find((e) => e.field_id === field?._sid)) {
                const HEIGHT = 16
                spriteManager.drawSprite(
                    'warning',
                    'normal',
                    ctx,
                    // this places the warning icon on the left of the menu toggle
                    rect.x + rect.width - 45,
                    rect.height / 2 - HEIGHT / 2,
                    HEIGHT,
                    theme
                )
            }

            return false
        },
        [errors?.synthetic_field_errors, object._sid]
    )

    const drawCell = (
        args: Parameters<DrawCustomCellCallback>[0],
        columns: GridColumn[],
        records: RecordDto[],
        failedRecords: {
            [keyof: string]: FailedCommit
        },
        requiredFields: RequiredFields | undefined,
        addFieldButtonConfig: { title: string; width: number }
    ) => {
        const { ctx, rect, col, row, cell, theme } = args

        const column = columns?.[col - 1]
        const record = records?.[row]
        const field = (column as FieldGridColumn)?.field
        const required = requiredFields?.[field?.api_name]

        // If field is required and the record has no value, then draw this field ourselves
        if (record && required && !record?.[field?.api_name]) {
            const drawArea: Rectangle = {
                x: rect.x + theme.cellHorizontalPadding,
                y: rect.y + theme.cellVerticalPadding,
                width: rect.width - 2 * theme.cellHorizontalPadding,
                height: rect.height - 2 * theme.cellVerticalPadding,
            }

            ctx.fillStyle = colors.userInterface.error[600]
            const originalFont = ctx.font
            ctx.font = 'italic ' + ctx.font
            ctx.fillText(`${required.placeholder || 'required'}`, drawArea.x, drawArea.y + 13)
            ctx.font = originalFont
            return true
        }

        if (
            !isEqual(column, getAddFieldColumn(addFieldButtonConfig)) &&
            column &&
            record &&
            (failedRecords[record._sid]?.data?.[field.api_name] !== undefined ||
                failedRecords[record._sid]?.isCreate)
        ) {
            // draw error state
            ctx.lineWidth = 1
            ctx.strokeStyle = colors.userInterface.error[600]
            ctx.strokeRect(rect.x + 1, rect.y + 1, rect.width - 1, rect.height - 1)
        }

        if (cell.kind === GridCellKind.Custom) {
            return drawCustomCell(args)
        }
        return false
    }

    return { drawHeader, drawCell }
}

export default useDataGridDrawUtils
