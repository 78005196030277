export const fieldToItem = (field: FieldDto): BlockFieldAttributeContent => ({
    type: 'field',
    fieldId: field._sid,
    objectId: field.object_id,
    fieldName: field.api_name,
    required: false,
    fullWidth:
        field.type === 'long_text' ||
        (field.type === 'string' && field.options?.render_variant === 'richText'),
    showCreateButton: ['lookup', 'multi_lookup'].includes(field.type),
    isPrimary: field.is_primary,
})
