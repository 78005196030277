/* Code Quality: Good */

import React from 'react'

import PropTypes from 'prop-types'

import { fetchWithAuth } from 'data/utils/utils'
import Frame from 'features/core/Frame'
import List from 'features/views/List/List'
import { Button, ButtonModal, Heading, Section, Text } from 'legacy/v1/ui'
import { Container } from 'legacy/v1/ui/index'

import { SettingsContent, SettingsWrapper } from '../settings/ui/SettingsFrame'
import SettingsHeading from '../settings/ui/SettingsHeading'

const TableCacheInfoTable = ({ overviewInfo, showData }) => {
    const renderToText = ({ cell }) => <Text>{cell.value}</Text>
    const apiLink = (label) => {
        const renderToApiLink = ({ cell }) =>
            cell.value ? (
                <ApiLinkButton
                    apiLink={cell.value}
                    label={label}
                    showData={showData}
                    objectName={cell.row.original.name}
                />
            ) : (
                <Text>-</Text>
            )
        return renderToApiLink
    }

    const columns = [
        {
            Header: 'Table',
            accessor: 'name',
            Cell: renderToText,
        },
        {
            Header: 'Schema',
            accessor: 'schema',
            Cell: apiLink('External schema'),
        },
        {
            Header: 'Data',
            accessor: 'data',
            Cell: apiLink('External data'),
        },
    ]

    return <List columns={columns} data={overviewInfo} />
}

TableCacheInfoTable.propTypes = {
    overviewInfo: PropTypes.object.isRequired,
    showData: PropTypes.func.isRequired,
}

class ApiLinkButton extends React.Component {
    state = { loading: false, data: null }

    loadData = (callback) => {
        if (this.state.loading || this.state.data) {
            return
        }

        const linkSuffix = this.props.apiLink.split('/').slice(4).join('/')

        fetchWithAuth(linkSuffix, {}).then((response) => {
            response.json().then((result) => {
                this.setState({ data: result, loading: false })
                callback(result)
            })
        })
        this.setState({ loading: true })
    }

    showData = () => {
        if (this.state.data) {
            this.props.showData(this.props.objectName, this.props.label, this.state.data)
        } else {
            this.loadData((data) => {
                this.props.showData(this.props.objectName, this.props.label, data)
            })
        }
    }

    render() {
        return <Button onClick={this.showData}>{this.props.label}</Button>
    }
}

ApiLinkButton.propTypes = {
    apiLink: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    showData: PropTypes.func.isRequired,
    objectName: PropTypes.string.isRequired,
}

class DataDebuggerPage extends React.Component {
    state = { overviewInfo: null, modalOpen: false, modalData: null, modalTitle: null }

    UNSAFE_componentWillMount() {
        this.loadOverviewInfo()
    }

    loadOverviewInfo = () => {
        fetchWithAuth('data-debugger/', {}).then((response) => {
            response.json().then((result) => {
                this.setState({
                    debuggerEnabled: result.enabled,
                    overviewInfo: result.tables,
                    syncLog: result.synclog,
                })
            })
        })
    }

    render() {
        return (
            <Frame header={false}>
                <SettingsHeading name="Data Debugger" />
                <SettingsWrapper>
                    {this.state.overviewInfo && (
                        <>
                            <SettingsContent style={{ marginBottom: 20 }}>
                                <Section>
                                    <Container>
                                        <Text>
                                            Data Debugger:{' '}
                                            {this.state.debuggerEnabled === true
                                                ? 'Enabled'
                                                : this.state.debuggerEnabled === false
                                                ? 'Disabled'
                                                : ''}
                                        </Text>
                                    </Container>
                                </Section>
                            </SettingsContent>
                            <Section>
                                <Heading size="medium">Logs</Heading>
                                <Button
                                    disabled={!this.state.syncLog}
                                    onClick={() =>
                                        this.setState({
                                            modalData: this.state.syncLog,
                                            modalOpen: true,
                                            modalTitle: 'Last Schema Sync Log',
                                            modalJSON: false,
                                        })
                                    }
                                >
                                    Last Schema Sync
                                </Button>
                            </Section>
                            <Section>
                                <Heading size="medium">Tables</Heading>
                                <TableCacheInfoTable
                                    overviewInfo={this.state.overviewInfo}
                                    showData={(objectName, dataKind, data) => {
                                        const title = `${dataKind} for ${objectName}`
                                        console.log(title, data)
                                        this.setState({
                                            modalData: data,
                                            modalOpen: true,
                                            modalTitle: title,
                                            modalJSON: true,
                                        })
                                    }}
                                />
                                <ButtonModal
                                    onClose={() => this.setState({ modalOpen: false })}
                                    open={this.state.modalOpen}
                                    dialog
                                    header={() => (
                                        <Heading
                                            style={{ padding: 0, margin: 0, paddingLeft: 16 }}
                                            size="medium"
                                        >
                                            {this.state.modalTitle}
                                        </Heading>
                                    )}
                                    button={false}
                                >
                                    {() => (
                                        <textarea style={{ height: '300px' }}>
                                            {this.state.modalJSON
                                                ? JSON.stringify(this.state.modalData, null, 4)
                                                : this.state.modalData}
                                        </textarea>
                                    )}
                                </ButtonModal>
                            </Section>
                        </>
                    )}
                </SettingsWrapper>
            </Frame>
        )
    }
}

DataDebuggerPage.propTypes = {}

export default DataDebuggerPage
