import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useConfirmModal from 'app/ConfirmModal'
import { getUrl, Urls } from 'app/UrlService'
import { useDeleteView } from 'data/hooks/views'
import useEditMode from 'features/admin/edit-mode/useEditMode'

import { Icon, Text } from 'v2/ui'
import { DeleteData } from 'v2/ui/svgs'
import { Modal } from 'v2/ui/utils/useModalToggle'

import { formatDeleteViewErrorLabel, formatDeleteViewLabel } from './labels'

export const useConfirmDeleteViewModal = () => {
    const { show } = useConfirmModal()
    const { mutateAsync: deleteView, isError, reset } = useDeleteView()

    const { close: closeEditMode } = useEditMode()

    const history = useHistory()

    return useCallback(
        (params: { view: ViewDto; beforeRedirect?: () => void }) => {
            const { view, beforeRedirect } = params

            show({
                title: 'Confirm Delete',
                confirmVariant: 'PrimaryError',
                icon: <Icon svg={<DeleteData />} size="56px" ml="15px" mb={4} />,
                message: (
                    <>
                        <Text>{formatDeleteViewLabel(view)}</Text>
                        {isError && <Text variant="error">{formatDeleteViewErrorLabel(view)}</Text>}
                    </>
                ),
                onConfirm: async (modal: Modal<unknown>) => {
                    await deleteView(view._sid)
                    closeEditMode()

                    beforeRedirect?.()

                    history.replace(getUrl(Urls.Root))

                    reset()
                    modal.setIsOpen(false)
                },
                onCancel: (modal: Modal<unknown>) => {
                    reset()
                    modal.setIsOpen(false)
                },
            })
        },
        [show, isError, deleteView, closeEditMode, history, reset]
    )
}
