import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'

import AppContext from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { useStacks } from 'data/hooks/stacks'
import { orderAlphabeticallyStacks } from 'data/utils/utils'
import NotificationMenu from 'features/collaboration/NotificationMenu'

//@ts-ignore
import { Button, Icon } from 'v2/ui'

//@ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../../utils/analytics'

const ButtonSize = 42

export const AppBarButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    margin-bottom: 2px;
    width: ${ButtonSize}px;
    height: ${ButtonSize}px;
    font-size: 1rem;
    font-weight: bold;
    padding-left: unset;
    padding-right: unset;
    border: none;
    background: transparent;
    color: ${(props) =>
        props.navTheme.theme === 'light'
            ? V4DesignSystem.colors.gray[500]
            : props.navTheme?.textBright};
    transition: background 0.2s ease-in-ease-out;
    :hover {
        background: ${(props) =>
            props.navTheme.theme === 'light' ? '#F4F5F8' : 'rgba(255, 255, 255, 0.2)'};
    }
`

type NotificationBarProps = {
    navTheme: any
    userMenuOnly: boolean
}

const NotificationBar: React.FC<NotificationBarProps> = ({ navTheme, userMenuOnly }) => {
    const location = useLocation()
    const showStacks = location.pathname === '/__account'
    const workspaceAccount: any = useContext(AppContext)
    const selectedStack: any = useContext(AppContext)

    const accountId = selectedStack?.account_id ? selectedStack?.account_id : workspaceAccount?._sid
    const studioUser: any = useAppUserContext()
    const { data: stacks } = useStacks()

    const orderedStacks = useMemo(() => {
        if ((!studioUser || !selectedStack) && !showStacks) return null
        return stacks ? orderAlphabeticallyStacks(stacks, accountId) : []
    }, [stacks, selectedStack, studioUser, showStacks, accountId])

    return (
        <NotificationMenu right={false} ml={4} pt="12px">
            <AppBarButton
                ml={orderedStacks.length === 0 ? '-9px' : -4}
                mt="-12px"
                navTheme={navTheme}
                isDisabled={userMenuOnly}
                onClick={() => {
                    analytics.track('notification menu clicked', {
                        from: 'app bar',
                    })
                }}
            >
                <Icon icon="bell" size="md" />
            </AppBarButton>
        </NotificationMenu>
    )
}

export default NotificationBar
