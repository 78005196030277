/* Code Quality: Not audited */

import React from 'react'

import PropTypes from 'prop-types'

import LabelledAttribute from 'legacy/v1/ui/components/LabelledAttribute'

import Attribute from './Attribute'

const AttributeList = (props) => {
    const attributes = (props.fields || []).map((field) => (
        <LabelledAttribute
            key={field._sid}
            label={field.label || field.api_name}
            dontHideOverflow={
                ['dropdown', 'lookup', 'multi_select', 'multi_lookup', 'date', 'datetime'].indexOf(
                    field.type
                ) !== -1
            }
        >
            <Attribute
                field={field}
                editable={props.editable}
                inline={props.inline !== false}
                onChange={(value) => props.onChange({ [field.api_name]: value })}
            >
                {getValueFromApiName(props.data, field.api_name, field.defaultValue)}
            </Attribute>
        </LabelledAttribute>
    ))
    return <>{attributes}</>
}

AttributeList.propTypes = {
    data: PropTypes.object,
    fields: PropTypes.array,
    onChange: PropTypes.func,
}

export default AttributeList

const getValueFromApiName = (data, api_name, defaultValue) => {
    if (!api_name) return null
    let val = data || {}
    api_name.split('.').forEach((name) => (val = val[name]))

    return val !== undefined ? val : defaultValue
}
