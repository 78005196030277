import { useCallback, useContext } from 'react'

import { AppContext } from 'app/AppContext'
import { Rights, useAppUserContext } from 'app/AppUserContext'
import { useDataConnections } from 'data/hooks/dataConnections'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'

const getOneDayFromToday = () => {
    const todayTimestamp = new Date().getTime()
    const oneDayInMs = 24 * 60 * 60 * 1000

    return new Date(todayTimestamp + oneDayInMs).toISOString()
}

const getShouldShowReminder = (
    user: UserDto,
    selectedStack: StackDto,
    airtableV1Connections: DataConnectionDto[]
): boolean => {
    const skipUntilDate = user
        ? selectedStack?.options?.new_skip_airtable_v2_upgrade_reminder_until?.[user._sid]
        : null

    const skipUpgradeReminder = skipUntilDate && new Date(skipUntilDate) > new Date()

    return airtableV1Connections.length > 0 && !skipUpgradeReminder
}

export const useAirtableV2UpgradeReminder = (): {
    shouldShowReminder: boolean
    setOneWeekReminder: () => void
} => {
    const { data: allDataConnections } = useDataConnections()
    const { selectedStack } = useContext(AppContext)
    const { user, isStudioUser, isImpersonating, hasRight } = useAppUserContext()
    const updateStackOptions = useUpdateStackOptions()

    const { flags } = useLDFlags()

    const isAdmin = user && isStudioUser && !isImpersonating && hasRight(Rights.Admin.Any)

    const airtableV1Connections = allDataConnections.filter(
        (dc) => dc.type == 'airtable' && dc.version == 1
    )

    const setOneWeekReminder = useCallback(() => {
        if (!user) return

        updateStackOptions(selectedStack as StackDto, {
            new_skip_airtable_v2_upgrade_reminder_until: {
                ...selectedStack?.options?.new_skip_airtable_v2_upgrade_reminder_until,
                [user._sid]: getOneDayFromToday(),
            },
        })
    }, [updateStackOptions, selectedStack, user])

    const shouldShowReminder =
        flags.enableAirtableV2UpgradeReminder && user && isAdmin && selectedStack
            ? getShouldShowReminder(user, selectedStack, airtableV1Connections)
            : false

    return {
        shouldShowReminder,
        setOneWeekReminder,
    }
}
