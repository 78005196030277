import get from 'lodash/get'

// match inline filters to record filters to then send to the backend
const translateEndUserFilters = (filters, columns, searchValue) => {
    let validFilters = []
    Object.keys(filters).forEach((key) => {
        const column = columns.find((column) => column.accessor == key)
        const filter = get(filters, key)
        let type = ''
        let value = filter
        if (column) {
            if (typeof filter === 'object') {
                type = get(filter, 'filterType')
                value = get(filter, 'filterValue')
                if (['dropdown', 'lookup'].includes(column.type)) {
                    if (type == 'contains') {
                        type = 'oneOf'
                    }
                    if (type == 'containsNone') {
                        type = 'noneOf'
                    }
                }
                if (['multi_select', 'multi_lookup'].includes(column.type)) {
                    if (type == 'contains') {
                        type = 'containsAny'
                    }
                }
            }
            if (column.type == 'checkbox') {
                value = value == '1' ? 'true' : 'false'
            }
            validFilters.push({ field: { api_name: key }, options: { value, option: type } })
        }
    })
    // Add the search in the same format to append it to the url
    if (searchValue) {
        validFilters.push({ field: { api_name: '_search' }, options: { value: searchValue } })
    }
    return validFilters
}

export default translateEndUserFilters
