import React, { useState } from 'react'

import { ButtonProps, FlexProps } from '@chakra-ui/react'

import { ProtectedFeature, ProtectedFeatureIndicator } from '../utils/ProtectedFeature'

import Button from './Button'
import Flex from './Flex'
import Text from './Text'
import TitleIcon from './TitleIcon'

type Action = Omit<ButtonProps, 'variant' | 'children'> & {
    content?: string | React.ReactNode
    variant?: string
    buttonSize?: string | string[]
    href?: string
}

type Props = Omit<FlexProps, 'title'> & {
    icon?: string
    svg?: React.ReactNode
    title?: string | React.ReactNode
    subtitle?: string | React.ReactNode
    actions?: Action[]
    classes?: any
    protectedFeature?: any
}

const FeatureSplashScreen = ({
    icon,
    svg,
    title,
    subtitle,
    actions = [],
    classes,
    protectedFeature,
    ...props
}: Props) => {
    const [isProtectedOpen, setIsProtectedOpen] = useState(false)
    return (
        <>
            <Flex
                width="100%"
                height="100%"
                column
                align="center"
                textAlign="center"
                justify="center"
                position="relative"
                {...props}
            >
                {protectedFeature && (
                    <div
                        style={{
                            position: 'absolute',
                            right: '3px',
                            top: '3px',
                        }}
                    >
                        <ProtectedFeatureIndicator
                            feature={protectedFeature}
                            onClick={() => setIsProtectedOpen(true)}
                        />
                    </div>
                )}
                <TitleIcon icon={icon} svg={svg} />
                <Text variant="adminHeading" mb={4} mt={8}>
                    {title}
                </Text>
                <Text fontSize="md" maxWidth="460px">
                    {subtitle}
                </Text>
                {Boolean(actions?.length) && (
                    <Flex mt={8} className={classes}>
                        {actions.map(
                            (
                                {
                                    content,
                                    variant,
                                    buttonSize = ['sm', 'sm', 'md', 'md'],
                                    ...props
                                },
                                idx
                            ) => (
                                <Button
                                    key={idx}
                                    variant={
                                        variant ||
                                        (idx === 0 ? 'adminPrimaryV4' : 'adminSecondaryV4')
                                    }
                                    buttonSize={buttonSize}
                                    mx={2}
                                    {...props}
                                >
                                    {content}
                                </Button>
                            )
                        )}
                    </Flex>
                )}
            </Flex>
            {protectedFeature && (
                <ProtectedFeature
                    feature={protectedFeature}
                    showModal={isProtectedOpen}
                    onModalClosed={() => setIsProtectedOpen(false)}
                />
            )}
        </>
    )
}
export default FeatureSplashScreen
