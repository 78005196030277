import type { FilterAction, RecordFiltersState } from './types'

function reducer(state: RecordFiltersState, action: FilterAction): RecordFiltersState {
    switch (action.type) {
        case 'FILTER_ADD':
            return {
                ...state,
                filters: [
                    ...state.filters.filter(({ _id }) => _id !== action.payload.filter._id),
                    action.payload.filter,
                ],
            }
        case 'FILTER_UPDATE':
            return {
                ...state,
                filters: state.filters.map((filter) =>
                    filter._id !== action.payload.id
                        ? filter
                        : {
                              _id: filter._id,
                              field: filter.field,
                              field_sid: filter.field_sid || filter.field?._sid,
                              options: action.payload.filter.options,
                          }
                ),
            }
        case 'FILTER_DELETE':
            return {
                ...state,
                filters: state.filters.filter(({ _id }) => _id !== action.payload.id),
            }
        case 'FILTER_RESET':
            return {
                ...state,
                filters: action.payload.filters,
            }
        default:
            return state
    }
}

export default reducer
