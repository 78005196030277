/* Code Quality: OK */

import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { withStack } from 'data/wrappers/WithStacks'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import ThemeWrapper from 'features/pages/blocks/styles/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import { Heading, Section } from 'legacy/v1/ui'

import { Button, Input, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { CenterPanel, CenterPanelWrapper } from '../studio/ui/Layout'

import { SignupApi } from './utils/index'

const BASE_CLASS = STYLE_CLASSES.REGISTER_PAGE

const STATUS_NEW = 'STATUS_NEW'
const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS'
const STATUS_SIGNED_UP = 'STATUS_SIGNED_UP'
const STATUS_REJECTED = 'STATUS_REJECTED'
const REJECTED_EMAIL =
    'Please check that you have entered your email address correctly, making sure that it does not contain any spaces.'
const NOT_AUTHORISED = 'That email address is not permitted to register for this app.'
const DUPLICATED_EMAILS =
    'This email address is duplicated, please contact your admin to solve this conflict'

class UserSignupPage extends React.Component {
    state = { title: 'Stacker', logo: '', icon: '' }

    constructor(props) {
        super(props)
        this.state.status = STATUS_NEW

        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        this.state.email = searchString.email
    }

    register = (e) => {
        e.preventDefault()
        const { email } = this.state
        const password = Math.random().toString(36).slice(2)
        const name = email
        this.setState({ status: STATUS_IN_PROGRESS }, () => {
            SignupApi.registerEndUser(email, password, name, name)
                .then((response) => {
                    const token = response.api_token
                    if (!token) {
                        this.setState({ status: STATUS_REJECTED, error: response.detail })
                        return
                    }
                    // We don't set their user yet – they need to go through email verification first!
                    this.setState({ status: STATUS_SIGNED_UP })
                })
                .catch((response) => {
                    response.json().then((json) => {
                        const errorCode = get(json, 'error_code')
                        if (errorCode == 201) {
                            this.setState({
                                status: STATUS_REJECTED,
                                error: DUPLICATED_EMAILS,
                            })
                        } else {
                            this.setState({
                                status: STATUS_REJECTED,
                                error: response.status === 403 ? NOT_AUTHORISED : REJECTED_EMAIL,
                            })
                        }
                    })
                })
        })
    }
    isStudioUser = () => {
        return localStorage.getItem('studio_token')
    }

    render() {
        const { isLaunched, stackOptions } = this.props
        if (!this.props.stack._sid) return null
        const options = stackOptions || {}

        const appName = options?.external_access?.name || this.props.stack.name

        const logo = getLogoUrl(this.props.stack, true)
        const notLaunched = !isLaunched
        const disabledStyle = { backgroundColor: '#eee' }

        if (stackOptions.passwordless) {
            window.location = getUrl(Urls.Login)
        }

        return (
            <>
                <DefaultHeaderMeta title="Login" />
                <ThemeWrapper loadFromStack>
                    {() => (
                        <CenterPanelWrapper className={BASE_CLASS}>
                            <CenterPanel>
                                <form>
                                    {logo && (
                                        <Section margin="large" padding="none" centered>
                                            <Logo src={logo} />
                                        </Section>
                                    )}
                                    {this.state.status !== STATUS_SIGNED_UP && (
                                        <>
                                            <Section margin="medium" padding="none" centered>
                                                <Heading size="medium" margin="none">
                                                    <span
                                                        className={STYLE_CLASSES.REGISTER_FOR_TEXT}
                                                    >
                                                        Register for
                                                    </span>{' '}
                                                    {appName}
                                                </Heading>
                                                <Text className={STYLE_CLASSES.REGISTER_INTRO}>
                                                    You&apos;ll be sent an email confirmation
                                                </Text>
                                            </Section>
                                            <Section margin="medium" padding="none">
                                                <Input
                                                    disabled={notLaunched}
                                                    style={notLaunched ? disabledStyle : {}}
                                                    onChange={(e) =>
                                                        this.setState({ email: e.target.value })
                                                    }
                                                    padding="medium"
                                                    placeholder="Email"
                                                    value={this.state.email}
                                                    className={STYLE_CLASSES.EMAIL_INPUT}
                                                />
                                            </Section>
                                            <Button
                                                onClick={this.register}
                                                width="100%"
                                                variant="primary"
                                                buttonSize="md"
                                                type="submit"
                                                isDisabled={
                                                    notLaunched ||
                                                    this.state.status === STATUS_IN_PROGRESS
                                                }
                                                className={STYLE_CLASSES.REGISTER_BUTTON}
                                            >
                                                {this.state.status === STATUS_IN_PROGRESS
                                                    ? 'Creating your account'
                                                    : 'Register'}
                                            </Button>
                                            {this.state.status === STATUS_REJECTED && (
                                                <Text size="medium" mt={4} variant="error">
                                                    {this.state.error}
                                                </Text>
                                            )}
                                            <Section style={{ alignItems: 'center' }} margin="none">
                                                <Text margin="none" padding="none">
                                                    <span className={STYLE_CLASSES.LOGIN_TEXT}>
                                                        Already registered?{' '}
                                                    </span>
                                                    <Link
                                                        to={getUrl(Urls.Login)}
                                                        className={STYLE_CLASSES.LOGIN_LINK}
                                                    >
                                                        Login
                                                    </Link>
                                                </Text>
                                            </Section>
                                        </>
                                    )}
                                    {this.state.status === STATUS_SIGNED_UP && (
                                        <Section margin="medium" padding="none" centered>
                                            <Text
                                                centered
                                                style={{ fontSize: '1.2em', lineHeight: '1.4em' }}
                                            >
                                                <strong>We&apos;ve sent you an email.</strong>{' '}
                                                <br />
                                                Click on the link in it to continue.
                                            </Text>
                                        </Section>
                                    )}
                                </form>
                            </CenterPanel>
                        </CenterPanelWrapper>
                    )}
                </ThemeWrapper>
            </>
        )
    }
}

UserSignupPage.propTypes = {
    stack: PropTypes.object.isRequired, // From withStacks
    stackOptions: PropTypes.object.isRequired, // From withStacks
}

const Logo = styled('img')`
    height: 60px;
    object-fit: contain;
    max-width: 90%;

    @media (max-width: 420px) {
        height: 35px;
    }
`

export default withStack(withRouter(UserSignupPage))
