import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from 'react'

import isEqual from 'lodash/isEqual'

import useDebounce from 'v2/ui/utils/useDebounce'

import { nextFilterOptions } from '../constants'

import reducer from './reducer'
import type { FilterAction, RecordFiltersState } from './types'

const RecordFiltersContext = createContext<{
    state: RecordFiltersState
    object: ObjectDto | undefined
    dispatch: (action: FilterAction) => void
    onAddFilter: (filter: Omit<Filter, '_id'>) => void
    onFieldChanged: (filter: Filter & { id: number }) => void
    onDeleteFilter: (id: number) => void
    onResetFilters: (filters: Filter[]) => void
}>({
    state: { filters: [] },
    object: undefined,
    dispatch: () => undefined,
    onAddFilter: () => undefined,
    onFieldChanged: () => undefined,
    onDeleteFilter: () => undefined,
    onResetFilters: () => undefined,
})

type Props = {
    initialFilters: Filter[] | undefined
    object: ObjectDto | undefined
    includeIncompleteFilters: boolean
    onChange: (filters: Filter[]) => void
}

export const RecordFiltersProvider: FC<Props> = ({
    initialFilters,
    object,
    includeIncompleteFilters,
    onChange,
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, { filters: initialFilters || [] })

    const previousFiltersSent = useRef<Filter[]>(initialFilters || [])

    const onChangeCallback = useCallback((filters: Filter[]) => onChange(filters), [onChange])
    const debouncedOnChange = useDebounce(onChangeCallback, 300)

    const onAddFilter = useCallback((filter: Omit<Filter, '_id'>) => {
        dispatch({
            type: 'FILTER_ADD',
            payload: {
                filter: { ...filter, _id: Math.random() },
            },
        })
    }, [])

    const onFieldChanged = useCallback(({ id, ...filter }: Filter & { id: number }) => {
        dispatch({ type: 'FILTER_UPDATE', payload: { id, filter } })
    }, [])

    const onDeleteFilter = useCallback((id: number) => {
        dispatch({ type: 'FILTER_DELETE', payload: { id } })
    }, [])

    const onResetFilters = useCallback((filters: Filter[]) => {
        dispatch({ type: 'FILTER_RESET', payload: { filters } })
    }, [])

    useEffect(() => {
        const completeFilters = state.filters.filter((filter) => {
            const { option, value } = filter.options
            // @ts-ignore
            const requiresValue = option && nextFilterOptions[option]
            return option && ((requiresValue && value) || !requiresValue)
        })

        const finalFilters = includeIncompleteFilters ? state.filters : completeFilters

        // If the new value is different than the last value we sent to the
        // subscriber, then send it now
        if (!isEqual(finalFilters, previousFiltersSent.current)) {
            previousFiltersSent.current = finalFilters
            debouncedOnChange(finalFilters)
        }
    }, [debouncedOnChange, includeIncompleteFilters, state.filters])

    useEffect(() => {
        if (!isEqual(initialFilters, previousFiltersSent.current)) {
            previousFiltersSent.current = initialFilters || []
            dispatch({ type: 'FILTER_RESET', payload: { filters: initialFilters || [] } })
        }
    }, [initialFilters])

    return (
        <RecordFiltersContext.Provider
            value={{
                state,
                object,
                dispatch,
                onAddFilter,
                onFieldChanged,
                onDeleteFilter,
                onResetFilters,
            }}
        >
            {children}
        </RecordFiltersContext.Provider>
    )
}

export const useRecordFilters = () => useContext(RecordFiltersContext)
