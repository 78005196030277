import { getIsSyntheticField } from '../../../../utils/fieldUtils'

export function getIsFieldSynced({
    dataConnectionType,
    field,
}: {
    dataConnectionType?: DataConnectionType
    field: FieldDto
}): boolean {
    const isSyncedTable =
        dataConnectionType !== 'native_tables' && dataConnectionType !== 'stacker_users'
    const isSyntheticField = getIsSyntheticField(field)

    return isSyncedTable && !isSyntheticField && !field.is_stacker_augmented_field
}
