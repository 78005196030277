import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAppContext } from 'app/AppContext'
import { withDataConnections } from 'data/wrappers/WithDataConnections'

import { Button, Flex } from 'v2/ui'

import DATA_PROVIDERS from '../admin/data-connector/dataProviderConfig'
import { ProviderIcon } from '../admin/data-connector/ProviderIcon'

import { BANNER_HEIGHT } from './appBannerConstants'

function TemplateInstallSuccessBanner({
    leftOffset,
    showingAppBar,
    buttonProps = {},
    setIsBannerShowing,
    ...props
}) {
    const { selectedStack } = useAppContext()

    const { dataConnections } = props

    const [showBanner, setShowBanner] = useState(false)

    const [providerType, setProviderType] = useState('gsheets')

    const [serviceName, setServiceName] = useState('')

    const [dataSourceUrl, setDataSourceUrl] = useState('')

    const [dataSource, setDataSource] = useState('')

    const handleClose = useCallback(() => {
        setShowBanner(false)
        setIsBannerShowing(false)
        sessionStorage.removeItem('isFromTemplateInstall')
    }, [setIsBannerShowing])

    const isUserAtDifferentApp =
        Boolean(sessionStorage.getItem('isFromTemplateInstall')) &&
        Boolean(selectedStack?._sid) &&
        sessionStorage.getItem('isFromTemplateInstall') !== selectedStack?._sid

    useEffect(() => {
        // If user go to a different app, close banner.
        if (isUserAtDifferentApp) {
            handleClose()
        }
        if (dataConnections && dataConnections.length) {
            const connection = dataConnections[0]
            const { type, options, service_name } = connection
            const provider = DATA_PROVIDERS[type]
            setProviderType(type)
            setServiceName(service_name)

            if (sessionStorage.getItem('isFromTemplateInstall')) {
                if (type === 'gsheets' && options) {
                    setDataSource(provider.name)
                    setDataSourceUrl(provider.getSourceLink(connection))

                    // Show banner if user came from installing template.
                    setShowBanner(true)
                    setIsBannerShowing(true)
                }
            }
        }
    }, [dataConnections, isUserAtDifferentApp, setIsBannerShowing, handleClose])

    return (
        <Flex
            display={showBanner ? 'flex' : 'none'}
            justifyContent="space-between"
            position="fixed"
            px={3}
            top={0}
            right={0}
            left={showingAppBar ? leftOffset : 0}
            height={BANNER_HEIGHT}
            wrap="nowrap"
            zIndex={100}
            color="white"
            bg="black"
        >
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ProviderIcon
                    type={providerType}
                    serviceName={serviceName}
                    style={{ width: '25px' }}
                />

                <StyledText style={{ marginLeft: '10px' }}>
                    &quot;{selectedStack?.name}&quot; was added to your {dataSource} account
                </StyledText>

                <Button
                    style={{
                        marginLeft: '10px',
                        backgroundColor: '#4A4F55',
                        height: '80%',
                        borderRadius: '5px',
                    }}
                    href={dataSourceUrl}
                >
                    <FontAwesomeIcon
                        icon={faExternalLink}
                        style={{ color: '#ADB0B5', fontSize: '13px' }}
                    />
                    <StyledText style={{ marginLeft: '10px' }}>Open</StyledText>
                </Button>
            </div>

            <Button
                buttonSize="sm"
                icon="x"
                variant="iconButton"
                flexShrink={0}
                px={3}
                py={2}
                color="gray.400"
                onClick={handleClose}
                {...buttonProps}
            ></Button>
        </Flex>
    )
}

export default withDataConnections(TemplateInstallSuccessBanner)

const StyledText = styled.span`
    color: white;
    font-size: 15px;
`
