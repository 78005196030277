import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type WarningProps = {
    backgroundColor?: string
    color?: string
    size?: number
}

const Warning: React.FC<WarningProps> = ({
    size = 36,
    color = colors.userInterface.warning[1000],
    backgroundColor = colors.userInterface.neutral[0],
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 36 36">
        <circle fill={backgroundColor} cx="50%" cy="50%" r={size} />
        <path
            d="M18 36C21.5601 36 25.0402 34.9443 28.0003 32.9665C30.9603 30.9886 33.2674 28.1774 34.6298 24.8883C35.9922 21.5992 36.3487 17.98 35.6541 14.4884C34.9596 10.9967 33.2453 7.78943 30.7279 5.27209C28.2106 2.75474 25.0033 1.04041 21.5116 0.345875C18.02 -0.34866 14.4008 0.00780106 11.1117 1.37018C7.82263 2.73256 5.01142 5.03966 3.03355 7.99974C1.05569 10.9598 3.8147e-06 14.4399 3.8147e-06 18C3.8147e-06 20.3638 0.465584 22.7044 1.37017 24.8883C2.27475 27.0722 3.60063 29.0565 5.27208 30.7279C6.94353 32.3994 8.92784 33.7252 11.1117 34.6298C13.2956 35.5344 15.6362 36 18 36ZM16.2 10.8C16.2 10.3226 16.3896 9.86478 16.7272 9.52721C17.0648 9.18965 17.5226 9.00001 18 9.00001C18.4774 9.00001 18.9352 9.18965 19.2728 9.52721C19.6104 9.86478 19.8 10.3226 19.8 10.8V19.8C19.8 20.2774 19.6104 20.7352 19.2728 21.0728C18.9352 21.4104 18.4774 21.6 18 21.6C17.5226 21.6 17.0648 21.4104 16.7272 21.0728C16.3896 20.7352 16.2 20.2774 16.2 19.8V10.8ZM18 23.4C18.356 23.4 18.704 23.5056 19 23.7034C19.296 23.9011 19.5267 24.1823 19.663 24.5112C19.7992 24.8401 19.8349 25.202 19.7654 25.5512C19.696 25.9003 19.5245 26.2211 19.2728 26.4728C19.0211 26.7245 18.7003 26.896 18.3512 26.9654C18.002 27.0349 17.6401 26.9992 17.3112 26.863C16.9823 26.7267 16.7011 26.496 16.5034 26.2C16.3056 25.904 16.2 25.556 16.2 25.2C16.2 24.7226 16.3896 24.2648 16.7272 23.9272C17.0648 23.5896 17.5226 23.4 18 23.4Z"
            fill={color}
        />
    </svg>
)

export default Warning
