import React from 'react'

import { BoxProps } from '@chakra-ui/react'

import { useDataConnection } from 'data/hooks/dataConnections'
import { useObject } from 'data/hooks/objects'

import { Box } from 'v2/ui'

import { ProviderIcon } from '../admin/data-connector/ProviderIcon'

type Props = BoxProps & {
    objectId: string
    iconSize?: string
}
const ObjectLabel: React.FC<Props> = ({ objectId, ...props }) => {
    const { object } = useObject(objectId)
    const { data: dc } = useDataConnection(object?.data_connection)
    if (!dc || !object) return null

    return (
        <Box display="inline" {...props}>
            <ProviderIcon
                type={dc.type}
                serviceName={dc.service_name}
                display="inline-flex"
                verticalAlign="middle"
                size="1.2em"
                mr={2}
            />
            <span style={{ verticalAlign: 'middle' }}>{object.name}</span>
        </Box>
    )
}

export default ObjectLabel
