import { UseQueryOptions, UseQueryResult } from 'react-query'

import { getCurrentStackId } from 'app/AppContextStore'
import { useAppUserContext } from 'app/AppUserContext'

import {
    queryClient,
    QueryOptions,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { invalidateAppErrors } from './appErrors'

const LIST_NAME = 'user-lists'
const ENDPOINT = 'user-lists/'

export function useUserLists(
    options: UseQueryOptions<UserListDto[]> = {},
    internalOptions: QueryOptions<UserListDto[]> = {}
): UseQueryResult<UserListDto[]> {
    const stackId = getCurrentStackId()
    const { isAdmin, isImpersonating } = useAppUserContext()

    return useQuery(
        [LIST_NAME, stackId as string],
        ENDPOINT,
        // We don't need user lists to load unless we're an admin
        { ...options, enabled: Boolean(isAdmin || isImpersonating) },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
            ...internalOptions,
        }
    )
}

export function useCreateUserList() {
    const stackId = getCurrentStackId() as string
    return useCreateItem([LIST_NAME, stackId], ENDPOINT)
}
export function useUpdateUserList() {
    const stackId = getCurrentStackId() as string
    return useUpdateItem<UserListDto>([LIST_NAME, stackId], ENDPOINT, {
        onSuccess: () => {
            invalidateAppErrors()
        },
    })
}

export function useDeleteUserList() {
    const stackId = getCurrentStackId() as string
    return useDeleteItem([LIST_NAME, stackId], ENDPOINT)
}

export function invalidateUserLists() {
    const stackId = getCurrentStackId()
    return queryClient.invalidateQueries([LIST_NAME, stackId])
}
