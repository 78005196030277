// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import { List, ListItem, Spinner } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { useConfirmModalWithPromise } from 'app/ConfirmModal'
import { createCheckoutSession, upgradePlan } from 'data/api/subscriptionApi'
import { PlanPickerOptions, resetPlanPicker, usePlanPickerOptions } from 'data/hooks/planPicker'
import { FeatureRequirementsMap, TIERS } from 'features/billing/types'
import { getAllowedPlansReason } from 'features/billing/utils'
import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { Box, Button, Flex, Text, Toast } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import PlanUpgradeModal from '../PlanUpgradeModal'
import { SubscriptionStatus } from '../types'

import { buildPlanCompModels, PlanCompModel } from './BuildPlanCompModels'
import { PlanPickerGrid } from './PlanPickerGrid'
import { BillingPeriods, CustomPlanPickerProps } from './types'

const { colors } = stackerTheme()
const initialBillingPlaceholder = 'year'

export const launchIntercom = () => {
    return (window as any).Intercom('showNewMessage')
}

export const reasonParts: FeatureRequirementsMap = {
    apps: (feature) => (feature.count > 1 ? `${feature.count} apps` : null),
    admins: (feature) => (feature.count > 1 ? `${feature.count} collaborators` : null),
    collaboration: (_) => 'Comments & Notifications enabled',
    multiple_permission_rules: (_) => 'Advanced Permissions (multiple permission rules)',
    multiple_roles: (_) => 'Roles enabled',
    kanban: (_) => 'Kanban Views',
    inbox_view: (_) => 'Inbox Views',
    global_custom_css: (_) => 'Custom CSS',
    custom_scripts: (_) => 'Custom Scripts',
    workspace_custom_domain: (_) => 'a Custom Domain',
    intercom_app_id: (_) => 'Intercom Integration',
    fullstory_org_id: (_) => 'FullStory Integration',
}

const CustomPlanPicker = ({ requiredTier }: CustomPlanPickerProps) => {
    const { data: planPickerOptions = {} as PlanPickerOptions } = usePlanPickerOptions({
        requiredTier,
    })
    const { plans: availablePlans = [], feature_usage: featureUsage } = planPickerOptions
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriods>(initialBillingPlaceholder)
    const [checkoutError, setCheckoutError] = useState<string | null>(null)
    const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null)
    const { workspaceAccount } = useAppContext()

    const openCheckoutSession = useCallback((priceId) => {
        setCheckoutError(null)
        return createCheckoutSession({
            successUrl: `${window.location.origin}${window.location.pathname}?__la=openBilling`,
            cancelUrl: `${window.location.origin}${window.location.pathname}?__la=openPlans`,
            priceId,
        })
            .then((res) => {
                if (res.checkout_url) {
                    window.location.assign(res.checkout_url)
                }
            })
            .catch((ex) => {
                console.error(ex)
                setCheckoutError('There was an error, please try again.')
            })
    }, [])

    const allowedPlansReason = useMemo(() => {
        return getAllowedPlansReason(reasonParts, featureUsage, Boolean(requiredTier))
    }, [featureUsage, requiredTier])

    /**
     * Confirm modal when users select plan
     */
    const showSelectPlanConfirmModal = useConfirmModalWithPromise({
        zIndex: 2800,
    })

    const subscriptionId = workspaceAccount?.stripe_subscription_id

    const selectedPlan = useCallback(
        async (
            tier: keyof typeof TIERS,
            priceId: string,
            subscription_status: SubscriptionStatus
        ) => {
            // Show the upgrade modal if:
            // 1. The user is already subscribed and has an active subscription
            // Go to the checkout session if:
            // 1. The user has no subscription
            // 2. The user has a cancelled subscription
            const upgradeOrCheckout = () =>
                subscriptionId && subscription_status !== 'cancelled'
                    ? setSelectedPriceId(priceId)
                    : openCheckoutSession(priceId)

            const isShowConfirmModal = featureUsage
                .filter((feature) => feature.is_skip_tier_restriction)
                .some((feature) => TIERS[feature.required_tier] > TIERS[tier])

            if (isShowConfirmModal) {
                const featuresSoonDisabled = Array.from(
                    new Set(featureUsage.filter((feature) => feature.is_skip_tier_restriction))
                )
                    .map((key) => reasonParts[key.name](key))
                    .map((reason) => reason?.replace('enabled', ''))

                return showSelectPlanConfirmModal({
                    title: `You selected the ${tier} plan.`,
                    message: (
                        <Text>
                            The features below are not included in this plan and will be disabled
                            when you continue :
                            <List styleType="disc" textAlign="left" mt={4}>
                                {featuresSoonDisabled.map((feature) => (
                                    <ListItem key={feature}>{feature}</ListItem>
                                ))}
                            </List>
                        </Text>
                    ),
                    onConfirm: upgradeOrCheckout,
                }).catch(() => {})
            } else {
                upgradeOrCheckout()
            }
        },
        [featureUsage, openCheckoutSession, showSelectPlanConfirmModal, subscriptionId]
    )

    const handledSelectedPlan = (plan: PlanCompModel) => {
        const { isEnterprisePlan, tier, priceId } = plan
        if (isEnterprisePlan) {
            launchIntercom()
        } else {
            selectedPlan(tier, priceId, workspaceAccount?.subscription_status)
        }
    }

    const toggleBillingMonthly = () => {
        setBillingPeriod('month')
    }
    const toggleBillingYearly = () => {
        setBillingPeriod('year')
    }

    const selectedPlanOptions = selectedPriceId
        ? availablePlans.find((plan) => plan.prices[billingPeriod].price_id === selectedPriceId)
        : null

    if (availablePlans.length < 1) {
        return (
            <Flex width="100%" height="100%" justify="center">
                <Spinner size="xl" />
            </Flex>
        )
    }
    const plans: PlanCompModel[] = buildPlanCompModels(
        availablePlans,
        billingPeriod,
        workspaceAccount?.subscription_status
    )

    return (
        <>
            <div style={{ marginBottom: '12px', textAlign: 'center' }}>
                <Box
                    display="inline-block"
                    bg="userInterface.neutral.400"
                    borderRadius="5px"
                    margin="0 auto"
                >
                    <Button
                        data-testId="plan-picker.monthly-toggle"
                        variant={billingPeriod === 'month' ? 'planPickerOn' : 'planPickerOff'}
                        buttonSize="large"
                        disabled={false}
                        aria-label="Monthly Billing"
                        onClick={toggleBillingMonthly}
                    >
                        Monthly
                    </Button>
                    <Button
                        data-testId="plan-picker.yearly-toggle"
                        variant={billingPeriod === 'year' ? 'planPickerOn' : 'planPickerOff'}
                        buttonSize="large"
                        disabled={false}
                        aria-label="Yearly Billing"
                        onClick={toggleBillingYearly}
                    >
                        Yearly
                    </Button>
                </Box>
            </div>
            <PlanPickerGrid plans={plans} handleSelectedPlan={handledSelectedPlan}></PlanPickerGrid>
            <PlanUpgradeModal
                userCount={planPickerOptions.users_count}
                tier={selectedPlanOptions?.tier}
                price={selectedPlanOptions?.prices[billingPeriod]}
                billingPeriod={billingPeriod}
                isOpen={!!selectedPriceId}
                onClose={() => setSelectedPriceId(null)}
                actions={[
                    {
                        label: 'Upgrade',
                        'aria-label': 'Upgrade',
                        'data-testId': 'plan-picker.confirm-upgrade',
                        variant: 'Primary',
                        width: '244px',
                        display: 'block',
                        margin: '0 auto',
                        onClick: () => {
                            setCheckoutError(null)
                            return (
                                selectedPriceId &&
                                upgradePlan(selectedPriceId)
                                    .then(() => {
                                        setSelectedPriceId(null)
                                        openWorkspaceSettingsModal({ page: 'billing' })
                                        resetPlanPicker()
                                    })
                                    .catch((ex) => {
                                        console.error(ex)
                                        setCheckoutError('There was an error, please try again.')
                                    })
                            )
                        },
                    },
                ]}
            />

            {allowedPlansReason && (
                <Text
                    paddingTop="10px"
                    color={colors.userInterface.accent[1000]}
                    textAlign="center"
                >
                    {allowedPlansReason}
                </Text>
            )}
            <Toast
                status="error"
                show={!!checkoutError}
                onCloseComplete={() => {
                    setCheckoutError(null)
                }}
                title={checkoutError}
            />
        </>
    )
}

export default CustomPlanPicker
