import { toast } from 'react-toastify'
/**
 *
 * @param {('error'|'info'|'success')} type - the type of toast to display. Default: info
 **/
export const makeToast = (id, message, type = 'info') => {
    if (!toast.isActive(id)) {
        toast[type](message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            toastId: id,
        })
    }
}
