import { toast } from 'react-toastify'

import store from 'app/store'
import { getUrl } from 'app/UrlService'
import { userActions } from 'data/api/userApi'
import { SignupApi } from 'features/auth/utils/index'
import { evaluateFormulas } from 'features/pages/blocks/settings/formulas'

import { getActionListFromContext } from './AvailableActions'

const { dispatch } = store

const evaluatePromiseSerial = (funcs) =>
    funcs.reduce(
        (promise, func) =>
            promise.then((result) => func().then(Array.prototype.concat.bind(result))),
        Promise.resolve([])
    )

function updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
        return uri.replace(re, `$1${key}=${value}$2`)
    }
    return `${uri + separator + key}=${value}`
}

const runBlockActions = ({ blockActionsType, block, context, history, fetchPages }) => {
    //
    const availableActions = getActionListFromContext(context)

    // disable events in the editor
    if (context.editor.isEditing) return

    // Support this for legacy reasons
    if (blockActionsType === 'onClickActions') blockActionsType = 'actions'

    const actions = block.config.attributes[blockActionsType] || []

    const promiseList = actions.map((action) => {
        if (action.type === 'page.action.redirect') {
            const location = evaluateFormulas(context)(action.location)
            return () =>
                new Promise((resolve) => {
                    if (location.indexOf('http') === -1) {
                        window.scrollTo(0, 0)
                        history.push(getUrl(location))
                    } else {
                        window.location = location
                    }
                    resolve()
                })
        }
        if (action.type === 'page.action.debug') {
            const message = evaluateFormulas(context)(action.message)
            return () => new Promise((resolve) => resolve(console.log(message)))
        } else if (action.type === 'page.action.toast') {
            const message = evaluateFormulas(context)(action.message)
            return () =>
                new Promise((resolve) =>
                    resolve(
                        toast.success(message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 1500,
                            hideProgressBar: true,
                        })
                    )
                )
        } else if (action.type === 'page.action.login') {
            const email = evaluateFormulas(context)(action.email)
            const password = evaluateFormulas(context)(action.password)
            return () =>
                new Promise((resolve) =>
                    userActions
                        // Need to dispatch it outselves as we're not using connect
                        .logInEndUser(
                            email,
                            password
                        )(dispatch)
                        .then((response) => {
                            const token = response.api_token
                            if (!token) {
                                return
                            }
                            fetchPages().then(() => resolve())
                        })
                )
        } else if (action.type === 'page.action.register') {
            const email = evaluateFormulas(context)(action.email)
            const password = evaluateFormulas(context)(action.password)
            const firstName = evaluateFormulas(context)(action.first_name)
            const lastName = evaluateFormulas(context)(action.last_name)
            return () =>
                new Promise((resolve) =>
                    SignupApi.registerEndUser(email, password, firstName, lastName)
                        .then((response) => {
                            const token = response.api_token
                            if (!token) {
                                return
                            }

                            localStorage.setItem('user_token', token)
                            localStorage.setItem('user', JSON.stringify(response))
                            resolve()
                        })
                        .catch((error) => {
                            console.log('[BLOCK ACTIONS] Register error', error)
                            if (error.detail === 'Incorrect authentication credentials.') {
                                const newLocation = updateQueryStringParameter(
                                    window.location.href,
                                    'error',
                                    'already_registered'
                                )
                                window.location = newLocation
                            }
                        })
                )
        } else if (action.type === 'page.action.web_request') {
            const url = evaluateFormulas(context)(action.url)
            const payload = evaluateFormulas(context)(action.payload)
            let isJson = false
            try {
                JSON.parse(payload)
                // payload = JSON.stringify(payload)
                isJson = true
            } catch (e) {
                // Clearly isn't JSON, try anyway
            }

            return () =>
                new Promise((resolve) =>
                    resolve(
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': isJson ? 'application/json' : 'text/plain',
                            },
                            body: payload,
                        })
                    )
                )
        } else {
            const matching = (availableActions || []).filter(
                (available) => available.id === action.type
            )

            if (matching.length) {
                const actionFunction = matching[0].action
                return () => actionFunction(evaluateFormulas(context)(action.value))
            } else return () => Promise.resolve()
        }
    })

    evaluatePromiseSerial(promiseList)
}

export default runBlockActions
