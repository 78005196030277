/* Code Quality: Not audited */

import React from 'react'

import icon from 'assets/images/icon.png'
import addRow from 'assets/images/icons/add-row.svg'
//#region imports
import api from 'assets/images/icons/api.svg'
import button from 'assets/images/icons/button.svg'
import code from 'assets/images/icons/code.svg'
import csv from 'assets/images/icons/csv.svg'
import _delete from 'assets/images/icons/delete.png'
import edit from 'assets/images/icons/edit.svg'
import editFile from 'assets/images/icons/edit-file.svg'
import email from 'assets/images/icons/email.svg'
import linkedRecords from 'assets/images/icons/linked-records.svg'
import menuData from 'assets/images/icons/menu-data.png'
import menuDesign from 'assets/images/icons/menu-design.png'
import menuFlow from 'assets/images/icons/menu-flow.png'
import _new from 'assets/images/icons/new.svg'
import ok from 'assets/images/icons/ok.png'
import pdf from 'assets/images/icons/pdf.png'
import searchProperty from 'assets/images/icons/search-property.svg'
import time from 'assets/images/icons/time.svg'
import userProfiles from 'assets/images/icons/user-profiles.svg'
import stackerLogo from 'assets/images/stacker-logo.png'
import cx from 'classnames'
import PropTypes from 'prop-types'

const Icons = {
    api,
    email,
    new: _new,
    edit,
    button,
    time,
    csv,
    'search-property': searchProperty,
    code,
    pdf,
    'edit-file': editFile,
    'add-row': addRow,
    'stacker-logo': stackerLogo,
    icon,
    ok,
    delete: _delete,
    menuDesign,
    menuFlow,
    menuData,
    'linked-records': linkedRecords,
    'user-profiles': userProfiles,
}
//#endregion

function Icon({ name, weight, ...otherProps }) {
    const icon = Icons[name]
    const weightDict = { light: 'l', medium: '', strong: 's' }
    const iconWeight = weightDict[weight] || ''

    if (icon) {
        const className = cx('icon', { clickable: otherProps.onClick })

        return <img src={icon} alt="icon" className={className} {...otherProps} />
    }

    const className = cx('fa' + iconWeight, 'fa-' + name)

    return <i className={className} />
}

Icon.propTypes = {
    name: PropTypes.oneOf(Object.keys(Icons)),
}
Icon.names = Object.keys(Icons)

export default Icon
