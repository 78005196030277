import { useEffect } from 'react'

import qs from 'qs'

import { openProfileSettingsModal } from 'features/profile/ProfileSettingsModal'
import {
    openPlanPickerModal,
    openWorkspaceSettingsModal,
} from 'features/utils/__hackyGlobalModalControls'

import { useAppSettings } from '../workspace/AdminSideTray/hooks/useAppSettings'
import { useOpenAppUsers } from '../workspace/AdminSideTray/hooks/useOpenAppUsers'

let startupAction = null
let actions = []

/**
 * Sets the action to execute when the observer component is mounted.
 *
 * This function allows for early detection of which load action should be
 * executed, separating the actual execution of the action from the detection.
 * Since we have various redirects on app startup this allows us to detect
 * which load action should be executed before the redirects drop the query
 * param and then wait until the app is loaded before executing it.
 */
export function consumeLoadAction() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (query.__la) {
        // remember the action name for later, we will run it via the LoadActionObserver
        startupAction = query.__la
        // remove the action name param from the URL
        const url = new URL(window.location)
        url.search = qs.stringify({ ...query, __la: undefined })
        window.history.replaceState(window.history.state, null, url.href)
    }
}

/**
 * Runs the load action and doesn't render anything.
 *
 * Render this deep in the tree so that all the necessary components for
 * executing the load action are ready. If any context or props are required
 * for some actions in the future, pass them through this component.
 */
function LoadActionObserver() {
    const openUserModalProxy = useOpenAppUsers()
    const { open: openAppSettings } = useAppSettings()

    // Setup functions here to define them as load actions that get
    // executed when the app is starting

    // when setting up existing functions assign them an alias so that we don't
    // break URLs when the internal function name changes
    //
    // avoid removing existing entries for the same reason, there might be URLs out
    // there that still use that action, implement a fallback if possible/necessary

    actions['openPlans'] = openPlanPickerModal
    actions['openProfileSettings'] = openProfileSettingsModal
    actions['openAppSettings'] = openAppSettings
    actions['openWorkspaceSettings'] = openWorkspaceSettingsModal
    actions['openBilling'] = () => openWorkspaceSettingsModal({ page: 'billing' })
    actions['openAppUsers'] = openUserModalProxy

    useEffect(() => {
        if (actions[startupAction]) {
            requestAnimationFrame(() => {
                actions[startupAction]()
            })
        }
    }, [])
    return null
}

export default LoadActionObserver
