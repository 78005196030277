import { useRef } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import useLDFlags from 'data/hooks/useLDFlags'

import analytics from './analytics'

export default function AnalyticsIdentifier() {
    const { user, studioUser } = useAppUserContext()
    const userId = user?._sid
    const identifiedUserId = useRef<string>()
    const { flags } = useLDFlags()

    if (
        userId &&
        userId !== identifiedUserId.current &&
        (studioUser || flags.enableEventTrackingForAuths)
    ) {
        identifiedUserId.current = userId
        analytics.identify(userId)
    }
    return null
}
