import React, { FC } from 'react'

import styled from '@emotion/styled'

import { openManageUserSettingsModal } from 'utils/modalOpeners'

import { Button, Flex } from 'v2/ui'

const Header = styled(Flex)`
    width: 100%;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
`

export const FiltersHeader: FC = () => (
    <Header>
        <Button
            variant="adminDefault"
            mb="6px"
            icon="cog"
            color="gray.400"
            fontSize="sm"
            onClick={openManageUserSettingsModal}
        >
            Advanced
        </Button>
    </Header>
)
