import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { useAppContext } from '../../../../../app/AppContext'
import { useObjectCache } from '../../../../../data/hooks/cacheInfo'
import { UpgradeFailureWithoutPermissionsModal } from '../UpgradeFailureWithoutPermissionsModal'
import { useOAuthFlow } from '../useOAuthFlow'
import { getReturnUrlForOAuthFlow } from '../utils/getReturnUrlForOAuthFlow'
import { useAirtableConnectionParams } from '../utils/useAirtableConnectionParams'

import { AirtableRepairErrorModal } from './AirtableRepairErrorModal'
import { AirtableRepairLoadingModal } from './AirtableRepairLoadingModal'
import { tryServerRepair } from './airtableRepairLogic'
import { AirtableRepairSuccessModal } from './AirtableRepairSuccessModal'
import { AirtableRepairFlowState } from './airtableRepairTypes'

export type UseRepairAirtableFlowResult = {
    modal: ReactElement
    onStart: () => void
    showModal: (modalState: AirtableRepairFlowState) => void
}

export const useRepairAirtableFlow = (
    dataConnection: DataConnectionDto,
    onClose = () => {}
): UseRepairAirtableFlowResult => {
    const [state, setState] = useState<AirtableRepairFlowState>('idle')
    const { selectedStack } = useAppContext()
    const { startCacheFill } = useObjectCache()
    const returnUrl =
        selectedStack && dataConnection
            ? getReturnUrlForOAuthFlow({ dataConnection, mode: 'repair', stack: selectedStack })
            : undefined
    const { onStart: onStartOAuthFlow, modal: oauthModal } = useOAuthFlow({
        returnUrl,
        dataConnectionSid: dataConnection?._sid,
        shouldOverwriteToken: true,
    })
    const {
        repairingConnection,
        dataConnectionSid: repairingDataConnectionSid,
        resetParams,
    } = useAirtableConnectionParams()

    const onStart = useCallback(() => {
        resetParams()
        setState('loading')
        tryServerRepair({ dataConnection, startCacheFill }).then((result) => {
            setState(result)
            if (result === 'invalid_token') {
                onStartOAuthFlow()
            }
        })
    }, [dataConnection, onStartOAuthFlow, resetParams, startCacheFill])

    const internalOnClose = useCallback(() => {
        setState('idle')
        onClose()
    }, [onClose])

    const showModal = useCallback(
        (modalState) => {
            resetParams()
            setState(modalState)
        },
        [resetParams]
    )

    const [startedFromUrlParam, setStartedFromUrlParam] = useState(false)
    useEffect(() => {
        if (
            !startedFromUrlParam &&
            dataConnection?._sid === repairingDataConnectionSid &&
            repairingConnection
        ) {
            onStart()
            setStartedFromUrlParam(true)
        }
    }, [
        dataConnection,
        onStart,
        repairingConnection,
        repairingDataConnectionSid,
        startedFromUrlParam,
    ])

    const modal: ReactElement =
        state === 'loading' ? (
            <AirtableRepairLoadingModal />
        ) : state === 'error' ? (
            <AirtableRepairErrorModal onClose={internalOnClose} />
        ) : state === 'valid' ? (
            <AirtableRepairSuccessModal onClose={internalOnClose} />
        ) : state === 'missing_permissions' ? (
            <UpgradeFailureWithoutPermissionsModal
                isOpen={true}
                onClose={internalOnClose}
                onTryAgain={onStart}
            />
        ) : state === 'invalid_token' ? (
            oauthModal
        ) : (
            <></>
        )

    return {
        modal,
        onStart,
        showModal,
    }
}
