import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import Color from 'color'
import PropTypes from 'prop-types'

import { isExternal } from 'utils/utils'

import STYLE_CLASSES from 'v2/ui/styleClasses'
import { withTheme } from 'v2/ui/theme/components/withTheme'

import Icon from './Icon'
import ScreenReaderText from './ScreenReaderText'

const BASE_CLASS = STYLE_CLASSES.BUTTON

const Button = (props) => {
    let backgroundColor = props.theme.buttonColor
    let fontColor = props.theme.buttonFontColor
    let borderColor = props.theme.buttonBorderColor

    if (props.color === 'success') {
        backgroundColor = props.theme.successColor
    }
    if (props.color === 'danger') {
        backgroundColor = props.theme.dangerColor
    }
    if (props.color === 'brand') {
        backgroundColor = props.theme.brandColor
    }
    if (backgroundColor !== props.theme.buttonColor) {
        // We'll somehow need to change this to something smarter to work
        // out if we need to show borders for white/light buttons or
        // change the font to white if necessary
        borderColor = backgroundColor
        fontColor = 'white'
    }
    if (props.color === 'clear') {
        backgroundColor = 'rgba(0,0,0,0)'
        borderColor = 'rgba(0,0,0,0)'
    }
    let hoverColor
    try {
        hoverColor = Color(backgroundColor).darken(0.1).string()
    } catch (e) {
        hoverColor = backgroundColor
    }
    let hoverFontColor
    if (props.color === 'success') {
        hoverFontColor = 'white'
    } else {
        hoverFontColor = '#999'
    }

    let fontSize
    let defaultPadding
    switch (props.size) {
        case 'small':
            fontSize = 'fontS'
            defaultPadding = 'small'
            break
        case 'large':
            fontSize = 'fontL'
            defaultPadding = 'large'
            break
        default:
            fontSize = 'fontM'
            defaultPadding = 'medium'
    }

    const newProps = { ...props }

    //allows us to have an anchor, internal link or button styled as a button
    const getElementType = () => {
        if (props.isAnchor) {
            if (isExternal(props.href)) {
                return 'a'
            }
            newProps.to = props.href
            return Link
        }
        return 'button'
    }

    const Button = styled(getElementType())`
        cursor: ${(props) => (props.isDisabled ? 'unset' : 'pointer')};
        user-select: none;
        tab-index: 0;

        display: ${(props) => (props.display ? props.display : 'inline-block')};
        text-align: center;

        font-family: ${(props) => props.theme.buttonFontFamily || props.theme.fontFamily};
        font-size: ${(props) => props.theme.buttonFontSize || props.theme[fontSize]};
        color: ${fontColor};

        background-color: ${backgroundColor};
        border-radius: ${(props) => (props.round ? '100%' : props.theme.buttonBorderRadius)};
        border: ${(props) => props.theme.buttonBorderThickness} solid ${borderColor};

        padding: ${(props) => props.theme[props.padding || defaultPadding]};
        text-transform: ${(props) => props.theme.buttonTextTransform || 'none'};
        letter-spacing: ${(props) => props.theme.buttonLetterSpacing || '0px'};
        margin: ${(props) => props.theme[props.margin || 'small']}
            ${(props) => (props.sideMargin ? props.theme[props.sideMargin] : '0px')};

        width: ${(props) => (props.width ? props.width : 'unset')};
        ${(props) =>
            props.isDisabled &&
            `
            opacity: 0.5;`} ${(props) =>
            props.round &&
            `
        height: 30px;
        width: 30px;
        padding: 6px 9px;
        font-size: 17px;
        `} font-weight: bold;

        &:active {
            background-color: ${hoverColor};
            color: ${hoverFontColor};
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        ${(props) =>
            props.noRadius &&
            `
            border-radius: 0px;
            `};

        &:hover {
            color: ${(props) => props.theme.buttonHoverFontColor};
            background-color: ${(props) => props.theme.buttonHoverColor};
            border-color: ${(props) => props.theme.buttonHoverBorderColor};
        }
    `
    let label = props.children || ''

    if (props.icon) {
        label = (
            <>
                <Icon margin="none" icon={props.icon} weight={props.iconWeight} />
                {label && <span style={{ width: 8, display: 'inline-block' }} />}
                {label}
            </>
        )
    }

    return (
        <Button
            role="button"
            className={BASE_CLASS}
            tabIndex={props.tabIndex ? props.tabIndex : 0}
            {...newProps}
            onClick={!props.isDisabled && props.onClick}
        >
            {label}
            {props.screenReaderText && (
                <ScreenReaderText>{props.screenReaderText}</ScreenReaderText>
            )}
        </Button>
    )
}
Button.displayName = 'Button'

Button.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['primary', 'success', 'brand', 'danger', 'clear']),
}

export default withTheme(Button)
