import React, { useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { importUrl } from 'data/api/bundlesApi'
import ImportUpload from 'features/bundles/ImportUpload'
import { Divider, ExpandSection } from 'legacy/v1/ui'

import { Box, Button, Modal, Text } from 'v2/ui'
import { Alert } from 'v2/ui/svgs'

import TextArea from '../../../v2/ui/components/Textarea'
import ExportFeatureModal from '../Bundles/ExportFeatureModal'
import ExportTemplateModal from '../Bundles/ExportTemplateModal'

export const BundlesSetting = ({ selectedStack }: StackDto) => {
    const { workspaceAccount } = useAppContext()
    const [exportFeatureModalOpen, setExportFeatureModalOpen] = useState<boolean>(false)
    const [importCompletedModalOpen, setIsImportCompletedModalOpen] = useState<boolean>(false)
    const [exportTemplateModalOpen, setExportTemplateModalOpen] = useState<boolean>(false)
    const [featureVariables, setFeatureVariables] = useState<string>('')
    return (
        <>
            {/* @ts-expect-error */}
            <ExpandSection
                always
                // @ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Export your App as a Template
                    </div>
                }
                text={null}
            >
                <Text fontSize={16} my={2} py={2}>
                    Export your application configuration as a Template. This will create a .json
                    file.
                </Text>
                <Button
                    marginRight="auto"
                    variant="Primary"
                    buttonSize="small"
                    onClick={() => {
                        setExportTemplateModalOpen(true)
                    }}
                >
                    Export Template
                </Button>
            </ExpandSection>
            {/* @ts-expect-error */}
            <Divider margin="none" />

            {/* @ts-expect-error */}
            <ExpandSection
                always
                // @ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Export your App as a Feature
                    </div>
                }
                text={null}
            >
                <Text fontSize={16} my={2} py={2}>
                    Export your application configuration as a Feature. This will create a .json
                    file.
                </Text>
                <Button
                    marginRight="auto"
                    variant="Primary"
                    buttonSize="small"
                    onClick={() => {
                        setExportFeatureModalOpen(true)
                    }}
                >
                    Export Feature
                </Button>
            </ExpandSection>
            {/* @ts-expect-error */}
            <Divider margin="none" />

            {/* @ts-expect-error */}
            <ExpandSection
                mb="10px"
                always
                // @ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>Import a Feature</div>
                }
                text={null}
            >
                <Text fontSize={16} my={2} py={2}>
                    Import a .json app file and add a feature to your app. You can import an app
                    from your workspace settings.
                </Text>
                <TextArea
                    value={featureVariables}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFeatureVariables(e.target.value)
                    }}
                    mt="5px"
                    mb="10px"
                    width="400px"
                    height="120px"
                    placeholder="Put your variables as JSON object (optional)"
                    border={`1px solid neutral.500`}
                    borderRadius={5}
                />
                <ImportUpload
                    onSuccess={(value: { filesUploaded: any[]; filesFailed: any[] }) => {
                        importUrl(
                            value.filesUploaded[0].url,
                            workspaceAccount?._sid,
                            selectedStack._sid,
                            featureVariables ? JSON.parse(featureVariables) : {}
                        ).then(() => setIsImportCompletedModalOpen(true))
                    }}
                />
            </ExpandSection>
            <ExportTemplateModal
                isOpen={exportTemplateModalOpen}
                onClose={() => {
                    setExportTemplateModalOpen(false)
                }}
            />
            <ExportFeatureModal
                isOpen={exportFeatureModalOpen}
                onClose={() => {
                    setExportFeatureModalOpen(false)
                }}
            />
            <ImportCompletedModal
                isOpen={importCompletedModalOpen}
                onClose={() => {
                    setIsImportCompletedModalOpen(false)
                }}
            />
        </>
    )
}

const ImportCompletedModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            noPadding
            showCloseButton={false}
            size={'300px'}
            body={
                <Box mb="15px">
                    <Alert />
                    <Text variant="modalTitle" mt={6} fontWeight="bold">
                        Feature Import Completed
                    </Text>
                    <Text mt={4}>
                        Your feature has been imported successfully! Start customizing it now...
                    </Text>
                    <Button
                        variant="Primary"
                        buttonSize="extraLarge"
                        mt={4}
                        onClick={onClose}
                        width="100%"
                    >
                        Continue
                    </Button>
                </Box>
            }
        />
    )
}
