// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ObjectCard } from 'features/admin/onboarding/OnboardingTablesPage'

import { Button, Flex, Heading, Input, Modal, ScrollBox, Text } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

type OnboardingTablesToggleProps = {
    onFinish: (values: { [key: string]: boolean }) => void
    stack: any
    setDisabledTables: (values: { [key: string]: boolean }) => void
    disabledTables: { [key: string]: boolean }
    objects: any[] | undefined
    selectedUserTableId: string | undefined
}
const ActionsContainer = styled(Flex)`
    flex-wrap: unset;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 18px;
    @media (max-width: 876px) {
        flex-direction: column;
        align-items: stretch;
    }
`

const ButtonContainer = styled(Flex)`
    flex-wrap: unset;
    justify-content: space-between;

    @media (max-width: 876px) {
        justify-content: space-between;
        margin-top: 12px;
    }
`
export const OnboardingTablesToggle: React.FC<OnboardingTablesToggleProps> = ({
    objects = [],
    ...props
}: OnboardingTablesToggleProps) => {
    const { setDisabledTables, disabledTables } = props
    const [search, setSearch] = React.useState('')
    const stackerUsersTableId = props.stack.options.data_mapping.user_object
    const isMobile = useIsMobile()

    const isSearchInName = (name: string, search: string) => {
        return name.toLowerCase().includes(search.toLowerCase())
    }

    const resetAllTo = (objects: any, resetTo: boolean, search?: string) => {
        const obj: { [key: string]: boolean } = { ...disabledTables }
        for (const value of Object.values<{ _sid: string; name: string }>(objects)) {
            if (value._sid === props.selectedUserTableId) {
                continue
            }
            if (search) {
                if (isSearchInName(value.name, search)) {
                    obj[value._sid] = resetTo
                } else {
                    obj[value._sid] = disabledTables[value._sid]
                }
            } else {
                obj[value._sid] = resetTo
            }
        }
        setDisabledTables(obj)
    }

    return (
        <Modal
            size={'500px'}
            height={'500px'}
            isSecondLayer={true}
            noDividers={true}
            isOpen={true}
            showCloseButton={false}
            padding={'40px'}
        >
            <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
                <Heading variant="modal" value={'Tables'} />
                <Text variant="modal">Choose the tables to use in your app</Text>
            </Flex>
            <ActionsContainer>
                <div style={{ position: 'relative' }}>
                    <Input
                        style={{ paddingLeft: 34, height: 36 }}
                        placeholder={'Search'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <FontAwesomeIcon
                        // @ts-ignore
                        icon={faSearch}
                        style={{
                            zIndex: 1,
                            color: '#ced0d7',
                            fontSize: 18,
                            position: 'absolute',
                            left: 10,
                            top: 8,
                        }}
                    />
                </div>
                <ButtonContainer>
                    <Button
                        style={{
                            flexGrow: 1,
                            marginRight: 8,
                            marginLeft: isMobile ? 0 : 8,
                        }}
                        variant="adminSecondaryV4"
                        onClick={() => {
                            resetAllTo(objects, false, search)
                        }}
                    >
                        Enable all
                    </Button>
                    <Button
                        style={{ flexGrow: 1 }}
                        variant="adminSecondaryV4"
                        onClick={() => {
                            resetAllTo(objects, true, search)
                        }}
                    >
                        Disable all
                    </Button>
                </ButtonContainer>
            </ActionsContainer>
            {/* @ts-ignore */}
            <ScrollBox style={{ height: 320 }}>
                {Object.keys(objects).map((key) => {
                    // Don't show tables that don't match the search value (if any)
                    // and don't show the Stacker Users table.
                    if (
                        (search.length !== 0 && !isSearchInName(objects[key].name, search)) ||
                        objects[key]._sid === stackerUsersTableId
                    ) {
                        return null
                    }
                    return (
                        <ObjectCard
                            key={key}
                            // @ts-ignore
                            margin={'0px 0px 10px 0px'}
                            isUserTable={props.selectedUserTableId === objects[key]._sid}
                            object={objects[key]}
                            // @ts-ignore
                            tablesDisabled={disabledTables}
                            onClick={() => {
                                const objectSid = objects[key]._sid
                                disabledTables[objectSid] = !disabledTables[objectSid]
                                setDisabledTables({ ...disabledTables })
                            }}
                        />
                    )
                })}
            </ScrollBox>
            <Button
                style={{ marginTop: 24 }}
                buttonSize="md"
                variant="adminPrimaryV4"
                onClick={() => props.onFinish(disabledTables)}
            >
                Finish
            </Button>
        </Modal>
    )
}
