export const METRIC_BLOCK_HEIGHT = 149
export const MAX_NUM_METRICS = 4

export const NEW_METRIC_ATTRIBUTES: Omit<MetricsBlockAttributes, 'order'> = {
    title: '',
    objectId: '',
    aggregate: {
        type: 'count',
    },
    display: {
        label: '',
        decimalPlaces: 'max2',
        abbreviation: 'none',
    },
    filters: [],
    showRelated: false,
    relatedField: {
        label: '',
        value: '',
        objectId: '',
        fieldId: '',
        isExternalField: false,
    },
    hideTitle: false,
}
