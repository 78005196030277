/* Code Quality: Not audited */

import React, { useCallback, useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import { useCreateRole, useDeleteRole, useRoles, useUpdateRole } from 'data/hooks/roles'

function WithRoles({ children }) {
    const { selectedStack } = useContext(AppContext)
    const { data } = useRoles()
    const { mutateAsync: updateRole } = useUpdateRole()
    const { mutateAsync: createRole } = useCreateRole()
    const { mutateAsync: removeRole } = useDeleteRole()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roles = data || []
    // don't show internal roles if we're not in a workspace app
    const filteredData = useMemo(
        () =>
            roles.filter(
                (item) =>
                    item.stack_id === selectedStack?._sid &&
                    (selectedStack.options?.workspace_app || item.options?.type !== 'internal')
            ),
        [roles, selectedStack]
    )

    return children({
        roles: filteredData,
        roleActions: undefined,
        onChangeRole: updateRole,
        createRole,
        removeRole,
    })
}

export default WithRoles

export const withRoles = (Child) => (props) => {
    const fn = useCallback((roleProps) => <Child {...roleProps} {...props} />, [props])
    return <WithRoles>{fn}</WithRoles>
}
