// @ts-strict-ignore
import React, { useEffect, useMemo, useState } from 'react'
import { MoveFocusInside } from 'react-focus-lock'

import { createRecord } from 'data/hooks/records/recordOperations'

import { ConditionalWrapper, Flex } from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'
import { SimpleModalCompat as Modal } from 'v2/ui/components/SimpleModal'

type Props = {
    object: ObjectDto
    isOpen: boolean
    onClose: (record?: RecordDto) => void
}
const MODAL_BODY_STYLE: React.CSSProperties = {
    padding: '2px',
    textAlign: 'left',
}
export const NewRecordModal: React.VFC<Props> = ({ object, isOpen, onClose }) => {
    const [record, setRecord] = useState({ object_id: object._sid })
    const [error, setError] = useState<string | undefined>()
    const editableFields = useMemo(
        () =>
            object?.fields?.filter(
                (field) =>
                    field &&
                    !field.connection_options?.read_only &&
                    !field.connection_options?.is_disabled
            ) || [],
        [object?.fields]
    )
    useEffect(() => {
        if (isOpen) {
            setRecord({ object_id: object?._sid })
        }
    }, [isOpen, object?._sid])
    const handleSetValue = (field: string, value: any) => {
        setRecord((record) => ({ ...record, [field]: value }))
    }
    const handleSetValid = () => {}

    const handleSave = () => {
        setError(undefined)
        return createRecord(record)
            .then((createdRecord) => onClose(createdRecord as RecordDto))
            .catch(() => setError('There was an error adding this record. Please try again'))
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            title="New Record"
            size="600px"
            height="auto"
            style={{ maxHeight: '80%' }}
            showCloseButton
            isSecondLayer={true}
            errorMessage={error}
            actions={[
                {
                    label: 'Save',
                    variant: 'Primary',
                    icon: 'check',
                    buttonSize: 'sm',
                    onClick: handleSave,
                },
            ]}
            bodyStyle={MODAL_BODY_STYLE}
            // @ts-ignore
            body={
                <Flex column width="100%" height="100%" alignItems="stretch">
                    {editableFields.map((field, idx) => (
                        <ConditionalWrapper
                            key={field._sid}
                            condition={idx === 0}
                            wrapper={(children) => <MoveFocusInside>{children}</MoveFocusInside>}
                        >
                            <AttributeDisplay
                                isCreate
                                isInlineCreate
                                key={field._sid}
                                objectId={object?._sid}
                                fieldId={field._sid}
                                record={record}
                                fullWidth
                                editing
                                showErrors
                                setValue={handleSetValue}
                                setValid={handleSetValid}
                            />
                        </ConditionalWrapper>
                    ))}
                </Flex>
            }
        />
    )
}

export default NewRecordModal
