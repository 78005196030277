/* Code Quality: Not audited */

import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'

import types from './types'

const blockSource = {
    beginDrag(props) {
        // Return the data describing the dragged item
        const payload = props.payload
        return payload
    },

    endDrag(props, monitor) {
        if (!monitor.didDrop()) {
            return
        }

        // When dropped on a compatible target, do something
        const item = monitor.getItem()
        const dropResult = monitor.getDropResult()
        console.log('DROP', item, props.payload, dropResult)
        props.onDrop &&
            dropResult &&
            props.onDrop({ dragPayload: props.payload, dropPayload: dropResult })
    },

    canDrag: function (props) {
        return !props.disabled
    },
}

const blockTarget = {
    drop(props, monitor) {
        if (monitor.didDrop()) return
        const payload = props.payload
        return payload
    },

    hover(props, monitor, component) {
        if (!monitor.isOver({ shallow: true })) return

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleHeight = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const hoverMiddleY = hoverBoundingRect.top + hoverMiddleHeight

        // Get horizontal middle
        const hoverMiddleWidth = (hoverBoundingRect.left - hoverBoundingRect.right) / 2
        const hoverMiddleX = hoverBoundingRect.right + hoverMiddleWidth

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        if (hoverMiddleY < clientOffset.y) {
            // console.log("lower", props.payload.id)
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.remove('topHover')
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.add('bottomHover')
            monitor.getItem().dropYPosition = 'bottom'
        } else {
            // console.log("upper", props.payload.id)
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.add('topHover')
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.remove('bottomHover')
            monitor.getItem().dropYPosition = 'top'
        }

        if (hoverMiddleX > clientOffset.x) {
            // console.log("left", props.payload.id)
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.add('leftHover')
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.remove('rightHover')
            monitor.getItem().dropXPosition = 'left'
        } else {
            // console.log("right", props.payload.id)
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.remove('leftHover')
            // eslint-disable-next-line react/no-find-dom-node
            findDOMNode(component).classList.add('rightHover')
            monitor.getItem().dropXPosition = 'right'
        }
    },
}

/**
 * Specifies which props to inject into your component.
 */
function collectDrag(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        // You can ask the monitor about the current drag state:
        isDragging: monitor.isDragging(),
    }
}

/**
 * Specifies which props to inject into your component.
 */
function collectDrop(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver({ shallow: true }),
    }
}

class DraggableAndDroppable extends Component {
    // These two props are injected by React DnD,
    // as defined by your `collect` function above:

    render() {
        const { isDragging, connectDragSource, connectDropTarget, isOver, disabled, dropPosition } =
            this.props
        // connectDragPreview(img)
        const payload = {
            isDragging: isDragging,
            isOver: isOver,
            dropPosition,
        }

        let children = this.props.children(payload)
        children = typeof children === 'function' ? <div>Hello</div> : children
        if (disabled) return children
        if (this.props.disableDrag) return connectDropTarget(children)
        return connectDropTarget(connectDragSource(children))
    }
}

export default DropTarget(
    types.BLOCK,
    blockTarget,
    collectDrop
)(DragSource(types.BLOCK, blockSource, collectDrag)(DraggableAndDroppable))
