import React, { useCallback, useMemo } from 'react'

import FieldsEditor from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditor'

import { useObject } from 'data/hooks/objects'

import { Box } from 'v2/ui'

const getItems = (columns: ListViewColumnConfig[] = []) => {
    return columns.filter((x) => x.selected)
}

type FieldSelectorProps = {
    objectId: string
    config?: ListViewColumnConfig[]
    onChange?: (newColumns: ListViewColumnConfig[]) => void
    maxItemsSelected?: number
    display?: string
    editorProps?: Partial<React.ComponentPropsWithoutRef<typeof FieldsEditor>>
    animate?: boolean
}

const FieldSelector: React.FC<FieldSelectorProps> = ({
    objectId,
    config,
    onChange,
    maxItemsSelected,
    display = 'table',
    editorProps,
    animate = true,
}) => {
    const { object } = useObject(objectId)

    const items = useMemo(() => getItems(config), [config])

    const handleUpdate = useCallback(
        (items: ListViewColumnConfig[]) => {
            // Send back the array of selected fields. We're including the selected: true flag for
            // backwards compatibility with the old editor, in case we have to revert.
            onChange?.(
                items.map((x) => ({
                    ...x,
                    selected: true,
                    fieldApiName: object?.fields.find((field) => field._sid === x.fieldId)
                        ?.api_name,
                }))
            )
        },
        [object?.fields, onChange]
    )

    if (!object) return null

    return (
        <Box position="relative" flexGrow={1} mt={2} height={animate ? '100%' : undefined}>
            <FieldsEditor
                object={object}
                items={items}
                setItems={
                    handleUpdate as React.ComponentPropsWithoutRef<typeof FieldsEditor>['setItems']
                }
                maxItemsSelected={maxItemsSelected}
                hideFieldRequiredSetting
                hideFieldDescriptionSetting
                hideFieldFullWidthSetting
                hideCreateButtonSetting
                showAllFieldsModeSetting
                disallowSections
                display={display}
                includePrimaryFieldInAllFieldsMode
                animate={animate}
                {...editorProps}
            />
        </Box>
    )
}

export default FieldSelector
