import { useHistory } from 'react-router-dom'

import { useNavigation } from 'data/hooks/navigation'
import { usePages } from 'data/hooks/pages'
import { useViews } from 'data/hooks/views'

import { getBreadcrumbItems } from './breadcrumbsUtils'

export function useBreadcrumbItems(): ReturnType<typeof getBreadcrumbItems> {
    const { data: navigation = [] } = useNavigation()
    const { data: pages = [] } = usePages()
    const { data: views = [] } = useViews()
    const history = useHistory()

    return getBreadcrumbItems(navigation as NavigationDto[], pages as PageDto[], views, history)
}
