import React from 'react'

export const LinkFormModePicker = ({ showDelimiter, modeInput, delimiterInput }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 10,
                }}
            >
                {modeInput}
            </div>
            <div
                style={{
                    display: 'flex',
                    opacity: showDelimiter ? 1 : 0,
                    alignItems: 'center',
                }}
            >
                <div style={{ marginRight: 5 }}>with delimiter</div>
                {delimiterInput}
            </div>
        </div>
    )
}
