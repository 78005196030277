// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'

import { useJobPoller } from 'data/api/jobApi'

import { Button, Flex } from 'v2/ui'

import {
    errorStep,
    importCompleteStep,
    recordsCreatedStep,
    recordsImportStartedStep,
    StepStatus,
    StepType,
    tableCreatedStep,
    tableImportStartedStep,
} from './importSteps'
import Step from './Step'

type ImportProgressUpdateProps = {
    jobId: string
    onComplete?: (objectId: string | null, errorDetails: any) => void
    onError?: (errorDetails?) => void
}

const ImportProgressUpdate = ({ jobId, onComplete, onError }: ImportProgressUpdateProps) => {
    const [steps, setSteps] = useState({
        [StepType.STEP_TABLE_CREATION]: tableImportStartedStep,
    })

    const [completed, setCompleted] = useState(false)
    const [errored, setErrored] = useState(false)
    const [errorDetails, setErrorDetails] = useState()
    const [objectSid, setObjectSid] = useState(null)

    const updateProgress = (job) => {
        const {
            job_info: { step, counts, error, error_details, object_sid },
        } = job

        setObjectSid(object_sid)

        if (step === StepType.STEP_TABLE_CREATION) {
            updateStep(tableImportStartedStep)
        } else if (step === StepType.STEP_RECORD_CREATION) {
            updateStep(tableCreatedStep)
            updateStep(
                recordsImportStartedStep(`Importing records: ${counts?.succeeded || 0} imported`)
            )
        } else if (step === StepType.STEP_COMPLETE) {
            handleImportComplete(counts)
        } else if (step === StepType.STEP_STATUS_ERROR) {
            setErrorDetails(error_details)
            handleImportError(error, error_details)
        }

        return job.jobId
    }

    const pollState = useJobPoller(jobId, updateProgress, 0)

    const updateStep = useCallback(
        (step) => {
            if (step.type === StepType.STEP_STATUS_ERROR) {
                const finishSteps = (steps) => {
                    Object.keys(steps).map((stepKey) => {
                        let step = steps[stepKey]
                        if (step.status === StepStatus.STATUS_STARTED)
                            step.status = StepStatus.STATUS_ERROR
                        return step
                    })

                    return steps
                }

                setSteps({ ...finishSteps(steps), [step.type]: step })
            } else {
                setSteps((steps) => ({ ...steps, [step.type]: step }))
            }
        },
        [steps]
    )

    const handleImportComplete = useCallback(
        (records) => {
            updateStep(tableCreatedStep)
            updateStep(recordsCreatedStep(`${records?.succeeded} records imported`))
            updateStep(importCompleteStep)
            setCompleted(true)
            setErrored(false)
        },
        [updateStep]
    )

    const handleImportError = useCallback(
        (error, errorDetails) => {
            updateStep(errorStep(errorDetails))

            if (onError) onError(errorDetails)
            else console.error(error, errorDetails)

            setCompleted(false)
            setErrored(true)
        },
        [updateStep, onError]
    )

    useEffect(() => {
        if (pollState.isRunning || !pollState.isComplete) {
            return
        }

        if (pollState.isComplete && !pollState.isSuccessful) {
            onError?.()
        }

        updateProgress(pollState.job)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollState])

    return (
        <>
            {Object.keys(steps).map((key) => (
                <Step key={key} step={steps[key]} />
            ))}

            {(completed || errored) && (
                <Flex flexDirection="row" justifyContent="right">
                    <Button
                        variant="Primary"
                        p={2}
                        marginTop={5}
                        width="100%"
                        buttonSize="medium"
                        onClick={() => onComplete?.(objectSid, errorDetails)}
                        data-testid="import-progress.done-button"
                    >
                        Done
                    </Button>
                </Flex>
            )}
        </>
    )
}

export default ImportProgressUpdate
