import get from 'lodash/get'

import store from 'app/store'

const getCurrentUser = () => {
    const state = store.getState()
    return get(state, 'user.user')
}

export default getCurrentUser
