import React from 'react'

import Icon from './Icon'
import Link from './Link'
const HelpIcon = ({ link, size = 'sm', color = 'gray.300', ...props }) => {
    return (
        <Link simplifiedComponent href={link}>
            <Icon icon="questionCircle" size={size} color={color} {...props} />
        </Link>
    )
}
export default HelpIcon
