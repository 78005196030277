import React, { VFC } from 'react'

import styled from '@emotion/styled'

import { Button, Text } from 'v2/ui'
import { IllustrationConnectivity } from 'v2/ui/svgs'

const Wrapper = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`

const StyledButton = styled(Button)`
    width: 200px;
    height: 35px;
`

type Props = {
    showSettings: () => void
    showFields: () => void
}

const ErrorState: VFC<Props> = ({ showSettings, showFields }) => (
    <Wrapper>
        <IllustrationConnectivity />
        <Text fontWeight="bold">Your records could not be loaded.</Text>
        <ButtonsWrapper>
            <StyledButton variant="Secondary" size="sm" onClick={showSettings}>
                Check table settings
            </StyledButton>
            <StyledButton variant="Primary" size="sm" onClick={showFields}>
                Manage fields
            </StyledButton>
        </ButtonsWrapper>
    </Wrapper>
)

export default ErrorState
