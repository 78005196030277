import React, { useMemo } from 'react'

import { ActionList } from 'legacy/v1/ui'

import { ActionListItem } from 'v2/ui/components/ActionList'

import { ViewCellBaseProps } from './types'

type RecordActionsTableCellProps = ViewCellBaseProps & {
    getRecordActions: (record: RecordDto) => ActionListItem[]
}

export const RecordActionsTableCell: React.FC<RecordActionsTableCellProps> = ({
    cell,
    getRecordActions,
}) => {
    const actions = useMemo(
        () => getRecordActions(cell.row.original),
        [cell.row.original, getRecordActions]
    )
    if (!actions || !actions.length) return null

    return (
        <div
            style={{
                color: '#ccc',
                paddingTop: 10,
            }}
        >
            {/* @ts-expect-error */}
            <ActionList
                icon="ellipsis-v"
                // accessibilityLabel="More Actions"
                buttonStyle={{
                    fontWeight: 'normal',
                    padding: '8px',
                    fontSize: '0.9em',
                }}
                actions={actions}
            />
        </div>
    )
}
