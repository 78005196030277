import {
    faAddressBook,
    faAddressCard,
    faAlicorn,
    faAnalytics,
    faArchive,
    faAt,
    faBackpack,
    faBell,
    faBook,
    faBookDead,
    faBookHeart,
    faBox,
    faBracketsCurly,
    faBriefcase,
    faBrowser,
    faBug,
    faBuilding,
    faBullseyeArrow,
    faCalendarAlt,
    faCalendarCheck,
    faCameraRetro,
    faCartArrowDown,
    faCartPlus,
    faCashRegister,
    faCassetteTape,
    faChartBar,
    faChartLine,
    faChartPie,
    faChessKing,
    faChessQueen,
    faCity,
    faClarinet,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClipboardListCheck,
    faClipboardPrescription,
    faClipboardUser,
    faClock,
    faCocktail,
    faCoffee,
    faCog,
    faCoins,
    faCommentAltCheck,
    faCommentAltExclamation,
    faCommentAltSmile,
    faCommentCheck,
    faComputerClassic,
    faCrow,
    faCubes,
    faDatabase,
    faDeer,
    faDesktop,
    faDiceSix,
    faDigging,
    faDiploma,
    faDog,
    faDollarSign,
    faDolly,
    faDoNotEnter,
    faDrone,
    faDuck,
    faEdit,
    faEgg,
    faElephant,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faFarm,
    faFileCertificate,
    faFileCheck,
    faFileDownload,
    faFileImport,
    faFileSearch,
    faFileSignature,
    faFilm,
    faFlag,
    faFlask,
    faFolderTree,
    faForklift,
    faFrenchFries,
    faFunction,
    faFunnelDollar,
    faFutbol,
    faGiftCard,
    faHammer,
    faHandHoldingHeart,
    faHandHoldingMedical,
    faHandHoldingSeedling,
    faHandHoldingUsd,
    faHeadset,
    faHeadSide,
    faHeadSideBrain,
    faHome,
    faHorseSaddle,
    faHouseUser,
    faIdCard,
    faImages,
    faInbox,
    faInboxIn,
    faInboxOut,
    faInfoSquare,
    faLandmark,
    faLaptop,
    faLaptopCode,
    faLayerGroup,
    faLeaf,
    faLightbulb,
    faLock,
    faLockAlt,
    faMagic,
    faMagnet,
    faMailbox,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMapSigns,
    faMeat,
    faMedal,
    faMegaphone,
    faMicrophoneAlt,
    faMobile,
    faMobileAlt,
    faMonkey,
    faMountain,
    faMountains,
    faMousePointer,
    faMugTea,
    faMusic,
    faOtter,
    faPaintBrushAlt,
    faPaperclip,
    faParachuteBox,
    faParagraph,
    faPaw,
    faPeopleCarry,
    faPersonSign,
    faPhoneOffice,
    faPianoKeyboard,
    faPlane,
    faPlay,
    faPlayCircle,
    faPoll,
    faPollPeople,
    faPoo,
    faPrayingHands,
    faQuidditch,
    faQuoteLeft,
    faRadiation,
    faRam,
    faRecycle,
    faRedoAlt,
    faRingsWedding,
    faRoad,
    faRobot,
    faRocketLaunch,
    faRouter,
    faRss,
    faRunning,
    faSackDollar,
    faSandwich,
    faScroll,
    faServer,
    faShapes,
    faShare,
    faShareAlt,
    faSheep,
    faShieldCheck,
    faSitemap,
    faSkull,
    faSkullCow,
    faSkullCrossbones,
    faSmilePlus,
    faSmileWink,
    faSnake,
    faSnooze,
    faSortNumericDown,
    faSortSizeUpAlt,
    faStamp,
    faStarShooting,
    faSuitcase,
    faTable,
    faTaco,
    faThumbsDown,
    faThumbsUp,
    faTicket,
    faTimesCircle,
    faToilet,
    faTools,
    faTrafficCone,
    faTrafficLightGo,
    faTrashAlt,
    faTrees,
    faTurtle,
    faTv,
    faUser,
    faUserCheck,
    faUserCog,
    faUserFriends,
    faUserHeadset,
    faUserRobot,
    faUsersCog,
    faUserSecret,
    faUserShield,
    faUserUnlock,
    faVoicemail,
    faVolcano,
    faWalking,
    faWarehouse,
    faWaveform,
    faWhale,
    faWheelchair,
    faWindow,
    faWrench,
} from '@fortawesome/pro-duotone-svg-icons'

export default [
    { label: 'faAddressCard', icon: faAddressCard },
    { label: 'faBox', icon: faBox },
    { label: 'faBracketsCurly', icon: faBracketsCurly },
    { label: 'faBriefcase', icon: faBriefcase },
    { label: 'faBuilding', icon: faBuilding },
    { label: 'faBrowser', icon: faBrowser },
    { label: 'faCalendarCheck', icon: faCalendarCheck },
    { label: 'faCartPlus', icon: faCartPlus },
    { label: 'faCity', icon: faCity },
    { label: 'faClock', icon: faClock },
    { label: 'faCommentAltSmile', icon: faCommentAltSmile },
    { label: 'faCommentAltExclamation', icon: faCommentAltExclamation },
    { label: 'faCommentAltCheck', icon: faCommentAltCheck },
    { label: 'faComputerClassic', icon: faComputerClassic },
    { label: 'faDollarSign', icon: faDollarSign },
    { label: 'faDolly', icon: faDolly },
    { label: 'faEdit', icon: faEdit },
    { label: 'faEnvelopeOpenDollar', icon: faEnvelopeOpenDollar },
    { label: 'faExclamationTriangle', icon: faExclamationTriangle },
    { label: 'faFileCertificate', icon: faFileCertificate },
    { label: 'faFileCheck', icon: faFileCheck },
    { label: 'faFileDownload', icon: faFileDownload },
    { label: 'faFlask', icon: faFlask },
    { label: 'faFunnelDollar', icon: faFunnelDollar },
    { label: 'faHandHoldingHeart', icon: faHandHoldingHeart },
    { label: 'faHandHoldingMedical', icon: faHandHoldingMedical },
    { label: 'faHandHoldingSeedling', icon: faHandHoldingSeedling },
    { label: 'faHandHoldingUsd', icon: faHandHoldingUsd },
    { label: 'faHeadSideBrain', icon: faHeadSideBrain },
    { label: 'faHeadSide', icon: faHeadSide },
    { label: 'faHome', icon: faHome },
    { label: 'faHouseUser', icon: faHouseUser },
    { label: 'faIdCard', icon: faIdCard },
    { label: 'faImages', icon: faImages },
    { label: 'faInfoSquare', icon: faInfoSquare },
    { label: 'faLandmark', icon: faLandmark },
    { label: 'faLaptop', icon: faLaptop },
    { label: 'faHammer', icon: faHammer },
    { label: 'faLeaf', icon: faLeaf },
    { label: 'faLightbulb', icon: faLightbulb },
    { label: 'faLockAlt', icon: faLockAlt },
    { label: 'faMagnet', icon: faMagnet },
    { label: 'faMailbox', icon: faMailbox },
    { label: 'faMapMarkedAlt', icon: faMapMarkedAlt },
    { label: 'faMapMarkerAlt', icon: faMapMarkerAlt },
    { label: 'faMedal', icon: faMedal },
    { label: 'faMegaphone', icon: faMegaphone },
    { label: 'faMobileAlt', icon: faMobileAlt },
    { label: 'faMousePointer', icon: faMousePointer },
    { label: 'faMountains', icon: faMountains },
    { label: 'faPaintBrushAlt', icon: faPaintBrushAlt },
    { label: 'faPaperclip', icon: faPaperclip },
    { label: 'faParachuteBox', icon: faParachuteBox },
    { label: 'faParagraph', icon: faParagraph },
    { label: 'faPianoKeyboard', icon: faPianoKeyboard },
    { label: 'faPhoneOffice', icon: faPhoneOffice },
    { label: 'faPoo', icon: faPoo },
    { label: 'faPlayCircle', icon: faPlayCircle },
    { label: 'faQuoteLeft', icon: faQuoteLeft },
    { label: 'faRobot', icon: faRobot },
    { label: 'faShieldCheck', icon: faShieldCheck },
    { label: 'faShareAlt', icon: faShareAlt },
    { label: 'faSnake', icon: faSnake },
    { label: 'faScroll', icon: faScroll },
    { label: 'faSuitcase', icon: faSuitcase },
    { label: 'faTaco', icon: faTaco },
    { label: 'faThumbsUp', icon: faThumbsUp },
    { label: 'faTicket', icon: faTicket },
    { label: 'faTimesCircle', icon: faTimesCircle },
    { label: 'faTools', icon: faTools },
    { label: 'faTrafficLightGo', icon: faTrafficLightGo },
    { label: 'faTrees', icon: faTrees },
    { label: 'faUserUnlock', icon: faUserUnlock },
    { label: 'faUserSecret', icon: faUserSecret },
    { label: 'faUserRobot', icon: faUserRobot },
    { label: 'faUserShield', icon: faUserShield },
    { label: 'faUserFriends', icon: faUserFriends },
    { label: 'faUserHeadset', icon: faUserHeadset },
    { label: 'faUserCog', icon: faUserCog },
    { label: 'faUserCheck', icon: faUserCheck },
    { label: 'faVolcano', icon: faVolcano },
    { label: 'faWalking', icon: faWalking },
    { label: 'faWhale', icon: faWhale },
    { label: 'faWindow', icon: faWindow },
    { label: 'faChartBar', icon: faChartBar },
    { label: 'faChartLine', icon: faChartLine },
    { label: 'faChartPie', icon: faChartPie },
    { label: 'faAnalytics', icon: faAnalytics },
    { label: 'faPoll', icon: faPoll },
    { label: 'faMegaphone', icon: faMegaphone },
    { label: 'faGiftCard', icon: faGiftCard },
    { label: 'faBullseyeArrow', icon: faBullseyeArrow },

    { label: 'faClipboardUser', icon: faClipboardUser },
    { label: 'faClarinet', icon: faClarinet },
    { label: 'faClipboardCheck', icon: faClipboardCheck },
    { label: 'faClipboardList', icon: faClipboardList },
    { label: 'faClipboardListCheck', icon: faClipboardListCheck },
    { label: 'faClipboard', icon: faClipboard },
    { label: 'faClipboardPrescription', icon: faClipboardPrescription },
    { label: 'faChessKing', icon: faChessKing },
    { label: 'faChessQueen', icon: faChessQueen },
    { label: 'faCassetteTape', icon: faCassetteTape },
    { label: 'faCashRegister', icon: faCashRegister },
    { label: 'faCartArrowDown', icon: faCartArrowDown },
    { label: 'faCameraRetro', icon: faCameraRetro },
    { label: 'faCalendarAlt', icon: faCalendarAlt },
    { label: 'faBug', icon: faBug },
    { label: 'faBackpack', icon: faBackpack },
    { label: 'faBookDead', icon: faBookDead },
    { label: 'faBook', icon: faBook },
    { label: 'faBookHeart', icon: faBookHeart },
    { label: 'faBell', icon: faBell },
    { label: 'faAt', icon: faAt },
    { label: 'faAddressBook', icon: faAddressBook },
    { label: 'faAlicorn', icon: faAlicorn },
    { label: 'faArchive', icon: faArchive },
    { label: 'faDog', icon: faDog },
    { label: 'faDoNotEnter', icon: faDoNotEnter },
    { label: 'faEnvelopeOpenText', icon: faEnvelopeOpenText },
    { label: 'faFrenchFries', icon: faFrenchFries },
    { label: 'faFutbol', icon: faFutbol },
    { label: 'faFunction', icon: faFunction },
    { label: 'faForklift', icon: faForklift },
    { label: 'faCocktail', icon: faCocktail },
    { label: 'faCog', icon: faCog },
    { label: 'faCoins', icon: faCoins },
    { label: 'faCoffee', icon: faCoffee },
    { label: 'faCommentCheck', icon: faCommentCheck },
    { label: 'faCrow', icon: faCrow },
    { label: 'faCubes', icon: faCubes },
    { label: 'faDeer', icon: faDeer },
    { label: 'faDatabase', icon: faDatabase },
    { label: 'faDesktop', icon: faDesktop },
    { label: 'faDigging', icon: faDigging },
    { label: 'faDiceSix', icon: faDiceSix },
    { label: 'faDiploma', icon: faDiploma },
    { label: 'faDuck', icon: faDuck },
    { label: 'faDrone', icon: faDrone },
    { label: 'faElephant', icon: faElephant },
    { label: 'faEgg', icon: faEgg },
    { label: 'faExternalLink', icon: faExternalLink },
    { label: 'faFarm', icon: faFarm },
    { label: 'faEye', icon: faEye },
    { label: 'faFileImport', icon: faFileImport },
    { label: 'faFileSignature', icon: faFileSignature },
    { label: 'faFileSearch', icon: faFileSearch },
    { label: 'faFilm', icon: faFilm },
    { label: 'faFlag', icon: faFlag },
    { label: 'faFolderTree', icon: faFolderTree },
    { label: 'faHeadset', icon: faHeadset },
    { label: 'faHorseSaddle', icon: faHorseSaddle },
    { label: 'faInbox', icon: faInbox },
    { label: 'faInboxIn', icon: faInboxIn },
    { label: 'faInboxOut', icon: faInboxOut },
    { label: 'faLaptopCode', icon: faLaptopCode },
    { label: 'faLayerGroup', icon: faLayerGroup },
    { label: 'faLock', icon: faLock },
    { label: 'faMap', icon: faMap },
    { label: 'faMagic', icon: faMagic },
    { label: 'faMapSigns', icon: faMapSigns },
    { label: 'faMeat', icon: faMeat },
    { label: 'faMicrophoneAlt', icon: faMicrophoneAlt },
    { label: 'faMobile', icon: faMobile },
    { label: 'faMountain', icon: faMountain },
    { label: 'faMugTea', icon: faMugTea },
    { label: 'faMusic', icon: faMusic },
    { label: 'faMonkey', icon: faMonkey },
    { label: 'faOtter', icon: faOtter },
    { label: 'faPaw', icon: faPaw },
    { label: 'faPersonSign', icon: faPersonSign },
    { label: 'faPeopleCarry', icon: faPeopleCarry },
    { label: 'faPlane', icon: faPlane },
    { label: 'faPlay', icon: faPlay },
    { label: 'faPollPeople', icon: faPollPeople },
    { label: 'faPrayingHands', icon: faPrayingHands },
    { label: 'faQuidditch', icon: faQuidditch },
    { label: 'faRadiation', icon: faRadiation },
    { label: 'faRam', icon: faRam },
    { label: 'faRecycle', icon: faRecycle },
    { label: 'faRedoAlt', icon: faRedoAlt },
    { label: 'faRoad', icon: faRoad },
    { label: 'faRingsWedding', icon: faRingsWedding },
    { label: 'faRocketLaunch', icon: faRocketLaunch },
    { label: 'faRouter', icon: faRouter },
    { label: 'faRss', icon: faRss },
    { label: 'faRunning', icon: faRunning },
    { label: 'faSackDollar', icon: faSackDollar },
    { label: 'faSandwich', icon: faSandwich },
    { label: 'faServer', icon: faServer },
    { label: 'faShapes', icon: faShapes },
    { label: 'faShare', icon: faShare },
    { label: 'faSheep', icon: faSheep },
    { label: 'faSitemap', icon: faSitemap },
    { label: 'faSkull', icon: faSkull },
    { label: 'faSkullCow', icon: faSkullCow },
    { label: 'faSkullCrossbones', icon: faSkullCrossbones },
    { label: 'faSmilePlus', icon: faSmilePlus },
    { label: 'faSmileWink', icon: faSmileWink },
    { label: 'faSnooze', icon: faSnooze },
    { label: 'faSortNumericDown', icon: faSortNumericDown },
    { label: 'faSortSizeUpAlt', icon: faSortSizeUpAlt },
    { label: 'faStarShooting', icon: faStarShooting },
    { label: 'faStamp', icon: faStamp },
    { label: 'faTable', icon: faTable },
    { label: 'faThumbsDown', icon: faThumbsDown },
    { label: 'faToilet', icon: faToilet },
    { label: 'faTrafficCone', icon: faTrafficCone },
    { label: 'faTrashAlt', icon: faTrashAlt },
    { label: 'faTv', icon: faTv },
    { label: 'faTurtle', icon: faTurtle },
    { label: 'faUser', icon: faUser },
    { label: 'faUsersCog', icon: faUsersCog },
    { label: 'faVoicemail', icon: faVoicemail },
    { label: 'faWarehouse', icon: faWarehouse },
    { label: 'faWaveform', icon: faWaveform },
    { label: 'faWheelchair', icon: faWheelchair },
    { label: 'faWrench', icon: faWrench },
]
