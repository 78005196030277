import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { RadioButton, RadioGroup } from 'ui/components/Radio'

import { RenderInlineElement } from './ui'
/**
 *
 * @param {{name: string, label?: string, defaultValue?: any, errorMessages?: {[keyof: string]: string}, options: {label: string, value: string, disabled?: boolean}[], subtitle?: string} & Partial<import('react-hook-form').RegisterOptions>} param0
 */
export default function SelectForm({
    name,
    label = name,
    subtitle = '',
    defaultValue = null,
    options = [],
    required = false,
    pattern = undefined,
    errorMessages = {},
    ...props
}) {
    const {
        formState: { errors },
        control,
        setValue,
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement
            label={label}
            error={error}
            errorMessages={errorMessages}
            subtitle={subtitle}
        >
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ pattern, required }}
                render={({ field }) => (
                    <RadioGroup
                        name={name}
                        defaultValue={defaultValue}
                        value={field.value}
                        onValueChange={(value) => setValue(name, value, { shouldDirty: true })}
                        {...props}
                    >
                        {options.map((option) => (
                            <RadioButton key={option.value} value={option.value}>
                                {option.label}
                            </RadioButton>
                        ))}
                    </RadioGroup>
                )}
            />
        </RenderInlineElement>
    )
}
