import React, { useState } from 'react'

import PropTypes from 'prop-types'

import Button from 'v2/ui/components/Button'

import Container from './Container'
import Heading from './Heading'
import Section from './Section'
import Span from './Span'

const ExpandSection = ({
    heading,
    text,
    children,
    helpUrl,
    helpText,
    helpId,
    disabled,
    alwaysOpen,
    classes,
    testId,
}) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <Section padding="small" style={{ width: '100%' }}>
            <Container
                data-testid={testId ? testId : ''}
                noMargin
                noPadding
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Section noMargin style={{ padding: '0px 30px 0px 0px' }}>
                    <Heading
                        helpId={helpId}
                        helpUrl={helpUrl}
                        helpText={helpText}
                        helpAlign="right"
                        margin="none"
                        padding="none"
                        size="small"
                        style={{ fontSize: 16 }}
                        className={classes ? classes.heading : ''}
                    >
                        {heading}
                    </Heading>
                    {text && <Span style={{ lineHeight: '1.5em' }}>{text}</Span>}
                </Section>
                {children && !alwaysOpen && (
                    <Button
                        style={{ flexShrink: 0 }}
                        variant="adminSecondary"
                        disabled={disabled}
                        buttonSize="sm"
                        onClick={toggleOpen}
                    >
                        {open ? 'Collapse' : 'Expand'}
                    </Button>
                )}
            </Container>
            {(open || alwaysOpen) && children}
        </Section>
    )
}

ExpandSection.propTypes = {
    heading: PropTypes.string.isRequired,
    text: PropTypes.element,
    children: PropTypes.element,
    helpUrl: PropTypes.string,
    helpText: PropTypes.string,
}

ExpandSection.defaultProps = {
    text: '',
    children: null,
    helpUrl: '',
    helpText: '',
}

export default ExpandSection
