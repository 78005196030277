import * as React from 'react'
import { useMemo, useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import { useLDFlags } from 'data/hooks/useLDFlags'
import AppSettingsModalDataActions from 'features/AppSettings/Data/AppSettingsModalDataActions'
import AppSettingsModalDataFields from 'features/AppSettings/Data/AppSettingsModalDataFields'
import AppSettingsModalDataGrid from 'features/AppSettings/Data/AppSettingsModalDataGrid'
import AppSettingsModalDataLayouts from 'features/AppSettings/Data/AppSettingsModalDataLayouts'
import AppSettingsModalDataPermissions from 'features/AppSettings/Data/AppSettingsModalDataPermissions'
import AppSettingsModalDataSettings from 'features/AppSettings/Data/AppSettingsModalDataSettings'
import InstallableFeatureLibrary from 'features/installableFeatures/InstallableFeatureLibrary'

import stackerTheme from 'v2/ui/theme/styles/default'

import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from '../workspace/UnsavedChangesModal'

import GeneralSettings from './AppSettingsGeneralSettings/AppSettingsModalGeneralSettings'
import CustomerAccess from './CustomerAccess/AppModalCustomerAccess'
import DataSources from './DataSources/AppSettingsModalDataSources'
import Permissions from './Permissions/AppSettingsModalPermissions'
import Roles from './Roles/AppSettingsModalRoles'
import Appearance from './AppSettingsModalAppearance'
import Links from './AppSettingsModalLinks'
import Navigation from './AppSettingsModalNavigation'
import { Sidebar } from './AppSettingsModalSidebar'
import AppSettingsModalUserProfiles from './AppSettingsModalUserProfiles'

export const colors = stackerTheme().colors

const GeneralSettingsMemo = React.memo(GeneralSettings)
const LinksMemo = React.memo(Links)
const UserProfilesMemo = React.memo(AppSettingsModalUserProfiles)

const SectionComponents = {
    settings: AppSettingsModalDataSettings,
    data: AppSettingsModalDataGrid,
    fields: AppSettingsModalDataFields,
    permissions: AppSettingsModalDataPermissions,
    layouts: AppSettingsModalDataLayouts,
    actions: AppSettingsModalDataActions,
}

/**
 *
 * @param {AppSettingsPage} page
 * @param {*} selectedStack
 * @returns
 */
function getPageComponent(page, selectedStack) {
    switch (page?.name) {
        case 'general':
            return GeneralSettingsMemo

        case 'appearance':
            return Appearance

        case 'links':
            return LinksMemo

        case 'user_profiles':
            return UserProfilesMemo

        case 'customer_access':
            return (props) => (
                <CustomerAccess initialPage={page.meta?.section ?? 'general'} {...props} />
            )

        case 'navigation':
            return Navigation

        case 'roles':
            return Roles

        case 'data_sources':
            return DataSources

        case 'object': {
            const SectionComponent = SectionComponents[page.meta.section]

            return SectionComponent
                ? (props) => {
                      const objectId = page.meta.object._sid
                      return (
                          <SectionComponent
                              key={
                                  // remount to reload data when changing objects but staying on the same section
                                  `${page.meta.section}-${objectId}`
                              }
                              {...props}
                              stack={selectedStack}
                              stackId={selectedStack._sid}
                              feature={page.meta.feature}
                              object={page.meta.object}
                              objectId={objectId}
                          />
                      )
                  }
                : null
        }

        case 'permissions':
            return Permissions

        case 'installable_features':
            return InstallableFeatureLibrary

        default:
            return null
    }
}

export const AppSettingsContent = ({
    onRequestClose = () => {},
    modalState = { page: { name: 'general' } },
    setPage,
}) => {
    const formRef = useRef({})
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const { selectedStack } = useAppContext()

    const { flags } = useLDFlags()

    const { page } = modalState

    // Because in some cases getPageComponent returns an inline created function to render
    // each call will yield a new function and thus a new component. This useMemo will ensure
    // That doesn't happen on rerender of this component unless something actually has changed.
    const Component = useMemo(
        () => getPageComponent(page, selectedStack, flags),
        [page, selectedStack, flags]
    )

    /**
     * check for unsaved changes, then change the page
     * @param  {AppSettingsPage} page
     * @return {Promise<any>}
     **/
    const onChangePage = (page) => {
        return saveChanges(() => setPage(page))
    }

    // check for unsaved changes, then close the modal
    function onClose() {
        saveChanges(onRequestClose)
    }

    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
                overflow: 'hidden',
            }}
        >
            <Sidebar onChangePage={onChangePage} pageName={page.name} pageOptions={page.meta} />
            <div
                style={{
                    overflowY: 'auto',
                    width: '100%',
                    display: 'flex',
                }}
            >
                {Component ? (
                    <Component formRef={formRef} onCloseSettingsModal={onClose} {...page.props}>
                        {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                    </Component>
                ) : (
                    "can't render " + page.name
                )}
            </div>
        </div>
    )
}
