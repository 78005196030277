export default function getPreviousPeriodDisplaySettings(
    percentage: number | null,
    switchPreviousPeriodColors: boolean | undefined
) {
    if (percentage === null) {
        return {
            color: '',
            backgroundColor: '',
        }
    }

    const positive = switchPreviousPeriodColors ? percentage < 0 : percentage > 0
    if (positive) {
        return {
            color: 'userInterface.success.700',
            backgroundColor: 'userInterface.success.100',
        }
    }

    const negative = switchPreviousPeriodColors ? percentage > 0 : percentage < 0
    if (negative) {
        return {
            color: 'userInterface.error.700',
            backgroundColor: 'userInterface.error.100',
        }
    }

    return {
        color: 'userInterface.neutral.800',
        backgroundColor: 'userInterface.neutral.300',
    }
}
