import { useMemo } from 'react'

import { useFields } from 'data/hooks/fields'

import { getTimePeriodFilters } from './getTimePeriodFilters'
import { timePeriodFilterEnabled } from './timePeriodFilterUtils'

export const useTimePeriodFilters = (
    metric: MetricsBlockAttributes,
    previousTimePeriod: boolean = false
) => {
    const { data: fields } = useFields()

    return useMemo(() => {
        if (!timePeriodFilterEnabled(metric)) return []
        const timePeriodField = fields?.find(({ _sid }) => _sid === metric.limitToTimePeriodField)
        if (!timePeriodField) return []
        return getTimePeriodFilters({
            value: metric.limitToTimePeriod,
            fieldApiName: timePeriodField.api_name,
            previousTimePeriod,
        })
    }, [metric, fields, previousTimePeriod])
}
