import { RefObject, useEffect, useState } from 'react'

import {
    CompactSelection,
    DataEditorRef,
    GridCell,
    GridColumn,
    GridSelection,
    Item,
    Rectangle,
} from '@glideapps/glide-data-grid'
import { isEmpty, isEqual } from 'lodash'

import { useAppContext } from 'app/AppContext'

import { moveItem } from '../moveItem'
import type { DataGridEditorProps } from '../types'
import { getCellValue, getErrorMessage, isCellEditable } from '../utils'

import useConfirmDeleteRecords from './useConfirmDeleteRecords'
import { getAddFieldColumn } from './useDataGridColumns'
import { useDefaultFieldsOrder } from './useDefaultFieldsOrder'
import { FailedCommit, PendingDelete } from './useRecordEditManager'

type Args = Pick<
    DataGridEditorProps,
    | 'object'
    | 'records'
    | 'orderBy'
    | 'setOrderBy'
    | 'addField'
    | 'onColumnsOrderChanged'
    | 'onColumnHeaderClick'
    | 'onRecordsSelected'
> & {
    editorRef: RefObject<DataEditorRef>
    failedRecords: { [keyof: string]: FailedCommit }
    pendingDeletes: PendingDelete[]
    displayedFields: FieldDto[]
    columns: GridColumn[]
    addFieldButtonConfig: { title: string; width: number }
    editRecord: (record: RecordDto, patch: Partial<RecordDto>) => void
    deleteRecords: (recordsIds: string[]) => void
}

function useDataGridHandler({
    editorRef,
    object,
    records,
    failedRecords,
    pendingDeletes,
    displayedFields,
    columns,
    addFieldButtonConfig,
    editRecord,
    deleteRecords,
    orderBy,
    setOrderBy,
    addField,
    onColumnsOrderChanged,
    onColumnHeaderClick,
    onRecordsSelected,
}: Args) {
    const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | undefined>()
    const [gridSelection, setGridSelection] = useState<GridSelection | undefined>()
    const [visibleRegion, setVisibleRegion] = useState<Rectangle | undefined>()
    const [pinAddNewRow, setPinAddNewRow] = useState(false)
    const [showAddNewRow, setShowAddNewRow] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const { selectedStack } = useAppContext()
    const defaultFieldsOrder = useDefaultFieldsOrder(object)

    const showConfirmDelete = useConfirmDeleteRecords()

    const handleVisibleRegionChange = (newVisibleRegion: Rectangle) => {
        setVisibleRegion(newVisibleRegion)
    }

    const handleColumnClick = (col: number) => {
        if (isEqual(columns[col], getAddFieldColumn(addFieldButtonConfig))) {
            if (addField) {
                addField()
            }

            return
        }

        const field = displayedFields[col]
        // If this column is already selected, then additional clicks on it cycle the sort mode
        if (selectedColumnIndex === col) {
            // If this column is not yet sorting, set to ascending
            if (!orderBy?.id || orderBy.id !== field.api_name) {
                setOrderBy?.({ id: field.api_name, desc: false })
                // Otherwise if we're not already descending, set to that
            } else if (!orderBy?.desc) {
                setOrderBy?.({ id: field.api_name, desc: true })
                // Otherwise, clear the sort
            } else {
                setOrderBy?.(undefined)
            }
        } else {
            onColumnHeaderClick?.(field)
        }

        setSelectedColumnIndex(col)
    }

    const handleCellEdited = ([col, row]: Item, cell: GridCell): void => {
        if (!isCellEditable(cell)) {
            return
        }

        const field = displayedFields[col] as FieldDto
        const record = records[row]
        const value = getCellValue(cell)

        // Don't allow edits on rows that have been marked for deletion
        if (record && pendingDeletes.find((x) => x.recordId === record._sid)) {
            return
        }

        editRecord(record, { [field.api_name]: value })
    }

    const onFieldCreated = (field: FieldDto) => {
        if (columns.length === 0) {
            return
        }

        const index = displayedFields.findIndex((f) => f._sid === field._sid)
        editorRef.current?.scrollTo(index + 1, 0, 'horizontal', 100)

        // select the column
        setGridSelection((prevGridSelection) => {
            if (!prevGridSelection) {
                return prevGridSelection
            }

            return {
                ...prevGridSelection,
                columns: CompactSelection.fromSingleSelection(index),
            }
        })
    }

    const onGridSelectionChange = (selection: GridSelection) => {
        let columnSelection = selection.columns
        if (selection.columns.hasIndex(displayedFields.length)) {
            columnSelection = selection.columns.remove(displayedFields.length)
        }

        // If we have a currently selected column index, and it's no longer selected in the grid selection
        // then we clear our selectedColumnIndex state.
        if (
            selectedColumnIndex &&
            (selection.columns.length === 0 || !selection.columns.hasIndex(selectedColumnIndex))
        ) {
            setSelectedColumnIndex(undefined)
        }

        setGridSelection({ ...selection, columns: columnSelection })

        const selectedRecords = selection?.rows?.toArray().map((rowId) => records[rowId]) ?? []
        onRecordsSelected?.(selectedRecords)
    }

    const handleDelete = (selection: GridSelection) => {
        if (selection.current) {
            return true
        }

        const recordIds: string[] = []
        if (selection.rows.length === 0) {
            return false
        }
        const selectedRecords = selection?.rows?.toArray().map((rowId) => records[rowId]) ?? []
        for (
            let i = selection.rows.first() as number;
            i <= (selection.rows.last() as number);
            i += 1
        ) {
            selectedRecords.includes(records[i]) && recordIds.push(records[i]._sid)
        }

        showConfirmDelete(recordIds.length, async () => {
            setGridSelection(undefined)
            onRecordsSelected?.([])
            deleteRecords?.(recordIds)
        })

        return false
    }

    const onColumnMoved = (startIndex: number, endIndex: number) => {
        if (!selectedStack || !onColumnsOrderChanged) return

        // Cannot move column before the primary field or after the add field button
        if (endIndex === 0 || endIndex >= displayedFields.length) return

        // Cannot move the add field column
        if (startIndex >= displayedFields.length) return

        // Cannot move the primary field
        if (startIndex === 0) return
        const element = displayedFields[startIndex].api_name
        const fieldsOrder = displayedFields.map((field) => field?.api_name) || defaultFieldsOrder

        const result = moveItem(fieldsOrder, element, endIndex)

        onColumnsOrderChanged(result)
    }

    // Pin the row to the bottom of the grid if we have more records
    // than are currently showing
    useEffect(() => {
        if (!visibleRegion) {
            return
        }

        setPinAddNewRow(visibleRegion.y + visibleRegion.height <= records.length + 1)
    }, [visibleRegion, records.length])

    // when the pinned state of add row changes, we have to toggle it
    // on and off to get the datagrid to redraw correctly.
    useEffect(() => {
        setShowAddNewRow(false)
    }, [pinAddNewRow])

    useEffect(() => {
        setShowAddNewRow(true)
    }, [showAddNewRow])

    useEffect(() => {
        if (!isEmpty(failedRecords)) {
            const message = getErrorMessage(failedRecords)
            setErrorMessage(message)
        }
    }, [failedRecords])

    return {
        selectedColumnIndex,
        gridSelection,
        errorMessage,
        pinAddNewRow,
        showAddNewRow,
        handleCellEdited,
        handleColumnClick,
        handleDelete,
        handleVisibleRegionChange,
        onColumnMoved,
        onFieldCreated,
        onGridSelectionChange,
    }
}

export default useDataGridHandler
