import React, { memo } from 'react'

import ListView from 'v2/views/List/ListView'

type ListViewWrapperProps = React.ComponentPropsWithoutRef<typeof ListView> & {}

const ListViewWrapper: React.FC<ListViewWrapperProps> = memo(({ titleText, ...props }) => {
    return <ListView title={titleText} {...props} />
})

export default ListViewWrapper
