import React from 'react'
import { UseFiltersColumnProps } from 'react-table'

import WithRecords from 'data/wrappers/WithRecords'
import { recordsToOptions } from 'features/records/components/RecordDropdown'
import { filterColumnsBySelectedFields } from 'features/records/utils/userSpecificFilters'

import Button from 'v2/ui/components/Button'
import BooleanFilter from 'v2/ui/components/Filters/BooleanFilter'
import DropdownFilter from 'v2/ui/components/Filters/DropdownFilter'
import Flex from 'v2/ui/components/Flex'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { ViewColumn } from './types'

type Column = ViewColumn & UseFiltersColumnProps<RecordDto>

function renderFilters(columns: Column[], selectedFieldIds: string[]): React.ReactNode[] {
    const filteredColumns = filterColumnsBySelectedFields(columns, selectedFieldIds)

    return filteredColumns.reduce<React.ReactNode[]>((filtered, column) => {
        const { id, type, field, Header, setFilter, filterValue, icon } = column

        if (!id || !field) return filtered

        switch (type) {
            case 'checkbox':
                filtered.push(
                    <BooleanFilter
                        key={id}
                        label={Header}
                        onChange={(value: unknown) => setFilter(value)}
                        currentValue={filterValue}
                        // @ts-expect-error
                        icon={icon}
                    />
                )

                break

            case 'dropdown':
            case 'multi_select': {
                const isMultiChoice = type === 'multi_select'

                filtered.push(
                    // @ts-expect-error
                    <DropdownFilter
                        key={id}
                        options={field?.options?.options}
                        label={Header}
                        onChange={(value: unknown) => setFilter(value)}
                        currentValue={filterValue}
                        multi={isMultiChoice}
                        icon={icon}
                    />
                )

                break
            }

            case 'lookup':
            case 'multi_lookup': {
                const lookupTarget = field?.options?.lookup_target
                const isMultiChoice = type === 'multi_lookup'

                filtered.push(
                    <WithRecords
                        key={id}
                        objectId={lookupTarget}
                        options={{
                            includeFields: [],
                        }}
                    >
                        {({ records }) => {
                            return (
                                // @ts-expect-error
                                <DropdownFilter
                                    options={recordsToOptions(records || [])}
                                    label={Header}
                                    onChange={(value: unknown) => setFilter(value)}
                                    currentValue={filterValue}
                                    multi={isMultiChoice}
                                    icon={icon}
                                />
                            )
                        }}
                    </WithRecords>
                )

                break
            }
        }

        return filtered
    }, [])
}

type EndUserFiltersProps = React.ComponentPropsWithoutRef<typeof Flex> & {
    clearFilters: () => void
    columns?: Column[]
    filtersCount?: number
    userFilterFieldIds?: string[]
}

const EndUserFilters = ({
    clearFilters,
    columns = [],
    filtersCount = 0,
    userFilterFieldIds = [],
    ...props
}: EndUserFiltersProps) => {
    const renderedFilters = renderFilters(columns, userFilterFieldIds)
    if (renderedFilters.length < 1) return null

    return (
        <Flex mb={4} className={STYLE_CLASSES.INLINE_FILTERS} {...props}>
            <Flex flex={1}>{renderedFilters}</Flex>
            {filtersCount > 0 && (
                <Button
                    fontSize={['button.sm', null, null, 'button.smLg']}
                    variant="clear"
                    m={0}
                    p={0}
                    mb={2}
                    alignSelf="flex-start"
                    ml={2}
                    onClick={clearFilters}
                >
                    Clear Filters
                </Button>
            )}
        </Flex>
    )
}

export default React.memo(EndUserFilters)
