import React from 'react'

import { BoxProps, FlexProps, Spinner } from '@chakra-ui/react'

import Box from './Box'
import Flex from './Flex'
import Progress from './Progress'
import Text from './Text'

type Props = BoxProps & {
    isLoading: boolean
    loadingText?: string
    showProgressBar?: boolean
    indicatorColor?: string
    progress?: number
    size?: 'sm' | 'md' | 'lg'
    keepChildrenMounted?: boolean
    loadingBackgroundProps?: FlexProps
}
const LoadingScreen = ({
    isLoading,
    loadingText,
    size = 'md',
    children,
    showProgressBar,
    progress,
    keepChildrenMounted,
    loadingBackgroundProps,
    indicatorColor = 'neutral.800',
    ...props
}: Props) => {
    const loading = (
        <Flex
            column
            bg="white"
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            left="0"
            justify="center"
            zIndex={10}
            {...loadingBackgroundProps}
        >
            <Flex column={showProgressBar} width="100%" justify="center">
                {showProgressBar ? (
                    <Progress
                        size={size}
                        value={progress}
                        min={0}
                        max={100}
                        isAnimated
                        hasStripe
                        alignSelf="stretch"
                        mb={4}
                    />
                ) : (
                    <Spinner size={size} color={indicatorColor} />
                )}

                {loadingText && (
                    <Text ml={4} size={size}>
                        {loadingText}
                    </Text>
                )}
            </Flex>
        </Flex>
    )

    return (
        <Box position="relative" width="100%" height="100%" {...props}>
            {(!isLoading || keepChildrenMounted) && children}
            {isLoading && loading}
        </Box>
    )
}
export default LoadingScreen
