import React, { forwardRef } from 'react'

import styled from '@emotion/styled'
import { variant } from 'styled-system'

import Box from './Box'

const StyledBox = styled(Box)`
    ${variant({
        variants: {
            row: {
                my: 0,
                borderBottom: '1px solid',
                borderColor: 'grey.200',
                '&:lastChild': { borderBottom: 0 },
                '&:hover': { bg: 'rows.backgroundHover' },
            },
        },
    })}
`

const StackerFlex = (
    { justify, children, align = 'center', wrap = 'wrap', direction, column, ...props },
    ref
) => {
    direction = direction || (column ? 'column' : null)
    return (
        <StyledBox
            ref={ref}
            display="flex"
            justifyContent={justify}
            alignItems={align}
            flexWrap={wrap}
            flexDirection={direction}
            {...props}
        >
            {children}
        </StyledBox>
    )
}

export default React.memo(forwardRef(StackerFlex))
