import React, { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin } from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Box, Flex, LoadingScreen } from 'v2/ui'

import AddUserDropdown from './AddUserDropdown'
import { ManualAppUserList } from './ManualAppUserList'

type Props = {
    formRef: any
    userList?: UserListDto
    children?: React.ReactNode
}
export default function ManualAppUsers({ formRef, userList, children }: Props) {
    const { selectedStack: stack } = useAppContext()
    const { data: users = [], isLoading } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: true,
    })

    const updateStackSharingSettings = useUpdateStackSharingSettings()

    const nonAdminUsers = useMemo(
        () => users.filter(({ role }: { role: string }) => role !== 'internal_admin'),
        [users]
    )

    const saveChanges = (patch: any) => {
        assertIsDefined(stack)
        return updateStackSharingSettings(stack, patch).then(refetchAppUsersForAdmin)
    }
    if (isLoading) {
        return <LoadingScreen isLoading={true} width="100%" height="100%" />
    }
    return (
        <Flex
            column
            width="100%"
            height="100%"
            maxHeight="100%"
            wrap="nowrap"
            align="stretch"
            flexGrow={1}
            flexShrink={1}
            minHeight="0"
        >
            <AddUserDropdown userList={userList} />
            <Box height="3" />
            <ManualAppUserList
                disabled={false}
                formRef={formRef}
                onSave={saveChanges}
                users={nonAdminUsers}
                userList={userList}
            >
                {children}
            </ManualAppUserList>
        </Flex>
    )
}
