import * as React from 'react'

import styled from '@emotion/styled'

import Icon from 'v2/ui/components/Icon'
import Input from 'v2/ui/components/Input'

import Flex from 'ui/deprecated/atoms/Flex'

import PickerButton from '../../../ui/deprecated/components/PickerButton'
import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'
import ItemPickerForm from '../../../ui/forms/ItemPickerForm'

/**  @type {string[]}*/
const colorSwatch = [
    '#CECFD4',
    '#1780BB',
    '#21A58D',
    '#21A164',
    '#38A91E',
    '#889C0D',
    '#C29A33',
    '#E27816',
    '#EA5C0C',
    '#C9251B',
    '#8E656F',
    '#BA3364',
    '#9D3C88',
    '#7A44AF',
    '#4B55B0',
    '#5A7698',
]

export const defaultColor = colorSwatch[1]

/**
 * @param { any } param0
 */
export default function ColorPickerForm({ defaultColor = null, ...props }) {
    return (
        <ItemPickerForm
            renderCurrentValue={(value) => <ColorItem value={value || defaultColor} />}
            place="right"
            bodyPadding="12px"
            {...props}
        >
            {({ onChange, value }) => (
                <ColorPickerContents
                    onChange={onChange}
                    value={value}
                    showCustom={props.showCustom}
                />
            )}
        </ItemPickerForm>
    )
}

export function ColorPickerDropdown({ defaultColor = null, onChange, value, place, ...props }) {
    return (
        <PickerButton
            key={value}
            buttonContent={<ColorItem value={value || defaultColor} />}
            place={place}
            {...props}
        >
            <ColorPickerContents onChange={onChange} value={value} />
        </PickerButton>
    )
}

const hexColorRegex = /#[0-9A-F]{6}$/i // Starts with `#` and only six chars

const ColorPickerContents = ({ value, onChange, showCustom }) => {
    const [inputVal, setVal] = React.useState(value)
    React.useEffect(() => {
        setVal(value)
    }, [value, setVal])
    const handleCustomInputChange = (e) => {
        const val = e.target.value || ''
        if (val.match(hexColorRegex)) {
            onChange(e.target.value)
        }
        if (val.length < 8) {
            setVal(e.target.value)
        }
    }
    return (
        <Flex column>
            <Flex style={{ width: 200, flexWrap: 'wrap' }}>
                {colorSwatch.map((color) => (
                    <StyledColorItem
                        key={color}
                        className={value === color ? 'active' : ''}
                        onClick={() => onChange(color)}
                    >
                        <Icon icon="check" color="white" />
                        <ColorItem value={color} />
                    </StyledColorItem>
                ))}
            </Flex>
            {showCustom && (
                <>
                    <div style={{ ...V4DesignSystem.label }}>Custom</div>
                    <Input
                        value={inputVal}
                        onChange={handleCustomInputChange}
                        className={inputVal.match(hexColorRegex) ? '' : 'invalid'}
                    />
                </>
            )}
        </Flex>
    )
}

function ColorItem({ value }) {
    return <div style={{ minWidth: 20, height: 20, borderRadius: 5, background: value }} />
}

const StyledColorItem = styled.div`
    position: relative;
    flex-shrink: 0;
    margin-right: 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    border-radius: 8px;
    cursor: pointer;
    svg {
        transition: 0.2s all ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
    }
    &.active {
        svg {
            opacity: 1;
        }
    }
`
