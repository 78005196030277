import React, { useContext, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'

type LayoutObjectEditorProps = {
    isOpen: boolean
    onClose?: () => void
    title?: string
    editorId?: string | undefined
    onCloseOverride?: () => void
}

export const LayoutObjectEditor: React.FC<LayoutObjectEditorProps> = ({
    children,
    isOpen,
    onClose,
    title,
    editorId,
    onCloseOverride, // used to override the onClose button only
}) => {
    const isOpenPrev = useRef(isOpen)

    const { setActiveEditor, editorPortal, setActiveEditorTitle, setcloseHandler } =
        useContext(LayoutEditorContext)

    const content = children

    useEffect(() => {
        if (isOpen) {
            setActiveEditor({ title, onClose, id: editorId }, onCloseOverride)
        } else {
            // if the editor is previously open and now closed, call the onCloseOverride
            if (isOpenPrev && onCloseOverride) onCloseOverride()
        }
        isOpenPrev.current = isOpen
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        setcloseHandler(onCloseOverride)
    }, [onCloseOverride, setcloseHandler])

    useEffect(() => {
        if (!isOpen) return
        if (!title) return setActiveEditor(null)

        setActiveEditorTitle(title!)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, title])

    if (isOpen && editorPortal) {
        return createPortal(content, editorPortal)
    } else {
        return null
    }
}
export default LayoutObjectEditor
