import React from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'

import FieldPicker from 'features/studio/ui/FieldPicker'

import { getIsSyntheticField } from '../../../../utils/fieldUtils'

import { LinkFormObjectLabel } from './LinkFormObjectLabel'

export const LinkFormFieldDropdown = ({
    variant,
    value,
    onChange,
    object,
    limitFieldType,
    ...inputProps
}: {
    variant: 'primary' | 'secondary'
    value: string
    onChange: (val: string | null) => void
    object?: ObjectDto
    limitFieldType?: string
} & Record<string, any>) => {
    const { syntheticLinksNumericMatchingEnabled } = useFlags()
    const defaultLinkTypes = syntheticLinksNumericMatchingEnabled
        ? ['string', 'number']
        : ['string']
    const allowedTypes = limitFieldType ? [limitFieldType] : defaultLinkTypes

    const shouldIncludeField = (field: FieldDto): boolean => {
        return allowedTypes.includes(field.type) && !getIsSyntheticField(field)
    }

    // If the type of currently chosen field isn't allowed, reset the dropdown
    if (value) {
        if (!object) {
            throw new Error('object not set')
        }
        const { fields } = object
        const selectedField = fields.find((f) => f._sid === value)
        if (selectedField && !allowedTypes.includes(selectedField.type)) {
            onChange(null)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 5 }}>
                <LinkFormObjectLabel variant={variant} object={object} />
            </div>
            <FieldPicker
                {...inputProps}
                style={{ height: 'auto' }}
                variant="settings"
                value={value}
                onChange={onChange}
                placeholder="select field"
                objectId={object?._sid ?? ''}
                filter={shouldIncludeField}
                isDisabled={false}
            />
        </div>
    )
}
