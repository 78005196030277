import React from 'react'

import { Box, Flex, Text } from 'v2/ui'

import { ProviderTypes } from '../../admin/data-connector/dataConnectorTypes'
import DATA_PROVIDERS from '../../admin/data-connector/dataProviderConfig'
import { ProviderIcon } from '../../admin/data-connector/ProviderIcon'

export enum ConnectionModalMode {
    Edit = 'edit',
    Create = 'create',
    Sync = 'sync',
    Mobile = 'mobile',
}

export const ProviderNameWithIconHeader: React.FC<{
    type: ProviderTypes
    mode: ConnectionModalMode
    customTitle?: string | null
    serviceName?: string
}> = (props) => {
    const provider = DATA_PROVIDERS[props.type]
    const providerTitle = props.customTitle || provider.name
    const headerText = getHeaderText(props.mode, providerTitle)

    return (
        <Flex justifyContent="center" mt={6} style={{ fontSize: 20, fontWeight: 'bold' }}>
            <Box textAlign="center">
                <ProviderIcon
                    type={props.type}
                    serviceName={props.serviceName}
                    size={'40px'}
                    mb={4}
                />
                <Text style={{ textAlign: 'center', verticalAlign: 'middle' }}>{headerText}</Text>
            </Box>
        </Flex>
    )
}

const getHeaderText = (mode: ConnectionModalMode, providerTitle: string) => {
    switch (mode) {
        case ConnectionModalMode.Edit:
            return `Edit data from ${providerTitle}`
        case ConnectionModalMode.Create:
            return `Add data from ${providerTitle}`
        case ConnectionModalMode.Sync:
            return `Syncing ${providerTitle} schema`
        case ConnectionModalMode.Mobile:
            return 'Use computer to connect'
        default:
            return 'Edit data source'
    }
}
