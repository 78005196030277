import React, { useCallback, useState } from 'react'

import JSZip from 'jszip'

import Button from '../Button'

import { Attachment } from './types'
const FILESTACK_URL_REGEX1 = /^https:\/\/cdn\.files\.stackerhq\.com\/[a-zA-Z0-9]+$/
const FILESTACK_URL_REGEX2 = /^https:\/\/cdn\.filestackcontent\.com\/[A-Za-z0-9]+$/
const FILESTACK_CDN_BASE_URL = 'https://cdn.filestackcontent.com/'
const AIRTABLE_URL = 'https://v5.airtableusercontent.com/'

type DownloadAttachmentsProps = {
    attachments: Attachment[]
    fieldName?: string
}

export const DownloadAttachmentsButton: React.FC<DownloadAttachmentsProps> = ({
    attachments,
    fieldName,
}) => {
    const [isDownloading, setIsDownloading] = useState(false)

    const onDownloadAll = useCallback(() => {
        setIsDownloading(true)
        const airtableFiles: { url: string; filename: string }[] = []
        const filestackUrls: string[] = []

        const getAttachmentUrl = (attachment: Attachment): string => {
            return typeof attachment === 'object' ? attachment.url : attachment
        }

        attachments.forEach((attachment: Attachment) => {
            const url: string = getAttachmentUrl(attachment)

            if (FILESTACK_URL_REGEX1.test(url) || FILESTACK_URL_REGEX2.test(url)) {
                filestackUrls.push(url)
            } else if (url.startsWith(AIRTABLE_URL)) {
                airtableFiles.push({ url, filename: attachment.filename })
            }
        })

        downloadFiles(airtableFiles, filestackUrls, fieldName)

        setIsDownloading(false)
    }, [attachments, fieldName])

    return (
        <Button
            variant="clear"
            disabled={isDownloading}
            onClick={onDownloadAll}
            style={{ color: '#999', marginLeft: '15px', marginY: 'auto' }}
            icon="download"
            size="small"
        >
            Download all ({attachments.length})
        </Button>
    )
}

const downloadFiles = async (
    airtableFiles: { url: string; filename: string }[],
    filestackUrls: string[],
    name: string = 'files'
) => {
    const zip = new JSZip()

    try {
        const airtableFilePromises = airtableFiles.map(async ({ url, filename }) => {
            try {
                const response = await fetch(url, { method: 'GET' })
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
                }
                const blob = await response.blob()

                zip.file(filename, blob)
            } catch (error) {
                console.error(`Error fetching ${url}:`, error)
            }
        })

        const fetchFilestackZip = async (urls: string[]) => {
            if (urls.length < 1) return

            const filestackUrl = getFilestackDownloadUrl(urls)

            try {
                const response = await fetch(filestackUrl, { method: 'GET' })
                if (!response.ok) {
                    throw new Error(`Failed to fetch Filestack ZIP: ${response.statusText}`)
                }
                const blob = await response.blob()

                const zipFromFilestack = await JSZip.loadAsync(blob)
                zipFromFilestack.forEach((relativePath, file) => {
                    zip.file(relativePath, file.async('blob'))
                })
            } catch (error) {
                console.error('Error fetching Filestack files:', error)
            }
        }

        await Promise.all([...airtableFilePromises, fetchFilestackZip(filestackUrls)])

        const content = await zip.generateAsync({ type: 'blob' })

        const link = document.createElement('a')
        link.href = URL.createObjectURL(content)
        link.download = `${name}.zip`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(link.href)
    } catch (error) {
        console.error('Error creating combined zip file:', error)
    }
}

const getFilestackDownloadUrl = (urlList: string[]): string => {
    const handlesList = `[${urlList.join(',')}]`
    return `${FILESTACK_CDN_BASE_URL}zip/${handlesList}`
}
