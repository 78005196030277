const space = (config) => {
    const { chakraTheme } = config

    return {
        table: {
            header: '6px',
            row: chakraTheme.space[1],
            rowLg: chakraTheme.space[2],
            outer: chakraTheme.space[4],
            outerRight: chakraTheme.space[8],
        },
        container: {
            padding: chakraTheme.space[3],
            paddingLg: chakraTheme.space[6],
        },
        button: {
            padding: chakraTheme.space[2],
            paddingLg: chakraTheme.space[3],
            sm: {
                px: chakraTheme.space[4],
                pxLg: chakraTheme.space[4],
            },
            md: {
                px: chakraTheme.space[6],
                pxLg: chakraTheme.space[6],
            },
            filter: {
                px: chakraTheme.space[2],
                pxLg: chakraTheme.space[2],
            },
        },
        page: {
            heading: {
                mt: chakraTheme.space[6],
                mtLg: chakraTheme.space[10],
                mb: chakraTheme.space[6],
                mbLg: chakraTheme.space[6],
            },
        },
        emptyState: {
            padding: chakraTheme.space[3],
            paddingLg: chakraTheme.space[6],
        },
        admin: {
            palette: {
                sectionLabelMargin: chakraTheme.space[2],
                sectionLabelMarginLg: chakraTheme.space[2],
                widget: {
                    sectionLabelMargin: chakraTheme.space[2],
                    sectionLabelMarginLg: chakraTheme.space[2],
                },
            },
        },
    }
}

export default space
