import without from 'lodash/without'

import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    FETCH_COMPLETED,
    FETCH_STARTED,
    PERMISSION_RULE_CREATED,
    PERMISSION_RULE_FETCHED,
    PERMISSION_RULE_REMOVED,
    PERMISSION_RULE_UPDATED,
    STACK_SELECTED,
} from '../utils/constants'

export const permissionRulesInitialState = {
    permissionRules: [],
    entities: {},
    fetching: [],
    fetched: false,
}

export function permissionRuleReducer(state = permissionRulesInitialState, action) {
    let permissionRules
    let permissionRule
    let fetching
    switch (action.type) {
        case PERMISSION_RULE_FETCHED:
            if (!action.payload) return state
            permissionRules = unique([
                ...(action.overrideLocalCache ? [] : state.permissionRules),
                ...action.payload.map((pr) => pr._sid),
            ])
            return {
                ...state,
                permissionRules,
                entities: { ...state.entities, ...byID(action.payload) },
                fetched: true,
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case PERMISSION_RULE_CREATED:
            if (!action.payload) return state
            permissionRules = unique([...state.permissionRules, action.payload._sid])
            return {
                ...state,
                permissionRules,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case PERMISSION_RULE_UPDATED:
            if (!action.payload) return state
            permissionRule = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [permissionRule._sid]: permissionRule },
            }
        case PERMISSION_RULE_REMOVED:
            permissionRules = without(state.permissionRules, action.id)
            return {
                ...state,
                permissionRules,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return permissionRulesInitialState
        default:
            return state
    }
}
