import React, { useMemo } from 'react'

import { Box, OverflowList, Tag } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

type TagListProps = {
    singleLine?: boolean
    items?: (string | React.ReactElement)[]
    maxVisible?: number
    itemStyle?: React.CSSProperties
    wrapText?: boolean
    renderIndicator?: (hiddenCount: number) => React.ReactNode
}

const TagList: React.FC<TagListProps> = ({
    singleLine,
    items = [],
    maxVisible,
    itemStyle,
    wrapText,
    renderIndicator,
}) => {
    const tags = useMemo(() => {
        if (!Array.isArray(items)) return null

        return items.map((x, index) => (
            <Tag
                key={index}
                title={typeof x === 'string' ? x : undefined}
                style={{
                    marginRight: '4px',
                    marginBottom: !singleLine ? '4px' : undefined,
                    flexShrink: 0,
                    ...itemStyle,
                }}
                whiteSpace={wrapText ? 'initial' : 'nowrap'}
            >
                {x}
            </Tag>
        ))
    }, [items, itemStyle, singleLine, wrapText])

    if (!Array.isArray(items)) return null

    if (singleLine) {
        return (
            /* @ts-expect-error */
            <OverflowList
                maxWidth="100%"
                maxHeight="100%"
                className={STYLE_CLASSES.TAG_LIST}
                maxVisible={maxVisible}
                renderIndicator={renderIndicator}
            >
                {tags}
            </OverflowList>
        )
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            maxWidth="100%"
            maxHeight="100%"
            overflow="hidden"
            alignItems="center"
            className={STYLE_CLASSES.TAG_LIST}
        >
            {tags}
        </Box>
    )
}

export default React.memo(TagList)
