import React, { useMemo, useState, VFC } from 'react'
import { useWatch } from 'react-hook-form'

import styled from '@emotion/styled'

import { useObject } from 'data/hooks/objects'
import { ConfigurationComponentProps } from 'features/admin/fields/common'
import FiltersConfiguration from 'features/admin/fields/FiltersConfiguration'
import { ConfigurationField } from 'features/admin/fields/ui'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { Collapse, Dropdown, Flex, Input, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { isAllowedAutomaticLinkType, isMatchingFieldType } from './filterFieldUtils'

const { colors } = stackerTheme()

const ONE_TO_MANY_OPTION = { label: 'One to many', value: 'lookup' }
const MANY_TO_MANY_OPTION = { label: 'Many to many', value: 'multi_lookup' }

const ObjectConditionLabel = styled(Text)<{ isLocal?: boolean }>`
    border-radius: 4px;
    padding: 4px 8px;

    background-color: ${(props) =>
        props.isLocal ? colors.userInterface.neutral[150] : colors.appColors.green[100]};
    color: ${(props) =>
        props.isLocal ? colors.appColors.blue[500] : colors.userInterface.neutral[900]};
`

const LookupObjectLabel = styled(Text)<{ isLocal?: boolean }>`
    font-weight: bold;
    color: ${(props) => (props.isLocal ? colors.appColors.blue[500] : colors.appColors.green[300])};
`

const AutomaticRecordLinkConfiguration: VFC<ConfigurationComponentProps> = ({ object, field }) => {
    const relationshipType = useWatch({ name: 'type' })
    const targetObjectId = useWatch({ name: 'options.lookup_target' })
    const localLookupFieldId = useWatch({
        name: 'connection_options.relationship_local_lookup_field',
    })
    const targetLookupFieldId = useWatch({
        name: 'connection_options.relationship_target_lookup_field',
    })

    const { object: targetObject } = useObject(targetObjectId)
    const [localFieldType, setLocalFieldType] = useState<FieldType | null>(null)
    const [targetFieldType, setTargetFieldType] = useState<FieldType | null>(null)

    useMemo(() => {
        let localLookupField = object?.fields.find(({ _sid }) => _sid === localLookupFieldId)
        setLocalFieldType(localLookupField?.type || null)

        let targetLookupField = targetObject?.fields.find(
            ({ _sid }) => _sid === targetLookupFieldId
        )
        setTargetFieldType(targetLookupField?.type || null)
    }, [object, targetObject, localLookupFieldId, targetLookupFieldId])

    // Many-to-many links only work with strings. Technically we could allow matching a delimiter
    // separated string to a number field but we're not enabling that for now to stop scope creep
    const options = useMemo(() => {
        if (!localLookupFieldId) {
            return [ONE_TO_MANY_OPTION, MANY_TO_MANY_OPTION]
        }

        const localLookupField = object?.fields.find(({ _sid }) => _sid === localLookupFieldId)

        return !localLookupField || localLookupField?.type === 'string'
            ? [ONE_TO_MANY_OPTION, MANY_TO_MANY_OPTION]
            : [ONE_TO_MANY_OPTION]
    }, [object, localLookupFieldId])

    return (
        <>
            <ConfigurationField
                label="Link to another record in"
                name="options.lookup_target"
                as={ObjectPicker}
                errorMessages={{ required: 'Select a table to link to' }}
                variant="settings"
                placeholder="Select a table to link to"
                controlled
                usePortal={false}
            />
            <Collapse isOpen={!!targetObject}>
                <Flex
                    style={{ gap: '4px' }}
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="wrap"
                    mt={3}
                >
                    <Flex wrap="nowrap" style={{ gap: '4px' }}>
                        <Text>If</Text>
                        <ObjectConditionLabel isLocal>{object?.name}</ObjectConditionLabel>
                        <ConfigurationField
                            style={{ width: '125px' }}
                            name="connection_options.relationship_local_lookup_field"
                            as={FieldPicker}
                            objectId={object?._sid}
                            filter={(field: FieldDto) =>
                                isAllowedAutomaticLinkType(field) &&
                                isMatchingFieldType(field.type, targetFieldType)
                            }
                            placeholder="Select a field"
                            errorMessages={{ required: 'Select a field' }}
                            controlled
                            usePortal={false}
                            isClearable={false}
                        />
                    </Flex>

                    <Flex wrap="nowrap" style={{ gap: '4px' }}>
                        <Text>matches</Text>
                        <ObjectConditionLabel>{targetObject?.name}</ObjectConditionLabel>
                        <ConfigurationField
                            style={{ width: '125px' }}
                            name="connection_options.relationship_target_lookup_field"
                            as={FieldPicker}
                            objectId={targetObjectId}
                            filter={(field: FieldDto) =>
                                isAllowedAutomaticLinkType(field) &&
                                isMatchingFieldType(field.type, localFieldType)
                            }
                            placeholder="Select a field"
                            errorMessages={{ required: 'Select a field' }}
                            controlled
                            usePortal={false}
                            isClearable={false}
                        />
                    </Flex>
                </Flex>
                <ConfigurationField
                    show={!!targetObjectId}
                    label="Relationship type"
                    name="type"
                    as={Dropdown}
                    placeholder="Select a relationship type"
                    options={options}
                    errorMessages={{ required: 'Select a relationship type' }}
                    controlled
                    usePortal={false}
                    controlledDefaultValue={field?.type}
                />
                <ConfigurationField
                    show={
                        !!relationshipType
                            ? relationshipType === 'multi_lookup'
                            : field?.type === 'multi_lookup'
                    }
                    label="With delimiter"
                    as={Input}
                    name="connection_options.relationship_local_lookup_field_delimiter"
                    required={relationshipType === 'multi_lookup'}
                    controlled
                />
                <Flex mt={3} flexDirection="row" justifyContent="flex-start" align-items="center">
                    <Text>Each&nbsp;</Text>
                    <LookupObjectLabel isLocal>{object?.name}&nbsp;</LookupObjectLabel>
                    <Text>
                        record only link to&nbsp;
                        {relationshipType === 'lookup' ? 'a single' : 'multiple'}
                        &nbsp;
                    </Text>
                    <LookupObjectLabel>{targetObject?.name}&nbsp;</LookupObjectLabel>
                    <Text>{relationshipType === 'lookup' ? 'record.' : 'records.'}</Text>
                </Flex>
                <Flex flexDirection="row" justifyContent="flex-start" align-items="center" mt={1}>
                    <Text>Each&nbsp;</Text>
                    <LookupObjectLabel>{targetObject?.name}&nbsp;</LookupObjectLabel>
                    <Text>record can be linked to by multiple&nbsp;</Text>
                    <LookupObjectLabel isLocal>{object?.name}&nbsp;</LookupObjectLabel>
                    <Text>records.</Text>
                </Flex>
                <ConfigurationField
                    show={!!targetObjectId}
                    as={FiltersConfiguration}
                    name="connection_options.relationship_target_lookup_filters"
                    object={targetObject}
                    field={field}
                    controlled
                    required={false}
                    contextRecordObject={object}
                />
            </Collapse>
        </>
    )
}

export default AutomaticRecordLinkConfiguration
