import styled from '@emotion/styled'

import stackerTheme from 'v2/ui/theme/styles/default'
const { space, colors } = stackerTheme()

export const ViewHeaderEditorInputGroup = styled.div`
    width: 100%;
    padding-top: ${space[1]};

    & + & {
        margin-top: ${space[8]};
        border-top: 1px solid ${colors.neutral[400]};
        padding-top: ${space[5]};
    }

    label {
        display: block;
    }
`
