import React, { useEffect, useState } from 'react'

import { performOperation } from 'data/api/accountApi'
import { useJobPoller } from 'data/api/jobApi'
import { refetchAccounts } from 'data/hooks/accounts'
import { Card, Container } from 'legacy/v1/ui'

import { Button } from 'v2/ui'

import { AccountOperation, AccountOperationResponse } from './types'

export const AutomaticAuth0Settings = ({ workspaceAccount }: Account | Partial<Account>) => {
    const [jobId, setJobId] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const [isImportingMembers, setIsImportingMembers] = useState(false)

    const isAuth0Enabled = workspaceAccount?.optional_features?.auth0_enabled
    const isOrganizationSet = workspaceAccount?.auth0_organization_id
    const isLoading = isUpdating || isImportingMembers

    const polling = useJobPoller(jobId, () => undefined, 600)

    useEffect(() => {
        if (polling.isComplete) {
            if (!polling.isSuccessful) {
                alert(
                    'Could not set up auth0 members for account. Please contact engineering for support.'
                )
            }
            alert('Successfully set up auth0 members for account!')
            setIsImportingMembers(false)
        }
    }, [polling.isComplete, polling.isSuccessful])

    const performAccountOperation = async (operationName: AccountOperation) => {
        setIsUpdating(true)
        await performOperation(workspaceAccount, operationName)
            // TODO: use the correct types once we have `fetchAndReturn` in TS.
            .then((rawResponse: Record<string, any>) => {
                const typedResponse = rawResponse as AccountOperationResponse
                const job_sid = typedResponse?.job_sid
                if (job_sid) {
                    setJobId(job_sid)
                    setIsImportingMembers(true)
                }
                refetchAccounts()
            })
            .catch((ex) => {
                console.error(ex)
                alert('Failed, see console log.')
            })
        setIsUpdating(false)
    }

    return (
        <>
            <div style={{ color: '#e9e9ea', fontSize: 15 }}>
                A0FA ONLY (NON-ENTERPRISE SSO ONLY) - Automated Operations
            </div>
            =
            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                =
                <Container wrapItems>
                    <Button
                        variant="Primary"
                        buttonSize="sm"
                        style={{ marginRight: '10px' }}
                        isDisabled={isAuth0Enabled || isLoading}
                        isLoading={isImportingMembers}
                        onClick={() => performAccountOperation('setup_auth0')}
                    >
                        Set Up Auth0 [Non-Enterprise SSO]
                    </Button>

                    <Button
                        buttonSize="sm"
                        isDisabled={!isAuth0Enabled || isLoading}
                        style={{ marginRight: '10px' }}
                        onClick={() => performAccountOperation('setup_auth0')}
                        isLoading={isImportingMembers}
                    >
                        Resync Auth0 Account [Non-Enterprise SSO]
                    </Button>
                    <Button
                        buttonSize="sm"
                        isDisabled={(!isAuth0Enabled && !isOrganizationSet) || isLoading}
                        style={{ backgroundColor: '#ffa500' }}
                        onClick={() => performAccountOperation('rollback_automatic_auth0')}
                    >
                        [DANGER ZONE] Rollback Auth0 Configuration
                    </Button>
                </Container>
            </Card>
            <div style={{ color: '#e9e9ea', fontSize: 15 }}>
                ENTERPRISE SSO - Automated Operations
            </div>
            =
            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                =
                <Container wrapItems>
                    <Button
                        variant="Primary"
                        buttonSize="sm"
                        style={{ marginRight: '10px' }}
                        isDisabled={isAuth0Enabled || !isOrganizationSet || isLoading}
                        onClick={() => performAccountOperation('enable_auth0_for_enterprise')}
                    >
                        Enable Auth0 for Enterprise SSO
                    </Button>
                </Container>
            </Card>
        </>
    )
}
