import React, { VFC } from 'react'

import { AppUsersModalContent } from 'features/users/app-users-modal/AppUsersModal'
import MigratedFromV3Banner from 'features/users/app-users-modal/MigratedFromV3Banner'

import { Box, Flex } from 'v2/ui'

import { SlidingPaneFrame } from '../common/SlidingPaneFrame'

type ManageUsersSidePaneProps = {}
export const ManageUsersSidePane: VFC<ManageUsersSidePaneProps> = () => (
    <SlidingPaneFrame title="Manage users" description="Manage who has access to this app.">
        <Flex
            p={4}
            flexGrow={1}
            overflowY="auto"
            alignItems="stretch"
            flexDirection="column"
            justifyContent="space-between"
        >
            <Flex width="100%" overflowY="auto" flex={1} mb={4}>
                <AppUsersModalContent isOpen={true} />
            </Flex>
            <Box width="100%">
                <MigratedFromV3Banner borderRadius="5px" />
            </Box>
        </Flex>
    </SlidingPaneFrame>
)
