import React, { FC } from 'react'

import { Flex, Icon, Link, ScrollBox } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { useActionsContext } from './ActionsContextProvider'
import { Spacer } from './ui'

const { colors } = stackerTheme()

type Props = {
    steps: ActionStep[]
}

export const ActionStepsList: FC<Props> = ({ steps }) => (
    <ScrollBox
        style={{ background: colors.userInterface.neutral[500], padding: 8 }}
        flexGrow={1}
        minHeight={100}
    >
        {steps.map((step, index) => (
            <>
                <ActionStep step={step} />
                {index < steps.length - 1 && <Spacer />}
            </>
        ))}
    </ScrollBox>
)

const ActionStep: FC<{ step: ActionStep }> = ({ step }) => {
    const { addModalToStack, removeModalFromStack } = useActionsContext()

    const setIsModalOpen = (stepId: string) => {
        if (stepId) {
            addModalToStack('editStep', { stepId })
        } else {
            removeModalFromStack()
        }
    }

    return (
        <>
            <Flex
                backgroundColor="white"
                px={4}
                py={2}
                borderRadius={4}
                color={colors.grey[600]}
                border={`1px solid ${colors.grey[300]}`}
            >
                <Link
                    as="div"
                    flex={1}
                    onClick={() => setIsModalOpen(step.id)}
                    color={colors.userInterface.neutral[1000]}
                    data-testId={step.displayName.toLowerCase().replace(/\s+/g, '-') + '-step'}
                >
                    {step.title ? `${step.title} (${step.displayName})` : step.displayName}
                </Link>
                <Icon
                    icon="cog"
                    onClick={() => setIsModalOpen(step.id)}
                    color={colors.userInterface.neutral[1000]}
                    style={{ cursor: 'pointer' }}
                />
            </Flex>
        </>
    )
}
