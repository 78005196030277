import isEqual from 'lodash/isEqual'

import { ListViewOrderBy, SetOrderBy } from './types'

type setNewOrderByValueProps = {
    newValue: ListViewOrderBy
    orderBy: ListViewOrderBy
    setOrderBy: SetOrderBy
}

export const setNewOrderByValue = ({ newValue, orderBy, setOrderBy }: setNewOrderByValueProps) => {
    if (!isEqual(newValue, orderBy)) setOrderBy(newValue)
}
