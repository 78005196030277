import React from 'react'

import { useTheme } from '@chakra-ui/react'

import { Box, Button, Icon } from 'v2/ui'
import { SolidChevronDown, SolidClose } from 'v2/ui/svgs'

type InlineFilterMenuButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    onClear?: () => void
    showClearButton?: boolean
    isActive?: boolean
}

export const InlineFilterMenuButton = React.forwardRef<
    HTMLButtonElement,
    InlineFilterMenuButtonProps
>(({ isActive, showClearButton, onClear, ...props }, ref) => {
    const theme = useTheme()
    const isExpanded: boolean = props['aria-expanded']

    const isUsed = isExpanded || isActive

    return (
        <Button
            fontSize="sm"
            py={1.5}
            pl={2}
            pr={2}
            lineHeight={1}
            fontWeight="bold"
            iconAlign="right"
            border="1.5px solid"
            borderColor={isUsed ? 'brand.500' : 'userInterface.neutral.400'}
            boxShadow="0px 3px 4px -5px rgba(24, 24, 28, 0.13), 0px 1px 2px rgba(24, 24, 28, 0.14)"
            icon={
                <Box
                    ml={1}
                    py={showClearButton ? 0.5 : 0}
                    transform={isExpanded && !showClearButton ? 'rotate(-180deg)' : 'rotate(0deg)'}
                    _hover={{
                        opacity: showClearButton ? 0.6 : 1,
                    }}
                    onClick={
                        showClearButton
                            ? (e) => {
                                  e.stopPropagation()
                                  onClear?.()
                              }
                            : undefined
                    }
                >
                    {showClearButton ? (
                        <SolidClose size={16} color={theme.colors.userInterface.neutral[1000]} />
                    ) : (
                        <Icon
                            size="20px"
                            fill="userInterface.neutral.600"
                            svg={<SolidChevronDown />}
                        />
                    )}
                </Box>
            }
            bgColor="userInterface.neutral.0"
            color="userInterface.neutral.1000"
            _focus={{
                boxShadow:
                    '0px 3px 4px -5px rgba(24, 24, 28, 0.13), 0px 1px 2px rgba(24, 24, 28, 0.14)!important',
            }}
            _hover={{
                bgColor: isUsed ? undefined : 'userInterface.neutral.100',
            }}
            _active={{
                bgColor: isUsed ? undefined : 'userInterface.neutral.200',
            }}
            _expanded={{
                boxShadow: '0px 0px 0px 2px var(--chakra-colors-brand-300)!important',
            }}
            _focusVisible={{
                bgColor: 'userInterface.neutral.0',
                borderColor: 'brand.500',
                boxShadow: '0px 0px 0px 2px var(--chakra-colors-brand-300)!important',
            }}
            _disabled={{
                borderColor: 'userInterface.neutral.400',
                color: 'userInterface.neutral.700',
                cursor: 'not-allowed',
                bgColor: 'userInterface.neutral.0',
                boxShadow:
                    '0px 3px 4px -5px rgba(24, 24, 28, 0.13), 0px 1px 2px rgba(24, 24, 28, 0.14)!important',
                _hover: {
                    bgColor: 'userInterface.neutral.0',
                },
                _active: {
                    bgColor: 'userInterface.neutral.0',
                },
            }}
            {...props}
            ref={ref}
        />
    )
})
