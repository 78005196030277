// @ts-strict-ignore
// https://getanalytics.io/plugins/segment/
import segmentPlugin from '@analytics/segment'
import Analytics from 'analytics'

import settings from 'app/settings'

const analyticPlugins: any[] = []

// Don't load segment when running tests or if running the FE locally
if (settings.NODE_ENV !== 'test' && !settings.IS_LOCAL_INSTANCE) {
    analyticPlugins.push(
        segmentPlugin({
            writeKey: settings.SEGMENT_KEY,
            customScriptSrc: `https://segment-cdn.stackerhq.com/analytics.js/v1/${settings.SEGMENT_KEY}/analytics.min.js`,
        })
    )
}
// Plugin to just log instead of tracking for dev environments
// and stop events for triggering when support login is true
// https://www.npmjs.com/package/analytics
// https://getanalytics.io/plugins/writing-plugins/#2-custom-plugins
analyticPlugins.push({
    name: 'custom',
    initialize: ({}) => {
        // Here is where you would load any scripts - passed as `config`.
        return true
    },
    page: ({ payload }) => {
        if (localStorage.getItem('support_login') === 'true') {
            return true
        }
        if (!settings.IS_PROD) console.log('[Analytics] Page -', payload)
    },
    track: ({ payload }) => {
        if (localStorage.getItem('support_login') === 'true') {
            return true
        }
        if (!settings.IS_PROD) console.log(`[Analytics] Track -`, payload)
    },
    identify: ({ payload }) => {
        if (localStorage.getItem('support_login') === 'true') {
            return true
        }
        if (!settings.IS_PROD) console.log('[Analytics] Identify -', payload)
    },
    loaded: () => {
        // return boolean so analytics knows when it can send data to third-party
        return true
    },
})

const analytics = Analytics({
    app: 'stacker-web-app',
    // If we're in production, disable debug
    debug: process.env.NODE_ENV !== 'production',
    plugins: analyticPlugins,
})

analytics.on('ready', () => {
    analytics.page()
    // Analytics are ready. Segment should be reachable via the global window object via window.analytics
    // @ts-ignore
    // console.log(`Analytics ready`, window?.analytics)
    // Overwrite anon ID for cross domain tracking if needed
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#override-the-anonymous-id-from-the-segment-snippet
    // window.analytics.anonymousId('123')
})
export default analytics
