import React, { FC, ReactElement } from 'react'

import { TabList, TabPanels } from '@chakra-ui/react'

import { Flex, Tab, TabPanel } from 'v2/ui'
import { TabsWithOverflow } from 'v2/ui/components/Tabs'

type Props = {
    aggregationWidget: MetricsBlockAttributes
    dataPanel: ReactElement
    displayPanel: ReactElement
    filtersPanel: ReactElement
}

export const AggregationEditor: FC<Props> = ({
    aggregationWidget,
    dataPanel,
    displayPanel,
    filtersPanel,
}) => {
    if (!aggregationWidget) {
        return null
    }

    return (
        <TabsWithOverflow variant="admin" p={3}>
            <TabList>
                <Tab variant="admin" w="33%">
                    Data
                </Tab>
                <Tab variant="admin" w="33%">
                    Display
                </Tab>
                <Tab variant="admin" w="33%">
                    Filters
                </Tab>
            </TabList>
            <TabPanels overflow="auto" mt={4}>
                <TabPanel
                    as={Flex}
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    rowGap={3}
                >
                    {dataPanel}
                </TabPanel>
                <TabPanel
                    as={Flex}
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    rowGap={3}
                >
                    {displayPanel}
                </TabPanel>
                <TabPanel>{filtersPanel}</TabPanel>
            </TabPanels>
        </TabsWithOverflow>
    )
}
