import React, { useState } from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import HELP_LINKS from 'app/helpLinks'
import { useUpdateStack } from 'data/hooks/stacks'
import CodeEditor from 'features/admin/settings/appearance/CodeEditor'
import { FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'
import { ExpandSection } from 'legacy/v1/ui'

import { Box, Button, Text } from 'v2/ui'
import { FEATURES, ProtectedFeature, ProtectedFeatureIndicator } from 'v2/ui/utils/ProtectedFeature'

import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

type CustomScriptsSelfServeProps = {
    showUpgradeButton: boolean
    isCustomScriptsLocked: boolean
    selectedStack: StackDto
    onSave: () => void
    onSaveError: () => void
    formRef: HTMLElement
}

export const CustomScriptsSelfServe = ({
    showUpgradeButton,
    isCustomScriptsLocked,
    selectedStack,
    onSave,
    onSaveError,
    formRef,
}: CustomScriptsSelfServeProps) => {
    const { hasRight } = useAccountUserContext()
    const [showCustomScriptsUpgrade, setCustomScriptsUpgrade] = useState<boolean>(false)
    const { mutateAsync: updateStack } = useUpdateStack()
    const canAddScripts = hasRight(Rights.SelfServeCustomScripts)
    return (
        <>
            <ExpandSection
                always
                className="admin-app-settings-custom-scripts"
                //@ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Custom Scripts
                        <ProtectedFeatureIndicator
                            onClick={() => setCustomScriptsUpgrade(true)}
                            feature={FEATURES.selfServeCustomScripts}
                            ml={3}
                        />
                    </div>
                }
                helpText="How to set up your custom scripts"
                text={null}
            >
                {
                    <>
                        {showUpgradeButton && isCustomScriptsLocked && (
                            <Button
                                marginRight="auto"
                                variant="upgradePrimary"
                                onClick={() => setCustomScriptsUpgrade(true)}
                            >
                                Upgrade
                            </Button>
                        )}
                        <Text fontSize={16} mt={2} pt={2}>
                            Integrate custom scripts from third-party apps such as Weglot, Posthog,
                            UserVoice and more. Learn more in our{' '}
                            <a
                                href={HELP_LINKS.SELF_SERVE_CUSTOM_SCRIPTS}
                                target="_blank"
                                rel="noreferrer"
                            >
                                help center.
                            </a>
                            {isCustomScriptsLocked &&
                                ' Upgrade to Stacker Enterprise to access this feature.'}
                        </Text>

                        {!canAddScripts && (
                            <Text fontSize={14} mt={2}>
                                {`To configure custom scripts, your workspace owner needs to enable
                                this in the workspace's settings.`}
                            </Text>
                        )}

                        {!isCustomScriptsLocked && canAddScripts && (
                            <Form
                                onSubmit={(data) => {
                                    const { custom_scripts, custom_page_scripts } = data
                                    return updateStack({
                                        id: selectedStack._sid,
                                        patch: {
                                            options: {
                                                ...selectedStack.options,
                                                custom_page_scripts: custom_page_scripts,
                                                custom_scripts: custom_scripts,
                                            },
                                        },
                                    })
                                        .then(() => {
                                            onSave()
                                        })
                                        .catch(() => {
                                            onSaveError()
                                        })
                                }}
                                options={{
                                    defaultValues: {
                                        custom_scripts: selectedStack?.options?.custom_scripts,
                                        custom_page_scripts:
                                            selectedStack?.options?.custom_page_scripts,
                                    },
                                }}
                            >
                                <FormFrame
                                    title={null}
                                    formRef={formRef}
                                    padding={0}
                                    paddingFooter={`20px 0 0 0`}
                                >
                                    <Text fontWeight="bold" color="userInterface.neutral.1000">
                                        JS to run on every page
                                    </Text>
                                    <Box border="1px solid #ccc" mt={2} mb={2}>
                                        <FormField
                                            style={{ pointerEvents: 'none' }}
                                            as={CodeEditor}
                                            name="custom_page_scripts"
                                            formRef={formRef}
                                            language="html"
                                            controlled
                                        />
                                    </Box>
                                    <Text
                                        fontWeight="bold"
                                        color="userInterface.neutral.1000"
                                        mt={8}
                                    >
                                        JS to run only once
                                    </Text>
                                    <Box border="1px solid #ccc" mt={1}>
                                        <FormField
                                            as={CodeEditor}
                                            name="custom_scripts"
                                            formRef={formRef}
                                            language="html"
                                            controlled
                                        />
                                    </Box>
                                </FormFrame>
                            </Form>
                        )}
                    </>
                }
            </ExpandSection>
            <ProtectedFeature
                feature={FEATURES.selfServeCustomScripts}
                showModal={showCustomScriptsUpgrade}
                onModalClosed={() => setCustomScriptsUpgrade(false)}
            />
        </>
    )
}
