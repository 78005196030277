import without from 'lodash/without'

import {
    APP_CREATED,
    APP_REMOVED,
    APP_SELECTED,
    APP_UPDATED,
    APPS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from 'data/utils/constants'
import { unique } from 'utils/utils'

import { byID } from '../utils/byID'

// STATE
export const initialState = {
    apps: [],
    entities: {},
    fetching: [],
    selected: null,
}

// REDUCERS
export function appReducer(state = initialState, action) {
    let apps
    let app
    let fetching
    switch (action.type) {
        case APPS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                apps: unique([...state.apps, ...action.payload.map((a) => a._sid)]),
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case APP_CREATED:
            if (!action.payload) return state
            apps = [...state.apps, [action.payload._sid]]
            return {
                ...state,
                apps,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case APP_UPDATED:
            if (!action.payload) return state
            app = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [app._sid]: app },
            }
        case APP_REMOVED:
            apps = state.apps.map((as) => without(as, action.id))
            return {
                ...state,
                apps,
            }
        case APP_SELECTED:
            return {
                ...state,
                selected: action.id,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
