import React, { isValidElement } from 'react'

import { get } from 'lodash'
import keyBy from 'lodash/keyBy'
import getFieldPerms from 'v2/views/utils/getFieldPerms'

import { useProcessFilter } from 'features/records/components/RecordFilters'
import EditableLayoutObject from 'features/utils/EditableLayoutObject'

import { Container, Divider, FixedGrid, Flex, Text } from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'

import ProfileCardEditor from './ProfileCardEditor'

const ProfileCard = ({
    context,
    config,
    profileImage,
    title,
    showControls,
    setConfig,
    recordPermissions,
    ...props
}) => {
    const processFilter = useProcessFilter()
    const fieldConditionalVisibilityFilters = config?.profileFieldConditionalVisibilityFilters

    const fields = config.profileFields
        ? filterProfileFields(
              config.profileFields,
              context,
              recordPermissions,
              fieldConditionalVisibilityFilters,
              processFilter,
              showControls
          )
        : []

    const isInlineCreate = get(context, 'view.isInlineCreate')

    return (
        <EditableLayoutObject
            title="Profile fields"
            editor={
                <ProfileCardEditor object={context.object} config={config} setConfig={setConfig} />
            }
        >
            {(showEditor) => (
                <Container
                    width="100%"
                    display="flex"
                    flexDirection={['column', 'column', 'row', 'column']}
                    alignItems="stretch"
                    mr={4}
                    mb={0}
                    onMouseDownCapture={showControls ? showEditor : null}
                    p={[
                        'container.paddingLg',
                        'container.paddingLg',
                        'container.paddingLg',
                        'container.paddingLg',
                    ]}
                    {...props}
                >
                    {profileImage}
                    <Flex column wrap="nowrap" align="stretch">
                        {isValidElement(title) ? (
                            title
                        ) : (
                            <Text
                                as="h2"
                                variant="profileCardTitle"
                                alignSelf={['center', 'center', 'start', 'center']}
                                maxWidth="100%"
                                textAlign="center"
                            >
                                {title}
                            </Text>
                        )}
                        <Divider variant="default" my={'container.padding'} />
                        <FixedGrid columns={[1, 1, 2, 1]} gap={4}>
                            {fields.map(
                                ({
                                    fieldId,
                                    required,
                                    fullWidth,
                                    readOnly,
                                    label,
                                    description,
                                    ...otherOptions
                                }) => (
                                    <AttributeDisplay
                                        isInlineCreate={isInlineCreate}
                                        key={fieldId}
                                        objectId={context.objectId}
                                        fieldId={fieldId}
                                        record={context.record}
                                        required={required}
                                        fullWidth={fullWidth}
                                        readOnly={readOnly}
                                        editing={context.view.editing}
                                        showErrors={context.view.showErrors}
                                        setValue={context.view.actions.setValue}
                                        setValid={context.view.actions.setValid}
                                        valid={context.view.valid}
                                        isLoading={context.view?.isLoading}
                                        isVisible={true}
                                        labelOverride={label}
                                        editDescription={description}
                                        {...otherOptions}
                                    />
                                )
                            )}
                        </FixedGrid>
                    </Flex>
                </Container>
            )}
        </EditableLayoutObject>
    )
}

const filterProfileFields = (
    profileFields,
    context,
    recordPermissions,
    fieldConditionalVisibilityFilters,
    processFilter,
    showControls
) => {
    // Check the record permissions and mark certain fields as read only or not.

    const objectPermissions = get(context, 'object', {}) || {}
    const [allowedFieldNamesToRead, allowedFieldNamesToUpdate, allowedFieldNamesToCreate] =
        getFieldPerms(recordPermissions, objectPermissions)

    const isCreate = get(context, 'view.creating', false)
    let writable = false
    let writableFields = []
    if (isCreate) {
        writable = get(objectPermissions, 'may_create', false)
        writableFields = allowedFieldNamesToCreate
    } else {
        writable = get(recordPermissions, 'may_update', false)
        writableFields = allowedFieldNamesToUpdate
    }

    const fields = keyBy(get(context, 'object.fields', []) || [], (f) => f.api_name)

    let allowedFields = []
    profileFields.forEach(({ fieldId, fieldName, ...rest }) => {
        const passesVisibilityFilters =
            showControls ||
            !fieldConditionalVisibilityFilters?.[fieldId] ||
            processFilter([context.record], fieldConditionalVisibilityFilters[fieldId])?.length > 0
        if (allowedFieldNamesToRead.includes(fieldName) && passesVisibilityFilters) {
            const fieldIsWritable = writable && writableFields.includes(fieldName)
            const readOnly =
                !fieldIsWritable || get(fields, `${fieldName}.connection_options.read_only`)
            allowedFields.push({ fieldId, fieldName, ...rest, readOnly })
        }
    })

    return allowedFields
}

export default ProfileCard
