// @ts-strict-ignore
import React, { useState } from 'react'

import { Box } from 'v2/ui'

import { BackgroundImage, FixedGrid, Lightbox } from '../../v2/ui'

type SimpleGalleryProps = {
    images: string[]
}

function SimpleGallery({ images }: SimpleGalleryProps) {
    const [selectedImage, setSelectedImage] = useState<string | null>(null)

    return (
        <>
            {/* @ts-ignore */}
            <FixedGrid columns={[1, 1, 2, 2]} spacing="12px">
                {images.map((url, index) => (
                    <ImageCard
                        key={index}
                        url={url}
                        onClick={() => {
                            setSelectedImage(url)
                        }}
                    />
                ))}
            </FixedGrid>
            <Lightbox
                images={images}
                selectedImage={selectedImage}
                isOpen={!!selectedImage}
                onClose={() => setSelectedImage(null)}
                allowOpen={false}
            />
        </>
    )
}

function ImageCard({ url, onClick }) {
    return (
        <>
            {/* @ts-ignore */}
            <Box onClick={onClick} borderRadius="lg" style={{ cursor: 'pointer' }}>
                <BackgroundImage src={url} borderRadius="lg" w="100%" h="200px" />
            </Box>
        </>
    )
}

export default SimpleGallery
