import * as Sentry from '@sentry/react'
import saveAs from 'file-saver'

import { filtersToQueryDict } from 'features/utils/filtersToQueryDict'

import { buildUrl, fetchAndReturn, fetchAndReturnRaw, StackerAPI } from '../utils/utils'

import { waitUntilRecordUnlocked } from './recordLock'

function buildQueryDictFromFilters(filters, options) {
    const queryDict = filtersToQueryDict(filters)

    if (options && options.dereference) {
        queryDict._dereference = true
    }
    if (options && options.dereferenceMultiFields) {
        queryDict._dereferenceList = JSON.stringify(options.dereferenceMultiFields)
    }

    if (options && options.disablePartials) {
        queryDict._disablePartials = options.disablePartials
    }
    if (options && options.includeFields) {
        // Back end has a weird behavior where an empty array has no effect. So
        // to tell the backend we don't need any fields, we pass [null]
        const fields = options.includeFields.length > 0 ? options.includeFields : [null]
        queryDict._includeFields = JSON.stringify(fields)
    }
    if (options && !!options.excludeRecordsIdFromCsv) {
        queryDict._excludeRecordsIdFromCsv = options.excludeRecordsIdFromCsv
    }
    if (options && options.csvFieldsOrder) {
        queryDict._csvFieldsOrder = JSON.stringify(options.csvFieldsOrder)
    }
    if (options && options.searchFields) {
        const fields = options.searchFields.length > 0 ? options.searchFields : [null]
        queryDict._searchFields = JSON.stringify(fields)
    }
    if (options && options.forcePartials) {
        queryDict._forcePartials = true
    }
    if (options && options.orderBy && options.orderBy.id) {
        queryDict.order_by = `${options.orderBy.desc ? '-' : ''}${options.orderBy.id}`
    }
    if (options && options.pageSize) {
        queryDict._count = options.pageSize
    }
    if (options && options.pageIndex) {
        queryDict._start = options.pageSize * options.pageIndex
    }
    if (options && options.trigger) {
        queryDict._trigger = options.trigger
    }

    return queryDict
}

// API
class RecordApi extends StackerAPI {
    path = 'records/'

    onFetchFailed = (error, extra) => {
        // Connection error. Wrapping so a failure here
        // doesn't mask the underlying error
        try {
            Sentry.withScope((scope) => {
                scope.setTags(extra)
                scope.setTag('error', error)
                if (error instanceof Error) {
                    Sentry.captureMessage('Record fetch failed: connection error')
                    // Response object from server error
                } else if (error.status) {
                    Sentry.captureMessage(`Record fetch failed: Server error (${error.status})`)
                } else {
                    Sentry.captureException(`Record fetch failed: Unknown error type`)
                }
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    getObjectRecords(objectId, filters, options, params = {}, returnRawResponse = false) {
        if (!objectId) {
            return Promise.reject()
        }

        let path
        if (
            ['app', 'page', 'object', 'field', 'data_connection', 'feature'].indexOf(objectId) !==
            -1
        ) {
            // Only replaces one _, but that's all we need right now!
            path = `${objectId.replace('_', '-')}s/`
        } else {
            path = `objects/${objectId}/records/`
        }

        const queryDict = buildQueryDictFromFilters(filters, options)
        if (returnRawResponse) {
            return fetchAndReturnRaw(buildUrl(path, queryDict), params, null, options)
        }

        return fetchAndReturn(buildUrl(path, queryDict), params, null, this.onFetchFailed, options)
    }

    async downloadCsvRecords({
        object,
        filename,
        viewId,
        filters,
        includeFields,
        disablePartials,
        orderBy,
        csvFieldsOrder,
        excludeRecordsIdFromCsv = false,
        ignorePreviewAndImpersonation = false,
    }) {
        const response = await recordApi.getObjectRecords(
            object._sid,
            filters,
            {
                includeFields,
                disablePartials,
                orderBy,
                viewId,
                csvFieldsOrder,
                excludeRecordsIdFromCsv,
                ignorePreviewAndImpersonation,
            },
            { headers: { Accept: 'text/csv' } },
            true
        )

        const blob = await response.blob()
        saveAs(blob, filename)
    }

    async getRecord(recordId, options) {
        if (!recordId) {
            return Promise.reject()
        }
        if (typeof recordId !== 'string') {
            Sentry.captureMessage(`getRecord called with non-string (${recordId})`)
            return Promise.reject()
        }

        let path = `records/${recordId}/`

        if (recordId.startsWith('feature.')) {
            path = `features/${recordId}/`
        }

        const queryDict = filtersToQueryDict([])

        if (options && options.dereference) {
            queryDict._dereference = true
        }
        // Collect is now not used in the backend
        // if (options && options.collect) {
        //     queryDict._collect = options.collect
        // }
        if (options && options.noCache) {
            queryDict._noCache = true
        }

        await waitUntilRecordUnlocked(recordId)

        // return fetchAndReturn(path, {})
        return fetchAndReturn(buildUrl(path, queryDict), {}, null, this.onFetchFailed)
    }

    importCsv(objectId, batchId) {
        const path = `objects/${objectId}/records/import/`
        fetchAndReturn(
            buildUrl(path),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ batch_id: batchId }),
            },
            null,
            this.onFetchFailed
        )
    }

    generateSampleRecords(objectId) {
        const path = `objects/${objectId}/records/generate/`
        return fetchAndReturn(
            buildUrl(path),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
            },
            null,
            this.onFetchFailed
        )
    }
}

export const recordApi = new RecordApi()
