import React from 'react'

import { getHeaderOptionsFromListViewOptions, patchListViewHeader } from './listViewHeaderUtils'
import ViewHeaderEditor from './ViewHeaderEditor'

type ListViewHeaderEditorProps = {
    onChange: (patch: Partial<ListViewOptions>) => void
    listOptions?: ListViewOptions
}

export const ListViewHeaderEditor: React.FC<ListViewHeaderEditorProps> = ({
    onChange,
    listOptions,
}) => {
    const headerOptions = getHeaderOptionsFromListViewOptions(listOptions)

    const handleOnChange = (patch: Partial<PageHeader>) => {
        const newListOptions = patchListViewHeader(listOptions, patch)
        onChange(newListOptions)
    }

    return <ViewHeaderEditor onChange={handleOnChange} pageHeader={headerOptions} />
}
