/* Code Quality: Not audited */

import React, { useContext } from 'react'

import orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'

import AppContext from 'app/AppContext'
import {
    useAccounts,
    useCreateAccount,
    useDeleteAccount,
    useUpdateAccount,
} from 'data/hooks/accounts'

function WithAccounts({ children }) {
    const { data: accounts, status } = useAccounts()
    const { mutateAsync: createAccount } = useCreateAccount()
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const { mutateAsync: deleteAccount } = useDeleteAccount()

    const { workspaceAccount: account } = useContext(AppContext)

    return children({
        accounts: orderBy(accounts, 'created_date'),
        createAccount,
        onChange: updateAccount,
        updateAccount,
        deleteAccount,
        fetched: status === 'success',
        accountActions: undefined,
        workspaceAccount: account,
    })
}

WithAccounts.propTypes = {
    children: PropTypes.func.isRequired,
}

export default WithAccounts

export const withAccounts = (Child) => (props) =>
    <WithAccounts>{(accountProps) => <Child {...accountProps} {...props} />}</WithAccounts>
