// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import AppUserContext, { Rights } from 'app/AppUserContext'
import { getUrl } from 'app/UrlService'
import { useAppErrors } from 'data/hooks/appErrors'
import { useViewFromId } from 'data/hooks/views'
import { withFeatures } from 'data/wrappers/WithFeatures'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { useObjectFromId } from 'features/actions/helpers'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { dateDifferenceToString, sortByKey } from 'utils/utils'

import { Box, Button, MenuItem, ScrollBox } from 'v2/ui'
import { Warning } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { useAppSettings } from '../workspace/AdminSideTray/hooks/useAppSettings'
import { useOpenAppUsers } from '../workspace/AdminSideTray/hooks/useOpenAppUsers'

const { colors } = stackerTheme()

type ErrorMenuProps = {
    stack: any
    isStudioUser: boolean
    children?: any
    maxBoxHeight?: string
    as?: JSX.Element
    props?: any
}

const ErrorMenu = ({
    children,
    maxBoxHeight = 'initial',
    as,
    isStudioUser,
    ...props
}: ErrorMenuProps): JSX.Element | null => {
    const { isImpersonating, hasRight } = useContext(AppUserContext)
    const { workspaceAccount } = useAppContext()
    const customerAccess = !workspaceAccount?.optional_features?.sync_stacker_users

    const [appErrors, setAppErrors] = useState([])

    const { data } = useAppErrors()

    // @ts-ignore
    const isAdmin = isStudioUser && !isImpersonating && hasRight(Rights.Admin.Any)

    useEffect(() => {
        if (data) {
            let fetchedErrors = []
            // Data from backend in the form of {layout_filter_errors: [], user_access_errors: [], permission_rule_errors: []}.
            // We will get all the values of the keys and combine them into a single array.
            Object.keys(data).forEach((key) => {
                fetchedErrors = fetchedErrors.concat(data[key])
            })
            // Show the latest app errors first.
            setAppErrors(sortByKey(fetchedErrors, 'modified_date', false))
        }
    }, [data])

    return appErrors.length > 0 && isAdmin ? (
        <Menu placement="top">
            {/* @ts-expect-error */}
            <MenuButton as={as || Button} {...props}>
                {children}
            </MenuButton>
            <MenuList style={{ maxWidth: '280px' }}>
                <ScrollBox style={{ maxHeight: maxBoxHeight }}>
                    {appErrors.map((error) => {
                        return (
                            <ErrorNotificationItem
                                key={error}
                                error={error}
                                customerAccessEnabled={customerAccess}
                            />
                        )
                    })}
                </ScrollBox>
            </MenuList>
        </Menu>
    ) : null
}

export default withStack(withFeatures(withUser(ErrorMenu)))

type errorTypeToValue = {
    user_profile: string
    permission_rule: string
    user_list_filter: string
    user_list_email_field: string
    record_filter: string
    synthetic_link: string
    user_list_table: string
}

const ERROR_TYPE_TO_LABEL: errorTypeToValue = {
    user_profile: 'User profile',
    permission_rule: 'Permission rule',
    user_list_filter: 'User list filter',
    user_list_email_field: 'User list email field',
    record_filter: 'Filter',
    synthetic_link: 'Relationship field',
    user_list_table: 'User table',
}

function ErrorNotificationItem({
    error,
    customerAccessEnabled,
}: {
    error: any
    customerAccessEnabled: boolean
}): JSX.Element {
    const { object }: { object: ObjectDto | undefined | null } = useObjectFromId(error.object_id)
    const view = useViewFromId(error?.view_id)
    const { open: openAppSettings } = useAppSettings()
    const { showManageData } = useSlidingPane()

    const dateDifference = error.modified_date && dateDifferenceToString(error.modified_date)

    const history = useHistory()

    const openAppUsers = useOpenAppUsers()

    const handleErrorClick = useCallback(
        (error) => {
            switch (error.error_type) {
                // Since error checking checks for both issues with customer lists and user lists in the NUM,
                // We should check if customer access enabled to determine whether to open the customer access modal
                // Or the user table modal (NUM)
                case 'user_profile':
                    openAppSettings({ page: { name: 'user_profiles' } })
                    break
                case 'permission_rule':
                    openAppSettings({
                        page: {
                            name: 'permissions',
                        },
                    })
                    break
                case 'user_list_filter':
                case 'user_list_email_field':
                case 'user_list_table':
                    if (customerAccessEnabled) {
                        openAppSettings({
                            page: {
                                name: 'customer_access',
                                meta: {
                                    section: error.list_id,
                                },
                            },
                        })
                    } else {
                        openAppUsers()
                    }
                    break
                case 'record_filter':
                    history.push(getUrl(`${view?.url}`))
                    break
                case 'synthetic_link':
                    if (object?._sid) showManageData(object._sid)
                    break
                default:
                    break
            }
        },
        [
            object,
            customerAccessEnabled,
            history,
            view,
            openAppUsers,
            openAppSettings,
            showManageData,
        ]
    )

    const getMessage = (error, object) => {
        // Get the appropriate message based on the error type.
        // Note: We do have a label for the invalid filters.
        // However, at the moment, we only show 'invalid filter' so specifying the label of the broken filters doesn't benefit the user
        // And only adds an extra layer of complexity/confusion to the message
        switch (error.error_type) {
            case 'permission_rule':
                return (
                    <>
                        Invalid permission rule at
                        <strong>
                            {' '}
                            {object?.name} table
                            {' > '}
                            {error.rule_label}
                        </strong>
                        .
                    </>
                )

            case 'user_list_filter':
                return (
                    <>
                        Invalid filter in user list: <strong>{error.list_name}</strong>.
                    </>
                )

            case 'user_list_email_field':
                return (
                    <>
                        Invalid email field for user list: <strong>{error.list_name}</strong>.
                    </>
                )

            case 'record_filter':
                return (
                    <>
                        Invalid layout filter in <strong>{view?.name}</strong> view.
                    </>
                )

            case 'synthetic_link':
                return (
                    <>
                        {object?.name} table contains an invalid relationship field:{' '}
                        <strong>{error.field_label}</strong>.
                    </>
                )

            default:
                break
        }
    }

    const message = getMessage(error, object)

    return (
        <MenuItem
            as={'button'}
            onClick={() => handleErrorClick(error)}
            style={{ display: 'block', padding: '13px' }}
            onMouseEnter={(event) => {
                // Prevent Chakra from focusing the last item which causes it to auto-scroll.
                event.stopPropagation()
                event.preventDefault()
            }}
        >
            <StyledHeaderDiv>
                <Box style={{ display: 'inline', marginRight: '5px' }}>
                    <Warning color={colors.userInterface.warning[1000]} size={16} />
                </Box>
                <strong style={{ fontSize: '14px' }}>
                    {ERROR_TYPE_TO_LABEL[error.error_type]} needs updating
                </strong>
            </StyledHeaderDiv>
            {dateDifference && <StyledTimestamp>{dateDifference}</StyledTimestamp>}
            <span
                style={{
                    display: 'block',
                    fontSize: '14px',
                    lineHeight: '16px',
                    wordBreak: 'break-word',
                }}
            >
                {message}
            </span>
        </MenuItem>
    )
}

const StyledHeaderDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
`

const StyledTimestamp = styled('span')`
    display: block;
    color: #adb0b5;
    font-weight: 700;
    margin-left: 28px;
    margin-bottom: 15px;
    font-size: 12px;
`
