/* Code Quality: Good */

import React, { useContext } from 'react'
import { arrayMove } from 'react-sortable-hoc'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { CreateDashboardButton } from 'v2/views/Dashboard/CreateDashboard'
import usePageRoleConfig from 'v2/views/utils/usePageRoleConfig'

import { AppContext } from 'app/AppContext'
import { fetchWithAuth } from 'data/utils/utils'
import withLDFlags from 'data/wrappers/WithLDFlags'
import { withNavigation } from 'data/wrappers/WithNavigation'
import { withPages } from 'data/wrappers/WithPages'
import { withStack } from 'data/wrappers/WithStacks'
import { withViews } from 'data/wrappers/WithViews'
import { RolesSelectToggle } from 'features/pages/blocks/settings/attributes/items/form/RolesSelect'
import ToggleSortPicker from 'features/views/List/ToggleSortPicker'
import { Button, Icon8, Input, Text } from 'legacy/v1/ui'

import { Box, Divider, Dropdown, Flex, Tooltip } from 'v2/ui'

import { buildNavTree } from './NavigationUtils'

class _NavigationEditor extends React.Component {
    state = {
        navigation: [],
        menuStyle: 'top',
        initialized: false,
    }

    changeHidden = (item) => {
        // Need to clone item, not just update it to ensure that changes to it
        // are picked up everywhere in the client
        const updatedItem = { ...item }
        updatedItem.hidden = !updatedItem.hidden
        this.updateNavigation(updatedItem)
    }

    logSentryError = (msg, data) => {
        Sentry.withScope((scope) => {
            Object.keys(data).forEach((key) => {
                scope.setExtra(key, data[key])
            })
            scope.setLevel('error')
            Sentry.captureMessage(`Navigation error: ${msg}`)
        })
    }

    updateNavigation = (item) => {
        const { navigationActions } = this.props

        const { navigation } = this.state
        const updatedNavigation = navigation

        const index = navigation.findIndex((i) => i._sid === item._sid)
        if (index < 0) {
            this.logSentryError('Unable to find sid on navigation', {
                sid: item._sid,
                navigation,
            })
            return
        }
        updatedNavigation[index] = item
        navigationActions.update(item._sid, { ...item })

        this.setState({ navigation: updatedNavigation })
    }

    changeLabel = (item, newLabel) => {
        if (!newLabel) return
        // Need to clone item, not just update it to ensure that changes to it
        // are picked up everywhere in the client
        const updatedItem = { ...item }
        updatedItem.label = newLabel

        this.updateNavigation(updatedItem)
    }

    changeOrder = (navItem, oldIndex, newIndex) => {
        const { navigationActions } = this.props
        const { navigation } = this.state

        const sameLevelNav = sortBy(navigation, 'display_order').filter(
            (n) => n.parent_id === navItem.parent_id
        )
        const sortedNav = arrayMove(sameLevelNav, oldIndex, newIndex)
        const updatedDisplayOrder = navigation.map((n) => {
            const index = sortedNav.indexOf(n)
            // if not same  level (not sorted) will return -1
            if (index >= 0 && n.display_order !== index + 1) {
                // Need to clone item, not just update it to ensure that changes to it
                // are picked up everywhere in the client
                const updatedNavItem = { ...n }
                updatedNavItem.display_order = index + 1
                navigationActions.update(updatedNavItem._sid, { ...updatedNavItem })
                return updatedNavItem
            } else {
                return n
            }
        })

        this.setState({ navigation: updatedDisplayOrder })
    }

    resetButton = () => {
        return (
            <Button
                style={{
                    fontWeight: 'normal',
                    fontSize: '80%',
                    width: '120px',
                }}
                size="small"
                color="danger"
                onClick={() => {
                    fetchWithAuth('navigation-reset/', {}).then(() => {
                        this.props.navigationActions.fetch().then((response) => {
                            if (response) this.setState({ navigation: response.payload })
                            /* console.log(
                        `nav ${sortBy(navigation, ['display_order']).map(
                            n => `${n.label} (${n.display_order})`
                        )}`
                    ) */
                        })
                    })
                }}
            >
                Reset navigation
            </Button>
        )
    }

    setMenuStyle = (value) => {
        this.setState({ menuStyle: value })
        const vertical = value === 'left'
        this.props.onChangeStack(this.props.stack._sid, {
            options: { ...this.props.stack.options, vertical_nav: vertical },
        })
    }
    render() {
        if (!this.props.navigation || this.props.navigation.length === 0) return this.resetButton()
        if (!this.state.initialized) {
            this.setState({
                initialized: true,
                navigation: this.props.navigation,
                menuStyle: this.props.stack.options?.vertical_nav ? 'left' : 'top',
            })
            return ''
        }
        const menuStyleOptions = [
            { label: 'Top', value: 'top' },
            { label: 'Left', value: 'left' },
        ]

        const workspaceApp = this.props.stack.options?.workspace_app

        return (
            <>
                <Flex mb={2}>
                    {!workspaceApp && (
                        <>
                            <Box mr={2}>
                                <Text>Navigation placement: </Text>
                            </Box>
                            <Dropdown
                                options={menuStyleOptions}
                                value={this.state.menuStyle}
                                onChange={(value) => this.setMenuStyle(value)}
                                isSearchable={false}
                                isClearable={false}
                            />
                        </>
                    )}
                    <div style={{ flex: 1 }} />
                    <CreateDashboardButton />
                </Flex>
                <Divider />
                <NavigationPicker
                    items={buildNavTree(this.state.navigation, this.props.views, this.props.pages)}
                    changeHidden={this.changeHidden}
                    changeLabel={this.changeLabel}
                    changeOrder={this.changeOrder}
                    isTopLevel={true}
                />
                {localStorage.getItem('reset_navigation') && this.resetButton()}
            </>
        )
    }
}

class NavigationPicker extends React.Component {
    displayTree = (item) => {
        /*
        This method contains the logic to display either the folder or the link depending on its children:
        - if the item is a folder (with children) and only 1 child, display the child instead of the folder
        - if the item is a folder with more than 1 children, display the folder and another sortable list of links (without children)
        - if there are no children, then the item is a link, so display it.
        */
        const { changeOrder, changeLabel, changeHidden, isTopLevel } = this.props

        let topNav = ''
        let secondNav = ''

        const isHome = isTopLevel && this.childTree().findIndex((n) => n._sid === item._sid) === 0
        if (!item.children) {
            // No children, we are displaying just a view
            topNav = <MenuLabelViewEdit isHome={isHome} item={item} changeLabel={changeLabel} />
        } else if (item.children.length > 1) {
            // Folder and many views
            // we will display the parent and the children
            topNav = <MenuLabelViewEdit isHome={isHome} item={item} changeLabel={changeLabel} />

            secondNav = (
                <NavigationPicker
                    changeHidden={changeHidden}
                    changeLabel={changeLabel}
                    changeOrder={changeOrder}
                    items={sortBy(item.children, ['display_order'])}
                    itemWrapper={SecondLevel}
                />
            )
        } else {
            // Folder and a single view
            // we will display just he view
            const navLink = item.children.find((navItem) => navItem.parent_id !== null)
            if (navLink)
                topNav = (
                    <MenuLabelViewEdit isHome={isHome} item={navLink} changeLabel={changeLabel} />
                )
        }
        return (
            <>
                {topNav}
                {secondNav}
                <ChangeNavigationRole item={item} />
            </>
        )
    }

    childTree() {
        // Filter out parents with only 1 child, display child instead
        return this.props.items.map((i) => {
            if (i.children && i.children.length === 1) {
                return i.children[0]
            } else {
                return i
            }
        })
    }

    render() {
        const { items, changeOrder, changeHidden, itemWrapper } = this.props

        const tree = this.childTree()
        return (
            <ToggleSortPicker
                items={tree}
                renderItem={this.displayTree}
                toggleItem={(item) => {
                    return {
                        ...item,
                        hidden: !item.hidden,
                    }
                }}
                isSelected={(item) => {
                    if (item.children && item.children.length === 1) {
                        return !item.children[0].hidden
                    } else {
                        return !item.hidden
                    }
                }}
                itemToId={(item) => item._sid}
                onReorder={(oldIndex, newIndex) => {
                    changeOrder(items[oldIndex], oldIndex, newIndex)
                }}
                onToggle={(index) => {
                    changeHidden(tree[index])
                }}
                ItemWrapper={itemWrapper || TopLevel}
            />
        )
    }
}

class MenuLabelViewEdit extends React.Component {
    state = {
        editing: false,
        label: this.props.item.label,
    }

    homeNavigation = () => {
        return (
            <Tooltip
                id="navigation-home-tooltip"
                label="The first item in your navigation menu will be your homepage"
                placement="right"
                display="inline-block"
            >
                <Icon8 icon="home" size="48" iconStyle="ios-filled" color="CCCCCC" />
            </Tooltip>
        )
    }

    render() {
        const { item, changeLabel } = this.props

        // Only override label if it's different!
        const labelToSet = this.state.label !== item.label ? this.state.label : null

        const saveLabel = (newLabel) => {
            if (!newLabel) {
                this.setState({
                    editing: false,
                })
                return
            }
            changeLabel(item, newLabel)
            this.setState({
                editing: false,
                label: newLabel,
            })
        }

        if (this.state.editing) {
            return (
                <span>
                    <Input
                        defaultValue={this.state.label}
                        onChange={(e) => this.setState({ label: e.target.value })}
                        style={{ display: 'inline-block', marginRight: '8px' }}
                    />
                    <EditText
                        onClick={() => {
                            const label = get(item, 'options.view_name', item.label)
                            saveLabel(label)
                        }}
                    >
                        Reset
                    </EditText>
                    <EditText onClick={() => saveLabel(labelToSet)}>Save</EditText>
                </span>
            )
        }
        return (
            <>
                <Text size="fontS" style={{ display: 'inline-flex' }} title={item.url}>
                    {this.state.label}{' '}
                    <Icon8
                        icon="pencil"
                        iconStyle="ios-filled"
                        color="CCCCCC"
                        displaySize="17"
                        style={{ cursor: 'pointer', marginLeft: 10 }}
                        onClick={() => this.setState({ editing: true })}
                    />
                </Text>
                {this.props.isHome && this.homeNavigation()}
            </>
        )
    }
}

// This z-index is to display items when dragging on the popup modal
const TopLevel = styled('div')`
    z-index: 6;
`
const SecondLevel = styled('div')`
    display: list-item;
    margin-left: 25px;
    z-index: 6;
`

const EditText = styled(Text)`
    cursor: pointer;
    margin 0px 8px 0px 0px;
    padding: 0px;
    font-size: 0.8em;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
`

export default withLDFlags(withStack(withPages(withViews(withNavigation(_NavigationEditor)))))

function _ChangeNavigationRole({ item, pages, views }) {
    const targetView = views.find(({ url }) => url === item.url)
    const targetPage = pages.find((page) => page.options.view_id === targetView?._sid)
    const { selectedStack } = useContext(AppContext)
    const { setAndSavePageRoles, pageRoles } = usePageRoleConfig(targetPage)

    if (!item.parent_id || !targetPage || !selectedStack?.options?.roles__enabled) {
        return null
    }

    return (
        <RolesSelectToggle
            onChange={(pageRoles) => {
                setAndSavePageRoles(pageRoles)
            }}
            value={pageRoles}
        />
    )
}

const ChangeNavigationRole = withPages(withViews(_ChangeNavigationRole))
