import styled from '@emotion/styled'

import WithMarginAndPadding, { WithMarginAndPaddingPropTypes } from '../utils/WithMarginAndPadding'

type ContainerProps = WithMarginAndPaddingPropTypes & {
    width?: string
    maxSize?: number
    spaced?: boolean
    wrapItems?: boolean
    centered?: boolean
}

const Container = styled(WithMarginAndPadding)<ContainerProps>`
    // Basically, a container is a horizontal stacking (column-based) flex container
    // Compare against Section

    display: flex;
    flex-direction: row;
    align-items: flex-start; // Don't make things be as tall as the container

    width: ${(props) => props.width};
    ${(props) =>
        props.maxSize &&
        `
        flex-grow: ${props.maxSize};
    `}
    ${(props) =>
        props.spaced &&
        `
        justify-content: space-between;
    `}
    ${(props) =>
        props.wrapItems &&
        `
        flex-wrap: wrap;
    `}
    ${(props) =>
        props.centered &&
        `
        align-items: center;
    `}
`

Container.displayName = 'Container'

export default Container
