/* Code Quality: Bad */

import React from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { UserApi } from 'data/api/userApi'
import { fetchCombination, getCollection } from 'data/githubFeatures'
import { fetchWithAuth } from 'data/utils/utils'
import { withUser } from 'data/wrappers/WithUser'
import { install_feature } from 'features/admin/features/AddFeaturePage'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import { Section, Text } from 'legacy/v1/ui'
import publicAsset from 'utils/publicAsset'

import { Content, ContentWrapper } from '../../studio/ui/Layout'
import { logo, setFeatureLogo, SignupApi } from '../utils'

class SignupCreatingStackPage extends React.Component {
    state = { portal: null, title: 'account', logo: '', icon: '' }

    loadingGif = publicAsset('/static/media/Spinner.svg')

    loginAndRedirect = (studio_token, redirect) => {
        UserApi.requestStudioTemporaryAuthToken(studio_token, redirect).then((response) => {
            if (response.redirect) {
                const joiner = response.redirect.indexOf('?') === -1 ? '?' : '&'
                // Using the auth token directly here permits logging in directly to the dashboard, without the bounce through the login page.
                window.location = `${response.redirect}${joiner}auth_token=${response.auth_token}`
            }
        })
    }

    UNSAFE_componentWillMount() {
        this.register().then()
    }

    register = async () => {
        const query = queryString.parse(this.props.location.search.slice(1)) // Slice to remove the "?"
        const { hash } = query
        const vars = queryString.parse(atob(hash))
        const collection = getCollection()

        const stackUrl = localStorage.getItem('stack_url')
        const stackName = localStorage.getItem('stack_name')

        const { portal } = vars
        const { email } = vars
        const { password } = vars
        const name = email

        // Register the user
        const response = await SignupApi.signup({ name, email, password, portal: true })

        const token = response.api_token
        if (!token) {
            this.setState({ error: true, processing: false })
            return
        }

        this.props.userActions.studioUserLoggedIn(response)

        if (stackName === portal && stackUrl) {
            // If there is already a portal with the same name,
            // we will redirect automatically to that stack
            // this is to prevent people from creating multiple stacks
            // by pressing back button or something similar
            console.log(`Stack '${portal}' already exists, redirecting to ${stackUrl}`)
            window.location = stackUrl
            return
        }

        const studio_token = localStorage.getItem('studio_token')
        if (!studio_token) window.location = getUrl(Urls.Register)
        if (!portal) window.location = getUrl(Urls.Register2)

        setFeatureLogo(this, collection)

        // Instead of cloning a stack, we're going to create a blank stack
        // templateActions.clone(templateSID, portal, portal, res => {
        fetchCombination().then((features) => {
            const feature = features.hasOwnProperty(collection)
                ? features[collection]
                : features.default

            const stackOptions = feature.config || {} // add default config from feature or {}
            stackOptions.domain = window.location.host.replace('studio.', '') // Install stacks under the same domain they were created
            stackOptions.is_portal = true
            fetchWithAuth('stacks/', {
                method: 'POST',
                body: JSON.stringify({
                    name: portal,
                    origin: window.location.host,
                    options: stackOptions,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) =>
                response.json().then((res) => {
                    let landingPage = getUrl(Urls.StudioFeatures)
                    if (feature.config && feature.config.landing)
                        landingPage = feature.config.landing
                    const stackUrl = res.base_url + landingPage
                    const protocol = stackUrl.includes('.local') ? 'http' : 'https'
                    // Redirect to the stack on an absolute URL
                    const redirect = `${protocol}://${stackUrl}`

                    localStorage.setItem('stack_url', redirect)
                    localStorage.setItem('stack_name', portal)
                    console.log(`Installing features from bundle ${collection}`)
                    let installFeatures = feature.features.map((package_url) => {
                        return install_feature(package_url)
                    })
                    if (stackOptions.advanced_permissions_on_user) {
                        installFeatures.push(
                            fetchWithAuth('objects/object.stacker.user/', {
                                method: 'GET',
                            }).then((response) => {
                                return response.json().then((result) => {
                                    let userOptions = result.options || {}
                                    userOptions.permissions__use_permissions = true
                                    return fetchWithAuth('objects/object.stacker.user/', {
                                        method: 'PATCH',
                                        body: JSON.stringify({
                                            options: userOptions,
                                        }),
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                })
                            })
                        )
                    }
                    // Wait until all installfeatures have finished
                    Promise.all(installFeatures.map((p) => p.catch((e) => e))).then((results) => {
                        console.log(
                            `${results.length} feature installs finished, redirecting to ${redirect}`
                        )
                        this.loginAndRedirect(studio_token, redirect)
                    })
                })
            )
        })
    }

    render() {
        return (
            <>
                <DefaultHeaderMeta title={`Creating your ${this.state.title}`} />
                <ContentWrapper>
                    <Content>
                        <Frame>
                            {logo(this.state.logo)}
                            <Text style={{ fontSize: '1.8em', fontWeight: 'bold' }}>
                                Creating your {this.state.title}
                            </Text>
                            <Section margin="medium" padding="none">
                                <Image src={this.loadingGif} />
                            </Section>
                        </Frame>
                    </Content>
                </ContentWrapper>
            </>
        )
    }
}

export default withUser(withRouter(SignupCreatingStackPage))

const Frame = styled('div')`
    width: 100%;
    max-width: 400px;
    min-height: 480px;
    margin: 0% auto;
    text-align: center;
`

const Image = styled('img')`
    vertical-align: middle;
    height: 128px;
    width: 128px;
    margin: 0% auto;
`
