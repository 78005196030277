/* Code Quality: Not audited */

import React from 'react'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'

import deepcopy from 'deepcopy'
import PropTypes from 'prop-types'

import { Checkbox } from 'ui/components/Checkbox'

import Text from './Text'

const SortableItem = SortableElement(({ value, onToggle, checked }) => (
    <li className={'sortable-fields-item ' + (checked ? 'selected' : '')}>
        <span className="icon-drag-handle">
            <i className="icon-drag-handle" />
        </span>
        <span className="selected-indicator">
            <Checkbox
                onCheckedChange={() => {
                    onToggle()
                }}
                checked={checked}
            />
        </span>
        <Text style={{ display: 'inline', userSelect: 'none' }}>{value}</Text>
    </li>
))

const SortableList = SortableContainer(({ items, onToggle }) => {
    return (
        <ul className="sortable-fields">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={value ? value.label : ''}
                    onToggle={() => onToggle(index, !value.isSelected)}
                    checked={value.isSelected}
                />
            ))}
        </ul>
    )
})

export default class SortablePicker extends React.Component {
    render() {
        return (
            <SortableList
                items={this.getFieldOptions()}
                onSortEnd={this.onSortEnd.bind(this)}
                onToggle={this.onToggleSelected.bind(this)}
                distance={10}
            />
        )
    }

    constructor(props) {
        super(props)
        if (props.fields) this.state = { fields: props.fields }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (JSON.stringify(this.props.fields) != JSON.stringify(newProps.fields)) {
            this.setState({ fields: this.props.fields })
        }
    }

    getValues() {
        let values = this.props.values
        if (!values.length) {
            // If none are selected, select them all
            values = this.getFields().map((field) => field._sid)
        }
        return values
    }

    getFields() {
        return deepcopy(this.props.fields || [])
    }

    getFieldOptions() {
        let options = this.getFields()
            .sort(this.compareFields.bind(this))
            .map((field) => {
                let isSelected = this.getValues().indexOf(field._sid) !== -1
                return { ...field, isSelected: isSelected }
            })
        return options
    }

    compareFields(a, b) {
        let values = this.getValues()
        let aIndex = values.indexOf(a._sid)
        let bIndex = values.indexOf(b._sid)
        if (aIndex === -1) return 1
        if (bIndex === -1) return -1
        if (aIndex === bIndex) return 0
        return aIndex < bIndex ? -1 : 1
    }

    onToggleSelected(index, toggleTo) {
        this.props.onChange([])
        let items = this.getFieldOptions()
        let item = items[index]
        item.isSelected = toggleTo
        let values = items.filter((item) => item.isSelected).map((item) => item._sid)
        this.setState({ fields: values }, () => this.props.onChange(values))
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        let items = this.getFieldOptions()
        items[oldIndex].isSelected = true
        let values = arrayMove(items, oldIndex, newIndex)
        values = values.filter((item) => item.isSelected).map((item) => item._sid)
        this.setState({ fields: values }, () => this.props.onChange(values))
    }
}

SortablePicker.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object).isRequired, // The fields that are the options
    values: PropTypes.arrayOf(PropTypes.string), // returned list is a list of IDs
    onChange: PropTypes.func, // returned list is a list of IDs
}
