import React from 'react'

import styled from '@emotion/styled'

import { Box, Text } from 'v2/ui'

const Container = styled('div')`
    position: relative;
    max-width: 100%;
`

const Spacer = styled(Text)`
    height: 0;
    overflow: hidden;
`

const Content = styled(Text)`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 4px 0 0;
    margin: -4px -4px 0 0;
    box-sizing: content-box;
`

type DisplayTextProps = React.ComponentPropsWithoutRef<typeof Text> & {
    singleLine?: boolean
    maxLines?: number
    removeTableSpacer?: boolean
    additionalStyles?: React.CSSProperties
}

const DisplayText: React.FC<DisplayTextProps> = ({
    singleLine,
    maxLines,
    removeTableSpacer,
    ...props
}) => {
    const { maxWidth = '100%', ...otherProps } = props

    let title = ''
    // If the child is a component instead of a bare string, don't set title="[object Object]"
    if (typeof props.children === 'string') {
        title = props.children
    }

    if (singleLine && !removeTableSpacer) {
        return (
            <>
                <Container>
                    <Content {...props} title={title} position="absolute" />
                    <Spacer {...otherProps} />
                </Container>

                <Text color="inherit" {...otherProps}>
                    &nbsp;
                </Text>
            </>
        )
    } else if (singleLine) {
        return <Content {...props} title={title} />
    } else {
        return (
            <Box
                maxWidth={maxWidth}
                maxHeight="100%"
                overflow="hidden"
                marginTop="-4px"
                paddingTop="4px"
                boxSizing="content-box"
            >
                <Text
                    color={props.color ? props.color : 'inherit'}
                    maxLines={maxLines}
                    {...props}
                    title={title}
                    maxWidth="100%"
                    whiteSpace="pre-wrap"
                    style={props.additionalStyles}
                />
            </Box>
        )
    }
}
export default DisplayText
