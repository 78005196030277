import * as React from 'react'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { v4 as uuid } from 'uuid'

import { useViewFromId } from 'data/hooks/views'

export const BREAKPOINT = 800
export const MOBILE_BREAKPOINT = 450
export const HAS_TABLET_BREAKPOINT = false

const DASHBOARD_CONTEXT = createContext()
export default function DashboardContextProvider({
    allowEdits,
    children,
    config,
    save: _save,
    onChange = () => {},
    mainObjectId = null,
    viewId,
    viewContext,
    viewFilters,
}) {
    const [editingChartId, setEditingChartId] = useState(null)
    const [currentLayout, setLayoutChanges] = useState(null)
    const [updateCount, setUpdateCount] = useState(0)
    const [width, setWidth] = useState(0)
    const ref = useRef()
    const hasStartedEditingRef = useRef()
    const windowTimeout = useRef()

    const breakpoint = getBreakpoint(width)

    const view = useViewFromId(viewId)

    const clearCurrentTimeout = () => {
        if (windowTimeout.current) clearTimeout(windowTimeout.current)
        windowTimeout.current = null
    }

    useEffect(() => {
        if (ref.current) {
            const { width, x } = ref.current.getBoundingClientRect()

            if (width) {
                setWidth(width)
            } // fixes a bug where sometimes chart don't appear on related lists
            else if (x === 0 && viewContext === 'relatedList') {
                clearCurrentTimeout()
                windowTimeout.current = setTimeout(
                    () => setWidth(ref.current.getBoundingClientRect().width),
                    1000
                )
            }
        }

        return clearCurrentTimeout
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const context = useMemo(() => {
        return {
            editingChartId: allowEdits && editingChartId,
            setEditingChartId,
            mainObjectId,
            viewId,
            viewType: view?.type,
            updateCount,
            currentLayout,
            viewContext,
            viewFilters,
            allowEdits: allowEdits && viewContext !== 'relatedList',
            width,
            breakpoint,
            config,
            save,
            createChart,
            updateChart,
            onLayoutChange,
            reset,
            deleteChart,
            hasStartedEditingRef,
        }

        function reset() {
            setLayoutChanges(null)
            setUpdateCount(updateCount + 1)
        }
        function onLayoutChange(changes) {
            if (!hasStartedEditingRef.current) {
                return
            }
            setLayoutChanges(changes)
            onChange && onChange(changes, breakpoint)
        }
        function createChart(data) {
            const id = uuid()
            // !! mutation
            config.charts = {
                ...config.charts,
                [id]: {
                    id,
                    ...data,
                },
            }

            setUpdateCount(updateCount + 1)
        }
        function updateChart(id, patch) {
            // !! mutation
            config.charts = {
                ...config.charts,
                [id]: {
                    ...config.charts[id],
                    ...patch,
                },
            }
            setUpdateCount(updateCount + 1)
        }
        function deleteChart(id) {
            if (window.confirm('Remove the chart?')) {
                // !! mutation
                delete config.charts[id]
                setUpdateCount(updateCount + 1)
            }
        }
        function save() {
            _save({ ...config, layout: currentLayout, breakpoint })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        editingChartId,
        allowEdits,
        config,
        _save,
        currentLayout,
        updateCount,
        mainObjectId,
        width,
        breakpoint,
        viewId,
        hasStartedEditingRef,
        viewContext,
        viewFilters,
    ])

    return (
        <DASHBOARD_CONTEXT.Provider value={context}>
            <div ref={ref}>{width > 0 && children}</div>
        </DASHBOARD_CONTEXT.Provider>
    )
}

/**
 * @return {{
    mainObjectId?: string
    viewId?: string
    viewType?: "dashboard" | "list"
    updateCount: number
    currentLayout: any
    config: CHARTS_CONFIG
    save: () => void
    createChart: (data: CHART_PARAMS) => void
    updateChart: (id: string, patch: Partial<CHART_PARAMS>) => void
    onLayoutChange: (params: any, breakpoint: GRID_BREAKPOINTS) => void
    reset: () => void
    deleteChart: (id: string) => void
    allowEdits: boolean
    editingChartId: string | null
    setEditingChartId: (id: string) => void
    width: number
    breakpoint: GRID_BREAKPOINTS
    hasStartedEditingRef: { current: boolean }
    viewContext: "dashboard" | "list" | "relatedList"
    viewFilters: any[]
 * }}
 */
export const useDashboardContext = () => useContext(DASHBOARD_CONTEXT)

/**
 *
 * @param {*} width
 * @return {GRID_BREAKPOINTS}
 */
function getBreakpoint(width) {
    if (width < MOBILE_BREAKPOINT) return 'xs'
    if (width < BREAKPOINT) return 'sm'
    return 'md'
}
