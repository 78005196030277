import React, { useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection, Label } from 'legacy/v1/ui'

import { Button, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, ProtectedFeature, ProtectedFeatureIndicator } from 'v2/ui/utils/ProtectedFeature'

import { Checkbox } from 'ui/components/Checkbox'

type CommentsAndNotificationSettingProps = {
    selectedStack: StackDto
    isCollaborationLocked: boolean
    showUpgradeButton: boolean
    onSave: () => void
    onSaveError: () => void
}

export const CommentsAndNotificationsSetting = ({
    selectedStack,
    isCollaborationLocked,
    showUpgradeButton,
    onSave,
    onSaveError,
}: CommentsAndNotificationSettingProps) => {
    const [enableCollaboration, setEnableCollaboration] = useState<boolean>(
        selectedStack?.options?.enable_comment_feed
    )
    const [enableCollaborationNotifications, setEnableCollaborationNotifications] =
        useState<boolean>(selectedStack?.options?.collaboration_email_notifications)
    const [showCollaborationUpgrade, setShowCollaborationUpgrade] = useState<boolean>(false)

    const { mutateAsync: updateStack } = useUpdateStack()

    return (
        <>
            <ExpandSection
                // @ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Comments & Notifications
                        <ProtectedFeatureIndicator
                            feature={FEATURES.collaboration}
                            ml={3}
                            onClick={() => setShowCollaborationUpgrade(true)}
                        />
                    </div>
                }
                helpUrl=""
                helpText=""
                text={null}
                classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.COLLABORATION }}
            >
                {!isCollaborationLocked ? (
                    <>
                        <FlexWrapper>
                            {/* @ts-expect-error */}
                            <Label as="label" htmlFor="enableCollabComments">
                                Enable Comments & Notifications.
                            </Label>
                            <Checkbox
                                id="enableCollabComments"
                                onCheckedChange={() => {
                                    setEnableCollaboration((prev) => !prev)
                                }}
                                checked={enableCollaboration}
                            />
                        </FlexWrapper>
                        <FlexWrapper>
                            {/* @ts-expect-error */}
                            <Label as="label" htmlFor="enableCollabNotifications">
                                Enable comment email notifications.
                            </Label>
                            <Checkbox
                                id="enableCollabNotifications"
                                onCheckedChange={() => {
                                    setEnableCollaborationNotifications((prev) => !prev)
                                }}
                                checked={enableCollaborationNotifications}
                            />
                        </FlexWrapper>
                        <StackColorButton
                            primary
                            color="stacker"
                            padding="small"
                            margin="none"
                            style={{ alignSelf: 'flex-start' }}
                            isDisabled={
                                enableCollaboration ===
                                    selectedStack?.options?.enable_comment_feed &&
                                enableCollaborationNotifications ===
                                    selectedStack?.options?.collaboration_email_notifications
                            }
                            onClick={() => {
                                updateStack({
                                    id: selectedStack._sid,
                                    patch: {
                                        options: {
                                            ...selectedStack.options,
                                            enable_comment_feed: enableCollaboration,
                                            collaboration_email_notifications:
                                                enableCollaborationNotifications,
                                        },
                                    },
                                })
                                    .then(() => {
                                        onSave()
                                    })
                                    .catch(() => {
                                        onSaveError()
                                    })
                            }}
                        >
                            Save
                        </StackColorButton>
                        {get(selectedStack, 'options.enable_comment_feed') && (
                            <Text mt="10px" fontSize="sm">
                                Commenting is enabled separately on each detail page layout.
                            </Text>
                        )}
                    </>
                ) : (
                    <>
                        <Text>
                            Allow your users to leave comments on every detail page of your app.
                        </Text>
                        {showUpgradeButton && (
                            <Button
                                marginRight="auto"
                                variant="upgradePrimary"
                                onClick={() => setShowCollaborationUpgrade(true)}
                            >
                                Upgrade
                            </Button>
                        )}
                    </>
                )}
            </ExpandSection>
            <ProtectedFeature
                feature={FEATURES.collaboration}
                showModal={showCollaborationUpgrade}
                onModalClosed={() => setShowCollaborationUpgrade(false)}
            />
        </>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const StackColorButton = (props: any) => (
    <Button
        variant="adminPrimary"
        buttonSize="sm"
        {...props}
        style={{ ...props.style, flexShrink: 0 }}
    />
)
