import React, { FC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type Props = {
    color?: string
    size?: number
}

const SolidCheck: FC<Props> = ({ color, size }) => (
    <svg
        width={size || 14}
        height={size || 14}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.941 2.894a.986.986 0 0 1 .04 1.395l-5.592 5.92a.987.987 0 0 1-1.414.02l-2.96-2.96a.987.987 0 0 1 1.394-1.395l2.244 2.242 4.894-5.182a.987.987 0 0 1 1.394-.04z"
            fill={color || colors.userInterface.accent[1000]}
        />
    </svg>
)

export default SolidCheck
