import React from 'react'

export const LinkListEmptyState = ({ style = {}, header, children }) => (
    <div
        style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...style,
        }}
    >
        {header}
        {children && <div>{children}</div>}
    </div>
)
