import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { AppUserContext } from 'app/AppUserContext'
import { withNavigation } from 'data/wrappers/WithNavigation'
import { withObjects } from 'data/wrappers/WithObjects'
import { withPages } from 'data/wrappers/WithPages'
import { withViews } from 'data/wrappers/WithViews'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { PathContext } from 'features/utils/PathContext'

import { Box, Flex } from 'v2/ui'

import AppNavItem from './AppNavItem'

const AppNavTree = ({ pages, objects, stack, views, navigation, navTheme }) => {
    const pathContext = useContext(PathContext)
    const history = useHistory()
    const routeUrl = pathContext.pageUrl
    const navTree = useNavTree({ navigation, views, pages, objects }).filter(
        (item) => item.display !== false
    )
    return (
        <Flex column align="stretch">
            {navTree.map(
                (
                    {
                        item,
                        view,
                        isOnChildRoute,
                        isActive,
                        isFolder,
                        topLevelItem,
                        topLevelUrl,
                        children,
                    },
                    index
                ) => {
                    return (
                        <Flex
                            key={item._sid}
                            style={{ width: '100%' }}
                            align="stretch"
                            direction="column"
                            flexWrap="nowrap"
                        >
                            <AppNavItem
                                isTopLevel
                                isFolder={isFolder}
                                url={topLevelUrl}
                                item={topLevelItem}
                                stack={stack}
                                label={topLevelItem.label}
                                view={view}
                                isActive={isActive}
                                navTheme={navTheme}
                                showExpandIcon={isFolder && !isOnChildRoute}
                                index={index}
                            ></AppNavItem>
                            {isOnChildRoute && children.length > 0 && (
                                <Box
                                    maxWidth="100%"
                                    boxShadow={`0px 3px 3px -2px inset ${navTheme.navBorderColor}, 0px -3px 3px -2px inset ${navTheme.navBorderColor}`}
                                    borderBottom={`1px solid  ${navTheme.navBorderColor}`}
                                    borderTop={`1px solid  ${navTheme.navBorderColor}`}
                                    bg={navTheme.navFadeColor}
                                    pt={1}
                                    pb={1}
                                >
                                    {children.map((child) => (
                                        <AppNavItem
                                            key={child._sid}
                                            isFolder={false}
                                            view={views.find((v) => v.url === child.url)}
                                            item={child}
                                            label={child.label}
                                            stack={stack}
                                            isActive={
                                                child.url === routeUrl ||
                                                history?.location?.state?.prev?.pathname?.endsWith(
                                                    child.url
                                                )
                                            }
                                            navTheme={navTheme}
                                        ></AppNavItem>
                                    ))}
                                </Box>
                            )}
                        </Flex>
                    )
                }
            )}
        </Flex>
    )
}

export default withObjects(withNavigation(withViews(withPages(React.memo(AppNavTree)))))

export function useNavTree({ navigation, views, pages, objects }) {
    const pathContext = useContext(PathContext)
    const routeUrl = pathContext.pageUrl
    const { role } = useContext(AppUserContext)

    const navTree = useMemo(() => {
        const tree = buildNavTree(navigation, views, pages)

        return tree
            .filter((item) => !item.hidden || item.children)
            .map((item) => {
                let topLevelItem

                if (!item.children) return { display: false } // Don't show at all if no available children

                let children = item.children.filter((child) => !child.hidden)
                let topLevelUrl
                let isFolder
                if (!children.length) return { display: false }
                if (children.length === 1) {
                    // If we've only got one child, display that a the top level instead
                    isFolder = false
                    topLevelItem = children[0]
                    children = []
                } else {
                    isFolder = true
                    topLevelItem = item
                    topLevelItem.url = children[0].url
                    topLevelUrl = children[0].url // If we've got multiple children, clicking on this takes us to the first child
                }

                // Are we on this route?
                let isActive = topLevelItem.url === routeUrl //|| topLevelItem.url === get(pathContext.feature, 'url')
                // Are we on a child's route?
                let isOnChildRoute = children.some((child) => child.url === routeUrl)

                // Am I a folder, and is one of my object detail or create pages being displayed?
                const object = objects.find((o) => o._sid === item.object_id)

                if (
                    object && // We've got a matching object
                    pathContext.feature && // We're on a feature root url
                    object.feature_id === pathContext.feature._sid // This is the same feature
                ) {
                    if (
                        pathContext.view &&
                        ['create', 'detail', 'dashboard', 'blankpage'].includes(
                            pathContext.view.type
                        )
                    ) {
                        isOnChildRoute = true
                        isActive = true
                    }
                }
                const view = views.find((v) => v.url === topLevelItem.url)

                return {
                    item,
                    view,
                    isOnChildRoute,
                    isActive,
                    object,
                    isFolder,
                    topLevelItem,
                    topLevelUrl,
                    children,
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        navigation,
        views,
        pages,
        pathContext.feature,
        pathContext.view,
        role /* role *is* required*/,
    ])

    return navTree
}
