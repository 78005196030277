import React, { FC } from 'react'

import { ChartPie } from 'v2/ui/svgs'

import { InvalidBlock, InvalidBlockProps } from '../common/InvalidBlock'

type InvalidChartBlockProps = Pick<
    InvalidBlockProps,
    'numberOfMetrics' | 'isEditing' | 'isActive' | 'isOnListView' | 'onClick'
>

import { CHART_BLOCK_HEIGHT } from './constants'

export const InvalidChartBlock: FC<InvalidChartBlockProps> = ({
    numberOfMetrics,
    isEditing,
    isActive,
    isOnListView,
    onClick,
}) => (
    <InvalidBlock
        blockType="chart"
        height={CHART_BLOCK_HEIGHT}
        numberOfMetrics={numberOfMetrics}
        isEditing={isEditing}
        isActive={isActive}
        narrowTitle="Configure your settings to display this chart."
        wideTitle="This chart is not ready yet"
        wideSubtitle="Configure your settings to display it."
        Illustration={ChartPie}
        isOnListView={isOnListView}
        onClick={onClick}
    />
)
