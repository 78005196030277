export const useRecordFiltersForRelartedRecords = ({
    isExternalField,
    localRecord,
    field,
}: {
    isExternalField: boolean
    localRecord: RecordDto | {}
    field?: FieldDto
}): Filter[] => {
    if (!isExternalField && !field) return []
    if (isExternalField && '_sid' in localRecord) {
        let option: FilterOperation = 'is'
        if (field?.type === 'multi_lookup') {
            option = 'containsAny'
        }
        return [
            {
                _id: Math.random(),
                field: { api_name: field?.api_name },
                options: {
                    value: localRecord._sid,
                    option,
                    operator: 'AND',
                },
            },
        ]
    }

    // Airtable contains a field with the linked ids
    const localRecordOptionsValue = localRecord && '_sid' in localRecord && localRecord?._sid

    return [
        {
            _id: Math.random(),
            field: { api_name: '_sid' }, // A fake field object for the SID "field".
            options: {
                value: `${localRecordOptionsValue}___${field?.api_name}`,
                option: 'appearsIn',
                operator: 'AND',
            },
        },
    ]
}
