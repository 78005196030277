import React from 'react'

type ConditionalWrapperProps = React.PropsWithChildren<{
    condition?: boolean
    wrapper: (children: React.ReactNode) => React.ReactElement
}>

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
    condition,
    wrapper,
    children,
}) => {
    if (condition) {
        return wrapper(children)
    }

    return <>{children}</>
}

export default React.memo(ConditionalWrapper)
