import React from 'react'

import styled from '@emotion/styled'
import { css } from 'emotion'

import KeyValueList from 'features/studio/ui/KeyValueList'
import { Button, Icon, Section, Text, TextArea } from 'legacy/v1/ui'
import EditableWrapper from 'legacy/v1/ui/utils/EditableWrapper'
import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import 'moment/min/locales'

import 'react-datepicker/dist/react-datepicker.css'

const EditIcon = styled(Icon)`
    position: absolute;
    right: -7px;
    top: unset;
    font-size: 13px;
    display: none !important;
    cursor: pointer;
    padding: 5px 10px;
    text-shadow: 0px 0px 7px white;

    &:hover {
        display: inline-block !important;
    }
`

const ClearIcon = styled(Icon)`
    position: absolute;
    right: -3px;
    top: 25px;
    font-size: 13px;
    display: none !important;
    cursor: pointer;
    padding: 5px 10px;
    text-shadow: 0px 0px 7px white;

    &:hover {
        display: inline-block !important;
    }
`

const hover = css`
    &:hover + i {
        display: inline-block !important;
    }
`

const hoverChildren = css`
    &:hover > i {
        display: inline-block !important;
    }
    width: 100%;
    height: 100%;
    padding-right: 10px;
    display: inline-block;
`

const InlineImage = styled('img')`
    max-height: 150px;
    max-width: 150px;
    width: ${(props) => props.width || 'unset'};
    height: ${(props) => props.height || 'unset'};
    border-radius: ${(props) => (props.circle ? '100%' : 'unset')};
    vertical-align: middle;
`

export function renderImageAttribute(onChange, props) {
    const form = (
        <ReactFilestack
            options={{
                accept: 'image/*',
            }}
            onSuccess={(value) => {
                onChange(value.filesUploaded[0].url)
            }}
            customRender={({ onPick }) => (
                <Button padding="small" onClick={onPick}>
                    Upload
                </Button>
            )}
        />
    )
    let url = props.children
    try {
        if (url && url.indexOf('http') === -1 && url.indexOf('data:') === -1) {
            url = `http://${url}`
        }
    } catch (e) {
        url = null
    }

    const { circle, height, width } = props.options || {}

    const display = url ? (
        <InlineImage circle={circle} height={height} width={width} className={hover} src={url} />
    ) : (
        '-'
    )

    if (props.editable & props.inline) {
        return (
            <EditableWrapper
                input={({ end, ref }) =>
                    React.cloneElement(form, {
                        innerRef: (me) => (ref.ref = me),
                        onBlur: end,
                        onSuccess: (value) => {
                            onChange(value.filesUploaded[0].url)
                            end()
                        },
                    })
                }
                value={({ start }) => (
                    <span className={hoverChildren}>
                        {display}
                        {props.children && <ClearIcon icon="times" onClick={() => onChange('')} />}
                        <EditIcon icon="pencil" onClick={start} />
                    </span>
                )}
            />
        )
    }
    if (props.editable) {
        return (
            <Section margin="none" padding="none">
                {props.children && display}
                {form}
            </Section>
        )
    }
    return display
}

export function renderFileAttribute(onChange, props) {
    const form = (
        <ReactFilestack
            onSuccess={(value) => {
                onChange(value.filesUploaded[0].url)
            }}
            customRender={({ onPick }) => (
                <Button padding="small" onClick={onPick}>
                    Upload
                </Button>
            )}
        />
    )
    let url = props.children
    try {
        if (url && url.indexOf('http') === -1 && url.indexOf('data:') === -1) {
            url = `http://${url}`
        }
    } catch (e) {
        console.log('Expected a string when displaying File')
    }

    const display = url ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={url} target="_blank">
            Download
        </a>
    ) : (
        '-'
    )

    if (props.editable & props.inline) {
        return (
            <EditableWrapper
                input={({ end, ref }) =>
                    React.cloneElement(form, {
                        innerRef: (me) => (ref.ref = me),
                        onBlur: end,
                        onSuccess: (value) => {
                            onChange(value.filesUploaded[0].url)
                            end()
                        },
                    })
                }
                value={({ start }) => (
                    <span className={hoverChildren}>
                        {display}
                        {props.children && <ClearIcon icon="times" onClick={() => onChange('')} />}
                        <EditIcon icon="pencil" onClick={start} />
                    </span>
                )}
            />
        )
    }
    if (props.editable) {
        return (
            <Section margin="none" padding="none">
                {props.children && display}
                {form}
            </Section>
        )
    }
    return display
}

export function renderKeyValueAttribute(onChange, props) {
    const options = props.field.options || {}
    const form = (
        <KeyValueList
            keyName={options.keyName || 'label'}
            valueName={options.valueName || 'value'}
            onChange={onChange}
            value={props.children || []}
        />
    )

    return form
}

export function renderJSONAttribute(onChange, props) {
    const inlineForm = <TextArea margin="none">{JSON.stringify(props.children) || ''}</TextArea>
    const form = inlineForm
    const display = <Text margin="none">{JSON.stringify(props.children) || '-'}</Text>

    if (props.editable & props.inline) {
        return (
            <EditableWrapper
                input={({ end, ref }) =>
                    React.cloneElement(inlineForm, {
                        innerRef: (me) => (ref.ref = me),
                        onBlur: end,
                    })
                }
                value={({ start }) => React.cloneElement(display, { onClick: start })}
                onChange={(v) => onChange(JSON.parse(v))}
            />
        )
    }
    if (props.editable) {
        return form
    }
    return display
}
