import React, { useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'

import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection, Label } from 'legacy/v1/ui'

import { Button, ConfirmationModal, Input, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { Feature, FEATURES } from 'v2/ui/utils/ProtectedFeature'

type ChangeUrlSettingProps = {
    selectedStack: StackDto
    stacks: StackDto[]
    isFeatureLocked: (feature: Feature, stack: StackDto) => boolean
}

export const ChangeUrlSetting = ({
    selectedStack,
    stacks,
    isFeatureLocked,
}: ChangeUrlSettingProps) => {
    const [url, setUrl] = useState<string>(selectedStack?.url_slug)
    const [invalidUrl, setInvalidUrl] = useState<boolean>(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const { mutateAsync: updateStack } = useUpdateStack()
    const getSubdomain = (baseUrl: String) => {
        if (!baseUrl) return ''
        return baseUrl.split('.')[0]
    }
    const checkPortalAvailableNames = (url: string) => {
        const filteredStacks =
            stacks?.filter(({ account_id }) => account_id === selectedStack.account_id) ?? []

        const sameName = filteredStacks.find(({ url_slug }) => url === url_slug)

        if (sameName && sameName._sid !== selectedStack._sid) {
            setInvalidUrl(true)
        } else {
            setInvalidUrl(false)
        }
    }

    const updateUrl = () => {
        updateStack({
            id: selectedStack._sid,
            patch: {
                url_slug: url,
            },
        })
            .then(() => {
                setProcessing(false)
                setError(false)
                const authUrl = `/${url}` as Location | (string & Location)
                window.location = authUrl
            })
            .catch((e) => {
                Sentry.captureMessage(`Error updating stack url ${get(e, 'message')}`)
                setProcessing(false)
                setError(true)
            })
    }

    return (
        //@ts-expect-error
        <ExpandSection
            heading="Change your URL"
            helpUrl=""
            helpText=""
            text={null}
            classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.CHANGE_URL }}
            testId="general-settings.change-url"
        >
            <FlexWrapper>
                <Label style={{ margin: '5px 5px 5px 0px' }}>{window.location.origin}/</Label>
                <Input
                    style={{ width: '200px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const subdomain = e.target.value?.toLowerCase?.()
                        setUrl(subdomain)
                        checkPortalAvailableNames(subdomain)
                    }}
                    value={url}
                    data-testid="general-settings.change-url.input"
                />
                <StackColorButton
                    primary
                    color="stacker"
                    padding="small"
                    margin="none"
                    style={{ marginLeft: '10px' }}
                    isDisabled={invalidUrl || !url || url === getSubdomain(selectedStack.base_url)}
                    onClick={() => {
                        setConfirmModalOpen(true)
                    }}
                    data-testid="general-settings.change-url.button"
                >
                    Save
                </StackColorButton>
                {invalidUrl && (
                    <Label style={{ margin: '5px', color: 'red' }}>
                        An app with that url already exists
                    </Label>
                )}
            </FlexWrapper>
            {!isFeatureLocked(FEATURES.workspaceCustomDomain, selectedStack) && (
                <Text mt="5px" fontSize="sm">
                    Stacker URLs can only contain letters and numbers. Symbols are not supported. To
                    use a Custom Domain, change your Workspace Custom Domain.
                </Text>
            )}
            <ConfirmationModal
                isOpen={confirmModalOpen}
                title="Confirm URL change"
                onClose={() => {
                    setConfirmModalOpen(true)
                    setProcessing(false)
                    setError(false)
                }}
                onConfirm={updateUrl}
                inProgress={processing}
                details="Are you sure that you wish to update the stack URL? Any links to your
            previous url will no longer work."
                error={
                    error
                        ? 'Sorry, there was an error updating the URL. Please try again.'
                        : undefined
                }
            />
        </ExpandSection>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const StackColorButton = (props: any) => (
    <Button
        variant="adminPrimary"
        buttonSize="sm"
        {...props}
        style={{ ...props.style, flexShrink: 0 }}
    />
)
