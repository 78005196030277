import React, { useContext, useReducer, useState } from 'react'
import { createPortal } from 'react-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { withUser } from 'data/wrappers/WithUser'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import SideMenuButton from 'features/utils/SideMenuButton'
import { useLayoutContextPortal } from 'features/utils/useLayoutContextPortal'
import { formatEditNameLabel, formatSaveButtonLabel } from 'features/views/labels'

import { ActionList, Box, Button, Flex, Heading } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { SolidMoreVertical } from 'v2/ui/svgs'

const StyledHeading = styled(Heading)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ViewEditPane = (props) => {
    const context = useContext(LayoutEditorContext)
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [state, setState] = useState({
        editingName: false,
    })

    const setPortal = useLayoutContextPortal('editorPortal')

    const closeActiveEditor = () => {
        if (context) context.closeEditor(true)
    }

    const stopEditing = () => {
        setState({ editingName: false })
    }

    const setName = (name) => {
        if (name) {
            props.setViewData({ name })
        }
    }

    const {
        saveView,
        viewName,
        isConfigDirty,
        children,
        view,
        additionalActions,
        hideSaveButton,
        hideActions,
        noPadding,
    } = props
    const layoutEditorContext = context || {}
    const activeEditor = get(layoutEditorContext, 'activeEditor')

    let actions = props.actions || []

    const editNameLabel = formatEditNameLabel(view)

    if (view) {
        actions = [
            ...actions,
            {
                label: editNameLabel,
                action: () => setState({ editingName: true }),
            },
        ]
    }
    const showActions = !hideActions && actions && actions.length > 0

    const actionButton = (
        <div>
            <ActionList
                pr={0}
                pl={1}
                actions={actions}
                dataTestId="view-actions"
                icon={<SolidMoreVertical />}
            />
        </div>
    )

    const showTitle = () => {
        if (activeEditor) return null

        if (view)
            return (
                <StyledHeading
                    startWithEditView
                    value={viewName}
                    variant="sideMenuSubHeading"
                    isPreviewFocusable={false}
                    onSubmit={stopEditing}
                    onCancel={stopEditing}
                    isEditable={state.editingName}
                    onChange={setName}
                />
            )

        return <StyledHeading variant="sideMenuSubHeading" value={viewName} />
    }

    let element = document.getElementById('sideMenu')
    element = layoutEditorContext.sideMenu || element
    if (!element) {
        // We do this because race conditions can mean that the blockSelector isn't yet out on the DOM
        // There may be a more idiomatic way to do it, but if there is, I don't know what it is
        window.setTimeout(() => forceUpdate(), 100)
        return null
    }

    const showHeader = !!activeEditor

    const saveButtonLabel = formatSaveButtonLabel(view, isConfigDirty)

    return createPortal(
        <>
            {showHeader && (
                <Flex
                    py={4}
                    px={noPadding ? 0 : 5}
                    borderBottomStyle="solid"
                    borderBottomWidth="1px"
                    borderBottomColor="grey.200"
                    className={ONBOARDING_CLASSES.EDIT_LAYOUT_HEADER}
                >
                    {activeEditor && (
                        <Button
                            data-testid="side-menu-back"
                            variant="clear"
                            size="sm"
                            icon="back"
                            p={0}
                            onClick={closeActiveEditor}
                            className={ONBOARDING_CLASSES.EDIT_LAYOUT_CLOSE_BUTTON}
                        />
                    )}
                    <Flex textAlign="center" flex={1} justifyContent="center">
                        <Heading
                            variant="sideMenuTypeHeading"
                            value={(activeEditor && activeEditor.title) || 'Layout'}
                        />
                    </Flex>
                    {!activeEditor && (
                        <Button
                            data-testid="side-menu-close"
                            variant="clear"
                            size="md"
                            icon="x"
                            p={0}
                            onClick={props.userActions.stopEditing}
                            className={ONBOARDING_CLASSES.EDIT_LAYOUT_CLOSE_BUTTON}
                        />
                    )}
                </Flex>
            )}
            <Box
                m={0}
                px={noPadding ? 0 : 5}
                display="flex"
                flexDirection="column"
                flex="1"
                overflow="auto"
                pb="5px"
            >
                {!activeEditor && (
                    <Box my={4}>
                        <Flex
                            m={0}
                            p={0}
                            justifyContent="space-between"
                            zIndex={1}
                            alignItems="center"
                        >
                            {showTitle()}
                            {additionalActions}
                            {showActions && actionButton}
                        </Flex>
                    </Box>
                )}

                <EditorPortal id="editorPortal" ref={setPortal} />

                <Box display={activeEditor ? 'none' : 'flex'} flexDirection="column" height="100%">
                    {children}
                </Box>
                {!hideSaveButton && !activeEditor && (
                    <SideMenuButton
                        isDirty={isConfigDirty}
                        label={saveButtonLabel}
                        onClick={saveView}
                    />
                )}
            </Box>
        </>,
        element
    )
}

export default withUser(ViewEditPane)

const EditorPortal = styled('div')`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    padding: 10px;
    margin: -10px;
    overflow: auto;

    &:empty {
        height: 0;
        padding: 0;
    }
`
