/* Code Quality: OK */
import React, { useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'

import { LoadingSplash } from 'v2/ui'

import AuthPageFrame from './stacker-auth/AuthPageFrame'
import SignInFlow from './stacker-auth/SignInFlow'
import { useAuth0Redirect } from './useAuth0Redirect'
export function SignupPage() {
    const { workspaceAccount } = useAppContext()
    const redirectToAuth0 = useAuth0Redirect()
    const [redirecting, setRedirecting] = useState(false)
    const useAuth0 = workspaceAccount && workspaceAccount.optional_features.auth0_enabled

    useEffect(() => {
        if (useAuth0) {
            redirectToAuth0()
            setRedirecting(true)
        }
    }, [useAuth0, redirectToAuth0])

    if (redirecting) {
        return <LoadingSplash />
    }
    return (
        <AuthPageFrame title="Create an account">
            <SignInFlow isSignUp />
        </AuthPageFrame>
    )
}

export default SignupPage
