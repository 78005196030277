// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

// @ts-ignore
import { Text } from 'v2/ui'

import { ProviderTypes } from '../../admin/data-connector/dataConnectorTypes'
import { ProviderIcon } from '../../admin/data-connector/ProviderIcon'

import { ProviderPickerItem } from './providerPickerTypes'

const ProviderTab = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 8px;
    margin: 4px;
    width: 110px;
    height: 80px;
    transition: background 0.3s, border-color 0.3s;
`

type ProviderPickerProps = {
    items: ProviderPickerItem<ProviderTypes>[]
    selected: ProviderTypes
    onClick: (id: ProviderTypes) => void
}
export const ProviderPicker: React.FC<ProviderPickerProps> = (props) => {
    return (
        <>
            {props.items.map((provider) => (
                <ProviderTab
                    key={provider.id}
                    style={{
                        border:
                            props.selected === provider.id
                                ? '3px solid #505bc2'
                                : '1px solid #dedee0',
                        padding: props.selected === provider.id ? 0 : 2,
                        background: props.selected === provider.id ? '#f5f5fd' : 'white',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (provider.id !== props.selected) {
                            props.onClick(provider.id)
                        }
                    }}
                >
                    <ProviderIcon type={provider.id} height="28px" size="28px" />
                    <Text>{provider.name}</Text>
                </ProviderTab>
            ))}
        </>
    )
}
