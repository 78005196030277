// @ts-strict-ignore
/* Code Quality: Good */

import React, { useCallback, useEffect, useState } from 'react'

import { List, ListItem } from '@chakra-ui/react'
import compact from 'lodash/compact'
import get from 'lodash/get'
import moment from 'moment'

import { fetchWithAuth } from 'data/utils/utils'
import { withDataConnections } from 'data/wrappers/WithDataConnections'
import { withStack } from 'data/wrappers/WithStacks'
import { fillCacheForObject, fillCacheForStack } from 'features/admin/data-connector/utils'
import AdminFrame from 'features/core/AdminFrame'
import { Button, ButtonModal, Container, Heading, Icon8, Section, Text } from 'legacy/v1/ui'

import { Flex } from 'v2/ui'
import SimpleList from 'v2/ui/components/List/List'

import { fieldFullName } from '../settings/object/utils'
import { SettingsContent, SettingsWrapper } from '../settings/ui/SettingsFrame'
import SettingsHeading from '../settings/ui/SettingsHeading'

const TableCacheInfoTable = ({ objectInfo, fillCache, isWithConnectedTables }) => {
    const renderToText = ({ cell }) => <Text>{cell.value}</Text>
    const renderToDate = ({ cell }) =>
        cell.value ? `${moment(cell.value).format()} (${moment(cell.value).fromNow()})` : ''
    const renderToDuration = ({ cell }) =>
        cell.value
            ? `${moment.duration(cell.value).as('milliseconds')} ms (${moment
                  .duration(cell.value)
                  .humanize()})`
            : 'N/A'

    const renderIsCached = ({ cell }) => {
        if (cell.row.original.no_cached_dc) return 'N/A'
        return cell.value ? (
            <img
                src="https://cdn.filestackcontent.com/eUZnXUWeThWQICEoR0zg"
                alt="Yes"
                style={{ width: '20px', height: '20px' }}
            />
        ) : (
            <img
                src="https://cdn.filestackcontent.com/jMv98nyCSXS6XxFvH1ng"
                alt="No"
                title="No"
                style={{ width: '18px', height: '18px' }}
            />
        )
    }

    const renderIsLoading = ({ cell }) => {
        const icon = cell?.value
            ? 'hourglass'
            : cell?.row.original.load_in_progress
            ? 'download'
            : null

        if (!icon) return null

        // @ts-expect-error
        return <Icon8 icon={icon} />
    }
    const columns = compact([
        {
            Header: 'Table',
            accessor: 'name',
            Cell: renderToText,
        },
        {
            Header: 'Cached',
            accessor: 'cached',
            Cell: renderIsCached,
        },
        {
            Header: 'Loading',
            accessor: 'manual_fill_requested',
            Cell: renderIsLoading,
        },
        isWithConnectedTables
            ? {
                  Header: 'Connected fields',
                  Cell: ({ cell }) => (
                      <Flex>
                          {cell.row.original.connected_fields.length ? (
                              <>
                                  <List styleType="disc" p={0}>
                                      {cell.row.original.connected_fields.map((f, i) => (
                                          <ListItem key={i}>
                                              {fieldFullName(
                                                  cell.row.original.connected_provider,
                                                  f
                                              )}
                                          </ListItem>
                                      ))}
                                  </List>
                              </>
                          ) : (
                              ''
                          )}
                      </Flex>
                  ),
              }
            : null,
        {
            Header: 'Records',
            accessor: 'records_cached',
            Cell: ({ cell }) =>
                'latest_cache_records' in cell.row.original &&
                cell.row.original.latest_cache_records !== null
                    ? `${cell.value} / ${cell.row.original.latest_cache_records}`
                    : cell.value
                    ? `${cell.value}`
                    : 'N/A',
        },
        {
            Header: 'Latest cache time (UTC)',
            accessor: 'latest_cache_time',
            Cell: renderToDate,
        },
        {
            Header: 'Filling time',
            accessor: 'latest_cache_duration',
            Cell: renderToDuration,
        },
        {
            Header: 'Actions',
            accessor: 'sid',
            Cell: ({ cell }) => {
                if (
                    cell.row.original.no_cached_dc ||
                    cell.row.original.dc_error ||
                    cell.row.original.type == 'native'
                )
                    return ''
                return (
                    <Flex flexDirection="column">
                        <Button
                            margin="none"
                            color="clear"
                            size="small"
                            onClick={() => {
                                fillCache(cell.value)
                            }}
                            disabled={cell.row.values?.manual_fill_requested}
                            style={{
                                fontWeight: 'normal',
                                fontSize: '80%',
                            }}
                        >
                            ⬆️ Fill
                        </Button>
                    </Flex>
                )
            },
        },
    ])

    let data = objectInfo.filter((info) => info.enabled)

    return (
        // @ts-ignore
        <SimpleList
            columns={columns}
            data={data}
            display="table"
            isRecordList={true}
            hideSearch={true}
            ignorePagination={true}
        />
    )
}

const CacheInfoPage: React.FC<any> = ({
    stack,
    dataConnections,
    onChange: onDataConnectionChange,
    refetchDataConnections,
}) => {
    const [cacheInfo, setCacheInfo] = useState<{
        cache_errors: any
        cache_backend: string
        precache_enabled: boolean
        unrecoverable_auth_errors: any
        postcache_init: boolean
        objects: any[]
        postcache_db_name?: string
        postcache_db_host?: string
        postcache_db_stack_prefix: string
    }>()
    const [errorsModalOpen, setErrorsModalOpen] = useState(false)
    const [errorsData, setErrorsData] = useState<{ [k: string]: any }>({})
    const [errorsModalTitle, setErrorsModalTitle] = useState<string>()

    const loadCacheInfo = useCallback(() => {
        return fetchWithAuth('cache/info', {}).then((response) =>
            response.json().then((result) => {
                setCacheInfo(result)
                return
            })
        )
    }, [setCacheInfo])

    useEffect(() => {
        loadCacheInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fillCache = (objectId) => {
        fillCacheForObject(objectId).then(() =>
            window.setTimeout(() => {
                loadCacheInfo()
            }, 1000)
        )
    }

    const showAuthErrors = useCallback(() => {
        const errorsData = Object.fromEntries(
            dataConnections
                .filter((dc) => dc.status !== 'valid')
                .map((dc) => [
                    dc._sid,
                    {
                        status: dc.status,
                        unrecoverable_auth_errors: get(dc, 'options.unrecoverable_auth_errors', []),
                    },
                ])
        )
        setErrorsModalOpen(true)
        setErrorsData(errorsData)
        setErrorsModalTitle('Unrecoverable auth errors')
    }, [dataConnections])

    const clearAuthErrors = useCallback(() => {
        dataConnections.forEach((dc) => {
            if (dc.status !== 'valid') {
                onDataConnectionChange(dc._sid, {
                    status: 'valid',
                    options: { ...dc.options, unrecoverable_auth_errors: [] },
                }).then(() => {
                    refetchDataConnections()
                    loadCacheInfo()
                })
            }
        })
    }, [dataConnections, onDataConnectionChange, refetchDataConnections, loadCacheInfo])

    const showCacheTriageErrors = useCallback(() => {
        setErrorsModalOpen(true)
        setErrorsData(cacheInfo?.cache_errors)
        setErrorsModalTitle('Cache errors triage log')
    }, [cacheInfo])

    const clearCacheTriageErrors = useCallback(() => {
        fetchWithAuth(`stacks/${stack._sid}/operation/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ operation: 'clear_postcache_error_log' }),
        })
            .then(() => {
                loadCacheInfo()
            })
            .catch(() => {})
    }, [stack, loadCacheInfo])

    return (
        <AdminFrame header={false}>
            <SettingsHeading name="Record Cache" parents={[]} />
            <SettingsWrapper>
                {cacheInfo && (
                    <>
                        <SettingsContent style={{ marginBottom: 20 }}>
                            <Section>
                                <Container>
                                    <Text>
                                        Cache status:
                                        <ul>
                                            <li>
                                                Pre-loading:{' '}
                                                {cacheInfo.precache_enabled ? 'Yes' : 'No'}
                                            </li>
                                            <li>
                                                Auth errors blocking pre-loading:{' '}
                                                {cacheInfo.unrecoverable_auth_errors
                                                    ? 'Yes '
                                                    : 'No'}
                                                {cacheInfo.unrecoverable_auth_errors && (
                                                    <>
                                                        <a onClick={showAuthErrors}>(view)</a>{' '}
                                                        <a onClick={clearAuthErrors}>(clear)</a>
                                                    </>
                                                )}
                                            </li>
                                            <li>Cache backend: {cacheInfo.cache_backend}</li>
                                            <li>
                                                Postcache initialised:{' '}
                                                {cacheInfo.postcache_init ? 'Yes ' : 'No'}
                                            </li>
                                            <li>
                                                Postcache database:{' '}
                                                {cacheInfo.postcache_db_name
                                                    ? `${cacheInfo.postcache_db_host} ▸ ${cacheInfo.postcache_db_name} ▸ ${cacheInfo.postcache_db_stack_prefix}_*`
                                                    : 'None'}
                                            </li>
                                            <li>
                                                Cache errors logged:{' '}
                                                {cacheInfo.cache_errors &&
                                                Object.keys(cacheInfo.cache_errors).length
                                                    ? 'Yes '
                                                    : 'No'}
                                                {cacheInfo.cache_errors &&
                                                    Object.keys(cacheInfo.cache_errors).length >
                                                        0 && (
                                                        <>
                                                            <a onClick={showCacheTriageErrors}>
                                                                (view)
                                                            </a>{' '}
                                                            <a onClick={clearCacheTriageErrors}>
                                                                (clear)
                                                            </a>
                                                        </>
                                                    )}
                                            </li>
                                        </ul>
                                    </Text>
                                </Container>
                            </Section>
                        </SettingsContent>

                        {cacheInfo.objects && (
                            <Section>
                                <Container spaced>
                                    <Heading size="medium">Tables</Heading>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            fillCacheForStack().then(() =>
                                                window.setTimeout(() => loadCacheInfo(), 1000)
                                            )
                                        }}
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: '80%',
                                        }}
                                    >
                                        ⬆️ Fill all
                                    </Button>
                                </Container>
                                <TableCacheInfoTable
                                    objectInfo={cacheInfo.objects.sort((x, y) =>
                                        x.name < y.name ? -1 : x.name > y.name ? 1 : 0
                                    )}
                                    fillCache={fillCache}
                                    isWithConnectedTables={
                                        stack?.combined_optional_features?.connected_tables
                                    }
                                />
                            </Section>
                        )}

                        <ButtonModal
                            onClose={() => setErrorsModalOpen(false)}
                            open={errorsModalOpen}
                            dialog
                            header={() => (
                                <Heading
                                    style={{ padding: 0, margin: 0, paddingLeft: 16 }}
                                    size="medium"
                                >
                                    {errorsModalTitle}
                                </Heading>
                            )}
                            button={false}
                        >
                            {() => (
                                <textarea style={{ height: '300px' }}>
                                    {JSON.stringify(errorsData, null, 4).replaceAll(
                                        '\\n',
                                        '\n        '
                                    )}
                                </textarea>
                            )}
                        </ButtonModal>
                    </>
                )}
            </SettingsWrapper>
            <Button
                color="clear"
                size="small"
                style={{
                    position: 'fixed',
                    top: '60px',
                    right: '10px',
                    fontWeight: 'normal',
                }}
                onClick={() => {
                    refetchDataConnections()
                    loadCacheInfo()
                }}
            >
                🔄
            </Button>
        </AdminFrame>
    )
}

export default withDataConnections(withStack(CacheInfoPage))
