import React, { FC } from 'react'

import { useTheme } from '@chakra-ui/react'

type Props = {
    color?: string
    size?: number
}

const WidgetCharts: FC<Props> = ({ color, size = 18 }) => {
    const theme = useTheme()
    const iconColor = color || theme.colors.grey[600]

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.35" y="0.35" width="13.3" height="13.3" rx="1.75" fill="transparent" />
            <path
                d="M4.07779 9.56554C4.12061 9.60837 4.18968 9.60837 4.23389 9.56554L6.14446 7.66464L7.90721 9.43845C7.95004 9.48127 8.02049 9.48127 8.06332 9.43845L11.8679 5.63527C11.9107 5.59244 11.9107 5.52199 11.8679 5.47916L11.3208 4.9321C11.3 4.91153 11.272 4.89999 11.2428 4.89999C11.2135 4.89999 11.1855 4.91153 11.1647 4.9321L7.98734 8.10809L6.22735 6.33705C6.20658 6.31648 6.17853 6.30495 6.1493 6.30495C6.12006 6.30495 6.09201 6.31648 6.07124 6.33705L3.53211 8.86099C3.51154 8.88176 3.5 8.90981 3.5 8.93905C3.5 8.96828 3.51154 8.99633 3.53211 9.0171L4.07779 9.56554Z"
                fill={iconColor}
            />
            <rect x="2.09998" y="11.2" width="9.8" height="0.7" rx="0.35" fill="#D0D4D9" />
            <rect
                x="2.09998"
                y="11.9"
                width="9.1"
                height="0.7"
                rx="0.35"
                transform="rotate(-90 2.09998 11.9)"
                fill="#D0D4D9"
            />
            <rect
                x="0.35"
                y="0.35"
                width="13.3"
                height="13.3"
                rx="1.75"
                stroke="#AEB5BC"
                strokeWidth="0.7"
            />
        </svg>
    )
}

export default WidgetCharts
