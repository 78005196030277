// @ts-strict-ignore
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { BoxProps, Spinner, Switch } from '@chakra-ui/react'
import { bindActionCreators } from 'redux'

import { useAppUserContext } from 'app/AppUserContext'
import { userActions } from 'data/api/userApi'

import { ActionList, Box, Button, Collapse, Flex, Text } from 'v2/ui'
import { ActionListItem } from 'v2/ui/components/ActionList'

import SubmitButton from '../../../ui/forms/SubmitButton'
import useIsFormDirty from '../../../ui/forms/useIsFormDirty'

import { GroupListItemType } from './groupListItemTypes'

export const AccessEnabledSwitch: React.FC<{
    isChecked: boolean
    onChange: (value: boolean) => undefined | Promise<any>
    layoutProps?: BoxProps
    isDisabled?: boolean
}> = ({ isChecked, layoutProps, onChange, isDisabled }) => {
    const [isSaving, setIsSaving] = useState(false)
    const handleChange = (e) => {
        const promise = onChange(e.target.checked)

        if (promise instanceof Promise) {
            setIsSaving(true)
            promise.then(() => setIsSaving(false))
        }
    }
    return (
        <Box {...layoutProps}>
            {isSaving ? (
                <Spinner size="sm" color="neutral.800" verticalAlign="middle" />
            ) : (
                <Switch
                    size="sm"
                    isChecked={isChecked}
                    onChange={handleChange}
                    mt="3px"
                    isDisabled={isDisabled}
                />
            )}

            <Text
                size="sm"
                color="neutral.800"
                ml={2}
                display="inline"
                fontWeight="bold"
                verticalAlign="middle"
            >
                {isChecked ? 'Access Enabled' : 'Access Disabled'}
            </Text>
        </Box>
    )
}

export const PlaceholderBox = ({ children }) => {
    return (
        <Flex
            column
            alignItems="center"
            justifyContent="center"
            border="1px solid"
            borderColor="neutral.400"
            bg="neutral.150"
            borderRadius="5px"
            flexGrow={1}
        >
            {children}
        </Flex>
    )
}

export const LoadingPlaceholder = ({ children }) => {
    return (
        <PlaceholderBox>
            <Spinner size="md" color="neutral.500" mb={3} />
            <Text color="neutral.700" textAlign="center">
                {children}
            </Text>
        </PlaceholderBox>
    )
}

type UserActionsProps = {
    user: any
    onRemove?: () => void
}
export const UserActions = ({ user, onRemove }: UserActionsProps) => {
    const { previewAsUser } = useAppUserContext()
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const boundUserActions = bindActionCreators(userActions, dispatch)

    const impersonateUser = () => {
        const userId = user._sid
        setIsLoading(true)
        // @ts-expect-error
        return boundUserActions.impersonate(userId, false).then((response) => {
            previewAsUser(response)
            setIsLoading(false)
        })
    }

    const actions: ActionListItem[] = []

    // Only if user has access
    if (user.role && user.role !== 'internal_admin') {
        actions.push({ icon: 'play', label: 'Preview as user', action: impersonateUser })
    }
    if (onRemove) {
        actions.push({ divider: true })
        actions.push({ icon: 'minusCircle', label: 'Remove from app', action: onRemove })
    }

    if (!actions.length) return null

    if (isLoading) return <Spinner size="sm" color="neutral.600" />
    return (
        <ActionList
            variant="iconButton"
            buttonSize="smDense"
            icon="dotsH"
            color="neutral.800"
            actions={actions}
        />
    )
}

type GroupActionsProps = {
    group: GroupListItemType
    onRemove?: () => void
}
export const GroupActions = ({ onRemove }: GroupActionsProps) => {
    const [isLoading] = useState(false)

    const actions: ActionListItem[] = []

    if (onRemove) {
        //actions.push({ divider: true })
        actions.push({ icon: 'minusCircle', label: 'Remove group from app', action: onRemove })
    }

    if (isLoading) return <Spinner size="sm" color="neutral.600" />
    return (
        // @ts-ignore
        <ActionList
            variant="iconButton"
            buttonSize="smDense"
            icon="dotsH"
            color="neutral.800"
            actions={actions}
        />
    )
}

export function SaveChangesBar({ error, cancelEdit }) {
    const hasChanges = useIsFormDirty()

    return (
        <Collapse isOpen={hasChanges}>
            <Flex column wrap="nowrap" align="flex-end" bg="neutral.200" p={2}>
                <Flex wrap="nowrap">
                    {!error && (
                        <Text mr={4} fontWeight="bold" color="neutral.800">
                            There are pending changes
                        </Text>
                    )}
                    <Collapse isOpen={!!error}>
                        <Text variant="error" mr={4}>
                            {error}
                        </Text>
                    </Collapse>
                    <Button variant="adminSecondaryV4" onClick={cancelEdit} mr={2}>
                        Cancel
                    </Button>
                    <SubmitButton v4 requireChanges>
                        Save
                    </SubmitButton>
                </Flex>
            </Flex>
        </Collapse>
    )
}
