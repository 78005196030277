/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import store from 'app/store'

export const unsynched__isStudioUser = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const studioUser = store.getState().user.studioUser
    return Boolean(studioUser)
}

export const unsynched__isLoggedIn = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const user = store.getState().user.user
    return Boolean(user)
}

export const unsynched__getUser = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const user = store.getState().user.user
    return user
}

export const unsynched__getStudioUser = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const user = store.getState().user.studioUser
    return user
}
export const unsynched__getUserToken = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const user = store.getState().user.user
    if (!user) return null
    return user.api_token && user.api_token.length > 9 ? user.api_token : null
}

export const unsynched__getStudioToken = () => {
    // This won't auto update, so can only be used in action handlers, not in React components
    const studioUser = store.getState().user.studioUser
    if (!studioUser) return null
    return studioUser.api_token
}
