export const BILLFLOW_SCRIPT_ATTR = 'data-billflow-script'

/**
 * Pricing tiers ordered by ascending price
 */
export const TIERS = {
    Starter: 1,
    Plus: 2,
    Pro: 3,
    Enterprise: 4,
}

/**
 * Matches a feature to the reasons it might not be allowed or its user-friendly name
 */
export type FeatureRequirementsMap = { [feature: string]: (_: any) => string | null }

export type FeatureUsage = {
    name: string
    count?: number
    is_skip_tier_restriction: boolean
    required_tier: keyof typeof TIERS
}
