import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { useUpdateStackOptions } from 'data/hooks/stacks'

import { Button, Icon, Modal, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { useAppSharingSettingsModal } from '../../users/ManageAppSharingSettingsModal'
import { useAppSettings } from '../../workspace/AdminSideTray/hooks/useAppSettings'

const colors = stackerTheme().colors

const openFunction = {}

// passing the provider type of the first data connection is no longer used for anything in this modal UI
// right now it just acts as a flag to show this modal, so could be a boolean,
// but might be useful metadata to have around for future additions to this flow,
// so let's keep it around
export function showFirstDataConnectedModal(providerType) {
    openFunction.setProviderType(providerType)
}

export function FirstDataConnectedModal() {
    const { show: showAppSharingSettingsModal } = useAppSharingSettingsModal()
    const { open: openAppSettings } = useAppSettings()
    const [providerType, setProviderType] = useState()

    useEffect(() => {
        openFunction.setProviderType = setProviderType
    }, [])

    const { selectedStack } = useAppContext()
    const updateStackOptions = useUpdateStackOptions()

    const handleSetupCustomerAccess = async () => {
        await updateStackOptions(selectedStack, { enable_external_access: true })

        openAppSettings({
            page: {
                name: 'customer_access',
                meta: {
                    section: 'add_customer_list',
                },
            },
        })
    }

    const closeModal = () => {
        setProviderType(null)
    }
    if (!providerType) return null

    return (
        // @david - commenting the tour out for now until we figure out how to better integrate
        // the new app creation flow with this, UX wise
        //
        // <VirtualTourOverlay tour={FirstDataConnectedTour} delayRenderChildren={4000}>
        <Modal isOpen showCloseButton={false} size={'720px'}>
            <div
                style={{
                    borderRadius: '50%',
                    background: V4DesignSystem.colors.blue[200],
                    width: 80,
                    height: 80,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                    marginTop: 8,
                }}
            >
                <Icon icon="addUserSolid" size="28px" color={V4DesignSystem.colors.blue[1000]} />
            </div>

            <Text
                fontSize="24px"
                fontWeight="bold"
                textAlign="center"
                color="neutral.900"
                mt="24px"
            >
                Add first users
            </Text>

            <Text fontSize="16px" textAlign="center" color="neutral.900" mt="6px" mb="16px">
                How do you want to add users?
            </Text>

            <AddUsersMethod
                title="Send invitation to email address"
                info="Recommended when adding team collaborators"
                icon="email"
                onClick={() => {
                    closeModal()
                    showAppSharingSettingsModal()
                }}
            />

            <AddUsersMethod
                title="Select a table with user's data"
                info="Recommended when adding customers, partners, consultants, etc"
                icon="tableLight"
                onClick={() => {
                    closeModal()
                    handleSetupCustomerAccess()
                }}
            />

            <Button
                variant="adminSecondaryV4"
                buttonSize="sm"
                onClick={() => {
                    closeModal()
                }}
                mt="14px"
            >
                I&apos;ll do it later
            </Button>
        </Modal>
        // </VirtualTourOverlay>
    )
}

/** @type {VIRTUAL_TOUR_OVERLAY_TOUR}} */
// Unclear why this has not been removed earlier. No capacity to investigate.
// eslint-disable-next-line unused-imports/no-unused-vars
const FirstDataConnectedTour = {
    key: 'first_data_connected',
    elements: [
        {
            icon: 'table',
            title: 'Your Data',
            subtitle: (
                <>
                    The app is now connected to your data. <br />
                    You can click on a record to edit it.
                </>
            ),
            targetElement: `table-container`,
            offsetX: 200,
            offsetY: -75,
            offsetOriginY: 'top',
            arrowTargetY: 'top',
            arrowTargetOffsetY: 50,
            delay: 200,
        },
        {
            icon: 'pointer',
            title: 'Navigate',
            subtitle: (
                <>
                    Use the navigation to <br /> move around your app.
                </>
            ),
            targetElement: 'navTree',
            offsetX: 300,
            offsetY: -50,
            offsetOriginX: 'left',
            offsetOriginY: 'top',
            delay: 1200,
            arrowTargetY: 'top',
            arrowTargetOffsetY: 50,
        },
        {
            icon: 'cog',
            title: 'Customize',
            subtitle: (
                <>
                    Change your layouts, invite your users,
                    <br /> apply permissions and much more.
                </>
            ),
            targetElement: 'appAdminTray',
            offsetX: 0,
            offsetY: -200,
            offsetOriginX: 'left',
            arrowTargetY: 'top',
            delay: 2200,
        },
    ],
}

const StyledAddUsersMethod = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;

    border: 1px solid ${colors.gray[200]};
    align-items: center;
    border-radius: 5px;
    margin-top: 12px;
    padding: 24px 32px;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 32px;
    }

    .text-container {
        color: #1f2123;
        .title {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
        }

        .info {
            font-size: 14px;
        }
    }

    &:hover {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    }
`

function AddUsersMethod({ icon, title, info, onClick }) {
    return (
        <StyledAddUsersMethod onClick={onClick}>
            <div className="icon-container">
                <Icon icon={icon} size="38px" color={V4DesignSystem.colors.blue[1000]} />
            </div>
            <div className="text-container">
                <div className="title">{title}</div>
                <div className="info">{info}</div>
            </div>
        </StyledAddUsersMethod>
    )
}
