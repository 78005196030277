import Color from 'color'

import {
    DROPDOWN_COLORS,
    DROPDOWN_TYPES,
    IMAGE_FIELD_TYPES,
    LINK_TYPES,
    MAGIC_SYNTHETIC_FIELD_TYPES,
} from 'data/utils/fieldDefinitions'
import { COLORS_ORDER } from 'features/admin/fields/DropdownFieldConfiguration/constants'
import { FIELD_NAME_REGEX } from 'features/formulas/constants/formulaRegexConstants'

import { ThemeConfig } from 'v2/ui/theme/styles/types'
import { AppColors } from 'v2/ui/theme/styles/types/colorBase'

export const getIsSyntheticField = (field?: Partial<FieldDto>): boolean =>
    !!field?.synthetic_field_type

export const isFormulaField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'formula'

export const getIsRelationshipField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'relationship'

export const getIsSymmetricRelationshipField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'symmetric_relationship'

export const getIsMultiLookupRelationship = (field?: Partial<FieldDto>): boolean =>
    field?.type === 'multi_lookup' && getIsRelationshipField(field)

export const getIsMultiLookupSymmetricRelationship = (field?: Partial<FieldDto>): boolean =>
    field?.type === 'multi_lookup' && getIsSymmetricRelationshipField(field)

export const getIsFieldLink = (field: FieldDto): boolean => LINK_TYPES.includes(field.type)

export const getIsDropdownField = (field: FieldDto): boolean => DROPDOWN_TYPES.includes(field.type)

export const getIsSingleLinkLookupTargetField = (field: FieldDto): boolean =>
    !getIsSyntheticField(field) ||
    MAGIC_SYNTHETIC_FIELD_TYPES.includes(field.synthetic_field_type as any)

export const getIsMultiLinkLookupTargetField = (field: FieldDto): boolean =>
    getIsSingleLinkLookupTargetField(field) && field.type != 'user_ref'

export const getDropdownFieldOptionColor = (
    fieldOption: Partial<FieldOptions>
): string | undefined => {
    if (fieldOption.color) {
        return DROPDOWN_COLORS[fieldOption.color] || DROPDOWN_COLORS[COLORS_ORDER[0]]
    }

    return fieldOption?.airtable_color ?? undefined
}

export function getDropdownFieldOptionTextColor(
    fieldOption: Partial<FieldOptions>,
    theme: ThemeConfig
): string | undefined {
    const { color, airtable_color } = fieldOption

    const { colors: themeColors } = theme

    if (color) {
        let currentThemeColor = themeColors.appColors[color as keyof AppColors]

        if (!currentThemeColor) {
            currentThemeColor = themeColors.appColors[COLORS_ORDER[0] as keyof AppColors]
        }

        return currentThemeColor[900]
    }

    if (airtable_color) {
        if (Color(airtable_color).isDark()) {
            return themeColors.userInterface.neutral[0]
        }

        return themeColors.userInterface.neutral[1000]
    }

    return undefined
}

const formulaSupportedFields: Set<FieldType> = new Set([
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'image',
    'long_text',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'percentage',
    'string',
    'url',
    'user_ref',
] as FieldType[])

export const isFormulaSupportedField = (field: FieldDto): boolean =>
    formulaSupportedFields.has(field.type)

export const isDisabled = (field: FieldDto): boolean => !!field.connection_options.is_disabled

export const isFieldParseable = (field: FieldDto): boolean =>
    !!`{${field.label}}`.match(FIELD_NAME_REGEX)

export const getCoverImageFields = (fields: FieldDto[]) =>
    fields
        .filter((field) => !field.connection_options.is_disabled)
        .filter((field) => IMAGE_FIELD_TYPES.includes(field.type))

export const getIsUserRefField = (field: FieldDto): boolean => field.type === 'user_ref'

export const canBePrimaryField = (field: FieldDto) => {
    const disallowedTypes = ['multi_lookup', 'lookup', 'multi_file', 'multi_select']
    // Formulas as primary fields can cause queries to become blocked if they contain lookup fields
    const disallowedSyntheticTypes: (string | undefined | null)[] = ['formula']
    return (
        !disallowedTypes.includes(field.type) &&
        !field?.connection_options?.is_disabled &&
        !disallowedSyntheticTypes.includes(field?.synthetic_field_type)
    )
}

export const canBeHeaderField = (field: FieldDto) => {
    const disallowedTypes = ['multi_lookup', 'lookup', 'multi_file', 'multi_select']
    return !disallowedTypes.includes(field.type) && !field?.connection_options?.is_disabled
}
