import React from 'react'

import {
    LinkListActions,
    LinkListContainer,
    LinkListEmptyState,
    LinkListHeader,
} from 'features/links'

import Button from 'ui/deprecated/atoms/Button'

export const LinkModalListView = ({ links = [], onNewClicked, children }) => {
    const newButton = (
        // @ts-expect-error
        <Button v4 icon="add" type="primary" onClick={onNewClicked}>
            New link
        </Button>
    )
    return (
        <LinkListContainer
            links={links}
            header={<LinkListHeader direction="horizontal" links={links} />}
            actions={<LinkListActions>{newButton}</LinkListActions>}
            emptyState={
                <LinkListEmptyState
                    header={<LinkListHeader direction="vertical" centered={true} />}
                >
                    {newButton}
                </LinkListEmptyState>
            }
        >
            {children}
        </LinkListContainer>
    )
}
