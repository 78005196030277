import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { invalidateMetrics } from 'data/hooks/metrics/legacyMetrics'

export default function DashboardCacheAutoFlush() {
    const history = useHistory()
    useEffect(() => {
        invalidateMetrics()
    }, [history.location.pathname])

    return null
}
