import { style, StyleRule } from '@vanilla-extract/css'

import { setStyles as setStandardStyles, StandardStyles } from '../theme/StandardStyles.css'
import { UtilityPropNames, UtilityProps, UtilityStyles } from './utilityProps.css'

export type StandardComponentProps = StandardStyles & UtilityProps

type StandardStyleRule = StyleRule & StandardStyles
type AcceptedStyles = StandardStyleRule | Array<StandardStyleRule>
const utilityStylePropNames = new Set(Object.keys(UtilityStyles) as UtilityPropNames[])

function _extractStandardAndUtilityProps<T extends StandardComponentProps>(
    props: T,
    keysToPassThrough?: Set<keyof T>
): [StandardStyles, UtilityProps, Omit<Omit<T, keyof StandardStyles>, UtilityPropNames>] {
    const [styleProps, remaining] = extractProps(
        props,
        {} as StandardStyles,
        setStandardStyles.properties,
        keysToPassThrough as Set<keyof StandardStyles>
    )
    const [utilityProps, final] = extractProps(
        remaining,
        {} as UtilityProps,
        utilityStylePropNames,
        keysToPassThrough as Set<keyof UtilityProps>
    )
    return [styleProps, utilityProps, final]
}

/** Extracts the standard style and utility props from the specified
 * props object and returns an array with the standard props and the remaining props
 */
export function extractStandardProps<T extends StandardComponentProps>(
    props: T,
    keysToPassThrough?: Set<keyof T>
): [StandardComponentProps, Omit<Omit<T, keyof StandardStyles>, UtilityPropNames>] {
    const [styleProps, utilityProps, final] = _extractStandardAndUtilityProps(
        props,
        keysToPassThrough
    )
    return [{ ...styleProps, ...utilityProps }, final]
}

/** Extracts the standard style and utility props from the specified Props object,
 * transforms those into the corresponding classNames and returns a final props
 * object which is ready to be passed to a component
 */
export function transformStandardProps<T extends StandardComponentProps>(
    props: T & { className?: string },
    keysToPassThrough?: Set<keyof T>
): Omit<Omit<T, keyof StandardStyles>, UtilityPropNames> & {
    className: string
} {
    const [styleProps, utilityProps, final] = _extractStandardAndUtilityProps(
        props,
        keysToPassThrough
    )

    const result = {
        ...final,
        className: composeClassNames(
            props.className,
            setStandardStyles(styleProps),
            ...Object.entries(utilityProps).map(([key, value]) =>
                Boolean(value) ? UtilityStyles[key as UtilityPropNames] : undefined
            )
        ),
    }

    return result
}

// Note, we need the target parameter to be passed in with the
// desired target type, otherwise we aren't able to return strongly typed
// results.
export function extractProps<
    TTarget extends Object,
    TSource extends TTarget,
    K extends keyof TTarget
>(
    props: TSource,
    target: TTarget,
    keysToExtract: Set<K>,
    keysToPassThrough?: Set<K>
): [TTarget, Omit<TSource, K>] {
    let otherProps = { ...props }
    for (const key of Object.keys(props) as K[]) {
        if (keysToExtract.has(key)) {
            target[key] = props[key]

            // some keys may be extracted, but left in the source object as well
            if (!keysToPassThrough || !keysToPassThrough.has(key)) {
                delete otherProps[key]
            }
        }
    }

    return [target, otherProps]
}

// Combines our StandardStyles with the full set of CSS style properties.
// This ensures that the values supplied to any of our standard styles are theme-aligned
// and get passed to our setStyles function. The rest of the non-standard styles
// get passed to the normal style function.
export function createStyles(styles: AcceptedStyles): string {
    const stylesArray = Array.isArray(styles) ? styles : [styles]
    const resultClasses: string[] = []
    for (const styleSet of stylesArray) {
        const standardStyles: Record<string, unknown> = {}
        const extendedStyles = { ...styleSet }
        // Check for each standard style property and if it has been supplied
        // pull it out and put it into standardStyles
        for (const key of Array.from(setStandardStyles.properties)) {
            if (key in extendedStyles) {
                standardStyles[key] = extendedStyles[key]
                delete extendedStyles[key]
            }
        }
        // If some standard styles were supplied, pass those through to our
        // standard styles function for application.
        if (Object.keys(standardStyles).length) {
            resultClasses.push(setStandardStyles(standardStyles))
        }
        // Send the rest through to the normal style function
        resultClasses.push(style(extendedStyles))
    }

    return resultClasses.join(' ')
}

// Takes a list of class names, removes falsey values, and also deduplicates
// our standard style atomic classes. This deduplication ensures that the last
// class supplied by the developer for a given property is the one that gets applied.
export function composeClassNames(...classNames: Array<string | undefined>) {
    return classNames.filter(Boolean).join(' ')
}
