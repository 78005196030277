import React from 'react'

import { Icon8 } from 'legacy/v1/ui'

import { Box, ConditionalWrapper, Draggable, Icon, Text, WithBadge } from 'v2/ui'

const BlockDraggable = ({ label, icon, description, iconStyle, isNew, ...props }) => {
    const getIcon = () => {
        // old style
        if (typeof icon === 'string') {
            return (
                <Icon8
                    large
                    size="100"
                    color="999999"
                    iconStyle={iconStyle || 'material'}
                    displaySize="24"
                    icon={icon || label}
                    draggable={false}
                />
            )
        }

        return <Icon svg={icon} ml={1} mr={3} size="admin.palette.widget.icon" alignSelf="center" />
    }

    return (
        <Draggable {...props}>
            {getIcon()}
            <Box flex={1}>
                <ConditionalWrapper
                    condition={isNew}
                    wrapper={(children) => (
                        <WithBadge
                            label="New"
                            badgeColor="userInterface.success.500"
                            fontSize={100}
                            aria-hidden="true"
                        >
                            {children}
                        </WithBadge>
                    )}
                >
                    <Text variant="paletteWidgetLabel">{label}</Text>
                </ConditionalWrapper>

                <Text variant="paletteWidgetDescription">{description}</Text>
            </Box>
        </Draggable>
    )
}

export default BlockDraggable
