// @ts-strict-ignore
import React from 'react'

import { Flex, Link, Modal, Text } from 'v2/ui'
import { BigStart, BigTemplates } from 'v2/ui/svgs'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'

type TemplateOrBlankAppModalProps = {
    isOpen: boolean
    onClose: () => void
    createBlankApp: () => void
    selectTemplate: () => void
    showCloseButton?: boolean
}

const TemplateOrBlankAppModal = ({
    isOpen,
    onClose,
    createBlankApp,
    selectTemplate,
    showCloseButton,
}: TemplateOrBlankAppModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Create a new app"
            size="460px"
            height="300px"
            headerVariant="largeCentered"
            showCloseButton={showCloseButton}
        >
            <Flex justify="space-around" mt="15px">
                <LargeButton
                    title="Start from scratch"
                    description="Create a new app from scratch."
                    icon={<BigStart />}
                    onClick={createBlankApp}
                    testId="create-new-app.start-from-scratch"
                />
                <LargeButton
                    title="Choose a template"
                    description="Choose a template and create your app."
                    icon={<BigTemplates />}
                    onClick={selectTemplate}
                    testId="create-new-app.choose-template"
                />
            </Flex>
        </Modal>
    )
}

export default TemplateOrBlankAppModal

export const LargeButton = ({
    title,
    description,
    icon,
    onClick,
    testId,
    width = '160px',
    ...props
}) => {
    return (
        <>
            <Link
                as="div"
                style={{
                    display: 'flex',
                    width: width,
                    height: '140px',
                    borderRadius: 4,
                    textDecoration: 'none',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                }}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={V4DesignSystem.colors.gray[100]}
                padding="20px"
                onClick={onClick}
                data-testid={testId}
                {...props}
            >
                {icon}
                <Text variant="largeButtonTitle">{title}</Text>
                <Text variant="largeButtonDescription">{description}</Text>
            </Link>
        </>
    )
}
