import React from 'react'

import HELP_LINKS from 'app/helpLinks'

import { Flex, Link } from 'v2/ui'

export const AirtableOAuthLearnMoreLink = (): React.ReactElement => (
    <Flex marginTop={5} flexDirection={'column'} alignItems={'flex-start'}>
        {
            <Link
                fontSize="xs"
                borderBottom="1px solid"
                variant="noHover"
                color="userInterface.accent.800"
                href={HELP_LINKS.AIRTABLE_OAUTH_LEARN_MORE}
            >
                Learn more
            </Link>
        }
    </Flex>
)
