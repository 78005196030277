import { useEffect, useRef } from 'react'

import { debounce } from 'lodash'

import { fetchWithAuth } from '../../../data/utils/fetchWithAuth'

export function usePotentialBaseUrl(
    accountName: string,
    setPotentialBaseUrl: (param: string | undefined) => void,
    setSubdomainError: (param: string | undefined) => void
): void {
    const { current: getPotentialBaseUrlForName } = useRef(
        debounce(async (accountName: string): Promise<void> => {
            if ((accountName || '').trim() === '') {
                setPotentialBaseUrl(undefined)

                return
            }

            let errorMsg

            try {
                const res = await fetchWithAuth(
                    `account/check-name/?name=${encodeURIComponent(accountName)}`
                )

                const successful = res.status < 400

                if (successful) {
                    const { base_url } = await res.json()

                    setPotentialBaseUrl(base_url)
                    setSubdomainError(undefined)

                    return
                }

                // specific error handling based on res status goes here
                //
                // if not handled, falls through to a generic error message
                if (res.status === 400) {
                    // a 400 ValidationError indicates that this particular org name would clash with a 'strict' reserved subdomain
                    // i.e. we just won't allow it at all
                    //
                    // NOTE purposefully obscuring any techy details about subdomains and reserved keywords,
                    // just say org name is taken. This is only really half true because outside of reserved subdomains
                    // org name duplication is fine (for now) but it's a lot easier to communicate!

                    errorMsg = 'This name is taken. Please choose another.'
                }
            } catch (err) {
                // log and fall through on any unexpected error
                console.error('Error getting url preview', err)
            }

            setSubdomainError(
                errorMsg ??
                    // IMPORTANT
                    // this message is just the catch all for unexpected errors
                    // better than showing nothing if, say, the backend is down
                    // for any other expected error we should have a specific and
                    // useful errMsg set letting user know exactly what to do to continue,
                    // super important as this is part of onboarding!
                    'There was an issue previewing the URL for this organisation name. Please wait a moment and try again.'
            )

            // best thing to do UI wise on an error is clear out the previous url preview and just show nothing,
            // this will get replaced with the result of any subsequent good request - don't want to leave
            // user in a state where we're showing an error but with the previw state of an org name they previously typed
            setPotentialBaseUrl(undefined)
        }, 500)
    )

    useEffect(() => {
        getPotentialBaseUrlForName(accountName?.trim())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountName])
}
