import * as React from 'react'
import { forwardRef } from 'react'

import { useDisclosure } from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import { Collapse, Icon, Input, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from 'ui/deprecated/atoms/Flex'
import DiscardButton from 'ui/forms/DiscardButton'
import SubmitButton from 'ui/forms/SubmitButton'
import { SyncRefWithForm } from 'ui/forms/utils'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'

const colors = stackerTheme().colors

export function Header({ children }) {
    return <div style={V4DesignSystem.h1}>{children}</div>
}

export function Subtitle({ children }) {
    return <div style={{ ...V4DesignSystem.pageSubtitle, marginTop: 8 }}>{children}</div>
}

function Spacer({ style = {} }) {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}

export const FormHeader = ({ title, subtitle, rightSideContent }) => (
    <Flex style={{ alignItems: 'flex-start' }}>
        <Flex column style={{ flexGrow: 1 }}>
            <Header>{title}</Header>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Flex>
        {rightSideContent}
    </Flex>
)

export function FormFrame({
    formRef,
    title,
    subtitle = null,
    children = [],
    titleBarRightContent = null,
    bottomBorder = null,
    padding,
    paddingFooter,
}) {
    if (!formRef) {
        throw new Error('missing formRef')
    }

    return (
        <>
            <SyncRefWithForm formRef={formRef} />
            <Flex column style={{ width: '100%', height: '100%' }}>
                <div style={{ flex: 1, overflowY: 'auto', padding: padding ?? 20 }}>
                    <FormHeader
                        title={title ?? null}
                        subtitle={subtitle}
                        rightSideContent={titleBarRightContent}
                    />
                    {bottomBorder ? <Divider /> : <div style={{ height: 11, minHeight: 11 }} />}

                    {children}
                </div>
                <FormFooter padding={paddingFooter} />
            </Flex>
        </>
    )
}

export function Frame({ title, subtitle, children, titleBarRightContent, noPadding, ...props }) {
    return (
        <Flex
            column
            style={{
                width: '100%',
                height: '100%',
                padding: noPadding ? 0 : 20,
                overflowY: 'auto',
                ...props,
            }}
        >
            {title && (
                <>
                    <Flex style={{ alignItems: 'center' }}>
                        <Flex column style={{ flexGrow: 1 }}>
                            <Header>{title}</Header>
                            {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        </Flex>
                        {titleBarRightContent}
                    </Flex>
                    <div style={{ height: 11, minHeight: 11 }} />
                </>
            )}
            {children}
        </Flex>
    )
}

function FormFooter({ padding }) {
    return (
        <>
            <Flex style={{ borderTop: '1px solid ' + colors.gray[100], padding: padding ?? 20 }}>
                <SubmitButton v4 requireChanges>
                    Save
                </SubmitButton>
                <Spacer />
                <DiscardButton v4 hideByDefault>
                    Discard
                </DiscardButton>
            </Flex>
        </>
    )
}

export function Divider({ dense = false, noMargin = false, style = {} }) {
    return (
        <div
            style={{
                margin: noMargin ? undefined : dense ? '6px 0px' : '12px 0px',
                height: 1,
                background: V4DesignSystem.colors.gray[100],
                alignSelf: 'stretch',
                flexShrink: 0,
                ...style,
            }}
        />
    )
}

export function SettingRow({
    leftSide,
    rightSide,
    leftSideStyle = null,
    rightSideStyle = null,
    containerStyle = null,
}) {
    return (
        <Flex style={{ alignItems: 'center', ...containerStyle }}>
            <Flex column style={{ flexBasis: '40%', flexShrink: 0, ...leftSideStyle }}>
                {leftSide}
            </Flex>
            <Flex column style={{ flexBasis: '60%', minWidth: 0, ...rightSideStyle }}>
                {rightSide}
            </Flex>
        </Flex>
    )
}

export function CollapsableSection({ header, children, defaultIsOpen, Toggle, collapseProps }) {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: defaultIsOpen })

    const ToggleComponent =
        // can be custom
        Toggle ??
        // but defaults to a simple grey up/down caret icon
        (({ isOpen }) => {
            return (
                <Icon icon={isOpen ? 'caretUp' : 'caretDown'} size="sm" color="gray.300" mr={3} />
            )
        })

    return (
        <>
            <Flex
                style={{ alignItems: 'center', wrap: 'nowrap', cursor: 'pointer' }}
                role="button"
                onClick={onToggle}
            >
                <div style={{ flexGrow: 1 }}>{header}</div>

                <ToggleComponent isOpen={isOpen} />
            </Flex>
            <Collapse {...collapseProps} isOpen={isOpen}>
                {children}
            </Collapse>
        </>
    )
}
export function SettingLabel({ children, marginRight = '12px' }) {
    return (
        <div
            style={{
                ...V4DesignSystem.settingLabel,
                marginRight: marginRight,
                marginTop: '8px',
                marginBottom: '8px',
            }}
        >
            {children}
        </div>
    )
}

/**
 * Input for changing the base_url attribute where the subdomain is editable
 * and domain is fixed and shown on the right.
 * We only accept alphanumerical characters for subdomain.
 */
export const BaseUrlInput = forwardRef(({ domain, ...props }, ref) => {
    const [showHint, setShowHint] = React.useState(false)
    const [text, setText] = React.useState('')
    const ACCEPTED = /^[a-zA-Z0-9]+$/

    const isInputAccepted = (text) => {
        return ACCEPTED.test(text)
    }

    return (
        <>
            <Flex wrap="nowrap" style={{ alignItems: 'center' }}>
                <Input
                    onKeyDown={(e) => {
                        setShowHint(false)
                        if (!isInputAccepted(e.key)) {
                            setShowHint(true)
                            setText(e.key)
                            e.preventDefault()
                            return false
                        }
                    }}
                    onPaste={(e) => {
                        const pasted = e.clipboardData.getData('Text')
                        if (isEmpty(pasted)) return
                        if (!isInputAccepted(pasted)) {
                            setShowHint(true)
                            setText(pasted)
                            e.preventDefault()
                            return false
                        }
                    }}
                    ref={ref}
                    {...props}
                />
                <Text ml={1}>.{domain}</Text>
            </Flex>
            {showHint && (
                <Text size="xs" mt={1}>
                    Only letters and numbers are allowed, &quot;{text}&quot; is invalid
                </Text>
            )}
        </>
    )
})

/**
 *
 * @param {ThemeConfig} navTheme
 */
export function wsNavThemeToV3(navTheme) {
    return {
        ...navTheme,
        navBorderColor: 'transparent',
        navFadeColor: 'pink', // not consumed
        textColorBright: navTheme.textBright,
        textColor: navTheme.text,
        hoverColor: navTheme.highlightBg,
        navColor: navTheme.bgNav,
        secondNavColor: navTheme.bgScdNav,
    }
}
