import React from 'react'

import { useTheme } from '@chakra-ui/react'

import { ThemeConfig } from 'v2/ui/theme/styles/types'

type WithThemeProps = {
    theme?: ThemeConfig
}

export function withTheme<P extends object>(
    Component: React.ComponentType<P>
): React.ExoticComponent<P & WithThemeProps> {
    const DecoratedComponent = React.forwardRef<HTMLElement, P & WithThemeProps>((props, ref) => {
        const theme = useTheme()

        const mergedTheme = { ...theme, ...props.theme }

        return <Component {...props} ref={ref} theme={mergedTheme} />
    })

    DecoratedComponent.displayName = `withTheme(${Component.displayName ?? Component.name})`

    return DecoratedComponent
}
