// @ts-strict-ignore
import React, { useContext, useMemo } from 'react'

import moment from 'moment'

import { useAppContext } from 'app/AppContext'
import { useRoleListOptions } from 'data/hooks/roleHelpers'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import useSendWelcomeEmailModal from 'features/AppSettings/CustomerAccess/useWelcomeEmailModal'

import { ActionList, Checkbox } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import UserList from '../shared/UserList'
import UserListItem, { UserListItemProps } from '../shared/UserListItem'
import { useSharingLinkModal } from '../SharingLinkModal'

const colors = stackerTheme().colors
type Props = {
    userList: UserListDto
}
type ListContextProps = {
    userList?: UserListDto
}
const ListContext = React.createContext<ListContextProps>({})
const AutomaticUserTableUsers: React.FC<Props> = ({ userList }) => {
    const { data: users = [], isLoading } = useAppUsersForAdmin(true)
    const filteredUsers = useMemo(
        () => users.filter((user) => user.user_list === userList._sid),
        [users, userList._sid]
    )

    // the list context allows us to supply the group and user components (below)
    // with relevant information
    const listContext = useMemo(
        () => ({
            userList,
        }),
        [userList]
    )

    if (isLoading) return null
    return (
        <ListContext.Provider value={listContext}>
            <UserList showHeader users={filteredUsers} hideGroups UserComponent={UserComponent} />
        </ListContext.Provider>
    )
}

const UserComponent: React.FC<UserListItemProps> = (props) => {
    const { user } = props
    const { selectedStack } = useAppContext()
    const showRoles = selectedStack?.options?.roles__enabled
    const { data: options } = useRoleListOptions()
    const role = options?.find((role) => role.value === props.user.role)
    const listContext = useContext(ListContext)
    const accessDisabled =
        selectedStack?.user_access_disabled || listContext.userList?.user_access_disabled
    const isNUMa =
        selectedStack?.options?.sync_users_as_auths &&
        selectedStack?.combined_optional_features?.sync_stacker_users

    // Show special NUMa only settings: sharing link & welcome email
    const showSpecialOptions = !accessDisabled && isNUMa

    const registered = user?.membership_options?.registered
        ? moment(user?.membership_options?.registered).format('L')
        : null

    const { show: showSendWelcomeEmail } = useSendWelcomeEmailModal(user, isNUMa)
    const { show: showSharingLinkModal } = useSharingLinkModal(user)

    // These options only show for NUMa apps migrated from v3
    const actions = [
        // @ts-expect-error
        { label: 'Send login email', action: () => showSendWelcomeEmail(), disabled: !user.email },
    ]

    if (!selectedStack?.options?.disable_sharing_links) {
        // @ts-expect-error
        actions.push({
            label: 'Sharing link...',
            action: () => showSharingLinkModal(user),
        })
    }

    return (
        <UserListItem
            {...props}
            limitWidth={isNUMa || showRoles}
            rowContainerStyle={{
                background: accessDisabled ? colors.neutral[100] : undefined,
            }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                    {isNUMa && (
                        <div style={{ width: '150px' }}>
                            <Checkbox isChecked={!!registered} isReadOnly />
                        </div>
                    )}
                    {showRoles && (
                        <div style={{ width: '150px', color: colors.neutral[800] }}>
                            {role?.label}
                        </div>
                    )}
                </>
            }
            rowActions={
                showSpecialOptions && (
                    <ActionList
                        variant="iconButton"
                        buttonSize="smDense"
                        icon="dotsH"
                        color="neutral.800"
                        actions={actions}
                    ></ActionList>
                )
            }
        />
    )
}

export default AutomaticUserTableUsers
