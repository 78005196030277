/* Code Quality: Not audited */

import React from 'react'

import PropTypes from 'prop-types'

import WithApp from 'data/wrappers/WithApp'
import WithFeatures from 'data/wrappers/WithFeatures'
import WithObject from 'data/wrappers/WithObject'
import WithRecord from 'data/wrappers/WithRecord'

import { getLinkComponentFromUrl } from './link_utils'

const cancelDoubleSlash = (url) => url.replace('//', '/')

const RecordLinkDisplay = ({
    recordId,
    record,
    noLink,
    linkText = '',
    disableRealTimeUpdates = false,
    layout,
}) => {
    return (
        <RecordLinkWrapper
            record={record}
            recordId={recordId}
            loading="-"
            acceptPartial
            disableRealTimeUpdates={disableRealTimeUpdates}
        >
            {({ record, primaryField }) => {
                // If there was an error on the fetch, we don't try to load the object
                if (record._fetch_failed || !record._object_id) return '-'

                if (record._object_id === 'page') {
                    // If it's a page, we need to get the app's url to link directly to it
                    return (
                        <WithApp appId={record.app_id}>
                            {({ app }) => {
                                return getLinkComponentFromUrl({
                                    display: record[primaryField.api_name],
                                    url: cancelDoubleSlash(app.url + record.url),
                                    noLink,
                                    layout,
                                })
                            }}
                        </WithApp>
                    )
                }
                return (
                    <WithFeatures>
                        {({ features }) => (
                            <WithObject objectId={record._object_id}>
                                {({ object }) => {
                                    let url
                                    if (object._sid === 'app') {
                                        // Go to the relevant studio page
                                        url = `/apps/${record._sid}`
                                    } else if (object._sid === 'object') {
                                        // Go to the relevant studio page
                                        url = `/table/${record._sid}`
                                    } else if (object._sid === 'data_connection') {
                                        // Go to the relevant studio page
                                        url = `/data/connection/${record._sid}`
                                    } else {
                                        // It's a record, so by convention its detail page will be
                                        // whatever app we're in /object.api_name/{id}

                                        const feature = features.find(
                                            (f) => f._sid === object.feature_id
                                        )
                                        const featureUrl = feature ? feature.url : '/'
                                        url = `${featureUrl}/view/${record._sid}`
                                    }
                                    return getLinkComponentFromUrl({
                                        display: linkText || record._primary,
                                        url,
                                        layout,
                                    })
                                }}
                            </WithObject>
                        )}
                    </WithFeatures>
                )
            }}
        </RecordLinkWrapper>
    )
}

// If we already have a record sent through as a prop then we don't need to use withRecord
const RecordLinkWrapper = ({ record, children, ...props }) => {
    if (record) {
        return <>{children({ record, primaryField: record?._primary })}</>
    }
    return <WithRecord {...props}>{children}</WithRecord>
}

RecordLinkWrapper.propTypes = {
    record: PropTypes.object,
    children: PropTypes.node.isRequired,
}

RecordLinkDisplay.propTypes = {
    recordId: PropTypes.string.isRequired,
    noLink: PropTypes.bool,
}

RecordLinkDisplay.defaultProps = {
    noLink: false,
}

export default RecordLinkDisplay
