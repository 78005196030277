import { useContext, useEffect, useMemo, useState } from 'react'
import { StreamContext } from 'react-activity-feed'

import { acceptHeaders, buildUrl, fetchAndReturn } from 'data/utils/utils'

const FEED_API = 'getstream-feed/'
const FOLLOWING_API = 'getstream-following/'

export const useFeedClient = (feedId) => {
    const useStreamClient = true
    const [encryptedFeedId, setEncryptedFeedId] = useState()
    const [isReady, setIsReady] = useState(!useStreamClient)
    const { client, token } = useContext(StreamContext)
    const userId = client.user.id

    useEffect(() => {
        // If we're set to use the local stream client, then we need to fetch the
        // encrypted feed id from the server.
        if (useStreamClient) {
            getEncryptedFeedId(feedId).then((result) => {
                setEncryptedFeedId(result)
                setIsReady(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedId])

    const addActivity = (activity) => {
        const queryDict = { record_id: feedId, token: token }
        return fetchAndReturn(buildUrl(FEED_API, queryDict), {
            method: 'POST',
            headers: acceptHeaders,
            body: JSON.stringify(activity),
        })
    }

    const removeActivity = (activityId) => {
        const queryDict = { record_id: feedId, activity_id: activityId }
        return fetchAndReturn(buildUrl(FEED_API, queryDict), {
            method: 'DELETE',
        })
    }

    const getIsFollowingRecord = () => {
        if (useStreamClient) {
            if (!isReady) return Promise.reject()

            const notificationFeed = client.feed('notification', userId)
            return notificationFeed
                .following({ filter: ['record:' + encryptedFeedId] })
                .then((result) => result.results && result.results.length > 0)
        }

        const queryDict = { record_id: feedId, user_id: userId }
        return fetchAndReturn(buildUrl(FOLLOWING_API, queryDict), {})
    }
    const setIsFollowingRecord = (follow) => {
        if (useStreamClient) {
            if (!isReady) return Promise.reject()
            const notificationFeed = client.feed('notification', userId)

            if (!follow) {
                notificationFeed.unfollow('record', encryptedFeedId)
            } else {
                notificationFeed.follow('record', encryptedFeedId, { limit: 0 })
            }

            return
        }

        const queryDict = {
            record_id: feedId,
            user_id: userId,
            follow: follow ? 1 : 0,
        }
        return fetchAndReturn(buildUrl(FOLLOWING_API, queryDict), { method: 'PUT' })
    }
    const getActivity = (options) => {
        if (useStreamClient) {
            if (!isReady) return Promise.reject()
            const feed = client.feed('record', encryptedFeedId)
            return feed.get({
                ...options,
                enrich: true,
                withOwnReactions: true,
                withReactionCounts: true,
            })
        }

        const queryDict = { record_id: feedId, ...options }
        return fetchAndReturn(buildUrl(FEED_API, queryDict), {})
    }

    const result = useMemo(() => {
        return {
            isReady,
            encryptedFeedId,
            getActivity,
            setIsFollowingRecord,
            getIsFollowingRecord,
            addActivity,
            removeActivity,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, encryptedFeedId, client])

    return result
}

function getEncryptedFeedId(recordId) {
    const queryDict = { record_id: recordId, feed_id_only: 1 }
    return fetchAndReturn(buildUrl(FEED_API, queryDict), {})
}

export default useFeedClient
