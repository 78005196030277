export const searchObject = (searchTerm: string, data: any[], propertiesToSearch: string[]) => {
    if (data && searchTerm) {
        const searchValue = searchTerm.toLowerCase().trim()
        return data.filter((item) =>
            propertiesToSearch.some((property) => {
                let value = item[property]
                if (!isNaN(value)) {
                    value = value.toString()
                }
                return typeof value === 'string' && value.toLowerCase().includes(searchValue)
            })
        )
    }

    return data
}
