import React, { memo, useCallback, useContext, useMemo, useState } from 'react'

import keyBy from 'lodash/keyBy'
import { generateId } from 'v2/blocks/utils'

import useLDFlags from 'data/hooks/useLDFlags'

import { Box, OrderableListSelector } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { SolidAddPlus, SolidStar } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'
import { AnimateRelocateContext } from 'v2/ui/utils/AnimateRelocate'

import Button from '../Button'
import { Item } from '../OrderableListSelector/types'
import Text from '../Text'

import ConditionalVisibilityModal from './ConditionalVisibilityModal'
import { fieldToItem } from './utils'
const { colors } = stackerTheme()

export const SECTION = 'section'
const provideKey = (item: Item) => item.fieldId || item.id || item._sid || ''
const defaultPaletteItems = {
    [SECTION]: { id: '1', label: undefined, type: SECTION },
}

type Props = {
    object: ObjectDto
    fields: FieldDto[]
    selectedItems: any[]
    onUpdate: (items: any[]) => void
    onEdit?: (item: Item, event: Event) => void
    disallowSections?: boolean
    maxItemsSelected?: number
    autoHideEditButton?: boolean
    maxHeight?: string
    allFieldsModeEnabled?: boolean
    allowEditSections?: boolean
    hideTopSection?: boolean
    disableReorder?: boolean
    nonDraggableSectionIndexes?: number[]
    showConditionalVisibility?: boolean
    conditionalVisibilityFilters?: { [fieldSid: string]: Filter[] }
    onConditionalVisibilityFiltersChange?: (filtersMap: { [fieldSid: string]: Filter[] }) => void
}

const FieldListEditor: React.FC<Props> = ({
    onUpdate,
    selectedItems,
    object,
    fields,
    onEdit,
    disallowSections,
    maxItemsSelected,
    conditionalVisibilityFilters = {},
    onConditionalVisibilityFiltersChange,
    showConditionalVisibility = false,
    maxHeight = undefined,
    autoHideEditButton = true,
    allFieldsModeEnabled,
    allowEditSections = true,
    hideTopSection = false,
    disableReorder = false,
    nonDraggableSectionIndexes = [],
}) => {
    const { flags } = useLDFlags()
    const [editingVisibilityFieldId, setEditingVisibilityFieldId] = useState<string | undefined>()
    const fieldsById = useMemo(() => keyBy(fields, (field) => field._sid), [fields])

    const nonDraggableSections = useMemo(
        () =>
            selectedItems.filter(
                (i, index) => i.type === SECTION && nonDraggableSectionIndexes.includes(index)
            ),
        [selectedItems, nonDraggableSectionIndexes]
    )

    const generateVisibilityAction = useCallback(
        (fieldId: string) => ({
            icon: 'eye',
            onClick: () => setEditingVisibilityFieldId(fieldId),
            color: conditionalVisibilityFilters[fieldId]?.length > 0 ? 'adminBrand' : 'gray.300',
            title: 'Edit conditional visibility',
        }),
        [conditionalVisibilityFilters]
    )

    const shouldShowVisibilityAction = useCallback(
        (type: string) =>
            type !== SECTION && flags.fieldConditionalVisibility && showConditionalVisibility,
        [flags.fieldConditionalVisibility, showConditionalVisibility]
    )

    const preparedToRenderSelectedItems = useMemo(() => {
        return (
            selectedItems
                // only include fields that still exist in the field list
                .filter(
                    (x) =>
                        (!x.fieldId || fieldsById[x.fieldId]) &&
                        (!hideTopSection || x.type !== SECTION)
                )
                .map((item) => ({
                    ...item,
                    isDisabled: allFieldsModeEnabled || item.isDisabled,
                    ...(shouldShowVisibilityAction(item.type)
                        ? { actions: [generateVisibilityAction(item.fieldId)] }
                        : {}),
                    props: {
                        cannotBeDragged: !!nonDraggableSections.find((s) => s.id === item.id),
                        hideBorder: item.type === SECTION,
                        fontWeight: item.type === SECTION ? 'bold' : null,
                        allowEdit: item.type !== SECTION || allowEditSections,
                        tooltip: allFieldsModeEnabled
                            ? "Select the mode 'Choose fields to display' to customise visibility"
                            : null,
                    },
                }))
        )
    }, [
        selectedItems,
        fieldsById,
        hideTopSection,
        allFieldsModeEnabled,
        allowEditSections,
        nonDraggableSections,
        generateVisibilityAction,
        shouldShowVisibilityAction,
    ])

    // We're getting a base list of all the fields and pulling
    // out just their _sid and label. This base list is then used
    // when creating our filtered list of "available items"
    const fieldsBase = useMemo(
        () =>
            fields.map((x) => ({
                id: x._sid,
                fieldId: x._sid,
                label: x.label,
            })),
        [fields]
    )

    // Provides the display label: either the specified label
    // or the default if none specified
    const provideLabel = useCallback(
        (item) => {
            if (item.label) return item.label
            if (item.fieldId) {
                return fieldsById[item.fieldId].label
            } else if (item.type === SECTION) {
                return 'Section'
            }
            return ''
        },
        [fieldsById]
    )

    const provideIcon = useCallback(
        (item: Item) => {
            if (nonDraggableSections.find((s) => s.id === item.id)) {
                return (
                    <Box mr={1}>
                        <SolidStar size={16} color={colors.userInterface.accent[1200]} />
                    </Box>
                )
            }
            return null
        },
        [nonDraggableSections]
    )

    const handleAdd = useCallback(
        (item) => {
            const field = fieldsById[item.fieldId]
            if (field) {
                item = fieldToItem(field)
            } else {
                item = { ...item, id: generateId(preparedToRenderSelectedItems) }
            }

            onUpdate([...preparedToRenderSelectedItems, item])
        },
        [fieldsById, onUpdate, preparedToRenderSelectedItems]
    )

    const renderPaletteItems = useCallback(
        (onAdd) => {
            if (allFieldsModeEnabled) {
                return null
            }

            return Object.values(defaultPaletteItems)
                .filter((x) => !(disallowSections && x.type === SECTION))
                .map((x, index) => (
                    <PaletteItem item={x} key={index} onAdd={onAdd} provideLabel={provideLabel} />
                ))
        },
        [provideLabel, disallowSections, allFieldsModeEnabled]
    )

    const onConditionalVisibilityUpdate = useCallback(
        (filters: Filter[]) => {
            editingVisibilityFieldId &&
                onConditionalVisibilityFiltersChange?.({
                    ...conditionalVisibilityFilters,
                    [editingVisibilityFieldId]: filters,
                })
            setEditingVisibilityFieldId(undefined)
        },
        [
            setEditingVisibilityFieldId,
            editingVisibilityFieldId,
            onConditionalVisibilityFiltersChange,
            conditionalVisibilityFilters,
        ]
    )
    return (
        <>
            <OrderableListSelector
                onUpdate={onUpdate}
                selectedItems={preparedToRenderSelectedItems}
                items={fieldsBase}
                onEdit={onEdit}
                onAdd={handleAdd}
                disableReorder={disableReorder || allFieldsModeEnabled}
                maxItemsSelected={maxItemsSelected}
                provideKey={provideKey}
                provideLabel={provideLabel}
                provideIcon={provideIcon}
                renderPaletteItems={renderPaletteItems}
                autoHideEditButton={autoHideEditButton}
                className={ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_CONTAINER}
                nonDraggableIndexes={nonDraggableSectionIndexes}
                editor="fields"
                maxHeight={maxHeight}
                showActionsOnDisabled={showConditionalVisibility}
            />
            {editingVisibilityFieldId && (
                <ConditionalVisibilityModal
                    object={object}
                    filters={conditionalVisibilityFilters[editingVisibilityFieldId]}
                    onUpdate={onConditionalVisibilityUpdate}
                    onClose={() => setEditingVisibilityFieldId(undefined)}
                />
            )}
        </>
    )
}

type PaletteItemProps = {
    item: Item
    onAdd: (item: Item) => void
    provideLabel: (item: Item) => string
}

const PaletteItem = memo(({ item, onAdd, provideLabel }: PaletteItemProps) => {
    const animator = useContext(AnimateRelocateContext)
    const domNodeLoaded = (node: any) => animator.registerNode(item.id, node)
    return (
        <Box ref={domNodeLoaded} onClick={() => onAdd(item)}>
            <Button p={2} variant="Tertiary" color={colors.userInterface.accent[1000]} size="small">
                <SolidAddPlus />
                <Text
                    color={colors.userInterface.accent[1000]}
                    fontSize="12px"
                    ml={2}
                >{`Add ${provideLabel(item)}`}</Text>
            </Button>
        </Box>
    )
})

export default FieldListEditor
