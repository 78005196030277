import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type TemplatesProps = {
    backgroundColor?: string
    size?: number
}
const Templates: React.FC<TemplatesProps> = ({
    backgroundColor = colors.userInterface.accent[200],
    size = 39,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 38) / 39}
            viewBox="0 0 39 38"
            fill="none"
            style={{
                minWidth: size,
                minHeight: size,
            }}
        >
            <circle cx="19.334" cy="19" r="19" fill={backgroundColor} />
            <rect x="6.034" y="9.5" width="15.2" height="15.2" rx="2.375" fill="#B5BEEF" />
            <g opacity=".4">
                <path
                    d="M13.16 16.863a.712.712 0 0 1 .712-.713h4.275a.713.713 0 0 1 .712.713v4.275a.712.712 0 0 1-.712.712h-4.275a.712.712 0 0 1-.713-.712v-4.275z"
                    fill="#fff"
                />
                <rect
                    opacity=".3"
                    x="8.409"
                    y="12.825"
                    width="10.925"
                    height="1.425"
                    rx=".713"
                    fill="#465DD8"
                />
                <rect
                    opacity=".3"
                    x="8.409"
                    y="15.2"
                    width="10.925"
                    height="1.425"
                    rx=".713"
                    fill="#465DD8"
                />
                <rect
                    opacity=".3"
                    x="8.409"
                    y="17.575"
                    width="10.925"
                    height="1.425"
                    rx=".713"
                    fill="#465DD8"
                />
                <rect
                    opacity=".3"
                    x="8.409"
                    y="19.95"
                    width="10.925"
                    height="1.425"
                    rx=".713"
                    fill="#465DD8"
                />
            </g>
            <rect x="17.434" y="11.4" width="15.2" height="15.2" rx="2.375" fill="#7E8EE4" />
            <g opacity=".4" fill="#465DD8">
                <path d="M25.983 20.425a.475.475 0 0 1 .475-.475h2.85a.475.475 0 0 1 .475.475v2.85a.475.475 0 0 1-.475.475h-2.85a.475.475 0 0 1-.475-.475v-2.85zM25.983 14.725a.475.475 0 0 1 .475-.475h2.85a.475.475 0 0 1 .475.475v2.85a.475.475 0 0 1-.475.475h-2.85a.475.475 0 0 1-.475-.475v-2.85z" />
            </g>
            <rect x="11.734" y="13.3" width="15.2" height="15.2" rx="2.375" fill="#465DD8" />
            <path
                d="M13.635 16.862a.67.67 0 0 1 .238-.503.877.877 0 0 1 .576-.21h9.772c.215 0 .423.076.575.21a.67.67 0 0 1 .239.503v1.425a.67.67 0 0 1-.239.504.877.877 0 0 1-.575.209h-9.772a.877.877 0 0 1-.576-.209.67.67 0 0 1-.238-.504v-1.425z"
                fill="#7E8EE4"
            />
            <path
                d="M18.385 20.662c0-.189.087-.37.243-.504a.907.907 0 0 1 .588-.208h4.987c.221 0 .432.075.588.208a.665.665 0 0 1 .244.504v4.275c0 .19-.088.37-.244.504a.907.907 0 0 1-.588.209h-4.987a.907.907 0 0 1-.588-.209.665.665 0 0 1-.243-.504v-4.275z"
                fill="#F0F3FF"
            />
            <path
                d="M14.585 19.95a1.13 1.13 0 0 0-.672.208c-.178.134-.278.315-.278.504v4.275c0 .19.1.37.278.504.178.134.42.209.672.209h1.9c.252 0 .493-.075.672-.209.178-.134.278-.315.278-.504v-4.275c0-.189-.1-.37-.278-.504a1.13 1.13 0 0 0-.672-.208h-1.9z"
                fill="#B5BEEF"
            />
        </svg>
    )
}

export default Templates
