import React, { forwardRef, useImperativeHandle, useState } from 'react'

import styled from '@emotion/styled'

import SyncingTimesLabel from 'features/AppSettings/DataSources/SyncingTimesLabel'
import SyncSchemaButtons from 'features/AppSettings/DataSources/SyncSchemaButtons'
import ObjectLabel from 'features/utils/ObjectLabel'

import { Flex, Text } from 'v2/ui'
import { Layout, UserLock } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { SearchBox } from '../../../v2/ui/components/SearchBox'
import { isUsingAirtableRealtimeUpdates } from '../data-connector/isUsingAirtableRealtimeUpdates'

import { useToolbarActions } from './Toolbar/useToolbarActions'
import { ExportDataToolbarButton } from './ExportDataToolbarButton'
import { ToolbarButton } from './ToolbarButton'

const colors = stackerTheme().colors

export type ObjectDataGridToolbarProps = {
    object: ObjectDto
    /**
     * When true, we hide the current object label + icon
     */
    hideLabel?: boolean
    /**
     * If set, only show schema and data sync buttons along with syncing time labels (i.e. hide all advanced buttons)
     */
    showOnlySyncButtons?: boolean
    dataConnection: DataConnectionDto
    search?: string
    onSearch: (search?: string) => void
    showDataSourceInfo?: boolean
    onModalToggled: (isOpened: boolean) => void
}

export type ObjectDataGridToolbarHandle = {
    showFields: () => void
    showSettings: () => void
}

export const ObjectDataGridToolbar: React.VFC<
    React.RefAttributes<any> & ObjectDataGridToolbarProps
> = forwardRef<ObjectDataGridToolbarHandle, ObjectDataGridToolbarProps>(
    (
        {
            object,
            hideLabel,
            showOnlySyncButtons,
            showDataSourceInfo,
            dataConnection,
            search,
            onSearch,
            onModalToggled,
        },
        ref
    ) => {
        const {
            toolbarModals,
            showSettings,
            showFields,
            showPermissions,
            showActions,
            showLayouts,
        } = useToolbarActions({ object, onModalToggled })

        useImperativeHandle(ref, () => ({ showSettings, showFields }))

        // SEARCH
        const [showSearch, setShowSearch] = useState<boolean>(false)

        const showNeedToSyncMessage = object?.connection_options?.fill_needed_for_field_changes
        const isStackerTable = dataConnection?.type === 'native_tables'

        const shouldShowSyncSchemaButtons = !isStackerTable
        const shouldShowSyncSchemaText =
            shouldShowSyncSchemaButtons && !isUsingAirtableRealtimeUpdates(dataConnection)

        if (isStackerTable && hideLabel && showOnlySyncButtons) {
            // in this case, nothing would be rendered - this early return prevents us rendering an empty div with padding
            return null
        }
        return (
            <ResponsiveWrapper
                wrap="nowrap"
                p={1}
                pr={2}
                shouldShowSyncSchemaButtons={shouldShowSyncSchemaButtons}
            >
                {!hideLabel && (
                    <ObjectLabel objectId={object?._sid} flexGrow={1} fontWeight="bold" ml={2} />
                )}
                {!shouldShowSyncSchemaButtons && !showOnlySyncButtons && (
                    <SearchBox
                        placeholder={`Find something in ${object.name} `}
                        search={search}
                        onSearch={onSearch}
                        ml={1}
                        mr={!shouldShowSyncSchemaButtons ? 'auto' : 'unset'}
                        showSearch={showSearch}
                        setShowSearch={setShowSearch}
                        dataTestIdPrefix="data-grid"
                    />
                )}
                {showNeedToSyncMessage && (
                    <Flex
                        wrap="nowrap"
                        px={2}
                        py={1}
                        mx={1}
                        bg={colors.userInterface.warning[200]}
                        align="center"
                        borderRadius="5px"
                    >
                        <Text color={colors.userInterface.warning[1000]} fontSize="14px">
                            You have field changes which require a data sync.
                        </Text>
                        <SyncSchemaButtons
                            showSchemaSync={false}
                            dataConnection={dataConnection}
                            buttonSize="extraSmall"
                            display="inline-flex"
                        />
                    </Flex>
                )}
                {shouldShowSyncSchemaButtons && !showNeedToSyncMessage && (
                    <Flex justifyContent="space-between" flexWrap="nowrap" mr="auto">
                        {!showOnlySyncButtons && (
                            <SearchBox
                                placeholder={`Find something in ${object.name} `}
                                search={search}
                                onSearch={onSearch}
                                ml={1}
                                mr={!shouldShowSyncSchemaButtons ? 'auto' : 'unset'}
                                showSearch={showSearch}
                                setShowSearch={setShowSearch}
                                dataTestIdPrefix="data-grid"
                            />
                        )}

                        {shouldShowSyncSchemaText && (
                            <SyncingTimesLabel
                                opacity={showSearch ? 0 : 1}
                                display={showSearch ? 'none' : 'block'}
                                textStyle={{
                                    whiteSpace: 'nowrap',
                                    display: 'inline',
                                    fontSize: '12px',
                                    color: colors.userInterface.neutral[800],
                                }}
                                dataConnection={dataConnection}
                                showDataSourceInfo={showDataSourceInfo}
                            />
                        )}

                        <Flex mr={4}>
                            <SyncSchemaButtons
                                dataConnection={dataConnection}
                                buttonSize="extraSmall"
                            />
                        </Flex>
                    </Flex>
                )}
                {!showOnlySyncButtons && (
                    <>
                        <Flex alignItems="center" wrap="nowrap" id="datagrid_buttonbar">
                            <ToolbarButton
                                icon="table"
                                onClick={showFields}
                                data-testid="datagrid.fields.button"
                            >
                                Fields
                            </ToolbarButton>
                            <ToolbarButton
                                icon="cog"
                                data-testid="datagrid.settings.button"
                                onClick={showSettings}
                            >
                                Settings
                            </ToolbarButton>
                            <ToolbarButton
                                svgIcon={<Layout />}
                                data-testid="datagrid.layouts.button"
                                onClick={showLayouts}
                            >
                                Layouts
                            </ToolbarButton>
                            <ToolbarButton
                                icon="bolt"
                                data-testid="datagrid.actions.button"
                                onClick={showActions}
                            >
                                Actions
                            </ToolbarButton>
                            <ToolbarButton
                                svgIcon={<UserLock />}
                                onClick={showPermissions}
                                data-testid="datagrid.permissions.button"
                            >
                                Permissions
                            </ToolbarButton>

                            <ExportDataToolbarButton objectId={object?._sid} />
                        </Flex>
                        {toolbarModals}
                    </>
                )}
            </ResponsiveWrapper>
        )
    }
)

const ResponsiveWrapper = styled(Flex)<{ shouldShowSyncSchemaButtons: boolean }>`
    justify-content: ${(props) =>
        !props.shouldShowSyncSchemaButtons ? 'flex-start' : 'space-between'};
    flex-direction: row;
    height: 44px;

    @media (max-width: 1200px) {
        flex-direction: ${(props) =>
            !props.shouldShowSyncSchemaButtons ? 'row' : 'column-reverse'};
        align-items: flex-start;
        height: unset;
    }
`
