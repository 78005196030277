import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { searchObject } from 'features/utils/searchObject'

import stackerTheme from 'v2/ui/theme/styles/default'

import { Template } from '../../data/hooks/templates'
import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { BackgroundImage, FixedGrid, Flex, Text } from '../../v2/ui'

import TemplateIcon from './TemplateIcon'

type TemplateLibraryProps = {
    templates: Template[]
    searchTerm: string
    onTemplateClick: (template: Template) => void
    emptySearch?: JSX.Element
}
const colors = stackerTheme().colors

const TemplateLibrary = (props: TemplateLibraryProps) => {
    const { templates, onTemplateClick, searchTerm = '', emptySearch } = props

    const filteredTemplates = useMemo(
        () => searchObject(searchTerm, templates, ['name', 'card_description']),
        [searchTerm, templates]
    )

    if (emptySearch && searchTerm && filteredTemplates.length === 0) {
        return emptySearch
    }

    return (
        <>
            {/* @ts-ignore */}
            <FixedGrid className="fixed-grid" columns={[1, 1, 3, 3]} spacing="12px" p={4} pb="80px">
                {filteredTemplates.map((template) => (
                    <TemplateCard
                        key={template.auto_id}
                        title={template.name}
                        description={template.card_description}
                        icon={template.icon}
                        iconColor={template.icon_color}
                        image={template.card_image}
                        isPublished={template.is_published}
                        onClick={() => {
                            onTemplateClick(template)
                        }}
                    />
                ))}
            </FixedGrid>
        </>
    )
}

type TemplateCardProps = {
    title: string
    description?: string
    icon?: string
    iconColor?: string
    image?: string
    isPublished: boolean
    onClick: () => void
}

function TemplateCard({
    title,
    description,
    icon,
    iconColor,
    image = '',
    isPublished,
    onClick,
}: TemplateCardProps) {
    return (
        <>
            {/* @ts-ignore */}
            <div onClick={onClick} style={{ position: 'relative', cursor: 'pointer' }}>
                <Flex column m={0} h="100%">
                    <BackgroundImage
                        src={image}
                        borderTopLeftRadius="lg"
                        borderTopRightRadius="lg"
                        w="100%"
                        h={[
                            'templateLibrary.coverImage',
                            null,
                            null,
                            'templateLibrary.coverImageLg',
                        ]}
                    />
                    {!isPublished && <UnpublishedLabel>Under development</UnpublishedLabel>}
                    <TemplateCardInfo>
                        <TemplateIcon
                            icon={icon || 'faClouds'}
                            color={iconColor ?? V4DesignSystem.colors.stacker}
                            fullSize
                            style={{ position: 'absolute', top: '-34px', left: '10px' }}
                        />
                        <Text variant="templateCardTitle" mb={2} mt={1}>
                            {title}
                        </Text>
                        <Text variant="templateCardDescription">{description}</Text>
                    </TemplateCardInfo>
                </Flex>
            </div>
        </>
    )
}

const TemplateCardInfo = styled.div`
    padding: 12px;
    border: 1px solid ${colors.neutral[500]};
    border-top: none;
    border-radius: 0 0 8px 8px;
    flex-grow: 1;
    width: 100%;
    position: relative;
`

const UnpublishedLabel = styled.div`
    background: #d00000aa;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 2px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    color: white;
    line-height: 16px;
    font-family: 'Inter UI';
    letter-spacing: 0.6px;
    border-radius: 0.375rem 0.375rem 0 0;
    width: 100%;
    text-align: center;
`

export default TemplateLibrary
