import { useCallback, useMemo } from 'react'

import { getCurrentStackId } from 'app/AppContextStore'
import { fetchWithAuth } from 'data/utils/utils'

import { invalidateRecords } from './records/recordOperations'
import { updateRecordQuery } from './records/updateRecordQuery'
import { queryClient, useCreateItem, useDeleteItem, useQuery, useUpdateItem } from './_helpers'
import { useFields } from './fields'
/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useActions'
const ENDPOINT = 'actions/'

export const StepTypes = {
    UpdateRecord: 'updateRecord',
    ZapierTrigger: 'zapierTrigger',
}

export function isStepVisible(step) {
    switch (step.type) {
        case StepTypes.UpdateRecord:
            return Boolean(step.fields?.find((field) => field.promptUser))
        case StepTypes.ZapierTrigger:
            return false
        default:
            return false
    }
}

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useActions(options = {}) {
    const stackId = getCurrentStackId()
    return useQuery([LIST_NAME, stackId], ENDPOINT, options)
}

export function useCreateAction() {
    const stackId = getCurrentStackId()
    return useCreateItem([LIST_NAME, stackId], ENDPOINT)
}
export function useUpdateAction() {
    const stackId = getCurrentStackId()
    return useUpdateItem([LIST_NAME, stackId], ENDPOINT)
}
export function useDeleteAction() {
    const stackId = getCurrentStackId()
    return useDeleteItem([LIST_NAME, stackId], ENDPOINT)
}

export function invalidateActions() {
    const stackId = getCurrentStackId()
    return queryClient.invalidateQueries([LIST_NAME, stackId])
}

export function useExecuteAction(objectId) {
    const { data: fields } = useFields({ objectId })
    const linksToOtherObjects = useMemo(
        () =>
            (fields ?? []).filter(
                (field) =>
                    field.object_id === objectId &&
                    (field.type === 'lookup' || field.type === 'multi_lookup')
            ),
        [fields, objectId]
    )

    const execute = useCallback(
        (data) => {
            return fetchWithAuth('execute/', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.status >= 400) {
                        return Promise.reject(response)
                    }

                    return response.json()
                })
                .then((data) => {
                    // update redux cache with an update records
                    if (data.records) {
                        updateRecordQuery(data.records, true)
                        const objectIdsToInvalidate = [
                            ...linksToOtherObjects
                                .map((field) => field.options?.lookup_target)
                                .filter((target) => !!target)
                                .reduce((set, target) => set.add(target), new Set()),
                        ]
                        for (const idToInvalidate of objectIdsToInvalidate) {
                            invalidateRecords(idToInvalidate)
                        }
                    }
                    return data
                })
        },
        [linksToOtherObjects]
    )

    return execute
}
