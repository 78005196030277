const getFieldPerms = (recordPermissions, objectPermissions) => {
    let allowedFieldNamesToRead = []
    let allowedFieldNamesToUpdate = []
    let allowedFieldNamesToCreate = []
    if (recordPermissions) {
        allowedFieldNamesToRead = recordPermissions.may_read_fields || []
        allowedFieldNamesToUpdate = recordPermissions.may_update_fields || []
    }
    // For reading and updating records, we already know the exact permissions
    // based on the existing record.When creating, the permissions must be based
    // on what we can infer at the object level, as we don't know ahead of time
    // what the record will look like.
    if (objectPermissions) {
        allowedFieldNamesToCreate = [
            ...(objectPermissions.may_create_fields || []),
            ...(objectPermissions.maybe_may_create_fields || []),
        ]
    }

    return [allowedFieldNamesToRead, allowedFieldNamesToUpdate, allowedFieldNamesToCreate]
}

export default getFieldPerms
