import React from 'react'
import { Filters } from 'react-table'

import EndUserFilters from 'v2/views/List/EndUserFilters'
import InlineFilters from 'v2/views/List/InlineFilters'
import { CustomListRendererColumn } from 'v2/views/List/types'

import useLDFlags from 'data/hooks/useLDFlags'

import { Box, Button, Flex } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

type DataListBlockFiltersProps = React.ComponentPropsWithoutRef<typeof Box> & {
    tableColumns?: CustomListRendererColumn[]
    setAllFilters?: (filters: Filters<RecordDto>) => void
    filters?: Filter[]
    inlineFilters?: Filter[]
    setInlineFilters?: (filters: Filter[]) => void
    userFilterFieldIds?: string[]
    object?: ObjectDto
}

export const DataListBlockFilters: React.FC<DataListBlockFiltersProps> = ({
    tableColumns,
    setAllFilters,
    filters,
    userFilterFieldIds,
    inlineFilters,
    setInlineFilters,
    object,
    ...props
}) => {
    const { flags } = useLDFlags()
    const filtersCount = filters ? Object.values(filters).length : 0

    return (
        <>
            {!flags?.newInlineFilters && (
                <EndUserFilters
                    clearFilters={() => setAllFilters?.([])}
                    filtersCount={filtersCount}
                    userFilterFieldIds={userFilterFieldIds}
                    columns={tableColumns}
                    m={0}
                    {...props}
                />
            )}
            {/* there a lot of checks here - mostly for type guarding, as all the pops for this component are optional */}
            {flags?.newInlineFilters &&
                inlineFilters &&
                object &&
                userFilterFieldIds &&
                setInlineFilters && (
                    <Flex
                        mt={4}
                        className={STYLE_CLASSES.INLINE_FILTERS}
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="flex-end"
                    >
                        <InlineFilters
                            filters={inlineFilters}
                            object={object}
                            userFilterFieldIds={userFilterFieldIds}
                            onChange={setInlineFilters}
                            columns={tableColumns}
                        />
                        {inlineFilters.length > 0 && (
                            <Box>
                                <Button
                                    fontSize={['button.sm', null, null, 'button.smLg']}
                                    variant="clear"
                                    m={0}
                                    p={2}
                                    pr={0}
                                    onClick={() => setInlineFilters?.([])}
                                    _hover={{
                                        color: 'userInterface.neutral.900',
                                    }}
                                >
                                    Clear all filters
                                </Button>
                            </Box>
                        )}
                    </Flex>
                )}
        </>
    )
}
