/* Code Quality: Not audited */

import React from 'react'

import deepcopy from 'deepcopy'

import { Icon, Input, Text } from 'v2/ui'

export default class KeyValueList extends React.Component {
    // Pass it a list of objects, and it will display and allow them to be changed or added

    defaultField(val, onChange) {
        return <Input value={val} onChange={(event) => onChange(event.target.value)} />
    }

    render() {
        let items = (this.props.value || []).map((item, index) => {
            let key = item[this.props.keyName]
            let value = item[this.props.valueName]

            let renderKeyField = this.props.keyRender || this.defaultField
            let keyField = renderKeyField(key, (val) => this.setNthKeyTo.bind(this)(index, val))

            let renderValueField = this.props.valueRender || this.defaultField
            let valueField = renderValueField(value, (val) =>
                this.setNthValueTo.bind(this)(index, val)
            )

            return (
                <div key={index} className="list-row">
                    {this.props.keyLabel && <label>{this.props.keyLabel}</label>}
                    {this.props.keyWrapper ? this.props.keyWrapper(keyField) : keyField}
                    {this.props.valueLabel && <label>{this.props.valueLabel}</label>}
                    {valueField}
                    <Icon icon="times" onClick={() => this.removeNthItem.bind(this)(index)} />
                </div>
            )
        })

        return (
            <div className="key-value-list interactive">
                {items}
                <Text>
                    <a onClick={this.addNewItem.bind(this)}>Add</a>
                </Text>
            </div>
        )
    }

    addNewItem() {
        let newItems = deepcopy(this.props.value || [])
        newItems.push({ [this.props.keyName]: '', [this.props.valueName]: '' })
        this.props.onChange(newItems)
    }

    setNthKeyTo(index, key) {
        let newItems = deepcopy(this.props.value)
        newItems[index][this.props.keyName] = key
        this.props.onChange(newItems)
    }

    setNthValueTo(index, value) {
        let newItems = deepcopy(this.props.value)
        newItems[index][this.props.valueName] = value
        this.props.onChange(newItems)
    }

    removeNthItem(n) {
        let newItems = deepcopy(this.props.value)
        remove(newItems, n)
        this.props.onChange(newItems)
    }
}

function remove(array, index) {
    array.splice(index, 1)
}
