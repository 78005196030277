import React, { useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { getUrl, Urls } from 'app/UrlService'
import { isImpersonatingEndUser } from 'data/utils/isAdmin'
import {
    AbsoluteLogo,
    HEADER_HEIGHT,
    HEADER_PADDING_X,
    HeaderBar,
    Logo,
} from 'features/core/nav/navUtils'
import { DefaultStackNameLogo, GetLogo } from 'features/utils/getLogo'
import { SidebarHeader, UserMenuButton } from 'features/workspace/Sidebar'

import { Box, Flex, Heading, Tooltip } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDimension from 'v2/ui/utils/useDimension'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import useMutationObserver from '../../../v2/ui/useMutationObserver'

import { TopNavigation } from './SecondaryNavigation'
import Tray from './Tray'
import UserMenu from './UserMenu'

type DesktopHeaderBarProps = {
    navigation: any
    views: ViewDto[]
    pages: PageDto[]
    features: FeatureDto[]
    shouldHideAppBarForGuest: boolean
    isStudioUser: boolean
    showDefaultStackLogo: boolean
    stack: StackDto
    stacks: StackDto[]
    workspaceAccount: Account
    theme: StackDto['theme']
    navTheme: { [key: string]: string }
    options: StackDto['options']
    logoUrl: string
    context: ViewLayoutContext
    studioUser: UserDto
    outerHeaderRef: React.RefObject<HTMLDivElement> | null
    children?: React.ReactNode
}

const DesktopHeaderBar: React.FC<DesktopHeaderBarProps> = ({
    navigation,
    views,
    features,
    shouldHideAppBarForGuest,
    isStudioUser,
    showDefaultStackLogo,
    stack,
    stacks,
    workspaceAccount,
    theme,
    navTheme,
    options,
    logoUrl,
    context,
    studioUser,
    outerHeaderRef,
}) => {
    const [headerBarRef, setHeaderBarRef] = useRefCallback()
    return (
        <HeaderBar ref={setHeaderBarRef} {...navTheme} className={STYLE_CLASSES.HEADER}>
            <HeaderLeftSide
                navTheme={navTheme}
                options={options}
                workspaceAccount={workspaceAccount}
                isStudioUser={isStudioUser}
                showDefaultStackLogo={showDefaultStackLogo}
                stack={stack}
                theme={theme}
                logoUrl={logoUrl}
                stacks={stacks}
            />
            {/* @ts-expect-error */}
            <TopNavWrapper headerRef={headerBarRef} outerHeaderRef={outerHeaderRef}>
                <TopNavigation
                    navigation={navigation}
                    theme={theme}
                    views={views}
                    features={features}
                    desktop
                    isWorkspace={stack.options.workspace_app}
                />
            </TopNavWrapper>

            <SideButtonsWrapper
                marginLeft={['10px']}
                flexWrap="nowrap"
                flexShrink="0"
                justifyContent="flex-end"
            >
                <Tray
                    workspaceAccount={workspaceAccount}
                    isSecondaryNavigation
                    navTheme={navTheme}
                    context={context}
                    shouldHideAppBarForGuest={shouldHideAppBarForGuest}
                />
                {shouldHideAppBarForGuest && (
                    <UserMenuButton
                        navTheme={navTheme}
                        studioUser={studioUser}
                        mobile={false}
                        iconOnly
                    />
                )}
                {!workspaceAccount && <UserMenu theme={theme} />}
            </SideButtonsWrapper>
        </HeaderBar>
    )
}

type HeaderLeftSideProps = {
    workspaceAccount: Account
    isStudioUser: boolean
    showDefaultStackLogo: boolean
    stack: StackDto
    stacks: StackDto[]
    theme: { [key: string]: string }
    navTheme: { [key: string]: string }
    options: StackDto['options']
    logoUrl: string
}

const HeaderLeftSide: React.FC<HeaderLeftSideProps> = ({
    workspaceAccount,
    isStudioUser,
    showDefaultStackLogo,
    stack,
    theme,
    navTheme,
    options,
    logoUrl,
}) => {
    const impersonatingOldStyleEndUser =
        isImpersonatingEndUser() && !workspaceAccount?.optional_features?.sync_stacker_users
    const showWorkspaceLogo = workspaceAccount && isStudioUser && !impersonatingOldStyleEndUser

    let content
    if (showWorkspaceLogo) {
        content = (
            <>
                <SidebarHeader
                    stack={stack}
                    hideAppBarForGuest={false}
                    isTopNav={true}
                    isV3App={false}
                    isMobile={false}
                    navTheme={navTheme}
                />

                {!options?.navbar_hide_app_name && (
                    <>
                        <div
                            style={{
                                borderLeft: `1px solid ${navTheme.textColor}`,
                                opacity: 0.2,
                                marginRight: 17,
                                height: 32,
                                marginLeft: 12,
                            }}
                        />
                        <Tooltip
                            label={stack?.name}
                            position="bottom"
                            wrapperStyle={{ minWidth: '0' }}
                        >
                            <Heading
                                value={stack?.name}
                                variant={'appName'}
                                color={navTheme.textColorBright}
                            />
                        </Tooltip>
                    </>
                )}
            </>
        )
    } else {
        content = (
            <>
                {showDefaultStackLogo ? (
                    <Link to={getUrl(Urls.Home)}>
                        <DefaultStackNameLogo
                            name={stack?.name}
                            navColor={theme.navColor}
                            brandColor={theme.brandColor}
                            wrapText={false}
                        />
                    </Link>
                ) : (
                    <AbsoluteLogo className={STYLE_CLASSES.LOGO}>
                        <Flex as={Link} to={getUrl(Urls.Home)}>
                            <GetLogo url={logoUrl}>{(logo: string) => <Logo src={logo} />}</GetLogo>
                        </Flex>
                    </AbsoluteLogo>
                )}
            </>
        )
    }
    return (
        <Flex
            flexWrap="nowrap"
            flexShrink="0"
            justifyContent="flex-start"
            overflow="hidden"
            marginRight={['10px']}
            maxWidth={[120, 120, 160, 220, 280]}
        >
            {content}
        </Flex>
    )
}

type TopNavWrapperProps = {
    outerHeaderRef: React.RefObject<HTMLElement>
    headerRef: React.RefObject<HTMLDivElement>
    children: React.ReactNode
}

const TopNavWrapper: React.FC<TopNavWrapperProps> = ({
    outerHeaderRef,
    headerRef,
    children,
    ...props
}) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const navRef = useRef<HTMLDivElement | null>(null)

    const wrapperWidth = useRef<number>(0)
    const navWidth = useRef<number>(0)

    const setSize = useCallback((headerWidth) => {
        wrapperWidth.current = wrapperRef.current?.getBoundingClientRect().width ?? 0
        navWidth.current = navRef.current?.getBoundingClientRect().width ?? 0

        // Make sure that the navigation is always displayed centered in the top bar.
        const navOffsetLeft = (wrapperRef.current?.offsetLeft ?? 0) - HEADER_PADDING_X
        let offsetLeft = Math.max(
            Math.floor((headerWidth - navWidth.current) / 2 - navOffsetLeft),
            0
        )
        if (offsetLeft + navWidth.current > wrapperWidth.current) {
            offsetLeft = 0
        }

        if (navRef.current) {
            navRef.current.style.left = `${offsetLeft}px`
        }
    }, [])

    const { width } = useDimension(headerRef, setSize)
    useMutationObserver(outerHeaderRef, () => {
        setSize(width)
    })

    return (
        <Flex
            flexGrow="1"
            flexShrink="1"
            flexWrap="nowrap"
            height={`${HEADER_HEIGHT}px`}
            position="relative"
            {...props}
            ref={wrapperRef}
        >
            <Box ref={navRef} position="absolute" maxWidth="100%" top={0}>
                {children}
            </Box>
        </Flex>
    )
}

const SideButtonsWrapper = styled(Flex)`
    &:empty {
        display: none;
    }
`

export default DesktopHeaderBar
