/* Code Quality: Not audited */

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { appActions, getAppsMemoized } from '../api/appApi'

class WithApps extends React.Component {
    UNSAFE_componentWillMount() {
        const { history } = this.props
        if (!history) return
        this.unsubscribeFromHistory = history.listen(() => {
            window.Appcues && window.Appcues.page()
        })
    }

    render() {
        const appsData = this.props.apps
        if (!appsData) return this.props.loading ? this.props.loading : ''
        const apps = Object.values(appsData)
        return this.props.children({
            apps,
            onChange: this.props.appActions.update,
            selectApp: this.selectApp,
        })
    }

    constructor(props) {
        super(props)
        if (!props.apps || !props.apps.length) props.appActions.fetch()
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.dontUpdate === true) {
            if (nextProps.apps.length !== this.props.apps.length) {
                return true
            }
            return false
        }
        return true
    }
}

WithApps.propTypes = {}

function mapStateToProps(state) {
    return {
        apps: getAppsMemoized(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WithApps))
