import React, { VFC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type Props = {
    color?: string
    size?: number
}

const SolidArrowDropdownDown: VFC<Props> = ({
    size = 16,
    color = colors.userInterface.accent[1000],
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 11.484a1.077 1.077 0 0 1-.491-.123 1.166 1.166 0 0 1-.395-.338L4.307 7.349a1.622 1.622 0 0 1-.173-1.592c.09-.223.24-.413.427-.547.188-.134.408-.207.633-.21h5.612c.225.003.445.076.633.21s.336.324.427.546a1.621 1.621 0 0 1-.173 1.593l-2.807 3.674a1.166 1.166 0 0 1-.395.338c-.153.08-.32.122-.491.123z"
            fill={color}
        />
    </svg>
)

export default SolidArrowDropdownDown
