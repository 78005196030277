// @ts-strict-ignore
import React, { useEffect, useRef, VFC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { Switch } from '@chakra-ui/react'
import { isEmpty, uniq } from 'lodash'

import { SERVER_PAGE_SIZE } from 'app/settings'
import { useRecords } from 'data/hooks/records'

import { Flex, Text } from 'v2/ui'

import { FormField } from '../../../../ui/forms/FormField'
import { ConfigurationComponentProps } from '../common'
import { fieldBaseTypeMap } from '../definitions/fieldTypeDefinitions'

import DropdownFieldOptionsEditor from './DropdownFieldOptionsEditor'

const autoPopulateBaseTypes = ['string', 'number', 'string_array']

const DropdownFieldConfiguration: VFC<ConfigurationComponentProps> = ({
    object,
    field,
    canChangeFieldType,
}) => {
    const { setValue, watch } = useFormContext()
    const options = useWatch({ name: 'options.options' })
    const hasAutoPopulated = useRef(!isEmpty(options))
    const filters = [
        {
            field: { api_name: field?.api_name },
            options: { option: 'isNotEmpty' },
        },
    ]

    const canAutoPopulate =
        !!field &&
        fieldBaseTypeMap[field.type] &&
        autoPopulateBaseTypes.includes(fieldBaseTypeMap[field.type] as string)
    // Set up query to fetch the records for this table that have values
    // in this field. Only enable this query if the user hasn't yet set
    // dropdown field options for this field.
    const { data: queryData } = useRecords({
        objectId: object?._sid,
        filters,
        fetchOptions: { includeFields: [field?.api_name], pageSize: SERVER_PAGE_SIZE },
        options: { enabled: canAutoPopulate },
    })
    const records = queryData?.records

    useEffect(() => {
        // if we have records, no specified options, and we haven't yet done the
        // auto-population operation...
        if (records && isEmpty(options) && !hasAutoPopulated.current) {
            // ...get the unique list of all the values for this field.
            const values = uniq(records.map((record) => record[field?.api_name])).sort() as string[]
            // filter out long values or values with new lines in them
            const newOptions = values
                .filter((item) => item && item.length < 50 && !item.includes('\n'))
                .map((item) => ({ label: item, value: item }))

            // if we have 20 or less, then go ahead and suggest those. If we have
            // more than 20 unique values, then perhaps this field has way too many unique values
            // and is't actually a good candidate for a dropdown field. The user may just be
            // exploring
            if (values.length < 20) {
                setValue('options.options', newOptions)
                hasAutoPopulated.current = true
            }
        }
    }, [field?.api_name, options, records, setValue])

    return (
        <>
            <Flex mb={2}>
                <Text
                    as="label"
                    mr={3}
                    color="neutral.1000"
                    htmlFor="dropdown-color-enable"
                    size="sm"
                >
                    Enable colored options
                </Text>
                <FormField
                    name="options.allow_dropdown_colors"
                    id="dropdown-color-enable"
                    as={Switch}
                    color="adminSwitch"
                    width="100%"
                    // This is to prevent the switch from always animating
                    // to enabled, whenever the parent popover is opened.
                    controlled={true}
                    isChecked={watch('options.allow_dropdown_colors')}
                    controlledDefaultValue={true}
                />
            </Flex>
            <FormField
                name="options.options"
                as={DropdownFieldOptionsEditor}
                errorMessages={{ required: 'Please add at least one option' }}
                disabled={!canChangeFieldType}
                controlled
                required
            />
        </>
    )
}

export default DropdownFieldConfiguration
