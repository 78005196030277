import { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { useDataConnections } from 'data/hooks/dataConnections'
import { useObjects } from 'data/hooks/objects'

import { getIsStackerUserObject } from './stackerUserUtils'

/**
 * Lists data sources available in the data grid
 * @returns List of available data sources
 */
export const useAvailableDataSources = () => {
    const { data: objects } = useObjects()
    const { data: sources } = useDataConnections()
    const { selectedStack } = useAppContext()

    const shouldHideStackerUsers = objects?.find(
        (ob) =>
            getIsStackerUserObject(ob) &&
            ob.connection_options?.is_hidden &&
            !selectedStack?.options?.always_show_stacker_user_table
    )
    return useMemo(
        () =>
            sources.filter((s: DataConnectionDto) => {
                return shouldHideStackerUsers && s.type === 'stacker_users' ? null : s
            }),
        [sources, shouldHideStackerUsers]
    )
}
