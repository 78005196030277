import React from 'react'

import { useAppContext } from 'app/AppContext'
import useConfirmModal from 'app/ConfirmModal'
import { PasswordApi, SignupApi } from 'features/auth/utils'

import { Text } from 'v2/ui'

const UNKNOWN_ERROR = 'An unknown error occurred. Please try again or contact support.'
const useSendWelcomeEmailModal = (user, NUMa = false) => {
    const { selectedStack } = useAppContext()

    const emailType = NUMa ? 'login' : 'welcome'

    const sendEmail = () => {
        const { email } = user
        const emailError = (response) => {
            if (response && response.json) {
                response
                    .json()
                    .then((jsonError) => {
                        const errorCode = jsonError?.error_code
                        const error =
                            errorCode == 201
                                ? 'There are multiple users with the same email.'
                                : UNKNOWN_ERROR

                        setData({ ...getModalData(error), isSaving: false })
                    })
                    .catch(() => {
                        setData({ ...getModalData(UNKNOWN_ERROR), isSaving: false })
                    })
            } else {
                setData({ ...getModalData(UNKNOWN_ERROR), isSaving: false })
            }
        }

        setData({ ...getModalData(), isSaving: true })

        if (NUMa) {
            PasswordApi.requestEndUserLoginEmail(email, selectedStack._sid)
                .then((response) => {
                    if (response.status >= 400) emailError(response)
                    else {
                        toggle()
                    }
                    return
                })
                .catch(emailError)
        } else if (user.membership_options?.registered) {
            PasswordApi.requestEndUserPasswordReset(email, selectedStack._sid)
                .then((response) => {
                    if (response.status >= 400) emailError(response)
                    else {
                        toggle()
                    }
                    return
                })
                .catch(emailError)
        } else {
            //new user
            const password = Math.random().toString(36).slice(2)

            SignupApi.registerEndUser(email, password, null, null, selectedStack._sid)
                .then((response) => {
                    const token = response.api_token
                    if (!token) {
                        emailError()
                        return
                    } else {
                        toggle()
                        return
                    }
                })
                .catch(emailError)
        }
    }

    const getModalData = (error) => ({
        icon: 'email',
        onConfirm: sendEmail,
        message: (
            <>
                Are you sure you want to send a {emailType} email to <strong>{user.name}</strong> at{' '}
                <strong>{user.email}</strong>?
                {error && (
                    <Text variant="error" mt={4}>
                        {error}
                    </Text>
                )}
            </>
        ),
    })

    const { show, toggle, setData } = useConfirmModal(getModalData())

    return { show }
}
export default useSendWelcomeEmailModal
