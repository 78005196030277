/* Code Quality: Good */

import React from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const getInitials = (name) => {
    if (!name) return null
    // splits words to array
    let nameArray
    try {
        nameArray = name.split(' ')
    } catch {
        nameArray = [' ']
    }
    let initials = ''
    // if it's a single word, return 1st and 2nd character
    if (nameArray.length === 1) {
        return `${nameArray[0].charAt(0)}${nameArray[0].charAt(1)}`.toUpperCase()
    }
    initials = nameArray[0].charAt(0)

    // else it's more than one, concat the initials in a loop
    // we've gotten the first word, get the initial of the last word
    // first word
    for (let i = nameArray.length - 1; i < nameArray.length; i++) {
        initials += nameArray[i].charAt(0)
    }
    // return capitalized initials
    return initials.toUpperCase()
}

const UserAvatar = ({ name, navTheme }) => {
    const initials = getInitials(name)
    let style = {
        backgroundColor: navTheme?.bgScdNav || navTheme?.button?.background || '#F4F5F8',
        color: navTheme?.textColorBright || navTheme?.button?.color || '#ffffff',
    }
    return <Avatar style={style}>{initials}</Avatar>
}

export default UserAvatar

UserAvatar.propTypes = {
    name: PropTypes.string,
}

UserAvatar.defaultProps = {
    name: '',
}

const Avatar = styled('div')`
    cursor: pointer;
    height: 32px;
    width: 32px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 122%;
    letter-spacing: -0.025em;
    color: white;

    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-contents: center;
    border-radius: 100%;
    flex-direction: column;

    margin-right: 10px;

    @media (max-width: 768px) {
        height: 24px;
        width: 24px;
        font-size: 12px;
    }
`
