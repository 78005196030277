import React from 'react'

import styled from '@emotion/styled'

import Box from './Box'

type SlideProps = React.ComponentPropsWithoutRef<typeof Box> & {
    hideOn?: 'left' | 'right' | 'top' | 'bottom'
}

/** Handles sliding the content out of view in the specified direction
 * NOTE: by default this container is absolutely positioned, so the wrapping
 * container must provide width and height.
 * Also wrapping container should hide overflow.
 */
const Slide: React.FC<SlideProps> = ({ hideOn, children, ...props }) => {
    return (
        <SlideBox hideOn={hideOn} {...props}>
            {children}
        </SlideBox>
    )
}

const getTransform = (hideOn: SlideProps['hideOn']) => {
    switch (hideOn) {
        case 'left':
            return 'translate3d(calc(-100% - 12px),0,0)'
        case 'top':
            return 'translate3d(0,-100%,0)'
        case 'right':
            return 'translate3d(calc(100% + 12px),0,0)'
        case 'bottom':
            return 'translate3d(0,-100%,0)'
        default:
            return 'unset'
    }
}

const SlideBox = styled(Box)<{ hideOn: SlideProps['hideOn'] }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.5s ease;
    transform: ${(props) => getTransform(props.hideOn)};
`

export default Slide
