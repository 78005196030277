import React, { useEffect } from 'react'

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tr,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { RotateExclamation } from 'v2/ui/svgs'

import { useDataConnectionDebugInfo } from '../../../../../data/hooks/dataConnections'
import { Badge, Banner, Flex, Text } from '../../../../../v2/ui'

import { PostgresConnectionEditorProps } from './postgresConnectorTypes'

const StyledAccordion = styled(Accordion)`
    padding: 0;
    margin: 0;
    border-color: transparent;
`
const StyledAccordionButton = styled(AccordionButton)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
    margin-bottom: 0;

    border-top: 1px solid ${(props) => props.theme.colors.userInterface.neutral[500]};
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
`
const StyledAccordionPanel = styled(AccordionPanel)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    padding: 0;
    width: 100%;
`

export const PostgresReviewSync: React.FC<PostgresConnectionEditorProps> = ({ context }) => {
    const { dataConnection, onClose, setNextHandler, setNextButtonText } = context
    const { data: DCDebugInfo, isLoading: isDCDebugInfoLoading } = useDataConnectionDebugInfo(
        dataConnection?._sid
    )

    useEffect(() => {
        setNextHandler(onClose)
        setNextButtonText('Close')
    }, [setNextHandler, onClose, setNextButtonText])

    const skippedTables =
        DCDebugInfo?.filter((debugInfo) =>
            [
                'POSTGRES_TABLE_IGNORED_EXPLICITLY',
                'INVALID_POSTGRES_TABLE_SKIPPED',
                'UNSUPPORTED_PRIMARY_KEY_TYPE',
            ].includes(debugInfo.connection_info_type)
        ) ?? []

    const skippedViews =
        DCDebugInfo?.filter((debugInfo) =>
            ['POSTGRES_VIEW_IGNORED_EXPLICITLY', 'INVALID_POSTGRES_VIEW_SKIPPED'].includes(
                debugInfo.connection_info_type
            )
        ) ?? []

    const skippedColumns =
        DCDebugInfo?.filter(
            (debugInfo) =>
                debugInfo.connection_info_type == 'IGNORING_UNSUPPORTED_POSTGRES_COLUMN_TYPE'
        ) ?? []

    // get all unique table names from both skipped tables/view and skipped columns
    // this is necessary as the table for a skipped column, is probably not fully skipped itself
    const allTableNames = new Set([
        ...skippedTables.map((table) => table.metadata?.table_name ?? 'Unknown table name'),
        ...skippedViews.map((view) => view.metadata?.view_name ?? 'Unknown table name'),
        ...skippedColumns.map(
            (column) => column.metadata?.relation_name ?? 'Unknown relation name'
        ),
    ])

    const skippedTablesAndViewsIncColumns = Array.from(allTableNames).map((tableOrViewName) => {
        const skippedColumnsForTable = skippedColumns.filter(
            (col) => col.metadata.relation_name === tableOrViewName
        )

        return {
            name: tableOrViewName,
            skippedColumns: skippedColumnsForTable ?? [],
        }
    })

    useEffect(() => {
        // close the modal automatically if the debug info has loaded and there's nothing to show
        if (!isDCDebugInfoLoading && skippedTablesAndViewsIncColumns.length == 0) {
            onClose()
        }
    }, [isDCDebugInfoLoading, skippedTablesAndViewsIncColumns, onClose])

    // review sync is only valid if the data connection is already created (and synced)
    if (!dataConnection) return null

    return (
        <>
            <StyledAccordion allowToggle>
                <Text textAlign="center" padding={2} marginBottom={5}>
                    Certain items from this database could not be imported, and have been skipped.
                    For more details, refer to the list of skipped items below.
                </Text>
                {(!DCDebugInfo || isDCDebugInfoLoading) && (
                    <Flex justifyContent="center" padding={4}>
                        <Spinner />
                    </Flex>
                )}
                {skippedTablesAndViewsIncColumns.map((skippedTable) => (
                    <AccordionItem key={skippedTable.name}>
                        <StyledAccordionButton role="button" as={Flex}>
                            <Flex alignItems="center" gap={3}>
                                <RotateExclamation />
                                <Text variant="paletteSectionLabel">{skippedTable.name}</Text>
                            </Flex>
                            <div>
                                <Badge
                                    color={
                                        skippedTable.skippedColumns.length == 0 ? 'gray' : 'yellow'
                                    }
                                >
                                    {skippedTable.skippedColumns.length == 0 ? (
                                        <>not synced</>
                                    ) : (
                                        <>
                                            {skippedTable.skippedColumns.length} field
                                            {skippedTable.skippedColumns.length > 1 ? 's' : ''}{' '}
                                            skipped
                                        </>
                                    )}
                                </Badge>
                                <AccordionIcon />
                            </div>
                        </StyledAccordionButton>

                        <StyledAccordionPanel>
                            {/* If the skippedColumns is empty, show a default error message */}
                            {skippedTable.skippedColumns.length == 0 ? (
                                <Banner icon="info" m={2}>
                                    <Text fontWeight="normal">This table could not be synced</Text>
                                </Banner>
                            ) : (
                                <TableContainer style={{ padding: 4 }}>
                                    <Table variant="striped" size="sm">
                                        <Tbody>
                                            {skippedTable.skippedColumns.map((skippedColumn) => (
                                                <Tr key={skippedColumn.metadata.column_name}>
                                                    <Td style={{ lineHeight: 1 }}>
                                                        {skippedColumn.metadata.column_name ??
                                                            'Unknown column name'}
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            )}
                        </StyledAccordionPanel>
                    </AccordionItem>
                ))}
            </StyledAccordion>
        </>
    )
}
