import React, { ChangeEvent, VFC } from 'react'

import { InputProps } from '@chakra-ui/react'

import { Collapse, Input, Text } from 'v2/ui'

const InputGroup: VFC<
    InputProps & {
        errors: any
        register: any
        hideLabel?: boolean
        disabled?: boolean
        onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    }
> = ({ title, placeholder, errors = {}, hideLabel = false, register, ...rest }) => {
    const errorMessage = errors[register.name]?.message
    return (
        <>
            {!hideLabel && (
                <Text size="sm" fontWeight="bold">
                    {title}
                </Text>
            )}
            <Input
                {...rest}
                {...register}
                placeholder={placeholder}
                variant="admin"
                mt={2}
                mb={2}
            />
            <Collapse isOpen={!!errorMessage}>
                <Text variant="error" size="sm" pb={2}>
                    {errorMessage}
                </Text>
            </Collapse>
        </>
    )
}

export default InputGroup
