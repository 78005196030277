// @ts-strict-ignore
import React, { useState } from 'react'

import styled from '@emotion/styled'

import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'
import { Icon, SearchInput, Tooltip } from '../../../v2/ui'
import { Checkbox } from '../../../v2/ui/components/Checkbox'
import { getAreObjectRecordsEditable } from '../../admin/fields/logic/availableFieldOperationUtils'

type FieldPermissionsProps = {
    value: any
    object: ObjectDto
    onChange: (any) => void
}

const PERMISSION_TYPES = ['read', 'update', 'create'] as const

const FieldPermissions = ({ value, onChange, object }: FieldPermissionsProps) => {
    const fieldPermissions = value

    const [filterString, setFilterString] = useState('')
    const [sortColumn, setSortColumn] = useState('')
    const [sortDirection, setSortDirection] = useState('')

    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const enabledFields = sortByFieldsOrder(object.fields).filter(
        (field) => !(field.connection_options && field.connection_options.is_disabled)
    )

    const allFields = fieldPermissions?.['*'] || {}

    const isFieldEditable = (apiName) => {
        return enabledFields.some(
            (objectField) =>
                objectField.api_name === apiName && !objectField.connection_options.read_only
        )
    }

    // The checkboxes in the header row do two things:
    //
    // - Check/uncheck all the individual field checkboxes for that action on change
    // - Set the '*' permission which for that action which, if true, overrides the field checkbox
    //
    // They do *not* control the record_permissions and create_permission fields (see comment in
    // buildPermissionRuleRequestDataFromFormData).
    const updateAllFields = (permission: string) => (event: any) => {
        const fields = JSON.parse(JSON.stringify(fieldPermissions))
        for (const field in fields) {
            // If this is a read permission, or the field is editable, or we are turning off
            // the permissions, then we can update this field to match the object level checkbox
            if (permission === 'read' || isFieldEditable(field) || !event.target.checked) {
                fields[field][permission] = event.target.checked
            }
        }
        fields['*'] = fields['*'] || {}
        fields['*'][permission] = event.target.checked
        if (permission !== 'read' && event.target.checked) {
            for (const field in fields) {
                fields[field]['read'] = true
            }
        }
        onChange(fields)
    }

    // Correspondingly, if you untick one of the field level checkboxes then the header checkbox
    // will be unchecked (though it may be indeterminate, see hasCheckedField)
    const updateSingleField = (field: string, permission: string) => (event) => {
        const fields = JSON.parse(JSON.stringify(fieldPermissions))
        if (!fields[field]) {
            fields[field] = {}
        }
        fields[field][permission] = event.target.checked
        // When enabling update/create, make sure read is also enabled
        if (permission !== 'read' && event.target.checked) {
            fields[field]['read'] = true
        }
        // When disabling a permission for a single field, make sure it is also disabled for '*' (the header)
        // For 'read', disable all the perms on '*'
        // N.B. As above, the record_permissions/create_permission are handled by buildPermissionRuleRequestDataFromFormData and not set here.
        if (!event.target.checked) {
            fields['*'] = fields['*'] || {}
            fields['*'][permission] = false
            if (permission === 'read') {
                fields['*']['update'] = false
                fields['*']['create'] = false
            }
        }
        onChange(fields)
    }

    const cycleFieldSort = (permission) => () => {
        if (sortColumn === permission && sortDirection === 'Down') {
            setSortDirection('Up')
        } else if (sortColumn === permission && sortDirection === 'Up') {
            setSortColumn('')
        } else {
            setSortColumn(permission)
            setSortDirection('Down')
        }
    }

    const filteredFields = enabledFields
        .filter((field) => field.label.toLowerCase().includes(filterString.toLowerCase()))
        .sort((a, b) => {
            if (!sortColumn) {
                return 0
            }
            const aChecked = fieldPermissions?.[a.api_name][sortColumn]
            const bChecked = fieldPermissions?.[b.api_name][sortColumn]
            if (sortDirection && aChecked && !bChecked) {
                return sortDirection === 'Up' ? 1 : -1
            } else if (sortDirection && !aChecked && bChecked) {
                return sortDirection === 'Up' ? -1 : 1
            }
            return 0
        })

    // Header checkboxes should be indeterminate if they are false but there's a tick in that column
    const hasCheckedField = { read: false, update: false, create: false }
    for (const field in fieldPermissions) {
        for (const permission in fieldPermissions[field]) {
            // only count fields as being checked if they are in the list of enabled fields.
            // We don't want to coutn disabled fields or deleted fields which still exist
            // in the rule
            if (
                fieldPermissions[field][permission] &&
                enabledFields.find((f) => f.api_name === field)
            ) {
                hasCheckedField[permission] = true
            }
        }
    }

    const disabledPermissionMap: Record<typeof PERMISSION_TYPES[number], boolean> = {
        read: false,
        update:
            !getAreObjectRecordsEditable(object) ||
            (!allFields['read'] && !hasCheckedField['read']),
        create:
            object.connection_options.read_only || (!allFields['read'] && !hasCheckedField['read']),
    }

    return (
        <FieldPermissionsTable>
            <thead>
                <td style={{ paddingLeft: 0 }}>
                    <SearchInput
                        width="100%"
                        variant="noBorder"
                        background="none"
                        value={filterString}
                        onChange={(val) => setFilterString(val)}
                        placeholder="Search fields..."
                    />
                </td>
                {PERMISSION_TYPES.map((permission) => {
                    const isDisabled = disabledPermissionMap[permission]
                    const checked = !!allFields[permission] && !isDisabled
                    const indeterminate =
                        !allFields[permission] && hasCheckedField[permission] && !isDisabled
                    return (
                        <td key={permission} className="checkbox-column">
                            <Tooltip
                                placement="bottom"
                                label={allFields[permission] ? 'Uncheck all' : 'Check all'}
                                display="inline-block"
                            >
                                <Checkbox
                                    data-testid={`object-permission-checkbox-${permission}`}
                                    size="sm"
                                    isChecked={checked}
                                    isIndeterminate={indeterminate}
                                    isDisabled={isDisabled}
                                    onChange={updateAllFields(permission)}
                                />
                            </Tooltip>
                            {' ' + permission.charAt(0).toUpperCase() + permission.slice(1)}
                            <Tooltip placement="bottom" label="Sort fields" display="inline-block">
                                <Icon
                                    icon={
                                        sortColumn === permission ? `sort${sortDirection}` : 'sort'
                                    }
                                    button
                                    onClick={cycleFieldSort(permission)}
                                    display="inline-block"
                                    p={1}
                                    marginLeft="4px"
                                />
                            </Tooltip>
                        </td>
                    )
                })}
            </thead>
            <tbody>
                {filteredFields.map((field) => (
                    <tr key={field.api_name} data-testid="field-permission-row">
                        <td>{field.label}</td>
                        {PERMISSION_TYPES.map((permission) => {
                            const isDisabled =
                                disabledPermissionMap[permission] ||
                                ((field.connection_options.read_only ||
                                    !fieldPermissions?.[field?.api_name]?.['read']) &&
                                    permission !== 'read')
                            const checked =
                                !!fieldPermissions?.[field?.api_name]?.[permission] && !isDisabled
                            return (
                                <td key={permission} className="checkbox-column">
                                    <Checkbox
                                        data-testid={`field-permission-checkbox-${permission}`}
                                        size="sm"
                                        isChecked={checked}
                                        isDisabled={isDisabled}
                                        onChange={updateSingleField(field?.api_name, permission)}
                                    />
                                </td>
                            )
                        })}
                    </tr>
                ))}
                {filteredFields.length === 0 && filterString && (
                    <tr>
                        <td colSpan={4}>No fields match your search</td>
                    </tr>
                )}
            </tbody>
        </FieldPermissionsTable>
    )
}

const FieldPermissionsTable = styled.table`
    border: 1px solid ${V4DesignSystem.colors.gray[100]};
    width: 100%;
    border-radius: 5px;
    margin-top: 12px;
    font-size: 14px;
    border-spacing: 0;
    overflow: hidden;
    border-collapse: separate;

    thead {
        background-color: ${V4DesignSystem.colors.gray[10]};
        font-size: 14px;
        color: ${V4DesignSystem.colors.gray[600]};
        font-weight: 600;
    }

    td {
        height: 32px;
        padding: 0 8px;
        border-bottom: 1px solid ${V4DesignSystem.colors.gray[100]};

        &.checkbox-column {
            width: 110px;
        }
    }

    tr:last-child td {
        border-bottom: none;
    }
`

export default FieldPermissions
