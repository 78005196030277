import { createSelector } from 'reselect'

import {
    DATA_CONNECTION_CREATED,
    DATA_CONNECTION_REMOVED,
    DATA_CONNECTION_UPDATED,
    DATA_CONNECTIONS_FETCHED,
} from '../utils/constants'
import { StackerActions } from '../utils/stackerActions'
import { acceptHeaders, fetchAndReturn, StackerAPI } from '../utils/utils'

// API
class DataConnectionApi extends StackerAPI {
    path = 'data-connections/'

    bypassPreviewAndImpersonation = true

    authorise = (connectionId) => {
        const path = `${this.path}${connectionId}/authorise/`
        return fetchAndReturn(path, {
            method: 'POST',
            headers: acceptHeaders,
        })
    }

    transformFromLocal = (data) => {
        if (!data) return data
        const keys = Object.keys(data)
        const out = {}
        keys.forEach((key) => {
            if (key.includes('options.')) {
                const keyName = key.split('.')[1]
                out.options = { ...out.options, [keyName]: data[key] }
            } else {
                out[key] = data[key]
            }
        })
        return out
    }
}
export const dataConnectionApi = new DataConnectionApi()

class DataConnectionActions extends StackerActions {
    deferStoreUpdates = true
    api = dataConnectionApi

    reducer = 'dataConnections'

    name = 'dataConnections'

    actionTypes = {
        fetched: DATA_CONNECTIONS_FETCHED,
        removed: DATA_CONNECTION_REMOVED,
        created: DATA_CONNECTION_CREATED,
        updated: DATA_CONNECTION_UPDATED,
    }
}

const DCA = new DataConnectionActions()
DCA.actions = { ...DCA.actions, fetchAcceptedDataConnections: DCA.fetchAcceptedDataConnections }
export const dataConnectionActions = DCA.actions

// SELECTORS
export const getDataConnectionEntities = (state) => state.dataConnections.entities
export const getDataConnectionsList = (state) => state.dataConnections.dataConnections

export const getDataConnectionsMemoized = createSelector(
    getDataConnectionsList,
    getDataConnectionEntities,
    (dataConnections, entities) =>
        dataConnections.map((dataConnectionId) => entities[dataConnectionId])
)
export const getDataConnectionEntitiesMemoized = createSelector(
    getDataConnectionEntities,
    (item) => item
)
// HELPERS
