import React, { useCallback, useMemo, useState } from 'react'

import { Spinner } from '@chakra-ui/react'

import useTrack from 'utils/useTrack'

import SearchInput from 'v2/ui/components/SearchInput'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Template, useTemplates } from '../../data/hooks/templates'
import { Box, FeatureSplashScreen, Flex, ScrollBox } from '../../v2/ui'
import LargeModal from '../AppSettings/LargeModal'

import TemplateDetail from './TemplateDetail'
import TemplateLibrary from './TemplateLibrary'
import { TemplateLibraryModalEmptyState } from './TemplateLibraryModalEmptyState'

function TemplateLibraryEmptyState() {
    return (
        <FeatureSplashScreen
            title="We're still working on these"
            subtitle="We are busy building out a full suite of templates. Why not get in touch and
            let us know what you'd like to see?"
            icon="wrench"
        />
    )
}

function TemplateLibraryLoadingState() {
    return (
        <Flex align="center" justify="center" h="100%">
            <Spinner />
        </Flex>
    )
}

type TemplateLibraryModalProps = {
    accountId: string
    templateId?: string
    onClose?: () => void
    onTemplateSelectionChange: (template: Template | null) => void
    onInstallComplete: (installedStack: StackDto, template: Template | null | undefined) => void
    onSkip?: () => void
    showCloseButton: boolean
}

const TemplateLibraryModal = (props: TemplateLibraryModalProps) => {
    const {
        accountId,
        templateId,
        onClose,
        onTemplateSelectionChange,
        onInstallComplete,
        showCloseButton,
        onSkip,
    } = props
    const [searchTerm, setSearchTerm] = useState('')
    const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(true)
    const { track } = useTrack()

    const { data: allTemplates, isLoading: isTemplatesLoading } = useTemplates()
    const templates: Template[] = useMemo(() => {
        if (!allTemplates) {
            return []
        }

        return (allTemplates as Template[]).filter((template) => !template.options?.type)
    }, [allTemplates])

    const selectedTemplate = useMemo(
        () =>
            templateId
                ? templates.find((t) => t.auto_id.toString() === templateId.toString())
                : null,
        [templateId, templates]
    )
    const onInstallSuccess = (stack: StackDto) => {
        setIsTemplatesModalOpen(false)
        onInstallComplete(stack, selectedTemplate as Template)
    }

    const onDetailClose = () => onTemplateSelectionChange(null)

    const trackSearch = useCallback(
        (value) => {
            if (value) {
                track('WIP - Frontend - Template Gallery - Search', {
                    search_string: value,
                })
            }
        },
        [track]
    )
    const debouncedTrackSearch = useDebounce(trackSearch, 1000, [])
    const handleFilterChange = useCallback(
        (value: string) => {
            setSearchTerm(value)
            debouncedTrackSearch(value)
        },
        [debouncedTrackSearch]
    )

    const clearSearch = useCallback(() => setSearchTerm(''), [])

    return (
        <>
            <LargeModal
                isOpen={isTemplatesModalOpen}
                onClose={onClose}
                size="1000px"
                height="800px"
                showCloseButton={showCloseButton}
                onSkip={onSkip}
                title={<div style={{ padding: '5px' }}>Create an app from a template</div>}
            >
                <Box w="100%">
                    {!selectedTemplate && (
                        <SearchInput
                            containerStyle={{ margin: '1rem' }}
                            placeholder="Search templates"
                            variant="admin-white"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            value={searchTerm}
                            onChange={handleFilterChange}
                            autoFocus
                        />
                    )}
                    <ScrollBox w="100%" h="100%">
                        {isTemplatesLoading && !selectedTemplate && <TemplateLibraryLoadingState />}
                        {!isTemplatesLoading && !selectedTemplate && templates.length === 0 && (
                            <TemplateLibraryEmptyState />
                        )}
                        {!isTemplatesLoading && !selectedTemplate && templates.length > 0 && (
                            <TemplateLibrary
                                templates={templates}
                                onTemplateClick={onTemplateSelectionChange}
                                searchTerm={searchTerm}
                                emptySearch={
                                    <TemplateLibraryModalEmptyState clearSearch={clearSearch} />
                                }
                            />
                        )}
                        {selectedTemplate && (
                            <TemplateDetail
                                accountId={accountId}
                                template={selectedTemplate}
                                onClose={onDetailClose}
                                onInstallComplete={onInstallSuccess}
                            />
                        )}
                    </ScrollBox>
                </Box>
            </LargeModal>
        </>
    )
}

export default TemplateLibraryModal
