/* Code Quality: Not audited */

import React from 'react'

import styled from '@emotion/styled'
import debounce from 'lodash/debounce'

import LazyLoader from 'utils/lazyComponents/LazyLoading'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Input from './Input'

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,
        color: '#ffffff',
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker }, () =>
            document.addEventListener('mousedown', this.handleClickOutside)
        )
    }

    handleClose = () => {
        document.removeEventListener('mousedown', this.handleClickOutside)
        this.props.onChange(this.state.color)
        this.setState({ displayColorPicker: false })
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.handleClose()
        }
    }

    debouncedOnChange = debounce((val) => {
        this.setState({ color: val }, () => this.props.onChange(val))
    }, 600)

    debouncedHandleChange = debounce((color) => this.handleChange(color), 600)

    handleChange = (color) => {
        const newColor = color && color.rgb.a === 1 ? color.hex : colorToRgb(color)
        this.setState({ color: newColor }, () => this.props.onChange(newColor))
    }

    constructor(props) {
        super(props)
        if (props.color) this.state.color = props.color
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.color !== this.props.color) {
            this.setState({ color: props.color })
        }
        if (!props.color) {
            this.setState({ color: null })
        }
    }

    render() {
        const Color = styled('div')`
            height: ${(props) => props.size || '14px'};
            width: ${(props) => props.size || '14px'};
            border-radius: 2px;
            background: ${this.state.color};
        `

        const Swatch = styled('div')({
            padding: '0px',
            background: '#fff',
            borderRadius: '2px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            marginRight: '4px',
            marginBottom: '-3px',
            verticalAlign: 'middle',
            height: (props) => props.size || '14px',
            width: (props) => props.size || '14px',
        })

        const Popover = styled('div')({
            position: 'absolute',
            top: 30,
            left: -45,
            zIndex: 11,
            fontFamily: this.props.theme.fontFamily,
            fontSize: '9px',
            transform: 'translate(-0px, -7px)',
        })

        const Wrapper = styled('span')`
            position: relative;
            & input {
                font-size: 9px !important;
            }

            & input.display {
                font-size 9pt !important;
            }
        `

        // this.state.displayColorPicker
        const InputComponent = this.props.inputComponent || Input
        return (
            <LazyLoader getModule={() => import('react-color')}>
                {({ SketchPicker }) => (
                    <Wrapper className="color-picker interactive">
                        <Swatch onClick={this.handleClick} size={this.props.size}>
                            <Color size={this.props.size} />
                        </Swatch>
                        {this.state.displayColorPicker ? (
                            <>
                                {/* <Cover onClick={this.handleClose} /> */}
                                <Popover innerRef={this.setWrapperRef} className="pop">
                                    <SketchPicker
                                        disableAlpha={true}
                                        color={this.state.color || '#ffffff'}
                                        //onChangeComplete={this.handleChangeComplete}
                                        onChange={this.debouncedHandleChange}
                                    />
                                </Popover>
                            </>
                        ) : null}
                        {!this.props.hideInput && (
                            <InputComponent
                                className="display"
                                style={{ display: 'inline-block' }}
                                defaultValue={this.state.color}
                                onChange={(event) => this.debouncedOnChange(event.target.value)}
                            />
                        )}
                    </Wrapper>
                )}
            </LazyLoader>
        )
    }
}

export default withTheme(ColorPicker)

const colorToRgb = (color) => {
    return `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
}
