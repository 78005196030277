import React from 'react'

import AcceptInviteFlow from 'features/auth/stacker-auth/AcceptInviteFlow'

import AuthPageFrame from './stacker-auth/AuthPageFrame'
const AcceptInvitePage = () => {
    return (
        <AuthPageFrame>
            <AcceptInviteFlow />
        </AuthPageFrame>
    )
}

export default AcceptInvitePage
