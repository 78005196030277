import React from 'react'

import { Box as ChakraBox, BoxProps as ChakraBoxProps, LayoutProps } from '@chakra-ui/react'

type BoxProps = ChakraBoxProps & {
    size?: LayoutProps['boxSize']
    visible?: boolean
}

const Box = React.forwardRef<HTMLDivElement, BoxProps>(({ size, ...props }, ref) => {
    return <ChakraBox ref={ref} boxSize={size} {...props} />
})

export default Box
