import React, { ChangeEvent, useState } from 'react'

import { useAppContext } from 'app/AppContext'

import { Box, Button, Input, Modal, Text } from 'v2/ui'
import { Alert as AlertIcon } from 'v2/ui/svgs'
import { colors } from 'v2/ui/theme/styles/default'

export type ExportModalProps = {
    isOpen: boolean
    allowCustomName: boolean
    onClose: () => void
    title: string
    onClick: (bundleName?: string) => Promise<void>
}

const ExportModal: React.FC<ExportModalProps> = ({
    isOpen,
    allowCustomName,
    onClose,
    title,
    onClick,
}) => {
    const { selectedStack } = useAppContext()
    const [bundleName, setBundleName] = useState(selectedStack?.options?.export_bundle_name)
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            noPadding
            showCloseButton={true}
            body={
                <Box mb="30px">
                    <AlertIcon />
                    <Text variant="modalTitle" mt={6} fontWeight="bold">
                        {title}
                    </Text>
                    <Text mt={4}>
                        Your app export contains sensitive data such as your data connection
                        credentials and needs to be stored securely. This will generate a .json
                        file.
                    </Text>
                    {allowCustomName && (
                        <Input
                            width="100%"
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setBundleName(event.target.value)
                            }
                            value={bundleName}
                            autoFocus
                            rightAdorner={
                                <Text fontSize="14px" px={2}>
                                    .json
                                </Text>
                            }
                            rightAdornerProps={{
                                borderTopRightRadius: '6px',
                                borderBottomRightRadius: '6px',
                                backgroundColor: colors.userInterface.neutral[500],
                                borderColor: 'transparent',
                            }}
                        />
                    )}
                    <Button
                        variant="Primary"
                        buttonSize="extraLarge"
                        mt={4}
                        width="100%"
                        onClick={() => onClick(bundleName)}
                    >
                        Download export file
                    </Button>
                </Box>
            }
        />
    )
}

export default ExportModal
