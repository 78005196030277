import React from 'react'

const BackgroundAlignMiddle: React.FC<React.SVGProps<SVGSVGElement>> = ({
    fill = '#B6BEC5',
    ...props
}) => {
    return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.854 11.278 6.781 9.35l1.927 1.927M6.781 9.67v4.176M4.854 3.57l1.927 1.926L8.708 3.57M6.781 5.175V1M1 7.423h11.562"
                stroke={fill}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default BackgroundAlignMiddle
