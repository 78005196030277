// @ts-strict-ignore
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { BoxProps } from '@chakra-ui/react'

import { AppUserContext } from 'app/AppUserContext'
import useConfirmModal from 'app/ConfirmModal'
import { deleteRecord } from 'data/hooks/records/recordOperations'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import useTrack from 'utils/useTrack'

import { Button, Text, Tooltip } from 'v2/ui'

import { useGoBackUsingBreadcrumbs } from '../utils/useGoBackUsingBreadcrumbs'

type Config = {
    conditions: any
}

type DeleteButtonProps = BoxProps & {
    config: Config
    record: RecordDto
    tooltip: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ config, record, tooltip, ...props }) => {
    const { role } = useContext(AppUserContext)
    const { track } = useTrack()
    const processFilter = useProcessFilter()
    const goBackUsingBreadcrumbs = useGoBackUsingBreadcrumbs()
    // Note: need to re-filter upon role change
    const visible = useMemo(
        () => record && processFilter([record], config.conditions).length > 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record, config.conditions, role, processFilter]
    )

    const history = useHistory()

    const { show: showConfirm } = useConfirmModal({
        endUser: true,
        message: (
            <>
                {record._primary ? (
                    <Text>
                        Are you sure you want to delete the record <b>{record._primary}</b>?
                    </Text>
                ) : (
                    <Text>Are you sure you want to delete this record?</Text>
                )}

                <Text mt={4} fontWeight="bold">
                    This action cannot be undone.
                </Text>
            </>
        ),
        confirmButtonText: 'Delete',
        onConfirm: (modal) => {
            return deleteRecord(record._sid)
                .then(() => {
                    track('WIP - Frontend - Record deleted')
                    // Only redirect if we have to. There's probably a better way to check this...
                    const onDetailPage = history.location.pathname.includes(record._sid)
                    if (onDetailPage) {
                        goBackUsingBreadcrumbs()
                    }
                    modal.toggle()
                })
                .catch(() => {
                    modal.toggle() // What to do if the delete fails?
                })
        },
    })

    if (!visible) return null

    return (
        <Tooltip disabled={!tooltip} label={tooltip}>
            <Button
                variant="Secondary"
                buttonSize="medium"
                icon="trash"
                ml={2}
                onClick={showConfirm}
                className="stk-delete-button"
                {...props}
            >
                Delete
            </Button>
        </Tooltip>
    )
}
export default DeleteButton
