import React from 'react'

import { saveExport } from 'data/api/bundlesApi'

import ExportModal, { ExportModalProps } from './ExportModal'

type ExportFeatureModalProps = Pick<ExportModalProps, 'isOpen' | 'onClose'>

const ExportFeatureModal: React.FC<ExportFeatureModalProps> = ({ isOpen, onClose }) => {
    const onClick = async () => {
        await saveExport(['apps_properties'])
        onClose()
    }
    const title = 'Please confirm your feature export'
    return (
        <ExportModal
            allowCustomName={false}
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            onClick={onClick}
        />
    )
}

export default ExportFeatureModal
