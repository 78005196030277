import { useMutation } from 'react-query'

import { acceptHeaders, fetchAndReturn } from '../utils/utils'

import { useQuery } from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useIntegrations'
const ENDPOINT = 'integrations/'

export type Integration = {
    service_name: string
    name: string
    category: string
    type: 'fivetran' | 'airtable' | 'gsheets' | 'postgresV2'
    is_native_connector: boolean
    imageUrl: string
}

export const useIntegrations = (options = {}) => {
    const query = useQuery(LIST_NAME, ENDPOINT, options, {
        bypassMatchingStackCheck: true,
    })
    const integrations: Integration[] = Array.isArray(query.data) ? query.data : []
    const categories: string[] = Array.from(
        new Set(integrations.map((integration) => integration.category))
    )
    return { categories, integrations: integrations, ...query }
}

export const useRequestIntegration = () => {
    return useMutation((message: string) => {
        const path = `${ENDPOINT}request/`
        return fetchAndReturn(path, {
            method: 'POST',
            headers: acceptHeaders,
            body: JSON.stringify({ message: message }),
        })
    })
}
