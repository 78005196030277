// @ts-strict-ignore
import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SET_IS_OPEN, SET_MODAL_DATA, TOGGLE_MODAL } from 'v2/reducers/ModalsReducer'

import { GlobalModalContext } from 'app/GlobalModals'

export type Modal<TData> = {
    toggle: () => void
    setIsOpen: (data: Partial<TData> | boolean | null | undefined) => void
    setData: (data: Partial<TData>) => void
    show: (data: Partial<TData> | SyntheticEvent) => void
    isOpen: boolean
    data: TData
}

export type ModalDeclaration<TData> = {
    icon?: React.ReactNode
    confirmVariant?: string
    message?: React.ReactNode
    onConfirm?: (modal: Modal<TData>) => Promise<void>
}

/*
    A Hook used for coordinating modals. The initiator of the modal can use this hook
    to pass state-data to the modal and toggle the model's open state.

    The modal component itself can use the hook to respond to the changes in open state,
    close itself, and also update the modal state-data, which the initiator can receive.
*/
export function useModalToggle<TData>(key: string, data?: TData): Modal<TData> {
    const isOpen = useSelector((state) => state.modals?.open?.[key])
    const stateData = useSelector((state) => state.modals?.data?.[key]) as TData
    const dispatch = useDispatch()

    const setData = useCallback(
        (value) => {
            dispatch({ type: SET_MODAL_DATA, key, value })
        },
        [dispatch, key]
    )
    const toggle = useCallback(() => {
        // if we're showing the modal, pass the incoming data to the store
        // so the modal can access it
        if (!isOpen) {
            setData(data)
        }
        dispatch({ type: TOGGLE_MODAL, key })
    }, [data, dispatch, isOpen, key, setData])
    const setIsOpen = useCallback(
        (value: TData | boolean | null | undefined) => {
            // if we're showing the modal, pass the incoming data to the store
            // so the modal can access it
            if (value) {
                setData(data)
            }
            dispatch({ type: SET_IS_OPEN, key, value })
        },
        // The broken lint rule says we need the generic TData in the dependencies, which we dont'
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, dispatch, key, setData]
    )
    const show = useCallback(
        (arg: TData | SyntheticEvent) => {
            // It's common to want to have a click event wired directly to this show method.
            // In which case, the arg will be a SyntheticEvent. Otherwise we can assume
            // arg is a new data object which should be used
            const newData = arg && 'nativeEvent' in arg ? null : arg
            setData(newData || data)
            dispatch({ type: SET_IS_OPEN, key, value: true })
        },
        // The broken lint rule says we need the generic TData in the dependencies, which we dont'
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, dispatch, key, setData]
    )

    return useMemo(
        () => ({ isOpen, toggle, setIsOpen, data: stateData, setData, show }),
        [isOpen, toggle, setIsOpen, stateData, setData, show]
    )
}

/*
    Used to register a modal component with the global modal context, as well as
    return an instance of the useModalToggle for controlling the modal.
*/
export function useModalDeclaration<TData>(
    key: string,
    Component: React.ComponentType,
    data?: TData
) {
    const { registerModal } = useContext(GlobalModalContext)
    const result = useModalToggle<TData>(key, data)

    // If the modal component is being specified, register
    useEffect(() => {
        if (Component) registerModal(key, Component)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Component])

    return result
}

export default useModalToggle
