import React from 'react'

import { Avatar } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

const colors = stackerTheme().colors

export type UserListItemProps = {
    user: any
    visible: boolean
    rowContents?: React.ReactNode
    rowActions?: React.ReactNode
    userNameDecorator?: React.ReactNode
    rowContainerStyle?: React.CSSProperties
    limitWidth?: boolean
}
function UserListItem(props: UserListItemProps) {
    const { user, visible } = props

    const width = props.limitWidth ? '55%' : props.rowActions ? '90%' : '95%'

    return (
        <UserRowContainer
            style={{
                display: visible ? 'flex' : 'none',
                color: colors.neutral[1000],
                ...props.rowContainerStyle,
            }}
        >
            <Avatar src={user.avatar} name={user.name?.toString()} size="xs" mr={2} />
            <div
                style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    minWidth: 0,
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                }}
            >
                <UserDisplayName
                    user={user}
                    userNameDecorator={props.userNameDecorator}
                    width={width}
                />
                {props.rowContents}
                {props.rowActions}
            </div>
        </UserRowContainer>
    )
}

export const UserRowContainer: React.FC<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ style, ...props }) => (
    <div
        style={{
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            alignItems: 'center',
            fontSize: '14px',
            ...style,
        }}
        {...props}
    />
)

type UserDisplayNameProps = {
    user: any
    userNameDecorator?: React.ReactNode
    width?: string
    showSpecialOptions?: boolean
}
export const UserDisplayName: React.FC<UserDisplayNameProps> = ({
    user,
    userNameDecorator,
    width,
}) => {
    //When the name field (primary field) of the users table
    //is a number, we are currently displaying the name as 1.0, 2.0, etc
    //if the name field is an integer, so here we remove it before displaying it
    let sanitizedUser = user.name?.toString() || ''
    if (sanitizedUser.match(/.0$/)) {
        sanitizedUser = sanitizedUser.replace('.0', '')
    }

    const isMobile = useIsMobile()
    return (
        <div
            style={{
                verticalAlign: 'middle',
                flexShrink: 0,
                display: 'flex',
                flexWrap: isMobile ? 'wrap' : 'nowrap',
                width: width,
                minWidth: 0,
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    fontWeight: 'bold',
                    marginRight: '8px',
                    width: '45%',
                    flexShrink: 0,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                <span
                    style={{
                        verticalAlign: 'middle',
                    }}
                >
                    {sanitizedUser || user.email}
                </span>
                {userNameDecorator}
            </div>

            <div
                style={{
                    opacity: 0.8,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexGrow: 1,
                }}
            >
                {user.email}
            </div>
        </div>
    )
}

export default React.memo(UserListItem)
