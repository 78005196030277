import React, { useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { GoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/react'

import { useThirdPartyCookiesCheck } from 'features/utils/ThirdPartyCookiesCheck'
import { Icon8 } from 'legacy/v1/ui'
import analytics from 'utils/analytics'

import { Box, Button, Collapse, Flex } from 'v2/ui'

import { getSignupParams, LoginApi } from '../utils'

const StyledButton = styled(Button)`
    width: 100%;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 6px;
    font-family: 'Google Sans', arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    &:hover {
        border-color: #d2e3fc;
        text-decoration: none;
        background-color: #f8faff;
    }
`

export const StyledGoogleLoginButton = ({ children, isLoading, ...props }) => (
    <StyledButton height="40px" {...props}>
        <Box borderRadius="5px" p="2px">
            <Icon8 icon="google-logo" size={48} displaySize="20" iconStyle="color" noMargin />
        </Box>
        {isLoading ? (
            <Flex flexGrow={1} column mr="40px">
                <Spinner />
            </Flex>
        ) : (
            <Box flexGrow={1} mx={4}>
                {children}
            </Box>
        )}
    </StyledButton>
)
const IGNORED_GOOGLE_ERRORS = ['access_denied', 'popup_closed_by_user']

const GoogleLoginButton = ({ onSuccess, onRejected, isSignUp, invitation }) => {
    const [showCookiesWarning, setShowCookiesWarning] = useState()
    const thirdPartyCookies = useThirdPartyCookiesCheck()

    const signup_params = getSignupParams()

    const handleSuccess = (response) => {
        LoginApi.login_with_provider_token({
            provider: 'google',
            jwt_token: response.credential,
            portal: true,
            options: {
                signup_params,
            },
            invitation_token: invitation?.token,
        }).then((response) => {
            if (!response.api_token) {
                onRejected(response)
                return
            }

            onSuccess(response)
        })
    }

    const handleFailure = (result) => {
        analytics.track('user registration failed', {
            event_category: 'user',
            event_description: 'New user registration failed',
            error: result.error,
            authentication_method: 'google',
        })

        if (result && IGNORED_GOOGLE_ERRORS.includes(result.error)) {
            // do nothing. These errors indicate the user aborted the google auth process.
            // If the user doesn't have 3rd party cookies enabled, then we can make a reasonable
            // guess that this prevented them from signing in. So we'll show a message about that now
            if (thirdPartyCookies.isUnsupported && !thirdPartyCookies.isLoading) {
                setShowCookiesWarning(true)
            }
        } else {
            // Not sure what happened. This is the catch all, so we just want to log it.
            Sentry.withScope((scope) => {
                scope.setExtra('response', result)
                scope.setLevel('error')
                Sentry.captureMessage('Sign In with Google failed')
            })
        }
    }

    return (
        <>
            <GoogleLogin
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                text={isSignUp ? 'signup_with' : 'signin_with'}
                width={400}
                logo_alignment="left"
                containerProps={{
                    'data-testid': 'google-login-button',
                    style: {
                        margin: '0 auto',
                        width: '100%',
                    },
                }}
            />
            <Collapse isOpen={!!showCookiesWarning}>
                <Box mt={2} bg="grey.100" radius="sm" p={2}>
                    Your browser is blocking <strong>third-party cookies</strong>, please change
                    your browser settings or {isSignUp ? 'sign up' : 'login'} with email and
                    password instead.
                </Box>
            </Collapse>
        </>
    )
}

export default GoogleLoginButton
