import { useCallback } from 'react'

import { useTheme } from '@chakra-ui/react'
import {
    DataEditorProps,
    GridCell,
    GridCellKind,
    ProvideEditorCallback,
} from '@glideapps/glide-data-grid'

import AttachmentsCellRenderer from '../cells/AttachmentsCell'
import DateCellRenderer from '../cells/DateCell'
import DropdownCellRenderer from '../cells/DropdownCell'
import { MagicUserFieldCellRenderer } from '../cells/MagicUserFieldCell'
import PercentageCellRenderer from '../cells/PercentageCell'
import RecordLinkRenderer from '../cells/RecordLinkCell'
import RichTextCellRenderer from '../cells/RichTextCell'
import type { CustomCellRenderer } from '../types'

type DrawCallback = NonNullable<DataEditorProps['drawCell']>

const CustomRenderers: CustomCellRenderer<any>[] = [
    AttachmentsCellRenderer,
    DateCellRenderer,
    DropdownCellRenderer,
    MagicUserFieldCellRenderer,
    PercentageCellRenderer,
    RecordLinkRenderer,
    RichTextCellRenderer,
]

// Provides custom cell renderers and editors for the data grid
function useFieldCells(): {
    drawCell: DrawCallback
    provideEditor: ProvideEditorCallback<GridCell>
    coercePasteValue: NonNullable<DataEditorProps['coercePasteValue']>
} {
    const theme = useTheme()

    const drawCell = useCallback<DrawCallback>(
        (args) => {
            const { cell } = args

            const newArgs = { ...args, appTheme: theme }

            if (cell.kind !== GridCellKind.Custom) return false
            for (const renderer of CustomRenderers) {
                if (renderer.isMatch(cell)) {
                    return renderer.draw(newArgs, cell as any)
                }
            }
            return false
        },
        [theme]
    )

    const provideEditor = useCallback<ProvideEditorCallback<GridCell>>((cell) => {
        if (cell.kind !== GridCellKind.Custom) return undefined

        for (const renderer of CustomRenderers) {
            if (renderer.isMatch(cell)) {
                return renderer.provideEditor(cell as any) as ReturnType<
                    ProvideEditorCallback<GridCell>
                >
            }
        }

        return undefined
    }, [])

    const coercePasteValue = useCallback<NonNullable<DataEditorProps['coercePasteValue']>>(
        (value, cell) => {
            if (cell.kind !== GridCellKind.Custom) {
                return undefined
            }

            for (const renderer of CustomRenderers) {
                if (renderer.isMatch(cell)) {
                    if (!renderer.onPaste) {
                        return undefined
                    }

                    return {
                        ...cell,
                        data: renderer.onPaste(value, cell.data),
                    }
                }
            }
        },
        []
    )

    return { drawCell, provideEditor, coercePasteValue }
}

export default useFieldCells
