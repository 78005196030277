import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as Sentry from '@sentry/react'

import { isStepVisible, StepTypes, useExecuteAction } from 'data/hooks/actions'
import { useRecordManager } from 'data/hooks/useRecordManager'

import {
    Button,
    Collapse,
    Divider,
    Flex,
    Heading,
    Hint,
    LoadingScreen,
    MarkdownText,
    Modal,
    ScrollBox,
    Text,
    useCtrlKey,
} from 'v2/ui'
import { useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

import UpdateRecordStep, { Props as UpdateRecordStepProps } from './UpdateRecordStep'

const MODAL_KEY = 'ExecuteAction'

export const useExecuteActionModal = (action: ActionDto, recordId: string) => {
    const data = useMemo(() => ({ action, recordId }), [action, recordId])
    return useModalDeclaration(MODAL_KEY, ExecuteActionModal, data)
}

const ExecuteActionModal: FC = () => {
    const { isOpen, toggle, data } =
        useModalToggle<{ action: ActionDto; recordId: string }>(MODAL_KEY)

    const [validity, setValidity] = useState<{ [keyof: string]: boolean }>({})
    const [isLoading, setIsLoading] = useState(true)
    const [stepData, setStepData] = useState<
        { [keyof: string]: { fields: Partial<RecordDto> } } | undefined
    >()
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const action = data?.action
    const actionId = action?._sid
    const recordId = data?.recordId
    const recordManager = useRecordManager(recordId, action?.object_id)
    const executeAction = useExecuteAction(action?.object_id)
    const loadedData = useRef<{ action: ActionDto; recordId: string } | null>()
    const ctrlKey = useCtrlKey()

    const setValid = useCallback((key: string, value: boolean) => {
        setValidity((state) => ({
            ...state,
            [key]: value,
        }))
    }, [])

    const isValid = useCallback(() => {
        let isValid = true
        Object.keys(validity).map((key) => {
            isValid = isValid && validity[key]
        })

        return isValid
    }, [validity])

    const save = useCallback(async () => {
        const data = { record_id: recordId, action_id: actionId, steps: stepData }

        if (!isValid()) {
            setError('Please fill out the required fields')
            return
        }

        setIsSaving(true)
        setError(null)
        try {
            await executeAction(data)
            setIsSaving(false)
            toggle()
        } catch (error) {
            Sentry.withScope((scope) => {
                scope.setExtra('action', actionId)
                scope.setLevel('error')
                Sentry.captureException(error)
            })

            setIsSaving(false)
            setError('An error occurred executing this action.')
        }
    }, [actionId, executeAction, isValid, recordId, stepData, toggle])

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                save()
            }
        },
        [save]
    )

    const updateStepData = useCallback((id: string, data: { fields: Partial<RecordDto> }) => {
        setError(null)
        setStepData((state) => ({ ...state, [id]: data }))
    }, [])

    // Clear the valid fields on action change
    useEffect(() => {
        setValidity({})
    }, [actionId])

    // When the modal closes, reset the record manager
    // and reset flag so that we relaoad the latest records
    // if they open the modal again.
    useEffect(() => {
        if (!isOpen) {
            recordManager.reset()
            loadedData.current = null
            setIsLoading(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    // When the data prop changes, and we are open, then initialize the action.
    // Load records, etc.
    useEffect(() => {
        if (data && loadedData.current !== data && isOpen && data?.action) {
            const { action, recordId } = data
            loadedData.current = data
            const recordIds: { [keyof: string]: boolean } = {}
            for (let step of action.options.steps) {
                // NOTE: Other step types such as updating related records,
                // creating new records, etc., will need to be addressed here
                // so that their records are loaded/registered with the record manager
                if (step.type === 'updateRecord') {
                    recordIds[recordId] = true
                }
            }
            recordManager.loadRecord(recordId).then(() => setIsLoading(false))
        }
    }, [data, isOpen, recordManager, recordId])

    if (!action) return null

    const { title, description, steps = [] } = action.options

    const hasVisibleActions = Boolean(steps.find(isStepVisible))
    if (!isOpen) return null

    return (
        <Modal isOpen onClose={toggle} title={title || action.name} size="600">
            <ScrollBox
                maxHeight={['90%', null, null, 600]}
                margin="-5px"
                padding="5px"
                onKeyDown={handleKeyDown}
            >
                <LoadingScreen isLoading={isLoading} minHeight="60px">
                    <Collapse
                        isOpen={!!!isLoading}
                        isOpenDelay={0.05}
                        maxHeight={['90%', null, null, 600]}
                    >
                        <Flex column align="stretch">
                            {description && (
                                <>
                                    <MarkdownText
                                        showRichTextEditor={typeof description === 'object'}
                                        convertToMarkdown={false}
                                    >
                                        {description}
                                    </MarkdownText>
                                    <Divider mt={4} />
                                </>
                            )}
                        </Flex>
                        <ActionSteps
                            action={action}
                            recordManager={recordManager}
                            recordId={recordId}
                            updateStepData={updateStepData}
                            setValid={setValid}
                            showErrors={!!error}
                        />
                        {hasVisibleActions && <Divider mt={4} />}
                        <Flex mt={4}>
                            <Text
                                variant="error"
                                flexGrow={1}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {error}
                            </Text>
                            <Button variant="moderateSm" onClick={toggle} mr={1}>
                                Cancel
                            </Button>
                            {/* @ts-expect-error */}
                            <Hint
                                label={`you can press ${ctrlKey} + enter to save`}
                                placement="bottom"
                                desktopOnly
                            >
                                <Button
                                    buttonSize="sm"
                                    icon="checkmark"
                                    isLoading={isSaving}
                                    onClick={save}
                                >
                                    Save
                                </Button>
                            </Hint>
                        </Flex>
                    </Collapse>
                </LoadingScreen>
            </ScrollBox>
        </Modal>
    )
}

const StepWrapper: FC<UpdateRecordStepProps> = (props) => {
    const { title, description, type } = props.step
    const Component = StepCompoments[type]

    return (
        <Flex column align="stretch" mt={4}>
            {title && (
                <Heading as="h2" value={title} variant="actionStepTitle" mb={description ? 0 : 4} />
            )}
            {description && (
                <Text variant="actionStepDescription">
                    <MarkdownText>{description}</MarkdownText>
                </Text>
            )}
            <Component {...props} />
        </Flex>
    )
}

const ActionSteps: FC<Omit<UpdateRecordStepProps, 'step'> & { action: ActionDto }> = ({
    action,
    ...props
}) => (
    <>
        {action?.options?.steps.map((step, index) => (
            <StepWrapper key={index} step={step} {...props} />
        ))}
    </>
)

const StepCompoments = {
    [StepTypes.UpdateRecord]: UpdateRecordStep,
}

export default ExecuteActionModal
