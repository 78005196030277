import React, { memo, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
// eslint-disable-next-line no-duplicate-imports
import { DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

import { css, keyframes } from '@emotion/react'
import get from 'lodash/get'
import { ViewCellBaseProps } from 'v2/views/List/types'

import { ListRecordWrapper } from 'features/admin/edit-mode/ListView/ListRecordWrapper'

import { BackgroundImage, Box, ConditionalWrapper, Container, Flex, Link } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import EditableFieldWrapper from '../../../features/admin/edit-mode/ListView/EditableListFieldWrapper'

type KanbanCardProps = {
    colIndex: number
    rowIndex: number
    rowLink?: (row: any) => string
    label: string
    cells: ViewCellBaseProps[]
    coverImage?: { url: string | null; fitImage: 'contain' | 'cover' } | false
    props: { [keyof: string]: any }
    row: { [keyof: string]: any }
    justDropped: boolean
    field: FieldDto
    viewOptions: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    object?: ObjectDto
    isFirstCard: boolean
    isContainerHovered: boolean
}

export const KanbanCard = memo(
    ({
        colIndex,
        rowIndex,
        rowLink,
        label,
        cells,
        coverImage,
        props,
        row,
        justDropped,
        field,
        viewOptions,
        setConfig,
        object,
        isFirstCard,
        isContainerHovered,
    }: KanbanCardProps): JSX.Element => {
        const link = rowLink ? rowLink(row) : ''
        const isDraggable = useMemo(() => canUpdateStatus(row, field), [row, field])
        return (
            <Draggable
                draggableId={row.original._sid}
                index={rowIndex}
                isDragDisabled={!isDraggable}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getCardStyle(provided.draggableProps.style, snapshot)}
                    >
                        <ConditionalWrapper
                            condition={!!link}
                            wrapper={(children) => (
                                <Link
                                    simplifiedComponent
                                    className={
                                        rowIndex === 0 && colIndex === 0
                                            ? ONBOARDING_CLASSES.TABLE_ITEM
                                            : ''
                                    }
                                    href={link}
                                >
                                    {children}
                                </Link>
                            )}
                        >
                            <ListRecordWrapper
                                recordId={row.original._sid}
                                key={row.original._sid}
                                h="100%"
                                highlight={isContainerHovered}
                            >
                                {({ isActive, lastHoveredRecordId }) => {
                                    const showEditControls =
                                        isActive || (isFirstCard && !lastHoveredRecordId)
                                    return (
                                        <Container
                                            position="relative"
                                            title={label}
                                            mb={2}
                                            {...props}
                                            css={justDropped ? cssAnimation : null}
                                        >
                                            <Box p="container.padding">
                                                {cells.map(({ label, value, column }, index) => (
                                                    <React.Fragment key={column.id}>
                                                        {index === 0 && (
                                                            <ConditionalWrapper
                                                                condition={!!coverImage}
                                                                wrapper={(children) => (
                                                                    <Flex wrap="nowrap">
                                                                        {children}
                                                                    </Flex>
                                                                )}
                                                            >
                                                                {index === 0 &&
                                                                    coverImage &&
                                                                    coverImage.url && (
                                                                        <BackgroundImage
                                                                            alignSelf="start"
                                                                            src={get(
                                                                                coverImage,
                                                                                'url'
                                                                            )}
                                                                            size={[
                                                                                'kanban.coverImage',
                                                                                null,
                                                                                null,
                                                                                'kanban.coverImageLg',
                                                                            ]}
                                                                            borderRadius="full"
                                                                            flexShrink={0}
                                                                            mr="table.rowLg"
                                                                        />
                                                                    )}
                                                                <h2
                                                                    className="kanban-record-label"
                                                                    style={{
                                                                        margin: '-6px -6px',
                                                                        padding: '6px',
                                                                        borderRadius: '6px',
                                                                        position: 'relative',
                                                                        display: 'grid',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    <EditableFieldWrapper
                                                                        position="horizontal"
                                                                        fieldId={
                                                                            column.renderOptions &&
                                                                            column?.renderOptions
                                                                                .fieldId
                                                                        }
                                                                        objectId={
                                                                            object && object._sid
                                                                        }
                                                                        viewOptions={viewOptions}
                                                                        setConfig={setConfig}
                                                                        hideLabelOptions
                                                                        showControls={
                                                                            showEditControls
                                                                                ? 'default'
                                                                                : 'never'
                                                                        }
                                                                    >
                                                                        {value}
                                                                    </EditableFieldWrapper>
                                                                </h2>
                                                            </ConditionalWrapper>
                                                        )}
                                                        {index > 0 && (
                                                            <>
                                                                <h3 className="kanban-card-label">
                                                                    <EditableFieldWrapper
                                                                        position="horizontal"
                                                                        fieldId={
                                                                            column.renderOptions &&
                                                                            column.renderOptions
                                                                                .fieldId
                                                                        }
                                                                        objectId={
                                                                            object && object._sid
                                                                        }
                                                                        viewOptions={viewOptions}
                                                                        setConfig={setConfig}
                                                                        showControls={
                                                                            showEditControls
                                                                                ? 'default'
                                                                                : 'never'
                                                                        }
                                                                    >
                                                                        {column.renderOptions &&
                                                                            (column.renderOptions
                                                                                .displayAsButton ||
                                                                                (!column
                                                                                    .renderOptions
                                                                                    .hideLabel &&
                                                                                    !column
                                                                                        .renderOptions
                                                                                        .treatAsUrl &&
                                                                                    label))}
                                                                    </EditableFieldWrapper>
                                                                </h3>
                                                                <div
                                                                    className="kanban-card-field-text"
                                                                    style={{
                                                                        wordBreak: 'break-word',
                                                                    }}
                                                                >
                                                                    {value}
                                                                </div>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </Box>
                                        </Container>
                                    )
                                }}
                            </ListRecordWrapper>
                        </ConditionalWrapper>
                    </div>
                )}
            </Draggable>
        )
    }
)

/** this disabled the default dnd drop animation.
 * we don't want it in our case because we're not
 * animating into a specific drop index at this time.
 * if we add support for ordering, then we will remove this
 */
const getCardStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
) => {
    if (!snapshot.isDropAnimating) {
        return style
    }
    return {
        ...style,
        transitionDuration: `0.001s`,
    }
}

const droppedAnimation = keyframes`
  0% {
    background-color: rgb(200,255,200);
  }
  100% {
    background-color: white;
  }
`
const cssAnimation = css`
    animation: ${droppedAnimation} 2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
`
const canUpdateStatus = (row: { [keyof: string]: any }, field: FieldDto) => {
    const { may_update_fields, may_update } = row?.original?._permissions || {}
    return may_update && may_update_fields.includes(field.api_name)
}
