import React, { useEffect, useState } from 'react'

import { TabPanels } from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import ObjectDataGrid from 'features/admin/datagrid/ObjectDataGrid'

import { Box, Flex, TabPanel } from 'v2/ui'

import type { ManageDataTabsProps } from './types'

export const ManageDataTabsPanel: React.VFC<
    Pick<
        ManageDataTabsProps,
        | 'objects'
        | 'emptyState'
        | 'selectedTabIndex'
        | 'updateDirtyPanes'
        | 'ignorePreviewAndImpersonation'
    >
> = ({
    objects,
    emptyState,
    selectedTabIndex,
    updateDirtyPanes,
    ignorePreviewAndImpersonation,
}) => {
    return isEmpty(objects) ? (
        <Flex
            w="100%"
            h="100%"
            column
            justifyContent="center"
            alignItems="center"
            bg="userInterface.neutral.0"
            borderTopLeftRadius="5px"
            borderBottomLeftRadius="5px"
        >
            {emptyState}
        </Flex>
    ) : (
        <TabPanels h="100%">
            {objects.map((object, index) => (
                <TabPanel key={object._sid} h="100%">
                    <Box
                        flexGrow={1}
                        h="100%"
                        bg="userInterface.neutral.0"
                        overflow="hidden"
                        borderTopLeftRadius="5px"
                        borderBottomLeftRadius="5px"
                    >
                        <ObjectTabPanelContents
                            isActive={index === selectedTabIndex}
                            objectId={object._sid}
                            setIsDirty={(dirty: boolean) => updateDirtyPanes(object._sid, dirty)}
                            ignorePreviewAndImpersonation={ignorePreviewAndImpersonation}
                        />
                    </Box>
                </TabPanel>
            ))}
        </TabPanels>
    )
}

type ObjectTabPanelContentsProps = {
    objectId: string
    isActive: boolean
    setIsDirty?: (dirty: boolean) => void
    ignorePreviewAndImpersonation?: boolean
}
export const ObjectTabPanelContents: React.VFC<ObjectTabPanelContentsProps> = ({
    objectId,
    isActive,
    setIsDirty,
    ignorePreviewAndImpersonation,
}) => {
    const [isInitialized, setIsInitialized] = useState(false)
    useEffect(() => {
        if (isActive) setIsInitialized(true)
    }, [isActive])
    // Don't load the contents of the pane until it has been activated.
    if (!isInitialized) return null

    return (
        <ObjectDataGrid
            hideLabel={true}
            objectId={objectId}
            setIsDirty={setIsDirty}
            isActive={isActive}
            ignorePreviewAndImpersonation={ignorePreviewAndImpersonation}
        />
    )
}
