import { useCallback } from 'react'

import moment from 'moment'

import { useObjectCache } from 'data/hooks/cacheInfo'
import { useObject } from 'data/hooks/objects'

/**
 * Either kicks of a cache fill for the specified object if it typically caches quickly
 * or flags the object as needing a cache fill, which will be displayed to the user in the UI somewhere.
 */
type ReturnType = () => Promise<void>
export const useSetDataSyncRequired = (objectId: string): ReturnType => {
    const { objectStatuses, startCacheFillForObject } = useObjectCache()
    const { object, changeObject } = useObject(objectId)

    const result = useCallback(async () => {
        const lastCacheDurationRaw = object?.connection_options?.cache_latest_duration
        // If this object last cached in less than 5 seconds, just go ahead and trigger
        // the cache fill now.
        if (
            object?._sid &&
            !objectStatuses[object._sid]?.fill_pending &&
            lastCacheDurationRaw &&
            moment(lastCacheDurationRaw, "'HH:mm:ss'").seconds() < 0.5
        ) {
            startCacheFillForObject(object?._sid)
            // Otherwise, mark the object as needing a cache fill, and that will
            // display UI to the user tell them to trigger it when ready
        } else {
            await changeObject({
                connection_options: {
                    ...object?.connection_options,
                    fill_needed_for_field_changes: true,
                },
            })
        }
    }, [
        changeObject,
        object?._sid,
        object?.connection_options,
        objectStatuses,
        startCacheFillForObject,
    ])

    return result
}
