import styled from '@emotion/styled'

const FloatingButton = styled('a')`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: block;
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: white;
    background-color: ${(props) => props.color || 'red'};
    font-size: 25px;
    text-align: center;
    line-height: 50px;
    transition: all 0.5s ease;
`
export default FloatingButton

export const SlidingFloatingButton = styled(FloatingButton)`
    margin-right: 0px;
    transform: rotateZ(200deg);
    transition-delay: 0.1s;
`

export const FloatingContainer = styled('div')`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: block;

    &:hover > a {
        margin-right: 60px;
        transform: rotateZ(0deg);
        transition-delay: 0s;
    }
`
