const VALUE_REGEXP = /^\d{1,4}$/

export function validateDayCounterValue(value: string): boolean {
    if (!value) return true

    if (!VALUE_REGEXP.test(value)) return false

    if (parseInt(value, 10).toString() !== value) return false

    return true
}
