// @ts-strict-ignore
import { useQuery } from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useInstallableFeatures'
const ENDPOINT = 'installable-features/'
const LIST_NAME_UNPUBLISHED: REACT_QUERY_DEPS_NAME = 'useInstallableFeatures&_unpublished'
const LIST_NAME_UNPUBLISHED_WITH_TYPE: REACT_QUERY_DEPS_NAME =
    'useInstallableFeatures&_unpublished&_type'

export function useInstallableFeatures(
    params: { unpublished?: boolean; type?: string } = {},
    options = {}
) {
    let features_key =
        params.unpublished && params.type
            ? LIST_NAME_UNPUBLISHED_WITH_TYPE
            : params.unpublished
            ? LIST_NAME_UNPUBLISHED
            : LIST_NAME
    return useQuery<FeatureDto[] | InstallableFeature[]>(features_key, ENDPOINT, options, {
        bypassMatchingStackCheck: true,
        queryParams: {
            unpublished: params.unpublished,
            type: params.type,
        },
    })
}

export const useDefaultFeature = (type) => {
    let feature
    if (type === 'table') {
        feature = {
            name: 'Default table',
            card_description: 'Start with a table with default fields.',
            icon_color: '#465dd8',
            icon: 'bolt',
            is_published: true,
            is_default: true,
        }
    }

    return { data: feature }
}
