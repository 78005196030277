import React, { ChangeEvent, FC } from 'react'

import { Switch } from '@chakra-ui/react'

import { Box, Dropdown, Flex, Text } from 'v2/ui'

const ROUND_OPTIONS: { label: string; value: ChartDecimalPlaces }[] = [
    { label: 'Max 2 decimal places', value: 'max2' },
    { label: 'Whole number', value: '0' },
    { label: '1 decimal place', value: '1' },
    { label: '2 decimal places', value: '2' },
    { label: '3 decimal places', value: '3' },
    { label: '4 decimal places', value: '4' },
    { label: '5 decimal places', value: '5' },
]

type DisplayPanelProps = {
    aggregationWidget: AggregateBlockAttributes
    onChange: (
        aggregationWidget: Pick<AggregateBlockAttributes, 'objectId'> &
            Partial<AggregateBlockAttributes>
    ) => void
}

export const DisplayPanel: FC<DisplayPanelProps> = ({
    aggregationWidget: { objectId, display },
    onChange,
}) => {
    const onChangeRoundResults = (
        newDecimalPlaces: AggregateBlockAttributes['display']['decimalPlaces']
    ) => {
        onChange({
            objectId,
            display: { ...display, decimalPlaces: newDecimalPlaces },
        })
    }

    const onChangeAbbreviate = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            objectId,
            display: {
                ...display,
                abbreviation: !event.target.checked ? 'none' : display.abbreviation,
                autoAbbreviation: event.target.checked,
            },
        })
    }

    return (
        <>
            <Box>
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="aggregation-rounding"
                    mb={1}
                >
                    Round results
                </Text>
                <Text mb={1} color="grey.700" size="xs">
                    E.g. show 2.499 as 2.5
                </Text>
                <Dropdown
                    inputId="aggregation-rounding"
                    variant="admin"
                    isClearable={false}
                    isSearchable={false}
                    options={ROUND_OPTIONS}
                    value={display.decimalPlaces}
                    onChange={onChangeRoundResults}
                />
            </Box>
            <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Box>
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="aggregation-abbreviate"
                        mb={1}
                    >
                        Abbreviate large results
                    </Text>
                    <Text color="grey.700" size="xs">
                        E.g. show 1200 as 12k
                    </Text>
                </Box>
                <Switch
                    id="aggregation-abbreviate"
                    colorScheme="adminSwitch"
                    isChecked={!!display.autoAbbreviation}
                    onChange={onChangeAbbreviate}
                />
            </Flex>
        </>
    )
}
