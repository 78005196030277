import { GetTokenSilentlyOptions } from '@auth0/auth0-react'

type AuthenticationState = {
    isAuth0Authenticated?: boolean
    isSupertokensAuthenticated?: boolean
    getAccessTokenSilently?: (options?: GetTokenSilentlyOptions) => Promise<string>
}

export const Authentication: AuthenticationState = {
    isAuth0Authenticated: false,
    isSupertokensAuthenticated: false,
    getAccessTokenSilently: undefined,
}
