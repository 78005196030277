import {
    Actions,
    assign,
    AssignAction,
    createMachine,
    DoneInvokeEvent,
    TransitionsConfig,
} from 'xstate'

import { determineFunctionAndKeywordSelection } from '../selection/formulaSelectionFunctions'

import {
    assignToContextFromInitializeEvent,
    insertAutocompleteTextAndSetSelection,
    setFocusAtEndOfEditor,
    updateSuggestionsAndHelperValues,
} from './formulaEditorMachineActions'
import { VALIDATE_FORMULA_AFTER_MS } from './formulaEditorMachineConstants'
import {
    FormulaEditorValidationResult,
    validateFormulaString,
} from './formulaEditorMachineServices'
import {
    FormulaEditorAutocompleteEvent,
    FormulaEditorContext,
    FormulaEditorMachineEvent,
    FormulaEditorTypestate,
} from './formulaEditorMachineTypes'

const eventsAfterSetup: TransitionsConfig<FormulaEditorContext, FormulaEditorMachineEvent> = {
    AUTOCOMPLETE: {
        cond: (context) =>
            context.canAutocomplete &&
            !!context.editorRef?.current &&
            (context.suggestedFields.length > 0 || context.suggestedKeywords.length > 0),
        actions: [
            assign((context, event: FormulaEditorAutocompleteEvent) => {
                const commonParams = {
                    selection: context.selection,
                    textarea: context.editorRef?.current as HTMLTextAreaElement,
                }
                const param: Parameters<typeof insertAutocompleteTextAndSetSelection>[0] = {
                    ...commonParams,
                    ...(event.payloadType === 'keyword'
                        ? { keyword: event.keyword, type: 'keyword' }
                        : event.payloadType === 'field'
                        ? { field: event.field, type: 'field' }
                        : context.suggestedFields.length > 0
                        ? {
                              field: context.suggestedFields[0],
                              type: 'field',
                          }
                        : { keyword: context.suggestedKeywords[0], type: 'keyword' }),
                }
                return { formulaString: insertAutocompleteTextAndSetSelection(param) }
            }) as AssignAction<FormulaEditorContext, FormulaEditorAutocompleteEvent>,
            updateSuggestionsAndHelperValues,
            assign((_) => ({
                canAutocomplete: false,
                suggestedFields: [],
                suggestedKeywords: [],
                functionForHelpPanel: undefined,
            })),
        ],
        target: 'waiting_to_validate',
    },
    BLUR: {
        actions: assign((_) => ({ isFocused: false })),
    },
    FOCUS: {
        actions: assign((_) => ({ isFocused: true })),
    },
    SELECTION_MOVED: {
        actions: [
            assign((context) => {
                const newSelection = context.editorRef?.current
                    ? determineFunctionAndKeywordSelection({
                          editor: context.editorRef.current,
                          shouldConsiderAfterCursor: true,
                      })
                    : undefined
                return {
                    canAutocomplete: false,
                    selection: newSelection ?? context.selection,
                }
            }),
            updateSuggestionsAndHelperValues,
        ],
    },
    TEXT_CHANGED: {
        actions: [
            assign((context, event) => {
                const newSelection = context.editorRef?.current
                    ? determineFunctionAndKeywordSelection({
                          editor: context.editorRef.current,
                          shouldConsiderAfterCursor: false,
                      })
                    : undefined
                return {
                    canAutocomplete: true,
                    formulaString: event.formulaString,
                    selection: newSelection ?? context.selection,
                }
            }),
            updateSuggestionsAndHelperValues,
        ],
        target: 'waiting_to_validate',
    },
}

export const formulaEditorMachine = createMachine<
    FormulaEditorContext,
    FormulaEditorMachineEvent,
    FormulaEditorTypestate
>({
    preserveActionOrder: true,
    context: {
        canAutocomplete: false,
        formulaString: '',
        functionForHelpPanel: undefined,
        isFocused: false,
        isBlank: false,
        selection: {},
        shouldOnlyShowFields: false,
        suggestedFields: [],
        suggestedKeywords: [],
        validationError: '',
    },
    id: 'formulaEditor',
    initial: 'uninitialized',
    states: {
        uninitialized: {
            on: {
                INITIALIZE: [
                    {
                        actions: [
                            assignToContextFromInitializeEvent,
                            (_, { editorRef, initialFormulaString }) => {
                                if (editorRef.current) {
                                    editorRef.current.value = initialFormulaString
                                    setFocusAtEndOfEditor(editorRef.current)
                                }
                            },
                            updateSuggestionsAndHelperValues,
                            ({ formulaString, onChange, parsedFormula }: FormulaEditorContext) => {
                                if (!onChange) {
                                    throw new Error('onChange not set')
                                }
                                if (!parsedFormula) {
                                    // Notify the parent if the initial value is invalid.
                                    // We're forced to use a timeout here because the form
                                    // context holding the state is modified by `FieldEditorForm`
                                    // inside a `useEffect` hook. This is bad for us because it
                                    // means that it gets changed AFTER we have notified them
                                    // about the change.
                                    // The cleaner solution would be to refactor `FieldEditorForm`
                                    // to stop using `useEffect` there, but for the time being,
                                    // this will have to do.
                                    setTimeout(() => {
                                        onChange({
                                            formulaString,
                                            isValid: false,
                                        })
                                    }, 1)
                                }
                            },
                        ],
                        target: 'idle',
                    },
                ],
            },
        },
        idle: {
            on: eventsAfterSetup,
        },
        waiting_to_validate: {
            after: {
                // Note that any events involving state transitions (e.g., TEXT_CHANGED)
                // will reset the counter even if we're transitioning to the same state.
                [VALIDATE_FORMULA_AFTER_MS]: {
                    target: 'validating',
                },
            },

            on: eventsAfterSetup,
        },
        validating: {
            invoke: {
                src: validateFormulaString,
                onDone: [
                    // Note that this is not executed if we've navigated out of the validating
                    // state before validateFormulaString finishes.
                    {
                        actions: [
                            assign((_, event) => ({
                                parsedFormula: event.data.isValid
                                    ? event.data.parsedFormula
                                    : undefined,
                                validationError: event.data.isValid ? '' : event.data.message,
                            })),
                            (context, event) => {
                                if (!context.onChange) {
                                    throw new Error('onChange not set')
                                } else if (event.data.isValid) {
                                    context.onChange({
                                        formulaString: context.formulaString,
                                        isValid: true,
                                        parsedFormula: event.data.parsedFormula,
                                    })
                                } else {
                                    context.onChange({
                                        formulaString: context.formulaString,
                                        isValid: false,
                                    })
                                }
                            },
                        ] as Actions<
                            FormulaEditorContext,
                            DoneInvokeEvent<FormulaEditorValidationResult>
                        >,
                        target: 'idle',
                    },
                ],
            },
            on: eventsAfterSetup,
        },
    },
    on: {
        SET_DATA_CONNECTION_TYPE: {
            actions: [
                assign((_, event) => ({ dataConnectionType: event.dataConnectionType })),
                updateSuggestionsAndHelperValues,
            ],
        },
    },
})
