import React, { FC, useMemo } from 'react'

import { useFields } from 'data/hooks/fields'

import { Box, Text } from 'v2/ui'

import { AggregationConfiguration } from '../../../common/panels/DataPanel/AggregationConfiguration'
import { isFieldSupportedForBucketing } from '../../../utils/isFieldSupportedForBucketing'
import { isFieldSupportedForGrouping } from '../../../utils/isFieldSupportedForGrouping'

import BucketByDropdown from './BucketByDropdown'
import { GroupByDropdown } from './GroupByDropdown'
import type { DataPanelProps } from './types'

export const DonutChartDataPanel: FC<DataPanelProps> = ({ chart, onChange }) => {
    const { data: fields } = useFields({ objectId: chart.objectId })

    const currentGroupByField = useMemo(() => {
        return fields?.find((f) => f._sid === chart.groupByFieldId)
    }, [fields, chart.groupByFieldId])

    const onBucketByChange = (value?: Bucket) => {
        onChange({
            objectId: chart.objectId,
            bucketBy: value,
        })
    }

    return (
        <>
            <Box>
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="chart-groupby"
                    display="block"
                >
                    Group by
                </Text>
                <GroupByDropdown
                    id="chart-groupby"
                    chart={chart}
                    fields={fields}
                    placeholder="Select a field to group by"
                    filter={isFieldSupportedForGrouping}
                    onChange={onChange}
                />
            </Box>
            {currentGroupByField && isFieldSupportedForBucketing(currentGroupByField) && (
                <BucketByDropdown value={chart.bucketBy} onChange={onBucketByChange} />
            )}
            {chart.objectId && (
                <AggregationConfiguration
                    aggregationWidget={chart}
                    onChange={onChange}
                    label="Aggregate"
                    tooltip="Choose the value you'd like to display"
                />
            )}
        </>
    )
}
