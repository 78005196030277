import useDimension from '../utils/useDimension'

// Check if the text is truncated
const useIsTextTruncated = (element) => {
    useDimension(element)
    if (!element?.current) return false

    const style = getComputedStyle(element?.current)

    return style.overflowX === 'hidden' && element.current.offsetWidth < element.current.scrollWidth
}
export default useIsTextTruncated
