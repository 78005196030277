// @ts-strict-ignore
import React from 'react'

import V4DesignSystem from '../../../../ui/deprecated/V4DesignSystem'
import { Text } from '../../../../v2/ui'
import { Checkbox } from '../../../../v2/ui/components/Checkbox'

export function RecordDeletion({
    value,
    onChange,
}: {
    value: boolean
    onChange: (value: boolean) => void
}) {
    return (
        <Text size="small" color={V4DesignSystem.colors.gray[600]}>
            <Checkbox
                size="sm"
                isChecked={value}
                onChange={(event) => {
                    onChange(event.target.checked)
                }}
            />{' '}
            Users can delete records
        </Text>
    )
}
