import React, { FC } from 'react'

import { Radio } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Icon from '../Icon'
import Tooltip from '../Tooltip'

const colors = stackerTheme().colors

type Props = {
    allFieldsModeEnabled: boolean
    onChange: (allFieldModeEnabled: boolean) => void
}

const AllFieldsModeRadio: FC<Props> = ({ allFieldsModeEnabled, onChange }) => (
    <Radio
        style={{ paddingBottom: '5px' }}
        options={[
            {
                label: 'Show all fields',
                value: 'enabled',
                tooltip: (
                    <Tooltip
                        placement="top"
                        label="All fields from the table will be visible in the default order."
                    >
                        <Icon
                            ml={1}
                            mb="2px"
                            icon="info"
                            size="sm"
                            color={colors.userInterface.neutral[700]}
                        />
                    </Tooltip>
                ),
            },
            {
                label: 'Choose fields to display',
                value: 'disabled',
                tooltip: (
                    <Tooltip
                        placement="top"
                        label="Customize the visibility of these fields. New fields added to the table will not automatically be visible on this view. "
                    >
                        <Icon
                            ml={1}
                            mb="2px"
                            icon="info"
                            size="sm"
                            color={colors.userInterface.neutral[700]}
                        />
                    </Tooltip>
                ),
            },
        ]}
        onChange={(mode) => onChange(mode === 'enabled')}
        value={allFieldsModeEnabled === true ? 'enabled' : 'disabled'}
        size="md"
    />
)

export default AllFieldsModeRadio
