import { Aggregation, AggregationGroup, GroupKey, isAggregationType } from './types'

const findAggregationByColumnName = (columnName: string): Aggregation | undefined => {
    const keys = columnName.split('_')
    if (isAggregationType(keys[0])) {
        return {
            type: keys[0],
            ...(keys.length > 1 ? { fieldApiName: keys.slice(1).join('_') } : {}),
        }
    } else {
        return undefined
    }
}

// check mapRawResultToAggregates.unit.test.ts for examples
export const mapRawResultToAggregates = (data: any): AggregationGroup[] => {
    return data.map(mapGroupItem)
}

const mapGroupItem = (item: any) => {
    const aggregate = Object.entries(item).reduce(
        (accumulator: AggregationGroup, currentEntry) => {
            // map special props
            if (currentEntry[0] === '_others' && currentEntry[1] === true) {
                accumulator.others = true
                return accumulator
            } else if (currentEntry[0] === '_other_groups_count') {
                accumulator.otherGroupsCount = currentEntry[1] as number
                return accumulator
            } else if (currentEntry[0] === '_secondary_groups') {
                const secondaryGroupItems = currentEntry[1] as []
                accumulator.secondaryGroups = secondaryGroupItems.map(mapGroupItem)
                return accumulator
            } else if (currentEntry[0].startsWith('_')) {
                //this is for additional fields
                return accumulator
            }

            // map aggregations and group keys
            const aggregation = findAggregationByColumnName(currentEntry[0])
            if (aggregation) {
                accumulator.aggregates = [
                    ...accumulator.aggregates,
                    { ...aggregation, value: (currentEntry[1] ?? 0) as number },
                ]
            } else {
                accumulator.groupKey = {
                    fieldApiName: currentEntry[0],
                    value: currentEntry[1] as string,
                }
            }
            return accumulator
        },
        { aggregates: [], groupKey: {} as GroupKey }
    )
    return aggregate
}
