// @ts-nocheck
import React, { useMemo } from 'react'

import { filtersToValue } from 'v2/views/Dashboard/forms/FiltersForm'

import { useObject } from 'data/hooks/objects'
import {
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Input, Text } from 'v2/ui'

import { LinkFormFieldDropdown } from './form/fragments/LinkFormFieldDropdown'
import { LinkFormModeDropdown } from './form/fragments/LinkFormModeDropdown'
import { LinkFormObjectDropdown } from './form/fragments/LinkFormObjectDropdown'
import { LinkFormObjectLabel } from './form/fragments/LinkFormObjectLabel'
import { LinkFormEmptyState } from './form/LinkFormEmptyState'
import { LinkFormFieldsPicker } from './form/LinkFormFieldsPicker'
import { LinkFormLayout } from './form/LinkFormLayout'
import { LinkFormModePicker } from './form/LinkFormModePicker'
import { LinkFormSection } from './form/LinkFormSection'
import { LinkFormSectionList } from './form/LinkFormSectionList'
import { useLinkFormFields } from './hooks/useLinkForm'

/**
 *
 * @param {{ form: import('react-hook-form').UseFormMethods<LinkFormValues>, preview?: LinkPreview | null } } props
 * @returns
 */
export const LinkFormFieldsMinimal = ({ form, enableManyToMany = true }) => {
    const formFields = useLinkFormFields(form)
    const values = form.watch()

    const { object: sourceObject } = useObject(values.source)
    const { object: targetObject } = useObject(values.target)
    const sourceField = sourceObject.fields.find((f) => f._sid === formFields.sourceField.value)

    // Many-to-many links only work with strings. Technically we could allow matching a delimiter
    // separated string to a number field but we're not enabling that for now to stop scope creep
    const allowManyToMany = !sourceField || sourceField.type === 'string'

    const showEmptyState = !values.source || !values.target
    const showExpanded = !showEmptyState

    // There's an issue with re-renders
    // that causes the dropdown to close when
    // selecting a field. This ensures
    // that we only rerender the dropdown
    // when the link target object changes
    const filtersMemoized = useMemo(() => {
        // If we haven't selected a target object
        // do not show the filter dropdown
        if (!targetObject) {
            return null
        }
        return (
            <Filters
                object={targetObject}
                fields={targetObject.fields}
                value={filtersToValue(formFields.filters.value, targetObject)}
                onChange={(filters) => formFields.filters.onChange(formatFilters(filters))}
                controlled
                hideCurrentUserOption
                hideTheRecordFilter
                overhangOperators={false}
                itemStyle={{ width: 250, maxWidth: '100%' }}
                wrapItems
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetObject])

    return (
        <LinkFormLayout>
            <LinkFormSectionList>
                <LinkFormSection title="Field Name">
                    <Input
                        {...formFields.sourceFieldLinkName}
                        name="sourceFieldLinkName"
                        variant="settings"
                        placeholder="enter a name for this field"
                        style={{
                            paddingTop: 11,
                            paddingBottom: 11,
                        }}
                    />
                </LinkFormSection>
                <LinkFormSection title="Link to another record in">
                    <LinkFormObjectDropdown
                        value={formFields.target.value}
                        onChange={formFields.target.onChange}
                        object={targetObject}
                        variant="secondary"
                        name="target"
                    />
                </LinkFormSection>
                {showExpanded && (
                    <LinkFormSection title="Link records based on matching data">
                        <LinkFormFieldsPicker
                            sourceObject={sourceObject}
                            targetObject={targetObject}
                            source={
                                <LinkFormFieldDropdown
                                    value={formFields.sourceField.value}
                                    onChange={formFields.sourceField.onChange}
                                    object={sourceObject}
                                    variant="primary"
                                    name="sourceField"
                                />
                            }
                            target={
                                <LinkFormFieldDropdown
                                    value={formFields.targetField.value}
                                    onChange={formFields.targetField.onChange}
                                    object={targetObject}
                                    variant="secondary"
                                    name="targetField"
                                    limitFieldType={sourceField && sourceField.type}
                                />
                            }
                        />
                    </LinkFormSection>
                )}
                {showExpanded && (
                    <LinkFormSection title="Relationship type">
                        {enableManyToMany && (
                            <>
                                <LinkFormModePicker
                                    showDelimiter={values.mode === 'many-to-many'}
                                    modeInput={
                                        <LinkFormModeDropdown
                                            value={formFields.mode.value}
                                            onChange={formFields.mode.onChange}
                                            allowManyToMany={allowManyToMany}
                                        />
                                    }
                                    delimiterInput={
                                        <Input variant="settings" {...formFields.delimiter} />
                                    }
                                />
                                <Text mt={3}>
                                    Each{' '}
                                    <LinkFormObjectLabel variant="primary" object={sourceObject} />{' '}
                                    record can only link to{' '}
                                    {values.mode === 'one-to-many' ? 'a single' : 'multiple'}{' '}
                                    <LinkFormObjectLabel
                                        variant="secondary"
                                        object={targetObject}
                                    />{' '}
                                    {values.mode === 'one-to-many' ? 'record' : 'records'}.
                                </Text>
                                <Text mt={3}>
                                    Each{' '}
                                    <LinkFormObjectLabel
                                        variant="secondary"
                                        object={targetObject}
                                    />{' '}
                                    record can be linked to by multiple{' '}
                                    <LinkFormObjectLabel variant="primary" object={sourceObject} />{' '}
                                    records.
                                </Text>
                            </>
                        )}
                    </LinkFormSection>
                )}
                {showExpanded && (
                    <LinkFormSection
                        value={formFields.enableFilters.value}
                        onChange={formFields.enableFilters.onChange.bind(
                            null,
                            !formFields.enableFilters.value
                        )}
                        title="Limit the records which can be selected"
                    >
                        <div style={{ display: 'inline-block', width: '100%' }}>
                            {filtersMemoized}
                        </div>
                    </LinkFormSection>
                )}
            </LinkFormSectionList>
            {showEmptyState && (
                <LinkFormEmptyState>Select table to configure the link</LinkFormEmptyState>
            )}
        </LinkFormLayout>
    )
}
