import React, { ReactElement } from 'react'

import * as Sentry from '@sentry/react'
import get from 'lodash/get'

import { queryStringSuffixMap } from 'features/utils/filtersToQueryDict'
import { Icon } from 'legacy/v1/ui'

import {
    currentRecordFieldMultiOptions,
    currentRecordFieldSingleOptions,
    filterableTypes,
    filterOptionsForType,
    labelMap,
} from '../constants'

import { getCompatibleFields } from './getCompatibleFields'

// Converts the filters returned by the Filters component to the format we want them in for the backend.
export function formatFilters(filters: Filter[]) {
    return filters.map((filter: Filter) => {
        const {
            options: { option },
        } = filter

        if (option && !queryStringSuffixMap.hasOwnProperty(option)) {
            Sentry.captureMessage(`Unexpected filter operation encountered ${option}`)
        }

        const suffix = queryStringSuffixMap[option]
        const operation = suffix ? suffix.replace(/^__/, '') : null // Remove leading "__" and convert "" to null.

        return {
            target: filter.field?.api_name,
            operation: operation || null,
            option: option,
            value: filter.options.value || '',
            objectId: filter.field?.options?.lookup_target,
        }
    })
}

export function getFilterOptionsForType(
    field: Partial<FieldDto>,
    hideCurrentUserOption: boolean,
    userObject: ObjectDto,
    userProfiles: ObjectDto[] | undefined,
    showRelativeDateFilters: boolean,
    contextRecordObject?: ObjectDto
): (keyof typeof labelMap)[] {
    if (!field) return []

    // @ts-expect-error filterOptionsForType is missing field types: address, copy, file, formula
    let options = [...filterOptionsForType[field.type]]

    if (showRelativeDateFilters && ['date', 'datetime'].indexOf(field.type as string) >= 0) {
        options.push('withinNext')
        options.push('withinLast')
    }

    // If this is a relationship field that looks up
    // from our designated user table, then show the
    // 'is current user' filter options

    const allProfiles = userProfiles ? [userObject, ...userProfiles] : [userObject]

    if (
        !hideCurrentUserOption &&
        allProfiles.find((profile) => get(field, 'options.lookup_target') === profile?._sid)
    ) {
        if (field.type === 'multi_lookup') {
            options = options.concat(['containsCurrentUser', 'doesNotContainCurrentUser'])
        } else {
            options = options.concat(['isCurrentUser', 'isNotCurrentUser'])
        }
    }

    const compatibleFields = contextRecordObject
        ? getCompatibleFields(contextRecordObject, field)
        : []
    if (compatibleFields.length > 0) {
        options = options.concat(
            field.type === 'multi_lookup'
                ? currentRecordFieldMultiOptions
                : currentRecordFieldSingleOptions
        )
    }

    let profiles: ObjectDto[] = []
    if (userProfiles?.find((profile) => get(field, 'options.lookup_target') === profile._sid)) {
        profiles = [...userProfiles]
    } else {
        profiles = allProfiles
    }

    // If there are fields on the user object which are compatible
    // with the selected field, then show additional options
    if (
        !hideCurrentUserOption &&
        profiles
            .map((object) => getCompatibleFields(object, field))
            .some((compatibleFields) => compatibleFields.length > 0)
    ) {
        if (field.type === 'multi_lookup') {
            options = options.concat(['containsCurrentUserField', 'doesNotContainCurrentUserField'])
        } else {
            options = options.concat(['isCurrentUserField', 'isNotCurrentUserField'])
        }
    }

    // Don't want to show isEmpty options on "The Record" filter
    if (field.api_name === '_sid') {
        options = options.filter((x) => x !== 'isEmpty' && x !== 'isNotEmpty')
    }

    return options
}

export function getFilterSourceOptions(
    fields: FieldDto[],
    config: { isCreate: boolean | undefined; hideTheRecordFilter: boolean; showRoleFilter: boolean }
) {
    const { isCreate, hideTheRecordFilter, showRoleFilter } = config
    const options: { type: FieldType; value: string; label: string | ReactElement }[] = fields
        .filter(
            (option) =>
                // Only show types which can be handled correctly.
                filterableTypes.includes(option.type) &&
                (!isCreate || !option?.connection_options?.read_only)
        )
        .map(({ type, label, _sid }) => ({
            type,
            label,
            value: _sid,
        }))

    if (!hideTheRecordFilter) {
        options.unshift({
            type: 'lookup',
            label: '[The Record]',
            value: '_sid',
        })
    }
    if (showRoleFilter) {
        options.unshift({
            type: 'lookup',
            label: (
                <>
                    <Icon icon="user-lock" />
                    User Role
                </>
            ),
            value: 'user_role',
        })
    }

    return options
}
