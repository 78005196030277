import React from 'react'

import { useAppContext } from 'app/AppContext'

import { Button, LoadingScreen } from 'v2/ui'

import { useSupertokensSsoWithRedirect } from './useSupertokensSsoWithRedirect'

export const SupertokensSSOLoginScreen = () => {
    const { workspaceAccount } = useAppContext()
    const { isRedirecting, startFlow, providers, isLoading } =
        useSupertokensSsoWithRedirect(workspaceAccount)
    return (
        <>
            <LoadingScreen isLoading={isLoading}>
                {providers?.map((x) => (
                    <Button
                        icon="key"
                        key={x._sid}
                        onClick={() => startFlow(x.third_party_id)}
                        variant="Secondary"
                        buttonSize="large"
                        isLoading={isRedirecting}
                    >
                        Continue with {x.name}
                    </Button>
                ))}
            </LoadingScreen>
        </>
    )
}
