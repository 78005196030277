import React from 'react'

import { Box } from 'v2/ui'
import Breadcrumbs from 'v2/ui/components/Breadcrumbs'

import { useBreadcrumbItems } from './utils/useBreadcrumbItems'

type AppBreadCrumbsProps = {
    breadCrumbsStyleOverride: { [key: string]: any }
    position: 'absolute' | 'relative'
}

const AppBreadcrumbs = (props: AppBreadCrumbsProps) => {
    const { showBackButton, items } = useBreadcrumbItems()

    if (!items.length || items.length === 1) {
        return null
    }

    return (
        <Box w="100%" top="0" left="0" zIndex={10} {...props}>
            <Breadcrumbs
                maxWidth="1000px"
                px={'10px'}
                margin={'4px auto'}
                linkVariant="breadcrumb"
                showBackButton={showBackButton}
                items={items}
                marginBottom={['-20px', null, null, '-30px']}
                {...props.breadCrumbsStyleOverride}
            />
        </Box>
    )
}

export default AppBreadcrumbs
