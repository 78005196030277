import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type PageProps = { size?: number; color?: string; doNotScale?: boolean }
const Page: React.FC<PageProps> = ({
    size = 16,
    color = colors.userInterface.accent[1000],
    doNotScale,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={doNotScale ? '' : '0 0 16 16'}
            fill="none"
        >
            <path d="m9 0 5 5H9V0z" fill="#fff" />
            <path
                opacity=".4"
                d="M9 0v5h5v9c0 1.125-.906 2-2 2H4c-1.125 0-2-.875-2-2V2c0-1.094.875-2 2-2h5z"
                fill={color}
            />
        </svg>
    )
}

export default Page
