import React, { FC } from 'react'

import { FiltersPanel as AggregationFiltersPanel } from '../../common/panels/FiltersPanel'

type FiltersPanelProps = {
    chart: ChartBlockAttributes
    onChange: (
        chart: Pick<ChartBlockAttributes, 'objectId'> & Partial<ChartBlockAttributes>
    ) => void
}

export const FiltersPanel: FC<FiltersPanelProps> = ({ chart, onChange }) => (
    <AggregationFiltersPanel
        aggregationWidget={chart}
        onChange={onChange}
        emptyStateTitle="Add filters to your charts"
    />
)

export default FiltersPanel
