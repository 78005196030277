import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { AppUserContext, Rights } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { withRoles } from 'data/wrappers/WithRoles'
import { withStack } from 'data/wrappers/WithStacks'
import { withUserTable } from 'data/wrappers/WithUserTable'
import RolePages from 'features/admin/settings/permissions/RolePages'
import { SettingsContent } from 'features/admin/settings/ui/SettingsFrame'
import Attribute from 'features/records/components/Attribute'
import {
    Container,
    Divider,
    FormLabel,
    Heading,
    Icon,
    Input,
    Section,
    Text,
} from 'legacy/v1/ui/index'

import { Button, ConfirmationModal, Icon as V2Icon } from 'v2/ui'

import { Checkbox } from 'ui/components/Checkbox'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { useAppSettings } from '../../workspace/AdminSideTray/hooks/useAppSettings'

export const RolePage = ({
    roles,
    stackOptions,
    userTable,
    onChangeStack,
    stack,
    onChangeRole,
    removeRole,
    history,
    role,
    resetActiveRole,
}) => {
    const defaultState = {
        error: false,
        processing: false,
        role: {},
        newRoleLabel: '',
        newRoleValue: '',
        newRoleDefault: '',
        duplicateNameError: false,
        duplicateValueError: false,
        pagesSetup: false,
        permittedPages: [],
        newRights: null,
        options: {},
        deleteModalOpen: false,
        confirmModalProcessing: false,
        confirmModalError: false,
    }
    const [state, setState] = useState(defaultState)
    const { previewAsRole } = useContext(AppUserContext)
    const { open: openAppSettings, close: closeAppSettings } = useAppSettings()

    const roleId = role?._sid
    const workspaceApp = stackOptions?.workspace_app
    const permittedPages = get(role, 'permitted_pages', []).sort()
    const defaultId = get(stackOptions, 'roles__default_user_role')

    useEffect(() => {
        setState((state) => {
            const newState = { ...state }
            if (role) {
                newState.role = role

                if (!state.newRoleLabel) {
                    newState.newRoleLabel = role.label
                }

                if (!state.newRoleLabel) {
                    newState.newRoleValue = role.type_value
                }

                if (!state.newRoleDefault) {
                    newState.newRoleDefault = defaultId
                }

                if (!state.newRights) {
                    newState.newRights = role.options.rights || []
                }

                if (!state.pagesSetup && roles.length) {
                    newState.pagesSetup = true
                    newState.permittedPages = get(role, 'permitted_pages', [])
                }
            }

            return newState
        })
    }, [defaultId, role, roles])

    const isDisabled = () => {
        const { newRoleLabel, newRoleValue, role, newRoleDefault, newRights } = state
        if (!role) return true
        const noChange =
            role.label === newRoleLabel &&
            role.type_value === newRoleValue &&
            newRoleDefault === defaultId &&
            isEqual(permittedPages, state.permittedPages.sort()) &&
            isEqual(newRights || [], role.options.rights || []) &&
            isEmpty(state.options)
        const hasValues = newRoleLabel && newRoleDefault && (newRoleValue || workspaceApp)
        const isDefault = newRoleDefault === roleId && newRoleLabel

        return noChange || !(hasValues || isDefault) || state.processing
    }

    const roleFieldId = get(stackOptions, 'data_mapping.role_field')
    const allPages =
        workspaceApp &&
        (state.options?.all_pages !== undefined
            ? state.options?.all_pages
            : role?.options?.all_pages)
    const roleField = () => {
        if (!userTable || !userTable.fields.length) return
        return userTable.fields.find((field) => field._sid === roleFieldId)
    }

    const isNameDuplicate = (name) => {
        return roles.some((role) => role.label === name && role._sid !== roleId)
    }

    const isValueDuplicate = (value) => {
        return roles.some((role) => role.type_value === value && role._sid !== roleId)
    }

    const saveUpdates = () => {
        setState((state) => ({
            ...state,
            error: false,
            duplicateNameError: false,
            duplicateValueError: false,
            processing: true,
        }))

        const { role } = state
        if (!role) return
        // updating role values
        if (
            role.label !== state.newRoleLabel ||
            role.type_value != state.newRoleValue ||
            !isEqual(permittedPages, state.permittedPages.sort()) ||
            !isEqual(state.newRights || [], role?.options.rights || []) ||
            !isEmpty(state.options)
        ) {
            if (isNameDuplicate(state.newRoleLabel)) {
                setState((state) => ({
                    ...state,
                    duplicateNameError: true,
                    processing: false,
                }))
                return
            }
            if (state.newRoleValue && isValueDuplicate(state.newRoleValue)) {
                setState((state) => ({
                    ...state,
                    duplicateValueError: true,
                    processing: false,
                }))
                return
            }

            const newOptions = { ...state.options, rights: state.newRights }
            onChangeRole({
                id: get(role, '_sid'),
                patch: {
                    label: state.newRoleLabel,
                    type_value: state.newRoleValue,
                    permitted_pages: state.permittedPages,
                    options: { ...role.options, ...newOptions },
                },
            })
                .then(invalidateObjects)
                .catch((e) => {
                    Sentry.captureMessage(`Error updating role ${get(e, 'message')}`)
                    setState((state) => ({
                        ...state,
                        error: true,
                        processing: false,
                    }))
                    return
                })
        }

        if (state.newRoleDefault !== defaultId) {
            onChangeStack(stack._sid, {
                options: {
                    ...stackOptions,
                    roles__default_user_role: state.newRoleDefault,
                },
            }).catch((e) => {
                Sentry.captureMessage(`Error updating role ${get(e, 'message')}`)
                setState((state) => ({
                    ...state,
                    error: true,
                    processing: false,
                }))
                return
            })
        }

        setState((state) => ({
            ...state,
            error: false,
            processing: false,
        }))
    }

    const closeDeleteModal = () => {
        setState((state) => ({
            ...state,
            deleteModalOpen: false,
            confirmModalProcessing: false,
            confirmModalError: false,
        }))
    }

    const deleteConfirmModal = () => (
        <ConfirmationModal
            isOpen={state.deleteModalOpen}
            title="Confirm Delete"
            onClose={closeDeleteModal}
            onConfirm={async () => {
                setState((state) => ({ ...state, confirmModalProcessing: true }))
                const { role } = state
                try {
                    await removeRole(get(role, '_sid'))
                    resetActiveRole()
                } catch (e) {
                    Sentry.captureMessage(
                        `Error removing role ${get(role, '_sid')}. Error message: ${get(
                            e,
                            'message'
                        )}`
                    )
                    setState((state) => ({
                        ...state,
                        confirmModalError: true,
                        confirmModalProcessing: false,
                    }))
                }
            }}
            inProgress={state.confirmModalProcessing}
            details="Are you sure that you wish to delete this role? This action cannot be undone"
            error={
                state.confirmModalError
                    ? 'Sorry, there was an error deleting this role. Please try again.'
                    : undefined
            }
        />
    )

    const showRolePages = () => (
        <RolePages>
            {({ pages }) => (
                <>
                    <Label>Pages</Label>
                    <div
                        style={{
                            margin: '5px -5px',
                            padding: '5px 10px',
                            display: 'flex',
                            alignItems: 'center',
                            background: V4DesignSystem.colors.gray[10],
                        }}
                    >
                        <V2Icon icon="world" />
                        <div style={{ width: 10 }} />
                        <div>
                            {pages.find((page) => !page.permitted_by_all_roles) ? (
                                <>
                                    These pages have role sharing enabled. <br />
                                    Enable role sharing on other pages
                                </>
                            ) : (
                                <>
                                    There are no pages with role sharing enabled. <br /> Enable role
                                    sharing for pages
                                </>
                            )}{' '}
                            <a
                                href=""
                                onClick={(event) => {
                                    openAppSettings({ page: { name: 'navigation' } })
                                    event.preventDefault()
                                }}
                            >
                                from the Navigation
                            </a>
                        </div>
                    </div>
                    <FlexWrapper style={{ flex: 1 }}>
                        {workspaceApp && (
                            <CheckboxItem style={{ minWidth: 250 }}>
                                <Checkbox
                                    data-testid="role-page-checkbox"
                                    checked={allPages}
                                    id="all pages"
                                    onCheckedChange={(checked) => {
                                        setState((state) => ({
                                            ...state,
                                            options: { ...state.options, all_pages: checked },
                                        }))
                                    }}
                                />
                                <FormLabel
                                    style={{ marginLeft: '10px', textAlign: 'left' }}
                                    htmlFor="all pages"
                                >
                                    All Pages
                                </FormLabel>
                            </CheckboxItem>
                        )}
                        {pages
                            .filter((page) => !page.permitted_by_all_roles)
                            .map((page) => (
                                <CheckboxItem style={{ minWidth: 250 }} key={page._sid}>
                                    <Checkbox
                                        data-testid="role-page-checkbox"
                                        disabled={allPages || page.permitted_by_all_roles}
                                        checked={
                                            allPages ||
                                            page.permitted_by_all_roles ||
                                            state.permittedPages.includes(page._sid)
                                        }
                                        id={`page-${page._sid}`}
                                        onCheckedChange={(checked) => {
                                            let permittedPages = state.permittedPages

                                            if (!checked) {
                                                permittedPages = state.permittedPages.filter(
                                                    (p) => p !== page._sid
                                                )
                                            } else {
                                                permittedPages = [...permittedPages, page._sid]
                                            }

                                            setState((state) => ({ ...state, permittedPages }))
                                        }}
                                    />
                                    <FormLabel
                                        style={{ marginLeft: '10px', textAlign: 'left' }}
                                        htmlFor={`page-${page._sid}`}
                                    >
                                        {page.permitted_by_all_roles && (
                                            <V2Icon
                                                icon="lock"
                                                display="inline"
                                                labelPlacement="bottom"
                                                size="sm"
                                                mr={2}
                                                label="This page is visible to all roles"
                                            />
                                        )}
                                        {page.name}
                                    </FormLabel>
                                </CheckboxItem>
                            ))}
                    </FlexWrapper>
                </>
            )}
        </RolePages>
    )

    if (!state.role || !role) return null

    return (
        <>
            <Content>
                <StyledSection>
                    <HeadingContainer>
                        <StyledIcon icon="user-lock" />
                        <Heading style={{ paddingTop: 0, paddingBottom: 0 }} size="medium">
                            {get(state.role, 'label')}
                        </Heading>
                    </HeadingContainer>
                    {workspaceApp && (
                        <Button
                            variant="adminSecondary"
                            buttonSize="sm"
                            icon="play"
                            mr={2}
                            onClick={() => {
                                previewAsRole(state.role?._sid)
                                history.push(getUrl(Urls.Home))
                                closeAppSettings()
                            }}
                        >
                            Test as {state.role?.label}
                        </Button>
                    )}
                </StyledSection>
                <Section>
                    <FlexWrapper>
                        <Label htmlFor="role-name">Name</Label>
                        <Input
                            value={state.newRoleLabel}
                            disabled={role?.options?.system_role}
                            onChange={(event) => {
                                const target = event.target.value
                                setState((state) => ({
                                    ...state,
                                    newRoleLabel: target,
                                }))
                            }}
                            id="role-name"
                            style={{
                                flex: 1,
                                background: role?.options?.system_role ? '#ddd' : null,
                            }}
                        />
                    </FlexWrapper>
                    {!workspaceApp && (
                        <FlexWrapper>
                            <Label htmlFor="role-value">Value</Label>
                            <Attribute
                                id="role-value"
                                editable
                                onChange={(value) =>
                                    setState((state) => ({ ...state, newRoleValue: value }))
                                }
                                field={roleField()}
                                style={{ flex: 1 }}
                            >
                                {state.newRoleValue}
                            </Attribute>
                        </FlexWrapper>
                    )}
                    <Divider />
                    {showRolePages()}
                    {workspaceApp && (
                        <>
                            <Divider />
                            <FlexWrapper>
                                <Label htmlFor="all-data">Can read/modify all data</Label>
                                <Checkbox
                                    id="all-data"
                                    checked={
                                        !!(state.newRights || []).find(
                                            (right) => right === Rights.AllDataAccess
                                        )
                                    }
                                    onCheckedChange={(checked) => {
                                        setState((state) => {
                                            let newRights = (state.newRights || []).filter(
                                                (right) => right !== Rights.AllDataAccess
                                            )
                                            if (checked) {
                                                newRights.push(Rights.AllDataAccess)
                                            }
                                            return {
                                                ...state,
                                                newRights: newRights,
                                            }
                                        })
                                    }}
                                />
                            </FlexWrapper>
                        </>
                    )}
                    <Divider />

                    {!workspaceApp && (
                        <FlexWrapper>
                            <Label htmlFor="role-default">Make default?</Label>
                            <Checkbox
                                id="role-default"
                                checked={roleId === state.newRoleDefault}
                                onCheckedChange={() => {
                                    if (roleId !== defaultId) {
                                        setState((state) => ({
                                            ...state,
                                            newRoleDefault:
                                                roleId === state.newRoleDefault
                                                    ? defaultId
                                                    : roleId,
                                        }))
                                    }
                                }}
                            />
                        </FlexWrapper>
                    )}
                    <Button
                        variant="adminPrimary"
                        buttonSize="sm"
                        isDisabled={isDisabled()}
                        style={{ width: '200px', alignSelf: 'center' }}
                        onClick={saveUpdates}
                    >
                        Save Changes
                    </Button>
                    {!role?.options?.system_role && defaultId !== roleId && (
                        <Button
                            variant="link"
                            color="gray.400"
                            buttonSize="sm"
                            style={{
                                width: '200px',
                                alignSelf: 'center',
                                textDecoration: 'underline',
                                marginTop: '10px',
                            }}
                            onClick={() =>
                                setState((state) => ({ ...state, deleteModalOpen: true }))
                            }
                        >
                            Delete Role
                        </Button>
                    )}

                    {state.error && (
                        <Text style={{ color: '#e74c3c' }}>
                            Sorry, there was an error updating this new role. Please try again.
                        </Text>
                    )}
                    {state.duplicateNameError && (
                        <Text style={{ color: '#e74c3c' }}>
                            A role already exists with this name. Please choose another name.
                        </Text>
                    )}
                    {state.duplicateValueError && (
                        <Text style={{ color: '#e74c3c' }}>
                            A role already exists with this value. Please choose another value.
                        </Text>
                    )}
                </Section>
                {deleteConfirmModal()}
            </Content>
            {/* <RolePermissions match={match} role={state.role} /> */}
        </>
    )
}

const Content = styled(SettingsContent)`
    flex-direction: column;
`

const StyledSection = styled(Section)`
    flex-direction: row;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    flex-wrap: wrap;
`

const HeadingContainer = styled(Container)`
    align-items: center;
    flex: 1;
`

const StyledIcon = styled(Icon)`
    font-weight: 400;
    font-size: 25px;
    margin-right: 15px;
`

const FlexWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const Label = styled(FormLabel)`
    text-align: left;
    margin-right: 20px;
    width: 200px;
    font-weight: bold;

    @media (max-width: 420px) {
        width: 100%;
    }
`

const CheckboxItem = styled('div')`
    flex: 0 182px;
    display: flex;
    align-items: center;
`
RolePage.propTypes = {
    stack: PropTypes.object.isRequired,
    onChangeStack: PropTypes.func.isRequired,
    userTable: PropTypes.object.isRequired,
    stackOptions: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    onChangeRole: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
}

export default withStack(withUserTable(withRoles(withRouter(RolePage))))
