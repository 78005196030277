export type HelpLinks = {
    [key: string]: string
}

const HELP_LINKS: HelpLinks = {
    USER_TABLE: 'https://docs.stacker.app/user-login/untitled#we-need-a-user-table',
    API_KEY:
        'https://docs.stacker.app/guides/getting-started-with-stacker/how-to-find-my-airtable-api-key',
    SHARE_BASE_LINK:
        'https://docs.stacker.app/guides/getting-started-with-stacker/how-to-find-your-airtable-share-base-link',
    PORTAL_NAME:
        'https://docs.stacker.app/guides/getting-started-with-stacker/what-is-a-good-app-name',
    CREATED_BY_FIELD: 'https://docs.stacker.app/pages/created-by-field',
    SHARING_LINKS: 'https://docs.stacker.app/user-login/user-registration-and-login/sharing-links',
    SUPPORT_ACCESS: 'https://docs.stacker.app/managing-your-account/support-access',
    ENABLING_DATA: 'https://docs.stacker.app/connecting-to-your-data/enabling-and-disabling-data',
    USER_ROLES: 'https://support.stackerhq.com/hc/en-us/articles/4409591860243-Set-up-User-Roles',
    MULTIPLE_PORTALS: 'https://docs.stacker.app/managing-your-account/managing-multiple-apps',
    CUSTOM_DOMAINS: 'https://docs.stacker.app/setting-up-your-app/custom-domains',
    CUSTOM_SCRIPTS: 'https://docs.stacker.app/features/custom-scripts',
    CLIENT_LOGOS: 'https://docs.stacker.app/features/client-logos',
    CUSTOM_CSS: 'https://docs.stacker.app/features/custom-css',
    GSHEETS_SHEET_REQUIREMENTS:
        'https://docs.stacker.app/sheets-troubleshooting/onboarding/requirements',
    THIRD_PARTY_COOKIES:
        'https://docs.stacker.app/sheets-troubleshooting/onboarding/third-party-cookies-are-disabled-error',
    COLLABORATION:
        'https://help.stackerhq.com/editing-your-app/editing-layout/detail-pages/tabs#enabling-activity',
    PASSWORDLESS_LOGIN:
        'https://docs.stacker.app/user-login/user-registration-and-login/passwordless-login',
    CREATE_PERMS_RELAXED_MODE:
        'https://docs.stacker.app/controlling-access/advanced-record-creation-permissions',
    FAST_SYNC: 'https://help.stackerhq.com/fundamentals/adding-data-source/cache-frequency',
    CREATE_FORMS: 'https://docs.stacker.app/pages/create-forms',
    PERMISSIONS: 'https://www.stackerhq.com/help-article/introduction-to-permissions',
    CUSTOMER_ACCESS: 'https://support.stackerhq.com/hc/en-us/articles/4409758155539',
    MIGRATION_V3:
        'https://support.stackerhq.com/hc/en-us/articles/4410353520403-Updating-from-old-version-of-Stacker-to-the-new-version-of-Stacker',
    FORMULA_FIELDS: 'https://support.stackerhq.com/hc/en-us/articles/4415076803731',
    INLINE_FILTERS:
        ' https://support.stackerhq.com/hc/en-us/articles/4415788506643-List-Inline-Filters',
    CUSTOMER_SUCCESS_360_DATA_MEETING: 'https://stacker.chilipiper.com/router/data_360',
    UPDATE_AIRTABLE_API_KEY_AND_PASSWORD:
        'https://support.stackerhq.com/hc/en-us/articles/11720130321939-Update-Airtable-API-Key-and-password',
    AIRTABLE_OAUTH_LEARN_MORE:
        'https://support.stackerhq.com/hc/en-us/articles/14372703347219#airtable-data-connection-upgrade-explained-0-0',
    INTERCOM_LEARN_MORE: 'https://support.stackerhq.com/hc/en-us/articles/16299184422547',
    FULLSTORY_LEARN_MORE: 'https://support.stackerhq.com/hc/en-us/articles/16299187152275',
    SELF_SERVE_CUSTOM_SCRIPTS: 'https://support.stackerhq.com/hc/en-us/articles/16680412291987',
    POSTGRES_CONNECTION_LEARN_MORE:
        'https://support.stackerhq.com/hc/en-us/articles/4410127291283-Postgres',
}

export default HELP_LINKS
