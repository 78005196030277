import React from 'react'

import styled from '@emotion/styled'

import instructionsImageUrl from './instructions.png'

export const AirtableUpgradeInstructionsImage = (): React.ReactElement => (
    <Image src={instructionsImageUrl} />
)

const Image = styled('img')`
    width: 379px;
    height: 226px;
`
