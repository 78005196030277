import { getCurrentStack } from 'app/AppContextStore'

export function isRightGranted(rights, value) {
    let matchAnyDescendants = false
    // The right requested will be in a format like this:
    //  admin.dataconnections.view
    //
    // Our matching logied allows for matching hierarchically.For instance
    // the caller asks for admin.dataconnections.view and the user has been
    // granded admin.dataconnections, then we return true.
    // Additionally we support checking for "any" child rights being granted.
    //
    // For instance, checking for "admin.*" will return true if the user has
    // admin.dataconnections.view granted as a right.
    while (value) {
        // If this right is explicitly granted, or we're matchiny any decendants
        // and a descendant right has been granted...
        if (
            rights.includes('*') ||
            rights.includes(value) ||
            (matchAnyDescendants && rights.find((right) => right.startsWith(value + '.')))
        ) {
            return true

            // otherwise, if the right we're checking has a period,
            // split it and check the next level up. For instance
            // admin.dataconnections.view will become admin.dataconnections
        } else if (value.includes('.')) {
            matchAnyDescendants = false

            // If we're ending with an asterisk, we want to go into manyAnyDescendants mode
            if (value.endsWith('.*')) {
                matchAnyDescendants = true
            }
            value = value.substring(0, value.indexOf('.'))
        } else {
            return false
        }
    }

    return false
}

export const getPreviewingRoleId = () => {
    const stack = getCurrentStack()

    if (!stack) return null

    const id = sessionStorage.getItem(`${stack._sid}_previewing_as_role`)
    if (id && id !== 'null') {
        return id
    }

    return null
}

export const getPreviousPreviewingRoleId = () => {
    const stack = getCurrentStack()

    if (!stack) return null

    const id = sessionStorage.getItem(`${stack._sid}_previous_previewing_as_role`)
    if (id && id !== 'null') {
        return id
    }

    return null
}

export const getPreviewingRoleIdFromLocalStorage = () => {
    const stack = getCurrentStack()

    if (!stack) return null
    const id = localStorage.getItem(`${stack._sid}_previewing_as_role`)
    if (id && id !== 'null') {
        return id
    }

    return null
}

export const getPreviewingUser = () => {
    const stack = getCurrentStack()

    if (!stack) return null

    const value = sessionStorage.getItem(`${stack._sid}_previewing_as_user`)
    if (value) return JSON.parse(value)

    return null
}

export const getPreviousPreviewingUser = () => {
    const stack = getCurrentStack()

    if (!stack) return null

    const value = sessionStorage.getItem(`${stack._sid}_previous_previewing_as_user`)
    if (value) return JSON.parse(value)

    return null
}

export const getPreviewingUserFromLocalStorage = () => {
    const stack = getCurrentStack()

    if (!stack) return null
    const value = localStorage.getItem(`${stack._sid}_previewing_as_user`)
    if (value) return JSON.parse(value)

    return null
}

export const setPreviousPreviewingUser = (user) => {
    const stack = getCurrentStack()

    if (!stack) return null

    const value = user ? JSON.stringify(user) : ''
    sessionStorage.setItem(`${stack._sid}_previous_previewing_as_user`, value)
}

export const setPreviewingUser = (user) => {
    const stack = getCurrentStack()

    if (!stack) return null

    const value = user ? JSON.stringify(user) : ''
    sessionStorage.setItem(`${stack._sid}_previewing_as_user`, value)
}

export const setPreviewingUserToLocalStorage = (user) => {
    const stack = getCurrentStack()

    if (!stack) return null

    const value = user ? JSON.stringify(user) : ''

    localStorage.setItem(`${stack._sid}_previewing_as_user`, value)
}

export const setPreviousPreviewingRoleId = (roleId) => {
    const stack = getCurrentStack()

    if (!stack) return null
    sessionStorage.setItem(`${stack._sid}_previous_previewing_as_role`, roleId)
}

export const setPreviewingRoleId = (roleId) => {
    const stack = getCurrentStack()

    if (!stack) return null
    sessionStorage.setItem(`${stack._sid}_previewing_as_role`, roleId)
}

export const setPreviewingRoleIdToLocalStorage = (roleId) => {
    const stack = getCurrentStack()

    if (!stack) return null
    localStorage.setItem(`${stack._sid}_previewing_as_role`, roleId)
}

export const syncPreviewingStatusFromLocalStorage = () => {
    // If we don't have a previewing_as_role and previewing_as_user in sessionStorage (for this tab)
    // but we do in local storage, pull that value in now so we continue the expected
    // behavior when the user clicks a link that opens in a new tab.
    let localStorageValue = getPreviewingRoleIdFromLocalStorage()
    if (!Boolean(getPreviewingRoleId()) && localStorageValue) {
        setPreviewingRoleId(localStorageValue)
    }

    localStorageValue = getPreviewingUserFromLocalStorage()
    if (!Boolean(getPreviewingUser()) && localStorageValue) {
        setPreviewingUser(localStorageValue)
    }
}

export const syncPreviewingStatusToLocalStorage = () => {
    if (getPreviewingRoleId()) {
        setPreviewingRoleIdToLocalStorage(getPreviewingRoleId())
    }
    if (getPreviewingUser()) {
        setPreviewingUserToLocalStorage(getPreviewingUser())
    }
}

export const isPreviewing = () => {
    return getPreviewingUser() || getPreviewingRoleId()
}
