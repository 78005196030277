import { useEffect, useRef, useState } from 'react'

import { fetchWithAuth } from '../utils/utils'

export default class JobApi {
    // The full declaration is needed to have an accurate TS type definition.
    // This should be removed once this file is converted to TS.
    // eslint-disable-next-line unused-imports/no-unused-vars
    static waitForJob(jobId, { progress = (job) => undefined, interval = 1, timeout = 300 } = {}) {
        // Polls the Job API waiting for the job to be complete.
        // Returns a promise which resolves or rejects when the job completes.
        // Calls the progress callback with the response data on each poll.

        // resolve, reject and progress are all called with a job status object.
        // The status can be one of these from the API: pending, succeeded, failed
        // Or one of these from this wrapper: timeout, error

        const pollUrl = `jobs/${jobId}/`

        let pollInterval
        let timer
        let rejectResult

        const cleanup = () => {
            clearInterval(pollInterval)
            if (timer) {
                clearTimeout(timer)
            }
        }

        const resultPromise = new Promise((resolve, reject) => {
            rejectResult = reject

            pollInterval = setInterval(() => {
                fetchWithAuth(pollUrl, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.status >= 400) {
                            // Received an error response
                            response
                                .json()
                                .then((json) => {
                                    // But it was a JSON error
                                    cleanup()
                                    reject({ status: 'error', error: json })
                                })
                                .catch(() => {
                                    // Some other non-JSON error
                                    cleanup()
                                    reject({ status: 'error', error: undefined })
                                })
                        } else {
                            // The response is good, check if the job is still pending, or resolved.
                            response
                                .json()
                                .then((job) => {
                                    if (job?.status === 'pending') {
                                        // If the task is still pending, call the progress callback.
                                        progress(job)
                                    } else if (job?.status === 'succeeded') {
                                        // The job completed successfully, so this promise resolves.
                                        cleanup()
                                        resolve(job)
                                    } else if (job?.status === 'failed') {
                                        // The job failed on the server, so reject with this info.
                                        cleanup()
                                        reject(job)
                                    } else {
                                        // Unknown status value
                                        cleanup()
                                        reject({
                                            status: 'error',
                                            error: `Unknown status value ${job?.status}`,
                                        })
                                    }
                                })
                                .catch(() => {
                                    // The response should be JSON
                                    cleanup()
                                    reject({ status: 'error', error: undefined })
                                })
                        }
                    })
                    .catch((error) => {
                        // Something wrong with the actual request
                        cleanup()
                        reject({ status: 'error', error: error })
                    })
            }, interval * 1000)

            // If the interval runs too long, use this timeout to kill it.
            if (timeout > 0) {
                timer = setTimeout(() => {
                    clearInterval(pollInterval)
                    console.log(`Timed out waiting for ${jobId} (${timeout}s)`)
                    reject({ status: 'timeout' })
                }, timeout * 1000)
            }
        })

        // Return an object so the callers have a chance to clean up these timers if they need to
        // (for example on component dismount)
        return {
            result: resultPromise,
            cancel: () => {
                cleanup()
                rejectResult({ status: 'cancelled' })
            },
        }
    }
}

// A custom hook for managing the interaction with waitForJob,
// for example to make sure its timers are cleared on component dismount.
// We need the keep the arguments for correct TS definition. To be removed once this file has been converted to TS.
// eslint-disable-next-line unused-imports/no-unused-vars
export function useJobPoller(jobId, progress = (job) => undefined, timeout = 300) {
    // This stores the result object from waitForJob
    const jobPoller = useRef()
    // This state information is returned to the caller
    const [pollState, setPollState] = useState({
        isRunning: false,
        isComplete: false,
        isSuccessful: false,
        job: undefined,
    })

    // Once we have a Job ID, start polling
    useEffect(() => {
        if (jobId) {
            // If the Job ID has changed but we already have a poller running,
            // then cancel the old one and start the new one.
            if (jobPoller.current) {
                jobPoller.current.cancel()
            }
            jobPoller.current = JobApi.waitForJob(jobId, {
                progress,
                timeout,
            })

            setPollState({ isRunning: true })
            jobPoller.current.result
                .then((job) => {
                    setPollState({
                        isRunning: false,
                        isComplete: true,
                        isSuccessful: true,
                        job: job,
                    })
                })
                .catch((err) => {
                    // If the poller stopped because it was cancelled from this hook, then ignore.
                    if (err?.status === 'cancelled') {
                        return
                    }

                    // If the error is a failed job, we can return it.
                    const job = err?._object_id === 'job' ? err : undefined
                    setPollState({
                        isRunning: false,
                        isComplete: true,
                        isSuccessful: false,
                        job: job,
                    })
                })
        } else {
            // If there is no Job selected yet, just ignore.
            // If an existing job has been un-selected, then cancel its polling.
            if (jobPoller.current) {
                jobPoller.current.cancel()
                jobPoller.current = undefined
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId])

    // If the component unmounts, then clear the polling.
    useEffect(() => {
        return () => {
            if (jobPoller.current) {
                jobPoller.current.cancel()
            }
        }
    }, [])

    return pollState
}
