import React from 'react'

import { useAppContext } from 'app/AppContext'
import { useAppSharingSettingsModal } from 'features/users/ManageAppSharingSettingsModal'
import useTrack from 'utils/useTrack'

import { TrayButtonBase, TrayButtonBaseProps } from '../common/TrayButtonBase'
import { SIDE_PANE_ANIMATION_DURATION } from '../constants'
import useSlidingPane from '../hooks/useSlidingPane'

type ManageUsersButtonProps = TrayButtonBaseProps & {}
export const ManageUsersButton: React.VFC<ManageUsersButtonProps> = ({}) => {
    const { state, showManageUsers, close, openUnsavedChangesModal } = useSlidingPane()
    const { track } = useTrack()

    const { workspaceAccount } = useAppContext()
    const { toggle } = useAppSharingSettingsModal()

    const customerAccessEnabled = !workspaceAccount?.optional_features?.sync_stacker_users

    const toggleManageUsersPane = () => {
        if (state.key !== 'users') {
            showManageUsers()
        } else {
            close()
        }
    }

    const toggleAppSharingSettingsModal = () => {
        if (state.isDirty) {
            openUnsavedChangesModal(() => {
                close(true)
                setTimeout(toggle, SIDE_PANE_ANIMATION_DURATION)
            })

            return
        }

        close()
        setTimeout(() => toggle(), SIDE_PANE_ANIMATION_DURATION)
    }

    const handleClick = () => {
        track('WIP - Frontend - Admin Side Tray - Manage Users - Clicked')
        customerAccessEnabled ? toggleAppSharingSettingsModal() : toggleManageUsersPane()
    }

    return (
        <TrayButtonBase
            icon="avatar"
            tooltip="Manage users"
            onClick={handleClick}
            data-testid="admin-side-tray.manage-users-button"
            isActive={state.key === 'users'}
        />
    )
}
