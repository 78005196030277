import React, { useCallback } from 'react'

import FullSchemaSync from '../FullSchemaSync'

import { STEPS } from './postgresConnectorConstants'
import { PostgresConnectionEditorProps } from './postgresConnectorTypes'
import { EditExistingPostgresConnection } from './PostgresEditExistingConnection'
import { PostgresInitializeNewConnection } from './PostgresInitializeNewConnection'
import { PostgresReviewSync } from './PostgresReviewSync'

const PostgresConnectionEditor: React.FC<PostgresConnectionEditorProps> = (props) => {
    const context = props.context
    const {
        dataConnection,
        step,
        setStep,
        schemaSyncOnly,
        setNextHandler,
        setIsSaving,
        setHideButtons,
        setNextButtonDisabled,
        setNextButtonText,
        setShouldCloseOnSchemaSyncOnly,
    } = context

    const handlePostgresSchemaSyncNext = useCallback(() => {
        setStep(STEPS.reviewSync)
    }, [setStep])

    // similar to data connection's main onSyncComplete but initializationComplete is not set so we can go to syncReview step
    const onSyncComplete = useCallback(() => {
        setIsSaving(false)
        setHideButtons(false)
        setNextButtonDisabled(false)
        setNextButtonText('Next')
        // make sure on schema sync only we don't close the modal on next button click and instead call the nextHandler
        setShouldCloseOnSchemaSyncOnly(false)
    }, [
        setIsSaving,
        setHideButtons,
        setNextButtonDisabled,
        setNextButtonText,
        setShouldCloseOnSchemaSyncOnly,
    ])

    if (!dataConnection) {
        return <PostgresInitializeNewConnection context={context} />
    } else if (step === STEPS.reviewSync) {
        return <PostgresReviewSync context={context} />
    } else if (step === STEPS.syncSchema || schemaSyncOnly) {
        // Ready to sync the schema
        setNextHandler(handlePostgresSchemaSyncNext)
        return (
            <FullSchemaSync
                dataConnection={dataConnection}
                onStartSync={context.onStartSync}
                onComplete={onSyncComplete}
                onError={context.onSyncError}
            />
        )
    } else {
        return <EditExistingPostgresConnection context={context} />
    }
}

export default PostgresConnectionEditor
