import React from 'react'

export function SyncArrowPath({ color }: { color: string }) {
    return (
        <path
            fill={color}
            d="M8.451 4.34a.221.221 0 0 0 .025-.068l.268-1.428a.363.363 0 1 0-.714-.128l-.132.714a3.292 3.292 0 0 0-5.92.3.357.357 0 0 0 .47.463.357.357 0 0 0 .194-.196 2.528 2.528 0 0 1 2.385-1.574A2.56 2.56 0 0 1 7.255 3.7l-.589-.096a.362.362 0 1 0-.114.714l1.517.253h.058a.332.332 0 0 0 .12-.021.118.118 0 0 0 .037-.021.278.278 0 0 0 .071-.04l.029-.035a.382.382 0 0 0 .05-.058.207.207 0 0 0 .017-.057Zm-.635 1.321a.357.357 0 0 0-.468.2 2.528 2.528 0 0 1-2.37 1.56 2.56 2.56 0 0 1-2.229-1.278l.59.097h.056a.358.358 0 1 0 .057-.714l-1.517-.247a.325.325 0 0 0-.118 0h-.029a.41.41 0 0 0-.114.05.357.357 0 0 0-.064.065l-.032.035a.3.3 0 0 0-.025.068.157.157 0 0 0-.025.06l-.268 1.43a.357.357 0 0 0 .286.435h.064a.357.357 0 0 0 .357-.293l.132-.714a3.292 3.292 0 0 0 5.92-.296.357.357 0 0 0-.203-.458Z"
        />
    )
}
