import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidDownloadProps = {
    color?: string
    size?: number
}

const SolidDownload: React.VFC<SolidDownloadProps> = ({ color, size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 16}
            height={size || 16}
            viewBox="0 0 16 16"
            fill={color}
        >
            <path
                d="M3.822 13.601h8.356a.622.622 0 0 1 .051 1.242l-.051.003H3.822a.623.623 0 0 1-.051-1.243l.05-.002h8.357-8.356zM7.86 2.135l.05-.002a.622.622 0 0 1 .621.571l.002.052v7.585l1.705-1.704a.622.622 0 1 1 .88.88l-2.766 2.766a.623.623 0 0 1-.88 0L4.706 9.518a.622.622 0 0 1 .88-.88l1.703 1.702V2.756a.622.622 0 0 1 .571-.62l.05-.003-.05.002z"
                fill={color || colors.userInterface.accent[1000]}
            />
        </svg>
    )
}

export default SolidDownload
