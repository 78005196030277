/* Code Quality: Not audited */

import React, { Component } from 'react'

import styled from '@emotion/styled'
import Block from 'v2/blocks/Block'

import PropertyStore from 'features/pages/tree/broadcast/PropertyStore'
import defaultBlockTree from 'features/pages/tree/defaultBlockTree'

class BlockTree extends Component {
    /*
        BlockTree takes a block tree and renders it.
        It will take a set of context information and pass those to the first block.
        It will also provide a localized "block properties store", where blocks can set and access
        block properties
        We use BlockTree mainly for rendering the contents of: 1) a custom block 2) a page 3)
        a data repeater.
    */
    render() {
        return <PropertyStore isOuter={this.props.isOuter}>{this.renderTree}</PropertyStore>
    }

    renderTree = (propertyStore) => {
        const {
            tree,
            context,
            inheritPropertyStore,
            determineIsBlockDisabled,
            renderBlockDropZone,
        } = this.props

        const treeToRender =
            !tree || !tree.map ? JSON.parse(JSON.stringify(defaultBlockTree)) : tree

        // inheritPropertyStore is a property that is only true when BlockTree is called from a
        // data repeater. Data repeaters are a special case where we don't want to create a
        // whole new context for whatever BlockTree is rendering, hence this line:

        propertyStore = inheritPropertyStore ? context.propertyStore : propertyStore

        const blocks = treeToRender.map((block, index) => {
            return (
                <Block
                    isOuter={this.props.isOuter}
                    position={index}
                    key={block.id}
                    block={block}
                    context={{ ...context, propertyStore: propertyStore }}
                    determineIsBlockDisabled={determineIsBlockDisabled}
                    renderDropZone={renderBlockDropZone}
                />
            )
        })

        return (
            <TreeWrapper className="tree" dontGrow={this.props.dontGrow}>
                {blocks}
            </TreeWrapper>
        )
    }
}

export default BlockTree

const TreeWrapper = styled('div')`
    flex: 1 1;
    ${(props) => props.dontGrow && 'flex-grow: 0;'}
    width: 100%;
    .block:empty {
        display: none;
    }
`
