import React from 'react'

import type { LayoutEditorBlock } from 'v2/blocks/types'

import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'

import { AggregationBlock } from '../common/AggregationBlock'
import isValidAggregation from '../utils/isValidAggregation'

import InvalidMetricBlock from './InvalidMetricBlock'
import { MetricBlock } from './MetricBlock'
import { MetricsBlockEditor } from './MetricsBlockEditor'
import { MetricsBlockEmptyState } from './MetricsBlockEmptyState'

type MetricsBlockProps = {
    isOnListView?: boolean
}

const MetricsBlock: LayoutEditorBlock<MetricsBlockProps, MetricsBlock> = ({
    attrs,
    block,
    context,
    isOnListView,
}) => (
    <AggregationBlock
        attrs={attrs}
        blockId={block?.id}
        context={context}
        isOnListView={isOnListView}
        Block={MetricBlock}
        BlockEmptyState={MetricsBlockEmptyState}
        BlockInvalidState={InvalidMetricBlock}
        isValid={isValidAggregation}
    />
)

MetricsBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        Element: MetricsBlockEditor,
        field: 'metrics',
        simple: true,
        fullWidth: true,
        hideLabel: true,
        requireObject: false,
    }),
]

export default MetricsBlock
