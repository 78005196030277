import React from 'react'

import { useLinkCollection } from 'data/hooks/links'

export const LinkMenuItem = () => {
    const { linksById } = useLinkCollection()
    const linkCount = Object.keys(linksById).length
    const text = `Linked tables (${linkCount})`
    return <>{text}</>
}
