// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'

import { CustomCell, Rectangle } from '@glideapps/glide-data-grid'
import styled from 'styled-components'

import DateAttribute from 'v2/ui/components/Attribute/DateAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { CellData, CustomCellRenderer } from '../types'

export const DATE_CELL = 'date-cell'
export type DateCellData = CellData & {
    readonly kind: typeof DATE_CELL
    readonly value: string | null
    readonly displayValue: string | null
    readonly showTime: boolean
}

export type DateCell = CustomCell<DateCellData>

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding-top: 0px;
    color: ${(props) => props.theme.textDark};
    box-sizing: border-box;
`

const renderer: CustomCellRenderer<DateCell> = {
    isMatch: (c): c is DateCell => (c.data as any).kind === DATE_CELL,
    draw: (args, cell) => {
        const { ctx, theme, rect } = args
        if (!cell.data.displayValue) return true

        const drawArea: Rectangle = {
            x: rect.x + theme.cellHorizontalPadding,
            y: rect.y + theme.cellVerticalPadding,
            width: rect.width - 2 * theme.cellHorizontalPadding,
            height: rect.height - 2 * theme.cellVerticalPadding,
        }

        ctx.fillStyle = theme.textDark
        ctx.fillText(cell.data.displayValue, drawArea.x, drawArea.y + 13)

        return true
    },
    provideEditor: () => {
        const CustomEditor = (props) => {
            const wrapperRef = useRef<HTMLDivElement | null>(null)

            const { onChange, value: cell } = props

            const handleChange = (value: any) => {
                // update the cell with the new data value
                const newCell = { ...cell, data: { ...cell.data, value } }
                onChange(newCell)
            }

            useEffect(() => {
                wrapperRef.current?.focus()
            }, [])

            return (
                <Wrapper ref={wrapperRef} tabIndex={0}>
                    {/* @ts-ignore */}
                    <DateAttribute
                        mode={modes.editing}
                        onChange={handleChange}
                        showTime={cell.data.showTime}
                        field={cell.data.field}
                    >
                        {cell.data.value}
                    </DateAttribute>
                </Wrapper>
            )
        }

        return {
            editor: CustomEditor,
            deletedValue: (toDelete: DateCell) => ({
                ...toDelete,
                copyData: '',
                data: {
                    ...toDelete.data,
                    value: null,
                },
            }),
        }
    },
}

export default renderer
