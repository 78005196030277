import { useController } from 'react-hook-form'

import { getCachedField } from 'data/hooks/fields'
import { getCachedObjectsById } from 'data/hooks/objects/objectOperations'

const defaultChangeOpts = { shouldDirty: true, shouldValidate: true }
const getLinkFieldDefaultValue = (sourceField) => (sourceField ? sourceField.label : '')
const getSymmetricFieldDefaultValue = (maybeSourceObject) =>
    maybeSourceObject ? `${maybeSourceObject.name} records` : ``

/**
 *
 * @param {import('react-hook-form').UseFormMethods<LinkUpdateData>} form
 * @returns
 */
export const useLinkFormFields = (form) => {
    const objectById = getCachedObjectsById()
    const values = form.watch()

    const getObject = (objectId) => {
        return objectById[objectId]
    }

    const { field: source } = useController({
        control: form.control,
        name: 'source',
        rules: { required: true },
    })

    const { field: target } = useController({
        control: form.control,
        name: 'target',
        rules: { required: true },
    })

    const { field: sourceField } = useController({
        control: form.control,
        name: 'sourceField',
        rules: { required: true },
    })

    const { field: targetField } = useController({
        control: form.control,
        name: 'targetField',
        rules: { required: true },
    })

    const { field: sourceFieldLinkName } = useController({
        control: form.control,
        name: 'sourceFieldLinkName',
        rules: { required: true },
    })

    const { field: targetFieldLinkName } = useController({
        control: form.control,
        name: 'targetFieldLinkName',
        rules: {},
    })

    const { field: linkFieldObject } = useController({
        control: form.control,
        name: 'linkFieldObject',
        rules: { required: true },
    })

    const { field: legacy } = useController({
        control: form.control,
        name: 'legacy',
    })

    const { field: mode } = useController({
        control: form.control,
        name: 'mode',
        defaultValue: 'one-to-many',
        rules: { required: true },
    })

    const { field: delimiter } = useController({
        control: form.control,
        name: 'delimiter',
        defaultValue: ',',
        rules: { required: mode.value === 'many-to-many' },
    })

    const { field: filters } = useController({
        control: form.control,
        name: 'filters',
        rules: { required: false },
    })

    const { field: enableFilters } = useController({
        control: form.control,
        name: 'enableFilters',
        defaultValue: false,
        rules: { required: false },
    })

    return {
        source: {
            ...source,
            onChange: (value) => {
                if (value === values.source) {
                    return
                }
                if (!values.linkFieldObject || values.linkFieldObject === values.source) {
                    // Reset the link direction
                    form.setValue('linkFieldObject', value, defaultChangeOpts)
                }

                // Reset the source field
                form.setValue('sourceField', ``, defaultChangeOpts)

                // Set the symmetric field name based on the newly selected object
                form.setValue(
                    'targetFieldLinkName',
                    getSymmetricFieldDefaultValue(getCachedField(value)),
                    defaultChangeOpts
                )

                source.onChange(value)
            },
        },
        target: {
            ...target,
            onChange: (value) => {
                // Reset the target field
                form.setValue('targetField', ``, defaultChangeOpts)

                // If we change the target object we reset the filters
                if (value !== values.target) {
                    form.setValue('filters', [], defaultChangeOpts)
                }
                // If we current link field object points to the old target object, update
                // it to reference the changed value
                if (values.linkFieldObject === values.target) {
                    form.setValue('linkFieldObject', value, defaultChangeOpts)
                }
                target.onChange(value)
            },
        },
        sourceField: {
            ...sourceField,
            onChange: (value) => {
                // Whenver we change the source field we want sync the name of the link field as
                // well, so long as it hasn't already been set and it isn't an existing link.
                // Ideally we'd track if the user has edited the field but neither touchedFields nor
                // dirtyFields are quite right (touchedFields tracks clicks not edits, dirtyFields
                // includes programmatic changes) and the alternative involves lots of custom logic.
                const updatedField = getCachedField(value)
                const isNewForm = form.control.defaultValuesRef.current?.sourceFieldLinkName === ``
                if (!form.formState.touchedFields.sourceFieldLinkName && isNewForm) {
                    form.setValue(
                        'sourceFieldLinkName',
                        getLinkFieldDefaultValue(updatedField),
                        defaultChangeOpts
                    )
                }
                sourceField.onChange(value)
            },
        },
        targetField,
        linkFieldObject: {
            ...linkFieldObject,
            onChange: (value) => {
                if (value === values.source) {
                    // Revert the link and symmetric field names to match the
                    // new direction of the link.
                    // The link field name should be based on the source field.
                    // The symmetric field name should be based on the object
                    form.setValue(
                        'sourceFieldLinkName',
                        getLinkFieldDefaultValue(getCachedField(values.sourceField)),
                        defaultChangeOpts
                    )
                    form.setValue(
                        'targetFieldLinkName',
                        getSymmetricFieldDefaultValue(getObject(values.source)),
                        defaultChangeOpts
                    )
                } else {
                    form.setValue(
                        'sourceFieldLinkName',
                        getSymmetricFieldDefaultValue(getObject(values.target)),
                        defaultChangeOpts
                    )
                    form.setValue(
                        'targetFieldLinkName',
                        getLinkFieldDefaultValue(getCachedField(values.targetField)),
                        defaultChangeOpts
                    )
                }
                linkFieldObject.onChange(value)
            },
        },
        sourceFieldLinkName: {
            ...sourceFieldLinkName,
            onChange: (value) => {
                // We need to manually set the touched state
                // https://github.com/react-hook-form/react-hook-form/issues/1418
                Object.assign(form.formState.touchedFields, `sourceFieldLinkName`, true)
                sourceFieldLinkName.onChange(value)
            },
        },
        targetFieldLinkName,
        legacy,
        mode,
        delimiter,
        filters,
        enableFilters,
    }
}
