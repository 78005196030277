import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'

const getRecordField = (
    objectId: string
): Pick<FieldDto, '_sid' | 'api_name' | 'type' | 'label' | 'options'> => {
    return {
        _sid: '_sid',
        api_name: '_sid',
        type: 'lookup',
        label: 'The Record',
        options: { lookup_target: objectId },
    }
}

export const getFilterFieldFromId = (
    object: ObjectDto | undefined,
    fieldSid: string
): Partial<FieldDto> => {
    if (fieldSid === '_sid' && object) {
        return getRecordField(object._sid)
    }

    if (fieldSid === 'user_role') {
        return {
            type: 'user_role',
            _sid: 'user_role',
            label: 'User Role',
        }
    }

    const orderedFields = object ? sortFieldsByOrder(object.fields, object) : []

    return orderedFields.filter(({ _sid }) => _sid === fieldSid)[0]
}

export const getFilterFieldFromName = (
    object: Pick<ObjectDto, '_sid' | 'fields'> | undefined,
    fieldApiName: string
): Partial<FieldDto> => {
    if (fieldApiName === '_sid' && object) {
        return getRecordField(object._sid)
    }
    return object?.fields.find(({ api_name }) => api_name === fieldApiName)
}
