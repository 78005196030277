/*
    This store holds the current workspaceAccount and selectedStack.
    It's stored here so non-react code can access it as needed.
    It is kept up-to-date by the AppContextProvider.
*/
type InternalStore = {
    state: {
        selectedStack: StackDto | undefined
        workspaceAccount: Account | undefined
    }
}

const _internalStore: InternalStore = {
    state: {
        selectedStack: undefined,
        workspaceAccount: undefined,
    },
}

export const getCurrentStackId = () => {
    return _internalStore.state.selectedStack?._sid
}

/**
 * @deprecated please don't use that in React, use const { selectedStack } = useAppContext() instead
 */
export const getCurrentStack = () => {
    return _internalStore.state.selectedStack
}
export const getWorkspaceAccount = () => {
    return _internalStore.state.workspaceAccount
}

export default _internalStore
