import React, { useState, VFC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

const SolidEdit: VFC<{
    color?: string
    size?: number
    hoverColor?: string
}> = ({
    color = colors.userInterface.neutral[700],
    hoverColor = colors.userInterface.neutral[1000],
    size = 24,
}) => {
    const [currentColor, setCurrentColor] = useState<string>(color)

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={currentColor}
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={() => {
                setCurrentColor(hoverColor)
            }}
            onMouseLeave={() => {
                setCurrentColor(color)
            }}
        >
            <path d="M20.015 9.62 8.117 21.52a4.98 4.98 0 0 1-2.313 1.308L1.24 23.97a.997.997 0 0 1-1.21-1.21l1.142-4.562a4.98 4.98 0 0 1 1.31-2.315l11.897-11.9 5.636 5.636zm2.82-8.452a3.984 3.984 0 0 1 0 5.634l-1.411 1.407-5.636-5.635 1.41-1.406a3.984 3.984 0 0 1 5.636 0z" />
        </svg>
    )
}

export default SolidEdit
