import React, { useMemo } from 'react'

import { InlineFilter } from '../InlineFilter'
import { useInlineFilter } from '../useInlineFilter'
import { useUserFieldOptions } from '../useUserFieldOptions'
import { getInlineFilterValueLabels } from '../valueUtils'

import type { InlineFilterComponent } from './types'

type TextInlineFilterProps = {}

export const TextInlineFilter: InlineFilterComponent<TextInlineFilterProps> = ({
    field,
    columns,
}) => {
    const userFieldOptions = useUserFieldOptions(field)

    const { operation, filter, ...inlineFilterProps } = useInlineFilter({ field })

    const options = useMemo(() => {
        if (['isCurrentUserField', 'isNotCurrentUserField'].includes(operation)) {
            return userFieldOptions
        } else {
            return []
        }
    }, [operation, userFieldOptions])

    const valueLabels = useMemo(
        () => getInlineFilterValueLabels(filter, options),
        [filter, options]
    )

    return (
        <InlineFilter
            closeOnSelect={true}
            field={field}
            columns={columns}
            options={options}
            operation={operation}
            valueLabels={valueLabels}
            {...inlineFilterProps}
        />
    )
}
