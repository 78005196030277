import { Keyword } from '../formulaTypes'
import { StackerNonOperatorFormulaSyntaxTree } from '../parser/formulaParserTypes'

export const FORMULA_EDITOR_PLACEHOLDER = ['"Welcome " & {Name} & "!"']

export const keywordMap: Readonly<{
    [F in StackerNonOperatorFormulaSyntaxTree as F['function']]: Keyword
}> = {
    ABS: {
        name: 'absolute',
        insertText: 'ABS( )',
        syntax: 'ABS(number)',
        examples: ['ABS({Satisfaction Score} - {Target Score})'],
        helpText: 'Returns the absolute value.',
    },
    AND_FUN: {
        name: 'and',
        insertText: 'AND( , )',
        syntax: 'AND, AND(expression, [exp2, ...])',
        examples: ['AND({Invoice Paid}, {Order Delivered})'],
        helpText: 'Returns true if all the arguments are true, returns false otherwise.',
    },
    AVERAGE: {
        name: 'average',
        insertText: 'AVERAGE( , )',
        syntax: 'AVERAGE(number1, [number2, ...])',
        examples: ['AVERAGE({Q1 Revenue},{Q2 Revenue})'],
        helpText: 'Returns the average of the numbers.',
    },
    CONCAT: {
        name: 'concat',
        insertText: 'CONCAT( , )',
        syntax: 'CONCAT(string1, [string2, ..])',
        examples: ['CONCAT({First Name}, " ", {Last Name})'],
        helpText: 'Joins together different arguments.',
    },
    CREATED_AT: {
        name: 'created_at',
        insertText: 'CREATED_AT()',
        syntax: 'CREATED_AT()',
        examples: ['CREATED_AT()'],
        helpText: 'Returns the date and time when the record was created.',
    },
    CREATED_BY: {
        name: 'created_by',
        insertText: 'CREATED_BY()',
        syntax: 'CREATED_BY()',
        examples: ['CREATED_BY()'],
        helpText: 'Returns the name of the person who created the record.',
    },
    DATEADD: {
        name: 'date_add',
        insertText: 'DATEADD( , , )',
        syntax: 'DATEADD(date, number, unit)',
        examples: ['DATEADD({Order Date}, 7, "day")'],
        helpText:
            'Adds specified units to a date or datetime. Unit accepts "year", "month", "week", and "day".',
    },
    DATEDIF: {
        name: 'datedif',
        insertText: 'DATEDIF( ,  , )',
        syntax: 'DATEDIF(date1, date2, units)',
        examples: ['DATEDIF({Meeting Time}, NOW(), "hour")'],
        helpText:
            'Returns the difference between datetimes in the unit specified. Unit accepts "year", "month", "day", "week", "hour", "minute".',
    },
    DATESTR: {
        name: 'date_str',
        insertText: 'DATESTR( )',
        syntax: 'DATESTR(date)',
        examples: ['DATESTR({Birthday})'],
        helpText: 'Formats a date or datetime into a string (YYYY-MM-DD or YYYY-MM-DD HH:mm:SS).',
    },
    DAY: {
        name: 'day',
        insertText: `DAY( )`,
        syntax: 'DAY(date)',
        examples: ['DAY({Birthday})'],
        helpText: 'Returns the day of a date or datetime field as a number between 1 and 31.',
    },
    FORMAT_NUMBER: {
        name: 'format_number',
        insertText: 'FORMAT_NUMBER( )',
        syntax: 'FORMAT_NUMBER(number)',
        examples: ['FORMAT_NUMBER(1234567.89)'],
        helpText: 'Converts a number into a string, using commas as thousands separators.',
    },
    HOUR: {
        name: 'hour',
        insertText: `HOUR( )`,
        syntax: 'HOUR(date)',
        examples: ['HOUR({Meeting Time})'],
        helpText: 'Returns the hour of a date or datetime field as a number between 0 and 23.',
    },
    IF: {
        name: 'if',
        insertText: 'IF( , , )',
        syntax: 'IF(expression, ifTrue, ifFalse)',
        examples: ['IF({Order Delivered}, "Delivered", "Pending")'],
        helpText:
            'Returns ifTrue if the logical argument is true, otherwise it returns ifFalse. Can also be used to make nested IF statements.',
    },
    INT: {
        name: 'int',
        insertText: 'INT( )',
        syntax: 'INT(number)',
        examples: ['INT({Unit Price})', 'INT(1.99) => 1', 'INT(-1.99) => -2'],
        helpText: 'Returns the greatest integer that is less than or equal to the number.',
    },
    IS_AFTER: {
        name: 'is_after',
        insertText: 'IS_AFTER( )',
        syntax: 'IS_AFTER(date1, date2)',
        examples: ['IS_AFTER({Delivery Date}, {Target Delivery Date})'],
        helpText: 'Determines if date1 is later than date2. ',
    },
    IS_BEFORE: {
        name: 'is_before',
        insertText: 'IS_BEFORE( )',
        syntax: 'IS_BEFORE(date1, date2)',
        examples: ['IS_BEFORE({Delivery Date}, {Target Delivery Date})'],
        helpText: 'Determines if date1 is before date2.',
    },
    IS_SAME: {
        name: 'is_same',
        insertText: 'IS_SAME( )',
        syntax: 'IS_SAME(date1, date2, [unit])',
        examples: [
            'IS_SAME({Delivery Date}, TODAY(), "month")',
            'IS_SAME({First Appointment Date}, {Next Appointment Date})',
        ],
        helpText:
            'Compares two dates up to a unit and determines whether they are identical. Unit accepts "year", "month", "day", "hour", "minute", "exact" (compares all units).',
    },
    LAST_UPDATED_AT: {
        name: 'last_updated_at',
        insertText: 'LAST_UPDATED_AT()',
        syntax: 'LAST_UPDATED_AT()',
        examples: ['LAST_UPDATED_AT()'],
        helpText: 'Returns the date and time when the record was last updated.',
    },
    LAST_UPDATED_BY: {
        name: 'last_updated_by',
        insertText: 'LAST_UPDATED_BY()',
        syntax: 'LAST_UPDATED_BY()',
        examples: ['LAST_UPDATED_BY()'],
        helpText: 'Returns the name of the person who last modified the record.',
    },
    LEFT: {
        name: 'left',
        insertText: 'LEFT( , )',
        syntax: 'LEFT(string, howMany)',
        examples: ['LEFT({Order Code}, 3)'],
        helpText: 'Extracts a number of characters from the end of a string.',
    },
    LEN: {
        name: 'length',
        insertText: 'LEN( )',
        syntax: 'LEN(string)',
        examples: ['LEN({Name})'],
        helpText: 'Returns the length of a string.',
    },
    LOWER: {
        name: 'lower',
        insertText: 'LOWER( )',
        syntax: 'LOWER(string)',
        examples: ['LOWER({Customer Email})'],
        helpText: 'Makes a string lowercase.',
    },
    MAX: {
        name: 'max',
        insertText: 'MAX( , )',
        syntax: 'MAX(number, number...)',
        examples: ['MAX({Delivery Fee}, {Minimum Fee})'],
        helpText: 'Returns the largest of the given numbers.',
    },
    MIN: {
        name: 'min',
        insertText: 'MIN( , )',
        syntax: 'MIN(number, number...)',
        examples: ['MIN({Delivery Fee}, {Maximum Fee})'],
        helpText: 'Returns the smallest of the given numbers.',
    },
    MINUTE: {
        name: 'minute',
        insertText: `MINUTE( )`,
        syntax: 'MINUTE(date)',
        examples: ['MINUTE({Meeting Time})'],
        helpText: 'Returns the minute of a date or datetime field as a number between 0 and 59.',
    },
    MONTH: {
        name: 'month',
        insertText: `MONTH( )`,
        syntax: 'MONTH(date)',
        examples: ['MONTH({Birthday})'],
        helpText: 'Returns the month of a date or datetime field as a number between 1 and 12.',
    },
    NOT_FUN: {
        name: 'not',
        insertText: 'NOT( )',
        syntax: 'NOT(expression)',
        examples: ['NOT({Invoice Paid})'],
        helpText: 'Reverses a true to false.',
    },
    NOW: {
        name: 'now',
        insertText: `NOW()`,
        syntax: 'NOW()',
        examples: ['NOW()'],
        helpText: 'Returns the current date and time.',
    },
    OR_FUN: {
        name: 'or',
        insertText: 'OR( , )',
        syntax: 'OR, OR(expression, [exp2, ...])',
        examples: ['OR({Invoice Paid}, {Order Delivered})'],
        helpText: 'Returns true if any one of the arguments is true.',
    },
    RECORD_ID: {
        name: 'record_id',
        insertText: 'RECORD_ID()',
        syntax: 'RECORD_ID()',
        examples: ['RECORD_ID()'],
        helpText: 'Returns the unique Stacker record id.',
    },
    REGEX_EXTRACT: {
        name: 'regex_extract',
        insertText: 'REGEX_EXTRACT( , )',
        syntax: 'REGEX_EXTRACT(string, regex)',
        examples: ['REGEX_EXTRACT({Order Code}, "[0-9]+")'],
        helpText: 'Returns the first substring that matches a regular expression.',
    },
    REGEX_MATCH: {
        name: 'regex_match',
        insertText: 'REGEX_MATCH( , )',
        syntax: 'REGEX_MATCH(string, regex)',
        examples: ['REGEX_MATCH({Order Code}, "ORD.*")'],
        helpText: 'Returns whether the input text matches a regular expression.',
    },
    REGEX_REPLACE: {
        name: 'regex_replace',
        insertText: 'REGEX_REPLACE( , , )',
        syntax: 'REGEX_REPLACE(string, regex, replacement)',
        examples: ['REGEX_REPLACE({Price}, "USD", "$")'],
        helpText: 'Substitutes all matching substrings with a replacement string value.',
    },
    RIGHT: {
        name: 'right',
        insertText: 'RIGHT( , )',
        syntax: 'RIGHT(string, howMany)',
        examples: ['RIGHT({Order Code}, 5)'],
        helpText: 'Extracts a number of characters from the end of a string.',
    },
    ROUND: {
        name: 'round',
        insertText: 'ROUND( , )',
        syntax: 'ROUND(number, precision)',
        examples: ['ROUND({Unit Price}, 0)'],
        helpText:
            'Rounds to a number of decimal places as specified by precision. E.g. "0", "1", etc.',
    },
    ROUNDDOWN: {
        name: 'rounddown',
        insertText: 'ROUNDDOWN( , )',
        syntax: 'ROUNDDOWN(number, precision)',
        examples: [
            'ROUNDDOWN({Unit Price}, 0)',
            'ROUNDDOWN(3.5, 0) => 3',
            'ROUNDDOWN(3.8, 0) => 3',
            'ROUNDDOWN(-2.6, 0) => -2',
        ],
        helpText:
            'Rounds the value to the number of decimal places given by "precision," always rounding down (towards zero). E.g. "0", "1", etc.',
    },
    ROUNDUP: {
        name: 'roundup',
        insertText: 'ROUNDUP( , )',
        syntax: 'ROUNDUP(number, precision)',
        examples: [
            'ROUNDUP({Unit Price}, 0)',
            'ROUNDUP(3.5, 0) => 4',
            'ROUNDUP(3.4, 0) => 4',
            'ROUNDUP(-2.1, 0) => -3',
        ],
        helpText:
            'Rounds the value to the number of decimal places given by "precision," always rounding up (away from zero). E.g. "0", "1", etc.',
    },
    SUM: {
        name: 'sum',
        insertText: 'SUM( , )',
        syntax: 'SUM(number, [number2, ...])',
        examples: ['SUM({Q1 Revenue},{Q2 Revenue})'],
        helpText: 'Sums together numbers.',
    },
    TODAY: {
        name: 'today',
        insertText: `TODAY()`,
        syntax: 'TODAY()',
        examples: ['TODAY()'],
        helpText: 'Returns the current date, but not the current time.',
    },
    TRIM: {
        name: 'trim',
        insertText: 'TRIM( )',
        syntax: 'TRIM(string)',
        examples: ['TRIM({Customer Email})'],
        helpText: 'Removes whitespace at the beginning and end of string.',
    },
    UPPER: {
        name: 'upper',
        insertText: 'UPPER( )',
        syntax: 'UPPER(string)',
        examples: ['UPPER({Last Name})'],
        helpText: 'Makes string uppercase.',
    },
    VALUE: {
        name: 'value',
        insertText: 'VALUE( )',
        syntax: 'VALUE(string)',
        examples: [
            'VALUE({Quoted Price})',
            'VALUE("$1000") => 1000',
            'VALUE("25 days") => 25',
            'VALUE("10lbs") => 10',
        ],
        helpText: 'Converts text string to a number.',
    },
    YEAR: {
        name: 'year',
        insertText: `YEAR( )`,
        syntax: 'YEAR(date)',
        examples: ['YEAR({Birthday})'],
        helpText: 'Returns the four-digit year of a datetime.',
    },
    // Please keep this sorted alphabetically :-)
}

export const availableKeywords: Keyword[] = Object.values(keywordMap)
