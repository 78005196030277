/* Code Quality: Not audited */

import React, { memo } from 'react'

import styled from '@emotion/styled'

import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import { Flex, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

// TODO commenting out field level edit conflict highlighting for now
// to ship the simplest version of this, just uncomment this
// and delete the dummy Container impl below to turn this back on
//
// const UPDATE_HIGHLIGHT_YELLOW = 'none' // '#FFF9C4'

// const Container = styled(Flex)`
//     & .attr-label {
//         background-color: none;
//         padding: 0;
//         transition: background-color 0.5s linear, padding 0.2s linear;

//         &.updated {
//             background-color: ${UPDATE_HIGHLIGHT_YELLOW};
//             padding: 3px;
//         }
//     }
// `

const Container = Flex

const LabelledAttribute = memo(({ highlightFieldUpdated, ...props }) => {
    const labelVariant = props.variant ? `${props.variant}FieldLabel` : 'fieldLabel'
    const textVariant = props.variant ? `${props.variant}FieldText` : 'fieldText'
    const marginBottom = props.variant === 'highlighted' ? 0 : 4
    const isSupportLoginPermitted = useIsSupportLoginPermitted()

    return (
        <Container
            height={props.layout === 'highlighted' ? '100%' : 'inherit'}
            direction="column"
            align="left"
            wrap="nowrap"
            style={props.style}
            {...props}
        >
            {!props.hideLabel && (
                <Text variant={labelVariant}>
                    <span
                        className={`attr-label` + (highlightFieldUpdated ? ' updated' : undefined)}
                    >
                        {props.label}
                        {props.required && <RequiredStar>*</RequiredStar>}
                    </span>
                </Text>
            )}

            {props.editing && props.editDescription && (
                <FieldEditingDescription
                    className={STYLE_CLASSES.ATTRIBUTE_DESCRIPTION}
                    variant={textVariant}
                >
                    {props.editDescription}
                </FieldEditingDescription>
            )}

            <Text
                className={isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK}
                variant={textVariant}
                as={Flex}
                align="stretch"
                flexBasis="100%"
                mb={marginBottom}
            >
                {props.children}
            </Text>
        </Container>
    )
})
LabelledAttribute.displayName = 'LabelledAttribute'
export default LabelledAttribute

const RequiredStar = styled.span`
    color: red;
`

const FieldEditingDescription = styled(Text)`
    margin-bottom: 0.5rem;
`
