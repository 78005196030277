import React, { FC, memo, useMemo } from 'react'

import { Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Checkbox, Flex, Icon } from 'v2/ui'
import { SolidCheck, SolidClose } from 'v2/ui/svgs'
import { Xmark } from 'v2/ui/svgs/fa-sharp-regular'
import { layouts, modes, sizes } from 'v2/ui/utils/attributeSettings'

const StyledXmark = styled(Xmark)`
    color: ${(props) => props.theme.colors.grey[300]};
`

type Props = {
    field: FieldDto
    renderOptions: { [keyof: string]: any }
    mode?: typeof modes[keyof typeof modes]
    layout?: typeof layouts[keyof typeof layouts]
    size?: typeof sizes[keyof typeof sizes]
    checkboxDisplay?: 'default' | 'filled' | 'big'
    variant?: string
    noLinks?: boolean
    inline?: boolean
    onChange: (value: boolean) => void
    children?: boolean | string | number
}

const CheckboxAttribute: FC<Props> = ({
    mode,
    size,
    checkboxDisplay,
    layout,
    onChange,
    children,
    variant,
    ...props
}) => {
    const form = useMemo(() => {
        if (props.field.options && props.field.options.is_toggle) {
            return (
                <Switch
                    onChange={(e) => onChange(e.target.checked)}
                    value={typeof children === 'boolean' ? children.toString() : children}
                    isChecked={Boolean(children)}
                    variant={variant}
                />
            )
        }

        return (
            <Checkbox
                data-testid="checkbox-attribute.input"
                size={size}
                onChange={(e) => {
                    return onChange(e.target.checked)
                }}
                star={props.field.options && props.field.options.is_star}
                slider={props.field.options && props.field.options.is_slider}
                // chakra will not reset a checked box if you pass Null through
                // so we have to be explicit about false here if value is null
                isChecked={Boolean(children)}
                value={1}
                variant={variant}
            />
        )
    }, [children, onChange, props.field.options, size, variant])

    const checkedDisplay = useMemo(() => {
        if (!checkboxDisplay || checkboxDisplay === 'default') {
            return (
                <Icon
                    icon="checkmark"
                    size={size}
                    justifyContent={layout === 'highlighted' ? 'center' : 'flexStart'}
                    alignItems="flexStart"
                    color="brand.300"
                    data-testid="checkbox-default"
                />
            )
        }

        if (checkboxDisplay === 'filled') {
            return (
                <Box
                    p={1}
                    bg="brand.300"
                    borderRadius="4px"
                    width="fit-content"
                    m={layout === 'highlighted' ? 'auto' : 'inherit'}
                    data-testid="checkbox-filled"
                >
                    <SolidCheck size={12} color="white" />
                </Box>
            )
        }

        return (
            <Flex
                w="40px"
                h="40px"
                bg="brand.300"
                borderRadius="50%"
                m={layout === 'highlighted' ? 'auto' : 'inherit'}
                mt={1}
                justifyContent="center"
                alignItems="center"
                data-testid="checkbox-big"
            >
                <SolidCheck size={18} color="white" />
            </Flex>
        )
    }, [checkboxDisplay, layout, size])

    const uncheckedDisplay = useMemo(() => {
        if (!checkboxDisplay || checkboxDisplay === 'default' || checkboxDisplay === 'filled') {
            return (
                <Box m={layout === 'highlighted' ? 'auto' : 'inherit'}>
                    <StyledXmark />
                </Box>
            )
        }

        return (
            <Flex
                w="40px"
                h="40px"
                bg="userInterface.neutral.500"
                borderRadius="50%"
                m={layout === 'highlighted' ? 'auto' : 'inherit'}
                mt={1}
                justifyContent="center"
                alignItems="center"
            >
                <SolidClose color="white" />
            </Flex>
        )
    }, [checkboxDisplay, layout])

    if (mode === modes.editable || mode === modes.editing) {
        return form
    }

    return Boolean(children) ? checkedDisplay : uncheckedDisplay
}

export default memo(CheckboxAttribute)
