import styled from '@emotion/styled'

type SideMenuProps = {
    inSidePane: boolean
    width: string
    showOnRight: boolean
    showButton: boolean
}

export const SideMenu = styled('div')<SideMenuProps>`
    position: ${(props) => (props.inSidePane ? 'static' : 'fixed')};
    width: ${(props) => props.width};
    top: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => (props.showOnRight ? 'unset' : 'white')};
    margin-left: 0;
    height: 100%;
    box-shadow: ${(props) => (props.showOnRight ? 'unset' : '0px 0px 12px rgba(0, 0, 0, 0.15)')};
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    &:empty {
        margin-left: -${(props) => (props.showOnRight ? 0 : props.width)};
        box-shadow: unset;
    }
    ${(props) => ({ paddingBottom: props.showButton ? '60px' : '' })}
    z-index: 1000;
`
