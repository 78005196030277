import React, { SVGProps } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

export const RenderIcon = ({
    Icon,
    color = colors.userInterface.accent[1000],
    size = '16px',
}: {
    Icon: React.ComponentType<SVGProps<SVGSVGElement>>
    color?: string
    size?: string | number
}) => {
    return (
        <Icon
            fill={color}
            stroke={color}
            width={size}
            height={size}
            style={{ verticalAlign: 'middle' }}
        />
    )
}
