import React, { FC, ReactElement } from 'react'

import { Box, Slide } from 'v2/ui'

type AggregationBlockEditorProps = {
    isEditing: boolean
    listChildren: ReactElement
    editorChildren: ReactElement
}

export const AggregationBlockEditor: FC<AggregationBlockEditorProps> = ({
    isEditing,
    listChildren,
    editorChildren,
}) => (
    <Box overflowX="hidden">
        <Slide
            style={{ position: 'relative' }}
            display="flex"
            flexDirection="column"
            hideOn={isEditing ? 'left' : undefined}
            background="userInterface.neutral.0"
            paddingBottom="50px"
        >
            {listChildren}
        </Slide>
        <Slide style={{ top: '50px' }} paddingX={2} hideOn={isEditing ? undefined : 'right'}>
            {editorChildren}
        </Slide>
    </Box>
)
