import React, { FC, ReactNode } from 'react'

import { Grid } from '@chakra-ui/react'
import ProfileCard from 'v2/views/Detail/ProfileCard'

import { Box } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

type Props = {
    title: string
    profileLayout: boolean
    showControls: boolean
    context: any
    config: any
    recordPermissions: any
    header: ReactNode
    profileImage: ReactNode
    setConfig: (config: any, setDirty: boolean) => void
}

const LayoutWrapper: FC<Props> = ({
    title,
    profileLayout,
    showControls,
    context,
    config,
    recordPermissions,
    header,
    profileImage,
    setConfig,
    children,
}) => {
    if (!profileLayout) {
        return (
            <>
                {header}
                <Grid
                    templateColumns={['minmax(0, 1fr)']}
                    className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
                >
                    {children}
                </Grid>
            </>
        )
    }

    return (
        <>
            {header}
            <Grid
                templateColumns={[
                    'minmax(0, 1fr)',
                    'minmax(0, 1fr)',
                    'minmax(0, 1fr)',
                    'minmax(0, 1fr) minmax(0, 3fr)',
                ]}
                gap={4}
                className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
            >
                <Box mt={[4, 4, 4, 6]}>
                    <ProfileCard
                        config={config}
                        context={context}
                        showControls={showControls}
                        setConfig={setConfig}
                        profileImage={profileImage}
                        title={title}
                        recordPermissions={recordPermissions}
                    />
                </Box>
                <Box>{children}</Box>
            </Grid>
        </>
    )
}

export default LayoutWrapper
