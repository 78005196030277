import { useMutation, UseMutationResult } from 'react-query'

import { useDataConnectionActions } from 'data/hooks/dataConnections'

import { acceptHeaders, fetchWithAuth } from '../../../../../data/utils/utils'

export type AirtableUpgradeResult =
    | { isSuccess: true; error: undefined }
    | { isSuccess: false; error: 'noPermissions' | 'invalidToken' | 'unknown' }

export const useAirtableUpgradeMutation = (
    dataConnectionSid?: string
): UseMutationResult<AirtableUpgradeResult, void, void> => {
    const { fetch: fetchDataConnections }: { fetch: any } = useDataConnectionActions()
    return useMutation(
        () => {
            return fetchWithAuth(`data-connections/${dataConnectionSid}/upgrade/`, {
                method: 'POST',
                headers: acceptHeaders,
            }).then(async (data): Promise<AirtableUpgradeResult> => {
                if (data.status >= 200 && data.status <= 299) {
                    return { isSuccess: true, error: undefined }
                } else {
                    try {
                        const body = await data.json()
                        return {
                            isSuccess: false,
                            error:
                                body?.exception === 'DataConnectionUpgradeFailedNoBaseAccess'
                                    ? 'noPermissions'
                                    : body?.exception === 'DataConnectionUpgradeFailedInvalidToken'
                                    ? 'invalidToken'
                                    : 'unknown',
                        }
                    } catch (e) {
                        console.error(e)
                        return { isSuccess: false, error: 'unknown' }
                    }
                }
            })
        },
        {
            onSuccess: () => {
                // reload the data connection so the upgrade button is no longer shown for the data connection which was just upgraded
                fetchDataConnections(undefined, { force: true })
            },
        }
    )
}
