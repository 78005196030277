import { checkFormula, FormulaCheckStatus } from '../../../data/api/formula'
import { StackerFormulaSyntaxTree } from '../parser/formulaParserTypes'
import { parseStackerFormula } from '../parser/formulaParsingFunctions'

import { FormulaEditorContext } from './formulaEditorMachineTypes'

export type FormulaEditorValidationResult =
    | {
          isValid: true
          parsedFormula: StackerFormulaSyntaxTree
      }
    | {
          isValid: false
          message: string
      }

export const validateFormulaString = async ({
    dataConnectionType,
    field,
    formulaString,
    object,
    track,
}: FormulaEditorContext): Promise<FormulaEditorValidationResult> => {
    if (!object || !track) {
        throw new Error('Missing required parameters to validate formula string')
    }

    const parseResult = parseStackerFormula({ dataConnectionType, object, text: formulaString })

    if (!parseResult.isValid) {
        return Promise.resolve({
            isValid: false,
            message: `Your formula is not valid – please check it again.${
                parseResult.message ? ` (${parseResult.message})` : ''
            }`,
        })
    }

    const { status, message } = await checkFormula(field, object, parseResult.output)

    if (status !== FormulaCheckStatus.OK) {
        if (status === FormulaCheckStatus.INTERNAL_ERROR) {
            track('WIP - Frontend - Formula - Errored', {
                formula: formulaString,
                message,
            })
        }

        return Promise.resolve({
            isValid: false,
            message:
                status === FormulaCheckStatus.PARSE_ERROR
                    ? message
                    : 'We cannot understand this formula -- Contact us if the problem persists.',
        })
    }

    return Promise.resolve({
        isValid: true,
        parsedFormula: parseResult.output,
    })
}
