import React from 'react'

import AppContext from 'app/AppContext'
import { useUpdateAccount } from 'data/hooks/accounts'
import { useUpdateStack } from 'data/hooks/stacks'

import { Button, Flex, Modal, Text } from 'v2/ui'
import { Alert as AlertIcon } from 'v2/ui/svgs'

import { useAppSettings } from '../../workspace/AdminSideTray/hooks/useAppSettings'

const MigrateCssModal = (props) => {
    const { isOpen, onClose } = props
    const { workspaceAccount, selectedStack } = React.useContext(AppContext)
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { open: openAppSettings } = useAppSettings()

    const handleMigrate = (e) => {
        e.preventDefault()
        updateStack({
            id: selectedStack._sid,
            patch: {
                optional_features: {
                    ...selectedStack.options,
                    custom_css: false,
                },
            },
        })

        updateAccount({
            id: workspaceAccount._sid,
            patch: {
                optional_features: {
                    ...workspaceAccount.optional_features,
                    global_custom_css: true,
                    custom_css: false,
                },
            },
        }).then(() => {
            onClose(e)
            openAppSettings({ page: { name: 'appearance' } })
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            size={'400px'}
            body={
                <form>
                    <Flex justifyContent="center">
                        <AlertIcon />
                        <Text variant="modalTitle" mt={6} fontWeight="bold">
                            Please confirm your migration
                        </Text>
                        <Text mt={4}>
                            Your Custom CSS will be applied app level. Make sure you are happy with
                            the changes before continuing.
                        </Text>
                        <Button
                            variant="adminPrimary"
                            buttonSize="md"
                            mt={4}
                            width="100%"
                            type="submit"
                            onClick={handleMigrate}
                        >
                            Save
                        </Button>
                        <Button
                            variant="adminSecondary"
                            buttonSize="md"
                            mt={4}
                            width="100%"
                            type="submit"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Flex>
                </form>
            }
        ></Modal>
    )
}
export default MigrateCssModal
