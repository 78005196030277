// @ts-strict-ignore
import React, { useMemo, useState } from 'react'
import { components } from 'react-select'

import { FlexProps } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { workspaceGroups } from 'data/hooks/accounts'
import { useField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { useRecords } from 'data/hooks/records'
import { useRoleListOptions } from 'data/hooks/roleHelpers'
import { useSyncUsers, useUpdateStack } from 'data/hooks/stacks'
import {
    refetchAppUsersForAdmin,
    useAddWorkspaceUser,
    useWorkspaceUsers,
} from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Avatar, Box, Button, Dropdown, Flex, Icon } from 'v2/ui'

import analytics from '../../../utils/analytics'

const isEmailRegEx = /^(.+)@(.+)$/
type Props = {
    userList?: UserListDto
    showRoles?: boolean
    defaultRole?: string
    workspaceRole?: string
    showGroups?: boolean
    filterUsers?: (user: any) => boolean
    containerProps?: FlexProps
}

type AddUserOption = {
    group?: any
    user?: any
    record?: any
    name?: string
    email?: string
    value?: string
}

export default function AddUserDropdown({
    userList,
    showRoles: shouldShowRoles = true,
    defaultRole = 'user',
    workspaceRole = 'guest',
    showGroups = true,
    filterUsers,
    containerProps,
}: Props) {
    const { data: appUsers = [] } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: true,
    })
    const [selectedUser, setSelectedUser] = useState<AddUserOption | undefined>(undefined)
    const { hasRight } = useAccountUserContext()
    const [selectedRole, setSelectedRole] = useState(defaultRole)
    const [emailAddress, setEmailAddress] = useState('')
    const { selectedStack } = useAppContext()
    const showRoles = selectedStack?.options?.roles__enabled && shouldShowRoles

    const { createRecord } = useObject(userList?.object_id)
    const emailField = useField(userList?.email_field_id)
    const { mutateAsync: updateStack } = useUpdateStack()
    const { mutateAsync: addUser } = useAddWorkspaceUser()
    const canInvite = hasRight(Rights.InviteUsers)
    const syncUsers = useSyncUsers()

    const { data: roles } = useRoleListOptions()
    const { options: userOptions } = useAddAppUsersOptions({
        appUsers,
        userList,
        showGroups,
        filterUserOverride: filterUsers,
    })

    // If we are connected to a user list, then we want to trigger a stacker-users table sync
    // at the end of the save after we've done all our operations.
    const syncUsersAfterSave = !!userList

    const addUserToSharingSettings = (userId, role) => {
        const item = userOptions.find((u) => u.value === userId)
        const patch = updateStackSharingSettings(selectedStack, item?.group ? 'groups' : 'users', {
            [userId]: role,
        })
        assertIsDefined(selectedStack)
        return updateStack({
            id: selectedStack._sid,
            patch: { ...patch, skip_users_sync: syncUsersAfterSave },
        }).then(async () => {
            await refetchAppUsersForAdmin()
        })
    }

    const createRecordIfNeeded = (email, addRecord) => {
        if (addRecord && userList?.options?.mode === 'manual' && emailField) {
            return createRecord({
                [emailField.api_name]: email,
                // @ts-ignore
            }).then(() => undefined)
        } else {
            return Promise.resolve(undefined)
        }
    }

    const syncUsersIfNeeded = () => {
        return syncUsersAfterSave ? syncUsers() : Promise.resolve(undefined)
    }

    const refreshAfterSave = () => {
        return syncUsersIfNeeded()
            .then(refetchAppUsersForAdmin)
            .then(() => {
                setEmailAddress('')
                setSelectedUser(undefined)
            })
    }
    const handleInvite = (emailAddress, addRecord = false) => {
        // Add the user to the workspace first, then add to the app
        return addUser({
            email: emailAddress,
            options: { role: workspaceRole },
            skip_users_sync: true,
            send_email: !selectedStack?.user_access_disabled,
        }).then((response) => {
            return addUserToSharingSettings(response._sid, selectedRole)
                .then(() => createRecordIfNeeded(emailAddress, addRecord))
                .then(refreshAfterSave)
        })
    }

    const handleSubmit = () => {
        const selectedOption = userOptions.find((option) => option.value === selectedUser)
        if (emailAddress) {
            return handleInvite(emailAddress, true)
        } else if (selectedOption?.record) {
            return handleInvite(selectedOption?.email)
        } else {
            return addUserToSharingSettings(selectedUser, selectedRole).then(refreshAfterSave)
        }
    }

    const isEmailAddressDuplicate =
        emailAddress &&
        appUsers.some((user) => user.email.toLowerCase() === emailAddress.trim().toLowerCase())

    const finalUserOptions = [
        ...userOptions,
        ...(canInvite && emailAddress && !isEmailAddressDuplicate
            ? [{ isInvitation: true, value: emailAddress, label: emailAddress }]
            : []),
        ...(canInvite && !emailAddress
            ? [
                  {
                      isInvitation: true,
                      value: '',
                      label: 'someone by typing their email address',
                  },
              ]
            : []),
    ]

    const getNoOptionsMessage = ({ inputValue }) => {
        const existingUser = appUsers.find(
            (x) => x.email.toLowerCase() === inputValue.trim().toLowerCase()
        )
        if (existingUser) {
            return `${existingUser.name || existingUser.email} already has access to this app`
        }

        if (canInvite) {
            return 'Enter an email address to invite someone.'
        }

        return 'No matching users found.'
    }

    const handleInputChange = (value) => {
        if (isEmailRegEx.test(value) && !appUsers.find((x) => x.email === value)) {
            setEmailAddress(value)
        }
    }

    const placeholder = canInvite
        ? userOptions.length > 0
            ? 'Select user or enter email address'
            : 'Enter an email address'
        : 'Select user'
    return (
        <>
            <Flex wrap="nowrap" align="stretch" {...containerProps}>
                <Dropdown
                    placeholder={placeholder}
                    style={{
                        flexShrink: 1,
                        flexGrow: 1,
                        minWidth: 0,
                        background: 'white',
                    }}
                    onChange={(userId) => {
                        if (userId !== emailAddress) setEmailAddress('')
                        setSelectedUser(userId)
                    }}
                    value={selectedUser || emailAddress}
                    padding="5px"
                    noOptionsMessage={getNoOptionsMessage}
                    optionComponent={UserDropdownOption}
                    options={finalUserOptions}
                    onInputChange={handleInputChange}
                    shouldHidePlaceholderOnFocus={true}
                    renderValue={(data) => (
                        <UserDropdownOptionContent data={data} isSelected={true} />
                    )}
                />
                <Box width={2} height={2} />
                {showRoles && (
                    <SelectRole
                        roles={roles}
                        placeholder="Select a role"
                        selected={selectedRole}
                        onChange={setSelectedRole}
                        variant="settings"
                    />
                )}
                <Button
                    ml={2}
                    variant="adminPrimaryV4"
                    alignSelf="center"
                    buttonSize="sm"
                    flexShrink={0}
                    disabled={
                        (!selectedUser && !canInvite) ||
                        (!selectedUser && !emailAddress) ||
                        // @ts-ignore
                        selectedRole === selectedStack?.sharing?.users?.[selectedUser]
                    }
                    onClick={() => {
                        // @ts-ignore
                        analytics.track('app sharing user added', {
                            selectedUser,
                            selectedRole,
                        })

                        return handleSubmit()
                    }}
                >
                    {canInvite && (emailAddress || userOptions.length === 0) ? 'Invite' : 'Add'}
                </Button>
            </Flex>
        </>
    )
}

type useAddAppUsersOptionsProps = {
    appUsers: any[]
    userList?: UserListDto
    showGroups: boolean
    filterUserOverride?: (user: any) => boolean
}
function useAddAppUsersOptions({
    appUsers,
    userList,
    showGroups,
    filterUserOverride,
}: useAddAppUsersOptionsProps) {
    const { data: workspaceUsers = [], isLoading: isWorkspaceUsersLoading } = useWorkspaceUsers()

    const { selectedStack } = useAppContext()

    const emailField = useField(userList?.email_field_id)
    const emailFieldApiName = emailField?.api_name
    const { data: { records = [] } = {}, isLoading: isRecordsLoading } = useRecords({
        objectId: userList?.object_id,
    })
    const sharingWithGroups = selectedStack?.sharing.groups || {}
    const userOptions = useMemo(() => {
        const groups = showGroups
            ? workspaceGroups
                  .filter(({ _sid }) => !sharingWithGroups[_sid])
                  .map((group) => ({ group, value: group._sid, label: group.name }))
            : []

        const users = orderBy(
            workspaceUsers
                .filter((workspaceUser) =>
                    filterUserOverride
                        ? filterUserOverride(workspaceUser)
                        : !appUsers.find((user) => user._sid === workspaceUser._sid && !user.group)
                )
                .map((user) => ({
                    user,
                    name: user.name || user.email,
                    email: user.email,
                    value: user._sid,
                    label: `${user.name} ${user.email}`,
                })),
            (item) => item.name.toLowerCase()
        )

        // The record name should be a string. Check the primary field type before setting to name to prevent a crash
        const getName = (record: any) => {
            const primary = record._primary || (emailFieldApiName && record[emailFieldApiName])
            switch (typeof primary) {
                case 'string':
                    return primary
                case 'number':
                    return primary.toString()
                default:
                    return ' '
            }
        }

        const userTableRecords = orderBy(
            records
                .map((record) => ({
                    record,
                    value: record._sid,
                    name: getName(record),
                    email: emailFieldApiName ? record[emailFieldApiName] : '',
                    label: `${record._primary} ${
                        emailFieldApiName ? record[emailFieldApiName] : ''
                    }`,
                }))
                .filter(
                    (item) =>
                        !!item.email &&
                        !appUsers.find(
                            (user) => user.email.toLowerCase() === item.email.toLowerCase()
                        ) &&
                        !workspaceUsers.find(
                            (user) => user.email.toLowerCase() === item.email.toLowerCase()
                        )
                ),
            (item) => item.name.toLowerCase()
        )

        return [...groups, ...users, ...userTableRecords]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceUsers, appUsers, sharingWithGroups, emailFieldApiName, records])

    return {
        options: userOptions,
        isLoading: isRecordsLoading || isWorkspaceUsersLoading,
    }
}

function UserDropdownOption(props) {
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props

    return (
        <components.Option {...rest}>
            <UserDropdownOptionContent data={props.data}>
                {props.children}
            </UserDropdownOptionContent>
        </components.Option>
    )
}

function UserDropdownOptionContent(props): React.ReactElement {
    const { data, children, isSelected } = props
    const { email, group, label, name, record, user } = data
    if (group) {
        return (
            <Flex align="center">
                <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                    <Icon icon="users" size="sm" color="gray.400" />
                </Flex>

                <strong>{group.name}</strong>
            </Flex>
        )
    } else if (user || record) {
        return (
            <Flex align="center">
                <Avatar src={user?.avatar} name={name} size="xs" mr={2} />
                <strong>{name}</strong>
                <span style={{ marginLeft: '8px' }}>{email}</span>
            </Flex>
        )
    } else if (!isSelected) {
        return (
            <Flex align="center" cursor="pointer">
                <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                    <Icon icon="email" size="sm" color="gray.400" />
                </Flex>
                Invite {children}
            </Flex>
        )
    } else if (isEmailRegEx.test(label)) {
        return <>{label}</>
    } else {
        return <></>
    }
}

export function SelectRole({ selected, onChange, roles, placeholder = 'No access', ...props }) {
    return (
        <Dropdown
            value={selected}
            options={roles}
            placeholder={placeholder}
            onChange={onChange}
            isSearchable={false}
            data-testid="select-role"
            {...props}
        />
    )
}
function updateStackSharingSettings(stack, path, update) {
    return {
        sharing_patch: {
            [path]: {
                ...update,
            },
        },
    }
}
