import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { bindActionCreators } from 'redux'

import store from 'app/store'
import { userActions } from 'data/api/userApi'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { invalidatePages } from 'data/hooks/pages'
import getCurrentUser from 'data/utils/getCurrentUser'
import getObjectApiName from 'data/utils/getObjectApiName'

// Checks to see if the current user role has changed
const roleUpdate = (previousState, stack) => {
    const user = getCurrentUser()

    if (!stack || !user) return

    const stackOptions = get(stack, 'options')

    const rolesEnabled = get(stackOptions, 'roles__enabled', false) === true
    const roleFieldId = get(stackOptions, 'data_mapping.role_field')
    const roleFieldApiName = roleFieldId && getObjectApiName(roleFieldId)

    const boundUserActions = bindActionCreators(userActions, store.dispatch)

    // user role has updated
    if (
        rolesEnabled &&
        roleFieldId &&
        !isEqual(get(previousState, `user.${roleFieldApiName}`), get(user, roleFieldApiName))
    ) {
        invalidatePages()
        boundUserActions.getUser()
        invalidateObjects()
    }
}

export default roleUpdate
