/* Code Quality: Good */

import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import { DISPLAY_NAMES, withDataConnections } from 'data/wrappers/WithDataConnections'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'

import { Button, Divider, Icon, MenuItem } from 'v2/ui'

import DataConnectionProviders from '../../admin/data-connector/dataProviderConfig'

const SettingsMenu = ({
    stack,
    context,
    style,
    navTheme,
    children,
    as,
    dataConnections,
    icon = 'cog',
    ...props
}) => {
    let permissionsUrl = null
    let permissionsLabel = null
    let fieldsUrl = null
    let fieldsLabel = null
    let settingsUrl = null
    let settingsLabel = null

    if (context && context.feature && context.feature.url) {
        permissionsUrl = `${getUrl(Urls.AdminSetup)}${context.feature.url}/permissions`
        settingsUrl = `${getUrl(Urls.AdminSetup)}${context.feature.url}/settings`
        fieldsUrl = `${getUrl(Urls.AdminSetup)}${context.feature.url}/fields`
    }
    if (context && context.feature) {
        permissionsLabel = `${context.feature.name} Permissions`
        settingsLabel = `${context.feature.name} Settings`
        fieldsLabel = `${context.feature.name} Fields`
    }

    let dataConfigText
    let dataConfigLink = Urls.AdminMappingUpdate
    // Don't show either until the Stack is loaded.
    if (stack.name) {
        dataConfigText = `${DISPLAY_NAMES[stack.portal_type] || 'Airtable'} Update`
    }

    const workspaceApp = get(stack, 'options.workspace_app')

    const menu = (
        <>
            {settingsUrl && (
                <MenuItem as={Link} to={settingsUrl}>
                    {settingsLabel}
                </MenuItem>
            )}
            {fieldsUrl && (
                <MenuItem as={Link} to={fieldsUrl}>
                    {fieldsLabel}
                </MenuItem>
            )}
            {permissionsUrl && (
                <MenuItem as={Link} to={permissionsUrl}>
                    {permissionsLabel}
                </MenuItem>
            )}
            {/* {configurePageUrl ? (
                <Link to={configurePageUrl}>
                    <MenuItem padding="none" style={{ fontSize: '15px', color: '' }}>
                        {configureLabel}
                    </MenuItem>

            ) : (
                <MenuItem padding="none" style={{ fontSize: '15px', color: '', opacity: 0.5 }}>
                    {configureLabel || 'Layout'}
                </MenuItem>
            )} */}
            <Divider />
            {!workspaceApp && (
                <MenuItem as={Link} to={getUrl(Urls.AdminUsers)}>
                    Impersonate Users
                </MenuItem>
            )}
            {stack.portal_type !== 'mixed' ? (
                <MenuItem as={Link} to={getUrl(dataConfigLink)}>
                    {dataConfigText}
                </MenuItem>
            ) : (
                dataConnections
                    .filter(
                        (connection) => DataConnectionProviders?.[connection.type]?.allowSchemaSync
                    )
                    .map((connection) => {
                        const provider = DataConnectionProviders?.[connection.type] || {}
                        const ProviderIcon = provider.icon
                        return (
                            <MenuItem
                                key={connection._sid}
                                as={Link}
                                to={`${getUrl(Urls.AdminMappingUpdate)}?connection=${
                                    connection._sid
                                }&type=${connection.type}`}
                            >
                                {provider.icon && <ProviderIcon width={18} height={18} />}
                                <span style={{ paddingLeft: 8 }} />
                                Update {connection.label}
                            </MenuItem>
                        )
                    })
            )}
            <MenuItem as={Link} to={getUrl(Urls.AdminMenu)}>
                Configure Navigation
            </MenuItem>
            <Divider />
            <MenuItem
                tabindex="-1"
                onClick={() => {
                    window.CommandBar.open()
                }}
            >
                Shortcuts
            </MenuItem>
            <Divider />
            <MenuItem as={Link} to={getUrl(Urls.Admin)}>
                Setup Home
            </MenuItem>
        </>
    )

    return (
        <Menu color="black">
            <MenuButton
                as={as || Button}
                navTheme={navTheme}
                {...props}
                style={{
                    ...style,
                }}
            >
                {children || <Icon size="16" icon={icon} />}
            </MenuButton>
            <MenuList color="black" zIndex={9999}>
                {menu}
            </MenuList>
        </Menu>
    )
    /*
    return (
        <SettingsMenuContainer menu={menu}>
            <Icon8
                icon="settings"
                size="100"
                displaySize="25"
                iconStyle="ios-filled"
                color={color.replace('#', '')}
                style={{ marginRight: 10, ...style }}
            />
        </SettingsMenuContainer>
    )*/
}

SettingsMenu.propTypes = {
    location: PropTypes.object.isRequired, // from withRouter
    stack: PropTypes.object.isRequired, // From withStacks
}

export default withStack(withRouter(withUser(withDataConnections(SettingsMenu))))
