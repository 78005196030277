import React from 'react'

import SyncSchemaButtons, {
    useSyncSchemaFivetranCopy,
} from 'features/AppSettings/DataSources/SyncSchemaButtons'

import { Button, Flex, Text } from 'v2/ui'
import { BigRocket, EmptyBox } from 'v2/ui/svgs'

export const ManageDataEmptyState: React.FC<{
    dataConnection?: DataConnectionDto
    hasAllTablesDeactivated: boolean
    showImportCsvTableButton: boolean
    showImportTableDataButton: boolean
    onAddTable: () => void
    onImportCsvTable: () => void
    onImportTableData: () => void
}> = ({
    dataConnection,
    hasAllTablesDeactivated,
    onAddTable,
    onImportCsvTable,
    onImportTableData,
    showImportCsvTableButton,
    showImportTableDataButton,
}) => {
    const { type } = dataConnection ?? {}
    const { emptyStateCopy } = useSyncSchemaFivetranCopy(dataConnection)

    if (!dataConnection) return null

    if (type === 'fivetran') {
        return (
            <Flex alignItems="center" justifyContent="center" column>
                <BigRocket />
                <Text variant="heading" my={4}>
                    {emptyStateCopy || 'Your data is still syncing'}
                </Text>
                <SyncSchemaButtons
                    width="100%"
                    buttonStyle={{ width: '100%', margin: 0 }}
                    dataConnection={dataConnection}
                />
            </Flex>
        )
    }

    return (
        <Flex alignItems="center" justifyContent="center" column>
            <EmptyBox />
            <Text variant="heading" my={4}>
                {hasAllTablesDeactivated
                    ? "You don't have any active tables"
                    : "You don't have any tables yet"}
            </Text>
            <Flex direction="row">
                <Button
                    mr="10px"
                    width="200px"
                    variant="Primary"
                    onClick={onAddTable}
                    p={2}
                    fontSize="14px"
                    data-testid="manage-data.add-table-button"
                >
                    Add new table
                </Button>
                {showImportCsvTableButton && (
                    <Button
                        width="200px"
                        variant="Secondary"
                        onClick={onImportCsvTable}
                        p={2}
                        mr="10px"
                        fontSize="14px"
                        data-testid="manage-data.import-csv-table-button"
                    >
                        Import from CSV
                    </Button>
                )}
                {showImportTableDataButton && (
                    <Button
                        width="200px"
                        variant="Secondary"
                        onClick={onImportTableData}
                        p={2}
                        fontSize="14px"
                        data-testid="manage-data.import-table-data-button"
                    >
                        Paste data from spreadsheet
                    </Button>
                )}
            </Flex>
        </Flex>
    )
}
