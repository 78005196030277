import { StackerPlans } from '../../../data/hooks/planPicker'
import { SubscriptionStatus } from '../types'

import { BillingPeriods, BillingPeriodViewText, PlanCompModel } from './types'

const isCurrentPlan = (
    plan: StackerPlans,
    billingPeriod: BillingPeriods,
    subscriptionStatus: string
): boolean => subscriptionStatus !== 'cancelled' && plan.prices[billingPeriod].current_plan

const isDisabled = (
    plan: StackerPlans,
    billingPeriod: BillingPeriods,
    subscriptionStatus: SubscriptionStatus
): boolean => {
    return subscriptionStatus === 'cancelled'
        ? false
        : plan.disabled ||
              isCurrentPlan(plan, billingPeriod, subscriptionStatus) ||
              plan.prices[billingPeriod].disabled
}

const isEnterprisePlan = (plan: StackerPlans): boolean => plan.tier === 'Enterprise'

const buttonText = (isCurrentPlan: boolean, isEnterprisePlan: boolean): string => {
    return isCurrentPlan ? 'Current Plan' : isEnterprisePlan ? 'Contact Us' : 'Select Plan'
}

const buttonLabel = (isCurrentPlan: boolean, isEnterprisePlan: boolean, tier: string): string => {
    return isCurrentPlan
        ? `Current Plan ${tier}`
        : isEnterprisePlan
        ? `Contact Us For ${tier}`
        : `Select ${tier} Plan`
}

const buttonTestId = (tier: string): string => `plan-picker.select-plan.${tier.toLowerCase()}`

const billingViewText = (billingPeriod: BillingPeriods): BillingPeriodViewText =>
    billingPeriod === 'month' ? 'monthly' : 'yearly'

const buildPlanCompModels = (
    plans: StackerPlans[],
    billingPeriod: BillingPeriods,
    subscriptionStatus: SubscriptionStatus
): PlanCompModel[] => {
    return plans.map((plan: StackerPlans) => {
        return {
            isDisabled: isDisabled(plan, billingPeriod, subscriptionStatus),
            isEnterprisePlan: isEnterprisePlan(plan),
            buttonText: buttonText(
                isCurrentPlan(plan, billingPeriod, subscriptionStatus),
                isEnterprisePlan(plan)
            ),
            buttonLabel: buttonLabel(
                isCurrentPlan(plan, billingPeriod, subscriptionStatus),
                isEnterprisePlan(plan),
                plan.tier
            ),
            buttonTestId: buttonTestId(plan.tier),
            basePrice: plan.prices[billingPeriod].base_price,
            features: plan.features,
            tier: plan.tier,
            billingPeriod: billingViewText(billingPeriod),
            priceId: plan.prices[billingPeriod].price_id,
        }
    })
}

export { buildPlanCompModels }
export type { BillingPeriodViewText, PlanCompModel }
