// @ts-strict-ignore
import { useMemo } from 'react'

import { Theme as GlideTheme } from '@glideapps/glide-data-grid'

import stackerTheme from 'v2/ui/theme/styles/default'
const appTheme = stackerTheme()
const { colors, fonts } = appTheme

export const CellThemeOverride: Partial<GlideTheme> = {
    bgCell: colors.userInterface.neutral[0],
    accentLight: colors.userInterface.neutral[200],
    borderColor: colors.userInterface.neutral[400],
}

const AddPlaceHolderStyles = {
    textMedium: colors.userInterface.accent[1200],
    textLight: colors.userInterface.neutral[0],
    textHeader: colors.userInterface.neutral[0],
}
export const AddRowThemeOverride: Partial<GlideTheme> = {
    ...AddPlaceHolderStyles,
    bgIconHeader: colors.userInterface.accent[1200],
    fgIconHeader: colors.userInterface.accent[1200],
    bgHeaderHovered: colors.userInterface.neutral[100],
}

export const AddFieldColumnThemeOverride: Partial<GlideTheme> = {
    ...AddPlaceHolderStyles,
    bgIconHeader: colors.userInterface.neutral[0],
    bgHeader: colors.userInterface.accent[1200],
    bgHeaderHovered: colors.userInterface.accent[1000],
    bgHeaderHasFocus: colors.userInterface.accent[1200],
}

export const useDataGridTheme = () => {
    return useMemo(
        () => ({
            accentColor: colors.userInterface.neutral[600],
            accentFg: colors.userInterface.neutral[100],
            accentLight: colors.userInterface.neutral[400],
            bgHeader: colors.userInterface.neutral[200],
            bgHeaderHovered: colors.userInterface.neutral[100],
            bgHeaderHasFocus: colors.userInterface.neutral[400],
            fontFamily: fonts.body,
            bgCell: colors.userInterface.neutral[400],
            borderColor: colors.userInterface.neutral[400],
        }),
        []
    )
}
