import { useEffect } from 'react'

/**
 * Override Image.prototype so that Cohere can track datagrid activity
 */
const useOverrideImage = () => {
    useEffect(() => {
        const _Image = window.Image

        const overrideImage = () => {
            // @ts-ignore
            window.Image = function (width, height) {
                const res = new _Image(width, height)
                res.crossOrigin = 'Anonymous'
                return res
            }
        }
        const restoreImage = () => {
            window.Image = _Image
        }
        overrideImage()

        return () => {
            restoreImage()
        }
    }, [])
}

export default useOverrideImage
