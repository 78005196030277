import { useMemo } from 'react'

import isUrl from 'is-url'
import { ViewLayoutContext } from 'v2/blocks/types'

export function getQuickLinksUrlFromField(
    link?: QuickLinksBlockItemLink,
    context?: ViewLayoutContext
): string {
    if (!context?.record || link?.type !== 'field' || !link?.fieldId) return ''

    const field = context.object?.fields?.find((f) => f._sid === link.fieldId)
    if (!field) return ''

    const url = (context.record as RecordDto)[field.api_name] ?? ''

    const isUrlValid = isUrl(url)
    if (!isUrlValid) return ''

    return url
}

export function useFieldLink(link?: QuickLinksBlockItemLink, context?: ViewLayoutContext): string {
    const url = getQuickLinksUrlFromField(link, context)

    return useMemo(() => url, [url])
}
