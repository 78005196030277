import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type FlagProps = {
    backgroundColor?: string
    size?: number
}
const Flag: React.FC<FlagProps> = ({
    backgroundColor = colors.userInterface.accent[200],
    size = 38,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            style={{
                minWidth: size,
                minHeight: size,
            }}
        >
            <circle cx="19" cy="19" r="19" fill={backgroundColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m17.983 35.704 1.905-.876.101-.047L5.759 3.838l-2.006.923 14.23 30.943z"
                fill="#7E8EE4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.768 3.28A2.338 2.338 0 1 0 2.52 5.235a2.338 2.338 0 0 0 4.248-1.953z"
                fill="#7E8EE4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.586 3.347a1.045 1.045 0 1 0-1.899.873 1.045 1.045 0 0 0 1.9-.873z"
                fill="#B5BEEF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.087 17.52A24.953 24.953 0 0 1 32 20.31a28.61 28.61 0 0 0-1.835-6.848 30.863 30.863 0 0 1 7.025-3.06 36.137 36.137 0 0 0-14.36-4.048c-.246 3.723-.494 7.444-.744 11.166z"
                fill="#465DD8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.074 6.72a36.137 36.137 0 0 0-3.243-.366c-.246 3.723-.494 7.444-.744 11.166.745.05 1.49.133 2.229.249l1.758-11.05z"
                fill="#384AAD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.63 16.97a25.654 25.654 0 0 0-13.876 2.105L4.796 8.195A37.604 37.604 0 0 1 25.139 5.11L23.63 16.97z"
                fill="#465DD8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m2.179 2.725.623.994.871-.786-.752.9 1.017.585-1.089-.437-.242 1.148.079-1.17-1.166.124 1.137-.287-.478-1.07z"
                fill="#fff"
            />
        </svg>
    )
}

export default Flag
