import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    COMPOUNDS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from '../utils/constants'

// STATE
export const initialState = {
    compounds: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function compoundReducer(state = initialState, action) {
    let fetching
    switch (action.type) {
        case COMPOUNDS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                compounds: unique([
                    ...state.compounds,
                    ...action.payload.map((compound) => compound._sid),
                ]),
                entities: { ...state.entities, ...byID(action.payload) },
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
