import React from 'react'

export const LinkFormFieldsPicker = ({ source, target }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 5 }}>If </span>
            <div style={{ marginRight: 5 }}>{source}</div>
            <span style={{ marginRight: 5 }}> matches </span>
            <div>{target}</div>
        </div>
    )
}
