import { FormulaEditorFieldOrFunctionSelection } from '../selection/formulaSelectionTypes'

export const insertAutocompleteText = ({
    editor,
    selection,
    newText,
}: {
    editor: HTMLTextAreaElement
    selection?: FormulaEditorFieldOrFunctionSelection
    newText: string
}): { insertedTextStart: number; insertedTextEnd: number; result: string } => {
    let [start, end] = [editor.selectionStart - (selection?.name.length || 0), editor.selectionEnd]

    if (selection?.type === 'field') {
        if (editor.value.substring(start - 1, start) === '{') {
            start--
        }
    } else if (selection?.type === 'function') {
        if (editor.value.substring(end - 1, end) === '(') {
            start--
        }
    }

    editor.setRangeText(newText, start > 0 ? start : 0, end, 'preserve')
    return {
        insertedTextStart: start,
        insertedTextEnd: start + newText.length,
        result: editor.value,
    }
}
