// @ts-strict-ignore
import React from 'react'

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAppUserContext } from 'app/AppUserContext'
import { refetchAccounts } from 'data/hooks/accounts'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { assertIsDefined } from 'data/utils/ts_utils'
import { fetchAndReturn } from 'data/utils/utils'
import { getProvidersForOnboarding } from 'features/admin/data-connector/DataConnectors'
import publicAsset from 'utils/publicAsset'

import { Flex, ScrollBox, Text } from 'v2/ui'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import { ProviderTypes } from '../../admin/data-connector/dataConnectorTypes'
import DATA_PROVIDERS from '../../admin/data-connector/dataProviderConfig'

import { ConnectionModalMode, ProviderNameWithIconHeader } from './ConnectionModalHeader'
import { ModalWithHint } from './ModalWithHint'
import { ProviderPickerItem } from './providerPickerTypes'
import { ResponsiveProviderPicker, ResponsiveProviderRow } from './ResponsiveProviderPicker'

type ResponsiveNewConnectionModalProps = {
    stack: any
    onProviderChange: (id: ProviderTypes) => void
    provider: ProviderTypes
    serviceName?: string
    hintImageUrl?: string
    workspaceAccount: any
    demoApps?: {
        onFinishedCreatingDemoApp: (stack: any) => void
        apps: ProviderPickerItem<string>[] | 'LOADING'
    }
    showCloseButton: boolean
    isAppCreateFlow?: boolean
    onClose: (data?: DataConnectionDto) => void
    allowBackButton: boolean
    isOpen: boolean
    trapFocus?: boolean
}

export const ResponsiveNewConnectionModal: React.FC<ResponsiveNewConnectionModalProps> = (
    props
) => {
    const [step, setStep] = useUrlParam<'NOT_SELECTED' | 'SELECTED'>(
        'responsiveNewConnectionStep',
        props.isAppCreateFlow ? 'NOT_SELECTED' : 'SELECTED'
    )
    const { isOpen } = props
    const { user } = useAppUserContext()

    const [isDemoAppLoading, setIsDemoAppLoading] = React.useState(false)
    const demoAppLoading = () => {
        refetchAccounts().then((val) => {
            assertIsDefined(val[0].state.data)
            const account = val[0].state.data.find(
                (account) => account._sid === props.workspaceAccount._sid
            )
            assertIsDefined(account)
            if (!account.setup_data.demo_apps_being_created) {
                refetchStacks().then((val) => {
                    assertIsDefined(val[0].state.data)
                    const stack = val[0].state.data.find(
                        (stack) =>
                            stack.options.is_demo &&
                            stack.account_id === props.workspaceAccount._sid
                    )
                    assertIsDefined(stack)
                    assertIsDefined(props.demoApps)
                    props.demoApps.onFinishedCreatingDemoApp(stack)
                })
            } else {
                setTimeout(demoAppLoading, 500)
            }
        })
    }

    const handleDemoAppClick = (id: string) => {
        setIsDemoAppLoading(true)
        fetchAndReturn('onboarding-demo-apps/', {
            method: 'POST',
            body: JSON.stringify({
                demo_app_id: id,
                account_id: props.workspaceAccount._sid,
                user_id: user?._sid,
            }),
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        }).then(() => {
            demoAppLoading()
        })
    }

    if (step === 'NOT_SELECTED') {
        return (
            <ModalWithHint
                onClose={props.onClose}
                headerText={'Connect your data'}
                isHidden={false}
                trapFocus={props.trapFocus}
                isOpen={isOpen}
                showCloseButton={props.showCloseButton}
                hintImageUrl={publicAsset('/static/media/ResponsiveOnboardingDataSourceHero.svg')}
            >
                <ResponsiveProviderPicker
                    items={getProvidersForOnboarding(props.stack)}
                    onClick={(p) => {
                        setStep('SELECTED')
                        props.onProviderChange(p)
                    }}
                />
            </ModalWithHint>
        )
    } else {
        const p = DATA_PROVIDERS[props.provider]
        return (
            <ModalWithHint
                onClose={props.onClose}
                showCloseButton={props.showCloseButton}
                headerText={null}
                isHidden={false}
                isOpen={isOpen}
                forceHideImage={props.hintImageUrl === undefined}
                hintImageUrl={
                    p.notResponsive !== true && props.hintImageUrl ? props.hintImageUrl : undefined
                }
                hintImageClassAndStyle={
                    p.notResponsive === true
                        ? {
                              className: 'fas fa-laptop-medical',
                              style: {
                                  maxWidth: 180,
                                  height: 180,
                                  color: '#4a5ed8',
                                  fontSize: '80px',
                                  background: '#dadef7',
                                  borderRadius: '50%',
                              },
                          }
                        : {
                              style: { maxWidth: '95%', height: '95%' },
                              containerStyle: { height: step === 'SELECTED' ? '60%' : '40%' },
                          }
                }
            >
                {props.allowBackButton ? (
                    <Flex
                        style={{
                            position: 'absolute',
                            top: 18,
                            left: 18,
                            cursor: 'pointer',
                            height: 20,
                            background: 'rgba(255,255,255, 0.8)',
                        }}
                        onClick={() => setStep('NOT_SELECTED')}
                    >
                        {/* @ts-ignore */}
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <Text style={{ marginLeft: 8 }}>Back</Text>
                    </Flex>
                ) : null}
                <ProviderNameWithIconHeader
                    serviceName={props.serviceName}
                    type={props.provider}
                    mode={p.notResponsive ? ConnectionModalMode.Mobile : ConnectionModalMode.Create}
                />
                {p.notResponsive === true ? (
                    <NonResponsiveProvider
                        demoApps={
                            props.demoApps === undefined
                                ? undefined
                                : {
                                      apps: props.demoApps.apps,
                                      handleDemoAppClick: handleDemoAppClick,
                                  }
                        }
                        isLoading={isDemoAppLoading}
                        name={p.name}
                        onChooseDifferentDataSourceClick={() => setStep('NOT_SELECTED')}
                    />
                ) : (
                    props.children
                )}
            </ModalWithHint>
        )
    }
}

type NonResponsiveProviderProps = {
    name: string
    onChooseDifferentDataSourceClick: () => void
    isLoading: boolean
    demoApps?: {
        handleDemoAppClick: (id: string) => void
        apps: ProviderPickerItem<string>[] | 'LOADING'
    }
}

const NonResponsiveProvider: React.FC<NonResponsiveProviderProps> = (props) => {
    if (props.isLoading) {
        return (
            <img
                alt=""
                src={publicAsset('/static/media/Spinner.svg')}
                style={{
                    alignSelf: 'center',
                    marginLeft: 0,
                    marginRight: 8,
                    height: 64,
                    width: '100%',
                }}
            />
        )
    }
    return (
        <Flex direction={'column'} style={{ height: '38vh', alignItems: 'stretch' }} wrap="nowrap">
            {props.demoApps === undefined ? (
                <Text
                    variant="modal"
                    style={{
                        fontWeight: 600,
                        textAlign: 'center',
                        marginBottom: 24,
                        fontSize: '18px',
                    }}
                >
                    Login using a computer to create an app from your {props.name} base
                </Text>
            ) : (
                <TryADemoApp
                    demoApps={props.demoApps.apps}
                    onDemoAppClick={props.demoApps.handleDemoAppClick}
                />
            )}
            <Flex
                style={{
                    cursor: 'pointer',
                    marginTop: 8,
                }}
                onClick={props.onChooseDifferentDataSourceClick}
            >
                <Text style={{ justifyContent: 'flex-start', flexGrow: 1, marginBottom: 0 }}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span style={{ marginLeft: 16 }}>Change data source</span>
                </Text>
            </Flex>
        </Flex>
    )
}

type TryADemoAppProps = {
    demoApps: ProviderPickerItem<string>[] | 'LOADING'
    onDemoAppClick: (id: string) => void
}
const TryADemoApp: React.FC<TryADemoAppProps> = (props) => {
    return (
        <>
            <Text
                variant="modal"
                style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    marginBottom: 24,
                    fontSize: '18px',
                }}
            >
                In the meantime, you can try a demo app on mobile.
            </Text>
            <ScrollBox style={{ flexGrow: 1 }}>
                {props.demoApps === 'LOADING'
                    ? 'Loading....'
                    : props.demoApps.map((app) => {
                          return (
                              <ResponsiveProviderRow
                                  key={app.id}
                                  item={app}
                                  onClick={props.onDemoAppClick}
                              />
                          )
                      })}
            </ScrollBox>
        </>
    )
}
