import React from 'react'

import { ButtonProps } from '@chakra-ui/react'

import WithPages from 'data/wrappers/WithPages'
import WithViews from 'data/wrappers/WithViews'
import { GlobalCallbackKeys } from 'features/commandbar/commandbar-keys'
import CommandBarShortcut from 'features/commandbar/CommandBarShortcut'

import { Button } from 'v2/ui'

import { getCreateViewUrl } from '../utils/getCreateViewUrl'

type CreateButtonProps = {
    objectId: string
    feature?: FeatureDto
    queryParams?: string
    text?: string
    buttonVariant?: string
    buttonSize?: string
    size?: string
} & Omit<ButtonProps, 'children'>

const CreateButton = ({
    objectId,
    feature,
    queryParams,
    size,
    buttonSize = 'medium',
    buttonVariant = 'App',
    ...props
}: CreateButtonProps) => {
    return (
        <WithViews allStacks={false}>
            {({ views }: { views: ViewDto[] }) => {
                const createView = views.find(
                    (view) => view.object_id === objectId && view.type === 'create'
                )
                if (createView) {
                    return (
                        <WithPages allStacks={false}>
                            {({ pages }: { pages: PageDto[] }) => {
                                const createViewUrl = getCreateViewUrl(
                                    createView,
                                    pages,
                                    feature,
                                    queryParams
                                )
                                const viewPage = pages.find(
                                    (page) => page.options.view_id === createView._sid
                                )
                                if (!feature || !viewPage) return null
                                return (
                                    <>
                                        {createViewUrl && (
                                            <CommandBarShortcut
                                                name={GlobalCallbackKeys.NewRecord}
                                                action={createViewUrl}
                                            />
                                        )}
                                        <Button
                                            variant={buttonVariant}
                                            buttonSize={buttonSize}
                                            icon="add"
                                            ml={1}
                                            href={createViewUrl}
                                            {...props}
                                            iconProps={{
                                                pr: props.text === '' ? 0 : undefined,
                                            }}
                                            style={{
                                                paddingRight: props.text === '' ? 11 : undefined,
                                                paddingLeft: props.text === '' ? 11 : undefined,
                                            }}
                                            className="stk-add-new-button"
                                            size={size}
                                        >
                                            {props.text ?? 'Add New'}
                                        </Button>
                                    </>
                                )
                            }}
                        </WithPages>
                    )
                }

                return null
            }}
        </WithViews>
    )
}

export default CreateButton
