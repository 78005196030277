import React, { useContext } from 'react'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import LayoutObjectEditor from 'features/utils/LayoutObjectEditor'
import { BlankPageHeaderEditor } from 'features/views/ViewHeader/BlankPageHeaderEditor'

import BlankPageRolesEditor from './BlankPageRolesEditor'
import BlankPageViewEditorBlockPane from './BlankPageViewEditorBlockPane'

const HEADER_EDITOR_TITLE = 'Header'
const ROLES_EDITOR_TITLE = 'Roles'

type BlankPageViewEditorPanesProps = {
    onChange: (patch: Partial<ViewDto>, onCommitChange?: () => void) => void
    view?: ViewDto
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
}

const BlankPageViewEditorPanes: React.FC<BlankPageViewEditorPanesProps> = ({
    onChange,
    view,
    pageRoles,
    setPageRoles,
}) => {
    const context = useContext(LayoutEditorContext)

    const onOpenHeaderSettings = () => {
        context.setActiveEditorTitle(HEADER_EDITOR_TITLE)
    }

    const onOpenRolesSettings = () => {
        context.setActiveEditorTitle(ROLES_EDITOR_TITLE)
    }

    const isHeaderSettings = context.activeEditor?.title === HEADER_EDITOR_TITLE
    const isRolesSettings = context.activeEditor?.title === ROLES_EDITOR_TITLE

    return (
        <>
            <LayoutObjectEditor isOpen={isHeaderSettings} title={HEADER_EDITOR_TITLE}>
                <BlankPageHeaderEditor view={view} onChange={onChange} />
            </LayoutObjectEditor>

            <LayoutObjectEditor isOpen={isRolesSettings} title={ROLES_EDITOR_TITLE}>
                <BlankPageRolesEditor pageRoles={pageRoles} setPageRoles={setPageRoles} />
            </LayoutObjectEditor>

            {!isHeaderSettings && !isRolesSettings && (
                <BlankPageViewEditorBlockPane
                    onOpenHeaderSettings={onOpenHeaderSettings}
                    onOpenRolesSettings={onOpenRolesSettings}
                    view={view}
                />
            )}
        </>
    )
}

export default BlankPageViewEditorPanes
