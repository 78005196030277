/* Code Quality: Not audited */

import React, { Component } from 'react'

import Dropdown from './Dropdown'
import Text from './Text'

const choices = [
    {
        label: '=',
        value: 'eq',
    },
    {
        label: '>',
        value: 'gt',
    },
    {
        label: '<',
        value: 'lt',
    },
    {
        label: '≠',
        value: 'neq',
    },
    {
        label: 'contains',
        value: 'contains',
    },
    {
        label: 'starts with',
        value: 'startsWith',
    },
    {
        label: 'ends with',
        value: 'endsWith',
    },
    // {
    //     label: "is date before",
    //     value: "date_before",
    // },
    // {
    //     label: "is date a after",
    //     value: "date_after",
    // },
    // {
    //     label: "is same day as",
    //     value: "same_day",
    // },
]

const defaultValue = [undefined, 'eq', undefined]

export default class FormulasAttribute extends Component {
    onChange = (key, keyValue) => {
        const value = this.props.value || defaultValue

        const immutableValue = [...value]
        immutableValue[key] = keyValue

        this.props.onChange(immutableValue)
    }
    render() {
        const value = this.props.value || defaultValue
        return (
            <>
                <div className="display-flex">
                    <Text value={value[0]} onChange={(value) => this.onChange(0, value)} size="7" />
                    <Dropdown
                        value={value[1]}
                        options={choices}
                        onChange={(value) => this.onChange(1, value)}
                        placeholder=""
                    />
                    <Text value={value[2]} onChange={(value) => this.onChange(2, value)} size="7" />
                </div>
            </>
        )
    }
}
