import React from 'react'
import { useForm } from 'react-hook-form'

import { UserProfileFormFields } from 'features/userProfiles'

import { LinkModalForm } from '../../links'

export const UserProfileEditModalView = ({
    isOpen,
    link,
    onClose,
    onSubmit,
    source,
}: {
    isOpen: boolean
    link?: LinkView
    onClose: () => void
    onSubmit: (data: LinkUpdateData) => Promise<unknown>
    source: string
}): React.ReactElement => {
    const formContext = useForm({
        mode: 'all',
        defaultValues: {
            source: link?.source.objectId,
            sourceField: link?.source.fieldId,
            target: link?.target.objectId,
            targetField: link?.target.fieldId,
            linkFieldObject: link?.source.objectId,
            sourceFieldLinkName: link?.name,
            targetFieldLinkName: link?.symmetric?.fieldName ?? ``,
            // We consider links as legacy if they are created before the introduction of
            // the automated creation of symmetric fields
            legacy: !link?.symmetric,
            mode: 'one-to-many',
        },
    })

    // We do not want to include the user table in the object selection
    // as it's already selected on the LHS of the user profile link
    function isNotUserTable(object: ObjectDto) {
        return object._sid !== source
    }

    return (
        <LinkModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            form={formContext}
            isSecondLayer={true}
            minHeight="35%"
        >
            <UserProfileFormFields form={formContext} filterTargetObject={isNotUserTable} />
        </LinkModalForm>
    )
}
