const httpsPrefixRegExp = /^(https?:)?\/\//i

const longUrlHostnames = [
    'youtube.com',
    'www.youtube.com',
    'm.youtube.com',
    'www.youtube-nocookie.com',
    'yotube-nocookie.com',
]

const shortUrlHostname = 'youtu.be'

const videoSearchParameterKey = 'v'
const playlistSearchParameterKey = 'list'

const embedPathnamePrefix = '/embed/'
const embedPlaylistPathnamePrefix = '/embed/videoseries'
const videoPathnamePrefix = '/v/'
//V1 is the embed url format, V2 is the watch url format
const videoHasStartTimeV1 = 'start'
const videoHasStartTimeV2 = 't'

function isLongYouTubeUrl(url: URL): boolean {
    return longUrlHostnames.includes(url.hostname)
}

function isShortYouTubeUrl(url: URL): boolean {
    return url.hostname === shortUrlHostname
}

// This function could've been a single RegExp,
// but imagine having to change it. :D
export function getYouTubeVideoIdFromUrl(url: string): string {
    if (!url) return ''

    let finalUrl = url.trim()

    // Add the protocol so the code below doesn't throw.
    if (!httpsPrefixRegExp.test(finalUrl)) {
        finalUrl = `https://${finalUrl}`
    }

    try {
        const finalUrlAsObj = new URL(finalUrl)
        const { pathname, searchParams } = finalUrlAsObj

        if (isLongYouTubeUrl(finalUrlAsObj)) {
            if (pathname === '/watch') {
                // https://www.youtube.com/watch?v=<VIDEO_ID>
                return searchParams.get(videoSearchParameterKey) ?? ''
            } else if (
                pathname.startsWith(embedPathnamePrefix) &&
                !pathname.startsWith(embedPlaylistPathnamePrefix)
            ) {
                // https://www.youtube.com/embed/<VIDEO_ID>
                return pathname.substring(embedPathnamePrefix.length)
            } else if (pathname.startsWith(videoPathnamePrefix)) {
                // https://www.youtube.com/v/<VIDEO_ID>
                return pathname.substring(videoPathnamePrefix.length)
            }
        } else if (isShortYouTubeUrl(finalUrlAsObj)) {
            // https://youtu.be/<VIDEO_ID>
            return pathname.substring(1)
        }
    } catch {}

    return ''
}

export function getYouTubeVideoParamsFromUrl(url: string) {
    if (!url) return ''

    let finalUrl = url.trim()

    if (!httpsPrefixRegExp.test(finalUrl)) {
        finalUrl = `https://${finalUrl}`
    }
    try {
        const finalUrlAsObj = new URL(finalUrl)
        const { searchParams } = finalUrlAsObj
        const startTimeV1 = searchParams.get(videoHasStartTimeV1) ?? null
        const startTimeV2 = searchParams.get(videoHasStartTimeV2) ?? null
        if (startTimeV1) {
            return `?start=${startTimeV1}`
        }
        if (startTimeV2) {
            return `?start=${startTimeV2}`
        }
    } catch {}

    return ''
}

export function isValidYouTubeVideoUrl(url: string): boolean {
    const videoId = getYouTubeVideoIdFromUrl(url)

    return Boolean(videoId)
}

export function getEmbeddableYouTubeVideoUrl(url: string): string {
    const videoId = getYouTubeVideoIdFromUrl(url)
    const startTime = getYouTubeVideoParamsFromUrl(url)
    if (!videoId) return ''

    return `https://www.youtube.com${embedPathnamePrefix}${videoId}${startTime}`
}

export function getYouTubePlaylistIdFromUrl(url: string): string {
    if (!url) return ''

    let finalUrl = url.trim()

    // Add the protocol so the code below doesn't throw.
    if (!httpsPrefixRegExp.test(finalUrl)) {
        finalUrl = `https://${finalUrl}`
    }

    try {
        const finalUrlAsObj = new URL(finalUrl)
        const { pathname, searchParams } = finalUrlAsObj

        if (!isLongYouTubeUrl(finalUrlAsObj)) return ''

        if (pathname === '/playlist' || pathname === embedPlaylistPathnamePrefix) {
            // https://www.youtube.com/playlist?list=<PLAYLIST_ID>
            // https://www.youtube.com/embed/videoseries?list=<PLAYLIST_ID>
            return searchParams.get(playlistSearchParameterKey) ?? ''
        }
    } catch {}

    return ''
}

export function isValidYouTubePlaylistUrl(url: string): boolean {
    const playlistId = getYouTubePlaylistIdFromUrl(url)

    return Boolean(playlistId)
}

export function getEmbeddableYouTubePlaylistUrl(url: string): string {
    const playlistId = getYouTubePlaylistIdFromUrl(url)
    if (!playlistId) return ''

    return `https://www.youtube.com${embedPlaylistPathnamePrefix}?${playlistSearchParameterKey}=${playlistId}`
}
