import React, { FC, SVGProps, useCallback, useMemo } from 'react'

import styled from '@emotion/styled'

import { Box, Button, ConditionalWrapper, OrderableListSelector, Text, Tooltip } from 'v2/ui'
import type { Item } from 'v2/ui/components/OrderableListSelector/types'
import { SolidAddPlus } from 'v2/ui/svgs'
import { ChartSimple, Pencil, TrashCan } from 'v2/ui/svgs/fa-sharp-regular'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type ListWidgetsProps = {
    widgets: AggregateBlockAttributes[]
    emptyTitle: string
    emptySubtitle: string
    EmptyIllustration: FC<SVGProps<SVGSVGElement>>
    addButtonLabel: string
    maxWidgets: number
    maxWidgetsReachedText: string
    newWidgetAttributes: Omit<AggregateBlockAttributes, 'order'>
    onStartEditing: (widget: AggregateBlockAttributes) => void
    onAddWidget: (widget: AggregateBlockAttributes) => void
    onDeleteWidget: (widgetOrder: number) => void
    onReorderWidgets: (widgets: (AggregateBlockAttributes & { previousOrder: number })[]) => void
    defaultLabelPrefix: string
}

export const ListWidgets: FC<ListWidgetsProps> = ({
    widgets,
    emptyTitle,
    emptySubtitle,
    EmptyIllustration,
    addButtonLabel,
    maxWidgets,
    maxWidgetsReachedText,
    newWidgetAttributes,
    onStartEditing,
    onAddWidget,
    onDeleteWidget,
    onReorderWidgets,
    defaultLabelPrefix,
}) => {
    const handleAddWidget = useCallback(() => {
        const newWidget: AggregateBlockAttributes = {
            order: widgets.length,
            ...newWidgetAttributes,
        }

        onAddWidget(newWidget)
    }, [newWidgetAttributes, onAddWidget, widgets.length])

    const handleDeleteWidget = useCallback(
        (widgetOrder: number) => {
            onDeleteWidget(widgetOrder)
        },
        [onDeleteWidget]
    )

    const handleReorderWidgets = useCallback(
        (reorderedItems: Item[]) => {
            const reorderedWidgets = reorderedItems.map(({ id }, index) => {
                const widget = widgets.find(({ order }) => parseInt(id) === order)!

                return {
                    ...widget,
                    previousOrder: widget.order,
                    order: index,
                }
            })

            onReorderWidgets(reorderedWidgets)
        },
        [onReorderWidgets, widgets]
    )

    const widgetsItems = useMemo(
        () =>
            widgets.map(
                (widget, index): Item => ({
                    id: widget.order.toString(),
                    label: widget.title || `${defaultLabelPrefix} ${index + 1}`,
                    props: { showToggle: false },
                    iconOrderableItem: <StyledChartSimple />,
                    actions: [
                        {
                            icon: <StyledPencil />,
                            onClick: () => onStartEditing(widget),
                            color: 'userInterface.neutral.600',
                            title: 'Edit',
                        },
                        {
                            icon: <StyledTrashCan />,
                            onClick: () => handleDeleteWidget(widget.order),
                            color: 'userInterface.neutral.600',
                            title: 'Delete',
                        },
                    ],
                })
            ),
        [defaultLabelPrefix, handleDeleteWidget, onStartEditing, widgets]
    )

    return (
        <>
            {widgets.length === 0 && (
                <>
                    <Text fontWeight="bold" color="userInterface.neutral.1000" mt="10px">
                        {emptyTitle}
                    </Text>
                    <Text size="xs" color="grey.700" mb={3}>
                        {emptySubtitle}
                    </Text>
                    <Box alignSelf="center">
                        <EmptyIllustration />
                    </Box>
                </>
            )}
            <OrderableListSelector
                items={widgetsItems}
                selectedItems={widgetsItems}
                onUpdate={handleReorderWidgets}
                renderPaletteItems={() => (
                    <Box w="fit-content">
                        <ConditionalWrapper
                            condition={widgets.length >= maxWidgets}
                            wrapper={(children) => (
                                <Tooltip
                                    placement="bottom"
                                    maxWidth="260px"
                                    label={<Text fontSize="0.85rem">{maxWidgetsReachedText}</Text>}
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <Button
                                variant="Tertiary"
                                size="sm"
                                color="userInterface.accent.1000"
                                p={1.5}
                                mt={-2}
                                icon={
                                    <Box mr={2}>
                                        <SolidAddPlus />
                                    </Box>
                                }
                                onClick={handleAddWidget}
                                disabled={widgets.length >= maxWidgets}
                            >
                                {addButtonLabel}
                            </Button>
                        </ConditionalWrapper>
                    </Box>
                )}
                hideSearch
            />
        </>
    )
}

const StyledChartSimple = styled(ChartSimple)`
    margin-right: 4px;
    color: #aeb5bc;
`

const StyledPencil = styled(Pencil)`
    width: 14px;
    height: 14px;
    color: #AEB5BC;
    &:hover {
        color: ${colors.userInterface.accent[1000]};
    `

const StyledTrashCan = styled(TrashCan)`
    width: 14px;
    height: 14px;
    color: #AEB5BC;
    &:hover {
        color: ${colors.userInterface.error[600]};
    `
