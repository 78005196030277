import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import styled from '@emotion/styled'

import { NONE_VALUE_SECTION_LABEL } from 'data/hooks/metrics/groupAggregateData'

import { NONE_VALUE_SECTION_COLOR, NONE_VALUE_SECTION_LABEL_COLOR } from './constants'

const DOT_RADIUS = 4

const Text = styled.text<{ textAnchor: string }>`
    fill: ${(props) => props.theme.colors.neutral[1000]};
    text-anchor: ${(props) => props.textAnchor};
`

type Props = {
    showLabels: boolean
    index: number
    name: string
    cx: number
    cy: number
    x: number
    y: number
    fill: string
    textAnchor: 'start' | 'end'
    payload: {
        showInLegend: boolean
    }
    hideLegendForSmallSlices: boolean
    _isOther: boolean
    chartWidth: number | undefined
    outerRadius: number
}

export const Label: FC<Props> = ({
    showLabels,
    name,
    x,
    y,
    cx,
    cy,
    fill,
    textAnchor,
    hideLegendForSmallSlices,
    _isOther,
    chartWidth,
    outerRadius,
    ...props
}) => {
    const textRef = useRef<SVGTextElement>(null)

    const dotFill = useMemo(() => {
        if (name === NONE_VALUE_SECTION_LABEL) {
            return NONE_VALUE_SECTION_LABEL_COLOR
        }

        if (_isOther) {
            return NONE_VALUE_SECTION_COLOR
        }

        return fill
    }, [_isOther, fill, name])

    const dotCoordinates = useMemo(() => {
        // Compute the distance between the center (cx, cy) of the donut/pie chart and the label coordinate (x, y) given by Recharts
        const labelDistance = Math.sqrt((x - cx) * (x - cx) + (y - cy) * (y - cy))
        // We want to place the dot on a concentric circle with radius bigger than the donut/pie
        const dotDistance = outerRadius + outerRadius * 0.12
        // Compute the ratio between the label distance and the dot distance
        const r = dotDistance / labelDistance

        // Interpolate the dot position based on the ratio and the label position
        return {
            x: (1 - r) * cx + r * x,
            y: (1 - r) * cy + r * y,
        }
    }, [cx, cy, outerRadius, x, y])

    const truncateLabel = useCallback(() => {
        if (!textRef.current || !chartWidth) {
            return
        }

        textRef.current.textContent = name
        let textLength = textRef.current.getComputedTextLength()
        while (
            textRef.current.textContent.length > 1 &&
            (x - textLength < 0 || x + textLength > chartWidth)
        ) {
            textRef.current.textContent =
                textRef.current.textContent.length - 1 > 4
                    ? `${textRef.current.textContent.slice(0, -2)}…`
                    : '…'
            textLength = textRef.current.getComputedTextLength()
        }
    }, [chartWidth, name, x])

    useEffect(() => {
        truncateLabel()
    }, [chartWidth, name, truncateLabel, x])

    if (!showLabels || (props.payload.showInLegend && hideLegendForSmallSlices)) {
        return null
    }

    return (
        <>
            <Text ref={textRef} x={x} y={y + 5} textAnchor={textAnchor}>
                {name}
            </Text>
            <circle cx={dotCoordinates.x} cy={dotCoordinates.y} r={DOT_RADIUS} fill={dotFill} />
        </>
    )
}
