import React, { VFC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

const SolidShrink: VFC<{
    color?: string
    size?: number
}> = ({ color = colors.userInterface.neutral[700], size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.45166 11.625H4.13916C3.63135 11.625 3.1626 11.9375 2.96729 12.4062C2.81104 12.875 2.92822 13.4219 3.27979 13.7734L4.45166 14.9453L1.40479 17.9922C0.896973 18.5 0.896973 19.2812 1.40479 19.75L2.26416 20.6484C2.77197 21.1562 3.55322 21.1562 4.06104 20.6484L7.10791 17.6016L8.27979 18.7734C8.51416 19.0078 8.82666 19.125 9.13916 19.125C9.33447 19.125 9.49072 19.125 9.64697 19.0469C10.1157 18.8516 10.3892 18.3828 10.3892 17.875V12.5625C10.3892 12.0547 9.99854 11.625 9.45166 11.625ZM12.6157 10.375H17.9282C18.397 10.375 18.8657 10.1016 19.061 9.63281C19.2563 9.16406 19.1392 8.61719 18.7876 8.26562L17.6157 7.09375L20.6626 4.04688C21.1704 3.53906 21.1704 2.75781 20.6626 2.25L19.8032 1.39062C19.2954 0.882812 18.5142 0.882812 18.0063 1.39062L14.9595 4.4375L13.7876 3.26562C13.436 2.91406 12.8892 2.79688 12.4204 2.99219C11.9517 3.1875 11.6782 3.65625 11.6782 4.16406V9.4375C11.6782 9.98438 12.0688 10.375 12.6157 10.375Z"
            fill={color}
        />
    </svg>
)

export default SolidShrink
