import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import DropdownAttribute from 'features/pages/blocks/settings/attributes/items/form/Dropdown'
import TextAttribute from 'features/pages/blocks/settings/attributes/items/form/Text'
import RenderRowAttribute, {
    RenderRowAttributeRenderProps,
} from 'features/pages/blocks/settings/attributes/RenderRowAttribute'
import FieldPicker from 'features/studio/ui/FieldPicker'

import { Icon, Radio, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import QuickLinksBlockEditorIcon from './QuickLinksBlockEditorIcon'
import { useInternalLinks } from './useInternalLinks'
import { filterQuickLinksBlockEditorFields } from './utils'

const { space } = stackerTheme()

const Wrapper = styled.div`
    padding: ${space[1]} 0;
`

const FormRow = styled.div`
    display: grid;
    grid-template-columns: 2.3rem repeat(2, 1fr);
    width: 100%;
    grid-column-gap: ${space[1]};
    grid-row-gap: ${space[2]};
    & + & {
        margin-top: ${space[2]};
    }
`

const FullSizeInputRow = styled.div`
    grid-column: 2 / -1;
`

type QuickLinksBlockEditorItemProps = Omit<
    RenderRowAttributeRenderProps<QuickLinksBlock['config']['attributes'], 'items'>,
    'value' | 'onChange'
> & {
    index: number
    value: QuickLinksBlockItem
    onChange: (value: QuickLinksBlockItem) => void
    brandColor?: string
    className?: string
    style?: React.CSSProperties
}

const QuickLinksBlockEditorItem: React.FC<QuickLinksBlockEditorItemProps> = ({
    index,
    value,
    onChange,
    brandColor,
    className,
    style,
    context,
    ...props
}) => {
    const internalLinks = useInternalLinks()
    const isExternal = value.link && (!value.link.type || value.link.type === 'external')

    const onUpdateValueParam = <K extends keyof QuickLinksBlockItem>(
        paramKey: K,
        paramValue: QuickLinksBlockItem[K]
    ) => {
        onChange({
            ...value,
            [paramKey]: paramValue,
        })
    }

    const onUpdateLink = <K extends KeyOfUnion<QuickLinksBlockItemLink>>(
        paramKey: K,
        // @ts-expect-error: TS doesn't infer that `K` can be used to index `QuickLinksBlockItemLink` at the time of writing this. :(
        paramValue: QuickLinksBlockItemLink[K]
    ) => {
        let newLink: QuickLinksBlockItemLink

        if (!value.link) {
            newLink = {
                type: 'external',
                label: '',
                url: '',
                [paramKey]: paramValue,
            }
        } else {
            newLink = {
                ...value.link,
                [paramKey]: paramValue,
            }
        }
        onUpdateValueParam('link', newLink)
    }

    const linkLabel = `Link ${index + 1}`

    const linkTypeOptions = useMemo(() => {
        const options: React.ComponentPropsWithoutRef<typeof Radio>['options'] = [
            { label: 'Internal link', value: 'internal' },
            { label: 'External link', value: 'external' },
        ]

        if (context.viewType === 'detail') {
            const hasObject = Boolean(context.object?._sid)

            options.push({
                label: 'From field',
                value: 'field',
                disabled: !hasObject,
            })
        }

        return options
    }, [context.viewType, context.object?._sid])

    let linkType = value.link?.type ?? 'internal'
    if (isExternal) linkType = 'external'

    return (
        <Wrapper className={className} style={style} role="group" aria-label={linkLabel}>
            <Text fontWeight="bold" mb={4}>
                {linkLabel}
            </Text>
            <FormRow>
                <QuickLinksBlockEditorIcon
                    value={value.icon}
                    onChange={(newValue) => onUpdateValueParam('icon', newValue)}
                    brandColor={brandColor}
                />
                <FullSizeInputRow>
                    <TextAttribute
                        {...(props as unknown as RenderRowAttributeRenderProps)}
                        label="Title"
                        onChange={(newValue) => onUpdateValueParam('title', newValue)}
                        value={value.title}
                    />
                </FullSizeInputRow>
            </FormRow>
            <FormRow>
                <FullSizeInputRow>
                    <TextAttribute
                        {...(props as unknown as RenderRowAttributeRenderProps)}
                        label="Subtitle"
                        onChange={(newValue) => onUpdateValueParam('subtitle', newValue)}
                        value={value.subtitle ?? ''}
                    />
                </FullSizeInputRow>
            </FormRow>
            <FormRow>
                <FullSizeInputRow>
                    <DropdownAttribute
                        {...(props as unknown as RenderRowAttributeRenderProps)}
                        options={linkTypeOptions}
                        value={linkType}
                        menuPlacement="auto"
                        onChange={(newValue) =>
                            onUpdateLink('type', newValue as QuickLinksBlockItemLink['type'])
                        }
                        isClearable={false}
                    />
                </FullSizeInputRow>
            </FormRow>
            {isExternal && (
                <FormRow>
                    <Icon
                        aria-hidden="true"
                        icon="fa-link"
                        iconPack="far"
                        color="neutral.800"
                        style={{ gridRow: '1 / 3' }}
                    />
                    <FullSizeInputRow>
                        <TextAttribute
                            {...(props as unknown as RenderRowAttributeRenderProps)}
                            label="Button label"
                            onChange={(newValue) => onUpdateLink('label', newValue)}
                            value={value.link?.label ?? ''}
                        />
                    </FullSizeInputRow>
                    <FullSizeInputRow>
                        <TextAttribute
                            {...(props as unknown as RenderRowAttributeRenderProps)}
                            label="https://"
                            onChange={(newValue) => onUpdateLink('url', newValue)}
                            value={
                                (value.link as QuickLinksBlockItemExternalLink | undefined)?.url ??
                                ''
                            }
                        />
                    </FullSizeInputRow>
                </FormRow>
            )}
            {value.link?.type === 'internal' && (
                <>
                    <FormRow>
                        <Icon
                            aria-hidden="true"
                            icon="fa-link"
                            iconPack="far"
                            color="neutral.800"
                            style={{ gridRow: '1 / 3' }}
                        />
                        <FullSizeInputRow>
                            <TextAttribute
                                {...(props as unknown as RenderRowAttributeRenderProps)}
                                label="Button label"
                                onChange={(newValue) => onUpdateLink('label', newValue)}
                                value={value.link?.label ?? ''}
                            />
                        </FullSizeInputRow>
                        <FullSizeInputRow>
                            <DropdownAttribute
                                {...(props as unknown as RenderRowAttributeRenderProps)}
                                options={internalLinks.map((link) => {
                                    return {
                                        label: link.label,
                                        value: link.viewId,
                                    }
                                })}
                                value={value?.link?.viewId}
                                menuPlacement="auto"
                                onChange={(newValue: any) => onUpdateLink('viewId', newValue)}
                            />
                        </FullSizeInputRow>
                    </FormRow>
                </>
            )}
            {value.link?.type === 'field' && (
                <>
                    <FormRow>
                        <Icon
                            aria-hidden="true"
                            icon="fa-link"
                            iconPack="far"
                            color="neutral.800"
                            style={{ gridRow: '1 / 3' }}
                        />
                        <FullSizeInputRow>
                            <TextAttribute
                                {...(props as unknown as RenderRowAttributeRenderProps)}
                                label="Button label"
                                onChange={(newValue) => onUpdateLink('label', newValue)}
                                value={value.link?.label ?? ''}
                            />
                        </FullSizeInputRow>
                        <FullSizeInputRow>
                            <RenderRowAttribute
                                {...(props as unknown as RenderRowAttributeRenderProps)}
                                noLabel={true}
                                noMargin={true}
                                render={(props) => {
                                    return (
                                        <FieldPicker
                                            {...(props as unknown as RenderRowAttributeRenderProps)}
                                            objectId={context.object?._sid}
                                            onChange={(value: string | null) =>
                                                onUpdateLink('fieldId', value ?? '')
                                            }
                                            isDisabled={false}
                                            value={
                                                (value.link as QuickLinksBlockItemFieldLink)
                                                    ?.fieldId
                                            }
                                            menuPlacement="auto"
                                            filter={filterQuickLinksBlockEditorFields}
                                        />
                                    )
                                }}
                            />
                        </FullSizeInputRow>
                    </FormRow>
                </>
            )}
        </Wrapper>
    )
}

export default QuickLinksBlockEditorItem
