import React, { FC, useMemo } from 'react'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { useAppContext } from 'app/AppContext'
import { getStackTheme } from 'app/Branding'
import { useField } from 'data/hooks/fields'
import { useMetrics } from 'data/hooks/metrics'
import { useViewFromId } from 'data/hooks/views'
import { formatNumberValue } from 'features/charts/utils/utils'

import { Box, Flex, Icon, Text } from 'v2/ui'
import { MetricsBlockError } from 'v2/ui/svgs'

import { METRIC_NARROW_WIDTH } from '../common/InvalidBlock'
import { showPreviousTimePeriod } from '../common/timePeriodFilters/timePeriodFilterUtils'
import { Wrapper } from '../common/Wrapper'
import { useMetricConfiguration } from '../hooks/useMetricConfiguration'

import { METRIC_BLOCK_HEIGHT } from './constants'
import { PreviousPeriod } from './PreviousPeriod'

const StyledTitle = styled(Text)`
    font-size: 14px;
    align-self: center;
    margin-top: 8px;
    @container (width <= ${METRIC_NARROW_WIDTH}px) {
        font-weight: normal;
        padding-top: 0;
    }
`

const IconWrapper = styled(Box)<{ color?: string }>`
    ${({ color }) => color && `background-color: ${color};`}
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex: 0 0 auto;
`

type MetricBlockProps = {
    metric: MetricsBlockAttributes
    numberOfMetrics: number
    isEditing: boolean
    isActive: boolean
    context: ViewLayoutContext
    isOnListView?: boolean
    onClick?: () => void
}

export const MetricBlock: FC<MetricBlockProps> = ({
    metric,
    numberOfMetrics,
    isEditing,
    isActive,
    context,
    isOnListView,
    onClick,
}) => {
    const { selectedStack } = useAppContext()
    const theme = useMemo(() => getStackTheme(selectedStack), [selectedStack])

    const view = useViewFromId(metric.inheritFiltersFromViewId)
    const field = useField(
        metric.aggregate.type !== 'count' ? metric.aggregate.field_sid : undefined
    )

    const metricConfiguration = useMetricConfiguration(metric, view, context?.record || {})
    const shouldShowPreviousTimePeriod = useMemo(() => showPreviousTimePeriod(metric), [metric])

    const {
        data: metrics,
        // @ts-expect-error
        isError,
        // @ts-expect-error
        isFetched: isCurrentResultFetched,
    } = useMetrics(metricConfiguration)

    const formattedMetricValue = useMemo(() => {
        if (!metrics || metrics.data.length === 0) {
            return { value: '0', abbreviation: undefined }
        }

        return formatNumberValue({
            value: metrics.data[0].value,
            config: metricConfiguration,
            display: metric.display,
            metrics,
        })
    }, [metric.display, metricConfiguration, metrics])

    return (
        <Wrapper
            pl={5}
            pr={5}
            pt={!metrics && isError ? 3 : 5}
            pb={!metrics && isError ? 3 : 5}
            numberOfMetrics={numberOfMetrics}
            isEditing={isEditing}
            isActive={isActive}
            height={METRIC_BLOCK_HEIGHT}
            isOnListView={isOnListView}
            onClick={onClick}
        >
            {metrics && (
                <Flex direction="row" flexWrap="nowrap">
                    {metric.display.icon && (
                        <IconWrapper color={theme.brandColor} aria-hidden="true" mr={4}>
                            <Icon icon={metric.display.icon} color="white" size="xl" />
                        </IconWrapper>
                    )}
                    <Box width="100%">
                        {!metric.hideTitle && (
                            <Text
                                fontWeight="bold"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                w="100%"
                                mb={3}
                                alignSelf="flex-start"
                                color="userInterface.neutral.1000"
                                fontSize={18}
                            >
                                {metric.title}
                            </Text>
                        )}
                        <Text fontSize={50} fontWeight="bold" color="userInterface.neutral.1000">
                            {field?.type === 'currency' && (field?.options?.currency_symbol ?? '')}
                            {formattedMetricValue.value}
                            {formattedMetricValue.abbreviation}
                        </Text>
                        {shouldShowPreviousTimePeriod && (
                            <PreviousPeriod
                                metrics={metrics}
                                isCurrentResultFetched={isCurrentResultFetched}
                                metric={metric}
                                view={view}
                                record={context?.record || {}}
                            />
                        )}
                    </Box>
                </Flex>
            )}
            {!metrics && isError && (
                <>
                    <MetricsBlockError />
                    <StyledTitle>We couldn&apos;t load this big number.</StyledTitle>
                </>
            )}
        </Wrapper>
    )
}
