import React from 'react'

import styled from '@emotion/styled'

import { Icon } from 'v2/ui'

const Wrapper = styled.div<{ color?: string }>`
    ${({ color }) => color && `background-color: ${color};`}
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
`

type QuickLinksBlockItemIconProps = React.ComponentPropsWithoutRef<'div'> &
    Pick<QuickLinksBlockItem, 'icon'> & {
        brandColor?: string
    }

const QuickLinksBlockItemIcon: React.FC<QuickLinksBlockItemIconProps> = ({
    brandColor,
    icon,
    ...props
}) => {
    return (
        <Wrapper aria-hidden="true" color={brandColor} {...props}>
            <Icon icon={icon} size="xl" color="white" />
        </Wrapper>
    )
}

export default QuickLinksBlockItemIcon
