import type { Config } from '../types'

const fontSizes = (config: Config) => {
    const { fontSizes, iconSizes } = config

    const data = {
        page: {
            text: fontSizes.sm,
            textLg: fontSizes.md,
            heading: fontSizes.md,
            headingLg: fontSizes.lg,
        },
        table: {
            label: fontSizes.xs,
            fieldText: fontSizes.xs,
            fieldTextLg: fontSizes.sm,
            recordLabel: fontSizes.xs,
            recordLabelLg: fontSizes.xs,
        },
        fields: {
            label: fontSizes.xs,
            text: fontSizes.sm,
        },
        feed: {
            header: fontSizes.sm,
            headerLg: fontSizes.sm,
        },
        highlightedFields: {
            label: fontSizes[100],
            labelLg: fontSizes[200],
            text: fontSizes[300],
            textLg: fontSizes[500],
        },
        container: {
            label: fontSizes.sm,
            labelLg: fontSizes.md,
            icon: iconSizes.sm,
            text: fontSizes[200],
            textLg: fontSizes[400],
        },
        onboarding: {
            title: fontSizes.lg,
            titleLg: fontSizes.xl,
            subtitle: fontSizes.md,
            subtitleLg: fontSizes.lg,
            icon: iconSizes.sm,
            text: fontSizes.smmd,
            textLg: fontSizes.md,
            errorDetails: fontSizes.sm,
        },
        pagination: {
            text: fontSizes.xs,
            textLg: fontSizes.sm,
            textArrow: '0px',
            textArrowLg: fontSizes.sm,
        },
        cards: {
            recordLabel: fontSizes.sm,
            recordLabelLg: fontSizes.md,
        },
        rows: {
            fieldText: fontSizes.xs,
            fieldTextLg: fontSizes.sm,
        },
        kanban: {
            recordLabel: fontSizes.xs,
            recordLabelLg: fontSizes.xs,
        },
        input: {
            text: fontSizes.sm,
            textLg: fontSizes.sm,
            icon: fontSizes.xs,
            iconLg: fontSizes.xs,
        },
        radio: {
            text: fontSizes.sm,
            textLg: fontSizes.sm,
        },
        button: {
            xs: fontSizes.xs,
            xsLg: fontSizes.xs,
            sm: fontSizes.sm,
            smLg: fontSizes.sm,
            md: fontSizes.smmd,
            mdLg: fontSizes.smmd,
        },
        emptyState: {
            text: fontSizes.sm,
            textLg: fontSizes.md,
            icon: fontSizes.xl,
            iconLg: fontSizes.xl,
        },
        admin: {
            sideMenu: {
                text: fontSizes.xs,
                textLg: fontSizes.sm,
                button: fontSizes.sm,
                buttonLg: fontSizes.sm,
                heading: fontSizes.sm,
                headingLg: fontSizes.sm,
                subHeading: fontSizes.smmd,
                subHeadingLg: fontSizes.md,
            },
            palette: {
                sectionLabel: fontSizes.sm,
                sectionLabelLg: fontSizes.sm,
                search: {
                    icon: fontSizes.xs,
                    iconLg: fontSizes.xs,
                },
                widget: {
                    label: fontSizes.xs,
                    labelLg: fontSizes.xs,
                    description: fontSizes.xs,
                    descriptionLg: fontSizes.xs,
                    sectionLabel: fontSizes.xs,
                    sectionLabelLg: fontSizes.xs,
                },
            },
            menuToggle: {
                text: fontSizes.sm,
                textLg: fontSizes.sm,
                icon: fontSizes.md,
            },
            blockAttribute: {
                description: fontSizes.sm,
            },
        },

        authentication: {
            header: fontSizes.lg,
            text: fontSizes.md,
        },
        modal: {
            heading: fontSizes.lg,
            headingLg: fontSizes.lg,
            body: fontSizes.md,
            bodyLg: fontSizes.md,
            button: fontSizes.md,
            buttonLg: fontSizes.md,
        },
        headings: {
            h100: fontSizes[200],
            h100Lg: fontSizes[300],
            h200: fontSizes[300],
            h200Lg: fontSizes[400],
            h300: fontSizes[400],
            h300Lg: fontSizes[500],
            h400: fontSizes[500],
            h400Lg: fontSizes[600],
            h500: fontSizes[600],
            h500Lg: fontSizes[700],
            h600: fontSizes[700],
            h600Lg: fontSizes[800],
        },
        internal: {
            nav: {
                label: fontSizes.smmd,
                childLabel: fontSizes.sm,
                appLabel: fontSizes.sm,
            },
        },
        launchBanner: {
            text: fontSizes.md,
            textLg: fontSizes.md,
            subText: fontSizes.smmd,
            subTextLg: fontSizes.smmd,
        },
        listHeader: {
            title: fontSizes.lg,
            titleLg: fontSizes.xxxl,
            desc: fontSizes.smmd,
            descLg: fontSizes.mdlg,
        },
        form: {
            helperText: fontSizes.sm,
        },
        breadcrumb: {
            linkTextSm: fontSizes.xs,
            linkTextLg: fontSizes.sm,
        },
        widgetContainer: {
            title: fontSizes[400],
            titleLg: fontSizes[500],
            description: fontSizes[200],
        },
    }

    return data
}

export default fontSizes
