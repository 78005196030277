import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Popover } from '@chakra-ui/react'
import get from 'lodash/get'

import Button from 'v2/ui/components/Button'
import Dropdown from 'v2/ui/components/Dropdown'
import FilterButton from 'v2/ui/components/Filters/FilterButton'

const DropdownFilter = ({
    options,
    dataTestId,
    accessibilityLabel,
    label,
    onChange,
    currentValue = [],
    multi,
    icon,
}) => {
    const buttonRef = useRef()
    const valueDropdown = useRef()
    const typeDropdown = useRef()
    const [showTypeDropdown, setShowTypeDropdown] = useState(false)

    const currentFilterValue = get(currentValue, 'filterValue', [])
    const currentFilterType = get(currentValue, 'filterType')

    const filterOptions = useMemo(() => {
        if (multi) {
            return [
                { value: 'contains', label: 'contains any of' },
                { value: 'containsNone', label: 'contains none of' },
                { value: 'is', label: 'is' },
                { value: 'isNot', label: 'is not' },
            ]
        }

        return [
            { value: 'contains', label: 'is any of' },
            { value: 'containsNone', label: 'is none of' },
        ]
    }, [multi])

    const [filterType, setFilterType] = useState(
        currentFilterType ? currentFilterType : get(filterOptions, '0.value')
    )

    useEffect(() => {
        if (showTypeDropdown) {
            typeDropdown.current.setState({ menuIsOpen: true })
        }
    }, [showTypeDropdown])

    useEffect(() => {
        if (currentFilterType && currentFilterType !== filterType) {
            setFilterType(currentFilterType)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilterType])

    const onSelect = useCallback(
        (value) => {
            const newFilterValue = value.length ? { filterType, filterValue: value } : undefined
            onChange(newFilterValue)
        },
        [onChange, filterType]
    )

    useEffect(() => {
        if (currentFilterValue.length < 1) return
        onSelect(currentFilterValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType])

    const filterTypeLabel = useMemo(() => {
        const filter = filterOptions.find((f) => f.value == filterType)
        return get(filter, 'label')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType])

    const filterLabel = useMemo(() => {
        if (!currentFilterValue.length) return label
        const valueLabels = options
            .filter(({ value }) => currentFilterValue.includes(value))
            .map(({ label }) => label)
        return `${label} ${filterTypeLabel} ${valueLabels.join(', ')}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterTypeLabel, currentFilterValue, options])

    if (!options || !options.length) return null

    return (
        <Popover initialFocusRef={showTypeDropdown ? typeDropdown : valueDropdown}>
            {({ isOpen }) => (
                <>
                    <FilterButton
                        icon={icon}
                        as={Button}
                        buttonSize="sm"
                        label={!isOpen && accessibilityLabel}
                        data-testid={dataTestId}
                        ref={buttonRef}
                        buttonText={filterLabel}
                        hasValue={currentFilterValue.length > 0}
                        onChange={onChange}
                    >
                        <>
                            {showTypeDropdown ? (
                                <Dropdown
                                    usePortal={false}
                                    ref={typeDropdown}
                                    fontSize="0.75rem"
                                    options={filterOptions}
                                    value={filterType}
                                    onChange={(value) => {
                                        setFilterType(value)
                                        setShowTypeDropdown(false)
                                    }}
                                    onBlur={() => {
                                        setShowTypeDropdown(false)
                                    }}
                                    isClearable={false}
                                    padding="6px"
                                    controlStyle={{
                                        marginBottom: '4px',
                                    }}
                                />
                            ) : (
                                <Button
                                    variant="filterType"
                                    onClick={() => {
                                        setShowTypeDropdown(true)
                                    }}
                                >
                                    {filterTypeLabel}
                                </Button>
                            )}
                            <Dropdown
                                usePortal={false}
                                closeMenuOnSelect={false}
                                ref={valueDropdown}
                                fontSize="0.75rem"
                                value={currentFilterValue}
                                isMulti
                                options={options}
                                onChange={onSelect}
                                padding="6px"
                                onFocus={() => valueDropdown.current.setState({ menuIsOpen: true })}
                            />
                        </>
                    </FilterButton>
                </>
            )}
        </Popover>
    )
}

export default React.memo(DropdownFilter)
