import React from 'react'

import {
    InlineFilterOperation,
    inlineFilterOpInputMap,
} from 'features/records/components/RecordFilters/constants'

import { InlineFilterInputComponent } from './types'

type InlineFilterValueInputProps = {
    operation?: InlineFilterOperation
}

export const InlineFilterValueInput: InlineFilterInputComponent<InlineFilterValueInputProps> = ({
    operation,
    ...props
}) => {
    if (!operation) return null

    const InputComponent = inlineFilterOpInputMap[operation]
    if (InputComponent) return <InputComponent {...props} />

    return null
}
