import React from 'react'

import { Dropdown } from 'v2/ui'

import { TIME_PERIOD_FILTERS } from '.'

type TimePeriodFilterDropdownProps = {
    value: string | undefined
    onChange: (newValue: string) => void
    inputId?: string
}

export const TimePeriodFilterDropdown = ({
    value,
    onChange,
    inputId,
}: TimePeriodFilterDropdownProps) => {
    const options = TIME_PERIOD_FILTERS.map((option) => ({
        label: option.label,
        value: option.value,
    }))
    return (
        <>
            <Dropdown
                options={options}
                value={value}
                onChange={(newValue: string) => {
                    onChange(newValue)
                }}
                inputId={inputId}
            />
        </>
    )
}
