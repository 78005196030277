//@ts-nocheck

// Why is this file a TSX but with nocheck enabled?

// I (Martin) needed to add the promise return type to the .remove function signature, so that you
// can use .then remove().then() in .tsx files without TypeScript complaining. There are various
// other TS issues with this file that I would like to fix but don't currently have the time to
// address so the pragmatic temporary solution is to make this a .tsx file but don't error check it.

// TODO: Remove @ts-nocheck and address typescript issues in this file

import get from 'lodash/get'

import { FETCH_COMPLETED, FETCH_STARTED } from 'data/utils/constants'

export class StackerActions {
    deferStoreUpdates = false
    actionTypes = { fetched: null, removed: null, created: null, updated: null, clear: null }

    api = null

    reducer = null

    name = 'data'

    fetch = (id?: string, options = { force: false, overrideLocalCache: false }) => {
        return (dispatch, getState) => {
            // Don't need to get it if we've already got it
            if (id && getState()[this.reducer].entities[id]) return Promise.resolve(null)

            // Let's make sure we're not sending multiple request for the same thing
            const fetchKey = this.actionTypes.fetched + id
            // console.log("[DATA] Shall I fetch for ${id}", getState()[this.reducer].fetching)
            if (getState()[this.reducer].fetching.indexOf(fetchKey) !== -1 && !options.force)
                return Promise.resolve(null)
            dispatch({ type: FETCH_STARTED, key: fetchKey })

            return this.api.get(id).then(
                this.processFetch ||
                    ((response) => {
                        const result = dispatch({
                            type: this.actionTypes.fetched,
                            payload: response,
                            id,
                            overrideLocalCache: options.overrideLocalCache,
                        })
                        if (this.afterFetch) this.afterFetch(dispatch, response)
                        return result
                    }),
                (error) => {
                    dispatch({ type: FETCH_COMPLETED, key: fetchKey })
                    console.error('failed to fetch', error)
                }
            )
        }
    }

    create = (data?: any, options?: any = {}) => {
        return (dispatch) => {
            return this.api.post(data, options).then(
                this.processCreate ||
                    ((response) => {
                        dispatch({ type: this.actionTypes.created, payload: response })
                        if (this.afterCreate) this.afterCreate(dispatch)
                        return response
                    })
            )
        }
    }

    update = (id: string, data: object, options?: any = {}): Promise<any> => {
        return (dispatch) => {
            // Since this API is used EVERYWHERE and I'm not sure about
            // all cases, I'm adding this deferStoreUpdate as an option
            // instead of making it the default behavior. HOwever, I think in most
            // cases it doesn't make sense to update the store until we get
            // a successful response from the server. Otherwise, if the server
            // request fails we'll end up with a store out of sync with the server.
            if (!get(options, 'deferStoreUpdate') && !this.deferStoreUpdates) {
                dispatch({ type: this.actionTypes.updated, id, payload: data })
            }
            return this.api.patch(id, data, options).then(
                this.processUpdate
                    ? this.processUpdate.bind(null, dispatch)
                    : (response) => {
                          dispatch({
                              type: this.actionTypes.updated,
                              id,
                              payload: response,
                              updateId: get(options, 'updateId'),
                          })
                          if (this.afterUpdate) this.afterUpdate(dispatch)
                          return response
                      }
            )
        }
    }

    remove = (id?: string, queryDict?: object, options = {}): Promise<any> => {
        return (dispatch) => {
            return this.api.delete(id, queryDict, options).then(
                this.processDelete ||
                    (() => {
                        const disp = dispatch({ type: this.actionTypes.removed, id })
                        if (this.afterRemove) this.afterRemove(dispatch)
                        return disp
                    })
            )
        }
    }

    /**
     * Switch the sides of a link field by deleting and recreating it on
     * the other side of the relationship
     * @param {*} id
     * @param {*} data
     * @returns
     */
    switch = (id, data) => {
        return async (dispatch) => {
            const deleteResp = await this.api.delete(id)
            const createResp = await this.api.post(data)

            this.processDelete
                ? await this.processDelete(deleteResp)
                : dispatch({ type: this.actionTypes.removed, id })

            this.processCreate
                ? await this.processCreate(createResp)
                : dispatch({ type: this.actionTypes.created, payload: createResp })

            if (this.afterRemove) this.afterRemove(dispatch)
            if (this.afterCreate) this.afterCreate(dispatch)

            return createResp
        }
    }

    clear = () => {
        return (dispatch) => {
            return dispatch({ type: this.actionTypes.clear })
        }
    }
    actions = {
        fetch: this.fetch,
        update: this.update,
        create: this.create,
        remove: this.remove,
        switch: this.switch,
        clear: this.clear,
    }
}
