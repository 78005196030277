const STYLE_CLASSES = {
    PAGE: 'stk-page',
    LIST_PAGE: 'stk-list-page',
    DETAIL_PAGE: 'stk-detail-page',
    CREATE_PAGE: 'stk-create-page',
    LOGIN_PAGE: 'stk-login-page',
    RESET_PASSWORD_TEXT: 'stk-reset-password-text',
    RESET_PASSWORD_LINK: 'stk-reset-password-link',
    REGISTER_TEXT: 'stk-register-text',
    REGISTER_LINK: 'stk-register-link',
    REGISTER_INTRO: 'stk-register-intro',
    LOGIN_TEXT: 'stk-login-text',
    LOGIN_LINK: 'stk-login-link',
    LOGIN_TITLE: 'stk-login-title',
    LOGIN_BUTTON: 'stk-login-button',
    EMAIL_INPUT: 'stk-email-input',
    PASSWORD_INPUT: 'stk-password-input',
    REGISTER_BUTTON: 'stk-register-button',
    REGISTER_FOR_TEXT: 'stk-register-for-text',
    REGISTER_PAGE: 'stk-register-page',
    HEADER_OUTER: 'stk-header-outer',
    HEADER: 'stk-header',
    SECONDARY_NAVIGATION: 'stk-secondary-navigation',
    LOGO: 'stk-logo',
    NAVIGATION: 'stk-navigation',
    NAVIGATION_ITEM: 'stk-navigation-item',
    USER_MENU: 'stk-user-menu',
    CONTENT: 'stk-content',
    BUTTON: 'stk-button',
    HEADING: 'stk-heading',
    TEXT: 'stk-text',
    INPUT: 'stk-input',
    ROWS: 'stk-rows',
    CARDS: 'stk-cards',
    CARD: 'stk-card',
    CARD_LINK: 'stk-card-link',
    CARD_CELL: 'stk-card-field',
    CARD_CELLS_DIVIDER: 'stk-card-cells-divider',
    TABLE: 'stk-table',
    TABLE_HEADER: 'stk-table-header',
    TABLE_HEADER_CELL: 'stk-table-header-cell',
    TABLE_ROW: 'stk-table-row',
    TABLE_CELL: 'stk-table-cell',
    LIST_SEARCH: 'stk-list-search',
    INLINE_FILTERS: 'stk-inline-filters',
    ATTRIBUTE: 'stk-field',
    TAG_LIST: 'stk-tag-list',
    TAG: 'stk-tag',
    PAGE_HEADER: 'stk-page-header',
    ATTRIBUTE_DESCRIPTION: 'stk-field-description',
    PROFILE_IMAGE: 'stk-profile-image',
    DATA_BLOCK: 'stacker-data-block',
    INBOX: 'stk-inbox',
    INBOX_LIST: 'stk-inbox-list',
    INBOX_RECORD: 'stk-inbox-record',
    INBOX_RECORD_INNER: 'stk-inbox-record-inner',
    RECORD_LIST: 'stk-record-list',
    RECORD_HEADER: 'stk-record-header',
    USER_MENU_SETTINGS: 'stk-user-menu-settings',
    USER_MENU_LOGOUT: 'stk-user-menu-logout',
    USER_MENU_WORKSPACES: 'stk-user-menu-workspaces',
    USER_MENU_WORKSPACES_TITLE: 'stk-user-menu-workspaces-title',
    USER_MENU_WORKSPACES_ADD_NEW: 'stk-user-menu-workspaces-add-new',
    USER_MENU_STACKER_CLASSIC: 'stk-user-menu-stacker-classic',
    EDITING_RECORD: 'stk-editing-record',
    ACTION_BUTTON_TEXT: 'stk-action-button-text',
    PAGINATION_BUTTON_NEXT: 'stk-pagination-next',
    PAGINATION_BUTTON_BACK: 'stk-pagination-back',
    FIELD_ERROR: 'stk-field-error',
    EDIT_BUTTON: 'stk-edit-button',
}

export default STYLE_CLASSES

export const OBJECT_CLASSES = ['PAGE']
export const PAGE_CLASSES = ['PAGE']
export const FIELD_CLASSES = ['ATTRIBUTE', 'TABLE_CELL', 'TABLE_HEADER_CELL', 'CARD_CELL']

export const ONBOARDING_CLASSES = {
    APP_NAV_MENU_ITEM: 'admin-app-nav-menu-item',
    TABLE_ITEM: 'admin-table-item',
    ADD_NEW_RECORD_BUTTON: 'admin-add-new-record-button',
    SAVE_RECORD_BUTTON: 'admin-detail-view-save-button',
    DETAIL_VIEW_EDIT_BUTTON: 'admin-detail-view-edit-button',
    EDIT_LAYOUT_BUTTON: 'admin-edit-layout-button',
    EDIT_LAYOUT_DRAG_AND_DROP_HIGHLIGHTS: 'admin-edit-layout-drag-and-drop-highlights',
    EDIT_LAYOUT_DRAG_AND_DROP_FIELDS: 'admin-edit-layout-drag-and-drop-fields',
    EDIT_LAYOUT_DRAG_AND_DROP_ANY: 'admin-edit-layout-drag-and-drop-any',
    EDIT_LAYOUT_HIGHLIGHTS: 'admin-edit-layout-highlights',
    EDIT_LAYOUT_FIELDS: 'admin-edit-layout-fields',
    EDIT_LAYOUT_CLOSE_BUTTON: 'admin-edit-layout-close-button',
    EDIT_LAYOUT_FIELD_CONTAINER: 'admin-edit-layout-field-container',
    EDIT_LAYOUT_FIELD_ITEM: 'admin-edit-layout-field-item',
    EDIT_LAYOUT_FIELD_SELECTED_ITEM: 'admin-edit-layout-field-selected-item',
    EDIT_LAYOUT_FIELD_ITEM_EDIT_BUTTON: 'admin-edit-layout-field-item-edit-button',
    EDIT_LAYOUT_FIELD_ITEM_SETTINGS: 'admin-edit-layout-field-item-settings',
    EDIT_LAYOUT_HEADER: 'admin-edit-layout-header',
    EDIT_LAYOUT_TEMPLATE: 'admin-edit-layout-template',
    APP_NAV_MENU: 'admin-app-nav-menu',
    TABLE_CONTAINER: 'admin-table-container',
    DETAIL_VIEW_CONTAINER: 'admin-detail-view-container',
    SETTINGS_MENU_BUTTON: 'admin-settings-menu',
    APP_SETTING_MENU_ITEM: 'admin-app-settings-menu-item',
    APP_SETTING_MENU_SETTINGS_ITEM: 'admin-app-settings-menu-settings-item',
    APP_SETTING_MENU_FIELDS_ITEM: 'admin-app-settings-menu-fields-item',
    APP_SETTING_MENU_PERMISSIONS_ITEM: 'admin-app-settings-menu-permissions-item',
    APP_SETTING_MENU_LAYOUTS_ITEM: 'admin-app-settings-menu-layouts-item',
    APP_SETTING_MENU_ACTIONS_ITEM: 'admin-app-settings-menu-actions-item',
    APP_SETTING_MENU_PREVIEW_AS_ITEM: 'admin-app-settings-menu-preview-as-item',
    APP_SETTINGS_DATA_CONNECTION: {
        ITEM: 'admin-app-settings-data-connection-item',
        PERMISSIONS_ITEM: 'admin-app-settings-data-connection-permissions-item',
        SETTINGS_ITEM: {
            DISPLAY_NAME: 'admin-app-settings-data-connection-settings-item-display-name',
            DATA_FILTERS: 'admin-app-settings-data-connection-settings-item-data-filters',
            SET_CREATED_BY: 'admin-app-settings-data-connection-settings-item-set-created-by',
            DELETE_OBJECT: 'admin-app-settings-data-connection-settings-item-delete-object',
            RENAME_OBJECT: 'admin-app-settings-data-connection-settings-item-rename-object',
        },
    },
    APP_SETTINGS_ENABLE_PERMISSIONS: 'admin-app-settings-enable-permissions',
    APP_SETTINGS_PERMISSIONS_LIST: 'admin-app-settings-permissions-list',
    APP_SETTINGS_PERMISSIONS_SAVE_BUTTON: 'admin-app-settings-permissions-save-button',
    APP_SETTINGS_PERMISSIONS_EDIT_BUTTON: 'admin-app-settings-permissions-edit-button',
    APP_SETTINGS_PERMISSIONS_OWNERSHIP_FILTER: 'admin-app-settings-permissions-ownership-filter',
    APP_SETTINGS_PERMISSIONS_DATA_FILTER: 'admin-app-settings-permissions-data-filter',
    APP_SETTINGS_PERMISSIONS_MENU_ITEM: 'admin-app-settings-permissions-menu-item',
    APP_SETTINGS_GENERAL: {
        RENAME_APP: 'admin-app-settings-rename-app',
        CHANGE_URL: 'admin-app-settings-change-url',
        DELETE_APP: 'admin-app-settings-delete-app',
        CACHE_FREQUENCY: 'admin-app-settings-cache-frequency',
        DEFAULT_TABLE_PERMISSIONS: 'admin-app-settings-default-table-premissions',
        COLLABORATION: 'admin-app-settings-collaboration',
    },
    USERS_BUTTON: 'admin-users-button',
    MODAL_OVERLAY: 'admin-modal-overlay',
    MODAL_CLOSE: 'admin-modal-close',
    PREVIEW_AS_LIST: 'admin-preview-as-list',
    APP_SETTINGS_USERS_SHARING_MODAL: 'app-settings-users-sharing-modal',
    APP_SETTINGS_NAV_NEW_PAGE: 'app-settings-nav-new-page',
    APP_SETTINGS_TABLE_NEW_FIELD: 'app-settings-table-new-field',
    LIST_VIEW_ACTIONS: {
        ADD_FIELD: 'admin-list-view-actions-add-field',
        MANAGE_FIELDS: 'admin-list-view-actions-manage-fields',
        EDIT_LAYOUT: 'admin-list-view-actions-edit-layout',
    },
    CREATE_TABLE_MODAL: {
        TABLE_TEMPLATE_CARD: 'admin-create-table-modal-template-card',
        TABLE_NAME: 'admin-create-table-modal-name',
        SAVE_BUTTON: 'admin-create-table-modal-save-button',
    },
    INTEGRATIONS_VIEW_ALL: 'admin-integrations-view-all',
    INTEGRATIONS_SEARCH: 'admin-integrations-search',
    INTEGRATIONS_CATEGORY: 'admin-integrations-category',
}
