import React, { useEffect, useRef } from 'react'
import AceEditor from 'react-ace'
import { useInView } from 'react-intersection-observer'

import ace from 'ace-builds/src-min-noconflict/ace'

import { withObjects } from 'data/wrappers/WithObjects'
import { withPages } from 'data/wrappers/WithPages'

import 'ace-builds/src-noconflict/mode-css'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'

ace.config.set('basePath', 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/')
ace.config.setModuleUrl(
    'ace/mode/css_worker',
    'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-css.js'
)
const langTools = ace.require('ace/ext/language_tools')

const CodeEditor = ({ value, onChange, language, autocompleters, name }) => {
    const aceEditor = useRef()
    const { ref: visibleRef, inView } = useInView()
    useEffect(() => {
        // If we don't do this then the value does not show once the
        // editor is visible
        if (aceEditor.current && inView) {
            aceEditor.current.editor.resize()
        }
    }, [aceEditor, inView])

    useEffect(() => {
        if (autocompleters) {
            langTools.setCompleters([autocompleters])
        }
    }, [autocompleters])

    return (
        <>
            <div ref={visibleRef} />
            <AceEditor
                ref={aceEditor}
                mode={language}
                theme="github"
                // name becomes an id for the editor so it must be unique
                name={name}
                value={value}
                onChange={onChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                width="100%"
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
            />
        </>
    )
}

export default withPages(withObjects(CodeEditor))
