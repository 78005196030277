import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styled from '@emotion/styled'
import { get } from 'lodash'

import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from 'ui/deprecated/atoms/Flex'
import DropdownForm from 'ui/forms/DropdownForm'
import SelectForm from 'ui/forms/SelectForm'
import { RenderInlineElement } from 'ui/forms/ui'

const colors = stackerTheme().colors

function ChartTypeOption({ label, name, onChange, selected }) {
    return (
        <ChartTypeOptionContainer selected={selected} role="button" onClick={() => onChange(name)}>
            {label}
        </ChartTypeOptionContainer>
    )
}

export function ChartTypeSelector({ value, onChange }) {
    return (
        <ChartTypeSelectorContainer alignItems="stretch">
            {CHART_TYPE_OPTIONS.map(({ name, label }) => (
                <ChartTypeOption
                    key={`aggr-option-${name}`}
                    selected={value === name}
                    onChange={onChange}
                    name={name}
                    label={label}
                />
            ))}
        </ChartTypeSelectorContainer>
    )
}

export function ChartTypeSelectorForm({
    label,
    name,
    defaultValue,
    required = false,
    errorMessages = {},
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement label={label} error={error} errorMessages={errorMessages}>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => <ChartTypeSelector {...field} />}
            />
        </RenderInlineElement>
    )
}

export function ChartTypeDropdownForm(props) {
    return <DropdownForm options={CHART_TYPE_OPTIONS} {...props} />
}

export function ChartTypeSelectForm(props) {
    return <SelectForm options={CHART_TYPE_OPTIONS} {...props} />
}

export const CHART_TYPE_OPTIONS = [
    {
        name: 'Number',
        value: 'Number',
        label: 'Number',
    },
    {
        name: 'Pie',
        value: 'Pie',
        label: 'Pie Chart',
    },
    // {
    //     name: 'Bar',
    //     value: 'Bar',
    //     label: 'Bar Chart',
    // },
]

export const CHART_TYPE_GROUP_TYPE_MAP = {
    Number: 'all',
    Pie: 'value',
    Bar: 'value',
}

const ChartTypeSelectorContainer = styled(Flex)`
    margin-bottom: -8px;

    & > div {
        margin-right: 12px;
    }

    & > div:last-of-type {
        margin-right: 0;
    }
`

export const ChartTypeOptionContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    background-color: ${(p) => (p.selected ? colors.gray[200] : colors.gray[100])};
    transition: background-color 0.1s linear, border-color 0.1s linear;
    border-width: 2px;
    border-style: solid;
    border-color: ${(p) => (p.selected ? colors.gray[300] : colors.gray[100])};
    cursor: pointer;
    color: ${colors.gray[800]};
    border-radius: 500px;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 16px;
    margin-bottom: 8px;

    &:hover {
        background-color: ${colors.gray[200]};
        border-color: ${(p) => (p.selected ? colors.gray[300] : colors.gray[200])};
    }
`
