import { EffectCallback, useEffect, useRef } from 'react'

/**
 * Sometimes you have an effect you don't want to run on initial load, but only
 * when one of the dependencies changes. This hook provides that function.
 * */
export default function useEffectOnlyOnUpdate(effect: EffectCallback, deps: any[]) {
    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            return
        }
        effect()
        // have to ignore this rule here, as we specifically don't want 'effect'
        // to be in our list of deps, as that function will be new with each render.
        // it doesn't matter as when any of our actual dependencies change, we'll be executing
        // the latest 'effect' function.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
