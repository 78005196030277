import { useEffect } from 'react'

const useMutationObserver = <T extends HTMLElement | undefined>(
    ref: React.RefObject<T> | React.MutableRefObject<T>,
    callback?: any,
    options = {
        attributes: true,
        childList: true,
        subtree: true,
    }
) => {
    useEffect(() => {
        if (ref.current) {
            const observer = new MutationObserver(callback)
            observer.observe(ref.current, options)
            return () => observer.disconnect()
        }
    }, [callback, options, ref])
}

export default useMutationObserver
