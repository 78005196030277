import * as React from 'react'
import { useState } from 'react'

import { Switch } from '@chakra-ui/react'

import AppContext from 'app/AppContext'
import HELP_LINKS from 'app/helpLinks'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import { useUserLists } from 'data/hooks/userLists'

import { Box, Flex, HelpIcon, Link, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from '../../workspace/UnsavedChangesModal'

import AppearanceSettings from './CustomerAccessAppearance'
import CustomerAccessEditList from './CustomerAccessEditList'
import GeneralSettings from './CustomerAccessGeneralSettings'
import Sidebar from './CustomerAccessSidebar'
import CustomerAccessSplash from './CustomerAccessSplash'
import CustomerAccessUsersList from './CustomerAccessUsersList'

export default function AppModalCustomerAccess({ formRef, children, initialPage = 'general' }) {
    const { selectedStack } = React.useContext(AppContext)
    const updateStackOptions = useUpdateStackOptions()

    const { data: userLists } = useUserLists()
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)

    const [page, setPage] = useState(initialPage)
    let componentProps = {}
    // check for unsaved changes, then change the page
    function onChangePage(name) {
        saveChanges(() => setPage(name))
    }
    // which page have we selected?
    let Component
    switch (page) {
        case 'general':
            Component = GeneralSettings
            break
        case 'appearance':
            Component = AppearanceSettings
            break
        case 'add_customer_list':
            Component = CustomerAccessEditList
            break
        case 'all_users':
            Component = CustomerAccessUsersList
            break

        default:
            const userList = userLists?.find((x) => x._sid === page)
            if (userList) {
                Component = CustomerAccessEditList
                componentProps.userList = userList
                componentProps.key = page
            }
    }

    const toggleEnabled = () =>
        updateStackOptions(selectedStack, { enable_external_access: !isEnabled })

    const isEnabled = selectedStack.options?.enable_external_access

    if (selectedStack.options?.enable_external_access === undefined) {
        return <CustomerAccessSplash />
    }

    return (
        <Flex column height="100%" width="100%" align="stretch" wrap="nowrap">
            <Flex p={2} bg={V4DesignSystem.colors.gray[50]}>
                <Switch size="sm" display="inline" isChecked={isEnabled} onChange={toggleEnabled} />
                {isEnabled ? (
                    <>
                        <Text ml={2} fontWeight="bold" display="inline">
                            Enabled&nbsp;
                        </Text>
                        <Text ml={2} display="inline">
                            Customers can access the app on&nbsp;
                        </Text>
                        <Link
                            display="inline"
                            href={`//${selectedStack.custom_base_url || selectedStack.base_url}`}
                        >
                            {selectedStack.custom_base_url || selectedStack.base_url}
                        </Link>
                    </>
                ) : (
                    <>
                        <Text ml={2} fontWeight="bold" display="inline">
                            Disabled
                        </Text>
                        <Text ml={3} display="inline">
                            Customers cannot access the app
                        </Text>
                    </>
                )}
                <Box flexGrow={1} />
                <HelpIcon link={HELP_LINKS.CUSTOMER_ACCESS} size="md" color="neutral.700" mr={2} />
            </Flex>
            <Flex
                flexGrow={1}
                wrap="nowrap"
                align="stretch"
                style={{
                    borderTop: '1px solid ' + V4DesignSystem.colors.gray[100],
                    maxHeight: '100%',
                    minHeight: '0',
                }}
            >
                <Sidebar onChange={onChangePage} active={page} userLists={userLists} />

                {Component ? (
                    <Component formRef={formRef} onChangePage={onChangePage} {...componentProps}>
                        {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                        {children}
                    </Component>
                ) : (
                    "can't render " + page
                )}
            </Flex>
        </Flex>
    )
}
