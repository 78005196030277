/* Code Quality: Good */

import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { NotificationMenu } from 'features/collaboration/NotificationMenu'
import { Icon8 } from 'legacy/v1/ui'

import { Button, Icon } from 'v2/ui'

import SettingsMenu from './SettingsMenu'

const ButtonText = styled.span`
    display: none;
    margin-left: 10px;
    font-size: 14px;

    @media (min-width: 1160px) {
        display: inline;
        margin-right: 4px;
    }
`

const Tray = ({
    style,
    stackOptions,
    location,
    context,
    isStudioUser,
    isImpersonating,
    isEditing,
    userActions,
    isSecondaryNavigation,
    navTheme,
    workspaceAccount,
    shouldHideAppBarForGuest,
}) => {
    const isAdmin = isStudioUser && !isImpersonating

    // eslint-disable-next-line react/prop-types
    const showStudioLink = stackOptions.enable_studio_link
    const isOnRoute = (url, loc) => loc.pathname === url || loc.pathname.startsWith(`${url}/`)
    const isOnAdminRoute = isOnRoute('/admin', location)

    const color = isSecondaryNavigation ? get(navTheme, 'button.color', '#ffffff') : '#B0B3B9'
    const selectedColor = get(navTheme, 'button.selectedColor', '#000000')

    const buttonStyle = {
        color: color,
        cursor: 'pointer',
        height: '32px',
        padding: '4px',
        minWidth: '32px',
        borderRadius: '3px',
        backgroundColor: get(navTheme, 'button.background', '#F4F5F8'),
        opacity: get(navTheme, 'button.opacity', 1),
        marginLeft: '8px',
        transition: 'color .1s linear',
    }

    return (
        <TrayContainer style={style}>
            {workspaceAccount && isAdmin && shouldHideAppBarForGuest === false ? null : (
                <NotificationMenu navTheme={navTheme} />
            )}
            {isAdmin && showStudioLink && (
                <Link to={getUrl(Urls.StudioTables)}>
                    <Icon8
                        icon="wrench"
                        size="100"
                        displaySize="25"
                        iconStyle="ios-filled"
                        color="ffffff"
                        style={{ marginRight: 10, ...buttonStyle }}
                    />
                </Link>
            )}
            {isAdmin && !workspaceAccount && (
                <MenuButton>
                    <Button
                        onClick={() => {
                            if (isEditing) {
                                userActions.stopEditing()
                            } else {
                                userActions.startEditing()
                            }
                        }}
                        style={{
                            ...buttonStyle,
                            padding: isEditing ? '0px 6px 0px 6px' : '4px 6px 4px 8px',
                            color: isEditing ? selectedColor : color,
                            border: isEditing ? `2px solid ${selectedColor}` : undefined,

                            // hide on /admin routes as doesn't do anything obvious for user,
                            // just toggles layout mode
                            ...(isOnAdminRoute && { display: 'none' }),
                        }}
                    >
                        <Icon size="16" icon="editBox" />
                        <ButtonText>Layout</ButtonText>
                    </Button>
                </MenuButton>
            )}
            {isAdmin && !workspaceAccount && (
                <SettingsMenu style={buttonStyle} context={context} navTheme={navTheme} />
            )}
        </TrayContainer>
    )
}

Tray.propTypes = {
    context: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired, // From withRouter
    style: PropTypes.object,
    isStudioUser: PropTypes.bool.isRequired, // From withUser
    isImpersonating: PropTypes.bool.isRequired, // From withUser
    stackOptions: PropTypes.object.isRequired, // From withStack
    userActions: PropTypes.object.isRequired, // From withUser
    isEditing: PropTypes.bool.isRequired, // From withUser
}

Tray.defaultProps = {
    style: {},
}

export default withStack(withUser(withRouter(Tray)))

const TrayContainer = styled('div')`
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

    &:empty {
        display: none;
    }
`
const MenuButton = styled('div')`
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;
`
