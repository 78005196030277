import React, { useCallback } from 'react'

import * as Sentry from '@sentry/react'

import { PostgresConnectionDetailsForm } from './PostgresConnectionDetailsForm'
import { STEPS } from './postgresConnectorConstants'
import {
    PostgresConnectionDetailsFormData,
    PostgresConnectionEditorProps,
} from './postgresConnectorTypes'

export const EditExistingPostgresConnection: React.FC<PostgresConnectionEditorProps> = ({
    context,
}) => {
    const {
        dataConnection,
        setDataConnection,
        setNextHandler,
        setIsSaving,
        setStep,
        onClose,
        updateDataConnection,
        setNextButtonText,
        isTesting,
        setIsTesting,
    } = context

    const onSubmit = useCallback(
        (values: PostgresConnectionDetailsFormData, paramsChanged: boolean) => {
            const { is_read_only, is_changing_password, ...secretOptions } = values as Omit<
                PostgresConnectionDetailsFormData,
                'sql_password'
            > & { sql_password?: string }
            setIsSaving(true)
            if (!is_changing_password) {
                delete secretOptions.sql_password
            }
            const data = {
                is_read_only: is_read_only,
                updated_secret_options: secretOptions,
            }
            updateDataConnection(data)
                .then((connection) => {
                    setIsSaving(false)
                    setDataConnection(connection)
                    if (paramsChanged) {
                        setStep(STEPS.syncSchema)
                    } else {
                        onClose()
                    }
                })
                .catch(Sentry.captureException)
                .finally(() => setIsSaving(false))
        },
        [onClose, setDataConnection, setIsSaving, setStep, updateDataConnection]
    )

    if (!dataConnection) {
        console.error('Data connection not found, cannot edit')
    }

    return dataConnection ? (
        <PostgresConnectionDetailsForm
            dataConnection={dataConnection}
            isCreate={false}
            onSubmit={onSubmit}
            setNextHandler={setNextHandler}
            setNextButtonText={setNextButtonText}
            isTesting={isTesting}
            setIsTesting={setIsTesting}
        />
    ) : null
}
