import React from 'react'

import { Dropdown } from 'v2/ui'

const oneToMany = { label: 'one to many', value: 'one-to-many' }
const manyToMany = { label: 'many to many', value: 'many-to-many' }

export const LinkFormModeDropdown = ({ value, onChange, allowManyToMany }) => {
    if (!allowManyToMany && value === manyToMany.value) {
        onChange(oneToMany.value)
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown
                options={allowManyToMany ? [oneToMany, manyToMany] : [oneToMany]}
                onChange={onChange}
                value={value}
                variant="settings"
            />
        </div>
    )
}
