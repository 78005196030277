import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type Props = {
    delay: number
    onHoverStart?: () => void
    onHoverEnd?: () => void
}

export default function useHover(
    { delay, onHoverStart, onHoverEnd }: Props = { delay: 0 }
): [boolean, { onMouseOver: () => void; onMouseLeave: () => void }] {
    const [hovered, setHovered] = useState(false)
    const timeout = useRef<NodeJS.Timeout | undefined>()
    const currentlyHovered = useRef<boolean>(false)

    const hoverStarted = useCallback(() => {
        setHovered(currentlyHovered.current)
        if (currentlyHovered.current && onHoverStart) {
            onHoverStart()
        }
    }, [onHoverStart])
    const hoverEnded = useCallback(() => {
        setHovered(false)
        onHoverEnd?.()
    }, [onHoverEnd])
    const eventHandlers = useMemo(
        () => ({
            onMouseOver() {
                currentlyHovered.current = true

                if (delay) {
                    timeout.current = setTimeout(hoverStarted, delay)
                } else {
                    hoverStarted()
                }
            },
            // Mouse leave is better than mouseOut because mouse out bubbles up
            // from any child element when the mouse leaves it. Mouse Leave fires
            // only when the mouse leaves this parent container.
            onMouseLeave() {
                if (timeout.current) clearTimeout(timeout.current)
                currentlyHovered.current = false
                hoverEnded()
            },
        }),
        [delay, hoverEnded, hoverStarted]
    )

    useEffect(() => {
        return () => {
            if (timeout.current) clearTimeout(timeout.current)
        }
    }, [])

    return [hovered, eventHandlers]
}
