import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import { RecordManager } from 'data/hooks/useRecordManager'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'

import { getFieldsWithPerms } from './getFieldsWithPerms'
import { useObjectFromId } from './helpers'

export type Props = {
    step: ActionStep
    recordId: string
    recordManager: RecordManager
    updateStepData: (stepId: string, stepData: { fields: Partial<RecordDto> }) => void
    setValid: (key: string, value: boolean) => void
    showErrors: boolean
}

const UpdateRecordStep: FC<Props> = ({
    step,
    recordId,
    recordManager,
    updateStepData,
    setValid,
    showErrors,
}) => {
    const { record, updateRecord } = recordManager
    const { object } = useObjectFromId(record?._object_id)
    const initializedRecord = useRef<string>()

    const processFilter = useProcessFilter()
    const fieldConditionalVisibilityFilters = step.fieldConditionalVisibilityFilters

    const setValue = useCallback(
        (name, value) => {
            updateRecord({ [name]: value })
        },
        [updateRecord]
    )

    const fieldsWithPerms = useMemo(() => {
        return getFieldsWithPerms(record, object, step.fields)
    }, [record, object, step.fields])

    const visibleFieldsWithPerms = useMemo(
        () =>
            fieldsWithPerms.filter(
                (field) =>
                    !fieldConditionalVisibilityFilters?.[field.fieldId] ||
                    processFilter([record], fieldConditionalVisibilityFilters[field.fieldId])
                        ?.length > 0
            ),
        [fieldConditionalVisibilityFilters, fieldsWithPerms, record, processFilter]
    )

    useEffect(() => {
        if (!record) return

        if (initializedRecord.current !== recordId) {
            const updates: Partial<RecordDto> = {}
            for (let field of visibleFieldsWithPerms.filter((field) => field.value !== undefined)) {
                updates[field.fieldName] = field.value
            }
            initializedRecord.current = recordId
            updateRecord(updates)
        } else {
            const values: Partial<RecordDto> = {}
            for (const field of visibleFieldsWithPerms) {
                values[field.fieldName] = record[field.fieldName]
            }

            updateStepData(step.id, { fields: values })
        }
    }, [record, recordId, visibleFieldsWithPerms, step.id, updateRecord, updateStepData])

    return (
        <>
            {visibleFieldsWithPerms
                .filter((field) => field.promptUser)
                .map(
                    ({
                        fieldId,
                        required,
                        fullWidth,
                        readOnly,
                        label,
                        description,
                        ...otherOptions
                    }) => (
                        <AttributeDisplay
                            key={fieldId}
                            fieldId={fieldId}
                            record={record}
                            required={required}
                            fullWidth={fullWidth}
                            readOnly={readOnly}
                            editing
                            isVisible
                            showErrors={showErrors}
                            setValue={setValue}
                            setValid={setValid}
                            labelOverride={label}
                            editDescription={description}
                            {...otherOptions}
                        />
                    )
                )}
        </>
    )
}

export default UpdateRecordStep
