import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { RenderInlineElement } from './ui'

/**
 *
 * @param {{name: string, label?: string, defaultValue?: any, errorMessages?: {[keyof: string]: string}} & Partial<import('react-hook-form').RegisterOptions>} param0
 */
export default function ObjectPickerForm({
    name,
    label = name,
    defaultValue = null,
    required = false,
    errorMessages = {},
    ...props
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement label={label} error={error} errorMessages={errorMessages}>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <>
                        <ObjectPicker placeholder="Select a table" {...field} {...props} />
                    </>
                )}
            />
        </RenderInlineElement>
    )
}
