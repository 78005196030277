import { UseQueryOptions } from 'react-query'

import { invalidateStacks } from './stacks/stackOperations'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useAccounts'
const ENDPOINT = 'accounts/'

export function useAccounts(options: UseQueryOptions<Account[]> = {}) {
    return useQuery<Account[]>(
        LIST_NAME,
        ENDPOINT,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS },
        {
            bypassMatchingStackCheck: true,
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}

export function useCreateAccount() {
    return useCreateItem<Account>(LIST_NAME, ENDPOINT, undefined, {
        // Submit this request using the studio user's token
        // and ignore any impersonation or role previewing.
        bypassPreviewAndImpersonation: true,
    })
}

export function useUpdateAccount() {
    return useUpdateItem<Account>(
        LIST_NAME,
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateStacks()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}

export function useDeleteAccount() {
    return useDeleteItem<Account>(LIST_NAME, ENDPOINT, undefined, {
        // Submit this request using the studio user's token
        // and ignore any impersonation or role previewing.
        bypassPreviewAndImpersonation: true,
    })
}

export function invalidateAccounts() {
    return queryClient.invalidateQueries(LIST_NAME)
}
export function refetchAccounts() {
    return queryClient.refetchQueries(LIST_NAME)
}
// Stubbed in support for user "groups". Currently we only have one group: workspace collaborators.
// But eventually we'll allow custom groups at the workspace level. This code will need to be extended
// then to include those groups.
export const workspaceGroups: WorkspaceGroupDto[] = [
    { _sid: 'workspace', name: 'Workspace Collaborators' },
]
