// @ts-strict-ignore
import React from 'react'

import { truncate } from 'lodash'

import { getShortFieldName } from 'features/admin/utils'

import { Icon, Text } from 'v2/ui'
import { DeleteTable } from 'v2/ui/svgs'
import { ModalDeclaration } from 'v2/ui/utils/useModalToggle'

/**
 * Get the full name of a field for display
 * @example Users → Name
 * @param object
 * @param field
 * @returns
 */
export const fieldFullName = (object, field) =>
    `${truncate(object.name, { length: 12 })} → ${field.label}`

export const extractObjectAndField = (
    fullName: string
): { objectName: string; fieldLabel: string } => {
    // TODO: What is the fullName contains an arrow as well?
    const splits = fullName.split(' → ')
    return {
        objectName: splits[0].trim(),
        fieldLabel: splits[1].trim(),
    }
}

export const isFieldShadowed = (field) => {
    return !!field?.connection_options.is_shadowed
}

export const getDeleteTableModalData = (object?: ObjectDto): ModalDeclaration<unknown> => {
    const isNativeObject = object?.connection_options?.stacker_native_object
    return {
        icon: <Icon svg={<DeleteTable />} size="56px" ml="15px" mb={4} />,
        confirmVariant: 'PrimaryError',
        message: (
            <>
                <Text fontWeight="bold">Delete {object?.name}?</Text>

                <Text mt={4}>Are you sure you want to delete this table?</Text>

                {isNativeObject ? (
                    <Text mt={4} fontWeight="bold">
                        This will also delete all data contained in this table. This action cannot
                        be undone.
                    </Text>
                ) : null}
            </>
        ),
    }
}

const TRUNCATED_FIELD_LABEL_LENGTH = 40

export function getTruncatedFieldLabel(field: FieldDto): string {
    return truncate(field.label, {
        length: TRUNCATED_FIELD_LABEL_LENGTH,
        omission: '…',
    })
}

const TRUNCATED_FIELD_DEVELOPER_NAME_LENGTH = 50

export function getTruncatedFieldDeveloperName(field: FieldDto): string {
    const developerName = getShortFieldName(field)

    return truncate(developerName, {
        length: TRUNCATED_FIELD_DEVELOPER_NAME_LENGTH,
        omission: '…',
    })
}
