import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import Button from 'ui/deprecated/atoms/Button'

import { LinkModalLayout } from './modal/LinkModalLayout'

export const LinkModalForm = ({
    children,
    form,
    isOpen,
    isSecondLayer = false,
    minHeight,
    onClose,
    onSubmit,
}: {
    children: React.ReactNode
    form: UseFormReturn<any>
    isOpen: boolean
    isSecondLayer?: boolean
    minHeight: string
    onClose: () => void
    onSubmit: (data: LinkUpdateData) => Promise<unknown>
}): React.ReactElement => {
    const values = form.watch()
    const hasErrors = Object.values(form.formState.errors).length > 0
    const isDisabled = !form.formState.isValid || hasErrors || form.formState.isSubmitting

    const handleSubmit = ({
        enableFilters,
        filters,
        ...rest
    }: LinkUpdateData & { enableFilters: boolean }) => {
        return onSubmit({
            // If filters aren't enabled
            // we set the filters to an empty list
            filters: enableFilters ? filters : [],
            ...rest,
        })
    }

    return (
        <LinkModalLayout
            isOpen={isOpen}
            onClose={onClose}
            showCloseButton
            legacy={values.legacy}
            size={800}
            isSecondLayer={isSecondLayer}
            minHeight={minHeight}
            action={
                <>
                    {values.source && values.target && (
                        <Button
                            // @ts-expect-error
                            style={{ flex: 1 }}
                            v4
                            type="primary"
                            onClick={form.handleSubmit(handleSubmit)}
                            disabled={isDisabled}
                        >
                            Save
                        </Button>
                    )}
                </>
            }
        >
            {children}
        </LinkModalLayout>
    )
}
