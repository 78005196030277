// @ts-strict-ignore
import React from 'react'
import { useWatch } from 'react-hook-form'

import FieldPicker from 'features/studio/ui/FieldPicker'

import {
    getIsMultiLinkLookupTargetField,
    getIsMultiLookupRelationship,
    getIsMultiLookupSymmetricRelationship,
    getIsSingleLinkLookupTargetField,
} from '../../../utils/fieldUtils'

import { ConfigurationComponentProps } from './common'
import { ConfigurationField } from './ui'

function filterRelationshipField(field) {
    const isLookup = field?.type === 'lookup'
    const isMultiLookupRelationship = getIsMultiLookupRelationship(field)
    const isMultiLookupSymmetricRelationship = getIsMultiLookupSymmetricRelationship(field)
    return isLookup || isMultiLookupRelationship || isMultiLookupSymmetricRelationship
}

export const LookupFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({ object }) => {
    const fieldId = useWatch({ name: 'connection_options.lookup_relationship_field' })
    const field = object?.fields?.find((f) => f._sid === fieldId)

    return (
        <>
            <ConfigurationField
                label="From the record linked in"
                as={FieldPicker}
                objectId={object?._sid}
                name="connection_options.lookup_relationship_field"
                placeholder="Select a field"
                filter={filterRelationshipField}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                show={!!field}
                label="Display the"
                as={FieldPicker}
                objectId={field?.options?.lookup_target}
                name="connection_options.lookup_field"
                placeholder="Select a field"
                filter={
                    field?.type === 'lookup'
                        ? getIsSingleLinkLookupTargetField
                        : getIsMultiLinkLookupTargetField
                }
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
        </>
    )
}
