import React from 'react'
import { Link } from 'react-router-dom'

import { Menu, MenuButton, MenuDivider, MenuGroup, MenuList } from '@chakra-ui/react'

import { useAccountUserContext } from 'app/AccountUserContext'
import { withAccounts } from 'data/wrappers/WithAccounts'
import { withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import publicAsset from 'utils/publicAsset'

import { Button, Flex, Icon, MenuItem, ScrollBox, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { openProfileSettingsModal } from '../profile/ProfileSettingsModal'

import { useCreateWorkspaceModal } from './CreateWorkspaceModal'

const UserMenu = ({
    studioUser,
    accounts,
    stacks,
    workspaceAccount,
    children,
    maxBoxHeight = '60vh',
    as,
    ...props
}) => {
    const { role: accountRole } = useAccountUserContext()
    const { show: showCreateWorkspaceModal } = useCreateWorkspaceModal()
    // Grab the first non-workspace app, if any, for displaying the
    // "Open Stacker Classic" option to let them get back to V3.
    const v3app = stacks?.find((stack) => !stack.options?.workspace_app)
    // Only show the workspaces section if this user is a workpace member (not a guest)
    // or has access to other workspaces
    const showWorkspaces = accounts?.length !== 1 || accountRole
    return (
        <Menu>
            <MenuButton as={as || Button} {...props}>
                {children}
            </MenuButton>

            <MenuList zIndex={9999}>
                <MenuGroup
                    title={
                        showWorkspaces && (
                            <Flex
                                className={STYLE_CLASSES.USER_MENU_WORKSPACES_TITLE}
                                textAlign="left"
                            >
                                <Text flexGrow={1}>Workspaces</Text>
                                <Icon
                                    button
                                    icon="add"
                                    size="sm"
                                    color="gray.400"
                                    onClick={showCreateWorkspaceModal}
                                    outerClassName={STYLE_CLASSES.USER_MENU_WORKSPACES_ADD_NEW}
                                    data-testid="user-menu.add-workspace"
                                />
                            </Flex>
                        )
                    }
                >
                    {showWorkspaces && (
                        <>
                            <ScrollBox
                                className={STYLE_CLASSES.USER_MENU_WORKSPACES}
                                style={{ maxHeight: maxBoxHeight }}
                            >
                                {accounts
                                    .filter(
                                        (x) =>
                                            stacks?.find(
                                                (stack) =>
                                                    stack.account_id === x._sid &&
                                                    stack.options?.workspace_app
                                            ) || x.is_workspace
                                    )
                                    .map((x) => {
                                        // the ignoreProfile param here tells us not to
                                        // prompt the user to fill out missing profile information
                                        // when switching to a workspace via the switcher
                                        let url = `//${x.url}/`

                                        // if the workspace is using auth0 or we don't have an api_token on hand,
                                        // send them to login page with our current user ID in the check_user param so it
                                        // will redirect them to home if the right user is logged in.
                                        //
                                        // Otherwise we can log them in using the api_token automatically.
                                        if (
                                            !x.optional_features?.auth0_enabled &&
                                            studioUser.api_token
                                        ) {
                                            url += `auth?ignoreProfile=1&token=${studioUser.api_token}`
                                        } else {
                                            url += `login?check_user=${studioUser._sid}`
                                        }

                                        return (
                                            <MenuItem key={x._sid} as="a" href={url}>
                                                <Icon
                                                    icon={x === workspaceAccount && 'check'}
                                                    mr={2}
                                                    width="1em"
                                                />
                                                {x.name || x._sid}
                                            </MenuItem>
                                        )
                                    })}
                            </ScrollBox>
                            <MenuDivider color="gray.300" />
                        </>
                    )}
                    {v3app && accountRole?.api_name === 'owner' && (
                        <MenuItem
                            className={STYLE_CLASSES.USER_MENU_STACKER_CLASSIC}
                            as={'a'}
                            href={`//${v3app.url}/admin?api_token=${studioUser.api_token}&r=/admin/account/apps`}
                        >
                            <img
                                src={publicAsset('/static/media/stacker-fold.png')}
                                style={{
                                    width: '1rem',
                                    marginRight: '8px',
                                    verticalAlign: 'middle',
                                }}
                            />
                            Open Stacker Classic
                        </MenuItem>
                    )}
                    <MenuItem
                        className={STYLE_CLASSES.USER_MENU_SETTINGS}
                        icon="avatar"
                        onClick={openProfileSettingsModal}
                    >
                        My Settings
                    </MenuItem>

                    <MenuItem
                        className={STYLE_CLASSES.USER_MENU_LOGOUT}
                        icon="signOut"
                        as={Link}
                        to="/logout"
                    >
                        Log Out
                    </MenuItem>
                </MenuGroup>
            </MenuList>
        </Menu>
    )
}
export default withUser(withAccounts(withStacks(UserMenu)))
