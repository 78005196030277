import React, { useContext } from 'react'
// @ts-strict-ignore - because typescript strict complains about this import being untyped

export const Rights = {
    Admin: {
        All: 'admin',
        Any: 'admin.*',
        DataConnections: {
            All: 'admin.dataconnections',
            Any: 'admin.dataconnections.*',
            View: 'admin.dataconnections.view',
        },
    },
    AllDataAccess: 'all_data_access',
}

export type AppUserContextValue = {
    user?: UserDto
    studioUser?: UserDto
    role?: RoleDto | null
    previewing: boolean
    isStudioUser: boolean
    isLoggedIn: boolean
    isImpersonating: boolean
    isEditing: boolean
    isLoading: boolean
    isLoadingIncludingStack: boolean
    isAdmin: boolean
    authStateKey?: number
    hasRight: (right: string) => boolean
    previewAsRole: (roleId?: string) => void
    previewAsUser: (user: any) => void
}
export const defaultContext: AppUserContextValue = {
    user: undefined,
    studioUser: undefined,
    role: undefined,
    previewing: false,
    isStudioUser: false,
    isLoggedIn: false,
    isImpersonating: false,
    isEditing: false,
    isLoading: false,
    isLoadingIncludingStack: false,
    isAdmin: false,
    authStateKey: undefined,
    hasRight: () => false,
    previewAsRole: () => {},
    previewAsUser: () => {},
}

export const AppUserContext = React.createContext(defaultContext)

export const useAppUserContext = () => {
    return useContext(AppUserContext)
}
export default AppUserContext
