import { recordApi } from 'data/api/recordApi'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

import { lockRecordFromUpdate, unlockRecordFromUpdate } from '../../api/recordLock'
import { queryClient } from '../_helpers'
import { invalidateAppUsersList } from '../users/invalidateAppUsersList'

import { updateRecordQuery } from './updateRecordQuery'

// These methods can be used outside of react components
/**
 * Updates a record and adds to the query cache
 * @param {string} recordId
 * @param {any | undefined} data
 */

export const updateRecord = (
    recordId: string,
    data: RecordDto,
    options: any = {}
): Promise<any> => {
    // Optimistically update first
    if (options?.deferStoreUpdate) {
        updateRecordCache(data)
    }
    // Undefined can come when setting a number field to empty, but this is ignored by
    // recordApi.patch - let's convert these into nulls.
    for (const key of Object.keys(data)) {
        if (data[key] === undefined) {
            data[key] = null
        }
    }

    lockRecordFromUpdate(recordId)
    return recordApi
        .patch(recordId, data, options)
        .then((response) => {
            updateRecordCache(response as any)
            if ((response as any)?.[0]?._reload_app_users) {
                invalidateAppUsersList()
            }

            return response
        })
        .finally(() => {
            unlockRecordFromUpdate(recordId)
        })
}
/**
 * Paste/import raw data to update records and create if necessary
 *
 * @param {string} objectId
 * @param {any} records
 */

export const importRawRecords = async (
    objectId: string,
    records: any,
    options: any = {}
): Promise<void> => {
    const path = `objects/${objectId}/records/import_raw/`
    const url = buildUrl(path)

    await fetchAndReturn(
        url,
        {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(records),
        },
        null,
        null,
        options
    )
}
/**
 * Creates a record and adds to the query cache
 * @param {any} data
 */

export const createRecord = (data: RecordDtoForCreation, options: any = {}): Promise<any> => {
    return recordApi.post(data, options).then((response) => {
        const records = Array.isArray(response) ? response : [response]
        updateRecordQuery(records, true)
        //
        // need to invalidate any query caches for objects where new records have been created
        const objects = new Set(records.map((record) => record._object_id))
        objects.forEach((object) => {
            invalidateRecords(object)
        })
        if ((response as any)?._reload_app_users) {
            invalidateAppUsersList()
        }
        return response
    })
}
/**
 * Deletes a record and invalidates the query cache
 * @param {string} recordId
 */

export const deleteRecord = (recordId: string): Promise<any> => {
    return recordApi
        .delete(recordId)
        .then((response) => response.json())
        .then((response) => {
            queryClient.invalidateQueries(['record', recordId])
            queryClient.invalidateQueries(['records', response?._object_id])
            if (response?._reload_app_users) {
                invalidateAppUsersList()
            }
            return response
        })
}
/**
 * Deletes multiple records and invalidates the query cache
 * @param {string} objectId
 * @param {string[]} records
 */

export const deleteRecords = (
    objectId: string,
    records: string[],
    options: any = {}
): Promise<any> => {
    const path = `objects/${objectId}/records/delete/`
    return fetchAndReturn(
        buildUrl(path),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ record_ids: records }),
        },
        null,
        null,
        options
    ).then((response) => {
        records.forEach((recordId) => queryClient.invalidateQueries(['record', recordId]))
        queryClient.invalidateQueries(['records', objectId])
        if ((response as any)?._reload_app_users) {
            invalidateAppUsersList()
        }
    })
}

/**
 * Directly the record in the query cache & invalidate the object queries
 * @param {any} data
 */

export const updateRecordCache = (data: RecordDto | RecordDto[]): void => {
    const records = Array.isArray(data) ? data : [data]
    updateRecordQuery(records, true)
}
/**
 * Invalidates object records so that it will be refetched by any components using the query
 * @param {string} objectId
 */
export const invalidateRecords = (objectId: string): Promise<any> => {
    return queryClient.invalidateQueries(['records', objectId])
}
/**
 * Invalidates the record so that it will be refetched by any components using the query
 * @param {string} recordId
 */
export const invalidateRecord = (recordId: string): Promise<any> => {
    return queryClient.invalidateQueries(['record', recordId])
}
