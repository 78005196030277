import React from 'react'

import { Box, Tooltip } from 'v2/ui'
import { SolidInfo } from 'v2/ui/svgs'

type InfoTooltipProps = {
    label: string
}
export const InfoTooltip: React.FC<InfoTooltipProps> = ({ label }) => {
    return (
        <Tooltip
            label={label}
            placement="bottom"
            maxWidth="170px"
            labelStyle={{ fontSize: '0.85rem' }}
        >
            <Box
                color="userInterface.neutral.700"
                transition="color 0.1s ease-out"
                _hover={{
                    color: 'userInterface.accent.1000',
                }}
                mt="-0.3rem"
            >
                <SolidInfo size={11} color="currentColor" />
            </Box>
        </Tooltip>
    )
}
