export function shouldAllowAddNewButton(
    listType: string,
    targetObject?: ObjectDto,
    selectedField?: FieldDto,
    relationshipField?: FieldDto,
    symmetricField?: FieldDto
): boolean {
    if (targetObject?.connection_options?.read_only) return false
    if (listType === 'all') return true

    const isSymmetricFieldDisabled = symmetricField?.connection_options?.is_disabled
    if (isSymmetricFieldDisabled) return false

    const isSelectedFieldExternal = selectedField?.isExternalField

    const airTableRelType = relationshipField?.connection_options?.airtable_relation_type
    const isSupportedAirtableRelType =
        airTableRelType === 'many-many' || airTableRelType === 'many-one'

    if (isSelectedFieldExternal || isSupportedAirtableRelType) {
        return true
    }

    const isRelationshipFieldMultiLookup = relationshipField?.type === 'multi_lookup'
    const isStackerNativeObject = targetObject?.connection_options?.stacker_native_object
    const hasObjectField = targetObject?.fields?.some(
        (f) => f.api_name === relationshipField?.api_name
    )
    if (isRelationshipFieldMultiLookup && isStackerNativeObject && hasObjectField) {
        return true
    }

    return false
}
