import { bindActionCreators } from 'redux'

import store from 'app/store'
import { permissionRuleActions } from 'data/api/permissionsApi'

export function refetchPermissions() {
    // Refetch data connections
    // todo: Move to react-query
    const dispatch = store.dispatch
    const boundPermissionsActions = bindActionCreators(permissionRuleActions, dispatch)
    return boundPermissionsActions.fetch(undefined, { force: true, overrideLocalCache: false })
}

export function invalidatePermissions() {
    // Invalidate permissions
    // todo: Move to react-query - for now it just calls refetch
    return refetchPermissions()
}
