import { SubscriptionStatus } from './types'

/**
 * Checks if the object is the Stacker User object
 * @returns True if the object is the Stacker User object
 */
export const getIsStackerUserObject = (o: ObjectDto): boolean => {
    return o.connection_options?.simpleconn_object === 'STACKER_USERS'
}

export const getSubscriptionStatus = (workspaceAccount: Account): SubscriptionStatus => {
    return workspaceAccount?.subscription_status
}

export const isSubscriptionCancelled = (workspaceAccount: Account): boolean => {
    return getSubscriptionStatus(workspaceAccount) === 'cancelled'
}
