/* Code Quality: Not audited */

import React from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import { blockActions, getBlockEntitiesMemoized, getBlocksMemoized } from 'data/api/blockApi'

class UnconnectedWithBlock extends React.Component {
    render() {
        const blocksData = this.props.blocks
        if (!blocksData) return this.props.loading ? this.props.loading : ''
        const blocks = Object.values(blocksData)
        return this.props.children({
            blocks,
            blockEntities: this.props.blockEntities,
            onChange: this.props.blockActions.update,
            createBlock: this.props.blockActions.create,
        })
    }

    constructor(props) {
        super(props)
        if (!props.blocks || !props.blocks.length) props.blockActions.fetch()
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.dontUpdate === true) return false
        return true
    }
}

UnconnectedWithBlock.propTypes = {}

function mapStateToProps(state) {
    return {
        blocks: getBlocksMemoized(state),
        blockEntities: getBlockEntitiesMemoized(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        blockActions: bindActionCreators(blockActions, dispatch),
    }
}

const WithBlocks = connect(mapStateToProps, mapDispatchToProps)(UnconnectedWithBlock)

export default WithBlocks

export const withBlocks = (Child) => (props) =>
    <WithBlocks>{(blockProps) => <Child {...blockProps} {...props} />}</WithBlocks>
