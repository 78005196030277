import React, { FC } from 'react'

import { Box, Text } from 'v2/ui'
import { SolidCheck } from 'v2/ui/svgs'

import { CustomRadioGroup } from '../CustomRadio'

const CHECKBOX_FIELD_OPTIONS = [
    { value: 'default', label: 'Default', icon: <SolidCheck size={16} /> },
    {
        value: 'filled',
        label: 'Filled',
        icon: (
            <Box p={1} bg="userInterface.accent.1000" borderRadius="4px">
                <SolidCheck size={12} color="white" />
            </Box>
        ),
    },
    {
        value: 'big',
        label: 'Big',
        icon: (
            <Box p={2} bg="userInterface.accent.1000" borderRadius="50%">
                <SolidCheck size={18} color="white" />
            </Box>
        ),
    },
]

type Props = {
    setState: (state: { [keyof: string]: any }) => void
    sendUpdate: any
    queuedChanges: any
    selectedOptions?: string
}

const CheckboxAttributeEditor: FC<Props> = ({
    setState,
    sendUpdate,
    queuedChanges,
    selectedOptions,
}) => {
    const onChange = (value: 'default' | 'filled' | 'big') => {
        queuedChanges.current = { ...queuedChanges.current, checkboxDisplay: value }
        sendUpdate()
        setState((state: { [keyof: string]: any }) => ({ ...state, checkboxDisplay: value }))
    }

    return (
        <>
            <Text variant="paletteSectionLabel">Display</Text>
            <CustomRadioGroup
                selectedOption={selectedOptions}
                options={CHECKBOX_FIELD_OPTIONS}
                onChange={onChange}
            />
        </>
    )
}

export default CheckboxAttributeEditor
