import { createSelector } from 'reselect'

import { COMPOUNDS_FETCHED } from '../utils/constants'
import { StackerActions } from '../utils/stackerActions'
import { StackerAPI } from '../utils/utils'

// API
class CompoundApi extends StackerAPI {
    path = 'compound-blocks/'
}
const compoundApi = new CompoundApi()

class CompoundActions extends StackerActions {
    api = compoundApi

    reducer = 'compounds'

    name = 'compounds'

    actionTypes = {
        fetched: COMPOUNDS_FETCHED,
    }
}

const CA = new CompoundActions()
CA.actions = { ...CA.actions }
export const compoundActions = CA.actions

// SELECTORS
export const getCompoundEntities = (state) => state.compounds.entities
export const getCompoundsList = (state) => state.compounds.compounds

export const getCompoundsMemoized = createSelector(
    getCompoundsList,
    getCompoundEntities,
    (compounds, entities) => compounds.map((compoundId) => entities[compoundId])
)
export const getCompoundEntitiesMemoized = createSelector(getCompoundEntities, (item) => item)

// HELPERS
