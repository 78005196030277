// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

export const GlobalModalContext = React.createContext<{
    registerModal: (key: string, Component: React.ComponentType) => void
    unregisterModal: (key: string) => void
}>({
    registerModal: () => {},
    unregisterModal: () => {},
})

export default function GlobalModals({ children }) {
    const [modals, setModals] = useState<{ [key: string]: React.ComponentType }>({})

    const registerModal = useCallback((key: string, Component: React.ComponentType) => {
        setModals((modals) => ({ ...modals, [key]: Component }))
    }, [])

    const unregisterModal = useCallback((key) => {
        setModals((modals) => {
            const newModals = { ...modals }
            delete newModals[key]
            return newModals
        })
    }, [])

    const context = useMemo(
        () => ({ registerModal, unregisterModal }),
        [registerModal, unregisterModal]
    )
    return (
        <GlobalModalContext.Provider value={context}>
            <>{children}</>

            {Object.values(modals).map((Component, index) => (
                <Component key={index} />
            ))}
        </GlobalModalContext.Provider>
    )
}
