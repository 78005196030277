/* eslint-disable react/prefer-stateless-function */
/* Code Quality: Not audited */

import React, { Component } from 'react'

import isUrl from 'is-url'

import { fieldPicker, text } from 'features/pages/blocks/settings/attributes/items/primitives'
import { StackColorButton } from 'legacy/v1/ui'

class _DetailViewActionButtonBlock extends Component {
    render() {
        const { attrs, evaluateFormulas, showAttributePopover } = this.props

        const { isEditing } = this.props.context.editor
        const navigateOrConfigure = (url) => {
            if (isEditing) {
                showAttributePopover()
            } else {
                window.open(url, '_blank')
            }
        }

        let url
        let isDisabled = true
        const { record, object } = this.props.context
        const { fieldId } = attrs

        const label =
            !fieldId && isEditing
                ? 'Click to configure'
                : evaluateFormulas(attrs.buttonTitle) || 'Open'

        if (fieldId && object && record) {
            // Get the field accessor

            const field = object.fields.find((f) => f._sid === fieldId)
            if (field) {
                url = record[field.api_name]
                if (isUrl(url)) {
                    isDisabled = false
                }
            }
        }

        return (
            <StackColorButton
                style={{ display: 'inline-block', marginLeft: 4, marginBottom: 16, marginTop: 16 }}
                isDisabled={isDisabled && !isEditing}
                size="small"
                onClick={() => {
                    navigateOrConfigure(url)
                }}
            >
                {label}
            </StackColorButton>
        )
    }
}

_DetailViewActionButtonBlock.attributes = [
    text({ field: 'buttonTitle', label: 'Label', simple: true }),
    fieldPicker({
        field: 'fieldId',
        label: 'URL to open',
        filter: (field) => ['string', 'url'].includes(field.type),
        simple: true,
    }),
]

_DetailViewActionButtonBlock.behaviours = {
    onClick: true,
}

export default _DetailViewActionButtonBlock
