import { useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { useUpdatePage } from 'data/hooks/pages'

const usePageRoleConfig = (page = {}) => {
    const { mutateAsync: updatePage } = useUpdatePage()

    let rolesForPage = get(page, 'permitted_by_roles', [])

    const defaultState = {
        loadedPageRoles: page.permitted_by_all_roles
            ? (rolesForPage = ['*'].concat(rolesForPage))
            : rolesForPage,
        pageRoles: rolesForPage,
        rolesDirty: false,
    }

    const [state, setState] = useState(defaultState)

    useEffect(() => {
        if (!isEqual(rolesForPage, state.loadedPageRoles)) {
            setState((state) => ({ ...state, loadedPageRoles: rolesForPage }))
        }
    }, [rolesForPage, state.loadedPageRoles])

    const revertChanges = () => {
        setState(defaultState)
    }

    const setPageRoles = (pageRoles) => {
        let rolesDirty = false
        let newRoles = pageRoles
        if (pageRoles.find((x) => x === '*')) {
            newRoles = ['*']
        }

        if (!isEqual(state.loadedPageRoles, newRoles)) rolesDirty = true
        setState((state) => ({ ...state, pageRoles: newRoles, rolesDirty }))
    }

    const doSavePageRoles = (pageRoles) => {
        const pageId = get(page, '_sid')
        const patch = {}

        if (pageRoles.find((x) => x === '*')) {
            patch.permitted_by_all_roles = true
        } else {
            patch.permitted_by_all_roles = false
            patch.permitted_by_roles = pageRoles
        }

        updatePage({
            id: pageId,
            patch,
        })
            .then(() => {
                setState((state) => ({ ...state, rolesDirty: false }))
            })
            .catch((e) => {
                Sentry.captureMessage(`Error updating page permitted_by_roles ${get(e, 'message')}`)
            })
    }

    const savePageRoles = () => {
        doSavePageRoles(state.pageRoles)
    }

    const setAndSavePageRoles = (pageRoles) => {
        let newRoles = pageRoles
        if (pageRoles.find((x) => x === '*')) {
            newRoles = ['*']
        }
        doSavePageRoles(newRoles)
        setState((state) => ({ ...state, pageRoles: newRoles }))
    }

    return {
        setPageRoles,
        savePageRoles,
        setAndSavePageRoles,
        rolesDirty: state.rolesDirty,
        pageRoles: state.pageRoles,
        revertChanges,
    }
}

export default usePageRoleConfig
