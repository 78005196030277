import React from 'react'

import { Button, Flex, Text } from 'v2/ui'
import EmptyState from 'v2/ui/components/EmptyState'
import { EmptyFilters, EmptyView } from 'v2/ui/svgs'

type DataListBlockEmptyStateProps = {
    hasFilters?: boolean
    onClearFilters: () => void
}

export const DataListBlockEmptyState: React.FC<DataListBlockEmptyStateProps> = ({
    hasFilters,
    onClearFilters,
}) => {
    return (
        <EmptyState
            name="records"
            compact={true}
            cta={() =>
                hasFilters ? (
                    <Flex column={true}>
                        <Text variant="emptyState" mb={5}>
                            Clear your filters and try again.
                        </Text>

                        <Button variant="sm" onClick={onClearFilters}>
                            Clear Filters
                        </Button>
                    </Flex>
                ) : null
            }
            custom={hasFilters ? 'No records match your filters' : ''}
            svg={hasFilters ? EmptyFilters : EmptyView}
            p={3}
            py={10}
        />
    )
}
