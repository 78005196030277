import isRichTextEmpty from 'features/pages/blocks/blockTypes/form/richTextEditorHelpers/isRichTextEmpty'

export function isHeaderEmpty(header?: PageHeader): boolean {
    if (!header) {
        return true
    }

    // In edit mode, we still display the header with edit controls even if empty
    if (header.type === 'modular') {
        return false
    }

    const isSubtitleEmpty = isRichTextEmpty(header.subtitle)

    if (header.type === 'image') {
        return !header.title && isSubtitleEmpty && !header.src
    }

    return !header.title && isSubtitleEmpty
}

export function getHeaderContextColorMode(header?: PageHeader): 'light' | 'dark' | null {
    if (!header || header.type === 'default' || header.type === 'modular') return null

    if (header.src) {
        return header.colorMode ?? null
    }

    if (header.showBackgroundColor) return 'dark'

    return null
}
