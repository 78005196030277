import React, { forwardRef } from 'react'

import { cx } from 'emotion'

import { Icon } from 'v2/ui'

import './ButtonV4.scss'
/**
 *
 * @param {{theme: "light" | "dark" | "brand", [keyof: string]: any}} param0
 * @param {*} ref
 * @returns
 */
function ButtonV4(
    {
        theme,
        size,
        type,
        buttonType,
        icon,
        dropdown = false,
        children,
        onClick,
        isLoading,
        disabled,
        style,
    },
    ref
) {
    if (type === 'primary' && theme === 'brand') {
        throw new Error('not supported yet, missing brand color')
    }

    const classNames = cx(
        {
            disabled: disabled,
            isLoading: isLoading,
        },
        `atom-button size-${size} type-${type} theme-${theme}`
    )

    return (
        <button className={classNames} onClick={onClick} type={buttonType} ref={ref} style={style}>
            <div className="spinner">
                <Icon icon="spinner" className="fa-spin" />
            </div>
            {icon && (
                <div className="icon">
                    <Icon icon={icon} />
                </div>
            )}
            {icon && children && <div className="spacer" style={{ width: 8 }} />}
            {children && <div className="children">{children}</div>}
            {(icon || children) && dropdown && (
                <div className="spacer" style={{ width: children ? 14 : 9 }} />
            )}
            {dropdown && (
                <div className="icon dropdown">
                    <Icon icon="arrowDown" />
                </div>
            )}
        </button>
    )
}

export default forwardRef(ButtonV4)
