import React from 'react'

import { useRadioGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useLDFlags } from 'data/hooks/useLDFlags'

import { Box } from 'v2/ui'
import { ViewHeaderBanner, ViewHeaderDefault, ViewHeaderModular } from 'v2/ui/svgs'

import ViewHeaderDisplayRadio from './ViewHeaderDisplayRadio'

const StyledRadioButtonGroup = styled(Box)`
    display: flex;
    align-items: center;
`

type Value = PageHeader['type']

type RadioOption = {
    label: string
    value: Value
    icon: React.ReactNode
}

const options: RadioOption[] = [
    {
        label: 'Default',
        value: 'default',
        icon: <ViewHeaderDefault />,
    },
    {
        label: 'Banner',
        value: 'image',
        icon: <ViewHeaderBanner />,
    },
    {
        label: 'Modular',
        value: 'modular',
        icon: <ViewHeaderModular />,
    },
]

type ViewHeaderDisplayInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Box>,
    'value' | 'onChange'
> & {
    value?: Value
    isBlankPage?: boolean
    onChange: (newValue: Value) => void
}

const ViewHeaderDisplayInput: React.FC<ViewHeaderDisplayInputProps> = ({
    value,
    isBlankPage,
    onChange,
    ...props
}) => {
    const { flags } = useLDFlags()

    const { getRootProps, getRadioProps } = useRadioGroup({
        value: value,
        onChange: (value) => onChange(value as Value),
    })

    const group = getRootProps()
    const isModularHeaderAvailable = flags?.modularHeader && !isBlankPage

    return (
        // @ts-expect-error looks like a emotion v10 (for storybook) and v11 type mismatch https://github.com/emotion-js/emotion/issues/2320
        <StyledRadioButtonGroup {...group} spacing={2} {...props}>
            {options
                .filter(({ value }) => value !== 'modular' || isModularHeaderAvailable)
                .map(({ label, value: optionValue, icon }) => {
                    const radioProps = getRadioProps({ value: optionValue })

                    return (
                        <ViewHeaderDisplayRadio
                            key={optionValue}
                            label={label}
                            icon={icon}
                            {...radioProps}
                        />
                    )
                })}
        </StyledRadioButtonGroup>
    )
}

export default ViewHeaderDisplayInput
