import { FieldPatch } from 'features/admin/fields/common'

import { CreatableFieldTypeKey } from '../definitions/fieldTypeDefinitions'

import { getLinkedFieldObjectName } from './getLinkedFieldObjectName'
import { getLookupDynamicFieldLabel } from './getLookupDynamicFieldLabel'
import { getRollupDynamicFieldLabel } from './getRollupDynamicFieldLabel'
import { getUniqueFieldLabel } from './getUniqueFieldLabel'

type getDynamicFieldLabelProps = {
    fieldType: CreatableFieldTypeKey
    objectId: string
    fieldId?: string
    field?: FieldPatch
}

export const getDynamicFieldLabel = ({
    fieldType,
    objectId,
    fieldId,
    field,
}: getDynamicFieldLabelProps): string | undefined => {
    if (!field) return

    let defaultFieldLabel
    // lookup and rollup field types are handled differently
    if (fieldType === 'lookup_related_value') {
        defaultFieldLabel = getLookupDynamicFieldLabel(field)
    } else if (fieldType === 'rollup_related_records') {
        defaultFieldLabel = getRollupDynamicFieldLabel(field)
    } else {
        defaultFieldLabel = getLinkedFieldObjectName(field)
    }

    if (!defaultFieldLabel) return

    // Ensure that the field label is unique
    return getUniqueFieldLabel(defaultFieldLabel, objectId, fieldId)
}
