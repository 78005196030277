import React, { FC, MouseEvent } from 'react'

import styled from '@emotion/styled'

import { Box, Icon } from 'v2/ui'

const ICON_SIZE = 22

const Wrapper = styled(Box)`
    position: relative;
    width: 100%;

    padding: 1em;
    padding-bottom: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    border: 1px dashed ${({ theme }) => theme.colors.userInterface.accent[1000]};
    border-radius: 6px;
`

const IconWrapper = styled(Box)`
    cursor: pointer;

    position: absolute;
    top: calc(-${ICON_SIZE}px - 0.5em);
    left: 0;

    padding: 0.25em;
    background-color: ${({ theme }) => theme.colors.userInterface.error[400]};

    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.userInterface.error[600]};
`

type Props = {
    onDelete: (event: MouseEvent) => void
}

export const EditWrapper: FC<Props> = ({ onDelete, children }) => (
    <Wrapper>
        <IconWrapper onClick={onDelete}>
            <Icon icon="trash" fill="white" label="Delete" labelPlacement="bottom" />
        </IconWrapper>
        {children}
    </Wrapper>
)
