/* Code Quality: Not audited */
import React, { useCallback, useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import { useCreatePage, useDeletePage, usePages, useUpdatePage } from 'data/hooks/pages'

function WithPages({ children, showFeatureConfigData = false, allStacks }) {
    const { data: incomingData, status } = usePages()

    const { mutateAsync: updatePage } = useUpdatePage()
    const { mutateAsync: createPage } = useCreatePage()
    const { mutateAsync: deletePage } = useDeletePage()
    const { selectedStack } = useContext(AppContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = incomingData || []
    const pages = useMemo(() => {
        if (status !== 'success') return data
        if (showFeatureConfigData) {
            data.filter((page) => allStacks || page.stack_id === selectedStack?._sid)
        }
        return data.filter(
            (page) =>
                !page.is_feature_settings &&
                !page.is_feature_template &&
                (allStacks || page.stack_id === selectedStack?._sid)
        )
    }, [allStacks, data, selectedStack, showFeatureConfigData, status])

    return children({
        pages,
        pageActions: undefined,
        onChange: (...args) => {
            if (args.length === 2) return updatePage({ id: args[0], patch: args[1] })
            return updatePage(args[0])
        },
        updatePage,
        createPage,
        removePage: deletePage,
    })
}

WithPages.propTypes = {}

const ConnectedWithStacks = React.memo(WithPages)

export default ConnectedWithStacks

export const withPages = (Child) => (props) => {
    const fn = useCallback((stackProps) => <Child {...stackProps} {...props} />, [props])
    return <ConnectedWithStacks>{fn}</ConnectedWithStacks>
}
