/* Code Quality: Not audited */

import React, { useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import {
    useCreateFeature,
    useDeleteFeature,
    useFeatures,
    useUpdateFeature,
} from 'data/hooks/features'

const notImplemented = () => {
    throw Error('This feature is not implemented')
}

function WithFeatures({ children, allStacks }) {
    const { data: incomingData } = useFeatures()
    const { mutateAsync: updateFeature } = useUpdateFeature()
    const { mutateAsync: createFeature } = useCreateFeature()
    const { mutateAsync: deleteFeature } = useDeleteFeature()
    const { selectedStack } = useContext(AppContext)

    const data = incomingData || []
    const filteredData = useMemo(
        () => data.filter((item) => allStacks || item.stack_id === selectedStack?._sid),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, selectedStack]
    )

    return children({
        features: filteredData,
        createFeature,
        removeFeature: deleteFeature,
        onChange: updateFeature,
        updateFeature,
        previewPages: notImplemented, // Old stacker-classic page builder stuff not currently used
        clearPreviews: notImplemented, // Old stacker-classic page builder stuff not currently used
        newSetting: notImplemented, // Old stacker-classic page builder stuff not currently used
        updateSetting: notImplemented, // Old stacker-classic page builder stuff not currently used
    })
}

export default WithFeatures

export const withFeatures = (Child) => (props) =>
    <WithFeatures>{(featureProps) => <Child {...featureProps} {...props} />}</WithFeatures>
