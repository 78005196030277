import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import keyBy from 'lodash/keyBy'

import { ErrorBoundary } from 'app/ErrorBoundaries'
import { reorder } from 'utils/utils'

import Box from 'v2/ui/components/Box'
import stackerTheme from 'v2/ui/theme/styles/default'
import AnimateRelocateContextProvider, { AnimateRelocateContext } from 'v2/ui/utils/AnimateRelocate'

import { ONBOARDING_CLASSES } from '../../styleClasses'
import { EmptySearch } from '../../svgs'
import Button from '../Button'
import Flex from '../Flex'
import ScrollBox from '../ScrollBox'
import SearchInput from '../SearchInput'
import Text from '../Text'

import { OrderableItemsList } from './OrderableItemsList'
import { Item, OrderableListSelectorProps } from './types'
import {
    defaultProvideIcon,
    defaultProvideKey,
    defaultProvideLabel,
    emptySearchLabel,
} from './utils'
const { colors } = stackerTheme()

const OrderableListSelectorInternal: React.FC<OrderableListSelectorProps> = ({
    onUpdate,
    selectedItems,
    items,
    onAdd,
    onEdit,
    maxItemsSelected,
    provideKey = defaultProvideKey,
    provideLabel = defaultProvideLabel,
    provideIcon = defaultProvideIcon,
    renderPaletteItems,
    autoHideEditButton = true,
    disableReorder = false,
    emptySearch = { label: emptySearchLabel },
    onKeyboardItemSelected,
    nonDraggableIndexes,
    hideSearch,
    /**
     * When true, the search box is focus on open
     */
    autofocus = false,
    showActionsOnDisabled,
    ...props
}) => {
    // States
    const [isDragging, setIsDragging] = useState(false)
    const [filterValue, setFilterValue] = useState('')
    const [activeItemIdx, setActiveItemIdx] = useState(-1)

    const selectedItemsRef = useRef<Item[]>()
    const animator = useContext(AnimateRelocateContext)
    const restoreActiveItem = useRef<number | null>(null)
    const filterInput = useRef<HTMLInputElement>()
    const itemsByKey = useMemo(() => keyBy(items, provideKey), [items, provideKey])

    // Focus on search input only at the first render
    useEffect(() => {
        if (autofocus) {
            filterInput?.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const provideLabelInternal = useCallback(
        (item: Item) => {
            let label = provideLabel(item)

            // If the item itself had no label (ie., if we're looking at a selected item),
            // try to look up the label from the associated item from the available list
            if ((label === null || label === undefined) && itemsByKey[provideKey(item)]) {
                label = provideLabel(itemsByKey[provideKey(item)])
            }

            return label as string
        },
        [itemsByKey, provideKey, provideLabel]
    )

    const filterItem = useCallback(
        (item) =>
            !filterValue ||
            provideLabelInternal(item)?.toLowerCase().includes(filterValue.toLowerCase()),
        [filterValue, provideLabelInternal]
    )

    const availableItems = useMemo(
        () =>
            items.filter(
                (x) => !selectedItems.find((y) => provideKey(y) === provideKey(x)) && filterItem(x)
            ),
        [items, selectedItems, filterItem, provideKey]
    )

    const filteredSelectedItems = useMemo(
        () => selectedItems.filter(filterItem),
        [filterItem, selectedItems]
    )

    const allFilteredItems = useMemo(
        () => filteredSelectedItems.concat(availableItems),
        [filteredSelectedItems, availableItems]
    )

    const handleAdd = useCallback(
        (item) => {
            animator.recordOrigin(provideKey(item))
            onAdd?.(item)
        },
        [animator, provideKey, onAdd]
    )

    const handleDragStart = useCallback(() => setIsDragging(true), [])
    const handleDragEnd = useCallback(() => setIsDragging(false), [])

    const handleReorder = (oldIndex: number, newIndex: number) => {
        if (activeItemIdx >= 0) {
            // record which item should have focus after the reorder
            restoreActiveItem.current = newIndex
        }

        // The indexes are for the filteredSelectedItems[] array which may be a subset
        // of our actual selectedItems[] if a filter is applied.
        // We need to move the specified item in the unfiltered selectedItems[] array
        // so we translate the indexes to the actdual array.
        newIndex = selectedItems.indexOf(allFilteredItems[newIndex])
        oldIndex = selectedItems.indexOf(allFilteredItems[oldIndex])

        onUpdate(reorder(selectedItems, oldIndex, newIndex))
    }

    const handleRemove = useCallback(
        (item) => {
            animator.recordOrigin(provideKey(item))
            onUpdate(selectedItemsRef?.current?.filter((x) => x !== item) || [])
        },
        [animator, onUpdate, provideKey]
    )

    const handleFilterChange = useCallback((value) => {
        if (value === ' ') value = ''
        setFilterValue(value)
        setActiveItemIdx(-1)
    }, [])

    const handleFilterInputFocus = useCallback((e) => {
        setActiveItemIdx(-1)
        e.stopPropagation()
        e.preventDefault()
    }, [])

    const handleItemFocus = useCallback(
        (item) => setActiveItemIdx(allFilteredItems.indexOf(item)),
        [allFilteredItems]
    )

    const handleArrowDown = () => {
        // if we're at the end of the list put focus back in the filter box
        if (activeItemIdx === allFilteredItems.length - 1) {
            if (filterInput.current) filterInput.current.focus()
        } else {
            setActiveItemIdx(activeItemIdx + 1)
        }
    }
    const handleArrowUp = () => {
        // if we're at the beginning of the list already move focus back to filter box
        if (activeItemIdx <= 0) {
            if (filterInput.current) filterInput.current.focus()
        } else {
            setActiveItemIdx(activeItemIdx - 1)
        }
    }

    // Toggle selected status of active item on ENTER key
    const handleEnterKey = () => {
        let activeItem = allFilteredItems[activeItemIdx]
        if (!!onKeyboardItemSelected) {
            onKeyboardItemSelected(activeItem)
            return
        }

        if (activeItem) {
            if (selectedItems.includes(activeItem)) {
                handleRemove(activeItem)
            } else {
                onAdd?.(activeItem)
                // If we're toggling ON an item in the available list
                // move the active item index + 1 so we move to the next
                // item in the list rather than "jumping" up one
                if (activeItemIdx < allFilteredItems.length - 1) {
                    setActiveItemIdx((x) => x + 1)
                }
            }
        }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if (isDragging) return

        switch (e.keyCode) {
            case 40:
                handleArrowDown()
                e.preventDefault()
                break
            case 38:
                handleArrowUp()
                e.preventDefault()
                break
            case 13:
                handleEnterKey()
                break
            case 27:
                setFilterValue('')
                if (filterInput.current) filterInput.current.focus()
                break
        }
    }

    useEffect(() => {
        // we push a reference to the latest selected items
        // into a ref as it acts like a class-level variable
        // and can be accessed from our event handlers
        // without them needing to make selectedItems a dependency.
        // This improves render performance because our event handlers
        // won't be being updated every time selectedItems changes.
        selectedItemsRef.current = selectedItems
        // If we have a record active item index that needs
        // restored, do it now
        if (restoreActiveItem.current !== null) {
            setActiveItemIdx(restoreActiveItem.current)
            restoreActiveItem.current = null
        }
    }, [selectedItems])

    const maxFieldsReached = maxItemsSelected && selectedItems.length >= maxItemsSelected

    const onClearSearch = () => {
        setFilterValue('')
        filterInput?.current?.focus()
    }

    return (
        <Flex
            column
            maxHeight={props.maxHeight || '100%'}
            width="100%"
            wrap="noWrap"
            align="stretch"
            onKeyDown={handleKeyDown}
            className={props.className}
            overflow="hidden"
        >
            {!hideSearch && (
                <SearchInput
                    ref={filterInput}
                    value={filterValue}
                    placeholder="Search"
                    onChange={handleFilterChange}
                    onClick={handleFilterInputFocus}
                    onFocus={() => (autofocus ? null : handleFilterInputFocus)}
                    flexShrink={0}
                    {...props.searchInputProps}
                />
            )}
            {isEmpty(allFilteredItems) && !isEmpty(filterValue) ? (
                <Flex column m={4}>
                    <Box maxWidth="100px" maxHeight="100px">
                        <EmptySearch size={60} />
                    </Box>
                    <Text
                        textAlign="center"
                        mt={2}
                        mb={2}
                        fontSize="12px"
                        color={colors.userInterface.neutral[1000]}
                    >
                        {emptySearch?.label(filterValue)}
                    </Text>
                    <Button
                        variant="Tertiary"
                        color={colors.userInterface.accent[1000]}
                        buttonSize="extraSmall"
                        onClick={onClearSearch}
                    >
                        Clear search
                    </Button>
                </Flex>
            ) : (
                <>
                    <ScrollBox
                        display="flex"
                        flexDirection="column"
                        wrap="noWrap"
                        flexGrow={1}
                        my={2}
                        maxHeight="100%"
                        overflowY="auto"
                        alignItems="stretch"
                    >
                        <ErrorBoundary>
                            <OrderableItemsList
                                items={filteredSelectedItems}
                                activeItem={allFilteredItems[activeItemIdx]}
                                allowReorder
                                isChecked
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                                onItemFocus={handleItemFocus}
                                onReorder={disableReorder ? undefined : handleReorder}
                                onCheckedChanged={handleRemove}
                                onEdit={onEdit}
                                provideLabel={provideLabelInternal}
                                provideIcon={provideIcon}
                                provideKey={provideKey}
                                autoHideEditButton={autoHideEditButton}
                                className={
                                    props.editor === 'fields'
                                        ? ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_SELECTED_ITEM
                                        : ''
                                }
                                showActionsOnDisabled={showActionsOnDisabled}
                                nonDraggableIndexes={nonDraggableIndexes}
                            />
                        </ErrorBoundary>

                        <ListContainer faded={isDragging || maxFieldsReached}>
                            <OrderableItemsList
                                items={availableItems}
                                onCheckedChanged={maxFieldsReached ? undefined : handleAdd}
                                onItemFocus={handleItemFocus}
                                isChecked={false}
                                activeItem={allFilteredItems[activeItemIdx]}
                                provideLabel={provideLabel}
                                provideIcon={provideIcon}
                                provideKey={provideKey}
                                checkingDisabled={maxFieldsReached}
                                className={
                                    props.editor === 'fields'
                                        ? ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_ITEM
                                        : ''
                                }
                                nonDraggableIndexes={nonDraggableIndexes}
                            />
                        </ListContainer>
                    </ScrollBox>
                    {renderPaletteItems && renderPaletteItems(handleAdd)}
                </>
            )}
        </Flex>
    )
}

const ListContainer = styled.div<{ faded?: number | boolean }>`
    transition: opacity 0.2s;
    opacity: ${(props) => (props.faded ? 0.5 : 1)};
`

export const OrderableListSelector = (props: OrderableListSelectorProps) => (
    // @ts-expect-error until AnimateRelocateContextProvider is converted to tsx
    <AnimateRelocateContextProvider provideKey={props.provideKey}>
        <OrderableListSelectorInternal {...props} />
    </AnimateRelocateContextProvider>
)
