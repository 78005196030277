// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { withRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { UserApi } from 'data/api/userApi'
import analytics from 'utils/analytics'

import { Box, Collapse, Flex, Icon } from 'v2/ui'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import Form, { FormWrapper } from 'ui/forms/Form'
import SubmitButton from 'ui/forms/SubmitButton'

import { Button, InputForm, Text } from './AuthUIElements'

type EmailVerificationProps = {
    onSuccess: (user: any) => void
    email?: string
    isSignUp?: boolean
    onEmailChange?: (email: string) => void
}

/*
Displays a form for entering an email address and sending the user an email
with a magic login link and verification code. They can enter the code in this
form to login.
*/
const EmailVerificationForm = ({
    onSuccess,
    email: defaultEmail,
    isSignUp = false,
    onEmailChange,
}: EmailVerificationProps) => {
    const emailFormContext = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { email: defaultEmail },
    })
    const codeFormContext = useRef<UseFormReturn<any>>()
    const [email, setEmail] = useUrlParam<string>('email', '')
    const inputtedEmail = emailFormContext.watch('email')

    useEffect(() => {
        inputtedEmail && onEmailChange && onEmailChange(inputtedEmail)
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we're ignoring onEmailChange to prevent render loops
    }, [inputtedEmail])

    const handleSubmit = (data) => {
        analytics.track('user login submitted', {
            event_category: 'user',
            event_description: 'User login was started',
            authentication_method: 'email code',
        })

        return UserApi.sendLoginCodeEmail(data.email, isSignUp)
            .then((response) => {
                if (response.error) {
                    emailFormContext.setError('email', {
                        type: 'manual',
                        message: response.error,
                    })
                } else {
                    setEmail(data.email)
                    codeFormContext.current?.clearErrors()
                }
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                emailFormContext.setError('email', {
                    type: 'manual',
                    message: 'An unknown error occurred.',
                })
            })
    }
    const handleSubmitCode = (data) => {
        return UserApi.authenticateWithLoginCode(email, data.code, isSignUp)
            .then((response) => {
                if (response.error) {
                    analytics.track('user login failed', {
                        event_category: 'user',
                        error: response.error,
                        event_description: 'User login failed',
                        authentication_method: 'email code',
                    })

                    codeFormContext.current?.setError('code', {
                        type: 'manual',
                        message: response.error,
                    })
                } else {
                    analytics.track('user login completed', {
                        event_category: 'user',
                        event_description: 'User login was completed',
                        authentication_method: 'email code',
                    })

                    onSuccess(response)
                }
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                codeFormContext.current?.setError('code', {
                    type: 'manual',
                    message: 'An unknown error occurred.',
                })
            })
    }

    return (
        <Flex column align="stretch" mb={3}>
            {!email && (
                <FormWrapper
                    formContext={emailFormContext}
                    options={{
                        mode: 'onSubmit',
                        defaultValues: { email: defaultEmail },
                    }}
                    onSubmit={handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
                >
                    {/*@ts-ignore*/}
                    <InputForm
                        autoFocus
                        noMargin
                        name="email"
                        placeholder="Enter email address"
                        errorMessages={{
                            required: 'Please enter your email address',
                            pattern: 'Please enter a valid email address',
                        }}
                        required
                        pattern={/^\S+@\S+$/i}
                    />
                    <SubmitButton
                        mt={3}
                        buttonSize="sm"
                        as={Button}
                        disabled={false}
                        aria-label="Continue with email"
                    >
                        Continue with email
                    </SubmitButton>
                </FormWrapper>
            )}

            <Collapse isOpen={!!email}>
                <Form
                    ref={codeFormContext}
                    options={{
                        mode: 'onSubmit',
                    }}
                    onSubmit={handleSubmitCode}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <Box textAlign="left">
                        {/*@ts-ignore*/}
                        <Icon icon="emailSolid" size="smmd" display="inline" color="brand.200" />
                        <Text display="inline" ml={2}>
                            <strong>Check your email</strong>
                        </Text>
                        <Text mt={2}>Click the link in the email or enter the code below.</Text>

                        {/*@ts-ignore*/}
                        <InputForm
                            autoFocus
                            noMargin
                            mt={3}
                            name="code"
                            placeholder="Enter code"
                            errorMessages={{ required: 'Please enter the code from the email' }}
                            required
                        />
                    </Box>

                    <SubmitButton
                        mt={3}
                        buttonSize="sm"
                        as={Button}
                        disabled={false}
                        aria-label="Submit code"
                    >
                        Continue
                    </SubmitButton>
                </Form>
            </Collapse>
        </Flex>
    )
}
export default withRouter(EmailVerificationForm)
