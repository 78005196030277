// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UseQueryResult } from 'react-query'

import isEqual from 'lodash/isEqual'

import { useAppContext } from 'app/AppContext'
import { useObject } from 'data/hooks/objects'
import { useGetRecord, useUpdateRecord } from 'data/hooks/records'
import { getRecord } from 'data/hooks/records/getRecord'
import { invalidateRecord } from 'data/hooks/records/recordOperations'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'

type WithRecordReturnProps = {
    object: ObjectDto | undefined
    primaryField: object
    record: any | undefined
    onChange: (value: Object) => Promise<any>
}

type WithRecordProps = {
    objectId?: string
    recordId: string
    options?: any[]
    children: (props: WithRecordReturnProps) => void
    loading?: JSX.Element
    acceptPartial?: boolean
    disableRealTimeUpdates?: boolean
    reloadOnMount?: boolean
    onError?: (error: unknown) => void
}

const WithRecord = ({
    recordId,
    children,
    acceptPartial = false,
    loading,
    disableRealTimeUpdates = false,
    reloadOnMount = false,
    onError,
}: WithRecordProps) => {
    const {
        data: record,
        isLoading: isRecordLoading,
        error,
    } = useGetRecord({
        recordId,
    }) as UseQueryResult<RecordDto>

    const RecordObjectId = record?._object_id
    // @ts-expect-error
    const { mutateAsync: updateRecord } = useUpdateRecord(RecordObjectId)
    const { object, primaryField, schemaTimestamp } = useObject(RecordObjectId)
    const { selectedStack } = useAppContext()
    const [currentRecord, setCurrentRecord] = useState(record)
    const [currentObject, setCurrentObject] = useState(object)
    const prevRecord = useRef(record)

    useEffect(() => {
        // Schema has changed
        if (record && record.schemaTimestamp !== schemaTimestamp) {
            invalidateRecord(recordId)
        }
    }, [record, recordId, schemaTimestamp])

    useEffect(() => {
        if (!!error) {
            onError?.(error)
        }
    }, [error, onError])

    // Subscribe to any record updates
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [object?._sid],
        disabled: disableRealTimeUpdates,
        handler: () => {
            invalidateRecord(recordId)
        },
    })

    // This makes sure that we always fetch the most recent version of the record on mount
    useEffect(() => {
        if (reloadOnMount) invalidateRecord(recordId)
    }, [recordId, reloadOnMount])

    const onChange = useCallback(
        (data: object) => {
            return updateRecord({ id: recordId, patch: data })
        },
        [recordId, updateRecord]
    )

    useEffect(() => {
        if (!isEqual(record, currentRecord)) {
            setCurrentRecord(record)
        }

        if (!isEqual(object, currentObject)) {
            setCurrentObject(object)
        }
    }, [record, object, currentObject, currentRecord])

    useEffect(() => {
        const prev = prevRecord.current
        if (record?._sid && record?._sid === recordId) {
            prevRecord.current = record
        }
        // We've changed record id
        if (prev?._sid !== recordId) {
            prevRecord.current = undefined
        }
    }, [record, prevRecord, recordId])

    // we only have a partial record, so refetch the full record
    useEffect(() => {
        if (record?._partial && !acceptPartial) {
            getRecord(recordId)
        }
    }, [acceptPartial, record?._partial, recordId])

    const discardCurrentRecord = currentRecord?._partial && !acceptPartial

    const returnObject = useMemo(() => {
        return {
            object: currentObject,
            primaryField,
            record: isRecordLoading && prevRecord?.current ? prevRecord?.current : currentRecord,
            onChange,
        }
    }, [currentRecord, primaryField, currentObject, onChange, isRecordLoading])

    if ((isRecordLoading || !object || discardCurrentRecord) && loading) return loading

    if (!currentObject || !currentRecord || discardCurrentRecord) return null

    return <>{children(returnObject)}</>
}

export default WithRecord

export const withRecord =
    (Child) =>
    ({ recordId, ...props }) =>
        (
            <WithRecord recordId={recordId}>
                {(recProps) => <Child {...recProps} {...props} />}
            </WithRecord>
        )
