import React from 'react'

import { Button, EmptyState, Flex, Text } from 'v2/ui'
import { EmptySearch } from 'v2/ui/svgs'

type TemplateLibraryModalEmptyStateProps = {
    clearSearch: () => void
}

export const TemplateLibraryModalEmptyState: React.VFC<TemplateLibraryModalEmptyStateProps> = ({
    clearSearch,
}) => {
    const EmptySearchCta = () => (
        <Flex column>
            <Text variant="emptyState" mb={5}>
                Clear your search and start again.
            </Text>
            <Button variant="sm" ml={[0, null, 2]} mt={[2, null, 0]} onClick={clearSearch}>
                Clear search
            </Button>
        </Flex>
    )

    return (
        <EmptyState
            name="templates"
            custom="No templates match your search"
            cta={EmptySearchCta}
            svg={EmptySearch}
        />
    )
}
