// @ts-strict-ignore
import React, { useMemo, useState } from 'react'

import styled from '@emotion/styled'

import { importUrl } from '../../data/api/bundlesApi'
import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { Button, FixedGrid, Flex, Icon, MarkdownText, Text } from '../../v2/ui'
import SimpleGallery from '../galleries/SimpleGallery'

import FeatureIcon from './FeatureIcon'
import { reloadMetadataAndDataAfterInstall } from './reloadMetadataAndDataAfterInstall'

type InstallableFeatureDetailProps = {
    accountId: string | undefined
    stackId: string | undefined
    feature: FeatureDto
    onClose: () => void
    onInstallComplete: (objectSids: StackDto | Array<string>) => void
}

function InstallableFeatureDetail(props: InstallableFeatureDetailProps) {
    const { accountId, stackId, feature, onClose, onInstallComplete } = props

    const galleryImageUrls = useMemo(
        () => feature?.gallery_images?.split(',').map((image) => image.trim()) ?? [],
        [feature]
    )

    const functionalities = useMemo(
        () =>
            feature?.functionalities_description?.options
                ?.split(',')
                .map((functionality) => functionality.trim()) ?? [],
        [feature]
    )

    const [isInstalling, setIsInstalling] = useState(false)

    const installFeature = async () => {
        setIsInstalling(true)

        const { object_sids: objectSids } = await importUrl(feature.bundle_url, accountId, stackId)
        reloadMetadataAndDataAfterInstall()

        setIsInstalling(false)
        onInstallComplete(objectSids)
    }

    return (
        <Flex column align="center" mb={4}>
            <Flex
                column
                align="left"
                style={{
                    padding: '10px',
                    width: '100%',
                    maxWidth: '940px',
                }}
            >
                <Flex mb={4}>
                    <Button variant="adminBackButton" onClick={onClose}>
                        Back
                    </Button>
                </Flex>
                <Flex justify="space-between">
                    <Flex>
                        <FeatureIcon
                            icon={feature.icon || 'faClouds'}
                            color={feature.icon_color || V4DesignSystem.colors.stacker}
                            fullSize
                        />
                        <Flex column alignItems="start">
                            <Text variant="installableFeatureCardTitle">{feature.name}</Text>
                            {!feature.is_published && (
                                <UnpublishedButton>Under development</UnpublishedButton>
                            )}
                        </Flex>
                    </Flex>
                    <Button
                        disabled={isInstalling}
                        variant="adminPrimaryV4"
                        icon={isInstalling ? 'refresh' : 'add'}
                        size="sm"
                        onClick={installFeature}
                    >
                        {isInstalling ? 'Installing...' : 'Install'}
                    </Button>
                </Flex>
                {feature.detail_description && (
                    <>
                        <Text mt="16px" variant="appSettingsBody">
                            <MarkdownText>{feature.detail_description}</MarkdownText>
                        </Text>
                    </>
                )}
                {galleryImageUrls && galleryImageUrls.length && (
                    <SimpleGallery images={galleryImageUrls} />
                )}
                <FunctionalitiesContainer>
                    <Text variant="installableFeatureCardTitle" p={4}>
                        Functionalities
                    </Text>
                    {/* @ts-ignore */}
                    <FixedGrid w="100%" columns={[1, 1, 1, 3, 3, 3]} spacing="12px" p={4}>
                        {functionalities?.map((functionality) => (
                            <Text key={functionality} variant="appSettingsBody">
                                <Icon icon="check" mr={2} display="inline" />
                                {functionality}
                            </Text>
                        ))}
                    </FixedGrid>
                </FunctionalitiesContainer>
            </Flex>
        </Flex>
    )
}

const UnpublishedButton = styled.div`
    background: #d00000aa;
    padding: 1px 2px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    color: white;
    line-height: 13px;
    font-family: 'Inter UI';
    letter-spacing: 0.6px;
    border-radius: 3px;
`

const FunctionalitiesContainer = styled.div`
    background: #f9f9fa;
    padding: 1px 2px 0px 3px;
    border-radius: 10px;
    margin: 15px 2px 4px 2px;
`

export default InstallableFeatureDetail
