export const modes = {
    editable: 'editable',
    editing: 'editing',
    readonly: 'readonly',
}

export const layouts = {
    inline: 'inline',
    block: 'block',
    highlighted: 'highlighted',
}

export const sizes = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
}
