import React from 'react'

import styled from '@emotion/styled'

import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import { Box, Button, CircleIconButton } from 'v2/ui'
import styledProps from 'v2/ui/utils/styledProps'

import { RenderRowAttributeRenderProps } from '../../RenderRowAttribute'

const Container = styled(Box)`
    position: relative;
    border-radius: 10%;
`

const StyledImage = styled('img')(styledProps, `object-fit: cover;`)

type ImageThumbnailProps = {
    image: string
    onDelete?: (image: string) => void
    canDelete?: boolean
    size?: 'sm' | 'md' | 'lg'
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
    canDelete,
    image,
    onDelete,
    size = 'lg',
}) => {
    return (
        <Container
            boxSize={`thumbnail.${size}`}
            mr={canDelete ? 4 : 1}
            mb={canDelete ? 4 : 1}
            flexShrink={0}
        >
            <StyledImage
                border="1px solid"
                borderColor="grey.200"
                borderRadius="10%"
                size={`thumbnail.${size}`}
                p={1}
                src={image}
                alt=""
            />
            {canDelete && (
                <CircleIconButton
                    icon="x"
                    onClick={() => onDelete?.(image)}
                    position="absolute"
                    top="-12px"
                    right="-12px"
                />
            )}
        </Container>
    )
}

type SingleImageUploadAttributeProps = RenderRowAttributeRenderProps & {
    image: string
    onSuccess?: () => void
    onDelete?: (image: string) => void
}

const SingleImageUploadAttribute: React.VFC<SingleImageUploadAttributeProps> = ({
    onSuccess,
    image,
    ...props
}) => {
    if (image) {
        return <ImageThumbnail image={image} {...props} />
    }

    return (
        <ReactFilestack
            options={{
                accept: 'image/*',
            }}
            onSuccess={onSuccess}
            customRender={({ onPick }: { onPick: () => void }) => (
                <Button
                    variant="moderateSm"
                    padding="small"
                    icon="add"
                    onClick={onPick}
                    width="thumbnail.lg"
                    minHeight={'thumbnail.lg'}
                >
                    Add
                </Button>
            )}
        />
    )
}

export default SingleImageUploadAttribute
