import React, { useCallback, useMemo } from 'react'

import Button from 'v2/ui/components/Button'
import FilterButton from 'v2/ui/components/Filters/FilterButton'

const BooleanFilter = ({ dataTestId, accessibilityLabel, label, onChange, currentValue }) => {
    const value = currentValue === '1' || currentValue === true

    const onClick = useCallback(() => {
        onChange(currentValue === '0' ? undefined : !value ? '1' : '0')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange])

    const hasValue = currentValue === '0' || currentValue === '1'

    const filterLabel = useMemo(() => {
        if (!hasValue) return label
        return `${label} is ${value ? 'true' : 'false'}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue, value, label])

    return (
        <FilterButton
            icon={value ? 'checkSquare' : 'checkBox'}
            as={Button}
            buttonSize="sm"
            label={accessibilityLabel}
            data-testid={dataTestId}
            hasValue={hasValue}
            onClick={onClick}
            buttonText={filterLabel}
            onChange={onChange}
        />
    )
}

export default React.memo(BooleanFilter)
