import { useEffect, useMemo, useReducer } from 'react'

export type PatchableObjectState = {
    originalObjectHasChanged: boolean
    hasChanges: boolean
    hasUncommittedChanges: boolean
    patches: Partial<ActionDto>[]
    uncommittedPatches: Partial<ActionDto>[]
    originalObject: ActionDto
    draftObject: ActionDto
    applyPatch: (patch: Partial<ActionDto>) => void
    commit: (patch: Partial<ActionDto>) => void
    rollback: () => void
}

type UpdateOriginalObjectAction = {
    type: 'updateOriginalObject'
    originalObject: ActionDto
}

type ApplyPatchAction = {
    type: 'applyPatch'
    patch: Partial<ActionDto>
}

type CommitAction = {
    type: 'commit'
    patch: Partial<ActionDto>
}

type RollbackAction = {
    type: 'rollback'
}

type ClearAction = {
    type: 'clear'
}

type PatchableObjectAction =
    | UpdateOriginalObjectAction
    | ApplyPatchAction
    | CommitAction
    | RollbackAction
    | ClearAction

export default function usePatchableObject(originalObject: ActionDto): PatchableObjectState {
    const [state, dispatch] = useReducer(patchableObjectReducer, getInitialState(originalObject))

    useEffect(() => {
        dispatch({ type: 'updateOriginalObject', originalObject })
    }, [originalObject])

    return useMemo(
        () => ({
            ...state,
            applyPatch(patch: Partial<ActionDto>) {
                dispatch({ type: 'applyPatch', patch })
            },
            clear() {
                dispatch({ type: 'clear' })
            },
            commit(patch = {}) {
                dispatch({ type: 'commit', patch })
            },
            rollback() {
                dispatch({ type: 'rollback' })
            },
        }),
        [state]
    )
}

function patchableObjectReducer(
    state: PatchableObjectState,
    action: PatchableObjectAction
): PatchableObjectState {
    switch (action.type) {
        case 'applyPatch': {
            const uncommittedPatches = [...state.uncommittedPatches, action.patch]
            return {
                ...state,
                hasUncommittedChanges: true,
                uncommittedPatches,
                draftObject: computeDraftObject(state, [...state.patches, ...uncommittedPatches]),
            }
        }
        case 'updateOriginalObject':
            return {
                ...state,
                originalObjectHasChanged: true,
                originalObject: action.originalObject,
            }
        case 'commit': {
            const patches = [...state.patches, ...state.uncommittedPatches, action.patch]
            return {
                ...state,
                hasChanges: true,
                hasUncommittedChanges: false,
                uncommittedPatches: [],
                patches,
                draftObject: computeDraftObject(state, patches),
            }
        }
        case 'rollback': {
            return {
                ...state,
                hasUncommittedChanges: false,
                uncommittedPatches: [],
                draftObject: computeDraftObject(state, state.patches),
            }
        }
        case 'clear':
            return getInitialState(state.originalObject)
    }
}

function computeDraftObject(state: PatchableObjectState, patches: Partial<ActionDto>[]) {
    return Object.assign({}, state.originalObject, ...patches)
}

function getInitialState(originalObject: ActionDto): PatchableObjectState {
    return {
        originalObjectHasChanged: false,
        hasChanges: false,
        hasUncommittedChanges: false,
        patches: [],
        uncommittedPatches: [],
        originalObject,
        draftObject: originalObject,
        applyPatch: () => {},
        commit: () => {},
        rollback: () => {},
    }
}
