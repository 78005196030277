import React from 'react'

import { useUserLists } from 'data/hooks/userLists'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'

const _withUserLists = ({ stack, objects, children }) => {
    const { data: userLists = [] } = useUserLists()

    const open_signup_sid = stack?.options?.open_sign_up?.user_list || ''

    const openSignupList = userLists?.find((list) => list?._sid === open_signup_sid)
    const openSignupObject = objects?.find((object) => object?._sid === openSignupList?.object_id)

    return children({
        userLists,
        openSignupList,
        openSignupObject,
    })
}

const WithUserLists = withStack(withObjects(_withUserLists))

export default WithUserLists

export const withUserLists = (Child) => (props) =>
    <WithUserLists>{(listProps) => <Child {...listProps} {...props} />}</WithUserLists>
