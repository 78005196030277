import React from 'react'

import InlineCreate from 'v2/views/Create/InlineCreate'

import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import RecordDropdown, { RecordDropdownProps } from 'features/records/components/RecordDropdown'

import { Flex } from 'v2/ui'

type Props = {
    showCreateButton: boolean
    isInlineCreate: boolean
    objectId: string
    objects: ObjectDto[]
    stackOptions: StackDto['options']
    isMulti: boolean
    value: string | string[]
    onChange: (value: string | string[]) => void
} & RecordDropdownProps

const RecordDropdownWithCreate: React.FC<Props> = ({
    showCreateButton,
    isInlineCreate,
    objectId,
    objects,
    stackOptions,
    isMulti,
    onChange,
    ...props
}) => {
    const safeValue = props.value ?? []
    const shouldShowCreateButton = () => {
        // only allow one inline create modal for now
        if (!showCreateButton || isInlineCreate) return false
        if (!stackOptions.new_create_form) return false
        const object = objects.find((object) => object._sid === objectId)
        if (!object) return false

        return object?._permissions?.may_create
    }

    return (
        <Flex
            direction={['column', null, 'row']}
            alignItems={['stretch', null, 'center']}
            mt={showCreateButton ? '-0.25rem' : ''}
            maxWidth="100%"
        >
            <RecordDropdown
                objectId={objectId}
                {...props}
                onChange={onChange}
                showCreateButton={shouldShowCreateButton()}
                isMulti={isMulti}
            />
            {shouldShowCreateButton() && (
                //@ts-expect-error js world
                <InlineCreate
                    buttonVariant="moderateSm"
                    objectId={objectId}
                    onCreate={(recordId) => onChange(isMulti ? [...safeValue, recordId] : recordId)}
                />
            )}
        </Flex>
    )
}

export default withObjects(withStack(RecordDropdownWithCreate))
