import React, { ChangeEvent, FC, ReactNode } from 'react'

import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Divider, Flex, Icon, Input, Text } from 'v2/ui'
import { CustomRadioGroup } from 'v2/ui/components/CustomRadio'
import { SolidChevronDown } from 'v2/ui/svgs'
import { Horizontal, Vertical } from 'v2/ui/svgs/charts'

import { DisplayPanel as AggregationDisplayPanel } from '../../common/panels/DisplayPanel'
import { HARD_LIMIT_MAX_ITEMS } from '../constants'

const StyledAccordion = styled(Accordion)`
    padding: 0;
    margin: 0;

    border-color: transparent;
`

const StyledAccordionButton = styled(AccordionButton)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0;
    margin-bottom: 1rem;

    border-width: 0;

    &:hover {
        background-color: ${(props) => props.theme.colors.userInterface.neutral[0]};
    }
`

const ButtonIcon = styled(SolidChevronDown)<{ isExpanded: boolean | undefined }>`
    transition: transform 0.25s ease-in-out;
    transform: rotateZ(${(props) => (props.isExpanded ? -180 : 0)}deg);
`

const StyledAccordionPanel = styled(AccordionPanel)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;

    width: 100%;
    padding: 0;
`

type DisplayPanelProps = {
    chart: ChartBlockAttributes
    onChange: (
        chart: Pick<ChartBlockAttributes, 'objectId'> & Partial<ChartBlockAttributes>
    ) => void
}

const BAR_LAYOUT_TYPE_OPTIONS: {
    label: string
    value: string
    icon: ReactNode
}[] = [
    {
        value: 'vertical',
        label: 'Vertical',
        icon: <Icon svg={<Vertical />} />,
    },
    {
        value: 'horizontal',
        label: 'Horizontal',
        icon: <Icon svg={<Horizontal />} />,
    },
]

const SORT_TYPE_OPTIONS = [
    {
        value: 'value',
        label: 'Value',
    },
    {
        value: 'label',
        label: 'Label',
    },
]

const SORT_ORDER_OPTIONS = [
    {
        value: 'asc',
        label: 'Ascending',
    },
    {
        value: 'desc',
        label: 'Descending',
    },
]

export const DisplayPanel: FC<DisplayPanelProps> = ({ chart, onChange }) => {
    const onChangeShowLabels = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            objectId: chart.objectId,
            display: { ...chart.display, showLabels: !event.target.checked },
        })
    }

    const onChangeShowValues = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            objectId: chart.objectId,
            display: { ...chart.display, showValues: !event.target.checked },
        })
    }

    const onChangeLimit = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            objectId: chart.objectId,
            display: {
                ...chart.display,
                limitNumberItems:
                    event.target.value.length > 0
                        ? Math.max(1, Math.min(parseInt(event.target.value), HARD_LIMIT_MAX_ITEMS))
                        : undefined,
            },
        })
    }

    const onChangeBarLayout = (newLayoutType: ChartBlockAttributes['display']['layout']) => {
        onChange({
            objectId: chart.objectId,
            display: { ...chart.display, layout: newLayoutType },
        })
    }

    const onChangeSortType = (newType: ChartSort['type']) => {
        onChange({
            objectId: chart.objectId,
            sort: { order: chart.sort?.order ?? 'asc', type: newType },
        })
    }

    const onChangeSortOrder = (newOrder: ChartSort['order']) => {
        onChange({
            objectId: chart.objectId,
            sort: { type: chart.sort?.type ?? 'label', order: newOrder },
        })
    }

    return (
        <>
            {chart.type === 'Bar' && (
                <Box>
                    <Text variant="paletteSectionLabel" my={0}>
                        Display as
                    </Text>
                    <CustomRadioGroup
                        selectedOption={chart.display.layout}
                        options={BAR_LAYOUT_TYPE_OPTIONS}
                        onChange={onChangeBarLayout}
                    />
                </Box>
            )}
            <Box>
                <Flex flexDirection="row" justifyContent="space-between">
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="chart-show-labels"
                        display="block"
                    >
                        Hide labels
                    </Text>
                    <Switch
                        id="chart-show-labels"
                        colorScheme="adminSwitch"
                        isChecked={!chart.display.showLabels}
                        onChange={onChangeShowLabels}
                    />
                </Flex>
                {chart.type !== 'Pie' && (
                    <Flex flexDirection="row" justifyContent="space-between">
                        <Text
                            variant="paletteSectionLabel"
                            as="label"
                            htmlFor="chart-show-values"
                            display="block"
                        >
                            Hide values
                        </Text>
                        <Switch
                            id="chart-show-values"
                            colorScheme="adminSwitch"
                            isChecked={!chart.display.showValues}
                            onChange={onChangeShowValues}
                        />
                    </Flex>
                )}
            </Box>
            <Divider />
            {chart.type === 'Bar' && (
                <Box>
                    <Text variant="paletteSectionLabel" my={0}>
                        Sort
                    </Text>
                    <CustomRadioGroup
                        my={2}
                        selectedOption={chart.sort?.type ?? 'label'}
                        options={SORT_TYPE_OPTIONS}
                        onChange={onChangeSortType}
                        variant="compact"
                    />
                    <CustomRadioGroup
                        my={2}
                        selectedOption={chart.sort?.order ?? 'asc'}
                        options={SORT_ORDER_OPTIONS}
                        onChange={onChangeSortOrder}
                        variant="compact"
                    />
                    <Divider mt={5} />
                </Box>
            )}
            <StyledAccordion defaultIndex={0} allowToggle>
                <AccordionItem>
                    {({ isExpanded }) => (
                        <>
                            <StyledAccordionButton role="button" as={Flex}>
                                <Text variant="paletteSectionLabel">Display settings</Text>
                                <ButtonIcon isExpanded={isExpanded} />
                            </StyledAccordionButton>
                            <StyledAccordionPanel>
                                <AggregationDisplayPanel
                                    aggregationWidget={chart}
                                    onChange={onChange}
                                />
                                {chart.type !== 'Line' && (
                                    <Box>
                                        <Text
                                            variant="paletteSectionLabel"
                                            as="label"
                                            htmlFor="chart-limit-display"
                                            mb={1}
                                        >
                                            Limit number of items
                                        </Text>
                                        <Text mb={1} color="grey.700" size="xs">
                                            Choose how many items will appear. Min 1, max{' '}
                                            {HARD_LIMIT_MAX_ITEMS}
                                        </Text>
                                        <Input
                                            id="chart-limit-display"
                                            type="number"
                                            min={1}
                                            max={HARD_LIMIT_MAX_ITEMS}
                                            placeholder="e.g. 15"
                                            value={chart.display.limitNumberItems}
                                            onChange={onChangeLimit}
                                        />
                                    </Box>
                                )}
                            </StyledAccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </StyledAccordion>
        </>
    )
}
