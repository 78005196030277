import React, { FC, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import queryString from 'qs'

import { Button, Flex, Modal, Text } from 'v2/ui'
import { SolidCheckWhite } from 'v2/ui/svgs'

const CheckWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 28px;

    background-color: ${(props) => props.theme.colors.userInterface.accent[1000]};
`

const WelcomeModal: FC = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const { migrated_from_v3: migratedFromV3 = '0' } = queryString.parse(
            window.location.search,
            { ignoreQueryPrefix: true }
        )

        setIsOpen(migratedFromV3 !== '0')
    }, [])

    return (
        <Modal
            size="300px"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            showCloseButton={false}
            noDividers
        >
            <Flex direction="column" justifyContent="Center">
                <CheckWrapper>
                    <SolidCheckWhite />
                </CheckWrapper>
                <Text variant="modalTitle">Welcome to the new Stacker</Text>
                <Text
                    fontSize="14px"
                    color="userInterface.neutral.800"
                    textAlign="center"
                    lineHeight="1.4"
                    marginTop="9px"
                    marginBottom="20px"
                >
                    You have successfully updated to the latest version of Stacker. Have a look
                    around &mdash; we hope you like it.
                </Text>
                <Button
                    variant="Primary"
                    buttonSize="extraLarge"
                    width="210px"
                    onClick={() => setIsOpen(false)}
                >
                    Let&apos;s go
                </Button>
            </Flex>
        </Modal>
    )
}

export default WelcomeModal
