import React, { forwardRef, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react'

import { AppUserContext, Rights } from 'app/AppUserContext'
import { getStackTheme } from 'app/Branding'
import { getUrl, Urls } from 'app/UrlService'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { navigationColor } from 'features/admin/settings/navigation/NavigationUtils'
import AppBar from 'features/AppBar/AppBar'
import NotificationMenu from 'features/collaboration/NotificationMenu'
import { Logo } from 'features/core/nav/navUtils'
import SettingsMenu from 'features/core/nav/SettingsMenu'
import UserMenu from 'features/core/nav/UserMenu'
import AppNavTree from 'features/internal/nav/AppNavTree'
import { DefaultStackNameLogo, GetLogo, getLogoUrl } from 'features/utils/getLogo'
import useStableState from 'utils/useStableState'

import { Box, Button, Divider, Flex, Icon, MenuItem, ScrollBox, Text, Tooltip } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { BANNER_HEIGHT } from '../appBannerConstants'

const SideNav = ({ context, stack, showAppBar, showSideNav }) => {
    const { previewing, isImpersonating } = useContext(AppUserContext)
    const showingPreviewBar = (previewing || isImpersonating) && stack?.options?.workspace_app

    const theme = getStackTheme(stack)

    const navTheme = showSideNav && navigationColor(theme)
    const isMobile = window.innerWidth < 768
    const stackLogo = getLogoUrl(stack, false, null)

    // show the 'default stack logo' if there's no logo set in the theme, and it's enabled for the stack
    // NOTE: if it's not enabled for the stack, and there is no logo set in the theme, it defaults to the Stacker logo image
    const showDefaultStackLogo = theme && !stackLogo && theme.enableDefaultStackLogo

    return (
        <Flex wrap="nowrap" align="stretch" height="100%" maxHeight="100%" maxWidth="100%">
            {showAppBar && <AppBar />}
            {showSideNav && (
                <Flex
                    column
                    wrap="nowrap"
                    flexGrow={1}
                    minWidth={0}
                    align="stretch"
                    background={navTheme.navColor}
                    maxWidth="100%"
                    marginTop={showingPreviewBar ? `${BANNER_HEIGHT}px` : null}
                >
                    {!isMobile &&
                        (showAppBar ? (
                            <Tray context={context} navTheme={navTheme} />
                        ) : (
                            <FullTray context={context} navTheme={navTheme} />
                        ))}

                    <Divider variant="custom" bg={navTheme.hoverColor} />

                    <Box p={4}>
                        {showDefaultStackLogo ? (
                            <Link to={getUrl(Urls.Home)}>
                                <DefaultStackNameLogo
                                    name={stack?.name}
                                    navColor={theme.navColor}
                                    brandColor={theme.brandColor}
                                    wrapText
                                />
                            </Link>
                        ) : (
                            <Flex as={Link} to={getUrl(Urls.Home)} className={STYLE_CLASSES.LOGO}>
                                <GetLogo url={navTheme?.stackerLogo}>
                                    {(logo) => <Logo src={logo} />}
                                </GetLogo>
                            </Flex>
                        )}
                    </Box>

                    <Divider variant="custom" bg={navTheme.hoverColor} />
                    <ScrollBox
                        overflowY="auto"
                        flexGrow={1}
                        flexShrink={1}
                        scrollbarColor={navTheme.highlightColor}
                    >
                        <AppNavTree stack={stack} navTheme={navTheme} />
                    </ScrollBox>
                </Flex>
            )}
        </Flex>
    )
}

const Tray = withUser(
    ({ context, navTheme, isStudioUser, isImpersonating, isEditing, userActions }) => {
        const { hasRight } = useContext(AppUserContext)
        const isAdmin = isStudioUser && !isImpersonating && hasRight(Rights.Admin.Any)

        return (
            <>
                <Flex wrap="nowrap">
                    {isAdmin && (
                        <>
                            <TrayButton
                                icon="edit"
                                ml="-2px"
                                navTheme={navTheme}
                                isActive={isEditing}
                                flexBasis="50%"
                                buttonSize="smDense"
                                onClick={() => {
                                    if (isEditing) {
                                        userActions.stopEditing()
                                    } else {
                                        userActions.startEditing()
                                    }
                                }}
                            >
                                Edit Mode
                            </TrayButton>

                            <SettingsMenu
                                containerStyle={{ flexBasis: '50%' }}
                                context={context}
                                as={TrayButton}
                                buttonSize="smDense"
                                navTheme={navTheme}
                                icon="cog"
                            >
                                Manage App
                            </SettingsMenu>
                        </>
                    )}
                </Flex>
            </>
        )
    }
)

const FullTray = withUser(
    ({ context, navTheme, isStudioUser, isImpersonating, isEditing, userActions }) => {
        const isAdmin = isStudioUser && !isImpersonating

        const isMobile = window.innerWidth < 768
        return (
            <>
                <Flex wrap="nowrap" justify="space-between" alignSelf="stretch">
                    <Box flexGrow={1} ml={2}>
                        <NotificationMenu right={false} ml={['8px', null, '12px']} pt="12px">
                            <TrayButton
                                ml={['-8px', null, '-12px']}
                                mt="-12px"
                                navTheme={navTheme}
                                icon="bell"
                                buttonSize="mdDense"
                            />
                        </NotificationMenu>
                    </Box>
                    {!isMobile && isAdmin && (
                        <TrayButton
                            navTheme={navTheme}
                            icon="edit"
                            buttonSize="mdDense"
                            isActive={isEditing}
                            onClick={() => {
                                if (isEditing) {
                                    userActions.stopEditing()
                                } else {
                                    userActions.startEditing()
                                }
                            }}
                        />
                    )}
                    {!isMobile && isAdmin && (
                        <SettingsMenu
                            as={TrayButton}
                            context={context}
                            navTheme={navTheme}
                            buttonSize="mdDense"
                        >
                            <Icon icon="cog" />
                        </SettingsMenu>
                    )}

                    <UserMenu renderWrapper={(p) => <div {...p} />}>
                        <TrayButton navTheme={navTheme} icon="avatar" buttonSize="mdDense" mr={2} />
                    </UserMenu>
                </Flex>
            </>
        )
    }
)

export const TrayButton = forwardRef(({ navTheme, children, isActive, ...props }, ref) => {
    return (
        <Button
            variant={isActive ? null : 'sideNav'}
            color={navTheme.textColor}
            _hover={{ bg: navTheme.hoverColor }}
            bg={navTheme.highlightColor}
            ref={ref}
            {...props}
        >
            {children}
        </Button>
    )
})

export const PreviewingRole = ({ role, previewAsRole, navTheme, buttonProps = {} }) => {
    // Only want to show this tooltip on page load, to highlight to the user
    // that they are in preview mode. So we useStableState here to make sure
    // even though the entire component is recreated due to StreamApp loading
    // we won't flash the toolip.
    const [toolTipDisplayed, setToolTipDisplayed] = useStableState('previewingTooltipDisplayed')
    const { isOpen, onToggle } = useDisclosure()
    const { data: roles } = useStackRoles()

    // Displaying the tooltip on a delay to give the app chance to fully load
    useEffect(() => {
        setTimeout(() => {
            if (!toolTipDisplayed) {
                setToolTipDisplayed(true)
                onToggle()
            }
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Tooltip
            placement="right"
            trigger="manual"
            open={isOpen}
            label={`You are testing the app as the ${role?.label} role.`}
            onRequestClose={onToggle}
        >
            <Flex justifyContent="space-between" p={4} wrap="nowrap">
                <Menu>
                    <MenuButton
                        as={Button}
                        icon="caretDown"
                        variant="clear"
                        iconAlign="right"
                        color={navTheme.textColor}
                        buttonSize="smNoPadding"
                        _hover={{ bg: navTheme.hoverColor }}
                        pl={0}
                        flexShrink={1}
                    >
                        <>
                            <Text color={navTheme.textColor} fontSize="sm" display="inline">
                                Testing as&nbsp;
                            </Text>
                            <Text
                                color={navTheme.textColorBright}
                                fontSize="sm"
                                display="inline"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                            >
                                {role?.label}
                            </Text>
                        </>
                    </MenuButton>
                    <MenuList>
                        {roles.map((x) => (
                            <MenuItem key={x._sid} onClick={() => previewAsRole(x._sid)}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                <Button
                    buttonSize="sm"
                    flexShrink={0}
                    bg={navTheme.hoverColor}
                    _hover={{ bg: navTheme.navFadeColor }}
                    onClick={() => previewAsRole(null)}
                    {...buttonProps}
                >
                    Stop
                </Button>
            </Flex>
        </Tooltip>
    )
}

export default withStack(SideNav)
