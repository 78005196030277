import React, { useMemo, useState } from 'react'

import { v4 as uuid } from 'uuid'

import ObjectFieldsFilter from 'features/records/components/RecordFilters'

import { Box, Button, Divider, Flex, Icon, Input, Modal, OrderableListSelector, Text } from 'v2/ui'

const DetailsTabsSelector = ({
    object,
    setConfig,
    tabs,
    blocks,
    activityLabel = 'Activity',
    enableComments,
    allowComments,
}) => {
    const [editTabCondition, editConditions] = useState(null)
    const [editTabName, editName] = useState(null)

    const handleCreateAction = () => {
        const id = 'tab_' + uuid()

        const newBlocks = [...blocks, updateBlocksId(getBaseBlocks(object), id)]

        setConfig({
            tabs: addItemBeforeActivity(tabs, {
                name: 'Tab ' + tabs.length,
                id,
                active: true,
                treeIndex: blocks.length,
            }),
            blocks: newBlocks,
        })
    }

    function handleUpdate(selectedItems) {
        const itemIds = selectedItems.map(({ id }) => id)

        const orderedTabs = tabs.sort((a, b) =>
            /*b.id === 'activity' ? -1 : */
            itemIds.indexOf(a.id) < itemIds.indexOf(b.id) ? -1 : 1
        )

        orderedTabs.map((tab) => {
            tab.active = itemIds.includes(tab.id)
        })

        setConfig({ tabs: [...orderedTabs] })
    }
    function patchTab(id, patch) {
        setConfig({ tabs: tabs.map((tab) => (tab.id === id ? { ...tab, ...patch, id } : tab)) })
    }

    const allTabs = useMemo(() => {
        const currentTabs = tabs || []
        // This makes sure that users will always get the activity option if it ever gets deleted
        if (!currentTabs.some((t) => t.id == 'activity')) {
            currentTabs.push({
                id: 'activity',
                type: 'activity',
                name: activityLabel,
                active: tabs.length ? false : enableComments,
            })
        }
        return currentTabs
    }, [tabs, enableComments, activityLabel])

    // For activity tabs, use the name instead of the label to prevent crash
    // Since the label attribute is a function.
    const provideTabLabel = (item) => {
        return item.id === 'activity' ? item.name : item.label
    }

    const activeItems = allTabs
        .filter(({ active }) => active)
        .map(({ name, id, conditions, type }) => ({
            name: name,
            label:
                type === 'activity' ? (
                    <Flex>
                        <Icon icon="comments" color="gray.300" mr={2} /> {name}
                    </Flex>
                ) : (
                    name
                ),
            id,
            conditions,
            actions: [
                {
                    icon: 'edit',
                    onClick: editName,
                    color: 'gray.300',
                    title: 'Edit tab name',
                },
                {
                    icon: 'eye',
                    onClick: editConditions,
                    color: conditions?.length > 0 ? 'adminBrand' : 'gray.300',
                    title: 'Edit conditional visibility',
                },
            ],
            isDisabled: type === 'activity' && !allowComments,
        }))

    return (
        <>
            <Flex column wrap="nowrap" align="stretch" minHeight={0}>
                <Flex mt={4}>
                    <Text variant="paletteSectionLabel" flexGrow={1}>
                        Tabs
                    </Text>
                    <Button
                        data-testId="add-tab-button"
                        icon="add"
                        buttonSize="smDense"
                        variant="adminPrimary"
                        onClick={handleCreateAction}
                    >
                        Add
                    </Button>
                </Flex>
                <Box mt={4} height="100%" maxHeight="100%" flexGrow={1} minHeight={0}>
                    <OrderableListSelector
                        onAdd={(item) => {
                            handleUpdate(addItemBeforeActivity(activeItems, item))
                        }}
                        items={tabs.map(({ name, id, type }) => ({
                            name: name,
                            label:
                                type === 'activity' ? (
                                    <Flex>
                                        <Icon icon="comments" color="gray.300" mr={2} /> {name}
                                    </Flex>
                                ) : (
                                    name
                                ),
                            id,
                            isDisabled: type === 'activity' && !allowComments,
                        }))}
                        provideLabel={provideTabLabel}
                        selectedItems={activeItems}
                        onUpdate={handleUpdate}
                    />
                </Box>
            </Flex>
            {editTabCondition && (
                <EditConditionsModal
                    item={editTabCondition}
                    onUpdate={({ id, conditions }) => patchTab(id, { conditions })}
                    object={object}
                    onClose={() => editConditions(null)}
                />
            )}
            {editTabName && (
                <EditTabName
                    item={editTabName}
                    onUpdate={({ id, name }) => patchTab(id, { name })}
                    onClose={() => editName(null)}
                />
            )}
        </>
    )
}

// If activity is last, we want it to stay last
function addItemBeforeActivity(currentItems, newItem) {
    const last = currentItems[currentItems.length - 1]
    const allButLast = currentItems.slice(0, -1)
    if (last?.id === 'activity') {
        return [...allButLast, newItem, last]
    }
    return [...currentItems, newItem]
}
const EditTabName = ({ item, onUpdate, onClose }) => {
    const [name, setName] = useState(item.name)

    const save = () => {
        onUpdate({ ...item, name })
        onClose()
    }

    return (
        <Modal
            isOpen={Boolean(item)}
            title="Edit tab name"
            onClose={onClose}
            size="700px"
            showCloseButton={false}
        >
            <Flex column align="stretch">
                <Text>Update the name of the tab</Text>
                <Divider my={2} />
                <Input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Tab's name"
                />

                <Divider my={2} />
                <Flex justify="flex-end">
                    <Button variant="moderateSm" icon="x" onClick={onClose} mr={1}>
                        Cancel
                    </Button>
                    <Button
                        buttonSize="sm"
                        icon="checkmark"
                        onClick={save}
                        disabled={!name || name === item.name}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    )
}
const EditConditionsModal = ({ object, item, onUpdate, onClose }) => {
    const [conditions, setConditions] = useState(item.conditions)
    const save = () => {
        onUpdate({ ...item, conditions })
        onClose()
    }

    return (
        <Modal
            isOpen={Boolean(item)}
            title="Edit conditions"
            onClose={onClose}
            size="700px"
            showCloseButton={false}
        >
            <Flex column align="stretch">
                <Text>Select the conditions under which this tab should be visible.</Text>
                <Divider my={2} />
                <Box flexGrow={1} maxWidth={'100%'}>
                    <ObjectFieldsFilter
                        object={object}
                        value={conditions}
                        showRoleFilter
                        fields={object.fields.filter(
                            (field) => !field.connection_options.is_disabled
                        )}
                        onChange={setConditions}
                    />
                </Box>
                <Divider my={2} />
                <Flex justify="flex-end">
                    <Button variant="moderateSm" icon="x" onClick={onClose} mr={1}>
                        Cancel
                    </Button>
                    <Button buttonSize="sm" icon="checkmark" onClick={save}>
                        Save
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default DetailsTabsSelector

const getBaseBlocks = (object) => ({
    id: 'page',
    type: 'container',
    config: {
        style: {
            default: {
                width: '100%',
                height: '100%',
                margin: '0px auto',
                flexGrow: '0',
                justifyContent: 'flex-start',
            },
        },
        locked: true,
        broadcast: [],
        attributes: {
            label: 'Page',
            direction: 'column',
        },
    },
    childBlocks: [
        {
            id: 'center_content',
            type: 'container',
            config: {
                style: {
                    default: {
                        width: '100%',
                        flexGrow: '0',
                        maxWidth: '1000px',
                        marginTop: '20px',
                        minHeight: '50px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '0px',
                    },
                },
                locked: true,
                broadcast: [],
                attributes: {
                    label: 'Center Content',
                    direction: 'column',
                },
            },
            childBlocks: [
                {
                    id: 'field_container_0',
                    type: 'field_container',
                    config: {
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                        broadcast: [],
                        attributes: {
                            contents: [
                                {
                                    id: uuid(),
                                    type: 'section',
                                },
                            ],
                            objectId: object._sid,
                        },
                    },
                    childBlocks: [],
                },
            ],
        },
    ],
})

function updateBlocksId(blocks, id) {
    return {
        ...blocks,
        id,
        childBlocks: blocks.childBlocks?.map((block) => ({
            ...block,
            id: block.id + id,
            childBlocks: block.childBlocks?.map((block) => ({
                ...block,
                id: block.id + id,
            })),
        })),
    }
}
