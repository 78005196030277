import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { NotificationDropdown, StreamContext, useFeedContext } from 'react-activity-feed'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'

import AppContext, { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { getUrl } from 'app/UrlService'
import { useFeatures } from 'data/hooks/features'
import { useStacks } from 'data/hooks/stacks'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Avatar, Box, Flex, Icon, Link, Text } from 'v2/ui'
import getPlainTextFromSlate from 'v2/ui/utils/getPlainTextFromSlate'
import { isCollaborationEnabled } from 'v2/ui/utils/ProtectedFeature'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import withStream from './withStream'

import 'react-activity-feed/dist/index.css'

const NotificationMenu_ = ({ navTheme, children, right = true, ...props }) => {
    const context = useContext(StreamContext)
    const { isStudioUser, isImpersonating, user } = useAppUserContext()
    const { workspaceAccount, selectedStack } = useAppContext()
    const workspacesMode =
        isStudioUser && !(isImpersonating && user?._object_id) && workspaceAccount
    const collaborationEnabled = isCollaborationEnabled(selectedStack)
    const { close } = useSlidingPane()
    // v3/customer access with collaboration turned off
    if (!(workspacesMode || collaborationEnabled)) return null

    return (
        <Box onClick={() => close()} mr="7px" {...props}>
            <NotificationDropdown
                key={context.token}
                right={right}
                feedGroup="notification"
                width={window.innerWidth <= 768 ? window.innerWidth * 0.7 : 475}
                notify
                Placeholder={() => <Text m={4}>No notifications to show.</Text>}
                // set mark_seen to true when bug in react-activity-feed will be fixed
                // https://github.com/GetStream/react-activity-feed/issues/366
                options={{ mark_seen: false }}
                LoadingIndicator={() => (
                    <Flex column m={4}>
                        <Spinner color="gray.400" />
                    </Flex>
                )}
                Icon={() =>
                    children || (
                        <NotificationButton navTheme={navTheme}>
                            <Icon size={16} icon="bell" />
                        </NotificationButton>
                    )
                }
                userId={context.user.id}
                Group={NotificationGroup}
            />
        </Box>
    )
}

export const NotificationMenu = memo(withStream(NotificationMenu_))

export const NotificationButton = styled(Flex)`
    color: ${(props) =>
        props.navTheme?.textColorBright || props.navTheme?.button?.color || '#ffffff'};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    padding: 4px;
    width: 32px;
    border-radius: 3px;
    background-color: ${(props) =>
        props.navTheme?.bgScdNav || props.navTheme?.button?.background || '#F4F5F8'};
    opacity: ${(props) => get(props.navTheme, 'button.opacity', 1)};
    @media (max-width: 768px) {
        border-radius: 100%;
        height: 24px;
        width: 24px;
    }
`

const PseudoLink = styled.span`
    color: #2980b9;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const NotificationGroup = ({ activityGroup }) => {
    const { activities } = activityGroup
    const context = useContext(StreamContext)
    const { onMarkAsSeen } = useFeedContext()
    // remove this when bug in react-activity-feed will be fixed
    // https://github.com/GetStream/react-activity-feed/issues/366
    useEffect(() => {
        if (!activityGroup.is_seen) onMarkAsSeen(activityGroup)
    }, [onMarkAsSeen, activityGroup])
    return (
        <Box>
            {/* <Text>{`${group} (${activity_count}):`}</Text> */}
            <NotificationList>
                {activities.map((x, index) => (
                    <Notification key={index} activity={x} userId={context.user.id} />
                ))}
            </NotificationList>
        </Box>
    )
}

function tryGetPlainText(data, length = 200) {
    try {
        return getPlainTextFromSlate(JSON.parse(data), length)
    } catch (ex) {
        if (!ex.message || !ex.message.includes('Unexpected token')) {
            Sentry.captureException(ex)
        }
    }
}
const Notification = ({ activity, userId }) => {
    const { workspaceAccount } = useContext(AppContext)
    const { data: stacks } = useStacks()

    let { verb, relatedTo, actor, reaction, object } = activity

    const { data: features } = useFeatures({}, relatedTo?.stackId)

    const feature = useMemo(() => {
        if (relatedTo?.featureSid && features) {
            return features.find((f) => relatedTo?.featureSid === f._sid)
        }
        return false
    }, [features, relatedTo?.featureSid])

    let textObject

    const timeDisplay = useDisplayTimeFrom(activity.time)
    let reactionTarget

    const handleMouseDown = useCallback(() => {}, [])

    if (typeof object === 'string') {
        textObject = object
    }
    if (
        (verb === 'post' || verb === 'comment') &&
        activity.mentions &&
        activity.mentions.includes(userId)
    ) {
        verb = 'mentioned you'
    } else {
        if (reaction && object) {
            textObject = object.object
            reactionTarget = object.verb
            relatedTo = object.relatedTo
        }
        if (verb === 'comment') {
            textObject = get(activity, 'reaction.data.object')
            verb = 'replied'
        } else {
            verb = verb + (verb.endsWith('e') ? 'd' : 'ed')
        }
    }

    const plainText = useMemo(() => {
        return tryGetPlainText(textObject)
    }, [textObject])

    const relatedToUrl = useMemo(() => {
        let result
        if (relatedTo) {
            // Object has been disabled so do not show a link to this notification
            if (relatedTo?.featureSid && !feature) {
                result = null
            } else {
                // For new mentions we store a feature id and a record id. This means that they will still
                // work if the feature url changes in the future
                if (feature && relatedTo?.recordSid) {
                    result = `${feature?.url}/view/${relatedTo?.recordSid}#activity`
                } else {
                    // This is so that it still works for old mentions which use the url
                    result = relatedTo.url
                }

                // if we're in a workspace
                if (workspaceAccount) {
                    result = getUrl(
                        result,
                        stacks?.find((stack) => stack._sid === relatedTo.stackId)
                    )
                } else if (relatedTo.domain !== window.location.hostname) {
                    result = `//${relatedTo.domain}${result}`
                }
            }
            return result
        }
    }, [feature, relatedTo, workspaceAccount, stacks])

    const isAlreadyInThisUrl = relatedTo.fullUrl === window.location.href

    return (
        <Box as="li" p={3} borderBottom="1px solid" borderColor="grey.100">
            <Flex wrap="noWrap">
                <Avatar
                    name={get(actor, 'data.name')}
                    src={get(actor, 'data.profileImage')}
                    size="sm"
                    verticalAlign="middle"
                    mr={2}
                />
                <Box flexGrow={1} overflow="hidden">
                    <Text
                        mb={1}
                        flexGrow={1}
                        variant="feedHeader"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        <strong>{get(actor, 'data.name')}</strong> {verb}
                        {reactionTarget && ` your ${reactionTarget}`}
                        {!relatedToUrl && (
                            <>
                                {' on '}
                                {relatedTo.name}
                            </>
                        )}
                        {relatedTo && relatedToUrl && (
                            <>
                                {' on '}
                                {isAlreadyInThisUrl ? (
                                    <PseudoLink>{relatedTo.name}</PseudoLink>
                                ) : (
                                    <Link
                                        href={
                                            relatedTo.fullUrl === window.location.href
                                                ? undefined
                                                : relatedToUrl
                                        }
                                        openInNewTab={false}
                                        onMouseDown={handleMouseDown}
                                    >
                                        {relatedTo.name}
                                    </Link>
                                )}
                            </>
                        )}
                        :
                    </Text>
                    <Text variant={'feedTimestamp'}>{timeDisplay}</Text>
                </Box>
            </Flex>
            {plainText && (
                <Flex mt={2} wrap="noWrap">
                    <Icon icon="quoteLeft" size="xs" color="gray.200" alignSelf="flex-start" />
                    <Text mx={1} maxLines={2} borderRadius="sm">
                        {plainText}
                    </Text>
                    <Icon
                        icon="quoteRight"
                        size="xs"
                        color="gray.200"
                        alignSelf="flex-end"
                        pb="3px"
                    />
                </Flex>
            )}
        </Box>
    )
}

const NotificationList = styled('ul')`
    padding: unset;
    list-style: none;
    margin: unset;
`

export default NotificationMenu
