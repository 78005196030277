const AGGREAGATION_TYPES = ['count', 'sum', 'avg'] as const
export type AggregationType = typeof AGGREAGATION_TYPES[number]

export const isAggregationType = (x: any): x is AggregationType => AGGREAGATION_TYPES.includes(x)

export type Aggregation = {
    fieldApiName?: string
    type: AggregationType
}

type Aggregate = {
    value: number
} & Aggregation

export type GroupKey = {
    fieldApiName: string
    value: string
}

export type AggregationGroup = {
    aggregates: Aggregate[]
    groupKey?: GroupKey
    others?: boolean
    otherGroupsCount?: number
    secondaryGroups?: AggregationGroup[]
}
