import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FixedSizeGrid as Grid } from 'react-window'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'emotion'
import debounce from 'lodash/debounce'

import { SearchInput } from 'v2/ui'
import { getFaIconName } from 'v2/ui/components/Icon'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import ItemPickerForm from 'ui/forms/ItemPickerForm'

import IconsList from './iconsList'

export const icons = IconsList

const ICON_HEIGHT = 30

const CONTENT_WIDTH = 360

const CONTENT_HEIGHT = 308

// Returns a font awesome definition
export const findIcon = (iconName, iconPack = 'fad') => {
    const iconDef = findIconDefinition({ prefix: iconPack, iconName: getFaIconName(iconName) })
    if (iconDef) return iconDef
    return false
}

// Returns a list of icon objects.
const getAllIcons = (iconPack = 'fad') => {
    let keys = []
    const allIcons = library?.definitions?.[iconPack]
    if (allIcons) {
        keys = Object.keys(allIcons)
    }

    // First 2 elements are fad and prefix which can be ignored.
    const icons = keys.map((componentName) => {
        return {
            label: `fa-${componentName}`,
            icon: { icon: allIcons[componentName], iconName: componentName, prefix: iconPack },
        }
    })

    return icons
}

function IconContents({ onChange, value, setSelectedIcon, searchValue, setSearchValue, iconPack }) {
    const MAX_NUM_COLUMN = 9

    const [content, setContent] = useState({
        icons: [],
        rowCount: 0,
        columnCount: MAX_NUM_COLUMN,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchIcons = useCallback(
        debounce((newValue) => handleFilterIcons(newValue), 300),
        []
    )

    const handleSearchChange = useCallback(
        (value) => {
            // Remove spaces in front.
            value = value.trimStart()
            setSearchValue(value)
            handleSearchIcons(value)
        },
        [handleSearchIcons, setSearchValue]
    )

    // Update content with new icons, row count and column count.
    const handleIconsChange = useCallback((newIcons) => {
        // numRows and numColumn are needed in react-window.
        let numRows = Math.floor(newIcons.length / MAX_NUM_COLUMN)

        // Check if there are icons when numRows is 0.
        if (numRows === 0 && newIcons.length > 0) {
            numRows = 1
        }
        // Set numColumn to length of icons if numRows is <= 1.
        const numColumn = numRows <= 1 ? newIcons.length : MAX_NUM_COLUMN
        setContent({
            icons: newIcons,
            rowCount: numRows,
            columnCount: numColumn,
        })
    }, [])

    // Filter icons according to search value.
    const handleFilterIcons = (value) => {
        const icons = getAllIcons(iconPack)
        let newIcons = icons.filter((icon) => {
            if (value === '') {
                return icon
            } else if (icon.label.toLowerCase().includes(value.toLowerCase())) {
                return icon
            }
            return false
        })
        handleIconsChange(newIcons)
    }

    // When popover is opened, filter icons with searchValue.
    useEffect(() => {
        handleFilterIcons(searchValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const scrollActiveIconIntoView = (grid) => {
        if (!grid) return

        const activeIconIdx = content.icons.findIndex((icon) => icon.label === value)
        if (activeIconIdx < 0) return

        const activeIconRowIdx = Math.floor(
            activeIconIdx / (content.icons.length / content.rowCount)
        )
        const activeIconColumnIdx = activeIconIdx - activeIconRowIdx * content.columnCount

        grid.scrollToItem({
            columnIndex: activeIconColumnIdx,
            rowIndex: activeIconRowIdx,
            align: 'center',
        })
    }

    const gridRef = useRef()

    useEffect(() => {
        const grid = gridRef.current
        if (!grid) return

        if (searchValue && content.icons.length > 0) {
            grid.scrollToItem({ columnIndex: 0, rowIndex: 0 })
        } else {
            scrollActiveIconIntoView(grid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const setGridRef = (grid) => {
        gridRef.current = grid

        if (!searchValue) scrollActiveIconIntoView(grid)
    }

    return (
        <IconPickerWrapper searchValue={searchValue} onChangeSearchValue={handleSearchChange}>
            {content.icons.length > 0 ? (
                <Grid
                    ref={setGridRef}
                    columnCount={content.columnCount}
                    rowCount={content.rowCount}
                    columnWidth={40}
                    rowHeight={ICON_HEIGHT}
                    height={CONTENT_HEIGHT}
                    width={CONTENT_WIDTH}
                    className={css`
                        scrollbar-width: thin;
                        scrollbar-height: thin;
                        scrollbar-color: rgba(0, 0, 0, 0.5);

                        ::-webkit-scrollbar {
                            width: 8px;
                            height: 8px;
                        }
                        ::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        ::-webkit-scrollbar-thumb {
                            background-color: rgba(0, 0, 0, 0.5);
                            border-radius: 20px;
                        }
                    `}
                    style={{ marginLeft: '5px', overflowX: 'hidden' }}
                >
                    {({ columnIndex, rowIndex, style }) => {
                        // Check is needed as icons is rendered before columnIndex and rowIndex are updated.
                        if (content.rowCount < 1) return null

                        const currentIcon = content.icons[rowIndex * MAX_NUM_COLUMN + columnIndex]
                        const isActive = value === currentIcon.label

                        return (
                            <div style={style}>
                                {
                                    <StyledIconItem
                                        tabIndex={isActive ? -1 : undefined}
                                        className={isActive ? 'active' : ''}
                                        onClick={(e) => {
                                            setSelectedIcon(currentIcon)
                                            onChange(e, currentIcon.label)
                                        }}
                                    >
                                        <IconItem value={currentIcon.icon} />
                                    </StyledIconItem>
                                }
                            </div>
                        )
                    }}
                </Grid>
            ) : (
                <div
                    style={{
                        paddingLeft: '10px',
                        paddingTop: '10px',
                        height: CONTENT_HEIGHT,
                        width: CONTENT_WIDTH,
                    }}
                >
                    <span>No results</span>
                </div>
            )}
        </IconPickerWrapper>
    )
}
/**
 * @param { any } param0
 */
export default function IconPickerForm({
    iconPack,
    customOnChange,
    color,
    fontSize,
    width,
    isFetching = false,
    ...props
}) {
    const [icons, setIcons] = useState([])

    const [searchValue, setSearchValue] = useState('')

    const [selectedIcon, setSelectedIcon] = useState(props?.item?.options?.icon)

    const { setValue } = useFormContext()

    useEffect(() => {
        if (props?.item?.options?.icon) {
            setValue('icon', props?.item?.options?.icon)
        }
    }, [props?.item?.options?.icon, setValue])

    useEffect(
        () => {
            if (!props.onIconChange) {
                return
            }
            let passIcon = selectedIcon?.label
            props.onIconChange(passIcon, props.item)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedIcon]
    )

    return (
        <ItemPickerForm
            renderCurrentValue={(name) => {
                if (!selectedIcon && isFetching) {
                    return <Spinner size="xs" />
                } else {
                    if (name === selectedIcon?.label)
                        return (
                            <IconItem
                                value={selectedIcon?.icon}
                                color={color}
                                fontSize={fontSize}
                                width={width}
                            />
                        )
                    const iconDef = findIcon(name, iconPack)
                    return (
                        <IconItem value={iconDef} color={color} fontSize={fontSize} width={width} />
                    )
                }
            }}
            place="right"
            bodyPadding={0}
            {...props}
            closeOnChange
        >
            {({ onChange, value }) => (
                <IconContents
                    onChange={(e, newIcon) => {
                        onChange(newIcon)
                        customOnChange && customOnChange(e, newIcon)
                    }}
                    value={value}
                    setSelectedIcon={setSelectedIcon}
                    icons={icons}
                    setIcons={setIcons}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    iconPack={iconPack}
                />
            )}
        </ItemPickerForm>
    )
}

function IconItem({ value, color = '#456', fontSize = 20, width = 20 }) {
    return (
        <div
            style={{
                minWidth: 30,
                height: ICON_HEIGHT,
                lineHeight: '35px',
                borderRadius: 5,
                textAlign: 'center',
            }}
        >
            <FontAwesomeIcon icon={value} style={{ color, fontSize, width }} />
        </div>
    )
}

const StyledIconItem = styled.button`
    position: relative;
    flex-shrink: 0;
    margin-right: 8px;
    margin-bottom: 6px;
    margin-top: 5px;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    background: transparent;
    &.active {
        background: ${() => V4DesignSystem.colors.gray[100]};
    }
`

const supportedSearchKeysRegexp = /^[a-z0-9]$/i // If it's a letter or a digit.

const IconPickerWrapper = ({ children, searchValue, onChangeSearchValue }) => {
    const searchInputRef = useRef(null)
    const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)

    const handleKeyDown = (e) => {
        if (isSearchInputFocused) return

        if (supportedSearchKeysRegexp.test(e.key)) {
            e.stopPropagation()
            searchInputRef.current?.focus()
        }
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <SearchInput
                ref={searchInputRef}
                value={searchValue}
                onChange={onChangeSearchValue}
                style={{
                    background: 'white',
                    borderBottom: '1px solid whitesmoke',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    borderRadius: '5px 5px 0px 0px',
                }}
                autoFocus={true}
                onFocus={() => setIsSearchInputFocused(true)}
                onBlur={() => setIsSearchInputFocused(false)}
            />
            {children}
        </div>
    )
}
