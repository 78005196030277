const vars = {
    // General display
    fontFamily: 'Inter UI, Helvetica Neu, Helvetica, Sans',
    fontColor: '#304155',
    lightFontColor: '#babcca',
    fontSize: '12pt',
    fontWeight: 'normal',
    neutral100: 'rgb(230, 230, 230)',
    neutral200: 'rgb(200, 200, 200)',

    fontS: '10pt',
    fontM: '12pt',
    fontL: '14pt',
    fontXL: '18pt',

    // Global Colors
    backgroundColor: '#f5f7fa', // This is the very back of the app
    surfaceColor: 'white', // This is the color of all the components
    inputBackgroundColor: 'white', // This is the color of all the components
    elementHighlightColor: '#ebf5ff', // This is the color of all the components
    linkTextColor: '#2980b9',
    outlineColor: '#ddd',

    brandColor: '#f39c12',
    successColor: '#2ecc71',
    dangerColor: '#e74c3c',

    dividerColor: '#eee',
    dividerThickness: 1,

    borderThickness: '1px',
    borderRadius: '5px',

    // Layout elements
    elementBorderRadius: '5px',
    elementShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    elementBorder: '0px solid #e8e8e8',

    smallElementBorderRadius: '5px',
}

const stackerTheme = {
    ...vars,

    // Dividers, e.g. used in Tables

    // Spacing
    none: '0px',
    spaceXXS: '2px',
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '32px',
    xlarge: '64px',
    spaceXXL: '128px',

    // Tables
    columnHeadingWeight: '600',
    columnHeadingColor: 'unset',
    columnHeadingSize: '0.9em',
    rowHighlightColor: '#f6f8f9',

    // Element display

    // Headings etc.
    pageTitleSize: '28pt',
    pageTitleWeight: '600',
    pageTitleFont: 'Eina01',
    pageTitleColor: '#304155',

    // Buttons
    buttonFontFamily: vars.fontFamily,
    buttonFontSize: vars.fontSize,
    buttonFontColor: vars.fontColor,
    buttonColor: vars.surfaceColor,
    buttonBorderColor: vars.outlineColor,
    buttonBorderRadius: '5px',
    buttonBorderThickness: vars.borderThickness,
    buttonTextTransform: 'none',
    buttonLetterSpacing: '0px',

    buttonHoverFontColor: null,
    buttonHoverColor: null,
    buttonHoverBorderColor: null,

    // Headings
    headingOneSize: '24pt',
    headingTwoSize: '18pt',
    headingThreeSize: '14pt',
    headingWeight: '600',
    headingFont: 'Eina01',
    headingColor: vars.fontColor,
    headingTextTransform: 'none',
    headingLetterSpacing: '0px',
}
export default stackerTheme
