import styled from '@emotion/styled'

import { Button } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const ButtonSize = 42

export const AppBarButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    margin-bottom: 2px;
    width: ${ButtonSize}px;
    height: ${ButtonSize}px;
    font-size: 1rem;
    font-weight: bold;
    padding-left: unset;
    padding-right: unset;
    border: none;
    background: ${(props) => props.navTheme.bgAppBar};
    transition: background 0.2s ease-in-ease-out;
    color: ${V4DesignSystem.colors.gray[500]};
    :hover {
        background: ${(props) =>
            props.navTheme.theme === 'light'
                ? '#F4F5F8'
                : // @ts-ignore
                  props.theme.colors.appBar.backgroundHover};
    }
`
