export const findWidgetBlockById = (blocks: Block[], id: string): Block | undefined => {
    let result: Block | undefined = undefined
    for (let i = 0; !result && i < blocks.length; i++) {
        if (blocks[i].id === id) {
            result = blocks[i]
        } else {
            result = findWidgetBlockById(blocks[i].childBlocks ?? [], id)
        }
    }
    return result
}
