import React, { useEffect, useState } from 'react'

import { ButtonProps } from '@chakra-ui/react'

import useConfirmModal from 'app/ConfirmModal'
import { useObject } from 'data/hooks/objects'
import useTrack from 'utils/useTrack'

import { Button, ConditionalWrapper, Icon, Text, Tooltip } from 'v2/ui'
import { DeleteField } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { getIsSyntheticField } from '../../../../utils/fieldUtils'

const { colors } = stackerTheme()

type Props = Omit<ButtonProps, 'children' | 'variant'> & {
    icon?: string
    label?: string
    buttonSize?: string
    field: FieldDto
    variant?: string
    children?: React.ReactNode
    ignorePreviewAndImpersonation?: boolean
    fieldName?: string
    onAfterDelete?: () => void
}
export const DeleteFieldButton: React.FC<Props> = ({
    field,
    isDisabled,
    ignorePreviewAndImpersonation,
    fieldName = 'field',
    onAfterDelete,
    ...props
}) => {
    const { object, deleteField, changeObject: updateObject } = useObject(field.object_id)
    const [error, setError] = useState<string | undefined>()
    const isStackerDynamicField = getIsSyntheticField(field)
    const { track } = useTrack()

    const afterFieldDeletedUpdateObject = async (deletedField: FieldDto) => {
        if (!object?.options?.fields_order) return

        await updateObject(
            {
                ...object,
                options: {
                    ...object.options,
                    fields_order: object.options.fields_order.filter(
                        (f) => f !== deletedField.api_name
                    ),
                },
            },
            {
                allowOptimisticUpdates: true,
            }
        )
    }

    const modalData = {
        zIndex: 9999,
        title: `Delete ${field.label}?`,
        titleStyle: { colors: colors.userInterface.neutral[1000] },
        icon: <Icon svg={<DeleteField />} size="56px" ml="15px" mb={4} />,

        confirmVariant: 'PrimaryError',
        message: (
            <>
                <Text style={{ color: colors.userInterface.neutral[900] }}>
                    Are you sure you want to delete this {fieldName.toLowerCase()}?
                </Text>

                {isStackerDynamicField ? (
                    <Text mt={4} fontWeight="bold">
                        This action cannot be undone.
                    </Text>
                ) : (
                    <Text mt={4} fontWeight="bold">
                        This will also delete all data contained in this {fieldName.toLowerCase()}.
                        This action cannot be undone.
                    </Text>
                )}

                {error && (
                    <Text mt={4} variant="error">
                        {error}
                    </Text>
                )}
            </>
        ),
        onConfirm: (modal: any) => {
            setError(undefined)
            track('WIP - Frontend - Field - Deleted', {
                type: field.type,
                options: field.options,
                name: field.label,
            })
            return deleteField(field._sid, undefined, {
                bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
            })
                .then(() => {
                    if (onAfterDelete) onAfterDelete()
                    return afterFieldDeletedUpdateObject(field)
                })
                .then(() => modal.toggle())
                .catch(() => {
                    setError('Failed to delete the field')
                })
        },
        onCancel: (modal: any) => {
            setError(undefined)
            modal.toggle()
        },
    }

    const { show: showConfirm, setData } = useConfirmModal(modalData)

    useEffect(() => {
        setData(modalData)
        // This will trigger the modal to show the error message if the error message changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const handleClick = () => {
        showConfirm(modalData)
    }

    return (
        <ConditionalWrapper
            condition={isDisabled}
            wrapper={(children: any) => (
                <Tooltip
                    placement="bottom"
                    label="This is the display name field and cannot be deleted."
                >
                    {children}
                </Tooltip>
            )}
        >
            <Button {...props} isDisabled={isDisabled} onClick={handleClick} />
        </ConditionalWrapper>
    )
}

export default DeleteFieldButton
