import React from 'react'

import { HelpLinks } from './helpLinks'

type Props = {
    HELP_LINKS: HelpLinks
}

export const HelpLinksPage: React.FC<Props> = ({ HELP_LINKS }) => {
    return (
        <div>
            {Object.keys(HELP_LINKS).map((x) => (
                <div key={x}>
                    <a href={HELP_LINKS[x]}>{HELP_LINKS[x]}</a>
                </div>
            ))}
        </div>
    )
}
