import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import store from 'app/store'
import { REACT_QUERY } from 'data/utils/constants'

import { invalidatePermissions } from './permissions/permissionOperations'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    authedUserDomainFilter,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { invalidatePages } from './pages'

export function isDisabled(selectedStack, workspaceAccount) {
    const state = store.getState()
    const studioUser = get(state, 'user.studioUser') || {}
    return !studioUser?._sid || authedUserDomainFilter(selectedStack, workspaceAccount)
}
/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useRoles(options = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    const enabled = options.enabled !== false && !isDisabled(selectedStack, workspaceAccount)
    return useQuery(
        REACT_QUERY.roles.listName,
        REACT_QUERY.roles.endpoint,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS, enabled },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
            bypassMatchingStackCheck: true,
        }
    )
}

export function useCreateRole() {
    return useCreateItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint, {
        onSuccess: () => {
            invalidatePages()
            invalidatePermissions()
        },
    })
}
export function useUpdateRole() {
    return useUpdateItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint, {
        onSuccess: () => {
            invalidatePages()
            invalidatePermissions()
        },
    })
}

export function refetchRoles() {
    return queryClient.refetchQueries(REACT_QUERY.roles.listName)
}

export function useDeleteRole() {
    return useDeleteItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint)
}
