import React from 'react'

import { useAppContext } from 'app/AppContext'
import HELP_LINKS from 'app/helpLinks'
import { useUpdateStackOptions } from 'data/hooks/stacks'

import { FeatureSplashScreen } from 'v2/ui'
const CustomerAccessSplash = () => {
    const { selectedStack } = useAppContext()
    const updateStackOptions = useUpdateStackOptions()

    const handleSetup = () => {
        return updateStackOptions(selectedStack, { enable_external_access: true })
    }
    return (
        <FeatureSplashScreen
            icon="world"
            title="Customer Access"
            subtitle={
                <>
                    Enable Customer Access to allow your customers to see and interact with data in
                    this app. <br />
                    <br /> Your list of customers can be imported from your data and given roles to
                    grant them just the right level of access.
                </>
            }
            actions={[
                { content: 'Set up Customer Access', onClick: handleSetup },
                { content: 'Learn more', href: HELP_LINKS.CUSTOMER_ACCESS },
            ]}
        />
    )
}
export default CustomerAccessSplash
