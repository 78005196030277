import { AccountOperation, AccountOperationResponse } from 'features/stackerSettings/types'

import { fetchAndReturn } from '../utils/utils'

export const performOperation = (
    workspaceAccount: Partial<Account> | Account,
    operationName: AccountOperation
): Promise<AccountOperationResponse> => {
    return fetchAndReturn(`accounts/${workspaceAccount._sid}/operation/`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
            operation: operationName,
        }),
    }) as Promise<AccountOperationResponse>
}
