import { useCallback, useEffect, useState } from 'react'

import QueryString from 'qs'
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdparty'

import { getAbsoluteUrl, getUrl, Urls } from 'app/UrlService'
import { useAuthProviders } from 'data/hooks/authProviders'

export function useSupertokensSsoWithRedirect(workspaceAccount: Account | null) {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const [isRedirecting, setIsRedirecting] = useState(false)
    const { data: providers, isLoading } = useAuthProviders(workspaceAccount?._sid)

    const startFlow = useCallback(
        async function startFlow(thirdPartyId: string) {
            setIsRedirecting(true)
            try {
                sessionStorage.setItem(
                    'redirectAfterCallback',
                    decodeURIComponent(query.r?.toString() ?? '')
                )
                const url = await getAuthorisationURLWithQueryParamsAndSetState({
                    thirdPartyId,
                    frontendRedirectURI: getAbsoluteUrl(
                        getUrl(
                            `${Urls.AuthThirdPartyCallback}/${thirdPartyId}`,
                            undefined,
                            workspaceAccount
                        )
                    ),
                    options: {},
                })
                window.location.assign(url)
            } catch (ex) {
                console.error(ex)
                setIsRedirecting(false)
            }
        },
        [query.r, workspaceAccount]
    )

    useEffect(() => {
        // We honor this old auth0 setting that skips the login page and redirects to the first provider
        if (
            providers?.length &&
            !workspaceAccount?.optional_features.auth0_show_login_page &&
            workspaceAccount?.supertokens_sso_enabled
        ) {
            const firstProvider = providers?.[0]
            if (firstProvider) {
                debugger
                startFlow(firstProvider.third_party_id)
            }
        }
    }, [
        providers,
        startFlow,
        workspaceAccount?.optional_features.auth0_show_login_page,
        workspaceAccount?.supertokens_sso_enabled,
    ])

    return { startFlow, isRedirecting, isLoading, providers }
}
