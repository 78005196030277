import React, { FC, SVGProps } from 'react'

import styled from '@emotion/styled'

import { Text } from 'v2/ui'

import { Wrapper } from '../common/Wrapper'

export const METRIC_NARROW_WIDTH = 430

const NarrowText = styled(Text)`
    align-self: center;

    @container (width > ${METRIC_NARROW_WIDTH}px) {
        display: none;
    }
`

const WideText = styled(Text)`
    align-self: center;

    @container (width <= ${METRIC_NARROW_WIDTH}px) {
        display: none;
    }
`

export type InvalidBlockProps = {
    blockType: 'chart' | 'metric'
    height: number
    numberOfMetrics: number
    isEditing: boolean
    isActive: boolean
    isOnListView?: boolean
    narrowTitle: string
    wideTitle: string
    wideSubtitle: string
    Illustration: FC<SVGProps<SVGSVGElement>>
    onClick?: () => void
}

export const InvalidBlock: FC<InvalidBlockProps> = ({
    blockType,
    height,
    numberOfMetrics,
    isEditing,
    isActive,
    isOnListView,
    narrowTitle,
    wideTitle,
    wideSubtitle,
    Illustration,
    onClick,
}) => (
    <Wrapper
        pl={6}
        pr={6}
        pt={blockType === 'metric' ? 3 : 6}
        pb={blockType === 'metric' ? 3 : 6}
        height={height}
        numberOfMetrics={numberOfMetrics}
        isEditing={isEditing}
        isActive={isActive}
        isOnListView={isOnListView}
        onClick={onClick}
    >
        <Illustration style={{ width: '100%', paddingTop: '10px', marginBottom: '10px' }} />
        <NarrowText size="sm" textAlign="center" mt={1} pt={1}>
            {narrowTitle}
        </NarrowText>
        <WideText textAlign="center" mt={2} fontWeight="bold" fontSize="md">
            {wideTitle}
        </WideText>
        <WideText size="sm" textAlign="center" mt={1} pt={1}>
            {wideSubtitle}
        </WideText>
    </Wrapper>
)
