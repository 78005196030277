/* Code Quality: Not audited */

import React from 'react'

import get from 'lodash/get'

import WithObject from 'data/wrappers/WithObject'
import { withViews } from 'data/wrappers/WithViews'

import { Dropdown } from 'v2/ui'

const ViewPicker = ({ params, views, onChange, value, isExternalField }) => {
    let { fieldId, objectId, listType, listObjectId } = params

    if (isExternalField && params.field) {
        ;({ fieldId, objectId, listType } = params.field)
    }

    // If showing all records then we do not need to check for the field lookup
    if (listType == 'all') {
        const availableViews = views.filter(
            (view) => view.object_id == listObjectId && view.type == 'list'
        )

        return (
            <Dropdown
                onChange={onChange}
                placeholder="Select Layout"
                isClearable={false}
                options={viewsToOptions(availableViews || [])}
                value={value}
            />
        )
    }

    return (
        <WithObject objectId={objectId}>
            {({ object }) => {
                const field = object.fields.filter(
                    (field) => field._sid == fieldId && get(field, 'options.lookup_target')
                )

                let availableViews = []
                if (field.length) {
                    const lookupObject = isExternalField
                        ? objectId
                        : get(field[0], 'options.lookup_target')

                    availableViews = views.filter(
                        (view) => view.object_id == lookupObject && view.type == 'list'
                    )
                }

                return (
                    <Dropdown
                        onChange={onChange}
                        placeholder="Select Layout"
                        isClearable={false}
                        options={viewsToOptions(availableViews || [])}
                        value={value}
                    />
                )
            }}
        </WithObject>
    )
}

const viewsToOptions = (view) => {
    return view.map((record) => ({
        label: record.name,
        value: record._sid,
    }))
}

ViewPicker.propTypes = {}

export default withViews(ViewPicker)
