import { useLayoutEffect, useState } from 'react'

export const useIsMobile = (breakpoint = 768): boolean => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    useLayoutEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowWidth < breakpoint
}
