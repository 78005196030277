/* Code Quality: Not audited */

import React from 'react'
import Select, { components } from 'react-select'

import { useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Checkbox from './Checkbox'
import Icon from './Icon'

const StyledLabel = styled.label`
    width: 80%;
    display: inline-block;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'wrap')};
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin-left: 5px;
`

const CheckboxDropdown = (props) => {
    const theme = useTheme()
    const style = props.style ? props.style : {}
    const { margin, width, flex } = style
    const fontSize = props.fontSize ? props.fontSize : theme.fontSize

    const customStyles = {
        container: (provided) => ({
            ...provided,
            margin: margin || '',
            flex: flex || 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            flexWrap: 'nowrap',
        }),
        multiValue: (provided) => ({
            ...provided,
            minWidth: '40px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize,
            backgroundColor:
                state.isFocused && !props.noHighlights
                    ? theme.elementHighlightColor
                    : theme.inputBackgroundColor,
            color: props.color ? props.color : theme.fontColor,
            padding: props.padding ? `${props.padding} 8px` : '8px',
        }),
        placeholder: () => ({
            color: props.color ? props.color : theme.fontColor,
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontSize,
            color: props.color ? props.color : theme.fontColor,
            textAlign: 'left',
        }),
        control: (provided) => ({
            ...provided,
            background: theme.input,
            fontSize,
            borderRadius: theme.elementBorderRadius,
            border: props.border
                ? props.border
                : `${theme.dividerThickness}px solid ${theme.outlineColor}`,
            outline: 'none',
            color: props.color ? props.color : theme.fontColor,
            boxShadow: 'none',
            minWidth: props.width ? props.width : width || '150px',
            minHeight: 'auto',
            '&:hover': {
                borderColor: theme.outlineColor,
            },
            ...props.controlStyle,
            display: props.hideControl ? 'none' : 'flex',
        }),
        menu: (provided) => ({
            ...provided,
            outline: 'none',
            boxShadow: 'none',
            background: 'white',
            border: props.border
                ? props.border
                : `${theme.dividerThickness}px solid ${theme.outlineColor}`,
            margin: 0,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        ...props.additionalStyles,
    }

    let selected = null
    if (props.value && props.options) {
        if (props.isMulti) {
            selected = []
            props.options.forEach((option) => {
                if (props.value.includes(option.value)) selected.push(option)
            })
        } else {
            if (props.returnObject) {
                selected = props.value
            }
            props.options.forEach((option) => {
                if (option.value === props.value) selected = option
            })
        }
    }

    // If we selected an option then unpack its value and pass to onChange
    // Otherwise we cleared the select box, so pass empty to onChange
    // In the multi-select case we receive an array of options and pass out an array of unpacked values.
    let onChange
    if (props.isMulti) {
        onChange = (optionList) => {
            if (optionList) {
                const optionValues = optionList.map((option) =>
                    props.returnObject ? option : option.value
                )
                return props.onChange && props.onChange(optionValues)
            } else {
                return props.onChange && props.onChange([])
            }
        }
    } else {
        onChange = (option) =>
            option
                ? props.onChange && props.onChange(props.returnObject ? option : option.value)
                : props.onChange && props.onChange(null)
    }

    const selectRef = React.createRef()

    function getLength(options) {
        return options.reduce((acc, curr) => {
            if (curr.options) return acc + getLength(curr.options)
            return acc + 1
        }, 0)
    }

    const Option = (props) => (
        <div>
            <components.Option {...props} style={{ display: 'flex' }}>
                {props.isMulti && (
                    <Checkbox
                        isChecked={props.isSelected}
                        isDisabled={props.isDisabled}
                        onChange={() => null}
                        verticalAlign="middle"
                    ></Checkbox>
                )}
                <StyledLabel title={props.data.tooltip}>
                    {props.data?.icon && (
                        <Icon icon={props.data?.icon} size="sm" display="inline" mr={1} />
                    )}
                    {props.label}
                </StyledLabel>
            </components.Option>
        </div>
    )

    const { placeholder } = props

    const ValueContainer = (props) => {
        const optionsLength = getLength(props.getValue() || [])
        return (
            <components.ValueContainer {...props}>
                <span>{optionsLength ? `${optionsLength} selected` : placeholder}</span>
            </components.ValueContainer>
        )
    }

    return (
        <Select
            ref={selectRef}
            {...props}
            value={selected}
            onChange={onChange}
            options={props.options}
            ignoreCase
            multi={props.isMulti}
            removeSelected={false}
            styles={customStyles}
            closeMenuOnSelect={!props.isMulti}
            hideSelectedOptions={false}
            isClearable={props.isClearable === false ? false : true}
            components={{ Option, ValueContainer }}
        />
    )
}

CheckboxDropdown.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
}

CheckboxDropdown.defaultProps = {
    value: undefined,
    onChange: undefined,
    isMulti: false,
}

export default CheckboxDropdown
