// @ts-strict-ignore
import * as React from 'react'

import { CustomCell } from '@glideapps/glide-data-grid'
import styled from 'styled-components'

import MultiRecordLinkAttribute from 'v2/ui/components/Attribute/MultiRecordLinkAttribute'
import SingleRecordLinkAttribute from 'v2/ui/components/Attribute/SingleRecordLinkAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import EditorWrapper from '../components/EditorWrapper'
import { drawRoundedValues } from '../drawUtils'
import type { CellData, CustomCellRenderer } from '../types'

export const RECORD_LINK_CELL = 'record-link-cell'

export type RecordLinkCellData = CellData & {
    readonly kind: typeof RECORD_LINK_CELL
    readonly targetObjectId: string
    value: string[] | string | undefined | null
    readonly bypassPreviewAndImpersonation?: boolean
    readonly contextRecord: RecordDto
}

export type RecordLinkCell = CustomCell<RecordLinkCellData>

const EditorWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 0px;
    color: ${(p) => p.theme.textDark};
    box-sizing: border-box;
`

const renderer: CustomCellRenderer<RecordLinkCell> = {
    isMatch: (c): c is RecordLinkCell => (c.data as any).kind === RECORD_LINK_CELL,
    draw: (args, cell) => {
        let { provider, targetObjectId, value } = cell.data

        const getRecordBySid = (sid: string) => {
            return provider.getRecord(targetObjectId, sid)
        }
        const getRecordText = (item: RecordDto) => {
            return item?._primary || ''
        }

        const values: string[] = []
        if (Array.isArray(value)) {
            values.push(...value)
        } else if (typeof value === 'string') {
            values.push(value)
        }

        const valuesToDraw = values
            .map((sid) => getRecordBySid(sid))
            .filter((record) => record) // Filter out deleted records
            .map((validRecord) => ({ label: getRecordText(validRecord) }))

        drawRoundedValues(args, valuesToDraw)

        return true
    },
    provideEditor: (cell) => {
        const Component =
            cell.data.field.type === 'multi_lookup'
                ? MultiRecordLinkAttribute
                : SingleRecordLinkAttribute

        // eslint-disable-next-line react/display-name
        const CustomEditor = (props) => {
            const { onChange, value: cell } = props
            const { readonly, provider, contextRecord } = cell.data as RecordLinkCellData

            const handleChange = (value: any) => {
                // update the cell with the new data value
                const newCell = { ...cell, data: { ...cell.data, value } }
                onChange(newCell)
            }

            const handleRecordsSelected = (records: RecordDto[]) => {
                // When records are selected in the dropdown, push them over to the cell provider
                // so these records are available for rendering the selected cell value
                if (provider.onRecordsSelected && records) {
                    provider.onRecordsSelected(records)
                }
            }
            return (
                <EditorWrapper>
                    <EditorWrap className={readonly ? 'readonly' : ''}>
                        {/* @ts-ignore */}
                        <Component
                            field={cell.data.field}
                            contextRecord={contextRecord}
                            mode={modes.editing}
                            usePortal
                            onChange={handleChange}
                            inDataGrid
                            bypassPreviewAndImpersonation={cell.data.bypassPreviewAndImpersonation}
                            controlStyle={{
                                border: '1px solid white',
                                '&:hover': {
                                    border: '1px solid white',
                                },
                            }}
                            onRecordsSelected={handleRecordsSelected}
                        >
                            {cell.data.value}
                        </Component>
                    </EditorWrap>
                </EditorWrapper>
            )
        }

        return {
            editor: CustomEditor,
            disablePadding: true,
            deletedValue: (toDelete: RecordLinkCell) => ({
                ...toDelete,
                copyData: '',
                data: {
                    ...toDelete.data,
                    value: null,
                },
            }),
        }
    },
}

export default renderer
