import React, { VFC } from 'react'

import { BoxProps, useTheme } from '@chakra-ui/react'

import { assertIsDefined } from 'data/utils/ts_utils'

import { Box, Button } from 'v2/ui'

type Props = BoxProps & {
    view: { protectedFeature: {}; title: string; icon: string; isReleased?: boolean }
    isSelected: boolean
    onClick?: () => void
}

const LayoutButton: VFC<Props> = ({ view, isSelected, onClick }) => {
    assertIsDefined(view.icon)
    const Icon: any = view.icon
    const { colors } = useTheme()
    return (
        <Button
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: 'normal',
                fontSize: '12px',
                background: isSelected
                    ? colors.userInterface.accent[1000]
                    : colors.userInterface.neutral[0],
                color: isSelected
                    ? colors.userInterface.neutral[0]
                    : colors.userInterface.neutral[1000],
            }}
            variant="Secondary"
            width="130px"
            height="30px"
            size="small"
            isSelected={isSelected}
            onClick={onClick}
            data-testid={`${view.title}-layout-button`}
            px={2}
        >
            <Box as="span" mr={2}>
                <Icon
                    width="14px"
                    height="14px"
                    fill={
                        isSelected
                            ? colors.userInterface.neutral[0]
                            : colors.userInterface.accent[1000]
                    }
                />
            </Box>
            {view.title}
        </Button>
    )
}

export default LayoutButton
