import React from 'react'

type SolidArrowUpProps = {
    color: string
    size?: number
}
const SolidArrowUp: React.VFC<SolidArrowUpProps> = ({ color, size = 16 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.75 13.5V4.606l3.27 2.723a.75.75 0 1 0 .96-1.155l-4.5-3.75a.893.893 0 0 0-.113-.068c-.038 0-.06 0-.098-.052A.75.75 0 0 0 8 2.25a.75.75 0 0 0-.27.053c-.037 0-.06 0-.097.052a.892.892 0 0 0-.113.068l-4.5 3.75a.75.75 0 0 0 .13 1.242.75.75 0 0 0 .83-.087l3.27-2.723v8.896a.75.75 0 0 0 1.5 0z"
                fill={color}
            />
        </svg>
    )
}

export default SolidArrowUp
