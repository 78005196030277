import { getUrl, Urls } from 'app/UrlService'

type AttributeMap = {
    [T in Block as T['type']]: T['config']['attributes']
}

export function makeDummyAttributes<K extends keyof AttributeMap>(blockType: K): AttributeMap[K] {
    switch (blockType) {
        case 'banner':
            return makeBannerDummyAttributes() as AttributeMap[K]
        case 'iframe':
            return makeEmbedDummyAttributes() as AttributeMap[K]
        case 'text':
            return makeTextDummyAttributes() as AttributeMap[K]
        default:
            return {} as AttributeMap[K]
    }
}

export function makeBannerDummyAttributes(): AttributeMap['banner'] {
    return {
        url: 'https://stackerhq.com',
        label: 'Button label',
        title: 'Banner widget header',
        center: false,
        description: {
            ops: [
                {
                    insert: 'The Banner widget enables you to provide a call to action on a page. You can add a header or if you prefer not to just leave the field blank. There is a free text field to provide some descriptive text which is left aligned by default, or you can centre your content. Lastly, you can add a button to link out to a URL of your choice.\n',
                },
            ],
        },
        openInNewTab: true,
    }
}

export function makeEmbedDummyAttributes(): AttributeMap['iframe'] {
    return {
        url: 'https://www.youtube.com/embed/oKqDV00dyXU',
        type: 'url',
        title: '',
        height: '600px',
    }
}

export function makeTextDummyAttributes(): AttributeMap['text'] {
    return {
        content: {
            ops: [
                {
                    insert: 'You can select from two header sizes',
                },
                {
                    insert: '\n',
                    attributes: {
                        header: 3,
                    },
                },
                {
                    insert: '\nThe text block comes loaded with Rich Text functionality to bring your content to life. From the menu bar, you can access a number of formatting capabilities, including the ability to ',
                },
                {
                    insert: 'add hyperlinks',
                    attributes: {
                        link: 'http://www.stackerhq.com',
                    },
                },
                {
                    insert: '. As you would expect, you can add ',
                },
                {
                    insert: 'bold,',
                    attributes: {
                        bold: true,
                    },
                },
                {
                    insert: ' ',
                },
                {
                    insert: 'italics,',
                    attributes: {
                        italic: true,
                    },
                },
                {
                    insert: ' and ',
                },
                {
                    insert: 'strikethrough',
                    attributes: {
                        strike: true,
                    },
                },
                {
                    insert: '.\n\n',
                },
                {
                    insert: 'You can also make sentences and paragraphs into quotations to highlight particular pieces of content.',
                    attributes: {
                        italic: true,
                    },
                },
                {
                    insert: '\n',
                    attributes: {
                        blockquote: true,
                    },
                },
                {
                    insert: '\nAdding bullet points is a ',
                },
                {
                    insert: 'breeze',
                    attributes: {
                        bold: true,
                    },
                },
                {
                    insert: '\nBullet 1',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: 'Bullet 2',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: 'Bullet 3',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: '\nYou can add numbered lists too:\nFirst',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: 'Second',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: 'Third',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: '\nLastly, you can also embed images by simply entering an image URL like the example below taken from our website. \n\n',
                },
                {
                    insert: {
                        image: 'https://assets-global.website-files.com/60798d77be76070f831d5989/63404df52892be2ea39dd1a7_glide-alternatives.png',
                    },
                    attributes: {
                        alt: 'https://assets-global.website-files.com/60798d77be76070f831d5989/63404df52892be2ea39dd1a7_glide-alternatives.png',
                    },
                },
                {
                    insert: '\n',
                },
            ],
        },
    }
}

export function makeCalloutDummyAttributes(): AttributeMap['callout'] {
    return {
        style: 'brand',
        subtitle:
            'The Callout widget can be used to draw attention to organization updates, changes to the application or any other important information you wish to highlight to users of an application.',
        title: 'Callout widget header',
    }
}

export function makeQuickLinksDummyAttributes(
    baseUrl = window.location.origin
): AttributeMap['quicklinks'] {
    const homeUrl = `${baseUrl}${getUrl(Urls.Home)}`

    return {
        items: [
            {
                title: 'Homepage',
                subtitle: "A quick link for navigating back to your app's homepage",
                icon: 'fa-home',
                link: {
                    type: 'external',
                    label: 'Take me home',
                    url: homeUrl,
                },
                order: 0,
            },
            {
                title: 'Stacker Help Centre',
                subtitle: 'Learn more about Stacker',
                icon: 'fa-question-circle',
                link: {
                    type: 'external',
                    label: 'View',
                    url: 'https://support.stackerhq.com/hc/en-us',
                },
                order: 2,
            },
            {
                title: 'Request feature',
                subtitle: 'Request a new feature or improvement to an existing feature',
                icon: 'fa-inbox',
                link: {
                    type: 'external',
                    label: 'Submit',
                    url: 'https://feedback.stackerhq.com/feature-requests',
                },
                order: 3,
            },
        ],
    }
}

export function makeBlankPageDummyBlocks(): Block[] {
    return [
        {
            id: 'page',
            type: 'container',
            config: {
                style: {
                    default: {
                        width: '100%',
                        height: '100%',
                        margin: '0px auto',
                        flexGrow: 0,
                        justifyContent: 'flex-start',
                    },
                },
                locked: true,
                broadcast: [],
                attributes: {
                    label: 'Page',
                    direction: 'column',
                },
            },
            childBlocks: [
                {
                    id: 'center_content',
                    type: 'container',
                    config: {
                        style: {
                            default: {
                                width: '100%',
                                flexGrow: 0,
                                maxWidth: '1000px',
                                marginTop: '20px',
                                minHeight: '50px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '0px',
                            },
                        },
                        locked: true,
                        broadcast: [],
                        attributes: {
                            label: 'Center Content',
                            direction: 'column',
                        },
                    },
                    childBlocks: [],
                },
            ],
        },
    ]
}

export function makeBannerDummyBlock(): BannerBlock {
    return {
        id: 'banner_0',
        type: 'banner',
        config: {
            style: {
                default: {
                    width: '100%',
                },
            },
            broadcast: [],
            attributes: makeBannerDummyAttributes(),
        },
        local_id: 'banner_0',
        childBlocks: [],
    }
}

export function makeEmbedDummyBlock(): EmbedBlock {
    return {
        id: 'iframe_0',
        type: 'iframe',
        config: {
            style: {
                default: {
                    width: '100%',
                    boxShadow: 'none',
                    borderRadius: '5px',
                },
            },
            broadcast: [],
            attributes: makeEmbedDummyAttributes(),
        },
        local_id: 'iframe_0',
        childBlocks: [],
    }
}

export function makeTextDummyBlock(): TextBlock {
    return {
        id: 'text_0',
        type: 'text',
        config: {
            style: {
                default: {
                    width: '100%',
                },
            },
            broadcast: [],
            attributes: makeTextDummyAttributes(),
        },
        local_id: 'text_0',
        childBlocks: [],
    }
}

export function makeCalloutDummyBlock(): CalloutBlock {
    return {
        id: 'callout_0',
        type: 'callout',
        config: {
            style: {
                default: {
                    width: '100%',
                },
            },
            broadcast: [],
            attributes: makeCalloutDummyAttributes(),
        },
        local_id: 'callout_0',
        childBlocks: [],
    }
}

export function makeQuickLinksDummyBlock(): QuickLinksBlock {
    return {
        id: 'quicklinks_0',
        type: 'quicklinks',
        config: {
            style: {
                default: {
                    width: '100%',
                },
            },
            broadcast: [],
            attributes: makeQuickLinksDummyAttributes(),
        },
        local_id: 'quicklinks_0',
        childBlocks: [],
    }
}
