import React, { useCallback } from 'react'

import { PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

import Box from 'v2/ui/components/Box'
import Button from 'v2/ui/components/Button'
import ConditionalWrapper from 'v2/ui/components/ConditionalWrapper'
import Flex from 'v2/ui/components/Flex'
import Icon from 'v2/ui/components/Icon'

const FilterButton = ({
    dataTestId,
    onChange,
    buttonText,
    label,
    buttonRef,
    hasValue,
    children,
    icon,
    onClick,
}) => {
    const clearFilter = useCallback(() => onChange(undefined), [onChange])

    return (
        <>
            <Flex position="relative" flexWrap="nowrap" mb={2}>
                <ConditionalWrapper
                    wrapper={(children) => <PopoverTrigger>{children}</PopoverTrigger>}
                    condition={children}
                >
                    <Button
                        buttonSize="filter"
                        variant={!hasValue && 'filterButton'}
                        label={label}
                        data-testid={dataTestId}
                        ref={buttonRef}
                        hasValue={hasValue}
                        onClick={onClick}
                    >
                        <>
                            <Icon
                                icon={icon}
                                color={hasValue ? 'white' : 'filters.button.icon'}
                                fontSize="xs"
                                mr={2}
                            />
                            <Box whiteSpace="normal">{buttonText}</Box>
                            {hasValue && (
                                <Box pl={['button.sm.px', null, null, 'button.sm.pxLg']} />
                            )}
                        </>
                    </Button>
                </ConditionalWrapper>
                {hasValue && (
                    <Button
                        buttonSize="filter"
                        onClick={clearFilter}
                        icon="x"
                        variant="clearWhite"
                        position="absolute"
                        right={0}
                    />
                )}
            </Flex>
            {children && (
                <PopoverContent
                    placement="bottom-start"
                    minW={2}
                    p={2}
                    zIndex={2}
                    maxHeight="300px"
                    minWidth="200px"
                >
                    <PopoverArrow />
                    {children}
                </PopoverContent>
            )}
        </>
    )
}

export default React.memo(FilterButton)
