import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export const retestAirtableConnection = (connectionSid: string): Promise<Record<string, any>> => {
    return fetchWithAuth('check-airtable-connection/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            connection_sid: connectionSid,
        }),
    }).then((response) => {
        return response.json()
    })
}

export const fetchWebhookInfo = (connectionSid: string): Promise<Record<string, any>> => {
    return fetchWithAuth(`data-connections/${connectionSid}/get_webhook_status`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }).then((response) => {
        return response.json()
    })
}
