/* Code Quality: Not audited */

import React, { useCallback, useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import { useCreateView, useDeleteView, useUpdateView, useViews } from 'data/hooks/views'

export default function WithViews({ children, allStacks }) {
    const { data } = useViews()
    const { selectedStack } = useContext(AppContext)
    const views = data || []
    const filteredData = useMemo(
        () => views.filter((item) => allStacks || item.stack_id === selectedStack?._sid),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [views, selectedStack]
    )

    const { mutateAsync: updateView } = useUpdateView()

    const { mutateAsync: createView } = useCreateView()
    const { mutateAsync: deleteView } = useDeleteView()

    const handleChange = useCallback(
        (...args) => {
            if (args.length === 2) return updateView({ id: args[0], patch: args[1] })
            return updateView(args[0])
        },
        [updateView]
    )

    return children({
        views: filteredData,
        onChange: handleChange,
        updateView,
        createView: createView,
        removeView: deleteView,
    })
}

export function withViews(Child) {
    return (props) => <WithViews>{(viewProps) => <Child {...viewProps} {...props} />}</WithViews>
}
