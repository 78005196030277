// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

//@ts-ignore
import { RadioButton, Text } from 'v2/ui'

//@ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const FiltersContainer = styled.div`
    border: 1px solid ${V4DesignSystem.colors.gray[100]};
    border-radius: 5px;
    margin-top: 8px;
    width: 100%;
`

const Label = ({ text }) => {
    return (
        <div
            style={{
                fontSize: 14,
                fontWeight: 600,
                marginBottom: 4,
            }}
        >
            {text}
        </div>
    )
}

type RadioOptionProps = {
    value: any
    alternativeValues?: any[]
    label: string
    text: string
    content?: React.FC | JSX.Element
    disabled?: boolean
}

export type RadioWithContentFormProps = {
    onChange: (arg0: any) => {}
    value: boolean
    options: RadioOptionProps[]
    disabled: boolean
}

const RadioWithContentForm: React.FC<RadioWithContentFormProps> = ({
    onChange,
    value,
    options,
    disabled,
}) => {
    const onRadioChange = (radioIndex) => {
        onChange(options[radioIndex].value)
    }

    return (
        <FiltersContainer>
            {options.map((option, index) => {
                const optionSelected = !!(
                    value === option.value ||
                    (option.alternativeValues && option.alternativeValues.includes(value))
                )

                return (
                    <div
                        key={index}
                        style={{
                            padding: '0.75rem 1rem',
                            borderBottom: `1px solid ${V4DesignSystem.colors.gray[100]}`,
                            background: optionSelected ? V4DesignSystem.colors.gray[10] : 'none',
                            marginBottom: index === options.length - 1 ? '-1px' : '0',
                        }}
                    >
                        <RadioButton
                            checkedRadioColor={V4DesignSystem.colors.stacker}
                            size="lg"
                            isChecked={optionSelected}
                            width="100%"
                            onChange={() => {
                                onRadioChange(index)
                            }}
                            disabled={disabled || option.disabled}
                        >
                            <Label text={option.label} />
                            <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                                {option.text}
                            </Text>
                        </RadioButton>
                        {option.content && optionSelected && (
                            <div
                                style={{
                                    marginTop: '0.75rem',
                                    paddingTop: '0.75rem',
                                    borderTop: `1px solid ${V4DesignSystem.colors.gray[100]}`,
                                }}
                            >
                                {option.content}
                            </div>
                        )}
                    </div>
                )
            })}
        </FiltersContainer>
    )
}

export default RadioWithContentForm
