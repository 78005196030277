import { DISPLAY_TYPES } from 'v2/views/List/DisplayTypes'

export const DATA_LIST_DISPLAY_TYPES: Partial<typeof DISPLAY_TYPES> = {
    table: DISPLAY_TYPES.table,
    card: DISPLAY_TYPES.card,
    rows: DISPLAY_TYPES.rows,
}

export function getPageSizesVariants(display?: string): number[] {
    switch (display) {
        case 'card':
        case 'rows':
        case 'table':
            return [3, 6, 9]
        default:
            return [3, 5, 10]
    }
}

export function getDefaultPageSize(_display?: string): number {
    return 3
}
