import React from 'react'

import { Frame } from 'features/workspace/WorkspaceSettingsModalUi'

import CustomPlanPicker from './CustomPlanPicker'

export default function WorkspaceUpgradeSettings({ children }) {
    return (
        <Frame title="Upgrade Plan" flex={1}>
            <CustomPlanPicker />
            {children}
        </Frame>
    )
}
