/* Code Quality: Not audited */

import React, { Component } from 'react'

import { evaluateFormulas } from '../settings/formulas'
export const ATTR_NAME = 'behavior_visibility'

class BlockVisibility extends Component {
    render() {
        const value = this.props.block.config.attributes[ATTR_NAME]
        if (!value) return this.props.children

        if (value.length !== 3) return this.props.children

        const evaluateF = evaluateFormulas(this.props.context)

        const v1 = evaluateF(value[0]) || value[0]
        const v2 = evaluateF(value[2]) || value[2]
        const method = value[1]
        if (compare(v1, v2, method)) return this.props.children

        return <div style={{ display: 'none' }}>{this.props.children}</div>
    }
}

function compare(value1, value2, compareMethod) {
    switch (compareMethod) {
        case 'eq':
            return value1 === value2
        case 'neq':
            return value1 !== value2
        case 'lt':
            return value1 < value2
        case 'lte':
            return value1 <= value2
        case 'gt':
            return value1 > value2
        case 'gte':
            return value1 >= value2
        case 'contains':
            return String(value1).includes(value2)
        case 'startsWith':
            return String(value1).startsWith(value2)
        case 'endsWith':
            return String(value1).endsWith(value2)
        default:
            return true
    }
}

export default BlockVisibility
