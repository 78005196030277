import shortid from 'shortid'

export function generateId(items: { id?: string }[]) {
    const ids = items.reduce((agg, curr) => {
        if (curr.id) agg.add(curr.id)

        return agg
    }, new Set<string>())

    let id = ''
    do {
        id = shortid.generate()
    } while (ids.has(id))

    return id
}
