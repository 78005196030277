// @ts-strict-ignore
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { useAppContext } from 'app/AppContext'
import { AppUserContext } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { userActions } from 'data/api/userApi'
import {
    setPreviousPreviewingRoleId,
    setPreviousPreviewingUser,
} from 'features/auth/utils/roleUtils'

const HOME = 'home'
type ImpersonateUserProps = {
    user: { _sid: string; type?: string }
    onSelected?: () => void
    redirectTo?: string
}

type ImpersonateRoleProps = {
    roleId: string
    onSelected?: () => void
    redirectTo?: string
}

const useImpersonation = () => {
    const { isImpersonating, previewAsUser, previewAsRole } = useContext(AppUserContext)
    const homeUrl = getUrl(Urls.Home, null)
    const dispatch = useDispatch()
    const boundUserActions = useMemo(() => bindActionCreators(userActions, dispatch), [dispatch])
    const history = useHistory()
    const { selectedStack } = useAppContext()

    const defaultRoleSid = selectedStack?.options.roles__default_user_role

    const stopImpersonating = useCallback(
        async (options: { redirectTo?: string } = {}) => {
            let { redirectTo } = options

            // clean up current values
            previewAsUser(null)

            if (redirectTo === HOME) redirectTo = homeUrl

            if (isImpersonating) {
                await boundUserActions.stopImpersonating()
                if (redirectTo) {
                    history.push(redirectTo)
                }
            }
        },
        [boundUserActions, history, homeUrl, isImpersonating, previewAsUser]
    )

    const impersonateUser = useCallback(
        ({ user, onSelected, redirectTo }: ImpersonateUserProps): Promise<void> => {
            if (redirectTo === HOME) redirectTo = homeUrl
            return (
                boundUserActions
                    .impersonate(user._sid, user.type === 'customer' ? true : null)
                    // @ts-ignore
                    .then((response) => {
                        if (onSelected) onSelected()
                        // If the user is not found, we preview the default role
                        if (response.status === 404) {
                            previewAsRole(defaultRoleSid)
                            previewAsUser(null)
                            setPreviousPreviewingUser(null)
                        } else {
                            previewAsUser(response)
                            setPreviousPreviewingRoleId(null)
                            setPreviousPreviewingUser(user)
                        }
                        if (redirectTo) {
                            history.push(redirectTo)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            )
        },
        [boundUserActions, history, homeUrl, previewAsUser, previewAsRole, defaultRoleSid]
    )

    const impersonateRole = useCallback(
        ({ roleId, onSelected, redirectTo }: ImpersonateRoleProps) => {
            if (redirectTo === HOME) redirectTo = homeUrl
            return stopImpersonating().then(() => {
                if (onSelected) onSelected()
                previewAsRole(roleId)
                setPreviousPreviewingRoleId(roleId)
                setPreviousPreviewingUser(null)
                if (redirectTo) {
                    history.push(redirectTo)
                }
            })
        },
        [history, homeUrl, previewAsRole, stopImpersonating]
    )

    return {
        stopImpersonating,
        impersonateUser,
        impersonateRole,
    }
}

export default useImpersonation

type ImpersonationProps = {
    stopImpersonating: () => void
    impersonateUser: () => void
    impersonateRole: () => void
}

// for use in class components
export const withImpersonation =
    <P extends ImpersonationProps>(Child: React.ComponentType<P>) =>
    (props: Omit<P, keyof ImpersonationProps>) => {
        const impersonationProps = useImpersonation()

        return <Child {...impersonationProps} {...(props as P)} />
    }
