import { queryClient } from '../_helpers'
import { reloadMetadataAfterObjectChange } from '../refreshData'

import { getCachedObjects, getQueryKey } from './objectOperations'
/**
 * Refetches objects and returns a promise that resolves when the query is done
 */
export const refetchObjects = async () => {
    await queryClient.refetchQueries(getQueryKey()).then(() => {
        reloadMetadataAfterObjectChange()
    })

    return getCachedObjects()
}
