import React from 'react'

type SolidArrowUpProps = {
    color: string
    size?: number
}
const SolidArrowDown: React.VFC<SolidArrowUpProps> = ({ color, size = 16 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.25 3v8.896L3.98 9.172a.75.75 0 1 0-.96 1.155l4.5 3.75a.907.907 0 0 0 .113.068c.037 0 .06 0 .097.052a.75.75 0 0 0 .27.053.75.75 0 0 0 .27-.053c.038 0 .06 0 .098-.053a.906.906 0 0 0 .112-.067l4.5-3.75a.752.752 0 0 0-.702-1.295.749.749 0 0 0-.258.14l-3.27 2.723V3a.75.75 0 0 0-1.5 0z"
                fill={color}
            />
        </svg>
    )
}

export default SolidArrowDown
