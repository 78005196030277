export const MAX_COLUMNS = 8
export const MAX_CARDS = 20

type KanbanStatus = {
    value: string
    label?: string
    isEmpty?: boolean
    color?: string
}

export const getDisplayedStatuses = (field: FieldDto, statusColumns: (string | null)[]) => {
    if (!field && (!statusColumns || statusColumns.includes(null))) return null

    const statuses = field?.options?.options && [...field.options.options]

    // If the user asked us to include a column for records with no status value,
    // do so now and insert at the top of the list
    if (statuses && (!statusColumns || statusColumns.includes(null))) {
        statuses.unshift({
            label: 'None',
            value: '',
        })
    }

    // This are the statuses the user has optied to display in this view.
    // Note we limit the number of visible columns regardless of what the user
    // has selected so they don't get in a visually broken state.
    const displayedStatuses =
        statuses &&
        statuses
            .filter(
                (x: KanbanStatus) =>
                    !statusColumns ||
                    statusColumns.length === 0 ||
                    statusColumns.includes(x.value) ||
                    (statusColumns.includes(null) && x.value.length === 0)
            )
            .slice(0, MAX_COLUMNS)

    return displayedStatuses
}

/*
    This routine returns a filter object that fetches only
    the cards that should be displayed on the board
*/
export const getKanbanFilters = (object: ObjectDto, viewOptions?: { [keyof: string]: any }) => {
    const statusFieldId = viewOptions && viewOptions.statusField
    const statusColumns = viewOptions && viewOptions.statusColumns

    if (!object || !viewOptions) return

    const field = object.fields.find((x) => x._sid === statusFieldId)
    const displayedStatuses = getDisplayedStatuses(field, statusColumns!)

    if (displayedStatuses) {
        let filterValues = displayedStatuses.map((x: KanbanStatus) => x.value)
        let filterOperator = 'oneOf'

        // If we are including the None column (cards with no status value)
        if (displayedStatuses.find((x: KanbanStatus) => x.value === null || x.value === '')) {
            // the filter operation changes to noneOf the columns
            // not on the board, so that cards with no fied value are included
            filterValues =
                field?.options?.options
                    .filter((x: KanbanStatus) => !displayedStatuses.includes(x))
                    .map((x: KanbanStatus) => x.value) ?? []
            filterOperator = 'noneOf'
        }

        // Do not filter if the value is empty
        // but do send through the field name for includefields
        if (!filterValues.length) {
            return [
                {
                    field: { api_name: field.api_name },
                    options: {},
                },
            ]
        }

        return [
            {
                field: { api_name: field.api_name },
                options: {
                    value: filterValues,
                    option: filterOperator,
                },
            },
        ]
    }

    return
}
