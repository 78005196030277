import React, { FC } from 'react'

import { Moment } from 'moment'

import { relativeFiltersLabelsByValue } from 'features/records/components/InlineFilterItem'
import { formatDateAsString } from 'utils/dateUtils'

import DateAttribute from 'v2/ui/components/Attribute/DateAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import type { AdminFilterProps } from './types'

export const FilterDate: FC<AdminFilterProps> = ({ value, field, onChange, editMode = true }) => {
    // if we're not in edit mode, then just display the selected values
    // not the input control
    //
    const showTime = field?.type === 'datetime'

    if (!editMode) {
        // Check if it's a relative filter and return the label if it is
        return (
            <>
                {relativeFiltersLabelsByValue[value as keyof typeof relativeFiltersLabelsByValue] ??
                    formatDateAsString(value, field, showTime)}
            </>
        )
    }

    return (
        // @ts-expect-error Children is not explicitely defined and DateAttribute is in JSX
        <DateAttribute
            mode={modes.editing}
            field={field}
            showTime={showTime}
            onChange={(event: string | Moment) => {
                // Filter date is currently only used by 'date' field.types,
                // but just in case we want to add this to datetime too
                // we extract the time if it is date only.
                //
                let dateString = event
                if (typeof event !== 'string') {
                    dateString = showTime ? event.toISOString() : event.format('YYYY-MM-DD')
                }
                onChange?.(dateString as string)
            }}
        >
            {value}
        </DateAttribute>
    )
}
