import { useCallback, useMemo, useReducer } from 'react'

type Patch<T> = Partial<T>

type State<T> = {
    patch: Patch<T>
    isPatched: boolean
}

type ApplyPatchAction<T> = {
    type: 'apply-patch'
    payload: Patch<T>
}

type CancelPatchAction = {
    type: 'cancel-patch'
}

type Action<T> = ApplyPatchAction<T> | CancelPatchAction

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
        case 'apply-patch':
            return {
                isPatched: true,
                patch: { ...state.patch, ...action.payload },
            }

        case 'cancel-patch':
            return {
                isPatched: false,
                patch: {},
            }

        default:
            return state
    }
}

function getInitialState<T>(): State<T> {
    return {
        patch: {},
        isPatched: false,
    }
}

export function usePatchedData<T>(originalData: T) {
    const [state, dispatch] = useReducer(reducer, getInitialState<T>())

    const applyPatch = useCallback(
        (newPatch: Patch<T>) => dispatch({ type: 'apply-patch', payload: newPatch }),
        []
    )

    const cancelPatch = useCallback(() => dispatch({ type: 'cancel-patch' }), [])

    return useMemo(
        () => ({
            isPatched: state.isPatched,
            patchedData: {
                ...originalData,
                ...state.patch,
            },
            applyPatch,
            cancelPatch,
            patch: state.patch,
        }),
        [applyPatch, cancelPatch, originalData, state.isPatched, state.patch]
    )
}
