// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { queryClient, removeStackSpecificQueries } from 'data/hooks/_helpers'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { useStacks } from 'data/hooks/stacks'
import {
    invalidateAppUserQuery,
    resetUserRecordQuery,
    useFetchAppUser,
} from 'data/hooks/users/main'
import {
    getPreviewingRoleId,
    getPreviewingRoleIdFromLocalStorage,
    getPreviewingUser,
    getPreviewingUserFromLocalStorage,
    isRightGranted,
    setPreviewingRoleId,
    setPreviewingRoleIdToLocalStorage,
    setPreviewingUser,
    setPreviewingUserToLocalStorage,
    syncPreviewingStatusFromLocalStorage,
    syncPreviewingStatusToLocalStorage,
} from 'features/auth/utils/roleUtils'
import { getUserRoleId } from 'features/auth/utils/roleUtilsWithStoreDependency'
import { isSupportLoginPermitted } from 'utils/supportLogin'
import useStableState from 'utils/useStableState'

import usePrevious from 'v2/ui/hooks/usePrevious'

import AppContext from './AppContext'
import { AppUserContext, AppUserContextValue, Rights } from './AppUserContext'

/*
    This provider keeps track of the currently authenticated user and
    provides functions for checking roles and rights available to that user
    in the current stack.
*/
export const AppUserContextProvider: React.FC = ({ children }) => {
    const { selectedStack } = useContext(AppContext)
    const isFirstRender = useRef(true)
    const [role, setRole] = useState<RoleDto | null | undefined>()
    const user = useSelector<unknown, UserDto>(
        (state: any) => state.user.user || state.user.studioUser
    )
    const userId = user?._sid
    const previousUserId = usePrevious(userId)
    const studioUser = useSelector<unknown, UserDto>((state: any) => state.user.studioUser)
    const [previewingAsRoleId, setPreviewingAsRoleId] = useState<string | null>(
        getPreviewingRoleIdFromLocalStorage()
    )
    const [authStateKey, setAuthStateKey] = useState(window.performance.now())
    const [previewingAsUser, setPreviewingAsUser] = useState<UserDto | null>(
        getPreviewingUserFromLocalStorage()
    )
    // Load the current studio user object in context of the current selected stack.
    // The back-end will decorate it with some stack-specific context information
    // such as the user's corresponding data record id.
    // (This is a no-op if we're logged in as an end user.)
    const { isLoading: userIsLoading } = useFetchAppUser()

    const { data: roles, isLoading: rolesLoading } = useStackRoles()
    const { data: stacks } = useStacks()

    // Update the user's role when the user, roles, or selectedStack change.
    useEffect(() => {
        if (!selectedStack || !user || !roles?.length) return setRole(null)
        const assignedRole = getUserRoleId()
        // Keep local state in sync with the previewing role coming from
        // sessionStorage
        setPreviewingAsRoleId(getPreviewingRoleId())
        setPreviewingAsUser(getPreviewingUser())
        setRole(roles.find((role) => role._sid === assignedRole))
    }, [user, roles, selectedStack, previewingAsRoleId, previewingAsUser?._sid])

    // Remove stack specific queries when the user id or stack id changes
    useEffect(() => {
        if (!isFirstRender.current) removeStackSpecificQueries()
    }, [selectedStack?._sid, previewingAsUser?._sid, previewingAsRoleId])

    // When the stack changes, make sure we get the latest app user
    // record loaded from the server
    useEffect(() => {
        if (!isFirstRender.current) invalidateAppUserQuery()
    }, [selectedStack?._sid, userId])

    useEffect(() => {
        if (!isFirstRender.current) return resetUserRecordQuery()
    }, [selectedStack?._sid, userId, user])

    useEffect(() => {
        // If the user logs out, remove all queries
        if (!userId && previousUserId) {
            queryClient.removeQueries()
        }
    }, [previousUserId, userId])

    // Returns whether or not the current user has the specified right
    // in the context of the current stack
    const hasRight = useCallback(
        (right) => {
            if (!selectedStack || !user || !role) return false

            const rights = role.options?.rights || []

            return isRightGranted(rights, right)
        },
        [role, selectedStack, user]
    )

    const isImpersonating = Boolean(studioUser && user?._sid !== studioUser?._sid)
    const isAdmin = Boolean(studioUser && !isImpersonating && hasRight(Rights.Admin.Any))
    const [, setSupportLoginPermitted] = useStableState('permitSupportLogin')
    // One time, on load, initialize the previewing_as_role values in session/localstorage
    useEffect(() => {
        syncPreviewingStatusFromLocalStorage()

        // On focus of the browser tab, push the session storage value into local storage,
        // so if the user clicks a link in this tab that opens in a new tab, that new tab
        // will continue the previewing state of the tab they came from.
        window.addEventListener('focus', syncPreviewingStatusToLocalStorage)
    }, [])

    useEffect(() => {
        if (studioUser) setSupportLoginPermitted(isSupportLoginPermitted(studioUser, stacks))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studioUser, stacks])

    // Sets (or clears) the currently selected "preview as" role.
    const previewAs = useCallback((user: UserDto | null, roleId: string | null) => {
        // We update local storage so if the user clicks a link that opens in a new tab
        // they will still be previewing
        setPreviewingRoleIdToLocalStorage(roleId || '')
        setPreviewingUserToLocalStorage(user || '')
        // We update session storage as that actually drives the behavior
        setPreviewingRoleId(roleId || '')
        setPreviewingUser(user || '')

        resetUserRecordQuery()
        invalidateAppUserQuery()
        // And we update the local state object just to trigger a refresh
        setPreviewingAsRoleId(roleId)
        setPreviewingAsUser(user)
        // A unique key every time the auth state changes due to previewing.
        // This lets other components re-compose if appropriate.
        setAuthStateKey(window.performance.now())
    }, [])

    // Sets (or clears) the currently selected "preview as" role.
    const previewAsRole = useCallback(
        (roleId) => {
            previewAs(null, roleId)
        },
        [previewAs]
    )
    // Sets (or clears) the currently selected "preview as" role.
    const previewAsUser = useCallback(
        (user: UserDto | null) => {
            previewAs(user, null)
        },
        [previewAs]
    )

    const contextState: AppUserContextValue = useMemo(() => {
        return {
            user,
            studioUser,
            role: role,
            previewing: Boolean(previewingAsRoleId || previewingAsUser),
            isStudioUser: Boolean(studioUser),
            isLoggedIn: Boolean(user),
            isImpersonating,
            isEditing: user?.isEditing ?? false,
            hasRight: hasRight,
            previewAsRole,
            previewAsUser,
            isLoading: userIsLoading || rolesLoading,
            isLoadingIncludingStack: userIsLoading || rolesLoading || !selectedStack,
            isAdmin,
            authStateKey,
        }
    }, [
        user,
        studioUser,
        role,
        hasRight,
        previewingAsRoleId,
        previewingAsUser,
        previewAsRole,
        previewAsUser,
        userIsLoading,
        isAdmin,
        selectedStack,
        isImpersonating,
        rolesLoading,
        authStateKey,
    ])

    // So that we don't trigger invalidations the first time that the component is mounted
    useEffect(() => {
        isFirstRender.current = false
    }, [])

    return <AppUserContext.Provider value={contextState}>{children}</AppUserContext.Provider>
}
