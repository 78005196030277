import React, { useCallback, useRef, useState } from 'react'
import Truncate from 'react-truncate-markup'

import { Text, TextProps as ChakraTextProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import STYLE_CLASSES from 'v2/ui/styleClasses'

import useIsTextTruncated from '../hooks/useIsTextTruncated'

const BASE_CLASS = STYLE_CLASSES.TEXT

const StyledText = styled(Text)`
    ${variant({
        variants: {
            error: {
                color: 'text.error',
            },
            errorDark: {
                bg: 'red.800',
                color: 'red.100',
            },
            errorLight: {
                bg: 'red.100',
                color: 'text.error',
            },
            success: {
                color: 'text.success',
            },
            tableLabel: {
                fontSize: 'table.label',
                py: 'table.row',
                px: 0,
            },
            cardLabel: {
                fontSize: 'table.label',
                px: 0,
                mb: '2px',
                mt: '20px',
                lineHeight: '1.35em',
                color: 'table.label',
                fontWeight: 'bold',
            },
            chartLabel: {
                fontSize: '24px',
                color: 'grey.800',
                fontWeight: 'bold',
            },
            chartValueNumber: {
                fontSize: '64px',
                color: 'blue.800',
                fontWeight: 'bold',
            },
            cardFieldText: {
                fontSize: ['table.fieldText', null, null, 'table.fieldTextLg'],
                px: 0,
                lineHeight: '1.2em',
                color: 'table.fieldText',
            },
            rowFieldText: {
                fontSize: ['rows.fieldText', null, null, 'rows.fieldTextLg'],
                px: 0,
                lineHeight: '1.2em',
                color: 'rows.fieldText',
            },
            headingFieldText: {
                fontWeight: 'bold',
                fontSize: 'lg',
                mb: 0,
            },
            subheadingFieldText: {
                color: '#777',
                mb: 0,
            },
            kanbanCardLabel: {
                fontSize: 'table.label',
                px: 0,
                mb: '2px',
                mt: 2,
                lineHeight: '1.35em',
                color: 'table.label',
                fontWeight: 'bold',
            },
            kanbanCardFieldText: {
                fontSize: ['table.fieldText', null, null, 'table.fieldText'],
                px: 0,
                lineHeight: '1.2em',
                color: 'table.fieldText',
            },
            tableFieldText: {
                fontSize: ['table.fieldText', null, null, 'table.fieldTextLg'],
                px: 0,
                py: 2,
                color: 'table.fieldText',
            },
            pagination: {
                p: 4,
                fontWeight: 'pagination.text',
                color: 'pagination.text',
                fontSize: ['pagination.text', null, 'pagination.textLg'],
            },
            recordLabel: {
                fontSize: ['cards.recordLabel', null, null, 'cards.recordLabelLg'],
                fontWeight: 'bold',
            },
            recordLabelRow: {
                fontSize: ['cards.recordLabel', null, null, 'cards.recordLabelLg'],
                fontWeight: 'bold',
            },
            kanbanRecordLabel: {
                fontSize: ['kanban.recordLabel', null, null, 'kanban.recordLabel'],
                fontWeight: 'bold',
                minWidth: 0,
            },
            fieldLabel: {
                mb: 2,
                fontSize: 'fields.label',
                color: 'fields.label',
                fontWeight: 'bold',
            },
            headingFieldLabel: {
                mb: 2,
                fontSize: 'fields.label',
                color: 'fields.label',
                fontWeight: 'bold',
            },
            subheadingFieldLabel: {
                mb: 2,
                fontSize: 'fields.label',
                color: 'fields.label',
                fontWeight: 'bold',
            },
            fieldText: {
                fontSize: 'fields.text',
                lineHeight: '1.3rem',
            },
            hint: {
                fontSize: 'fields.label',
                color: 'fields.label',
            },
            emptyState: {
                fontSize: ['emptyState.text', null, null, 'emptyState.textLg'],
            },
            paletteWidgetLabel: {
                fontWeight: 'bold',
                fontSize: [
                    'admin.palette.widget.label',
                    null,
                    null,
                    'admin.palette.widget.labelLg',
                ],
                color: 'admin.palette.widget.label',
            },
            paletteOptionLabel: {
                fontSize: [
                    'admin.palette.widget.label',
                    null,
                    null,
                    'admin.palette.widget.labelLg',
                ],
                color: 'admin.palette.widget.label',
            },
            paletteWidgetDescription: {
                mt: 1,
                fontSize: [
                    'admin.palette.widget.description',
                    null,
                    null,
                    'admin.palette.widget.descriptionLg',
                ],
                color: 'admin.palette.widget.description',
            },
            adminFieldLabel: {
                fontSize: [
                    'admin.palette.sectionLabel',
                    null,
                    null,
                    'admin.palette.sectionLabelLg',
                ],
                color: 'admin.palette.sectionLabel',
                my: [
                    'admin.palette.sectionLabelMargin',
                    null,
                    null,
                    'admin.palette.sectionLabelMarginLg',
                ],
                fontWeight: 'bold',
            },
            paletteSectionLabel: {
                fontSize: [
                    'admin.palette.sectionLabel',
                    null,
                    null,
                    'admin.palette.sectionLabelLg',
                ],
                color: 'admin.palette.sectionLabel',
                my: [
                    'admin.palette.sectionLabelMargin',
                    null,
                    null,
                    'admin.palette.sectionLabelMarginLg',
                ],
                fontWeight: 'bold',
            },
            menuText: {
                fontSize: ['admin.sideMenu.text', null, null, 'admin.sideMenu.textLg'],
                color: 'admin.sideMenu.text',
            },
            modalBody: {
                fontSize: ['modal.body', null, null, 'modal.bodyLg'],
                color: 'modal.body',
            },
            onboardingTitle: {
                fontSize: ['onboarding.title', null, null, 'onboarding.titleLg'],
                color: 'onboarding.title',
                fontWeight: 'bold',
                mb: [4, 4, 6, 6],
            },
            onboardingSubtitle: {
                fontSize: ['onboarding.subtitle', null, null, 'onboarding.subtitleLg'],
                color: 'onboarding.subtitle',
                fontWeight: 'bold',
                mb: [6, 6, 8, 8],
            },
            onboardingInstructionHeading: {
                fontSize: ['onboarding.subtitle', null, null, 'onboarding.subtitleLg'],
                fontWeight: 'bold',
                mb: [2, 2, 4, 4],
            },
            onboardingText: {
                fontSize: ['onboarding.text', null, null, 'onboarding.textLg'],
                color: 'onboarding.text',
                mb: [6, 6, 8, 8],
            },
            onboardingErrorDetail: {
                fontSize: ['onboarding.errorDetails', null, null, 'onboarding.errorDetails'],
                color: 'onboarding.errorDetails',
                mb: [6, 6, 8, 8],
                mt: ['-18px', '-18px', '-24px', '-24px'],
            },

            onboardingHint: {
                fontSize: ['onboarding.text', null, null, 'onboarding.text'],
                color: 'onboarding.text',
            },
            onboardingPrompt: {
                fontSize: ['onboarding.text', null, null, 'onboarding.textLg'],
                color: 'onboarding.text',
                mb: [3, 3, 4, 4],
            },
            widgetContainerDescription: {
                fontSize: 'widgetContainer.description',
                lineHeight: 'text.200.lh',
                whiteSpace: 'pre-line',
            },
            bannerText: {
                fontWeight: 'bold',
                fontSize: ['launchBanner.text', null, null, 'launchBanner.textLg'],
                my: '10px',
            },
            bannerSubText: {
                fontWeight: 'bold',
                fontSize: ['launchBanner.subText', null, null, 'launchBanner.subTextLg'],
                my: '10px',
            },
            bannerTextRegular: {
                fontSize: ['launchBanner.text', null, null, 'launchBanner.textLg'],
                my: '10px',
            },
            feedHeader: {
                fontSize: ['feed.header', null, null, 'feed.header'],
                color: 'grey.500',
            },
            feedTimestamp: {
                fontSize: ['feed.header', null, null, 'feed.header'],
                color: 'grey.400',
            },
            highlightedFieldLabel: {
                mb: 2,
                fontSize: ['highlightedFields.label', null, null, 'highlightedFields.labelLg'],
                color: 'fields.label',
                fontWeight: 'bold',
                textAlign: 'center',
            },
            highlightedFieldText: {
                fontSize: ['highlightedFields.text', null, null, 'highlightedFields.textLg'],
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 0,
            },
            highlightedSmallFieldLabel: {
                mb: 2,
                fontSize: 'highlightedFields.label',
                color: 'fields.label',
                fontWeight: 'bold',
                textAlign: 'center',
            },
            highlightedSmallFieldText: {
                fontSize: 'highlightedFields.text',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 0,
            },
            internalNavLabel: {
                fontSize: 'internal.nav.label',
            },
            internalChildNavLabel: {
                fontSize: 'internal.nav.childLabel',
            },
            internalNavLabelActive: {
                fontSize: 'internal.nav.label',
            },
            internalChildNavLabelActive: {
                fontSize: 'internal.nav.childLabel',
            },
            internalNavAppLabel: {
                fontSize: 'internal.nav.appLabel',
                color: 'internal.nav.label',
                fontWeight: 'bold',
            },
            authenticationHeader: {
                fontSize: 'authentication.header',
                color: 'grey.500',
                fontWeight: 'bold',
            },
            authenticationText: {
                fontSize: 'authentication.text',
                color: 'grey.500',
            },
            listHeaderTitle: {
                fontSize: ['listHeader.title', null, null, 'listHeader.titleLg'],
                fontWeight: 'bold',
            },
            listHeaderDesc: {
                fontSize: ['listHeader.desc', null, null, 'listHeader.descLg'],
                overflow: 'hidden',
            },
            profileCardTitle: {
                fontSize: ['headings.h400lg', null, 'headings.h400', 'headings.h400Lg'],
                mt: 0,
            },
            alert: {
                fontSize: ['sm', null, 'smmd', 'smmd'],
                color: 'alertForeground',
                fontWeight: 'bold',
            },
            modalTitle: {
                fontSize: ['headings.h300lg', null, 'headings.h300', 'headings.h300Lg'],
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontWeight: 'bold',
            },
            modalLargeTitle: {
                fontSize: ['headings.h400lg', null, 'headings.h400', 'headings.h400Lg'],
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                my: 6,
            },
            modalHeading: {
                fontSize: ['headings.h200lg', null, 'headings.h200', 'headings.h200Lg'],
                color: 'gray.500',
                my: 2,
            },
            adminHeading: {
                fontSize: ['headings.h400lg', null, 'headings.h400', 'headings.h400Lg'],
                fontWeight: 'bold',
            },
            modalSubHeading: {
                color: 'gray.500',
                my: 2,
            },
            dataSourceTitle: {
                fontSize: ['headings.h200lg', null, 'headings.h200', 'headings.h200lg'],
                color: 'black',
                fontWeight: 'bold',
            },
            dataSourceSubTitle: {
                fontSize: 'sm',
                color: 'gray.500',
            },
            formHelperText: {
                fontSize: 'form.helperText',
                color: 'form.helperText',
            },
            tableNote: {
                color: 'gray.500',
                py: 2,
            },
            largeButtonTitle: {
                fontSize: '200',
                my: 2,
                fontWeight: 'bold',
            },
            largeButtonDescription: {
                fontSize: '100',
            },
            largeSelectButtonTitle: {
                fontSize: '200',
                color: 'inherit',
                fontWeight: 'bold',
            },
            largeSelectButtonDescription: {
                fontSize: '100',
                color: 'inherit',
            },
            templateCardTitle: {
                fontSize: '400',
                fontWeight: 'bold',
            },
            templateCardDescription: {
                fontSize: '200',
            },
            appSettingsBody: {
                fontSize: '200',
            },
            profile: {
                fontSize: '16px',
            },
            installableFeatureCardTitle: {
                fontSize: '400',
                fontWeight: '600',
            },
            installableFeatureCardDescription: {
                fontSize: '100',
                color: 'gray.500',
            },
            checkbox: {
                fontSize: '12px',
            },
            heading: {
                fontSize: '18px',
                fontWeight: 'bold',
            },
            subHeading: {
                fontSize: '14px',
            },
        },
    })}

    ${variant({
        prop: 'colorMode',
        variants: {
            dark: {
                color: 'white',
            },
            light: {
                color: 'grey.500',
            },
        },
    })}
`

const TRIM_PROPS: Partial<ChakraTextProps> = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}

type TextProps = ChakraTextProps & {
    size?: ChakraTextProps['fontSize']
    maxLines?: number
    trimText?: boolean
    isSelected?: boolean
    htmlFor?: string
}

const StackerText = ({
    size,
    children = null,
    p = 0,
    m = 0,
    maxLines,
    color = 'text.body',
    className = '',
    variant,
    trimText = false,
    ...props
}: TextProps) => {
    const ref = useRef<HTMLParagraphElement>(null)
    // Checks if the text is truncated via css
    const isTextTruncated = useIsTextTruncated(ref)
    const [isContentTruncated, setIsContentTruncated] = useState(isTextTruncated)

    // Called when truncated using react-truncate-markup
    const handleTruncate = useCallback(
        (isTruncated) => {
            if (isTruncated !== isContentTruncated) {
                setIsContentTruncated(isTruncated)
            }
        },
        [isContentTruncated, setIsContentTruncated]
    )

    const isTruncated = isTextTruncated || isContentTruncated

    const mergedClassName = `${BASE_CLASS} ${
        variant ? BASE_CLASS + '-' + variant : ''
    } ${className}`
    let content = children
    let as = props.as

    if (maxLines && content) {
        content = (
            <Truncate onTruncate={handleTruncate} lines={maxLines}>
                <span>{content}</span>
            </Truncate>
        )
        // If we're using the Truncate component, we can't have the wrapper be a <p> tag
        // because the Truncate component generates a <p> tag
        as = 'div'
    }

    return (
        <StyledText
            as={as}
            overflowWrap="break-word"
            color={color}
            p={p}
            m={m}
            fontSize={size}
            className={mergedClassName}
            variant={variant}
            {...(trimText ? TRIM_PROPS : null)}
            {...props}
            ref={ref}
            title={isTruncated ? props.title : undefined}
            data-title={props.title}
            icon="info"
        >
            {content}
        </StyledText>
    )
}

export default React.memo(StackerText)
