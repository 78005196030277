import { getUrl } from 'app/UrlService'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'

import type { BreadcrumbsItem } from 'v2/ui/components/Breadcrumbs'

const NAV_HISTORY_TYPES = ['list', 'detail', 'dashboard', 'create']

export const getBreadcrumbItems = (
    navigation: NavigationDto[],
    pages: PageDto[],
    views: ViewDto[],
    history: { [key: string]: any }
): { showBackButton?: boolean; items: BreadcrumbsItem[] } => {
    const currentItem = history.location
    let { objectId, type } = currentItem?.state || {}
    let prevItem = currentItem?.state?.prev
    let showBackButton = false

    if (!objectId || !type) {
        return { items: [] }
    }

    // Start with the current history item
    let items = [
        {
            ...currentItem,
            pathname: null,
        },
    ]

    // If coming from create then clear the previous state
    if (prevItem && prevItem?.state?.type == 'create') {
        prevItem = null
    }

    // Add the previous item if it exists
    if (prevItem) {
        items.unshift(prevItem)
        objectId = prevItem?.state?.objectId || objectId
    }

    // If the previous item was not a list or we're switching object id then we know that we've come
    // from a linked record and want to show the back button
    if (
        (prevItem && prevItem?.state?.type !== 'list') ||
        (prevItem && prevItem?.state?.objectId !== currentItem?.state?.objectId)
    ) {
        showBackButton = true
    }

    // Use the navigation items to find the parent lists
    if (!showBackButton && NAV_HISTORY_TYPES.includes(type)) {
        // use the navtree as this takes into account pages and views
        const navTree = buildNavTree(navigation, views, pages)
        const parent = navTree.find((nav) => nav.object_id == objectId)
        let allChildren = (parent?.enabled && !parent?.hidden && parent?.children) || []

        // ignore disabled children
        allChildren = allChildren?.filter((n) => n.enabled && !n.hidden)

        // Find the parent navigation item if more than one child available
        // OR we have no previous link
        if (allChildren.length > 1 || (!prevItem && type != 'list')) {
            const url = allChildren[0]?.url
            const includeUrl = url && getUrl(url)
            items.unshift({
                pathname: includeUrl,
                state: {
                    type: 'list',
                    title: parent?.label,
                },
            })
        }
    }

    return { showBackButton, items }
}

export const goBackUsingBreadcrumbs = (
    navigation: NavigationDto[],
    pages: PageDto[],
    views: ViewDto[],
    history: { [key: string]: any }
) => {
    const breadcrumbItems = getBreadcrumbItems(navigation, pages, views, history)

    let previousBreadcrumbUrl = getPreviousBreadcrumbUrl(breadcrumbItems)
    if (previousBreadcrumbUrl.length < 1) {
        previousBreadcrumbUrl = getUrl('/')
    }

    history.push(previousBreadcrumbUrl)
}

export function getPreviousBreadcrumbUrl(params: ReturnType<typeof getBreadcrumbItems>): string {
    const { showBackButton, items } = params

    // The last breadcrumb is the page we're on, pick the one before it
    if (items.length >= 2) {
        return items[items.length - 2].pathname ?? ''
    } else if (showBackButton && items.length) {
        return items[items.length - 1].pathname ?? ''
    }

    return ''
}
