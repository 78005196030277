import { InlineFilterMultiValueOption } from './types'

export function getInlineFilterValue(filter?: Filter): Filter['options']['value'] {
    const fieldType = filter?.field?.type
    switch (fieldType) {
        case 'dropdown':
        case 'multi_select':
        case 'multi_lookup':
        case 'checkbox': {
            const value = filter?.options.value ?? []
            if (!Array.isArray(value)) return [value]

            return value
        }
        case 'percentage':
            const value = filter?.options.value
            if (!value) {
                return value
            }

            try {
                const percentageValue = parseFloat(Array.isArray(value) ? value[0] : value)
                return (percentageValue * 100).toFixed()
            } catch {
                return value
            }

        default:
            return filter?.options.value
    }
}

export function getInlineFilterValueLabels(
    filter: Filter | undefined,
    options: InlineFilterMultiValueOption[]
): string[] {
    const field = filter?.field
    if (!field) return []

    const value = getInlineFilterValue(filter)

    if (options.length > 0) {
        const optionsByValue = options.reduce(
            (agg, curr) => agg.set(curr.value, curr),
            new Map<string, InlineFilterMultiValueOption>()
        )

        if (Array.isArray(value)) {
            return value.map((v) => optionsByValue.get(v)?.label ?? v)
        }

        const label = value && optionsByValue.get(value)?.label
        if (label) return [label]
    }

    if (Array.isArray(value)) return value
    if (value) return [value]
    return []
}
