export default function truncateLabel(
    label: string,
    xPosition: number,
    textSvg: SVGTextElement | null,
    maxWidth: number,
    minLetters: number = 3
): { isTruncated: boolean; label: string } {
    if (!textSvg || !maxWidth) {
        return { isTruncated: false, label }
    }

    textSvg.textContent = label
    let textLength = textSvg.getComputedTextLength()
    let isTruncated = false

    while (
        textSvg.textContent.length > 1 &&
        (xPosition - textLength < 0 || textLength > maxWidth)
    ) {
        textSvg.textContent =
            textSvg.textContent.length - 1 > minLetters
                ? `${textSvg.textContent.slice(0, -2)}…`
                : '…'

        textLength = textSvg.getComputedTextLength()
        isTruncated = true
    }

    return { isTruncated, label: textSvg.textContent }
}
