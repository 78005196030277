// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useStacks } from 'data/hooks/stacks'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { getStackForCurrentUrl } from 'data/utils/utils'

import useLDIdentify from '../data/hooks/useLDIdentify'
import { STACK_SELECTED } from '../data/utils/constants'

import { AppContext } from './AppContext'
import _internalStore from './AppContextStore'
import settings from './settings'

/*
    This provider keeps track of which workspace account (if any) and which
    stack we are currently running under.
*/

export const AppContextProvider = ({ children }) => {
    const [workspaceAccount, setWorkspaceAccountState] = useState<Account | null>(null)
    const [selectedStack, setSelectedStackState] = useState<StackDto | null>(null)
    const [lastVisitedStack, setLastVisitedStackState] = useState<StackDto | null>(null)
    const location = useLocation()
    const history = useHistory()
    const ldIdentify = useLDIdentify()
    useEffect(() => {
        if (selectedStack) {
            ldIdentify(workspaceAccount ?? undefined, selectedStack ?? undefined)
        }
    }, [ldIdentify, selectedStack, workspaceAccount])

    const setSelectedStack = useCallback((newStack) => {
        setSelectedStackState(() => {
            // Keep the AppContextState object up to date
            // so these ids are accessible to non-react code such as APIs etc
            _internalStore.state = {
                ..._internalStore.state,
                selectedStack: newStack,
            }
            return newStack
        })
        if (newStack) {
            setLastVisitedStackState(newStack)
        }
    }, [])
    const initializeSelectedStack = useCallback(
        (stacks) => {
            if (!stacks) {
                return
            }

            // On the studio domain, selected stack is managed directly, not via URL.
            // We want to pull the selected stack out of the latest stacks array
            // to make sure our selectedStack object is the latest version.
            if (settings.SUPPORTED_STUDIO_DOMAINS.includes(settings.HOST)) {
                if (selectedStack) {
                    const newStack = stacks.find((s) => s._sid === selectedStack._sid)
                    setSelectedStack(newStack)
                }
            } else {
                const newStack = getStackForCurrentUrl(workspaceAccount?._sid, stacks)
                // update the url to that app if not set already
                if (workspaceAccount?._sid && location.pathname === '/' && newStack) {
                    return history.replace('/' + newStack.url_slug)
                }
                setSelectedStack(newStack)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSelectedStack, workspaceAccount, location]
    )

    const setWorkspaceAccount = useCallback((account) => {
        setWorkspaceAccountState(() => {
            // Keep the AppContextState object up to date
            // so these ids are accessible to non-react code such as APIs etc
            _internalStore.state = { ..._internalStore.state, workspaceAccount: account }
            return account
        })
    }, [])
    // When accounts have loaded, check to see if our current host matches the
    // base url of any of the accounts. If so then we are running in "workspace"
    // mode under that account
    const initializeSelectedAccount = useCallback(
        (accounts) => {
            if (!accounts) {
                return
            }

            // On the studio domain, selected stack is managed directly, not via URL.
            // We want to pull the selected stack out of the latest stacks array
            // to make sure our selectedStack object is the latest version.
            if (settings.SUPPORTED_STUDIO_DOMAINS.includes(settings.HOST)) {
                if (workspaceAccount) {
                    const account = accounts.find((a) => a._sid === workspaceAccount._sid)
                    setWorkspaceAccount(account)
                }
            } else {
                const host = settings.HOST

                const account = accounts.find(
                    (account) => account.base_url === host || account.custom_base_url === host
                )
                setWorkspaceAccount(account)
            }
        },
        [workspaceAccount, setWorkspaceAccount]
    )

    const { isLoading, initialLoadComplete, loadingAccountFailed } = useInitialMetadata({
        onLoadHandlers: { accounts: initializeSelectedAccount, stacks: initializeSelectedStack },
    })

    const { data: stacks } = useStacks()
    const dispatch = useDispatch()
    const previousStack = useRef<StackDto | null>()

    // When the stack changes, we dispatch this redux message so the reducers
    // that need to can clear state.
    useEffect(() => {
        if (previousStack.current?._sid !== selectedStack?._sid) {
            dispatch({ type: STACK_SELECTED })
        }

        previousStack.current = selectedStack
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStack])

    // Watch for change in location and if the selected stack should change
    // issue the redux action
    useMemo(() => {
        initializeSelectedStack(stacks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, stacks, workspaceAccount, initializeSelectedStack])

    const isSubscribed = workspaceAccount?.subscription_status === 'subscribed'

    const contextData = useMemo(() => {
        return {
            workspaceAccount,
            selectedStack,
            lastVisitedStack,
            isLoading,
            initialLoadComplete,
            setWorkspaceAccount,
            setSelectedStack,
            loadingAccountFailed,
            isSubscribed,
        }
    }, [
        workspaceAccount,
        selectedStack,
        lastVisitedStack,
        isLoading,
        initialLoadComplete,
        setWorkspaceAccount,
        setSelectedStack,
        loadingAccountFailed,
        isSubscribed,
    ])

    return <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
}
