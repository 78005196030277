import React from 'react'

import EditableWrapper from 'v2/ui/utils/EditableWrapper'

import Box from './Box'
import Flex from './Flex'
import Icon from './Icon'

const Editable = ({ input, display, onChange }) => {
    return (
        <EditableWrapper
            onChange={onChange}
            input={input}
            value={({ start }) => (
                <Flex
                    width="100%"
                    maxHeight="100%"
                    align="stretch"
                    wrap="nowrap"
                    border="1px solid"
                    borderRadius={2}
                    transition="border-color .2s"
                    borderColor="transparent"
                    cursor="pointer"
                    onClick={start}
                    role="group"
                    _hover={{ borderColor: 'input.border' }}
                >
                    <Box flexGrow={1} overflow="hidden">
                        {display(start)}
                    </Box>
                    <Icon
                        opacity={0}
                        transition="opacity .2s"
                        icon="edit"
                        size="sm"
                        bg="transparent"
                        color="grey.300"
                        _groupHover={{ opacity: 1 }}
                    />
                </Flex>
            )}
        />
    )
}

export default React.memo(Editable)
