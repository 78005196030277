import isArray from 'lodash/isArray'
import memoize from 'lodash/memoize'
import { v1 as uuidv1 } from 'uuid'

// avoid creating new objects when doing destructuring when both inputs didn't change
// this probably has flaws

const map = new WeakMap()

function getObjectUniqId(object) {
    if (!object) return ''

    let id = map.get(object)
    if (!id) {
        id = uuidv1()
        map.set(object, id)
    }

    return id
}

const MergeObject = memoize(
    function (a, b) {
        if (!a) return b
        if (isArray(b)) return [...a, ...b]
        return Object.assign({}, a, b)
    },
    function (a, b) {
        if (!a) return getObjectUniqId(b)
        return getObjectUniqId(a) + getObjectUniqId(b)
    }
)

export default MergeObject
