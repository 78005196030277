import React, { FC } from 'react'

import { FilterDropdown } from './FilterDropdown'
import { FilterRelationship } from './FilterRelationship'
import { FilterText } from './FilterText'
import { FilterUserRole } from './FilterUserRole'
import type { AdminFilterProps } from './types'

export const FilterTextOrDropdown: FC<AdminFilterProps> = (props) => {
    switch (props.field?.type) {
        case 'dropdown':
        case 'multi_select':
            return <FilterDropdown {...props} />
        case 'checkbox':
            if (props.field.options) {
                props.field.options.options = [
                    { label: 'true', value: 'true' },
                    { label: 'false', value: 'false' },
                ]
            }

            return <FilterDropdown {...props} />
        case 'lookup':
        case 'multi_lookup':
            return <FilterRelationship {...props} />
        case 'user_role':
            return <FilterUserRole {...props} />
        default:
            return <FilterText {...props} />
    }
}
