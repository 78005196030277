import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

import { DataEditorRef } from '@glideapps/glide-data-grid'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDimension from 'v2/ui/utils/useDimension'

import { ADD_FIELD_HEADER_BUTTON_ID } from '../constants'

export function createVirtualElement(dataTestId?: string): HTMLDivElement {
    const elm = document.createElement('div')
    elm.style.position = 'absolute'
    elm.style.pointerEvents = 'none'
    if (dataTestId) elm.setAttribute('data-testid', dataTestId)
    // add the new element to our special portal for displaying them
    document.getElementById('dataGridPlaceholderPortal')?.appendChild(elm)
    return elm
}

export function positionElement(
    element: HTMLElement,
    x: number,
    y: number,
    width: number,
    height: number
) {
    element.style.left = `${x}px`
    element.style.top = `${y}px`
    element.style.height = `${height}px`
    element.style.width = `${width}px`
}

// Creates virtual dom elements positioned absolutely to match the positions of relevant
// data grid elements. This allows us to target those elements with UserFlow tours
export function useVirtualDataGridElements({
    isActive,
    columnCount,
    rowCount,
    canAddFields,
    editorRef,
    containerRef,
    onHideAddField,
}: {
    isActive: boolean
    canAddFields: boolean
    columnCount: number
    rowCount: number
    editorRef: DataEditorRef | null
    containerRef: MutableRefObject<HTMLElement | null>
    onHideAddField?: () => void
}) {
    const addFieldElement = useRef<HTMLElement | undefined>()
    const columnHeadersElement = useRef<HTMLElement | undefined>()
    const rowHeadersElement = useRef<HTMLElement | undefined>()
    const { width: containerWidth, height: containerHeight } = useDimension(containerRef)
    const allRefs = useRef<MutableRefObject<HTMLElement | undefined>[]>([
        addFieldElement,
        columnHeadersElement,
        rowHeadersElement,
    ])

    const [isAddFieldVisible, setIsAddFieldVisible] = useState(false)

    useEffect(() => {
        if (!isAddFieldVisible) onHideAddField?.()
    }, [isAddFieldVisible, onHideAddField])

    const hasRows = rowCount > 0
    const hasColumns = columnCount > 0
    useEffect(() => {
        const allRefsCopy = [...allRefs.current]
        const visibilityChanged: IntersectionObserverCallback = (entries) => {
            for (const entry of entries) {
                if (entry.target === addFieldElement.current) {
                    const isVisible = entry.isIntersecting

                    // only want to attach the test id if the element is on screen, otherwise remove it
                    addFieldElement.current.setAttribute(
                        'data-testid',
                        isVisible ? ADD_FIELD_HEADER_BUTTON_ID : ''
                    )

                    setIsAddFieldVisible(isVisible)
                }
            }
        }

        const ob = new IntersectionObserver(visibilityChanged)
        if (isActive) {
            // Create the elements and observe their visibility
            if (canAddFields) {
                addFieldElement.current = createVirtualElement()
            }

            if (hasColumns) {
                columnHeadersElement.current = createVirtualElement('data-grid-column-headers')
            }
            if (hasRows) {
                rowHeadersElement.current = createVirtualElement('data-grid-row-headers')
            }
        }

        allRefs.current.forEach((ref) => {
            if (ref.current) ob.observe(ref.current)
        })

        // clean up
        return () => {
            ob.disconnect()

            allRefsCopy.forEach((ref) => {
                if (ref.current) {
                    document.getElementById('dataGridPlaceholderPortal')?.removeChild(ref.current)
                    ref.current = undefined
                }
            })
        }
    }, [canAddFields, hasColumns, hasRows, isActive])

    // This is debounced so we don't do this for every pixel of a resize for instance
    const updatePositions = useDebounce(
        useCallback(() => {
            if (!editorRef) return

            // Get the position of the Add Field column header
            // and move the virtual element into the same size and position
            if (canAddFields) {
                // Sometimes due to timing, immediately after disabling/deleting a field
                // we are called with the old column count here and thus columnCount+1
                // is out of range and throws an exception.
                try {
                    const rect = editorRef.getBounds(columnCount, 0)
                    if (rect && addFieldElement.current) {
                        positionElement(
                            addFieldElement.current,
                            rect.x,
                            rect.y - rect.height,
                            rect.width,
                            rect.height
                        )
                    }
                } catch {
                    return
                }
            }

            const topLeftRect = editorRef.getBounds(0, 0)
            const topRightRect = editorRef.getBounds(columnCount, 0)
            const bottomLeftRect = editorRef.getBounds(0, rowCount - 1)
            if (topLeftRect && topRightRect && columnHeadersElement.current) {
                positionElement(
                    columnHeadersElement.current,
                    topLeftRect.x + topLeftRect.width,
                    topLeftRect.y - topLeftRect.height,
                    Math.min(
                        topRightRect.x + topRightRect.width - topLeftRect.x - topLeftRect.width,
                        containerWidth
                    ),
                    topLeftRect.height
                )
            }

            if (topLeftRect && bottomLeftRect && rowHeadersElement.current) {
                positionElement(
                    rowHeadersElement.current,
                    topLeftRect.x,
                    topLeftRect.y,
                    topLeftRect.width,
                    Math.min(
                        bottomLeftRect.y + bottomLeftRect.height - topLeftRect.y,
                        containerHeight
                    )
                )
            }
        }, [editorRef, canAddFields, columnCount, rowCount, containerWidth, containerHeight]),
        200
    )

    // Every render, update the positions of the DOM elements.
    // This is safe because it's debounced above.
    updatePositions()
    return
}
