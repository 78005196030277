// @ts-strict-ignore
import React, { useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { SharingSettingsPatch } from 'data/hooks/stacks'
import { UserCancelledError } from 'utils/utils'

import { FormWrapper } from '../../../ui/forms/Form'
import { decodeFieldKey, encodeFieldKey, SyncRefWithForm } from '../../../ui/forms/utils'
import { SaveChangesBar, UserActions } from '../shared/AppUsersUI'
import UserList from '../shared/UserList'
import UserListItem, { UserListItemProps } from '../shared/UserListItem'

type Props = {
    formRef: any
    children: any
    users: any[]
    onSave: (data: SharingSettingsPatch) => Promise<void>
}
type ListContextProps = {
    userList?: UserListDto
}
const ListContext = React.createContext<ListContextProps>({})
export default function AdministratorsUserList(props: Props) {
    const { formRef, children, users, onSave } = props
    const [listResetKey, setListResetKey] = useState(0)
    const [error, setError] = useState<string | undefined>()

    const formContext = useForm<SharingSettingsPatch>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const cancelEdit = () => {
        formContext.reset()
        setListResetKey((val) => val + 1)
        setError(undefined)
    }

    const saveChanges = (patch) => {
        // decode the userIds from the patch before sending to the caller
        const translatedPatch = Object.keys(patch).reduce((result, key) => {
            result[decodeFieldKey(key)] = patch[key]
            return result
        }, {})

        setError(undefined)

        try {
            return onSave(translatedPatch)
                .then(() => {
                    setError(undefined)
                    formContext.reset()
                    setListResetKey((val) => val + 1)
                })
                .catch((ex) => {
                    // Unless this is a UserCancelledError
                    if (!(ex instanceof UserCancelledError)) {
                        console.error(ex)
                        // If the promise was rejected, it was a server error
                        // so show a generic message
                        setError('An error occurred. You may not have permission to do this.')
                    }
                    throw ex
                })
        } catch (ex) {
            // Unless this is a UserCancelledError
            if (!(ex instanceof UserCancelledError)) {
                // If an error was thrown during the processing of the save data
                // it's the caller rejecting the data and so we should show the message
                setError(ex.message)
            }
            throw ex
        }
    }

    // the list context allows us to supply the group and user components (below)
    // with relevant information
    const listContext = useMemo(() => ({}), [])

    return (
        <FormWrapper
            formContext={formContext}
            onSubmit={saveChanges}
            // @ts-ignore
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 0,
                maxHeight: '100%',
            }}
            onlySubmitChangedfields
            resetOnSuccess
        >
            {formRef && <SyncRefWithForm formRef={formRef} />}
            <ListContext.Provider value={listContext}>
                <UserList
                    hideGroups
                    // react-hook-form can't just "reset" the form state to default values
                    // unless those default values are specified up front in the useForm call.
                    // We're using inline default values at the field level, so that doesn't work.
                    // The solution is just to recreate the list when resetting form state.
                    key={listResetKey}
                    inEditMode
                    users={users}
                    UserComponent={UserComponent}
                />

                <SaveChangesBar cancelEdit={cancelEdit} error={error} />
                {children}
            </ListContext.Provider>
        </FormWrapper>
    )
}

const UserComponent: React.FC<UserListItemProps> = (props) => {
    const { watch, setValue, register } = useFormContext()
    const userKey = encodeFieldKey(props.user._sid)
    const deletedkey = `${userKey}.deleted`
    const deleted = watch(deletedkey)
    const removeUser = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }
    return (
        <UserListItem
            {...props}
            rowContainerStyle={{
                textDecoration: deleted ? 'line-through' : undefined,
            }}
            rowContents={
                <>
                    <input type="hidden" {...register(deletedkey)} />

                    <div style={{ flexGrow: 1 }} />
                </>
            }
            rowActions={!deleted && <UserActions user={props.user} onRemove={removeUser} />}
        />
    )
}
