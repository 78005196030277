import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import AppContext from 'app/AppContext'
import { queryClient } from 'data/hooks/_helpers'
import { Icon8 } from 'legacy/v1/ui'

import { Flex, Link, LoadingSplash } from 'v2/ui'

import { Urls } from './UrlService'

const MetadataLoading = ({ children, onlyShowOnInitialLoad = true }) => {
    const { isLoading, initialLoadComplete, loadingAccountFailed } = useContext(AppContext)

    const location = useLocation()

    // Check if the current location is the logout route
    // This is a hack so that we allow users to logout even if the accounts
    // request fails with an old/invalid user
    const isLogoutRoute = location.pathname === '/logout'

    if (!isLoading || (initialLoadComplete && onlyShowOnInitialLoad)) {
        // If we weren't able to load the account, show the error page.
        // IMPORTANT: we must not render the children in this case, because they
        // they will try to access the stacks, etc, and will trigger a refetch
        // which will put us back into a loading state, and we'll be stuck in
        // an infinite render/fetch loop.
        if (loadingAccountFailed && !isLogoutRoute) {
            return <FailedPage />
        }

        return children
    }

    return <LoadingSplash />
}

const FailedPage = () => {
    const tryAgain = () => {
        queryClient.invalidateQueries()
    }

    const redirectToLogout = () => {
        window.location.assign(`${window.location.origin}${Urls.Logout}`)
    }

    return (
        <Flex
            column
            align="center"
            width="100%"
            height="100vh"
            justify="center"
            fontFamily="sans-serif"
        >
            <Icon8
                icon="sad-cloud"
                iconStyle="material-outlined"
                displaySize="200"
                color="333333"
                noMargin
            />

            <h1>Unable to connect to the server</h1>
            <p>
                <Link onClick={tryAgain}>Click here</Link> to try again.
                <Link onClick={redirectToLogout}>Click here</Link> to log out.
            </p>
        </Flex>
    )
}
export default MetadataLoading
