import React from 'react'

import styled from '@emotion/styled'
import { LayoutEditorBlock } from 'v2/blocks/types'

import {
    boolean,
    conditionalVisibility,
    longText,
    text,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'

import {
    Collapse,
    ConfigureWidgetPlaceholder,
    Container,
    Heading,
    MarkdownText,
    RenderButtonOrLink,
    Text,
} from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'

const StyledContainer = styled(Container)`
    & > a {
        align-self: center;
    }

    img {
        display: inline;
    }
`

type BannerBlockProps = {}

const BannerBlock: LayoutEditorBlock<BannerBlockProps, BannerBlock> = ({ attrs, context }) => {
    const { title, description, url, label, openInNewTab, center } = attrs
    const { isEditing } = context.editor
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    const ContainerComponent = center ? StyledContainer : Container

    if (!title && !description && !url) {
        if (!isEditing) {
            return null
        }
        return (
            <ConfigureWidgetPlaceholder
                message="Banner"
                subtitle="Display a heading, text and a button"
                Icon={SVGIcons.Banner}
            />
        )
    }

    let titleVariant = description ? 'widgetContainerTitle' : 'widgetContainerTitleNoMargin'

    return (
        <Collapse isOpen={!hidden} duration={0.4}>
            <ContainerComponent
                p={['container.padding', null, null, 'container.paddingLg']}
                mb={4}
                display={!description || center ? 'flex' : undefined}
                flexDirection={description || center ? 'column' : undefined}
                textAlign={center ? 'center' : undefined}
                justifyContent={center ? 'center' : undefined}
                alignItems="center"
                flexWrap="wrap"
            >
                {title && <Heading as="h2" value={title} flexGrow={1} variant={titleVariant} />}

                {description && (
                    <Text variant="widgetContainerDescription">
                        <MarkdownText
                            showRichTextEditor={typeof description === 'object'}
                            convertToMarkdown={false}
                        >
                            {description}
                        </MarkdownText>
                    </Text>
                )}

                {url && (
                    /* @ts-expect-error */
                    <RenderButtonOrLink
                        width={['100%', '100%', 'auto', 'auto']}
                        variant="fieldButton"
                        url={url}
                        button={true}
                        openInNewTab={openInNewTab}
                        mt={[4, 4, description || center ? 4 : 0, description || center ? 4 : 0]}
                    >
                        {label}
                    </RenderButtonOrLink>
                )}
            </ContainerComponent>
        </Collapse>
    )
}

BannerBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    text({ field: 'title', label: 'Title', simple: true, fullWidth: true }),
    longText({
        field: 'description',
        label: 'Description',
        multiline: true,
        maxHeight: '200px',
        fullWidth: true,
        simple: true,
    }),
    boolean({ field: 'center', label: 'Center content', simple: true }),
    text({ field: 'url', label: 'Button URL', simple: true, fullWidth: true }),
    text({ field: 'label', label: 'Button Label', simple: true, fullWidth: true }),
    boolean({ field: 'openInNewTab', label: 'Open in new tab', simple: true }),
]

export default BannerBlock
