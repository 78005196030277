import React, { VFC } from 'react'
import { useWatch } from 'react-hook-form'

import { useObject } from 'data/hooks/objects'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { useAppContext } from '../../../app/AppContext'
import { useDataConnection } from '../../../data/hooks/dataConnections'
import { FormField } from '../../../ui/forms/FormField'

import { getCanChooseTableForLink } from './logic/availableFieldOperationUtils'
import { ConfigurationComponentProps } from './common'
import FiltersConfiguration from './FiltersConfiguration'
import { ConfigurationField } from './ui'

const RecordLinkConfiguration: VFC<ConfigurationComponentProps> = ({ object, field }) => {
    const targetObjectId = useWatch({ name: 'options.lookup_target' })
    const { object: targetObject } = useObject(targetObjectId)
    const { data: dataConnection } = useDataConnection(object?.data_connection)
    const { selectedStack: stack, workspaceAccount: account } = useAppContext()

    const isDisabled =
        stack && account
            ? !getCanChooseTableForLink({
                  account,
                  dataConnection: dataConnection ?? undefined,
                  field,
                  stack,
              })
            : true
    return (
        <>
            <FormField
                name="options.lookup_target"
                as={ObjectPicker}
                errorMessages={{ required: 'Select a table to link to' }}
                variant="settings"
                placeholder="Select a table to link to"
                disabled={isDisabled}
                controlled
                required
                usePortal={false}
            />
            <ConfigurationField
                show={!!targetObjectId}
                as={FiltersConfiguration}
                name="connection_options.relationship_target_lookup_filters"
                object={targetObject}
                contextRecordObject={object}
                field={field}
                controlled
                required={false}
            />
        </>
    )
}

export default RecordLinkConfiguration
