import React, { memo, useCallback, useMemo, useRef } from 'react'

import PropTypes from 'prop-types'
import { filtersToValue } from 'v2/views/Dashboard/forms/FiltersForm'

import { useField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { useGetFieldRecords } from 'data/hooks/records'
import WithObject from 'data/wrappers/WithObject'

import { Editable } from 'v2/ui'
import { modes } from 'v2/ui/utils/attributeSettings'

import { getIsRelationshipField } from '../../../../utils/fieldUtils'

import { resolveContextRecordFilters } from './utils/resolveContextRecordFilters'
import DisplayText from './DisplayText'
import RecordDropdown from './RecordDropdown'
import RecordLinkDisplay from './RecordLinkDisplay'

const SingleRecordLinkAttribute = memo(
    ({
        mode,
        onChange,
        renderOptions = {},
        controlStyle = {},
        field,
        isInlineCreate,
        inDataGrid,
        dereferencedRecords,
        bypassPreviewAndImpersonation,
        onRecordsSelected,
        placeholder,
        contextRecord,
        ...props
    }) => {
        const { object: targetObject } = useObject(field.options?.lookup_target)
        const { records = [], isLoading } = useGetFieldRecords({
            field,
            value: props.children,
            dereferencedRecords,
        })
        const foreignMatchField = useField(
            getIsRelationshipField(field) &&
                field?.connection_options?.relationship_target_lookup_field
        )
        // If this is a synthetic relationship field, we want to filter out any
        // target records where the match field is blank
        const recordFilter = useCallback(
            (record) => !foreignMatchField || record[foreignMatchField.api_name],
            [foreignMatchField]
        )
        const fieldFilters = field.connection_options?.relationship_target_lookup_filters
        // If any lookup filters has been specified on the link field
        // we apply them for the dropdown
        const rawFilters = useMemo(
            () => filtersToValue(fieldFilters || [], targetObject),
            [fieldFilters, targetObject]
        )

        const filters = useMemo(
            () => resolveContextRecordFilters(rawFilters, contextRecord),
            [rawFilters, contextRecord]
        )

        const dropdownRef = useRef()
        if (inDataGrid) {
            dropdownRef.current?.select?.inputRef?.focus()
        }

        const { disableSearch, showCreateButton } = renderOptions
        const form = (
            <WithObject objectId={field.object_id}>
                {() => {
                    let value = props.children

                    return (
                        <RecordDropdown
                            forwardedRef={dropdownRef}
                            className={inDataGrid ? 'click-outside-ignore' : ''}
                            showCreateButton={!!showCreateButton}
                            isInlineCreate={isInlineCreate}
                            objectId={field.options && field.options.lookup_target}
                            value={props.children || value}
                            onChange={onChange}
                            autoFocus={mode === modes.editable}
                            isSearchable={!disableSearch}
                            filter={recordFilter}
                            filters={filters}
                            // If this is a synthetic stacker relationshpi field
                            // we want to ensure that the available options are
                            // deduplicated based on the target field value
                            distinctBy={foreignMatchField?.api_name}
                            distinctOrderBy={'_sid'}
                            controlStyle={{
                                background: 'white',
                                ...controlStyle,
                            }}
                            bypassPreviewAndImpersonation={bypassPreviewAndImpersonation}
                            onRecordsSelected={onRecordsSelected}
                            placeholder={placeholder}
                        />
                    )
                }}
            </WithObject>
        )

        const record = records[0]

        const display = props.children ? (
            <RecordLinkDisplay
                record={record}
                margin="none"
                recordId={props.children}
                noLink={props.noLinks}
                bypassPreviewAndImpersonation={bypassPreviewAndImpersonation}
                layout={props.layout}
            />
        ) : (
            <DisplayText>-</DisplayText>
        )

        if (isLoading && mode !== modes.editing) return null

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end }) =>
                        React.cloneElement(form, {
                            onBlur: () => {
                                end()
                            },
                        })
                    }
                    display={() => display}
                    onChange={onChange}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

SingleRecordLinkAttribute.propTypes = {
    /** handles saving the changed value */
    onChange: PropTypes.func,
}
export default SingleRecordLinkAttribute
