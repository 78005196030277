import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import type { Row } from 'react-table'

import * as Sentry from '@sentry/react'
import type { History, Location } from 'history'
import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import uniq from 'lodash/uniq'
import queryString from 'query-string'
import { ViewLayoutContext } from 'v2/blocks/types'
import DetailView from 'v2/views/Detail/DetailView'
import useViewBreadcrumbTitle from 'v2/views/useViewBreadcrumbTitle'
import { getColumns } from 'v2/views/utils/getColumns'
import isArrayEqual from 'v2/views/utils/isArrayEqual'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'
import useViewConfig from 'v2/views/utils/useViewConfig'

import { SERVER_PAGE_SIZE } from 'app/settings'
import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { getUrl } from 'app/UrlService'
import { recordApi } from 'data/api/recordApi'
import { useObject } from 'data/hooks/objects'
import { useUserRecord } from 'data/hooks/users/main'
import requiresBackendFiltering from 'data/utils/requiresBackendFiltering'
import { withFeatures } from 'data/wrappers/WithFeatures'
import { withObjects } from 'data/wrappers/WithObjects'
import WithRecords from 'data/wrappers/WithRecords'
import { withStack } from 'data/wrappers/WithStacks'
import { withViews } from 'data/wrappers/WithViews'
import { FrameContext } from 'features/core/Frame'
import { getFilterFields, processStaticFilter } from 'features/records/components/logic/recordLogic'
import { getOrderFields, processOrder } from 'features/records/components/RecordOrder'
import { currentUserOptions, userFieldOptions } from 'features/utils/filtersToQueryDict'
import { ListViewHeader } from 'features/views/ViewHeader/ListViewHeader'
import useTrack from 'utils/useTrack'

import { getKanbanFilters } from 'v2/ui/components/kanbanUtils'
import LoadingOverlay from 'v2/ui/components/LoadingOverlay'
import Toast from 'v2/ui/components/Toast'
import { FEATURES, isFeatureLocked, ProtectedFeature } from 'v2/ui/utils/ProtectedFeature'
import { useDeepEqualsMemoValue } from 'v2/ui/utils/useDeepEqualsMemoValue'
import useDeepEqualsState from 'v2/ui/utils/useDeepEqualsState'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import useLDFlags from '../../../data/hooks/useLDFlags'
import { getDefaultPageSize as getDefaultListPageSize } from '../../ui/components/List/utils'
import useGetCalendarFilters from '../Calendar/useGetCalendarFilters'
import { getHiddenColumnsForUserFilter } from '../utils/getHiddenColumnsForUserFilter'
import { useOrderByParams } from '../utils/orderBy/useOrderByParams'
import userflowHooks from '../utils/useShowNewFieldsUserFlow'

import { getColumnConfig } from './getColumnConfig'
import ListViewEditMode from './ListViewEditMode'
import ListViewEditPane from './ListViewEditPane'
import ListViewInboxMode from './ListViewInboxMode'
import ListViewLoadingState from './ListViewLoadingState'
import ListViewObjectNotAccessible from './ListViewObjectNotAccessible'
import { CustomListRendererComponent, CustomLoadingState, ViewColumn } from './types'
import { ViewState } from './viewStateType'

const currentOptions = new Set(currentUserOptions.concat(userFieldOptions))

const getFilteredOrderedRecords = memoize(
    (
        records: RecordDto[],
        filters: Filter[],
        relatedListRecord?: string,
        limitRows?: boolean,
        orderBy?: { id: string; desc?: boolean }
    ) => {
        let filteredRecords = processStaticFilter(records, filters, relatedListRecord)
        if (orderBy) {
            filteredRecords = processOrder(filteredRecords, [orderBy])
        }
        if (limitRows) {
            filteredRecords = filteredRecords.slice(0, limitRows)
        }

        return filteredRecords
    }
)

const defaultState = {
    loadingTimedOut: false,
}

type ViewConfig = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    setViewData: (patch: Partial<ViewDto>) => void
    saveConfig: () => Promise<void>
    isConfigDirty: boolean
    viewState: ViewState
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
    rolesDirty: boolean
    revertChanges: () => void
}

type ListViewProps = {
    context?: ViewLayoutContext
    onChangeStack?: (...args: any[]) => Promise<StackDto>
    features?: FeatureDto[]
    stack?: StackDto
    view?: ViewDto
    views?: ViewDto[]
    objects?: ObjectDto[]
    fetchedObjects?: boolean
    onError?: (error?: unknown) => void
    onRecordsLoaded?: (number: number | undefined | null) => void
    additionalFilters?: Filter[]
    limitRows?: boolean
    hideTitle?: boolean
    showControls?: boolean
    hideFields?: FieldDto[]
    isRecordList?: boolean
    stackOptions?: StackDto['options']
    hideEditControls?: boolean
    listEditControls?: React.ComponentType
    noLinks?: boolean
    isRecordListOnCreate?: boolean
    relatedFieldMayCreateNewRecords?: boolean
    relatedListSymmetricColumnName?: string
    title?: string
    autoFilledRelatedListRecord?: string
    relatedListType?: string
    parentDetailViewIds?: string[]
    parentListViewIds?: string[]
    relatedListAttrs?: { displayCharts: boolean }
    relatedListRecord?: string
    relatedListEditing?: boolean
    recordListTitle?: string
    relatedListIds?: string[]
    relatedListField?: string
    history?: History
    location?: Location<{ objectId: string }>
    hideSearch?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    customListRenderer?: CustomListRendererComponent
    customEmptyState?: () => React.ReactElement
    customLoadingState?: CustomLoadingState
    allowDownload?: boolean
}

export function getServerDefaultPageSize(
    displayType: string,
    showDetailView: boolean,
    useServerSidePagination: boolean,
    view?: ViewDto
): number | undefined {
    const enablePaging = displayType !== 'kanban'
    if (enablePaging) {
        let clientSidePageSize = view?.options?.pageSize
        if (!clientSidePageSize) {
            clientSidePageSize = getDefaultListPageSize(displayType)
        }
        // When serverSidePagination is enabled, we load each page separately. When disabled, we load up to SERVER_PAGE_SIZE records
        // and then page the records on the client side
        const serverPageSize = useServerSidePagination ? clientSidePageSize : SERVER_PAGE_SIZE
        return showDetailView ? 1 : serverPageSize
    }
    return undefined
}

const getSearchQueryVariable = function (variable: string): string | undefined {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1])
        }
    }
}

export const ListView: React.FC<ListViewProps> = ({
    context,
    features,
    view,
    views,
    fetchedObjects,
    objects,
    onError: recordError,
    onRecordsLoaded: recordsLoaded,
    additionalFilters,
    limitRows,
    hideTitle,
    showControls,
    hideFields,
    isRecordList,
    stackOptions,
    stack,
    onChangeStack,
    hideEditControls,
    listEditControls,
    noLinks,
    isRecordListOnCreate,
    relatedFieldMayCreateNewRecords,
    relatedListSymmetricColumnName,
    autoFilledRelatedListRecord,
    location,
    title,
    history,
    relatedListType,
    parentDetailViewIds = [],
    parentListViewIds = [],
    relatedListField,
    recordListTitle,
    relatedListAttrs,
    relatedListRecord,
    relatedListEditing,
    relatedListIds = [],
    hideSearch,
    getPageSizeOptions,
    getDefaultPageSize,
    customListRenderer,
    customEmptyState,
    customLoadingState,
    allowDownload: allowDownloadOverride,
}) => {
    const frameContext = React.useContext(FrameContext)
    const [state, setState] = useState(defaultState)
    const [currentRelatedListIds, setCurrentRelatedListIds] = useState(relatedListIds)
    const [key, setKey] = useState(Date.now())
    const [showDuplicateListsLocked, setShowDuplicateListsLocked] = useState(false)
    const loadingTimer = useRef(0)
    const isDuplicateListsLocked = isFeatureLocked(FEATURES.duplicateLists, stack)
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
    const [waitingForUserRecord, setWaitingForUserRecord] = useState(true)
    const [showSuccessToast, setShowSuccessToast] = useState(false)
    const [toastSuccessText, setToastSuccessText] = useState('')

    const { isFetched: userRecordFetched } = useUserRecord()
    const objectId = view?.object_id
    const { object } = useObject(objectId)
    const [isEmptyList, setIsEmptyList] = useState(false)

    const { track } = useTrack()

    userflowHooks.useShowNewFieldsUserFlow(object?.fields ?? [], view, stack, isEmptyList)

    // this will allow child components to re-mount this component
    const forceUpdate = useCallback(() => setKey(Date.now()), [setKey])

    // re-fetch the related list after editing the parent record
    // only if the field has been updated
    useEffect(() => {
        if (
            isRecordList &&
            !relatedListEditing &&
            relatedListType !== 'all' &&
            !isArrayEqual(relatedListIds, currentRelatedListIds)
        ) {
            setCurrentRelatedListIds(relatedListIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordList, relatedListEditing, relatedListType, relatedListIds])

    useEffect(() => {
        forceUpdate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRelatedListIds])

    const onCreate = useCallback(
        (_newRecord, successText) => {
            forceUpdate()
            if (successText) {
                setShowLoadingOverlay(true)
                setToastSuccessText(successText)
            } else {
                setToastSuccessText('')
            }
        },
        [forceUpdate, setShowLoadingOverlay, setToastSuccessText]
    )

    const onRecordsLoadStarted = () => {
        const timer = window.setTimeout(() => {
            setState((state) => ({ ...state, loadingTimedOut: true }))
            setShowLoadingOverlay(false)
        }, 10 * 1000)
        loadingTimer.current = timer
    }

    const onRecordsLoaded = (numLoaded?: number | null) => {
        window.clearTimeout(loadingTimer.current)

        if (state.loadingTimedOut) {
            setState((state) => ({ ...state, loadingTimedOut: false }))
        }
        // null => Fetch cancelled.
        if (recordsLoaded && numLoaded !== null) {
            recordsLoaded(numLoaded)
        }

        if (toastSuccessText) setShowSuccessToast(true)
        setShowLoadingOverlay(false)
    }

    const onError = () => {
        window.clearTimeout(loadingTimer.current)
        if (recordError) {
            recordError()
        }
    }

    useEffect(() => {
        return () => {
            window.clearTimeout(loadingTimer.current)
        }
    }, [])

    const onSaveView = () => {
        // Note that we've edited the view for the onboarding checklist
        if (!stackOptions?.has_edited_view && stack) {
            onChangeStack?.(stack._sid, {
                options: {
                    ...stackOptions,
                    has_edited_view: true,
                },
            })
        }
    }

    const onDownloadCsv = async () => {
        track('Frontend - List View - Export CSV - Clicked', { table_name: object?.name })
        const csvIncludedFields = columns.reduce<string[]>((combined, column) => {
            if (column.field?.api_name && column.show !== false) {
                combined.push(column.field?.api_name)
            }
            return combined
        }, [])

        try {
            await recordApi.downloadCsvRecords({
                object,
                viewId: viewState?.view?._sid,
                filename:
                    `${stack?.name}_${object?.name}`.replace(/[^a-z0-9]/gi, '_').toLowerCase() +
                    '.csv',
                filters: listViewFilters,
                includeFields: csvIncludedFields,
                excludeRecordsIdFromCsv: true,
                disablePartials: showControls || showDetailView,
                orderBy: enableBackendFiltering ? orderBy : undefined,
                csvFieldsOrder: csvIncludedFields,
            })
        } catch (error) {
            Sentry.captureException(error)
            track('Frontend - List View - Export CSV - Failed', { table_name: object?.name })
        }
    }

    const {
        setConfig,
        setViewData,
        saveConfig,
        isConfigDirty,
        viewState,
        setPageRoles,
        rolesDirty,
        pageRoles,
        revertChanges,
    } = useViewConfig(view, context?.page, onSaveView, object) as ViewConfig

    // This is used for the calendar view, so that we can filter records based on a start and end date
    const {
        display,
        startDateField,
        endDateField,
        defaultCalendarView,
        defaultDateOption,
        defaultDate,
        order,
        filters: viewStateFilters,
        title: viewStateTitle,
        showAllFields,
        columns: viewStateColumns,
        enableSpecificEndUserFilters,
        specificEndUserFilters,
        actionButtons,
    } = viewState?.view?.options

    const allowDownload = allowDownloadOverride ?? viewState?.view?.options.allowDownload
    const mergedHideSearch =
        hideSearch === undefined ? viewState?.view?.options?.hide_search_bar : hideSearch

    const { orderBy, setOrderBy, orderByHasInitialised } = useOrderByParams({
        adminOrderBy: order,
        disableParams: !!isRecordList,
    })

    // check to see if any of our filters rely in current user record values
    // and if so, and we are still waiting on the user record info from the server
    // then show a loading state while we wait.
    useEffect(() => {
        const filters = viewStateFilters?.find((filter) =>
            currentOptions.has(filter?.options?.option)
        )
        setWaitingForUserRecord(!userRecordFetched && !!filters)
    }, [userRecordFetched, viewStateFilters])

    const [allowRedirect, setAllowRedirect] = useState(false)
    const addFilters = additionalFilters || []
    const filters: Filter[] = useDeepEqualsMemoValue((viewStateFilters ?? []).concat(addFilters))

    // Initialize the filter with the search parameter from the URL if there's one.
    const [endUserFilters, setEndUserFilters] = useDeepEqualsState(
        getSearchQueryVariable('search')
            ? [
                  {
                      field: { api_name: '_search' },
                      options: { value: getSearchQueryVariable('search') },
                  },
              ]
            : []
    )
    const [endUserFilters__NotForBackend, setEndUserFilters__NotForBackend] = useDeepEqualsState([]) // used for charts

    const allFilters: Filter[] = useMemo(() => {
        return [...filters, ...endUserFilters]
    }, [filters, endUserFilters])

    const allFilters__NotForBackend: Filter[] = useMemo(() => {
        return [...filters, ...endUserFilters__NotForBackend]
    }, [filters, endUserFilters__NotForBackend])

    const isMobile = useIsMobile()
    const shouldIgnoreInbox = isMobile || isRecordList

    // This supports our functionality to only show a single record from this list
    const showDetailView = display === 'single_record'
    const showInbox = display === 'inbox' && !shouldIgnoreInbox

    const isListView = !showDetailView && !showInbox

    // Don't show the title while loading if we plan for the detail view to come in
    const hideTitleAndButtonsWhileLoading = showDetailView

    const disableHistoryBreadcrumb = isRecordList || !orderByHasInitialised
    const finalTitle = title || viewStateTitle || viewState?.view?.name
    const breadcrumbTitle = useViewBreadcrumbTitle(viewState?.view)

    useHistoryBreadcrumb(
        { title: breadcrumbTitle, type: 'list', objectId },
        disableHistoryBreadcrumb
    )

    const titleLabel = useMemo(() => {
        // If we're on a detail view, don't show the title until the object has had a chance to load
        const showTitle = !hideTitleAndButtonsWhileLoading && !hideTitle
        if (!showTitle) return undefined

        return finalTitle
    }, [hideTitle, finalTitle, hideTitleAndButtonsWhileLoading])

    const feature = useMemo(() => {
        const featureId = view?.feature_id

        return features?.find((f) => f._sid === featureId)
    }, [features, view])

    const rowLinkFunction = useCallback(
        (row: Row<RecordDto>) => {
            const link = getUrl(`${feature?.url}/view/${row.original._sid}`)

            return {
                pathname: link,
                state: {
                    prev: history?.location,
                    type: 'detail',
                    title: row?.original?._primary,
                },
            } as Location
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history?.location, feature?.url]
    )

    const { calendarRecordFilters, calendarFilter, setCalendarFilter } = useGetCalendarFilters({
        object,
        startDate: startDateField,
        endDate: endDateField,
        defaultView: defaultCalendarView,
        defaultDateOption,
        defaultDate,
        display,
    })

    const detailView = useMemo(() => {
        const featureId = feature?._sid

        return views?.find((v) => v.feature_id === featureId && v.type === 'detail')
    }, [views, feature])

    const objectFields = object?.fields
    const objectPermissions = object?._permissions

    // If isShowAllFields, we map all fields to a column and override viewOptions for display options components
    const columnConfig: ListViewColumnConfig[] = useMemo(() => {
        if (!object) return []
        return getColumnConfig(object, viewStateColumns, showAllFields)
    }, [object, showAllFields, viewStateColumns])

    const overrideViewOptions = useMemo(() => {
        return { ...viewState?.view?.options, columns: columnConfig }
    }, [columnConfig, viewState])

    const setViewOptions = useCallback(
        (patch: Partial<ListViewOptions>) => {
            setConfig({
                ...viewState?.view?.options,
                ...patch,
            })
        },
        [setConfig, viewState?.view?.options]
    )

    const columns: ViewColumn[] = useMemo(() => {
        // Hide read=false fields by api_name using permissions
        let fieldsToHideNames = objectFields?.map((field) => field.api_name) ?? []
        if (objectPermissions) {
            const visibleFields = new Set(
                objectPermissions.may_read_fields.concat(objectPermissions.maybe_may_read_fields)
            )

            fieldsToHideNames = fieldsToHideNames.filter((name) => !visibleFields.has(name))
        }

        // We sometimes allow columns to be hidden (e.g. in a detail view)
        const fieldsToHideIDs = hideFields?.map((field) => field._sid) ?? []

        return getColumns({
            columnConfig,
            fieldsToHideNames,
            fieldsToHideIDs,
            objectFields: objectFields ?? [],
            viewState,
        })
    }, [columnConfig, hideFields, objectFields, objectPermissions, viewState])

    const userFilterFieldIds = useMemo(() => {
        if (enableSpecificEndUserFilters) {
            return specificEndUserFilters ?? []
        }

        const idsSet = columns.reduce<Set<string>>((agg, curr) => {
            if (curr.field) {
                agg.add(curr.field._sid)
            }

            return agg
        }, new Set())

        return [...idsSet]
    }, [columns, enableSpecificEndUserFilters, specificEndUserFilters])

    const { mergedColumns, hiddenColumnIds } = useMemo(() => {
        const columnFieldIds = columns.reduce((agg, curr) => {
            if (curr.field?._sid) agg.add(curr.field?._sid)
            return agg
        }, new Set<string>())

        // in Specific End User Filters mode some of the filters are on fields that are not in the view. To make it work,
        // we need to create hidden columns for those fields.
        const userFiltersHidden = userFilterFieldIds.reduce((agg, curr) => {
            if (!columnFieldIds.has(curr)) agg.add(curr)
            return agg
        }, new Set())

        const hiddenColumns: ViewColumn[] = getHiddenColumnsForUserFilter(
            objectFields?.filter((field) => userFiltersHidden.has(field._sid)) ?? []
        )

        return {
            mergedColumns: columns.concat(hiddenColumns),
            hiddenColumnIds: hiddenColumns.map((column) => column.id!),
        }
    }, [columns, objectFields, userFilterFieldIds])

    const multiLookupSids = useMemo(() => {
        return (
            columns?.reduce((agg: string[], curr) => {
                if (curr.type === 'multi_lookup' && curr.field) {
                    agg.push(curr.field._sid)
                }

                return agg
            }, []) ?? []
        )
    }, [columns])

    // lookup objects to watch for realtime updates
    const lookupObjects = useMemo(() => {
        return (
            columns?.reduce((agg: string[], curr) => {
                const lookupTarget = curr.field?.options?.lookup_target
                if (lookupTarget && (curr.type === 'lookup' || curr.type === 'multi_lookup')) {
                    agg.push(lookupTarget)
                }

                return agg
            }, []) ?? []
        )
    }, [columns])

    const layoutSpecificFilters = useMemo(() => {
        switch (display) {
            case 'kanban':
                return (
                    (object &&
                        (getKanbanFilters(object, viewState?.view?.options) as
                            | Filter[]
                            | undefined)) ||
                    []
                )
            case 'calendar':
                return calendarRecordFilters
            default:
                return []
        }
    }, [calendarRecordFilters, display, object, viewState])

    const includeFields = useMemo(() => {
        let filterFields: string[] = getFilterFields(allFilters.concat(layoutSpecificFilters))
        if (orderBy) {
            filterFields = filterFields.concat(getOrderFields([orderBy]))
        }

        // Get action buttons which have conditional visibility set
        const conditionalButtons =
            actionButtons?.filter(
                (x) => typeof x.conditions !== 'undefined' && x.conditions.length > 0
            ) || []

        // Get a flattened version of all active conditions
        const conditions = conditionalButtons?.map((button) => button.conditions).flat() || []

        // Remove conditions that do not have a specific field (i.e. the user role filter)
        for (const condition of conditions) {
            if (condition.field?.api_name && condition.field.type !== 'user_role') {
                filterFields.push(condition.field.api_name)
            }
        }

        for (const column of mergedColumns) {
            if (column.field?.api_name) {
                filterFields.push(column.field.api_name)
            }
        }

        return uniq(filterFields)
    }, [allFilters, layoutSpecificFilters, mergedColumns, orderBy, actionButtons])

    const enableBackendFiltering: boolean = useMemo(
        () => requiresBackendFiltering(stackOptions),
        [stackOptions]
    )

    const query = useMemo(() => queryString.parse(location?.search as string), [location?.search])

    // Kanban doesn't support paging, so fetch all data
    const { flags } = useLDFlags()
    const defaultPageSize = getServerDefaultPageSize(
        display,
        showDetailView,
        !!flags.serverSidePagination,
        view
    )

    let defaultPageIndex = 0
    if (flags.serverSidePagination && query.page_num && !isRecordList) {
        defaultPageIndex = parseInt(query.page_num as string, 10) - 1
    }

    const [pageIndex, setPageIndex] = useState(defaultPageIndex)
    const [pageSize, setPageSize] = useState(defaultPageSize)

    const listViewFilters = useMemo(() => {
        return enableBackendFiltering ? allFilters.concat(layoutSpecificFilters) : undefined
    }, [allFilters, enableBackendFiltering, layoutSpecificFilters])

    // This only happens if a record list then tries to call the same detail view
    // we need to stop this from happening to stop an endless loop
    const hasDuplicateDetailViewID = useMemo(() => {
        return showDetailView && detailView && parentDetailViewIds.includes(detailView._sid)
    }, [detailView, parentDetailViewIds, showDetailView])
    if (hasDuplicateDetailViewID) {
        console.log(
            `Stopping list rendering due to duplicate detail view id in the tree. View Id ${detailView?._sid}`
        )
        return null
    }

    if (!fetchedObjects || !objects) return null

    if (objects.length === 0 || !object) {
        if (customEmptyState) return customEmptyState()

        return (
            <ListViewObjectNotAccessible
                setConfig={setConfig}
                display={display}
                title={titleLabel}
                showControls={showControls}
                isRecordList={isRecordList}
                isMobile={isMobile}
            />
        )
    }

    if (!objectFields || !objectPermissions) return null
    if (isListView && !showControls && !columns.length) return null
    // This prevents a wasted render. We have a useEffect which updates
    // the location.state with breadcrumb information. That update of location
    // causes a rerender of the page immediately after the initial render.
    // This line avoids rendering at all if we haven't updated the location.state yet
    if (!disableHistoryBreadcrumb && location?.state?.objectId !== objectId) return null

    return (
        <>
            {!isRecordList && !showInbox && context && (
                <ListViewHeader
                    viewLayoutContext={context}
                    listOptions={viewState?.view?.options}
                    onChange={setConfig}
                />
            )}
            {/* If we have to wait for the current user's profile records to load,
            before we can filter properly, show the loading state now. */}
            {waitingForUserRecord ? (
                <ListViewLoadingState
                    setConfig={setConfig}
                    display={display}
                    feature={feature}
                    showControls={showControls}
                    title={titleLabel}
                    isMobile={isMobile}
                    loadingTimedOut={state.loadingTimedOut}
                    customLoader={customLoadingState}
                />
            ) : (
                <WithRecords
                    key={key}
                    ignoreLoading={showControls}
                    renderOnError
                    objectId={objectId}
                    filters={listViewFilters}
                    options={{
                        dereference: showDetailView ? false : true,
                        dereferenceMultiFields: showDetailView ? undefined : multiLookupSids,
                        includeFields,
                        disablePartials: showControls || showDetailView,
                        orderBy: enableBackendFiltering ? orderBy : undefined,
                        viewId: viewState?.view?._sid,
                        pageSize,
                        pageIndex,
                    }}
                    loading={
                        <ListViewLoadingState
                            setConfig={setConfig}
                            display={display}
                            feature={feature}
                            showControls={showControls}
                            title={titleLabel}
                            isMobile={isMobile}
                            loadingTimedOut={state.loadingTimedOut}
                            customLoader={customLoadingState}
                        />
                    }
                    onRecordsLoadStarted={onRecordsLoadStarted}
                    onRecordsLoaded={onRecordsLoaded}
                    onError={onError}
                    internalOptions={{ lookupObjects }}
                >
                    {({
                        records,
                        totalResults,
                        isLoading,
                        isServerLoading,
                        dereferencedRecords,
                        loadingFailed,
                    }) => {
                        const filteredRecords = getFilteredOrderedRecords(
                            records,
                            allFilters ?? [],
                            relatedListRecord,
                            limitRows,
                            orderBy
                        )
                        setIsEmptyList(isEmpty(filteredRecords))

                        const isDetailView = showDetailView

                        // If we're either still performing the initial load (isLoading)
                        // or we have no results (because we filtered the available ones out)
                        // and we're still waiting for the server response, show the loading indicator
                        const showLoadingState =
                            isLoading ||
                            (isServerLoading && filteredRecords.length === 0) ||
                            loadingFailed

                        const showCreate = !Boolean(viewState?.view?.options?.disableCreateForm)

                        return (
                            <>
                                <ProtectedFeature
                                    feature={FEATURES.duplicateLists}
                                    showModal={showDuplicateListsLocked}
                                    onModalClosed={() => setShowDuplicateListsLocked(false)}
                                />
                                {showControls && (
                                    <ListViewEditPane
                                        viewLayoutContext={context}
                                        saveConfig={saveConfig}
                                        setConfig={setViewOptions}
                                        setViewData={setViewData}
                                        setAllowRedirect={setAllowRedirect}
                                        setShowDuplicateListsLocked={setShowDuplicateListsLocked}
                                        setPageRoles={setPageRoles}
                                        onDisplayChange={frameContext.onDisplayChange}
                                        viewState={viewState}
                                        isConfigDirty={isConfigDirty}
                                        isRolesDirty={rolesDirty}
                                        isDuplicateListsLocked={isDuplicateListsLocked}
                                        showDetailView={showDetailView}
                                        showCreate={showCreate}
                                        object={object}
                                        feature={feature}
                                        view={view}
                                        views={views}
                                        stack={stack}
                                        filteredRecords={filteredRecords}
                                        pageRoles={pageRoles}
                                        detailView={detailView}
                                        columnConfig={columnConfig}
                                        getDefaultPageSize={getDefaultPageSize}
                                        getPageSizeOptions={getPageSizeOptions}
                                    />
                                )}

                                {showLoadingState ? (
                                    <ListViewLoadingState
                                        setConfig={setConfig}
                                        display={display}
                                        feature={feature}
                                        showControls={showControls}
                                        title={titleLabel}
                                        isMobile={isMobile}
                                        failed={loadingFailed}
                                        loadingTimedOut={state.loadingTimedOut}
                                        customLoader={customLoadingState}
                                    />
                                ) : (
                                    <>
                                        {isListView && (
                                            <ListViewEditMode
                                                title={titleLabel}
                                                setConfig={setConfig}
                                                showControls={showControls}
                                                setEndUserFilters={setEndUserFilters}
                                                setEndUserFilters__NotForBackend={
                                                    setEndUserFilters__NotForBackend
                                                }
                                                rowLinkFunction={rowLinkFunction}
                                                setCalendarFilter={setCalendarFilter}
                                                setOrderBy={setOrderBy}
                                                isRecordList={isRecordList}
                                                noLinks={noLinks}
                                                enableBackendFiltering={enableBackendFiltering}
                                                isServerLoading={isServerLoading}
                                                view={view}
                                                feature={feature}
                                                viewState={viewState}
                                                object={object}
                                                columns={mergedColumns}
                                                hiddenColumnIds={hiddenColumnIds}
                                                userFilterFieldIds={userFilterFieldIds}
                                                filteredRecords={filteredRecords}
                                                overrideViewOptions={overrideViewOptions}
                                                endUserFilters={endUserFilters}
                                                allFilters__NotForBackend={
                                                    allFilters__NotForBackend
                                                }
                                                dereferencedRecords={dereferencedRecords}
                                                totalResults={totalResults}
                                                relatedFieldMayCreateNewRecords={
                                                    relatedFieldMayCreateNewRecords
                                                }
                                                hideEditControls={hideEditControls}
                                                isRecordListOnCreate={isRecordListOnCreate}
                                                listEditControls={listEditControls}
                                                relatedListSymmetricColumnName={
                                                    relatedListSymmetricColumnName
                                                }
                                                relatedListType={relatedListType}
                                                autoFilledRelatedListRecord={
                                                    autoFilledRelatedListRecord
                                                }
                                                calendarFilter={calendarFilter}
                                                orderBy={orderBy}
                                                relatedListAttrs={relatedListAttrs}
                                                history={history}
                                                location={location}
                                                relatedListField={relatedListField}
                                                hideSearch={mergedHideSearch}
                                                getPageSizeOptions={getPageSizeOptions}
                                                getDefaultPageSize={getDefaultPageSize}
                                                customListRenderer={customListRenderer}
                                                allowDownload={allowDownload}
                                                onDownloadCsv={onDownloadCsv}
                                                setPageIndex={setPageIndex}
                                                setPageSize={setPageSize}
                                                pageSize={pageSize}
                                                pageIndex={pageIndex}
                                            />
                                        )}
                                        {isDetailView && detailView && (
                                            <DetailView
                                                view={detailView}
                                                feature={feature}
                                                objectId={detailView.object_id}
                                                config={detailView.options}
                                                showControls={false}
                                                record={filteredRecords[0]}
                                                isRecordList={isRecordList}
                                                showCreate={showCreate}
                                                onCreate={onCreate}
                                                parentDetailViewIds={
                                                    detailView
                                                        ? [...parentDetailViewIds, detailView._sid]
                                                        : parentDetailViewIds
                                                }
                                                parentListViewIds={
                                                    view
                                                        ? [...parentListViewIds, view._sid]
                                                        : parentListViewIds
                                                }
                                                title={recordListTitle}
                                                fromListView
                                            />
                                        )}
                                        {showInbox && detailView && (
                                            <ListViewInboxMode
                                                setConfig={setConfig}
                                                showControls={showControls}
                                                isRecordList={isRecordList}
                                                noLinks={noLinks}
                                                enableBackendFiltering={enableBackendFiltering}
                                                isServerLoading={isServerLoading}
                                                feature={feature}
                                                detailView={detailView}
                                                viewState={viewState}
                                                object={object}
                                                showCreate={showCreate}
                                                onCreate={onCreate}
                                                stackOptions={stackOptions}
                                                parentDetailViewIds={parentDetailViewIds}
                                                parentListViewIds={parentListViewIds}
                                                recordListTitle={recordListTitle}
                                                title={titleLabel}
                                                columns={mergedColumns}
                                                filteredRecords={filteredRecords}
                                                overrideViewOptions={overrideViewOptions}
                                                setEndUserFilters={setEndUserFilters}
                                                setEndUserFilters__NotForBackend={
                                                    setEndUserFilters__NotForBackend
                                                }
                                                rowLinkFunction={rowLinkFunction}
                                                endUserFilters={endUserFilters}
                                                dereferencedRecords={dereferencedRecords}
                                                totalResults={totalResults}
                                                relatedFieldMayCreateNewRecords={
                                                    relatedFieldMayCreateNewRecords
                                                }
                                                hideEditControls={hideEditControls}
                                                isRecordListOnCreate={isRecordListOnCreate}
                                                listEditControls={listEditControls}
                                                relatedListSymmetricColumnName={
                                                    relatedListSymmetricColumnName
                                                }
                                                relatedListType={relatedListType}
                                                autoFilledRelatedListRecord={
                                                    autoFilledRelatedListRecord
                                                }
                                                history={history}
                                                location={location}
                                                userFilterFieldIds={userFilterFieldIds}
                                                hideSearch={mergedHideSearch}
                                                getPageSizeOptions={getPageSizeOptions}
                                                getDefaultPageSize={getDefaultPageSize}
                                                allowDownload={allowDownload}
                                                onDownloadCsv={onDownloadCsv}
                                                setPageIndex={setPageIndex}
                                                setPageSize={setPageSize}
                                                pageSize={pageSize}
                                                pageIndex={pageIndex}
                                            />
                                        )}
                                    </>
                                )}

                                <UnsavedChangesModal
                                    isDirty={(isConfigDirty || rolesDirty) && !allowRedirect}
                                    onSave={saveConfig}
                                    revertChanges={() => {
                                        frameContext.onDisplayChange(view?.options.display)
                                        revertChanges()
                                    }}
                                />
                            </>
                        )
                    }}
                </WithRecords>
            )}
            <Toast
                show={showSuccessToast}
                onCloseComplete={() => setShowSuccessToast(false)}
                title={toastSuccessText}
            />
            <LoadingOverlay isOpen={showLoadingOverlay} />
        </>
    )
}

export default withRouter(withViews(withFeatures(withObjects(withStack(ListView)))))
