import { FieldPatch } from 'features/admin/fields/common'

import { editableFieldTypeDefinitionMap } from '../definitions/editableFieldTypeDefinitions'
import { CreatableFieldTypeKey } from '../definitions/fieldTypeDefinitions'

import { getDynamicFieldLabel } from './getDynamicFieldLabel'
import { getUniqueFieldLabel } from './getUniqueFieldLabel'

const NEEDS_DYNAMIC_LABELS = [
    'lookup',
    'multi_lookup',
    'rollup_related_records',
    'lookup_related_value',
    'relationship',
]

export const getDefaultFieldLabel = (
    fieldType: CreatableFieldTypeKey,
    objectId: string,
    fieldId?: string,
    fieldPatch?: FieldPatch
): string | undefined => {
    if (NEEDS_DYNAMIC_LABELS.includes(fieldType)) {
        return getDynamicFieldLabel({ fieldType, objectId, fieldId, field: fieldPatch })
    }

    let defaultFieldLabel = editableFieldTypeDefinitionMap[fieldType].default_label

    // Ensure that the fieldPatch label is unique
    defaultFieldLabel = getUniqueFieldLabel(defaultFieldLabel, objectId, fieldId)

    return defaultFieldLabel
}
