const ALLOWED_STRING_TYPES = ['string', 'long_text', 'url']
const ALLOWED_NUMERIC_TYPES = ['number']
const ALLOWED_SYNTHETIC_FIELD_TYPES = ['record_id']
const ALLOWED_TYPES = [...ALLOWED_STRING_TYPES, ...ALLOWED_NUMERIC_TYPES]

export const isAllowedAutomaticLinkType = (field: FieldDto): boolean => {
    const valid_synthetic_type = Boolean(
        field.synthetic_field_type &&
            ALLOWED_SYNTHETIC_FIELD_TYPES.includes(field.synthetic_field_type)
    )
    const valid_type = Boolean(!field.synthetic_field_type && ALLOWED_TYPES.includes(field.type))
    return field && !field.connection_options?.is_disabled && (valid_type || valid_synthetic_type)
}

export const isMatchingFieldType = (
    fieldType: FieldType,
    selectedFieldType: FieldType | null
): boolean => {
    if (!selectedFieldType) return true
    const stringMatch =
        ALLOWED_STRING_TYPES.includes(selectedFieldType) && ALLOWED_STRING_TYPES.includes(fieldType)
    const numberMatch =
        ALLOWED_NUMERIC_TYPES.includes(selectedFieldType) &&
        ALLOWED_NUMERIC_TYPES.includes(fieldType)
    return stringMatch || numberMatch
}
