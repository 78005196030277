import * as React from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import TrialProgress from 'features/billing/TrialProgress'
import UpgradeButton from 'features/billing/UpgradeButton'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { SidebarItem } from '../../v2/ui'

import { isSubscriptionCancelled } from './stackerUserUtils'

/**
 * list of sidebar items
 * @type {{id: WorkspaceSettingsPage, name: string, icon: string}[]}
 */
const menu = [
    {
        name: 'General',
        id: 'general',
        icon: 'cog',
    },
    {
        name: 'Appearance',
        id: 'appearance',
        icon: 'brush',
    },
    {
        name: 'Billing',
        id: 'billing',
        icon: 'creditCard',
        rightRequired: Rights.ManageBilling,
        isDisabled: (account) =>
            account?.subscription_status === 'trial' || !account?.stripe_customer_id,
    },
    {
        name: 'Collaborators',
        id: 'users',
        icon: 'users',
    },
    {
        name: 'Advanced',
        id: 'advanced',
        icon: 'cogs',
        rightRequired: Rights.ManageUsers,
    },
]
export function Sidebar({ onChange, active, onClose }) {
    const { hasRight } = useAccountUserContext()
    const { selectedStack, workspaceAccount } = useAppContext()
    const inTrial = workspaceAccount?.trial_ui_enabled && workspaceAccount?.is_using_trial
    return (
        <Flex
            column
            style={{
                width: 250,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                overflowY: 'auto',
                padding: 20,
                flexShrink: 0,
            }}
        >
            <div style={{ ...V4DesignSystem.label, paddingLeft: 10 }}>Settings</div>
            <div style={{ height: 5 }} />
            {menu
                .filter(({ rightRequired }) => !rightRequired || hasRight(rightRequired))
                .filter(
                    ({ isDisabled }) =>
                        !isDisabled ||
                        (typeof isDisabled === 'function' &&
                            !isDisabled(workspaceAccount, selectedStack))
                )
                .filter(
                    ({ optionalFeatureRequired }) =>
                        !optionalFeatureRequired ||
                        !!selectedStack?.combined_optional_features?.[optionalFeatureRequired]
                )
                .map(({ icon, name, id }) => (
                    <React.Fragment key={id}>
                        <SidebarItem
                            active={active === id}
                            onChange={() => onChange(id)}
                            icon={icon}
                        >
                            {name}
                        </SidebarItem>
                        <div style={{ height: 1 }} />
                    </React.Fragment>
                ))}
            <div style={{ marginTop: 'auto' }}>
                <TrialProgress
                    showPlanButton={hasRight(Rights.ManageBilling)}
                    onClose={onClose}
                    onChange={onChange}
                />
                {!inTrial && !isSubscriptionCancelled(workspaceAccount) && (
                    <UpgradeButton
                        data-testId="workspace-settings.upgrade"
                        fromWorkspaceSettingsModal={true}
                    />
                )}
            </div>
        </Flex>
    )
}
