import React, { useEffect, useRef, VFC } from 'react'

import { Banner, Box, SlideInBox } from 'v2/ui'
import type { Variants } from 'v2/ui/components/Banner'

import { SHOW_PASTING_BANNER_DURATION_MS } from '../constants'

type Props = {
    show: boolean
    text: string
    icon: string
    variant: Variants
    onHide: () => void
    useTimer?: boolean
}

const PastingBanner: VFC<Props> = ({ show, icon, text, variant, onHide, useTimer }) => {
    const timerIdRef = useRef<number | null>(null)

    useEffect(() => {
        if (!show) {
            return
        }

        if (timerIdRef.current !== null) {
            clearTimeout(timerIdRef.current)
            timerIdRef.current = null
        }

        if (useTimer) {
            // Ignore the TS error here because it raises as a NodeJS timer instead of the browser one
            // @ts-ignore
            timerIdRef.current = setTimeout(() => {
                onHide()
                timerIdRef.current = null
            }, SHOW_PASTING_BANNER_DURATION_MS)
        }
    }, [show, onHide, useTimer])

    return (
        <SlideInBox slideIn={show} slideOut={!show}>
            <Box ml={3} mb={3}>
                <Banner icon={icon} variant={variant}>
                    {text}
                </Banner>
            </Box>
        </SlideInBox>
    )
}

export default PastingBanner
