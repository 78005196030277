// @ts-strict-ignore
import React from 'react'

import { Heading, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Icon8 } from 'legacy/v1/ui'

import { Box, Collapse, Text } from 'v2/ui'

import { StepStatus, StepType } from './importSteps'

type StepProps = {
    step: any
}

const Step = ({ step }: StepProps) => {
    const stepDetail = stepDetails()[step?.type]
    const statusDetail = statusDetails[step?.status]

    return (
        <Collapse isOpen={true}>
            <Card
                style={{
                    marginBottom: 10,
                    borderTop: `5px solid ${statusDetail.highlightColor}`,
                    width: '100%',
                    padding: 8,
                }}
            >
                <Section>
                    <Container>
                        <div style={{ display: 'inline-flex', marginRight: 'auto' }}>
                            <Heading style={{ fontSize: 15, margin: '0px', padding: '0px' }}>
                                <Box as="span" display="flex" alignItems="center">
                                    {/* @ts-expect-error */}
                                    <Icon8
                                        icon={stepDetail.icon}
                                        size="100"
                                        displaySize="20"
                                        iconStyle="ios-filled"
                                    />
                                    <span>{stepDetail.title}</span>
                                </Box>
                            </Heading>
                        </div>
                        <div>
                            {step.status === StepStatus.STATUS_STARTED && (
                                <Spinner size="sm" className="chromatic-ignore" />
                            )}
                            {step.status === StepStatus.STATUS_COMPLETE && (
                                // @ts-expect-error
                                <Icon8
                                    icon="checked"
                                    size="100"
                                    displaySize="20"
                                    iconStyle="color"
                                />
                            )}
                        </div>
                    </Container>
                    <Container>
                        <Text fontSize="sm" my={2} alignSelf="center">
                            {step.description || stepDetail.description}
                        </Text>
                    </Container>
                </Section>
            </Card>
        </Collapse>
    )
}

const stepDetails = () => {
    const details = {
        [StepType.STEP_TABLE_CREATION]: {
            title: 'Importing Table',
            icon: 'table-1',
        },
        [StepType.STEP_RECORD_CREATION]: {
            title: 'Importing Records',
            icon: 'select-column',
        },
        [StepType.STEP_COMPLETE]: {
            title: 'Import Complete',
            icon: 'available-updates',
        },
        [StepType.STEP_STATUS_ERROR]: {
            title: 'Error on import',
            icon: 'error',
        },
    }

    return details
}

const statusDetails = {
    [StepStatus.STATUS_STARTED]: { highlightColor: '#FFA500', backgroundColor: '' },
    [StepStatus.STATUS_COMPLETE]: { highlightColor: '#2fd24a', backgroundColor: '' },
    [StepStatus.STATUS_ERROR]: { highlightColor: 'red', backgroundColor: '' },
}

export default Step

const Card = styled('div')`
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
    border: 0px solid #e8e8e8;

    padding: 16px;

    cursor: inherit;
`

const Section = styled('div')`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    padding: 8px;
`

const Container = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    width: 100%;
`
