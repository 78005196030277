import React, { useMemo } from 'react'

import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox'
import shortid from 'shortid'

import { extractStandardProps, StandardComponentProps } from '../../helpers/styles'

import * as Parts from './Checkbox.parts'

import { CheckboxVariants } from './Checkbox.css'

type CheckboxProps = Omit<RadixCheckboxProps, 'onChange' | 'asChild'> &
    CheckboxVariants &
    StandardComponentProps & {
        id?: string
        variant?: 'app'
    }

export const Checkbox: React.FC<CheckboxProps> = ({ id, variant, className, ...props }) => {
    // this is so the id property can be optional, but still use the
    // label htmlFor property to link the label to the input.
    const effectiveId = useMemo(() => id || shortid.generate(), [id])
    // Extract the standard component style props and pass those along to the container.
    // Everything else goes to the Root part.
    const [standardProps, rootProps] = extractStandardProps(props)
    return (
        <Parts.Container className={className} {...standardProps}>
            <Parts.Root id={effectiveId} {...rootProps} variant={variant}>
                <Parts.Indicator>
                    <Parts.CheckIcon />
                </Parts.Indicator>
            </Parts.Root>
            {props.children && (
                <Parts.Label htmlFor={effectiveId} disabled={rootProps.disabled}>
                    {props.children}
                </Parts.Label>
            )}
        </Parts.Container>
    )
}
