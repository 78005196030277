import React, { useEffect } from 'react'

import { useOAuthFlow } from 'features/AppSettings/DataSources/Airtable/useOAuthFlow'

import { Button } from 'v2/ui'

export type AirtableConnectionEditorOAuthStepParams = {
    dataConnection: DataConnectionDto
    getOAuthReturnUrl: (dc: DataConnectionDto) => string
}

export const AirtableConnectionEditorOAuthStep: React.FC<
    AirtableConnectionEditorOAuthStepParams
> = ({ dataConnection, getOAuthReturnUrl }) => {
    const { onStart, modal } = useOAuthFlow({
        dataConnectionSid: dataConnection?._sid,
        returnUrl: dataConnection ? getOAuthReturnUrl(dataConnection) : undefined,
    })

    useEffect(() => {
        onStart()
        // we want to run this only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <p>
                To connect your Airtable base, you will need to grant us access to your Airtable
                account.
            </p>
            <Button variant="Primary" buttonSize="sm" onClick={onStart}>
                Grant Access
            </Button>
            {modal}
        </div>
    )
}
