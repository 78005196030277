import React from 'react'

import { ConditionalWrapper, Heading, Link } from 'v2/ui'

import { useDataListBlockViewUrl } from './useDataListBlockViewUrl'

type DataListBlockHeaderTitleProps = {
    title?: string
    objectId?: string
}

export const DataListBlockHeaderTitle: React.FC<DataListBlockHeaderTitleProps> = ({
    title,
    objectId,
}) => {
    const viewUrl = useDataListBlockViewUrl(objectId)

    return (
        <Heading as="h1" flex="none" flexShrink="1" variant="containerLabel">
            <ConditionalWrapper
                condition={Boolean(viewUrl)}
                wrapper={(children) => (
                    <Link
                        to={viewUrl}
                        _hover={{
                            color: 'brand.400',
                        }}
                    >
                        {children}
                    </Link>
                )}
            >
                {title}
            </ConditionalWrapper>
        </Heading>
    )
}
