export const getBarChartOptions = (chart: ChartBlockAttributes) => {
    return {
        yAxis: true,
        legend: Boolean(chart.display.showLabels) ? 'xAxis' : ('bottom' as 'xAxis' | 'bottom'),
        maxItems: chart.display.limitNumberItems,
        layout: chart.display.layout,
        showStacked: chart.display.showStacked ?? false,
        showLabels: chart.display.showLabels ?? true,
        showValues: chart.display.showValues ?? true,
    }
}
