import React from 'react'

import Box from './Box'

type BackgroundImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    src: string | null
    objectFit?: 'cover' | 'contain'
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
    src,
    objectFit = 'cover',
    size,
    borderRadius = 0,
    ...props
}) => (
    <Box
        backgroundImage={`url(${src})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={objectFit}
        size={size}
        borderRadius={objectFit == 'contain' ? 0 : borderRadius}
        {...props}
    />
)

export default React.memo(BackgroundImage)
