import React from 'react'

import styled from '@emotion/styled'

const ScreenReaderText = ({ children }) => {
    const ScreenReaderSpan = styled.span`
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
        -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
    `
    return <ScreenReaderSpan>{children}</ScreenReaderSpan>
}

export default ScreenReaderText
