import React, { useCallback, useEffect, useRef } from 'react'
import Markdown from 'react-markdown'

import styled from '@emotion/styled'

import richTextStyles from 'v2/ui/theme/styles/richText'

import QuillRichText from '../../../features/pages/blocks/blockTypes/form/QuillRichText'

const Display = styled(Markdown)(richTextStyles)

const cleanUpMarkdown = (value) => {
    // Airtable markdown is often invalid like this
    // Sentenced with **bold **text in it
    // These regex's correct this for the supported marks to
    // Sentenced with **bold** text in it
    if (typeof value !== 'string') return value

    value = value.replace(/(\*\*.+?)(\s*)(\*\*)/g, '$1**$2')
    value = value.replace(/(__.+?)(\s*)(__)/g, '$1__$2')
    value = value.replace(/(_.+?)(\s*)(_)/g, '$1_$2')
    value = value.replace(/(`.+?)(\s*)(`)/g, '$1`$2')
    value = value.replace(/(~~.+?)(\s*)(~~)/g, '$1~~$2')
    value = value.replace(/(^\s*)(\[ \])/gm, '$1☐')
    value = value.replace(/(^\s*)(\[x\])/gm, '$1☑')
    return value
}

// AirTable automatically escapes these characters with \ in their rich text fields.
// This causes a problem for us with URLs that contain these characters. We need to
// unescape them at render time.
// Characters: *_\`]{
const unescape_regex = /\\([\*\]\_\`\\\{])/g

const fixLink = (uri) => {
    return uri && uri.replace(unescape_regex, '$1')
}

const fixLinks = (text) => {
    if (typeof text !== 'string') {
        return text
    }
    return (
        text &&
        text.replace(/http.*?(\s|$)/g, (match) => {
            return fixLink(match)
        })
    )
}

const MarkdownText = (props) => {
    const displayRef = useRef(null)

    const addOnClickToLinks = useCallback(() => {
        var elements = displayRef?.current?.getElementsByTagName('a')
        for (var i = 0, len = elements?.length; i < len; i++) {
            elements[i].onclick = function (e) {
                e.preventDefault()
                e.stopImmediatePropagation()
                window.open(e.target.href)
            }
        }
    }, [displayRef])

    useEffect(() => {
        // Open link in a new tab without navigating to details view.
        if (!props.showRichTextEditor) {
            addOnClickToLinks()
        }
    }, [props.showRichTextEditor, props.children, addOnClickToLinks])

    return props.showRichTextEditor ? (
        <QuillRichText
            value={fixLinks(props.children)}
            resize="none"
            width="100%"
            margin="none"
            readOnly={true}
            defaultTheme="bubble"
            background={props.background || 'transparent'}
            convertToMarkdown={props.convertToMarkdown}
            containerHeadingSize={props.containerHeadingSize}
        />
    ) : (
        <div ref={displayRef}>
            <Display source={cleanUpMarkdown(props.children)} transformLinkUri={fixLink} />
        </div>
    )
}
export default MarkdownText
