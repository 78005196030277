import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidChevronLeftProps = {
    color?: string
    size?: number
    doNotScale?: boolean
}
const SolidChevronLeft: React.VFC<SolidChevronLeftProps> = ({
    color = colors.userInterface.neutral[700],
    size = 6,
    doNotScale,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 10) / 6}
            viewBox={doNotScale ? '' : '0 0 6 10'}
            fill="none"
        >
            <path
                d="M4.72949 0.33465C4.85212 0.333941 4.9737 0.357447 5.08723 0.40382C5.20077 0.450194 5.30403 0.518524 5.39111 0.60489C5.47845 0.691519 5.54777 0.794582 5.59508 0.908138C5.64239 1.02169 5.66675 1.14349 5.66675 1.26651C5.66675 1.38953 5.64239 1.51133 5.59508 1.62488C5.54777 1.73844 5.47845 1.8415 5.39111 1.92813L2.30665 4.99395L5.26996 8.0784C5.44352 8.253 5.54094 8.48918 5.54094 8.73537C5.54094 8.98155 5.44352 9.21773 5.26996 9.39233C5.18334 9.47967 5.08027 9.54899 4.96672 9.5963C4.85316 9.64361 4.73136 9.66797 4.60834 9.66797C4.48533 9.66797 4.36353 9.64361 4.24997 9.5963C4.13642 9.54899 4.03335 9.47967 3.94672 9.39233L0.349746 5.66489C0.179005 5.4907 0.0833673 5.2565 0.0833673 5.01259C0.0833673 4.76867 0.179005 4.53448 0.349746 4.36028L4.07718 0.632845C4.16083 0.542518 4.26154 0.469659 4.3735 0.418477C4.48546 0.367294 4.60645 0.338803 4.72949 0.33465Z"
                fill={color}
            />
        </svg>
    )
}

export default SolidChevronLeft
