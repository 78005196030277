/* Code Quality: Not audited */

import React, { useState } from 'react'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { useStackDetails } from 'data/hooks/stackDetails'
import { useUserLists } from 'data/hooks/userLists'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import WithStacks from 'data/wrappers/WithStacks'
import AttributeList from 'features/records/components/AttributeList'
import { Button, Card, Container, Section, SectionHeader, Text } from 'legacy/v1/ui'
import Breadcrumbs from 'legacy/v1/ui/components/Breadcrumbs'
import PageTitle from 'legacy/v1/ui/components/PageTitle'

import { Box } from 'v2/ui'

import TemplateIcon from '../../templates/TemplateIcon'
import { TEMPLATE_CATEGORIES } from '../../templates/TemplateLibrarySidebar'

const CACHES = [{ label: 'Postcache', value: 'postcache' }]

// see readme in this directory
const d = (s) => JSON.parse(atob(s)).join('')
const _k = d(
    'WyJfIiwiXyIsIl8iLCJzIiwidCIsImEiLCJjIiwiayIsInMiLCJlIiwidCIsInQiLCJpIiwibiIsImciLCJzIl0='
)
const _p = d(
    'WyI2IiwibiIsIm4iLCIqIiwidSIsIjIiLCJyIiwiLiIsIjQiLCJLIiwidiIsIjgiLCJNIiwiOSIsIm4iLCJLIiwiQiIsInEiLCJXIiwiNCIsIjgiLCJZIiwiLiIsIl8iLCJUIiwiLiIsIkAiLCJKIiwiZSIsIkoiXQ=='
)
const _m = d('WyJQIiwiYSIsInMiLCJzIiwidyIsIm8iLCJyIiwiZCJd')

const StackSettingsPage = () => {
    const [show, setShow] = useState(!settings.IS_PROD || localStorage.getItem(_k) === 'true')
    const { data: users = [] } = useAppUsersForAdmin(true)
    const { data: userLists } = useUserLists()
    const { data: stackDetails } = useStackDetails()

    if (!show) {
        const __p = prompt(_m)

        if (__p === _p) {
            localStorage.setItem(_k, 'true')
            setShow(true)
        }
    }

    if (!show) {
        return null
    }

    const isEligibleForNUMa = (users, userLists, workspaceAccount, stack) => {
        return (
            stack?.options?.workspace_app &&
            workspaceAccount?.optional_features?.sync_stacker_users &&
            !workspaceAccount?.optional_features?.auth0_enabled &&
            !(userLists?.length > 0) &&
            !(users?.filter(({ role }) => role !== 'internal_admin')?.length > 0)
        )
    }

    return (
        <div style={{ margin: '30px' }}>
            <WithStacks>
                {({ workspaceAccount, selectedStack: stack, onChange }) => {
                    return (
                        <>
                            <Container marginBottom="medium" spaced>
                                <Section margin="none" padding="none">
                                    <Breadcrumbs
                                        crumbs={[
                                            { label: 'Stack', link: getUrl(Urls.StudioStack) },
                                        ]}
                                    />
                                    <PageTitle>{stack.name} </PageTitle>
                                </Section>
                            </Container>
                            <SectionHeader>Stack Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack}
                                        onChange={(vals) => onChange(stack._sid, vals)}
                                        fields={[
                                            { api_name: 'name', label: 'Name' },
                                            {
                                                api_name: 'custom_base_url',
                                                label: 'Custom Url',
                                            },
                                        ]}
                                    />
                                    <AttributeList
                                        data={stackDetails}
                                        fields={[{ api_name: 'stack_mode', label: 'Stack Mode' }]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Custom Scripts</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Text>You must enclose in {'<script>'} tags</Text>
                                <Container>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'custom_scripts',
                                                label: 'Scripts to run once',
                                                type: 'long_text',
                                            },
                                            {
                                                api_name: 'custom_page_scripts',
                                                label: 'Scripts to run per page change',
                                                type: 'long_text',
                                            },
                                            {
                                                api_name: 'fullstory_org_id',
                                                label: 'Full story organisation id',
                                            },
                                            {
                                                api_name: 'intercom_app_id',
                                                label: 'Intercom app id',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Account Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack}
                                        onChange={(vals) => onChange(stack._sid, { ...vals })}
                                        fields={[
                                            {
                                                api_name: 'team_plan',
                                                label: 'Enable Team Plan',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Optional Features</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) => {
                                            if (
                                                vals.sync_users_as_auths &&
                                                !isEligibleForNUMa(
                                                    users,
                                                    userLists,
                                                    workspaceAccount,
                                                    stack
                                                )
                                            ) {
                                                alert(
                                                    `This app cannot be automatically converted to NUMa. Apps with any of the following features are currently ineligible for conversion: Auth0, open signup, non-admin users, and user lists. Please contact engineering for assistance.`
                                                )
                                                return
                                            }
                                            return onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }}
                                        fields={[
                                            {
                                                api_name: 'enable_advanced_mode',
                                                label: 'Enable Advanced Editor Mode',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_feature_builder',
                                                label: 'Enable Feature Builder',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_studio_link',
                                                label: 'Enable Studio Link',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'whitelist_new_users',
                                                label: 'Register new users from a whitelist',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_right_aligned_menu',
                                                label: 'Enable right aligned menu',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_multiple_permissions',
                                                label: 'Enable multiple permission rules',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'secondary_navigation',
                                                label: 'Secondary navigation',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'ui_version',
                                                label: 'UI Version',
                                                type: 'dropdown',
                                                options: {
                                                    options: [
                                                        { label: '1', value: 1 },
                                                        { label: '2', value: 2 },
                                                    ],
                                                },
                                            },
                                            {
                                                api_name: 'enable_field_widget',
                                                label: 'Remove Field Palette',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'new_create_form',
                                                label: 'New Create Form',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'launched',
                                                label: 'Launched',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'read_only_data',
                                                label: 'Read Only',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_internal_preview_banner',
                                                label: 'Internal UI Preview Banner',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_internal_data_editor',
                                                label: 'Internal UI – Enable data editor',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'stacker_generated_ids',
                                                label: 'Enable Stacker generated IDs on Gsheets',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'workspace_app',
                                                label: 'Workspace App',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multi_data_source',
                                                label: 'Multi-data-source mode',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fullstory_force',
                                                label: 'Force Fullstory on',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'stacker_relationships',
                                                label: 'Stacker Relationships',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_many_to_many',
                                                label: 'Many-to-many relationships',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_linked_tables_app_settings',
                                                label: 'Linked tables in app settings',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'activity_label',
                                                label: 'Comments Tab Label',
                                            },
                                            {
                                                api_name: 'enable_new_app_settings',
                                                label: 'New App Settings',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'roles__enabled',
                                                label: 'Roles enabled',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'passwordless',
                                                label: 'Enable Passwordless Login',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'sync_users_as_auths',
                                                label: 'Sync users as auths',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'always_show_stacker_user_table',
                                                label: 'Always show stacker user table',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'allow_custom_brand_color',
                                                label: 'Allow custom app color',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'disable_sharing_links',
                                                label: 'Disable sharing links',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'show_intercom_on_login_page',
                                                label: 'Show intercom on login page',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name:
                                                    'end_user_collaboration_email_notifications',
                                                label: 'End user collaboration notifications',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>
                                Premium Features{' '}
                                {stack?.options?.workspace_app && (
                                    <>
                                        (App overrides){' '}
                                        {JSON.stringify(stack.optional_features) !==
                                            JSON.stringify({}) && (
                                            <Button
                                                onClick={() => {
                                                    onChange(stack._sid, {
                                                        optional_features: {},
                                                    })
                                                }}
                                            >
                                                Clear All
                                            </Button>
                                        )}
                                    </>
                                )}
                            </SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.combined_optional_features}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                optional_features: {
                                                    ...stack.optional_features,
                                                    ...vals,
                                                },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'whitelabel',
                                                label: 'Whitelabel',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multiple_list_views',
                                                label: 'Multiple list views',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },

                                            {
                                                api_name: 'multiple_permission_rules',
                                                label: 'Multiple permission rules',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multiple_roles',
                                                label: 'Multiple roles',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'kanban',
                                                label: 'Kanban View',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fast_sync',
                                                label: 'Fast Sync',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'custom_scripts',
                                                label: 'Custom Scripts',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'self_serve_custom_scripts',
                                                label: 'Self-serve custom scripts',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'client_logos',
                                                label: 'Client Logos',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            // {
                                            //     api_name: 'custom_css',
                                            //     label: 'Custom CSS',
                                            //     type: 'checkbox',
                                            //     options: { is_toggle: true },
                                            // },
                                            {
                                                api_name: 'global_custom_css',
                                                label: 'Global Custom CSS',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },

                                            {
                                                api_name: 'custom_domain',
                                                label: 'Custom domain',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'collaboration',
                                                label: 'Collaboration comments',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'action_buttons',
                                                label: 'Action Buttons',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'charts',
                                                label: 'Charts',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multi_tabs_detailview',
                                                label: 'Multi tabs on details view',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'new_gsheets_connections',
                                                label: 'Allow New GSheets Connections',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'new_salesforce_connections',
                                                label: 'Allow New Salesforce Connections',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fivetran_connector',
                                                label: 'Fivetran Data Connector',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'inbox_view',
                                                label: 'Inbox View',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'third_party_tags',
                                                label: 'Third party tags',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Auth0 Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'auth0__url',
                                                label: 'Auth0 URL',
                                            },

                                            {
                                                api_name: 'auth0__client_id',
                                                label: 'Auth0 Client ID',
                                            },

                                            {
                                                api_name: 'auth0__client_secret',
                                                label: 'Auth0 Client Secret',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Template Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'match_tables_to_template',
                                                label: 'Match tables to template',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'template_name',
                                                label: 'Template Name',
                                            },

                                            {
                                                api_name: 'template_url',
                                                label: 'Template URL',
                                            },

                                            {
                                                api_name: 'template_image_url',
                                                label: 'Template Image URL',
                                            },
                                            {
                                                api_name: 'is_clonable',
                                                type: 'checkbox',
                                                label: 'Publicly clonable as demo app',
                                            },
                                            {
                                                api_name: 'is_demo',
                                                type: 'checkbox',
                                                label: 'Is a demo app',
                                            },
                                        ]}
                                    />
                                    {stack.options?.is_clonable && (
                                        <Box
                                            mt="16px"
                                            p="8px"
                                            border="2px solid rgba(0,0,0,0.4)"
                                            borderRadius="4px"
                                        >
                                            Demo id for register page query param
                                            <Box
                                                as="span"
                                                ml="16px"
                                                p="2px 6px"
                                                fontWeight="600"
                                                background="rgba(0,0,0,0.1)"
                                                borderRadius="2px"
                                            >
                                                {stack._sid.split('.')[1]}
                                            </Box>
                                        </Box>
                                    )}
                                </Container>
                            </Card>
                            <SectionHeader>Email Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'default_from_email',
                                                label: 'Default from email',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Caching &amp; schema sync</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'caching__precache_tables',
                                                label: 'Periodically refresh the cache',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'schema__enable_auto_updates',
                                                label: 'Auto schema sync',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'caching__backend',
                                                label: 'Cache backend',
                                                type: 'dropdown',
                                                options: {
                                                    options: CACHES,
                                                },
                                            },
                                            {
                                                api_name: 'apply_archive_filters_to_cache',
                                                label: 'Archive filters',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Debug settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'debug__enable_data_debugger',
                                                label: 'Enable data debugger recording',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'ignore_for_status_monitoring',
                                                label: 'Ignore for status monitoring',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>V4 template settings</SectionHeader>
                            {!workspaceAccount?.options?.permit_template_authoring && (
                                <Card style={{ marginTop: 10, marginBottom: 3 }}>
                                    <Text style={{ margin: '8px 24px' }}>
                                        ⚠️ This account is not permitted to author templates.
                                    </Text>
                                </Card>
                            )}
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Text style={{ margin: '8px 24px' }}>
                                    Templates are only supported for single-data-source GSheets apps
                                    for the moment.
                                </Text>
                                <Container wrapItems>
                                    <Container
                                        style={{
                                            margin: '16px 8px 8px 8px',
                                            padding: '16px',
                                            width: '250px',
                                        }}
                                    >
                                        <TemplateIcon
                                            icon={stack?.options?.theme?.icon}
                                            color={stack?.options?.theme?.brandColor}
                                            fullSize
                                        />
                                        <Text style={{ padding: '4px 0', fontWeight: 'bold' }}>
                                            {stack?.name}
                                        </Text>
                                    </Container>
                                    <AttributeList
                                        editable={
                                            workspaceAccount?.options?.permit_template_authoring
                                        }
                                        data={stack.template_options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                template_options: {
                                                    ...stack.template_options,
                                                    ...vals,
                                                },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'is_template',
                                                label: 'Use this app as a template',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'is_published',
                                                label: 'Publish this app in the public template library',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'categories',
                                                label: 'Categories',
                                                type: 'multi_select',
                                                options: {
                                                    options: TEMPLATE_CATEGORIES,
                                                },
                                            },
                                            {
                                                api_name: 'short_description',
                                                label: 'Short description',
                                                type: 'string',
                                            },
                                            {
                                                api_name: 'long_description',
                                                label: 'Long description (markdown)',
                                                type: 'long_text',
                                            },
                                            {
                                                api_name: 'card_image',
                                                label: 'Card image (~300x200 ideal)',
                                                type: 'image',
                                            },
                                            {
                                                api_name: 'gallery_images',
                                                label: 'Gallery images',
                                                type: 'multi_file',
                                            },
                                            {
                                                api_name: 'ordering_key',
                                                label: 'Ordering key (numeric)',
                                                type: 'number',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                        </>
                    )
                }}
            </WithStacks>
        </div>
    )
}

export default StackSettingsPage
