/* Code Quality: Not audited */

import React, { memo, useMemo } from 'react'

import { injectGlobal } from 'emotion'
import { ThemeProvider } from 'emotion-theming'

import WithStacks from 'data/wrappers/WithStacks'
import { evaluateThemeFormulas } from 'features/studio/theme/utils'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

const ThemeWrapperInner = memo(({ stackTheme, children }) => {
    const siteTheme = useMemo(
        () =>
            evaluateThemeFormulas({
                ...stackerTheme,
                ...stackTheme,
            }),
        [stackTheme]
    )

    return (
        <ThemeProvider theme={siteTheme}>
            {children({ theme: siteTheme })}
            {injectGlobal`
                page > a {
                color: ${siteTheme.linkTextColor};
                }
                `}
        </ThemeProvider>
    )
})

const ThemeWrapper = (props) => {
    // When not authenticated as a studio user, the theme
    // is accessed via App (the backend copies it there from Stack)
    // This is so that a non-studio user doesn't need to access Stack

    if (props.loadFromStack) {
        return (
            <WithStacks>
                {({ selectedStack }) => {
                    const stackTheme =
                        (selectedStack && selectedStack.options && selectedStack.options.theme) ||
                        {}

                    return (
                        <ThemeWrapperInner stackTheme={stackTheme}>
                            {props.children}
                        </ThemeWrapperInner>
                    )
                }}
            </WithStacks>
        )
    }
    return <ThemeWrapperInner stackTheme={props.app.theme}>{props.children}</ThemeWrapperInner>
}
export default ThemeWrapper
