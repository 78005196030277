/* Code Quality: OK */
import React from 'react'

import { getUrl, Urls } from 'app/UrlService'

class SupportLogin extends React.Component {
    // This is a special page that is only enabling a support_login flag
    // so we know if we're logged in as member of Stacker
    // so we can disable stuff like Intercom
    // Authentication via api_token happens on PrivateRoute, so nothing else to do here
    constructor(props) {
        super(props)

        localStorage.setItem('support_login', 'true')
        window.location = `${getUrl(Urls.Admin)}${window.location.search}`
    }

    render() {
        return ''
    }
}

export default SupportLogin
