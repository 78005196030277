// @ts-strict-ignore
import React, { SVGProps, useCallback, useEffect, useMemo, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { GridColumn, SpriteMap, Theme } from '@glideapps/glide-data-grid'

import { useAppContext } from 'app/AppContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { assertIsDefined } from 'data/utils/ts_utils'
import {
    fieldTypeComponentList,
    toFieldTypeComponentData,
} from 'features/admin/fields/definitions/fieldTypeComponents'
import isRichTextField from 'utils/isRichTextField'

import { Lock, SolidArrowDown, SolidArrowUp, Warning } from 'v2/ui/svgs'
import { AddField } from 'v2/ui/svgs/field-icons'
import { WrapIconWithSync } from 'v2/ui/svgs/WrapIconWithSync'
import stackerTheme from 'v2/ui/theme/styles/default'

import { getEditableFieldTypeDefinition } from '../../admin/fields/definitions/editableFieldTypeDefinitions'
import { getIsFieldConfigurable } from '../../admin/fields/logic/availableFieldOperationUtils'
import { getIsFieldSynced } from '../../admin/fields/logic/getIsFieldSynced'
import { AddFieldColumnThemeOverride } from '../components/dataGridThemeUtils'
import localConfiguration from '../localConfiguration'
import type { FieldGridColumn } from '../types'

import { useSortingByFieldsOrder } from './useDefaultFieldsOrder'

const { colors } = stackerTheme()

export type AddFieldColumnType = GridColumn & {
    isAddNew: true
    width: number
    title: string
}

const IconWithPadding = ({
    Icon,
    color,
    ...props
}: {
    Icon: React.ComponentType<SVGProps<SVGElement>>
    color: string
}) => {
    // Need to expand the view box a bit to get the icon the right size for the header
    // and the way Glide renders it
    return <Icon viewBox="-2 -2 15 16" stroke={color} fill={color} {...props} />
}

const renderFieldTypeIcon = (Icon: React.ComponentType<SVGProps<SVGElement>>, color: string) => {
    return renderToStaticMarkup(<IconWithPadding Icon={Icon} color={color} />)
}

const renderFieldTypeIconSynced = (
    Icon: React.ComponentType<SVGProps<SVGElement>>,
    color: string
) => {
    // We wrap the render icon with a mask

    const MyIcon = (props) => <IconWithPadding {...props} color={color} Icon={Icon} />
    return renderToStaticMarkup(<WrapIconWithSync Icon={MyIcon} color={color} />)
}

const headerIcons = (() => {
    let result: SpriteMap = fieldTypeComponentList.reduce((result, fieldType) => {
        if (fieldType?.iconComponent) {
            result[fieldType.value] = (p) => {
                assertIsDefined(fieldType?.iconComponent)
                return renderFieldTypeIcon(fieldType?.iconComponent, p.bgColor)
            }
        }

        return result
    }, {})

    result = fieldTypeComponentList.reduce((result, fieldType) => {
        if (fieldType?.iconComponent) {
            result[`${fieldType.value}-synced`] = (p) => {
                assertIsDefined(fieldType?.iconComponent)
                return renderFieldTypeIconSynced(fieldType?.iconComponent, p.bgColor)
            }
        }

        return result
    }, result)

    result['add'] = (p) => renderFieldTypeIcon(AddField, p.bgColor)
    result['down'] = () =>
        renderToStaticMarkup(<SolidArrowDown color={colors.userInterface.accent[1000]} />)
    result['up'] = () =>
        renderToStaticMarkup(<SolidArrowUp color={colors.userInterface.accent[1000]} />)
    result['warning'] = () =>
        renderToStaticMarkup(
            <Warning backgroundColor="white" color={colors.userInterface.warning[1000]} />
        )
    result['protected'] = () =>
        renderToStaticMarkup(<Lock fill={colors.userInterface.accent[1000]} />)

    return result
})()

function fieldToColumn({
    allowConfigureFields,
    dataConnectionType,
    field,
    orderBy,
    themeOverride,
    width,
}: {
    allowConfigureFields?: boolean
    dataConnectionType?: DataConnectionType
    field: FieldDto
    orderBy?: { id: string; desc?: boolean }
    themeOverride?: Partial<Theme>
    width?: number
}): FieldGridColumn {
    const isConfigurable = getIsFieldConfigurable({ dataConnectionType, field })
    const defaultWidth = field.type === 'long_text' || isRichTextField(field) ? 200 : 120
    const isSynced = getIsFieldSynced({ dataConnectionType, field })
    const rawFieldType = getEditableFieldTypeDefinition(field)
    const fieldType = rawFieldType ? toFieldTypeComponentData(rawFieldType) : undefined
    assertIsDefined(fieldType)

    let overlayIcon
    if (field?.api_name === orderBy?.id && !orderBy?.desc) {
        overlayIcon = 'up'
    } else if (field?.api_name === orderBy?.id && orderBy?.desc) {
        overlayIcon = 'down'
    } else if (field.connection_options.is_protected) {
        overlayIcon = 'protected'
    }

    const baseIcon = fieldType?.iconComponent ? fieldType?.value : undefined
    const icon = baseIcon && isSynced ? `${baseIcon}-synced` : baseIcon

    return {
        title: field.label,
        width: width || defaultWidth,
        field,
        hasMenu: isConfigurable && allowConfigureFields,
        themeOverride,
        icon,
        overlayIcon,
    }
}

export const getAddFieldColumn = (addFieldButtonConfig: {
    width: number
    title: string
}): AddFieldColumnType => {
    return {
        isAddNew: true,
        icon: 'add',
        themeOverride: AddFieldColumnThemeOverride,
        ...addFieldButtonConfig,
    }
}

type Props = {
    object: ObjectDto
    allowConfigureFields?: boolean
    showAddFieldColumn?: boolean
    orderBy?: { id: string; desc?: boolean }
    addFieldButtonConfig?: { width: number; title: string }
}
type ReturnType = {
    columns: GridColumn[]
    columnIsSynced: boolean[]
    displayedFields: FieldDto[]
    setColumnWidth: (column: GridColumn, width: number) => void
    headerIcons: SpriteMap
}
export function useDataGridColumns({
    object,
    orderBy,
    allowConfigureFields,
    showAddFieldColumn,
    addFieldButtonConfig,
}: Props): ReturnType {
    const { selectedStack } = useAppContext()

    const { data: dataConnection } = useDataConnection(object?.data_connection)

    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const fields = sortByFieldsOrder(object.fields)

    const [colWidth, setColWidth] = useState<{ [keyof: string]: number }>({})

    const displayedFields = useMemo(() => {
        if (!fields || fields.length === 0) {
            return []
        }

        const primaryField = fields.find(({ is_primary }) => is_primary) as FieldDto
        const allButPrimaryFields = fields?.filter(
            (field) => !field.connection_options?.is_disabled && !field.is_primary
        )

        if (primaryField) {
            return [primaryField, ...allButPrimaryFields]
        } else {
            return allButPrimaryFields
        }
    }, [fields])

    // Create columns from our fields for the object.
    const columns = useMemo<GridColumn[]>(() => {
        return displayedFields
            .filter((field) => !field.connection_options?.is_disabled)
            .map(
                (field, rowIndex) =>
                    fieldToColumn({
                        allowConfigureFields,
                        dataConnectionType: dataConnection?.type,
                        field,
                        orderBy,
                        themeOverride: rowIndex === 0 ? { baseFontStyle: 'bold 13px' } : {},
                        width: !!colWidth ? colWidth[field._sid] : undefined,
                    }) as GridColumn
            )
            .concat(
                showAddFieldColumn && addFieldButtonConfig
                    ? [getAddFieldColumn(addFieldButtonConfig)]
                    : []
            )
    }, [
        addFieldButtonConfig,
        displayedFields,
        showAddFieldColumn,
        dataConnection?.type,
        allowConfigureFields,
        colWidth,
        orderBy,
    ])

    const columnIsSynced = useMemo<boolean[]>(() => {
        return displayedFields
            .filter((field) => !field.connection_options?.is_disabled)
            .map((field) => getIsFieldSynced({ dataConnectionType: dataConnection?.type, field }))
            .concat(showAddFieldColumn && addFieldButtonConfig ? [false] : [])
    }, [addFieldButtonConfig, displayedFields, showAddFieldColumn, dataConnection?.type])

    const setColumnWidth = useCallback(
        (column: GridColumn, newSize: number) => {
            if ('field' in column) {
                const fieldColumn = column as FieldGridColumn
                const newColumnWidths = { ...colWidth, [fieldColumn.field._sid]: newSize }
                assertIsDefined(selectedStack)
                localConfiguration.columnsWidth.set(selectedStack, object, newColumnWidths)
                setColWidth(newColumnWidths)
            }
            return false
        },
        [colWidth, object, selectedStack]
    )

    useEffect(() => {
        if (!selectedStack) {
            return
        }

        setColWidth(localConfiguration.columnsWidth.get(selectedStack, object))
    }, [selectedStack, object])

    return { columns, columnIsSynced, setColumnWidth, displayedFields, headerIcons }
}
export default useDataGridColumns
