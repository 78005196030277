import React, { useRef } from 'react'

import { assertIsDefined } from 'data/utils/ts_utils'
import { UserCancelledError } from 'utils/utils'

import { ConfirmationModal, Icon } from 'v2/ui'
import { useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

const MODAL_KEY = 'Confirm'
export const useConfirmModal = (data) => {
    return useModalDeclaration(MODAL_KEY, ConfirmModal, data)
}

// Shows a confirm modal using a simple show() method which returns
// a promise which resolves when the user clicks Confirm or is rejected
// if the user clicks Cancel.
export function useConfirmModalWithPromise(suppliedData) {
    const resolvePromise = useRef()
    const rejectPromise = useRef()
    const getFinalData = (preliminaryData) => ({
        ...preliminaryData,
        onConfirm: async ({ setIsOpen }) => {
            await preliminaryData?.onConfirm?.()
            assertIsDefined(resolvePromise.current)
            resolvePromise.current(undefined)
            setIsOpen(false)
        },
        onCancel: async ({ setIsOpen }) => {
            await preliminaryData?.onCancel?.()
            assertIsDefined(rejectPromise.current)
            rejectPromise.current(new UserCancelledError())
            setIsOpen(false)
        },
    })

    const show = (overwriteModalData) => {
        showConfirm(getFinalData({ ...suppliedData, ...overwriteModalData }))
        return new Promise((resolve, reject) => {
            resolvePromise.current = resolve
            rejectPromise.current = reject
        })
    }
    const { show: showConfirm } = useConfirmModal(getFinalData(suppliedData))

    return show
}

const ConfirmModal = () => {
    const modal = useModalToggle(MODAL_KEY)
    const { isOpen, toggle, data = {} } = modal
    const {} = data

    const confirmVariant =
        data?.confirmVariant || (data?.endUser ? 'endUserPrimary' : 'adminPrimary')
    const cancelVariant =
        data?.cancelVariant || (data?.endUser ? 'endUserSecondary' : 'adminSecondary')

    if (!data) return null

    const handleConfirm = () => {
        // If caller has specified a confirm handler, then call it now
        if (data.onConfirm) {
            return data.onConfirm(modal)

            // otherwise, just hide the modal
        } else {
            toggle()
        }
    }

    const handleCancel = () => {
        // If caller has specified a cancel handler, then call it now
        if (data.onCancel) {
            data.onCancel(modal)

            // otherwise, just hide the modal
        } else {
            toggle()
        }
    }

    const icon =
        typeof data.icon === 'string' ? (
            <Icon
                size="56px"
                icon={data.icon || 'warning'}
                mb={4}
                color="grey.200"
                display="inline-block"
            />
        ) : (
            data.icon
        )

    let customDetailsComponent
    if (typeof data.message === 'function') {
        customDetailsComponent = data.message(modal)
    } else if (typeof data.message !== 'string') {
        customDetailsComponent = data.message
    }
    const details = !customDetailsComponent ? data.message : undefined
    return (
        <ConfirmationModal
            isOpen={isOpen}
            zIndex={data.zIndex || 9999}
            customSize={data.customSize}
            customConfirm={data.confirmButtonText}
            customCancel={data.cancelButtonText}
            inProgress={data.isSaving}
            title={data.title}
            details={details}
            icon={icon}
            customDetailsComponent={customDetailsComponent}
            onConfirm={handleConfirm}
            onClose={handleCancel}
            confirmVariant={confirmVariant}
            cancelVariant={cancelVariant}
        />
    )
}

export default useConfirmModal
