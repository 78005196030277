import queryString from 'qs'

export function isTest() {
    const host = window.location.host
    return host.includes('stackerlabs.com') || host.endsWith('.local') || host.endsWith('.net')
}

function getBranch() {
    return isTest() ? 'master' : 'prod'
}

function makeGithubRequest(file, use_test_feature_branch) {
    const api = 'https://api.github.com/repos/worktoolsio/stacker-features/contents/'
    const token = '21f09303177376a53a8dd7b96ac881f423be5f45' //This is using Stackerbot's personal token
    //Do not use raw githubusercontent bbecause its token expires...
    //https://stackoverflow.com/questions/38132908/do-github-raw-urls-expire
    //Raw githubusercontent file doens't work with Authorization and CORS, so we need to use the API

    const fetchOptions = { headers: { Authorization: 'Token ' + token } }
    let branch
    // If use_test_feature_branch is undefined, we use prod or master depending on the host
    // if use_test_feature_branch is True, we will use master
    // if use_test_feature_branch is False, we will use prod regarding the host
    if (use_test_feature_branch !== undefined) branch = use_test_feature_branch ? 'master' : 'prod'
    else branch = getBranch()
    console.debug('Fetching features from branch ', branch, use_test_feature_branch)
    const apiUrl = api + file + '.json?ref=' + branch

    return new Promise((resolve, reject) => {
        fetch(apiUrl, fetchOptions)
            .then((response) => {
                response.json().then((json) => {
                    const features = JSON.parse(atob(json.content)) //Decode base64
                    return resolve(features)
                })
            })
            .catch((error) => {
                return reject(error)
            })
    })
}
export function fetchCombination(use_test_feature_branch) {
    /**
     Returns the info.json content from github https://github.com/worktoolsio/stacker-features/blob/master/combination.json
    Schema:
    {"bundle-id": ["feature1", "feature2"...]}


    eg:
    {
        "basic" : [ "news-feed.feature", "messaging.feature" ],
        "crm" : [ "accounts.feature", "activity.feature", "tasks.feature", "calendar.feature"],
        "default": ["accounts.feature"]
    }
    */
    return makeGithubRequest('combination', use_test_feature_branch)
}

export function fetchInfo(use_test_feature_branch) {
    /**
    Returns the info.json content from github https://github.com/worktoolsio/stacker-features/blob/master/info.json
    Schema:
    [{ "name": "string", "file": "string", "icon": "string",  "description": "string", "cover": "string"}]

    eg:
    [
    { "name" : "News Feed", "file" : "news-feed.feature", "icon" : "https://icons8.com/feed.png" },
    { "name" : "Messaging", "file" : "messaging.feature", "icon" : "https://icons8.com/message.png" }
    ]
     */
    return makeGithubRequest('info', use_test_feature_branch)
}

export function getCollection() {
    const query = queryString.parse(window.location.search.slice(1)) // Slice to remove the "?"
    const host = window.location.host
    let collection = 'default'
    if (query.collection) collection = query.collection
    else if (host.startsWith('studio.')) collection = host.split('.')[1]
    return collection
}
