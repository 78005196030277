import React from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { withRoles } from 'data/wrappers/WithRoles'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { getFilterFieldFromId } from 'features/utils/filterToField'
import { getAreRolesEnabled } from 'features/utils/getAreRolesEnabled'

import { Button, Dropdown } from 'v2/ui'

import { getFilterSourceOptions } from './utils/utils'
import { nextFilterOptions } from './constants'
import { FieldFilter } from './FieldFilter'

class _ObjectFieldsFilter extends React.Component {
    state = {
        filters: this.props.value || [],
        isAdding: !this.props.value,
    }

    workspaceApp = get(this.props, 'stackOptions.workspace_app')

    userRoleField =
        this.props.stackOptions?.roles__enabled && this.props.stackOptions?.data_mapping?.role_field
    userObject = get(this.props, 'stackOptions.data_mapping.user_object')

    select = (id) => {
        const field = getFilterFieldFromId(this.props.object, id)

        this.setState(({ filters }) => ({
            isAdding: false,
            filters: [
                ...filters,
                { field, field_sid: field?._sid, options: { operator: 'AND' }, _id: Math.random() },
            ],
        }))
    }

    onChangeField = ({ id, options }) => {
        const { option, value } = options
        const requiresValue = option && nextFilterOptions[option]

        this.setState(
            ({ filters }) => ({
                isAdding: false,
                filters: filters.map((filter) => {
                    if (filter._id !== id) return filter

                    return {
                        ...filter,
                        options,
                    }
                }),
            }),
            () => {
                // only add this filter if it is complete, otherwise clear it
                if (option && ((requiresValue && value) || !requiresValue)) {
                    this.props.onChange(this.state.filters)
                } else {
                    this.props.onChange(this.state.filters.filter((filter) => filter._id !== id))
                }
            }
        )
    }

    delete = (id) => {
        this.setState(
            ({ filters }) => ({ filters: filters.filter(({ _id }) => _id !== id) }),
            () => {
                this.props.onChange(this.state.filters)
                if (this.state.filters.length === 0) this.setState({ isAdding: true })
            }
        )
    }

    render() {
        const { hideTheRecordFilter, showRoleFilter, isCreate, object, getShouldShowField } =
            this.props
        if (!this.props.fields) return ''
        const fields = this.props.fields.filter(getShouldShowField ?? (() => true))

        const options = getFilterSourceOptions(sortFieldsByOrder(fields, object), {
            isCreate,
            hideTheRecordFilter,
            showRoleFilter: showRoleFilter && this.userRoleField,
        })

        const areRolesEnabled = getAreRolesEnabled(this.props.stack)

        return (
            <>
                {this.state.filters.map((filter, index) => {
                    // Ignore role filters if roles are not enabled
                    if (!areRolesEnabled && filter?.field?.type === 'user_role') return null
                    const { field: currentField } = filter
                    const fieldSid =
                        currentField?.api_name === '_sid'
                            ? '_sid'
                            : currentField?.type === 'user_role'
                            ? 'user_role'
                            : currentField?._sid
                    // the saved filter.field could be outdated, so we get the up to date field using
                    // the field sid
                    const field = getFilterFieldFromId(this.props.object, fieldSid)
                    return (
                        <FieldFilter
                            key={filter._sid}
                            object={this.props.object}
                            roles={this.props.roles}
                            field={field}
                            filterOptions={filter.options}
                            showRelativeDateFilters={this.props.showRelativeDateFilters ?? false}
                            hideCurrentUserOption={this.props.hideCurrentUserOption}
                            userObjectId={get(this.props, 'stackOptions.data_mapping.user_object')}
                            options={options}
                            showLogicalOperator={index > 0}
                            onChange={this.onChangeField}
                            id={filter._id}
                            delete={() => this.delete(filter._id)}
                        />
                    )
                })}
                {this.state.isAdding ? (
                    <div style={{ marginTop: 10 }}>
                        <Dropdown
                            options={options}
                            style={{ flex: 1 }}
                            onChange={(value) => this.select(value)}
                        />
                    </div>
                ) : (
                    <div>
                        <ConditionButton
                            onClick={() => this.setState({ isAdding: true })}
                            variant="adminPrimary"
                            buttonSize="sm"
                            hasFilters={!!this.state.filters.length}
                        >
                            Add Condition
                        </ConditionButton>
                    </div>
                )}
            </>
        )
    }
}

export default withUser(withStack(withRoles(_ObjectFieldsFilter)))

const SMALL_FILTER_BREAKPOINT = '750px'

const ConditionButton = styled(Button)`
    margin-top: ${(props) => (!props.hasFilters ? '10px' : '0.5rem')};
    @media only screen and (min-width: ${SMALL_FILTER_BREAKPOINT}) {
        margin-top: ${(props) => (!props.hasFilters ? '10px' : '1.5rem')};
    }
`
