/* Code Quality: Not audited */
import React, { Component, useCallback } from 'react'

import BlankPageView from 'v2/views/BlankPage/BlankPageView'
import CreateViewWrapper from 'v2/views/Create/CreateViewWrapper'
import DashboardView from 'v2/views/Dashboard/DashboardView'
import DetailViewWrapper from 'v2/views/Detail/DetailViewWrapper'
import ListViewWrapper from 'v2/views/List/ListViewWrapper'
import { useGoBackUsingBreadcrumbs } from 'v2/views/utils/useGoBackUsingBreadcrumbs'

import { useGetFeature } from 'data/hooks/features'
import WithRecord from 'data/wrappers/WithRecord'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import WithView from 'data/wrappers/WithView'
import { text } from 'features/pages/blocks/settings/attributes/items/primitives'
import { LoadingState } from 'features/views/List/ui/utils'

import { Box } from 'v2/ui'
import { isCollaborationEnabled } from 'v2/ui/utils/ProtectedFeature'

const Loading = () => (
    <Box m={8}>
        <LoadingState />
    </Box>
)

const ViewBlockInner = ({ view, onChange, ...props }) => {
    const feature = useGetFeature(view?.feature_id)
    const recordId = props.context.url && props.context.url.id
    const { context, isEditing } = props
    const handleViewChange = useCallback((options) => onChange({ options }), [onChange])
    const goBackUsingBreadcrumbs = useGoBackUsingBreadcrumbs()

    // If the record throws a permissions error, then the current user
    // has no permission to view this page, so we're just going to redirect to home
    const handleRecordLoadError = useCallback(
        (error) => {
            if (error?.status === 403 || error?.status === 404) {
                goBackUsingBreadcrumbs()
            }
        },
        [goBackUsingBreadcrumbs]
    )
    if (view.type === 'list') {
        return (
            <ListViewWrapper
                view={view}
                context={context}
                showControls={isEditing}
                onChange={handleViewChange}
            />
        )
    }
    if (view.type === 'create') {
        return (
            <CreateViewWrapper
                view={view}
                showRelated
                context={context}
                feature={feature}
                objectId={view.object_id}
                config={view.options}
                showControls={isEditing}
                onChange={handleViewChange}
                isCreate
            />
        )
    }
    if (view.type === 'detail') {
        // If collaboration is disabled, filter out activity tab
        if (!isCollaborationEnabled(props.stack) && view.options.tabs) {
            view.options.tabs = view.options.tabs.filter((tab) => {
                return tab.type !== 'activity'
            })
        }
        return (
            <WithRecord
                recordId={recordId}
                loading={<Loading />}
                onError={handleRecordLoadError}
                reloadOnMount
            >
                {({ record }) => {
                    return (
                        <DetailViewWrapper
                            view={view}
                            showRelated
                            context={context}
                            feature={feature}
                            objectId={view.object_id}
                            config={view.options}
                            showControls={isEditing}
                            onChange={handleViewChange}
                            record={record}
                        />
                    )
                }}
            </WithRecord>
        )
    }
    if (view.type === 'dashboard') {
        return <DashboardView view={view} recordId={recordId} context={context} />
    }
    if (view.type === 'blankpage') {
        return <BlankPageView view={view} page={context?.page} showControls={isEditing} />
    }
    return 'Unknown view'
}
class ViewBlock extends Component {
    render() {
        const { attrs } = this.props

        return (
            <WithView viewId={attrs.viewId}>
                {({ view, onChange }) => (
                    <ViewBlockInner {...this.props} view={view} onChange={onChange} />
                )}
            </WithView>
        )
    }
}

export default withStack(withUser(ViewBlock))

ViewBlock.attributes = [text({ field: 'viewId', label: 'View' })]
