export const getIcon = (fieldType: FieldType) => {
    switch (fieldType) {
        case 'multi_select':
            return 'multiSelect'
        case 'multi_lookup':
        case 'lookup':
            return 'lookup'
        case 'dropdown':
            return 'dropdown'
        case 'checkbox':
            return 'checkBox'
        default:
            return 'text'
    }
}
