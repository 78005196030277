import { useCallback } from 'react'
import { UseQueryResult } from 'react-query'
import { useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useNavigation } from 'data/hooks/navigation'
import { usePages } from 'data/hooks/pages'
import { useViews } from 'data/hooks/views'

import { goBackUsingBreadcrumbs } from './breadcrumbsUtils'

export const useGoBackUsingBreadcrumbs = () => {
    const history = useHistory()
    const { data: navigation } = useNavigation() as UseQueryResult<NavigationDto[]>
    const { data: pages } = usePages() as UseQueryResult<PageDto[]>
    const { data: views } = useViews()

    return useCallback(() => {
        if (!navigation || !pages || !views) return history.replace(getUrl(Urls.Home))
        goBackUsingBreadcrumbs(navigation, pages, views, history)
    }, [navigation, pages, views, history])
}
