import { preprocessFilterValue } from 'features/utils/filtersToQueryDict'

import { buildQueryKey, queryClient, useQuery, useQueryKeyBuilder } from '../_helpers'
/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useMetrics'
const ENDPOINT = 'metrics/'

// @TODO
//
// shim to transform old config format to new one
// config will be resaved in new format when saved, so just save
// all existing layouts to transform on dev environments then this can be deleted
export function _transformOldConfigFormatToNew(oldConfig) {
    const newConfig = { ...oldConfig }
    if (!!newConfig.aggr_type) {
        newConfig.aggr = {
            type: newConfig.aggr_type + '',
        }

        delete newConfig.aggr_type

        if (newConfig.field_sid) {
            newConfig.aggr.field_sid = oldConfig.field_sid + ''

            delete newConfig.field_sid
        }

        newConfig.group = {
            type: newConfig.aggr.type === 'group' ? 'value' : 'all',
        }

        if (newConfig.group.type === 'value') {
            newConfig.group.field_sid = oldConfig.aggr.field_sid
        }
    }

    return newConfig
}

function preprocessQuery(query) {
    if (query.filters) {
        query.filters = query.filters.map((filter) => ({
            ...filter,
            value: preprocessFilterValue(filter.objectId, filter.option, filter.value),
        }))
    }
    return query
}
/**
 *
 * @param {MetricsQueryRequest} query
 * @param {import('react-query').UseQueryOptions } options
 *
 * @returns {{ data: MetricsQueryResponse}} res
 */
export function useLegacyMetrics(query, options = {}) {
    query = _transformOldConfigFormatToNew(query)

    query = {
        ...query,
        filters: flattenFiltersForMetrics(query.filters),
    }

    const queryKey = useQueryKeyBuilder([LIST_NAME, query], {
        includeAuthKeys: true,
    })
    return useQuery(queryKey, ENDPOINT, options, {
        provideFetchOptions: () => ({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(preprocessQuery(query)),
        }),
    })
}

const flattenFiltersForMetrics = (filters) =>
    filters.reduce((agg, filter) => {
        if (filter.option === 'isInBetween') {
            return [
                ...agg,
                {
                    operation: 'gt',
                    target: filter.target,
                    value: filter.value[0],
                },
                {
                    operation: 'lt',
                    target: filter.target,
                    value: filter.value[1],
                },
            ]
        }
        return [...agg, filter]
    }, [])

export function invalidateMetrics() {
    return queryClient.removeQueries(
        buildQueryKey(LIST_NAME, {
            includeAuthKeys: true,
        }),
        { exact: false }
    )
}

export function invalidateMetric(objectId) {
    return queryClient.invalidateQueries(
        buildQueryKey([LIST_NAME, objectId], {
            includeAuthKeys: true,
        }),
        { exact: false }
    )
}
