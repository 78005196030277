import { isImpersonating, isImpersonatingEndUser } from 'data/utils/isAdmin'
import { makeToast } from 'utils/toaster'

export function checkIsCollaborationActivityDisabled() {
    const activityDisabled = isImpersonating() && !isImpersonatingEndUser()
    if (activityDisabled) {
        makeToast(
            'activityDisabled',
            'This feature is not available while previewing as another user.'
        )
    }
    return activityDisabled
}
