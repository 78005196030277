// @ts-strict-ignore
import React, { useMemo } from 'react'

import get from 'lodash/get'
import { useRecordFiltersForRelartedRecords } from 'v2/blocks/useRecordFiltersForRelatedRecords'
import { isRecordListDownloadAllowed } from 'v2/views/List/isRecordListDownloadAllowed'
import { isSearchBarHidden } from 'v2/views/List/isSearchBarHidden'
import ListViewWrapper from 'v2/views/List/ListViewWrapper'

import { withObjects } from 'data/wrappers/WithObjects'
import { withRecordActions } from 'data/wrappers/WithRecords'
import { withStack } from 'data/wrappers/WithStacks'
import { withViews } from 'data/wrappers/WithViews'

import Heading from '../../legacy/v1/ui/components/Heading'
import { getIsSymmetricRelationshipField } from '../../utils/fieldUtils'

type Props = {
    title: string
    editing: boolean
    viewId: string

    isExternalField: boolean
    listType: 'all' | 'related'
    objectId: string
    parentListViewIds: string[]
    parentDetailViewIds: string[]
    recordListAttrs: RecordListBlock['config']['attributes']
    record: any
    objects: any[]
    views: any[]
    onChange: Function
    field: any
    creating: boolean
    mayCreateRecords: boolean
}

// set true to get debug logs, including in production
const DEBUG = false
const logDebug = (...args) => {
    if (DEBUG) {
        let log = 'log'
        console[log](...args)
    }
}

const RecordListComponent: React.FC<Props> = ({
    title,
    field,
    record: localRecord,
    editing,
    creating,
    viewId,
    mayCreateRecords,
    isExternalField,
    listType,
    objectId,
    parentListViewIds,
    parentDetailViewIds,
    recordListAttrs,

    // props injected by HOCs
    objects, // from withObjects
    views, // from withViews
    // onChange: updateRecord, // from withRecordActions (not currently used)
}) => {
    let object =
        listType === 'all'
            ? objects &&
              objects.find(
                  (obj) => obj._sid === objectId && !obj.connection_options?.data_mapping_disabled
              )
            : objects &&
              objects.find(
                  (obj) =>
                      obj._sid ===
                          (isExternalField ? field.object_id : field.options?.lookup_target) &&
                      !obj.connection_options?.data_mapping_disabled
              )

    const listTitle = title || `${object && object.name}`
    const heading = useMemo(() => {
        return <Heading size="medium">{listTitle}</Heading>
    }, [listTitle])

    // Set up the filters
    const linkedRecordIds = useMemo(
        () => (localRecord && localRecord[field.api_name] ? localRecord[field.api_name] : []),
        [localRecord, field]
    )

    const filters = useRecordFiltersForRelartedRecords({
        isExternalField: isExternalField,
        localRecord: localRecord,
        field: field,
    })

    // Work out the list view to load
    if (!objects || !object || !localRecord?._sid) return null

    /*
  This shows a mini list view

  We assume that the links are symmetric (as in Airtable),
  so this is equivalent to showing all the linked-to records on this object.
  [For a previous version without this assumption, see commit 45952958]
  Single-value lookups are shown in the detail view, and only multi-lookups
  are shown in these related lists.

  We find this by:
      - Given a multi-lookup field from this record to a remote object,
      - Finding the list view for the looked up object
      - Displaying it with an extra filter applied to show
          only those records which are linked to.
  */

    const objectListView = views.find(
        (view) =>
            view.object_id === object._sid &&
            view.type === 'list' &&
            (!viewId || viewId === view._sid)
    )

    if (!objectListView) return null

    const allowDownload = isRecordListDownloadAllowed(recordListAttrs, objectListView)
    const hideSearch = isSearchBarHidden(recordListAttrs, objectListView)

    if (listType === 'all') {
        return (
            <ListViewWrapper
                heading={heading}
                titleText={listTitle}
                view={objectListView}
                isRecordList
                isRecordListOnCreate={creating}
                relatedFieldMayCreateNewRecords={mayCreateRecords}
                relatedListType={listType}
                parentDetailViewIds={parentDetailViewIds}
                parentListViewIds={parentListViewIds}
                recordListTitle={title}
                relatedListAttrs={recordListAttrs}
                allowDownload={allowDownload}
                hideSearch={hideSearch}
            />
        )
    }

    if (field.type === 'multi_lookup' || field.type === 'lookup') {
        // Get the corresponding column from the related list
        const getRelatedListColumnName = () => {
            if (isExternalField) return get(field, 'api_name')

            // if the related list is for a native object
            if (object.connection_options?.stacker_native_object) {
                // if the field used for the relationship *is* the symmetric link on the related list object,
                // then return the field on the object that it points to
                if (getIsSymmetricRelationshipField(field)) {
                    const lookup_field = field.connection_options.relationship_target_lookup_field

                    if (lookup_field) {
                        for (let relatedObjectField of object.fields ?? []) {
                            if (relatedObjectField._sid === lookup_field) {
                                logDebug(
                                    `---\nfound related list column name for related list of [${object.api_name}]\n` +
                                        `object: ${object.api_name} (sid ${object._sid})\n` +
                                        `field: ${field.api_name} (sid ${field._sid}) [note: this is a symmetric field]\n` +
                                        `found related field: ${relatedObjectField.api_name} (sid ${relatedObjectField._sid})]]`
                                )

                                return relatedObjectField.api_name
                            }
                        }

                        logDebug(
                            `---\nERROR: could not find related list column name for related list of [${object.api_name}]\n` +
                                `object: ${object.api_name} (sid ${object._sid})\n` +
                                `field: ${field.api_name} (sid ${field._sid}) [note: this is a symmetric field]\n\n` +
                                `Cause: could not find a field in object.fields with _sid = field.connection_options.relationship_target_lookup_field [${field.connection_options.relationship_target_lookup_field}]`
                        )

                        return null
                    }

                    logDebug(
                        `---\nERROR: could not find related list column name for related list of [${object.api_name}]\n` +
                            `object: ${object.api_name} (sid ${object._sid})\n` +
                            `field: ${field.api_name} (sid ${field._sid}) [note: this is a symmetric field]\n\n` +
                            `Cause: field.connection_options.relationship_target_lookup_field is [${field.connection_options.relationship_target_lookup_field}]`
                    )

                    return null
                }

                // otherwise, find the symmetric link on the related list object
                // that corresponds to this lookup field
                // by searching for it in the object fields
                for (let relatedObjectField of object.fields ?? []) {
                    const connection_options = relatedObjectField.connection_options
                    if (!connection_options) {
                        continue
                    }

                    if (
                        getIsSymmetricRelationshipField(relatedObjectField) &&
                        connection_options.relationship_target_lookup_field === field._sid
                    ) {
                        logDebug(
                            `---\nfound related list column name for related list of [${object.api_name}]\n` +
                                `object: ${object.api_name} (sid ${object._sid})\n` +
                                `field: ${field.api_name} (sid ${field._sid}) [note: this is not a symmetric field]\n` +
                                `found related field: ${relatedObjectField.api_name} (sid ${relatedObjectField._sid})]]`
                        )

                        return relatedObjectField.api_name
                    }
                }

                logDebug(
                    `---\nERROR: could not find related list column name for related list of [${object.api_name}]\n` +
                        `object: ${object.api_name} (sid ${object._sid})\n` +
                        `field: ${field.api_name} (sid ${field._sid})\n\n` +
                        `Cause: could not find a (symmetric) field in object.fields with connection_options.relationship_target_lookup_field = field._sid [${field._sid}]`
                )

                return null
            }

            if (
                !get(field, 'connection_options.airtable_symmetric_column_id') &&
                !get(field, 'connection_options.simpleconn_symmetric_column_id')
            ) {
                return null
            }

            const symmetricColumn = object.fields.find((f) => {
                const airtable_match =
                    f.connection_options.airtable_column_id &&
                    f.connection_options.airtable_column_id ===
                        field.connection_options.airtable_symmetric_column_id
                const simpleconn_match =
                    f.connection_options.simpleconn_field &&
                    f.connection_options.simpleconn_field ===
                        field.connection_options.simpleconn_symmetric_column_id
                return airtable_match || simpleconn_match
            })

            return get(symmetricColumn, 'api_name')
        }

        const symmetricColumnName = getRelatedListColumnName()

        return (
            <ListViewWrapper
                heading={heading}
                titleText={listTitle}
                view={objectListView}
                additionalFilters={filters}
                relatedListSymmetricColumnName={symmetricColumnName}
                relatedListField={field._sid}
                isRecordList
                isRecordListOnCreate={creating}
                autoFilledRelatedListRecord={get(localRecord, '_sid')}
                relatedFieldMayCreateNewRecords={mayCreateRecords}
                relatedListType={listType}
                parentDetailViewIds={parentDetailViewIds}
                parentListViewIds={parentListViewIds}
                recordListTitle={title}
                relatedListAttrs={recordListAttrs}
                relatedListRecord={localRecord}
                relatedListEditing={editing}
                relatedListIds={linkedRecordIds}
                allowDownload={allowDownload}
                hideSearch={hideSearch}
            />
        )
    } else {
        return null
    }
}

const RecordList = withRecordActions(withViews(withObjects(withStack(RecordListComponent))))

export default RecordList
