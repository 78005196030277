import React from 'react'

import { Button, EmptyState, Flex, Text } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

type DataListBlockSearchEmptyStateProps = {
    clearSearch: () => void
}
const DataListBlockSearchEmptyState = ({ clearSearch }: DataListBlockSearchEmptyStateProps) => {
    const emptyViewCta = () => {
        return (
            <Flex column>
                <Text variant="emptyState" mb={5}>
                    Clear your search and start again.
                </Text>
                <Button variant="sm" ml={[0, null, 2]} mt={[2, null, 0]} onClick={clearSearch}>
                    Clear search
                </Button>
            </Flex>
        )
    }

    return (
        <EmptyState
            custom="No records match your search"
            cta={emptyViewCta}
            svg={SvgIcons.EmptySearch}
            name="records"
        />
    )
}

export default DataListBlockSearchEmptyState
