import React from 'react'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { StackIcon } from '../AppBar/StackIcon'

type FeatureIconProps = {
    icon: string
    color?: string
    fullSize?: boolean
    style?: any
}

function FeatureIcon({
    icon,
    color = V4DesignSystem.colors.stacker,
    fullSize = false,
    style = {},
}: FeatureIconProps) {
    let buttonSize = 30
    let buttonLineHeight = '38px'
    let iconSize = 18
    let borderRadius = 6
    if (fullSize) {
        buttonSize = 42
        buttonLineHeight = '48px'
        iconSize = 23
        borderRadius = 8
    }

    return (
        <div
            style={{
                display: 'inline-block',
                width: buttonSize,
                height: buttonSize,
                lineHeight: buttonLineHeight,
                textAlign: 'center',
                borderRadius: borderRadius,
                color: 'white',
                background: color,
                marginRight: '12px',
                ...style,
            }}
        >
            {icon && (
                <StackIcon
                    stack={{ name: '', options: { theme: { icon: icon } } }}
                    isSelected={true}
                    theme={{ theme: 'light' }}
                    size={iconSize}
                />
            )}
        </div>
    )
}

export default FeatureIcon
