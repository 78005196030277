import React, { FC, useEffect, useMemo, useState } from 'react'

import usePageRoleConfig from 'v2/views/utils/usePageRoleConfig'

import { useStackPages } from 'data/hooks/pages'
import { useStackViews } from 'data/hooks/views'
import { RolesSelectToggle } from 'features/pages/blocks/settings/attributes/items/form/RolesSelect'

type Props = {
    item: { parent_id: string; url: string } & object
}

const ChangeNavigationRole: FC<Props> = ({ item }) => {
    const [icon, setIcon] = useState<'lock' | 'userLock' | 'world'>('lock')

    const pages: PageDto[] = useStackPages()
    const views = useStackViews()

    const targetView = useMemo(() => views.find(({ url }) => url === item.url), [item, views])
    const targetPage = useMemo(
        () => pages.find((page) => page.options.view_id === targetView?._sid),
        [pages, targetView]
    )

    const { setAndSavePageRoles, pageRoles } = usePageRoleConfig(targetPage)

    useEffect(() => {
        setIcon(pageRoles.includes('*') ? 'world' : 'userLock')
    }, [pageRoles])

    if (!item.parent_id || !targetPage) {
        return null
    }

    return (
        <RolesSelectToggle
            onChange={setAndSavePageRoles}
            value={pageRoles}
            icon={icon}
            isInSettingsModal
        />
    )
}

export default ChangeNavigationRole
