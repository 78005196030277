export const mainAccountSettings = [
    {
        api_name: 'account_mode',
        label: 'Account Mode',
        type: 'string',
    },
    {
        api_name: 'trial_ui_enabled',
        label: 'Enable Trial UI',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'trial_start_date',
        label: 'Trial Start Date',
        type: 'datetime',
    },
    {
        api_name: 'trial_end_date',
        label: 'Trial End Date',
        type: 'datetime',
    },
    {
        api_name: 'subscription_status',
        label: 'Subscription Status',
        type: 'dropdown',
        options: {
            options: [
                { label: 'trial', value: 'trial' },
                { label: 'subscribed', value: 'subscribed' },
                { label: 'cancelled', value: 'cancelled' },
            ],
        },
    },
    {
        api_name: 'additional_users',
        label: 'Additional free users',
        type: 'number',
    },
    {
        api_name: 'stripe_customer_id',
        label: 'Stripe Customer Id',
        type: 'string',
    },
    {
        api_name: 'stripe_subscription_id',
        label: 'Stripe Subscription Id',
        type: 'string',
    },
    {
        api_name: 'stripe_subscription_item_id',
        label: 'Stripe Subscription Item Id',
        type: 'string',
    },
    {
        api_name: 'plan_tier',
        label: 'Plan Tier',
        type: 'dropdown',
        options: {
            options: [
                { label: 'Starter', value: 'Starter' },
                { label: 'Plus', value: 'Plus' },
                { label: 'Pro', value: 'Pro' },
                { label: 'Enterprise', value: 'Enterprise' },
            ],
        },
    },
    {
        api_name: 'intercom_app_id',
        label: 'Intercom app id',
    },
]

export const additionalAccountSettings = []

export const premiumFeatures = [
    {
        api_name: 'whitelabel',
        label: 'Whitelabel',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'multiple_list_views',
        label: 'Multiple list views',
        type: 'checkbox',
        options: { is_toggle: true },
    },

    {
        api_name: 'multiple_permission_rules',
        label: 'Multiple permission rules',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'multiple_roles',
        label: 'Multiple roles',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'kanban',
        label: 'Kanban View',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'fast_sync',
        label: 'Fast Sync',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'custom_scripts',
        label: 'Custom Scripts',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'self_serve_custom_scripts',
        label: 'Self-serve custom scripts',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'client_logos',
        label: 'Client Logos',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'custom_css',
        label: 'Custom CSS',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'custom_domain',
        label: 'Custom domain',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'collaboration',
        label: 'Collaboration comments',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'charts',
        label: 'Charts',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'multi_tabs_detailview',
        label: 'Multi tabs on details view',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'salesforce_connector',
        label: 'Salesforce Data Connector',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'sync_stacker_users',
        label: 'Sync Stacker Users',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'inbox_view',
        label: 'Enable inbox view',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'workspace_custom_domain',
        label: 'Enable Workspace Custom Domain',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'global_custom_css',
        label: 'Enable Global Custom CSS',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'unlimited_apps',
        label: 'Enable Unlimited Apps',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'third_party_tags',
        label: 'Third party tags',
        type: 'checkbox',
        options: { is_toggle: true },
    },
]

export const experimentalFeatures = [
    {
        api_name: 'stacker_native_tables',
        label: 'Stacker Native Tables',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'bundles',
        label: 'Bundles',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'connected_tables',
        label: 'Enable/disable connected tables',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'installable_features',
        label: 'Installable Features',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'datagrid',
        label: 'Datagrid',
        type: 'checkbox',
        options: { is_toggle: true },
    },
    {
        api_name: 'datagrid_max_records',
        label: 'Datagrid Max Records',
        type: 'number',
        default_value: 1000,
    },
]
