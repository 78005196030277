import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { RenderInlineElement } from 'ui/forms/ui'

import { AGGR_OPTIONS } from '../aggrConstants'

import { AggrTypeOption } from './AggrTypeOption'

export const AggrTypeSelectorContainer = styled(Flex)`
    margin-bottom: -8px;

    & > div {
        margin-right: 12px;
    }

    & > div:last-of-type {
        margin-right: 0;
    }
`

export function AggrTypeSelector({ value, onChange }) {
    return (
        <AggrTypeSelectorContainer alignItems="stretch">
            {AGGR_OPTIONS.map(({ name, label }) => (
                <AggrTypeOption
                    key={`aggr-option-${name}`}
                    selected={value === name}
                    onChange={onChange}
                    name={name}
                    label={label}
                />
            ))}
        </AggrTypeSelectorContainer>
    )
}

export function AggrTypeSelectorForm({
    label,
    name,
    defaultValue,
    required = false,
    errorMessages = {},
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement label={label} error={error} errorMessages={errorMessages}>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => <AggrTypeSelector {...field} />}
            />
        </RenderInlineElement>
    )
}
