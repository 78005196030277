import { measureTextCached, Rectangle } from '@glideapps/glide-data-grid'

import type { DrawArgs } from './types'

const tagHeight = 20
const innerPad = 6

export function roundedRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
) {
    ctx.moveTo(x + radius, y)
    ctx.arcTo(x + width, y, x + width, y + radius, radius)
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius)
    ctx.arcTo(x, y + height, x, y + height - radius, radius)
    ctx.arcTo(x, y, x + radius, y, radius)
}

export function drawRoundedValues(
    args: DrawArgs,
    values: { label: string; color?: string; backgroundColor?: string }[]
) {
    const { ctx, theme, rect } = args

    const drawArea: Rectangle = {
        x: rect.x + theme.cellHorizontalPadding,
        y: rect.y + theme.cellVerticalPadding,
        width: rect.width - 2 * theme.cellHorizontalPadding,
        height: rect.height - 2 * theme.cellVerticalPadding,
    }
    const rows = Math.max(1, Math.floor(drawArea.height / (tagHeight + innerPad)))

    let x = drawArea.x
    let row = 1
    let y = drawArea.y + (drawArea.height - rows * tagHeight - (rows - 1) * innerPad) / 2
    for (const value of values) {
        const color = value.backgroundColor || theme.bgBubble

        ctx.font = `12px ${theme.fontFamily}`
        const metrics = measureTextCached(value.label, ctx)
        const width = metrics.width + innerPad * 2
        const textY = tagHeight / 2

        if (x !== drawArea.x && x + width > drawArea.x + drawArea.width && row < rows) {
            row++
            y += tagHeight + innerPad
            x = drawArea.x
        }

        ctx.fillStyle = color
        ctx.beginPath()
        roundedRect(ctx, x, y, width, tagHeight, tagHeight / 4)
        ctx.fill()

        ctx.fillStyle = value.color || theme.textDark
        ctx.fillText(value.label, x + innerPad, y + textY)

        x += width + 4
        if (x > drawArea.x + drawArea.width && row >= rows) break
    }
}
