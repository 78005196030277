import React from 'react'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import Box from './Box'

type BadgeProps = {
    color: 'red' | 'yellow' | 'green' | 'gray' | 'blue'
}

export const Badge: React.FC<BadgeProps> = ({ color, children }) => {
    const boxColor =
        color == 'blue' ? V4DesignSystem.colors[color][800] : V4DesignSystem.colors[color][700]

    return (
        <Box
            backgroundColor={V4DesignSystem.colors[color][100]}
            color={boxColor}
            padding="0 7px"
            fontSize="12px"
            height="20px"
            lineHeight="20px"
            fontWeight={600}
            whiteSpace="nowrap"
            display="inline-block"
            borderRadius="3px"
        >
            {children}
        </Box>
    )
}
