import moment from 'moment'

import useStableState from './useStableState'

export const useIsSupportLoginPermitted = () => {
    const [isUserSupportLoginPermitted] = useStableState('permitSupportLogin')
    return isUserSupportLoginPermitted
}

export const isSupportLoginPermitted = (user, stacks) => {
    return isV3SupportLoginPermitted(stacks) || isV4SupportLoginPermitted(user)
}

const isV3SupportLoginPermitted = (stacks) =>
    !!stacks?.find((stack) => stack.options?.permit_support_login)

export const isV4SupportLoginPermitted = (user) => {
    const options = user?.options || {}
    return options.support_login_expiration_date
        ? options.permit_support_login && moment(options.support_login_expiration_date).isAfter()
        : options.permit_support_login
}
