import { bootIntercom } from 'utils/intercom'

export const setOnboardingStep = (step, props) => {
    const updateOnboardingStep = (Intercom) => Intercom('update', { 'Onboarding step': step })
    const { user, stack } = props
    user &&
        stack &&
        bootIntercom({
            user,
            stackId: stack._sid,
            callback: updateOnboardingStep,
        })
}
