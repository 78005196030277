/* Code Quality: Not audited */

import React, { Component } from 'react'

import styled from '@emotion/styled'
import deepcopy from 'deepcopy'
import mapKeys from 'lodash/mapKeys'
import sortBy from 'lodash/sortBy'

import { withFeatures } from 'data/wrappers/WithFeatures'
import { withViews } from 'data/wrappers/WithViews'
import ToggleSortPicker from 'features/views/List/ToggleSortPicker'
import { Icon8, Input, Text } from 'legacy/v1/ui'

export const featureToMenuRank = (feature) => {
    if (feature.options && feature.options.menu_display_rank) {
        return feature.options.menu_display_rank
    } else {
        return feature.name
    }
}

const incrementRank = (rankString) => {
    return `${rankString}a`
}

class MenuItemPicker extends Component {
    state = { features: [], views: [] }

    constructor(props) {
        super(props)
        this.state.features = deepcopy(props.features)
        this.state.views = deepcopy(props.views)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.features.length != this.props.features.length) {
            this.setState({ features: nextProps.features })
        }

        if (nextProps.views.length != this.props.views.length) {
            this.setState({ views: nextProps.views })
        }
    }

    render() {
        const { onChange } = this.props

        const featuresById = mapKeys(this.state.features, (value) => value._sid)

        const allItems = sortBy(this.state.views, [featureToMenuRank]).filter((view) => {
            const feature = featuresById[view.feature_id]
            if (!feature) return false
            return view.type == 'list'
        })

        const changeLabel = (item, newLabel) => {
            const newOptions = {
                ...item.options,
                menu_label: newLabel,
            }
            onChange(item._sid, {
                options: newOptions,
            })
        }

        const renderItem = (item) => (
            <MenuLabelViewEdit
                item={item}
                changeLabel={(newLabel) => changeLabel(item, newLabel)}
            />
        )
        const isSelected = (item) => !item.options.hide_from_menu
        const toggleItem = (item) => ({
            ...item,
            options: {
                ...item.options,
                hide_from_menu: !item.options.hide_from_menu,
            },
        })
        const itemToId = (item) => item._sid

        const onToggle = (index) => {
            const item = allItems[index]
            const newOptions = {
                ...item.options,
                hide_from_menu: !item.options.hide_from_menu,
            }
            onChange(item._sid, {
                options: newOptions,
            })

            // Mutate this locally to avoid flicker
            const feature = this.state.views.find((f) => f._sid === item._sid)
            if (feature) {
                feature.options = newOptions
            }
        }
        const changeRank = (item, newRank) => {
            const newOptions = { ...item.options, menu_display_rank: newRank }
            onChange(item._sid, {
                options: { ...item.options, menu_display_rank: newRank },
            })

            // Mutate this locally to avoid flicker
            const feature = this.state.views.find((f) => f._sid === item._sid)
            if (feature) {
                feature.options = newOptions
            }
        }

        const onReorder = (oldIndex, newIndex) => {
            // Move this menu item to just before where the new index currently is
            const itemToMove = allItems[oldIndex]
            const targetItem = allItems[newIndex]

            // If we're moving an item forwards it'll behave differently to if we're moving it backwards
            const movingDown = oldIndex < newIndex

            // If we're moving down, then we want to move it to AFTER the targetItem
            // Otherwise we want to move it to BEFORE

            if (movingDown) {
                // We want to move it to after the targetItem
                // So we make its rank the rank after the targetItem's
                const targetItemRank = featureToMenuRank(targetItem)
                let myRank = incrementRank(targetItemRank)
                changeRank(itemToMove, myRank)

                // And then recursively check that we don't need to update the following one's ranks
                let i = newIndex + 1
                while (i < allItems.length && myRank >= featureToMenuRank(allItems[i])) {
                    const nextItem = allItems[i]
                    const nextRank = incrementRank(myRank)
                    changeRank(nextItem, nextRank)
                    myRank = nextRank
                    i += 1
                }
            } else {
                // We're moving it up and before the targetItem
                // So we swap its rank with the target items's rank, and then set the target item's
                // rank to the one after
                const targetItemRank = featureToMenuRank(targetItem)
                changeRank(itemToMove, targetItemRank)

                let myRank = incrementRank(targetItemRank)
                changeRank(targetItem, myRank)

                // And then recursively check that we don't need to update the following one's ranks
                let i = newIndex + 1
                while (
                    i < allItems.length &&
                    i < oldIndex &&
                    myRank >= featureToMenuRank(allItems[i])
                ) {
                    const nextItem = allItems[i]
                    const nextRank = incrementRank(myRank)
                    changeRank(nextItem, nextRank)
                    myRank = nextRank
                    i += 1
                }
            }
        }

        return (
            <ToggleSortPicker
                items={allItems}
                renderItem={renderItem}
                toggleItem={toggleItem}
                isSelected={isSelected}
                itemToId={itemToId}
                onReorder={onReorder}
                onToggle={onToggle}
                ItemWrapper={MenuItem}
                isMenu
            />
        )
    }
}

export default withViews(withFeatures(MenuItemPicker))

const MenuItem = styled('div')`
    border-radius: 5px;
    background-color: #e6ecf5;
    margin: 5px;
    width: 400px;
    z-index: 100;
`

class MenuLabelViewEdit extends Component {
    state = {
        editing: false,
        label: this.props.item.options.menu_label || this.props.item.name,
    }

    render() {
        const { item, changeLabel } = this.props

        // Only override label if it's different!
        const labelToSet = this.state.label !== item.name ? this.state.label : null

        const saveLabel = (newLabel) => {
            changeLabel(newLabel)
            this.setState({
                editing: false,
                label: newLabel || this.props.item.name,
            })
        }

        if (this.state.editing) {
            return (
                <span>
                    <Input
                        defaultValue={this.state.label}
                        onChange={(e) => this.setState({ label: e.target.value })}
                        style={{ display: 'inline-block' }}
                    />
                    <EditText onClick={() => saveLabel(item.name)}>Reset</EditText>
                    <EditText onClick={() => saveLabel(labelToSet)}>Save</EditText>
                </span>
            )
        }
        return (
            <>
                <Text size="fontS" style={{ display: 'inline-flex' }}>
                    {this.state.label}{' '}
                    <Icon8
                        icon="pencil"
                        iconStyle="ios-filled"
                        color="CCCCCC"
                        displaySize="17"
                        style={{ cursor: 'pointer', marginLeft: 10 }}
                        onClick={() => this.setState({ editing: true })}
                    />
                </Text>
            </>
        )
    }
}

const EditText = styled(Text)`
cursor: pointer;
margin 0px 0px 0px 5px;
padding: 0px;
font-size: 0.8em;
display: inline-block;
text-decoration: underline;
cursor: pointer;

`
