import React from 'react'

import Box from './Box'
import Flex from './Flex'
import Icon from './Icon'

const selected = {
    borderColor: 'grey.300',
}

const Draggable = ({ children, ...props }) => {
    return (
        <Box
            as={Flex}
            p={2}
            borderRadius="3px"
            tabIndex={0}
            _hover={selected}
            _focus={selected}
            outline={0}
            my="3px"
            cursor="pointer"
            border="1px solid"
            borderColor="grey.200"
            w="100%"
            flexWrap="nowrap"
            {...props}
        >
            <Flex flex={1}>{children}</Flex>
            <Icon icon="dragHandle" color="gray.200" ml={2} />
        </Box>
    )
}

export default Draggable
