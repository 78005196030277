import React from 'react'

import styled from '@emotion/styled'

import { useLayoutContextPortal } from './useLayoutContextPortal'
// have to have the overflow hidden in here otherwise
// children will not be able to limit their height and
// scroll overflowing content.
// Also add the -5/5 padding/margin so that the overflow
// being hidden doesn't cut of things like box-shadows around child elements
const StyledDiv = styled('div')`
    overflow: hidden;
    margin: -5px -5px 5px;
    padding: 5px 5px 20px;
    display: flex;
    position: relative;
`

const StyledOverlay = styled('div')`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: white;
    &: hover {
        + p {
            opacity: 1;
        }
    }

    + p {
        transition: opacity 0.4s ease-in;
        font-size: 0.8rem;
        opacity: 0;
        position: absolute;
        top: 57%;
        left: 50%;
        transform: translate(-50%, -100%);
        color: white;
        background-color: #15181d;
        max-width: 250px;
        width: max-content;
        padding: 8px 12px;
        border-radius: 5px;
        text-align: center;
        outline: 1px solid black;
        &: hover {
            opacity: 1;
        }
    }
`

type BlockSelectorPortalProps = {
    useLayoutFrom?: string
}

const BlockSelectorPortal = ({ useLayoutFrom }: BlockSelectorPortalProps) => {
    const setPortal = useLayoutContextPortal('blockSelector')
    return (
        <>
            <StyledDiv id="blockSelector" ref={setPortal}>
                {useLayoutFrom && (
                    <>
                        <StyledOverlay className="overlay"></StyledOverlay>
                        <p>{`Select "Customize your create layout" to add new widgets`}</p>
                    </>
                )}
            </StyledDiv>
        </>
    )
}
export default React.memo(BlockSelectorPortal)
