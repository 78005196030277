import * as React from 'react'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'
import { closeManageUserSettingsModal } from '../../../utils/modalOpeners'
import { Button, Flex, Text } from '../../../v2/ui'
import { useAppSettings } from '../../workspace/AdminSideTray/hooks/useAppSettings'

export const RolesSettings = () => {
    const { open: openAppSettings } = useAppSettings()
    const handleManageRoles = () => {
        closeManageUserSettingsModal()
        openAppSettings({ page: { name: 'roles' } })
    }
    return (
        <Flex column mb={4} align="left">
            <Text fontWeight="bold" mb={2}>
                Roles
            </Text>
            <Text size="small" color={V4DesignSystem.colors.gray[600]} mb={3}>
                Create custom roles for users to be able to choose pages, data and controls to show
                or hide from some users.
            </Text>
            <Button
                variant="adminSecondary"
                icon="users"
                color="gray.400"
                fontSize="sm"
                buttonSize="sm"
                style={{ width: 180 }}
                onClick={handleManageRoles}
            >
                Manage Roles
            </Button>
        </Flex>
    )
}
