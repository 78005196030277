import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

type StickyProps = {
    disabled?: boolean
    topOffset?: number
    threshold?: number | number[]
    rootMargin?: string

    children?: (ref: React.RefObject<HTMLElement>, isFixed: boolean) => React.ReactNode
}

const Sticky: React.FC<StickyProps> = ({
    children,
    threshold,
    rootMargin,
    disabled,
    topOffset = 0,
}) => {
    const [isFixed, setIsFixed] = useState(false)
    const animationRef = useRef(0)
    const isLoaded = useRef(false)
    const childRef = useRef<HTMLElement>(null)
    const prevValues = useRef<Record<string, unknown>>({})
    const fillerDiv = useRef<HTMLDivElement>(null)

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: threshold,
        rootMargin: rootMargin,
    })

    useEffect(() => {
        if (!isLoaded.current || disabled) {
            isLoaded.current = inView
            return
        }

        if (!inView) {
            const prev = prevValues.current
            const style = childRef.current?.style
            const filler = fillerDiv.current?.style

            // Create a filler element to fill the space that is going to be left
            // by the element that is being positioned fixed
            if (filler) {
                const childHeight = childRef.current?.offsetHeight
                filler.minHeight = childHeight + 'px'
                filler.marginTop = style?.marginTop ?? ''
                filler.marginBottom = style?.marginBottom ?? ''
                filler.width = (childRef.current?.offsetWidth ?? 0) + 'px'
                filler.display = 'block'
            }

            if (style) {
                // Save the existing style values on the child
                prev.position = style.position
                prev.left = style.left
                prev.top = style.top
                prev.right = style.right
                prev.width = style.width
                prev.marginLeft = style.marginLeft
                prev.marginRight = style.marginRight

                // Position the child fixed, preserving the current width/top/left/right positioning.
                var rect = childRef.current?.getBoundingClientRect()
                style.position = 'fixed'
                style.top = (rect?.top ?? 0) + 'px'
                style.left = (rect?.left ?? 0) + 'px'
                style.right = document.documentElement.clientWidth - (rect?.right ?? 0) + 'px'
                style.width = '0'
                // Schedule setting the top to 0 (scheduling so the top value can be transitioned)
                animationRef.current = window.requestAnimationFrame(
                    () => (style.top = String(topOffset))
                )
            }

            setIsFixed(true)
        } else {
            // Restore previous style values
            const prev = prevValues.current
            const style = childRef.current?.style
            if (style) {
                style.position = prev.position as string
                style.left = prev.left as string
                style.top = prev.top as string
                style.right = prev.right as string
                style.width = prev.width as string
            }

            // Hide filler
            const filler = fillerDiv.current?.style
            if (filler) {
                filler.display = 'none'
            }
            setIsFixed(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, entry])

    useEffect(() => {
        return () => {
            if (animationRef.current) cancelAnimationFrame(animationRef.current)
        }
    }, [])

    return (
        <>
            <div ref={ref} />
            {children?.(childRef, isFixed)}
            <div ref={fillerDiv} />
        </>
    )
}

export default Sticky
