import React from 'react'

import styled from '@emotion/styled'

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`

const Container = styled.div`
    width: 1000px;
    padding: 0 10px;
    margin: 0 auto;
    max-width: 100%;
`

type ViewHeaderWrapperProps = React.PropsWithChildren<{}>

const ViewHeaderWrapper: React.FC<ViewHeaderWrapperProps> = ({ children, ...props }) => {
    return (
        <Wrapper {...props}>
            <Container>{children}</Container>
        </Wrapper>
    )
}

export default ViewHeaderWrapper
