/* Code Quality: Good */

import React from 'react'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'

import { withUser } from 'data/wrappers/WithUser'
import { navigationColor } from 'features/admin/settings/navigation/NavigationUtils'
import { Text } from 'legacy/v1/ui'

import { Link } from 'v2/ui'

import UserAvatar from './UserAvatar'
import UserMenuContainer from './UserMenuContainer'

const UserMenu = ({ user, theme, children, containerStyle, renderWrapper, navTheme }) => {
    if (!navTheme) {
        navTheme = theme ? navigationColor(theme) : null
    }

    const name = user ? user._primary || user.name || 'My Account' : ''

    const menu = (
        <>
            <Link to="/logout">
                <Text style={{ fontSize: '15px', color: '#000' }}>Log Out</Text>
            </Link>
        </>
    )

    return (
        <UserMenuContainer menu={menu} style={containerStyle} renderWrapper={renderWrapper}>
            {children || <UserAvatar name={name} navTheme={navTheme} />}
        </UserMenuContainer>
    )
}

UserMenu.propTypes = {
    user: PropTypes.object.isRequired, // from withUser
    userActions: PropTypes.object.isRequired, // from withUser
    location: PropTypes.object.isRequired, // from withRouter
    history: PropTypes.object.isRequired, // from withRouter
}

export default withRouter(withUser(UserMenu))
