import React from 'react'

import { BoxProps } from '@chakra-ui/react'

import { Box } from 'v2/ui'
import useHover from 'v2/ui/utils/useHover'

import useEditMode from '../useEditMode'

import { getEditableBlockStyle } from './EditModeUI'

/**
 * Provides a wrapper for a layout block which can be edited.
 * Handles showing the dashed edit border, can be extended to show
 * edit toolbar, etc.
 *
 **/

export type EditableBlockWrapperProps = Omit<BoxProps, 'children'> & {
    highlight?: boolean
    onHoverStart?: () => void
    onHoverEnd?: () => void
    children?: React.ReactNode | ((args: { isHovered?: boolean }) => React.ReactNode)
}
export const EditableBlockWrapper: React.FC<EditableBlockWrapperProps> = ({ children }) => {
    return <>{typeof children === 'function' ? children({}) : children}</>
}

export const BlockContainer: React.FC<EditableBlockWrapperProps> = ({
    children,
    style,
    highlight,
    onHoverStart,
    onHoverEnd,
    ...props
}) => {
    const { isOpen: isEditModeOpen } = useEditMode()
    const [isWrapperHovered, hoverHandlers] = useHover({
        delay: 50,
        onHoverStart,
        onHoverEnd,
    })

    return (
        <Box
            {...props}
            {...hoverHandlers}
            style={{
                ...(isEditModeOpen
                    ? getEditableBlockStyle(highlight || isWrapperHovered)
                    : undefined),
                ...style,
            }}
        >
            {typeof children === 'function'
                ? children({
                      isHovered: isWrapperHovered,
                  })
                : children}
        </Box>
    )
}
