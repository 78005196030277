import React, { FC, MouseEvent } from 'react'

import { useTheme } from '@chakra-ui/react'

type Props = {
    color?: string
    size?: number
    onClick?: (event: MouseEvent) => void
}

const SolidClose: FC<Props> = ({ color, size = 16, onClick }) => {
    const theme = useTheme()

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M11.695 11.725c-.19.173-.45.27-.72.27-.271 0-.53-.097-.722-.27L8 9.398l-2.252 2.327a1.031 1.031 0 0 1-.331.203 1.115 1.115 0 0 1-.785.002 1.033 1.033 0 0 1-.332-.2.922.922 0 0 1-.222-.3.845.845 0 0 1 .003-.71.923.923 0 0 1 .224-.3L6.65 8 4.305 5.58a.924.924 0 0 1-.225-.3.846.846 0 0 1-.003-.71.922.922 0 0 1 .222-.3c.095-.086.208-.154.332-.2a1.115 1.115 0 0 1 .785.002c.124.048.236.117.33.203L8 6.604l2.253-2.329c.095-.086.207-.155.331-.203a1.114 1.114 0 0 1 .785-.003c.124.047.237.115.332.201.095.086.17.188.222.3a.847.847 0 0 1-.003.71.924.924 0 0 1-.225.3L9.352 8l2.343 2.42c.095.087.17.188.222.3a.846.846 0 0 1 0 .706.922.922 0 0 1-.222.3z"
                fill={color || theme.colors.userInterface.neutral[600]}
            />
        </svg>
    )
}

export default SolidClose
