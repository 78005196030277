import React, { FC, useCallback, useMemo, useState } from 'react'

import { useFeatures } from 'data/hooks/features'
import { useObject } from 'data/hooks/objects'
import { useViews } from 'data/hooks/views'

import { Modal } from 'v2/ui'
import useModalToggle, { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { INLINE_CREATE_VIEW_MODAL } from './constants'
import CreateView from './CreateView'

export const useInlineCreateViewModal = () =>
    useModalDeclaration(INLINE_CREATE_VIEW_MODAL, InlineCreateViewModal)

const MODAL_BODY_STYLE = {
    padding: '2px',
    textAlign: 'left',
}

const InlineCreateViewModal: FC = () => {
    const [actions, setActions] = useState([])

    const { isOpen, toggle, data } = useModalToggle<{
        objectId: string
        queryParams: any
        onCreate?: (id: any, message: string) => void
    }>(INLINE_CREATE_VIEW_MODAL)

    const { data: features } = useFeatures()
    const { data: views } = useViews()
    const { object } = useObject(data?.objectId)

    const view = useMemo(() => {
        return views?.find((view) => {
            return view.object_id === data?.objectId && view.type === 'create'
        })
    }, [data?.objectId, views])

    const feature = useMemo(() => {
        const featureId = view?.feature_id
        return features?.find((feature: FeatureDto) => {
            return feature._sid === featureId
        })
    }, [view, features])

    const config = useMemo(() => {
        return view?.options ?? null
    }, [view])

    const onInlineCreate = useCallback(
        (record_id) => {
            if (data?.onCreate) {
                data.onCreate(record_id, 'Record Created')
            }
            toggle()
        },
        [data, toggle]
    )

    return (
        <Modal
            isOpen={isOpen}
            title={config?.title || `${object?.name}: add new`}
            scrollBehavior="inside"
            size="600px"
            body={
                <CreateView
                    queryParams={data?.queryParams}
                    hideTitle
                    view={view}
                    showRelated
                    feature={feature}
                    objectId={data?.objectId}
                    showControls={false}
                    config={config}
                    onCreate={onInlineCreate}
                    isCreate
                    doNotRedirect
                    closeModal={toggle}
                    inlineCreate
                    setModalActions={setActions}
                />
            }
            onClose={toggle}
            actions={actions}
            bodyStyle={MODAL_BODY_STYLE}
        />
    )
}

export default InlineCreateViewModal
