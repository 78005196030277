import React from 'react'

export const LinkFormLayout = ({ children }) => {
    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                fontSize: 14,
            }}
        >
            {children}
        </div>
    )
}
