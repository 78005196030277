import styled from '@emotion/styled'

export const Layout = styled.div`
    display: flex;
    flex-direction: column;

    .content {
        flex-grow: 1;
    }
`
