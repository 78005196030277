import React, { forwardRef } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'

import { Flex } from 'v2/ui'

export const HEADER_HEIGHT: number = 64
export const HEADER_PADDING_X: number = 40

type StyledHeaderBarProps = {
    height: string
    textColorBright: string
    isWorkspaceApp: boolean
    bgScdNav: string
    navColor: string
}

const StyledHeaderBar = styled('div', {
    shouldForwardProp: (prop) => isPropValid(prop),
})<StyledHeaderBarProps>`
    width: 100%;
    height: ${(props) => (props.height ? props.height : '84px')};
    padding: 20px ${HEADER_PADDING_X}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => (props.textColorBright ? props.textColorBright : '#000')};
    position: relative;
    // TODO: change to theme
    background: ${(props) =>
        props.isWorkspaceApp ? props.bgScdNav : props.navColor ? props.navColor : '#fff'};

    @media (max-width: 768px) {
        height: 40px;
        padding: 10px 20px 10px 12px;
    }
`

export const HeaderBar = forwardRef<StyledHeaderBarProps, any>((props, ref) => {
    return <StyledHeaderBar ref={ref} {...props} />
})
export const AbsoluteLogo = styled(Flex)`
    flex: 1;

    @media (max-width: 768px) {
        flex-grow: 1;
    }
`

export const Logo = styled('img')`
    height: 40px;
    max-width: 100%;
    @media (max-width: 768px) {
        height: 26px;
    }
`
