import { Keyword } from '../formulaTypes'

export const getCaretPositionAfterInsertingKeyword = ({
    keyword,
    start,
}: {
    keyword: Keyword
    start: number
}): number => start + keyword.insertText.indexOf('(') + 1

export const setCaretAtPositionAndFocus = ({
    editor,
    position,
}: {
    editor: HTMLTextAreaElement
    position: number
}): void => {
    editor.setSelectionRange(position, position)
    editor.focus()
}
