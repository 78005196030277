import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import WithPages from 'data/wrappers/WithPages'
import { Heading } from 'legacy/v1/ui'

const _SettingsNav = ({ feature, location }) => {
    const pageToTab = (page) => {
        const url = `${getUrl(Urls.AdminSetup)}${feature.url}${page.url}`
        const atLocation = location.pathname === url
        return (
            <Link to={url}>
                <NavTab selected={atLocation}>
                    <Heading style={{ margin: 0, padding: 0, fontWeight: 700, fontSize: '14pt' }}>
                        {page.name}
                    </Heading>
                </NavTab>
            </Link>
        )
    }

    return (
        <WithPages showFeatureConfigData>
            {({ pages }) => {
                let pagesToShow = pages
                    .filter((page) => page.feature_id === feature._sid)
                    .filter((page) => page.is_feature_settings)
                    // hide views from config (we do these via edit button now)
                    .filter((page) => page.name !== 'Create')
                    .filter((page) => page.name !== 'List')
                    .filter((page) => page.name !== 'Detail')

                pagesToShow = sortBy(pagesToShow, ['label']).reverse()

                // Add object feature pages in manually
                pagesToShow.push({
                    url: '/fields',
                    name: <>Fields</>,
                })
                pagesToShow.push({
                    url: '/settings',
                    name: (
                        <>
                            {/* <Icon style={{ marginLeft: -10 }} icon="cog" /> */}
                            Settings
                        </>
                    ),
                })

                pagesToShow.push({
                    url: '/permissions',
                    name: <>Permissions</>,
                })
                pagesToShow.push({
                    url: '/layouts',
                    name: <>Layouts</>,
                })
                pagesToShow.push({
                    url: '/actions',
                    name: <>Actions</>,
                })

                return pagesToShow.map(pageToTab)
            }}
        </WithPages>
    )
}

_SettingsNav.propTypes = {
    feature: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}
export const SettingsNav = withRouter(_SettingsNav)

const NavTab = styled.div`
    padding: 15px 30px 20px;
    background: ${(props) => (props.selected ? 'white' : 'rgba(0,0,0,0)')};
    border-radius: 5px 5px 0px 0px;
    display: inline-flex;
    margin-bottom: -10px;
`

export const SettingsWrapper = styled.div`
    max-width: 1000px;
`

export const SettingsContent = styled.div`
    background: white;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    margin-bottom: 30px;
`
