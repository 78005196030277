import React, { useEffect, useState } from 'react'

import { quickImport } from 'features/admin/data-connector/quick_import'

// @ts-ignore
import { Text } from 'v2/ui'

type SimpleSchemaSyncProps = {
    onSyncComplete: () => void
    onSyncError: () => void
    onSyncStart: (text: string) => void
    dataConnection: DataConnectionDto
}

const SimpleSchemaSync: React.FC<SimpleSchemaSyncProps> = (props) => {
    const [error, setError] = useState()
    const [importStarted, setImportStarted] = useState(false)

    useEffect(() => {
        if (!importStarted) {
            // For fixed schema sources, we can use the synchronous quickImport
            // instead of redirecting to the schema sync page.
            props.onSyncStart('Initializing connection...')
            setImportStarted(true)
            quickImport(props.dataConnection)
                .then(() => {
                    props.onSyncComplete()
                })
                .catch((err) => {
                    console.error(err)
                    setError(err)
                    props.onSyncError()
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importStarted])

    if (error) {
        return (
            <>
                <Text variant="error">An error occurred initializing the connection.</Text>
                <Text mt={4}>Please contact support for assistance.</Text>
            </>
        )
    }
    return null
}

export default SimpleSchemaSync
