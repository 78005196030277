// Checks if a value is empty
// Allows zeros by default
const isEmptyValue = (value: any, allowZero: boolean = true) => {
    if (allowZero && value === 0) return false

    if (!value) return true

    if (Array.isArray(value) && value.length === 0) return true

    return false
}

export default isEmptyValue
