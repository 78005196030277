import React, { useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import styled from '@emotion/styled'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Button from './Button'
import Icon from './Icon'
import Text from './Text'

const ActionList = ({
    open,
    theme,
    icon,
    iconColor,
    iconSize,
    buttonText,
    actions,
    accessibilityLabel,
    buttonStyle,
    menuItemStyle,
    tooltipId,
    toolTipAlign,
    before,
    after,
    menuAlign,
    menuMinWidth,
    dataTestId,
}) => {
    const [listOpen, setListOpen] = useState(open)

    const node = useRef()

    // close when clicking outside of element
    const handleClick = (e) => {
        if (node.current && !node.current.contains(e.target)) {
            setListOpen(false)
        }
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])

    const backgroundColor = theme.buttonColor
    const borderColor = theme.buttonBorderColor

    const ButtonContainer = styled('div')`
        background-color: ${backgroundColor};
        border-radius: ${(props) => (props.round ? '100%' : props.theme.buttonBorderRadius)};
        border: ${(props) => props.theme.buttonBorderThickness} solid ${borderColor};
        position: absolute;
        top: 100%;
        right: ${menuAlign == 'left' ? 'auto' : '0'};
        left: ${menuAlign == 'left' ? '0' : 'auto'};
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        z-index: 2;
        min-width: ${menuMinWidth || 'auto'};
    `

    const ActionButton = styled(Button)`
        display: block;
        width: 100%;
        &:hover {
            background-color: ${theme.elementHighlightColor};
        }
        text-align: left;
    `

    const ActionListContainer = styled('div')`
        position: relative;
        display: inline-block;
    `

    return (
        <ActionListContainer>
            <Button
                data-testid={dataTestId}
                data-tip
                data-for={tooltipId || 'actionList'}
                margin="none"
                padding="none"
                style={buttonStyle}
                onClick={(e) => {
                    setListOpen(!listOpen)
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                {before}
                {icon && (
                    <>
                        {accessibilityLabel && (
                            <ReactTooltip
                                id={tooltipId || 'actionList'}
                                effect="solid"
                                place={toolTipAlign || 'left'}
                                delayShow="1000"
                            >
                                <div
                                    style={{
                                        maxWidth: '200px',
                                        textAlign: 'left',
                                    }}
                                >
                                    <Text
                                        size="fontS"
                                        padding="none"
                                        margin="none"
                                        style={{ color: 'white' }}
                                    >
                                        {accessibilityLabel}
                                    </Text>
                                </div>
                            </ReactTooltip>
                        )}
                        <Icon icon={icon} color={iconColor} size={iconSize} />
                    </>
                )}{' '}
                {icon ? buttonText : ''}
                {after}
            </Button>
            {listOpen && actions && (
                <div
                    ref={node}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <ButtonContainer>
                        {actions.map(({ label, action, disabled }, index) => (
                            <ActionButton
                                size="small"
                                margin="none"
                                padding="none"
                                style={menuItemStyle || buttonStyle}
                                color="clear"
                                key={index}
                                disabled={disabled}
                                onClick={() => {
                                    action()
                                    setListOpen(false)
                                }}
                            >
                                {label}
                            </ActionButton>
                        ))}
                    </ButtonContainer>
                </div>
            )}
        </ActionListContainer>
    )
}

export default withTheme(ActionList)
