import React from 'react'

type GenericObject = { [key: string]: any }

type PathContextType = {
    pageUrl?: string
    page?: GenericObject
    feature?: GenericObject
    listView?: GenericObject
    recordId?: string
    view?: GenericObject
}

export const PathContext = React.createContext<PathContextType>({})
