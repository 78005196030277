/* Code Quality: Not audited */

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import './PageTitle'

const Breadcrumbs = (props) => {
    const Crumbs = styled('div')`
        margin-top: ${props.theme.small};

        & a {
            color: #ccc;
            font-family: ${props.theme.fontFamily};
            font-size: ${props.theme.fontSize};
        }

        & + div {
            margin-top: 0;
        }
    `
    const Divider = styled('span')`
        color: #ccc;
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSize};
        margin: ${props.theme.xsmall};
        &:after {
            content: ${props.divider || "'▸'"};
        }
    `
    const Crumb = (props) => {
        if (!props.to) props = { ...props, to: '#' }
        return <Link {...props} />
    }

    let crumbs = props.crumbs || []
    crumbs = crumbs.map((crumb) => (
        <Crumb key={crumb.label} to={crumb.link}>
            {crumb.label}
        </Crumb>
    ))
    crumbs = crumbs.map((crumb, i) => [crumb, <Divider key={i} />]) || []
    crumbs = crumbs.flat()
    crumbs.pop() // Get rid of the last arrow

    return <Crumbs>{crumbs}</Crumbs>
}

Breadcrumbs.propTypes = {}
Breadcrumbs.displayName = 'Breadcrumbs'

export default withTheme(Breadcrumbs)
