import styled from '@emotion/styled'
import { variant } from 'styled-system'

import Box from './Box'

const Divider = styled(Box)<{
    variant?: 'default' | 'soft' | 'custom' | 'accountSettings'
    alignSelf?: string
    height?: string
    dense?: boolean
}>`
    ${variant({
        variants: {
            default: {
                height: '1px',
                minHeight: '1px',
                bg: 'userInterface.neutral.400',
            },
            soft: {
                height: '2px',
                background:
                    'linear-gradient(90deg, transparent 0%, rgba(0,0,0,.1) 20%, rgba(0,0,0,.1)  80%, transparent  100%)',
            },
            custom: {},
            accountSettings: {
                height: '1px',
                bg: '#737681',
            },
        },
    })}
`

Divider.defaultProps = {
    variant: 'default',
    alignSelf: 'stretch',
    height: '1px',
}

export default Divider
