import React from 'react'
import { withRouter } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'

import { withDataConnections } from 'data/wrappers/WithDataConnections'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { Divider, Frame } from 'features/workspace/WorkspaceSettingsModalUi'

import DataSourcesBanner from './v2//DataSourcesBanner'
import { DataSourceDisplay } from './DataSourceDisplay'
import { EditConnectionModal } from './EditConnectionModal'
import { NewConnectionModal } from './NewConnectionModal'
import SyncConfirmationModal from './SyncConfirmationModal'

class AppSettingsModalDataSourcesSetup extends React.Component {
    constructor(props) {
        super(props)

        const queryParams = new URLSearchParams(props.location.search)

        // When we are redirected back to this page after an external auth flow,
        // we'll have a connected param (the DC sid) and a provider param (the key of the
        // data connection provider).

        // Put this into state so we can render the right UI later on to continue the
        // DC configuration
        this.state = {
            selectingTables: null,
            provider: queryParams.get('provider'),
            connected: queryParams.get('connected'),
            edit_connection: queryParams.get('edit'),
            serviceName: queryParams.get('service_name'),
            dataConnectionSid: queryParams.get('data_connection'),
            showSyncConfirmation: queryParams.get('syncing'),
            showSyncSchema: props.showSchemaSyncDefault,
        }
    }

    render() {
        const { stack, dataConnections } = this.props
        const filteredDataConnections = dataConnections.filter((dc) => dc.type !== 'stacker_users')

        return (
            <Frame title="Data Sources">
                <DataSourcesBanner onSelect={this.handleNewDataSourceSelect} />

                {!this.props.dataConnectionsFetched ? (
                    <Spinner size="sm" />
                ) : (
                    <>
                        {filteredDataConnections.map((dc, index) => (
                            <React.Fragment key={index}>
                                <Divider dense />

                                <DataSourceDisplay
                                    expanded={filteredDataConnections.length === 1}
                                    key={dc._sid}
                                    dataConnection={dc}
                                    editDataConnection={() =>
                                        this.setState({ editingDataConnection: dc })
                                    }
                                    removeDataConnection={this.props.removeDataConnection}
                                    stack={stack}
                                />
                            </React.Fragment>
                        ))}

                        <Divider dense />
                        {this.renderEditModal()}
                    </>
                )}

                <SyncConfirmationModal
                    dataConnectionSid={this.state.dataConnectionSid}
                    isOpen={this.state.showSyncConfirmation}
                    onClose={() => {
                        this.setState({ showSyncConfirmation: false })
                    }}
                    serviceName={this.state.serviceName}
                />
            </Frame>
        )
    }

    handleNewDataSourceSelect = (key) => this.setState({ newDataSourceProvider: key })

    handleCloseModal = () => {
        this.setState({
            newDataSourceProvider: null,
            connected: null,
            edit_connection: null,
            editingDataConnection: null,
        })

        // remove any query params that are left after redirecting back to this page
        // from an external flow
        const newHistoryState = {
            search: '',
        }
        if (this.props.location.pathname.indexOf('/admin-modal')) {
            newHistoryState.pathname = this.props.location.pathname.replace('/admin-modal', '/')
        }
        this.props.history.replace(newHistoryState)
    }

    renderEditModal() {
        let connectedDC
        // If have been redirected back to this page from an external auth flow
        // we'll have a DC side specified. Find that DC now.
        if (this.state.connected) {
            connectedDC = this.props.dataConnections.find((dc) => dc._sid === this.state.connected)
        } else if (this.state.edit_connection) {
            connectedDC = this.props.dataConnections.find(
                (dc) => dc._sid === this.state.edit_connection
            )
        }
        // If we aren't creating a new data connection, we're not editing an existing connection
        // and we're not picking up with the configuration of a connection post-redirect, then
        // don't render the modal
        if (
            !this.state.newDataSourceProvider &&
            !this.state.editingDataConnection &&
            !connectedDC
        ) {
            return null
        }

        const isNew =
            Boolean(this.state.connected) ||
            (!this.state.editingDataConnection && !this.state.edit_connection)
        const provider =
            connectedDC?.type ||
            this.state.editingDataConnection?.type ||
            this.state.newDataSourceProvider
        const dc = connectedDC || this.state.editingDataConnection
        return isNew ? (
            <NewConnectionModal
                stack={this.props.stack}
                isOpen={true}
                onClose={this.handleCloseModal}
                provider={provider}
                dataConnection={dc}
            />
        ) : (
            <EditConnectionModal
                isOpen={true}
                onClose={this.handleCloseModal}
                provider={provider}
                dataConnection={dc}
            />
        )
    }
}

export default withRouter(
    withDataConnections(withUser(withStack(AppSettingsModalDataSourcesSetup)))
)
