import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'

import truncateLabel from '../utils/truncateLabel'

// When rotated, the label is rotated 45 degrees clockwise. Converted to radians
const LABEL_ANGLE = (45 * Math.PI) / 180

const Text = styled.text`
    fill: ${(props) => props.theme.colors.neutral[1000]};
`

type Props = {
    payload: {
        coordinate: number
        index: number
        isShow: boolean
        offset: number
        tickCoord: number
        value: string
    }
    barWidth: number
    width: number
    height: number
    chartHeight: number
    isLastLabel: boolean
    textAnchor: 'start' | 'end'
    rotateLabel: boolean
    onLabelRotated: () => void
}

export const CategoryLabel: FC<Props> = ({
    payload,
    barWidth,
    chartHeight,
    height,
    isLastLabel,
    textAnchor,
    rotateLabel,
    onLabelRotated,
}) => {
    const textRef = useRef<SVGTextElement>(null)
    const [isLabelRotated, setIsLabelRotated] = useState(false)

    const { maxDiagonalWidth, maxHorizontalWidth } = useMemo(
        () => ({
            maxDiagonalWidth: (height - 3) / Math.cos(LABEL_ANGLE),
            maxHorizontalWidth: barWidth - 4,
        }),
        [barWidth, height]
    )

    const buildLabel = useCallback(() => {
        const minLetters = isLastLabel ? 1 : 3

        if (!rotateLabel) {
            const maxAvailableWidth = isLastLabel ? maxHorizontalWidth / 2 : maxHorizontalWidth
            const isTruncated = truncateLabel(
                payload.value,
                payload.tickCoord,
                textRef.current,
                maxAvailableWidth,
                minLetters
            )

            if (!isTruncated) {
                setIsLabelRotated(false)
                return
            }
        }

        if (maxDiagonalWidth <= maxHorizontalWidth && !rotateLabel) {
            setIsLabelRotated(false)
            return
        }

        const maxAvailableDiagonalWidth = isLastLabel
            ? height / 2 / Math.cos(LABEL_ANGLE)
            : maxDiagonalWidth

        truncateLabel(
            payload.value,
            payload.tickCoord,
            textRef.current,
            maxAvailableDiagonalWidth,
            minLetters
        )
        setIsLabelRotated(true)
        onLabelRotated()
    }, [
        height,
        isLastLabel,
        maxDiagonalWidth,
        maxHorizontalWidth,
        onLabelRotated,
        payload.tickCoord,
        payload.value,
        rotateLabel,
    ])

    useEffect(() => {
        buildLabel()
    }, [buildLabel])

    if (isLabelRotated) {
        return (
            <Text
                ref={textRef}
                x={payload.tickCoord - barWidth / 4}
                y={chartHeight - height + 6}
                textAnchor="start"
                transform={`rotate(45 ${payload.tickCoord - barWidth / 4} ${
                    chartHeight - height + 6
                })`}
            >
                {payload.value}
            </Text>
        )
    }

    return (
        <Text
            ref={textRef}
            x={payload.tickCoord}
            y={chartHeight - height + 12}
            textAnchor={isLastLabel ? 'end' : textAnchor}
        >
            {payload.value}
        </Text>
    )
}
