import React, { useEffect, useMemo } from 'react'

import { useRecords } from 'data/hooks/records'
import { recordsToOptions } from 'features/records/components/RecordDropdown'

import { InlineFilter } from '../InlineFilter'
import { useInlineFilter } from '../useInlineFilter'
import { getInlineFilterValueLabels } from '../valueUtils'

import { LookupFilterContextProvider, useLookupFilter } from './LookupFilterContext'
import type { InlineFilterComponent } from './types'

const LookupInlineFilterInternal: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { searchString, setIsFetching } = useLookupFilter()

    const fetchOptions = {
        includeFields: ['_primary'],
        pageSize: 50,
        forcePartials: true,
    }

    const filters = useMemo(() => {
        if (searchString) {
            return [
                {
                    field: { api_name: '_search' },
                    options: { value: searchString, operator: 'AND' },
                },
            ]
        }

        return undefined
    }, [searchString])

    const { filter, ...inlineFilterProps } = useInlineFilter({ field })

    const { data: { records = [] } = {}, isFetching } = useRecords({
        objectId: field.options?.lookup_target,
        filters,
        fetchOptions,
        options: { refetchOnMount: true }, // only remount if the data is stale
    })
    const options = useMemo(() => recordsToOptions(records), [records])

    const valueLabels = useMemo(
        () => getInlineFilterValueLabels(filter, options),
        [filter, options]
    )

    useEffect(() => {
        setIsFetching(isFetching)
    }, [isFetching, setIsFetching])

    return (
        <InlineFilter
            field={field}
            columns={columns}
            valueLabels={valueLabels}
            options={options}
            {...inlineFilterProps}
        />
    )
}

export const LookupInlineFilter: typeof LookupInlineFilterInternal = (props) => {
    return (
        <LookupFilterContextProvider>
            <LookupInlineFilterInternal {...props} />
        </LookupFilterContextProvider>
    )
}
