import React from 'react'

import { Flex } from 'v2/ui'

import { LinkFormObjectLabel } from '../form/fragments/LinkFormObjectLabel'

import { LinkPreviewCard } from './LinkPreviewCard'
import { LinkPreviewContainer } from './LinkPreviewContainer'
import { LinkPreviewValue } from './LinkPreviewValue'

/**
 *
 * @param {{ sourceObject: object, sourceField: object, targetObject: object, targetField: object, values: LinkUpdateData}} param0
 * @returns
 */
export const LinkPreviewView = ({
    sourceObject,
    sourceField,
    targetObject,
    targetField,
    values,
}) => {
    const getRecordName = (object, id) => `${object?.name} record ${id}`

    const sourceRecordName = getRecordName(sourceObject, 1)
    const targetRecordName = getRecordName(targetObject, 1)

    const sourceSymmetricValues = [
        getRecordName(targetObject, 1),
        getRecordName(targetObject, 2),
        getRecordName(targetObject, 3),
    ]
    const targetSymmetricValues = [
        getRecordName(sourceObject, 1),
        getRecordName(sourceObject, 2),
        getRecordName(sourceObject, 3),
    ]

    const underlyingLinkFieldValue = `${sourceObject.name} ${sourceField.label}`.toLowerCase()

    const sourceLinkValue =
        values.linkFieldObject === values.source ? targetRecordName : underlyingLinkFieldValue
    const targetLinkValue =
        values.linkFieldObject === values.target ? sourceRecordName : underlyingLinkFieldValue

    // If either the source or target link is readonly, the link is considered read only as well
    // Once a field is created and is shadowed, it will be labeled as read only by the backend
    // so we need to add the check for the shadowed status to get the original value
    const sourceReadOnly =
        sourceField?.connection_options?.is_locked ?? sourceField?.connection_options?.read_only
    const targetReadOnly =
        targetField?.connection_options?.is_locked ?? targetField?.connection_options?.read_only

    // If we're editing an existing link, get the read only status from it. If we are
    // creating a new link, its read only status is based on the source and target fields
    const linkReadOnly = sourceReadOnly || targetReadOnly
    return (
        <LinkPreviewContainer>
            <LinkPreviewCardView
                legacy={values.legacy}
                label={<LinkFormObjectLabel variant="primary" object={sourceObject} />}
                recordName={sourceRecordName}
                isLink={values.linkFieldObject === values.source}
                linkName={values.sourceFieldLinkName}
                linkValue={sourceLinkValue}
                symmetricValues={sourceSymmetricValues}
                underlyingFieldName={sourceField.label}
                underlyingLinkFieldValue={underlyingLinkFieldValue}
                isReadOnlyLink={linkReadOnly}
                mode={values.mode}
            />
            <LinkPreviewCardView
                legacy={values.legacy}
                label={<LinkFormObjectLabel variant="secondary" object={targetObject} />}
                recordName={targetRecordName}
                isLink={values.linkFieldObject === values.target}
                linkValue={targetLinkValue}
                linkName={values.targetFieldLinkName}
                symmetricValues={targetSymmetricValues}
                underlyingFieldName={targetField.label}
                underlyingLinkFieldValue={underlyingLinkFieldValue}
                isReadOnlyLink={linkReadOnly}
                mode={values.mode}
            />
        </LinkPreviewContainer>
    )
}

const LinkPreviewCardView = ({
    isLink,
    label,
    recordName,
    linkName,
    linkValue,
    symmetricValues,
    underlyingFieldName,
    legacy,
    underlyingLinkFieldValue,
    isReadOnlyLink,
    mode,
}) => {
    // Legacy links do not have symmetric fields so we do not
    // show them in the preview
    const showSymmetricValues = !legacy
    return isLink ? (
        <LinkPreviewLinkCardView
            label={label}
            recordName={recordName}
            fields={[
                <LinkPreviewValue
                    key={underlyingFieldName}
                    isReadOnly={true}
                    fieldName={underlyingFieldName}
                    values={[underlyingLinkFieldValue]}
                />,
                <LinkPreviewValue
                    key={linkName}
                    fieldName={linkName}
                    isReadOnly={isReadOnlyLink}
                    isLink={true}
                    isReference={true}
                    values={mode === 'many-to-many' ? symmetricValues : [linkValue]}
                />,
            ]}
        />
    ) : (
        <LinkPreviewSymmetricCardView
            legacy={legacy}
            label={label}
            recordName={recordName}
            fields={
                showSymmetricValues
                    ? [
                          <LinkPreviewValue
                              key={underlyingFieldName}
                              fieldName={underlyingFieldName}
                              values={[underlyingLinkFieldValue]}
                          />,
                          <LinkPreviewValue
                              key={linkName}
                              fieldName={linkName}
                              isReadOnly={isReadOnlyLink}
                              isReference={true}
                              values={symmetricValues}
                          />,
                      ]
                    : [
                          <LinkPreviewValue
                              key={underlyingFieldName}
                              fieldName={underlyingFieldName}
                              values={[underlyingLinkFieldValue]}
                          />,
                      ]
            }
        />
    )
}

const LinkPreviewLinkCardView = ({ label, recordName, fields }) => {
    return (
        <LinkPreviewCard>
            {label}
            <Flex>
                <b>{recordName}</b>
            </Flex>
            {fields.map((field) => field)}
        </LinkPreviewCard>
    )
}

const LinkPreviewSymmetricCardView = ({ label, recordName, fields }) => {
    return (
        <LinkPreviewCard>
            {label}
            <Flex>
                <b>{recordName}</b>
            </Flex>
            {fields.map((field) => field)}
        </LinkPreviewCard>
    )
}
