import styled from '@emotion/styled'

export const Cell = styled('div')`
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    padding: 0.6rem;

    :last-child {
        border-right: 0;
    }
`

export const Button = styled('button')`
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
    background: white;
    border-radius: 5px;
    cursor: pointer;

    :disabled {
        opacity: 0.3;
    }

    &:active {
        outline: none;
    }
`

export const Select = styled('select')`
    appearance: none;
    background: white;
    border: 0;
    margin: 0;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    border: 0;
    cursor: pointer;
`
