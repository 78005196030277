/* Code Quality: Not audited */

import React, { Component } from 'react'

class ContainerBlockType extends Component {
    render() {
        let { ChildBlocks } = this.props
        return <ChildBlocks />
    }
}

ContainerBlockType.propTypes = {}

ContainerBlockType.isDroppable = true

ContainerBlockType.attributes = []

ContainerBlockType.behaviours = {
    onClick: true,
}
export default ContainerBlockType
