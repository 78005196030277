import React from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { userActions } from '../api/userApi'

class _WithUser extends React.Component {
    render() {
        return this.props.children({
            userActions: this.props.userActions,
            user: this.props.user,
            studioUser: this.props.studioUser,
            isStudioUser: Boolean(this.props.studioUser),
            isLoggedIn: Boolean(this.props.user),
            isImpersonating:
                this.props.studioUser &&
                get(this.props.user, '_sid') !== get(this.props.studioUser, '_sid'),
            isEditing: this.props.isEditing,
            redirect: this.props.redirect,
            editingStartRequested: this.props.editingStartRequested,
            editingStopRequested: this.props.editingStopRequested,
            allUserDataFetched: this.props.allUserDataFetched,
        })
    }
}

_WithUser.propTypes = {
    children: PropTypes.func.isRequired,
    userActions: PropTypes.object.isRequired, // From redux
    user: PropTypes.object.isRequired, // From redux
    studioUser: PropTypes.object.isRequired, // From redux
    isEditing: PropTypes.bool.isRequired, // From redux
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        studioUser: state.user.studioUser,
        isEditing: state.user.isEditing,
        editingStartRequested: state.user.editingStartRequested,
        editingStopRequested: state.user.editingStopRequested,
        redirect: state.user.redirect,
        allUserDataFetched: state.user.allUserDataFetched,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

const WithUser = connect(mapStateToProps, mapDispatchToProps)(_WithUser)

export default WithUser

export const withUser = (Child) => (props) =>
    <WithUser>{(userProps) => <Child {...userProps} {...props} />}</WithUser>
