import { QueryDict } from 'features/utils/filtersToQueryDict'

import { preprocessChartsFilterValue } from './preprocessChartsFilterValue'

export const chartsFiltersToQueryDict = (filters: ChartFilter[]): QueryDict => {
    return filters.reduce<QueryDict>(
        (agg, filter) => {
            const value = preprocessChartsFilterValue(filter)
            if (value !== undefined) {
                const encodedModifier = filter.operation ? `___${filter.operation}` : ''
                const serializedValue = value.toString()

                const field = filter.target ?? ''
                const encodedFilter = `${encodeURIComponent(
                    field
                )}${encodedModifier}=${encodeURIComponent(serializedValue)}`

                agg['filters'].push(encodedFilter)
            }

            return agg
        },
        { filters: [] }
    )
}
