import React, { useContext, useState } from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { useAccounts, useDeleteAccount } from 'data/hooks/accounts'
import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'
import { ExpandSection } from 'legacy/v1/ui'

import { Alert, Box, Button, Input, Link, Modal, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { getNextWorkspaceUrl } from './utils/getNextWorkspaceUrl'

type DeleteWorkspaceProps = {
    isInModal?: boolean
    prefix?: React.ReactElement
}

type DeleteModalProps = {
    isOpen: boolean
    closeModal: () => void
    isSecondLayer: boolean
}
const handleClick = () => {
    openWorkspaceSettingsModal({ page: 'billing' })
}

const DeleteModal = ({ isOpen = false, closeModal, isSecondLayer = false }: DeleteModalProps) => {
    const { workspaceAccount, isSubscribed } = useContext(AppContext)
    const [urlMatch, setUrlMatch] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const { studioUser } = useAppUserContext()
    const { mutateAsync: deleteAccount } = useDeleteAccount()
    const { data: accounts } = useAccounts()

    const [processing, setProcessing] = useState<boolean>(false)

    if (!workspaceAccount) return null
    return (
        <Modal isOpen={isOpen} size="600px" onClose={closeModal} isSecondLayer={isSecondLayer}>
            <Box display="flex" m={4} flexDirection="column" textAlign="center">
                <Text variant="modalTitle" fontWeight="bold">
                    Are you sure you want to delete your workspace?
                </Text>
                <Text fontWeight="bold" mt={4}>
                    Deleting your workspace is irreversible.
                </Text>
                {isSubscribed && (
                    <Alert
                        status="warning"
                        description={
                            <Text>
                                Your workspace currently has an active subscription, which prevents
                                you from deleting it. Please {''}
                                <span>
                                    <Link
                                        borderBottom="1px solid"
                                        variant="noHover"
                                        onClick={handleClick}
                                    >
                                        click here to cancel your subscription first,
                                    </Link>
                                </span>{' '}
                                then contact support to delete your workspace.
                            </Text>
                        }
                        m={0}
                        mt={4}
                        fontSize="sm"
                        lineHeight="normal"
                    />
                )}
                <Text mt={4}>
                    Please type <b>{workspaceAccount.name}</b> to confirm
                </Text>
                <Input
                    disabled={isSubscribed}
                    mt={4}
                    variant="admin"
                    data-testid="workspace-settings.modal.delete-workspace.input"
                    id="delete-app-input"
                    value={urlMatch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUrlMatch(e.target?.value)
                    }
                />

                <Button
                    data-testid="workspace-settings.modal.delete-workspace.button"
                    variant="PrimaryError"
                    buttonSize="md"
                    mt={4}
                    disabled={urlMatch !== workspaceAccount.name || processing}
                    onClick={() => {
                        setProcessing(true)
                        setError(false)
                        if (workspaceAccount)
                            deleteAccount(workspaceAccount._sid)
                                .then(() => {
                                    const currentAccounts = accounts?.filter(
                                        (a) => a._sid !== workspaceAccount._sid
                                    )
                                    const nextWorkspaceUrl = getNextWorkspaceUrl({
                                        accounts: currentAccounts,
                                        studioUser,
                                    })
                                    window.location.href = nextWorkspaceUrl
                                })
                                .catch(() => {
                                    setProcessing(false)
                                    setError(true)
                                })
                    }}
                >
                    I understand. Delete my workspace.
                </Button>
                <Button variant="Secondary" buttonSize="md" mt={4} onClick={closeModal}>
                    Back to Safety
                </Button>
                {error && (
                    <Text style={{ color: '#e74c3c' }}>
                        Sorry, there was an error deleting the workspace. Please try again.
                    </Text>
                )}
            </Box>
        </Modal>
    )
}

export const DeleteWorkspace = ({ isInModal = false, prefix }: DeleteWorkspaceProps) => {
    const { hasRight } = useAccountUserContext()
    const { workspaceAccount } = useContext(AppContext)
    const hasDeleteRights = hasRight(Rights.DeleteWorkspace)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

    if (!hasDeleteRights || !workspaceAccount) return null

    return (
        <>
            {prefix}
            {/* @ts-expect-error */}
            <ExpandSection
                heading="Delete your workspace"
                helpUrl=""
                helpText=""
                text={null}
                classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.DELETE_APP }}
                testId="workspace-settings.delete-workspace"
            >
                <Button
                    variant="PrimaryError"
                    buttonSize="sm"
                    width="100px"
                    onClick={() => setDeleteModalOpen(true)}
                    data-testid="workspace-settings.delete-workspace.button"
                >
                    Delete
                </Button>
                <DeleteModal
                    isSecondLayer={isInModal}
                    isOpen={deleteModalOpen}
                    closeModal={() => setDeleteModalOpen(false)}
                />
            </ExpandSection>
        </>
    )
}
