import React from 'react'
import Vimeo from 'react-vimeo-embed'

import styled from '@emotion/styled'

import { Alert, Button, Flex, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import V4DesignSystem from '../../../../ui/deprecated/V4DesignSystem'

import { AirtableUpgradePermissionsImage } from './utils/AirtableUpgradePermissionsImage'
import { AirtableOAuthLearnMoreLink } from './AirtableOAuthLearnMoreLink'
import {
    AIRTABLE_NO_PERMISSION_RETRY_BUTTON_TEST_ID,
    AIRTABLE_UPGRADE_NO_PERMISSION_MODAL_TEST_ID,
} from './airtableTestIds'
import { StyledFlex } from './StyledComponents'

type UpgradeFailureWithoutPermissionsModalProps = {
    isOpen: boolean
    onClose: () => void
    onTryAgain: () => void
}

const Row = styled(Flex)`
    flex-direction: row;
    text-align: center;
    justify-content: center;
    width: 100%;
`

const StyledText = styled(Text)`
    text-align: center;
    display: block;
    width: 100%;
`

export const UpgradeFailureWithoutPermissionsModal = ({
    isOpen,
    onClose,
    onTryAgain,
}: UpgradeFailureWithoutPermissionsModalProps) => {
    return (
        <SimpleModalCompat
            size="470px"
            height="auto"
            isSecondLayer
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            data-testid={AIRTABLE_UPGRADE_NO_PERMISSION_MODAL_TEST_ID}
        >
            <StyledFlex>
                <AirtableUpgradePermissionsImage />
                <Row>
                    <Text variant="heading" marginTop={1} marginBottom={4}>
                        Adjust your permissions
                    </Text>
                </Row>
                <StyledText fontSize="14px" marginBottom={4}>
                    Please go to{' '}
                    <a href="https://airtable.com" target="_blank" rel="noreferrer">
                        Airtable
                    </a>{' '}
                    to adjust your permissions and repair Airtable access.
                </StyledText>
                <Vimeo
                    video="https://vimeo.com/video/809767223?h=f3086db5f9"
                    autoplay
                    muted
                    loop
                    height={225}
                    width={450}
                    controls={false}
                />
                <Alert
                    border="1px solid"
                    borderColor="userInterface.neutral.800"
                    description={
                        <>
                            <Text fontSize="14px" style={{ lineHeight: '19px' }}>
                                First, click on your picture in the top right corner, then click on{' '}
                                <strong>Integrations</strong>. Navigate to{' '}
                                <strong>Third-party integrations</strong>, and click on Stacker. Set
                                permissions to <strong>All current and future bases</strong>.
                            </Text>
                        </>
                    }
                    status="info"
                    iconStyle={{
                        size: 4,
                        color: V4DesignSystem.colors.gray[300],
                    }}
                    background="#F8F9FA"
                    m={0}
                    mt={5}
                    mb={5}
                    w="100%"
                    fontSize={12}
                    boxShadow="none"
                ></Alert>
                <Row w="100%">
                    <Button
                        flexGrow={1}
                        variant="Secondary"
                        maxW={221}
                        mr={5}
                        buttonSize="medium"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        flexGrow={1}
                        variant="Primary"
                        maxW={221}
                        buttonSize="medium"
                        onClick={onTryAgain}
                        data-testid={AIRTABLE_NO_PERMISSION_RETRY_BUTTON_TEST_ID}
                    >
                        Try Again
                    </Button>
                </Row>
                <AirtableOAuthLearnMoreLink />
            </StyledFlex>
        </SimpleModalCompat>
    )
}
