import React, { useEffect, useState } from 'react'

import useTrack from 'utils/useTrack'

import { Template } from '../../../data/hooks/templates'
import TemplateLibraryModal from '../../templates/TemplateLibraryModal'

type TemplateLibraryOnboardingProps = {
    onInstallComplete: (stack: StackDto, template: Template | null | undefined) => void
    onSelectTemplate?: (template: Template | null) => void
    onSkip?: () => void
    accountId: string
}
const TemplateLibraryOnboarding = (props: TemplateLibraryOnboardingProps) => {
    const { track } = useTrack()
    const { accountId, onInstallComplete, onSkip, onSelectTemplate } = props
    const [templateId, setTemplateId] = useState<string | undefined>(undefined)
    const onTemplateSelectionChange = (template: Template | null) => {
        if (template) {
            track('WIP - Frontend - Onboarding - Templates - Selected', {
                template_name: template?.name,
            })
        }

        setTemplateId(template?.auto_id)
        onSelectTemplate?.(template)
    }

    // Only run once
    useEffect(() => {
        track('WIP - Frontend - Onboarding - Templates - Modal opened')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TemplateLibraryModal
            showCloseButton={false}
            accountId={accountId}
            templateId={templateId}
            onTemplateSelectionChange={onTemplateSelectionChange}
            onInstallComplete={onInstallComplete}
            onSkip={onSkip}
        />
    )
}

export default TemplateLibraryOnboarding
