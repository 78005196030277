import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import queryString from 'qs'

const QUERIES = ['ignore_css']

const PreserveQuery = ({}) => {
    const location = useLocation()
    const history = useHistory()
    const query = useRef(
        queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        })
    )

    useEffect(() => {
        let newQuery = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        })
        let needToUpdate = false
        QUERIES.forEach((q) => {
            if (newQuery[q] !== query.current[q]) {
                needToUpdate = true
                newQuery[q] = query.current[q]
            }
        })
        if (needToUpdate) {
            const search = queryString.stringify(newQuery)
            history.replace({ ...location, search })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return null
}

export default PreserveQuery
