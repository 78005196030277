import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalCallbackKeys } from './commandbar-keys'

type CommandBarShortcutProps = {
    name: GlobalCallbackKeys
    action: string | (() => void)
    isActive?: boolean
}

/**
 *
 * A component for managing callbacks for commands with keyboard shortcuts.
 *
 * Many of the shortcuts are for actions that require changing state at
 * the component level. The shortcut component allows us to manage these
 * callbacks with a small footprint.
 *
 * @param {GlobalCallbackKeys} name
 * @param {string | (() => void)} action - If a string is passed as
 * an action, the shortcut will be treated as a link command and will
 * navigate to the provided url via the router.
 *
 * If a function is provided, the shortcut will be treated as a callback
 * command and will execute the function.
 *
 * @param {boolean | undefined} [isActive=true]
 *
 * @returns {null}
 */
const CommandBarShortcut = ({ name, action, isActive = true }: CommandBarShortcutProps): null => {
    const history = useHistory()

    useEffect(() => {
        if (isActive) {
            if (typeof action === 'string') {
                window.CommandBar?.addCallback(name, () => history.push(action))
            } else {
                window.CommandBar?.addCallback(name, action)
            }
        }

        return () => {
            window.CommandBar?.removeCallback(name)
        }
    }, [name, action, isActive, history])

    return null
}

export default CommandBarShortcut
