import React from 'react'

const BackgroundAlignTop: React.FC<React.SVGProps<SVGSVGElement>> = ({
    fill = '#B6BEC5',
    ...props
}) => {
    return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m4.854 5.175 1.927-1.927 1.927 1.927M6.781 3.57v8.67M1 1.321h11.562"
                stroke={fill}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default BackgroundAlignTop
