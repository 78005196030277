export const RECORD_LOCK_SETTINGS = {
    checkFrequencyMs: 200,
    lockDuration: 15000,
}

const recordLockMap: Record<string, number> = {}

export function lockRecordFromUpdate(recordSid: string): void {
    cleanRecordLockMap()
    recordLockMap[recordSid] = Date.now()
}

export function unlockRecordFromUpdate(recordSid: string): void {
    delete recordLockMap[recordSid]
}

export async function waitUntilRecordUnlocked(recordSid: string): Promise<void> {
    let remainingLoops = Math.floor(
        RECORD_LOCK_SETTINGS.lockDuration / RECORD_LOCK_SETTINGS.checkFrequencyMs
    )
    while (remainingLoops > 0) {
        cleanRecordLockMap()
        if (!recordLockMap[recordSid]) {
            return
        }
        await new Promise((resolve) => setTimeout(resolve, RECORD_LOCK_SETTINGS.checkFrequencyMs))
        remainingLoops -= 1
    }
}

function cleanRecordLockMap() {
    const deleteOlderThan = Date.now() - RECORD_LOCK_SETTINGS.lockDuration
    for (const key of Object.keys(recordLockMap)) {
        if (recordLockMap[key] < deleteOlderThan) {
            delete recordLockMap[key]
        }
    }
}
