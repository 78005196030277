import React, { useCallback, useEffect, useRef, useState } from 'react'

import LayoutObjectEditor from 'features/utils/LayoutObjectEditor'

import { Button, Icon, Tooltip } from 'v2/ui'
import { IconProps } from 'v2/ui/components/Icon'
import { SolidCog } from 'v2/ui/svgs'

type MenuToggleProps = {
    label: string
    title: string
    icon?: string
    svg?: IconProps['svg']
    tooltipText?: string
    tooltipStartOpen?: boolean
    style?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    'data-testid'?: string
}

const MenuToggle: React.FC<MenuToggleProps> = ({
    label,
    title,
    icon,
    svg,
    children,
    tooltipText,
    tooltipStartOpen = false,
    style,
    buttonStyle,
    'data-testid': dataTestId,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const windowTimeout = useRef<ReturnType<typeof setTimeout> | null>()

    const handleCloseToolip = useCallback(() => {
        setTooltipOpen(false)
    }, [])

    const clearCurrentTimeout = useCallback(() => {
        if (windowTimeout.current) clearTimeout(windowTimeout.current)
        windowTimeout.current = null
    }, [])

    useEffect(() => {
        if (tooltipStartOpen) {
            clearCurrentTimeout()
            windowTimeout.current = setTimeout(() => setTooltipOpen(true), 1)
        }
        return clearCurrentTimeout
    }, [tooltipStartOpen, clearCurrentTimeout])

    const toggleOpen = useCallback(() => {
        setTooltipOpen(false)
        setIsOpen((isOpen) => !isOpen)
    }, [])
    const closeToggle = useCallback(() => setIsOpen(false), [])

    return (
        <Tooltip
            placement="right"
            open={tooltipOpen}
            label={tooltipText}
            onRequestClose={handleCloseToolip}
            disabled={!tooltipText}
            style={style}
        >
            <>
                <Button
                    variant="menuToggle"
                    onClick={toggleOpen}
                    svgIcon={<SolidCog />}
                    iconAlign="right"
                    iconColor="grey.300"
                    style={buttonStyle}
                    data-testid={dataTestId}
                >
                    <Icon
                        icon={icon}
                        svg={svg}
                        mr={2}
                        color="admin.menuToggle.icon"
                        fontSize="admin.menuToggle.icon"
                    />
                    {label}
                </Button>
                <LayoutObjectEditor isOpen={isOpen} title={title} onClose={closeToggle}>
                    {children}
                </LayoutObjectEditor>
            </>
        </Tooltip>
    )
}

export default MenuToggle
