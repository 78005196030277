import React from 'react'

export const LinkFormSection = ({ title, children, ...props }) => {
    const isToggle = typeof props.value === 'boolean'
    return (
        <div style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
            <div style={{ fontSize: '0.95rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
                    {isToggle && (
                        <input
                            style={{ marginRight: 10 }}
                            type="checkbox"
                            checked={props.value}
                            onChange={props.onChange}
                        />
                    )}
                    <span>
                        <b>{title}</b>
                    </span>
                </div>
            </div>
            {isToggle && props.value === false
                ? null
                : React.Children.map(children, (child) => (
                      <div style={{ marginTop: 8 }}>{child}</div>
                  ))}
        </div>
    )
}
