import * as React from 'react'
import { Suspense, useMemo, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'

import { withUser } from 'data/wrappers/WithUser'
import Chart, { mapChartTypeToElement } from 'features/charts/RenderChart'
import { mutate_convertDesktopLayoutToMobile } from 'features/charts/utils/utils'

import { EmptyState, Flex, Icon, Modal } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import Button from 'ui/deprecated/atoms/Button'
import GridLayout from 'ui/deprecated/molecules/GridLayout'

import AddAChart from './AddAChart'
import DashboardContextProvider, {
    BREAKPOINT,
    HAS_TABLET_BREAKPOINT,
    MOBILE_BREAKPOINT,
    useDashboardContext,
} from './context'
import EditChart from './EditChart'

const colors = stackerTheme().colors

function RenderDashboardFromView({ isDashboardPage }) {
    const {
        config,
        currentLayout,
        onLayoutChange,
        updateCount,
        width,
        breakpoint,
        setEditingChartId,
        editingChartId,
        allowEdits,
        viewType,
    } = useDashboardContext()

    const forcedBreakpoint = 'md'

    const charts = useMemo(() => {
        const charts = Object.entries(config.charts || {}).map(([chartId, chartData]) => {
            const constraints = mapChartTypeToElement[chartData.chart_type].gridParams
            return {
                ...chartData,
                dataGrid: {
                    h: constraints?.defaultH || constraints?.minH || 1,
                    w: constraints?.defaultW || constraints?.minW || 1,
                    ...config.layouts[forcedBreakpoint]?.find(({ i }) => i === chartId),
                    ...currentLayout?.[forcedBreakpoint]?.find(({ i }) => i === chartId),
                    ...constraints,
                },
            }
        })

        charts.map(({ dataGrid, chart_type }) => {
            const constraints = mapChartTypeToElement[chart_type].gridParams
            if (dataGrid.h > constraints?.maxH) {
                dataGrid.h = constraints.maxH
            }
            if (dataGrid.h < constraints?.minH) {
                dataGrid.h = constraints.minH
            }
            if (dataGrid.w > constraints?.maxW) {
                dataGrid.w = constraints.maxW
            }
            if (dataGrid.w < constraints?.minW) {
                dataGrid.w = constraints.minW
            }
        })

        // newly added charts don't have positions yet
        charts.map(({ dataGrid }) => {
            if (!dataGrid.hasOwnProperty('x') && !dataGrid.hasOwnProperty('y')) {
                const [smallestColumn, smallestColumnHeight] = getSmallestColumn(
                    charts.map(({ dataGrid }) => dataGrid),
                    dataGrid.w
                )
                dataGrid.x = smallestColumn // this is needed for newly added charts
                dataGrid.y = smallestColumnHeight + 100 // we want it be as down as possible, without breaking the layout
            }
        })

        return charts
    }, [config.charts, config.layouts, forcedBreakpoint, currentLayout])

    const emptyState = config.charts && charts.length === 0

    return (
        <div style={{ marginLeft: breakpoint === 'md' && viewType === 'dashboard' ? 10 : 0 }}>
            <RenderGridLayout
                key={updateCount}
                charts={
                    breakpoint !== 'md'
                        ? mutate_convertDesktopLayoutToMobile({
                              charts,
                              maxCols: breakpoint === 'xs' ? 1 : 2,
                          })
                        : charts
                }
                onChange={(data, layouts) => onLayoutChange(layouts)}
                width={width}
            />
            <Modal
                size="760px"
                isOpen={editingChartId}
                onClose={() => setEditingChartId(null)}
                title="Edit Chart"
                _style={{ zIndex: 110, padding: 10 }}
            >
                {editingChartId && (
                    <EditChart
                        key={editingChartId}
                        item={charts.find(({ id }) => id === editingChartId)}
                        onRequestClose={() => setEditingChartId(null)}
                    />
                )}
            </Modal>

            {allowEdits ? (
                <div
                    style={{
                        // the horizontal padding lines the edges of the widget up with
                        // the cards in the grid(there's 10px padding around each card)
                        padding: '8px 10px 0',
                    }}
                >
                    <AddAChart
                        emptyState={emptyState}
                        EmptyStateContent={
                            isDashboardPage ? (
                                <div style={{ fontWeight: 600 }}>
                                    Start building your new dashboard
                                </div>
                            ) : null
                        }
                    />
                </div>
            ) : emptyState && isDashboardPage ? (
                <EmptyState
                    custom="This dashboard is being configured"
                    svg={SvgIcons.EmptyDashboard}
                />
            ) : null}
        </div>
    )
}

const RenderGridLayout = ({ charts, onChange, width }) => {
    const { allowEdits, editingChartId, hasStartedEditingRef, breakpoint, viewContext } =
        useDashboardContext()

    const [currentBreakpoint, setCurrentBreakpoint] = useState(breakpoint)

    // because there is an inner margin of -10px on lists, expanding the size of 20px, we need to adjust the breakpoints
    let size_adjustment = 0
    if (viewContext !== 'dashboard') {
        size_adjustment = 20
    }

    return (
        <GridLayout
            cols={{ md: 4, xs: 1, sm: 2 }}
            key={currentBreakpoint}
            rowHeight={125}
            breakpoints={{
                md: BREAKPOINT - 1 + size_adjustment,
                xs: 0,
                ...(HAS_TABLET_BREAKPOINT ? { sm: MOBILE_BREAKPOINT - 1 + size_adjustment } : {}),
            }}
            width={width}
            onLayoutChange={onChange}
            isDraggable={allowEdits}
            isResizable={allowEdits}
            onDragStart={(...props) => {
                hasStartedEditingRef.current = true
                props[5].style.zIndex = 3
            }}
            onDragStop={(...props) => {
                props[5].style.zIndex = 1
            }}
            onResizeStart={() => (hasStartedEditingRef.current = true)}
            onBreakpointChange={setCurrentBreakpoint}
        >
            {charts.map((chart) => (
                <div
                    data-grid={chart.dataGrid}
                    key={chart.id}
                    style={{
                        justifyContent: 'center',
                        height: '100%',
                        background: 'white',
                        padding: 10,
                        borderRadius: '0.375rem',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 5px',
                        position: 'relative',
                        overflow: 'hidden',
                        opacity: editingChartId && editingChartId !== chart.id ? 0.4 : 1,
                        cursor: allowEdits ? 'grab' : 'default',
                        zIndex: 1,
                    }}
                >
                    <ChartActions id={chart.id} />
                    <ChartErrorBoundary>
                        {chart.widget_type === 'chart' || true ? (
                            <Suspense fallback={<ChartLoader />}>
                                <Chart {...chart} />
                            </Suspense>
                        ) : (
                            <div>&apos;{chart.widget_type}&apos; is an unsupported widget_type</div>
                        )}
                    </ChartErrorBoundary>
                </div>
            ))}
        </GridLayout>
    )
}

/**
 *
 * @param {DATAGRID[]} charts
 * @param {number} size size of the chart to add
 */
function getSmallestColumn(charts, size = 1) {
    const columns = [-1, -1, -1, -1]
    for (let dataGrid of charts) {
        // if size > 1, it can't be put in the last column
        const realPositionChart = Math.min(dataGrid.x, 4 - dataGrid.w)

        const endPositionInitialColumn = columns[realPositionChart] + dataGrid.h
        let finalPosition = endPositionInitialColumn

        // add chart's height to all columns it is in
        for (let i = realPositionChart; i < realPositionChart + dataGrid.w; i++) {
            finalPosition = Math.max(finalPosition, columns[i] + dataGrid.h)
        }
        for (let i = realPositionChart; i < realPositionChart + dataGrid.w; i++) {
            columns[i] = finalPosition
        }
    }

    // for wider charts, we need to compute the smallest tuple of columns it can fit in
    const combo = []
    for (let i = 0; i <= 4 - size; i++) {
        const current = {
            i,
            max: -1,
        }

        for (let y = 0; y < size; y++) {
            current.max = Math.max(current.max, columns[i + y])
        }

        combo.push(current)
    }

    let min = combo[0].max
    let minColumn = 0
    // which combo has the smallest biggest column, ie [ [3,2,2], [2,2,4] ] -> the first group has the smallest biggest ( 3, vs 4 )
    for (const item of combo) {
        if (item.max < min) {
            min = item.max
            minColumn = item.i
        }
    }

    return [minColumn, min]
}

const ChartErrorBoundary = withUser(
    class extends React.Component {
        constructor(props) {
            super(props)
            this.state = { error: null }
        }

        static getDerivedStateFromError(error) {
            return { error }
        }

        render() {
            if (this.state.error) {
                // in production, log the error message
                if (process.env.NODE_ENV === 'production') {
                    const logMessage = `Could not display chart. Message: ${JSON.stringify(
                        this.state.error
                    )}`
                    Sentry.captureMessage(logMessage)
                }
                // keep this. Seems like some error are swallowed in chrome
                console.log({
                    ChartErrorBoundary: this.state.error,
                })

                return (
                    <Flex
                        column
                        style={{ justifyContent: 'center', height: '100%', position: 'relative' }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                            }}
                        >
                            <Icon icon="pie" style={{ fontSize: 100, color: colors.gray[200] }} />
                        </div>
                        <div
                            style={{
                                color: colors.gray[700],
                                position: 'relative',
                                textAlign: 'center',
                                fontWeight: 500,
                            }}
                        >
                            We can&apos;t display this chart
                        </div>
                    </Flex>
                )
            }

            return this.props.children
        }
    }
)

function ChartLoader() {
    return (
        <Flex
            column
            style={{
                justifyContent: 'center',
                height: '100%',
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute' }}>
                <Icon icon="pie" style={{ fontSize: 100, color: colors.gray[200] }} />
            </div>
            <div>
                <Spinner size="md" color="gray.500" />
            </div>
        </Flex>
    )
}

function ChartActions({ id }) {
    const { allowEdits, deleteChart, setEditingChartId } = useDashboardContext()

    if (!allowEdits) return null
    return (
        <div style={{ position: 'absolute', right: 3, top: 5, zIndex: 3 }}>
            <Button
                icon="edit"
                type="secondary"
                onClick={() => setEditingChartId(id)}
                style={{ marginRight: 3 }}
            />
            <Button icon="x" type="secondary" onClick={() => deleteChart(id)} />
        </div>
    )
}

// common styles for the container used by the view and the widget
export const renderDashboardContainerStyles = {
    marginTop: 25,

    // this offsets the horizontal margin added around the first
    // and last cards in a row by react-grid-layout - it seems
    // to set these 'margins' with js so this hack is the only way
    // to offset them
    marginLeft: -10,
    marginRight: -10,
}

/**
 *
 * @param {{viewContext: "dashboard" | "list" | "relatedList" | "unknown", [keyof: string]: any}} param0
 */
export default function RenderDashboard({
    allowEdits,
    config,
    save,
    mainObjectId,
    onChange,
    container: Container,
    viewId,
    viewContext,
    viewFilters,
}) {
    return (
        <DashboardContextProvider
            allowEdits={allowEdits}
            config={config}
            save={save}
            mainObjectId={mainObjectId}
            viewId={viewId}
            onChange={onChange}
            viewContext={viewContext}
            viewFilters={viewFilters}
        >
            <CanRenderDashboard>
                <Container>
                    <RenderDashboardFromView isDashboardPage={viewContext === 'dashboard'} />
                </Container>
            </CanRenderDashboard>
        </DashboardContextProvider>
    )
}

function CanRenderDashboard({ children }) {
    const { breakpoint, allowEdits } = useDashboardContext()

    if (breakpoint !== 'md' && allowEdits) {
        return (
            <div style={{ textAlign: 'center', padding: 10 }}>
                You need a wider screen to edit charts
            </div>
        )
    }

    return children
}
