/* Code Quality: OK */

import React from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import queryString from 'qs'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { withPages } from 'data/wrappers/WithPages'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import ThemeWrapper from 'features/pages/blocks/styles/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import { Section } from 'legacy/v1/ui'
import Input from 'legacy/v1/ui/components/Input'

import { Button, Heading, Icon, Text } from 'v2/ui'

import { CenterPanel, CenterPanelWrapper } from '../studio/ui/Layout'

import { PasswordApi } from './utils/index'

const STATUS_NEW = 'STATUS_NEW'
const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS'
const STATUS_SUBMITTED = 'STATUS_SUBMITTED'
const STATUS_REJECTED = 'STATUS_REJECTED'
const INVALID_EMAIL = 'INVALID_EMAIL'
const INVALID_CODE = 'INVALID_CODE'
const TOO_MANY_TRIES = 'TOO_MANY_TRIES'

class LoginPasswordlessPage extends React.Component {
    state = { title: 'Stacker', logo: '', icon: '', email: '', sentEmail: false }

    constructor(props) {
        super(props)
        this.state.status = STATUS_NEW

        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        this.state.email = searchString.email
    }

    sendLoginCodeEmail = (e) => {
        e.preventDefault()
        const { email } = this.state

        if (!email) this.setState({ status: INVALID_EMAIL })
        else {
            this.setState({ status: STATUS_IN_PROGRESS }, () => {
                PasswordApi.requestPasswordlessEmail(email)
                    .then((response) => {
                        if (response.status >= 400) this.setState({ status: STATUS_REJECTED })
                        else this.setState({ sentEmail: true, status: STATUS_SUBMITTED })
                    })
                    .catch(() => {
                        this.setState({ status: STATUS_REJECTED })
                    })
            })
        }
        return null
    }

    tryToAuthEndUser = async (apiToken) => {
        if (apiToken) {
            const token = apiToken
            const user = await this.props.userActions.logInEndUserByApiToken(token)
            const isAuthenticated = !!user.api_token

            if (isAuthenticated) window.location.assign(getUrl(Urls.Home))
            else {
                this.setState({ status: INVALID_CODE })
            }
        }
    }

    authenticate = (e) => {
        e.preventDefault()
        const { email, code } = this.state
        if (!code) this.setState({ status: INVALID_CODE })
        else {
            this.setState({ status: STATUS_IN_PROGRESS }, () => {
                PasswordApi.requestPasswordlessLogin(email, code)
                    .then((response) => {
                        response.json().then((json) => {
                            const apiToken = json.api_token
                            if (!apiToken) {
                                if (json.detail === 'Too many tries')
                                    this.setState({ status: TOO_MANY_TRIES })
                                else this.setState({ status: INVALID_CODE })
                            } else this.tryToAuthEndUser(apiToken)
                        })
                    })
                    .catch(() => {
                        this.setState({ status: STATUS_REJECTED })
                    })
            })
        }
        return null
    }

    render() {
        if (!this.props.stack._sid) return null

        const launched = this.props.isLaunched

        const logo = getLogoUrl(this.props.stack, true)

        const adminLoginLink = `${settings.STUDIO_URL}/login`

        return (
            <>
                <DefaultHeaderMeta title="Login" />
                <ThemeWrapper loadFromStack>
                    {() => (
                        <CenterPanelWrapper>
                            <CenterPanel>
                                <Section margin="large" padding="none" centered>
                                    <Logo src={logo} />
                                </Section>

                                {launched && !this.state.sentEmail && (
                                    <form onSubmit={this.sendLoginCodeEmail}>
                                        <Section centered>
                                            <Heading value="Login" />
                                        </Section>
                                        <Section margin="medium" padding="none">
                                            <Input
                                                onChange={(e) =>
                                                    this.setState({ email: e.target.value })
                                                }
                                                value={this.state.email}
                                                padding="medium"
                                                placeholder="Your email address"
                                            />
                                        </Section>
                                        <Button
                                            variant="primary"
                                            buttonSize="md"
                                            type="submit"
                                            width="100%"
                                            isDisabled={this.state.status === STATUS_IN_PROGRESS}
                                        >
                                            {this.state.status === STATUS_IN_PROGRESS
                                                ? 'Sending login code'
                                                : 'Send login code'}
                                        </Button>
                                        {this.state.status === INVALID_EMAIL && (
                                            <Text size="medium">
                                                This is not a valid email, please insert a valid
                                                email and try again
                                            </Text>
                                        )}
                                        {this.state.status === STATUS_REJECTED && (
                                            <Text size="medium">
                                                There is no account associated with that email
                                                address.
                                            </Text>
                                        )}
                                    </form>
                                )}
                                {this.state.sentEmail && (
                                    <form onSubmit={this.authenticate}>
                                        <Section centered>
                                            <Heading value="Login" />
                                        </Section>
                                        <Section margin="medium" padding="none" centered>
                                            <Icon icon="email" fontSize="32" />
                                        </Section>
                                        <Section margin="medium" padding="none">
                                            <Input
                                                onChange={(e) =>
                                                    this.setState({ code: e.target.value })
                                                }
                                                value={this.state.code}
                                                padding="medium"
                                                placeholder="Your code"
                                            />
                                        </Section>
                                        <Button
                                            variant="primary"
                                            buttonSize="md"
                                            type="submit"
                                            width="100%"
                                            isDisabled={this.state.status === STATUS_IN_PROGRESS}
                                        >
                                            {this.state.status === STATUS_IN_PROGRESS
                                                ? 'Logging in'
                                                : 'Log in'}
                                        </Button>
                                        <Section margin="medium" padding="none" centered>
                                            <Text
                                                color="#0000ff"
                                                cursor="pointer"
                                                onClick={() => {
                                                    this.setState({ sentEmail: false })
                                                }}
                                            >
                                                Send another code
                                            </Text>
                                        </Section>
                                        {this.state.status === INVALID_CODE && (
                                            <Text size="medium">
                                                Please add a valid 6 digit code to log in
                                            </Text>
                                        )}
                                        {this.state.status === TOO_MANY_TRIES && (
                                            <Text size="medium">
                                                Too many tries, please ask your administrator to
                                                unlock this account.
                                            </Text>
                                        )}
                                        {this.state.status === STATUS_REJECTED && (
                                            <Text size="medium">
                                                We weren&apos;t able to logging you in.
                                            </Text>
                                        )}
                                    </form>
                                )}
                                {!launched && (
                                    <>
                                        <Section centered>
                                            <Heading
                                                value="This app is still being built and is not yet
                                                        launched"
                                            />
                                        </Section>
                                        <Section style={{ alignItems: 'center' }} margin="none">
                                            <Text margin="none" padding="none">
                                                Are you the admin?{' '}
                                                <a href={adminLoginLink}>Log in here</a>
                                            </Text>
                                        </Section>
                                    </>
                                )}
                            </CenterPanel>
                        </CenterPanelWrapper>
                    )}
                </ThemeWrapper>
            </>
        )
    }
}

LoginPasswordlessPage.propTypes = {
    location: PropTypes.object.isRequired, // from WithRouter
    stackOptions: PropTypes.object.isRequired, // from withStack
    stack: PropTypes.object.isRequired, // from withStack,
    userActions: PropTypes.object.isRequired, // from withUser,
    pageActions: PropTypes.object.isRequired, // From withPages,
}

const Logo = styled('img')`
    height: 60px;
    object-fit: contain;

    @media (max-width: 420px) {
        height: 35px;
    }
`

export default withPages(withUser(withStack(withRouter(LoginPasswordlessPage))))
