/* Code Quality: Not audited */

import React from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withTheme } from 'v2/ui/theme/components/withTheme'

const Icon8 = ({
    icon,
    iconStyle,
    large,
    size,
    displaySize,
    color,
    noMargin,
    fallbackIcon,
    ...otherProps
}) => {
    let sizeToDisplay = displaySize
    if (!displaySize) {
        sizeToDisplay = large ? '40' : '20'
    }
    const src = `https://img.icons8.com/${iconStyle}/${size || sizeToDisplay}/${color}/${icon}`
    const fallbackSrc = `https://img.icons8.com/${iconStyle}/${
        size || sizeToDisplay
    }/${color}/${fallbackIcon}`

    const Image = styled('img')`
        vertical-align: middle;
        margin-right: ${() => (noMargin ? '0px' : '8px')};
        height: ${() => `${sizeToDisplay}px`};
    `

    return (
        <Image
            {...otherProps}
            src={src}
            onError={(e) => {
                if (fallbackIcon) {
                    e.target.src = fallbackSrc
                }
            }}
        />
    )
}

Icon8.propTypes = {
    icon: PropTypes.string,
    iconStyle: PropTypes.string,
    large: PropTypes.bool,
    size: PropTypes.string,
    displaySize: PropTypes.string,
    color: PropTypes.string,
    noMargin: PropTypes.bool,
    fallbackIcon: PropTypes.string,
}

Icon8.defaultProps = {
    icon: 'icon',
    iconStyle: 'material',
    large: false,
    size: undefined,
    displaySize: undefined,
    color: '000000',
    noMargin: false,
    fallbackIcon: undefined,
}

export default withTheme(Icon8)
