import React from 'react'

import styled from '@emotion/styled'

import WithStacks from 'data/wrappers/WithStacks'

const PoweredLink = styled('a')`
    text-align: center;
    color: ${(props) => props.theme.fontColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 0.8em;
`

const PoweredWrapper = styled('div')`
    flex: 0;
    width: 100%;
    text-align: center;
    flex-basis: 100%;
    margin: 20px 0;
    align-self: flex-end;
`

const PoweredBy = (props) => {
    return (
        <WithStacks>
            {({ selectedStack: stack }) => {
                const combined_optional_features = stack.combined_optional_features

                //stops the powered by flashing up before we have the stack data
                if (!stack._sid) return null

                let whitelabel = false

                if (combined_optional_features) whitelabel = combined_optional_features.whitelabel

                if (whitelabel != true) {
                    const poweredByLink =
                        'https://www.stackerhq.com/?utm_source=stacker_footer&utm_medium=powered&utm_content=' +
                        window.location.hostname
                    return (
                        <PoweredWrapper style={props.wrapperStyle}>
                            <PoweredLink href={poweredByLink} target="_blank">
                                Powered By Stacker
                            </PoweredLink>
                        </PoweredWrapper>
                    )
                }

                return null
            }}
        </WithStacks>
    )
}

export default PoweredBy
