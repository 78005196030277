/* Code Quality: Not audited */

import React from 'react'
import RDatePicker from 'react-datepicker'

import styled from '@emotion/styled'
import { css } from 'emotion'
import moment from 'moment'
import PropTypes from 'prop-types'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import 'react-datepicker/dist/react-datepicker.css'

const FixedCss = styled('span')`
    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list {
        padding: 0;
    }

    .react-datepicker-popper {
        position: absolute;
        will-change: unset !important;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
    }
    ${(props) =>
        props.bugfixdatetime
            ? `
                  .react-datepicker {
                      min-width: 313px;

                      max-width: 313px;
                  }
              `
            : ``};

    .;
`

const DatePicker = (props) => {
    const styles = css`
        font-family: ${props.width || 'unset'};
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSize};
        background-color: ${props.theme.inputBackgroundColor};
        border-radius: ${props.theme.elementBorderRadius};
        padding: ${props.theme[props.padding || 'small']};
        border: ${props.theme.dividerThickness}px solid ${props.theme.outlineColor};

        &:focus {
            outline: none;
        }
    `

    let selected = props.selected
    if (selected && typeof selected === 'string') {
        selected = moment(selected).local()
    }

    if (selected && moment.isMoment(selected)) {
        selected = selected.local()
    }

    const treatAsLocal = (date) => {
        if (!date) return date
        const stringDate = moment(date).format('YYYY-MM-DDTHH:mm:ss') // Remove the timezone first
        const localDate = moment(stringDate).local()
        return localDate
    }

    const onChange = (value) => {
        let localValue = treatAsLocal(value)
        props.onChange && props.onChange(localValue)
        props.afterChange && props.afterChange(localValue)
    }

    return (
        <FixedCss bugfixdatetime={props.showTimeSelect}>
            <RDatePicker
                showYearDropdown
                {...props}
                onChange={onChange}
                selected={selected}
                className={styles}
                fixedHeight={true}
            />
        </FixedCss>
    )
}

DatePicker.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
}

export default withTheme(DatePicker)
