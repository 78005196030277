import React from 'react'

import { Box } from 'v2/ui'

type DataListBlockWrapperProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const DataListBlockWrapper: React.FC<DataListBlockWrapperProps> = (props) => {
    return (
        <Box
            backgroundColor="userInterface.neutral.0"
            rounded={6}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.15)"
            mb={4}
            {...props}
        />
    )
}
