import React, { FC, SVGProps } from 'react'

import { BoxProps } from '@chakra-ui/react'

import Flex from './Flex'

type CalloutIconProps = {
    color: string
    svg: FC<SVGProps<SVGSVGElement>>
} & BoxProps
const CalloutIcon = ({ color, svg, ...props }: CalloutIconProps) => {
    const SvgIcon = svg

    return (
        <Flex
            flexShrink={0}
            justifyContent="center"
            alignItems="center"
            rounded="full"
            w="1.8rem"
            h="1.8rem"
            bg={color}
            {...props}
        >
            <SvgIcon style={{ width: '0.9rem', height: '0.9rem' }} />
        </Flex>
    )
}
export default CalloutIcon
