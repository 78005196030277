import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
    TEMPLATES_FETCHED,
} from '../utils/constants'

// STATE
export const initialState = {
    templates: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function templateReducer(state = initialState, action) {
    let fetching
    switch (action.type) {
        case TEMPLATES_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                templates: unique([
                    ...state.templates,
                    ...action.payload.map((template) => template._sid),
                ]),
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
