/* eslint-disable prefer-destructuring */

import { getCurrentStackId } from 'app/AppContextStore'
import settings from 'app/settings'
import isAdmin from 'data/utils/isAdmin'
import { fetchWithAuth } from 'data/utils/utils'
import { getCustomBackendConfig } from 'utils/getCustomBackendConfig'
import { bootIntercom, shutdownIntercom } from 'utils/intercom'
import { makeToast } from 'utils/toaster'

import analytics from '../../utils/analytics'
import {
    CLEAR_STORE,
    DISCARD_EDITING_REQUEST,
    DISCARD_STOP_EDITING_REQUEST,
    EDITING_START_REQUESTED,
    EDITING_STARTED,
    EDITING_STOP_REQUESTED,
    EDITING_STOPPED,
    END_USER_LOGGED_IN,
    END_USER_LOGGED_OUT,
    IMPERSONATION_STARTED,
    IMPERSONATION_STOPPED,
    SET_REDIRECT,
    SET_STREAM_TOKEN,
    SET_USED_TEMP_AUTH_TOKEN,
    STUDIO_USER_LOGGED_IN,
    STUDIO_USER_LOGGED_OUT,
    USER_DATA_FETCHED,
    USER_LIST_FETCHED,
    USER_LIST_ITEM_ADDED,
    USER_UPDATED,
} from '../utils/constants'

// API
const apiRoot = settings.API_ROOT
const loginApiRoot = `${apiRoot}login/`
const getAuthTokenRoot = `${apiRoot}request-auth-token/`
const redeemAuthTokenRoot = `${apiRoot}redeem-auth-token/`
const studioUserInfoRoot = `${apiRoot}users/`
const endUserAuthApiRoot = `${apiRoot}authenticate/`
const getStreamTokenRoot = `getstream-token/`
const regenerateApiRoot = `regenerate-api-token/`
const regenerateIntegrationApiRoot = `change-integration-key/`

const endUserDataApiRoot = `enduser/`
// Unclear why we need this variable. Probably can be removed but no capacity to investigate.
// eslint-disable-next-line unused-imports/no-unused-vars
const usersDataApiRoot = `users/`
const impersonatePath = `impersonate/` // Doesn't need the root as it's authenticated

let exitEditModeCheckpoints = []
let enterEditModeCheckpoints = []
export class UserApi {
    static logInStudioUser(email, password, redirect) {
        const body = {
            email,
            password,
        }
        if (redirect) {
            body.redirect = redirect
        }
        return fetch(loginApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static requestStudioTemporaryAuthToken(apiToken, redirect) {
        const body = {
            api_token: apiToken,
        }
        if (redirect) {
            body.redirect = redirect
        }
        return fetch(getAuthTokenRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }
    // Sends an email with a magic link and login code to the specified email address.
    // (Only used for Stacker users, not old-style end users)
    static sendLoginCodeEmail(email, isSignUp) {
        const body = {
            email,
        }
        if (isSignUp) {
            body.is_sign_up = 1
        }
        return fetch(`${apiRoot}login/code/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((response) => {
            return response.json()
        })
    }

    static handleOpenSignUp(email, stackSid, inviteToken) {
        return fetch(`${apiRoot}open-signup/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                email,
                stack_sid: stackSid,
                invite_token: inviteToken,
            }),
        }).then((response) => {
            return response.json()
        })
    }

    // Logs the user in with an email address and login code sent to them via email.
    static authenticateWithLoginCode(email, code, isSignUp) {
        const body = {
            email,
            code,
            is_sign_up: isSignUp,
        }
        return fetch(`${apiRoot}login/code/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((response) => {
            return response.json()
        })
    }

    static logInStudioUserByTemporaryAuthToken(authToken) {
        return fetch(redeemAuthTokenRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                auth_token: authToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }
    static getStreamToken() {
        return fetchWithAuth(getStreamTokenRoot, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...getCustomBackendConfig(),
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => data.stream_user_token)
    }

    static logInStudioUserByApiToken(apiToken) {
        return fetch(studioUserInfoRoot, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': apiToken,
                ...getCustomBackendConfig(),
            },
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static logInEndUser(email, password) {
        return fetch(endUserAuthApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static logInEndUserByApiToken(apiToken) {
        return fetch(endUserAuthApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': apiToken,
                ...getCustomBackendConfig(),
            },
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static getUser() {
        return fetchWithAuth(endUserDataApiRoot, {
            method: 'GET',
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    // Regenerates new api token for a given user
    static regenerateApiTokenForAuth(recordId) {
        return fetchWithAuth(regenerateApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                record_id: recordId,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                makeToast(
                    'could_not_regenerate_api_token',
                    'An error occurred trying to regenerate api token. If this problem continues, please contact support.',
                    'error'
                )
                return Promise.reject(error)
            })
    }

    static regenerateIntegrationKey() {
        return fetchWithAuth(regenerateIntegrationApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                makeToast(
                    'could_not_regenerate_integration_key',
                    'An error occurred trying to regenerate your integration key. If this problem continues, please contact support.',
                    'error'
                )
                return Promise.reject(error)
            })
    }

    static impersonate(recordId, isEndUser = true) {
        return fetchWithAuth(
            impersonatePath,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    record_id: isEndUser ? recordId : null,
                    user_id: !isEndUser ? recordId : null,
                }),
            },
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            { bypassPreviewAndImpersonation: true }
        )
            .then((response) => {
                const { status } = response
                if (status === 404) {
                    return { status }
                }
                return response.json()
            })
            .catch((error) => {
                makeToast(
                    'impersonate_failed',
                    'An error occurred. If this problem continues, please contact support.',
                    'error'
                )
                return Promise.reject(error)
            })
    }
}

//
// //////////////// ACTIONS  ///////////////
//

export class UserActions {
    static logInEndUser = (email, password) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            return UserApi.logInEndUser(email, password).then((user) => {
                if (user.api_token) {
                    dispatch({ type: CLEAR_STORE })
                    dispatch({
                        type: END_USER_LOGGED_IN,
                        payload: user,
                    })
                }
                return Promise.resolve(user)
            })
        }
    }

    static fetchUserList = () => {
        return (dispatch) => {
            return fetchWithAuth('list-users/', {})
                .then((response) => {
                    return response.json()
                })
                .then((payload) => dispatch({ type: USER_LIST_FETCHED, payload }))
        }
    }

    static logInEndUserByApiToken = (token) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            return UserApi.logInEndUserByApiToken(token).then((user) => {
                dispatch({ type: CLEAR_STORE })
                dispatch({
                    type: END_USER_LOGGED_IN,
                    payload: user,
                })
                return Promise.resolve(user)
            })
        }
    }

    static endUserLoggedIn = (user) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            dispatch({ type: CLEAR_STORE })
            dispatch({
                type: END_USER_LOGGED_IN,
                payload: user,
            })
            return Promise.resolve(user)
        }
    }

    static logOutEndUser = () => {
        return (dispatch) => {
            dispatch({ type: CLEAR_STORE })
            dispatch({
                type: END_USER_LOGGED_OUT,
            })

            return Promise.resolve()
        }
    }

    static logOutAllUsers = () => {
        analytics.reset()
        return (dispatch) => {
            dispatch({ type: CLEAR_STORE })
            dispatch({
                type: END_USER_LOGGED_OUT,
            })
            dispatch({
                type: STUDIO_USER_LOGGED_OUT,
            })

            shutdownIntercom()
            return Promise.resolve()
        }
    }

    static getUser = (token) => {
        // gets all user information + extra such as role id
        return (dispatch) => {
            // do not need to do for an admin so skip the fetch
            if (isAdmin()) return Promise.resolve({})
            return UserApi.getUser(token).then((user) => {
                dispatch({
                    type: USER_UPDATED,
                    payload: user,
                })
                dispatch({
                    type: USER_DATA_FETCHED,
                })
                return Promise.resolve(user)
            })
        }
    }

    static getStreamToken = (user) => {
        // Gets the Stream user token and saves it to the user objectd
        return (dispatch) => {
            return UserApi.getStreamToken().then((token) => {
                dispatch({
                    type: SET_STREAM_TOKEN,
                    payload: { userId: user._sid, token },
                })
                return Promise.resolve(token)
            })
        }
    }

    static logInStudioUser = (email, password, redirect) => {
        // Log in an studio user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            return UserApi.logInStudioUser(email, password, redirect).then((response) => {
                // If we get a force_redirect from the server, that means that the server just
                // wants us to stop everything we're doing and redirect the user somewhere else.
                // In that case, let's just drop all of our Redux logic and just propagate the
                // response - the redirect will be done elsewhere.
                if (response.api_token && !response.force_redirect) {
                    dispatch({ type: CLEAR_STORE })
                    dispatch({
                        type: STUDIO_USER_LOGGED_IN,
                        payload: response,
                    })
                    UserActions._initializeStudioUser(response)
                }
                return Promise.resolve(response)
            })
        }
    }

    static logInStudioUserByApiToken = (token) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            return UserApi.logInStudioUserByApiToken(token).then((users) => {
                const user = users[0]
                dispatch({ type: CLEAR_STORE })
                dispatch({
                    type: STUDIO_USER_LOGGED_IN,
                    payload: user,
                })
                UserActions._initializeStudioUser(user)
                return Promise.resolve(user)
            })
        }
    }

    static logInStudioUserByTemporaryAuthToken = (token) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch, getState) => {
            // If we've already used this temp auth token, just return null.
            // This probably means we've got a superfluous rerender. At any rate
            // using the same auth token again will fail and overwrite our valid
            // user info with the failure message
            if (getState()['user'].usedTempAuthToken === token) return Promise.resolve(null)

            dispatch({ type: SET_USED_TEMP_AUTH_TOKEN, payload: token })

            return UserApi.logInStudioUserByTemporaryAuthToken(token).then((user) => {
                dispatch({ type: CLEAR_STORE })
                dispatch({
                    type: STUDIO_USER_LOGGED_IN,
                    payload: user,
                })
                UserActions._initializeStudioUser(user)
                return Promise.resolve(user)
            })
        }
    }

    static requestStudioTemporaryAuthToken(apiToken, redirect) {
        return () => {
            return UserApi.requestStudioTemporaryAuthToken(apiToken, redirect)
        }
    }

    static studioUserLoggedIn = (user) => {
        // Log in an end user and put their details in the store
        // Put the key in local storage
        return (dispatch) => {
            dispatch({ type: CLEAR_STORE })
            dispatch({
                type: STUDIO_USER_LOGGED_IN,
                payload: user,
            })
            UserActions._initializeStudioUser(user)
            return Promise.resolve(user)
        }
    }

    static _loadCanny = (user) => {
        if (window.Canny)
            window.Canny('identify', {
                appID: '5d7aa841ed39305a2e0fb477',
                user: {
                    email: user.email,
                    name: user.name,
                    id: user._sid,
                    companies: [
                        {
                            id: getCurrentStackId(),
                            name: window.location.host,
                        },
                    ],
                },
            })
    }

    static _initializeStudioUser = (user) => {
        const token = user && user.api_token
        if (!token) {
            return false
        }

        try {
            // Intercom is always loaded but will use a different token for non prod domains
            bootIntercom({ user })
            // Only keep track of canny on prod domains.
            if (settings.IS_PROD) {
                UserActions._loadCanny(user)
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error initializing third party scripts')
        }
        return Promise.resolve(user)
    }

    static impersonate(recordId, isEndUser) {
        return (dispatch) => {
            return UserApi.impersonate(recordId, isEndUser).then((user) => {
                dispatch({
                    type: IMPERSONATION_STARTED,
                    payload: user,
                })
                return Promise.resolve(user)
            })
        }
    }

    static stopImpersonating() {
        return (dispatch) => {
            dispatch({
                type: IMPERSONATION_STOPPED,
            })
            return Promise.resolve()
        }
    }

    static startEditing() {
        return async (dispatch) => {
            dispatch({
                type: EDITING_START_REQUESTED,
            })
            // If we have handlers registered for this edit mode transition, then
            // we need to call each one now and wait on the promise it returns
            for (const handler of enterEditModeCheckpoints) {
                // If any handler returns false, then we cancel the transition
                if (!(await handler())) {
                    dispatch({
                        type: DISCARD_EDITING_REQUEST,
                    })
                    return false
                }
            }
            // We can now make the transition
            dispatch({
                type: EDITING_STARTED,
            })
            return true
        }
    }

    static stopEditing(options = {}) {
        return async (dispatch) => {
            dispatch({
                type: EDITING_STOP_REQUESTED,
            })
            // If we have handlers registered for this edit mode transition, then
            // we need to call each one now and wait on the promise it returns
            for (const handler of exitEditModeCheckpoints) {
                // If any handler returns false, then we cancel the transition
                if (!(await handler())) {
                    dispatch({
                        type: DISCARD_STOP_EDITING_REQUEST,
                    })
                    return false
                }
            }
            // We can now make the transition
            dispatch({
                type: EDITING_STOPPED,
                payload: { leaveSlidingPaneOpen: options?.leaveSlidingPaneOpen },
            })
            return true
        }
    }

    static discardEditingRequest() {
        return (dispatch) => {
            dispatch({
                type: DISCARD_EDITING_REQUEST,
            })
            return Promise.resolve()
        }
    }

    static setRedirect(redirect) {
        return (dispatch) => {
            dispatch({
                type: SET_REDIRECT,
                value: redirect,
            })
            return Promise.resolve()
        }
    }
    static addUserListItem(user) {
        return (dispatch) => {
            dispatch({
                type: USER_LIST_ITEM_ADDED,
                payload: user,
            })
            return Promise.resolve()
        }
    }
}

export function registerExitEditModeCheckpoint(handler) {
    exitEditModeCheckpoints = [...exitEditModeCheckpoints, handler]
}
export function unregisterExitEditModeCheckpoint(handler) {
    exitEditModeCheckpoints = exitEditModeCheckpoints.filter((x) => x !== handler)
}

export function registerEnterEditModeCheckpoint(handler) {
    enterEditModeCheckpoints = [...enterEditModeCheckpoints, handler]
}
export function unregisterEnterEditModeCheckpoint(handler) {
    enterEditModeCheckpoints = enterEditModeCheckpoints.filter((x) => x !== handler)
}

export const userActions = {
    logInStudioUser: UserActions.logInStudioUser,
    logInEndUser: UserActions.logInEndUser,
    logInEndUserByApiToken: UserActions.logInEndUserByApiToken,
    endUserLoggedIn: UserActions.endUserLoggedIn,
    logOutEndUser: UserActions.logOutEndUser,
    logOutAllUsers: UserActions.logOutAllUsers,
    logInStudioUserByApiToken: UserActions.logInStudioUserByApiToken,
    logInStudioUserByTemporaryAuthToken: UserActions.logInStudioUserByTemporaryAuthToken,
    requestStudioTemporaryAuthToken: UserActions.requestStudioTemporaryAuthToken,
    studioUserLoggedIn: UserActions.studioUserLoggedIn,
    impersonate: UserActions.impersonate,
    stopImpersonating: UserActions.stopImpersonating,
    startEditing: UserActions.startEditing,
    stopEditing: UserActions.stopEditing,
    getUser: UserActions.getUser,
    getStreamToken: UserActions.getStreamToken,
    fetchUserList: UserActions.fetchUserList,
    setRedirect: UserActions.setRedirect,
    addUserListItem: UserActions.addUserListItem,
    discardEditingRequest: UserActions.discardEditingRequest,
}
