import styled from '@emotion/styled'

import Text from './Text'

const TopLabel = styled(Text)`
    color: #aab1bb;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7em;
    margin: 0px;
    padding: 3px 0;
    letter-spacing: 0.3px;
`

TopLabel.displayName = 'TopLabel'
export default TopLabel
