import React, { useMemo } from 'react'

import type { RadioGroupItemProps as RadixRadioButtonProps } from '@radix-ui/react-radio-group'
import shortid from 'shortid'

import { extractStandardProps, StandardComponentProps } from '../../helpers/styles'

import * as Parts from './Radio.parts'

type RadioButtonProps = Omit<RadixRadioButtonProps, 'asChild'> &
    StandardComponentProps & {
        id?: string
    }

export const RadioButton: React.FC<RadioButtonProps> = ({ id, className, ...props }) => {
    // this is so the id property can be optional, but still use the
    // label htmlFor property to link the label to the input.
    const effectiveId = useMemo(() => id || shortid.generate(), [id])
    const [standardProps, rootProps] = extractStandardProps(props)
    return (
        <Parts.Container className={className} {...standardProps}>
            <Parts.Button id={effectiveId} {...rootProps}>
                <Parts.Indicator />
            </Parts.Button>
            {props.children && (
                <Parts.Label htmlFor={effectiveId} disabled={rootProps.disabled}>
                    {props.children}
                </Parts.Label>
            )}
        </Parts.Container>
    )
}
