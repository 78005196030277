/* Code Quality: Not audited */

import React, { useEffect, useRef } from 'react'
import SketchPicker from 'react-color'

import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import styled from '@emotion/styled'

import Box from './Box'
import Flex from './Flex'
import Input from './Input'
const invalidRegEx = /[^0-9a-fA-F]*/g
export const ColorPicker = ({ color, onChange, inputVariant }) => {
    const inputRef = useRef()
    const lastValidColor = useRef()
    const setPosition = useRef()

    const handleInputChange = (e) => {
        let hexString = e.target.value
        // save the current selection start position because when we
        // modify the value at all, it will reset the cursor pos to the end
        setPosition.current = e.target.selectionStart

        // Strip all invalid characters
        hexString = hexString.replace(invalidRegEx, '')
        hexString = '#' + hexString

        // enforce max length
        hexString = hexString.substring(0, 7)

        onChange(hexString)
    }

    // When the input loses focus if the value isn't valid, reset it
    // to the last valid color
    const handleLostFocus = () => {
        if (color !== lastValidColor.current) {
            onChange(lastValidColor.current)
        }
    }

    // We may need to reset the cursor position to the last index
    // if we update the input valid with our validation logic. This
    // prevents the cursor from jumping to the end
    const handleSelectionChanged = () => {
        if (setPosition.current) {
            inputRef.current.setSelectionRange(setPosition.current, setPosition.current)
            setPosition.current = null
        }
    }

    const handleColorChange = (color) => {
        const newColor = color && color.rgb.a === 1 ? color.hex : colorToRgb(color)
        onChange(newColor)
    }

    useEffect(() => {
        // if the color value is changing, test it to see if it is a valid
        // hex color. If it is, then save it as our "lastValidColor"
        if (lastValidColor.current !== color) {
            try {
                lastValidColor.current = color
            } catch (ex) {}
        }
    }, [color])

    return (
        <Flex wrap="nowrap">
            <Popover>
                <PopoverTrigger>
                    <Swatch background={color} />
                </PopoverTrigger>
                <PopoverContent zIndex={99999} width={200}>
                    <SketchPicker
                        disableAlpha={true}
                        color={color || '#ffffff'}
                        //onChangeComplete={this.handleChangeComplete}
                        onChange={handleColorChange}
                    />
                </PopoverContent>
            </Popover>
            <Input
                ml={3}
                ref={inputRef}
                value={color}
                onBlur={handleLostFocus}
                onChange={handleInputChange}
                onSelect={handleSelectionChanged}
                variant={inputVariant}
            />
        </Flex>
    )
}
const colorToRgb = (color) => {
    return `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
}

const Swatch = styled(Box)`
    width: ${(props) => props.size || '32px'};
    height: ${(props) => props.size || '32px'};
    border-radius: 10%;
    flex-shrink: 0;
`
export default ColorPicker
