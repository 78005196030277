import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { Flex, Icon, Input, Text, Tooltip } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const EditText = styled(Text)`
    cursor: pointer;
    display: inline-block;

    margin: 0px 8px 0px 0px;
    padding: 0px;

    font-size: 0.9em;
    color: ${colors.userInterface.accent[1000]};
`

type Props = {
    item: any
    isHome: boolean
    changeLabel: (item: any, label: string) => void
}

const MenuLabelViewEdit: FC<Props> = ({ item, isHome, changeLabel }) => {
    const [label, setLabel] = useState('')
    const [editing, setEditing] = useState(false)

    const saveLabel = (newLabel: string | null): void => {
        if (!newLabel) {
            setEditing(false)
            return
        }

        changeLabel(item, newLabel)
        setLabel(newLabel)
        setEditing(false)
    }

    const onEdit = (): void => {
        setEditing(true)
    }

    const onReset = (): void => {
        const resetLabel = item.options?.view_name || item.label
        saveLabel(resetLabel)
    }

    const onSave = (): void => {
        saveLabel(label !== item.label ? label : null)
    }

    useEffect(() => {
        setLabel(item.label)
    }, [item])

    if (editing) {
        return (
            <Flex direction="row">
                <Input
                    style={{ marginLeft: '-8px', marginRight: '8px' }}
                    defaultValue={label}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
                    autoFocus
                />
                <EditText onClick={onReset}>Reset</EditText>
                <EditText onClick={onSave}>Save</EditText>
            </Flex>
        )
    }

    return (
        <>
            <Flex direction="row">
                <Text size="sm">{label}</Text>
                <Icon
                    icon="edit"
                    style={{
                        cursor: 'pointer',
                        marginLeft: 10,
                        color: colors.userInterface.neutral[600],
                    }}
                    onClick={onEdit}
                />
            </Flex>
            {isHome && (
                <Tooltip
                    label="The first enabled item in your navigation menu will be your homepage"
                    placement="right"
                    display="inline-block"
                >
                    <Icon
                        icon="home"
                        color={colors.userInterface.neutral[600]}
                        style={{ marginLeft: 10 }}
                    />
                </Tooltip>
            )}
        </>
    )
}

export default MenuLabelViewEdit
