import React, { useEffect, useMemo, useRef } from 'react'

import PropTypes from 'prop-types'
export const AnimateRelocateContext = React.createContext()

/**
 * This component provides functionality to animate elements that are relocating from
 * one place in the DOM tree to another. For instance, when an item is moving from one
 * list to another.
 *
 * Here's how it works:
 *
 * 1. When an item first renders, it calls our registerNode() function
 * with the a unique key and the DOM node currently hosting the item.
 *
 * 2. When an element is about to move, it calls our recordOrigin() function with the key
 * which uses getBoundingClientRect() to get the viewport location of the current node.
 * We record that origin position.
 *
 * 3. When the element mounts in the new location, it calls animate() with the unique key
 * and we are able to calculate the x/y delta between it's new mounted location and its origine.
 * We apply a translate3d and a transform transition to animate the item from the origin position
 * to the new position.
 *
 * NOTABLE LIMITATIONS:
 *
 * Currently the item being animated is still inside it's target container. So if that target container
 * hides overflow, then the animating item will be invisible until it gets inside the
 * scroll area. To support this scenario, we'd need to set the positioning of the item as fixed
 * during the animation, and also translate our getBoundingClientRect() values to proper fixed positions
 * taking into account window scrolling, etc.
 *
 */
export const AnimateRelocateContextProvider = ({
    calculateDuration,
    supplyTransition,
    children,
}) => {
    const origins = useRef({})
    const nodes = useRef({})
    const windowTimeout = useRef({})

    const clearCurrentTimeout = () => {
        if (windowTimeout.current) clearTimeout(windowTimeout.current)
        windowTimeout.current = null
    }

    useEffect(() => {
        return clearCurrentTimeout
    }, [])

    // by default we move 600px per second
    const defaultDurationCalc = (deltaX, deltaY) =>
        Math.max(Math.abs(deltaX), Math.abs(deltaY)) / 1000

    const defaultTransition = (deltaX, deltaY) => {
        const duration =
            (calculateDuration && calculateDuration(deltaX, deltaY)) ||
            defaultDurationCalc(deltaX, deltaY)
        return `transform ${duration}s cubic-bezier(0.550, 0.085, 0.680, 0.530)`
    }

    const recordOrigin = (key) => {
        if (nodes.current[key]) {
            origins.current[key] = nodes.current[key].getBoundingClientRect()
        }
    }
    const registerNode = (key, node) => {
        nodes.current[key] = node
    }
    const getDelta = (key, targetRect) => {
        const origin = origins.current[key]

        if (!origin) return [0, 0]
        origins.current[key] = null // clear out the saved origin

        return [origin.left - targetRect.left, origin.top - targetRect.top]
    }

    const animate = (key, node) => {
        const targetRect = node.getBoundingClientRect()
        const [left, top] = getDelta(key, targetRect)

        if (left === 0 && top === 0) return

        // This handler will reset the zIndex and the transform/transition
        // information after our transition has completed
        const onMoveTransitionEnd = (e) => {
            if (e.target === node && node) {
                node.style.zIndex = 0
                node.style.transform = 'unset'
                node.style.transition = `unset`
                node.removeEventListener('transitionend', onMoveTransitionEnd)
            }
        }
        // Set the initial transform using the delta values
        // so that our node is positioned at the origin
        node.style.transform = `translate3d(${left}px,${top}px,0)`
        node.style.zIndex = 9999
        node.addEventListener('transitionend', onMoveTransitionEnd)

        // use setTimeout to let the above take effectd before
        // setting the transition
        clearCurrentTimeout()
        windowTimeout.current = setTimeout(() => {
            if (!node) return

            // Now set the transform to 0 to move the item back to its new position
            node.style.transform = 'translate3d(0,0,0)'
            node.style.transition =
                (supplyTransition && supplyTransition(left, top)) || defaultTransition(left, top)
        }, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const context = useMemo(() => ({ registerNode, getDelta, recordOrigin, animate }), [])

    return (
        <AnimateRelocateContext.Provider value={context}>
            {children}
        </AnimateRelocateContext.Provider>
    )
}

AnimateRelocateContextProvider.propTypes = {
    /** called with the deltaX and deltaY values for the animation
     * about to take place. Expects a duration value in seconds to be returned.
     * This allows the caller to specify a duration proportional to the distance
     * being animated
     */
    calculateDuration: PropTypes.func,

    /** called with deltaX and deltaY values for the animate.
     * expects a css transition value in return. For instance:
     * transform .2s ease
     */
    supplyTransition: PropTypes.func,
}

export default AnimateRelocateContextProvider
