import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { Box, Flex, Icon, Text } from 'v2/ui'

type HiddenColumnProps = {
    statusKey: string
    statusLabel?: string
    lastDroppedOnColumn?: string | null
}

/** displays a placeholder for hidden columns so the user can drop cards into those columns */
export const HiddenColumn = ({
    statusKey,
    statusLabel,
    lastDroppedOnColumn,
}: HiddenColumnProps) => {
    const receivedDrop = lastDroppedOnColumn === statusKey
    return (
        <Droppable droppableId={statusKey}>
            {(provided, snapshot) => (
                <Box
                    width="150px"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    px={8}
                    py={4}
                    mb={4}
                    maxHeight="200px"
                    borderRadius="6px"
                    transition="background .2s, min-height .2s"
                    border="1px solid"
                    borderColor="grey.200"
                    bg={snapshot.isDraggingOver || receivedDrop ? 'grey.200' : ''}
                >
                    <Text variant="kanbanColumnHeader" align="center" verticalAlign="middle">
                        {statusLabel}
                    </Text>
                    <Flex column>
                        <Icon
                            icon="checkmark"
                            color="rgb(0,200,0)"
                            fontWeight="bold"
                            size="xl"
                            mt={4}
                            transition="opacity .2s"
                            opacity={receivedDrop ? 1 : 0}
                        />
                        {provided.placeholder}
                    </Flex>
                </Box>
            )}
        </Droppable>
    )
}
