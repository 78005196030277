import React from 'react'

export const LinkFormSectionList = ({ children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {React.Children.map(children, (child, index) => {
                return <div style={index > 0 ? { marginTop: 20 } : {}}>{child}</div>
            })}
        </div>
    )
}
