import React from 'react'

import {
    SCHEMA_ERROR_AT_SERVICE,
    SCHEMA_ERROR_FETCHING,
    SCHEMA_ERROR_PASSWORD,
    SCHEMA_ERROR_WRONG_FORMAT,
} from 'features/admin/data-connector/ImportErrors'

// @ts-ignore
import { Icon, Text } from 'v2/ui'

import { AirtableSchemaSyncServiceError } from '../AirtableSchemaSyncServiceError'

import { AirtableConnectionEditorSettings } from './AirtableConnectionEditorSettings'

export type AirtableConnectionEditorErrorProps = {
    onChangeHintImage: (url: string) => void
    updateDataConnection: (data?: any) => Promise<DataConnectionDto>
    error: any
    errorDetails: any
    connectionFields: any
    setConnectionFields: any
    dataBlock: boolean
    onSaveSuccess: (dc: DataConnectionDto, fields: any) => void
}

export const AirtableConnectionEditorError: React.FC<AirtableConnectionEditorErrorProps> = ({
    error,
    updateDataConnection,
    connectionFields,
    onChangeHintImage,
    dataBlock,
    onSaveSuccess,
}) => {
    let errorMessage: JSX.Element | null = null

    const editConnectionProps = { updateDataConnection, connectionFields }
    if (error === SCHEMA_ERROR_WRONG_FORMAT) {
        errorMessage = (
            <>
                <Text mb={3} variant="error">
                    <Icon icon="alert" display="inline" mr={2} />
                    There was an error connecting to your Airtable.{' '}
                </Text>
                <Text mb={3}>
                    It&apos;s possible you provided a share link for a single view, instead of for
                    your whole base. Please double-check your Shared Base Link and try again.
                </Text>
                <Text mb={3}>
                    Still having issues? Get in touch at{' '}
                    <a href="mailto:support@stackerhq.com">support@stackerhq.com</a> and we&apos;ll
                    help you get set up.
                </Text>

                <AirtableConnectionEditorSettings
                    onSaveSuccess={onSaveSuccess}
                    dataBlock={dataBlock}
                    onChangeHintImage={onChangeHintImage}
                    showApiKey={false}
                    showSharingLink={true}
                    saveFunction={updateDataConnection}
                    highlightedFields={['sharingLink']}
                    connectionFields={editConnectionProps.connectionFields}
                />
            </>
        )
    } else if (error === SCHEMA_ERROR_FETCHING) {
        errorMessage = (
            <>
                <Text mb={3}>
                    There was an error connecting to your Airtable. It looks like the URL you
                    provided for your base wasn&apos;t correct. Please double-check your Shared Base
                    Link and try again.
                </Text>
                <Text mb={3}>
                    Still having issues? Get in touch at{' '}
                    <a href="mailto:support@stackerhq.com">support@stackerhq.com</a> and we&apos;ll
                    help you get set up.
                </Text>
                <AirtableConnectionEditorSettings
                    onSaveSuccess={onSaveSuccess}
                    dataBlock={dataBlock}
                    onChangeHintImage={onChangeHintImage}
                    saveFunction={updateDataConnection}
                    showApiKey={false}
                    showSharingLink
                    highlightedFields={['sharingLink']}
                    connectionFields={editConnectionProps.connectionFields}
                />
            </>
        )
    } else if (error === SCHEMA_ERROR_PASSWORD) {
        errorMessage = (
            <>
                <Text mb={3}>
                    <Icon icon="alert" display="inline" mr={2} />
                    {connectionFields?.password
                        ? 'The sharing link password you provided is incorrect. Please enter the correct password below.'
                        : 'Your Airtable sharing link is password-protected. Please provide the password below.'}
                </Text>
                <AirtableConnectionEditorSettings
                    onSaveSuccess={onSaveSuccess}
                    dataBlock={dataBlock}
                    onChangeHintImage={onChangeHintImage}
                    saveFunction={updateDataConnection}
                    showApiKey={false}
                    highlightedFields={['password']}
                    forceShowPassword={true}
                    connectionFields={editConnectionProps.connectionFields}
                />
                <Text fontSize="sm" color="gray.400" mt={4}>
                    Note: email-restricted sharing links are not supported.
                </Text>
            </>
        )
    } else if (error === SCHEMA_ERROR_AT_SERVICE) {
        errorMessage = <AirtableSchemaSyncServiceError />
    }

    return errorMessage
}
