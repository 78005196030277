import { useMemo } from 'react'

import { useActions } from 'data/hooks/actions'
import { useObjects } from 'data/hooks/objects'

export function useActionsFromObjectId(objectId: string) {
    const { data: actions, status, ...other } = useActions()

    const data = useMemo(() => {
        if (status !== 'success') {
            return
        }

        return (actions as ActionDto[]).filter((action: ActionDto) => objectId === action.object_id)
    }, [actions, objectId, status])

    return { data, status, ...other }
}

export function useObjectFromId(objectId: string) {
    const { data: objects, status } = useObjects()

    return useMemo(() => {
        if (status !== 'success' || !objectId) {
            return { object: null, status }
        }

        const object = objects.find(({ _sid }) => objectId === _sid)
        return { object, status }
    }, [objectId, objects, status])
}

export function updateStep(action: ActionDto, stepId: string, patch: Partial<ActionStep>) {
    const newSteps = action.options.steps.map((step) => {
        if (step.id === stepId) {
            return { ...step, ...patch }
        }
        return step
    })

    return { options: { ...action.options, steps: newSteps } }
}

export function addStep(action: ActionDto, step: ActionStep): Pick<ActionDto, 'options'> {
    return { options: { ...action.options, steps: [...action.options.steps, step] } }
}

export function updateStepField(
    action: ActionDto,
    stepId: string,
    fieldId: string,
    patch: Partial<ActionStepField>
) {
    const steps = action.options.steps || []

    const stepToUpdate = steps.find(({ id }) => id === stepId)

    if (!stepToUpdate) {
        throw new Error(`Can't update step id ${stepId}`)
    }

    const fields = stepToUpdate.fields.map((field) => {
        if (field.fieldId === fieldId) {
            return { ...field, ...patch }
        }

        return field
    })

    return updateStep(action, stepId, { fields })
}
