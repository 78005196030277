import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { useObject } from 'data/hooks/objects'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { BlankPageHeader } from 'features/views/ViewHeader/BlankPageHeader'
import useTrack from 'utils/useTrack'

import FieldLayoutEditor from '../FieldLayoutEditor'
import { determineIsBlockDisabled } from '../utils/determineIsBlockDisabled'
import { findWidgetBlockById } from '../utils/findWidgetBlockById'

import BlankPageViewEditorDropZone from './BlankPageViewEditorDropZone'

type BlockEditorChange = {
    blockId: string
    action: string
}

type BlankPageViewEditorProps = {
    onChange: (patch: Partial<ViewDto>, onCommitChange?: () => void) => void
    view?: ViewDto
    isEditing?: boolean
}

const BlankPageViewEditor: React.FC<BlankPageViewEditorProps> = ({ view, onChange, isEditing }) => {
    const { object } = useObject(view?.object_id)
    const { user } = useAppUserContext()
    const { track } = useTrack()

    const { contextVersion } = useContext(LayoutEditorContext)

    const context = {
        record: {},
        object,
        user,
        view: {
            actions: {
                setValue: console.log,
                setValid: console.log,
            },
            valid: {},
            editing: false,
            creating: false,
            showErrors: false,
            isLoading: false,
        },
    }

    const recordPermissions = {
        read: true,
        update: true,
        delete: true,
    }

    // Due to how the Layout Editor works, we can't change update callbacks between re-rerenders.
    // This means that if we use the `view` variable inside of `handleBlockChange()`,
    // its value will always be the initial one, and will never be changed. This means that if we
    // update `view.options` in another place, the changes won't be applied here, and we would
    // overwrite them.
    // By using a reference to our `View` inside of `handleBlockChange()`, we keep the function
    // the same between re-renders, but we will dereference it to get the value every time
    // `handleBlockChange()` is called.
    const viewRef = useRef(view)
    useEffect(() => {
        viewRef.current = view
    }, [view])

    const handleBlockChange = useCallback(
        async (changes: BlockEditorChange[], blocks: BlankPageViewOptions['blocks']) => {
            const dereferencedView = viewRef.current
            if (!dereferencedView) return

            changes?.forEach((changedBlock) => {
                const block = findWidgetBlockById(blocks, changedBlock.blockId)
                if (changedBlock.action === 'create') {
                    track('Frontend Widget Created', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'page',
                    })
                } else if (changedBlock.action === 'updateConfig') {
                    track('Frontend Widget Modified', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'page',
                    })
                }
            })

            onChange(
                {
                    ...dereferencedView,
                    options: {
                        ...dereferencedView.options,
                        blocks,
                    },
                },
                () => {
                    track('Frontend - Blank page - Saved changes to page')
                }
            )
        },
        [onChange, track]
    )

    return (
        <>
            <BlankPageHeader headerOptions={view?.options?.pageHeader} />
            {/* @ts-expect-error */}
            <FieldLayoutEditor
                key={contextVersion}
                tree={view?.options.blocks}
                treeIndex={0}
                context={context}
                object={object}
                onChange={handleBlockChange}
                config={view?.options}
                showControls={isEditing}
                isFieldDisabled={false}
                recordPermissions={recordPermissions}
                hideFields={true}
                viewConfig={view?.options}
                viewType={view?.type}
                hideAddPaneTitle={true}
                determineIsBlockDisabled={determineIsBlockDisabled}
                renderBlockDropZone={BlankPageViewEditorDropZone}
                showBlockSelector={isEditing}
            />
        </>
    )
}

export default BlankPageViewEditor
