import React, { useState } from 'react'

import styled from '@emotion/styled'

import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection } from 'legacy/v1/ui'

import { Button, Input } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

type RenameAppSettingProps = {
    selectedStack: StackDto
    onSave: () => void
    onSaveError: () => void
}

export const RenameAppSetting = ({ selectedStack, onSave, onSaveError }: RenameAppSettingProps) => {
    const [appName, setAppName] = useState<string>(selectedStack.name)
    const { mutateAsync: updateStack } = useUpdateStack()
    return (
        //@ts-expect-error
        <ExpandSection
            heading="Rename your app"
            helpUrl=""
            helpText=""
            text={null}
            classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.RENAME_APP }}
            testId="general-settings.rename-app"
        >
            <FlexWrapper>
                <Input
                    style={{ width: '310px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAppName(e.target.value)
                    }
                    value={appName}
                    data-testid="general-settings.rename-app.input"
                />
                <StackColorButton
                    primary
                    color="stacker"
                    padding="small"
                    margin="none"
                    style={{ marginLeft: '10px' }}
                    isDisabled={!appName || appName === selectedStack.name}
                    onClick={() => {
                        updateStack({
                            id: selectedStack._sid,
                            patch: {
                                name: appName,
                            },
                        })
                            .then(() => {
                                onSave()
                            })
                            .catch(() => {
                                onSaveError()
                            })
                    }}
                    data-testid="general-settings.rename-app.button"
                >
                    Save
                </StackColorButton>
            </FlexWrapper>
        </ExpandSection>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const StackColorButton = (props: any) => (
    <Button
        variant="adminPrimary"
        buttonSize="sm"
        {...props}
        style={{ ...props.style, flexShrink: 0 }}
    />
)
