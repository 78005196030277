import React, { useContext, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import AppBreadcrumbs from 'v2/views/AppBreadcrumbs'

import useEditMode from 'features/admin/edit-mode/useEditMode'
import { HeaderContext } from 'features/utils/HeaderContext'
import { useLayoutContextPortal } from 'features/utils/useLayoutContextPortal'

import { Box } from 'v2/ui'

import { EDIT_MODE_GUTTER } from './frameConstants'

const HeroContainer = ({
    backdropWidth = null,
    idOverride,
    hide = false,
    showBreadcrumbs,
    breadCrumbsStyleOverride = {},
}) => {
    const context = useContext(HeaderContext)
    const setPortal = useLayoutContextPortal(idOverride || 'pageHeroHeader')
    useEffect(() => {
        context.setShowBreadcrumbs(showBreadcrumbs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBreadcrumbs])

    const { isOpen } = useEditMode()

    /**
     * We keep the initial size of Backdrop to use in case the backdropWith is empty
     * i..e when users switch between detail view and list view
     */
    const [initialWidth] = useState(backdropWidth)

    return (
        <Box
            position="relative"
            id={idOverride ? idOverride : 'pageHeroContainer'}
            display={hide ? 'none' : ''}
        >
            {showBreadcrumbs && (
                <AppBreadcrumbs
                    breadCrumbsStyleOverride={breadCrumbsStyleOverride}
                    position={context.headerImageVisible ? 'absolute' : 'relative'}
                />
            )}
            <AnimatedBox
                initialWidth={initialWidth}
                backdropWidth={backdropWidth}
                isEditOpen={isOpen}
                position="relative"
                maxWidth="100%"
                id="pageHeroHeader"
                ref={setPortal}
            />
        </Box>
    )
}

const AnimatedBox = styled(Box)`
    width: ${(p) =>
        p.isEditOpen
            ? `${p.backdropWidth + EDIT_MODE_GUTTER}px`
            : `${p.backdropWidth || p.initialWidth}px`};
`
export default HeroContainer
