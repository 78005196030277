import React from 'react'

export const LinkFormHeader = ({ style, source, target }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 16, ...style }}>
            <span style={{ marginRight: 10 }}>
                <b>Link</b>
            </span>
            <div style={{ marginRight: 10 }}>{source}</div>
            <span style={{ marginRight: 10 }}>
                <b>to</b>
            </span>
            <div style={{ marginRight: 10 }}>{target}</div>
        </div>
    )
}
