/* Code Quality: Not audited */

import React from 'react'

class EditableWrapper extends React.Component {
    state = {
        editing: false,
        hovering: false,
        ref: React.createRef(),
    }

    startEdit = () => {
        this.setState({ editing: true })
    }

    endEdit = (event) => {
        this.props.onChange && event && this.props.onChange(event.target.value)
        this.setState({ editing: false })
    }

    render() {
        if (this.state.editing) {
            return this.props.input({ end: this.endEdit, ref: this.state.ref })
        } else {
            return this.props.value({ start: this.startEdit })
        }
    }

    constructor(props) {
        super(props)
        if (props.initiallyEditing) {
            this.state.editing = true
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initiallyEditing) {
            this.setState({ editing: true })
        }
    }

    componentDidUpdate() {
        if (this.state.ref.current) {
            this.state.ref.current.focus()
        }
    }
}
export default EditableWrapper
