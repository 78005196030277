import React from 'react'

import { MenuButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Button, Icon } from 'v2/ui'

const StyledIcon = styled(Icon)<{ isActive?: boolean }>`
    transform: ${({ isActive }) => (isActive ? 'rotate(-45deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease-out;
`

const MenuButtonComponent: React.FC<
    React.ComponentPropsWithoutRef<typeof MenuButton> &
        React.ComponentPropsWithoutRef<typeof Button>
> = MenuButton

type FloatingAddNewButtonTriggerProps = React.ComponentPropsWithRef<typeof Button> & {
    isActive?: boolean
    isDark?: boolean
}

export const FloatingAddNewButtonTrigger = React.forwardRef<
    HTMLElement,
    FloatingAddNewButtonTriggerProps
>(({ isActive, isDark, ...props }, ref) => {
    return (
        <MenuButtonComponent
            as={Button}
            icon={
                <StyledIcon
                    icon="add"
                    width="1rem"
                    height="1rem"
                    marginRight="0.2rem"
                    isActive={isActive}
                />
            }
            size="md"
            variant={isDark ? 'floatingDark' : 'floatingLight'}
            {...props}
            ref={ref}
        >
            Add new
        </MenuButtonComponent>
    )
})
