/* Code Quality: Not audited */

import React from 'react'

import WithObject from 'data/wrappers/WithObject'
import { SortablePicker } from 'legacy/v1/ui'

class SortableFieldsPicker extends React.Component {
    render() {
        return (
            <WithObject loading="LOADING" objectId={this.props.objectId}>
                {({ object }) => {
                    return (
                        <SortablePicker
                            onChange={this.props.onChange}
                            fields={object.fields}
                            values={this.props.value || []}
                        />
                    )
                }}
            </WithObject>
        )
    }
}

SortableFieldsPicker.propTypes = {}

export default SortableFieldsPicker
