import React from 'react'

import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import BlockPicker from 'features/studio/blocks/BlockPicker'
import FieldPicker from 'features/studio/ui/FieldPicker'
import KeyValueList from 'features/studio/ui/KeyValueList'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import RelatedListAddButtonConfigurator from 'features/studio/ui/RelatedListAddButtonConfigurator'
import SortableFieldsPicker from 'features/studio/ui/SortableFieldsPicker'
import ViewPicker from 'features/studio/views/ViewPicker'
import { getAreRolesEnabled } from 'features/utils/getAreRolesEnabled'
import MenuToggle from 'features/views/List/MenuToggle'

import { Button, Dropdown, Modal } from 'v2/ui'
import LongTextAttribute from 'v2/ui/components/Attribute/LongTextAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import Attribute from '../RenderRowAttribute'

import CheckboxAttribute from './form/Checkbox'
import DropdownAttribute from './form/Dropdown'
import FormulasAttribute from './form/Formulas'
import ObjectFiltersAttribute from './form/objectFilters'
import ObjectOrderAttribute from './form/objectOrder'
import TextAttribute from './form/Text'

export const text = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => <TextAttribute label="Sort By" {...props} {...renderedElementProps} />}
        {...attributeProps}
    />
)

export const longText = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => (
            <LongTextAttribute
                showRichTextEditor
                enableMarkdown
                mode={modes.editing}
                label="Sort By"
                {...props}
                convertToMarkdown={false}
                height="200px"
                {...renderedElementProps}
            >
                {props.value}
            </LongTextAttribute>
        )}
        {...attributeProps}
    />
)

export const keyvaluelist = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        render={(props) => (
            <KeyValueList
                keyName={attributeProps.key}
                valueName={attributeProps.value}
                {...props}
                {...renderedElementProps}
            />
        )}
        {...attributeProps}
    />
)

export const image = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => <TextAttribute label="Sort By" {...props} {...renderedElementProps} />}
        {...attributeProps}
    />
)

export const multiline = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => (
            <TextAttribute multiline label="Sort By" {...props} {...renderedElementProps} />
        )}
        {...attributeProps}
    />
)

export const boolean = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <CheckboxAttribute {...props} />} />
)

export const options = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <DropdownAttribute {...props} />} />
)
export const formulas = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <FormulasAttribute {...props} />} />
)
export const objectFilters = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={(props) => (
            <ModalAttribute label={props.label}>
                <ObjectFiltersAttribute {...props} />
            </ModalAttribute>
        )}
    />
)
export const objectOrder = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <ObjectOrderAttribute {...props} />} />
)
export const blocks = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <BlockPicker {...props} />} />
)

export const viewPicker = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <ViewPicker {...props} />} />
)

export const objectPicker = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <ObjectPicker {...props} />} />
)

export const fieldPicker = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={({ filter, ...props }) => {
            const objectId =
                props.params.objectId ||
                props.objectId ||
                (props.context.object && props.context.object._sid)
            if (!objectId) {
                return ''
            }
            const combinedFilter = (field) => {
                return !get(field, 'connection_options.is_disabled') && (!filter || filter(field))
            }
            return <FieldPicker objectId={objectId} filter={combinedFilter} {...props} />
        }}
    />
)

export const metadataPicker = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={(props) => (
            <Dropdown
                onChange={props.onChange}
                placeholder="Select data source"
                clearable={false}
                options={[
                    { label: 'Page', value: 'page' },
                    { label: 'Block', value: 'block' },
                    { label: 'Field', value: 'field' },
                    { label: 'Data Table', value: 'object' },
                ]}
                value={props.value}
            />
        )}
    />
)

export const sortableFieldPicker = (attributeProps) => {
    return (
        <Attribute
            {...attributeProps}
            render={(props) => {
                const { objectId } = props.params
                if (!objectId) {
                    return ''
                }
                return <SortableFieldsPicker objectId={objectId} {...props} />
            }}
        />
    )
}

// This is mainly a checkbox, but under certain circumstancews we replace it with some explanatory text.
export const relatedListAddButtonConfigurator = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={(props) => {
            const { listType } = props.params
            let { objectId, fieldId } = props.params
            if (listType !== 'all') {
                if (attributeProps.isExternalField && props.params.field) {
                    ;({ fieldId, objectId } = props.params.field)
                }
                if (!objectId || !fieldId) {
                    return ''
                }
            }
            return (
                <RelatedListAddButtonConfigurator
                    objectId={objectId}
                    fieldId={fieldId}
                    listType={listType}
                    isExternalField={attributeProps.isExternalField}
                    {...props}
                />
            )
        }}
    />
)

// This is a generic configurator wrapper than can be used
// to wrap whatever component is supplied by the Element prop
export const configuratorWrapper = ({ Element, requireObject = true, ...attributeProps }) => (
    <Attribute
        noLabel={!attributeProps.label}
        {...attributeProps}
        render={(props) => {
            const objectId = props.params.objectId
            if (requireObject && !objectId) {
                return ''
            }
            return <Element objectId={objectId} {...props} />
        }}
    />
)

// This is an attribute that will make some layouts invisible
// depending on the dynamic value of the record
export const conditionalVisibility = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={(props) => {
            const { selectedStack } = useAppContext()
            const areRolesEnabled = getAreRolesEnabled(selectedStack)

            const viewType = props.context.viewType

            const isBlankPage = viewType === 'blankpage'
            const shouldDisplayForBlankPages = isBlankPage && areRolesEnabled

            const object = isBlankPage ? null : props.context.object

            const hasFields = props.field && object
            if (!hasFields && !shouldDisplayForBlankPages) return null

            const hideTheRecordFilter = isBlankPage || !!props.context.view.useNewCreateForm

            let filters = props.params[props.field] || []

            if (!areRolesEnabled && filters.length > 0) {
                filters = filters.filter((filter) => filter?.field?.type !== 'user_role')
            }

            const label =
                !filters || filters.length === 0
                    ? 'Set conditional visibility'
                    : filters.length + ' visibility filter' + (filters.length > 1 ? 's' : '')
            const fields =
                object?.fields.filter((field) => !field.connection_options?.is_disabled) ?? []

            return (
                <div style={{ height: '27px' }}>
                    <MenuToggle
                        faicon="eye"
                        label={label}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            left: '0px',
                            height: '27px',
                            backgroundColor: '#F4F5F8',
                            textAlign: 'center',
                            color: '#555555',
                            zIndex: 101,
                        }}
                        textStyle={{
                            color: '#555555',
                            marginLeft: '8px',
                        }}
                        toggleMenuCursor="default"
                    >
                        <div style={{ minWidth: '400px', maxWidth: '400px' }}>
                            <Filters
                                object={object}
                                value={filters}
                                showRoleFilter
                                isCreate={props.context?.view?.creating}
                                fields={fields}
                                onChange={props.onChange}
                                withHeader={true}
                                place="bottom-end"
                                securityInfoMessage="Conditional visibility lets you show and hide an element, based on a filter."
                                hideTheRecordFilter={hideTheRecordFilter}
                            />
                        </div>
                    </MenuToggle>
                </div>
            )
        }}
    />
)

class ModalAttribute extends React.Component {
    state = {
        open: false,
    }

    open = () => this.setState({ open: true })

    close = () => this.setState({ open: false })

    render() {
        return (
            <>
                <Button onClick={this.open}>edit {this.props.label}</Button>
                <Modal showCloseButton isOpen={this.state.open} onClose={this.close}>
                    {this.props.children}
                </Modal>
            </>
        )
    }
}
