export const insertCustomCss = (css, attribute) => {
    const cssElement = document.createElement('style')
    cssElement.setAttribute(attribute, 'true')
    cssElement.innerHTML = css
    document.head.appendChild(cssElement)
}

export const removeCustomCss = (attribute) => {
    const customCss = document.head.querySelectorAll(`[${attribute}]`)
    Array.prototype.forEach.call(customCss, (node) => node.parentNode.removeChild(node))
}
