import React from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import CommandBarErrorBoundary from 'features/commandbar/CommandBarErrorBoundary'

import CommandBar from './CommandBar'

const CommandBarSwitcher = () => {
    const { flags } = useLDFlags()

    if (flags?.disableCommandBar) {
        return null
    }

    return (
        <CommandBarErrorBoundary>
            <CommandBar />
        </CommandBarErrorBoundary>
    )
}

export default CommandBarSwitcher
