import { useEffect, useRef, useState } from 'react'

export const useThirdPartyCookiesCheck = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isUnsupported, setIsUnsupported] = useState(false)
    const iframeRef = useRef()

    const handleMessage = (evt) => {
        if (evt.data === 'MM:3PCunsupported') {
            setIsLoading(false)
            setIsUnsupported(true)
        } else if (evt.data === 'MM:3PCsupported') {
            setIsLoading(false)
            setIsUnsupported(false)
        }
    }
    useEffect(() => {
        window.addEventListener('message', handleMessage, false)

        setTimeout(() => setIsLoading(false), 3000)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    useEffect(() => {
        if (isLoading) {
            const element = document.createElement('iframe')
            // hide this iframe so it doesn't show up at all
            element.style.position = 'absolute'
            element.style.left = '-100px'
            element.style.top = 0
            element.style.width = 0
            element.style.height = 0

            element.src =
                'https://mindmup.github.io/3rdpartycookiecheck/start.html?' + new Date().getTime()
            document.body.appendChild(element)
            iframeRef.current = element
        } else {
            try {
                document.body.removeChild(iframeRef.current)
            } catch (e) {}
            iframeRef.current = null
        }
        // remove element on unmount
        return () => {
            try {
                document.body.removeChild(iframeRef.current)
            } catch (e) {}
        }
    }, [isLoading])

    const refresh = () => {
        setIsLoading(true)
    }

    return { isLoading, isUnsupported, refresh }
}
