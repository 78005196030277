/* Code Quality: Not audited */

import React from 'react'

import { ThemeProvider } from 'emotion-theming'

import WithBlocks from 'data/wrappers/WithBlocks'
import WithCompounds from 'data/wrappers/WithCompounds'
import { withStack } from 'data/wrappers/WithStacks'

import smallDarkTheme from '../components/smallDarkTheme'

class BlocksList extends React.Component {
    render() {
        return (
            <WithCompounds>
                {() => {
                    return (
                        <WithBlocks>
                            {() => {
                                return (
                                    <ThemeProvider theme={smallDarkTheme}>
                                        <>{this.props.children({ blocks: [] })}</>
                                    </ThemeProvider>
                                )
                            }}
                        </WithBlocks>
                    )
                }}
            </WithCompounds>
        )
    }
}
export default withStack(BlocksList)
