import stackerTheme from 'v2/ui/theme/styles/default'
import type { Color } from 'v2/ui/theme/styles/types/colorBase'

const { colors } = stackerTheme()

export const FIELD_TYPES = [
    'checkbox',
    'currency',
    'currency_varying',
    'date',
    'datetime',
    'dropdown',
    'file',
    'formula',
    'image',
    'long_text',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'password',
    'percentage',
    'string',
    'url',
    'user_ref',
    'user_role',
] as const

export type FieldType = typeof FIELD_TYPES[number]

export const IMAGE_FIELD_TYPES: FieldType[] = ['image', 'multi_file']

export const LINK_TYPES: FieldType[] = ['lookup', 'multi_lookup']

export const DROPDOWN_TYPES: FieldType[] = ['dropdown', 'multi_select']

export const DROPDOWN_COLORS: { [keyof: string]: Color } = {
    lightGrey: colors.appColors.lightGrey[200],
    blue: colors.appColors.blue[200],
    teal: colors.appColors.teal[200],
    pine: colors.appColors.pine[200],
    green: colors.appColors.green[200],
    moss: colors.appColors.moss[200],
    gold: colors.appColors.gold[200],
    orange: colors.appColors.orange[200],
    poppy: colors.appColors.poppy[200],
    ruby: colors.appColors.ruby[200],
    mauve: colors.appColors.mauve[200],
    raspberry: colors.appColors.raspberry[200],
    mulberry: colors.appColors.mulberry[200],
    purple: colors.appColors.purple[200],
    indigo: colors.appColors.indigo[200],
    slateGrey: colors.appColors.slateGrey[200],
}

export const AUGMENTED_DATA_FIELD_TYPES: FieldType[] = [
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'long_text',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'percentage',
    'string',
    'url',
]
export const MAGIC_SYNTHETIC_FIELD_TYPES = [
    'created_at',
    'updated_at',
    'created_by',
    'updated_by',
    'record_id',
] as const

export const SYNTHETIC_FIELD_TYPES = [
    'relationship',
    'symmetric_relationship',
    'lookup',
    'rollup',
    'formula',
    ...MAGIC_SYNTHETIC_FIELD_TYPES,
] as const

export type MagicSyntheticFieldType = typeof MAGIC_SYNTHETIC_FIELD_TYPES[number]

export type SyntheticFieldType = typeof SYNTHETIC_FIELD_TYPES[number]
