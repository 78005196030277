import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidAddPlusProps = { size?: number; color?: string }
const SolidAppPlus: React.FC<SolidAddPlusProps> = ({
    size = 10,
    color = colors.userInterface.accent[1000],
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 11) / 10}
            viewBox="0 0 10 11"
            fill="inherit"
        >
            <path
                d="M9.089 4.559H5.941V1.41c0-1.213-1.883-1.213-1.883 0V4.56H.91c-1.213 0-1.213 1.882 0 1.882h3.148V9.59c0 1.213 1.883 1.213 1.883 0V6.44h3.148c1.214 0 1.214-1.882 0-1.882z"
                fill={color}
            />
        </svg>
    )
}

export default SolidAppPlus
