import React, { SVGProps } from 'react'

import { SyncArrowPath } from 'v2/ui/svgs/SyncArrowPath'

export type WrapIconWithSyncProps = {
    Icon: React.ComponentType<SVGProps<SVGSVGElement>>
    size?: string
    color?: string
}

export function WrapIconWithSync({ Icon, color = 'black', size = '1em' }: WrapIconWithSyncProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <mask id="sync-circle">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="75" cy="75" r="25" fill="black" />
                </mask>
            </defs>
            <g mask="url(#sync-circle)" width="100">
                <Icon width="90" height="90" />
            </g>
            <g transform="translate(50, 50)">
                <svg width="50" height="50" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <SyncArrowPath color={color} />
                </svg>
            </g>
        </svg>
    )
}
