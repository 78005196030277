import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { Dropdown } from 'v2/ui'

import { RenderInlineElement } from './ui'

/**
 *
 * @param {{name: string, label?: string, defaultValue?: any, styleContainer?: any, errorMessages?: {[keyof: string]: string}, row?: boolean ,options: {label: string, value: string}[], subtitle?: string} & Partial<import('react-hook-form').RegisterOptions> & any} param0
 */
export default function DropdownForm({
    name,
    label = name,
    subtitle = '',
    defaultValue = null,
    options = [],
    required = false,
    pattern = undefined,
    errorMessages = {},
    row = false,
    disabled = false,
    styleContainer = {},
    ...props
}) {
    const {
        formState: { errors },
        control,
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement
            label={label}
            error={error}
            errorMessages={errorMessages}
            subtitle={subtitle}
            row={row}
            style={styleContainer}
        >
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ pattern, required }}
                render={({ field }) => (
                    <>
                        <Dropdown
                            options={options}
                            {...field}
                            value={field.value || defaultValue}
                            disabled={disabled}
                            className={error ? 'invalid' : ''}
                            {...props}
                        />
                    </>
                )}
            />
        </RenderInlineElement>
    )
}
