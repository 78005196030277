import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import changeUrl from 'v2/views/utils/changeUrl'
import usePageRoleConfig from 'v2/views/utils/usePageRoleConfig'

import { getUrl } from 'app/UrlService'
import { useUpdateView } from 'data/hooks/views'
import useTrack from 'utils/useTrack'

import trackInlineFilterChanges from './trackInlineFilterChanges'

const useViewConfig = (view = {}, page = {}, onSave, object) => {
    const { mutateAsync: updateView } = useUpdateView()
    const {
        setPageRoles,
        savePageRoles,
        rolesDirty,
        pageRoles,
        revertChanges: revertRoleChanges,
    } = usePageRoleConfig(page)
    const { track } = useTrack()
    const history = useHistory()

    const defaultState = {
        loadedView: view,
        view: cloneDeep(view),
        isConfigDirty: false,
        shouldSave: false,
    }
    const [state, setState] = useState(defaultState)

    const revertChanges = () => {
        setState(defaultState)
        revertRoleChanges()
    }

    const setConfig = useCallback(
        // eslint-disable-next-line
        (partialConfig, shouldSave) => {
            // compare patch and current columns and set `defaultFieldsSelected` when different
            const columns = state.view?.options.columns?.map((c) => c.fieldId)
            const patchColumns = partialConfig.columns?.map((c) => c.fieldId)
            const fieldsUpdated = !isEmpty(patchColumns) && patchColumns !== columns
            const finalPatch = { ...partialConfig }

            // if the user is turning off showAllFields and they
            // still have the default fields selected, then update the
            // view to have all fields selected.
            // (note we don't do this if this patch is also providing
            // an updated list of fields)
            if (
                object &&
                state.view?.options?.defaultFieldsSelected &&
                state.view?.options?.showAllFields &&
                partialConfig.showAllFields === false &&
                !patchColumns
            ) {
                finalPatch.columns = object.fields.map((f) => ({
                    fieldApiName: f.api_name,
                    fieldId: f._sid,
                    selected: true,
                    ...state.view?.options.columns?.find((f) => f.fieldId === f._sid),
                }))
            }

            setState((state) => {
                const update = {
                    ...state,
                    view: {
                        ...state.view,
                        options: {
                            ...get(state, 'view.options', {}),
                            ...finalPatch,
                        },
                    },
                    isConfigDirty: true,
                    shouldSave: shouldSave === true,
                }
                // We set defaultFieldsSelected to false  the first time
                // the user has made changes to the selected fields
                if (fieldsUpdated) {
                    update.view.options.defaultFieldsSelected = false
                }
                return update
            })
        },
        [object, state.view?.options]
    )

    const setViewData = (partialConfig) => {
        setState((state) => ({
            ...state,
            view: { ...state.view, ...partialConfig },
            isConfigDirty: true,
        }))
    }

    const saveConfig = useCallback(async () => {
        // If the url has changed then we need to push them to the new url (the old one is now invalid)
        // we can't do this in componentdidupdate as it'll already have updated the state by then
        const oldUrl = get(view, 'url')

        if (onSave) await onSave()

        if (state.isConfigDirty) {
            // need to put this in a try catch, otherwise it fails editing views through the settings menu
            try {
                if (view.type === 'list') {
                    // If the display type has been changed to single-record, we want an extra log.
                    if (
                        get(state.view, 'options.display') === 'single_record' &&
                        get(view, 'options.display') !== 'single_record'
                    ) {
                        track('layout updated', {
                            view: 'list view',
                            type: 'single record view',
                        })
                    }
                    // track if changing the list display type
                    // Single record view is already dealt with above so put this in an
                    // else if clause so we don't repeat that
                    else if (state?.view?.options?.display !== view?.options?.display) {
                        track('layout updated', {
                            view: 'list view',
                            type: state?.view?.options?.display,
                        })
                    }

                    trackInlineFilterChanges({
                        object,
                        track,
                        oldOptions: view?.options,
                        newOptions: state.view?.options,
                    })
                } else {
                    // Create and detail views are handled on different code paths. This is a catchall which I don't expect to hit.
                    track('layout updated', {
                        view: 'unknown',
                        view_unknown_type: view.type,
                    })
                }

                return updateView({ id: view._sid, patch: { ...state.view } })
                    .then((updated) => {
                        const newUrl = get(updated, 'url')
                        setState({ ...state, isConfigDirty: false })
                        changeUrl(getUrl(oldUrl), getUrl(newUrl), true, history)
                    })
                    .catch((e) => {
                        Sentry.captureMessage(`Error updating view config ${get(e, 'message')}`)
                    })
            } catch (e) {
                Sentry.captureMessage(`Error updating view config ${get(e, 'message')}`)
            }
        }

        if (rolesDirty) {
            savePageRoles()
        }
    }, [view, onSave, state, rolesDirty, updateView, object, track, history, savePageRoles])

    useEffect(() => {
        if (!isEqual(view, state.loadedView)) {
            const clonedView = cloneDeep(view)

            setState((prevState) => {
                let updatedView = view

                // Completely keep any local changes that have been made if it's the same view
                if (view?._sid === state.loadedView?._sid && prevState.isConfigDirty) {
                    updatedView = { ...clonedView, ...prevState.view }
                }

                return {
                    ...prevState,
                    loadedView: view,
                    view: updatedView,
                }
            })
        }
    }, [state.loadedView, view])

    useEffect(() => {
        if (!state.shouldSave || !state.isConfigDirty) {
            return
        }

        saveConfig()
        setState({ shouldSave: false })
    }, [state.shouldSave, state.isConfigDirty, saveConfig])

    return {
        setConfig,
        setViewData,
        saveConfig,
        isConfigDirty: state.isConfigDirty,
        viewState: state,
        rolesDirty,
        setPageRoles,
        pageRoles,
        revertChanges,
    }
}

export default useViewConfig
