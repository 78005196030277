import { acceptHeaders, fetchAndReturn, StackerAPI } from '../utils/utils'

class FieldApi extends StackerAPI {
    path = 'fields/'

    bulkPatch(data) {
        return fetchAndReturn(this.path, {
            method: 'PATCH',
            headers: acceptHeaders,
            body: JSON.stringify(data),
        })
    }

    getObjectFields(objectId) {
        return fetchAndReturn(this.path, { query: { object_id: objectId } })
    }

    transformFromLocal = (data) => {
        const keys = Object.keys(data)
        const out = {}
        keys.forEach((key) => {
            // Transform connection_options.x: y to a dictionary of connection_options: {x: y}
            if (key.includes('connection_options.')) {
                const keyName = key.split('.')[1]
                let value = data[key]
                if (keyName === 'options' && typeof data[key] === 'string') {
                    // This is for options.options
                    value = []
                    data[key]
                        .split('\n')
                        .forEach((item) => value.push({ label: item, value: item }))
                }
                out.connection_options = { ...out.connection_options, [keyName]: value }
            }

            // Transform options.x: y to a dictionary of options: {x: y}
            else if (key.includes('options.')) {
                const keyName = key.split('.')[1]
                let value = data[key]
                if (keyName === 'options' && typeof data[key] === 'string') {
                    // This is for options.options
                    value = []
                    data[key]
                        .split('\n')
                        .forEach((item) => value.push({ label: item, value: item }))
                }
                out.options = { ...out.options, [keyName]: value }
            } else {
                out[key] = data[key]
            }
        })
        return out
    }

    // transformToLocal = data => {
    //     const keys = Object.keys(data)
    //     let out = {}
    //     keys.forEach(key => {
    //         if (key !== "options") {
    //             out[key] = data[key]
    //         }
    //     })
    //     Object.keys(data.options || {}).forEach(key => (out["options" + key] = data.options[key]))
    //     return out
    // }
}
export const fieldApi = new FieldApi()
