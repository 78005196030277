// @ts-strict-ignore
import React, { FC, useMemo } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { Rights, useAppUserContext } from 'app/AppUserContext'

import stackerTheme from 'v2/ui/theme/styles/default'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { AppSettingsButton } from './app-settings/AppSettingsButton'
import SlidingPane from './common/SlidingPane'
import { EditModeButton } from './edit-layout/EditModeButton'
import useSlidingPane from './hooks/useSlidingPane'
import { DataGridButton } from './manage-data/DataGridButton'
import { ManageUsersButton } from './manage-users/ManageUsersButton'
import { PreviewAsButton } from './preview-as/PreviewAsButton'

const { colors } = stackerTheme()

const Wrapper = styled.div<{ isOpen: boolean; borderRightColor?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;

    padding: 5px;
    padding-right: 9px;
    margin-right: -4px;
    border-right: 1px solid ${(p) => p.borderRightColor};
    background-color: white;
    box-shadow: ${(props) =>
        props.isOpen ? '-4px 0 4px 0 rgba(0, 0, 0, 0.2)' : '-2px 0 4px 0 rgba(0, 0, 0, 0.15)'};

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    z-index: 1000;
`

const AdminTray: FC = () => {
    const { isAdmin, hasRight } = useAppUserContext()
    const { selectedStack } = useAppContext()
    const isMobile = useIsMobile()
    const { state } = useSlidingPane()

    const showAdminTray = useMemo(
        () =>
            isAdmin &&
            hasRight(Rights.Admin.Any) &&
            !isMobile &&
            selectedStack &&
            selectedStack?.options?.workspace_app,
        [hasRight, isAdmin, isMobile, selectedStack]
    )
    if (!showAdminTray) {
        return null
    }
    return (
        <SlidingPane>
            <Wrapper
                isOpen={!!state.key}
                id="admin-side-tray"
                borderRightColor={colors.userInterface.neutral[100]}
            >
                <EditModeButton />
                <DataGridButton />
                <ManageUsersButton />
                <PreviewAsButton />
                <AppSettingsButton />
            </Wrapper>
        </SlidingPane>
    )
}

export default AdminTray
