// @ts-strict-ignore
import React from 'react'
import { render } from 'react-dom'

import './polyfills'
import './utils/dev-tools'

import Root from './app/Root'
import store from './app/store'
import { consumeLoadAction } from './features/loadActions'

import 'v2/ui/theme/styles.scss'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

function doRender(RootElem) {
    render(<RootElem store={store} />, document.getElementById('app'))
}
//You can use msw to mock backend calls for BE that doesn't yet exist. For that, add you handlers here.
//They will be applied only to dev build
//Please remove your handlers from this array when actual BE endpoint is implemented
//in example mswHandlers = [getIntegrationsHandler]
const mswHandlers = []
if (process.env.NODE_ENV === 'development' && mswHandlers.length > 0) {
    const { mswWorkerOrServerProxy } = require('./msw/mswWorkerOrServerProxy')
    mswWorkerOrServerProxy.use(...mswHandlers)
    mswWorkerOrServerProxy.start({
        //to debug unhandled request, use custom handler here https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest
        onUnhandledRequest: 'bypass',
    })
    //use worker.printHandlers() to get list of all registered handlers
}

consumeLoadAction()
doRender(Root)
