/* Code Quality: Not audited */

import React from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import { compoundActions, getCompoundsMemoized } from 'data/api/compoundApi'

class WithCompounds extends React.Component {
    render() {
        const compoundsData = this.props.compounds
        if (!compoundsData) return this.props.loading ? this.props.loading : ''
        const compounds = Object.values(compoundsData)
        return this.props.children({
            compounds,
        })
    }

    constructor(props) {
        super(props)
        props.compoundActions.fetch()
    }
}

WithCompounds.propTypes = {}

function mapStateToProps(state) {
    return {
        compounds: getCompoundsMemoized(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        compoundActions: bindActionCreators(compoundActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithCompounds)
