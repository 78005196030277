import React, { FC, useMemo } from 'react'

import { useMetrics } from 'data/hooks/metrics'

import { Flex, Icon, Text } from 'v2/ui'

import { TIME_PERIOD_FILTERS } from '../common/timePeriodFilters'
import { getPercentageChange } from '../common/timePeriodFilters/getPercentageChange'
import { useMetricConfiguration } from '../hooks/useMetricConfiguration'

import getPreviousPeriodDisplaySettings from './utils/getPreviousPeriodDisplaySettings'
import getPreviousPeriodIcon from './utils/getPreviousPeriodIcon'

type PreviousPeriodProps = {
    metrics: MetricsQueryResponse | undefined
    isCurrentResultFetched: boolean
    metric: MetricsBlockAttributes
    view: ViewDto
    record: {} | RecordDto
}

export const PreviousPeriod: FC<PreviousPeriodProps> = ({
    metrics,
    isCurrentResultFetched,
    metric,
    view,
    record,
}) => {
    const previousPeriodMetricConfiguration = useMetricConfiguration(metric, view, record, true)

    // @ts-expect-error
    const { data: previousPeriodMetrics, isFetched: isPreviousResultFetched } = useMetrics(
        previousPeriodMetricConfiguration
    )

    const value = useMemo(() => {
        const currentValue = metrics?.data[0]?.value ?? 0
        const previousValue = previousPeriodMetrics?.data[0]?.value ?? 0

        if (typeof currentValue !== 'number' || typeof previousValue !== 'number') {
            return null
        }

        return getPercentageChange(previousValue, currentValue)
    }, [metrics?.data, previousPeriodMetrics?.data])

    const periodLabel = useMemo(
        () =>
            TIME_PERIOD_FILTERS.find(
                (filter) => filter.value === metric.limitToTimePeriod
            )?.label?.toLowerCase(),
        [metric.limitToTimePeriod]
    )

    const { color, backgroundColor } = useMemo(
        () => getPreviousPeriodDisplaySettings(value, metric.switchPreviousPeriodColors),
        [metric.switchPreviousPeriodColors, value]
    )

    const icon = useMemo(() => getPreviousPeriodIcon(value), [value])

    if (
        !periodLabel ||
        !metric.limitToTimePeriod ||
        !isPreviousResultFetched ||
        !isCurrentResultFetched ||
        typeof value !== 'number'
    ) {
        return null
    }

    return (
        <Flex>
            <Flex
                backgroundColor={backgroundColor}
                display="inline-flex"
                mr={1}
                p="4px 8px 4px 8px"
                borderRadius="4px"
            >
                <Icon w="16px" h="16px" icon={icon} color={color} mr={1} />
                <Text color={color} lineHeight="16px" fontWeight="bold">
                    {Math.abs(value)}%
                </Text>
            </Flex>
            <Text color="userInterface.neutral.850">vs {periodLabel}</Text>
        </Flex>
    )
}
