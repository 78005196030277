// @ts-strict-ignore
import { useMemo } from 'react'
import { useQuery as _useQuery, UseQueryResult } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/AppContextStore'
import { fieldApi } from 'data/api/fieldApi'
import { byID } from 'data/utils/byID'
import { Field } from 'features/admin/data-connector/editors/native-tables/fieldTypes'

import { addFieldsToObjects } from './objects/objectOperations'
import { buildQueryKey, queryCache, queryClient } from './_helpers'

type UseFieldsOptions = {
    disabled?: boolean
    disabledValue?: any
    disabledFn?: () => boolean
}

type UseFieldsParams = {
    objectId?: string | undefined
    options?: UseFieldsOptions
}

const keyWithAuth = (stackId: string | undefined, objectId?: string) => {
    let queryKey = ['fields']
    if (objectId) queryKey.push(objectId)
    if (stackId) queryKey.push(stackId)
    return buildQueryKey(queryKey, {
        includeAuthKeys: true,
    })
}

const getQueryKey = (objectId?: string) => {
    const stackId = getCurrentStackId()
    return keyWithAuth(stackId, objectId)
}

const useQueryKey = (objectId?: string) => {
    const { selectedStack } = useAppContext()
    return keyWithAuth(selectedStack?._sid, objectId)
}

const fieldsQuery = (selectedStack: StackDto | null, objectId?: string) => {
    if (!selectedStack?._sid) return Promise.reject()
    return fieldApi.getObjectFields(objectId).then((fields: FieldDto[]) => {
        onSuccess(fields)
        return fields
    })
}

const onSuccess = (fields: FieldDto[]) => {
    // Add the fields to the object queries
    addFieldsToObjects(fields)
}

type UseFields = {
    data: FieldDto[] | undefined
    isLoading: boolean
    isFetched: boolean
}

// Get the fields for an object id or if left undefined it will fetch all fields
export const useFields = ({ objectId, options = {} }: UseFieldsParams = {}): UseFields => {
    const queryKey = useQueryKey(objectId)
    const { selectedStack } = useAppContext()

    const result: UseQueryResult<FieldDto[], any> = _useQuery(
        queryKey,
        () => fieldsQuery(selectedStack, objectId),
        {
            enabled: !options.disabled || (options.disabledFn && !options.disabledFn()),
        }
    )

    return useMemo(
        () => ({
            data: result.data,
            isLoading: result.isLoading,
            isFetched: result.isFetched,
        }),
        [result.data, result.isLoading, result.isFetched]
    )
}

export const useField = (fieldId: string | undefined) => {
    const { data: fields = [] } = useFields()

    return fieldId ? fields.find((field) => field._sid === fieldId) : undefined
}

export const useDefaultFields = () => {
    const defaultFields = [
        { label: 'Name', type: 'string', is_primary: true },
        {
            label: 'Description',
            type: 'string',
            is_primary: false,
            options: { render_variant: 'richText' },
        },
        {
            label: 'Status',
            type: 'dropdown',
            is_primary: false,
            options: {
                options: [
                    { label: 'To-do', value: 'To-do', color: 'lightGrey' },
                    { label: 'In Progress', value: 'In Progress', color: 'blue' },
                    { label: 'Done', value: 'Done', color: 'teal' },
                    { label: 'Cancelled', value: 'Cancelled', color: 'pine' },
                ],
            },
        },
    ]

    return { data: defaultFields } as { data: Array<Partial<FieldDto>> }
}

// These methods can be used outside of react components

/**
 * Updates single or ultiple fields in the query cache
 */
const updateFieldQuery = (field: FieldDto | FieldDto[]) => {
    if (!field) {
        return
    }

    const key = getQueryKey()
    const fieldQuery = queryCache.find(key)
    if (!fieldQuery) {
        return
    }

    const { queryKey } = fieldQuery
    const fields = Array.isArray(field) ? field : [field]
    const fieldIds = fields.map(({ _sid }) => _sid)
    const hasPrimaryFieldUpdate = fields.find(({ is_primary }) => is_primary)

    queryClient.setQueryData(queryKey, (cachedFields: FieldDto[]) => {
        const updatedIds: string[] = []

        // Update any existing object
        const newData = cachedFields.map((f) => {
            if (!fieldIds.includes(f._sid)) {
                // If the primary field has changed, the old one must be unset as being the primary
                if (hasPrimaryFieldUpdate && f.is_primary) {
                    return { ...f, is_primary: false }
                }

                return f
            }

            updatedIds.push(f._sid)
            return fields.find(({ _sid }) => _sid === f._sid) || f
        })

        // Check to see if there are any items that need adding to the cache
        const newFields = fields.filter(({ _sid }) => !updatedIds.includes(_sid))
        const updatedFields = [...newData, ...newFields]
        onSuccess(updatedFields)

        return updatedFields
    })
}

/**
 * Removes a field from the query cache
 */
const removeFieldQuery = (fieldId: string) => {
    if (!fieldId) return
    const fieldQueries = queryCache.findAll('fields')
    // We don't know what object the field belongs to, so we need to remove it from all objects
    fieldQueries.forEach((fieldQuery) => {
        const { queryKey } = fieldQuery
        queryClient.setQueryData(queryKey, (old: []) => {
            if (!old) return old

            const updatedFields = old.filter((f: FieldDto) => f._sid != fieldId)
            onSuccess(updatedFields)
            return updatedFields
        })
    })
}

/**
 * Upates a field and updates the query cache
 */
export const updateField = (fieldId: string, data: object, options = {}) => {
    return fieldApi.patch(fieldId, data, options).then((field: FieldDto) => {
        updateFieldQuery(field)
        return field
    })
}

/**
 * Upates multiple fields and updates the query cache
 */
export const bulkUpdateFields = (data: FieldDto[]) => {
    return fieldApi.bulkPatch(data).then((fields: FieldDto[]) => {
        updateFieldQuery(fields)
        return fields
    })
}

/**
 * Deletes a field and updates the query cache
 */
export const deleteField = (fieldId: string, queryDict: null | undefined = null, options = {}) => {
    return fieldApi.delete(fieldId, queryDict, options).then(() => {
        removeFieldQuery(fieldId)
        refetchFields()
    })
}

/**
 * Creates a field and updates the query cache
 */
export const createField = (data: Field, options = {}) => {
    return fieldApi.post(data, options).then((field: FieldDto) => {
        updateFieldQuery(field)
        return field
    })
}

/**
 * Invalidates the fields so that it will be refetched by any components using the query
 */
export const invalidateFields = () => {
    return queryClient.invalidateQueries(getQueryKey())
}

/**
 * Refetches fields and returns a promise
 */
export const refetchFields = async () => {
    await queryClient.refetchQueries(getQueryKey())
    return getCachedFields()
}

/**
 * Switch the sides of a link field by deleting and recreating it on
 * the other side of the relationship

 */
export const switchField = (id: string, data: FieldDto) => {
    return deleteField(id).then(() => createField(data))
}

/**
 * Gets the current fields from the query cache
 */
export const getCachedFields = (): FieldDto[] | undefined => {
    return queryClient.getQueryData(getQueryKey())
}

/**
 * Gets the current fields from the query cache
 */
export const getCachedFieldsById = (): object | undefined => {
    return byID(queryClient.getQueryData(getQueryKey()))
}

/**
 * Gets a field from the query cache
 */
export const getCachedField = (fieldId: string): FieldDto | undefined => {
    const cachedFields = getCachedFieldsById()
    return cachedFields ? cachedFields[fieldId] : undefined
}
