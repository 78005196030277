import * as React from 'react'

import styled from '@emotion/styled'

import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from '../../../../ui/deprecated/atoms/Flex'

const colors = stackerTheme().colors

const AggrTypeOptionContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    background-color: ${(p) => (p.selected ? colors.gray[200] : colors.gray[100])};
    transition: background-color 0.1s linear, border-color 0.1s linear;
    border-width: 2px;
    border-style: solid;
    border-color: ${(p) => (p.selected ? colors.gray[300] : colors.gray[100])};
    cursor: pointer;
    color: ${(p) => (p.selected ? colors.gray[800] : colors.gray[500])};
    border-radius: 500px;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 16px;
    margin-bottom: 8px;

    &:hover {
        background-color: ${colors.gray[200]};
        color: ${colors.gray[800]};
        border-color: ${(p) => (p.selected ? colors.gray[300] : colors.gray[200])};
    }
`

export function AggrTypeOption({ label, name, onChange, selected }) {
    return (
        <AggrTypeOptionContainer selected={selected} role="button" onClick={() => onChange(name)}>
            {label}
        </AggrTypeOptionContainer>
    )
}
