// @ts-strict-ignore
import get from 'lodash/get'

import { getUrl, Urls } from '../../app/UrlService'
import { getConnectedObjectsFromIDs } from '../../data/hooks/objects'
import { buildUrl, fetchAndReturn } from '../../data/utils/utils'

import formatters from './commandbar-formatters'

/**
 * Context helpers
 */

export const getStacksWithIcons = async (
    currentStack: StackDto,
    workspaceAccount: Account | null,
    allStacks?: StackDto[]
) => {
    if (allStacks) {
        const stacks = await Promise.all(
            allStacks
                .filter(shouldDisplayStack(currentStack, workspaceAccount))
                .map((stack) => formatters.app(stack))
        )

        return stacks.length > 1 ? stacks : null
    }

    return null
}

const currentPath = (history: History) => get(history, 'location.pathname')

export const redirectTo = (history: History) =>
    // TODO: is this really needed now?
    currentPath(history).startsWith(getUrl(Urls.Admin)) ? getUrl(Urls.Home) : currentPath(history)

export const getListsForSettings = (
    objects: ObjectDto[],
    features: FeatureDto[],
    dataConnections: DataConnectionDto[]
) =>
    dataConnections
        .flatMap(({ connected_objects }) => {
            const _objects = getConnectedObjectsFromIDs(connected_objects, objects, features)
            return _objects.length > 0
                ? _objects.map((object) => ({ object, id: object._sid, label: object.name }))
                : null
        })
        .filter(Boolean)

export const getWorkspaces = (accounts: Account[], currentUser: Record<string, unknown>) =>
    accounts.length > 1
        ? accounts?.map((account) => formatters.workspace(currentUser, account))
        : null

const shouldDisplayStack =
    (selectedStack: StackDto | null, workspaceAccount: Account | null) => (stack: StackDto) =>
        stack.account_id === (selectedStack?.account_id || workspaceAccount?._sid) &&
        !!stack.options?.workspace_app

/**
 * Callback helpers
 */

export const fetchRecords = (objectId: string) =>
    fetchAndReturn(
        buildUrl(
            ['app', 'page', 'object', 'field', 'data_connection', 'feature'].indexOf(objectId) !==
                -1
                ? `${objectId.replace('_', '-')}s/`
                : `objects/${objectId}/records/`
        ),
        {},
        null,
        null
    )

type OpenDataGrid = (
    objects: ObjectDto[],
    showManageData: (objectId?: string) => void
) => (args: { list: { label: string }; section: { id: string } }) => void

export const openDataGrid: OpenDataGrid =
    (objects, showManageData) =>
    ({ list }) => {
        const object = objects.find(({ name }) => name === list.label)
        showManageData(object?._sid)
    }
