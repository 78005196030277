import React, { FC, useMemo } from 'react'

import { Flex } from 'v2/ui'

type Props = {
    payload: {
        value: string
        payload: {
            fill: string
            showInLegend: boolean
            payload: {
                formatted: boolean
            }
        }
    }[]
}

export const Legend: FC<Props> = ({ payload }) => {
    const sortedData = useMemo(() => {
        return payload.filter((item) => item.payload.showInLegend)
    }, [payload])

    return (
        <>
            {sortedData.map((item) => (
                <Flex key={item.value} style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                    <div
                        style={{
                            width: 14,
                            height: 10,
                            marginRight: 5,
                            background: item.payload.fill,
                            display: 'inline-block',
                        }}
                    />
                    {item.value}
                    <div
                        style={{
                            marginLeft: 3,
                            fontSize: '0.8rem',
                            opacity: 0.7,
                            maxWidth: 70,
                            whiteSpace: 'nowrap',
                            display: 'none',
                        }}
                        className="ellipsis"
                    >
                        ( {item.payload.payload.formatted} )
                    </div>
                </Flex>
            ))}
        </>
    )
}
