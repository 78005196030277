import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { getEmbeddableUrl } from './utils'

const StyledIframe = styled.iframe`
    height: 300px;
    border: 0;
    width: 100%;
`

type EmbeddableContentProps = React.ComponentPropsWithoutRef<'iframe'> & {}

const EmbeddableContent: React.FC<EmbeddableContentProps> = ({ src = '', ...props }) => {
    const iframeUrl = useMemo(() => getEmbeddableUrl(src), [src])

    if (iframeUrl === null) return null

    return <StyledIframe allowFullScreen={true} src={iframeUrl} {...props} />
}

export default EmbeddableContent
