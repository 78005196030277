const defaultTree = [
    {
        id: 'page',
        type: 'container',
        config: {
            attributes: {
                label: 'Page',
                direction: 'column',
            },
            style: {
                default: {
                    width: '100%',
                    height: '100%',
                    justifyContent: 'flex-start',
                    margin: '0px auto',
                    flexGrow: 0,
                },
            },
        },
        childBlocks: [
            {
                id: 'center_content',
                type: 'container',
                config: {
                    attributes: {
                        label: 'Center Content',
                        direction: 'column',
                    },
                    style: {
                        default: {
                            width: '100%',
                            maxWidth: '1000px',
                            minHeight: '50px',
                            marginTop: '20px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '0px',
                            flexGrow: '0',
                            paddingBottom: '30px',
                        },
                    },
                },
                childBlocks: [
                    {
                        id: 'container_0',
                        type: 'container',
                        config: {
                            attributes: {
                                direction: 'column',
                                label: 'Content container',
                            },
                            style: {
                                default: {
                                    width: '100%',
                                    height: '100%',
                                    margin: '0px auto',
                                    flexGrow: 0,
                                    justifyContent: 'flex-start',
                                },
                            },
                        },
                        childBlocks: [
                            {
                                id: 'text_0',
                                type: 'heading',
                                config: {
                                    attributes: {
                                        label: 'Text',
                                        text: 'Your page goes here!',
                                    },
                                    style: {
                                        default: {
                                            fontSize: '30pt',
                                            fontWeight: 'bold',
                                        },
                                    },
                                    broadcast: [],
                                },
                                childBlocks: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]

export default defaultTree
