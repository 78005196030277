import React, { useEffect, useRef, useState } from 'react'

import { CustomListRendererColumn } from 'v2/views/List/types'

import { SearchBox } from 'v2/ui/components/SearchBox'
import { useSearchRecords } from 'v2/ui/utils/useSearchRecords'

type DataListBlockSearchBoxProps = {
    handleSearch?: (value: string | undefined, data: RecordDto[]) => void
    searchQuery?: string
    searchColumns?: CustomListRendererColumn[]
    dereferencedRecords?: RecordDto[]
    records?: RecordDto[]
}

const DataListBlockSearchBox = ({
    handleSearch,
    searchQuery,
    records,
    searchColumns,
    dereferencedRecords,
}: DataListBlockSearchBoxProps) => {
    const [showSearch, setShowSearch] = useState(false)
    const searchRecords = useSearchRecords(
        records ?? [],
        searchColumns ?? [],
        dereferencedRecords ?? []
    )

    const searchInputRef = useRef<HTMLInputElement>(null)

    // Close search input when clicked outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchInputRef.current && !searchInputRef.current.contains(event.target as Node)) {
                setShowSearch(false)
            }
        }
        if (showSearch) {
            document.addEventListener('click', handleClickOutside)
        }
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [setShowSearch, showSearch])

    return (
        <SearchBox
            ref={searchInputRef}
            placeholder="Search"
            buttonVariant="DataWidgetSearchButton"
            location="right"
            search={searchQuery}
            onSearch={(value) => {
                handleSearch && handleSearch(value, searchRecords(value))
            }}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            dataTestIdPrefix="DataWidget"
        />
    )
}

export default DataListBlockSearchBox
