import { getDropdownFieldOptionColor } from 'utils/fieldUtils'

import { InlineFilterMultiValueOption } from './types'

export function filterMultiValueOptions(options: InlineFilterMultiValueOption[], query: string) {
    const normalizedQuery = query.trim().toLowerCase()
    if (!normalizedQuery) return options

    return options.filter(
        ({ label }) => label && String(label)?.toLowerCase().includes(normalizedQuery)
    )
}

export function mapDropdownOptionsToMultiValueOptions(
    fieldOptions?: Partial<FieldOptions>,
    useThemeColors: boolean = false
): InlineFilterMultiValueOption[] {
    if (!fieldOptions?.options) return []

    const useFieldColors = fieldOptions.allow_dropdown_colors

    return fieldOptions.options.map((option) => {
        let color: string | undefined = undefined
        if (useFieldColors) color = getDropdownFieldOptionColor(option)
        if (useThemeColors) color = option.color

        return {
            label: option.label ?? option.value,
            value: option.value,
            color,
        }
    })
}
