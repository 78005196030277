import React from 'react'
import { usePopper } from 'react-popper'

export type DataGridTooltipProps = {
    tooltip:
        | {
              title: string
              bounds: {
                  x: number
                  y: number
                  width: number
                  height: number
              }
          }
        | undefined
}

export function DataGridTooltip({ tooltip }: DataGridTooltipProps) {
    const [popperElement, setPopperElement] = React.useState<null | HTMLDivElement>(null)

    const virtualElement = React.useMemo(
        () =>
            tooltip?.bounds
                ? {
                      getBoundingClientRect() {
                          return {
                              top: tooltip.bounds.y,
                              left: tooltip.bounds.x,
                              right: tooltip.bounds.x + tooltip.bounds.width,
                              bottom: tooltip.bounds.y + tooltip.bounds.height,
                              width: tooltip.bounds.width,
                              height: tooltip.bounds.height,
                          }
                      },
                  }
                : undefined,
        [tooltip?.bounds]
    )

    const { styles, attributes } = usePopper(virtualElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 5],
                },
            },
        ],
    })

    if (!tooltip) return null
    return (
        <div
            ref={setPopperElement}
            style={{
                fontSize: '12px',
                color: 'white',
                opacity: 0.9,
                padding: '8px 12px',
                borderRadius: '5px',
                boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
                backgroundColor: '#15181d',
                ...styles.popper,
            }}
            {...attributes.popper}
        >
            {tooltip.title}
        </div>
    )
}
