/* Code Quality: Not audited */

import { createContext } from 'react'

import { fetchAndReturn, fetchWithAuth, StackerAPI } from '../utils/utils'

// API
class ObjectApi extends StackerAPI {
    path = 'objects/'

    createDataFeature(id) {
        return fetchAndReturn(`objects/${id}/create-data-feature`, {
            method: 'POST',
        })
    }

    /**
     * Connects a table with a Provider (source) table
     * @param {} id Current table ID
     * @param {*} sourceTableId Provider table ID
     * @returns
     */
    connect(id, sourceTableId) {
        const body = {
            method: 'POST',
            body: JSON.stringify({ row_provider: sourceTableId }),
        }
        return fetchWithAuth(`objects/${id}/connect/`, body)
            .then((response) => response.json())
            .catch((error) => error)
    }

    /**
     * Disconnect a table from its provider
     * @param {*} id Current table ID
     * @returns
     */
    disconnect(id) {
        const body = {
            method: 'POST',
            body: JSON.stringify({ row_provider: null }),
        }
        return fetchWithAuth(`objects/${id}/connect/`, body)
            .then((response) => response.json())
            .catch((error) => error)
    }

    transformFromLocal = (data) => {
        if (!data) return data
        const keys = Object.keys(data)
        const out = {}
        keys.forEach((key) => {
            if (key.includes('connection_options.')) {
                const keyName = key.split('.')[1]
                out.connection_options = { ...out.connection_options, [keyName]: data[key] }
            } else if (key.includes('options.')) {
                const keyName = key.split('.')[1]
                out.options = { ...out.options, [keyName]: data[key] }
            } else {
                out[key] = data[key]
            }
        })
        return out
    }
}
export const objectApi = new ObjectApi()

export const objectSettingsContext = createContext()
