import React, { useCallback, useEffect, useState } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { refetchDataConnections } from 'data/hooks/dataConnections'
import { getUserApiToken, Token } from 'data/utils/getUserToken'
import { getOAuthBounceUrl } from 'features/AppSettings/DataSources/Airtable/utils/getOAuthBounceUrl'
import AttributeList from 'features/records/components/AttributeList'

import { Button, Flex, Text } from 'v2/ui'
import { Badge } from 'v2/ui/components/Badge'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { getUrl } from '../../../../../app/UrlService'
import { fetchWebhookInfo, retestAirtableConnection } from '../airtableConnectionTools'

import { AirtableConnectionStatusInfo } from './AirtableConnectionStatusInfo'
import { getFieldsForConnectionType } from './getFieldsForConnectionType'
import { PayloadsBrowser } from './PayloadsBrowser'
import { PostgresV2AdditionalDebugData } from './PostgresV2AdditionalDebugData'

type DataConnectionWithUserDisplayName = DataConnectionDto & { userDisplayName: string }

const formatDataConnection = (dc: DataConnectionDto): DataConnectionWithUserDisplayName => ({
    ...dc,
    userDisplayName: `${dc.user_oauth_token?.user} (${dc.user_oauth_token?.user_email})`,
})

type ConnectionDetailsModalProps = {
    isOpen: boolean
    onClose: () => void
    connection: DataConnectionDto
}

export const ConnectionDetailsModal = ({
    connection,
    isOpen,
    onClose,
}: ConnectionDetailsModalProps): React.ReactElement => {
    const [status, setStatus] = useState<undefined | string>(connection.status)
    const [webhookData, setWebhookData] = useState<Record<string, any> | undefined>(undefined)
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const formattedConnection = formatDataConnection(connection)
    const { user } = useAppUserContext()
    const [apiToken, setApiToken] = useState<Token | undefined>(undefined)
    const [isShowingPayloadsBrowser, setIsShowingPayloadsBrowser] = useState(false)

    useEffect(() => {
        getUserApiToken(user).then((token) => {
            setApiToken(token)
        })
    }, [user])

    const isAirtable = connection.type === 'airtable'
    const isStackerTableOrUsers =
        connection.type === 'native_tables' || connection.type === 'stacker_users'

    const onFetchWebhook = useCallback(() => {
        if (connection && isAirtable) {
            fetchWebhookInfo(connection._sid).then((data) => {
                setWebhookData(data)
            })
        }
    }, [connection, isAirtable, setWebhookData])

    useEffect(() => {
        onFetchWebhook()
    }, [onFetchWebhook])

    const onRetest = () => {
        if (!connection._sid || !isAirtable) return

        setIsUpdating(true)
        retestAirtableConnection(connection._sid)
            .then((data) => {
                if (status != data.token_status) {
                    setStatus(data.token_status)
                    refetchDataConnections()
                }
            })
            .finally(() => {
                onFetchWebhook()
                setIsUpdating(false)
            })
    }

    return (
        <>
            <SimpleModalCompat
                size="650px"
                height="570px"
                noDividers
                padding="10px 20px 10px 20px"
                zIndex={1501}
                actionsBorder={false}
                isOpen={isOpen}
                onClose={onClose}
                hideTitleBottomBorder
                showCloseButton={true}
            >
                <Flex column>
                    <Text variant="heading" fontWeight="bold">
                        {connection.label} - Connection Details
                    </Text>
                    <Flex pt={2}>
                        <Badge color="blue">{connection.type}</Badge>
                        <div style={{ paddingRight: '5px' }} />
                        <Badge color="gray">V{connection.version}</Badge>
                    </Flex>
                    <Flex wrapItems>
                        <AttributeList
                            data={formattedConnection}
                            fields={getFieldsForConnectionType(connection.type)}
                        />

                        {isAirtable && (
                            <AirtableConnectionStatusInfo
                                isUpdating={isUpdating}
                                status={status}
                                webhookData={webhookData}
                            />
                        )}

                        {connection.type === 'postgresV2' && (
                            <PostgresV2AdditionalDebugData connection={connection} />
                        )}
                    </Flex>
                    {isAirtable && (
                        <Button
                            isLoading={isUpdating}
                            color="clear"
                            size="small"
                            variant="Secondary"
                            style={{ padding: '10px', marginTop: '10px' }}
                            onClick={onRetest}
                        >
                            Retest Connection
                        </Button>
                    )}
                    {isAirtable && (
                        <Button
                            size="small"
                            variant="Secondary"
                            style={{ padding: '10px', marginTop: '10px' }}
                            onClick={() => setIsShowingPayloadsBrowser(true)}
                        >
                            Payloads browser
                        </Button>
                    )}
                    {!isStackerTableOrUsers && (
                        <Button
                            color="clear"
                            size="small"
                            variant="Secondary"
                            style={{ padding: '10px', marginTop: '10px' }}
                            href={getOAuthBounceUrl({
                                dataConnection: connection,
                                apiToken,
                                returnUrl: window.location.origin + getUrl('/'),
                                shouldOverwriteToken: true,
                            })}
                        >
                            Re-authorise
                        </Button>
                    )}
                </Flex>
            </SimpleModalCompat>
            {isAirtable && (
                <PayloadsBrowser
                    connection={connection}
                    isOpen={isShowingPayloadsBrowser}
                    onClose={() => setIsShowingPayloadsBrowser(false)}
                ></PayloadsBrowser>
            )}
        </>
    )
}
