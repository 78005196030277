/* Code Quality: Not audited */

import React, { Component, createContext } from 'react'

import find from 'lodash/find'
import some from 'lodash/some'

import MergeObjects from '../MergeObjects'

export const context = createContext()

export const { Provider, Consumer } = context

/**
 * @augments {Component<{context: BroadcastContextType, when?:(action: Broadcastable) => boolean, children: any}, {items: Broadcastable[]}>}
 */
export class BroadcastContext extends Component {
    state = {
        items: [],
    }

    /**
     * @type {Partial<BroadcastContextType>}
     */
    contextObject = {
        /**
         * @param {EntityId} blockId
         * @param {Broadcastable} broadcastItem
         */
        add: (blockId, broadcastItem) => {
            // should we handle it or should it be our parent
            if (this.props.context && typeof this.props.when === 'function') {
                if (!this.props.when(broadcastItem)) {
                    return this.props.context.add(blockId, broadcastItem)
                }
            }
            console.log(
                'BroadcastContext.add',
                this.props.context ? 'in context' : 'globally',
                broadcastItem.channel,
                broadcastItem,
                this.props.debugMessage
            )
            this.setState(({ items }) => {
                let newItems
                // Add a new item if this is new, otherwise replace the old one
                const alreadyAdded = some(items, (row) => broadcastItem.id === row.id)
                if (alreadyAdded) {
                    // Replace the item
                    newItems = items.map((item) => {
                        if (item.id === broadcastItem.id) return broadcastItem
                        return item
                    })
                } else {
                    newItems = [...items, broadcastItem]
                }
                return { items: newItems }
            })
        },
        /**
         * @param {EntityId} blockId
         * @param {string} broadcastId
         */
        remove: (blockId, broadcastId) => {
            this.setState(({ items }) => {
                const broadcastItem = items.filter(
                    (broadcastItem) => broadcastItem.id === broadcastId
                )[0]

                if (!broadcastItem) {
                    if (this.props.context) {
                        this.props.context.remove(blockId, broadcastId)
                    }
                    return null
                }

                // should we handle it or should it be our parent
                if (this.props.context && typeof this.props.when === 'function') {
                    if (!this.props.when(broadcastItem)) {
                        this.props.context.remove(blockId, broadcastId)
                        return null
                    }
                }
                console.log(
                    'BroadcastContext.remove',
                    this.props.context ? 'in context' : 'globally',
                    broadcastItem.channel,
                    blockId,
                    broadcastId
                )

                return {
                    items: items.filter((row) => row !== broadcastItem),
                }
            })
        },
        getItemByName: (published_name, deep = false) => {
            const item = find(this.state.items, (item) => item.published_name === published_name)

            if (item) {
                return item
            }
            if (deep && this.props.context) {
                return this.props.context.getItemByName(published_name, deep)
            }
        },
        getItemById: (id, deep = false) => {
            const item = find(this.state.items, (item) => item.id === id)

            if (item) {
                return item
            }
            if (deep && this.props.context) {
                return this.props.context.getItemById(id, deep)
            }
        },
        updatePublishedName: (id, newName) => {
            const item = this.contextObject.getItemById(id)

            if (!item && this.props.context) {
                return this.props.context.updatePublishedName(id, newName)
            }

            this.setState(({ items }) => ({
                items: items.map((row) => {
                    if (row !== item) return row

                    return {
                        ...row,
                        published_name: newName,
                    }
                }),
            }))
        },
    }
    renderChildren() {
        if (typeof this.props.children === 'function') {
            return this.props.children({
                allItems: this.contextObject.items,
                localItems: this.state.items,
            })
        }

        return this.props.children
    }
    render() {
        const items = MergeObjects(this.props.context && this.props.context.items, this.state.items)
        if (this.contextObject.items !== items) {
            this.contextObject = {
                ...this.contextObject,
                items,
            }
        }

        if (!this.props.context) {
            // @ts-ignore
            window.broadcast = this.contextObject
        }
        return <Provider value={this.contextObject}>{this.renderChildren()}</Provider>
    }
}

BroadcastContext.propTypes = {}

export default function BroadcastContextInContext(props) {
    return <Consumer>{(context) => <BroadcastContext {...props} context={context} />}</Consumer>
}
