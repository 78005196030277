import React, { useCallback, useMemo, VFC } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import type { FieldTypeComponentData } from 'features/admin/fields/definitions/fieldTypeComponents'

import { Text } from 'v2/ui'
import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'
import stackerTheme from 'v2/ui/theme/styles/default'

import type { FieldGroupOption, InputDevice } from './types'

const { colors } = stackerTheme()

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const FieldTypeGroup = styled.div`
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const OptionWrapper = styled.div<{
    highlighted: boolean
    activeInputDevice: InputDevice | null
    isSelected: boolean
}>`
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;

    width: 100%;

    padding: 5px 8px;

    ${(props) =>
        props.activeInputDevice === 'keyboard' &&
        props.highlighted &&
        css`
            background-color: ${colors.userInterface.accent[100]};
        `}

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${colors.userInterface.neutral[200]};
        `}

    ${(props) =>
        props.activeInputDevice === 'mouse' &&
        css`
            &:hover {
                background-color: ${colors.userInterface.accent[100]};
            }
        `}
`

const SecondaryLabel = styled(Text)`
    font-size: 13px;
    color: ${colors.userInterface.neutral[800]};
`

const GroupLabel = styled(SecondaryLabel)`
    margin-left: 8px;
    margin-bottom: 2px;
`

const Label = styled(Text)`
    font-size: 14px;
    white-space: nowrap;
`

const Description = styled(SecondaryLabel)`
    margin-left: 8px;

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) =>
        props.isSelected &&
        css`
            color: ${colors.userInterface.neutral[850]};
        `}
`

type Props = {
    options: FieldGroupOption[]
    flattenOptions: FieldTypeComponentData[]
    highlightedOptionIndex: number | null
    activeInputDevice: InputDevice | null
    onHighlightOption: (optionIndex: number) => void
    onChange: (selectedFieldType: string) => void
    value?: string | null
}

const FieldTypeOptions: VFC<Props> = ({
    options,
    flattenOptions,
    highlightedOptionIndex,
    activeInputDevice,
    onHighlightOption,
    onChange,
    value,
}) => {
    const highlightedFieldType = useMemo(
        () => (highlightedOptionIndex === null ? -1 : flattenOptions[highlightedOptionIndex]),
        [flattenOptions, highlightedOptionIndex]
    )
    const useScrollIntoViewRef = useScrollIntoView(activeInputDevice === 'keyboard', {
        block: 'nearest',
        behavior: 'smooth',
    })

    const onOptionMouseEnter = useCallback(
        (fieldType: FieldTypeComponentData) => {
            if (activeInputDevice !== 'mouse') {
                return
            }

            onHighlightOption(flattenOptions.findIndex((option) => option === fieldType))
        },
        [activeInputDevice, flattenOptions, onHighlightOption]
    )
    // if we've only got a few options, it looks worse to have the groups in there
    const hideGroups = flattenOptions.length < 7
    return (
        <Wrapper>
            {hideGroups
                ? flattenOptions.map((fieldType) => (
                      <FieldTypeOption
                          key={fieldType.value}
                          fieldType={fieldType}
                          onChange={onChange}
                          highlighted={fieldType === highlightedFieldType}
                          activeInputDevice={activeInputDevice}
                          onOptionMouseEnter={onOptionMouseEnter}
                          scrollIntoViewRef={useScrollIntoViewRef}
                          isSelected={fieldType.value === value}
                      />
                  ))
                : options.map(({ label: groupLabel, fieldTypes }) => (
                      <FieldTypeGroup
                          key={groupLabel}
                          ref={
                              fieldTypes?.[0] === highlightedFieldType
                                  ? useScrollIntoViewRef
                                  : undefined
                          }
                      >
                          <GroupLabel>{groupLabel}</GroupLabel>
                          {fieldTypes.map((fieldType) => (
                              <FieldTypeOption
                                  key={fieldType.value}
                                  fieldType={fieldType}
                                  onChange={onChange}
                                  highlighted={fieldType === highlightedFieldType}
                                  activeInputDevice={activeInputDevice}
                                  onOptionMouseEnter={onOptionMouseEnter}
                                  scrollIntoViewRef={useScrollIntoViewRef}
                                  isSelected={fieldType.value === value}
                              />
                          ))}
                      </FieldTypeGroup>
                  ))}
        </Wrapper>
    )
}

type FieldTypeOptionProp = {
    fieldType: FieldTypeComponentData
    highlighted: boolean
    activeInputDevice: InputDevice | null
    onOptionMouseEnter: (fieldType: FieldTypeComponentData) => void
    onChange: (selectedFieldType: string) => void
    scrollIntoViewRef: (elm: HTMLElement | null) => void
    isSelected?: boolean
}
const FieldTypeOption = React.memo<FieldTypeOptionProp>(
    ({
        fieldType,
        highlighted,
        activeInputDevice,
        onOptionMouseEnter,
        onChange,
        scrollIntoViewRef,
        isSelected = false,
    }) => {
        return (
            <OptionWrapper
                key={fieldType.value}
                highlighted={highlighted}
                activeInputDevice={activeInputDevice}
                ref={highlighted ? scrollIntoViewRef : null}
                onMouseEnter={() => onOptionMouseEnter(fieldType)}
                onClick={() => onChange(fieldType.value)}
                isSelected={isSelected}
            >
                {/* @ts-ignore */}
                <fieldType.iconComponent
                    fill={colors.userInterface.accent[1000]}
                    stroke={colors.userInterface.accent[1000]}
                    style={{
                        marginRight: '8px',
                        alignSelf: 'center',
                        minWidth: '16px',
                        minHeight: '16px',
                    }}
                />
                <Label>{fieldType.label}</Label>
                <Description isSelected={isSelected}>{fieldType.description}</Description>
            </OptionWrapper>
        )
    }
)
export default FieldTypeOptions
