import React from 'react'

import { ListCallout } from 'features/views/List/ui/utils'
import Icon8 from 'legacy/v1/ui/components/Icon8'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import Box from './Box'
import Text from './Text'

const ConfigureWidgetPlaceholder = ({ message, subtitle, Icon, onClick = () => {}, ...props }) => (
    <ListCallout
        style={{
            cursor: 'pointer',
            padding: '70px 40px',
            marginBottom: '10px',
            flexDirection: 'column',
        }}
        onClick={onClick}
        {...props}
    >
        <Box>
            {Icon ? (
                <Icon width={50} />
            ) : (
                <Icon8
                    iconStyle="ios-glyphs"
                    size="100"
                    icon="settings"
                    color="9da3ab"
                    displaySize="35"
                    style={{ marginRight: 0 }}
                />
            )}
        </Box>
        <Text color={V4DesignSystem.colors.gray[800]} fontWeight="bold" mt={4}>
            {message}
        </Text>
        {subtitle && (
            <Text color={V4DesignSystem.colors.gray[600]} mt={2} size="sm">
                {subtitle}
            </Text>
        )}
    </ListCallout>
)

export default ConfigureWidgetPlaceholder
