import { useEffect, useState } from 'react'

const useCommandBar = () => {
    const [commandBarReady, setCommandBarReady] = useState(false)

    const useAddToCommandBar = (callback: () => void) => {
        useEffect(() => {
            if (commandBarReady) {
                try {
                    callback()
                } catch (e) {
                    console.log(`cound not add ${callback.name}`)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [callback, commandBarReady])
    }

    return { setCommandBarReady, useAddToCommandBar }
}

export default useCommandBar
