// @ts-strict-ignore
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppUserContext } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { assertNever } from 'data/utils/ts_utils'
import { Button } from 'features/auth/stacker-auth/AuthUIElements'

import useUrlParam from 'v2/ui/utils/useUrlParam'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { Flex } from '../../v2/ui'

import AuthPageFrame from './stacker-auth/AuthPageFrame'

enum ErrorTypes {
    AUTH0_ERROR,
}

const ErrorMessageContainer = styled.div`
    width: 100%;

    .inner {
        border-radius: 6px;
        background: #fff;
        color: #000;
        font-size: 18px;
        padding: 20px 24px;
    }

    .paragraph {
        margin-top: 16px;

        &:first-of-type {
            font-weight: 600;
            margin-bottom: 32px;
            background: ${V4DesignSystem.colors.gray[100]};
            padding: 12px 16px;
            border-radius: 2px;
            font-size: 20px;
        }
    }

    .err-code {
        color: ${V4DesignSystem.colors.gray[500]};
        font-size: 22px;
        font-weight: 600;
        padding-top: 16px;
    }
`

const ErrorMessage: React.FC<ErrorState> = ({ type }) => {
    const auth0 = useAuth0()
    const handleClick = () => {
        auth0.logout({ returnTo: `${window.location.origin}${getUrl(Urls.Login)}` })
    }
    switch (type) {
        // if any error is returned from the auth0 flow itself, it probably means that
        // auth0 is not configured correctly in some way - this would be something for
        // us to fix, but the user can't do anything about it other than tell their admin
        // give them a code to report to their admin to identify  this
        case ErrorTypes.AUTH0_ERROR:
            return (
                <ErrorMessageContainer>
                    <div className="inner">
                        <div className="paragraph">Sorry, something went wrong.</div>
                        <div className="paragraph">
                            Please contact your workspace Administrator to report the following
                            error code on log in.
                        </div>
                        <div className="paragraph err-code">AUTH-001</div>

                        <Button onClick={handleClick} mt={6}>
                            Back to Login
                        </Button>
                    </div>
                </ErrorMessageContainer>
            )

        default:
            assertNever(type)
    }
}

const LoadingSpinner: React.FC = () => {
    return (
        <Flex height="100%" align="center" column py="100px">
            <Spinner />
        </Flex>
    )
}

type ErrorState = {
    type: ErrorTypes
    code: string
    description: string
}
export function Auth0CompleteLoginPage() {
    const auth0 = useAuth0()
    const [success, setSuccess] = useState(false)
    const [redirectTo] = useUrlParam('r', '', { uriDecode: true })
    const [auth0Error] = useUrlParam('error', '')
    const [auth0ErrorDescription] = useUrlParam('error_description', '')
    const [errorState, setErrorState] = useState<ErrorState | undefined>()
    const { isAuthenticated } = auth0
    const { user } = useAppUserContext()

    useEffect(() => {
        // Wait until we have a user so we know whether to direct them to edit-profile
        if (user && isAuthenticated) {
            setSuccess(true)
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (auth0Error) {
            setErrorState({
                type: ErrorTypes.AUTH0_ERROR,
                code: auth0Error,
                description: auth0ErrorDescription,
            })
        }
    }, [auth0Error, auth0ErrorDescription])

    // @ts-ignore
    const successRedirect = redirectTo || getUrl(Urls.Root)

    return (
        // @ts-ignore
        <AuthPageFrame>
            {success ? (
                user?.membership_options?.profile_pending ? (
                    <Redirect to={getUrl(Urls.EditProfile)} />
                ) : (
                    <Redirect to={successRedirect} />
                )
            ) : errorState !== undefined ? (
                <ErrorMessage {...errorState} />
            ) : (
                <LoadingSpinner />
            )}
        </AuthPageFrame>
    )
}

export default connect()(Auth0CompleteLoginPage)
