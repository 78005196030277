import React, { useMemo } from 'react'

import { HStack } from '@chakra-ui/react'

import { Text } from 'v2/ui'

import { formatMultiValueLabel } from './utils'

type InlineFilterDescriptorProps = React.ComponentPropsWithoutRef<typeof HStack> & {
    label: string
    opLabel?: string
    values?: string[]
    showValue?: boolean
}

export const InlineFilterDescriptor: React.FC<InlineFilterDescriptorProps> = ({
    label,
    opLabel,
    values = [],
    showValue = false,
    ...props
}) => {
    const valueLabel = useMemo(() => formatMultiValueLabel(values), [values])

    return (
        <HStack spacing={1} py={1} {...props}>
            <Text color="inherit" lineHeight={1} py={1} fontWeight="bold" fontSize="sm">
                {label}
            </Text>

            {showValue && (
                <>
                    {opLabel && (
                        <Text color="inherit" lineHeight={1} py={1} fontWeight="bold" fontSize="sm">
                            {opLabel}
                        </Text>
                    )}
                    {valueLabel && (
                        <Text
                            color="brand.500"
                            fontWeight="bold"
                            fontSize="xs"
                            lineHeight={1}
                            px={1}
                            py={1}
                            backgroundColor="brand.100"
                            rounded={4}
                            whiteSpace="nowrap"
                        >
                            {valueLabel}
                        </Text>
                    )}
                </>
            )}
        </HStack>
    )
}
