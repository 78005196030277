/* Code Quality: Not audited */

import React, { useCallback } from 'react'

import { useObject } from 'data/hooks/objects'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { getCoverImageFields } from 'utils/fieldUtils'

import { Box, Dropdown, Flex, Radio, Text } from 'v2/ui'

const fitOptions = [
    { label: 'Crop', value: 'false' },
    { label: 'Fit', value: 'true' },
]

const CoverImage = ({ showImageDisplayOptions, ...props }) => {
    const { object } = useObject(props.objectId)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    const onChange = useCallback(
        (id) => props.onChange({ ...props.value, id }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onChange, props.value]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeFit = useCallback((val) => props.onChangeFit(val == 'true'), [props.onChangeFit])
    const value = props.value || {}

    let { fields } = object
    fields = getCoverImageFields(sortByFieldsOrder(fields))

    return (
        <Flex>
            <Box flex={1}>
                <Box mb={4}>
                    <label>
                        <Text variant="paletteSectionLabel">{props.label || 'Image source'}</Text>
                        <Dropdown
                            adminTheme
                            options={fields.map((field) => ({
                                value: field.api_name,
                                label: field.label,
                            }))}
                            value={value.id}
                            onChange={onChange}
                        />
                    </label>
                </Box>
                {showImageDisplayOptions && (
                    <label>
                        <Text variant="paletteSectionLabel">Image display settings</Text>
                        <Radio
                            value={props.fitImage === true ? 'true' : 'false'}
                            onChange={changeFit}
                            options={fitOptions}
                        />
                    </label>
                )}
            </Box>
        </Flex>
    )
}

export default CoverImage
