// @ts-strict-ignore
import React, { useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { getUrl } from 'app/UrlService'
import { useInstallableFeatures } from 'data/hooks/installableFeatures'
import { useNavigation } from 'data/hooks/navigation'

import { Box } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { useAppContext } from '../../app/AppContext'
import { importUrl } from '../../data/api/bundlesApi'
import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import {
    BackgroundImage,
    Button,
    FixedGrid,
    Flex,
    Modal as ModalV2,
    ScrollBox,
    Text,
} from '../../v2/ui'
import { Success } from '../../v2/ui/svgs'

import FeatureIcon from './FeatureIcon'
import InstallableFeatureDetail from './InstallableFeatureDetail'
import { reloadMetadataAndDataAfterInstall } from './reloadMetadataAndDataAfterInstall'

const colors = stackerTheme().colors

const InstallableFeatureLibrary = () => {
    const showUnpublished =
        localStorage.getItem('stacker_show_unpublished_installable_features') === 'true'
    const { selectedStack, workspaceAccount } = useAppContext()

    const { data: features } = useInstallableFeatures({
        unpublished: showUnpublished,
        type: 'feature',
    })
    const [selectedFeature, setSelectedFeature] = useState<FeatureDto | null>(null)

    const [isInstallCompleteModalOpen, setIsInstallCompleteModal] = useState(false)
    const [installedObjectSids, setInstalledObjectSids] = useState<[] | null>(null)
    const [isInstalling, setIsInstalling] = useState(false)
    const { data: navigation = [] } = useNavigation() as UseQueryResult<RecordDto[]>
    const history = useHistory()

    const onFeatureClick = (feature: FeatureDto | null) => {
        setSelectedFeature(feature)
    }

    const onDetailPageClose = () => {
        setSelectedFeature(null)
    }

    const onInstallNew = () => {
        setSelectedFeature(null)
        setIsInstallCompleteModal(false)
    }

    const onInstallSuccess = (objectSids) => {
        setInstalledObjectSids(objectSids)
        setIsInstallCompleteModal(true)
    }

    const onInstallComplete = (objectSids) => {
        setIsInstallCompleteModal(false)

        const sid = objectSids?.object_sids?.[0]

        const navItem = navigation?.find((nav) => nav.object_id === sid && nav.parent_id)

        if (navItem?.url) history.push(getUrl(navItem.url, selectedStack))
    }

    const installFeature = async (feature) => {
        setIsInstalling(true)

        const objectSids = await importUrl(
            feature.bundle_url,
            workspaceAccount?._sid,
            selectedStack?._sid
        )
        reloadMetadataAndDataAfterInstall()

        setIsInstalling(false)
        onInstallSuccess(objectSids)
    }

    return (
        <>
            <InstallModal
                isOpen={isInstalling || isInstallCompleteModalOpen}
                isInstallComplete={isInstallCompleteModalOpen}
                onClose={() => onInstallComplete(installedObjectSids!)}
                onInstallNew={() => onInstallNew()}
                onFeatureRedirect={() => onInstallComplete(installedObjectSids!)}
            />

            {/* @ts-ignore */}
            {selectedFeature ? (
                <ScrollBox w="100%" h="100%">
                    <InstallableFeatureDetail
                        accountId={workspaceAccount?._sid}
                        stackId={selectedStack?._sid}
                        feature={selectedFeature}
                        onClose={onDetailPageClose}
                        onInstallComplete={onInstallSuccess}
                    />
                </ScrollBox>
            ) : (
                <Flex column align="start">
                    <Flex column align="start" m={4}>
                        <Text variant="installableFeatureCardTitle" mb={2}>
                            Explore Features
                        </Text>
                        <Text variant="installableFeatureCardDescription">
                            Features help you create apps more rapidly, without the need to start
                            from scratch.{' '}
                        </Text>
                    </Flex>

                    {/* @ts-ignore */}
                    <FixedGrid columns={[1, 1, 3, 3]} spacing="12px" p={4}>
                        {features?.map((feature) => (
                            <InstallableFeatureCard
                                key={feature.name}
                                title={feature.name}
                                description={feature.card_description}
                                icon={feature.icon}
                                iconColor={feature.icon_color}
                                image={feature.card_image}
                                isPublished={feature.is_published}
                                onItemClick={() => {
                                    onFeatureClick(feature)
                                }}
                                onInstallFeature={() => {
                                    installFeature(feature)
                                }}
                            />
                        ))}
                    </FixedGrid>
                </Flex>
            )}
        </>
    )
}

type InstallableFeatureCardProps = {
    title: string
    description?: string
    icon?: string
    iconColor?: string
    image?: string
    isPublished: boolean
    onItemClick: () => void
    onInstallFeature: () => void
}

const InstallableFeatureCard = ({
    title,
    description,
    icon,
    iconColor,
    image = '',
    isPublished,
    onItemClick,
    onInstallFeature,
}: InstallableFeatureCardProps) => {
    return (
        <>
            {/* @ts-ignore */}
            <div onClick={onItemClick} style={{ position: 'relative', cursor: 'pointer' }}>
                <Flex column m={0}>
                    <BackgroundImage
                        src={image}
                        borderTopLeftRadius="lg"
                        borderTopRightRadius="lg"
                        w="100%"
                        h={[
                            'installableFeatureLibrary.coverImage',
                            null,
                            null,
                            'installableFeatureLibrary.coverImage',
                        ]}
                    />
                    {!isPublished && <UnpublishedLabel>Under development</UnpublishedLabel>}
                    <FeatureCardInfo>
                        <Flex row mb={5}>
                            <FeatureIcon
                                icon={icon || 'faClouds'}
                                color={iconColor || V4DesignSystem.colors.stacker}
                            />
                            <Text variant="installableFeatureCardTitle">{title}</Text>
                        </Flex>
                        <Text variant="installableFeatureCardDescription" mb={5}>
                            {description}
                        </Text>
                        <Button
                            variant="adminPrimaryV4"
                            size="sm"
                            w="100%"
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                onInstallFeature()
                            }}
                        >
                            Install feature
                        </Button>
                    </FeatureCardInfo>
                </Flex>
            </div>
        </>
    )
}

type InstallModalProps = {
    isOpen: boolean
    isInstallComplete: boolean
    onClose: () => void
    onInstallNew: () => void
    onFeatureRedirect: () => void
}

const InstallModal = ({
    isOpen,
    isInstallComplete,
    onClose,
    onInstallNew,
    onFeatureRedirect,
}: InstallModalProps) => {
    return (
        <ModalV2
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            showCloseButton={false}
            noPadding
            size="400px"
            body={
                <form>
                    <Box mb="15px" mt="15px">
                        <Success />
                        <Text variant="modalTitle" mt={6} fontWeight="bold">
                            {isInstallComplete
                                ? 'Installation complete'
                                : 'Installation in progress'}
                        </Text>
                        <Text mt={4}>
                            {isInstallComplete
                                ? 'Your feature has been installed successfully! Start customizing it  now...'
                                : 'Thank you for being patient while the feature is being installed'}
                        </Text>

                        <Flex row justifyContent="space-around">
                            <Button
                                variant="Primary"
                                buttonSize="large"
                                disabled={!isInstallComplete}
                                mt={4}
                                onClick={onInstallNew}
                                width="50%"
                            >
                                Install another
                            </Button>
                            <Button
                                variant="Secondary"
                                buttonSize="large"
                                disabled={!isInstallComplete}
                                mt={4}
                                onClick={onFeatureRedirect}
                                width="50%"
                            >
                                See feature
                            </Button>
                        </Flex>
                    </Box>
                </form>
            }
        />
    )
}

const FeatureCardInfo = styled.div`
    padding: 12px;
    border: 1px solid ${colors.neutral[500]};
    border-top: none;
    border-radius: 0 0 8px 8px;
    flex-grow: 1;
    width: 100%;
    position: relative;
`

const UnpublishedLabel = styled.div`
    background: #d00000aa;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 2px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    color: white;
    line-height: 16px;
    font-family: 'Inter UI';
    letter-spacing: 0.6px;
    border-radius: 0.375rem 0.375rem 0 0;
    width: 100%;
    text-align: center;
`

export default InstallableFeatureLibrary
