import React from 'react'

import { DropdownInput } from './DropdownInput'
import { TextInput } from './TextInput'
import { InlineFilterInputComponent } from './types'

type TextOrDropdownInputProps = {}

export const TextOrDropdownInput: InlineFilterInputComponent<TextOrDropdownInputProps> = (
    props
) => {
    switch (props.field.type) {
        case 'lookup':
        case 'dropdown':
        case 'checkbox':
            return <DropdownInput singleValue={true} {...props} />
        case 'multi_select':
        case 'multi_lookup':
            return <DropdownInput singleValue={false} {...props} />
        case 'string':
        case 'long_text':
        case 'url':
        case 'image':
            return <TextInput {...props} />
        default:
            return null
    }
}
