import React, { FC } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'
import { AdvancedOptionsItem } from 'v2/views/List/AdvancedOptionsItem'

import FieldPicker from 'features/studio/ui/FieldPicker'

import { Box, Flex, Text } from 'v2/ui'

import { DataPanel as AggregationDataPanel } from '../../common/panels/DataPanel'
import { AggregationConfiguration } from '../../common/panels/DataPanel/AggregationConfiguration'
import { TimePeriodFilterDropdown } from '../../common/timePeriodFilters/TimePeriodFilterDropdown'

type DataPanelProps = {
    metric: MetricsBlockAttributes
    onChange: (
        metric: Pick<MetricsBlockAttributes, 'objectId'> & Partial<MetricsBlockAttributes>
    ) => void
    currentObject: ObjectDto
    context: ViewLayoutContext
}

export const DataPanel: FC<DataPanelProps> = ({ metric, onChange, currentObject, context }) => {
    const onChangeLimitToTimePeriodEnabled = (limitToTimePeriodEnabled: boolean) => {
        onChange({
            ...metric,
            limitToTimePeriodEnabled,
        })
    }

    const onChangeLimitToTimePeriod = (limitToTimePeriod: string) => {
        onChange({
            ...metric,
            limitToTimePeriod,
        })
    }

    const onChangeLimitToTimePeriodField = (limitToTimePeriodField: string) => {
        onChange({
            ...metric,
            limitToTimePeriodField,
        })
    }

    const onChangeCompareToPreviousPeriod = (compareToPreviousPeriod: boolean) => {
        onChange({
            ...metric,
            compareToPreviousPeriod,
        })
    }

    const onChangeSwitchPreviousPeriodColors = (switchPreviousPeriodColors: boolean) => {
        onChange({
            ...metric,
            switchPreviousPeriodColors,
        })
    }

    return (
        <>
            <AggregationDataPanel
                context={context}
                currentObject={currentObject}
                aggregationWidget={metric}
                onChange={onChange}
            />
            {metric.objectId && (
                <>
                    <AggregationConfiguration
                        aggregationWidget={metric}
                        onChange={onChange}
                        label="Aggregate"
                        tooltip="Choose the value you'd like to display"
                    />
                    <Flex flexDirection="row" justifyContent="space-between">
                        <AdvancedOptionsItem
                            label="Limit to time period"
                            id="limit-to-time-period-enabled"
                            colorScheme="adminSwitch"
                            value={metric.limitToTimePeriodEnabled}
                            onChange={onChangeLimitToTimePeriodEnabled}
                            labelVariant="paletteSectionLabel"
                            size="md"
                        />
                    </Flex>
                    {metric.limitToTimePeriodEnabled && (
                        <>
                            <Box>
                                <Text
                                    variant="paletteSectionLabel"
                                    as="label"
                                    htmlFor="limit-to-field"
                                    display="block"
                                >
                                    Date Field
                                </Text>
                                <FieldPicker
                                    inputId="limit-to-field"
                                    variant="admin"
                                    objectId={metric.objectId}
                                    value={metric.limitToTimePeriodField}
                                    onChange={onChangeLimitToTimePeriodField}
                                    isDisabled={false}
                                    filter={(field) =>
                                        field.type === 'date' || field.type === 'datetime'
                                    }
                                />
                            </Box>
                            <>
                                <Box>
                                    <Text
                                        variant="paletteSectionLabel"
                                        as="label"
                                        htmlFor="limit-to-period"
                                        display="block"
                                    >
                                        Time Period
                                    </Text>
                                    <TimePeriodFilterDropdown
                                        inputId="limit-to-period"
                                        value={metric.limitToTimePeriod}
                                        onChange={onChangeLimitToTimePeriod}
                                    />
                                </Box>
                                <Flex flexDirection="row" justifyContent="space-between">
                                    <AdvancedOptionsItem
                                        label="Compare to previous period"
                                        id="ccompare-to-previous-period-enabled"
                                        colorScheme="adminSwitch"
                                        value={metric.compareToPreviousPeriod}
                                        onChange={onChangeCompareToPreviousPeriod}
                                        labelVariant="paletteSectionLabel"
                                        size="md"
                                    />
                                </Flex>
                                <Flex flexDirection="row" justifyContent="space-between">
                                    <AdvancedOptionsItem
                                        label="Switch positive/negative colors"
                                        id="compare-to-previous-period-switch-colors"
                                        colorScheme="adminSwitch"
                                        helpText="By default, increases are shown in green (positive) and decreases in red (negative)"
                                        value={metric.switchPreviousPeriodColors}
                                        onChange={onChangeSwitchPreviousPeriodColors}
                                        labelVariant="paletteSectionLabel"
                                        size="md"
                                    />
                                </Flex>
                            </>
                        </>
                    )}
                </>
            )}
        </>
    )
}
