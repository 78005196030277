// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'

import { bulkUpdateFields, createField, deleteField, updateField } from 'data/hooks/fields'
import { useObjects } from 'data/hooks/objects'
import { createObject, deleteObject, updateObject } from 'data/hooks/objects/objectOperations'
import { createRecord, updateRecord } from 'data/hooks/records/recordOperations'

type Props = {
    objectId: string | null | undefined
    children: any
    loading?: JSX.Element | null | string
}

export const WithObject = ({ objectId, loading, children }: Props) => {
    const { data: objects } = useObjects()

    const object = useMemo(() => {
        return objects?.find((o) => o._sid === objectId)
    }, [objectId, objects])

    const createObjectField = useCallback(
        (data) => {
            if (object?._sid) createField({ ...data, object_id: object?._sid })
        },
        [object]
    )

    const createObjectRecord = useCallback(
        (data) => {
            if (object?._sid) createRecord({ ...data, object_id: object?._sid })
        },
        [object]
    )

    const changeObject = useCallback(
        (data) => {
            if (object?._sid) updateObject(object?._sid, data)
        },
        [object]
    )

    if (!objects) return loading ? loading : null
    if (!object) return loading ? loading : null

    return children({
        object: object || { fields: [] },
        primaryField: getPrimaryField(object),
        onChange: updateObject,
        createField: createObjectField,
        createRecord: createObjectRecord,
        updateRecord,
        createObject,
        changeObject,
        deleteField,
        changeField: updateField,
        bulkChangeFields: bulkUpdateFields,
        removeObject: deleteObject,
    })
}

export default WithObject

const getPrimaryField = (object) => {
    const primaries = object?.fields.filter((field) => field.is_primary)
    if (!primaries || !primaries.length) return { api_name: '_sid' }
    return primaries[0]
}

export const withObject =
    (Child: React.ComponentType<any>) =>
    ({ objectId, ...props }: { objectId: string; [prop: string]: any }) =>
        (
            <WithObject objectId={objectId}>
                {(recProps) => <Child {...recProps} {...props} />}
            </WithObject>
        )
