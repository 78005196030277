import React, { useState } from 'react'

import { useLinkCollection } from 'data/hooks/links'
import { withStack } from 'data/wrappers/WithStacks'
import { LinkListView } from 'features/links'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { UserProfileEditModalView } from './UserProfiles/UserProfileEditView'
import { UserProfileModalListView } from './UserProfiles/UserProfileListView'
import { UserProfileNewModalView } from './UserProfiles/UserProfileNewView'

function AppSettingsModalUserProfiles({
    children,
    stackOptions,
}: {
    children: React.ReactNode
    stackOptions: StackDto['options']
}): React.ReactElement {
    const { linksById, getLinks, actions } = useLinkCollection()
    const links = getLinks()
    const userObjectSid = stackOptions?.data_mapping?.user_object
    const userObjectEmailSid = stackOptions?.data_mapping?.user_email_field
    const userProfiles = links.filter((link) => link.source.objectId === userObjectSid)

    const [editingFieldId, setEditingFieldId] = useState('')
    const newModal = useModalToggle('new-modal')

    // Getters

    const getLink = (linkId: string) => linksById[linkId]

    // Handlers

    const handleCreateLinkSubmit = async (data: LinkCreateData) => {
        await actions.createLink(data)
        newModal.setIsOpen(false)
    }

    const handleUpdateLinkSubmit = async (data: LinkUpdateData) => {
        await actions.updateLink(editingFieldId, data)
        setEditingFieldId('')
    }

    const handleNewClick = () => {
        newModal.setIsOpen(true)
    }

    const handleEditClick = (link: LinkView) => {
        setEditingFieldId(link.linkId)
    }

    const handleModalClose = () => {
        setEditingFieldId('')
        newModal.setIsOpen(false)
    }

    return (
        <div style={{ display: 'flex', flex: 1, padding: 20, overflowY: 'auto', height: '100%' }}>
            <UserProfileModalListView userProfiles={userProfiles} onNewClicked={handleNewClick}>
                <LinkListView links={userProfiles} actions={actions} onEdit={handleEditClick} />
                {children}
            </UserProfileModalListView>
            <UserProfileNewModalView
                source={userObjectSid}
                sourceField={userObjectEmailSid}
                isOpen={newModal.isOpen}
                onClose={handleModalClose}
                onSubmit={handleCreateLinkSubmit}
            />
            <UserProfileEditModalView
                key={editingFieldId}
                source={userObjectSid}
                isOpen={!!editingFieldId}
                onClose={handleModalClose}
                link={getLink(editingFieldId)}
                onSubmit={handleUpdateLinkSubmit}
            />
        </div>
    )
}

export default withStack(AppSettingsModalUserProfiles)
