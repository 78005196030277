import React from 'react'

import { FlexProps } from '@chakra-ui/react'

import { useObjectCache } from 'data/hooks/cacheInfo'
import { useObjects } from 'data/hooks/objects'
import { authoriseFivetran } from 'features/integrations/fivetranUtils'

import { Button, Flex } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import DATA_PROVIDERS from '../../admin/data-connector/dataProviderConfig'

import { showSyncDataSourceSchemaModal } from './SyncDataSourceSchemaModal'

const colors = stackerTheme().colors

type SyncSchemaButtonsProps = FlexProps & {
    dataConnection: DataConnectionDto
    buttonSize?: string
    buttonStyle?: any
    showSchemaSync?: boolean
}

export const useSyncSchemaFivetranCopy = (
    dataConnection?: DataConnectionDto
): {
    buttonCopy: string | null
    emptyStateCopy: string | null
    isFivetranAuthorised: boolean
    isFivetranSyncing: boolean
} => {
    if (!dataConnection)
        return {
            buttonCopy: null,
            emptyStateCopy: null,
            isFivetranAuthorised: false,
            isFivetranSyncing: false,
        }

    const { type } = dataConnection
    const isFivetranAuthorised = dataConnection.options?.fivetran_connector_authorised || false
    const isFivetranSyncing = dataConnection.options?.fivetran_connector_synced === false

    if (type === 'fivetran' && isFivetranAuthorised) {
        return isFivetranSyncing
            ? {
                  buttonCopy: 'Syncing...',
                  emptyStateCopy: 'Your data is still syncing',
                  isFivetranAuthorised,
                  isFivetranSyncing,
              }
            : {
                  buttonCopy: 'Sync now',
                  emptyStateCopy: 'Sync your data to see new records ',
                  isFivetranAuthorised,
                  isFivetranSyncing,
              }
    }
    if (type === 'fivetran' && !isFivetranAuthorised) {
        return {
            buttonCopy: 'Continue',
            emptyStateCopy: 'Your data is not configured yet.',
            isFivetranAuthorised,
            isFivetranSyncing,
        }
    }
    return { buttonCopy: null, emptyStateCopy: null, isFivetranAuthorised, isFivetranSyncing }
}

const SyncSchemaButtons: React.FC<SyncSchemaButtonsProps> = ({
    dataConnection,
    buttonSize = 'sm',
    buttonStyle,
    showSchemaSync = true,
    ...props
}) => {
    const { data: objects }: { data: ObjectDto[] | undefined } = useObjects()
    const { _sid: sid, type } = dataConnection
    const { objectStatuses, startCacheFill } = useObjectCache()
    const { allowSchemaSync, allowDataSync: dataSync } = DATA_PROVIDERS[type] || {}
    const { isFivetranAuthorised, isFivetranSyncing, buttonCopy } =
        useSyncSchemaFivetranCopy(dataConnection)

    const objectsForThisDC = objects?.filter((o) => o.data_connection == sid)
    const objectsFilling = objectsForThisDC?.some(
        (o) => objectStatuses?.[o._sid]?.fill_pending && objectStatuses?.[o._sid]?.enabled
    )

    const shouldDisableSyncButtons =
        dataConnection.status !== 'valid' &&
        dataConnection.type === 'airtable' &&
        dataConnection.version === 2

    return (
        <Flex flexWrap="nowrap" {...props}>
            {type === 'fivetran' && isFivetranAuthorised && (
                <Button
                    // temporarily disable manual sync while fivetran is known to be broken
                    disabled={true}
                    width="100%"
                    flexShrink={0}
                    variant="Secondary"
                    buttonSize={buttonSize}
                    ml={2}
                    icon="refresh"
                    iconProps={isFivetranSyncing ? { className: 'spin' } : null}
                    iconColor={colors.userInterface.accent[1000]}
                    onClick={() => {
                        showSyncDataSourceSchemaModal(dataConnection._sid)
                    }}
                    {...buttonStyle}
                >
                    {buttonCopy}
                </Button>
            )}
            {type === 'fivetran' && !isFivetranAuthorised && (
                <Button
                    // temporarily disable manual sync while fivetran is known to be broken
                    disabled={true}
                    flexShrink={0}
                    variant="Secondary"
                    buttonSize={buttonSize}
                    ml={2}
                    onClick={() => {
                        authoriseFivetran(dataConnection)
                    }}
                    {...buttonStyle}
                >
                    {buttonCopy}
                </Button>
            )}
            {allowSchemaSync && type !== 'fivetran' && showSchemaSync && (
                <Button
                    flexShrink={0}
                    variant="Secondary"
                    buttonSize={buttonSize}
                    ml={2}
                    icon="refresh"
                    iconColor={colors.userInterface.accent[1000]}
                    onClick={() => {
                        showSyncDataSourceSchemaModal(dataConnection._sid)
                    }}
                    isDisabled={shouldDisableSyncButtons}
                    {...buttonStyle}
                >
                    Schema
                </Button>
            )}

            {dataSync && type !== 'fivetran' && (
                <Button
                    flexShrink={0}
                    variant="Secondary"
                    buttonSize={buttonSize}
                    ml={2}
                    icon="refresh"
                    iconProps={objectsFilling ? { className: 'spin' } : null}
                    iconColor={colors.userInterface.accent[1000]}
                    isDisabled={shouldDisableSyncButtons || objectsFilling}
                    onClick={() => {
                        startCacheFill(sid)
                    }}
                    {...buttonStyle}
                >
                    Data
                </Button>
            )}
        </Flex>
    )
}

export default SyncSchemaButtons
