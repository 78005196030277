import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import store from 'app/store'

export const isAdmin = () => {
    const state = store.getState()
    const user = get(state, 'user.user') || {}
    const studioUser = get(state, 'user.studioUser') || {}
    const isImpersonating = !isEqual(studioUser._sid, user._sid)
    return studioUser && !isImpersonating
}

export const isImpersonating = () => {
    const state = store.getState()
    const user = get(state, 'user.user') || {}
    const studioUser = get(state, 'user.studioUser') || {}
    const isImpersonating = !isEqual(studioUser._sid, user._sid)
    return studioUser && isImpersonating
}

// Returns whether or not we're impersonating an "end user"
// as opposed to another Stacker user.
export const isImpersonatingEndUser = () => {
    const state = store.getState()
    const user = get(state, 'user.user') || {}
    const studioUser = get(state, 'user.studioUser') || {}
    const isImpersonating = !isEqual(studioUser._sid, user._sid)
    return studioUser && isImpersonating && user?._object_id
}

export default isAdmin
