/* Code Quality: Not audited */

import React from 'react'
import { default as ReactModal } from 'react-modal'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Button from './Button'

// This is recommended by the docs of react-modal to help
// screenreaders around whether the modal is visible or not
// but it breaks in tests and storybooks where there is no dom
// around the component
if (document.getElementById('app')) {
    ReactModal.setAppElement('#app')
}

class Modal extends React.Component {
    render() {
        return (
            <ReactModal
                {...this.props}
                appElement={document.body}
                style={{
                    content: {
                        background: '#012',
                        ...this.props.style,
                    },
                    overlay: {
                        background: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 2,
                        ...this.props.overlayStyle,
                    },
                }}
            >
                {this.props.children}
                {this.showClose && (
                    <div>
                        <Button onClick={this.props.onRequestClose}>Close</Button>
                    </div>
                )}
            </ReactModal>
        )
    }
}
export default withTheme(Modal)
