import React, { FC, MouseEvent, useContext, useEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import ChartsBlock from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock'
import { ChartsBlockEmptyState } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/ChartsBlockEmptyState'
import { CHART_BLOCK_HEIGHT } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/constants'
import MetricsBlock from 'v2/blocks/blockTypes/view/aggregationBlocks/MetricsBlock'
import { METRIC_BLOCK_HEIGHT } from 'v2/blocks/blockTypes/view/aggregationBlocks/MetricsBlock/constants'
import { MetricsBlockEmptyState } from 'v2/blocks/blockTypes/view/aggregationBlocks/MetricsBlock/MetricsBlockEmptyState'
import { ViewLayoutContext } from 'v2/blocks/types'

import useEditMode from 'features/admin/edit-mode/useEditMode'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'

import { Button, ConditionalWrapper, Flex, Heading, MarkdownText, Text } from 'v2/ui'
import { SolidChevronDown } from 'v2/ui/svgs'

import { COLLAPSIBLE_CHARTS_EDITOR_ID, COLLAPSIBLE_METRICS_EDITOR_ID } from './constants'
import { EditWrapper } from './EditWrapper'
import { SelectWidgetArea } from './SelectWidgetArea'

const TOGGLE_BUTTON_SIZE = 24
const TOGGLE_ANIMATION_DURATION = 250
const EMPTY_HEIGHT = 100
const EMPTY_STATE_HEIGHT = 230

function getHeaderHeight(
    isEditing: boolean,
    isOpen: boolean,
    isEmpty: boolean,
    blockHeight: number,
    numberOfRows: number
) {
    if (!isOpen) {
        return (TOGGLE_BUTTON_SIZE * 2) / 3
    }

    if (isEmpty) {
        return EMPTY_HEIGHT
    }

    return (blockHeight + 12) * numberOfRows + TOGGLE_BUTTON_SIZE + 4 + (isEditing ? 44 : 0)
}

const Wrapper = styled.div<{
    isEditing: boolean
    isOpen: boolean
    isEmpty: boolean
    blockHeight: number
    numberOfWidgets: number
}>`
    background-color: ${({ theme }) => theme.colors.userInterface.neutral[0]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.userInterface.neutral[200]};

    overflow: hidden;
    transition: height ${TOGGLE_ANIMATION_DURATION}ms ease-in-out;

    height: ${({ isOpen, isEditing, isEmpty, blockHeight }) =>
        getHeaderHeight(isEditing, isOpen, isEmpty, blockHeight, 1)}px;

    @media (max-width: ${({ isEditing }) => (isEditing ? 1350 : 1100)}px) {
        height: ${({ isOpen, isEditing, isEmpty, numberOfWidgets, blockHeight }) =>
            getHeaderHeight(
                isEditing,
                isOpen,
                isEmpty,
                blockHeight,
                Math.ceil(numberOfWidgets / 2)
            )}px;
    }

    @media (max-width: ${({ isEditing }) => (isEditing ? 1100 : 800)}px) {
        height: ${({ isOpen, isEditing, isEmpty, numberOfWidgets, blockHeight }) =>
            getHeaderHeight(isEditing, isOpen, isEmpty, blockHeight, numberOfWidgets)}px;
    }
`

const ToggleButton = styled(Button)<{ isOpen: boolean }>`
    cursor: pointer;

    position: absolute;
    bottom: -${TOGGLE_BUTTON_SIZE / 2}px;
    left: calc(50% - ${TOGGLE_BUTTON_SIZE / 2}px);

    z-index: 9;

    width: ${TOGGLE_BUTTON_SIZE}px;
    height: ${TOGGLE_BUTTON_SIZE}px;

    padding: 0;

    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.userInterface.neutral[300]};
    box-shadow: 0px 1px 0px 0px rgba(89, 94, 106, 0.1);

    transform: rotateZ(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
    transition: transform ${TOGGLE_ANIMATION_DURATION}ms ease-in-out;

    background-color: ${({ theme }) => theme.colors.userInterface.neutral[0]};

    &:hover {
        background-color: ${({ theme }) => theme.colors.userInterface.neutral[0]};
    }
`

const StyledChevron = styled(SolidChevronDown)`
    fill: ${(props) => props.theme.colors.userInterface.neutral[1000]};
`

type Props = {
    viewLayoutContext: ViewLayoutContext
    headerOptions: PageHeaderModular
    onChange: (patch: Partial<ListViewOptions>) => void
}

export const ViewHeaderModular: FC<Props> = ({ viewLayoutContext, headerOptions, onChange }) => {
    const { collapsibleWidgets, collapsibleWidgetsType, title, subtitle } = headerOptions

    const [isOpen, setIsOpen] = useState(false)

    const { isOpen: isEditModeOpen } = useEditMode()
    const layoutEditorContext = useContext(LayoutEditorContext)

    const isEditingModularHeader =
        layoutEditorContext.activeEditor?.id === COLLAPSIBLE_CHARTS_EDITOR_ID ||
        layoutEditorContext.activeEditor?.id === COLLAPSIBLE_METRICS_EDITOR_ID

    const isHeaderEmpty = useMemo(() => {
        const hasCollapsibleWidgets =
            collapsibleWidgetsType && collapsibleWidgets && collapsibleWidgets.length > 0

        return !hasCollapsibleWidgets && !isEditingModularHeader
    }, [collapsibleWidgets, collapsibleWidgetsType, isEditingModularHeader])

    const showEditWrapper = useMemo(() => {
        if (!isOpen || !isEditModeOpen) {
            return false
        }

        return !isHeaderEmpty || isEditingModularHeader
    }, [isEditingModularHeader, isEditModeOpen, isHeaderEmpty, isOpen])

    const blockHeight = useMemo(() => {
        if (
            collapsibleWidgetsType === 'charts' ||
            layoutEditorContext.activeEditor?.id === COLLAPSIBLE_CHARTS_EDITOR_ID
        ) {
            return collapsibleWidgets.length === 0 ? EMPTY_STATE_HEIGHT : CHART_BLOCK_HEIGHT
        }

        return collapsibleWidgets.length === 0 ? EMPTY_STATE_HEIGHT : METRIC_BLOCK_HEIGHT
    }, [collapsibleWidgets.length, collapsibleWidgetsType, layoutEditorContext.activeEditor?.id])

    const onOpenMetricsEditor = () => {
        layoutEditorContext.setActiveEditor({
            id: COLLAPSIBLE_METRICS_EDITOR_ID,
            title: 'Big numbers',
        })
    }

    const onOpenChartsEditor = () => {
        layoutEditorContext.setActiveEditor({
            id: COLLAPSIBLE_CHARTS_EDITOR_ID,
            title: 'Charts',
        })
    }

    const onOpenEditor = () => {
        if (!isEditModeOpen || isHeaderEmpty) {
            return
        }

        if (
            collapsibleWidgetsType === 'metrics' ||
            layoutEditorContext.activeEditor?.id === COLLAPSIBLE_METRICS_EDITOR_ID
        ) {
            onOpenMetricsEditor()
            return
        }

        onOpenChartsEditor()
    }

    const onDeleteCollapsibleWidgets = (event: MouseEvent) => {
        onChange({ collapsibleWidgets: undefined, collapsibleWidgetsType: undefined })
        layoutEditorContext.closeEditor()
        event.stopPropagation()
    }

    useEffect(() => {
        if (!isEditModeOpen) {
            return
        }

        setIsOpen(true)
    }, [isEditModeOpen])

    if (
        !isEditModeOpen &&
        collapsibleWidgets.length === 0 &&
        (!title || title.length === 0) &&
        (!subtitle || Object.keys(subtitle).length === 0)
    ) {
        return null
    }

    return (
        <>
            <Flex column maxWidth="100%" p={5} pb={0} align="left" bg="userInterface.neutral.0">
                {title && (
                    <Heading
                        as="h1"
                        fontSize={[500, null, null, 500]}
                        value={title}
                        my={0}
                        maxWidth="100%"
                    />
                )}
                {subtitle && (
                    <Text fontSize="300" mt={2}>
                        <MarkdownText showRichTextEditor={typeof subtitle === 'object'}>
                            {subtitle}
                        </MarkdownText>
                    </Text>
                )}
            </Flex>
            <Wrapper
                isEditing={isEditModeOpen}
                isOpen={isOpen}
                isEmpty={isHeaderEmpty}
                numberOfWidgets={Math.max(collapsibleWidgets.length, 1)}
                blockHeight={blockHeight}
            >
                <Flex
                    position="relative"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    backgroundColor="userInterface.neutral.0"
                    paddingLeft={5}
                    paddingRight={5}
                    mt={5}
                    onClick={onOpenEditor}
                >
                    <ConditionalWrapper
                        condition={showEditWrapper}
                        wrapper={(children) => (
                            <EditWrapper onDelete={onDeleteCollapsibleWidgets}>
                                {children}
                            </EditWrapper>
                        )}
                    >
                        {isHeaderEmpty &&
                            (!layoutEditorContext.activeEditor ||
                                layoutEditorContext.activeEditor.title === 'Header') && (
                                <SelectWidgetArea
                                    onAddMetrics={onOpenMetricsEditor}
                                    onAddCharts={onOpenChartsEditor}
                                />
                            )}
                        {!isHeaderEmpty &&
                            layoutEditorContext.activeEditor?.id === COLLAPSIBLE_CHARTS_EDITOR_ID &&
                            collapsibleWidgets.length === 0 && <ChartsBlockEmptyState />}
                        {!isHeaderEmpty &&
                            layoutEditorContext.activeEditor?.id ===
                                COLLAPSIBLE_METRICS_EDITOR_ID &&
                            collapsibleWidgets.length === 0 && <MetricsBlockEmptyState />}
                        {!isHeaderEmpty && (
                            <>
                                {collapsibleWidgetsType === 'metrics' && (
                                    <MetricsBlock
                                        attrs={{ metrics: collapsibleWidgets }}
                                        context={viewLayoutContext}
                                        isOnListView
                                    />
                                )}
                                {collapsibleWidgetsType === 'charts' && (
                                    <ChartsBlock
                                        attrs={{ metrics: collapsibleWidgets }}
                                        context={viewLayoutContext}
                                        isOnListView
                                    />
                                )}
                            </>
                        )}
                    </ConditionalWrapper>
                </Flex>
            </Wrapper>
            <ToggleButton
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((prevIsOpen) => !prevIsOpen)
                }}
            >
                <StyledChevron />
            </ToggleButton>
        </>
    )
}
