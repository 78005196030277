import { useMutation, UseMutationOptions } from 'react-query'

import { invalidateNavigation } from 'data/hooks/navigation'
import { useCreateView } from 'data/hooks/views'
import { assertIsDefined } from 'data/utils/ts_utils'
import { makeBlankPageDummyBlocks } from 'features/blank-pages/dummyContent'

export type CreateBlankPageDto = {
    name: string
    viewOptions?: BlankPageViewOptions
}

export type CreateBlankPageResponse = {
    view: ViewDto
}

export function useCreateBlankPage(
    options?: UseMutationOptions<CreateBlankPageResponse, unknown, CreateBlankPageDto, unknown>
) {
    const { mutateAsync: createView } = useCreateView()

    return useMutation(async ({ name, viewOptions }: CreateBlankPageDto) => {
        const view = (await createView({
            name,
            label: name,
            type: 'blankpage',
            options: {
                title: name,
                menu_label: name,
                blocks: makeBlankPageDummyBlocks(),
                pageHeader: {
                    title: name,
                    type: 'default',
                },
                ...viewOptions,
            },
        })) as ViewDto
        assertIsDefined(view)

        await invalidateNavigation()

        return {
            view,
        }
    }, options)
}
