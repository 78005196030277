import React from 'react'

import Frame from 'features/core/Frame'

const AdminFrame = ({ children, ...props }) => {
    return (
        <>
            <Frame {...props}>{children}</Frame>
        </>
    )
}

export default AdminFrame
