import React, { FC, useMemo, useState } from 'react'

import styled from '@emotion/styled'

import { queryClient } from 'data/hooks/_helpers'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { REACT_QUERY } from 'data/utils/constants'
import useTrack from 'utils/useTrack'

import { importUrl } from '../../data/api/bundlesApi'
import { invalidateFeatures } from '../../data/hooks/features'
import { invalidateNavigation } from '../../data/hooks/navigation'
import { invalidatePages } from '../../data/hooks/pages'
import { Template } from '../../data/hooks/templates'
import { invalidateViews } from '../../data/hooks/views'
import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { Button, Flex, MarkdownText, Text } from '../../v2/ui'
import SimpleGallery from '../galleries/SimpleGallery'

import TemplateIcon from './TemplateIcon'

type TemplateDetailProps = {
    accountId: string
    template: Template
    onClose: () => void
    handleInstallTemplate?: (template: Template) => Promise<StackDto>
    onInstallComplete: (stack: StackDto) => void
}

const TemplateDetail: FC<TemplateDetailProps> = ({
    accountId,
    template,
    onClose,
    onInstallComplete,
    handleInstallTemplate,
}) => {
    const [isInstalling, setIsInstalling] = useState(false)
    const { track } = useTrack()

    const galleryImageUrls = useMemo(
        () => template?.gallery_images?.split(',').map((image) => image.trim()) ?? [],
        [template]
    )

    const installTemplate = async () => {
        track('WIP - Frontend - Onboarding - Templates - Install - Clicked', {
            template_name: template?.name,
        })

        setIsInstalling(true)

        const stack = handleInstallTemplate
            ? ((await handleInstallTemplate(template)) as StackDto)
            : ((await importUrl(template.bundle_url, accountId)) as StackDto)

        await Promise.all([
            queryClient.invalidateQueries(REACT_QUERY.roles.listName),
            refetchStacks(),
            invalidateViews(),
            invalidatePages(),
            invalidateNavigation(),
            invalidateFeatures(),
        ])
        setIsInstalling(false)
        onInstallComplete(stack)
    }

    return (
        <Flex column align="center" mb={4}>
            <Flex
                column
                align="left"
                style={{
                    padding: '10px',
                    width: '100%',
                    maxWidth: '940px',
                }}
            >
                <Flex mb={4}>
                    <Button variant="adminBackButton" onClick={onClose}>
                        Back
                    </Button>
                </Flex>
                <Flex justify="space-between">
                    <Flex>
                        <TemplateIcon
                            icon={template.icon || 'faClouds'}
                            color={template.icon_color ?? V4DesignSystem.colors.stacker}
                            fullSize
                        />
                        <Flex column alignItems="start">
                            <Text variant="templateCardTitle">{template.name}</Text>
                            {!template.is_published && (
                                <UnpublishedButton>Under development</UnpublishedButton>
                            )}
                        </Flex>
                    </Flex>
                    <Button
                        disabled={isInstalling}
                        variant="adminPrimaryV4"
                        icon={isInstalling ? 'refresh' : 'add'}
                        size="sm"
                        onClick={installTemplate}
                    >
                        {isInstalling ? 'Setting up' : 'Use Template'}
                    </Button>
                </Flex>
                {template.detail_description && (
                    <>
                        <Text mt="16px" variant="appSettingsBody">
                            <MarkdownText>{template.detail_description}</MarkdownText>
                        </Text>
                    </>
                )}
                {galleryImageUrls && galleryImageUrls.length ? (
                    <SimpleGallery images={galleryImageUrls} />
                ) : null}
            </Flex>
        </Flex>
    )
}

const UnpublishedButton = styled.div`
    background: #d00000aa;
    padding: 1px 2px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    color: white;
    line-height: 13px;
    font-family: 'Inter UI';
    letter-spacing: 0.6px;
    border-radius: 3px;
`

export default TemplateDetail
