import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidHomeProps = {
    color?: string
    size?: number
}
const SolidHome: React.FC<SolidHomeProps> = ({
    color = colors.userInterface.neutral[1100],
    size = 14,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                d="M7.438.265a1.12 1.12 0 0 0-1.447 0L.395 5.013A1.12 1.12 0 0 0 0 5.865v7.015A1.119 1.119 0 0 0 1.119 14h3.544a.56.56 0 0 0 .56-.56V8.779h2.983v4.662c0 .31.251.56.56.56h3.544a1.119 1.119 0 0 0 1.119-1.12V5.867a1.12 1.12 0 0 0-.396-.853L7.438.264z"
                fill={color}
            />
        </svg>
    )
}

export default SolidHome
