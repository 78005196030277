import { useMemo } from 'react'

import { getCreateViewUrl } from 'v2/views/utils/getCreateViewUrl'

import { useAppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import { useFeatures } from 'data/hooks/features'
import { usePages } from 'data/hooks/pages'
import { useStackViews } from 'data/hooks/views'

export const useInternalLink = (viewId: string | undefined) => {
    const { selectedStack, workspaceAccount } = useAppContext()

    const views = useStackViews() as ViewDto[]
    const { data: pages } = usePages() as { data: PageDto[] }
    const { data: features } = useFeatures() as { data: FeatureDto[] }

    const view = useMemo(() => views.find((view) => view._sid === viewId), [viewId, views])
    return useMemo(() => {
        if (!view) {
            return null
        }
        if (view.type === 'create') {
            const viewFeature = features?.find((feature) => feature._sid === view.feature_id)
            return getCreateViewUrl(
                view,
                pages,
                viewFeature,
                undefined,
                selectedStack,
                workspaceAccount
            )
        } else {
            return getUrl(view.url, selectedStack, workspaceAccount)
        }
    }, [view, selectedStack, features, pages, workspaceAccount])
}
