import React from 'react'

import Box from './Box'

const PADDING = ['container.padding', null, null, 'container.paddingLg']
const ContainerPadding = ({ children, ...props }) => (
    <Box p={PADDING} {...props}>
        {children}
    </Box>
)

export default React.memo(ContainerPadding)
