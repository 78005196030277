import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export type DataConnectionTestBody = {
    type: 'postgresV2'
    data_connection_sid?: string
    updated_secret_options: Partial<PostgresConnectionDetails> & {
        sql_password?: string
    }
}

export const testConnection = async (
    data: DataConnectionTestBody
): Promise<Record<string, any>> => {
    const response = await fetchWithAuth('data-connections/test/', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    return response.json()
}
