import React, { useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import moment from 'moment'

import { EstimateData, getPlanUpgradeEstimate } from 'data/api/subscriptionApi'

import { Banner, Divider, Flex, Text } from 'v2/ui'
import { CompatProps, SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

type PlanPrice = {
    price_id: string
    base_price: string
    included_users: number
    extra_user_price: string
    current_plan: boolean
}

type PlanUpgradeModalProps = CompatProps & {
    priceId?: string
    userCount?: number
    tier?: string
    price?: PlanPrice
    billingPeriod?: string
}

const PlanUpgradeModal: React.FC<PlanUpgradeModalProps> = ({
    userCount,
    tier,
    price,
    isOpen,
    billingPeriod,
    ...otherProps
}) => {
    const [estimateData, setEstimateData] = useState<EstimateData | null>(null)
    useEffect(() => {
        isOpen &&
            !!price?.price_id &&
            getPlanUpgradeEstimate(price?.price_id).then((data) => setEstimateData(data))
    }, [isOpen, price?.price_id])

    let billingDate
    if (estimateData) {
        const date = new Date(estimateData?.billing_period_end_date * 1000)
        billingDate = moment(date).format('DD MMM YYYY')
    }

    return (
        <SimpleModalCompat
            size="580px"
            height="522px"
            isSecondLayer
            data-testid="plan-upgrade-modal"
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            hideTitleBottomBorder
            {...otherProps}
        >
            {!estimateData && (
                <Flex width="100%" height="100%" justify="center">
                    <Spinner size="md" />
                </Flex>
            )}
            {estimateData && (
                <Flex>
                    <Banner
                        variant="Information"
                        icon="rocket"
                        width="100%"
                        style={{ padding: '12px ' }}
                    >
                        <Text fontSize={200} lineHeight={1.4}>
                            {tier} plan
                        </Text>
                        {price && (
                            <>
                                <Text
                                    fontSize={100}
                                    lineHeight={1.4}
                                    color={colors.userInterface.neutral[1000]}
                                    fontWeight={400}
                                    data-testid="base-price"
                                >
                                    Starting at{' '}
                                    <span
                                        style={{ fontWeight: 700 }}
                                    >{`${price?.base_price}/month`}</span>
                                </Text>

                                <Text
                                    fontSize={100}
                                    lineHeight={1.4}
                                    color={colors.userInterface.neutral[1000]}
                                    fontWeight={400}
                                >
                                    Number of collaborator seats included in the base price:{' '}
                                    <span
                                        style={{ fontWeight: 700 }}
                                    >{`${price?.included_users}`}</span>
                                </Text>
                                <Text
                                    fontSize={100}
                                    lineHeight={1.4}
                                    color={colors.userInterface.neutral[1000]}
                                    fontWeight={400}
                                >
                                    Price per extra collaborator seat:{' '}
                                    <span
                                        style={{ fontWeight: 700 }}
                                    >{`${price?.extra_user_price}`}</span>
                                </Text>
                            </>
                        )}
                    </Banner>
                    <Flex width="100%" justify="space-between" mt={6}>
                        <Text
                            fontSize={400}
                            fontWeight={600}
                            color={colors.userInterface.neutral[1000]}
                        >
                            Total number of collaborator seats
                        </Text>
                        <Text
                            fontSize={200}
                            fontWeight={600}
                            color={colors.userInterface.neutral[1000]}
                        >
                            {userCount}
                        </Text>
                    </Flex>
                    <Flex width="100%" justify="space-between" mt={4}>
                        <Text
                            fontSize={300}
                            fontWeight={600}
                            color={colors.userInterface.neutral[1000]}
                        >
                            Subtotal
                        </Text>
                        <Text
                            fontSize={300}
                            fontWeight={600}
                            color={colors.userInterface.neutral[1000]}
                            data-testid="subtotal"
                        >
                            {estimateData?.subtotal_amount}
                        </Text>
                    </Flex>
                    <Divider width="100%" mt={4} />

                    {/* Commented the promo code until next slice */}
                    {/* <Tag className="tag">
                    <Icon
                        width="10px"
                        height="7.5px"
                        m="0 8px 0 0"
                        icon="faTicket"
                        color={colors.userInterface.accent[800]}
                    />
                    <Text fontSize="12px" color={colors.userInterface.accent[800]}>
                        Apply promo code
                    </Text>
                </Tag> */}
                    <Flex width="100%" justify="space-between" mt={4}>
                        <Text
                            fontSize={200}
                            fontWeight={600}
                            color={colors.userInterface.neutral[800]}
                        >
                            VAT
                        </Text>
                        <Text
                            fontSize={200}
                            color={colors.userInterface.neutral[800]}
                            data-testid="tax-amount"
                        >
                            {estimateData?.tax_amount}
                        </Text>
                    </Flex>
                    <Divider width="100%" my={4} />
                    <Flex width="100%" justify="space-between" mt={6}>
                        <Text
                            fontSize={400}
                            fontWeight={600}
                            color={colors.userInterface.neutral[1000]}
                        >
                            New plan total
                        </Text>
                        <Text
                            fontSize={400}
                            fontWeight={600}
                            color={colors.userInterface.accent[800]}
                            data-testid="total-amount"
                        >
                            {estimateData?.total_amount}/{billingPeriod}
                        </Text>
                    </Flex>
                    {billingDate && (
                        <Flex width="100%" justify="space-between" mt={2}>
                            <Text
                                fontSize={200}
                                fontWeight={400}
                                color={colors.userInterface.neutral[1000]}
                            >
                                Next Billing Date
                            </Text>
                            <Text
                                fontSize={200}
                                fontWeight={400}
                                color={colors.userInterface.neutral[1000]}
                                data-testid="total-amount"
                            >
                                {billingDate}
                            </Text>
                        </Flex>
                    )}
                    <Flex width="100%" justify="space-between" mt={4}>
                        <Text
                            fontSize={100}
                            fontWeight={200}
                            color={colors.userInterface.neutral[850]}
                        >
                            Any remaining balance paid towards your current plan will be deducted
                            against your new plan on your next billing date. An invoice will be
                            generated and you will be able to access it via the billing settings
                            tab.
                        </Text>
                    </Flex>
                </Flex>
            )}
        </SimpleModalCompat>
    )
}

export default PlanUpgradeModal
