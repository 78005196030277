import React from 'react'

import { Input } from 'v2/ui'

export const LinkFormFieldNameInput = ({ name, value, onChange, ...inputProps }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
                <Input
                    {...inputProps}
                    value={value}
                    onChange={onChange}
                    name={name}
                    variant="settings"
                    placeholder="enter a name for this field"
                />
            </div>
        </div>
    )
}
