// @ts-strict-ignore
import React, { useState } from 'react'

import * as Sentry from '@sentry/react'

import HELP_LINKS from 'app/helpLinks'
import { useObject } from 'data/hooks/objects'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Alert, Banner, Box, Button, Divider, FeatureSplashScreen, Flex } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import ProtectedFeature, { FEATURES } from 'v2/ui/utils/ProtectedFeature'

import analytics from '../../../utils/analytics'
import { PermissionTable, PermissionTableRows } from '../Permissions/PermissionTable'

const learnMore = (
    <Button variant="adminSecondary" buttonSize="sm" href={HELP_LINKS.PERMISSIONS}>
        Learn more
    </Button>
)

const AppSettingsModalDataPermissions = ({ stack, stackId, objectId, feature, noPadding }) => {
    const { object, changeObject } = useObject(objectId)
    const [errorTogglingPermissions, setErrorTogglingPermissions] = useState(null)

    if (!object) return null

    const togglePermissions = () => {
        return changeObject({
            options: {
                ...object.options,
                permissions__use_permissions: !object.options?.permissions__use_permissions,
            },
        }).then(
            () => {
                if (object?.options?.permissions__use_permissions) {
                    analytics.track('table permissions disabled')
                } else {
                    analytics.track('table permissions enabled')
                }
            },
            (e) => {
                Sentry.captureMessage(
                    `Error while toggling 'permissions__use_permissions'. Error message: ${e.message}`
                )
                setErrorTogglingPermissions(e)
            }
        )
    }

    // Get list of roles WITH all data access. For now we are not showing the corresponding banner
    // because that plus the "You can now edit permissions for your whole app in one place" one
    // would be too much noise, but once people are used to that feature we can reinstate this

    // const { data: allRoles } = useRoles()
    // const rolesWithAllAccess = allRoles.filter((role) => {
    //     return (
    //         role.stack_id === stackId &&
    //         role.options?.rights?.find((x) => x === Rights.AllDataAccess)
    //     )
    // })

    const enabled = object?.options?.permissions__use_permissions

    if (!enabled) {
        return (
            <>
                <FeatureSplashScreen
                    icon="userLock"
                    title="Set permissions to restrict access"
                    subtitle={
                        <>
                            If you want to specify what all or some users can access, you can do so
                            by creating and modifying permission rules.
                            {errorTogglingPermissions && (
                                <Alert status="error" description="An unknown error occured." />
                            )}
                        </>
                    }
                    actions={[
                        { content: 'Enable permissions', onClick: togglePermissions },
                        { content: 'Learn more', href: HELP_LINKS.PERMISSIONS },
                    ]}
                    classes={ONBOARDING_CLASSES.APP_SETTINGS_ENABLE_PERMISSIONS}
                />
            </>
        )
    }

    return (
        <Box width="100%">
            <Flex
                p={!noPadding ? '20px' : undefined}
                flexDirection="column"
                alignItems="stretch"
                minHeight="100%"
                className={ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_LIST}
            >
                <FormHeader
                    title="Permissions"
                    subtitle="Choose what kind of data access you want to give your users"
                    rightSideContent={learnMore}
                />

                <Divider my={1} />

                {/* <Banner icon="info">
                    {rolesWithAllAccess.map((role, idx) => (
                        <>
                            {idx > 0 && idx < rolesWithAllAccess.length - 1 && ', '}
                            {idx > 0 && idx === rolesWithAllAccess.length - 1 && ' and '}
                            <strong>{role.label}</strong>
                        </>
                    ))}{' '}
                    users can view and modify all app data regardless of permission settings
                </Banner> */}

                <PermissionTable includeTableName={false}>
                    <PermissionTableRows
                        stack={stack}
                        stackId={stackId}
                        objectId={objectId}
                        feature={feature}
                    />
                </PermissionTable>

                <ProtectedFeature
                    feature={FEATURES.multiplePermissionRules}
                    hideIfAvailable={true}
                    mt="auto"
                    style={{ cursor: 'pointer' }}
                >
                    <Banner width="100%" mt={2} variant="InformationSecondary">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button marginRight="10px" variant="upgradePrimary" size="small">
                                Pro
                            </Button>
                            Upgrade to a Pro plan or higher to add multiple permission rules per
                            table.
                        </div>
                    </Banner>
                </ProtectedFeature>
            </Flex>
        </Box>
    )
}

export default AppSettingsModalDataPermissions
