import React from 'react'

import { Collapse, Flex, Text } from 'v2/ui'

import { FormField, FormFieldProps } from '../../../ui/forms/FormField'
type ConfigurationFieldProps = FormFieldProps & { show?: boolean; label?: React.ReactNode }
export const ConfigurationField: React.VFC<ConfigurationFieldProps> = ({
    show = true,
    label,
    required = true,
    ...formFieldProps
}) => {
    return (
        <Collapse isOpen={show}>
            <Flex column wrap="nowrap" align="stretch">
                {label && (
                    <Text mb={2} mt={3}>
                        {label}
                    </Text>
                )}
                <FormField variant="settings" required={required} {...formFieldProps} />
            </Flex>
        </Collapse>
    )
}
