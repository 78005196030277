import { getUrl, Urls } from 'app/UrlService'
import { AirtableConnectionParams } from 'features/AppSettings/DataSources/Airtable/utils/useAirtableConnectionParams'

export function getReturnUrlForOAuthFlow({
    dataConnection,
    mode,
    stack,
}: {
    dataConnection: DataConnectionDto
    mode: 'upgrade' | 'repair'
    stack: StackDto
}): string {
    const currentUrl = new URL(window.location.href)
    const adminUrl = stack.options?.enable_new_app_settings ? Urls.AdminModal : Urls.Admin

    const delimiter = adminUrl.includes('?') ? '&' : '?'

    let origin = window.location.origin

    // build params for the redirect url
    const params = new URLSearchParams()
    if (mode === 'upgrade') {
        params.append(AirtableConnectionParams.Upgrading, '1')
    } else if (mode === 'repair') {
        params.append(AirtableConnectionParams.Repairing, '1')
    }
    params.append(AirtableConnectionParams.DataConnection, dataConnection._sid)

    // forward existing url params to the redirect url
    if (currentUrl.search) {
        currentUrl.searchParams.forEach((value, key) => {
            if (!Object.values<string>(AirtableConnectionParams).includes(key))
                params.append(key, value)
        })
    }

    // Redirect to the OAuth flow, which will redirect back to us when finished.
    return encodeURIComponent(
        `${origin}${getUrl(adminUrl, undefined)}${delimiter}${params.toString()}`
    )
}
