// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import { Rights, useAppUserContext } from 'app/AppUserContext'
import { useConfirmModalWithPromise } from 'app/ConfirmModal'
import { useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin } from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import { Header } from 'features/workspace/WorkspaceSettingsModalUi'

import { Flex, Icon, Text } from 'v2/ui'
import SimpleModal from 'v2/ui/components/SimpleModal'
import { useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

import AddUserDropdown from './AddUserDropdown'
import AdministratorsUserList from './AdministratorsUserList'

const MODAL_KEY = 'AppAdministrators'
export const useAdministratorsModal = () => {
    return useModalDeclaration(MODAL_KEY, EditAdministratorsModal)
}

export default function EditAdministratorsModal() {
    const { isOpen, setIsOpen } = useModalToggle(MODAL_KEY)
    const { selectedStack: stack } = useAppContext()
    const { hasRight, role, user } = useAppUserContext()
    const formRef = useRef({})
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const showConfirmRemoveCurrentUser = useConfirmModalWithPromise({
        title: 'Remove your administrator access?',
        message: 'You will no longer be able to configure this app.',
    })
    const { data: appUsers = [] } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: true,
    })

    const onClose = () => {
        saveChanges(() => setIsOpen(false))
    }

    const updateStackSharingSettings = useUpdateStackSharingSettings()

    const handleSave = (patch) => {
        assertIsDefined(stack)
        assertIsDefined(user)
        const admins = adminUsers.filter((user) => !patch[user._sid] || !patch[user._sid].deleted)

        if (admins.length === 0) {
            throw new Error('There must be at least one admin user.')
        }

        // If user is removing herself, prompt for confirmation
        if (patch[user._sid]?.deleted) {
            return showConfirmRemoveCurrentUser().then(() => {
                return updateStackSharingSettings(stack, patch).then(refetchAppUsersForAdmin)
            })
        }

        return updateStackSharingSettings(stack, patch).then(refetchAppUsersForAdmin)
    }

    // if the user's role changes and they are no longer an administrator, close the modal.
    useEffect(() => {
        if (!hasRight(Rights.Admin.Any)) {
            setIsOpen(false)
        }
    }, [role, hasRight, setIsOpen])

    const unsavedChangesModal = displayChangesModal ? (
        // @ts-ignore
        <UnsavedChangesModal {...displayChangesModal} />
    ) : null

    // don't show any users in the AddUsersDropdown who are already admins
    const filterUsers = (workspaceUser) => {
        return (
            appUsers.find((user) => user._sid === workspaceUser._sid)?.specified_role !==
            'internal_admin'
        )
    }

    const adminUsers = appUsers.filter((user) => user.specified_role === 'internal_admin')
    return (
        <SimpleModal
            open={isOpen}
            onClose={onClose}
            showCloseButton
            width="600px"
            height="600px"
            title={
                <>
                    <Icon icon="key" size="small" color="neutral.600" mr={2} display="inline" />
                    <span>Administrators</span>
                </>
            }
        >
            <Flex column wrap="nowrap" align="stretch" flexGrow={1}>
                <Header>
                    Administrators
                    {/* @ts-ignore */}
                    <Text ml={2} color="neutral.800" display="inline">
                        ({adminUsers.length})
                    </Text>
                </Header>
                <Text size="sm" mt={1} mb={3}>
                    Who can configure the app, manage users, and access all data.
                </Text>
                <AddUserDropdown
                    workspaceRole="member"
                    defaultRole="internal_admin"
                    showRoles={false}
                    showGroups={false}
                    filterUsers={filterUsers}
                    containerProps={{ mb: 2 }}
                />
                <AdministratorsUserList formRef={formRef} users={adminUsers} onSave={handleSave}>
                    {unsavedChangesModal}
                </AdministratorsUserList>
            </Flex>
        </SimpleModal>
    )
}
