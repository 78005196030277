// @ts-nocheck
import React from 'react'

import { LinkHorizontalListHeader, LinkVerticalListHeader } from 'features/links'

/**
 *
 * @param {{ links?: any[], direction?: 'horizontal' | 'vertical', centered?: boolean }} props
 * @returns
 */
export const UserProfileListHeader = ({ direction = 'horizontal', centered = false }) => {
    const title = `User Profiles`
    const description = `Link your data to your users to use in permissions`
    return direction === 'horizontal' ? (
        <LinkHorizontalListHeader
            title={title}
            description={description}
            iconName="user-profiles"
        />
    ) : (
        <LinkVerticalListHeader
            title={title}
            description={description}
            centered={centered}
            iconName="user-profiles"
        />
    )
}
