import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

function configureStore(initialState) {
    return doConfigureStore(initialState, true)
}

function doConfigureStore(initialState, isProd = false) {
    const middlewares = [
        // Add other middleware on this line...

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
        thunk,
    ]

    if (!isProd) {
        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        middlewares.push(reduxImmutableStateInvariant())
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    )

    // if (module.hot) {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept("./rootReducer", () => {
    //         const nextReducer = require("./rootReducer").default // eslint-disable-line global-require
    //         store.replaceReducer(nextReducer)
    //     })
    // }

    return store
}

export default configureStore
