/* Code Quality: Not audited */

import React, { Component } from 'react'

import orderBy from 'lodash/orderBy'

import { memoizeTwoArgs } from 'utils/utils'

export default class RecordOrder extends Component {
    render() {
        return <div />
    }
}

/**
 * @param {any[]} data
 * @param {{id: string, desc: boolean}[]} order
 */
function _processOrder(data = [], order) {
    if (!order) return data

    return orderBy(
        data,
        order.map(({ id }) => {
            // Check the format of the data in this field.
            const fieldValues = data.map((row) => row[id])
            const allNumbers = fieldValues.every(
                (v) => typeof v === 'number' || v == null || typeof v == 'undefined'
            )

            // Generate a suitable ordering key for each row.
            return (row) => {
                let value = row[id]
                if (allNumbers) {
                    // in Descending mode, lodash puts nulls before numbers.
                    // we want nulls to sort after numbers, not before, so we use an empty string
                    return value != null ? value : ''
                } else {
                    // Keep nulls at the end, regarding the desc/asc order
                    return value != null ? value.toString().toLowerCase() : ''
                }
            }
        }),
        order.map(({ desc }) => (desc ? 'desc' : 'asc'))
    )
}

export function getOrderFields(order) {
    return order.map((x) => x.id)
}

export const processOrder = memoizeTwoArgs(_processOrder)
