import { useCallback } from 'react'

import useUrlParam from 'v2/ui/utils/useUrlParam'

export enum AirtableConnectionParams {
    DataConnection = 'data_connection_sid',
    Repairing = 'repairing_connection',
    Upgrading = 'upgrading_airtable_v1',
}

export type UseAirtableConnectionParamsResult = {
    upgradingAirtableV1: string
    dataConnectionSid: string
    repairingConnection: string
    resetParams: () => void
}

export const useAirtableConnectionParams = (): UseAirtableConnectionParamsResult => {
    const [upgradingAirtableV1, setUpgradingAirtableV1] = useUrlParam<string>(
        AirtableConnectionParams.Upgrading,
        ''
    )

    const [repairingConnection, setRepairingConnection] = useUrlParam<string>(
        AirtableConnectionParams.Repairing,
        ''
    )

    const [dataConnectionSid, setDataConnectionSid] = useUrlParam<string>(
        AirtableConnectionParams.DataConnection,
        ''
    )

    const resetParams = useCallback(() => {
        setUpgradingAirtableV1('')
        setDataConnectionSid('')
        setRepairingConnection('')
    }, [setUpgradingAirtableV1, setDataConnectionSid, setRepairingConnection])

    return { upgradingAirtableV1, dataConnectionSid, repairingConnection, resetParams }
}
