import React from 'react'
import { Link } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { NotificationMenu } from 'features/collaboration/NotificationMenu'
import { AbsoluteLogo, HeaderBar, Logo } from 'features/core/nav/navUtils'
import { DefaultStackNameLogo, GetLogo } from 'features/utils/getLogo'
import { UserMenuButton } from 'features/workspace/Sidebar'

import { Button, Flex } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { SecondNavigation, TopNavigation } from './SecondaryNavigation'
import UserMenu from './UserMenu'

type MobileHeaderBarProps = {
    shouldHideAppBarForGuest: boolean
    navigation: any
    pages: PageDto[]
    views: ViewDto[]
    features: FeatureDto[]
    showDefaultStackLogo: boolean
    theme: {
        brandColor: string
        icon: string
        navColor: string
        logo_icon: string | undefined
    }
    navTheme: { [key: string]: string }
    logoUrl: string
    hideNavItems: boolean
    onMobileMenuClick: () => void
    stack: StackDto
    workspaceAccount: Account
    studioUser: UserDto
    isImpersonating: boolean
    children?: React.ReactNode
}

const MobileHeaderBar: React.FC<MobileHeaderBarProps> = ({
    shouldHideAppBarForGuest,
    navigation,
    pages,
    views,
    features,
    showDefaultStackLogo,
    theme,
    navTheme,
    logoUrl,
    hideNavItems,
    onMobileMenuClick,
    stack,
    workspaceAccount,
    studioUser,
    isImpersonating,
}) => {
    const isWorkspaceApp = workspaceAccount && !isImpersonating
    return (
        <>
            <HeaderBar
                {...navTheme}
                className={STYLE_CLASSES.HEADER}
                navItemsHidden={hideNavItems}
                isWorkspaceApp={isWorkspaceApp}
            >
                {((isWorkspaceApp && !shouldHideAppBarForGuest) || hideNavItems) && (
                    <Button
                        icon="bars"
                        onClick={onMobileMenuClick}
                        color={navTheme.textColorBright}
                        bg="transparent"
                        mr={4}
                        px={0}
                        _hover={undefined}
                    />
                )}
                <Flex flex="1">
                    {showDefaultStackLogo ? (
                        <Link to={getUrl(Urls.Home)}>
                            <DefaultStackNameLogo
                                name={stack?.name}
                                navColor={theme.navColor}
                                brandColor={theme.brandColor}
                                wrapText={false}
                            />
                        </Link>
                    ) : logoUrl ? (
                        <AbsoluteLogo className={STYLE_CLASSES.LOGO}>
                            <Flex as={Link} to={getUrl(Urls.Home)}>
                                <GetLogo url={logoUrl}>
                                    {(logo: string) => <Logo src={logo} />}
                                </GetLogo>
                            </Flex>
                        </AbsoluteLogo>
                    ) : workspaceAccount ? (
                        <b>{workspaceAccount.name}</b>
                    ) : (
                        ''
                    )}
                </Flex>

                <Flex justifyContent="flex-end">
                    <NotificationMenu navTheme={navTheme} />
                    {workspaceAccount ? (
                        // should show the user menu button only if the app bar
                        // is not being displayed in the drawer.
                        shouldHideAppBarForGuest && (
                            <UserMenuButton
                                navTheme={navTheme}
                                studioUser={studioUser}
                                mobile
                                alignSelf="start"
                                iconOnly={false}
                            />
                        )
                    ) : (
                        <UserMenu
                            containerStyle={{ paddingTop: 0 }}
                            theme={theme}
                            navTheme={navTheme}
                        />
                    )}
                </Flex>
            </HeaderBar>
            {!hideNavItems && (
                <div
                    style={{
                        background: navTheme.navColor,
                        color: navTheme.textColor,
                        overflow: 'hidden',
                    }}
                >
                    <TopNavigation
                        navigation={navigation}
                        theme={theme}
                        views={views}
                        features={features}
                        isWorkspace={stack.options.workspace_app}
                        desktop={false}
                    />
                </div>
            )}
            {!hideNavItems && (
                <SecondNavigation
                    views={views}
                    theme={theme}
                    pages={pages}
                    navigation={navigation}
                    features={features}
                    isWorkspace={stack.options.workspace_app}
                />
            )}
        </>
    )
}

export default MobileHeaderBar
