// @ts-nocheck
import React from 'react'

import Icon from 'legacy/v1/ui/legacy/Icon'

/**
 *
 * @param {{ links?: any[], direction?: 'horizontal' | 'vertical', centered?: boolean }} props
 * @returns
 */
export const LinkListHeader = ({ links = [], direction = 'horizontal', centered = false }) => {
    const title = links.length > 0 ? `Linked tables (${links.length})` : `Linked tables`
    const description = `Link records in one table to records in another table`
    return direction === 'horizontal' ? (
        <LinkHorizontalListHeader title={title} description={description} />
    ) : (
        <LinkVerticalListHeader title={title} description={description} centered={centered} />
    )
}

export const LinkHorizontalListHeader = ({ title, description, iconName = 'linked-records' }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Icon name={iconName} />
            <div
                style={{
                    marginLeft: 20,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <h3 style={{ marginTop: 0, marginBottom: 0 }}>{title}</h3>
                <p style={{ marginTop: 3, marginBottom: 0 }}>{description}</p>
            </div>
        </div>
    )
}

export const LinkVerticalListHeader = ({
    title,
    description,
    iconName = 'linked-records',
    centered = false,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: centered ? 'center' : 'flex-start',
            }}
        >
            <Icon name={iconName} />
            <h3 style={{ marginTop: 10, marginBottom: 0 }}>{title}</h3>
            <p style={{ marginTop: 3 }}>{description}</p>
        </div>
    )
}
