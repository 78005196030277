import { getCachedObject } from 'data/hooks/objects/objectOperations'

const getObjectApiName = (objectId) => {
    if (!objectId) return

    const object = getCachedObject(objectId)

    return object?.api_name
}

export default getObjectApiName
