export default function getPreviousPeriodIcon(percentage: number | null) {
    if (percentage === null) {
        return ''
    }

    if (percentage < 0) {
        return 'longArrowDown'
    }

    if (percentage > 0) {
        return 'longArrowUp'
    }

    return 'forward'
}
