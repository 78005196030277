import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import stackerTheme from 'v2/ui/theme/styles/default'

import { RenderInlineElement } from 'ui/forms/ui'

import PickerButton from '../deprecated/components/PickerButton'
export const colors = stackerTheme().colors

/**
 * @param {{name: string, label?: string, defaultValue?: any, subtitle?:string, contentStyle?:any, renderCurrentValue: (value: any) => any, children: ({onChange, value}: {onChange: () => void, value: any}) => any, closeOnChange?: boolean, errorMessages?: {[keyof: string]: string}} & Partial<import('react-hook-form').RegisterOptions>} param0
 */
export default function ItemPickerForm({
    label,
    name,
    subtitle = '',
    defaultValue,
    required = false,
    errorMessages = {},
    children,
    place = null,
    renderCurrentValue = (value) => value,
    closeOnChange = false,
    bodyPadding = '0px',
    disabled = false,
    row,
    buttonHeight,
    ...props
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)
    return (
        <RenderInlineElement
            label={label}
            error={error}
            errorMessages={errorMessages}
            subtitle={subtitle}
            row={row}
            {...props}
        >
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <PickerButton
                        buttonHeight={buttonHeight}
                        key={closeOnChange ? field.value : 'popover'}
                        buttonContent={renderCurrentValue(field.value)}
                        place={place}
                        bodyPadding={bodyPadding}
                        disabled={disabled}
                        {...props}
                    >
                        {children(field)}
                    </PickerButton>
                )}
            />
        </RenderInlineElement>
    )
}
