import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Label = styled('p')`
    color: ${(props) => props.theme.fontColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.theme[props.size || 'fontM']};
    padding: ${(props) => props.theme[props.padding || 'small']} 0px;
    margin: ${(props) => props.theme[props.margin || 'small']} 0px;
    margin-right: ${(props) => props.theme[props.margin || 'medium']};
    display: inline-block;
    text-align: right;
    ${(props) =>
        props.above &&
        `
        display: block;
        text-align: left;
    `};
    ${(props) =>
        props.light &&
        `
        color: ${props.theme.lightFontColor};
    `};
`

Label.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['fontS', 'fontM', 'fontL', 'fontXL']),
    above: PropTypes.bool,
    light: PropTypes.bool,
}

Label.displayName = 'Label'
export default Label
