import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'
import { importUrl } from 'data/api/bundlesApi'
import { refetchFeatures } from 'data/hooks/features'
import { refetchNavigation } from 'data/hooks/navigation'
import { refetchPages } from 'data/hooks/pages'
import { refetchRoles } from 'data/hooks/roles'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { refetchViews } from 'data/hooks/views'

import { LoadingSplash } from 'v2/ui'

export const InstallBundlePage: React.FC = () => {
    const history = useHistory()
    const { workspaceAccount } = useAppContext()
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    const bundleUrl = (query.url || '').toString()
    const accountId = workspaceAccount?._sid
    useEffect(() => {
        const installBundle = async () => {
            try {
                const stack = (await importUrl(bundleUrl, accountId)) as StackDto
                await Promise.all([
                    refetchRoles(),
                    refetchStacks(),
                    refetchViews(),
                    refetchPages(),
                    refetchNavigation(),
                    refetchFeatures(),
                ])

                history.push(getUrl(Urls.Home, stack))
            } catch (ex) {
                console.error(ex)
                alert('Install failed: ' + ex.message)
            }
        }
        if (bundleUrl) {
            installBundle()
        }
    }, [bundleUrl, accountId, history])

    return <LoadingSplash />
}
export default InstallBundlePage
