import * as React from 'react'

import { ButtonGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

import AppUserContext from 'app/AppUserContext'
import { invalidateAppUsersList } from 'data/hooks/users/invalidateAppUsersList'
import { useUpdateUser } from 'data/hooks/users/main'
import { useRefreshUserIntegrationKey } from 'data/hooks/users/useRefreshUserIntegrationKey'

import { Avatar, Banner, Button, Flex, Tooltip } from 'v2/ui'
import QuickCopyButton from 'v2/ui/components/QuickCopyButton'
import ReactFilestack from 'v2/ui/ReactFilestack'

import Form from 'ui/forms/Form'
import InputForm from 'ui/forms/InputForm'

import { Divider, FormFrame } from '../workspace/WorkspaceSettingsModalUi'

const Label = ({ text, marginBottom }) => {
    return (
        <div
            style={{
                fontSize: '14px',
                color: 'rgb(31, 33, 35)',
                fontWeight: 600,
                marginBottom: marginBottom ?? '5px',
            }}
        >
            {text}
        </div>
    )
}

const NonEditable = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    border-radius: 6px;

    user-select: all;

    display: flex;
    align-items: center;

    flex-grow: 1;
`

const WithAdorner = styled.div`
    width: 100%;
    display: flex;
    & > :first-child {
        height: auto;
        border-radius: 6px 0px 0px 6px;
    }
    & > :last-child {
        button {
            height: auto;
            border-radius: 0px 6px 6px 0px;
        }
    }
`

const AskBanner = styled(Banner)`
    margin-top: 12px;
`

const AskText = styled.div`
    margin-bottom: 12px;
`

// not part of the designs, just a placeholder to show the user's email - later we'll make it editable
const EmailDisplay = ({ user }) => {
    return <NonEditable>{user.email}</NonEditable>
}

const IntegrationKey = ({ user }) => {
    const refresh = useRefreshUserIntegrationKey()
    const [askToRefresh, setAskToRefresh] = React.useState(false)

    if (!user.integration_key) {
        return (
            <div>
                <Tooltip
                    closeOnClick
                    placement="bottom"
                    display="inline-block"
                    label="You may need an integration key to authenticate external services such as Zapier."
                >
                    <Button variant="moderateSm" padding="small" icon="key" onClick={refresh}>
                        Create an integration key
                    </Button>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <WithAdorner>
                <NonEditable>
                    <span>{user.integration_key}</span>
                    <QuickCopyButton value={user.integration_key} />
                </NonEditable>
                <Tooltip label="Refresh the key" placement="bottom" display="flex">
                    <Button
                        variant="moderateSm"
                        disable={askToRefresh}
                        padding="small"
                        icon="refresh"
                        onClick={() => setAskToRefresh(true)}
                    />
                </Tooltip>
            </WithAdorner>
            {askToRefresh && (
                <AskBanner variant="Information">
                    <AskText>
                        Refreshing the key will log you out of all your current integrations. Are
                        you sure you want to refresh?
                    </AskText>
                    <ButtonGroup>
                        <Button
                            variant="adminPrimaryV4"
                            padding="small"
                            icon="refresh"
                            onClick={() => refresh().then(() => setAskToRefresh(false))}
                        >
                            Yes, please refresh
                        </Button>
                        <Button
                            variant="adminSecondaryV4"
                            padding="small"
                            onClick={() => setAskToRefresh(false)}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </AskBanner>
            )}
        </>
    )
}

const UploadAvatar = ({ image, onChange, ...props }) => {
    return (
        <Flex {...props}>
            <Avatar src={image} size="lg" mr={4} />
            <ReactFilestack
                options={{
                    accept: 'image/*',
                }}
                onSuccess={(value) => {
                    if (onChange) {
                        onChange(value.filesUploaded[0].url)
                    }
                }}
                customRender={({ onPick }) => (
                    <Button
                        variant="moderateSm"
                        padding="small"
                        icon={image ? 'edit' : 'add'}
                        onClick={onPick}
                    >
                        {image ? 'Change' : 'Upload'}
                    </Button>
                )}
            />

            {image && (
                <Button
                    ml={2}
                    variant="moderateSm"
                    padding="small"
                    icon="trash"
                    onClick={() => onChange(null)}
                >
                    Remove
                </Button>
            )}
        </Flex>
    )
}

export default function ProfileSettingsModalGeneralSettings({ formRef, children }) {
    const { studioUser: user } = React.useContext(AppUserContext)
    const { mutateAsync: updateUser } = useUpdateUser()

    const onSubmit = (data) =>
        updateUser({
            id: user._sid,
            patch: {
                name: data.name,
            },
        })

    // avatar photo is not part of the form, it just saves inline when you change it
    const handleChangePhoto = (photo) => {
        updateUser({
            id: user._sid,
            patch: {
                options: {
                    ...user.options,
                    photo,
                },
            },
        })
    }

    // Refetch the user list when profile info is updated
    // Re-render activity with the correct name/avatar
    invalidateAppUsersList()

    const defaultValues = {
        name: user.name,
    }

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues,
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame title="General" formRef={formRef}>
                <Divider />
                <InputForm
                    name="name"
                    label="Name"
                    placeholder="Your Name"
                    variant="authentication"
                    required
                />
                <div style={{ height: 24 }} />

                <Label text="Email Address" />
                <EmailDisplay user={user} />

                <div style={{ height: 24 }} />

                <>
                    <Label text="Integration Key" />
                    <IntegrationKey user={user} />
                </>

                <div style={{ height: 24 }} />

                <Label text="Avatar" marginBottom="8px" />
                <UploadAvatar image={user.options.photo} onChange={handleChangePhoto} mb={4} />
            </FormFrame>
            {children}
        </Form>
    )
}
