import Color from 'color'

export const lighten = (color, amount) => {
    try {
        return Color(color).mix(Color('white'), amount).hex()
    } catch {
        return color // if the color passed can't be parsed, just return the same value instead of throwing
    }
}

export const darken = (color, amount) => {
    try {
        return Color(color).mix(Color('black'), amount).hex()
    } catch {
        return color // if the color passed can't be parsed, just return the same value instead of throwing
    }
}

export const alpha = (color, amount) => {
    try {
        return Color(color).alpha(amount).hex()
    } catch {
        return color // if the color passed can't be parsed, just return the same value instead of throwing
    }
}
