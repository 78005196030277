import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import FieldsEditor from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditor'

import {
    Banner,
    Box,
    Button,
    Checkbox,
    Divider,
    Flex,
    Input,
    Radio,
    ScrollBox,
    Text,
    Textarea,
} from 'v2/ui'

import { usePatchableActionContext } from './hooks/usePatchableActionContext'
import { useActionsContext } from './ActionsContextProvider'
import { updateStep } from './helpers'
import { FlexSpacer, Title } from './ui'

const hideReadOnlyFields = (field: FieldDto) => !field.connection_options.read_only

const ACTION_TYPES = {
    updateRecord: { displayName: 'Update Record', type: 'updateRecord' },
    /*
     * Note that the Zapier step needs to be unique for each
     * action.
     *
     * As there is only one step available per  action
     * available this is not an issue, but once we
     * allow multistep action we need to ensure the unicity
     * of the Zapier step.
     */
    zapierTrigger: { displayName: 'Trigger Zap from Zapier', type: 'zapierTrigger' },
}

function getStepFieldsFromObjectFields(step: ActionStep, objectFields: ActionStepField[]) {
    return objectFields.map((objectField) => {
        const match = step.fields.find((field) => field.fieldId === objectField.fieldId)

        if (match) return match

        return {
            ...objectField,
            required: undefined,
            value: undefined,
            label: undefined,
            promptUser: true,
        }
    })
}

const FieldModalName = 'EditField'

type Props = {
    item: ActionStep
    object: ObjectDto
    onRequestClose: () => void
}

export const EditActionStep: FC<Props> = ({ item: step, object, onRequestClose }) => {
    const { activeModal, addModalToStack, removeModalFromStack } = useActionsContext()
    const patchableActionContext = usePatchableActionContext()
    if (!patchableActionContext) {
        throw new Error('usePatchableActionContext must be used within a patchableActionContext')
    }

    const { applyPatch, draftObject: action, commit, rollback } = patchableActionContext
    const [title, setTitle] = useState(step.title)
    const [description, setDescription] = useState(step.description)
    const [fields, setFields] = useState(step.fields)
    const [confirmStep, setConfirmStep] = useState(!!step.always_confirm_step)

    const setFieldIdToEdit = useCallback(
        (id) => {
            if (id && activeModal !== FieldModalName) {
                addModalToStack(FieldModalName, { fieldId: id, stepId: step.id })
            } else if (!id && activeModal === FieldModalName) {
                removeModalFromStack()
            }
        },
        [activeModal, addModalToStack, removeModalFromStack, step.id]
    )

    const stepFields = React.useMemo(() => {
        return getStepFieldsFromObjectFields(step, fields)
    }, [fields, step])

    const setItems = useCallback(
        (fields) => {
            setFields(fields)
            applyPatch(
                updateStep(action, step.id, {
                    fields: getStepFieldsFromObjectFields(step, fields),
                })
            )
        },
        [action, applyPatch, step]
    )

    const changeType = useCallback(
        (newType: 'updateRecord' | 'zapierTrigger') => {
            const newConfig = ACTION_TYPES[newType]
            if (!newConfig) {
                return
            }

            applyPatch(
                updateStep(action, step.id, {
                    ...newConfig,
                })
            )
        },
        [action, applyPatch, step.id]
    )

    const changeFieldVisibilityFilters = useCallback(
        (filtersMap: { [fieldSid: string]: Filter[] }) => {
            applyPatch(
                updateStep(action, step.id, {
                    fieldConditionalVisibilityFilters: filtersMap,
                })
            )
        },
        [action, applyPatch, step.id]
    )

    const overwriteEditFieldFn = useCallback(
        (field) => {
            setFieldIdToEdit(field.fieldId)
        },
        [setFieldIdToEdit]
    )

    if (activeModal !== 'editStep') {
        return null
    }

    return (
        <ScrollBox margin="-5px" padding="5px" height="100%">
            <Flex column wrap="nowrap" align="stretch" height="100%">
                <>
                    <Title>Type of action</Title>
                    {/*
                     * Note that the Zapier step needs to be unique for each
                     * action.
                     *
                     * As there is only one step available per  action
                     * available this is not an issue, but once we
                     * allow multistep action we need to ensure the unicity
                     * of the Zapier step.
                     */}
                    <Radio
                        options={Object.values(ACTION_TYPES).map(({ type, displayName }) => ({
                            label: displayName,
                            value: type,
                        }))}
                        value={step.type}
                        onChange={(value: string) =>
                            changeType(value as 'updateRecord' | 'zapierTrigger')
                        }
                    />
                </>

                {step.type === 'updateRecord' && (
                    <Box position="relative" flexGrow={1} minHeight={200} height="100%" mt={3}>
                        <FieldsEditor
                            object={object}
                            items={stepFields}
                            setItems={setItems}
                            disallowSections
                            overwriteEditFieldFn={overwriteEditFieldFn}
                            autoHideEditButton={false}
                            filterField={hideReadOnlyFields}
                            showConditionalVisibility
                            conditionalVisibilityFilters={step.fieldConditionalVisibilityFilters}
                            onConditionalVisibilityFiltersChange={changeFieldVisibilityFilters}
                        />
                    </Box>
                )}
                {step.type === 'zapierTrigger' && (
                    <Box mt={3}>
                        <Banner>
                            You will need to create a Zap in Zapier that connects to this action
                            button. In Zapier, the action button will show both the step title and
                            the button name.
                        </Banner>
                    </Box>
                )}

                <Title>Step Title and Description</Title>
                <div>
                    <Input
                        value={title}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setTitle(event.target.value)
                        }
                        placeholder="Title"
                        size="sm"
                    />
                </div>
                <div>
                    <Textarea
                        style={{ width: '100%' }}
                        value={description}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setDescription(event.target.value)
                        }
                        placeholder="Description of this action"
                        rows={2}
                        mt={2}
                    />
                </div>
                <Divider my={4} />
                <Flex justifyContent="space-between">
                    {step.type === 'updateRecord' ? (
                        <Checkbox
                            isChecked={confirmStep}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setConfirmStep(event.target.checked)
                            }
                        >
                            <Text fontSize="0.875rem">Always show confirmation pop-up</Text>
                        </Checkbox>
                    ) : (
                        <FlexSpacer />
                    )}

                    <Flex style={{ gap: 8 }} height="85px">
                        <Button
                            variant="adminSecondary"
                            buttonSize="sm"
                            onClick={() => {
                                rollback()
                                onRequestClose()
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            variant="adminPrimary"
                            buttonSize="sm"
                            icon="check"
                            onClick={() => {
                                commit(
                                    updateStep(action, step.id, {
                                        title,
                                        description,
                                        fields: stepFields,
                                        always_confirm_step: confirmStep,
                                    })
                                )
                                onRequestClose()
                            }}
                        >
                            Save Action
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </ScrollBox>
    )
}
