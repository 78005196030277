import React, { FC, MutableRefObject, useRef } from 'react'
import { createPortal } from 'react-dom'

import styled from '@emotion/styled'
import type { CustomCell, Rectangle } from '@glideapps/glide-data-grid'

import { Button } from 'v2/ui'
import { getAttachmentUrlAndThumbnail } from 'v2/ui/components/Attribute/Attachment'
import AttachmentsAttribute from 'v2/ui/components/Attribute/AttachmentsAttribute'
import { Attachment } from 'v2/ui/components/Attribute/types'
import { modes } from 'v2/ui/utils/attributeSettings'

import EditorWrapper from '../components/EditorWrapper'
import { roundedRect } from '../drawUtils'
import type { CellData, CustomCellRenderer } from '../types'

export const ATTACHMENTS_CELL = 'attachments-cell'

export type AttachmentsCellData = CellData & {
    readonly kind: typeof ATTACHMENTS_CELL
    value: Attachment | Attachment[] | null
}

export type AttachmentsCell = CustomCell<AttachmentsCellData>

const AddFileButton: FC<{
    anchorRef: MutableRefObject<HTMLDivElement | null>
    onClick: () => void
}> = ({ anchorRef, onClick }) => {
    if (!anchorRef.current) {
        return null
    }

    return createPortal(
        <Button
            style={{ margin: '10px' }}
            variant="moderateSm"
            padding="small"
            icon="add"
            onClick={onClick}
        >
            Add file
        </Button>,
        anchorRef.current
    )
}

const AttachmentsAttributeWrapper = styled.div<{ isEmpty: boolean }>`
    margin-left: 10px;
    padding-top: ${(props) => (props.isEmpty ? 0 : 13)}px;

    max-height: 200px;
    overflow-y: auto;
`

const AttachmentsCellRenderer: CustomCellRenderer<AttachmentsCell> = {
    isMatch: (cell): cell is AttachmentsCell => (cell.data as any).kind === ATTACHMENTS_CELL,
    draw: (args, cell) => {
        const { ctx, theme, rect, imageLoader, col, row } = args

        const drawArea: Rectangle = {
            x: rect.x + theme.cellHorizontalPadding,
            y: rect.y + theme.cellVerticalPadding,
            width: rect.width - 2 * theme.cellHorizontalPadding,
            height: rect.height - 2 * theme.cellVerticalPadding,
        }

        ctx.fillStyle = theme.textDark

        let offsetX = 0

        cell.data.value.forEach((url: string) => {
            const imageResult = imageLoader.loadOrGetImage(
                getAttachmentUrlAndThumbnail(url, 'sm').thumbnail,
                col,
                row
            )

            if (!imageResult) {
                return
            }

            const imageWidth = imageResult.width * (drawArea.height / imageResult.height)
            roundedRect(ctx, drawArea.x + offsetX, drawArea.y, imageWidth, drawArea.height, 3)
            ctx.save()
            ctx.clip()
            ctx.drawImage(
                imageResult,
                drawArea.x + offsetX,
                drawArea.y,
                imageWidth,
                drawArea.height
            )
            ctx.restore()

            offsetX += imageWidth + theme.cellHorizontalPadding
        })

        return true
    },
    provideEditor: () => {
        const CustomEditor: FC<{
            value: AttachmentsCell
            onChange: (cell: AttachmentsCell) => void
        }> & { disableStyling?: boolean } = ({ value: cell, onChange }) => {
            const divRef = useRef<HTMLDivElement | null>(null)

            const handleChange = (value: Attachment | Attachment[]) => {
                const newCell = {
                    ...cell,
                    copyData: Array.isArray(value)
                        ? value.map(({ url }) => url).join(' ')
                        : value.url,
                    data: {
                        ...cell.data,
                        value,
                    },
                }

                onChange(newCell)
            }

            return (
                <EditorWrapper width={365}>
                    <div ref={divRef} />
                    <AttachmentsAttributeWrapper isEmpty={cell.data.value.length === 0}>
                        <AttachmentsAttribute
                            mode={modes.editing}
                            onChange={handleChange}
                            FormButton={({ onClick }) => (
                                <AddFileButton anchorRef={divRef} onClick={onClick} />
                            )}
                            isSingle={cell.data.field.type === 'image'}
                        >
                            {cell.data.value}
                        </AttachmentsAttribute>
                    </AttachmentsAttributeWrapper>
                </EditorWrapper>
            )
        }

        return {
            editor: CustomEditor,
            disablePadding: true,
            deletedValue: (toDelete: AttachmentsCell) => ({
                ...toDelete,
                copyData: '',
                data: {
                    ...toDelete.data,
                    value: null,
                },
            }),
        }
    },
}

export default AttachmentsCellRenderer
