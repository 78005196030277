import React, { useContext, useState } from 'react'

import { ParsedQs } from 'qs'

import { Button, Flex, Text } from 'v2/ui'

import useConfirmModal from '../../../../../app/ConfirmModal'
import { DataConnectionEditorContext } from '../../dataConnectionEditorContext'

import { AirtableConnectionEditorSettings } from './AirtableConnectionEditorSettings'
import { AIRTABLE_CONNECTION_EDITOR_STEPS } from './AirtableConnectionEditorSteps'
import { unlinkAirtableToken } from './unlinkAirtableToken'

export type EditExistingConnectionProps = {
    updateDataConnection: (data?: any) => Promise<DataConnectionDto>
    step: string | string[] | ParsedQs | ParsedQs[] | undefined
    onChangeHintImage: (url: string) => void
    dataBlock: boolean
    onSaveSuccess: (dc: DataConnectionDto, fields: any) => void
    useOAuthToken?: boolean
}
export const EditExistingConnection: React.FC<EditExistingConnectionProps> = (props) => {
    const [showSettings, setShowSettings] = useState<boolean>(false)
    const context = useContext(DataConnectionEditorContext)
    const [isRemovingToken, setIsRemovingToken] = useState(false)
    const { show } = useConfirmModal({
        confirmVariant: 'PrimaryError',
        title: 'Remove access?',
        message: (
            <>
                <Text mt={4}>
                    You will be unable to sync data until you connect another Airtable account.
                </Text>
            </>
        ),
        onConfirm: (modal: any) => {
            if (context.dataConnection) {
                setIsRemovingToken(true)
                unlinkAirtableToken(context.dataConnection)
                    .then((dc) => {
                        context.setDataConnection(dc)
                    })
                    .finally(() => {
                        setIsRemovingToken(false)
                    })
                modal.toggle()
            }
        },
    })

    if (!showSettings && props.step !== AIRTABLE_CONNECTION_EDITOR_STEPS.edit_connection) {
        return (
            <Flex>
                <Button
                    variant="Secondary"
                    buttonSize="sm"
                    alignSelf="start"
                    onClick={() => {
                        setShowSettings(true)
                        context.setHideButtons(true)
                    }}
                    display="flex"
                    alignItems="center"
                >
                    Update sharing link password
                </Button>
                {context.dataConnection?.user_oauth_token?.user_email && (
                    <>
                        <p data-testid="oauth-token-user-email">
                            This connection is linked to{' '}
                            {context.dataConnection.user_oauth_token.user_email}.
                        </p>
                        <Button
                            variant="Secondary"
                            color="danger"
                            buttonSize="sm"
                            alignSelf="start"
                            onClick={show}
                            display="flex"
                            alignItems="center"
                            isLoading={isRemovingToken}
                            mb={3}
                        >
                            Remove access
                        </Button>
                    </>
                )}
            </Flex>
        )
    }

    return (
        <AirtableConnectionEditorSettings
            onSaveSuccess={props.onSaveSuccess}
            saveFunction={props.updateDataConnection}
            onChangeHintImage={props.onChangeHintImage}
            forceShowPassword={showSettings}
            showApiKey={showSettings && !props.useOAuthToken}
            dataBlock={props.dataBlock}
            useOAuthToken={props.useOAuthToken}
        />
    )
}
