import React from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { useDeleteStack, useStacks } from 'data/hooks/stacks'
import { isLaunched, isSubscribed } from 'data/wrappers/WithStacks'
import GeneralSettings from 'features/AppSettings/CustomerAccess/CustomerAccessGeneralSettings'
import { Divider, ExpandSection, Section, Text } from 'legacy/v1/ui'

import { Button, Toast } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, isFeatureLocked, ProtectedFeature } from 'v2/ui/utils/ProtectedFeature'

import { BundlesSetting } from './BundlesSetting'
import { ChangeUrlSetting } from './ChangeUrlSetting'
import { CommentsAndNotificationsSetting } from './CommentsAndNotificationsSetting'
import { CustomScriptsSelfServe } from './CustomScriptsSelfServe'
import { DeleteAppSetting } from './DeleteAppSetting'
import { RenameAppSetting } from './RenameAppSetting'
import { ShowBreadcrumbsSettings } from './ShowBreadcrumbsSetting'
import { ThirdPartyTagsSetting } from './ThirdPartyTagsSetting'

const AppSettingsModalGeneralSettings = () => {
    const { mutateAsync: deleteStack } = useDeleteStack()
    const { data: stacks } = useStacks()
    const { selectedStack } = useAppContext()

    const [state, setState] = React.useState({
        name: selectedStack.name,
        url: selectedStack.url_slug,
        supportLogin: get(selectedStack, 'options.permit_support_login'),
        defaultTablePermissions: get(
            selectedStack,
            'options.permissions__default_for_tables',
            true
        ),
        newCreateForm: get(selectedStack, 'options.new_create_form'),
        createFormModal: get(selectedStack, 'options.create_form_modal'),
        showCustomDomainUpgrade: false,
        showSavedToast: false,
    })

    if (!selectedStack._sid) return ''

    const launched = isLaunched(selectedStack)
    const isStackSubscribed = isSubscribed(selectedStack)
    const isCollaborationLocked = isFeatureLocked(FEATURES.collaboration, selectedStack)
    const isSelfServeCustomScriptsLocked = isFeatureLocked(
        FEATURES.selfServeCustomScripts,
        selectedStack
    )
    const isThirdPartyTagsLocked = isFeatureLocked(FEATURES.thirdPartyTags, selectedStack)
    const isWorkspaceApp = selectedStack?.options?.workspace_app
    const canDeleteApp = isWorkspaceApp || !launched || selectedStack?.team_plan
    const showUpgradeButton = !isWorkspaceApp
    const isSupportLogin = localStorage.getItem('support_login') ?? false

    const isFastSyncEnabled = selectedStack?.combined_optional_features?.fast_sync

    const formRef = React.createRef({})

    return (
        <>
            <Section style={{ width: '100%' }}>
                <RenameAppSetting
                    selectedStack={selectedStack}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                ></RenameAppSetting>
                <Divider margin="none" />
                <ChangeUrlSetting
                    selectedStack={selectedStack}
                    stacks={stacks}
                    confirmModalOpen={() =>
                        setState((prevState) => ({ ...prevState, confirmModalOpen: true }))
                    }
                    isFeatureLocked={isFeatureLocked}
                ></ChangeUrlSetting>
                <Divider margin="none" />
                {selectedStack.options?.sync_users_as_auths && (
                    <ExpandSection
                        heading="External access"
                        helpUrl=""
                        helpText=""
                        text={null}
                        classes={{
                            heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.RENAME_APP,
                        }}
                    >
                        <Text>Set a custom app name and URL for external users.</Text>
                        <FlexWrapper>
                            <GeneralSettings formRef={formRef} isCompact={true} />
                        </FlexWrapper>
                    </ExpandSection>
                )}
                <Divider margin="none" />
                <DeleteAppSetting
                    selectedStack={selectedStack}
                    stacks={stacks}
                    deleteStack={deleteStack}
                    isStackSubscribed={isStackSubscribed}
                    canDeleteApp={canDeleteApp}
                />
                <Divider margin="none" />

                <ExpandSection
                    heading={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Cache frequency{' '}
                        </div>
                    }
                    helpUrl=""
                    helpText=""
                    text={null}
                    classes={{
                        heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.CACHE_FREQUENCY,
                    }}
                >
                    <Text flexGrow={1} size="smmd" mr={3}>
                        Data updates automatically every {isFastSyncEnabled ? '5' : '15'} minutes.{' '}
                    </Text>
                    {!isFastSyncEnabled && state.upgradeUrl && showUpgradeButton && (
                        <Button
                            marginRight="auto"
                            variant="upgradePrimary"
                            href={state.upgradeUrl}
                            openInNewTab
                        >
                            Upgrade
                        </Button>
                    )}
                </ExpandSection>
                <Divider margin="none" />
                <Divider margin="none" />
                <CommentsAndNotificationsSetting
                    selectedStack={selectedStack}
                    isCollaborationLocked={isCollaborationLocked}
                    showUpgradeButton={showUpgradeButton}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                ></CommentsAndNotificationsSetting>
                <Divider margin="none" />
                <CustomScriptsSelfServe
                    showUpgradeButton={showUpgradeButton}
                    isCustomScriptsLocked={isSelfServeCustomScriptsLocked}
                    selectedStack={selectedStack}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                    formRef={formRef}
                ></CustomScriptsSelfServe>

                <Divider margin="none" />
                <ThirdPartyTagsSetting
                    selectedStack={selectedStack}
                    showUpgradeButton={showUpgradeButton}
                    isThirdPartyTagsLocked={isThirdPartyTagsLocked}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                ></ThirdPartyTagsSetting>

                {(!isFeatureLocked(FEATURES.bundles, selectedStack) || isSupportLogin) && (
                    <>
                        <Divider margin="none" />
                        <BundlesSetting selectedStack={selectedStack}></BundlesSetting>

                        <Divider margin="none" />
                    </>
                )}
                <Divider margin="none" />
                <ShowBreadcrumbsSettings
                    selectedStack={selectedStack}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                ></ShowBreadcrumbsSettings>
                <Divider margin="none" />
                <ProtectedFeature
                    feature={FEATURES.customDomain}
                    showModal={state.showCustomDomainUpgrade}
                    onModalClosed={() =>
                        setState((prevState) => ({ ...prevState, showCustomDomainUpgrade: false }))
                    }
                />
            </Section>
            <Toast
                status="error"
                show={state.error}
                title="Sorry, there was an error. Please try again."
            />
            <Toast
                show={state.showSavedToast}
                onCloseComplete={() => {
                    setState((prevState) => ({ ...prevState, showSavedToast: false }))
                }}
                title="Changes saved"
            />
        </>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

export default AppSettingsModalGeneralSettings
