import React, { useCallback, useState } from 'react'

import LayoutObjectEditor from 'features/utils/LayoutObjectEditor'
/*
This utility component is used to wrap an element of the page (such as the profile card)
and provides all the plumbing to show the relevant editor in the side bar. 
*/
const EditableLayoutObject = ({ title, children, editor }) => {
    const [isOpen, setIsOpen] = useState(false)

    const showEditor = () => {
        setIsOpen(true)
    }

    const handleClose = useCallback(() => {
        setIsOpen(false)
    }, [])
    return (
        <>
            {children(showEditor)}
            <LayoutObjectEditor isOpen={isOpen} title={title} onClose={handleClose}>
                {editor}
            </LayoutObjectEditor>
        </>
    )
}
export default EditableLayoutObject
