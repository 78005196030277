import React from 'react'

import PropTypes from 'prop-types'

import Tooltip from 'v2/ui/components/Tooltip'

import Span from './Span'

const HelpLink = ({ children, url, style, tooltip, tooltipAlign, tooltipTextAlign }) => (
    <ConditionalWrapper
        condition={tooltip}
        wrapper={(children) => (
            <Tooltip
                label={tooltip}
                placement={tooltipAlign}
                textAlign={tooltipTextAlign}
                display="inline-flex"
            >
                {children}
            </Tooltip>
        )}
    >
        <Span size="fontS" style={style}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={url} target="_blank" style={{ display: 'inline-flex', alignItems: 'center' }}>
                <img
                    src="https://img.icons8.com/ios/50/2980b9/help.png"
                    height="14px"
                    width="14px"
                    style={{ marginRight: 8, display: 'inline-block' }}
                    alt="Help Icon"
                />{' '}
                {children}
            </a>
        </Span>
    </ConditionalWrapper>
)

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

HelpLink.propTypes = {
    children: PropTypes.element.isRequired,
    url: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    tooltipAlign: PropTypes.string,
    tooltipTextAlign: PropTypes.string,
    style: PropTypes.object,
}

HelpLink.defaultProps = {
    tooltipAlign: 'left',
    tooltipTextAlign: 'left',
    tooltip: '',
    style: {},
}

export default HelpLink
