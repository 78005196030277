import React, { useMemo } from 'react'

import { useViewFromId } from 'data/hooks/views'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import useTrack from 'utils/useTrack'

import { TrayButtonBase, TrayButtonBaseProps } from '../common/TrayButtonBase'
import useSlidingPane from '../hooks/useSlidingPane'

type EditModeButtonProps = TrayButtonBaseProps & {}

export const EditModeButton: React.VFC<EditModeButtonProps> = () => {
    const { isOpen, open } = useEditMode()
    const { state, close: closeSlidingPane } = useSlidingPane()

    const { track } = useTrack()

    const toggleEditMode = async () => {
        if (isOpen) {
            track('WIP - Frontend - Admin Side Tray - Exit Edit Mode - Clicked')
            // The edit mode is closed in the sliding pane close function
            closeSlidingPane()
        } else {
            track('WIP - Frontend - Admin Side Tray - Enter Edit Mode - Clicked')
            // If we succesfully enter edit mode, close any open side pane.
            await open()
        }
    }

    const selectedView = useViewFromId(state.contextInfo.viewId ?? '')

    const isDisabled = useMemo(() => {
        if (selectedView?.type === 'blankpage') {
            return !state.contextInfo.viewId
        }

        return !state.contextInfo.objectId && !state.contextInfo.viewId
    }, [selectedView?.type, state.contextInfo.objectId, state.contextInfo.viewId])

    return (
        <TrayButtonBase
            icon="brushSolid"
            onClick={toggleEditMode}
            tooltip="Edit layout"
            isActive={isOpen}
            disabled={isDisabled}
            data-testid="admin-side-tray.edit-layout-button"
        />
    )
}
