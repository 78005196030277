import { useEffect, useState } from 'react'

import useDeepEqualsMemoValue from '../utils/useDeepEqualsMemoValue'

/**
 * Returns a function which can be passed as a ref param to any HTML element.
 * When both the element is loaded and the active parameter is true, the element
 * will be scrolled into view. *
 */
export function useScrollIntoView(
    active: boolean = true,
    options?: ScrollIntoViewOptions | boolean
) {
    const [elementRef, setElementRef] = useState<HTMLElement | null>()
    const memoizedOptions = useDeepEqualsMemoValue(options)

    useEffect(() => {
        if (active && elementRef) {
            elementRef.scrollIntoView(memoizedOptions)
        }
    }, [active, elementRef, memoizedOptions])

    return setElementRef
}
