import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon as StyledCheckIcon, DividerHorizontalIcon } from '@radix-ui/react-icons'

import { makeComponent } from '../../helpers/recipes'

import { CheckboxStyles, ContainerStyles, IndicatorStyles, LabelStyles } from './Checkbox.css'

export const Container = makeComponent('div', ContainerStyles)
export const Root = makeComponent(CheckboxPrimitive.Root, CheckboxStyles)
export const Indicator = makeComponent(CheckboxPrimitive.Indicator, IndicatorStyles)
export const CheckIcon = StyledCheckIcon
export const IndeterminateIcon = DividerHorizontalIcon
export const Label = makeComponent('label', LabelStyles)
