import { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'

import { useRealtimeObjectUpdates } from '../../realtime/realtimeUpdates'
import { queryClient, useQuery, useQueryKeyBuilder } from '../_helpers'

import { fetchAppUserListDisabled } from './usersFetchMethods'

/**
 * Gets all the app users for the current studio user
 * ignoring preview/impersonation
 * NOTE: as this is pretty heavy, you need to enable it explicitly by using true as first parameter
 * @param isEnabled explicitly state is your react-query is enabled
 * @param {import('react-query').UseQueryOptions } options
 */
export function useAppUsersForAdmin(isEnabled = false, options = {}, internalOptions = {}) {
    const QUERY_NAME: REACT_QUERY_DEPS_NAME = 'useAppUsersForAdmin'
    if (!isEnabled) {
        options = {
            ...options,
            enabled: false,
        }
    }
    const { selectedStack, workspaceAccount } = useAppContext()
    const queryKey = useQueryKeyBuilder(QUERY_NAME, {
        includeStackId: true,
    })
    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })

    const query = useQuery(queryKey, 'list-app-users/?include_users_without_access=1', options, {
        disabledFn: () => fetchAppUserListDisabled(selectedStack, workspaceAccount),
        // Submit this request using the studio user's token
        // and ignore any impersonation or role previewing.
        bypassPreviewAndImpersonation: true,
    })
    // @ts-expect-error
    const includeUsersWithNoAccess = internalOptions?.includeUsersWithNoAccess
    return useMemo(() => {
        const data =
            query.data && !includeUsersWithNoAccess
                ? // @ts-expect-error
                  query.data.filter((user) => user.role)
                : query.data
        return {
            isLoading: query.isLoading,
            data,
            refetch: query.refetch,
            isFetched: query.isFetched,
        }
    }, [query.data, query.isLoading, query.refetch, query.isFetched, includeUsersWithNoAccess])
}
