import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export const LinkListContainer = ({ style = {}, header, links, children, actions, emptyState }) => {
    if (links.length === 0) {
        return (
            <div
                style={{
                    ...style,
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {emptyState}
            </div>
        )
    }
    return (
        <div style={{ ...style, flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                    <div></div>
                    {header}
                </div>
                <div>{actions}</div>
            </div>
            <div style={{ marginTop: 20 }}>{children}</div>
        </div>
    )
}
