import { useCallback, useState } from 'react'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import { dateDiff } from 'utils/utils'

import useLDFlags from './useLDFlags'
const useLDIdentify = (bypassCache = false) => {
    const [currentAccountId, setCurrentAccountId] = useState<string | null>()
    const ldClient = useLDClient()
    const { flags: oldFlags } = useLDFlags()
    return useCallback(
        (
            workspaceAccount: Account | undefined,
            selectedStack: StackDto | undefined
        ): Promise<LDFlagSet> => {
            const accountSid = workspaceAccount?._sid ?? selectedStack?.account_id
            if (accountSid && (bypassCache || accountSid != currentAccountId)) {
                if (ldClient) {
                    setCurrentAccountId(accountSid)
                    const context = {
                        key: accountSid,
                        kind: 'user',
                        ...(workspaceAccount
                            ? {
                                  version: workspaceAccount.options?.version ?? 4,
                                  for: workspaceAccount.options?.signup_params?.for ?? '',
                                  tag: workspaceAccount.options?.signup_params?.tag ?? '',
                                  flow: workspaceAccount.options?.signup_params?.flow ?? '',
                                  is_stacker_data:
                                      !!workspaceAccount.optional_features?.stacker_native_tables,
                                  account_created: workspaceAccount.created_date,
                                  hours_since_account_created: dateDiff(
                                      workspaceAccount.created_date,
                                      Date(),
                                      'hours'
                                  ),
                                  purpose: workspaceAccount.options?.purpose?.selected_value ?? '',
                                  company_size:
                                      workspaceAccount.options?.team_details?.value?.company_size ??
                                      '',
                              }
                            : {}),
                    }
                    return ldClient?.identify(context)
                } else {
                    return Promise.resolve(oldFlags)
                }
            } else {
                return Promise.resolve(oldFlags)
            }
        },
        [currentAccountId, oldFlags, ldClient, bypassCache]
    )
}
export default useLDIdentify
