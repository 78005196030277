/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import Box from './Box'

const hiddenStyle = css`
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
`

/**
 *
 * @param {any} param0
 */
const ScrollBox = ({
    as,
    scrollbarColor = 'rgba(0,0,0,.2)',
    scrollbarBackgroundColor = 'rgba(0,0,0,.1)',
    hideScrollbars = false,
    overflowY = 'auto',
    overflowX = 'hidden',
    scrollTrackSideMargin = undefined,
    ...props
}) => {
    const style = css`
        scrollbar-width: thin;
        scrollbar-height: thin;
        scrollbar-color: ${scrollbarColor};

        ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        ::-webkit-scrollbar-track {
            background: ${scrollbarBackgroundColor};
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${scrollbarColor};
            border-radius: 20px;
        }
        ${scrollTrackSideMargin
            ? 'padding-right: ' +
              scrollTrackSideMargin +
              'px; margin-right: -' +
              scrollTrackSideMargin +
              'px'
            : ''}
    `

    return (
        <Box
            as={as}
            overflowY={overflowY}
            overflowX={overflowX}
            {...props}
            css={hideScrollbars ? hiddenStyle : style}
        />
    )
}
export default ScrollBox
