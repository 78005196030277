import React, { VFC } from 'react'

import Box from './Box'
import Icon from './Icon'
import Text from './Text'

type EmptyStateProps = React.ComponentPropsWithoutRef<typeof Box> & {
    name: string
    compact?: boolean
    custom?: string
    cta?: any
    svg: VFC<any>
    icon?: string
    additionalActions?: JSX.Element
}

const EmptyState: VFC<EmptyStateProps> = ({
    name = 'records',
    compact,
    custom,
    cta = undefined,
    svg,
    icon,
    additionalActions = undefined,
    ...props
}) => {
    const CtaView = cta
    const SvgComponent = svg

    let message = `No ${name} to display`
    if (compact) {
        message = `No ${name}`
    }

    if (custom) {
        message = custom
    }

    return (
        <>
            <Box
                textAlign="center"
                p={['emptyState.padding', null, null, 'emptyState.paddingLg']}
                {...props}
            >
                <Icon
                    justifyContent="center"
                    svg={<SvgComponent />}
                    icon={icon}
                    mb={[2, null, null, 3]}
                    color="emptyState.icon"
                />
                <Text variant="emptyState" mb={7}>
                    {message}
                </Text>

                {cta && <CtaView />}

                {additionalActions}
            </Box>
        </>
    )
}

export default EmptyState
