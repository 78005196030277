import React from 'react'

import { BoxProps } from '@chakra-ui/react'

import { Flex, Heading, Text } from 'v2/ui'
import { EmptyState } from 'v2/ui/svgs'

type BlankPageEmptyStateProps = BoxProps

const BlankPageEmptyState: React.FC<BlankPageEmptyStateProps> = (props) => {
    return (
        <Flex direction="column" {...props}>
            <div>
                <EmptyState />
            </div>
            <Heading as="h2" variant="h300" mt={8} mb={3} value="Nothing on this page yet" />
            <Text color="neutral.800">Drag in a widget from the menu to get started 👉</Text>
        </Flex>
    )
}
export default BlankPageEmptyState
