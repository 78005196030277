import React from 'react'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { Box, ConditionalWrapper, Container, Heading, Link, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import QuickLinksBlockItemIcon from './QuickLinksBlockItemIcon'
import QuickLinksBlockItemLinkLabel from './QuickLinksBlockItemLinkLabel'
import { useFieldLink } from './useFieldLink'
import { useInternalLink } from './useInternalLink'

const { space } = stackerTheme()

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    border: 1px solid transparent;
    transition: border-color 0.15s ease-out;
`

const StyledQuickLinksBlockItemIcon = styled(QuickLinksBlockItemIcon)<{ hasGap: boolean }>`
    align-self: center;
    flex: 0 0 auto;
    ${({ hasGap }) => hasGap && `margin-right: ${space[4]}`};
`

const StyledLink = styled(Link)<{ hoverColor?: string }>`
    &:link {
        text-decoration: none;
    }

    &:hover .ql-container {
        border-color: ${({ hoverColor }) => hoverColor};
    }
`

type QuickLinksBlockItemProps = QuickLinksBlockItem & {
    brandColor?: string
    isEditing?: boolean
    context?: ViewLayoutContext
}

const QuickLinksBlockItem: React.FC<QuickLinksBlockItemProps> = ({
    title,
    subtitle,
    icon,
    link,
    brandColor,
    isEditing,
    context,
}) => {
    const { label } = link ?? {}
    const internalViewId = link?.type === 'internal' ? link.viewId : undefined
    const internalLinkUrl = useInternalLink(internalViewId)

    const fieldValue = useFieldLink(link, context)

    let url = ''
    switch (link?.type) {
        case 'internal':
            url = internalLinkUrl ?? ''
            break
        case 'external':
            url = link?.url ?? ''
            break
        case 'field':
            url = fieldValue
            break
    }

    const isIconOnlyLink = !title && !subtitle && !label

    return (
        <ConditionalWrapper
            condition={!!url && !isEditing}
            wrapper={(children: React.ReactNode) => (
                <StyledLink href={url} aria-label={label} hoverColor={brandColor}>
                    {children}
                </StyledLink>
            )}
        >
            <StyledContainer
                m={0}
                as="div"
                py={[8, null, null, 10]}
                px={[2, null, null, 5]}
                justifyContent={isIconOnlyLink ? 'center' : undefined}
                className="ql-container"
            >
                {icon && (
                    <StyledQuickLinksBlockItemIcon
                        brandColor={brandColor}
                        icon={icon}
                        hasGap={!isIconOnlyLink}
                    />
                )}
                {(title || subtitle) && (
                    <div>
                        <Heading as="h3" variant="h200" mt={0} mb={1} value={title} />
                        {subtitle && (
                            <Text color="neutral.800" size="sm">
                                {subtitle}
                            </Text>
                        )}
                    </div>
                )}
                {url && label && (
                    <Box bottom={[2, null, null, 4]} right={[2, null, null, 4]} position="absolute">
                        <QuickLinksBlockItemLinkLabel
                            label={label!}
                            isExternal={link?.type !== 'internal'}
                            brandColor={brandColor}
                        />
                    </Box>
                )}
            </StyledContainer>
        </ConditionalWrapper>
    )
}

export default QuickLinksBlockItem
