import React, { memo, useMemo, useRef } from 'react'

import { SystemStyleObject, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { DropdownOption } from 'features/admin/fields/DropdownFieldConfiguration/types'
import { getDropdownFieldOptionColor, getDropdownFieldOptionTextColor } from 'utils/fieldUtils'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import { ConditionalWrapper, Dropdown, Editable } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import DisplayText from './DisplayText'
import TagList from './TagList'

const ColoredOptionWrapper = styled.div<{ bgColor: string; textColor: string }>`
    width: fit-content;
    padding: 0.25rem;

    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};

    border-radius: 5px;
    display: inline-block;
`

type ColoredOptionProps = React.PropsWithChildren<{
    option: DropdownOption
}>

const ColoredOption: React.FC<ColoredOptionProps> = ({ option, children }) => {
    const theme = useTheme()

    const optionColor = getDropdownFieldOptionColor(option)!
    const textColor = getDropdownFieldOptionTextColor(option, theme)!

    return (
        <ColoredOptionWrapper bgColor={optionColor} textColor={textColor}>
            {children}
        </ColoredOptionWrapper>
    )
}

type IconWrapProps = {
    option: DropdownOption
    allowDropdownColors?: boolean
}

const IconWrap: React.FC<IconWrapProps> = ({ option, allowDropdownColors }) => {
    const optionColor = getDropdownFieldOptionColor(option)

    return (
        <ConditionalWrapper
            condition={Boolean(allowDropdownColors && optionColor)}
            wrapper={(children) => <ColoredOption option={option}>{children}</ColoredOption>}
        >
            {option.icon}
            {option.label}
        </ConditionalWrapper>
    )
}

type DropdownAttributeProps = {
    onChange: (value: DropdownOption | DropdownOption[]) => void
    mode?: typeof modes[keyof typeof modes]
    layout?: typeof layouts[keyof typeof layouts]
    isMulti?: boolean
    hasFocus?: boolean
    inDataGrid?: boolean
    field?: FieldDto
    id?: string
    style?: React.CSSProperties
    placeholder?: string
    children?: string | string[]
    renderOptions?: {
        disableSearch?: boolean
    }
    controlStyle?: SystemStyleObject
}

const DropdownAttribute: React.FC<DropdownAttributeProps> = ({
    mode,
    isMulti,
    layout,
    onChange,
    hasFocus,
    inDataGrid,
    renderOptions = {},
    controlStyle = {},
    placeholder,
    field,
    children,
    style,
    id,
}) => {
    const { disableSearch } = renderOptions

    const dropdownRef = useRef()
    if (inDataGrid) {
        // @ts-expect-error: Remove when we type the react-select module.
        dropdownRef.current?.select?.inputRef?.focus()
    }

    const options: DropdownOption[] = useMemo(() => field?.options?.options || [], [field])

    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    let className = isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK
    if (inDataGrid) {
        className += ' click-outside-ignore'
    }

    const trueValue: React.ReactNode = useMemo(() => {
        if (isMulti) {
            if (Array.isArray(children)) {
                const optionsByValue = options.reduce<Map<string, DropdownOption>>(
                    (acc, option) => acc.set(option.value, option),
                    new Map()
                )

                return (children as string[]).reduce<React.ReactElement[]>((acc, curr) => {
                    const option = optionsByValue.get(curr)
                    if (option)
                        acc.push(
                            <IconWrap
                                option={option}
                                allowDropdownColors={field?.options?.allow_dropdown_colors}
                            />
                        )

                    return acc
                }, [])
            }

            return children
        }

        const option = options.find((o) => o.value === children)
        if (option) {
            return (
                <IconWrap
                    option={option}
                    allowDropdownColors={field?.options?.allow_dropdown_colors}
                />
            )
        }
    }, [children, field, isMulti, options])

    const form = (
        <Dropdown
            ref={dropdownRef}
            style={style}
            margin="none"
            autoFocus={mode === modes.editable || hasFocus}
            options={options}
            onChange={onChange}
            value={children}
            renderValue={(data: DropdownOption) => {
                if (
                    !field?.options?.allow_dropdown_colors ||
                    (!data.airtable_color && !data.color)
                ) {
                    return data.label
                }

                return <ColoredOption option={data}>{data.label}</ColoredOption>
            }}
            isMulti={isMulti}
            id={id}
            maxWidth="100%"
            usePortal
            isSearchable={!disableSearch}
            className={className}
            placeholder={placeholder}
            allowDropdownColors={field?.options?.allow_dropdown_colors}
            defaultMenuIsOpen={inDataGrid}
            controlStyle={{
                background: 'white',
                ...controlStyle,
            }}
        />
    )

    const display: React.ReactElement = useMemo(() => {
        if (isMulti && trueValue) {
            const inline = layout === layouts.inline

            return (
                <TagList
                    items={trueValue as (string | React.ReactElement)[]}
                    singleLine={inline}
                    itemStyle={
                        field?.options?.allow_dropdown_colors
                            ? { padding: 0, background: 'none' }
                            : undefined
                    }
                />
            )
        }

        return <DisplayText>{trueValue || '-'}</DisplayText>
    }, [field?.options?.allow_dropdown_colors, isMulti, layout, trueValue])

    if (mode === modes.editable) {
        return (
            <Editable
                input={(props: unknown) =>
                    React.cloneElement(form, {
                        onBlur: () => {
                            // @ts-expect-error: Remove when we type the <Editable /> component.
                            props.end()
                        },
                    })
                }
                display={() => display}
                onChange={onChange}
            />
        )
    }
    if (mode === modes.editing) {
        return form
    }

    return display
}

export default memo(DropdownAttribute)
