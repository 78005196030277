import React from 'react'

import get from 'lodash/get'

import { Span } from 'legacy/v1/ui'

const SubscriptionBadge = ({ stack, account, ...props }) => {
    const sub = stack.subscription
    const isWorkspaceApp = stack?.options?.workspace_app
    const isLaunched = get(stack.options, 'launched')
    const isSubscribed = isWorkspaceApp ? account?.subscription_status === 'subscribed' : isLaunched

    if (!sub || !sub.status) return ''
    const { plan } = sub

    let text = ''
    let color = ''

    if (isSubscribed) {
        // legacy only
        if (plan === 'FREE') {
            text = 'FREE'
            color = 'orange'
        } else {
            text = 'SUBSCRIBED'
            color = '#74DEDA'
        }
    } else {
        text = 'TRIAL'
        color = 'orange'
    }

    if (!text) return null

    return (
        <Span
            {...props}
            padding="none"
            margin="none"
            size="fontS"
            style={{
                background: color,
                padding: '2px 4px',
                fontSize: '0.75em',
                fontWeight: 'bold',
                color: '#FFFFFF',
                ...props.style,
            }}
        >
            {text}
        </Span>
    )
}

export default SubscriptionBadge
