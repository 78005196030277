import { APP_BAR_WIDTH } from './frameConstants'

export const getLeftOffset = (
    showAppBar: boolean = false,
    showSideNav: boolean = false,
    showSidebar: boolean = false,
    shouldHideAppBarForGuest: boolean = false
) => {
    let margin = 0

    if (showSidebar || showSideNav) {
        margin = 270 + APP_BAR_WIDTH
    } else if (showAppBar && !shouldHideAppBarForGuest) {
        margin = APP_BAR_WIDTH
    }

    return margin
}
