import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { trim } from 'lodash'

import { Divider, Frame } from 'features/workspace/WorkspaceSettingsModalUi'

import { Avatar, Button, Collapse, Dropdown, Flex, Icon, ScrollBox, SearchInput, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'
import SubmitButton from 'ui/forms/SubmitButton'

import { decodeFieldKey, encodeFieldKey, SyncRefWithForm } from '../../../../ui/forms/utils'

const colors = stackerTheme().colors
export function UserAccessEditor({
    formRef,
    children,
    users,
    disabled,
    roleOptions,
    onlySubmitChangedUsers,
    onSave,
    provideUserRole,
    filterAvailableRoles,
    canEditUser,
    provideGroupUsers,
    renderAdditionalButtons,
}) {
    const [inEditMode, setInEditMode] = useState(false)
    const [error, setError] = useState()

    // Clear any errors on cancel
    useEffect(() => {
        if (!inEditMode) {
            setError(null)
        }
    }, [inEditMode])

    const saveChanges = (patch) => {
        // decode the userIds from the patch before sending to the caller
        const translatedPatch = Object.keys(patch).reduce((result, key) => {
            result[decodeFieldKey(key)] = patch[key]
            return result
        }, {})

        setError(null)

        try {
            return onSave(translatedPatch)
                .then(() => {
                    setInEditMode(false)
                })
                .catch((ex) => {
                    // If the promise was rejected, it was a server error
                    // so show a generic message
                    setError('An error occurred. You may not have permission to do this.')
                    throw ex
                })
        } catch (ex) {
            // If an error was thrown during the processing of the save data
            // it's the caller rejecting the data and so we should show the message
            setError(ex.message)
            throw ex
        }
    }

    const resetFormState = (formContext) => {
        formContext.reset({})
    }
    return (
        <Form
            onSubmit={saveChanges}
            onSuccess={resetFormState}
            style={{ flex: 1, minHeight: 0, maxHeight: '100%' }}
            onlySubmitChangedfields={onlySubmitChangedUsers}
        >
            {formRef && <SyncRefWithForm formRef={formRef} />}
            <UserListFrame
                formRef={formRef}
                users={users}
                inEditMode={inEditMode}
                setInEditMode={setInEditMode}
                disabled={disabled}
                roleOptions={roleOptions}
                provideUserRole={provideUserRole}
                filterAvailableRoles={filterAvailableRoles}
                canEditUser={canEditUser}
                error={error}
                provideGroupUsers={provideGroupUsers}
                renderAdditionalButtons={renderAdditionalButtons}
            />
            {children}
        </Form>
    )
}
export function UserListFrame({
    formRef,
    inEditMode,
    setInEditMode,
    users,
    disabled,
    roleOptions,
    provideUserRole,
    filterAvailableRoles,
    canEditUser,
    error,
    provideGroupUsers,
    renderAdditionalButtons = () => null,
}) {
    const { reset } = useFormContext()
    const handleToggleEditMode = () => {
        setInEditMode(!inEditMode)
    }

    const cancelEdit = () => {
        reset()
        setInEditMode(false)
    }

    const buttons = (
        <Flex column wrap="nowrap" align="flex-end">
            <Flex wrap="nowrap">
                <Button
                    variant="adminSecondaryV4"
                    onClick={cancelEdit}
                    mr={2}
                    style={{ display: inEditMode ? 'flex' : 'none' }}
                >
                    Cancel
                </Button>
                <SubmitButton v4 requireChanges style={{ display: inEditMode ? 'flex' : 'none' }}>
                    Save
                </SubmitButton>
                {renderAdditionalButtons({ inEditMode, disabled })}
                <Button
                    variant="adminSecondaryV4"
                    icon="edit"
                    onClick={handleToggleEditMode}
                    display={inEditMode ? 'none' : 'flex'}
                    disabled={disabled}
                >
                    Edit
                </Button>
            </Flex>
            <Collapse isOpen={!!error}>
                <Text variant="error" mt={2}>
                    {error}
                </Text>
            </Collapse>
        </Flex>
    )

    // count the number of unique users (including those from listed groups)
    const uniqueUsers = users.reduce((userIds, item) => {
        const addUserId = (user) => {
            if (!userIds.includes(user._sid)) {
                userIds.push(user._sid)
            }
        }
        // if this item is a group, process the group users
        if (item.isGroup) {
            provideGroupUsers(item._sid).forEach(addUserId)
        } else {
            addUserId(item)
        }
        return userIds
    }, [])

    return (
        <>
            <Frame
                title={`Collaborators (${uniqueUsers.length})`}
                flex={1}
                titleBarRightContent={buttons}
                overflowY="hidden"
                formRef={formRef}
            >
                <UserList
                    formRef={formRef}
                    inEditMode={inEditMode}
                    users={users}
                    roleOptions={roleOptions}
                    provideUserRole={provideUserRole}
                    filterAvailableRoles={filterAvailableRoles}
                    canEditUser={canEditUser}
                />
            </Frame>
        </>
    )
}

function UserList({
    inEditMode,
    users,
    roleOptions,
    provideUserRole,
    filterAvailableRoles,
    canEditUser,
}) {
    const [filterValue, setFilterValue] = useState()

    const handleFilterChange = useCallback((value) => {
        setFilterValue(trim(value))
    }, [])

    const filterUser = (user) => {
        if (filterValue) {
            const searchValue = filterValue.toLowerCase()
            const terms = [user.name?.toLowerCase(), user.email?.toLowerCase()]
            return Boolean(terms.find((x) => x?.includes(searchValue)))
        }

        return true
    }

    return (
        <Flex
            column
            border="1px solid"
            borderColor="gray.200"
            borderRadius="5px"
            maxHeight="100%"
            flexGrow={1}
            flexShrink={1}
            minHeight={0}
            wrap="nowrap"
            align="stretch"
        >
            <SearchInput
                width="100%"
                variant="noBorder"
                borderBottom="1px solid"
                borderColor="gray.100"
                value={filterValue}
                onChange={handleFilterChange}
            />
            <ScrollBox maxHeight="100%" flexGrow={1} overflowY="auto">
                {users?.map((user) => {
                    const isVisible = filterUser(user)
                    return (
                        <>
                            <UserListItem
                                user={user}
                                roleOptions={roleOptions}
                                inEditMode={inEditMode}
                                key={user._sid}
                                visible={isVisible}
                                provideUserRole={provideUserRole}
                                filterAvailableRoles={filterAvailableRoles}
                                canEditUser={canEditUser}
                            />
                            {isVisible && <Divider dense />}
                        </>
                    )
                })}
            </ScrollBox>
        </Flex>
    )
}

function UserListItem({
    user,
    roleOptions,
    inEditMode,
    visible = true,
    provideUserRole,
    filterAvailableRoles,
    canEditUser,
}) {
    const roleId = provideUserRole(user)
    const role = useMemo(() => roleOptions.find((x) => x.value === roleId), [roleOptions, roleId])
    const userKey = encodeFieldKey(user._sid)
    const deletedkey = `${userKey}.deleted`

    const { watch, setValue, register } = useFormContext()
    const deleted = watch(deletedkey)

    const removeUser = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }

    const options = filterAvailableRoles ? filterAvailableRoles(user, roleOptions) : roleOptions
    const isLocked = canEditUser && !canEditUser(user)
    return (
        <div
            style={{
                paddingLeft: '8px',
                paddingRight: '8px',
                alignItems: 'center',
                marginTop: '8px',
                marginbottom: '8px',
                display: visible ? 'flex' : 'none',
                fontSize: '14px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '200px',
                    flexShrink: 1,
                    flexGrow: 1,
                    minWidth: 0,
                    marginRight: '8px',
                }}
            >
                {user.isGroup ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '24px',
                            height: '24px',
                            marginRight: '8px',
                        }}
                    >
                        <Icon icon="users" size="sm" color="gray.400" />
                    </div>
                ) : (
                    <Avatar src={user.avatar} name={user.name} size="xs" mr={2} />
                )}
                <div style={{ flexShrink: 1, minWidth: 0, flexGrow: 1 }}>
                    <div
                        style={{
                            color: colors.neutral[1000],
                            fontWeight: 'bold',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textDecoration: deleted ? 'line-through' : null,
                        }}
                    >
                        {user.name}
                    </div>
                    <div
                        style={{
                            color: colors.neutral[800],
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textDecoration: deleted ? 'line-through' : null,
                        }}
                        color="neutral.800"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textDecoration={deleted ? 'line-through' : null}
                    >
                        {user.email}
                    </div>
                </div>
            </div>

            <input type="hidden" {...register(deletedkey)} />
            <WorkspaceRoleLabel
                role={role}
                style={{
                    width: '150px',
                    display: inEditMode && !isLocked && 'none',
                    textDecoration: deleted ? 'line-through' : null,
                }}
            />

            {!deleted && inEditMode && (
                <FormField
                    display={(!inEditMode || isLocked) && 'none'}
                    maxWidth="150px"
                    width="150px"
                    as={Dropdown}
                    controlledDefaultValue={roleId}
                    name={`${userKey}.role`}
                    options={options}
                    variant="settings"
                    isClearable={false}
                    isSearchable={false}
                    controlled
                    renderValue={(option) => <WorkspaceRoleLabel role={option} />}
                />
            )}
            {inEditMode && !deleted && (
                <Icon
                    data-testId="user-access-editor.delete-user"
                    icon="x"
                    button
                    ml={1}
                    color="gray.400"
                    px={2}
                    onClick={removeUser}
                    visibility={isLocked ? 'hidden' : null}
                />
            )}
        </div>
    )
}

export function WorkspaceRoleLabel({ role, ...rest }) {
    return (
        <Flex {...rest}>
            {role?.icon && (
                <div
                    style={{
                        width: 18,
                        flexShrink: 0,
                        marginRight: '0.3rem',
                    }}
                >
                    <Icon
                        icon={role?.icon}
                        color={role?.iconColor}
                        size="12px"
                        style={{
                            justifyContent: 'center',
                        }}
                    />
                </div>
            )}
            <span
                style={{
                    position: 'relative',
                    top: '2px',
                }}
            >
                {role?.label}
            </span>
        </Flex>
    )
}
