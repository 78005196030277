// @ts-strict-ignore
import React, { useContext, useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { useAppContext } from 'app/AppContext'
import { useRoleListOptions } from 'data/hooks/roleHelpers'
import { SharingSettingsPatch } from 'data/hooks/stacks'

import { Dropdown, Icon, Tooltip } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { FormWrapper } from '../../../ui/forms/Form'
import { FormField } from '../../../ui/forms/FormField'
import { decodeFieldKey, encodeFieldKey, SyncRefWithForm } from '../../../ui/forms/utils'
import { GroupActions, SaveChangesBar, UserActions } from '../shared/AppUsersUI'
import GroupListItem, { GroupListItemProps } from '../shared/GroupListItem'
import UserList from '../shared/UserList'
import UserListItem, { UserListItemProps } from '../shared/UserListItem'

const colors = stackerTheme().colors
type Props = {
    formRef: any
    children: any
    users: any[]
    disabled: boolean
    onSave: any
    userList?: UserListDto
}
type ListContextProps = {
    userList?: UserListDto
}
const ListContext = React.createContext<ListContextProps>({})
export function ManualAppUserList(props: Props) {
    const { formRef, children, users, onSave } = props
    const [listResetKey, setListResetKey] = useState(0)
    const [error, setError] = useState<string | undefined>()

    const formContext = useForm<SharingSettingsPatch>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { data: roleOptions } = useRoleListOptions()

    const cancelEdit = () => {
        formContext.reset()
        setListResetKey((val) => val + 1)
        setError(undefined)
    }

    const saveChanges = (patch) => {
        // decode the userIds from the patch before sending to the caller
        const translatedPatch = Object.keys(patch).reduce((result, key) => {
            result[decodeFieldKey(key)] = patch[key]
            return result
        }, {})

        setError(undefined)

        try {
            return onSave(translatedPatch)
                .then(() => {
                    setError(undefined)
                    setListResetKey((val) => val + 1)
                })
                .catch((ex) => {
                    console.error(ex)
                    // If the promise was rejected, it was a server error
                    // so show a generic message
                    setError('An error occurred. You may not have permission to do this.')
                    throw ex
                })
        } catch (ex) {
            // If an error was thrown during the processing of the save data
            // it's the caller rejecting the data and so we should show the message
            setError(ex.message)
            throw ex
        }
    }

    // the list context allows us to supply the group and user components (below)
    // with relevant information
    const listContext = useMemo(
        () => ({
            userList: props.userList,
        }),
        [props.userList]
    )

    return (
        <FormWrapper
            formContext={formContext}
            onSubmit={saveChanges}
            // @ts-ignore
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 0,
                maxHeight: '100%',
            }}
            onlySubmitChangedfields
            resetOnSuccess
        >
            {formRef && <SyncRefWithForm formRef={formRef} />}
            <ListContext.Provider value={listContext}>
                <UserList
                    // react-hook-form can't just "reset" the form state to default values
                    // unless those default values are specified up front in the useForm call.
                    // We're using inline default values at the field level, so that doesn't work.
                    // The solution is just to recreate the list when resetting form state.
                    key={listResetKey}
                    inEditMode
                    users={users}
                    roleOptions={roleOptions}
                    GroupComponent={GroupComponent}
                    UserComponent={UserComponent}
                />

                <SaveChangesBar cancelEdit={cancelEdit} error={error} />
                {children}
            </ListContext.Provider>
        </FormWrapper>
    )
}

const GroupComponent: React.FC<GroupListItemProps> = (props) => {
    const { watch, setValue, register } = useFormContext()
    const { selectedStack } = useAppContext()
    const showRoles = selectedStack?.options?.roles__enabled
    const { data: options } = useRoleListOptions()
    const key = encodeFieldKey(props.group._sid)
    const deletedkey = `${key}.deleted`
    const deleted = watch(deletedkey)
    const remove = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }
    const usersWithoutAccess = props.group.users.filter((user) => !user.role)
    const s = usersWithoutAccess.length > 1 ? 's' : ''
    const listContext = useContext(ListContext)
    const accessDisabled =
        selectedStack?.user_access_disabled || listContext.userList?.user_access_disabled
    return (
        <GroupListItem
            {...props}
            rowContainerStyle={{
                textDecoration: deleted ? 'line-through' : 'none',
                background: accessDisabled ? colors.neutral[100] : undefined,
            }}
            rowContents={
                <>
                    {usersWithoutAccess.length > 0 && (
                        <Tooltip
                            label={
                                <>
                                    {usersWithoutAccess.length} user{s} do not have record{s} in the
                                    user table
                                </>
                            }
                            wrapperStyle={{ marginLeft: '8px', verticalAlign: 'middle' }}
                            display="inline"
                            placement="top"
                        >
                            <Icon icon="alertCircle" size="small" color="alertIcon" mx={2} />
                        </Tooltip>
                    )}
                    <div style={{ flexGrow: 1 }} />
                    <input type="hidden" {...register(deletedkey)} />
                    {!deleted && showRoles && (
                        <FormField
                            maxWidth="150px"
                            width="150px"
                            as={Dropdown}
                            controlledDefaultValue={props.group.role}
                            name={`${key}.role`}
                            options={options}
                            variant="settings"
                            isClearable={false}
                            isSearchable={false}
                            controlled
                        />
                    )}
                    {!deleted && <GroupActions group={props.group} onRemove={remove} />}
                </>
            }
            UserComponent={GroupedUserComponent}
        />
    )
}

const UserComponent: React.FC<UserListItemProps> = (props) => {
    const { watch, setValue, register } = useFormContext()
    const { selectedStack } = useAppContext()
    const showRoles = selectedStack?.options?.roles__enabled
    const { data: options } = useRoleListOptions()
    const userKey = encodeFieldKey(props.user._sid)
    const deletedkey = `${userKey}.deleted`
    const deleted = watch(deletedkey)
    const removeUser = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }
    const hasAccess = props.user.role
    const listContext = useContext(ListContext)
    const accessDisabled =
        selectedStack?.user_access_disabled || listContext.userList?.user_access_disabled
    return (
        <UserListItem
            {...props}
            limitWidth={showRoles}
            rowContainerStyle={{
                color: !hasAccess ? colors.neutral[600] : undefined,
                textDecoration: deleted ? 'line-through' : undefined,
                background: accessDisabled ? colors.neutral[100] : undefined,
            }}
            rowContents={
                <>
                    {!hasAccess && !deleted && <UserNotInTable user={props.user} />}
                    <input type="hidden" {...register(deletedkey)} />

                    <div style={{ flexGrow: 1 }} />
                    {!deleted && showRoles && (
                        <FormField
                            maxWidth="150px"
                            width="150px"
                            as={Dropdown}
                            controlledDefaultValue={props.user.specified_role}
                            name={`${userKey}.role`}
                            options={options}
                            variant="settings"
                            isClearable={false}
                            isSearchable={false}
                            controlled
                        />
                    )}
                </>
            }
            rowActions={!deleted && <UserActions user={props.user} onRemove={removeUser} />}
        />
    )
}

const GroupedUserComponent: React.FC<UserListItemProps> = (props) => {
    const hasAccess = props.user.role
    const { watch } = useFormContext()
    const key = encodeFieldKey(props.user.group)
    const deletedkey = `${key}.deleted`
    const deleted = watch(deletedkey)
    return (
        <UserListItem
            {...props}
            rowContainerStyle={{
                color: !hasAccess ? colors.neutral[600] : undefined,

                textDecoration: deleted ? 'line-through' : undefined,
            }}
            rowContents={
                <>
                    {!hasAccess && <UserNotInTable user={props.user} />}
                    <div style={{ flexGrow: 1 }} />
                </>
            }
            rowActions={!deleted && <UserActions user={props.user} />}
        />
    )
}

// user is unused and can probably be removed but no capacity to investigate further.
// eslint-disable-next-line unused-imports/no-unused-vars
function UserNotInTable({ user }: { user: any }) {
    return (
        <Tooltip
            label={<>No record in the user table</>}
            wrapperStyle={{ marginLeft: '8px', verticalAlign: 'middle' }}
            display="inline"
            placement="top"
        >
            <Icon icon="alertCircle" size="small" color="alertIcon" display="inline-flex" />
        </Tooltip>
    )
}
