import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidColorPaletteProps = {
    color?: string
    size?: number
}
const SolidColorPalette: React.FC<SolidColorPaletteProps> = ({
    color = colors.userInterface.neutral[1100],
    size = 14,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#z5xxxdsgoa)">
                <path
                    d="M5.166 13.752A6.99 6.99 0 0 0 7 14H7.1a2.099 2.099 0 0 0 1.76-.998 2.09 2.09 0 0 0 .108-2.016l-.139-.29c-.317-.66-.068-1.258.272-1.598.34-.34.938-.588 1.596-.271l.29.139a2.093 2.093 0 0 0 2.016-.107 2.098 2.098 0 0 0 .999-1.76 6.946 6.946 0 0 0-.247-1.933C13.027 2.487 10.706.438 7.98.066 5.776-.233 3.61.488 2.05 2.05A6.983 6.983 0 0 0 .067 7.98c.37 2.726 2.42 5.045 5.099 5.772zM9.45 2.8a1.05 1.05 0 1 1 0 2.1 1.05 1.05 0 0 1 0-2.1zm-3.5-.7a1.05 1.05 0 1 1 0 2.1 1.05 1.05 0 0 1 0-2.1zM4.9 9.454a1.05 1.05 0 1 1-2.1 0 1.05 1.05 0 0 1 2.1 0zm-1.75-4.55a1.05 1.05 0 1 1 0 2.1 1.05 1.05 0 0 1 0-2.1z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="z5xxxdsgoa">
                    <path fill="#fff" d="M0 0h14v14H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SolidColorPalette
