import React, { FC } from 'react'

import type { TextProps } from '@chakra-ui/react'

import { Text } from 'v2/ui'

export const InlineLabel: FC<TextProps> = ({ children, ...props }) => (
    <Text px={2} size="sm" color="grey.300" display="inline" {...props}>
        {children}
    </Text>
)
