import React, { ChangeEvent, FC, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import { isUndefined } from 'lodash'

import { useAppContext } from 'app/AppContext'
import isRichTextField from 'utils/isRichTextField'

import { Box, Button, Checkbox, Collapse, Divider, Flex, Input, ScrollBox, Text } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'

import { usePatchableActionContext } from './hooks/usePatchableActionContext'
import { updateStepField } from './helpers'
import { FlexSpacer, Title } from './ui'

type Props = {
    item: any
    step: ActionStep
    object: ObjectDto
    onRequestClose: () => void
}

export const EditField: FC<Props> = ({ item, step, object, onRequestClose }) => {
    const patchableActionContext = usePatchableActionContext()
    if (!patchableActionContext) {
        throw new Error('usePatchableActionContext must be used within a patchableActionContext')
    }

    const { applyPatch, draftObject: action } = patchableActionContext
    const { selectedStack } = useAppContext()

    const [label, setName] = useState(item.label)
    const [value, _setValue] = useState(item.value)
    const [required, setRequired] = useState(item.required)
    const [promptUser, setPromptUser] = useState(item.promptUser)
    const [showCreateButton, setShowCreateButton] = useState(item.showCreateButton)
    const [enableRichTextEditor, setEnableRichTextEditor] = useState(item.enableRichTextEditor)
    const [toggleDefaultValue, setToggleDefaultValue] = useState(!isUndefined(item.value))

    const handleToggleDefaultValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked
        setToggleDefaultValue(newValue)

        // If default value is on, we want to default to 'null' which is
        // a blank blank value. If it is off, then we set to undefined, which
        // signifies that we aren't supplying a default value.
        _setValue(newValue ? null : undefined)
    }

    const field = object.fields.find((x) => x._sid === item.fieldId)

    // see if we should display the "show add button" option.
    // it's only relevant on non-readonly record link fields.
    const allowCreateNewOption =
        selectedStack?.options?.new_create_form &&
        field &&
        !field.connection_options?.read_only &&
        ['lookup', 'multi_lookup'].includes(field.type)

    return (
        <ScrollBox margin="-5px" padding="5px" height="100%">
            <Flex column wrap="nowrap" align="stretch" height="100%">
                <Title>Label</Title>
                <Input
                    value={label}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                />
                <Flex alignItems="center" mt={6}>
                    <Switch
                        isChecked={toggleDefaultValue}
                        onChange={handleToggleDefaultValue}
                        mr={2}
                    />
                    <Text fontSize="0.875rem">Enable default value</Text>
                </Flex>
                {toggleDefaultValue && (
                    <>
                        <Title>Default value for {field.label}</Title>
                        <Box flexBasis={0}>
                            <Attribute
                                field={object.fields.find(({ _sid }) => _sid === item.fieldId)}
                                editable={toggleDefaultValue}
                                onChange={_setValue}
                                size="sm"
                            >
                                {value}
                            </Attribute>
                        </Box>
                    </>
                )}
                <Checkbox
                    isChecked={promptUser}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setPromptUser(event.target.checked)
                    }
                    mt={4}
                >
                    <Text fontSize="0.875rem">Allow user to edit value</Text>
                </Checkbox>
                {isRichTextField(field) && (
                    <Checkbox
                        isChecked={enableRichTextEditor}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setEnableRichTextEditor(event.target.checked)
                        }
                        mt={4}
                    >
                        <Text fontSize="0.875rem">Enable rich text editor</Text>
                    </Checkbox>
                )}
                <Collapse isOpen={!!promptUser}>
                    <Checkbox
                        isChecked={required}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setRequired(event.target.checked)
                        }
                        mt={4}
                    >
                        <Text fontSize="0.875rem">Required</Text>
                    </Checkbox>
                </Collapse>
                <Collapse isOpen={!!(promptUser && allowCreateNewOption)}>
                    <Checkbox
                        isChecked={showCreateButton}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setShowCreateButton(event.target.checked)
                        }
                        mt={4}
                    >
                        Show add new button
                    </Checkbox>
                </Collapse>
                <Box flexGrow={1} />
                <Divider my={8} />
                <Flex justifyContent="flex-end">
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        onClick={() => {
                            onRequestClose()
                        }}
                    >
                        Back
                    </Button>
                    <FlexSpacer />
                    <Button
                        variant="adminPrimary"
                        buttonSize="sm"
                        onClick={() => {
                            applyPatch(
                                updateStepField(action, step.id, item.fieldId, {
                                    label,
                                    value,
                                    required,
                                    promptUser,
                                    enableRichTextEditor,
                                    showCreateButton,
                                })
                            )
                            onRequestClose()
                        }}
                    >
                        Save Field
                    </Button>
                </Flex>
            </Flex>
        </ScrollBox>
    )
}
