// @ts-strict-ignore
import { useMemo } from 'react'
import { useMutation, useQuery as _useQuery } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { objectApi } from 'data/api/objectApi'
import { recordApi } from 'data/api/recordApi'
import { acceptHeaders, fetchWithAuth } from 'data/utils/utils'
import { isBlankPageObject } from 'features/blank-pages/utils'

import { groupFieldsByObjectId } from './fields/fieldOperations'
import { getObject } from './objects/getObject'
import { OBJECT_LIST_NAME } from './objects/objectConstants'
import { createObject, invalidateObjects, updateObject } from './objects/objectOperations'
import { getRecord } from './records/getRecord'
import {
    createRecord,
    deleteRecord,
    invalidateRecords,
    updateRecord,
    updateRecordCache,
} from './records/recordOperations'
import { QueryOptions, useQueryKeyBuilder } from './_helpers'
import { bulkUpdateFields, createField, deleteField, updateField, useFields } from './fields'

type UseObjects = {
    options?: QueryOptions<ObjectDto[]>
    allowBlankPageObjects?: boolean
}

// Include the current auth information (user id, previewed user, previewed role, etc) in the ky
// so that when that info changes we refetch, as the permissions annotations on the objects
// needs updated
function useQueryKey() {
    return useQueryKeyBuilder(OBJECT_LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}

export function useObjects({ options = {}, allowBlankPageObjects }: UseObjects = {}) {
    const queryKey = useQueryKey()
    const { selectedStack } = useAppContext()
    const { data: fields } = useFields()

    const queryResult = _useQuery<ObjectDto[]>(
        queryKey,
        async () => {
            const objects = (await objectApi.get()) as ObjectDto[]
            const fieldsByObj = fields ? groupFieldsByObjectId(fields) : fields

            return objects.map((object) => ({
                ...object,
                fields: fieldsByObj ? fieldsByObj[object?._sid] || [] : [],
            }))
        },
        {
            enabled:
                (!options.disabled || (options.disabledFn && !options.disabledFn())) &&
                !!selectedStack?._sid,
        }
    )

    const data = useMemo(() => {
        const fieldsByObj = fields ? groupFieldsByObjectId(fields) : {}

        return queryResult.data?.reduce<ObjectDto[]>((agg, curr) => {
            if (allowBlankPageObjects || (!allowBlankPageObjects && !isBlankPageObject(curr))) {
                const currObjectFields = fieldsByObj[curr?._sid] || []

                agg.push({
                    ...curr,
                    fields: currObjectFields,
                })
            }

            return agg
        }, []) as ObjectDto[]
    }, [allowBlankPageObjects, fields, queryResult.data])

    return {
        ...queryResult,
        data,
    }
}

const getPrimaryField = (fields: any[] | undefined) => {
    if (!fields) return { api_name: '_sid' }
    const primaries = fields.filter((field) => field.is_primary)
    if (!primaries.length) return { api_name: '_sid' }
    return primaries[0]
}

type RecordActionType = {
    update: (id: string, data: object, options?: any) => Promise<any>
    create: (data: any) => Promise<any>
    remove: (id: string) => Promise<any>
    fetch: (id: string, options?: any) => Promise<any>
    onChange: (id: string, data: object, options?: any) => Promise<any>
    updateRecordCache: (data?: any) => void
    clear: (objectId: string) => void
}

export const useRecordActions = () => {
    const recordActions: RecordActionType = useMemo(() => {
        return {
            update: updateRecord,
            create: createRecord,
            remove: deleteRecord,
            fetch: getRecord,
            onChange: updateRecord,
            updateRecordCache,
            clear: invalidateRecords,
        }
    }, [])

    return recordActions
}

export function useObject(objectId: string | undefined) {
    const { data: objects = [] } = useObjects({ allowBlankPageObjects: true })

    const boundRecordActions = useRecordActions()

    const object = useMemo(() => {
        if (!objectId) return undefined

        return objects.find((obj) => obj._sid === objectId)
    }, [objectId, objects])

    return useMemo(
        () => {
            return {
                object,
                primaryField: getPrimaryField(object?.fields),
                fetch: getObject,
                clear: invalidateObjects,
                onChange: updateObject,
                createField: (data, options = {}) =>
                    createField(
                        {
                            ...data,
                            object_id: object?._sid,
                            feature_id: object?.feature_id,
                        },
                        options
                    ),
                createRecord: (data) =>
                    boundRecordActions.create({ ...data, object_id: object?._sid }),
                updateRecord: boundRecordActions.update,
                createObject,
                changeObject: (data, options = {}) => updateObject(objectId, data, options),
                deleteField,
                changeField: updateField,
                bulkChangeFields: bulkUpdateFields,
                importCsv: (batchId) => recordApi.importCsv(object?._sid, batchId),
                generateSampleRecords: () => recordApi.generateSampleRecords(object?._sid),
                schemaTimestamp: object?.schemaTimestamp,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [object, objectId]
    )
}

type SetDisabledObjectsInput = {
    [obj_sid: string]: boolean
}
export const useDisableObjects = () =>
    useMutation((req_body: SetDisabledObjectsInput) => {
        return fetchWithAuth(`objects/set-disabled/`, {
            method: 'POST',
            headers: acceptHeaders,
            body: JSON.stringify(req_body),
        })
    })

export function getConnectedObjectsFromIDs(
    objectIDs: string[],
    allObjects: ObjectDto[],
    allFeatures: FeatureDto[]
) {
    const objects: ObjectDto[] = []

    objectIDs.forEach((objectSid) => {
        const object = allObjects.find((o) => o._sid === objectSid)

        // do not show object with data_mapping_disabled set true
        if (!object?.connection_options || object.connection_options.data_mapping_disabled) {
            return
        }

        // do not show object without a matching feature
        if (
            !allFeatures ||
            !allFeatures.find((feature) => feature.options.object_id === object._sid)
        ) {
            return
        }

        objects.push(object)
    })

    return objects
}
