import { useContext, useEffect } from 'react'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'

export const useSideMenuButton = (label, isDirty, onClick) => {
    const context = useContext(LayoutEditorContext)

    const updateButton = () => {
        if (context) {
            context.setButton(label, isDirty, onClick)
        }
    }

    useEffect(() => {
        updateButton()

        return () => {
            context && context.clearButton()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label, isDirty, onClick])
}

const SideMenuButton = ({ label, isDirty, onClick }) => {
    useSideMenuButton(label, isDirty, onClick)

    return null
}

export default SideMenuButton
