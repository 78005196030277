import React from 'react'

import { LinkListActions, LinkListContainer, LinkListEmptyState } from 'features/links'
import { UserProfileListHeader } from 'features/userProfiles'

import Button from 'ui/deprecated/atoms/Button'

export const UserProfileModalListView = ({
    userProfiles = [],
    onNewClicked,
    children,
}: {
    children: React.ReactNode
    onNewClicked: () => void
    userProfiles: any[]
}): React.ReactElement => {
    const newButton = (
        // @ts-expect-error
        <Button v4 icon="add" type="primary" onClick={onNewClicked}>
            New User Profile
        </Button>
    )
    return (
        <LinkListContainer
            links={userProfiles}
            header={<UserProfileListHeader direction="horizontal" links={userProfiles} />}
            actions={<LinkListActions>{newButton}</LinkListActions>}
            emptyState={
                <LinkListEmptyState
                    header={<UserProfileListHeader direction="vertical" centered={true} />}
                >
                    {newButton}
                </LinkListEmptyState>
            }
        >
            {children}
        </LinkListContainer>
    )
}
