import React from 'react'

import { Icon, Modal } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Button from 'ui/deprecated/atoms/Button'
import Flex from 'ui/deprecated/atoms/Flex'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'

const colors = stackerTheme().colors

type LargeModalProps = {
    title: string | React.ReactNode
    isOpen: boolean
    onClose?: () => void
    size?: string
    height?: string
    showCloseButton?: boolean
    onSkip?: () => void
}

const LargeModal: React.FC<LargeModalProps> = ({
    title,
    isOpen,
    onClose,
    size,
    height,
    children,
    showCloseButton = true,
    onSkip,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            showCloseButton={false}
            size={size}
            height={height}
            noPadding
            scrollBehavior="inside"
        >
            {/* @ts-ignore */}
            <Flex column style={{ height: '100%' }}>
                {/* @ts-ignore */}
                <Flex
                    style={{
                        padding: '1px 10px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            ...V4DesignSystem.h3,
                        }}
                    >
                        {title}
                    </div>
                    {showCloseButton && (
                        <Button
                            onClick={() => {
                                onClose && onClose()
                                return Promise.resolve()
                            }}
                            type="link"
                        >
                            <Icon icon="x" color={V4DesignSystem.colors.gray[400]} />
                        </Button>
                    )}
                    {onSkip && (
                        <Button
                            type="link"
                            onClick={() => {
                                onSkip && onSkip()
                                return Promise.resolve()
                            }}
                        >
                            Skip
                        </Button>
                    )}
                </Flex>
                {/* @ts-ignore */}
                <Flex
                    style={{
                        borderTop: '1px solid ' + colors.grey[100],
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    {children}
                </Flex>
            </Flex>
        </Modal>
    )
}

export default LargeModal
