import { formatDate } from 'utils/dateUtils'

export type CalendarDate = {
    value: Date
    empty: boolean
    isDateTime: boolean
}

// Formats the dates for the calendar
export default function formatDateForCalendarData(
    date: any,
    endOfDay: boolean = false
): CalendarDate {
    const { dateValue: inputDate } = formatDate(date, '', true)

    if (!inputDate) {
        return {
            value: new Date(1800, 1, 1),
            empty: true,
            isDateTime: false,
        }
    }

    // Date only format
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        let value = inputDate.toDate()
        // For date fields they are set to the start of the day by default
        // For end dates, we want it to be set to the end of the day
        if (endOfDay) {
            value = inputDate.endOf('day').toDate()
        }
        return {
            value,
            empty: false,
            isDateTime: false,
        }
    } else {
        // Date time format
        return {
            value: inputDate.toDate(),
            empty: false,
            isDateTime: true,
        }
    }
}
