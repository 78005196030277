import React, { ChangeEvent } from 'react'

import { Input, Textarea } from 'v2/ui'

import { cleanUpProps } from '../../cleanUpProps'
import { RenderRowAttributeRenderProps } from '../../RenderRowAttribute'

type TextAttributeProps = Omit<RenderRowAttributeRenderProps, 'value' | 'onChange'> & {
    multiline?: boolean
    value?: string
    onChange: (value: string) => void
}

const TextAttribute: React.VFC<TextAttributeProps> = ({
    multiline,
    label,
    onChange,
    value,
    ...otherProps
}) => {
    return multiline ? (
        <Textarea
            variant="admin"
            size="lg"
            fullWidth
            style={{ minHeight: '100px' }}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
            placeholder={label}
            value={value || ''}
            {...cleanUpProps(otherProps)}
        />
    ) : (
        <Input
            value={value || ''}
            placeholder={label}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            {...cleanUpProps(otherProps)}
        />
    )
}

export default TextAttribute
