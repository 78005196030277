import React from 'react'

import { useRadio } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Button } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const IconWrapper = styled.div`
    height: 1.2rem;
    width: 1.2rem;
`

const StyledButton = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &,
    &:hover,
    &:active {
        background: none;
    }

    &:disabled {
        opacity: 0.4;
    }
`

type ViewHeaderPositionRadioProps = React.ComponentPropsWithoutRef<typeof Button> & {
    label: string
    value: PageHeaderImage['position']
    onChange: (newValue: PageHeaderImage['position']) => void
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
    isChecked?: boolean
    isDisabled?: boolean
}

const ViewHeaderPositionRadio = React.forwardRef<HTMLDivElement, ViewHeaderPositionRadioProps>(
    ({ label, value, onChange, icon: Icon, ...props }, ref) => {
        const { getInputProps, state } = useRadio(props)
        const { isChecked, isDisabled } = state
        return (
            <label>
                <input type="radio" {...getInputProps()} hidden />
                <StyledButton
                    aria-checked={isChecked}
                    role="radio"
                    isDisabled={isDisabled}
                    ref={ref}
                    aria-label={label}
                    p={0}
                    {...props}
                    tabIndex={isChecked ? -1 : 0}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                        if (e.key === ' ' || e.key === 'Enter') {
                            e.currentTarget.click()
                        }
                    }}
                    onClick={() => onChange(value)}
                    value={value}
                    rounded={5}
                    as={Box}
                >
                    <IconWrapper>
                        <Icon
                            fill={
                                isChecked
                                    ? colors.userInterface.neutral[900]
                                    : colors.userInterface.neutral[700]
                            }
                        />
                    </IconWrapper>
                </StyledButton>
            </label>
        )
    }
)

export default ViewHeaderPositionRadio
