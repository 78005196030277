import { buildUrl } from 'data/utils/utils'

import { buildSortingRequest } from './buildSortingRequest'
import { chartsFiltersToQueryDict } from './chartsFiltersToQueryDict'
import type { Aggregation } from './types'

const getAggregationColumnName = (fieldApiName: string | undefined, type: string) => {
    const keys = [type]
    if (fieldApiName) {
        keys.push(fieldApiName)
    }
    return keys.join('_')
}

type BuildAggregatesUrlProps = {
    objectId: string
    aggregations: Aggregation[]
    primaryGroupFieldApiName: string | undefined
    secondaryGroupFieldApiName: string | undefined
    filters: ChartFilter[]
    sort?: ChartSort
    bucketBy?: Bucket
    limit?: number
    previousTimePeriod?: boolean
}

export const buildAggregatesUrl = ({
    objectId,
    aggregations,
    primaryGroupFieldApiName,
    secondaryGroupFieldApiName,
    filters,
    sort,
    bucketBy,
    limit,
    previousTimePeriod = false,
}: BuildAggregatesUrlProps) => {
    const path = `objects/${objectId}/aggregates/`

    const filtersQueryDict = chartsFiltersToQueryDict(filters)
    const processedAggregations = aggregations.map((aggregation) => ({
        name: getAggregationColumnName(aggregation.fieldApiName ?? '', aggregation.type),
        field_api_name: aggregation.fieldApiName,
        type: aggregation.type,
    }))
    const sortingRequest = buildSortingRequest(
        sort,
        processedAggregations.map((a) => a.name),
        primaryGroupFieldApiName
    )
    const aggregationsQueryDict = { aggrs: JSON.stringify(processedAggregations) }
    const primaryGroupDict = primaryGroupFieldApiName
        ? { primary_group: primaryGroupFieldApiName }
        : {}
    const secondaryGroupDict = secondaryGroupFieldApiName
        ? { secondary_group: secondaryGroupFieldApiName }
        : {}

    const sortDict = sortingRequest ? { sort: JSON.stringify(sortingRequest) } : {}
    const bucketByDict = bucketBy ? { bucket_by: bucketBy } : {}
    const limitDict = limit ? { limit: limit } : {}
    return buildUrl(path, {
        ...filtersQueryDict,
        ...aggregationsQueryDict,
        ...primaryGroupDict,
        ...secondaryGroupDict,
        ...sortDict,
        ...bucketByDict,
        ...limitDict,
        ...(previousTimePeriod ? { __isPreviousTimePeriod: true } : {}), // Only used for storybook testing
    })
}
