import React, { VFC } from 'react'

import styled from '@emotion/styled'

import { Button, Text } from 'v2/ui'
import { IllustrationSearch } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const Wrapper = styled.div`
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 2px;
`

const NoText = styled(Text)`
    max-width: 100%;

    font-size: 14px;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 12px;
`

type Props = {
    search: string
    onClearSearch: () => void
}

const NoMatchingFieldTypes: VFC<Props> = ({ search, onClearSearch }) => (
    <Wrapper>
        <IllustrationSearch />
        <NoText>{`No data types match the search '${search}'.`}</NoText>
        <Button
            variant="Tertiary"
            size="sm"
            padding="8px"
            color={colors.userInterface.accent[1000]}
            onClick={onClearSearch}
        >
            Clear search
        </Button>
    </Wrapper>
)

export default NoMatchingFieldTypes
