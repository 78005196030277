import stackerDarkTheme from 'legacy/v1/ui/styleHelpers/stackerDarkTheme'
export default {
    ...stackerDarkTheme,
    spaceXXS: '1px',
    xsmall: '2px',
    small: '4px',
    medium: '8px',
    large: '16px',
    xlarge: '32px',
    spaceXXL: '64px',

    fontSize: '9pt',
    fontS: '8pt',
    fontM: '9pt',
    fontL: '10pt',
    fontXL: '12pt',

    borderRadius: '3px',
    elementBorderRadius: '3px',
    smallElementBorderRadius: '3px',
    buttonFontSize: '9pt',
}
