import React, { forwardRef } from 'react'

import { ButtonProps } from '@chakra-ui/react'

import Button from './Button'
import Icon from './Icon'

type Props = Omit<ButtonProps, 'children'>
const CloseButton = forwardRef<HTMLElement | undefined, Props>(({ onClick, ...props }, ref) => {
    return (
        <Button
            ref={ref}
            onClick={onClick}
            variant="iconButton"
            buttonSize="smDense"
            color="grey.400"
            {...props}
        >
            <Icon icon="x" />
        </Button>
    )
})

export default CloseButton
