import React from 'react'

import { LinkFieldLabel } from '../../LinkFieldLabel'

import { LinkFormObjectLabel } from './LinkFormObjectLabel'

export const LinkFormFieldLabel = ({ variant, object, field, showLink }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <LinkFormObjectLabel variant={variant} object={object} />
            <LinkFieldLabel style={{ marginLeft: 5 }} showLink={showLink}>
                {field.label}
            </LinkFieldLabel>
        </div>
    )
}
