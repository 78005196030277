import { useCallback, useMemo } from 'react'

import { findFilterByField } from 'v2/views/utils/findFilterByField'

import { InlineFilterOperation } from '../constants'
import { useRecordFilters } from '../provider/RecordFiltersProvider'

import {
    changeFilterOperation,
    getInlineFilterOp,
    getInlineFilterOpsForType,
    isInlineFilterActive,
    processInlineFilterValue,
} from './utils'
import { getInlineFilterValue } from './valueUtils'

type UseInlineFilterParams = {
    field: FieldDto
}

type UseInlineFilterResult = {
    filter?: Filter
    isActive: boolean
    value: FilterOptions['value']
    onValueChange: (value: FilterOptions['value']) => void
    operation: InlineFilterOperation
    onOperationChange: (operation: InlineFilterOperation) => void
    operations: InlineFilterOperation[]
    onClear: () => void
}

export function useInlineFilter(params: UseInlineFilterParams): UseInlineFilterResult {
    const { field } = params

    const {
        state: { filters },
        onFieldChanged,
        onAddFilter,
        onDeleteFilter,
    } = useRecordFilters()

    const filter = useMemo(() => {
        const filter = findFilterByField(field, filters)
        if (!filter) return undefined

        // Use the field from props, in case we want to override some values.
        return {
            ...filter,
            field,
        }
    }, [field, filters])

    const filterValue = useMemo(() => getInlineFilterValue(filter), [filter])

    const isFilterActive = useMemo(() => isInlineFilterActive(filter), [filter])

    const filterOperation = useMemo(
        () => getInlineFilterOp(filter, field.type),
        [filter, field.type]
    )

    const onValueChange = useCallback(
        (newValue: FilterOptions['value']) => {
            const processedValue = processInlineFilterValue(newValue, field.type)
            const hasValue = processedValue && processedValue.length > 0

            if (filter) {
                onFieldChanged({
                    id: filter._id,
                    ...filter,
                    options: {
                        ...filter.options,
                        value: hasValue ? processedValue : undefined,
                    },
                })
            } else {
                onAddFilter({
                    isNew: true,
                    field,
                    field_sid: field?._sid,
                    options: {
                        option: filterOperation,
                        value: processedValue,
                    },
                })
            }
        },
        [filter, onFieldChanged, onAddFilter, field, filterOperation]
    )

    const onOperationChange = useCallback(
        (newOperation: InlineFilterOperation) => {
            if (filter) {
                const updatedFilter = changeFilterOperation(filter, newOperation)
                onFieldChanged({
                    id: filter._id,
                    ...updatedFilter,
                })
            } else {
                onAddFilter({
                    isNew: true,
                    field,
                    field_sid: field?._sid,
                    options: {
                        value: undefined,
                        option: newOperation,
                    },
                })
            }
        },
        [field, filter, onAddFilter, onFieldChanged]
    )

    const handleClear = useCallback(() => {
        if (!filter?._id) return
        onDeleteFilter(filter._id)
    }, [filter?._id, onDeleteFilter])

    const operations = useMemo(() => getInlineFilterOpsForType(field.type), [field.type])

    return {
        filter,
        isActive: isFilterActive,
        value: filterValue,
        onValueChange,
        onOperationChange,
        operations,
        operation: filterOperation,
        onClear: handleClear,
    }
}
