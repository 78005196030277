import React from 'react'

import Flex from './Flex'
import Icon from './Icon'
const TitleIcon = ({ icon, svg, color = 'v4Blue' }) => {
    const SvgComponent = svg

    return (
        <>
            {svg ? (
                <Icon svg={<SvgComponent />} />
            ) : (
                <Flex
                    rounded="full"
                    bg="gray.200"
                    width="5rem"
                    height="5rem"
                    align="center"
                    justify="center"
                >
                    <Icon icon={icon} size="2.5rem" color={color} />
                </Flex>
            )}
        </>
    )
}
export default TitleIcon
