import React from 'react'

import { ButtonProps } from '@chakra-ui/react'

import { Button } from 'v2/ui'

type Props = Omit<ButtonProps, 'children' | 'variant'> & {
    icon?: string
    label?: string
    buttonSize?: string
    as?: React.FC
    onDeleted?: () => void
    variant?: string
    children?: React.ReactNode
    ignorePreviewAndImpersonation?: boolean
    fieldName?: string
    labelPlacement: 'left' | 'right' | 'top' | 'bottom'
}
export const PrimaryFieldButton: React.FC<Props> = ({ as, icon, ...props }) => {
    const Component = as || Button
    return <Component {...props} icon={icon} />
}

export default PrimaryFieldButton
