import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import queryString from 'query-string'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { getFiltersFromQuery, getQueryFromFilters } from './urlFilterConverters'

//this hook owns filters state and sync it with url
export const useUrlFilters = (object: ObjectDto, syncHistory: boolean = true) => {
    const [filters, setFilters] = useState<Filter[]>([])

    const location = useLocation()
    const history = useHistory()

    //update filters if url changed
    useEffect(() => {
        if (!syncHistory) {
            return
        }
        const queryFromFilters = getQueryFromFilters(filters, object.fields, location.search)
        const queryFromUrl = queryString.parse(location.search)

        //if current filters are the same as in new url, do not sync filters one more time
        if (queryString.stringify(queryFromFilters) === queryString.stringify(queryFromUrl)) {
            return
        }
        const filtersFromQuery: Filter[] = getFiltersFromQuery(queryFromUrl, object.fields)
        setFilters(filtersFromQuery)
        //we drop filters here, as we use it to check if we need sync at all, and don't use it as sync source
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, syncHistory, object?.fields])

    const previousFilters = usePrevious(filters)

    //update history if filters changed
    useEffect(() => {
        if (!syncHistory) {
            return
        }

        //do not sync initial filters state, as query params have higher priority and should override any initial value
        if (!previousFilters) {
            return
        }

        const newQuery = getQueryFromFilters(filters, object.fields, location.search)

        const normalizedSearch =
            location.search.indexOf('?') === 0 ? location.search.substring(1) : location.search
        if (normalizedSearch !== queryString.stringify(newQuery)) {
            // Doing replace here instead of push, as we don't want a new history item for each
            // filter change, unless we are also going to respond to query string changes via back/forward,
            // which we don't currently do.

            history.replace({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify(newQuery),
                state: location.state,
            })
        }
        //we drop location here, as we use it to check if we need sync at all, and don't use it as sync source
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, syncHistory, object?.fields])

    return { filters, setFilters }
}
