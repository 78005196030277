import React from 'react'

import findIndex from 'lodash/findIndex'
import memoize from 'lodash/memoize'
import moment from 'moment'

export var groupBy = function (xs, key) {
    if (typeof xs !== 'object') return {}
    if (typeof xs.reduce !== 'function') return {}
    return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}
// => {3: ["one", "two"], 5: ["three"]}

export var unique = function (a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1]
    })
}

const map = new WeakMap()
export const memoizeTwoArgs = (fn) =>
    memoize(fn, (a, b) => {
        if (typeof a === 'undefined' || typeof b === 'undefined') {
            return Math.random() + 1
        }
        if (!map.has(a)) map.set(a, Math.random())
        if (!map.has(b)) map.set(b, Math.random())

        return map.get(a) + '_' + map.get(b)
    })

export const stripSlash = (path, strict = false) => {
    if (path === '/' && !strict) {
        return path
    } else {
        return path.replace(/\/+$/, '')
    }
}

/** Reorder an array moving an item from its current position
 * (startIndex) to a new position (endIndex)
 */
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

/**A URL should be treated as internal if it matches one of the following:
 - //*hostname*
 - http://*hostname*
 - https://*hostname*
 - starts with / and not //

 See https://www.notion.so/stacker/Link-URL-Handling-Rules-412869fbcbe54de49edc9c1ff894c18b
 */

export const isExternal = (url) => {
    var host = window.location.hostname

    if (!url) return false

    return !(
        url.startsWith(`http://${host}`) ||
        url.startsWith(`https://${host}`) ||
        url.startsWith(`//${host}`) ||
        /^\/[^\/]/.test(url)
    )
}

export function sanitizeURL(url) {
    if (url == null) {
        return url
    }

    const proto = new URL(url).protocol
    // allow http, https, ftp
    // IMPORTANT: Don't allow data: protocol because of:
    // <a href="data:text/html;base64,PHNjcmlwdD5hbGVydCgneHNzJyk7PC9zY3JpcHQ+" target="_blank">here</a>
    if (proto === 'https:' || proto === 'http:' || proto === 'ftp:') {
        return url
    }
    return undefined
}

/**
 * Builds the param for after-login redirect that you can use directly in URLs.
 */
export function getRedirectParam() {
    const { pathname, search, hash } = window.location
    return encodeURIComponent(`${pathname}${search}${hash}`)
}

/** Checks whether a value is blank/empty. Usefull
 *  for checking required fields. The following values
 *  are considered blank:
 *
 * - undefined, null, false, '', Nan,
 * - arrays with no items
 */
export const isBlank = (value) => {
    // the only falsey value that isn't counted as blank is 0
    return (!value && value !== 0) || (Array.isArray(value) && value.length === 0)
}

export const getDecimalPlaces = (value) => {
    const parts = value.toString().split('.')
    return parts.length > 1 ? parts[1].length : 0
}

export const hash = (s) => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

const idComparator = (o1, o2) => o1._id === o2._id

function comparatorToPredicate(comparator, o1) {
    return (o2) => comparator(o1, o2)
}

/**
 * Returns new array with replaced element.
 */
export function replaceAt(array, idx, newElement) {
    return [...array.slice(0, idx), newElement, ...array.slice(idx + 1)]
}

/**
 * Replaces provided element with newElement in array using comparator and returns new array.
 *
 * Uses '_id' field comparator by default.
 */
export function replaceWith(array, newElement, comparator = idComparator) {
    const idx = findIndex(array, comparatorToPredicate(comparator, newElement))
    return replaceAt(array, idx, newElement)
}

/**
 * Checks if URL contains non-empty domain extensions.
 * @param {*} url URL
 * @returns Boolean value indicating if URL is valid or not.
 */
export function isUrlValid(url) {
    if (url) {
        const components = url.split('.')
        // URL has no domain extension: 'test'.
        if (components.length === 1) {
            return false
        } else {
            // Valid URL has non-empty domain extensions.
            // 'test..com' -> ['test', '', '']
            return !components.includes('')
        }
    }
    return false
}

/**
 * Decodes a utf8 string which has been encoded in hex format
 * @param {*} str1 a utf8 string encoded in hex
 * @returns the decoded string
 */
export function hexToString(str1) {
    return str1 ? decodeURIComponent(str1.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')) : str1
}

/**
 * Sort a list of object by a key in increasing order by default
 * @param {*} array Array
 * @param {*} key Key of object in string
 * @returns The sorted array
 */
export function sortByKey(array, key, increasing_order = true) {
    return array.sort(function (a, b) {
        var x = a[key]
        var y = b[key]
        if (increasing_order) {
            return x < y ? -1 : x > y ? 1 : 0
        } else {
            return x < y ? 1 : x > y ? -1 : 0
        }
    })
}

const DATE__MILLISECONDS = {
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 30 * 24 * 60 * 60 * 1000,
    month: 12 * 30 * 24 * 60 * 60 * 1000,
    year: 365 * 12 * 30 * 24 * 60 * 60 * 1000,
}

/**
 * Given a date, it returns the date difference in a message format.
 * For eg, '1 hour ago', '2 weeks ago', etc.
 * @param {*} date Date in string
 * @returns Date difference in string
 */
export function dateDifferenceToString(date) {
    let dateDifference = new Date().valueOf() - new Date(date).valueOf()
    let format = ''

    if (dateDifference < DATE__MILLISECONDS.hour) {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.minute)
        format = dateDifference <= 1 ? 'minute' : 'minutes'
    } else if (dateDifference < DATE__MILLISECONDS.day) {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.hour)
        format = dateDifference <= 1 ? 'hour' : 'hours'
    } else if (dateDifference < DATE__MILLISECONDS.week) {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.day)
        format = dateDifference <= 1 ? 'day' : 'days'
    } else if (dateDifference < DATE__MILLISECONDS.month) {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.week)
        format = dateDifference <= 1 ? 'week' : 'weeks'
    } else if (dateDifference < DATE__MILLISECONDS.year) {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.month)
        format = dateDifference <= 1 ? 'month' : 'months'
    } else {
        dateDifference = Math.floor(dateDifference / DATE__MILLISECONDS.year)
        format = dateDifference <= 1 ? 'year' : 'years'
    }

    return `${dateDifference <= 1 ? 1 : dateDifference} ${format} ago`
}

/**
 * Wraps a component with span if it's of type string.
 * This method is used in preventing translation issues.
 * @param {*} component
 * @returns component
 */
export function wrapStringComponentWithSpan(component, props = {}) {
    if (typeof component === 'string' && component.length > 0) {
        component = (
            <span {...props} className="stk-button-text">
                {component}
            </span>
        )
    }
    return component
}

export class UserCancelledError extends Error {}

/**
 * Convert from one base to another. Only works for integer values, not floats. Careful, with
 * extremely large numbers (bigger than 2^53) it falls over.
 * https://gist.github.com/ryansmith94/91d7fd30710264affeb9
 * https://stackoverflow.com/a/32480941/16538166
 * @param {string} value
 * @param {int} from_base
 * @param {int} to_base
 * @returns
 */
export function convertBase(value, from_base, to_base, capsFirst = false) {
    var range = capsFirst
        ? '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+/'.split('')
        : '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/'.split('')
    var from_range = range.slice(0, from_base)
    var to_range = range.slice(0, to_base)

    var dec_value = value
        .split('')
        .reverse()
        .reduce(function (carry, digit, index) {
            if (from_range.indexOf(digit) === -1)
                throw new Error('Invalid digit `' + digit + '` for base ' + from_base + '.')
            return (carry += from_range.indexOf(digit) * Math.pow(from_base, index))
        }, 0)

    var new_value = ''
    while (dec_value > 0) {
        new_value = to_range[dec_value % to_base] + new_value
        dec_value = (dec_value - (dec_value % to_base)) / to_base
    }
    return new_value || '0'
}

export function ensureArray(value) {
    return Array.isArray(value) ? value : value ? [value] : []
}

export function dateDiff(date1, date2, format) {
    const date = moment(date1)
    const dateFuture = moment(date2)
    const diff = Math.abs(Math.ceil(dateFuture.diff(date, format, true)))

    return diff
}

export const isFreeEmail = (email) => {
    const freeDomains = [
        'gmail',
        'yahoo',
        'aol',
        'hotmail',
        'outlook',
        'mail',
        'protonmail',
        'icloud',
        'live',
    ]
    return freeDomains.some((domain) => email.indexOf(`@${domain}.`) >= 0)
}

export class DeferredPromise {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject
            this.resolve = resolve
        })
    }
}
