import React, { FC } from 'react'

import { useTheme } from '@chakra-ui/react'

type Props = {
    color?: string
    size?: number
}

const SolidMetric: FC<Props> = ({ color, size = 18 }) => {
    const theme = useTheme()
    const iconColor = color || theme.colors.grey[600]

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="2.5"
                fill="transparent"
                stroke={iconColor}
            />
            <path
                d="M3 14.224L5.8335 16.7515C6.01854 16.9163 6.25904 17.0044 6.50675 16.9992C6.75445 16.9941 6.99103 16.8954 7.169 16.723L11.002 13.013L12.7905 14.7225C12.8859 14.8138 12.9985 14.8852 13.1217 14.9326C13.245 14.98 13.3764 15.0025 13.5084 14.9988C13.6403 14.9951 13.7703 14.9652 13.8906 14.9109C14.011 14.8566 14.1194 14.779 14.2095 14.6825L17 11.695L16.27 11.0125L13.48 13.999L11.668 12.267C11.4879 12.0947 11.248 11.9988 10.9987 11.9996C10.7494 12.0003 10.5101 12.0976 10.331 12.271L6.486 15.9935L3.6655 13.478L3 14.224Z"
                fill={iconColor}
            />
            <path
                d="M17 8.5H15L16 7L17 8.5ZM13 5H11V6H13V7H11.5V8H13V9H11V10H13C13.2651 9.9996 13.5192 9.89412 13.7067 9.70667C13.8941 9.51922 13.9996 9.26509 14 9V6C13.9997 5.73486 13.8943 5.48066 13.7068 5.29319C13.5193 5.10571 13.2651 5.00026 13 5ZM10 10H7V8C7.00026 7.73486 7.10571 7.48066 7.29319 7.29319C7.48066 7.10571 7.73486 7.00026 8 7H9V6H7V5H9C9.26514 5.00026 9.51934 5.10571 9.70681 5.29319C9.89429 5.48066 9.99974 5.73486 10 6V7C9.99974 7.26513 9.89429 7.51933 9.70681 7.70681C9.51934 7.89429 9.26514 7.99973 9 8H8V9H10V10ZM5 9V5H4V5.5H3V6.5H4V9H3V10H6V9H5Z"
                fill="#D0D4D9"
            />
        </svg>
    )
}

export default SolidMetric
