// @ts-strict-ignore
import React from 'react'

import ReactFilestack from '../../utils/lazyComponents/ReactFilestack'
import { Button } from '../../v2/ui'

const ImportUpload = ({ onSuccess }) => {
    return (
        <ReactFilestack
            options={{
                accept: '.json',
            }}
            onSuccess={onSuccess}
            customRender={({ onPick }) => (
                <Button marginRight="auto" variant="Primary" buttonSize="small" onClick={onPick}>
                    Import
                </Button>
            )}
        />
    )
}

ImportUpload.displayName = 'ImportUpload'

export default ImportUpload
