import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'

import { withStack } from 'data/wrappers/WithStacks'
import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'
import isRichTextField, { isAirtableRichTextField } from 'utils/isRichTextField'

import { Box, Checkbox, Collapse, Flex, Input, Text, Textarea } from 'v2/ui'
import { isFieldTypePlaceholderSupported } from 'v2/ui/components/Attribute/placeholder'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import isEmptyValue from 'v2/ui/utils/isEmptyValue'
import useDebounce from 'v2/ui/utils/useDebounce'

import Attribute from '../Attribute/Attribute'

import CheckboxAttributeEditor from './CheckboxAttributeEditor'
import PercentAttributeEditor from './PercentAttributeEditor'

const WRAP_FIELD_TYPES = ['string', 'long_text', 'multi_lookup']

const FieldAttributesEditor = ({
    item,
    fields,
    onUpdate,
    hideFieldRequiredSetting,
    hideFieldFullWidthSetting,
    hideFieldDescriptionSetting,
    hideCreateButtonSetting,
    hideRichTextSetting,
    hideColumns,
    hideUrlOptions,
    hideDisabledSearchSetting,
    hideEnableCopyPaste,
    stackOptions,
    display,
    showDefaultValueSetting,
    showPlaceholderSetting,
    isCreate,
}) => {
    const [state, setState] = useState({ ...item })
    const queuedChanges = useRef()

    const itemId = item?.fieldId || item?.id

    const doSendUpdate = useCallback(() => {
        // send all accumulated changes during the debounce period now
        // and reset the queue of changes.
        onUpdate(itemId, queuedChanges.current)
        queuedChanges.current = null
    }, [onUpdate, itemId])

    const sendUpdate = useDebounce(doSendUpdate, 300)

    const field = fields.find((x) => x._sid === item.fieldId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setState({ ...item, label: item.label || (field && field.label) }), [item])
    //disable editing if it's not a list view and field is displayed as button
    const enableLabel = display || !state.displayAsButton

    // Only show 'hide label' checkbox in displays where labels are not always hidden
    const showHideLabel = display !== 'inbox' && display !== 'rows'

    /**
     * List of fields types that can have a "Enable quick copy" setting
     */
    const canBeCopied = [
        'string',
        'long_text',
        'number',
        'url',
        'date',
        'datetime',
        'currency',
        'currency_varying',
        'percentage',
    ].includes(field?.type)

    const handleQuillchange = (value) => {
        queuedChanges.current = { ...queuedChanges.current, description: value }
        sendUpdate()
        setState((state) => ({ ...state, description: value }))
    }

    const handleChange = (e) => {
        const target = e.target
        let value = e.target?.value

        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }

        setState((state) => {
            if (target.id === 'label' && value === field?.label) {
                value = null
            }

            const newState = { ...state, [target.id]: value }
            // since sendUpdate is debounced, we want to batch up any changes together
            // to be processed/sent when the debounced function fires
            queuedChanges.current = { ...queuedChanges.current, [target.id]: value }
            sendUpdate()
            return newState
        })
    }

    const handleDefaultValueChange = (name, value) => {
        setState((state) => {
            const newState = { ...state, [name]: value }
            queuedChanges.current = { ...queuedChanges.current, [name]: value }
            sendUpdate()
            return newState
        })
    }

    const readOnlyField = field?.connection_options?.read_only
    const isFieldEditable = isCreate && !readOnlyField

    let isDefaultValueSupported = isFieldEditable && showDefaultValueSetting
    if (item.type !== 'field') isDefaultValueSupported = false

    const placeholder = state.lockValue ? '' : state.placeholder
    const isPlaceholderDisabled = state.lockValue

    const isPlaceholderSupported = useMemo(() => {
        if (item.type !== 'field') return false
        if (!isFieldEditable || !showPlaceholderSetting) return false

        return isFieldTypePlaceholderSupported(field?.type ?? '')
    }, [field?.type, isFieldEditable, showPlaceholderSetting, item.type])

    return (
        <div className={ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_ITEM_SETTINGS}>
            <Flex>
                <Text flexGrow={2} variant="paletteSectionLabel" mt={0}>
                    Label
                </Text>
                {item.type !== 'section' && showHideLabel && (
                    <Checkbox
                        isDisabled={!enableLabel}
                        id="hideLabel"
                        isChecked={state.hideLabel}
                        onChange={handleChange}
                        variant="admin"
                        mt={2}
                    >
                        Hide label
                    </Checkbox>
                )}
            </Flex>
            <Input
                variant="admin"
                id="label"
                disabled={state.hideLabel || !enableLabel}
                value={state.label}
                onChange={handleChange}
                mb={3}
            />

            {!hideFieldDescriptionSetting && (
                <>
                    <Text variant="paletteSectionLabel">Description</Text>
                    {item.type === 'section' ? (
                        <QuillRichText
                            onChange={handleQuillchange}
                            value={state?.description || ''}
                            convertToMarkdown={false}
                            height="200px"
                        />
                    ) : (
                        <Textarea
                            id="description"
                            variant="admin"
                            value={state.description}
                            onChange={handleChange}
                            width="100%"
                        />
                    )}
                </>
            )}

            {isDefaultValueSupported && (
                <>
                    <Text variant="paletteSectionLabel">Default value (optional)</Text>
                    <Box mb={3}>
                        <Attribute
                            id="defaultValue"
                            variant="admin"
                            editable
                            onChange={(value) => handleDefaultValueChange('defaultValue', value)}
                            field={field}
                        >
                            {state.defaultValue}
                        </Attribute>
                    </Box>
                </>
            )}

            {isPlaceholderSupported && (
                <>
                    <Text variant="paletteSectionLabel">Placeholder text (optional)</Text>
                    <Input
                        variant="admin"
                        id="placeholder"
                        value={placeholder}
                        onChange={handleChange}
                        mb={3}
                        disabled={isPlaceholderDisabled}
                    />
                </>
            )}

            {item.type === 'section' && !hideColumns ? (
                <>
                    <Text variant="paletteSectionLabel">Columns</Text>{' '}
                    <Box pl={1} pr={6}>
                        <Slider
                            flex="1"
                            min={1}
                            max={4}
                            value={state.columns || 3}
                            onChange={(value) =>
                                handleChange({ target: { id: 'columns', value: value } })
                            }
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>

                            <SliderThumb fontSize="sm" width="32px" height="20px">
                                {state.columns || 3}
                            </SliderThumb>
                        </Slider>
                    </Box>
                </>
            ) : null}
            {field && !(hideFieldFullWidthSetting && hideFieldRequiredSetting) && (
                <>
                    <Text variant="paletteSectionLabel">Settings</Text>
                    <Checkbox
                        isDisabled={isDefaultValueSupported && isEmptyValue(state.defaultValue)}
                        id="lockValue"
                        isChecked={state.lockValue}
                        onChange={handleChange}
                        variant="admin"
                    >
                        Read Only
                    </Checkbox>
                    {!hideFieldFullWidthSetting && (
                        <Checkbox
                            id="fullWidth"
                            isChecked={state.fullWidth}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Display as full-width
                        </Checkbox>
                    )}
                    {!hideFieldRequiredSetting && (
                        <Checkbox
                            id="required"
                            isChecked={state.required}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Required
                        </Checkbox>
                    )}

                    {/*
                      we only allow the customer to toggle this on/off if it's an airtable rich text field
                      for stacker data a rich text field always renders with the rich text editor
                    */}
                    {isAirtableRichTextField(field) && !hideRichTextSetting && (
                        <Checkbox
                            id="enableRichTextEditor"
                            isChecked={state.enableRichTextEditor}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Enable rich text editor
                        </Checkbox>
                    )}
                    {!hideEnableCopyPaste && canBeCopied && (
                        <Checkbox
                            id="enableCopyPaste"
                            isChecked={state.enableCopyPaste}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Enable quick copy
                        </Checkbox>
                    )}
                </>
            )}

            {field?.type === 'percentage' && !isCreate && (
                <PercentAttributeEditor
                    selectedOption={state?.percentageDisplay}
                    setState={setState}
                    sendUpdate={sendUpdate}
                    queuedChanges={queuedChanges}
                />
            )}

            {field?.type === 'checkbox' && !isCreate && (
                <CheckboxAttributeEditor
                    selectedOptions={state?.checkboxDisplay}
                    setState={setState}
                    sendUpdate={sendUpdate}
                    queuedChanges={queuedChanges}
                />
            )}

            {field &&
                ['dropdown', 'lookup', 'multi_select', 'multi_lookup'].includes(field.type) &&
                !hideDisabledSearchSetting && (
                    <Checkbox
                        id="disableSearch"
                        isChecked={state.disableSearch}
                        onChange={handleChange}
                        variant="admin"
                    >
                        Disable search
                    </Checkbox>
                )}

            {stackOptions.new_create_form &&
                !hideCreateButtonSetting &&
                field &&
                ['lookup', 'multi_lookup'].includes(field.type) && (
                    <Checkbox
                        id="showCreateButton"
                        isChecked={state.showCreateButton}
                        onChange={handleChange}
                        variant="admin"
                    >
                        Show add new button
                    </Checkbox>
                )}

            {field && field.type === 'string' && !isRichTextField(field) && !hideUrlOptions && (
                <Checkbox
                    id="treatAsUrl"
                    isChecked={state.treatAsUrl}
                    onChange={handleChange}
                    variant="admin"
                >
                    Treat as URL
                </Checkbox>
            )}

            {field && (
                <>
                    <Collapse
                        isOpen={
                            !!(
                                (!hideUrlOptions && field && field.type === 'url') ||
                                state.treatAsUrl ||
                                field.type === 'multi_file'
                            )
                        }
                        pl={state.treatAsUrl ? 4 : 0}
                        width="100%"
                    >
                        <Checkbox
                            id="displayAsImage"
                            isChecked={state.displayAsImage}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Display as image
                        </Checkbox>
                        {state.displayAsImage && (
                            <>
                                <Text variant="paletteSectionLabel">Image Alt Text</Text>
                                <Input
                                    id="imageAltText"
                                    variant="admin"
                                    value={state.imageAltText}
                                    onChange={handleChange}
                                    width="100%"
                                    mb={3}
                                />
                            </>
                        )}
                    </Collapse>

                    <Collapse
                        isOpen={
                            !!(
                                (!hideUrlOptions && field && field.type === 'url') ||
                                state.treatAsUrl
                            )
                        }
                        pl={state.treatAsUrl ? 4 : 0}
                        width="100%"
                    >
                        {!state.displayAsImage && (
                            <>
                                <Checkbox
                                    id="displayAsButton"
                                    isChecked={state.displayAsButton}
                                    onChange={handleChange}
                                    variant="admin"
                                >
                                    Display as button
                                </Checkbox>

                                <Checkbox
                                    id="openInNewTab"
                                    isChecked={state.openInNewTab}
                                    onChange={handleChange}
                                    variant="admin"
                                >
                                    Open in new tab
                                </Checkbox>
                                <Text variant="paletteSectionLabel">Link Text</Text>
                                <Input
                                    variant="admin"
                                    id="buttonTitle"
                                    value={state.buttonTitle}
                                    onChange={handleChange}
                                    mb={3}
                                />
                            </>
                        )}
                    </Collapse>
                    {field &&
                        WRAP_FIELD_TYPES.includes(field.type) &&
                        (display === 'table' || display === 'inbox') && (
                            <Checkbox
                                id="wrapText"
                                isChecked={state.wrapText}
                                onChange={handleChange}
                                variant="admin"
                            >
                                Wrap text
                            </Checkbox>
                        )}
                </>
            )}
        </div>
    )
}
export default withStack(FieldAttributesEditor)
