import React from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Button, Flex, Text } from 'v2/ui'

import analytics from '../../../utils/analytics'

import { ProviderTypes } from './dataConnectorTypes'

type DeprecatedConnectionScreenScreen = {
    provider: ProviderTypes
    onContinue: () => void
    studioUser?: UserDto | null
    selectedStack?: StackDto | null
}

export const DeprecatedConnectionScreen: React.FC<DeprecatedConnectionScreenScreen> = ({
    provider,
    onContinue,
    studioUser,
    selectedStack,
}) => {
    const { showManageData } = useSlidingPane()
    const { hasRight } = useAccountUserContext()

    const onSelectStackerTables = () => {
        showManageData()
        analytics.track('continue with stacker tables was clicked', {
            workspace_id: studioUser?.account_id,
            user_id: studioUser?._sid,
            app_id: selectedStack?._sid,
            event_category: 'app',
            event_description: 'Continued with stacker tables',
            datasource_type: provider,
        })
    }

    const onContactSupport = () => {
        onContinue()
        // @ts-expect-error
        window.Intercom(
            'showNewMessage',
            `Hi, I am unable to upgrade my Airtable connector at ${window.location.host}.`
        )
    }

    return (
        <Flex column align="stretch" mt={6}>
            <Text mb={2}>
                We&apos;re sorry, but this integration is no longer available for new connections.
            </Text>
            <Text>
                However, we&apos;ve got great alternatives that offer even more functionality.
                Easily{' '}
                <a
                    href="https://support.stackerhq.com/hc/en-us/articles/11054464278675-Importing-data-from-CSV"
                    target="_blank"
                    rel="noreferrer"
                >
                    import existing data
                </a>{' '}
                into Stacker tables from a CSV.
            </Text>
            <div style={{ height: 24 }} />
            <Flex width="100%" column justifyContent="space-between">
                <Button
                    width={'100%'}
                    onClick={onSelectStackerTables}
                    buttonSize="medium"
                    variant="Primary"
                    mb={2}
                >
                    Continue with Stacker Tables
                </Button>
                {
                    // @ts-expect-error
                    hasRight(Rights.ContactSupport) && window.Intercom && (
                        <Button
                            width={'100%'}
                            buttonSize="medium"
                            variant="Secondary"
                            onClick={onContactSupport}
                        >
                            Contact Support
                        </Button>
                    )
                }
            </Flex>
        </Flex>
    )
}
