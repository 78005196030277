import { useMemo } from 'react'

import { LayoutEditorAttributeProps } from 'v2/blocks/types'

import { useProcessFilter } from 'features/records/components/RecordFilters'

export function useAttributeRecordFilter<B extends Block>(
    attrs: LayoutEditorAttributeProps<B>['attrs'],
    record?: RecordDto | {},
    filtersArgName = 'filters'
): boolean {
    const processFilter = useProcessFilter()

    return useMemo(() => {
        const filters = attrs[filtersArgName as keyof typeof attrs]
        // If there is a  conditional visibility filter
        // evaluate its condition and do not display on view mode
        // This method returns True if it is filtered => not visible
        if (!attrs || !filters || !record) return false

        const records = [record]
        // Reuse existing records filter method
        // @ts-expect-error
        const filteredRecords = processFilter(records, filters)
        // Because we only filter 1 record on this detail record view
        // this will return either 1 (visible) or 0 (not visible)
        return filteredRecords.length === 0
    }, [attrs, record, processFilter, filtersArgName])
}
