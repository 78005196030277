export const CHART_BLOCK_HEIGHT = 250
export const HARD_LIMIT_MAX_ITEMS = 20
export const DEFAULT_MAX_ITEMS = 5
export const LINE_CHART_MAX_ITEMS = 12
export const MAX_NUM_CHARTS = 3

export const NEW_CHART_ATTRIBUTES: Omit<ChartBlockAttributes, 'order'> = {
    title: '',
    hideTitle: false,
    objectId: '',
    type: 'Pie',
    aggregate: {
        type: 'count',
    },
    display: {
        label: '',
        decimalPlaces: 'max2',
        abbreviation: 'none',
        showLabels: true,
        showValues: true,
    },
    filters: [],
    showRelated: false,
    relatedField: {
        label: '',
        value: '',
        objectId: '',
        fieldId: '',
        isExternalField: false,
    },
}
