import { useLayoutEffect, useRef } from 'react'

const useLockBodyScroll = (enable) => {
    const isLocked = useRef(false)

    const lockBodyScroll = () => {
        // can't cancel a scroll event, so this will keep it in the same position
        // if the scroll is somehow unlocked and then locks it again
        const x = window.scrollX
        const y = window.scrollY
        window.scrollTo(x, y)
        document.body.style.overflow = 'hidden'
        isLocked.current = true
    }

    const unlockBodyScroll = () => {
        document.removeEventListener('scroll', lockBodyScroll)
        if (isLocked.current) {
            document.body.style.overflow = ''
            isLocked.current = false
        }
    }

    useLayoutEffect(() => {
        if (enable) {
            // we need an event listener as another component could
            // potentially unlock the scroll
            document.addEventListener('scroll', lockBodyScroll)
            lockBodyScroll()
        } else {
            unlockBodyScroll()
        }

        return () => {
            unlockBodyScroll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enable])
}

export default useLockBodyScroll
