import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

//@ts-ignore
import Button from 'ui/deprecated/atoms/Button'

/**
 * If you change the form default behaviour (ie mode="onSubmit")
 * you need to pass <SubmitButton disabled={false} ...
 * @param {{requireChanges?: boolean, children: any, style?: any} & any} props
 */

type SubmitButtonProps = {
    as?: any
    onValidityChange?: (valid: boolean) => void
    requireChanges?: boolean
    [propName: string]: any // Allow any additional props for destructuring
}
const SubmitButton: React.FC<SubmitButtonProps> = ({
    as,
    onValidityChange,
    requireChanges,
    ...props
}) => {
    const { formState } = useFormContext()

    useEffect(() => {
        if (onValidityChange) {
            onValidityChange(formState.isValid)
        }
    }, [formState.isValid, onValidityChange])

    const hasChanges = useMemo(() => {
        return !requireChanges || Object.keys(formState.dirtyFields).length > 0
    }, [requireChanges, formState])

    const ButtonComponent = as || Button
    return (
        <ButtonComponent
            submit
            disabled={!formState.isValid || !hasChanges}
            isLoading={formState.isSubmitting}
            {...props}
        />
    )
}

export default SubmitButton
