// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import { FlexProps } from '@chakra-ui/react'
import { orderBy, trim } from 'lodash'

import { useAppContext } from 'app/AppContext'
import { workspaceGroups } from 'data/hooks/accounts'
import { Divider } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Divider as HeaderDivider, Flex, Icon, ScrollBox, SearchInput, Text } from 'v2/ui'

import { OldVirtualizedList } from '../../../v2/ui/components/OldVirtualizedList'

import GroupListItem from './GroupListItem'
import { GroupListItemType } from './groupListItemTypes'
import UserListItem from './UserListItem'

type GroupComponentType = React.ElementType<{
    group: GroupListItemType
    key: string
    visible: boolean
    filterUser: (user: any) => boolean
    hasSearchValue: boolean
}>
type UserComponentType = React.ElementType<{
    user: any
    key: string
    visible: boolean
}>

type UserListProps = {
    inEditMode?: boolean
    users: any[]
    showHeader?: boolean
    roleOptions?: any[]
    UserComponent?: UserComponentType
    GroupComponent?: GroupComponentType
    hideGroups?: boolean
    containerProps?: FlexProps
    disableScrollBox?: boolean
    disableSort?: boolean
}

export default function UserList(props: UserListProps) {
    const { selectedStack } = useAppContext()
    const { users, showHeader } = props
    const [filterValue, setFilterValue] = useState('')
    const sharingSettings = selectedStack?.sharing
    const handleFilterChange = useCallback((value) => {
        setFilterValue(trim(value))
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterUser = (user) => {
        if (filterValue) {
            const searchValue = filterValue.toLowerCase()
            const terms = [user.name?.toLowerCase(), user.email?.toLowerCase()]
            return Boolean(terms.find((x) => x?.includes(searchValue)))
        }

        return true
    }

    const isNUMa =
        selectedStack?.options?.sync_users_as_auths &&
        selectedStack?.combined_optional_features?.sync_stacker_users
    const showRoles = selectedStack?.options?.roles__enabled

    const [groups, ungroupedUsers] = useMemo<[GroupListItemType[] | undefined, any[]]>(() => {
        const sortedUsers = !props.disableSort
            ? orderBy(users, (user) => (user.name?.toString() || user.email).toLowerCase())
            : users
        if (props.hideGroups) return [undefined, sortedUsers]

        const ungroupedUsers: any[] = []
        const groups: GroupListItemType[] = workspaceGroups
            .map((group) => ({
                ...group,
                users: [],
                role: sharingSettings?.groups?.[group._sid],
            }))
            .filter((group) => group.role)

        sortedUsers.forEach((user) => {
            const group = groups.find(({ _sid }) => _sid === user.group)

            if (group) {
                group.users.push(user)
            } else {
                ungroupedUsers.push(user)
            }
            return groups
        })

        return [groups, ungroupedUsers]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, workspaceGroups, props.disableSort, props.hideGroups, sharingSettings])

    const GroupComponent: GroupComponentType = props.GroupComponent || GroupListItem
    const UserComponent: UserComponentType = props.UserComponent || UserListItem

    // Get the list of rows to render, groups + ungrouped users.
    const rows = useMemo(
        () =>
            (groups?.filter((group) => !filterValue || group.users.find(filterUser)) ?? []).concat(
                ungroupedUsers.filter(filterUser)
            ),
        [groups, ungroupedUsers, filterUser, filterValue]
    )

    // Render method for rendering a given row
    const renderRow = useCallback(
        ({ item }) => {
            if (item.users) {
                return (
                    <div key={item._sid}>
                        <GroupComponent
                            group={item}
                            key={item._sid}
                            visible
                            filterUser={filterUser}
                            hasSearchValue={!!filterValue}
                        />
                        <Divider noMargin />
                    </div>
                )
            } else {
                return (
                    <div key={item._sid}>
                        <UserComponent user={item} key={item._sid} visible />
                        <Divider noMargin />
                    </div>
                )
            }
        },
        [filterValue, filterUser, GroupComponent, UserComponent]
    )

    return (
        <Flex
            column
            border="1px solid"
            borderColor="gray.200"
            borderRadius="5px"
            flexGrow={1}
            flexShrink={1}
            minHeight={0}
            wrap="nowrap"
            align="stretch"
            {...props.containerProps}
        >
            <SearchInput
                // @ts-ignore
                width="100%"
                // @ts-ignore
                variant="noBorder"
                // @ts-ignore
                borderBottom="1px solid"
                // @ts-ignore
                borderColor="gray.100"
                // @ts-ignore
                value={filterValue}
                // @ts-ignore
                onChange={handleFilterChange}
            />
            {/* @ts-ignore */}
            <ScrollBox
                id="inner"
                maxHeight={!props.disableScrollBox ? '100%' : undefined}
                flexGrow={1}
                overflowY={!props.disableScrollBox ? 'auto' : 'unset'}
                overflowX={!props.disableScrollBox ? 'hidden' : 'unset'}
            >
                {showHeader && <UserListHeader showRoles={showRoles} isNUMa={isNUMa} />}

                <OldVirtualizedList
                    estimatedItemSize={33}
                    items={rows}
                    // The virtualized list seems to sometimes glitch when the number of items
                    // changes, so we're just recreating completely in that case
                    key={rows.length}
                    renderItem={renderRow}
                />
            </ScrollBox>
        </Flex>
    )
}

export function WorkspaceRoleLabel({ role, ...rest }) {
    return (
        <Flex {...rest}>
            {role?.icon && (
                <div
                    style={{
                        width: 18,
                        flexShrink: 0,
                        marginRight: '0.3rem',
                    }}
                >
                    {/* @ts-ignore */}
                    <Icon
                        icon={role?.icon}
                        color={role?.iconColor}
                        size="12px"
                        style={{
                            justifyContent: 'center',
                        }}
                    />
                </div>
            )}
            <span
                style={{
                    position: 'relative',
                    top: '2px',
                }}
            >
                {role?.label}
            </span>
        </Flex>
    )
}

const UserListHeader = ({ showRoles, isNUMa }) => {
    return (
        <Box position="sticky" top={0} bg="white" zIndex={10}>
            <Flex wrap="nowrap" my={2} fontWeight="bold" fontSize="sm" px={2}>
                <Text color="neutral.800" style={{ width: '50%', flexShrink: 0 }}>
                    Name / Email
                </Text>
                <div style={{ flexGrow: 1 }}></div>

                {isNUMa ? (
                    <Text color="neutral.800" style={{ width: '150px' }}>
                        Registered
                    </Text>
                ) : (
                    <div style={{ width: '150px' }}></div>
                )}

                {showRoles && (
                    <Text color="neutral.800" style={{ width: '150px' }}>
                        Role
                    </Text>
                )}

                {isNUMa && <div style={{ width: '40px' }}></div>}
            </Flex>
            <HeaderDivider />
        </Box>
    )
}
