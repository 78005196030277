import React, { VFC } from 'react'

import { LoadingSpinner } from 'features/admin/data-connector/ui'

import * as SvgIcons from 'v2/ui/svgs'

import Box from './Box'
import Flex from './Flex'
import Icon from './Icon'
import Text from './Text'

type SearchingStateProps = React.ComponentPropsWithoutRef<typeof Box> & {}

const SearchingState: VFC<SearchingStateProps> = () => {
    return (
        <>
            <Box
                textAlign="center"
                alignItems="center"
                p={['emptyState.padding', null, null, 'emptyState.paddingLg']}
            >
                <Icon
                    justifyContent="center"
                    svg={<SvgIcons.EmptySearch />}
                    mb={[2, null, null, 3]}
                    color="emptyState.icon"
                />
                <Flex justifyContent="center" flexDirection="column" pb={5}>
                    <Text variant="emptyState" mb={5}>
                        Searching for records
                    </Text>
                    <LoadingSpinner />
                </Flex>
            </Box>
        </>
    )
}

export default SearchingState
