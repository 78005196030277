import {
    AUGMENTED_DATA_FIELD_TYPES,
    MAGIC_SYNTHETIC_FIELD_TYPES,
    SYNTHETIC_FIELD_TYPES,
} from '../../../../data/utils/fieldDefinitions'
import {
    getIsDropdownField,
    getIsFieldLink,
    getIsSymmetricRelationshipField,
    getIsSyntheticField,
} from '../../../../utils/fieldUtils'
import { getAppUserModel } from '../../../core/stackUtils'
import {
    EditableFieldTypeDefinition,
    editableFieldTypeDefinitionList,
} from '../definitions/editableFieldTypeDefinitions'
import { CreatableFieldTypeKey } from '../definitions/fieldTypeDefinitions'

export const connectionSupportsEditingFieldTypes: DataConnectionType[] = [
    'native_tables',
    'gsheets',
    'postgresV2',
]

export const unsupportedTypes: Partial<Record<DataConnectionType, FieldType[]>> = {
    gsheets: ['file', 'currency_varying'],
    native_tables: ['file', 'currency_varying', 'image'],
}

export const numOnlyFieldTypes: CreatableFieldTypeKey[] = ['created_by', 'updated_by']

// list of target field types which are always unsupported for conversion to for specified data connections.
// (these types are unsupported regardless of the source field type)
export const unsupportedConversionTypes: Partial<Record<DataConnectionType, FieldType[]>> = {
    gsheets: ['lookup', 'multi_lookup', 'multi_file', 'multi_select', 'datetime'],
    postgresV2: [
        'checkbox',
        'date',
        'datetime',
        'currency_varying',
        'formula',
        'image',
        'lookup',
        'multi_lookup',
        'file',
        'multi_file',
        'multi_select',
        'password',
        'user_ref',
        'user_role',
    ],
}

// list of allowed conversion between types for the given data connection types, from current type to allowed types
// If a field type doesn't appear at all in the conversions, it's assumed that there are no restriction for conversions
// for that type
export const allowedConversionTypes: Partial<
    Record<DataConnectionType, Partial<Record<FieldType, FieldType[]>>>
> = {
    postgresV2: {
        string: ['string', 'long_text', 'url', 'dropdown'],
        url: ['string', 'long_text', 'url', 'dropdown'],
        dropdown: ['string', 'long_text', 'url', 'dropdown'],
        long_text: ['string', 'long_text', 'url', 'dropdown'],

        number: ['number', 'currency', 'percentage'],
        currency: ['number', 'currency', 'percentage'],
        percentage: ['number', 'currency', 'percentage'],

        // a postgres object can have fields with the following type, but we don't want these to be convertable to
        // anything else
        checkbox: [],
        date: [],
        datetime: [],
    },
}

const _getIsAllowedFieldConversion = (
    existingField: Partial<FieldDto>,
    fieldTemplate: EditableFieldTypeDefinition['field_template'],
    connectionType?: DataConnectionType
): boolean => {
    // only allow conversion to other augmented field types if the existing field is augmented
    const isExistingFieldAugmented = !!existingField.is_stacker_augmented_field
    // first check that the target field type is supported independent of current field type
    let isSupportedConversionType =
        connectionType && fieldTemplate.type
            ? !unsupportedConversionTypes[connectionType]?.includes(fieldTemplate.type)
            : false

    if (connectionType && fieldTemplate.type && existingField?.type) {
        const allowedTargetTypes = allowedConversionTypes[connectionType]?.[existingField.type]
        if (allowedTargetTypes != undefined) {
            // target types can be undefined if the DC type doesn't restrict conversions
            isSupportedConversionType =
                isSupportedConversionType && allowedTargetTypes.includes(fieldTemplate.type)
        }
    }
    // only allow dynamic fields to be used with other dynamic fields and non-dynamic fields to be used with other non-dynamic fields
    const isSameSyntheticFieldTemplate =
        getIsSyntheticFieldTemplate(fieldTemplate) == getIsSyntheticFieldTemplate(existingField)

    return isSameSyntheticFieldTemplate && (isSupportedConversionType || isExistingFieldAugmented)
}

export const getAvailableFieldTypes = ({
    account,
    connectionType,
    existingField,
    stack,
}: {
    account: Account
    connectionType: DataConnectionType | undefined
    existingField?: Partial<FieldDto>
    stack: StackDto
}): EditableFieldTypeDefinition[] => {
    const supportedTypesForEdition = connectionType
        ? editableFieldTypeDefinitionList.filter(
              (x) =>
                  !x.field_template.type ||
                  !unsupportedTypes[connectionType]?.includes(x.field_template.type)
          )
        : editableFieldTypeDefinitionList

    const supportedTypesForCreation =
        getAppUserModel(stack, account) === 'num'
            ? supportedTypesForEdition
            : supportedTypesForEdition.filter((x) => !numOnlyFieldTypes.includes(x.value))

    if (existingField) {
        return supportedTypesForEdition.filter((f) =>
            _getIsAllowedFieldConversion(existingField, f.field_template, connectionType)
        )
        // Otherwise, if this isn't a stacker native table, then only dynamic fields
        // can be added
    } else if (connectionType === 'stacker_users') {
        return supportedTypesForCreation.filter(
            ({ field_template }) =>
                getIsSyntheticFieldTemplate(field_template) &&
                !getIsMagicFieldTemplate(field_template)
        )
    } else if (connectionType !== 'native_tables') {
        return supportedTypesForCreation.filter((field) =>
            getIsFieldAvailableOutsideStackerTables(field.field_template)
        )
    } else {
        return supportedTypesForCreation
    }
}

const magicFieldTypeSet = new Set<string>(MAGIC_SYNTHETIC_FIELD_TYPES)

const syntheticFieldTypeSet = new Set<string>(SYNTHETIC_FIELD_TYPES)

const augmentedFieldTypeSet = new Set<string>(AUGMENTED_DATA_FIELD_TYPES)

export const getIsMagicFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.synthetic_field_type && magicFieldTypeSet.has(field.synthetic_field_type)

export const getIsSyntheticFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.synthetic_field_type && syntheticFieldTypeSet.has(field.synthetic_field_type)

export const getIsAugmentedDataFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.type && augmentedFieldTypeSet.has(field.type)

const getIsFieldAvailableOutsideStackerTables = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => {
    const isUsableSyntheticField =
        getIsSyntheticFieldTemplate(field) && !getIsMagicFieldTemplate(field)
    const isUsableAugmentedDataField = getIsAugmentedDataFieldTemplate(field)
    return isUsableSyntheticField || isUsableAugmentedDataField
}

export const getIsFieldConfigurable = ({
    dataConnectionType,
    field,
}: {
    dataConnectionType?: DataConnectionType
    field: FieldDto
}): boolean => {
    const isSyntheticField = getIsSyntheticField(field)
    const isLink = getIsFieldLink(field)
    const isDropdown = getIsDropdownField(field)

    return (
        (dataConnectionType && connectionSupportsEditingFieldTypes.includes(dataConnectionType)) ||
        isSyntheticField ||
        field.is_stacker_augmented_field ||
        isLink ||
        isDropdown
    )
}

export const getCanDeleteField = ({
    field,
    object,
}: {
    field?: FieldDto
    object?: ObjectDto
}): boolean => {
    const isNativeTableField = object?.connection_options?.stacker_native_object
    // If it's a new field, or it's an existing field that is dynamically generated
    const isStackerDynamicField = getIsSyntheticField(field ?? undefined)
    // Symmetric fields can only be removed from the source side of the relationship
    const isSymmetricRelationship = getIsSymmetricRelationshipField(field ?? undefined)
    return (
        !!field &&
        (isNativeTableField || isStackerDynamicField || field.is_stacker_augmented_field) &&
        !isSymmetricRelationship &&
        !field.connection_options.is_protected &&
        !field.is_primary &&
        !field.connection_options.is_shadowed
    )
}

const getCanChangeFieldType = ({
    dataConnection,
    field,
}: {
    dataConnection?: DataConnectionDto
    field: FieldDto
}): boolean => {
    // When the field is not protected, allow the field type to be changed if:
    // 1. We're not editing an existing field
    // 2. or we are editing an existing field and
    //    a) the connection type supports changing field types and this isn't a dynamic field (which we don't support changing at this time)
    //    b) or the connection type doesn't support changing field types and this is an augmented field
    const isFieldEditableInConnection =
        connectionSupportsEditingFieldTypes.includes(dataConnection?.type as DataConnectionType) &&
        !getIsSyntheticField(field)
    return (
        !field?.connection_options.is_protected &&
        (isFieldEditableInConnection || field.is_stacker_augmented_field)
    )
}

export const getFieldEditionData = ({
    account,
    dataConnection,
    field,
    stack,
}: {
    account: Account
    dataConnection?: DataConnectionDto
    field?: FieldDto
    stack: StackDto
}): {
    allowedTypes: EditableFieldTypeDefinition[]
    canChangeFieldType: boolean
} => {
    const canChangeFieldType =
        !field ||
        (!field?.connection_options.is_protected &&
            getCanChangeFieldType({ dataConnection, field: field }))
    const allowedTypes =
        canChangeFieldType && dataConnection
            ? getAvailableFieldTypes({
                  account,
                  connectionType: dataConnection.type,
                  existingField: field ?? undefined,
                  stack,
              })
            : editableFieldTypeDefinitionList
    return { allowedTypes, canChangeFieldType }
}

export const getCanChooseTableForLink = (
    // Ideally we should be using this next approach, but @storybook/react overwrites the native TS
    // utility type Parameters, so we need to keep the params in sync.
    // params: Parameters<typeof getFieldEditionData>
    params: {
        account: Account
        dataConnection?: DataConnectionDto
        field?: FieldDto
        stack: StackDto
    }
): boolean => {
    const { allowedTypes, canChangeFieldType } = getFieldEditionData(params)
    return (
        canChangeFieldType &&
        !!allowedTypes.find(
            (type) => type.value === 'lookup' && !type.field_template.synthetic_field_type
        )
    )
}

export const getAreObjectRecordsEditable = (object: ObjectDto): boolean =>
    !object.connection_options.read_only || object.fields.some(isFieldAugmentedAndEditable)

export const isFieldAugmentedAndEditable = (field: FieldDto): boolean =>
    field.is_stacker_augmented_field && !field.connection_options.read_only
