import { useMemo } from 'react'
import type { UseQueryOptions } from 'react-query'

import sortBy from 'lodash/sortBy'

import { useAppContext } from 'app/AppContext'
import { REACT_QUERY } from 'data/utils/constants'

import { useQuery } from './_helpers'
import { isDisabled } from './roles'

export function useStackRoles(options: UseQueryOptions<any, any> = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    const enabled = options.enabled !== false && !isDisabled(selectedStack, workspaceAccount)
    const result = useQuery(
        REACT_QUERY.roles.listName,
        REACT_QUERY.roles.endpoint,
        { ...options, enabled },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
            bypassMatchingStackCheck: true,
        }
    )
    const finalResult = useMemo(() => {
        const roles = Array.isArray(result?.data) ? result?.data : []
        return {
            isLoading: result.isLoading,
            data: sortBy(roles, [(role) => role.label.toLowerCase()]).filter(
                (item) => item.stack_id === selectedStack?._sid
            ),
        }
    }, [result.data, result.isLoading, selectedStack?._sid])
    return finalResult
}

// Returns the roles for a stack (excluding the internal_admin role)
// structured as list items for easily displaying in a dropdown
export function useRoleListOptions(idField = 'api_name') {
    const { workspaceAccount } = useAppContext()
    const query = useStackRoles()
    return useMemo(() => {
        let data
        if (query.data) {
            data = query.data
                .filter(
                    (role) =>
                        role.api_name !== 'internal_admin' ||
                        !workspaceAccount?.optional_features?.sync_stacker_users
                )
                .map((role) => ({ value: role[idField], label: role.label }))
        }

        return { isLoading: query.isLoading, data }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.data, query.isLoading, idField])
}
