import React from 'react'

// @ts-expect-error
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

/**
 *
 * @param {{ variant: 'primary' | 'secondary', children: any, style: object }} param0
 * @returns
 */
export const LinkFormBackgroundContainer = ({ style = {}, variant = 'primary', children }) => {
    return (
        <span
            style={{
                whiteSpace: 'nowrap',
                background: V4DesignSystem.linkBackground[variant],
                padding: '3px 4px',
                borderRadius: 4,
                ...style,
            }}
        >
            {children}
        </span>
    )
}
