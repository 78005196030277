import React from 'react'

import { Text } from 'v2/ui'

import { DataConnector, ErrorDetails } from '../dataConnectorTypes'

type SchemaSyncErrorProps = {
    provider: DataConnector
    error: string
    details?: ErrorDetails
}

export const SchemaSyncError: React.FC<SchemaSyncErrorProps> = ({ provider, error, details }) => {
    return (
        <>
            <Text variant="error" mb={3}>
                There was an error connecting to your {provider.name}.
            </Text>

            <Text mb={3}>
                Get in touch at <a href="support@stackerhq.com">support@stackerhq.com</a> with the
                following error code and we&apos;ll help you get set up.
            </Text>
            <Text>Error code: {error}</Text>
            {details?.sentry_event_id && <Text>Error log: {details.sentry_event_id}</Text>}
        </>
    )
}
