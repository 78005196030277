import TurndownService from 'turndown'

/**
 * Setup turndown with custom rules.
 */
const turndownService = new TurndownService({
    headingStyle: 'atx',
    bulletListMarker: '-',
    defaultReplacement: function (content, node) {
        return node.isBlock ? '\n' + content + '\n' : content
    },
    blankReplacement: function (content, node) {
        return node.isBlock ? content + '\n' : ''
    },
    keepReplacement: function (content, node) {
        return node.isBlock ? '\n' + node.outerHTML + '\n' : node.outerHTML
    },
})

function repeat(character, count) {
    return Array(count + 1).join(character)
}

/**
 * Indentation classNames will be included when user clicks on bullet, indent it
 * then change to another block type(header, blockquote).
 */
function getIndentationFromClass(className) {
    let indentation = ''
    if (className) {
        // Get last character of class name as they are ql-indent-1 to ql-indent-8.
        const level = parseInt(className.slice(className.length - 1))
        indentation = repeat('    ', level)
    }
    return indentation
}

turndownService.addRule('strikethrough', {
    filter: ['del', 's', 'strike'],
    replacement: function (content) {
        return '~~' + content + '~~'
    },
})

turndownService.addRule('code-block', {
    filter: ['pre'],
    replacement: function (content) {
        // Turndown escapes asterisk, underscore and backslash by adding backslash in front of them.
        // However only in a code-block, Airtable adds backslash in front of escape characters.
        // This causes extra backslash to be added.
        // To solve this, replace backslash in front of '*' and '_'. ('\*' -> '*', '\_' -> '_')
        // Then, replace double backslash with single backslash.
        content = content.replaceAll('\\*', '*').replaceAll('\\_', '_').replaceAll('\\\\', '\\')
        return '\n```\n' + content + '\n```\n'
    },
})

turndownService.addRule('blockquote', {
    filter: 'blockquote',

    replacement: function (content, node) {
        const prefixPattern = /^\s*>[ ]/
        content = content.replace(/^\n+|\n+$/g, '')
        content = content.replace(/^/gm, '> ')

        var prefix = content.match(prefixPattern)[0]
        content = content.replace(prefixPattern, '')
        var indentation = getIndentationFromClass(node.getAttribute('class'))

        return '\n' + prefix + indentation + content + '\n'
    },
})

turndownService.addRule('heading', {
    filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    replacement: function (content, node, options) {
        var hLevel = Number(node.nodeName.charAt(1))
        var indentation = getIndentationFromClass(node.getAttribute('class'))
        if (options.headingStyle === 'setext' && hLevel < 3) {
            var underline = repeat(hLevel === 1 ? '=' : '-', content.length)
            return '\n' + indentation + content + '\n' + underline + '\n'
        } else {
            // Will always go through here for 'atx' heading style.
            // If header's content is empty, return a space with new line to prevent turndown from ignoring it.
            if (content.trim() === '') {
                return '\u00a0\n'
            }
            return '\n' + repeat('#', hLevel) + indentation + ' ' + content + '\n'
        }
    },
})

turndownService.addRule('paragraph', {
    filter: 'p',

    replacement: function (content) {
        return '\n' + content + '\n'
    },
})

turndownService.addRule('list', {
    filter: ['ul', 'ol'],

    replacement: function (content, node) {
        var parent = node.parentNode
        if (parent.nodeName === 'LI' && parent.lastElementChild === node) {
            return '\n' + content
        } else {
            return '\n' + content + '\n'
        }
    },
})

// Detect list item and checkbox.
turndownService.addRule('listItem', {
    filter: 'li',

    replacement: function (content, node, options) {
        content = content
            .replace(/^\n+/, '') // remove leading newlines
            .replace(/\n+$/, '\n') // replace trailing newlines with just a single one
            .replace(/\n/gm, '\n    ') // indent
        var prefix = options.bulletListMarker + ' ' // Syntax for list item (-, + or *)
        var parent = node.parentNode

        // Ordered list
        if (parent.nodeName === 'OL') {
            var start = parent.getAttribute('start')
            var index = Array.prototype.indexOf.call(parent.children, node)
            prefix = (start ? Number(start) + index : index + 1) + '. ' // Eg: '1. ', '2. '.
        } else if (node.getAttribute('data-checked') !== null) {
            // Checkbox
            prefix = node.getAttribute('data-checked') === 'true' ? '- [x] ' : '- [ ] '
        }
        return prefix + content + (node.nextSibling && !/\n$/.test(content) ? '\n' : '')
    },
})

turndownService.addRule('break', {
    filter: 'br',

    replacement: function (content, node, options) {
        return options.br + '\n'
    },
})

export const customTurndownService = turndownService
