/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */
import React from 'react'
import ReactTooltip from 'react-tooltip'

import { css, jsx } from '@emotion/react'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import matchSorter from 'match-sorter'

import { Button, Icon, Label } from 'legacy/v1/ui'

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

export const filterBoolean = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = row.values[id]
        const value = filterValue === '1' || filterValue === true
        return rowValue == value
    })
}

filterBoolean.autoRemove = (val) => !val

export const filterDropdown = (rows, id, value) => {
    if (!value) return rows

    const { filterType, filterValue } = value

    if (Array.isArray(filterValue)) {
        filterValue.sort()
    }

    return rows.filter((row) => {
        let rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
            rowValue = rowValue.sort()
        }

        switch (filterType) {
            case 'contains':
                return Array.isArray(rowValue)
                    ? intersection(rowValue, filterValue).length > 0
                    : filterValue.includes(rowValue)
            case 'containsNone':
                return Array.isArray(rowValue)
                    ? intersection(rowValue, filterValue).length < 1
                    : !filterValue.includes(rowValue)
            case 'is':
                return isEqual(filterValue, rowValue)
            case 'isNot':
                return !isEqual(filterValue, rowValue)
        }
    })
}

filterDropdown.autoRemove = (val) => !val

// Define a default UI for filtering
export function DefaultColumnFilter({ filterValue, setFilter }) {
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder="Search..."
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ filterValue, setFilter, preFilteredRows, id }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach((row) => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({ filterValue, setFilter, preFilteredRows, id }) {
    // Calculate the min and max
    // using the preFilteredRows
    const [min, max] = React.useMemo(() => {
        let min = 0
        let max = 0
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({ filterValue = [], setFilter }) {
    return (
        <div
            css={css`
                display: flex;
            `}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder="Min"
                css={css`
                    width: 70px;
                    margin-right: 0.5rem;
                `}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder="Max"
                css={css`
                    width: 70px;
                    margin-left: 0.5rem;
                `}
            />
        </div>
    )
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

export function booleanFilter({
    column: { filterValue, setFilter, id },
    filter: { filterOnText, filterOffText, filterOnIcon, filterOffIcon },
}) {
    const filterText = (icon, text) => {
        if (icon && text) {
            const tooltipId = `${id}-${icon}`
            return (
                <>
                    <ReactTooltip id={tooltipId} effect="solid" place="top">
                        <Label size="fontS" padding="none" margin="none" style={{ color: 'white' }}>
                            {text}
                        </Label>
                    </ReactTooltip>
                    <Icon data-tip data-for={tooltipId} icon={icon} />
                </>
            )
        }

        if (icon) {
            return <Icon icon={icon} />
        }

        return text
    }

    return (
        <>
            {(filterOnText || filterOnIcon) && (
                <Button
                    size="small"
                    style={{
                        margin: 5,
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontSize: '0.8em',
                        color: filterValue === true ? '#FFFFFF' : '',
                        background: filterValue === true ? '#2980b9' : '',
                    }}
                    onClick={() => setFilter(filterValue !== true ? true : null)}
                >
                    {filterText(filterOnIcon, filterOnText)}
                </Button>
            )}

            {(filterOffText || filterOffIcon) && (
                <Button
                    size="small"
                    style={{
                        margin: 5,
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontSize: '0.8em',
                        color: filterValue === false ? '#FFFFFF' : '',
                        background: filterValue === false ? '#2980b9' : '',
                    }}
                    onClick={() => setFilter(filterValue !== false ? false : null)}
                >
                    {filterText(filterOffIcon, filterOffText)}
                </Button>
            )}
        </>
    )
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val
