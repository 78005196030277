import React from 'react'

// @ts-ignore
import { Collapse, Input, Text } from 'v2/ui'

// @ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export type InputFieldProps = React.InputHTMLAttributes<any> & {
    id: string
    label?: string
    placeholder: string
    onChange: (e: any) => void
    fields: any
    fieldErrors: any
    showErrors: boolean
    highlight: any
}
export const InputField: React.FC<InputFieldProps> = ({
    id,
    label,
    placeholder,
    onChange,
    fields,
    fieldErrors,
    showErrors,
    highlight,
    ...props
}) => {
    return (
        <>
            {label && (
                <label
                    style={{ ...V4DesignSystem.label, color: V4DesignSystem.colors.text }}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <Input
                id={id}
                variant="admin"
                onChange={onChange}
                placeholder={placeholder}
                value={fields[id]}
                isInvalid={Boolean(showErrors && fieldErrors[id]) || highlight}
                mt={2}
                mb={2}
                {...props}
            />
            <Collapse isOpen={!!(showErrors && fieldErrors[id])}>
                <Text variant="error" mb={2} fontSize="sm">
                    {fieldErrors[id]}
                </Text>
            </Collapse>
        </>
    )
}
