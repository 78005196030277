import React, { memo, useCallback } from 'react'

import { Icon, Radio, Tooltip } from 'v2/ui'
import type { RadioProps } from 'v2/ui/components/Radio'

type CreateViewControlsProps = {
    setConfig: (config: any) => void
    detailView: string
    useLayoutFrom?: string
} & Omit<RadioProps, 'onChange' | 'options'>

const CreateViewControls: React.FC<CreateViewControlsProps> = ({
    setConfig,
    detailView,
    useLayoutFrom,
    ...props
}) => {
    const handleChange = useCallback(
        (value) => {
            if (value === 'detailView') {
                setConfig({ use_layout_from: detailView })
            } else {
                setConfig({
                    use_layout_from: null,
                })
            }
        },
        [setConfig, detailView]
    )

    const CREATE_VIEW_OPTIONS = [
        {
            label: 'Use detail layout',
            value: 'detailView',
            tooltip: (
                <Tooltip
                    placement="top"
                    label="All fields from the detail layout will appear in the same order"
                >
                    <Icon ml={1} mb="2px" icon="info" size="sm" color="grey.700" />
                </Tooltip>
            ),
        },
        {
            label: 'Customize your create layout',
            value: 'customFields',
            tooltip: (
                <Tooltip
                    placement="top"
                    label="Customize the fields and widgets that appear on your create layout"
                >
                    <Icon ml={1} mb="2px" icon="info" size="sm" color="grey.700" />
                </Tooltip>
            ),
        },
    ]

    return (
        <Radio
            style={{ marginTop: '1em' }}
            onChange={handleChange}
            options={CREATE_VIEW_OPTIONS}
            value={useLayoutFrom ? 'detailView' : 'customFields'}
            {...props}
        />
    )
}

export default memo(CreateViewControls)
