/* eslint-disable object-shorthand */
import React from 'react'

import { Spinner } from '@chakra-ui/react'
import Color from 'color'

import { withStack } from 'data/wrappers/WithStacks'
import { Icon8 } from 'legacy/v1/ui/index'

import { Button, Cell, Select } from './Styles'

// Inclusive range [from, from+1, ... to-1, to]
const range = (from, to) => {
    return Array.from({ length: to - from + 1 }, (v, k) => k + from)
}

const buttonsToShow = (pageOptions, pageIndex, hideLast) => {
    // Generate the list of buttons, e.g.:
    // 1 *2* 3 4 5
    // 1 ... 5 6 *7* 8 9 ... 14
    // 1 *2* 3 4 5 ... 14
    // 1 ... 10 11 12 13 *14*
    // 1 2 3 4 *5* 6 7 ... 14      [Do not replace a single number with '...']
    // 1 ... 4 5 *6* 7 8 ... 14
    // 1 ... 8 9 *10* 11 12 13 14    [Do not replace a single number with '...']
    // 1 ... 7 8 *9* 10 11 ... 14
    const count = pageOptions.length
    const windowWidth = 5
    const halfWindow = 2

    let first = []
    let middle = []
    let last = []

    // All in one run
    if (count <= windowWidth + 2) {
        first = range(1, count)
    } else if (pageIndex < windowWidth) {
        // Selected page is near the start, with a gap afterwards
        first = range(1, Math.max(windowWidth, pageIndex + halfWindow + 1))
        if (!hideLast) last = [count]
    } else if (pageIndex >= count - windowWidth) {
        // Selected page is near the end, with a gap before
        first = [1]
        last = range(Math.min(count - windowWidth + 1, pageIndex + 1 - halfWindow), count)
    } else {
        // Selected page is in the middle, with two gaps
        first = [1]
        middle = range(pageIndex + 1 - halfWindow, pageIndex + 1 + halfWindow)
        if (!hideLast) last = [count]
    }

    return { first, middle, last }
}

const PaginationButton = ({ children, highlight, color, ...otherProps }) => {
    const style = { border: 'none', backgroundColor: 'transparent', color: color }
    const highlightStyle = { backgroundColor: color, color: 'white' }
    if (highlight) {
        Object.assign(style, highlightStyle)
    }
    return (
        <Button {...otherProps} style={style}>
            {children}
        </Button>
    )
}

const renderButtons = (buttons, gotoPage, selected, color) =>
    buttons.map((pageNum) => {
        const isSelected = pageNum === selected
        return (
            <PaginationButton
                key={pageNum}
                color={color}
                onClick={() => gotoPage(pageNum - 1)}
                highlight={isSelected}
            >
                {pageNum}
            </PaginationButton>
        )
    })

const Pagination = ({
    pageSize,
    pageIndex,
    pageOptions,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    display,
    stackOptions,
    hideLast,
    isLoading,
}) => {
    const color = (stackOptions.theme && stackOptions.theme.brandColor) || '#3272d9'
    const hexString = Color(color).hex().slice(1)

    if (pageOptions && pageOptions.length > 1) {
        const pageSizeOptions = display === 'list' ? [5, 10, 25, 50, 100] : [6, 12, 30, 60, 90]
        const buttons = buttonsToShow(pageOptions, pageIndex, hideLast)
        const ellipsis = <span style={{ color: color }}> ⋯ </span>
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Cell style={{ border: 'none' }}>
                    <Select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {pageSizeOptions.map((size) => (
                            <option key={size} value={size}>
                                Show {size} per page
                            </option>
                        ))}
                    </Select>
                </Cell>
                {isLoading && <Spinner />}
                <Cell style={{ marginLeft: 'auto' }}>
                    <PaginationButton
                        color={color}
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <Icon8
                            icon="less-than--v4"
                            displaySize="12"
                            style={{ marginBottom: '2px' }}
                            color={hexString}
                        />
                        <span>Previous</span>
                    </PaginationButton>
                    {renderButtons(buttons.first, gotoPage, pageIndex + 1, color)}
                    {buttons.middle.length
                        ? [ellipsis, renderButtons(buttons.middle, gotoPage, pageIndex + 1, color)]
                        : null}
                    {buttons.last.length
                        ? [ellipsis, renderButtons(buttons.last, gotoPage, pageIndex + 1, color)]
                        : null}
                    <PaginationButton
                        color={color}
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <span>Next</span>
                        <Icon8
                            icon="more-than--v4"
                            displaySize="12"
                            style={{ marginBottom: '2px', marginLeft: '5px' }}
                            color={hexString}
                        />
                    </PaginationButton>
                </Cell>
            </div>
        )
    } else {
        return null
    }
}

export default withStack(Pagination)
