import React, { useContext, useMemo } from 'react'

import { AppUserContext } from 'app/AppUserContext'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import { Button, Tooltip } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

const EditButton = ({ config, record, className = '', ...props }) => {
    const { tooltip } = props
    const { role } = useContext(AppUserContext)
    const processFilter = useProcessFilter()

    // Note: need to re-filter upon role change
    const visible = useMemo(
        () => record && processFilter([record], config.conditions).length > 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record, config.conditions, role]
    )

    if (!visible) return null

    const mergedClassName = `${className} ${STYLE_CLASSES.EDIT_BUTTON}`

    return (
        <Tooltip disabled={!tooltip} label={tooltip}>
            <Button
                variant="App"
                buttonSize="medium"
                icon="edit"
                ml={2}
                className={mergedClassName}
                {...props}
            >
                Edit
            </Button>
        </Tooltip>
    )
}
export default EditButton
