import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { orderBy, trim } from 'lodash'
import moment from 'moment'
import { bindActionCreators } from 'redux'

import { useAppContext } from 'app/AppContext'
import { userActions } from 'data/api/userApi'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { useAppUsers } from 'data/hooks/users/main'
import { Frame } from 'features/workspace/WorkspaceSettingsModalUi'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import {
    ActionList,
    Avatar,
    Box,
    Button,
    Divider,
    Flex,
    LoadingScreen,
    ScrollBox,
    SearchInput,
    Text,
} from 'v2/ui'
import { OldVirtualizedList } from 'v2/ui/components/OldVirtualizedList'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { useAppUserContext } from '../../../app/AppUserContext'

import { useSharingLinkModal } from './SharingLinkModal'
import useSendWelcomeEmailModal from './useWelcomeEmailModal'

const filterUser = (user, filterValue) => {
    if (filterValue) {
        const searchValue = filterValue.toLowerCase()

        const terms = [user.name?.toLowerCase(), user.email?.toLowerCase()]
        return Boolean(terms.find((x) => x?.includes(searchValue)))
    }

    return true
}
const CustomerAccessUsersList = () => {
    const { selectedStack } = useAppContext()
    const { previewAsUser } = useAppUserContext()
    const { data: users, isFetching: isUsersLoading } = useAppUsers()
    const [isLoading, setIsLoading] = useState()
    const { data: roles } = useStackRoles()
    const [filterValue, setFilterValue] = useState()
    const dispatch = useDispatch()
    const boundUserActions = bindActionCreators(userActions, dispatch)
    const customerAccessEnabled = selectedStack?.options?.enable_external_access

    const impersonateUser = useCallback(
        (userId) => {
            setIsLoading(true)
            return boundUserActions.impersonate(userId).then((response) => {
                previewAsUser(response)
                setIsLoading(false)
            })
        },
        [setIsLoading, boundUserActions, previewAsUser]
    )

    const renderRow = useCallback(
        ({ item: user }) => {
            return (
                <>
                    <UserListItem
                        user={user}
                        roles={roles}
                        key={user._sid}
                        onImpersonate={impersonateUser}
                    />
                    <Divider />
                </>
            )
        },
        [roles, impersonateUser]
    )

    const handleFilterChange = useCallback((value) => {
        setFilterValue(trim(value))
    }, [])

    const filteredUsers = useMemo(
        () =>
            orderBy(
                users?.filter(
                    (user) => user.type === 'customer' && filterUser(user, filterValue)
                ) || [],
                'name'
            ),
        [users, filterValue]
    )
    return (
        <Frame title="Users from all customer lists" overflowY="hidden">
            <Flex
                column
                maxHeight="100%"
                width="100%"
                flexGrow={1}
                flexShrink={1}
                minHeight={0}
                wrap="nowrap"
                align="stretch"
            >
                <LoadingScreen
                    isLoading={isLoading || isUsersLoading}
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                >
                    <SearchInput
                        width="100%"
                        variant="admin_white"
                        value={filterValue}
                        onChange={handleFilterChange}
                    />
                    <ScrollBox maxHeight="100%" flexGrow={1} overflowY="auto" mt={2}>
                        <Box position="sticky" top={0} bg="white" zIndex={10}>
                            <Divider opacity={0} />
                            <Flex wrap="nowrap" my={2} fontWeight="bold" fontSize="sm" px={2}>
                                <Text width="200px" color="neutral.800" flexShrink={0} flexGrow={1}>
                                    Name / Email
                                </Text>
                                <Text width="100px" color="neutral.800" flexShrink={0}>
                                    Registered
                                </Text>

                                <Text width="100px" color="neutral.800" flexShrink={0}>
                                    Role
                                </Text>
                                <Flex width="70px" flexShrink={0} />
                            </Flex>
                            <Divider />
                        </Box>
                        {!filteredUsers.length && (
                            <Text
                                m={10}
                                color="neutral.800"
                                fontSize="sm"
                                fontStyle="italic"
                                alignSelf="center"
                                textAlign="center"
                            >
                                {customerAccessEnabled
                                    ? 'No matching users.'
                                    : 'Customer Access is currently disabled.'}
                            </Text>
                        )}
                        <OldVirtualizedList
                            estimatedItemSize={33}
                            items={filteredUsers}
                            // The virtualized list seems to sometimes glitch when the number of items
                            // changes, so we're just recreating completely in that case
                            key={filteredUsers.length}
                            renderItem={renderRow}
                        />
                    </ScrollBox>
                </LoadingScreen>
            </Flex>
        </Frame>
    )
}

function UserListItem({ user, roles, onImpersonate }) {
    const roleId = user?.membership_options?.role
    const role = useMemo(() => roles.find((x) => x.api_name === roleId), [roles, roleId])
    const { show: showSharingLink } = useSharingLinkModal()
    const { show: showSendWelcomeEmail } = useSendWelcomeEmailModal(user)
    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    const { selectedStack } = useAppContext()

    const sendWelcomeEmail = () => {
        showSendWelcomeEmail()
    }
    const actions = [
        { label: 'Send Welcome Email', action: sendWelcomeEmail, disabled: !user.email },
    ]

    if (!selectedStack?.options?.disable_sharing_links) {
        actions.push({
            label: 'Sharing link...',
            action: () => showSharingLink({ userId: user._sid, userName: user.name }),
        })
    }

    const registered = user?.membership_options?.registered
        ? moment(user?.membership_options?.registered).format('L')
        : null
    return (
        <Flex wrap="nowrap" my={2} fontSize="sm" px={2}>
            <Flex
                width="200px"
                flexShrink={1}
                flexGrow={1}
                wrap="nowrap"
                minWidth={0}
                mr={2}
                className={isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK}
            >
                <Avatar src={user.avatar} name={user.name} size="sm" mr={2} />
                <Box flexShrink={1} minWidth={0}>
                    <Text
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                    >
                        {user.name}
                    </Text>
                    <Text
                        color="neutral.800"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                    >
                        {user.email}
                    </Text>
                </Box>
            </Flex>
            <Text
                width="100px"
                flexShrink={0}
                color={!registered ? 'neutral.800' : null}
                fontStyle={!registered ? 'italic' : null}
            >
                {registered || 'pending'}
            </Text>

            <Text width="100px" flexShrink={0}>
                {role?.label}
            </Text>
            <Flex width="70px" justify="flex-end" wrap="noWrap" flexShrink={0}>
                <Button
                    variant="adminSecondaryV4"
                    buttonSize="smDense"
                    icon="play"
                    iconColor="neutral.800"
                    size="xs"
                    onClick={() => onImpersonate(user._sid)}
                />
                <ActionList
                    variant="iconButton"
                    buttonSize="smDense"
                    icon="dotsH"
                    color="neutral.800"
                    actions={actions}
                ></ActionList>
            </Flex>
        </Flex>
    )
}

export default CustomerAccessUsersList
