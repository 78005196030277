import React, { useMemo } from 'react'
import FullStory, { FullStoryAPI } from 'react-fullstory'

import get from 'lodash/get'
import moment from 'moment'

import settings from 'app/settings'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'

// On non-prod environments, we mix several envs in one fullstory project, so we use the env name to distinguist the user IDs.
const environmentPrefix = () => {
    return settings.IS_PROD ? '' : `${settings.DOMAIN}#`
}

const getUserIdentifier = (stackOptions, stackId, userId, email) => {
    // If this is a customers full story script then just send
    // the user email
    if (stackOptions.fullstory_org_id) {
        return email
    }

    return `${environmentPrefix()}${stackId}#${userId}`
}

const FullStoryIdentifier_ = ({
    user, // withUser
    studioUser, // withUser
    isLoggedIn, // withUser
    isStudioUser, // withUser
    stack, //withStack
    stackOptions, //withStack
    objects, //withObjects
}) => {
    const emailField = useMemo(() => {
        if (!isLoggedIn || !stack || !objects) return false
        const userObjectId = stackOptions?.data_mapping?.user_object
        const emailFieldId = stackOptions?.data_mapping?.user_email_field
        const userObject = objects.find((object) => object._sid == userObjectId)
        return userObject?.fields.find((f) => f._sid === emailFieldId)
    }, [stackOptions, objects, isLoggedIn, stack])

    if (isLoggedIn && stack) {
        const stackId = stack._sid
        const sharedTags = {
            host: window.location.host,
            domain: settings.DOMAIN,
            stack_id: stackId,
            portal_type: stack.portal_type,
            launched: stack.launched,
            plan: get(stack, 'subscription.plan'),
        }
        if (isStudioUser) {
            const userIdentifier = getUserIdentifier(
                stackOptions,
                stackId,
                studioUser._sid,
                studioUser.email
            )
            try {
                FullStoryAPI('identify', userIdentifier, {
                    displayName: studioUser.email,
                    name: studioUser.name,
                    email: studioUser.email,
                    studio_user: true,
                    ...sharedTags,
                })
            } catch {
                // silently ignore any errors
            }
        } else {
            if (emailField) {
                const name = user.name || user._primary
                const email = emailField ? get(user, emailField.api_name, name) : name
                const userIdentifier = getUserIdentifier(stackOptions, stackId, user._sid, email)

                try {
                    FullStoryAPI('identify', userIdentifier, {
                        displayName: name,
                        name: name,
                        studio_user: false,
                        ...sharedTags,
                    })
                } catch {
                    // silently ignore any errors
                }
            }
        }
    }

    return null
}
const FullStoryWrapper_ = ({ user, stack, stackOptions }) => {
    const daysSinceUserSignedUp = useMemo(() => {
        // If for some reason created_date is not available, default to recording the session
        if (!user?.created_date) {
            return 0
        }

        return moment().diff(moment(user.created_date), 'days')
    }, [user])

    if (
        localStorage.getItem('support_login') ||
        !stack ||
        // Only record sessions for users that signed up 7 days ago or less
        // unless fullstory_force flag set in stack settings
        (daysSinceUserSignedUp > 7 && stackOptions?.fullstory_force !== true)
    ) {
        return null
    }

    const FULL_STORY_ORG_ID = stackOptions.fullstory_org_id || settings.FULL_STORY_ORG_ID

    return (
        <>
            <FullStoryIdentifier />
            <FullStory org={FULL_STORY_ORG_ID} />
        </>
    )
}

export const FullStoryWrapper = withUser(withStack(FullStoryWrapper_))

const FullStoryIdentifier = withUser(withStack(withObjects(FullStoryIdentifier_)))
