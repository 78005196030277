// @ts-strict-ignore
import React, { useEffect, useMemo, useState } from 'react'

import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { useObject } from 'data/hooks/objects'
import { useUserProfileObjects } from 'data/hooks/userProfiles'
import { doesFieldTypeMatch } from 'data/utils/ts_utils'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import MultiUserProfilesDropdown from 'features/studio/ui/MultiUserProfilesDropdown'
import UserProfilesDropdown from 'features/studio/ui/UserProfilesDropdown'

import { Button, Dropdown, Flex, Text } from 'v2/ui'

const CURRENT_USER_OPTION = {
    SET_TO_CURRENT_USER: 'setToCurrentUser',
    SET_TO_CURRENT_USER_FIELD: 'setToCurrentUserField',
}

type CreatedByEditorProps = {
    userTable: string
    object: ObjectDto
    changeObject: (options) => void
}

const CreatedByEditor: React.FC<CreatedByEditorProps> = ({ userTable, object, changeObject }) => {
    const { object: userObject } = useObject(userTable)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    const { selectedStack } = useAppContext()

    const [createdByUserField, setCreatedByUserField] = useState(
        object?.options?.created_by_user_field
    )
    const [createdByField, setCreatedByField] = useState(
        object?.options?.created_by_field || 'noneType'
    )
    const userProfileObjects = useUserProfileObjects(userObject?._sid)

    const allFields = [
        ...(userObject?.fields || []),
        ...userProfileObjects?.flatMap((object) => object.fields),
    ]

    const labelMap = {
        [CURRENT_USER_OPTION.SET_TO_CURRENT_USER]: 'Set to current user',
        [CURRENT_USER_OPTION.SET_TO_CURRENT_USER_FIELD]: "Set to current user's",
    }

    const getInitialCurrentUserOption = () => {
        const createdByFieldObject = object?.fields?.find((field) => field._sid === createdByField)

        const createdByUserFieldObject = allFields?.find(
            (field) => field.api_name === createdByUserField
        )

        const option =
            object?.options?.created_by_filter ||
            (['lookup', 'multi_lookup'].includes(createdByUserFieldObject?.type)
                ? CURRENT_USER_OPTION.SET_TO_CURRENT_USER
                : CURRENT_USER_OPTION.SET_TO_CURRENT_USER_FIELD)

        return createdByFieldObject ? option : null
    }

    const [currentUserOption, setCurrentUserOption] = useState<string | null | undefined>(
        getInitialCurrentUserOption()
    )

    const hasChanged =
        object?.options?.created_by_user_field !== createdByUserField ||
        object?.options?.created_by_field !== createdByField ||
        currentUserOption !== getInitialCurrentUserOption()

    // Gets all the fields on the user object which are of compatible type
    // with the selected local field. Only single-link relationship fields are included.
    const getMatchingUserFields = (fieldSid, fields, forUserTable = false) => {
        const localField = object?.fields?.find((field) => field._sid === fieldSid)

        return localField && localField.options?.lookup_target !== userTable
            ? fields
                  ?.filter(
                      (field) =>
                          // don't allow a multi-lookup field to be used as the value for a single lookup field
                          (localField.type !== 'lookup' || field.type !== 'multi_lookup') &&
                          (forUserTable
                              ? !field.connection_options?.is_disabled &&
                                doesFieldTypeMatch(field, localField) &&
                                field.object_id === userTable
                              : !field.connection_options?.is_disabled &&
                                doesFieldTypeMatch(field, localField))
                  )
                  .map((field) => ({ label: field.label, value: field.api_name }))
            : null
    }

    const filterUserFieldSelect = (userObject, userProfiles) => {
        if (userProfiles.length > 1) {
            const options = userProfiles.map((object) => ({
                label: object._sid,
                profile: object,
                options: getMatchingUserFields(createdByField, object.fields),
            }))

            return (
                <MultiUserProfilesDropdown
                    id="multi-user-profiles"
                    groupedOptions={options}
                    value={createdByUserField}
                    onChange={(field) => {
                        setCreatedByUserField(field)
                    }}
                    isClearable={false}
                    style={{ flex: 1 }}
                ></MultiUserProfilesDropdown>
            )
        } else {
            let options = []

            if (userProfiles.length === 1) {
                options = getMatchingUserFields(createdByField, userProfiles[0].fields)
            } else {
                options = getMatchingUserFields(createdByField, userObject?.fields)
            }

            return (
                <UserProfilesDropdown
                    id="user-profiles"
                    options={options}
                    value={createdByUserField}
                    onChange={(field) => {
                        setCreatedByUserField(field)
                    }}
                    isClearable={false}
                    style={{ flex: 1 }}
                ></UserProfilesDropdown>
            )
        }
    }

    const getFilterOptionsForType = (fieldSid, object, userObject, userProfiles) => {
        const localField = object?.fields?.find((field) => field._sid === fieldSid)
        if (!localField) return null

        // user field links to the user table
        if (localField.options?.lookup_target === userTable) {
            return [CURRENT_USER_OPTION.SET_TO_CURRENT_USER]
        }

        let options: string[] = []
        if (
            userProfiles.find(
                (profile) => get(localField, 'options.lookup_target') === profile._sid
            )
        ) {
            options = options.concat([CURRENT_USER_OPTION.SET_TO_CURRENT_USER])
        }

        let fields: ObjectDto[] = []

        if (
            userProfiles.find(
                (profile) => get(localField, 'options.lookup_target') === profile._sid
            )
        ) {
            fields = [...userProfiles]
        } else {
            fields = [userObject, ...userProfiles]
        }

        if (
            fields
                .map((object) =>
                    object?.fields.find(
                        (x) =>
                            !x.connection_options?.is_disabled && doesFieldTypeMatch(localField, x)
                    )
                )
                .filter((filter) => filter !== undefined).length > 0
        ) {
            options = options.concat([CURRENT_USER_OPTION.SET_TO_CURRENT_USER_FIELD])
        }

        return options
    }

    const workspaceApp = !!selectedStack?.options?.workspace_app

    const userObjectLookupTypes =
        workspaceApp &&
        allFields
            .filter(
                (field) =>
                    ['lookup', 'multi_lookup'].includes(field.type) &&
                    !field.connection_options?.is_disabled
            )
            .map((field) => field.options?.lookup_target)
    const validLookupTargetTypes = [userTable, ...(userObjectLookupTypes || [])]

    const dropdownOptions = sortByFieldsOrder(object.fields)
        .filter(
            (field) =>
                ['lookup', 'multi_lookup', 'string'].includes(field.type) &&
                !field.connection_options.is_disabled &&
                !field.connection_options.read_only
        )
        .filter((field) =>
            field.type === 'string'
                ? field
                : validLookupTargetTypes.includes(field.options?.lookup_target)
        )
        .map((field) => ({
            label: field.label,
            value: field._sid,
        }))

    dropdownOptions.push({
        label: 'None',
        value: 'noneType',
    })
    const userFields = useMemo(
        () => getMatchingUserFields(createdByField, allFields),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createdByField, allFields]
    )

    const isDirty = (createdByField !== undefined || createdByUserField !== undefined) && hasChanged

    const showCurrentUserFields =
        userFields && currentUserOption === CURRENT_USER_OPTION.SET_TO_CURRENT_USER_FIELD

    const filterOptions = useMemo(
        () => getFilterOptionsForType(createdByField, object, userObject, userProfileObjects),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createdByField, object, userObject, userProfileObjects]
    )

    const isInvalid =
        !createdByField ||
        (filterOptions &&
            filterOptions.length &&
            showCurrentUserFields &&
            (!createdByUserField || !currentUserOption))

    useEffect(() => {
        if (filterOptions && filterOptions.length === 1) {
            setCurrentUserOption(filterOptions[0])
        } else if (filterOptions && filterOptions.length === 0) {
            setCurrentUserOption(undefined)
        }
    }, [filterOptions])

    return (
        <>
            <Flex>
                <Dropdown
                    value={createdByField}
                    options={dropdownOptions}
                    onChange={(fieldSid) => {
                        let userValueFields = getMatchingUserFields(fieldSid, allFields)

                        setCreatedByField(fieldSid)
                        setCreatedByUserField(
                            userValueFields && userValueFields?.length === 1
                                ? userValueFields[0]?.value
                                : undefined
                        )
                    }}
                    isClearable={false}
                    style={{ flex: 1 }}
                />
                {filterOptions && filterOptions?.length > 0 && (
                    <>
                        {filterOptions?.length === 1 ? (
                            <Text px={3}>{labelMap[filterOptions[0]]}</Text>
                        ) : (
                            <Dropdown
                                options={filterOptions.map((value) => ({
                                    label: labelMap[value] || value,
                                    value,
                                }))}
                                value={currentUserOption}
                                style={{ flex: 1 }}
                                onChange={(opt) => {
                                    setCurrentUserOption(opt)
                                    setCreatedByUserField(
                                        opt === CURRENT_USER_OPTION.SET_TO_CURRENT_USER
                                            ? getMatchingUserFields(
                                                  createdByField,
                                                  allFields,
                                                  true
                                              )[0]?.value
                                            : undefined
                                    )
                                }}
                            />
                        )}
                        {showCurrentUserFields
                            ? filterUserFieldSelect(userObject, userProfileObjects)
                            : null}
                    </>
                )}
            </Flex>
            <Button
                variant="Primary"
                buttonSize="sm"
                disabled={!isDirty || isInvalid}
                width="70px"
                mt={1}
                onClick={() => {
                    // If the field is set to 'None', the created_by_field is deleted
                    if (createdByField === 'noneType' && get(object, 'options.created_by_field')) {
                        delete object.options?.created_by_field
                        delete object.options?.created_by_user_field
                        changeObject({
                            options: {
                                ...object.options,
                            },
                        })
                    } else {
                        changeObject({
                            options: {
                                ...object.options,
                                created_by_field: createdByField,
                                created_by_user_field: createdByUserField,
                                created_by_filter: currentUserOption,
                            },
                        })
                    }
                }}
            >
                Save
            </Button>
        </>
    )
}

export default CreatedByEditor
