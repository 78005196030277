// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useAppErrors } from 'data/hooks/appErrors'
import { useUserLists } from 'data/hooks/userLists'
import { fieldHasError, isSyntheticLink } from 'data/utils/error_utils'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { FieldEditorPopoverButton } from 'features/admin/fields/FieldEditorPopoverButton'
import { getShortFieldName } from 'features/admin/utils'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { stringifyStackerAST } from 'features/formulas/parser/formulaParsingFunctions'
import FieldPicker from 'features/studio/ui/FieldPicker'
import { FormLabel, Icon, Toggle } from 'legacy/v1/ui'
import { getIsSyntheticField, isFormulaField } from 'utils/fieldUtils'

import {
    Box,
    ConditionalWrapper,
    Flex,
    Icon as Alert,
    Input,
    ScrollBox,
    Text,
    Tooltip,
} from 'v2/ui'
import VirtualizedList from 'v2/ui/components/VirtualizedList'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import { List } from 'v2/ui/svgs'
import { WrapIconWithSync } from 'v2/ui/svgs/WrapIconWithSync'
import stackerTheme from 'v2/ui/theme/styles/default'

import DATA_PROVIDERS from '../../data-connector/dataProviderConfig'
import { LOOKUP_TYPES } from '../../fields/common'
import { getEditableFieldTypeDefinition } from '../../fields/definitions/editableFieldTypeDefinitions'
import { toFieldTypeComponentData } from '../../fields/definitions/fieldTypeComponents'
import {
    getCanDeleteField,
    getIsFieldConfigurable,
} from '../../fields/logic/availableFieldOperationUtils'
import { getIsFieldSynced } from '../../fields/logic/getIsFieldSynced'
import { RenderIcon } from '../../fields/RenderIcon'

import DeleteFieldButton from './DeleteFieldButton'
import PrimaryFieldButton from './PrimaryFieldButton'
import {
    fieldFullName,
    getTruncatedFieldDeveloperName,
    getTruncatedFieldLabel,
    isFieldShadowed,
} from './utils'

/**
 * This is a copy of FieldConfigurationEditor.js with the new redesign
 * to fit in the settings modal.
 */

const colors = stackerTheme().colors

const FieldLabel = styled.span`
    padding-right: 5px;
    font-weight: 900;
    color: ${colors.neutral[1000]};
    display: block;
    font-size: 14px;
`

type FieldRowProps = {
    field: FieldDto
    object: ObjectDto
    onChange: (data: any) => void
    onDelete: (data: any) => void
    allowTypeConfig: boolean
    connectedProviderObject: { object: ObjectDto }
    dataConnection: DataConnectionDto
    showDeveloperDetails: boolean
    disabled: boolean
    isShowDataMappingColumn: boolean
    isLockedUserEmail: boolean
    hasError: boolean
}

const FieldRow = ({
    field,
    object,
    onChange,
    allowTypeConfig,
    dataConnection,
    showDeveloperDetails,
    disabled,
    connectedProviderObject,
    isShowDataMappingColumn,
    isLockedUserEmail = false,
    hasError = false,
}: FieldRowProps) => {
    const rawFieldType = getEditableFieldTypeDefinition(field)
    const fieldType = rawFieldType ? toFieldTypeComponentData(rawFieldType) : undefined
    const isEnabled = !field.connection_options.is_disabled
    const shortFieldName = getShortFieldName(field)
    const isStackerDynamicField = getIsSyntheticField(field)

    const dataConnectionType = dataConnection.type
    const isConfigurable = getIsFieldConfigurable({
        dataConnectionType: dataConnectionType,
        field,
    })
    const isSynced = getIsFieldSynced({ dataConnectionType, field })

    const formula = useMemo(() => {
        if (isFormulaField(field)) {
            return stringifyStackerAST(field.connection_options.formula_parsed, object)
        }
        return null
    }, [field, object])

    const shouldShowDeleteField = getCanDeleteField({ field, object })

    const [mappedField, setMappedField] = useState<{ _sid: string }>()
    const fields = connectedProviderObject?.object?.fields
    useEffect(() => {
        setMappedField(
            fields?.find((f) => f._sid === field.connection_options.connected_table_mapped_field)
        )
    }, [fields, field.connection_options.connected_table_mapped_field])

    const mappedFieldPickerStyle = {
        display: 'inline-flex',
        controlStyle: { display: 'inline-flex', flexWrap: 'nowrap' },
        width: 160,
        maxWidth: 160,
        valueContainerProps: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
        },
    }

    /**
     * For now, synthetic and relationship fields cannot be mapped
     * @param {*} field
     * @returns True if the field can be mapped
     */
    const canBeMapped = (field) => !getIsSyntheticField(field) && !LOOKUP_TYPES.includes(field.type)

    const isAlreadyMapped = useCallback(
        (providerField) =>
            object.fields.some(
                (field) =>
                    field.connection_options.connected_table_mapped_field === providerField._sid
            ),
        [object]
    )

    const label = useMemo(() => getTruncatedFieldLabel(field), [field])
    const isLabelTruncated = label !== field.label

    const developerLabel = useMemo(() => getTruncatedFieldDeveloperName(field), [field])
    const isDeveloperLabelTruncated = developerLabel !== shortFieldName

    return (
        <FieldContainer data-testid="fieldContainer" className={STYLE_CLASSES.DATA_BLOCK}>
            <Flex width={isShowDataMappingColumn ? '25%' : '40%'}>
                <Flex width="100%" flexWrap="nowrap">
                    {isSynced && (
                        <Tooltip label="Synced field">
                            <WrapIconWithSync
                                size="20px"
                                Icon={DATA_PROVIDERS[dataConnectionType]?.icon || List}
                            />
                        </Tooltip>
                    )}
                    <Box marginLeft="0.25rem" width="calc(100% - 25px)">
                        <Tooltip
                            placement="top"
                            label={formula ?? undefined}
                            disabled={!formula}
                            showDelay={200}
                            maxWidth="500px"
                        >
                            <>
                                <Tooltip label={field.label} disabled={!isLabelTruncated}>
                                    <FieldLabel>{label}</FieldLabel>
                                </Tooltip>
                                <Tooltip
                                    label={shortFieldName}
                                    disabled={!isDeveloperLabelTruncated}
                                >
                                    <Flex width="calc(100% - 25px)">
                                        {showDeveloperDetails && (
                                            <Text
                                                whiteSpace="nowrap"
                                                textOverflow="ellipsis"
                                                overflow="hidden"
                                                size="13px"
                                            >
                                                {developerLabel}
                                            </Text>
                                        )}
                                    </Flex>
                                </Tooltip>
                            </>
                        </Tooltip>
                    </Box>
                </Flex>
            </Flex>

            <Flex width={isShowDataMappingColumn ? '25%' : '30%'} wrap="nowrap">
                {fieldType?.iconComponent && (
                    <RenderIcon
                        Icon={fieldType?.iconComponent}
                        color={colors.neutral[800]}
                        size="20px"
                    />
                )}
                <Box
                    m={0}
                    mt="auto"
                    mr={3}
                    p={0}
                    ml={1}
                    color={colors.neutral[800]}
                    wordBreak="break-word"
                    fontSize={14}
                >
                    <Text display="inline-block" verticalAlign="middle">
                        {fieldType?.label}
                    </Text>
                    {field.connection_options.airtable_column_type === 'lookup' && (
                        <Text
                            display="inline-block"
                            verticalAlign="middle"
                            ml={1}
                            color={colors.neutral[600]}
                        >
                            (lookup)
                        </Text>
                    )}
                </Box>
            </Flex>

            {isShowDataMappingColumn && (
                <Box width="25%">
                    {connectedProviderObject.object && (
                        <Flex>
                            <FieldPicker
                                isDisabled={!isEnabled || !canBeMapped(field)}
                                objectId={connectedProviderObject.object._sid}
                                filter={(f) =>
                                    field.type === f.type &&
                                    !f.connection_options?.is_disabled &&
                                    !f.is_deleted &&
                                    canBeMapped(f) &&
                                    (mappedField ? true : !isAlreadyMapped(f))
                                }
                                placeholder="Map field"
                                value={mappedField?._sid}
                                provideOptionLabel={(record, object) => (
                                    <Text style={{ whiteSpace: 'nowrap', display: 'block' }}>
                                        {fieldFullName(object, record)}
                                    </Text>
                                )}
                                onChange={(providerFieldId) => {
                                    onChange({
                                        connection_options: {
                                            ...field.connection_options,
                                            connected_table_mapped_field: providerFieldId,
                                        },
                                    })
                                }}
                                {...mappedFieldPickerStyle}
                            ></FieldPicker>
                        </Flex>
                    )}
                </Box>
            )}

            <Flex
                justifyContent="flex-end"
                ml="auto"
                width={isShowDataMappingColumn ? '25%' : '30%'}
            >
                {hasError && (
                    <Alert
                        style={{ marginLeft: '2px' }}
                        icon="warning"
                        size="15px"
                        color="#FF0000"
                    />
                )}
                {/* Settings Button */}
                {(isConfigurable || (allowTypeConfig && !isStackerDynamicField)) && (
                    <FieldEditorPopoverButton
                        data-testid="fieldConfigButton"
                        objectId={object?._sid}
                        icon="cog"
                        field={field}
                        variant="Tertiary"
                        pr={0}
                        fontSize={17}
                        color={colors.neutral[600]}
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    />
                )}
                {/* Delete Icon */}
                {shouldShowDeleteField && (
                    <DeleteFieldButton
                        icon="trash"
                        label="Delete this field"
                        buttonSize="sm"
                        _hover={{ color: 'black' }}
                        color={colors.neutral[600]}
                        field={field}
                        style={{
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            marginLeft: '10px',
                            backgroundColor: 'transparent',
                        }}
                    />
                )}
                {/* Toggle */}
                {field.is_primary ? (
                    <PrimaryFieldButton
                        icon="key"
                        label={`You cannot disable ${field.label}, as it is the primary field for this object`}
                        buttonSize="sm"
                        _hover={{ color: 'black' }}
                        color={colors.neutral[1000]}
                        labelPlacement="left"
                        style={{
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            marginLeft: '10px',
                            backgroundColor: 'transparent',
                        }}
                    />
                ) : (
                    <Flex pl="5px" mr="-5px">
                        {/* @ts-expect-error */}
                        <FormLabel hidden for={`${field._sid}_Toggle`}>
                            {field.label} Enable
                        </FormLabel>
                        <ConditionalWrapper
                            condition={isLockedUserEmail}
                            wrapper={(children) => (
                                <Tooltip
                                    label="You cannot disable the assigned user email field."
                                    position="bottom"
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <Toggle
                                small
                                theme={{ toggleBackgroundColor: '#465DD8' }}
                                id={`${field._sid}_Toggle`}
                                value={isEnabled}
                                disabled={disabled}
                                onChange={() =>
                                    onChange({
                                        connection_options: {
                                            ...field.connection_options,
                                            is_disabled: isEnabled,
                                        },
                                    })
                                }
                            />
                        </ConditionalWrapper>
                    </Flex>
                )}
            </Flex>
        </FieldContainer>
    )
}

type NewFieldConfigurationEditorProps = {
    object: ObjectDto
    connectedProviderObject: any
    changeField: (sid: string, data: any) => void
    objectDataConnection: any
    objects: any[]
    deleteField: (sid: string) => void
    showDeveloperDetails: boolean
    isConnectedTablesEnabled: boolean
    bulkChangeFields: (changes: any[]) => void
    setDataSyncRequired: () => void
}

/**
 *
 * @param param0
 * @returns
 */
const NewFieldConfigurationEditor: React.FC<NewFieldConfigurationEditorProps> = ({
    object,
    connectedProviderObject,
    changeField,
    objectDataConnection,
    objects,
    deleteField,
    showDeveloperDetails,
    isConnectedTablesEnabled,
    bulkChangeFields,
    setDataSyncRequired,
}: NewFieldConfigurationEditorProps) => {
    const { fields } = object

    const [filter, setFilter] = useState<string>()
    const { data } = useAppErrors()
    const errors = data?.synthetic_field_errors

    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const allowTypeConfig = get(objectDataConnection, 'type') === 'gsheets'
    const isShowDataMappingColumn = isConnectedTablesEnabled && connectedProviderObject?.object

    const filteredFields = useMemo(
        () =>
            sortByFieldsOrder(fields).filter(
                (field) =>
                    !get(field, 'connection_options.is_stacker_id') &&
                    (!filter || field.label.toLowerCase().includes(filter.toLowerCase()))
            ),
        [fields, filter, sortByFieldsOrder]
    )

    // Check if the field is user in the user table or customer list
    const { data: userLists = [] } = useUserLists()
    const isFieldUserEmail = (field, userLists) => {
        return userLists?.find((list) => list?.email_field_id === field._sid)
    }
    const renderRow = useCallback(
        ({ item: field }) => {
            const isShadowed = isFieldShadowed(field)
            // Check that the email field is enabled before 'locking' it
            const isLockedUserEmail =
                isFieldUserEmail(field, userLists) && !field?.connection_options?.is_disabled
            const isDisabled =
                isLookupTableDisabled(field, objects) || isLockedUserEmail || isShadowed

            let hasError = false
            if (isSyntheticLink(field)) {
                hasError = fieldHasError(field, errors) || false
            }

            const handleChange = (data) => {
                const fieldEnabled =
                    !data?.connection_options?.is_disabled && field.connection_options?.is_disabled
                changeField(field?._sid, data)

                // If we're enabling a field, and this data source should be
                // data synced on field changes, then set the flag for that now
                if (
                    fieldEnabled &&
                    DATA_PROVIDERS[objectDataConnection?.type]?.cacheFillNeededOnFieldChange
                ) {
                    setDataSyncRequired?.()
                }
            }

            return (
                <FieldRow
                    key={field._sid}
                    field={field}
                    object={object}
                    onChange={handleChange}
                    onDelete={() => deleteField(field._sid)}
                    allowTypeConfig={allowTypeConfig}
                    dataConnection={objectDataConnection}
                    showDeveloperDetails={showDeveloperDetails}
                    disabled={isDisabled}
                    connectedProviderObject={connectedProviderObject}
                    isShowDataMappingColumn={isShowDataMappingColumn}
                    isLockedUserEmail={isLockedUserEmail}
                    hasError={hasError}
                />
            )
        },
        [
            userLists,
            objects,
            object,
            allowTypeConfig,
            objectDataConnection,
            showDeveloperDetails,
            connectedProviderObject,
            isShowDataMappingColumn,
            errors,
            changeField,
            setDataSyncRequired,
            deleteField,
        ]
    )

    const setAll = useCallback(
        async (value) => {
            const changes: any[] = []
            filteredFields
                .filter(
                    (field) =>
                        !field.is_primary &&
                        !isFieldUserEmail(field, userLists) &&
                        !isFieldShadowed(field)
                ) // Never toggle the primary, please
                .forEach((field) =>
                    changes.push({
                        _sid: field._sid,
                        connection_options: {
                            ...field.connection_options,
                            is_disabled: value,
                        },
                    })
                )

            await bulkChangeFields(changes)

            // If we're enabling some fields, and this data source should be
            // data synced on field changes, then set the flag for that now
            if (
                changes.length > 0 &&
                value &&
                DATA_PROVIDERS[objectDataConnection?.type]?.cacheFillNeededOnFieldChange
            ) {
                setDataSyncRequired?.()
            }
        },
        [
            filteredFields,
            bulkChangeFields,
            objectDataConnection?.type,
            userLists,
            setDataSyncRequired,
        ]
    )

    return (
        <>
            <StyledSearchInput
                style={{ paddingLeft: '2rem' }}
                placeholder="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                leftAdorner={
                    <Icon icon="search" style={{ fontSize: '10px', color: colors.neutral[600] }} />
                }
            />

            <FieldTitlesContainer>
                <FieldText style={{ width: isShowDataMappingColumn ? '25%' : '40%' }}>
                    Field name
                </FieldText>

                <FieldText style={{ width: isShowDataMappingColumn ? '25%' : '30%' }}>
                    Data type
                </FieldText>

                {isShowDataMappingColumn && (
                    <FieldText style={{ width: '25%' }}>Data mapping</FieldText>
                )}

                <Flex
                    width={isShowDataMappingColumn ? '25%' : '30%'}
                    textAlign="right"
                    ml="auto"
                    justifyContent="end"
                >
                    <FieldLinkText onClick={() => setAll(false)}>Enable all</FieldLinkText>
                    <FieldLinkText style={{ marginLeft: '10px' }} onClick={() => setAll(true)}>
                        Disable all
                    </FieldLinkText>
                </Flex>
            </FieldTitlesContainer>
            <ScrollBox id="inner" maxHeight="100%" flexGrow={1} overflowY="auto" overflowX="hidden">
                <VirtualizedList
                    estimatedItemSize={44}
                    items={filteredFields}
                    // The virtualized list seems to sometimes glitch when the number of items
                    // changes, so we're just recreating completely in that case
                    key={filteredFields.length}
                    renderItem={renderRow}
                />
            </ScrollBox>
        </>
    )
}

export default withStack(withObjects(NewFieldConfigurationEditor))

function isLookupTableDisabled(field, objects) {
    if (field.type === 'lookup' || field.type === 'multi_lookup') {
        const lookupTable = field.options?.lookup_target

        const table = objects.find((object) => object._sid === lookupTable)

        if (!table) {
            return true
        }

        if (table.connection_options.data_mapping_disabled) {
            return true
        }
    }

    return false
}

const FieldTitlesContainer = styled(Flex)`
    border-bottom: 1px solid ${colors.neutral[500]};
    padding-bottom: 5px;
    padding-left: 10px;
`

const FieldContainer = styled(FieldTitlesContainer)`
    padding-top: 5px;
    min-height: 44px;
`

const FieldText = styled.span`
    color: ${colors.neutral[900]};
`
const FieldLinkText = styled(FieldText)`
    text-decoration: underline;
    cursor: pointer;
    font-weight: 900;
`

const StyledSearchInput = styled(Input)`
    margin-bottom: 20px;
    &:focus {
        outline: none;
    }
`
