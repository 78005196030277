import React from 'react'

import { BaseColor } from 'v2/ui/theme/styles/types'

import NumberAttribute from './NumberAttribute'
import PercentBar from './PercentBar'
import PercentRing from './PercentRing'

type PercentAttributeProps = {
    field: { [key: string]: any }
    editable: boolean
    renderOptions: { [key: string]: any }
    animateValueOnChange?: number
    recordId: string
    isLoading: boolean
    noLinks: boolean
    size: string
    inline: boolean
    children: number
    percentageDisplay?: 'percentRing' | 'percentBar' | 'percentNumber'
    mode?: string
    isCreate?: boolean
    layout?: 'highlighted' | 'inline' | 'block'
    singleLine?: boolean
    baseMaxWidth?: number
}

const PercentAttribute = ({
    percentageDisplay,
    ...props
}: PercentAttributeProps): React.ReactElement => {
    const percentValue = props.children * 100

    let progressColor: BaseColor = 'progressBar'
    const progressColorTheme = 'userInterface.neutral.1000'

    if (
        (!props.children && percentValue !== 0) ||
        props.isCreate ||
        percentValue > 100 ||
        percentValue < 0
    ) {
        return <NumberAttribute {...props} />
    }

    switch (percentageDisplay) {
        case 'percentRing':
            return (
                <>
                    <PercentRing
                        progressColor={progressColor}
                        percentValue={percentValue}
                        progressColorTheme={progressColorTheme}
                        isEditing={props.mode === 'editing'}
                        layout={props.layout}
                        {...props}
                    />
                </>
            )
        case 'percentBar':
            return (
                <PercentBar
                    barColor={progressColor}
                    percentValue={percentValue}
                    hasNumber
                    isEditing={props.mode === 'editing'}
                    {...props}
                />
            )
        default:
            return <NumberAttribute {...props} />
    }
}

export default PercentAttribute
