import { useCallback, useContext } from 'react'

import { LayoutEditorContext } from './LayoutEditorContext'

// This should be used for setting a portal as it also handles removing the reference on unmount
export const useLayoutContextPortal = (id: string) => {
    const { setPortal } = useContext(LayoutEditorContext)

    const setNewPortal = useCallback(
        (node: HTMLElement | null) => {
            setPortal(node, id)
        },
        [id, setPortal]
    )

    return setNewPortal
}
