import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Flex, Menu, MenuButton, MenuList, Spinner, TabList, TabPanels } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import { v4 as uuid } from 'uuid'
import CreateView from 'v2/views/Create/CreateView'
import DetailViewControls, { SYSTEM_BUTTONS } from 'v2/views/Detail/DetailViewControls'
import FieldLayoutEditor from 'v2/views/FieldLayoutEditor'
import { determineIsBlockDisabled } from 'v2/views/utils/determineIsBlockDisabled'
import getUpdatedRecords from 'v2/views/utils/getUpdatedRecords'
import optimisticallyUpdateRelatedRecord from 'v2/views/utils/optimisticallyUpdateRelatedRecord'
import { scrollToInvalidField } from 'v2/views/utils/scrollToInvalidField'
import { useGoBackUsingBreadcrumbs } from 'v2/views/utils/useGoBackUsingBreadcrumbs'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { AppUserContext } from 'app/AppUserContext'
import useConfirmModal from 'app/ConfirmModal'
import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { getUrl } from 'app/UrlService'
import { queryCache, queryClient } from 'data/hooks/_helpers'
import { useRecordActions } from 'data/hooks/objects'
import { getCachedRecord } from 'data/hooks/records/getCachedRecord'
import { useUserRecord } from 'data/hooks/users/main'
import {
    buildCleanRecordsDiff,
    diffRecords,
    realtimeUpdatesDebugLog,
    realtimeUpdatesLoggingEnabled,
    useIsRealtimeUpdatesEnabled,
} from 'data/realtime/realtimeUpdates'
import { recordDeletionAvailable } from 'data/utils/utils'
import { withObject } from 'data/wrappers/WithObject'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { withViews } from 'data/wrappers/WithViews'
import ActionButtonList from 'features/actions/ActionButtonList'
import { useActionButtons } from 'features/actions/ActionsContextProvider'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import CollaborationPanel from 'features/collaboration/CollaborationPanel'
import { GlobalCallbackKeys } from 'features/commandbar/commandbar-keys'
import CommandBarShortcut from 'features/commandbar/CommandBarShortcut'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import { updateAttachmentsMetadata } from 'features/records/utils/attachments'
import BlockSelectorPortal from 'features/utils/BlockSelectorPortal'
import ViewEditPane from 'features/views/ViewEditPane'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'
import useTrack from 'utils/useTrack'
import useWindowTitle from 'utils/useWindowTitle'

import {
    Alert,
    Box,
    Button,
    Collapse,
    Container,
    EmptyState,
    Tab,
    TabPanel,
    Tabs,
    Text,
} from 'v2/ui'
import { FixedContainer } from 'v2/ui/components/ContainerLabel'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import * as SvgIcons from 'v2/ui/svgs'
import { FEATURES, isCollaborationEnabled, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'
import useDimension from 'v2/ui/utils/useDimension'
import useIntersection from 'v2/ui/utils/useIntersection'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import { Checkbox } from 'ui/components/Checkbox'

import { findWidgetBlockById } from '../../utils/findWidgetBlockById'
import DeleteButton from '../DeleteButton'
import EditButton from '../EditButton'
import { RecordHeaderTitle } from '../RecordHeader'

import DetailViewProfileImage from './DetailViewProfileImage'
import EditsMadeAlert from './EditMadeAlert'
import LayoutWrapper from './LayoutWrapper'

const MAX_TABS_MOBILE = 2
const MAX_TABS_OTHER = 6
const DetailView = ({
    config,
    record,
    showControls: isEditing,
    objects,
    onChange,
    object,
    view,
    views,
    feature,
    stackOptions,
    stack,
    user,
    isRecordList,
    showCreate,
    onCreate,
    parentListViewIds,
    parentDetailViewIds,
    title: titleOverride,
    fromListView,
    linkToRecord,
    extraStickyOffset = 0,
}) => {
    const objectId = object._sid
    const recordId = record?._sid
    const { role, isImpersonating } = useContext(AppUserContext)
    const showControls = isEditing

    const processFilter = useProcessFilter()
    const { isLoading: userRecordLoading } = useUserRecord()
    const isRealtimeUpdatesEnabled = useIsRealtimeUpdatesEnabled(stack)
    const tabsEnabled = !isFeatureLocked(FEATURES.tabs, stack)
    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    const [activeTab, setActiveTab] = useState(0)
    const { track } = useTrack()
    const recordActions = useRecordActions()
    const goBackUsingBreadcrumbs = useGoBackUsingBreadcrumbs()

    useWindowTitle(record?._primary, fromListView)

    const history = useHistory()
    const location = history?.location
    const locationState = location?.state || {}

    const disableHistoryBreadcrumb = fromListView
    useHistoryBreadcrumb(
        { title: record?._primary, type: 'detail', objectId },
        disableHistoryBreadcrumb
    )

    const [activityCount, setActivityCount] = useState(0)
    const [activityHasMore, setActivityHasMore] = useState(false)

    const updateActivityCount = useCallback((count, hasMore) => {
        setActivityCount(count)
        setActivityHasMore(hasMore)
    }, [])

    const buildCleanRecordState = (record) => ({
        record,
        loadedRecord: record,
        recordUpdates: buildCleanRecordsDiff(),
        noRecordAccess: record?._permissions?.non_permitted_result,
    })

    const defaultData = {
        config: cloneDeep(config),
        loadedConfig: config,
        realtimeUpdatesEnabled: isRealtimeUpdatesEnabled,
        ...buildCleanRecordState(record),
    }

    const [data, setData] = useState(defaultData)

    const readOnly = object?.connection_options?.read_only
    const allowEdit =
        getAreObjectRecordsEditable(object) && get(data, 'record._permissions.may_update')

    const [state, setState] = useState({
        showErrors: false,
        isConfigDirty: false,
        isRecordDirty: false,
        isSaving: false,
        editing: allowEdit ? get(view, 'options.default_edit', false) : false,
        valid: {},
        saveError: false,
        autofillDone: false,
        doNotShowUnsavedChangesModal: false,
        recordNoLongerExists: false,
    })

    // Note: uses container dimensions instead of screen width and the isMobile hook because of
    // cases like the inbox view where there can be limited space even on relatively wide screens
    const [containerRef, containerRefCallback] = useRefCallback()
    const [headerRef, headerRefCallback] = useRefCallback()
    // Save the incoming config in a ref so it can be accessed even from
    // stale closures, which we unfortunately have from the FieldLayoutEditor
    // where it calls back into onChange. The root of that stale-ness is deep
    // in the block tree code somewhere and I haven't been able to find it.
    const incomingConfig = useRef()
    incomingConfig.current = config

    const headerInView = useIntersection(headerRef)
    const { width: containerWidth } = useDimension(containerRef)
    const { width } = useDimension(containerRef)
    const isNarrow = width && width < 650

    const systemButtons = useMemo(
        () => SYSTEM_BUTTONS.filter((x) => x._sid !== 'delete').map((x) => ({ id: x._sid })),
        []
    )
    const selectedButtons = data?.config?.pageButtons || systemButtons
    const activeActions = useActionButtons(selectedButtons, record, object, SYSTEM_BUTTONS)

    CommandBarShortcut({
        name: GlobalCallbackKeys.EditRecord,
        action: () => {
            setState((state) => ({
                ...state,
                editing: true,
            }))
        },
        isActive: get(data, 'record._permissions.may_update') && !showControls,
    })

    const actionButtons = useMemo(
        () => {
            const allowDelete =
                recordDeletionAvailable(object, stack) &&
                get(data, 'record._permissions.may_delete')

            return (
                <ActionButtonList
                    record={record}
                    maxVisible={isNarrow ? 1 : 3}
                    actions={activeActions.map((item) => {
                        if (item.action._sid === 'edit') {
                            if (allowEdit) {
                                return {
                                    ...item,
                                    Component: EditButton,
                                    onClick: () =>
                                        setState((state) => ({
                                            ...state,
                                            editing: true,
                                        })),
                                    isDisabled: showControls,
                                    className: ONBOARDING_CLASSES.DETAIL_VIEW_EDIT_BUTTON,
                                }
                            }
                        } else if (item.action._sid === 'delete') {
                            if (!readOnly && allowDelete) {
                                return {
                                    ...item,
                                    Component: DeleteButton,
                                    isDisabled: showControls,
                                }
                            }
                        } else {
                            return { ...item, isDisabled: showControls }
                        }
                    })}
                />
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeActions, showControls, record, isNarrow, allowEdit],
        'actionButtons'
    )

    const setValue = useCallback(
        (key, value) => setData((data) => ({ ...data, record: { ...data.record, [key]: value } })),
        []
    )

    const [listQuery] = queryCache.findAll(['records', record?._object_id])
    const listData = queryClient.getQueryData(listQuery?.queryKey)

    const records = listData?.records?.map((record) => record._sid) ?? []

    const activeRecordIndex = records?.indexOf(recordId)
    const prevRecord = records?.[activeRecordIndex - 1]
    const currentRecord = records?.[activeRecordIndex]
    const nextRecord = records?.[activeRecordIndex + 1]

    CommandBarShortcut({
        name: GlobalCallbackKeys.PreviousRecord,
        action: `${
            history.location.pathname.replace(currentRecord, prevRecord) +
            history.location.search.replace(currentRecord, prevRecord)
        }`,
        isActive: !!prevRecord,
    })
    CommandBarShortcut({
        name: GlobalCallbackKeys.NextRecord,
        action: `${
            history.location.pathname.replace(currentRecord, nextRecord) +
            history.location.search.replace(currentRecord, nextRecord)
        }`,
        isActive: !!nextRecord,
    })

    useEffect(() => {
        // Returning a function means it will run on unmount
        return () => {
            if (state.recordNoLongerExists) {
                // forces a refetch
                recordActions.fetch(data?.record?._sid)
            }
        }
    }, [state.recordNoLongerExists, data?.record?._sid, recordActions])

    useEffect(() => {
        track('app record viewed', {
            event_category: 'app',
            event_description: 'App record was viewed',
            is_impersonating: isImpersonating,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Check if the record dirty state needs updating
    useEffect(() => {
        const isRecordDirty =
            !isEqual(data.record, data.loadedRecord) && !record?._permissions?.non_permitted_result
        if (isRecordDirty !== state.isRecordDirty) {
            setState((state) => ({ ...state, isRecordDirty }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.record, data.loadedRecord])

    // recursively updates block ids in place
    const replaceBlockIds = (block, find, replace) => {
        block.id = block.id.replace(find, replace)
        block?.childBlocks?.forEach((b) => {
            replaceBlockIds(b, find, replace)
        })
    }

    const fixDuplicateTabs = (tabs = [], blocks = []) => {
        const currentIds = []
        let tabsWithoutDuplicates = tabs
        let blocksWithoutDuplicates = blocks
        // There was a bug where duplicate ids were being created.
        // This will check for any duplicate ids and rename them, along with their blocks
        // so that they can then work as expected
        tabsWithoutDuplicates = tabs?.map((tab) => {
            if (!currentIds.includes(tab.id)) {
                currentIds.push(tab.id)
                return tab
            } else {
                const block = blocksWithoutDuplicates.filter((block) => block.id.endsWith(tab.id))
                const newId = `tab_${uuid()}`
                // Recursively update the childblock ids
                if (block.length > 1) {
                    replaceBlockIds(block[1], tab.id, newId)
                }
                return { ...tab, id: newId }
            }
        })
        return { tabs: tabsWithoutDuplicates, blocks: blocksWithoutDuplicates }
    }

    // Make sure that tab ids are unique
    useEffect(() => {
        if (data.config.tabs) {
            const { tabs, blocks } = fixDuplicateTabs(data.config.tabs, data.config.blocks)
            if (!isEqual(tabs, data.config.tabs)) {
                setConfig(
                    {
                        blocks,
                        tabs,
                    },
                    state.editing
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.config.blocks, data.config.tabs])

    const setValid = useCallback(
        (key, value) =>
            setState((state) => ({
                ...state,
                validationError: false,
                valid: { ...state.valid, [key]: value },
            })),
        []
    )

    // Hide certain fields if they're disabled
    const isFieldDisabled = useCallback(
        (fieldId, useApiName = false) => {
            const fields = objects.map((object) => object.fields).flat()
            const field = fields.find((f) =>
                useApiName ? f.api_name === fieldId : f._sid === fieldId
            )
            return field && field.connection_options.is_disabled === true
        },
        [objects]
    )

    const context = useMemo(
        () => ({
            record: data.record,

            // metadata for realtime updates, if not enabled just set undefined
            // the consuming components will interpret undefined as the feature not being turned on
            recordUpdates: data.realtimeUpdatesEnabled ? data.recordUpdates : undefined,

            user: { ...user }, // needed to trigger conditional visibility on role change
            view: {
                actions: {
                    setValue,
                    setValid,
                },

                valid: state.valid,
                editing: state.editing,
                creating: false,
                showErrors: state.showErrors,
                isLoading: state.isSaving,
            },
            object,
            parentDetailViewIds,
            parentListViewIds,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data.record,
            data.recordUpdates,
            object,
            state.valid,
            state.editing,
            state.showErrors,
            state.isSaving,
            setValue,
            setValid,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            get(user, 'role_id'),
        ],
        'context'
    )

    const getFullUrl = () => {
        const url = new URL(window.location.href)
        url.hash = '#activity'
        return url
    }
    const collaborationRelatedTo = useMemo(
        () => ({
            id: record?._sid,
            url: `${feature?.url}/view/${record?._sid}#activity`,
            fullUrl: getFullUrl(),
            name: record?._primary || object?.name,
            stackId: stack?._sid,
            domain: stack?.url,
            objectId: object?._sid,
            recordSid: record?._sid,
            featureSid: feature?._sid,
        }),
        [
            record?._primary,
            object?.name,
            stack?._sid,
            stack?.url,
            feature?._sid,
            record?._sid,
            feature?.url,
            object?._sid,
        ],
        'relatedTo'
    )

    const handleTabChange = (id) => {
        const tab = allActiveTabs.find((t) => t.id === id)
        const { name } = tab || {}
        scrollRef.current = window.document.body.parentElement.scrollTop
        // Only change the url if this isn't a record list
        if (!isRecordList) {
            history.replace({
                pathname: location.pathname,
                search: location.search,
                hash: `#${encodeURI(name?.trim())}`,
                state: { ...locationState },
            })
        }
        setActiveTab(id)
    }

    useEffect(() => {
        if (config && !isEqual(data.loadedConfig, config)) {
            setData((data) => ({ ...data, loadedConfig: config, config }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    // used to update the record state to the latest record
    // cleaning out the old cached loadedRecord and diffs
    const resetRecordState = (record) => {
        setData((data) => ({
            ...data,
            ...buildCleanRecordState(record),
        }))
    }

    // on record update from redux store
    // for instance this fires on save or on reatime update
    useEffect(() => {
        if (realtimeUpdatesLoggingEnabled()) {
            realtimeUpdatesDebugLog(`Record updated in redux [state.editing is ${state.editing}]`, {
                ...record,
            })
        }

        if (state.editing) {
            // if in edit mode, get the diff between the loadedRecord
            // and the updated one but *don't* update it automatically
            //
            // we use the diff to notify the user about pontential conflicts
            // per field if they save after someone else updated the record
            // and also offer them the choice to discard their changes and
            // reload the updated record into state

            // We're in edit mode but we haven't edited any data so just swap in the new record
            if (!state.isRecordDirty && !isEqual(record, data.loadedRecord)) {
                resetRecordState(record)
                return
            }

            setData((data) => {
                /**
                 * Update the attachment metadata, since some field
                 * values (like URLs) expire after some time,
                 * and get replaced with new ones.
                 *
                 * This means that users could save the records with
                 * expired URLs, and override updated ones.
                 * @link [Airtable expiring URLs](https://support.airtable.com/docs/changes-to-airtable-attachments)
                 */
                // Update the links in the current record that's being edited
                const updatedEditingRecord = updateAttachmentsMetadata(data.record, record)
                // Update the links in the current loaded record
                const updatedLoadedRecord = updateAttachmentsMetadata(data.loadedRecord, record)
                // Diff the currently loaded record with the new record
                const recordUpdates = diffRecords(record, updatedLoadedRecord)

                return {
                    ...data,
                    record: updatedEditingRecord,
                    loadedRecord: updatedLoadedRecord,
                    recordUpdates,
                }
            })
        } else {
            // if not editing, just swap in the new record data automatically,
            // and clean out any previous diff.
            // Note: only do this if the record has actually changed.
            if (record !== data.record) {
                resetRecordState(record)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        record,

        // on an update request, we don't set the state.editing to false until *after* the
        // response comes back, and therefore until after the redux update (which updates record) has
        // already has gone through this useEffect - since we don't reload record into data when state.editing is true,
        // adding this dependency means that it *is* reloaded again when state.editing is set false afterwards
        state.editing,
    ])

    const revertConfigChanges = () => {
        setData((prevData) => ({ ...prevData, config: cloneDeep(config), loadedConfig: config }))
        setState((prevState) => ({ ...prevState, isConfigDirty: false }))
    }

    const revertRecordChanges = () => {
        setData((prevData) => ({ ...prevData, ...buildCleanRecordState(record) }))
        setState((prevState) => ({ ...prevState, isRecordDirty: false }))
    }

    const collaborationEnabled = isCollaborationEnabled(stack)
    const enableComments = data.config.enableComments && collaborationEnabled
    const activityLabel = stack.options?.activity_label || 'Activity'

    const activityCountLabel =
        activityCount > 0 ? ` (${activityCount}${activityHasMore ? '+' : ''})` : ''

    const tabs = useMemo(() => {
        const tabs = data.config.tabs || [
            { name: 'Details', id: 'details', treeIndex: 0, active: true },
            // { name: 'Activity', id: 'activity', active: enableComments, type: 'activity' },
        ]

        /* V3 only, on V4 it's handled in the TabsSelector */
        if (enableComments && !data.config.tabs) {
            tabs.push({
                name: activityLabel,
                id: 'activity',
                type: 'activity',
                active: enableComments,
            })
        }

        return tabs
    }, [activityLabel, data.config.tabs, enableComments])

    const allActiveTabs = useMemo(
        () => {
            return tabs
                .filter(({ active }) => active)
                .filter(
                    ({ conditions }) =>
                        !conditions?.length ||
                        showControls ||
                        (record && processFilter([record], conditions).length > 0)
                )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record, tabs, role, showControls, processFilter],
        'allActiveTabs'
    ) // Note: want to refilter on role change

    // limit the number of tabs displayed
    const { activeTabs, extraTabs } = useMemo(
        () => {
            const max = isNarrow ? MAX_TABS_MOBILE : MAX_TABS_OTHER
            if (allActiveTabs.length <= max) return { activeTabs: allActiveTabs, extraTabs: [] }
            const tabIndex = allActiveTabs.findIndex((t) => t.id === activeTab) || 0
            let extraTabs, activeTabs
            // if current tab is not visible, we replace the last visible tab with it
            if (tabIndex >= max) {
                extraTabs = allActiveTabs.filter((_, index) => index !== tabIndex).slice(max - 1)
                activeTabs = allActiveTabs.slice(0, max - 1)
                activeTabs.push(allActiveTabs[tabIndex])
            } else {
                extraTabs = allActiveTabs.slice(max)
                activeTabs = allActiveTabs.slice(0, max)
            }

            return {
                extraTabs,
                activeTabs,
            }
        },
        [allActiveTabs, activeTab, isNarrow],
        'tabs'
    )

    useEffect(() => {
        let tabId = decodeURI(location.hash.slice(1)?.trim())
        // Find the active tab based on the url
        // We try to find by name first, then fall back to id
        // Must trim the ids and names in case leading/trailing spaces are saved
        if (tabId && !isRecordList) {
            const newTab =
                allActiveTabs.find(
                    (tab) => tab.name?.trim() === tabId || tab.id?.trim() === tabId
                ) || allActiveTabs[0]
            if (newTab && newTab.id !== activeTab) {
                setActiveTab(newTab.id)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash, allActiveTabs])

    const activeTabsMap = activeTabs.map(({ id }) => id)

    const scrollRef = useRef(0)
    useEffect(() => {
        window.document.body.parentElement.scrollTop = scrollRef.current
    }, [activeTab])

    const adjustedTabIndex = useMemo(() => {
        if (activeTab) {
            return activeTabs.findIndex((t) => t.id === activeTab) || 0
        }
        return 0
    }, [activeTab, activeTabs])

    const { show: showDeletedModal } = useConfirmModal({
        title: 'Record no longer exists',
        message: (
            <Text>
                The record you are editing has been deleted. Do you want to stay on this page, or
                leave?
            </Text>
        ),
        endUser: true,
        confirmButtonText: 'Leave page',
        cancelButtonText: 'Stay on this page',
        onConfirm: (modal) => {
            // Go to list view
            recordActions.fetch(data?.record?._sid)
            goBackUsingBreadcrumbs()
            modal.toggle()
        },
    })

    let tree = get(data, 'config.blocks')

    const setConfig = useCallback((config, setDirty = true) => {
        setState((state) => ({
            ...state,
            isConfigDirty: setDirty ? true : state.isConfigDirty,
        }))

        setData((data) => ({ ...data, config: { ...data.config, ...config } }))
    }, [])

    const activeTabObject = useMemo(() => {
        return allActiveTabs.find((t) => t.id === activeTab) || allActiveTabs[0]
    }, [activeTab, allActiveTabs])

    const onBlockChange = useCallback(
        (changes, blocks) => {
            changes?.forEach((changedBlock) => {
                const block = findWidgetBlockById(blocks, changedBlock.blockId)
                if (changedBlock.action === 'create') {
                    track('Frontend Widget Created', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'detail_view',
                    })
                } else if (changedBlock.action === 'updateConfig') {
                    track('Frontend Widget Modified', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'detail_view',
                    })
                }
            })

            setState({
                ...state,
                isConfigDirty: true,
            })
            setData((data) => ({
                ...data,
                config: {
                    ...data.config,
                    blocks:
                        blocks.length > 1
                            ? blocks
                            : [
                                  ...tree.slice(0, activeTabObject.treeIndex),
                                  blocks[0],
                                  ...tree.slice(activeTabObject.treeIndex + 1),
                              ],
                },
            }))
        },

        [activeTabObject, state, tree, track]
    )

    // Scroll to the first error field when showErrors is set to true
    useEffect(() => {
        if (state.showErrors) scrollToInvalidField()
    }, [state.showErrors])

    if (!record) {
        if (showCreate && !isRecordList && object._permissions && object._permissions.may_create) {
            const view = views.find((view) => view.object_id === objectId && view.type === 'create')

            return (
                <CreateView
                    stack={stack}
                    view={view}
                    showRelated
                    feature={feature}
                    objectId={view.object_id}
                    config={view.options}
                    showControls={showControls}
                    onCreate={onCreate}
                    isCreate
                    fromDetailView
                    doNotRedirect
                />
            )
        }
        return (
            <Container mt={['page.heading.mt', null, null, 'page.heading.mtLg']}>
                <EmptyState name="record" svg={SvgIcons.EmptyView} />
            </Container>
        )
    }

    const isValid = () => {
        const validity = state.valid
        let isValid = true
        Object.keys(validity).map((key) => {
            isValid = isValid && validity[key]
        })

        return isValid
    }

    const saveConfig = async () => {
        track('layout updated', {
            view: 'detail view',
        })

        const deleteIsEnabled = data.config.pageButtons
            ? data.config.pageButtons.some((x) => x.id === 'delete')
            : false
        const deleteWasEnabled = data.loadedConfig.pageButtons
            ? data.loadedConfig.pageButtons.some((x) => x.id === 'delete')
            : false
        if (deleteIsEnabled && !deleteWasEnabled) {
            track('WIP - Frontend - Detail View - Delete Button - Enabled')
        }

        await onChange(data.config)
        setState((state) => ({ ...state, isConfigDirty: false }))
    }

    const cancelEdit = () => {
        setData((data) => ({
            ...data,
            record,
            config,
        }))
        setState((state) => ({
            ...state,
            showErrors: false,
            validationError: false,
            editing: false,
            saveError: false,
        }))
    }

    const saveRecordWithoutRedirect = () => {
        return saveRecord({ skipRedirect: true })
    }

    const saveRecord = () => {
        // Don't actually save if we're in the admin edit layout mode
        if (showControls) return setState((state) => ({ ...state, editing: false }))

        setState((state) => ({ ...state, isSaving: true, saveError: false }))

        // See if we're valid
        if (!isValid()) {
            setState((state) => ({
                ...state,
                showErrors: true,
                validationError: true,
                isSaving: false,
            }))
            // Note: we need this in both a useEffect and here so that it triggers both for the first time showErrors is set to true
            // and every time submit is clicked when the record is still not valid (showErrors is already true)
            scrollToInvalidField()
            return Promise.reject()
        }
        // Generate a diff between the loadedRecord and the current record
        const recordDiff = {}
        Object.keys(data.record).forEach((key) => {
            if (data.record[key] !== data.loadedRecord[key]) recordDiff[key] = data.record[key]
        })
        return recordActions
            .update(data?.record?._sid, recordDiff)
            .then((records) => {
                const [persisted] = records ?? []

                // This checks all lookup and multi lookup fields to see which
                // items have been updated and need to be refreshed
                // Note that we compare the persisted record value, not the local updated one
                // as we want to rely on the actual backend update logic
                const { recordsAdded, recordsRemoved, updatedRecordsIds } = getUpdatedRecords(
                    object.fields,
                    record,
                    persisted
                )

                if (updatedRecordsIds) {
                    updatedRecordsIds.forEach((recordSid) => {
                        const currentRecord = getCachedRecord(recordSid)
                        // let's just optimistically update the record for now
                        // so that it looks seamless and doesn't have to wait
                        // for the update
                        if (currentRecord) {
                            optimisticallyUpdateRelatedRecord(
                                persisted,
                                currentRecord,
                                recordsAdded,
                                recordsRemoved,
                                recordActions
                            )
                        }

                        recordActions.fetch(recordSid, {
                            noCache: true,
                        })
                    })
                }
                setState((state) => ({
                    ...state,
                    isSaving: false,
                    editing: false,
                }))
            })
            .catch((e) => {
                if (e.status === 404) {
                    showDeletedModal()
                    setState((state) => ({
                        ...state,
                        isSaving: false,
                        doNotShowUnsavedChangesModal: true,
                        recordNoLongerExists: true,
                    }))
                } else {
                    setState((state) => ({ ...state, isSaving: false, saveError: true }))
                }
                Sentry.captureMessage(`Error saving record. Error message: ${get(e, 'message')}`)
            })
    }
    const useLayoutFrom = get(data, 'config.use_layout_from')

    if (useLayoutFrom) {
        const layoutFromView = views.find((v) => v._sid === useLayoutFrom)
        if (layoutFromView) {
            tree = get(layoutFromView, 'options.blocks', tree)
        }
    }

    const readOnlyPortal = get(stackOptions, 'read_only_data')

    const editingButtons = (
        <>
            <CommandBarShortcut name={GlobalCallbackKeys.Cancel} action={cancelEdit} />
            <Button
                variant="moderateSm"
                icon="x"
                onClick={cancelEdit}
                mr={1}
                className="stk-cancel-button"
            >
                Cancel
            </Button>
            <CommandBarShortcut
                name={GlobalCallbackKeys.Save}
                action={saveRecord}
                isActive={!state.isSaving && !readOnlyPortal}
            />
            <Button
                variant="sm"
                icon="checkmark"
                onClick={saveRecord}
                isDisabled={state.isSaving || readOnlyPortal}
                label={readOnlyPortal && 'Saving changes is disabled on this app'}
                className="stk-save-button"
            >
                Save
            </Button>
        </>
    )

    const editControls = () => {
        if (state.editing) {
            return (
                <>
                    {linkToRecord && <div style={{ width: 8 }}></div>}
                    {editingButtons}
                </>
            )
        }

        return <>{actionButtons}</>
    }

    const profileLayout = get(data, 'config.display') === 'profile'
    const showTitle = !profileLayout
    const showProfileImage = !profileLayout

    const title = (
        <RecordHeaderTitle
            titleOverride={titleOverride}
            config={data.config}
            record={context.record}
            object={context.object}
            editing={context.view.editing}
            showErrors={context.view.showErrors}
            setValue={context.view.actions.setValue}
            setValid={context.view.actions.setValid}
            valid={context.view.valid}
            profileLayout={profileLayout}
        />
    )

    const isConfigDirty = state.isConfigDirty
    const defaultEdit = data.config && data.config.default_edit
    const toggleDefaultEdit = () => {
        setState((state) => ({
            ...state,
            isConfigDirty: true,
        }))
        setData((data) => ({
            ...data,
            config: {
                ...data.config,
                default_edit: !(data.config && data.config.default_edit),
            },
        }))
    }

    let viewName = (obj) => `${obj.name} detail`
    let viewActions = []

    if (getAreObjectRecordsEditable(object)) {
        viewActions.push({
            action: toggleDefaultEdit,
            label: (
                <div style={{ width: 'max-content' }}>
                    <Checkbox checked={defaultEdit}>Start in edit mode</Checkbox>
                </div>
            ),
        })
    }

    const recordPermissions = get(data, 'record._permissions')
    const buttons = (compact) => (
        <Flex
            flexShrink={0}
            height={compact ? 'auto' : ['profileImage.detail', null, null, 'profileImage.detailLg']}
            alignItems="center"
            alignSelf="flex-start"
            ml={2}
        >
            {linkToRecord && (
                <Button
                    buttonSize="sm"
                    icon={'link'}
                    onClick={() => history.push(getUrl(`${feature?.url}/view/${record._sid}`))}
                />
            )}
            {editControls()}
        </Flex>
    )

    const showUpdateNotification =
        state.editing &&
        data.realtimeUpdatesEnabled &&
        !data.recordUpdates.equal &&
        // the records may be seen updated if it is only a schema change, this would trigger a false positive for the banner
        // only show the update notification if there are changes in the record diff other than 'schemaTimestamp'
        Object.keys(data.recordUpdates.diff).filter((c) => c !== 'schemaTimestamp').length > 0

    if (showUpdateNotification) {
        realtimeUpdatesDebugLog('showing update notification because', data.recordUpdates)
    }

    const displayTabs = enableComments || activeTabs?.length > 1

    // Don't make the header 'sticky' if it's part of a 'One record' related record list view
    const showFixedHeader = !isRecordList

    let fixedHeader
    if (showFixedHeader) {
        let headingField = data.config?.recordHeader?.heading
        let recordTitle = record?.[headingField]
        const headingFieldIsDisabled = object.fields.find(
            (f) => f.api_name === headingField && f.connection_options.is_disabled
        )
        if (!recordTitle || headingFieldIsDisabled) {
            recordTitle = record._primary
        }

        // The sticky header should be open once the non-sticky one has been mounted and not in view
        const isOpen = !isUndefined(headerRef.current) && !headerInView

        fixedHeader = (
            <FixedContainer
                position="fixed"
                isFixed
                top={extraStickyOffset + 'px'}
                width={containerWidth + 96}
            >
                <Collapse isOpen={isOpen}>
                    <Flex alignItems="center" py={2} justifyContent="space-between">
                        <Text
                            fontSize="1.5rem"
                            as="h2"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            title={recordTitle}
                        >
                            {recordTitle}
                        </Text>
                        {buttons(true)}
                    </Flex>
                </Collapse>
            </FixedContainer>
        )
    }
    const profileImage = (
        <DetailViewProfileImage
            data={data}
            object={object}
            context={context}
            isFieldDisabled={isFieldDisabled}
            isProfileLayout={profileLayout}
        ></DetailViewProfileImage>
    )

    // Prevent overlap of 'follow' button
    const tabListPadding = activeTab === 'activity' ? 100 : 0

    return (
        <div
            ref={containerRefCallback}
            className={state.editing ? STYLE_CLASSES.EDITING_RECORD : ''}
        >
            {/* No record access - we have to make sure that we still render
            the unsaved changes modal otherwise it'll stop the action from
            happening after clicking save */}
            {data.noRecordAccess ? (
                <Alert
                    status="error"
                    variant="left-accent"
                    my={10}
                    title="You no longer have access to this record."
                ></Alert>
            ) : (
                <>
                    {showControls && (
                        <ViewEditPane
                            noPadding={false}
                            isConfigDirty={isConfigDirty}
                            viewName={viewName(object)}
                            saveView={saveConfig}
                            actions={viewActions}
                        >
                            <DetailViewControls
                                stack={stack}
                                object={object}
                                config={data.config}
                                setConfig={setConfig}
                                showCommentsOption={collaborationEnabled}
                                showTabsOption={tabsEnabled}
                                showActionButtonsOption={true}
                            />

                            <BlockSelectorPortal />
                        </ViewEditPane>
                    )}
                    {fixedHeader}
                    <LayoutWrapper
                        profileLayout={profileLayout}
                        title={title}
                        profileImage={profileImage}
                        config={data.config}
                        context={context}
                        showControls={showControls}
                        setConfig={setConfig}
                        recordPermissions={recordPermissions}
                        header={
                            (showTitle || buttons) && (
                                <Flex
                                    ref={headerRefCallback}
                                    mt={['page.heading.mt', null, null, 'page.heading.mtLg']}
                                    className={classNames(
                                        isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK,
                                        STYLE_CLASSES.RECORD_HEADER
                                    )}
                                    isEditable={false}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    flexWrap={isNarrow ? 'wrap' : 'nowrap'}
                                >
                                    <Box alignSelf="flex-start">
                                        {showProfileImage && profileImage}
                                        {userRecordLoading && (
                                            <Spinner size="md" color="neutral.800" mr={2} />
                                        )}
                                    </Box>
                                    {showTitle && title}
                                    {buttons(profileLayout)}
                                </Flex>
                            )
                        }
                    >
                        {showUpdateNotification && (
                            <EditsMadeAlert
                                onDiscardChanges={() => {
                                    // clicking the 'discard my changes' button will
                                    // reset the record state to the latest loaded record
                                    //
                                    // what is being shown in the edit mode form before it is pressed is the loadedRecord
                                    // plus any changes the user has made -- i.e. this will just update
                                    // all the form values to the latest record, and also clean the diff
                                    // so the 'edit notification' UI does not show (since we're now synced up with the latest)
                                    resetRecordState(record)
                                }}
                            />
                        )}

                        <Collapse isOpen={state.saveError}>
                            <Text variant="error">
                                {stackOptions.is_demo
                                    ? `Sorry, you can't edit records in a demo app!`
                                    : 'Sorry, there was an error saving your record. Please try again.'}
                            </Text>
                        </Collapse>
                        <Collapse isOpen={!!state.validationError}>
                            <Text variant="error">Please fill out the required fields below.</Text>
                        </Collapse>
                        <Tabs
                            onChange={(index) => handleTabChange(activeTabsMap[index])}
                            index={adjustedTabIndex}
                            position="relative"
                            maxWidth="100%"
                            className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
                        >
                            {displayTabs && (
                                <TabList pr={tabListPadding}>
                                    {activeTabs.map((tab) => (
                                        <Tab key={tab.name}>
                                            {tab.name}
                                            {tab.type === 'activity' && activityCountLabel}
                                        </Tab>
                                    ))}
                                    {extraTabs.length > 0 && (
                                        <Menu placement="bottom-end">
                                            {({ onClose }) => (
                                                <>
                                                    <MenuButton
                                                        as={Button}
                                                        size="sm"
                                                        icon="dotsH"
                                                        variant="clear"
                                                        ml={2}
                                                    />

                                                    <MenuList
                                                        minW={2}
                                                        p={2}
                                                        zIndex={2}
                                                        minWidth={150}
                                                        maxWidth={400}
                                                        maxHeight="60vh"
                                                        overflowY="auto"
                                                    >
                                                        {extraTabs.map(({ id, name, type }) => {
                                                            return (
                                                                <Button
                                                                    key={id}
                                                                    onClick={() => {
                                                                        handleTabChange(id)
                                                                        onClose()
                                                                    }}
                                                                    role="menuitem"
                                                                    variant="actionListButton"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        {name}
                                                                        {type === 'activity' &&
                                                                            activityCountLabel}
                                                                    </span>
                                                                </Button>
                                                            )
                                                        })}
                                                    </MenuList>
                                                </>
                                            )}
                                        </Menu>
                                    )}
                                </TabList>
                            )}
                            <TabPanels pt={1}>
                                {activeTabs.map((tab, index) => {
                                    const showBlockControls =
                                        showControls && !useLayoutFrom && index === adjustedTabIndex
                                    return tab.type === 'activity' ? (
                                        <TabPanel key={tab.id}>
                                            <div
                                                style={{
                                                    // if it's the only tab, there is a margin issue
                                                    marginTop: !displayTabs ? 45 : 0,
                                                }}
                                            >
                                                <CollaborationPanel
                                                    key={record._sid} // Recreate the component when the record ID changes.
                                                    onItemsLoaded={updateActivityCount}
                                                    relatedTo={collaborationRelatedTo}
                                                    id={record._sid}
                                                />
                                            </div>
                                        </TabPanel>
                                    ) : (
                                        <TabPanel key={tab.id}>
                                            {tree[tab.treeIndex] &&
                                            /* for perfs reasons we render the tab only if it's selected */
                                            index === adjustedTabIndex ? (
                                                /**
                                                 * a note about perfs:
                                                 * even though FieldLayoutEditor is memoised, it's useless to memoize onChange & tree variables
                                                 * because other fields are also changing
                                                 * (see c1827c48cbf9d03e39401828771ebf8a393577ac for a wip trying to optimise, without any success)
                                                 */

                                                <FieldLayoutEditor
                                                    tree={tree}
                                                    treeIndex={tab.treeIndex}
                                                    context={context}
                                                    object={object}
                                                    onChange={onBlockChange}
                                                    config={data.config}
                                                    showControls={showBlockControls}
                                                    isFieldDisabled={isFieldDisabled}
                                                    recordPermissions={recordPermissions}
                                                    hideFields={get(
                                                        stackOptions,
                                                        'enable_field_widget'
                                                    )}
                                                    viewConfig={data.config}
                                                    viewType={view.type}
                                                    determineIsBlockDisabled={
                                                        determineIsBlockDisabled
                                                    }
                                                    showBlockSelector={showBlockControls}
                                                />
                                            ) : (
                                                <div>
                                                    Can&apos;t display tree at index {tab.treeIndex}
                                                </div>
                                            )}
                                        </TabPanel>
                                    )
                                })}
                                {activeTabs?.length === 0 && showControls && (
                                    <Alert
                                        status="info"
                                        variant="left-accent"
                                        my={10}
                                        title="No active tabs."
                                        description="There are currently no active tabs to display. Please enable at least one tab to
                                            continue."
                                    />
                                )}
                            </TabPanels>
                        </Tabs>
                    </LayoutWrapper>
                </>
            )}
            <UnsavedChangesModal
                isDirty={state.isConfigDirty}
                onSave={saveConfig}
                revertChanges={revertConfigChanges}
            />

            <UnsavedChangesModal
                isDirty={state.isRecordDirty && !state.doNotShowUnsavedChangesModal}
                onSave={saveRecordWithoutRedirect}
                revertChanges={revertRecordChanges}
                endUserThemed
            />
        </div>
    )
}

export default withUser(withStack(withObjects(withViews(withObject(DetailView)))))
