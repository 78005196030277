import React from 'react'

import useTrack from 'utils/useTrack'

import { TrayButtonBase, TrayButtonBaseProps } from '../common/TrayButtonBase'
import useSlidingPane from '../hooks/useSlidingPane'

type AppSettingsButtonProps = TrayButtonBaseProps & {}

export const AppSettingsButton: React.VFC<AppSettingsButtonProps> = () => {
    const { track } = useTrack()

    const { state, showAppSettings, close: closeSlidingPane } = useSlidingPane()

    const handleClick = (): void => {
        track('WIP - Frontend - Admin Side Tray - App Settings - Clicked')
        if (state.key !== 'app-settings') {
            showAppSettings()
        } else {
            closeSlidingPane()
        }
    }

    return (
        <TrayButtonBase
            icon="cog"
            tooltip="App settings"
            onClick={handleClick}
            isActive={state.key === 'app-settings'}
            data-testid="admin-side-tray.app-settings-button"
        />
    )
}
