import React from 'react'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Flex, Text } from '../../../../../v2/ui'
import { SimpleModalCompat } from '../../../../../v2/ui/components/SimpleModal'
import { AIRTABLE_REPAIR_LOADING_MODAL_TEST_ID } from '../airtableTestIds'

const StyledFlex = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const StyledText = styled(Text)`
    flex: 0 0 auto;
    text-align: center;
    width: 100%;
`

const StyledSpinnerWrapper = styled(Flex)`
    flex: 0 0 auto;
    height: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const AirtableRepairLoadingModal = (): React.ReactElement => (
    <SimpleModalCompat
        size="400px"
        height="300px"
        isSecondLayer
        noDividers
        zIndex={1501}
        actionsBorder={false}
        isOpen
        hideTitleBottomBorder
        data-testid={AIRTABLE_REPAIR_LOADING_MODAL_TEST_ID}
        showCloseButton={false}
        closeOnEsc={false}
    >
        <StyledFlex>
            <StyledText variant="heading" marginBottom={8}>
                Repairing...
            </StyledText>
            <StyledSpinnerWrapper>
                <Spinner />
            </StyledSpinnerWrapper>
        </StyledFlex>
    </SimpleModalCompat>
)
