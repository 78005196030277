import { StepTypes } from 'data/hooks/actions'

import { useExecuteActionModal } from '../ExecuteActionModal'
import { getFieldsWithPerms } from '../getFieldsWithPerms'
import { useObjectFromId } from '../helpers'

import { useExecuteInvisibleAction } from './useExecuteInvisibleAction'

const isVisibleStep = (step: ActionStep, record: RecordDto, object: ObjectDto): boolean => {
    if (step.type === StepTypes.ZapierTrigger) {
        return false
    }

    if (step.always_confirm_step) {
        return true
    }

    return getFieldsWithPerms(record, object, step.fields).some((field) => field.promptUser)
}

export const useActionButton = (
    action: ActionDto,
    recordId: string,
    record: RecordDto
): { isVisible: boolean; executeAction: () => void } => {
    const actionId = action?._sid
    const hasZapierStep = action?.options?.steps.some(
        (step: ActionStep) => step.type === StepTypes.ZapierTrigger
    )
    const { toggle } = useExecuteActionModal(action, recordId)

    const run = useExecuteInvisibleAction({
        recordId,
        actionId,
        objectId: action.object_id,
        name: action.name,
        hasZapierStep,
    })

    const steps = action?.options?.steps || []

    const { object } = useObjectFromId(record?._object_id || '')

    const isVisibleAction = object
        ? steps.some((step: ActionStep) => isVisibleStep(step, record, object))
        : true

    return {
        isVisible: isVisibleAction,
        executeAction: isVisibleAction ? toggle : run,
    }
}
