// @ts-strict-ignore
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { queryClient } from './_helpers'
import { useAccounts } from './accounts'
import { useFeatures } from './features'
import { useNavigation } from './navigation'
import { usePages } from './pages'
import { useRoles } from './roles'
import { useStacks } from './stacks'
import { useViews } from './views'

export function useInitialMetadata(options) {
    const initialLoadComplete = useRef<boolean>()
    const reloadingPostAuthChange = useRef<boolean>()

    // Read the current authed user info out of the redux store
    const { user, studioUser } = useSelector((state) => ({
        user: state?.user?.user,
        studioUser: state?.user?.studioUser,
    }))

    const userId = studioUser?._sid || user?._sid
    const userIdRef = useRef(userId)

    const queryAccounts = useAccounts({
        onSuccess: options?.onLoadHandlers?.accounts,
    })
    const queryStacks = useStacks({
        onSuccess: options?.onLoadHandlers?.stacks,
    })

    const queryRoles = useRoles()
    const stackQueries = useInitialStackMetadata({
        initialLoadComplete: initialLoadComplete.current,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const workspaceQueries = [queryAccounts, queryStacks, queryRoles]

    // When the user auth state changes, we need to clear out our meta data and force a full refetch
    useEffect(() => {
        if (userId !== userIdRef.current) {
            userIdRef.current = userId
            // Set a flag so we know that we are doing a reload of queries due to
            // auth state change. **NOTE**: we don't completely clear/remove the queries
            // here because that loss of cached data triggers component tree changes
            // that can wipe out auth-related components with local state waiting for
            // auth change.
            reloadingPostAuthChange.current = true
            queryClient.invalidateQueries()
        }
    }, [userId, workspaceQueries, reloadingPostAuthChange])

    const isFetching = workspaceQueries.find((x) => x.isFetching)
    const isStackFetching = stackQueries.find((x) => x.isFetching)

    // set the isLoading context value to true if we're either loading
    // from a blank state, or we are reloading due to auth change
    const isLoading = Boolean(
        workspaceQueries.find((x) => x.isLoading) || (isFetching && reloadingPostAuthChange.current)
    )
    const isStackLoading = Boolean(
        stackQueries.find((x) => x.isLoading) ||
            (isStackFetching && reloadingPostAuthChange.current)
    )

    // if we failed to load any of the account level queries,
    // then we should return that information so a failure
    // page can be displayed, as the app won't work without this data.
    const loadingAccountFailed = Boolean(workspaceQueries.find((x) => x.isError))

    if (!isLoading) {
        initialLoadComplete.current = true
        reloadingPostAuthChange.current = false
    }

    return {
        initialLoadComplete: initialLoadComplete.current,
        isLoading: isLoading,
        isFetching: isFetching || isStackFetching,
        isStackLoading,
        loadingAccountFailed,
    }
}

const useInitialStackMetadata = ({ initialLoadComplete = false }) => {
    const queryOptions = useMemo(() => ({ enabled: initialLoadComplete }), [initialLoadComplete])
    const queryViews = useViews(queryOptions)
    const queryNavigation = useNavigation(queryOptions)
    const queryPages = usePages(queryOptions)
    const queryFeatures = useFeatures(queryOptions)

    const queries = [queryViews, queryNavigation, queryPages, queryFeatures]

    return queries
}
