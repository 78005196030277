import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { Item } from './types'

export type FieldsEditorContext = {
    editingFieldItem: Item | undefined
    setEditingFieldItem: (field: Item | undefined) => void

    isEditorOpen: boolean
    setIsEditorOpen: (isOpen: boolean) => void

    openEditorInPortal: boolean
    setOpenEditorInPortal: (inPortal: boolean) => void

    previousEditorTitle: string | undefined
    setPreviousEditorTitle: (title: string | undefined) => void
}

const defaultContext: FieldsEditorContext = {
    editingFieldItem: undefined,
    setEditingFieldItem: () => {},
    isEditorOpen: false,
    setIsEditorOpen: () => {},
    openEditorInPortal: false,
    setOpenEditorInPortal: () => {},
    previousEditorTitle: undefined,
    setPreviousEditorTitle: () => {},
}

const FieldsEditorContextImpl = React.createContext<FieldsEditorContext>(defaultContext)

type FieldsEditorContextProviderProps = React.PropsWithChildren<{}>

export const FieldsEditorContextProvider: React.FC<FieldsEditorContextProviderProps> = ({
    children,
}) => {
    const [editingFieldItem, setEditingFieldItem] = useState<Item | undefined>()
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [openEditorInPortal, setOpenEditorInPortal] = useState(false)

    const previousEditorTitle = useRef<string | undefined>()
    const setPreviousEditorTitle = useCallback((title: string | undefined) => {
        previousEditorTitle.current = title
    }, [])

    const context = useMemo(
        () => ({
            editingFieldItem,
            setEditingFieldItem,
            isEditorOpen,
            setIsEditorOpen,
            openEditorInPortal,
            setOpenEditorInPortal,
            previousEditorTitle: previousEditorTitle.current,
            setPreviousEditorTitle,
        }),
        [editingFieldItem, isEditorOpen, openEditorInPortal, setPreviousEditorTitle]
    )

    return (
        <FieldsEditorContextImpl.Provider value={context}>
            {children}
        </FieldsEditorContextImpl.Provider>
    )
}

export function useFieldsEditorContext(): FieldsEditorContext {
    const context = useContext(FieldsEditorContextImpl)

    return context
}
