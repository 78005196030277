import React, { useContext, useEffect, useRef } from 'react'

import { BreadcrumbsMode, HeaderContext } from 'features/utils/HeaderContext'

import ViewHeader from './ViewHeader'

type BlankPageHeaderProps = Omit<
    React.ComponentPropsWithoutRef<typeof ViewHeader>,
    'parentIsMounted' | 'onChange' | 'viewLayoutContext'
> & {}

export const BlankPageHeader: React.FC<BlankPageHeaderProps> = (props) => {
    const headerContext = useContext(HeaderContext)
    const isMounted = useRef(false)

    useEffect(() => {
        const context = headerContext
        if (!context) return

        context.setBreadcrumbsMode(BreadcrumbsMode.BackButtonOnly)

        return () => {
            context.setBreadcrumbsMode(BreadcrumbsMode.Full)
        }
    }, [headerContext])

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    return <ViewHeader {...props} parentIsMounted={isMounted.current} />
}
