import React, { useCallback, useMemo, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'

import { useAppContext } from 'app/AppContext'
import { useConfirmModal } from 'app/ConfirmModal'
import HELP_LINKS from 'app/helpLinks'
import { resetPages } from 'data/hooks/pages'
import { useRoles } from 'data/hooks/roles'
import { useUpdateStack } from 'data/hooks/stacks'

import { Alert, FeatureSplashScreen, Flex, Icon, Text } from 'v2/ui'
import { SolidWarning } from 'v2/ui/svgs'
import * as SvgIcons from 'v2/ui/svgs'
import {
    FEATURES,
    isFeatureLocked,
    ProtectedFeature,
    ProtectedFeatureIndicator,
} from 'v2/ui/utils/ProtectedFeature'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../../utils/analytics'
import { SidebarItem } from '../../../v2/ui'
import { slideInLeftAnimation } from '../AppSettingsModalSidebar'

import AddRole from './AppSettingsModalAddRole'
import RolePage from './AppSettingsModalRolePage'

type UseRolesData = {
    data?: RoleDto[]
}

export default function AppSettingsModalRoles({
    defaultActiveRoleId,
}: {
    defaultActiveRoleId: string
}): JSX.Element {
    const { selectedStack, workspaceAccount } = useAppContext()

    const [addRoleModalOpen, setAddRoleModalOpen] = useState(false)
    const { data: allRoles }: UseRolesData = useRoles()

    const roles = useMemo(
        () => allRoles?.filter(({ stack_id }) => stack_id === selectedStack?._sid) || [],
        [allRoles, selectedStack]
    )

    const filteredRoles = useMemo(() => {
        return !workspaceAccount?.optional_features?.sync_stacker_users
            ? roles
            : roles.filter((role) => role.api_name !== 'internal_admin')
    }, [workspaceAccount, roles])
    const [activeRoleId, setActiveRoleId] = useState<string | null>(
        defaultActiveRoleId || filteredRoles[0]?._sid
    )
    const rolesEnabled = selectedStack?.options?.roles__enabled
    const [errorTogglingRoles, setErrorTogglingRoles] = useState(null)
    const { mutateAsync: updateStack } = useUpdateStack()
    const [isProtectedOpen, setIsProtectedOpen] = useState(false)
    const { show: showConfirm } = useConfirmModal({})

    const resetActiveRole = useCallback(
        (): void => setActiveRoleId(defaultActiveRoleId || filteredRoles[0]?._sid),
        [defaultActiveRoleId, filteredRoles]
    )

    const DisableRolesConfirmationMessage = (): JSX.Element => {
        return (
            <>
                <Text mb={3}>
                    Disabling roles might give app users more access than they currently have, as
                    general permissions and page access rules will apply to them.
                </Text>

                <Alert
                    status="info"
                    iconStyle={{
                        size: 4,
                        color: V4DesignSystem.colors.gray[300],
                    }}
                    background="#F8F9FA"
                    description="After disabling roles, review your permissions to confirm that they are functioning as intended."
                    m={0}
                    mb={2}
                    w="100%"
                    fontSize={12}
                    boxShadow="none"
                />
            </>
        )
    }
    const showConfirmationModal = (): void => {
        showConfirm({
            title: 'Are you sure you want to disable roles?',
            icon: <Icon svg={<SolidWarning />} size="35px" />,
            message: DisableRolesConfirmationMessage(),
            confirmButtonText: 'Disable roles',
            cancelButtonText: 'Cancel',
            customSize: '450px',
            onConfirm: (modal: any) => {
                toggleRoles(), modal.toggle()
            },
        })
    }

    const toggleRoles = (): Promise<void> => {
        if (!selectedStack) {
            return Promise.resolve()
        }

        return updateStack({
            id: selectedStack._sid,
            patch: {
                options: {
                    ...selectedStack.options,
                    roles__enabled: !selectedStack.options?.roles__enabled,
                },
            },
        }).then(
            () => {
                if (selectedStack.options?.roles__enabled) {
                    analytics?.track('roles enabled')
                } else {
                    analytics?.track('roles disabled')
                }
                resetPages()
            },
            (e) => {
                Sentry.captureMessage(
                    `Error while toggling 'roles__enabled'. Error message: ${e.message}`
                )
                setErrorTogglingRoles(e)
            }
        )
    }

    return (
        <>
            {!rolesEnabled ? (
                <FeatureSplashScreen
                    svg={SvgIcons.RolesIllustration}
                    title="Roles"
                    protectedFeature={FEATURES.multipleRoles}
                    subtitle={
                        <>
                            To determine what each user can access in the app, you can give each
                            user a role and set permissions for the role.
                            {errorTogglingRoles && (
                                <Alert status="error" description="An unknown error occured." />
                            )}
                        </>
                    }
                    actions={[
                        !isFeatureLocked(FEATURES.multipleRoles, selectedStack)
                            ? {
                                  content: 'Enable roles',
                                  onClick: toggleRoles,
                              }
                            : { content: 'Upgrade', onClick: () => setIsProtectedOpen(true) },
                        { content: 'Learn more', href: HELP_LINKS.USER_ROLES },
                    ]}
                />
            ) : (
                <Flex
                    column
                    height="100%"
                    width="100%"
                    align="stretch"
                    wrap="nowrap"
                    position="relative"
                >
                    <Flex p={2} bg={V4DesignSystem.colors.gray[50]}>
                        <Switch
                            size="sm"
                            display="inline"
                            isChecked={rolesEnabled}
                            onChange={showConfirmationModal}
                        />
                        <Text ml={2} fontWeight="bold" display="inline">
                            Roles Enabled&nbsp;
                        </Text>
                        <Text ml={2} display="inline">
                            Users permissions in the app are determined by their roles.
                        </Text>
                        <div
                            style={{
                                position: 'absolute',
                                right: '3px',
                                top: '3px',
                            }}
                        >
                            <ProtectedFeatureIndicator
                                feature={FEATURES.multipleRoles}
                                onClick={() => setIsProtectedOpen(true)}
                            />
                        </div>
                    </Flex>
                    <Flex style={{ alignItems: 'stretch', flexWrap: 'nowrap' }}>
                        <Flex
                            column
                            style={{
                                width: 161,
                                borderRight: `1px solid ${V4DesignSystem.colors.gray[100]}`,
                                overflowY: 'auto',
                                padding: '20px 10px',
                                flexShrink: 0,
                                zIndex: 1,
                                animation: `${slideInLeftAnimation.name} 0.2s ease-out`,
                            }}
                        >
                            {filteredRoles.map((role) => (
                                <SidebarItem
                                    key={role._sid}
                                    active={role._sid === activeRoleId}
                                    onChange={() => {
                                        setActiveRoleId(role._sid)
                                    }}
                                    style={{ marginBottom: 1, width: 140 }}
                                >
                                    {role.label}
                                </SidebarItem>
                            ))}
                            <SidebarItem
                                icon="add"
                                style={{ marginTop: 8, width: 140 }}
                                onChange={() => {
                                    setActiveRoleId(null)
                                    setAddRoleModalOpen(true)
                                }}
                            >
                                Add a role
                            </SidebarItem>
                        </Flex>
                        <div
                            style={{
                                flexGrow: 1,
                                padding: '20px 10px',
                                overflow: 'auto',
                            }}
                        >
                            {activeRoleId && (
                                <RolePage
                                    key={activeRoleId}
                                    role={roles.find(({ _sid }) => _sid === activeRoleId)}
                                    resetActiveRole={resetActiveRole}
                                />
                            )}
                            {!activeRoleId && addRoleModalOpen && (
                                <AddRole
                                    onSubmit={(data: any) => {
                                        setActiveRoleId(data._sid)
                                        setAddRoleModalOpen(false)
                                    }}
                                />
                            )}
                        </div>
                    </Flex>
                </Flex>
            )}
            <ProtectedFeature
                feature={FEATURES.multipleRoles}
                showModal={isProtectedOpen}
                onModalClosed={() => setIsProtectedOpen(false)}
            />
        </>
    )
}
