import React, { CSSProperties, useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { Flex, Heading, Modal, ScrollBox } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export type ModalWithHintProps = {
    showCloseButton: boolean
    isOpen: boolean
    isHidden: boolean
    // Null means do NOT render header
    headerText: string | null

    forceHideImage?: boolean
    hintImageUrl?: string
    hintImageClassAndStyle?: {
        className?: string
        style?: CSSProperties
        containerStyle?: CSSProperties
    }
    trapFocus?: boolean
    onClose: () => void
}

const ImageContainer = styled('div')<React.HTMLAttributes<'div'> & { url?: string }>`
    flex-grow: 1;
    height: 80%;
    background-image: url(${(props: any) => props.url});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    max-width: 80%;
    background-color: #f6f7fb;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ModalWithHint: React.FC<ModalWithHintProps> = (props) => {
    const isMobile = useIsMobile()
    const isHintImageProvided =
        !props.forceHideImage &&
        (props.hintImageUrl !== undefined || props.hintImageClassAndStyle !== undefined)
    const [stateHintImageUrl, setStateHintImageUrl] = useState(props.hintImageUrl)

    useEffect(() => {
        setStateHintImageUrl(props.hintImageUrl)
    }, [props.hintImageUrl])

    return (
        <Modal
            showCloseButton={props.showCloseButton}
            closeButtonStyle={{ position: 'absolute', right: 16, top: 8 }}
            size={isHintImageProvided ? '80%' : '400px'}
            height={isHintImageProvided ? '85%' : undefined}
            isOpen={props.isOpen}
            isHidden={props.isHidden}
            isSecondLayer={true}
            noDividers={true}
            onClose={props.onClose || (() => null)}
            padding={isHintImageProvided ? '0' : undefined}
            trapFocus={props.trapFocus}
        >
            <Flex
                style={{ height: '100%' }}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
                wrap="nowrap"
                maxHeight="100%"
                direction={isMobile ? 'column-reverse' : 'row'}
            >
                <ScrollBox
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    wrap="nowrap"
                    minHeight={0}
                    maxHeight="100%"
                    overflowY="auto"
                    style={{
                        padding: isHintImageProvided ? 36 : null,
                        flexShrink: 0,
                        minWidth: !isMobile ? 350 : undefined,
                        maxWidth: isMobile ? undefined : isHintImageProvided ? 445 : 350,
                        width: isMobile ? '100%' : undefined,
                        flexGrow: isMobile ? 1 : undefined,
                    }}
                >
                    {props.headerText === null ? null : (
                        <Heading variant="modal" value={props.headerText} />
                    )}
                    {props.children}
                </ScrollBox>
                {isHintImageProvided && (
                    <Flex
                        flexGrow={isMobile ? 0 : 1}
                        justifyContent={'center'}
                        style={{
                            background: '#f6f7fb',
                            height: isMobile ? '40%' : '100%',
                            width: isMobile ? '100%' : undefined,
                            ...props.hintImageClassAndStyle?.containerStyle,
                        }}
                    >
                        <ImageContainer
                            id="modalWithHintImageContainer"
                            style={props.hintImageClassAndStyle?.style}
                            className={props.hintImageClassAndStyle?.className}
                            url={stateHintImageUrl}
                        />
                    </Flex>
                )}
            </Flex>
        </Modal>
    )
}
