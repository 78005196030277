import React, { useCallback, useState } from 'react'

import { BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled-base'

import { Button, Flex, Icon, Tooltip } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

type QuickCopyButtonProps = BoxProps & {
    isHidden: boolean
    value: string
    onDataCopied?: (copiedData: string) => void
}

const QuickCopyButton: React.FC<QuickCopyButtonProps> = ({
    isHidden,
    value,
    onDataCopied,
    ...rest
}) => {
    const DEFAULT_LABEL = 'Copy to clipboard'
    const [label, setLabel] = useState<string>(DEFAULT_LABEL)
    const [isOpen, setIsOpen] = useState<boolean>()

    const handleClick = useCallback(async () => {
        await navigator.clipboard.writeText(value)

        onDataCopied?.(value)
        setLabel('Copied!')
        setIsOpen(true)

        setTimeout(() => {
            setLabel(DEFAULT_LABEL)
            setIsOpen(false)
        }, 400)
    }, [value, onDataCopied])

    return (
        <CopyContentBox
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            p={0}
            m={0}
            isHidden={isHidden}
            {...rest}
        >
            <Tooltip open={isOpen} placement="top" label={label} delay={200}>
                <Button hideSpinner={true} variant="clear" onClick={handleClick}>
                    <Icon m={0} p={0} svg={<SvgIcons.Copy />} />
                </Button>
            </Tooltip>
        </CopyContentBox>
    )
}

const CopyContentBox = styled(Flex)<Partial<QuickCopyButtonProps>>`
    visibility: ${(props) => (props.isHidden ? 'hidden' : 'inherit')};
`

export default QuickCopyButton
