/* Code Quality: Not audited */

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'qs'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { getCollection } from 'data/githubFeatures'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import ThemeWrapper from 'features/pages/blocks/styles/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import { FormLabel, Heading, Section } from 'legacy/v1/ui'
import { isExternal } from 'utils/utils'

import { Button, Input, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { CenterPanel, CenterPanelWrapper } from '../studio/ui/Layout'

import LoginPasswordlessPage from './LoginPasswordlessPage'
import { setFeatureLogo } from './utils'

const BASE_CLASS = STYLE_CLASSES.LOGIN_PAGE

export class UserLoginPage extends React.Component {
    state = { icon: '', title: '', logo: '' }

    constructor(props) {
        // If we are requesting a redirect and are already logged
        // in, then request an auth token and do the redirect
        super(props)
        const redirect = this.getRedirect()
        const studioToken = localStorage.getItem('studio_token')

        if (redirect && studioToken) {
            props.userActions
                .requestStudioTemporaryAuthToken(studioToken, redirect)
                .then((response) => {
                    if (response.redirect) {
                        const joiner = response.redirect.indexOf('?') === -1 ? '?' : '&'
                        window.location = `${response.redirect}${joiner}auth_token=${response.auth_token}`
                    }
                })
        }

        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        this.state.email = searchString.email
    }

    UNSAFE_componentWillMount() {
        const collection = getCollection()

        localStorage.removeItem(`feature_config:${collection}`) // First step, we remove the feature_config item
        setFeatureLogo(this, collection) // Save the logo and the title in the state
    }

    componentDidMount() {
        this.tryToAuthEndUser().then()
    }

    getRedirect() {
        let redirect = null
        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        if (searchString && searchString.redirect) {
            redirect = decodeURI(searchString.redirect)
        }

        return this.isURL(redirect) && redirect
    }

    getApiToken = () => {
        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        if (searchString && searchString.api_token) {
            return searchString.api_token
        } else {
            return null
        }
    }

    // Upon successful auth, do a browser-redirect to the home page. This is better
    // than using an in-page redirection because some of the objects we have in redux
    // from pre-authorization are likely not complete.
    onUserAuthed = () => {
        const query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        // redirect to where user wanted to go before login
        if (query.r && !isExternal(query.r)) {
            // If there's a hash, add it back in to redirect to specific tab
            window.location.hash
                ? window.location.assign(query.r + window.location.hash)
                : window.location.assign(query.r)
        } else {
            window.location.assign(getUrl(Urls.Home))
        }
    }

    tryToAuthEndUser = async () => {
        const apiToken = this.getApiToken()
        if (apiToken) {
            const token = apiToken
            const user = await this.props.userActions.logInEndUserByApiToken(token)
            const isAuthenticated = !!user.api_token

            if (isAuthenticated) {
                this.onUserAuthed()
            } else {
                // If the end user tried and failed to authenticate with an api token (via sharing link)
                // then redirect to the login page
                return window.location.assign(getUrl(Urls.Login))
            }
        }
    }

    logIn = async (e) => {
        e.preventDefault()
        const loggedInAsEndUser = await this.logInEndUser()
        if (loggedInAsEndUser) return
        const loggedInAsStudioUser = await this.loginStudioUser()
        if (loggedInAsStudioUser) return
        if (!loggedInAsEndUser && !loggedInAsStudioUser) this.setState({ error: true })
    }

    loginStudioUser = () => {
        // If we have a redirect in the url, request it
        const redirect = this.getRedirect()
        return this.props.userActions
            .logInStudioUser(this.state.email, this.state.password, redirect)
            .then((response) => {
                if (!response.api_token) {
                    return Promise.resolve(false)
                }
                if (response.redirect) {
                    const joiner = response.redirect.indexOf('?') === -1 ? '?' : '&'
                    window.location = `${response.redirect}${joiner}auth_token=${response.auth_token}`
                } else {
                    this.onUserAuthed()
                }
                return Promise.resolve(true)
            })
            .catch(() => Promise.resolve(false))
    }

    logInEndUser = () => {
        return this.props.userActions
            .logInEndUser(this.state.email, this.state.password)
            .then((response) => {
                const token = response.api_token
                if (!token) {
                    return Promise.resolve(false)
                }

                this.onUserAuthed()
                return Promise.resolve(true)
            })
            .catch(() => Promise.resolve(false))
    }

    isStudioUser = () => {
        return localStorage.getItem('studio_token')
    }

    isURL(str) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?' + // port
                '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
                '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ) // fragment locator
        return pattern.test(str)
    }

    render() {
        const { stackOptions } = this.props

        if (!this.props.stack._sid) return null
        const options = stackOptions || {}

        if (stackOptions.passwordless) {
            return <LoginPasswordlessPage />
        }

        const appName = options?.external_access?.name || this.props.stack.name
        const logo = getLogoUrl(this.props.stack, true)

        const dataMapping = get(this.props.stack, 'options.data_mapping', {})

        const usernameFieldLabel = dataMapping.custom_username_label || 'Email'
        const passwordFieldLabel = dataMapping.custom_password_label || 'Password'
        const passwordFieldType = dataMapping.custom_password_field_type || 'password'
        const loginExplanation = dataMapping.custom_login_explanation || ''
        const hideRegistration = dataMapping.custom_hide_registration || false

        const launched = this.props.isLaunched
        const adminLoginLink = `${settings.STUDIO_URL}/login`

        return (
            <>
                <DefaultHeaderMeta title="Login" />
                <ThemeWrapper loadFromStack>
                    {() => (
                        <>
                            {this.getApiToken() && (
                                <CenterPanelWrapper className={BASE_CLASS}>
                                    <CenterPanel>
                                        <Section margin="large" padding="none" centered>
                                            <Logo src={logo} />
                                        </Section>
                                        <Section margin="none" padding="none" centered>
                                            <Heading size="medium" margin="large">
                                                Logging you into {appName}...
                                            </Heading>
                                        </Section>
                                    </CenterPanel>
                                </CenterPanelWrapper>
                            )}
                            {!this.getApiToken() && (
                                <CenterPanelWrapper className={BASE_CLASS}>
                                    <FlexBreak />
                                    <CenterPanel>
                                        <Section margin="large" padding="none" centered>
                                            <Logo src={logo} />
                                        </Section>
                                        {launched && (
                                            <form>
                                                <Section margin="none" padding="none" centered>
                                                    <Heading size="medium" margin="large">
                                                        <span className={STYLE_CLASSES.LOGIN_TITLE}>
                                                            Login to
                                                        </span>{' '}
                                                        {appName}
                                                    </Heading>
                                                    {loginExplanation && (
                                                        <Text>{loginExplanation}</Text>
                                                    )}
                                                </Section>
                                                <Section margin="none" padding="none">
                                                    <FormLabel hidden htmlFor="email">
                                                        {usernameFieldLabel}
                                                    </FormLabel>
                                                    <Input
                                                        onChange={(e) =>
                                                            this.setState({ email: e.target.value })
                                                        }
                                                        padding="medium"
                                                        placeholder={usernameFieldLabel}
                                                        id="email"
                                                        type="email"
                                                        value={this.state.email}
                                                        mb={2}
                                                        className={STYLE_CLASSES.EMAIL_INPUT}
                                                    />
                                                </Section>
                                                <Section margin="none" padding="none">
                                                    <FormLabel hidden htmlFor="password">
                                                        {passwordFieldLabel}
                                                    </FormLabel>
                                                    <Input
                                                        type={passwordFieldType}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                password: e.target.value,
                                                            })
                                                        }
                                                        padding="medium"
                                                        placeholder="Password"
                                                        id="password"
                                                        className={STYLE_CLASSES.PASSWORD_INPUT}
                                                    />
                                                </Section>
                                                <Button
                                                    mt={6}
                                                    variant="primary"
                                                    onClick={this.logIn}
                                                    type="submit"
                                                    buttonSize="md"
                                                    width="100%"
                                                    className={STYLE_CLASSES.LOGIN_BUTTON}
                                                >
                                                    Log in
                                                </Button>
                                            </form>
                                        )}
                                        {launched && !hideRegistration && (
                                            <Section style={{ alignItems: 'center' }} margin="none">
                                                <Text margin="none" padding="none">
                                                    <span
                                                        className={
                                                            STYLE_CLASSES.RESET_PASSWORD_TEXT
                                                        }
                                                    >
                                                        Forgot your password?{' '}
                                                    </span>
                                                    <Link
                                                        to={getUrl(Urls.ForgotPassword)}
                                                        className={
                                                            STYLE_CLASSES.RESET_PASSWORD_LINK
                                                        }
                                                    >
                                                        Reset it here
                                                    </Link>
                                                </Text>
                                                <Text margin="none" padding="none">
                                                    <span className={STYLE_CLASSES.REGISTER_TEXT}>
                                                        Not yet registered?{' '}
                                                    </span>
                                                    <Link
                                                        to={getUrl(Urls.Register)}
                                                        className={STYLE_CLASSES.REGISTER_LINK}
                                                    >
                                                        Sign up
                                                    </Link>
                                                </Text>
                                            </Section>
                                        )}

                                        {!launched && (
                                            <>
                                                <Section margin="none" padding="none" centered>
                                                    <Heading
                                                        size="medium"
                                                        margin="large"
                                                        style={{
                                                            textAlign: 'center',
                                                            lineHeight: '1.3em',
                                                        }}
                                                    >
                                                        This app is still being built and is not yet
                                                        launched
                                                    </Heading>
                                                </Section>
                                                <Section
                                                    style={{ alignItems: 'center' }}
                                                    margin="none"
                                                >
                                                    <Text margin="none" padding="none">
                                                        Are you the admin?{' '}
                                                        <a href={adminLoginLink}>Log in here</a>
                                                    </Text>
                                                </Section>
                                            </>
                                        )}
                                        {this.state.error && (
                                            <Section
                                                style={{
                                                    alignItems: 'center',
                                                    margin: '0',
                                                    padding: '0',
                                                }}
                                            >
                                                <Text style={{ color: 'red' }}>
                                                    Please check your username and password
                                                </Text>
                                            </Section>
                                        )}
                                    </CenterPanel>
                                </CenterPanelWrapper>
                            )}
                        </>
                    )}
                </ThemeWrapper>
            </>
        )
    }
}

UserLoginPage.propTypes = {
    history: PropTypes.object.isRequired, // From withRouter
    stack: PropTypes.object.isRequired, // From withStack,
    stackOptions: PropTypes.object, // From withStack,
    userActions: PropTypes.object.isRequired, // From withUser,
}

const Logo = styled('img')`
    height: 60px;
    object-fit: contain;
    max-width: 90%;

    @media (max-width: 420px) {
        height: 35px;
    }
`
const FlexBreak = styled('div')`
    flex-basis: 100%;
    height: 0;
`

export default withUser(withStack(UserLoginPage))
