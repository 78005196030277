import React, { forwardRef, memo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { getStackTheme } from 'app/Branding'
import { getUrl, Urls } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'
import { withUser } from 'data/wrappers/WithUser'
import AppBar from 'features/AppBar/AppBar'
import UserMenu from 'features/AppBar/UserMenu'
import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { DefaultStackNameLogo, getLogoUrl, useCustomerLogo } from 'features/utils/getLogo'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { FloatingAddNewButton } from 'features/workspace/FloatingAddNewButton/FloatingAddNewButton'

import { Avatar, Box, Divider, Flex, Icon, ScrollBox, Text, Tooltip } from 'v2/ui'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import Button from 'ui/deprecated/atoms/Button'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../../utils/analytics'
import useNavigationColor from '../../../v2/ui/hooks/useNavigationColor'
import { BANNER_HEIGHT } from '../../core/appBannerConstants'
import WorkspaceLogo from '../WorkspaceLogo'
import { wsNavThemeToV3 } from '../WorkspaceSettingsModalUi'

import AppNavTree from './AppNavTree'
import NotificationBar from './NotificationBar'

export default function SidebarWorkspace(props) {
    const { selectedStack: stack, workspaceAccount } = useAppContext()
    const { data: stacks } = useStacks()

    const filteredStacks =
        stacks?.filter((stack) => stack.account_id === workspaceAccount?._sid) ?? []

    const theme = stack?.options?.theme ?? {}
    const navTheme = useNavigationColor(theme)
    // Use default for appBar
    const appBarTheme = useNavigationColor({})
    const { studioUser } = useAppUserContext()

    const isGuest = studioUser?.membership_options?.role === 'guest'
    const hideAppBarForGuest = isGuest && filteredStacks?.length === 1

    return (
        <Flex wrap="nowrap" align="stretch" height="100%" maxHeight="100%" maxWidth="100%">
            {!hideAppBarForGuest && <AppBar navTheme={appBarTheme} />}
            {stack?._sid && (
                <Sidebar
                    showSideNav={props.showSideNav}
                    navTheme={navTheme}
                    stack={stack}
                    hideAppBarForGuest={hideAppBarForGuest}
                    overrideStyle={props.overrideStyle}
                    onClickNavItem={props.onClickNavItem}
                />
            )}
        </Flex>
    )
}

const Sidebar = memo(function Sidebar({
    navTheme,
    stack,
    hideAppBarForGuest,
    showSideNav,
    onClickNavItem,
}) {
    const [isBannerShowing] = useIsBannerShowing()
    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    const isMobile = window.innerWidth < 768

    const isV3App = !stack?.options?.workspace_app

    return (
        <Flex
            column
            wrap="nowrap"
            flexGrow={1}
            minWidth={0}
            align="stretch"
            background={navTheme.bgScdNav}
            onClick={handleClickOutsideSidePane}
            maxWidth="100%"
            marginTop={isBannerShowing && !isMobile ? `${BANNER_HEIGHT}px` : null}
            style={
                navTheme.theme === 'light'
                    ? { borderLeft: '1px solid #F5F7F8', borderRight: '1px solid #EEF0F1' }
                    : {}
            }
            className={ONBOARDING_CLASSES.APP_NAV_MENU}
            data-testid="app-nav-menu"
        >
            {!showSideNav ? (
                <Flex flexGrow={1} flexShrink={0} />
            ) : (
                <>
                    <SidebarHeader
                        stack={stack}
                        hideAppBarForGuest={hideAppBarForGuest}
                        isV3Ap={isV3App}
                        isMobile={isMobile}
                        navTheme={navTheme}
                    />
                    <Divider variant="custom" bg="#AAAAAA33" />
                    {!stack?.options?.navbar_hide_app_name &&
                        (stack.name.length >= 20 ? (
                            <Tooltip label={stack.name} placement="right">
                                <Flex px={4} pt={4} pb={1} style={{ paddingRight: 5 }}>
                                    <div
                                        style={{
                                            ...V4DesignSystem.h2,
                                            color: navTheme.textBright,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {stack.name}
                                    </div>
                                </Flex>
                            </Tooltip>
                        ) : (
                            <Flex px={4} pt={4} pb={1} style={{ paddingRight: 5 }}>
                                <div
                                    style={{
                                        ...V4DesignSystem.h2,
                                        color: navTheme.textBright,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {stack.name}
                                </div>
                            </Flex>
                        ))}
                    <ScrollBox
                        marginTop="10px"
                        overflowY="auto"
                        flexGrow={1}
                        flexShrink={1}
                        scrollbarColor={navTheme.highlightColor}
                    >
                        <AppNavTree
                            stack={stack}
                            navTheme={wsNavThemeToV3(navTheme)}
                            onClickNavItem={onClickNavItem}
                        />
                    </ScrollBox>
                </>
            )}

            <FloatingAddNewButton p={3} />

            {/* Hide edit layout button if user hasn't connected to any data source */}
            {!isMobile && (
                <>
                    <GuestTray
                        stack={stack}
                        navTheme={navTheme}
                        hideAppBarForGuest={hideAppBarForGuest}
                        showSideNav={showSideNav}
                    />
                </>
            )}
        </Flex>
    )
})

const AppLogo = ({ stack }) => {
    const { url: clientLogo } = useCustomerLogo()
    const theme = getStackTheme(stack)
    const stackLogo = getLogoUrl(stack, false, null)
    const showDefaultStackLogo = theme && !stackLogo && !clientLogo && theme.enableDefaultStackLogo

    if (showDefaultStackLogo) {
        return (
            <Link to={getUrl(Urls.Home)}>
                <DefaultStackNameLogo
                    name={stack?.name}
                    navColor={theme.navColor}
                    brandColor={theme.brandColor}
                    wrapText
                />
            </Link>
        )
    }
    return <WorkspaceLogo />
}

export const SidebarHeader = (props) => {
    return props.hideAppBarForGuest && !props.isV3App && !props.isMobile ? (
        <Flex direction="row" align="center" justify="space-between">
            {!props.isTopNav && <NotificationBar navTheme={props.navTheme} ml={5} />}

            <Box
                style={{ padding: '13px 5px 10px', minHeight: 66 }}
                textAlign="center"
                color={props.navTheme.textBright}
                className={STYLE_CLASSES.LOGO}
                flex={1}
            >
                <AppLogo stack={props.stack} />
            </Box>
        </Flex>
    ) : (
        <Box
            style={{ padding: '10px 5px', minHeight: 66 }}
            textAlign="center"
            color={props.navTheme.textBright}
            className={STYLE_CLASSES.LOGO}
        >
            <AppLogo stack={props.stack} />
        </Box>
    )
}

const GuestTray = withUser(({ hideAppBarForGuest, showSideNav, stack, navTheme, studioUser }) => {
    const isV3App = !stack?.options?.workspace_app
    // if we're logged in as a guest and we're hiding the app bar because they only have access
    // to one app, then show the user menu at the bottom of the nav
    if (hideAppBarForGuest && !isV3App && showSideNav) {
        return (
            <div height={10}>
                <Divider variant="custom" bg="#AAAAAA33" />
                <UserMenuButton studioUser={studioUser} navTheme={navTheme} />
            </div>
        )
    }

    return null
})

export const UserMenuButton = ({ studioUser, navTheme, mobile, iconOnly, ...otherProps }) => {
    const [userProfileMenuOpen, setUserProfileMenuOpen] = useState(false)

    const userMenuStyle = {
        backgroundColor: navTheme?.bgScdNav || navTheme?.button?.background || '#F4F5F8',
        color:
            navTheme?.textColorBright ||
            navTheme?.button?.color ||
            navTheme?.textBright ||
            '#ffffff',
    }

    const icon = studioUser?.options?.photo ? (
        <Avatar src={studioUser.options.photo} size="xs" />
    ) : (
        <Icon icon="avatar" />
    )

    const menuProps = mobile
        ? {
              height: '24px',
              width: '24px',
              fontSize: 'sm',
          }
        : { width: !iconOnly ? '100%' : null, height: 10 }

    return (
        <UserMenu
            onClick={() => {
                analytics.track('user menu clicked', {
                    from: 'side nav',
                })
                setUserProfileMenuOpen(!userProfileMenuOpen)
            }}
            {...menuProps}
            {...otherProps}
            style={userMenuStyle}
        >
            {mobile || iconOnly ? (
                icon
            ) : (
                <Flex row width="100%" justify="space-between" align="center" wrap="nowrap">
                    <Flex row align="center" wrap="nowrap" flexShrink={1} minWidth={0}>
                        {icon}
                        <Text
                            color={navTheme?.textBright}
                            variant="profile"
                            ml="10px"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {studioUser?.name || studioUser?.email}
                        </Text>
                    </Flex>
                    <Icon icon={userProfileMenuOpen ? 'arrowUp' : 'arrowDown'} ml={2} />
                </Flex>
            )}
        </UserMenu>
    )
}

export const TrayButton = forwardRef(({ children, tooltip, classes, ...props }, ref) => {
    const Wrapper = tooltip ? Tooltip : React.Fragment
    const wrapperProps = tooltip
        ? {
              popperOptions: {
                  modifiers: { preventOverflow: { enabled: false } },
              },
              placement: 'top',
              arrow: false,
              animation: 'fade',
              offset: -16,
              label: tooltip,
              className: classes,
          }
        : null
    return (
        <Wrapper {...wrapperProps}>
            <Button v4 theme={'light'} type="link" ref={ref} {...props}>
                {children}
            </Button>
        </Wrapper>
    )
})
