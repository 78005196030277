import * as React from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { SidebarItem } from '../../v2/ui'

/**
 * list of sidebar items
 * @type {{id: ProfileSettingsPage, name: string, icon: string, optionalFeatureRequired?: string }[]}
 */
const menu = [
    {
        name: 'General',
        id: 'general',
        icon: 'cog',
    },
    {
        name: 'Notifications',
        id: 'notifications',
        icon: 'emailSolid',
    },
    {
        name: 'Support Login',
        id: 'supportLogin',
        icon: 'users',
        isDisabled: (workspaceAccount, user) => {
            return user?.membership_options?.role === 'guest'
        },
        rightRequired: Rights.ContactSupport,
    },
]

const useMenuItems = (hasRight) => {
    const { selectedStack: stack, workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()

    return menu
        .filter(({ rightRequired }) => {
            if (!rightRequired) {
                return true
            }

            return hasRight(rightRequired)
        })
        .filter(({ optionalFeatureRequired }) => {
            if (!optionalFeatureRequired) {
                return true
            }

            // stack must be loaded before assessing whether to show this menu item
            return !!stack?.combined_optional_features?.[optionalFeatureRequired]
        })
        .filter(({ isDisabled }) => !isDisabled || !isDisabled(workspaceAccount, user))
}

export function Sidebar({ onChange, active }) {
    const { hasRight } = useAccountUserContext()

    const menuItems = useMenuItems(hasRight)

    return (
        <Flex
            column
            style={{
                width: 250,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                overflowY: 'auto',
                padding: 20,
                flexShrink: 0,
            }}
        >
            <div style={{ ...V4DesignSystem.label, paddingLeft: 10 }}>Settings</div>
            <div style={{ height: 5 }} />

            {menuItems.map(({ icon, name, id }) => (
                <React.Fragment key={`profile-settings-menu-${id}`}>
                    <SidebarItem active={active === id} onChange={() => onChange(id)} icon={icon}>
                        {name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </React.Fragment>
            ))}
        </Flex>
    )
}
