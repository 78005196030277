// @ts-strict-ignore
import React from 'react'

import type { CustomCell, Rectangle } from '@glideapps/glide-data-grid'

import LongTextAttribute from 'v2/ui/components/Attribute/LongTextAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { CellThemeOverride } from '../components/dataGridThemeUtils'
import EditorWrapper from '../components/EditorWrapper'
import type { CellData, CustomCellRenderer } from '../types'

export const RICHTEXT_CELL = 'richtext-cell'

export type RichTextCellData = CellData & {
    readonly kind: typeof RICHTEXT_CELL
    value: string | null
    rawValue: string | null
}

export type RichTextCell = CustomCell<RichTextCellData>

const RichTextCellRenderer: CustomCellRenderer<RichTextCell> = {
    isMatch: (cell): cell is RichTextCell => (cell.data as any).kind === RICHTEXT_CELL,
    draw: (args, cell) => {
        const { ctx, theme, rect } = args

        const drawArea: Rectangle = {
            x: rect.x + theme.cellHorizontalPadding,
            y: rect.y + theme.cellVerticalPadding,
            width: rect.width - 2 * theme.cellHorizontalPadding,
            height: rect.height - 2 * theme.cellVerticalPadding,
        }

        ctx.fillStyle = theme.textDark
        ctx.fillText(cell.data.value, drawArea.x, drawArea.y + 13)

        return true
    },
    provideEditor: () => {
        const CustomEditor = (props) => {
            const { onChange, value: cell } = props

            const handleChange = (value: string) => {
                const newCell = {
                    ...cell,
                    copyData: value,
                    data: {
                        ...cell.data,
                        value,
                        rawValue: value,
                    },
                }

                onChange(newCell)
            }

            return (
                <EditorWrapper width={400} ignoreEnterKey>
                    {/* @ts-ignore */}
                    <LongTextAttribute
                        // @ts-ignore
                        field={cell.data.field}
                        onChange={handleChange}
                        mode={modes.editing}
                        borderColor={CellThemeOverride.borderColor}
                        enableMarkdown
                        showRichTextEditor
                        autofocus
                    >
                        {cell.data.rawValue}
                    </LongTextAttribute>
                </EditorWrapper>
            )
        }

        return {
            editor: CustomEditor,
            disableStyling: true,
            styleOverride: { marginTop: '-36px' },
            deletedValue: (toDelete: RichTextCell) => {
                return {
                    ...toDelete,
                    copyData: '',
                    data: {
                        ...toDelete.data,
                        rawValue: null,
                        value: null,
                    },
                }
            },
        }
    },
    onPaste: (value, data) => {
        return { ...data, value, rawValue: value }
    },
}

export default RichTextCellRenderer
