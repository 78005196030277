export const getFilter = (fieldType: FieldType) => {
    switch (fieldType) {
        case 'multi_select':
        case 'multi_lookup':
        case 'lookup':
        case 'dropdown':
            return 'dropdown'
        case 'checkbox':
            return 'boolean'
        default:
            return 'text'
    }
}
