import { useEffect } from 'react'

import userflow from 'userflow.js'

import settings from 'app/settings'
import { useStacks } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'
import { withAccounts } from 'data/wrappers/WithAccounts'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useAppContext } from '../app/AppContext'
import { useAppUserContext } from '../app/AppUserContext'

const UserflowIdentifier = ({ accounts }) => {
    const { studioUser } = useAppUserContext()
    const { data: stacks } = useStacks()
    const { workspaceAccount } = useAppContext()
    const { flags = {} } = useLDFlags()

    // We have an LD flag which holds an array of LD flags we want to forward to Userflow
    const forwardedLDFlags = []

    // Get a list of all the LD flags which start the prefix 'userFlow'
    // or which are in our list of forwardedLdFlags
    // because we want to include these flag values as user attributes
    // to UserFlow so we can control UserFlows via a/b split LD flags, etc.
    const userFlowFlags = Object.entries(flags)
        .filter(([key]) => key.startsWith('userFlow') || forwardedLDFlags.includes(key))
        .reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
    const userFlowFlagsMemoized = useDeepEqualsMemoValue(userFlowFlags)
    const userFlowDisabled =
        flags?.disableUserflow || workspaceAccount?.options?.signup_params?.tag === 'cypress'
    useEffect(() => {
        if (studioUser && settings.USERFLOW_KEY) {
            const account = accounts.find((account) => account._sid === studioUser.account_id)
            const currentAccount = workspaceAccount ? workspaceAccount : account
            const userId = settings.IS_PROD ? studioUser._sid : studioUser.email

            if (userFlowDisabled) return

            userflow.init(settings.USERFLOW_KEY)
            userflow.identify(userId, {
                name: studioUser.name,
                email: studioUser.email,
                signed_up_at: studioUser.created_date,
                no_of_apps: stacks ? stacks.length : 0,
                is_stacker_native_tables:
                    workspaceAccount?.optional_features?.stacker_native_tables ?? false,
                is_stacker_data_onboarding:
                    workspaceAccount?.optional_features?.stacker_data_onboarding ?? false,
                preinstall_template: workspaceAccount?.options?.preinstall_template,
                role: studioUser.membership_options?.role,
                signup_params_for: studioUser.options?.signup_params?.for,
                signup_params_tag: studioUser.options?.signup_params?.tag,
                signup_params_flow: studioUser.options?.signup_params?.flow,
                ...userFlowFlagsMemoized,
                account_locked: currentAccount?.locked,
                company_size: currentAccount?.options?.company_info?.metrics?.employees,
                company_type: currentAccount?.options?.company_info?.type,
            })
        }
    }, [studioUser, workspaceAccount, stacks, userFlowFlagsMemoized, accounts, userFlowDisabled])

    return null
}
export default withAccounts(UserflowIdentifier)
