import React from 'react'

import { Checkbox as CheckboxBase } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

export const Checkbox = styled(CheckboxBase)`
    border-color: ${colors.input.border};
    color: ${colors.text.body};
    ${variant({
        variants: {
            admin: {
                display: 'flex',
                mb: 2,
                '& > span, & > div': {
                    fontSize: 'admin.sideMenu.text',
                },
                'input[type=checkbox]:checked + div, input[type=checkbox][aria-checked=mixed] + div':
                    {
                        backgroundColor: colors.userInterface.accent[1000],
                    },
            },
        },
    })};

    :active,
    :focus,
    :hover {
        cursor: ${(props) => (props.isReadOnly ? 'default' : 'pointer')};
    }
    :active {
        pointer-events: ${(props) => (props.isReadOnly ? 'none' : 'auto')};
    }
`

export default React.memo(Checkbox)
