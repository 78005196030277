// @ts-strict-ignore
export enum StepType {
    STEP_TABLE_CREATION = 'STEP_TABLE_CREATION',
    STEP_RECORD_CREATION = 'STEP_RECORD_CREATION',
    STEP_COMPLETE = 'STEP_COMPLETE',
    STEP_STATUS_ERROR = 'STEP_STATUS_ERROR',
}

export enum StepStatus {
    STATUS_STARTED = 'STATUS_STARTED',
    STATUS_COMPLETE = 'STATUS_COMPLETE',
    STATUS_ERROR = 'STATUS_ERROR',
}

export const tableImportStartedStep = {
    type: StepType.STEP_TABLE_CREATION,
    status: StepStatus.STATUS_STARTED,
    description: 'Importing the table',
}

export const tableCreatedStep = {
    type: StepType.STEP_TABLE_CREATION,
    status: StepStatus.STATUS_COMPLETE,
    description: 'Table created successfully',
}

export const recordsImportStartedStep = (description) => {
    return {
        type: StepType.STEP_RECORD_CREATION,
        status: StepStatus.STATUS_STARTED,
        description: description,
    }
}

export const recordsCreatedStep = (description) => {
    return {
        type: StepType.STEP_RECORD_CREATION,
        status: StepStatus.STATUS_COMPLETE,
        description: description,
    }
}

export const importCompleteStep = {
    type: StepType.STEP_COMPLETE,
    status: StepStatus.STATUS_COMPLETE,
    description: 'Your table & records have been imported.',
}

export const errorStep = (errorDetails) => {
    return {
        type: StepType.STEP_STATUS_ERROR,
        status: StepStatus.STATUS_ERROR,
        description: 'There was an error during the import, please contact support',
        errorDetails: errorDetails,
    }
}
