/* Code Quality: Not audited */

import React from 'react'

import styled from '@emotion/styled'
import queryString from 'qs'

import settings from 'app/settings'
import { fetchCombination } from 'data/githubFeatures'
import handleErrorResponse from 'data/utils/handleErrorResponse'
import { fetchWithAuth } from 'data/utils/utils'

const apiRoot = settings.API_ROOT

const passwordChangePath = 'change-password/' // Doesn't need the root as it's authenticated
const endUserRegisterApiRoot = `${apiRoot}authenticate/register`
const signupApiRoot = `${apiRoot}register/`
const passwordResetEmailPath = `${apiRoot}admin/reset-password/request/`
const adminPasswordResetPath = `${apiRoot}admin/reset-password/`
const endUserPasswordResetPath = `${apiRoot}authenticate/reset-password/`
const endUserRequestPasswordResetPath = `${apiRoot}authenticate/reset-password/request/`
const loginApiRoot = `${apiRoot}login/`
const loginWithProviderTokenApiRoot = `${apiRoot}login-with-provider-token/`
const endUserPasswordlessPath = `${apiRoot}admin/passwordless/`
const requestEndUserLoginEmailPath = `${apiRoot}authenticate/request-login-email/`

const JsonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export class LoginApi {
    static login(email, password, redirect) {
        let body = {
            email: email,
            password: password,
        }
        if (redirect) {
            body.redirect = redirect
        }
        return fetch(loginApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static login_with_provider_token(details) {
        return fetch(loginWithProviderTokenApiRoot, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }
}

export class SignupApi {
    static signup(details) {
        return fetch(signupApiRoot, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify(details),
        })
            .then((response) => {
                if (response.status >= 400) {
                    handleErrorResponse(response)
                    return Promise.reject(response)
                }

                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    static registerEndUser(email, password, firstName, lastName, stackId) {
        return fetch(endUserRegisterApiRoot, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                stack_id: stackId,
            }),
        }).then((response) => {
            if (response.status >= 400) {
                handleErrorResponse(response.clone())
                return Promise.reject(response)
            }
            return response.json()
        })
    }
}

export class PasswordApi {
    static changePassword(currentPassword, newPassword) {
        return fetchWithAuth(passwordChangePath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ current_password: currentPassword, new_password: newPassword }),
        }).then((response) => {
            return response.json()
        })
    }

    static resetPasswordSendEmail(email) {
        const url = `${passwordResetEmailPath}?email=${encodeURIComponent(email)}`
        return fetch(url) // Don't care about response
    }

    static endUserResetPassword(token, newPassword) {
        return fetch(endUserPasswordResetPath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ new_password: newPassword, token }),
        })
    }

    static requestEndUserLoginEmail(email, stack_id) {
        return fetch(requestEndUserLoginEmailPath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ email, stack_id }),
        })
    }

    static adminResetPassword(token, newPassword) {
        return fetch(adminPasswordResetPath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ new_password: newPassword, token }),
        })
    }

    static requestEndUserPasswordReset(email, stack_id) {
        return fetch(endUserRequestPasswordResetPath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ email, stack_id }),
        })
    }

    static requestPasswordlessEmail(email) {
        const url = endUserPasswordlessPath + '?email=' + encodeURIComponent(email)
        return fetch(url, {
            method: 'GET',
        })
    }

    static requestPasswordlessLogin(email, code) {
        return fetch(endUserPasswordlessPath, {
            method: 'POST',
            headers: JsonHeaders,
            body: JSON.stringify({ email, code }),
        })
    }

    static requestPasswordlessReset(auth) {
        return fetch(endUserPasswordlessPath, {
            method: 'DELETE',
            headers: JsonHeaders,
            body: JSON.stringify({ auth }),
        })
    }
}

export function logo(logoURL) {
    const Logo = styled('img')`
        width: 100%;
        max-width: 300px;
        display: block;
        margin: 0px auto ${(props) => props.theme.xlarge};
    `
    if (!logoURL) return '' // Do not show a broken image while we wait
    return <Logo src={logoURL} alt="Logo" style={{ marginBottom: 48, marginTop: 48 }} />
}

export function setFeatureLogo(self, collection) {
    const storageKey = `feature_config:${collection}`
    const featureConfig = localStorage.getItem(storageKey)
    function saveState(config) {
        if (config.logo) self.setState({ logo: config.logo })
        if (config.title) self.setState({ title: config.title })
        if (config.icon) self.setState({ icon: config.icon })
        if (config.placeholder) self.setState({ placeholder: config.placeholder })
    }
    if (featureConfig) {
        saveState(JSON.parse(featureConfig))
    } else
        fetchCombination().then((features) => {
            const feature = features.hasOwnProperty(collection)
                ? features[collection]
                : features.default
            // For default config, check https://github.com/worktoolsio/stacker-features/blob/master/combination.json
            if (feature.config) {
                saveState(feature.config)

                // We save the config on the storage so we can quickly set the logo on next steps
                localStorage.setItem(storageKey, JSON.stringify(feature.config))
            }
        })
}

export const isStudioDomain = () => window.location.host.slice(0, 7) === 'studio.'

export function getSignupParams() {
    const signup_params = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const allowedNonStandardFor = ['workspace']
    if (!allowedNonStandardFor.includes(signup_params.for)) {
        signup_params.for = 'stacker_data'
    }
    signup_params.flow = '4.1'
    return signup_params
}
