import React from 'react'

import { Button as Buttonv2, Flex, Modal as ModalV2, Text } from 'v2/ui'

import { Alert as AlertIcon } from '../../../v2/ui/svgs'

type ImportCompletedModalProps = {
    isOpen: boolean
    isDisabled: boolean
    onClose: () => void
}

export const ImportCompletedModal: React.FC<ImportCompletedModalProps> = ({
    isOpen,
    isDisabled,
    onClose,
}) => {
    return (
        <ModalV2
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            noPadding
            showCloseButton={false}
            size="300px"
            body={
                <form>
                    <Flex justifyContent="center" mb="15px">
                        <AlertIcon />
                        <Text variant="modalTitle" mt={6} fontWeight="bold">
                            App Import Completed
                        </Text>
                        <Text mt={4}>
                            Your app has been imported successfully! Start customizing it now...
                        </Text>
                        <Buttonv2
                            disabled={isDisabled}
                            variant="Primary"
                            buttonSize="extraLarge"
                            mt={4}
                            onClick={onClose}
                            width="100%"
                        >
                            Continue
                        </Buttonv2>
                    </Flex>
                </form>
            }
        />
    )
}
