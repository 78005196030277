// TODO: stop ignoring strict errors once react-redux is migrated to TS
// @ts-strict-ignore
import { useDispatch } from 'react-redux'

import { UserApi } from '../../api/userApi'
import { STUDIO_USER_UPDATED } from '../../utils/constants'

export function useRefreshUserIntegrationKey() {
    const dispatch = useDispatch()
    return () => {
        console.log('refreshing')
        return UserApi.regenerateIntegrationKey().then((user) => {
            console.log('refreshed', user)
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                dispatch({
                    type: STUDIO_USER_UPDATED,
                    payload: user,
                })
            }
        })
    }
}
