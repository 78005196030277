function isRichTextEmpty(text) {
    if (!text) return true
    // quill adds a line break when the contents have been cleared
    if (text?.ops && text?.ops.length === 1) {
        if (text?.ops[0]?.insert === '\n') return true
    }
    return false
}

export default isRichTextEmpty
