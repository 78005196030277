import React, { createContext, FC, useContext, useMemo, useState } from 'react'

import { useCreateAction, useUpdateAction } from 'data/hooks/actions'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import { useActionsFromObjectId } from './helpers'
import type { ActionsContext as ActionsContextType, ModalType } from './types'

const ActionsContext = createContext<ActionsContextType | undefined>(undefined)

export function useActionsContext() {
    const context = useContext(ActionsContext)
    if (!context) {
        throw new Error(
            "Can't access actions context provider, is your component wrapped in <ActionsContextProvider/> ?"
        )
    }

    return context
}

export function useActionButtons(
    selectedItems: ActionButton[],
    record: RecordDto,
    object: ObjectDto,
    additionalActions: ActionDto[] = []
) {
    const { data: actions } = useActionsFromObjectId(object?._sid)
    const processFilter = useProcessFilter()

    // want to re-evaluate available buttons if the record changes
    // or the user's role changes.
    return useMemo(() => {
        if (!selectedItems || !actions || !record) return []

        return selectedItems
            .filter(({ conditions }) => !conditions || processFilter([record], conditions))
            .map((actionButton) => ({
                action: additionalActions
                    .concat(actions)
                    .find((action) => action._sid === actionButton.id) as ActionDto,
                config: actionButton,
            }))
            .filter(({ action }) => action)
    }, [selectedItems, actions, record, additionalActions, processFilter])
}

type Props = {
    feature: FeatureDto
}

export const ActionsContextProvider: FC<Props> = ({ feature, children }) => {
    const { data: actions, status } = useActionsFromObjectId(feature?.options?.object_id)
    const { mutateAsync: doUpdateAction } = useUpdateAction()
    const { mutateAsync: createAction } = useCreateAction()
    const [modalStack, setModalStack] = useState<
        { name: ModalType; data?: { stepId?: string; fieldId?: string } }[]
    >([])

    const value = useMemo(() => {
        const context: ActionsContextType = {
            objectId: feature?.options.object_id,
            feature,
            actions,
            status,
            updateAction,
            modalStack,
            activeModal: modalStack[modalStack.length - 1]?.name,
            activeModalData: modalStack[modalStack.length - 1]?.data,
            addModalToStack: (name, data) => setModalStack((list) => [...list, { name, data }]),
            removeModalFromStack: () => setModalStack((list) => [...list.slice(0, -1)]),
            openAddStep() {
                context.addModalToStack('AddAStep')
            },
            editStep(stepId) {
                context.addModalToStack('editStep', { stepId })
            },
            editField(fieldId) {
                context.addModalToStack('EditField', { fieldId })
            },
        }
        return context

        function updateAction(actionId?: string, patch: Partial<ActionDto> = {}) {
            if (actionId) {
                return doUpdateAction({
                    id: actionId,
                    patch,
                })
            }

            return createAction(patch)
        }
    }, [feature, actions, status, modalStack, createAction, doUpdateAction])

    return <ActionsContext.Provider value={value}>{children}</ActionsContext.Provider>
}
