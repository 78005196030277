/* Code Quality: Good */

import PropTypes from 'prop-types'

import { withStack } from 'data/wrappers/WithStacks'
import SignupFrame from 'features/auth/admin-registration/ui/SignupFrame'

const OnboardingFrame = SignupFrame

OnboardingFrame.propTypes = {
    children: PropTypes.node.isRequired,
    rightContents: PropTypes.node,
    title: PropTypes.string.isRequired,
    stack: PropTypes.object.isRequired,
    stackOptions: PropTypes.object.isRequired,
}

export default withStack(OnboardingFrame)
