import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { LayoutEditorBlock } from 'v2/blocks/types'

import { useAppContext } from 'app/AppContext'
import { getStackTheme } from 'app/Branding'
import { useObjects } from 'data/hooks/objects'
import { useStackViews } from 'data/hooks/views'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'

import { ConfigureWidgetPlaceholder } from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import QuickLinksBlockEditor from './QuickLinksBlockEditor'
import QuickLinksBlockItem from './QuickLinksBlockItem'
import { getVisibleQuickLinksBlocks } from './utils'

const { space } = stackerTheme()

const gap = space[4]

const Wrapper = styled.div`
    margin-bottom: ${gap};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    grid-column-gap: ${gap};
    grid-row-gap: ${gap};
`

type QuickLinksBlockProps = {}

const QuickLinksBlock: LayoutEditorBlock<QuickLinksBlockProps, QuickLinksBlock> = ({
    attrs,
    context,
}) => {
    const { items } = attrs
    const { isEditing } = context.editor
    const views = useStackViews() as ViewDto[]
    const { data: objects = [] } = useObjects()
    const itemsWithContent = useMemo(
        () => getVisibleQuickLinksBlocks(items, views, objects),
        [items, views, objects]
    )

    const { selectedStack } = useAppContext()
    const theme = useMemo(() => getStackTheme(selectedStack), [selectedStack])

    // Conditional Visibility
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    if (passesFilters && !isEditing) return null

    if (itemsWithContent.length < 1) {
        if (!isEditing) return null

        return (
            <ConfigureWidgetPlaceholder
                message="Quick Links"
                subtitle="Display useful links"
                Icon={SVGIcons.QuickLinks}
            />
        )
    }

    return (
        <Wrapper>
            {itemsWithContent.map((item) => (
                <QuickLinksBlockItem
                    key={item.order}
                    brandColor={theme.brandColor}
                    isEditing={isEditing}
                    context={context}
                    {...item}
                />
            ))}
        </Wrapper>
    )
}

QuickLinksBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        field: 'items',
        Element: QuickLinksBlockEditor,
        simple: true,
        fullHeight: true,
        requireObject: false,
        noLabel: true,
        fullWidth: true,
    }),
]

export default QuickLinksBlock
