import React, { FC, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'
import getInitials from 'v2/views/utils/getInitials'

import { Icon } from 'v2/ui'

const IconSize = 23

const AppIcon = styled('img')<{ isSelected?: boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isSelected ? 1 : 0.8)};
    border-radius: 8px;
    :hover {
        opacity: 1;
    }
`

type Props = {
    stack?: any
    isSelected?: boolean
    theme?: any
    size?: number | string
}

export const StackIcon: FC<Props> = ({ stack, isSelected, theme, size = IconSize }) => {
    const [icon, setIcon] = useState()

    const [iconName, setIconName] = useState(get(stack.options, 'theme.icon'))

    useEffect(() => {
        setIconName(get(stack.options, 'theme.icon'))

        if (!iconName) setIconName(getInitials(stack.name))

        if (iconName?.match(/^fa/)) {
            setIcon(iconName)
        }
    }, [iconName, stack.options, setIcon, stack.name, icon])

    const color = theme.theme === 'light' ? '#9099A0' : 'rgba(255,255,255,0.7)'
    if (iconName.match(/^fa/) && icon) {
        return (
            <Icon
                height="100%"
                color={isSelected ? 'white' : color}
                fontSize={size}
                pb="2px"
                icon={icon}
            />
        )
    } else if (!iconName) {
        return <>{getInitials(stack.name)}</>
    } else {
        return <AppIcon src={iconName} isSelected={isSelected} />
    }
}
