import React from 'react'

import styled from '@emotion/styled'

import { Flex, Progress } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'
import { BaseColor } from 'v2/ui/theme/styles/types'

import NumberAttribute from './NumberAttribute'

type PercentBarProps = {
    barColor: BaseColor
    percentValue: number
    hasNumber?: boolean
    isEditing?: boolean
}

const colors = stackerTheme().colors

const PercentBar = ({
    barColor,
    percentValue,
    hasNumber,
    isEditing,
    ...props
}: PercentBarProps): React.ReactElement => {
    return (
        <Flex direction="row" wrap="noWrap" align="initial" width="100%">
            <StyledProgress barColor={barColor} value={percentValue} />
            {hasNumber && !isEditing && (
                <StyledPercentLabel data-testId="percentBar-label">
                    {percentValue.toFixed(0)}%
                </StyledPercentLabel>
            )}
            {isEditing && (
                <NumberAttribute
                    color={colors.userInterface.accent[1000]}
                    additionalStyles={{ fontSize: '0.75rem', fontWeight: 600 }}
                    {...props}
                />
            )}
        </Flex>
    )
}

const StyledProgress = styled(Progress)`
    border-radius: 0.1875rem;
    width: clamp(20px, 5vw, 300px);
    height: 0.375rem;
    margin: auto 1rem auto 0;
    flex-grow: 1;
`

const StyledPercentLabel = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    color: ${colors.userInterface.neutral[1000]};
`

export default PercentBar
