import styled from '@emotion/styled'

export type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'none'

export type WithMarginProps = React.HTMLAttributes<HTMLDivElement> & {
    margin?: Size
    marginRight?: Size
    marginLeft?: Size
    marginTop?: Size
    marginBottom?: Size
    noMargin?: boolean
}

const WithMargin = styled('div')<WithMarginProps>`
    margin: ${(props) => props.theme[props.margin || '']};
    ${(props) =>
        props.marginRight &&
        `
        margin-right: ${props.theme[props.marginRight]};
    `};
    ${(props) =>
        props.marginLeft &&
        `
        margin-left: ${props.theme[props.marginLeft]};
    `};
    ${(props) =>
        props.marginTop &&
        `
        margin-top: ${props.theme[props.marginTop]};
    `};
    ${(props) =>
        props.marginBottom &&
        `
        margin-bottom: ${props.theme[props.marginBottom]};
    `};
    ${(props) => props.noMargin && 'margin: 0px !important;'}
`

WithMargin.displayName = 'WithMargin'

export default WithMargin
