import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import queryString from 'qs'

import { buildUrl } from 'data/utils/utils'
import { useMemo } from 'utils/debugUtils'

type useUrlParamOptions = {
    uriDecode?: boolean
}
/*
Hook for reading/setting a URL param. Great for when you want state changes to result in
updated history so back/forward works. 
*/
export default function useUrlParam<T extends string>(
    param: string,
    defaultValue: T,
    options?: useUrlParamOptions
): [T, (val: T, replace?: boolean) => void] {
    const history = useHistory()
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })
    let { uriDecode } = options || {}
    let value = query[param]
    if (uriDecode && value) {
        value = decodeURIComponent(value.toString())
    }
    value = value || defaultValue

    const setValue = useCallback(
        (value: T, replace?: boolean) => {
            const query = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
            })
            if (value) {
                query[param] = value
            } else {
                delete query[param]
            }
            const url = buildUrl(window.location.pathname, query)

            if (replace) {
                history.replace(url)
            } else {
                history.push(url)
            }
        },
        [history, param]
    )

    return useMemo(() => [value, setValue], [value, setValue])
}
