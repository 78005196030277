export const COLORS_ORDER = [
    'lightGrey',
    'blue',
    'purple',
    'ruby',
    'moss',
    'slateGrey',
    'raspberry',
    'teal',
    'mauve',
    'poppy',
    'pine',
    'gold',
    'indigo',
    'orange',
    'mulberry',
    'green',
]
