import React from 'react'

import { useAppContext } from 'app/AppContext'
import { saveExport } from 'data/api/bundlesApi'
import { useUpdateStackOptions } from 'data/hooks/stacks'

import ExportModal, { ExportModalProps } from './ExportModal'

type ExportTemplateModalProps = Pick<ExportModalProps, 'isOpen' | 'onClose'>

const ExportTemplateModal: React.FC<ExportTemplateModalProps> = ({ isOpen, onClose }) => {
    const { selectedStack } = useAppContext()
    const updateStackOptions = useUpdateStackOptions()
    const onExport = async (bundleName?: string) => {
        if (!selectedStack || !bundleName) return
        await saveExport([], bundleName)

        await updateStackOptions(selectedStack, { export_bundle_name: bundleName })
        onClose()
    }

    const title = 'Please confirm your template export'
    return (
        <ExportModal
            allowCustomName={true}
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            onClick={onExport}
        />
    )
}

export default ExportTemplateModal
