import { useEffect, useMemo, useState } from 'react'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import moment from 'moment'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export type StackerLDFlagSet = LDFlagSet & {
    addFieldUserFlowEnabled: {
        enabled: boolean
        max_prompt_limit?: number
        time_delay_amount?: number
        time_delay_unit?: moment.unitOfTime.Base
    }
    disableUserflow?: boolean
    enableEventTrackingForAuths?: boolean
    disableCommandBar?: boolean
    realtimeThrottleTimeout?: number
    newInlineFilters?: boolean
    allowWorkspaceDeletion?: boolean
    showAllPagesSizes?: boolean
    fieldConditionalVisibility?: boolean
    recordsRefetchOnMountOff?: boolean
    disablePusherUpdates?: boolean
    serverSidePagination?: boolean
    useSchemaPolling?: boolean
}

export const useLDFlags = (): { flags: StackerLDFlagSet } => {
    const ldFlags = useFlags()
    const ldFlagOverrides: StackerLDFlagSet = useDeepEqualsMemoValue(
        safeParseJson(window.localStorage.getItem('ldFlagOverrides'))
    )

    return useMemo(
        () => ({
            flags: {
                ...ldFlags,
                ...ldFlagOverrides,
            },
        }),
        [ldFlags, ldFlagOverrides]
    )
}

/**
 * This hook comes with a cost: it renders twice, once saying ready is false, and again
 * when the waitForReady promise returns. If you don't need to monitor ready state,
 * use the hook above, as this one will cause double the renders, which could have significant
 * impact if used in a component that is replicated many times across the page
 * */
export const useLDFlagsWithReadyStatus = () => {
    const { flags } = useLDFlags()
    const ldClient = useLDClient()
    const [isClientReady, setIsClientReady] = useState(false)

    useEffect(() => {
        ldClient?.waitUntilReady().then(() => {
            setIsClientReady(true)
        })
    }, [ldClient])
    return useMemo(() => ({ flags, isClientReady }), [isClientReady, flags])
}

const safeParseJson = (jsonStr: string | null) => {
    try {
        return jsonStr ? JSON.parse(jsonStr) : {}
    } catch (e) {
        return {}
    }
}

export default useLDFlags
