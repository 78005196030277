import React, { useMemo } from 'react'

import { Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'
import ListViewMenuToggleTrigger from 'v2/views/List/ListViewMenuToggleTrigger'

import MenuToggle from 'features/views/List/MenuToggle'

import { Dropdown, Text } from 'v2/ui'
import { SolidBook } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { space } = stackerTheme()

const Wrapper = styled.div`
    min-width: 400px;
    max-width: 100%;
`

const InputGroup = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & + & {
        margin-top: ${space[2]};
    }
`

type DataListBlockEditorPerPageProps = {
    onChange: (config: Partial<DataListBlock['config']['attributes']>) => void
    attrs?: DataListBlock['config']['attributes']
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
}

const DataListBlockEditorPerPage: React.FC<DataListBlockEditorPerPageProps> = ({
    onChange,
    attrs,
    getPageSizeOptions,
    getDefaultPageSize,
}) => {
    const pageSize = useMemo(() => {
        if (attrs?.pageSize) return attrs.pageSize

        return getDefaultPageSize?.(attrs?.display)
    }, [attrs?.pageSize, attrs?.display, getDefaultPageSize])

    const pageSizeOptions = useMemo(
        () =>
            getPageSizeOptions?.(attrs?.display).map((option: number) => ({
                label: option,
                value: option,
            })) ?? [],
        [attrs?.display, getPageSizeOptions]
    )

    return (
        <HoverableMenuToggle
            customToggleComponent={(props) => (
                <ListViewMenuToggleTrigger
                    svg={<SolidBook />}
                    label="Records per page"
                    {...props}
                />
            )}
        >
            <Wrapper>
                <InputGroup role="group">
                    <Text as="label" htmlFor="per-page" size="sm">
                        Records per page
                    </Text>
                    <div>
                        <Dropdown
                            inputId="per-page"
                            adminTheme
                            value={pageSize}
                            options={pageSizeOptions}
                            onChange={(value: number) => onChange({ pageSize: value })}
                            isSearchable={false}
                        />
                    </div>
                </InputGroup>

                <InputGroup role="group">
                    <Text as="label" htmlFor="page-size" size="sm">
                        Allow users to change page size
                    </Text>
                    <Switch
                        as="div"
                        id="page-size"
                        isChecked={attrs?.allowChangePageSize}
                        onChange={() =>
                            onChange({ allowChangePageSize: !attrs?.allowChangePageSize })
                        }
                    />
                </InputGroup>
            </Wrapper>
        </HoverableMenuToggle>
    )
}

export default DataListBlockEditorPerPage

const HoverableMenuToggle = styled(MenuToggle)`
    margin-left: -8px;
    margin-right: -8px;
`
