import React from 'react'

import { useCreateWorkspaceModal } from 'features/AppBar/CreateWorkspaceModal'
import Frame from 'features/core/Frame'

import { Button, Flex, Text } from 'v2/ui'
import { AppBuild } from 'v2/ui/svgs'

import { WorkspacePageStyledContent } from '../WorkspaceHome'

const AccountOnboarding: React.FC = (): React.ReactElement => {
    const { show: showCreateWorkspaceModal } = useCreateWorkspaceModal()

    return (
        <Frame>
            <WorkspacePageStyledContent>
                <Flex height="100%" align="center" width="100%">
                    <Flex column width="100%">
                        <AppBuild style={{ maxWidth: '100%' }} />
                        <Text fontSize="lg" fontWeight="bold" mt={8} textAlign="center">
                            Welcome to Stacker!
                        </Text>
                        <Text fontSize="mdlg" color="gray.500" mt={3} mb={4} textAlign="center">
                            Let&apos;s create your first workspace
                        </Text>
                        <Button
                            variant="Primary"
                            buttonSize="extraLarge"
                            width="210px"
                            onClick={showCreateWorkspaceModal}
                        >
                            Create Workspace
                        </Button>
                    </Flex>
                </Flex>
            </WorkspacePageStyledContent>
        </Frame>
    )
}
export default AccountOnboarding
