import * as React from 'react'

import styled from '@emotion/styled'

const Flex = styled.div`
    display: flex;
    flex-direction: ${(p) => (p.column ? 'column' : 'row')};
`

export default ({ as, column, children, ...props }) => (
    <Flex as={as} column={column} {...props}>
        {children}
    </Flex>
)
