// This is TERRIBLE
// @TODO: this needs to be refactored
// We use global state variables & functions to open and close these modals, which are
// mutually dependent on each other, so they create circular dependencies
// if they are together with the component they control.
// it needs to be re-factored to use react context

import analytics from '../../utils/analytics'

// these are exported as an object even though only the subscribers are in them
// because the modal components themselves manipulate these lists and they try to
// overwrite them, which does not work in case of imported lists
// they are exported/imported because of the comment at the top
export const planModalState = {
    subscribers: [],
}
export const workspaceModalState = {
    subscribers: [],
}

/**
 * call this to open the modal from anywhere. No context or hooks needed
 * @param {{page: WorkspaceSettingsPage}} defaultState
 */
export function openWorkspaceSettingsModal(defaultState) {
    workspaceModalState.subscribers.map((s) => {
        s.setIsOpen(true)
        s.setDefaultState(defaultState)
        // the plan picker modal should never be open at the same time as
        // the billflow widgets will conflict
        closePlanPickerModal()
    })
}

export function closeWorkspaceSettingsModal(defaultState) {
    workspaceModalState.subscribers.map((s) => {
        s.setIsOpen(false)
        s.setDefaultState(defaultState)
    })
}

/**
 * call this to open the modal from anywhere. No context or hooks needed
 * @param {{fromWorkspaceSettingsModal: () => void }} param0
 */
export function openPlanPickerModal(options = {}) {
    const { fromWorkspaceSettingsModal = false, disableFocusLock = false, requiredTier } = options
    planModalState.subscribers.map((s) => {
        s.setIsOpen(true)
        // if coming from the workspace settings modal, then re-open it when we exit
        s.setOpenWorkspaceSettingsAfterClose(fromWorkspaceSettingsModal)
        s.setDisableFocusLock(disableFocusLock)
        s.setRequiredTier(requiredTier)
    })
    analytics.track('plans viewed', {})
    closeWorkspaceSettingsModal()
}
export function closePlanPickerModal(options = {}) {
    const { fromWorkspaceSettingsModal = false } = options
    planModalState.subscribers.map((s) => {
        s.setIsOpen(false)
        s.setOpenWorkspaceSettingsAfterClose(false)
        s.setRequiredTier(undefined)
    })

    if (fromWorkspaceSettingsModal) {
        openWorkspaceSettingsModal({ page: 'general' })
    }
}
