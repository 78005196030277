import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import isEmpty from 'lodash/isEmpty'

import { useUpdateUser } from 'data/hooks/users/main'

import { Avatar, Collapse, Flex } from 'v2/ui'
import ReactFilestack from 'v2/ui/ReactFilestack'

import { Button, Input, Text } from './AuthUIElements'

const EditProfile = ({ onSuccess, user }) => {
    const getDefaultName = () => {
        return !user?.email?.startsWith(user?.name + '@') ? user?.name : null
    }
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        name: getDefaultName(),
        photo: user?.options?.photo,
        errors: {},
    })
    const [error, setError] = useState(false)
    const { mutateAsync: updateUser } = useUpdateUser()

    useEffect(
        () =>
            setState((state) => ({
                ...state,
                name: getDefaultName(),
                photo: user?.options?.photo,
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    )

    const handleSave = (e) => {
        e.preventDefault()
        const errors = {}
        if (!state.name) {
            errors.name = 'Please enter your full name'
        }

        setState((state) => ({ ...state, errors }))

        if (!isEmpty(errors)) {
            return
        }

        setIsLoading(true)
        setError(false)
        updateUser({
            id: user._sid,
            patch: {
                name: state.name,
                set_profile_edited: true,
                options: { ...user.options, photo: state.photo },
            },
        })
            .then((response) => {
                onSuccess(response)
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e) => {
        const field = e.target.id
        const value = e.target.value

        setState((state) => ({ ...state, [field]: value }))
    }

    const handlePhotoChange = (photo) => {
        setState((state) => ({ ...state, photo: photo }))
    }
    return (
        <form onSubmit={handleSave} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="fieldLabel" alignSelf="start">
                Name
            </Text>
            <InputElement
                variant="authentication"
                id="name"
                placeholder="enter your full name"
                state={state}
                onChange={handleChange}
                aria-label="Name"
            />
            <Text variant="fieldLabel" alignSelf="start" mt={2}>
                Avatar
            </Text>
            <UploadAvatar image={state.photo} onChange={handlePhotoChange} mb={4} />
            <Collapse isOpen={error}>
                <Text variant="error" mb={1}>
                    Sorry, an error occurred saving your profile.
                </Text>
            </Collapse>
            <Button width="100%" buttonSize="sm" mt={1} mb={4} isLoading={isLoading} type="submit">
                Finish
            </Button>
        </form>
    )
}

const UploadAvatar = ({ image, onChange, ...props }) => {
    return (
        <Flex {...props}>
            <Avatar src={image} size="lg" mr={4} />
            <ReactFilestack
                options={{
                    accept: 'image/*',
                }}
                onSuccess={(value) => {
                    if (onChange) {
                        onChange(value.filesUploaded[0].url)
                    }
                }}
                customRender={({ onPick }) => (
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        padding="small"
                        icon={image ? 'edit' : 'add'}
                        onClick={onPick}
                    >
                        {image ? 'Change' : 'Upload'}
                    </Button>
                )}
            />

            {image && (
                <Button
                    ml={2}
                    variant="adminSecondary"
                    buttonSize="sm"
                    padding="small"
                    icon="trash"
                    onClick={() => onChange(null)}
                >
                    Remove
                </Button>
            )}
        </Flex>
    )
}

const InputElement = ({ id, state, onChange, ...props }) => (
    <>
        <Input
            variant="authentication"
            id={id}
            width="100%"
            mb={2}
            value={state[id]}
            onChange={onChange}
            {...props}
        />
        <Collapse isOpen={!!(state.errors && state.errors[id])}>
            <Text variant="error" mt="-0.5rem" mb={3}>
                {state.errors[id]}
            </Text>
        </Collapse>
    </>
)
export default withRouter(EditProfile)
