import { getCachedFields } from 'data/hooks/fields'

// Ensure that the field label is unique
export const getUniqueFieldLabel = (
    fieldLabel: string,
    objectId: string,
    fieldId?: string
): string => {
    const objectFields = getCachedFields()?.filter((x) => x.object_id === objectId)
    let uniqueFieldLabel = fieldLabel
    let i = 2
    while (
        objectFields?.some(
            (field) => field.label === uniqueFieldLabel && (!fieldId || field._sid !== fieldId)
        )
    ) {
        uniqueFieldLabel = `${fieldLabel} ${i}`
        i++
    }

    return uniqueFieldLabel
}
