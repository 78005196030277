import { useCallback, useState } from 'react'

import isEqual from 'lodash/isEqual'

// Useful when code may be generating complex state which may be identical
// to the state already held. This hook will NOT trigger a re-render/state change
// if the new value being produced is the the same the existing state.
export function useDeepEqualsState(value) {
    const [state, setState] = useState(value)

    const setStateInternal = useCallback(
        (value) => {
            setState((state) => {
                let newValue = value
                if (typeof value === 'function') {
                    newValue = value(state)
                }
                // Only return the new value if it is actually different
                // from the existing value
                return !isEqual(newValue, state) ? newValue : state
            })
        },
        [setState]
    )
    return [state, setStateInternal]
}

export default useDeepEqualsState
