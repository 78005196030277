import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import get from 'lodash/get'

import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { insertCustomScripts, removeCustomScripts } from 'utils/customScripts'

import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

export const CustomScripts = ({
    stackOptions,
    user,
    allUserDataFetched,
    isStudioUser,
    isImpersonating,
    stack,
}) => {
    //return children
    const customScripts = get(stackOptions, 'custom_scripts')
    const CUSTOM_SCRIPT_ATTR = 'data-custom-user-script'
    const customPageScripts = get(stackOptions, 'custom_page_scripts')
    const CUSTOM_PAGE_SCRIPT_ATTR = 'data-custom-page-user-script'
    const LOGGED_OUT_PATHS = ['/login', '/register', '/forgot-password', '/set-password']

    const location = useLocation()

    const extra_info = { user, location }

    // An admin user doesn't have an _email field as it doesn't call
    // the enduser endpoint
    if (!user?._email && user?.email) {
        extra_info.user['_email'] = user.email
    }

    const currentUserSid = user?._sid
    const userLoaded = allUserDataFetched || (isStudioUser && !isImpersonating)
    //we are only interested in the first part of the path
    const topPath = `/${location?.pathname?.split('/')?.[1]}`
    const readyToLoad = userLoaded || LOGGED_OUT_PATHS.includes(topPath)

    const isLocked = isFeatureLocked(FEATURES.customScripts, stack)

    useEffect(() => {
        if (!isLocked && readyToLoad && !localStorage.getItem('support_login')) {
            removeCustomScripts(CUSTOM_SCRIPT_ATTR)
            insertCustomScripts(customScripts, CUSTOM_SCRIPT_ATTR, extra_info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customScripts, userLoaded, currentUserSid, isLocked])

    // remove and add the page scripts again on location change
    // this is so that scripts such as GA can track all
    // page views
    useEffect(() => {
        if (!isLocked && !localStorage.getItem('support_login') && readyToLoad) {
            removeCustomScripts(CUSTOM_PAGE_SCRIPT_ATTR)
            insertCustomScripts(customPageScripts, CUSTOM_PAGE_SCRIPT_ATTR, extra_info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, userLoaded, customPageScripts, currentUserSid, isLocked])

    // Remove any scripts on unmount
    useEffect(() => {
        return () => {
            removeCustomScripts(CUSTOM_PAGE_SCRIPT_ATTR)
            removeCustomScripts(CUSTOM_SCRIPT_ATTR)
        }
    }, [])

    return null
}
export default withUser(withStack(memo(CustomScripts)))
