// @ts-strict-ignore
import { useEffect, useRef } from 'react'

export default function useWindowTitle(title: any, isFromRelatedList: boolean) {
    const defaultTitleRef = useRef(document.title)
    const timeoutRef: { current } = useRef<number>()
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if (title && !isFromRelatedList) document.title = title
            else document.title = defaultTitleRef.current
        }, 0)

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [title, isFromRelatedList])
}
