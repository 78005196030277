import { buildQueryKey, queryClient } from '../_helpers'

/**
 * Invalidates the app user list
 */
export const invalidateAppUsersList = (): void => {
    const QUERY_NAME: REACT_QUERY_DEPS_NAME = 'useAppUsers'
    const ADMIN_QUERY_NAME: REACT_QUERY_DEPS_NAME = 'useAppUsersForAdmin'
    queryClient.invalidateQueries(
        buildQueryKey(QUERY_NAME, {
            includeAuthKeys: true,
            includeStackId: true,
        })
    )
    queryClient.invalidateQueries(
        buildQueryKey(ADMIN_QUERY_NAME, {
            includeStackId: true,
        })
    )
}
