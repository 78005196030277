import React, { FC } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidatePages } from 'data/hooks/pages'
import { createNewStackData, useCreateStack } from 'data/hooks/stacks'
import { invalidateViews } from 'data/hooks/views'
import { assertIsDefined } from 'data/utils/ts_utils'
import ColorPickerForm, { defaultColor } from 'features/workspace/forms/ColorPicker'
import IconPickerForm from 'features/workspace/forms/IconPicker'

import { Flex, Heading, Modal } from 'v2/ui'

// @ts-ignore
import Form from 'ui/forms/Form'
// @ts-ignore
import InputForm from 'ui/forms/InputForm'
// @ts-ignore
import SubmitButton from 'ui/forms/SubmitButton'

type FormData = {
    name: string
    icon: string
    brandColor: string
}

type Props = {
    initialAppIcon?: string | null
    initialAppName?: string | null
    workspaceAccount: Account
    onAppCreated: (stack: StackDto) => void
    onCreateOverride?: ((appData: ReturnType<typeof createNewStackData>) => void) | null
}

const CreateAppModal: FC<Props> = ({
    initialAppIcon,
    initialAppName,
    workspaceAccount,
    onAppCreated,
    onCreateOverride,
}) => {
    const { user } = useAppUserContext()
    const { mutateAsync: createStack } = useCreateStack()

    const onCreate = async ({ name, icon, brandColor }: FormData): Promise<void> => {
        assertIsDefined(user)
        const data = createNewStackData(
            workspaceAccount._sid,
            // Remove the ts-ignore once data/hooks/users.js has been converted to TS
            // @ts-ignore
            user._sid,
            name,
            icon,
            undefined,
            workspaceAccount.options?.theme?.navColor,
            brandColor || workspaceAccount.options?.theme?.brandColor || defaultColor
        )

        if (onCreateOverride) {
            onCreateOverride(data)
            return
        }

        const stack = await createStack(data)

        invalidateViews()
        invalidatePages()
        invalidateNavigation()
        invalidateFeatures()

        onAppCreated(stack)
    }

    return (
        <Modal size="510px" showCloseButton={false} isOpen>
            <Flex
                style={{ width: '100%', marginBottom: 4, paddingBottom: 0 }}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Heading variant="modal" value={`What do you want to call your first app?`} />
            </Flex>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                options={{
                    mode: 'onSubmit',
                    defaultValues: {
                        name: initialAppName,
                        icon: initialAppIcon || 'faBook',
                        brandColor: workspaceAccount.options.theme?.brandColor || defaultColor,
                    },
                }}
                onSubmit={onCreate}
            >
                <Flex direction="column" alignItems="flex-start" flex={1}>
                    <InputForm
                        style={{ width: '100%' }}
                        name="name"
                        label="App name"
                        placeholder="e.g. Projects"
                        errorMessages={{ required: 'Please enter a name for your app' }}
                        variant="admin_white"
                        required
                    />
                    <Flex>
                        <IconPickerForm name="icon" label="App Icon" />
                        <div style={{ width: 15 }} />
                        <ColorPickerForm name="brandColor" label="Color" bodyPadding="4px 12px" />
                    </Flex>
                </Flex>
                <SubmitButton
                    style={{ margin: '32px auto 0', width: '250px' }}
                    variant="Primary"
                    buttonSize="medium"
                    aria-label="Submit and create the app"
                    disabled={false}
                >
                    Create
                </SubmitButton>
            </Form>
        </Modal>
    )
}

export default CreateAppModal
