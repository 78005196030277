import React from 'react'

import { RadioGroup, Stack, useRadioGroup } from '@chakra-ui/react'

import { RadioButton } from 'v2/ui'

export const LinkFormDirectionRadioGroup = ({
    sourceObject,
    targetObject,
    sourceField,
    targetField,
    values,
    value,
    onChange,
    renderLabel,
}) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        onChange,
        value,
    })

    const groupProps = getRootProps()

    const sourceProps = getRadioProps({ value: values.source })
    const targetProps = getRadioProps({ value: values.target })

    return (
        <RadioGroup value={value} onChange={(event) => onChange(event.target.value)}>
            <Stack {...groupProps}>
                <RadioButton size="md" colorScheme="grey" {...sourceProps}>
                    <div style={{ fontSize: 14 }}>
                        {renderLabel({
                            isSelected: value === values.source,
                            object: sourceObject,
                            field: sourceField,
                            index: 0,
                        })}
                    </div>
                </RadioButton>
                <RadioButton size="md" colorScheme="grey" {...targetProps}>
                    <div style={{ fontSize: 14 }}>
                        {renderLabel({
                            isSelected: value === values.target,
                            object: targetObject,
                            field: targetField,
                            index: 1,
                        })}
                    </div>
                </RadioButton>
            </Stack>
        </RadioGroup>
    )
}
