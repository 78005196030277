import React from 'react'
import { useForm } from 'react-hook-form'

import { UserProfileFormFields } from 'features/userProfiles'

import { LinkModalForm } from '../../links'

export const UserProfileNewModalView = ({ isOpen, onClose, onSubmit, source, sourceField }) => {
    const formContext = useForm({
        mode: 'all',
        defaultValues: {
            source,
            sourceField,
            target: ``,
            targetField: ``,
            linkFieldObject: source,
            sourceFieldLinkName: ``,
            targetFieldLinkName: `Stacker User`,
            // Newly created links always have a symmetric field
            // so they are not considered legacy
            legacy: false,
            mode: 'one-to-many',
        },
    })

    // We do not want to include the user table in the object selection
    // as it's already selected on the LHS of the user profile link
    function isNotUserTable(object) {
        return object._sid !== source
    }

    return (
        <LinkModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            form={formContext}
            isSecondLayer={true}
            minHeight="35%"
        >
            <UserProfileFormFields form={formContext} filterTargetObject={isNotUserTable} />
        </LinkModalForm>
    )
}
