import React from 'react'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'

import useLDFlags from '../hooks/useLDFlags'

type WithLDFlagsProps = {
    flags: LDFlagSet
    isClientReady: boolean
}

const withLDFlags = <T extends WithLDFlagsProps = WithLDFlagsProps>(
    WrappedComponent: React.ComponentType<T>
) => {
    // Try to create a nice displayName for React Dev Tools.
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

    const ComponentWithLDFlags = (props: Omit<T, keyof WithLDFlagsProps>) => {
        const ldFlags = useLDFlags()
        return <WrappedComponent {...ldFlags} {...(props as T)} />
    }

    ComponentWithLDFlags.displayName = `withLDFlags(${displayName})`

    return ComponentWithLDFlags
}

export default withLDFlags
