import without from 'lodash/without'

import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    FETCH_COMPLETED,
    FETCH_STARTED,
    PERMISSION_GROUP_CREATED,
    PERMISSION_GROUP_FETCHED,
    PERMISSION_GROUP_REMOVED,
    PERMISSION_GROUP_UPDATED,
    STACK_SELECTED,
} from '../utils/constants'

// STATE
export const permissionGroupsInitialState = {
    permissionGroups: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function permissionGroupReducer(state = permissionGroupsInitialState, action) {
    let permissionGroups
    let permissionGroup
    let fetching
    switch (action.type) {
        case PERMISSION_GROUP_FETCHED:
            if (!action.payload) return state
            permissionGroups = unique([
                ...state.permissionGroups,
                ...action.payload.map((pg) => pg._sid),
            ])
            return {
                ...state,
                permissionGroups,
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case PERMISSION_GROUP_CREATED:
            if (!action.payload) return state
            permissionGroups = unique([...state.permissionGroups, action.payload._sid])
            return {
                ...state,
                permissionGroups,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case PERMISSION_GROUP_UPDATED:
            if (!action.payload) return state
            permissionGroup = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [permissionGroup._sid]: permissionGroup },
            }
        case PERMISSION_GROUP_REMOVED:
            permissionGroups = state.permissionGroups.map((pg) => without(pg, action.id))
            return {
                ...state,
                permissionGroups,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return permissionGroupsInitialState
        default:
            return state
    }
}
