import React from 'react'

import { ViewRow } from 'v2/views/List/types'

import { BackgroundImage, Box } from 'v2/ui'

type NonFalsy<T> = T extends false ? never : T

type RowsListCoverImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    coverImage: NonFalsy<ViewRow['coverImage']>
    isInboxView?: boolean
}

export const RowsListCoverImage: React.FC<RowsListCoverImageProps> = ({
    coverImage,
    isInboxView,
    ...props
}) => {
    return (
        <Box p={[4, null, null, 4]} {...props}>
            <BackgroundImage
                objectFit={coverImage.fitImage}
                src={coverImage.url}
                size={[
                    isInboxView ? '60px' : 'rows.coverImage',
                    null,
                    null,
                    isInboxView ? '60px' : 'rows.coverImageLg',
                ]}
                borderRadius="full"
            />
        </Box>
    )
}
