import React from 'react'

import styled from '@emotion/styled'

import Card from 'legacy/v1/ui/components/Card'
import publicAsset from 'utils/publicAsset'

export const AirtablePanel = styled(Card)`
    width: 100%;
    max-width: 800px;
    height: 700px;
    text-align: center;
    margin: 10% auto;
    padding: 10%;
    align-items: center;
`

export const AirtableLogo = () => (
    <img
        alt="Airtable"
        style={{ verticalAlign: 'middle' }}
        src="https://cdn.filestackcontent.com/DMjFfWksROGaCPRroG2g"
        height={30}
    />
)

export const LoadingSpinner = () => (
    <img
        alt=""
        src={publicAsset('/static/media/Spinner.svg')}
        style={{
            marginLeft: 0,
            marginRight: 8,
            height: 20,
        }}
    />
)
