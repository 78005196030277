import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ButtonGroup = styled('span')`
    ${(props) =>
        !props.vertical &&
        `
        & > [role="button"]:first-of-type:not(:only-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        & > [role="button"]:last-of-type:not(:only-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        & > [role="button"]:not(:first-of-type):not(:last-of-type) {
            border-radius: 0px;
        }
        & > [role="button"]:not(:first-of-type):not(:only-child) {
            margin-left: -1px;
        }
    `} ${(props) =>
        props.vertical &&
        `

        display: flex;
        flex-direction: column;
        & > [role="button"]:first-of-type:not(:only-child) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        & > [role="button"]:last-of-type:not(:only-child) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
        & > [role="button"]:not(:first-of-type):not(:last-of-type) {
            border-radius: 0px;
        }
        & > [role="button"]:not(:first-of-type):not(:only-child) {
            margin-top: -1px;
        }
    `}


    margin: ${(props) => props.theme[props.margin || 'small']};
    padding: ${(props) => props.theme[props.padding || 'none']};
`

ButtonGroup.propTypes = {
    vertical: PropTypes.bool,
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}

export default ButtonGroup
