import React from 'react'

import { FlexProps } from '@chakra-ui/react'

import Icon from 'v2/ui/components/Icon'
import stackerTheme from 'v2/ui/theme/styles/default'

import Box from './Box'

const colors = stackerTheme().colors

export type Variants =
    | 'Information'
    | 'ImportantInformation'
    | 'Error'
    | 'Warning'
    | 'InformationSecondary'

type BannerProps = {
    icon?: string
    variant?: Variants
} & FlexProps

const colorPalette: { [key in Variants]: any } = {
    Information: {
        background: colors.userInterface.accent[200],
        color: colors.userInterface.neutral[1000],
        icon: colors.userInterface.accent[1000],
    },
    ImportantInformation: {
        background: colors.userInterface.accent[1600],
        color: 'white',
        icon: 'white',
    },
    Error: {
        background: colors.userInterface.error[200],
        color: colors.userInterface.neutral[1000],
        icon: colors.userInterface.error[600],
    },
    Warning: {
        background: colors.userInterface.warning[200],
        color: colors.userInterface.neutral[1000],
        icon: colors.userInterface.warning[1200],
    },
    InformationSecondary: {
        background: colors.userInterface.neutral[200],
        color: colors.userInterface.neutral[1000],
        icon: colors.userInterface.neutral[800],
    },
}

const Banner: React.FC<BannerProps> = (props) => {
    const {
        icon,
        children,
        variant = 'Information',
        justifyContent = 'flex-start',
        ...styleProps
    } = props

    const variantTheme = colorPalette[variant]
    return (
        <Box
            display="flex"
            alignItems="center"
            backgroundColor={variantTheme.background}
            padding="10px 10px 10px 14px"
            borderRadius={5}
            justifyContent={justifyContent}
            {...styleProps}
        >
            {icon && (
                <Icon
                    icon={icon}
                    size="sm"
                    color={variantTheme.icon}
                    // Adds just enough padding that if you make a multiline banner and set align-
                    // items to 'flex-start' the icon doesn't move relative to a single-line banner
                    py="1px"
                />
            )}
            <div
                style={{
                    fontSize: 14,
                    color: variantTheme.color,
                    paddingLeft: 10,
                    fontWeight: 600,
                }}
            >
                {children}
            </div>
        </Box>
    )
}
export default Banner
