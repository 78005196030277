import React, { ReactElement, useEffect, useState } from 'react'

import { isEmpty } from 'lodash'

import { Box, Button, Collapse, Flex, Heading, Modal, Text } from 'v2/ui'
import {
    Clipboard as ClipboardIcon,
    Kanban as KanbanIcon,
    More2 as More2Icon,
    Rocket as RocketIcon,
    User2 as User2Icon,
    Users as UsersIcon,
} from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const PREDEFINED_PURPOSES = {
    customerPortal: {
        title: 'Customer Portal',
        description: 'e.g. Partner portal, Vendor portal, Client portal',
    },
    internalTool: {
        title: 'Internal Workflow',
        description: 'e.g. Project tracker, Inventory tracker, Business Ops tracker',
    },
    communityPortal: {
        title: 'Community Portal',
        description: 'e.g. User Profiles, Member directory, Referral network',
    },
    saas: {
        title: 'Product',
        description: 'e.g. SaaS, Minimum Viable Product',
    },
    resourceDirectory: {
        title: 'Directory',
        description: 'e.g. People directory, Resource center, Product directory',
    },
    somethingElse: {
        title: 'Other',
        description: 'Anything that comes to mind - we are here to help.',
    },
}

type PurposeRadioButtonProps = {
    title: string
    description: string
    isSelected: boolean
    icon: ReactElement
    onClick: () => void
}
const PurposeRadioButton = (props: PurposeRadioButtonProps) => {
    const { title, description, isSelected, icon, onClick } = props
    const borderColor = isSelected ? '#465dd8' : colors.neutral[400]
    const backgroundColor = isSelected ? 'rgba(70, 93, 216, 0.2)' : 'transparent'
    return (
        <Flex
            flexDirection="column"
            w={135}
            h={121}
            border={`1px solid ${borderColor}`}
            onClick={onClick}
            borderRadius={3}
            mb="15px"
            backgroundColor={backgroundColor}
            style={{ position: 'relative', alignItems: 'flex-start', cursor: 'pointer' }}
        >
            <Box style={{ position: 'absolute', top: '10px', left: '10px' }}>{icon}</Box>
            <Text fontSize="14px" fontWeight="600" color="neutral.900" mt="45px" ml="10px">
                {title}
            </Text>
            <Text fontSize="12px" color="neutral.850" ml="10px" mr="10px" mt="10px">
                {description}
            </Text>
        </Flex>
    )
}

type PurposeModalProps = {
    userName?: string
    onSubmit: (purpose: string) => void
}

const PurposeModal = (props: PurposeModalProps) => {
    const { onSubmit } = props

    const [purpose, setPurpose] = useState('')
    const [showError, setShowError] = useState(false)

    // if purpose is used, call onSubmit & collapse disappears,
    // otherise show collapse & error message
    const handleSubmit = () => {
        !isEmpty(purpose) ? onSubmit(purpose) : setShowError(true)
    }

    useEffect(() => {
        if (!isEmpty(purpose)) {
            setShowError(false)
        }
    }, [purpose])
    return (
        <Modal size="500px" isOpen={true} showCloseButton={false}>
            <Flex
                direction={'column'}
                style={{
                    width: '100%',
                }}
            >
                <Heading variant="modal" value={`What are you building today?`} />

                <Flex justifyContent="space-between" mt="5px" width="440px">
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.customerPortal.title}
                        description={PREDEFINED_PURPOSES.customerPortal.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.customerPortal.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.customerPortal.title)}
                        icon={
                            <User2Icon
                                viewBox="0 0 25 25"
                                style={{ width: '25px', height: '25px' }}
                            />
                        }
                    />
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.internalTool.title}
                        description={PREDEFINED_PURPOSES.internalTool.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.internalTool.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.internalTool.title)}
                        icon={
                            <KanbanIcon
                                viewBox="0 0 25 25"
                                style={{ width: '25px', height: '25px' }}
                            />
                        }
                    />
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.communityPortal.title}
                        description={PREDEFINED_PURPOSES.communityPortal.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.communityPortal.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.communityPortal.title)}
                        icon={<UsersIcon size={25} />}
                    />
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.saas.title}
                        description={PREDEFINED_PURPOSES.saas.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.saas.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.saas.title)}
                        icon={
                            <RocketIcon
                                viewBox="0 0 25 25"
                                style={{ width: '25px', height: '25px' }}
                            />
                        }
                    />
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.resourceDirectory.title}
                        description={PREDEFINED_PURPOSES.resourceDirectory.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.resourceDirectory.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.resourceDirectory.title)}
                        icon={
                            <ClipboardIcon
                                viewBox="0 0 25 25"
                                style={{ width: '25px', height: '25px' }}
                            />
                        }
                    />
                    <PurposeRadioButton
                        title={PREDEFINED_PURPOSES.somethingElse.title}
                        description={PREDEFINED_PURPOSES.somethingElse.description}
                        isSelected={purpose === PREDEFINED_PURPOSES.somethingElse.title}
                        onClick={() => setPurpose(PREDEFINED_PURPOSES.somethingElse.title)}
                        icon={
                            <More2Icon
                                viewBox="0 0 25 25"
                                style={{ width: '25px', height: '25px' }}
                            />
                        }
                    />
                </Flex>

                <Button
                    variant="Primary"
                    buttonSize="large"
                    disabled={false}
                    aria-label="Continue"
                    mt="15px"
                    width="250px"
                    isDisabled={false}
                    onClick={handleSubmit}
                >
                    Next
                </Button>
                <Collapse isOpen={showError}>
                    <Text
                        variant="error"
                        style={{
                            marginTop: 12,
                            fontSize: 14,
                        }}
                    >
                        Please select a use case
                    </Text>
                </Collapse>
            </Flex>
        </Modal>
    )
}

export default PurposeModal
