/* Code Quality: BAD */

import React from 'react'
import { Helmet } from 'react-helmet'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import WithStacks from 'data/wrappers/WithStacks'
import Frame from 'features/core/Frame'
import publicAsset from 'utils/publicAsset'

import { ContentWrapper, Page } from './ui/Layout'

class FeatureStudio extends React.Component {
    state = {}

    render() {
        return (
            <Page>
                <WithStacks>
                    {({ selectedStack }) => (
                        <Helmet>
                            <link
                                rel="shortcut icon"
                                href={
                                    selectedStack.options.icon ||
                                    publicAsset('/static/media/favicon.clear.png')
                                }
                                type="image/png"
                            />
                            <meta charSet="utf-8" />
                            <title>{selectedStack.options.title || 'Stacker Studio'}</title>
                        </Helmet>
                    )}
                </WithStacks>
                <Frame>
                    <ContentWrapper
                        style={{
                            position: this.props.relative ? 'relative' : null,
                        }}
                    >
                        {this.props.children}
                    </ContentWrapper>
                </Frame>
            </Page>
        )
    }
}

FeatureStudio.propTypes = {
    children: PropTypes.node,
}

export default FeatureStudio

export const Top = styled('div')`
    // background-color: #e8e8e8;
`

export const CenteredContent = styled('div')`
    max-width: 1000px;
    margin: 0px auto;
`
