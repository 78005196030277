import { UseQueryOptions, UseQueryResult } from 'react-query'

import { useAppContext } from 'app/AppContext'

import { queryClient, useQuery, useQueryKeyBuilder } from '../_helpers'

import { buildAggregatesUrl } from './buildAggregatesUrl'
import { mapRawResultToAggregates } from './mapRawResultToAggregates'
import type { Aggregation, AggregationGroup } from './types'

type UseAggregatesProps = {
    objectId: string
    aggregations: Aggregation[]
    primaryGroupFieldApiName?: string
    secondaryGroupFieldApiName?: string
    filters: ChartFilter[]
    sort?: ChartSort
    bucketBy?: Bucket
    limit?: number
    previousTimePeriod?: boolean
    options: UseQueryOptions<AggregationGroup[]>
}

const LIST_NAME = 'aggregates'

export const useAggregates = ({
    objectId,
    aggregations,
    primaryGroupFieldApiName,
    secondaryGroupFieldApiName,
    filters = [],
    sort,
    bucketBy,
    limit,
    previousTimePeriod,
    options = {},
}: UseAggregatesProps): UseQueryResult<AggregationGroup[]> => {
    if (!aggregations || aggregations.length == 0) {
        options.enabled = false
    }
    const url = buildAggregatesUrl({
        objectId,
        aggregations,
        primaryGroupFieldApiName,
        secondaryGroupFieldApiName,
        filters,
        sort,
        bucketBy,
        limit,
        previousTimePeriod,
    })
    const { selectedStack } = useAppContext()
    const queryKey = useQueryKeyBuilder(
        [
            LIST_NAME,
            selectedStack?._sid,
            objectId,
            aggregations,
            primaryGroupFieldApiName,
            secondaryGroupFieldApiName,
            filters,
            sort,
            bucketBy,
            limit,
        ],
        { includeAuthKeys: true }
    )

    return useQuery<AggregationGroup[]>(queryKey, url, options, {
        bypassQueryDeps: true,
        customMapper: (data) => {
            return mapRawResultToAggregates(data)
        },
    })
}

export const invalidateAggregates = () => {
    return queryClient.invalidateQueries(LIST_NAME)
}

export const useInvalidateObjectAggregates = () => {
    const { selectedStack } = useAppContext()
    return (objectId: string) =>
        queryClient.invalidateQueries([LIST_NAME, selectedStack?._sid, objectId])
}
