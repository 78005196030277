import React from 'react'

import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Text } from 'v2/ui'
import { SolidChevronDown } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const HeaderIcon = styled(SolidChevronDown)<{ isExpanded: boolean | undefined }>`
    transition: transform 0.25s ease-in-out;
    transform: rotateZ(${({ isExpanded }) => (isExpanded ? -180 : 0)}deg);
`

const StyledAccordionButton = styled(AccordionButton)`
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0 4px 0 0;
    margin: 0;
    border-width: 0px;
    background-color: ${colors.userInterface.neutral[0]};

    &:hover {
        background-color: ${colors.userInterface.neutral[0]};
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }
`

const StyledAccordionPanel = styled(AccordionPanel)`
    padding: 0;
`

type AdvancedOptionsProps = Omit<React.ComponentPropsWithoutRef<typeof AccordionItem>, 'title'> & {
    title?: React.ReactNode
    panelStyle?: React.CSSProperties
}

const AdvancedOptions: React.FC<AdvancedOptionsProps> = ({
    title = 'Advanced options',
    children,
    panelStyle,
    ...props
}) => {
    return (
        <StyledAccordion allowMultiple>
            <AccordionItem {...props}>
                {({ isExpanded }) => (
                    <>
                        <StyledAccordionButton
                            role="button"
                            aria-label={`${isExpanded ? 'Hide' : 'Show'} advanced options`}
                        >
                            {typeof title === 'string' ? (
                                <Text variant="adminFieldLabel">{title}</Text>
                            ) : (
                                title
                            )}

                            <HeaderIcon aria-hidden="true" isExpanded={isExpanded} />
                        </StyledAccordionButton>
                        <StyledAccordionPanel style={panelStyle}>{children}</StyledAccordionPanel>
                    </>
                )}
            </AccordionItem>
        </StyledAccordion>
    )
}

export default AdvancedOptions

const StyledAccordion = styled(Accordion)`
    border-color: transparent;
`
