import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/* 
    Provides stable state that persists even when the entire component tree gets regenerated.
    State is stored in redux, so survives the duration of the memory session.
*/
const useStableState = (key) => {
    const state = useSelector((state) => state?.stable_state?.data?.[key])
    const dispatch = useDispatch()

    const setState = useCallback(
        (value) => dispatch({ type: SET_STABLE_STATE, key, value }),
        [key, dispatch]
    )
    return [state, setState]
}

export const SET_STABLE_STATE = 'SET_STABLE_STATE'
const initialState = {
    data: {},
}
export function StableStateReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STABLE_STATE:
            return {
                ...state,
                data: { ...state.data, [action.key]: action.value },
            }
        default:
            return state
    }
}

export default useStableState
