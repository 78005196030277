import React from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import EditableFieldWrapper from 'features/admin/edit-mode/ListView/EditableListFieldWrapper'
import { ListRecordWrapper } from 'features/admin/edit-mode/ListView/ListRecordWrapper'

import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import useHover from '../utils/useHover'

import BackgroundImage from './BackgroundImage'
import Box from './Box'
import ConditionalWrapper from './ConditionalWrapper'
import Container from './Container'
import ContainerLabel from './ContainerLabel'
import ContainerPadding from './ContainerPadding'
import Divider from './Divider'
import FixedGrid from './FixedGrid'
import Link from './Link'
import Text from './Text'

const ActionButtonsDiv = styled('div')`
    display: flex;

    margin: 15px 0 15px 10px;
`

const Cards = ({
    header = [],
    data,
    pagination,
    title,
    titleIsEditable,
    buttons,
    topFilters,
    rowLink,
    search,
    emptyContent,
    onTitleChange,
    additionalListContainerContent,
    object,
    viewOptions,
    setConfig,
    borderStyle,
    gridSpacing = '12px',
    ...props
}) => {
    const [isContainerHovered, hoverHandlers] = useHover()
    if (!data.length) {
        return (
            <Container
                title={title}
                buttons={buttons}
                topFilters={topFilters}
                search={search}
                onChange={onTitleChange}
                className={ONBOARDING_CLASSES.TABLE_CONTAINER}
                {...props}
            >
                {emptyContent}
            </Container>
        )
    }

    return (
        <>
            {title && (
                <ContainerLabel
                    isEditable={titleIsEditable}
                    buttons={buttons}
                    topFilters={topFilters}
                    search={search}
                    value={title}
                    onChange={onTitleChange}
                />
            )}
            <Box
                maxWidth="100%"
                position="relative"
                className={ONBOARDING_CLASSES.TABLE_CONTAINER}
                {...props}
            >
                {additionalListContainerContent}
                <FixedGrid
                    className={STYLE_CLASSES.CARDS}
                    columns={[1, 1, 2, 3]}
                    spacing={gridSpacing}
                    maxWidth="100%"
                    {...hoverHandlers}
                >
                    {data.map(
                        ({ label, cells, coverImage, props, row, actionButtons }, rowIndex) => {
                            const link = rowLink ? rowLink(row) : ''

                            return (
                                <ConditionalWrapper
                                    key={row.original._sid}
                                    condition={link}
                                    wrapper={(children) => (
                                        <Link
                                            className={`${
                                                rowIndex === 0 ? ONBOARDING_CLASSES.TABLE_ITEM : ''
                                            } ${STYLE_CLASSES.CARD_LINK}`}
                                            variant="card"
                                            href={link}
                                        >
                                            {children}
                                        </Link>
                                    )}
                                >
                                    <ListRecordWrapper
                                        recordId={row.original._sid}
                                        key={row.original._sid}
                                        h="100%"
                                        highlight={isContainerHovered}
                                    >
                                        {({ isActive, lastHoveredRecordId }) => {
                                            const editControlsActive =
                                                isActive || (rowIndex === 0 && !lastHoveredRecordId)

                                            return (
                                                <StyledContainer
                                                    borderStyle={borderStyle}
                                                    className={STYLE_CLASSES.CARD}
                                                    title={label}
                                                    my={0}
                                                    h="100%"
                                                    {...props}
                                                >
                                                    {coverImage && (
                                                        <BackgroundImage
                                                            objectFit={get(coverImage, 'fitImage')}
                                                            src={get(coverImage, 'url')}
                                                            borderTopLeftRadius="lg"
                                                            borderTopRightRadius="lg"
                                                            w="100%"
                                                            h={[
                                                                'cards.coverImage',
                                                                null,
                                                                null,
                                                                'cards.coverImageLg',
                                                            ]}
                                                        />
                                                    )}
                                                    <ContainerPadding>
                                                        {cells.map(
                                                            ({ label, value, column }, index) => (
                                                                <FieldCell
                                                                    key={
                                                                        column.renderOptions.fieldId
                                                                    }
                                                                    label={label}
                                                                    value={value}
                                                                    column={column}
                                                                    index={index}
                                                                    header={header}
                                                                    object={object}
                                                                    viewOptions={viewOptions}
                                                                    setConfig={setConfig}
                                                                    disableEditControls={
                                                                        !editControlsActive
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </ContainerPadding>

                                                    {actionButtons && (
                                                        <>
                                                            <Divider ml={4} mr={4} />

                                                            <ActionButtonsDiv>
                                                                {actionButtons}
                                                            </ActionButtonsDiv>
                                                        </>
                                                    )}
                                                </StyledContainer>
                                            )
                                        }}
                                    </ListRecordWrapper>
                                </ConditionalWrapper>
                            )
                        }
                    )}
                </FixedGrid>
            </Box>

            {pagination}
        </>
    )
}

const StyledContainer = styled(Container)`
    :hover {
        background-color: #fcfcfc;
    }
`

const FieldCell = ({
    label,
    value,
    column,
    index,
    header,
    object,
    viewOptions,
    setConfig,
    disableEditControls,
}) => {
    const [isHovered, hoverHandlers] = useHover({ delay: 50 })
    return (
        <div
            className={`${STYLE_CLASSES.CARD_CELL} ${STYLE_CLASSES.CARD_CELL}-${get(
                header,
                index + '.id'
            )}`}
            {...hoverHandlers}
        >
            {index === 0 && (
                <EditableFieldWrapper
                    position="horizontal"
                    fieldId={column.renderOptions.fieldId}
                    objectId={object._sid}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    hideLabelOptions
                    showControls={isHovered ? 'always' : disableEditControls ? 'never' : 'default'}
                >
                    <Text as="h2" variant="recordLabel">
                        {value}
                    </Text>
                </EditableFieldWrapper>
            )}
            {index > 0 && (
                <>
                    <Text variant="cardLabel" as="h3">
                        <EditableFieldWrapper
                            position="horizontal"
                            fieldId={column.renderOptions.fieldId}
                            objectId={object._sid}
                            viewOptions={viewOptions}
                            setConfig={setConfig}
                            showControls={
                                isHovered ? 'always' : disableEditControls ? 'never' : 'default'
                            }
                        >
                            {!column.renderOptions.hideLabel &&
                                (!column.renderOptions.treatAsUrl ||
                                    !column.renderOptions.displayAsButton) &&
                                label}
                        </EditableFieldWrapper>
                    </Text>
                    <Text variant="cardFieldText">{value}</Text>
                </>
            )}
        </div>
    )
}

export default React.memo(Cards)
