import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidZapProps = {
    color?: string
    size?: number
}
const SolidZap: React.FC<SolidZapProps> = ({
    color = colors.userInterface.neutral[0],
    size = 20,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 17) / 16}
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M13.913 5.9a.75.75 0 0 0-.663-.4H8.357l1.59-3.972A.75.75 0 0 0 9.25.5h-5.5a.75.75 0 0 0-.743.651l-1 7.5a.746.746 0 0 0 .743.849h4.572l-1.305 6.093a.75.75 0 0 0 1.352.58l6.5-9.5a.75.75 0 0 0 .044-.773z"
                fill={color}
            />
        </svg>
    )
}

export default SolidZap
