import React, { useCallback, useEffect, useMemo, useState } from 'react'

import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { useAccounts, useUpdateAccount } from 'data/hooks/accounts'
import { useDisableObjects, useObjects } from 'data/hooks/objects'
import { createNewStackData, useCreateStack, useStacks, useUpdateStack } from 'data/hooks/stacks'
import { useCreateUserList } from 'data/hooks/userLists'
import { assertIsDefined, assertNever } from 'data/utils/ts_utils'
import analytics from 'utils/analytics'

import useUrlParam from 'v2/ui/utils/useUrlParam'

import { importUrl } from '../../../data/api/bundlesApi'
import { useTemplates } from '../../../data/hooks/templates'
import useLDFlags from '../../../data/hooks/useLDFlags'
import useLDIdentify from '../../../data/hooks/useLDIdentify'
import useTrack from '../../../utils/useTrack'
import { isFreeEmail } from '../../../utils/utils'
import { Flex } from '../../../v2/ui'

import CreateAppModal from './CreateAppModal'
import { DataConnectionStep } from './DataConnectionStep'
import { MockFrame } from './MockFrame'
import OnboardingLoadingSplash from './OnboardingLoadingSplash'
import { OnboardingTablesToggle } from './OnboardingTablesToggle'
import { OnboardingUsersInvite } from './OnboardingUsersInvite'
import PurposeModal from './PurposeModal'
import TemplateLibraryOnboarding from './TemplateLibraryOnboarding'
import { WorkspaceAndStackOnboardingModal } from './WorkspaceAndStackOnboardingModal'
import { WorkspaceOnboardingContext } from './WorkspaceOnboardingContext'
import WorkspaceOnboardingModal from './WorkspaceOnboardingModal'

//please BUMP the version of this everytime you make any changes in onboarding UX here (step order, additional steps, etc)
const ONBOARDING_VERSION = '1.4.1'

export const BUNDLE_URL_TEMPLATE =
    'https://storage.googleapis.com/stacker-template-library-public/{name}.json'

const STEP_NAME = 'onboardingStep'
type Step =
    | 'WORKSPACE_AND_STACK'
    | 'DATA'
    | 'USERS'
    | 'TABLES'
    | 'REDIRECT'
    | 'PURPOSE'
    | 'TEMPLATE_LIBRARY'
    | 'STACK'
    | 'WORKSPACE'
    | 'LOADING'
    | 'PREINSTALL_TEMPLATE'
    | 'PREINSTALL_BUNDLE'

const DEFAULT_STEP: Step = 'WORKSPACE_AND_STACK'

function usersStepEnabled(account: Account): boolean {
    return !!account?.optional_features?.sync_stacker_users
}

function dataStepCompleted(account: Account): boolean {
    return !!account.options?.onboarding_status?.data_connected
}

function skipStackConfigSteps(account: Account): boolean {
    // Whether to redirect to the workspace & app as soon as they are created, without any other setup.
    return !!account?.optional_features?.stacker_data_onboarding
}

function isStackerDataOnboardingFlow(account: Account): boolean {
    return !!account?.optional_features?.stacker_data_onboarding
}

function getInitialOnboardingStep(
    account: Account,
    templatesEnabled: boolean,
    preinstallTemplate: string
): Step {
    const stackerDataOnboardingFlow = isStackerDataOnboardingFlow(account)

    if (stackerDataOnboardingFlow) {
        if (account.options?.onboarding_status?.stack_set) {
            return 'REDIRECT'
        } else if (account.options?.onboarding_status?.name_set) {
            // if we're supposed to preinstall a bundle, then we
            // go straight to that step
            if (account?.options?.signup_params?.bundle) {
                return 'PREINSTALL_BUNDLE'
            }
            return preinstallTemplate && parseInt(preinstallTemplate) > 0
                ? 'PREINSTALL_TEMPLATE'
                : 'TEMPLATE_LIBRARY'
        } else if (
            account.options?.onboarding_status?.purpose_set ||
            // Skip the purpose screen and go straight to name the workspace
            // if we have a preinstalled bundle specified
            account?.options?.signup_params?.bundle
        ) {
            return templatesEnabled ? 'WORKSPACE' : 'WORKSPACE_AND_STACK'
        } else {
            return 'PURPOSE'
        }
    } else {
        // If the account has already got part-way through onboarding, then skip to the appropriate stage.
        if (account.options?.onboarding_status?.name_set) {
            if (skipStackConfigSteps(account)) {
                return 'REDIRECT'
            } else if (!dataStepCompleted(account)) {
                return 'DATA'
            } else {
                if (
                    usersStepEnabled(account) &&
                    !account.options?.onboarding_status?.user_table_set
                ) {
                    return 'USERS'
                } else {
                    if (!account.options?.onboarding_status?.tables_set) {
                        return 'TABLES'
                    }
                    // Otherwise we're in an invalid state, just start over from DEFAULT_STEP
                }
            }
        }
        return DEFAULT_STEP
    }
}

const WorkspaceOnboardingManager: React.FC = () => {
    const { data: accounts } = useAccounts()
    const { data: stacks } = useStacks()
    const [step, setStep] = useUrlParam<Step>(STEP_NAME, 'LOADING')
    const { mutateAsync: createStack } = useCreateStack()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const { user } = useAppUserContext()
    const [selectedStack, setSelectedStack] = useState<any>()
    const [workspaceAccount, setWorkspaceAccount] = useState<any>()
    const [selectedUserTableId, setSelectedUserTableId] = useState<string>()
    const { mutateAsync: createList } = useCreateUserList()
    const { data: objects } = useObjects()
    const [disabledTables, setDisabledTables] = useState<{ [key: string]: boolean }>({})
    const [preloadedLogoUrl, setPreloadedLogoUrl] = useState<string | undefined>()
    const [preloadedNavColor, setPreloadedNavColor] = useState<string | undefined>()
    const [preloadedWorkspaceName, setPreloadedWorkspaceName] = useState<string | undefined>()
    const [initialAppName, setInitialAppName] = useState<string | null>(null)
    const [initialAppIcon, setInitialAppIcon] = useState<string | null>(null)
    const { setSelectedStack: updateSelectedStack } = useAppContext()
    const { mutate: setDisabledObjects } = useDisableObjects()

    const { track } = useTrack()
    const {
        flags: { templatesEnabled, preinstallTemplate },
    } = useLDFlags()
    const ldIdentify = useLDIdentify()

    const { data: allTemplates, isLoading: isTemplatesLoading } = useTemplates()

    const isStackerData = useMemo(
        () => isStackerDataOnboardingFlow(workspaceAccount),
        [workspaceAccount]
    )

    const workspaceAccountId = workspaceAccount?._sid

    const isUserQualified = (account: Account, user: any) => {
        const purpose = account.options.purpose?.selected_value
        return (
            account &&
            user &&
            !isFreeEmail(user.email) &&
            purpose &&
            purpose !== 'Other' &&
            purpose != 'Product' &&
            account.options.onboarding_status?.stack_set
        )
    }

    const trackQualified = (account: Account) => {
        track('Frontend - Onboarding - User Qualified', {
            version: ONBOARDING_VERSION,
            freeform_text: account.options.purpose?.freeform_text,
            use_case: account.options.purpose?.selected_value,
            workspace_settings: {
                workspace_name: account.name,
                workspace_color: account.options.theme?.brandColor,
                sidebar_color: account.options.theme?.navColor,
            },
        })
    }
    const trackStackerDataStepCompleted = useCallback(
        (stepName: string | null, options: any = null) => {
            if (!isStackerData) return
            track('WIP - Frontend - Onboarding step - Completed', {
                version: ONBOARDING_VERSION,
                ...(stepName ? { step_name: stepName } : {}),
                onboarding_id: 'stacker_data',
                ...(options ? { options: options } : {}),
            })
        },
        [isStackerData, track]
    )
    useEffect(() => {
        // initialize enabled state on all tables based on backend state
        if (objects) {
            const disabled: Record<string, boolean> = {}
            objects.forEach(
                (obj: any) => (disabled[obj._sid] = !!obj.connection_options?.data_mapping_disabled)
            )
            setDisabledTables(disabled)
        }
    }, [objects])

    useEffect(() => {
        const query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })

        const accountId = query.id as string

        assertIsDefined(accounts)
        assertIsDefined(accountId)
        // Set the workspace account based on the sid in the URL
        const account = accounts.find((account) => account._sid === accountId)
        //let's identify LD with account and decide on initial step based on LD Flags
        ldIdentify(account as Account, undefined).then((ldFlags) =>
            setStep(
                getInitialOnboardingStep(
                    account as Account,
                    ldFlags.templatesEnabled,
                    ldFlags.preinstallTemplate
                ),
                true
            )
        )
        assertIsDefined(account)
        assertIsDefined(user)
        setWorkspaceAccount(account)
        setPreloadedLogoUrl(account?.options?.theme?.logo)
        setPreloadedNavColor(account?.options?.theme?.navColor)
        setPreloadedWorkspaceName(account?.name)

        let stack = stacks?.find((stack) => stack.account_id === accountId)

        if (!stack) {
            // Remove the ts-ignore once data/hooks/users.js has been converted to TS
            // @ts-ignore
            const newStack = createNewStackData(accountId, user._sid)

            // Select the first (and should be only) stack
            setSelectedStack(newStack)
        } else {
            setSelectedStack(stack)
            // updates our store with the selected stack so that
            // future calls to the server can tag the request with this stack id.
            updateSelectedStack(stack)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveStack = async () => {
        if (selectedStack._sid) {
            const stack = await updateStack({ id: selectedStack._sid, patch: selectedStack })
            return stack
        }

        const stack = await createStack(selectedStack)
        setSelectedStack(stack)
        // updates our store with the selected stack so that
        // future calls to the server can tag the request with this stack id.
        updateSelectedStack(stack)

        return stack
    }

    const saveAccount = (optionsPatch = {}, accountPatch = {}) => {
        return updateAccount({
            id: workspaceAccount?._sid,
            patch: {
                ...workspaceAccount,
                ...accountPatch,
                options: {
                    ...workspaceAccount.options,
                    ...optionsPatch,
                },
            },
        })
            .then((account) => {
                setWorkspaceAccount(account)

                return account
            })
            .then((account) => ldIdentify(account, undefined))
    }

    const updateAccountOnboardingStatus = (key: string, value: boolean) => {
        return updateAccount({
            id: workspaceAccount?._sid,
            patch: {
                ...workspaceAccount,
                options: {
                    ...workspaceAccount.options,
                    onboarding_status: {
                        ...workspaceAccount.options.onboarding_status,
                        [key]: value,
                    },
                },
            },
        }).then((account) => setWorkspaceAccount(account))
    }

    const setUrlToWorkspace = (account: any, stack?: any) => {
        const redirectStr = stack ? `&r=${encodeURIComponent('/' + stack.url_slug)}` : ''
        assertIsDefined(user)
        window.location.assign(`//${account.url}/auth?token=${user.api_token}${redirectStr}`)
    }

    const redirectToWorkspace = (stack?: any) => {
        return updateAccountOnboardingStatus('tables_set', true).then(() => {
            setUrlToWorkspace(workspaceAccount, stack)
        })
    }

    const configureInitialApp = (purpose: string) => {
        switch (purpose) {
            case 'Customer Portal':
                setInitialAppName('Portal')
                setInitialAppIcon('faCircleUser')
                break
            case 'Internal Workflow':
                setInitialAppName('Tracker')
                setInitialAppIcon('faArrowsRepeat')
                break
            case 'Community Portal':
                setInitialAppName('Community')
                setInitialAppIcon('faScreenUsers')
                break
            case 'Product':
                setInitialAppName('App')
                setInitialAppIcon('faRocket')
                break
            case 'Directory':
                setInitialAppName('Directory')
                setInitialAppIcon('faPeopleGroup')
                break
            case 'Other':
                setInitialAppName('Tracker')
                setInitialAppIcon('faCircleStar')
                break

            default:
                break
        }
    }

    useEffect(() => {
        if (step === 'REDIRECT' && workspaceAccount && selectedStack) {
            if (isUserQualified(workspaceAccount, user)) {
                trackQualified(workspaceAccount)
            }
            setUrlToWorkspace(workspaceAccount, selectedStack)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, workspaceAccount, selectedStack])

    useEffect(() => {
        if (step === 'PREINSTALL_TEMPLATE' && !isTemplatesLoading) {
            const templateToPreinstall = allTemplates?.find((t) => t.auto_id === preinstallTemplate)
            if (!templateToPreinstall) {
                setStep('REDIRECT')
            } else {
                const preinstallTemplateAsync = async () => {
                    const stack = await importUrl(
                        templateToPreinstall.bundle_url,
                        workspaceAccountId
                    )
                    setSelectedStack(stack)
                    await saveAccount({
                        preinstalled_template: preinstallTemplate,
                        onboarding_status: {
                            ...workspaceAccount.options.onboarding_status,
                            stack_set: true,
                        },
                    })
                    trackStackerDataStepCompleted('preinstall_template', {
                        template_name: templateToPreinstall.name,
                        template_id: templateToPreinstall.auto_id,
                    })
                    //send that onboarding is completed
                    trackStackerDataStepCompleted(null)
                    setStep('REDIRECT')
                }
                preinstallTemplateAsync()
            }
        }
        //if we add workspaceAccount to deps here, it can retrigger async operation and duplicate request
        // another option will be adding state `isPreinstalling` and check for that
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, allTemplates, isTemplatesLoading, preinstallTemplate])

    // Handles when the &bundle param was specified during sign up.
    // This tells us we are to preinstall a given bundle.
    const preinstallBundle = workspaceAccount?.options?.signup_params?.bundle
    useEffect(() => {
        if (step === 'PREINSTALL_BUNDLE') {
            const installAsync = async () => {
                const bundleUrl = BUNDLE_URL_TEMPLATE.replace('{name}', preinstallBundle)

                const stack = await importUrl(bundleUrl, workspaceAccountId)
                setSelectedStack(stack)
                await saveAccount({
                    onboarding_status: {
                        ...workspaceAccount.options.onboarding_status,
                        stack_set: true,
                    },
                })
                trackStackerDataStepCompleted('preinstall_bundle', {
                    bundle: preinstallBundle,
                })
                //send that onboarding is completed
                trackStackerDataStepCompleted(null)
                setStep('REDIRECT')
            }
            installAsync()
        }

        // if we add workspaceAccount to deps here, it can retrigger async operation and duplicate request
        // this is hacky and I don't like it. But it's the pattern used in this file elsewhere
        // and pending a refactor of this file, I'm going to just follow this pattern.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, preinstallBundle])

    const stepAfterDataConnected = usersStepEnabled(workspaceAccount) ? 'USERS' : 'TABLES'
    const appCreationCompleted = () => {
        if (skipStackConfigSteps(workspaceAccount)) {
            // Redirect immediately to the app without the remaininig steps
            setStep('REDIRECT')
        } else if (dataStepCompleted(workspaceAccount)) {
            // Skip the dat step and go right to its successor
            setStep(stepAfterDataConnected)
        } else {
            // Just continue to the DATA step
            setStep('DATA')
        }
    }

    const getOverlayBody = () => {
        switch (step) {
            case 'PURPOSE': {
                return (
                    <PurposeModal
                        // Remove the ts-ignore once data/hooks/users.js has been converted to TS
                        // @ts-ignore
                        userName={user?.name}
                        onSubmit={(purpose) => {
                            return saveAccount({
                                purpose: {
                                    ...workspaceAccount.options.purpose,
                                    selected_value: purpose,
                                },
                                onboarding_status: {
                                    ...workspaceAccount.options.onboarding_status,
                                    purpose_set: true,
                                },
                            }).then(() => {
                                configureInitialApp(purpose)
                                trackStackerDataStepCompleted('use_case', {
                                    selection: purpose,
                                })
                                if (templatesEnabled) {
                                    setStep('WORKSPACE')
                                } else {
                                    setStep('WORKSPACE_AND_STACK')
                                }
                            })
                        }}
                    />
                )
            }
            case 'WORKSPACE': {
                return (
                    workspaceAccount && (
                        <WorkspaceOnboardingModal
                            preloadedLogoUrl={preloadedLogoUrl}
                            preloadedNavColor={preloadedNavColor}
                            preloadedWorkspaceName={preloadedWorkspaceName}
                            onChange={(wsSettings) => {
                                setWorkspaceAccount({
                                    ...workspaceAccount,
                                    ...(wsSettings.name ? { name: wsSettings.name } : {}),
                                    options: {
                                        ...workspaceAccount.options,
                                        theme: {
                                            ...workspaceAccount.options.theme,
                                            navColor: wsSettings.navColor,
                                            brandColor: wsSettings.brandColor,
                                            logo: wsSettings.logoUrl,
                                            branding: wsSettings.logoUrl ? 'logo' : 'name',
                                        },
                                    },
                                })
                            }}
                            onSubmit={async (wsSettings) => {
                                await saveAccount(
                                    {
                                        onboarding_status: {
                                            ...workspaceAccount.options.onboarding_status,
                                            name_set: true,
                                        },
                                        theme: {
                                            ...workspaceAccount.options.theme,
                                            navColor: wsSettings.navColor,
                                            brandColor: wsSettings.brandColor,
                                            logo: wsSettings.logoUrl,
                                            branding: wsSettings.logoUrl ? 'logo' : 'name',
                                        },
                                    },
                                    { name: wsSettings.name }
                                )
                                trackStackerDataStepCompleted('workspace_settings', {
                                    workspace_name: wsSettings.name,
                                    workspace_color: wsSettings.brandColor,
                                    sidebar_color: wsSettings.navColor,
                                })
                                if (preinstallTemplate && parseInt(preinstallTemplate) > 0) {
                                    setStep('PREINSTALL_TEMPLATE')
                                } else if (workspaceAccount?.options?.signup_params?.bundle) {
                                    setStep('PREINSTALL_BUNDLE')
                                } else {
                                    setStep('TEMPLATE_LIBRARY')
                                }
                            }}
                        />
                    )
                )
            }
            case 'TEMPLATE_LIBRARY': {
                return (
                    <TemplateLibraryOnboarding
                        accountId={workspaceAccountId}
                        onSkip={() => {
                            track('WIP - Frontend - Onboarding - Templates - Skipped')
                            setStep('STACK')
                        }}
                        onInstallComplete={(stack, template) => {
                            setSelectedStack(stack)
                            updateAccountOnboardingStatus('stack_set', true).then(() => {
                                trackStackerDataStepCompleted('template_picker', {
                                    template_name: template?.name,
                                    template_id: template?.auto_id,
                                })
                                //send that onboarding is completed
                                trackStackerDataStepCompleted(null)
                                setStep('REDIRECT')
                            })
                        }}
                    />
                )
            }
            case 'STACK': {
                return (
                    <CreateAppModal
                        initialAppName={initialAppName}
                        initialAppIcon={initialAppIcon}
                        workspaceAccount={workspaceAccount}
                        onAppCreated={(stack) => {
                            setSelectedStack(stack)
                            updateAccountOnboardingStatus('stack_set', true).then(() => {
                                //send that onboarding is completed
                                trackStackerDataStepCompleted(null)
                                setStep('REDIRECT')
                            })
                        }}
                    />
                )
            }
            case 'WORKSPACE_AND_STACK': {
                return (
                    <WorkspaceAndStackOnboardingModal
                        selectedStack={selectedStack}
                        workspaceAccount={workspaceAccount}
                        onChangeStack={(name: string, brandColor: string, icon: any) => {
                            setSelectedStack({
                                ...selectedStack,
                                name: name || 'App Name',
                                options: {
                                    ...selectedStack.options,
                                    theme: {
                                        ...selectedStack.options.theme,
                                        brandColor: brandColor,
                                        icon: icon,
                                    },
                                },
                            })
                        }}
                        onChangeAccount={(name: string, navColor: string, brandColor: string) => {
                            setWorkspaceAccount({
                                ...workspaceAccount,
                                name: name || 'Org Name',
                                options: {
                                    ...workspaceAccount.options,
                                    theme: {
                                        ...workspaceAccount.options.theme,
                                        navColor: navColor,
                                        brandColor: brandColor,
                                    },
                                },
                            })
                        }}
                        userName={user?.name ?? ''}
                        onSubmit={() => {
                            assertIsDefined(user)
                            analytics.track('onboarding workspace submitted', {
                                app_id: selectedStack._sid,
                                workspace_id: workspaceAccount._sid,
                                // Remove the ts-ignore once data/hooks/users.js has been converted to TS
                                // @ts-ignore
                                user_id: user._sid,
                                event_category: 'app',
                            })
                            return saveAccount({
                                onboarding_status: {
                                    ...workspaceAccount.options.onboarding_status,
                                    name_set: true,
                                    stack_set: true,
                                },
                            })
                                .then(saveStack)
                                .then(() => {
                                    if (isStackerData) {
                                        trackStackerDataStepCompleted('app_configuration')
                                        //send that onboarding is completed
                                        trackStackerDataStepCompleted(null)
                                    }
                                    appCreationCompleted()
                                })
                                .catch((e) =>
                                    analytics.track('onboarding workspace failed', {
                                        app_id: selectedStack._sid,
                                        workspace_id: workspaceAccount._sid,
                                        user_id: user._sid,
                                        event_category: 'app',
                                        exception_details: e.toString(),
                                    })
                                )
                        }}
                    />
                )
            }
            case 'DATA': {
                return (
                    <DataConnectionStep
                        redirectToWorkspace={redirectToWorkspace}
                        stack={selectedStack as StackDto}
                        workspaceAccount={workspaceAccount as Account}
                        onClose={(analyticsData) => {
                            analytics.track('onboarding data connected', {
                                app_id: selectedStack._sid,
                                workspace_id: workspaceAccount._sid,
                                user_id: user?._sid,
                                event_category: 'app',
                                selected_data_connection: analyticsData?.type,
                            })
                            setStep(stepAfterDataConnected)
                        }}
                        updateAccountOnboardingStatus={updateAccountOnboardingStatus}
                    />
                )
            }
            case 'USERS': {
                return (
                    <OnboardingUsersInvite
                        onClose={(patch?: { object_id: string; email_field_id: string }) => {
                            if (patch !== undefined && objects) {
                                const obj = objects.find((o) => o._sid === patch.object_id)
                                if (obj) {
                                    setSelectedUserTableId(patch.object_id)
                                    const fullPatch = {
                                        ...patch,
                                        name: obj.name,
                                        options: { role: 'user' },
                                    }
                                    return createList(fullPatch).then(() =>
                                        updateAccountOnboardingStatus('user_table_set', true)
                                            .then(() =>
                                                updateStack({
                                                    id: selectedStack._sid,
                                                    patch: {
                                                        ...selectedStack,
                                                        options: {
                                                            ...selectedStack.options,
                                                            show_app_users_splash: false,
                                                        },
                                                    },
                                                })
                                            )
                                            .then(() =>
                                                // replace URL so user can't come back to this step now that
                                                // they've configured a user table.
                                                setStep('TABLES', true)
                                            )
                                    )
                                }
                                return updateAccountOnboardingStatus('user_table_set', false).then(
                                    () => setStep('TABLES')
                                )
                            } else {
                                return updateAccountOnboardingStatus('user_table_set', false).then(
                                    () => setStep('TABLES')
                                )
                            }
                        }}
                    />
                )
            }
            case 'TABLES': {
                return (
                    <OnboardingTablesToggle
                        setDisabledTables={setDisabledTables}
                        disabledTables={disabledTables}
                        objects={objects}
                        stack={selectedStack}
                        onFinish={(values) => {
                            const analyticsData = Object.values(values).reduce(
                                (acc, isDisabled) => {
                                    if (!isDisabled) {
                                        acc.enabled++
                                    } else {
                                        acc.disabled++
                                    }
                                    return acc
                                },
                                { enabled: 0, disabled: 0 }
                            )
                            analytics.track('onboarding tables selected', {
                                app_id: selectedStack._sid,
                                workspace_id: workspaceAccount._sid,
                                user_id: user?._sid,
                                event_category: 'app',
                                count_of_enabled_tables: analyticsData.enabled,
                                count_of_disabled_tables: analyticsData.disabled,
                            })

                            setDisabledObjects(values, {
                                onSuccess: redirectToWorkspace,
                            })
                        }}
                        selectedUserTableId={selectedUserTableId}
                    />
                )
            }
            case 'LOADING':
            case 'PREINSTALL_TEMPLATE':
            case 'PREINSTALL_BUNDLE':
            case 'REDIRECT': {
                return null
            }
            default: {
                return assertNever(step)
            }
        }
    }

    if (!selectedStack) return null

    const workspaceOnboardingContextValue = { accountId: workspaceAccount._sid }

    // show the app frame so we see the workspace in the background
    return (
        <WorkspaceOnboardingContext.Provider value={workspaceOnboardingContextValue}>
            <MockFrame
                stack={selectedStack}
                account={workspaceAccount}
                disabledTables={disabledTables}
            >
                {(step === 'REDIRECT' ||
                    step === 'PREINSTALL_TEMPLATE' ||
                    step === 'PREINSTALL_BUNDLE') && (
                    <Flex
                        style={{
                            maxWidth: '100%',
                            justifyContent: 'center',
                            flexGrow: 1,
                        }}
                    >
                        <OnboardingLoadingSplash />
                    </Flex>
                )}
            </MockFrame>
            {getOverlayBody()}
        </WorkspaceOnboardingContext.Provider>
    )
}

export default WorkspaceOnboardingManager
