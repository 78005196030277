/* Code Quality: Not audited */

import React, { Component, createContext } from 'react'

import WithBlocks from 'data/wrappers/WithBlocks'
import Frame from 'features/core/Frame'
import { Dropdown, Text } from 'legacy/v1/ui'

export const frameContext = createContext()

const PageFrame = ({ page, app, children, blockEntities, context, view }) => {
    const pageWithDefaultFrame = (
        <Frame context={context} page={page} view={view}>
            {children}
        </Frame>
    )

    // Don't show frames for settings pages
    if (page.is_feature_settings) return pageWithDefaultFrame

    const frameID = page.options.frame || app.options.frame
    // Deal with the case where we've disabled the default frame
    if (frameID == 'no_frame') return pageWithDefaultFrame

    // Check for any default frame via meta tags
    let blockList = Object.values(blockEntities || {})
    let defaultFrameByTags = blockList.find((block) => block.tags['stacker.defaultFrame'])

    if (!frameID && !defaultFrameByTags) return pageWithDefaultFrame

    const frame = blockEntities[frameID] || defaultFrameByTags
    if (!frame || !frame.blocks || !frame.blocks.length) return pageWithDefaultFrame

    return pageWithDefaultFrame
}
export default PageFrame

export class PageFrameSelector extends Component {
    state = { isEditing: false }

    render() {
        const { page, app, onChange } = this.props
        const pageFrame = page.options.frame
        const appFrame = !pageFrame && app && app.options.frame
        const Editable = ({ children }) => (
            <>
                <Text>
                    {children}{' '}
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({ isEditing: true })}
                    >
                        Edit
                    </a>
                </Text>
            </>
        )
        return (
            <WithBlocks>
                {({ blockEntities, blocks }) => {
                    if (this.state.isEditing) {
                        const options = blocks
                            .filter((block) => JSON.stringify(block.blocks).includes('placeholder'))
                            .map((block) => ({
                                label: block.name,
                                value: block._sid,
                            }))
                        options.push({
                            label: "Don't show a frame",
                            value: 'no_frame',
                        })
                        options.push({
                            label: 'Inherit from app',
                            value: null,
                        })
                        return (
                            <div style={{ flex: 1, width: '100%', marginTop: 6 }}>
                                <Dropdown
                                    options={options}
                                    value={pageFrame}
                                    onChange={(value) => {
                                        this.setState({ isEditing: false })
                                        onChange(value)
                                    }}
                                />
                            </div>
                        )
                    } else {
                        if (pageFrame) {
                            const frame = blockEntities[pageFrame]
                            // We have a real frame
                            if (frame) return <Editable>{frame.name}</Editable>
                            // We have blocked this page from showing a frame
                            return <Editable>Don&apos;t show a frame</Editable>
                        } else if (appFrame) {
                            // We have a frame via the app
                            return <Editable>Inherited from app</Editable>
                        } else {
                            return <Editable>No frame set</Editable>
                        }
                    }
                }}
            </WithBlocks>
        )
    }
}
