import React, { useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'lodash'

import { useStackRoles } from 'data/hooks/roleHelpers'
import { useUserLists } from 'data/hooks/userLists'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { Icon } from 'legacy/v1/ui'

import { Banner, Box, Flex, Text } from 'v2/ui'

import RadioWithContentForm from 'ui/forms/RadioWithContentForm'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'

const externalLinkIcon = (
    <Icon margin="0px" style={{ color: '#CCC', fontSize: '0.7em' }} icon="external-link-alt" />
)

type UpdateRegistrationWhitelistProps = {
    onChange: (...args: any[]) => Promise<StackDto>
    value: string
    stack: StackDto
    onChangeStack: (...args: any[]) => Promise<StackDto>
    objects: ObjectDto[]
}

const UpdateRegistrationWhitelist: React.FC<UpdateRegistrationWhitelistProps> = ({
    onChange,
    value,
    stack,
    objects,
}: UpdateRegistrationWhitelistProps) => {
    const [, setWhiteListNewUsers] = useState('whitelist')
    const [hasLoaded, setHasLoaded] = useState(false)
    const [registerUrl, setRegisterUrl] = useState<string>()
    const [defaultRole, setDefaultRole] = useState<any>()
    const [isUsingConditionnalRoles, setIsUsingConditionalRoles] = useState<boolean>()
    const [userList, setUserList] = useState<UserListDto>()
    const [userObject, setUserObject] = useState<ObjectDto>()
    const [isMissingOpenSignUpUserList, setIsMissingOpenSignUpUserList] = useState<boolean>(false)

    const { data: roles } = useStackRoles()
    const { data: userLists = [] } = useUserLists()
    const rolesEnabled = stack?.options?.roles__enabled

    useEffect(() => {
        if (stack.options.open_sign_up?.user_list) {
            const userList = userLists.find((u) => u._sid === stack.options.open_sign_up?.user_list)
            setUserList(userList)
            setIsUsingConditionalRoles(!isEmpty(userList?.options.conditional_roles))
            setIsMissingOpenSignUpUserList(false)
        } else {
            setIsMissingOpenSignUpUserList(true)
        }
    }, [stack.options.open_sign_up?.user_list, userLists])

    useEffect(() => {
        const role = roles.find((r) => r.api_name === userList?.options.role)
        setDefaultRole(role)
    }, [roles, userList])

    useEffect(() => {
        setUserObject(objects.find((o) => o._sid === userList?.object_id))
    }, [objects, userList])

    const { protocol } = location

    useEffect(() => {
        if (stack && stack.options) {
            setHasLoaded(true)
            setWhiteListNewUsers(stack.options.whitelist_new_users ? 'whitelist' : 'open-signup')
            setRegisterUrl(`${protocol}//${stack.url}/register`)

            if (!hasLoaded) {
                setHasLoaded(true)
                setWhiteListNewUsers(
                    stack.options.whitelist_new_users ? 'whitelist' : 'open-signup'
                )
            }
        }
    }, [stack, protocol, hasLoaded])

    const signUpLink = useMemo(
        () => (
            <>
                <Text margin="none" ml={1} mt={2} size="sm">
                    <a href={registerUrl} rel="noopener noreferrer" target="_blank">
                        {registerUrl} {externalLinkIcon}
                    </a>
                </Text>
            </>
        ),
        [registerUrl]
    )

    return (
        // @ts-expect-error
        <Box wrapItems style={{ width: '100%', marginRight: '10px' }}>
            {userObject?.connection_options?.read_only && (
                <Banner icon="userLock" mt={1}>
                    The <strong>{userObject?.name}</strong> table is read-only. Open Signup mode is
                    not supported with a read-only user table.
                </Banner>
            )}
            {isMissingOpenSignUpUserList && (
                <Banner variant="Warning" icon="warning" mt={1}>
                    We cannot find a User table for this app. Contact support to configure Open sign
                    up.
                </Banner>
            )}
            <Flex pt={2} style={{ width: '100%' }}>
                <RadioWithContentForm
                    onChange={onChange}
                    // @ts-expect-error
                    value={value}
                    // @ts-expect-error
                    disabled={userObject?.connection_options?.read_only}
                    options={[
                        {
                            label: 'User list only',
                            text: 'Only users from your user list can register.',
                            value: 'whitelist',
                            content: (
                                <Flex alignItems="baseline" mb={2}>
                                    <Text color={V4DesignSystem.colors.gray[600]} size="sm">
                                        Users can sign up using the email from your User list at
                                    </Text>
                                    {signUpLink}
                                </Flex>
                            ),
                        },
                        {
                            label: 'Open signup',
                            text: 'Anyone with the link can register for this app.',
                            value: 'open-signup',
                            disabled: isMissingOpenSignUpUserList,
                            content: (
                                <Flex alignItems="baseline" mb={2}>
                                    <Text color={V4DesignSystem.colors.gray[600]} size="sm">
                                        Users can sign up using any email at
                                    </Text>
                                    {signUpLink}
                                    <Text
                                        color={V4DesignSystem.colors.gray[600]}
                                        ml={1}
                                        as="span"
                                        size="sm"
                                    >
                                        {isUsingConditionnalRoles
                                            ? ' and are assigned a Role according to your Conditional Roles settings.'
                                            : defaultRole &&
                                              rolesEnabled && (
                                                  <>
                                                      <Text ml={1} as="span" size="sm">
                                                          and are added as
                                                      </Text>
                                                      <Text
                                                          ml={1}
                                                          fontWeight="bold"
                                                          as="span"
                                                          size="sm"
                                                      >
                                                          {defaultRole.label}
                                                      </Text>
                                                  </>
                                              )}
                                    </Text>
                                </Flex>
                            ),
                        },
                    ]}
                />
            </Flex>
        </Box>
    )
}

export default withStack(withObjects(UpdateRegistrationWhitelist))
