import React, { createContext, useContext, useMemo, useState } from 'react'

type LookupFilterContext = {
    searchString: string
    setSearchString: (newDate: string) => void
    isFetching: boolean
    setIsFetching: (isFetching: boolean) => void
}

const lookupFilterContext = createContext<LookupFilterContext>({
    searchString: '',
    setSearchString: () => {},
    isFetching: false,
    setIsFetching: () => {},
})

type LookupFilterContextProviderProps = React.PropsWithChildren<{}>

export const LookupFilterContextProvider: React.FC<LookupFilterContextProviderProps> = ({
    children,
}) => {
    const [searchString, setSearchString] = useState('')
    const [isFetching, setIsFetching] = useState(false)

    const value = useMemo(
        () => ({
            searchString,
            setSearchString,
            isFetching,
            setIsFetching,
        }),
        [isFetching, searchString]
    )

    return <lookupFilterContext.Provider value={value}>{children}</lookupFilterContext.Provider>
}

export const useLookupFilter = () => {
    const { searchString, setSearchString, isFetching, setIsFetching } =
        useContext(lookupFilterContext)

    return useMemo(
        () => ({
            searchString,
            setSearchString,
            isFetching,
            setIsFetching,
        }),
        [isFetching, searchString, setIsFetching, setSearchString]
    )
}
