import React from 'react'

import { useAppContext } from 'app/AppContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import useTrack from 'utils/useTrack'

import { Box } from 'v2/ui'
import { useIsNavigationDark } from 'v2/ui/hooks/useIsNavigationDark'

import { useCreateEmptyBlankPageModal } from './CreateEmptyBlankPageModal'
import { useCreateEmptyTableModal } from './CreateEmptyTableModal'
import { FloatingAddNewButtonMenu } from './FloatingAddNewButtonMenu'
import { useShouldDisplayAddButton } from './useShouldDisplayAddButton'

type FloatingAddNewButtonProps = React.ComponentPropsWithoutRef<typeof Box> & {
    bypassDisplayCheck?: boolean
}

export const FloatingAddNewButton: React.FC<FloatingAddNewButtonProps> = ({
    bypassDisplayCheck,
    ...props
}) => {
    const { track } = useTrack()

    const { showAppSettings } = useSlidingPane()
    const onClickAddDataSource = () => {
        track('Frontend - Menu add new button - Data sources - Clicked')
        showAppSettings({ page: { name: 'data_sources' } })
    }

    const handleAddTable = useCreateEmptyTableModal()
    const onClickAddTable = () => {
        track('Frontend - Menu add new button - Table - Clicked')
        handleAddTable()
    }

    const onConfirmCreateEmptyBlankPage = async () => {
        track('Frontend - Menu add new button - Create page - Clicked')
    }
    const handleAddPage = useCreateEmptyBlankPageModal({ onConfirm: onConfirmCreateEmptyBlankPage })
    const onClickAddPage = () => {
        track('Frontend - Menu add new button - Page - Clicked')
        handleAddPage()
    }

    const isNavigationDark = useIsNavigationDark()

    const { selectedStack } = useAppContext()
    const isTopNavigation = selectedStack?.options?.vertical_nav_v4 === false

    const isButtonDark = !isNavigationDark || isTopNavigation

    const onClickButton = () => {
        track('Frontend - Menu add new button - Clicked')
    }

    const shouldDisplayButton = useShouldDisplayAddButton()
    if (!shouldDisplayButton && !bypassDisplayCheck) return null

    return (
        <Box {...props}>
            <FloatingAddNewButtonMenu
                onClickAddDataSource={onClickAddDataSource}
                onClickAddTable={onClickAddTable}
                onClickAddPage={onClickAddPage}
                onClickTrigger={onClickButton}
                isDark={isButtonDark}
            />
        </Box>
    )
}
