import React, { FC } from 'react'

import type { RenderRowAttributeRenderProps } from 'features/pages/blocks/settings/attributes/RenderRowAttribute'

import { AggregationBlockEditor } from '../common/AggregationBlockEditor'
import useAggregationBlockEditorHandler from '../common/useAggregationBlockEditorHandler'

import isValidChart from './utils/isValidChart'
import { ChartEditor } from './ChartEditor'
import { NEW_CHART_ATTRIBUTES } from './constants'
import { ListChartsEditor } from './ListChartsEditor'

type ChartsBlockEditorProps = Pick<
    RenderRowAttributeRenderProps<ChartsBlock['config']['attributes']>,
    'value' | 'context' | 'onChange'
> & {}

export const ChartsBlockEditor: FC<ChartsBlockEditorProps> = ({
    context,
    value: charts,
    onChange,
}) => {
    const {
        editingWidgetOrder,
        onStartEditingWidget,
        onAddWidget,
        onEditWidget,
        onDeleteWidget,
        onReorderWidgets,
    } = useAggregationBlockEditorHandler({
        widgets: charts,
        onChange,
        addEventName: 'Chart Added',
        configuredEventName: 'Chart Configured',
        configuredEventExtraInfo: (widget) => ({ chartType: widget.type }),
        defaultWidgetAttributes: NEW_CHART_ATTRIBUTES,
        isValid: isValidChart,
    })

    return (
        <AggregationBlockEditor
            isEditing={editingWidgetOrder !== null && editingWidgetOrder !== undefined}
            listChildren={
                <ListChartsEditor
                    charts={charts}
                    onStartEditingChart={onStartEditingWidget}
                    onAddChart={onAddWidget}
                    onDeleteChart={onDeleteWidget}
                    onReorderCharts={onReorderWidgets}
                />
            }
            editorChildren={
                <>
                    {editingWidgetOrder !== null && editingWidgetOrder !== undefined && (
                        <ChartEditor
                            chart={charts[editingWidgetOrder]}
                            onChange={onEditWidget}
                            currentObject={context.object}
                            context={context}
                        />
                    )}
                </>
            }
        />
    )
}
