import React, {
    CSSProperties,
    KeyboardEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    VFC,
} from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { LOGICAL_FIELD_TYPE_GROUPS } from 'features/admin/fields/common'
import {
    FieldTypeComponentData,
    fieldTypeComponentList,
} from 'features/admin/fields/definitions/fieldTypeComponents'

import stackerTheme from 'v2/ui/theme/styles/default'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import FieldTypeOptions from './FieldTypeOptions'
import NoMatchingFieldTypes from './NoMatchingFieldTypes'
import SearchInput from './SearchInput'
import SelectedValue from './SelectedValue'
import type { FieldGroupOption, InputDevice } from './types'

const { colors } = stackerTheme()

const Wrapper = styled.div<{ disabled: boolean; isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    overflow: hidden;

    border-radius: 5px;
    border: 1px solid
        ${(props) =>
            props.disabled ? 'rgba(219, 221, 223, 0.7)' : colors.userInterface.neutral[500]};
    transition: border-color 0.2s ease;

    ${(props) =>
        !props.disabled &&
        !props.isOpen &&
        css`
            &:hover {
                border-color: ${colors.userInterface.neutral[800]};
            }
        `}

    &:focus {
        outline: none;
    }
    &:focus-within {
        outline: none;
        box-shadow: none;
        border-width: 1px;
        border-color: ${colors.userInterface.neutral[800]};
    }
`

const FieldTypesWrapper = styled.div`
    width: 100%;
    max-height: min(450px, calc(50vh - 170px));

    padding-top: 10px;
    padding-bottom: 10px;

    overflow-y: scroll;

    border-top: 1px solid ${colors.userInterface.neutral[500]};
`

type Props = {
    value: string | null | undefined
    onChange: (selectedType: string) => void
    supportedFieldTypes: FieldTypeComponentData[]
    initialOpen?: boolean
    placeholder?: string
    disabled?: boolean
    style?: CSSProperties
    onToggled?: (isOpen: boolean) => void
    onSubmit?: () => void
}

const FieldTypeSearchableSelector: VFC<Props> = ({
    value,
    onChange,
    supportedFieldTypes,
    initialOpen,
    placeholder = 'Select a field type',
    disabled,
    style,
    onToggled,
    onSubmit,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)
    const [isOpen, setIsOpen] = useState(initialOpen)
    const [search, setSearch] = useState('')
    const [highlightedOptionIndex, setHighlightedOptionIndex] = useState<number | null>(null)
    const [activeInputDevice, setActiveInputDevice] = useState<InputDevice | null>(null)

    const options: FieldGroupOption[] = useMemo(() => {
        const searchString = search.toLowerCase()

        const groups = LOGICAL_FIELD_TYPE_GROUPS.map(({ name, fieldTypes }) => ({
            label: name,
            fieldTypes: supportedFieldTypes.filter(
                ({ value, search_tags: searchTags }) =>
                    fieldTypes.includes(value) &&
                    searchTags.find((tag) => tag.toLowerCase().includes(searchString))
            ),
        }))

        return groups.filter(({ fieldTypes }) => fieldTypes.length > 0)
    }, [supportedFieldTypes, search])

    const flattenOptions = useMemo(() => options.flatMap((option) => option.fieldTypes), [options])

    const fieldType = useMemo(
        () => fieldTypeComponentList.find((fieldType) => fieldType.value === value),
        [value]
    )

    const open = useCallback(() => {
        setIsOpen(true)
        onToggled?.(true)
    }, [onToggled])

    // When opening the dropdown via user interaction (ie., not defaulting to open)
    // set the focus on the search box
    useEffectOnlyOnUpdate(() => {
        if (isOpen) {
            searchRef.current?.focus()
        }
    }, [isOpen])
    const close = useCallback(() => {
        setIsOpen(false)
        setSearch('')
        setHighlightedOptionIndex(null)
        setActiveInputDevice(null)
        onToggled?.(false)
    }, [onToggled])

    // always highlight the first match (if any) so the user can just hit enter
    useEffect(() => {
        setHighlightedOptionIndex(!!search && options.length ? 0 : null)
    }, [options.length, search])

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (!!disabled) {
                return
            }

            setActiveInputDevice('keyboard')

            switch (event.key) {
                case 'ArrowDown':
                    if (!isOpen) {
                        open()
                        wrapperRef.current?.focus()
                        event.preventDefault()
                        return
                    }

                    event.preventDefault()
                    setHighlightedOptionIndex((prev) =>
                        prev === null ? 0 : Math.min(prev + 1, flattenOptions.length - 1)
                    )
                    break
                case 'ArrowUp':
                    event.preventDefault()
                    setHighlightedOptionIndex((prev) => (prev === null ? 0 : Math.max(prev - 1, 0)))
                    break
                case ' ':
                    if (!isOpen) {
                        open()
                        wrapperRef.current?.focus()
                        event.preventDefault()
                        return
                    }

                    break
                case 'Enter':
                    // If the list is closed and we have a selected value,
                    // pass this up to the parent so it can submit the form.
                    // Unfortunately, enter keys inside non-input elements like Divs
                    // don't automatically submit forms.
                    if (!isOpen && value) {
                        onSubmit?.()
                        return
                    }
                    if (!isOpen) {
                        open()
                        wrapperRef.current?.focus()
                        event.preventDefault()
                        return
                    }

                    if (highlightedOptionIndex !== null) {
                        onChange(flattenOptions[highlightedOptionIndex].value)
                        close()
                        wrapperRef.current?.focus()
                    }

                    event.preventDefault()
                    break
                case 'Escape':
                    event.preventDefault()
                    close()
                    wrapperRef.current?.focus()
                    break
                default:
                    break
            }
        },
        [
            disabled,
            isOpen,
            value,
            highlightedOptionIndex,
            close,
            open,
            flattenOptions,
            onSubmit,
            onChange,
        ]
    )

    const onMouseMove = useCallback(() => {
        if (!!disabled) {
            return
        }

        setActiveInputDevice('mouse')
    }, [disabled])

    const onFieldTypeSelected = useCallback(
        (selectedFieldType: string) => {
            if (!!disabled) {
                return
            }

            close()
            onChange(selectedFieldType)
        },
        [disabled, close, onChange]
    )

    const onClickSelectedValue = useCallback(() => {
        if (!!disabled) {
            return
        }

        if (isOpen) {
            close()
            return
        }

        open()
    }, [disabled, isOpen, open, close])

    return (
        <Wrapper
            ref={wrapperRef}
            disabled={!!disabled}
            isOpen={!!isOpen}
            style={style}
            // Don't want a tab stop here if we're open as the field
            // input below is the tab stop
            tabIndex={!isOpen && !disabled ? 0 : -1}
            onKeyDown={onKeyDown}
            onMouseMove={onMouseMove}
        >
            {isOpen ? (
                <>
                    <SearchInput
                        ref={searchRef}
                        value={search}
                        onChange={setSearch}
                        onClose={close}
                    />
                    <FieldTypesWrapper>
                        {options.length === 0 ? (
                            <NoMatchingFieldTypes
                                search={search as string}
                                onClearSearch={() => setSearch('')}
                            />
                        ) : (
                            <FieldTypeOptions
                                options={options}
                                flattenOptions={flattenOptions}
                                highlightedOptionIndex={highlightedOptionIndex}
                                activeInputDevice={activeInputDevice}
                                onHighlightOption={setHighlightedOptionIndex}
                                onChange={onFieldTypeSelected}
                                value={value}
                            />
                        )}
                    </FieldTypesWrapper>
                </>
            ) : (
                <SelectedValue
                    selectedFieldType={fieldType}
                    placeholder={placeholder}
                    disabled={disabled}
                    onClick={onClickSelectedValue}
                />
            )}
        </Wrapper>
    )
}

export default FieldTypeSearchableSelector
