import React, { CSSProperties, FC, MouseEvent, ReactNode, useMemo } from 'react'

import Box from './Box'
import Button from './Button'
import PopoverButton, {
    PopoverButtonChildrenProps,
    Props as PopoverButtonProps,
} from './PopoverButton'

const Content: FC<PopoverButtonChildrenProps> = ({
    isOpen,
    onToggle,
    buttonRef,
    close,
    children,
}) => {
    const content = useMemo(
        () =>
            typeof children === 'function'
                ? children({ isOpen, onToggle, buttonRef, close })
                : children,
        [buttonRef, children, close, isOpen, onToggle]
    )
    return <>{content}</>
}

type Props = Omit<PopoverButtonProps, 'defaultIsOpen' | 'icon'> & {
    buttonContent?: ReactNode
    bodyPadding?: string
    dropdownBoxStyle?: CSSProperties
    onDropdownClick?: (event: MouseEvent) => void
    hideIcon?: boolean
    icon?: string | Element
    children: (props: PopoverButtonChildrenProps) => ReactNode
}

const DropdownButton: FC<Props> = ({
    children,
    ButtonComponent = Button,
    place = null,
    buttonContent,
    bodyPadding = '12px',
    dropdownBoxStyle,
    onDropdownClick,
    hideIcon,
    ...props
}) => (
    <PopoverButton
        ButtonComponent={ButtonComponent}
        label={buttonContent}
        icon={hideIcon ? undefined : 'arrowDown'}
        place={place || 'bottom'}
        defaultIsOpen={false}
        {...props}
    >
        {({ isOpen, onToggle, buttonRef, close }: PopoverButtonChildrenProps) => (
            <Box
                onClick={onDropdownClick}
                bg="white"
                style={{
                    padding: bodyPadding,
                    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.14)',
                    borderRadius: '5px',
                    ...dropdownBoxStyle,
                }}
            >
                <Content buttonRef={buttonRef} isOpen={isOpen} onToggle={onToggle} close={close}>
                    {children}
                </Content>
            </Box>
        )}
    </PopoverButton>
)

export default DropdownButton
