import React, { ReactNode, useEffect } from 'react'

import {
    Alert as ChakraAlert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    CloseButton,
} from '@chakra-ui/react'

import Box from './Box'

// Creates an element that uses the hook so that we can
// use this in class components too

type AlertProps = {
    status: any
    variant?: 'solid' | 'subtle' | 'left-accent' | 'top-accent' | undefined
    id?: string
    title?: string
    isClosable?: boolean
    onClose?: any
    description?: ReactNode
    [propName: string]: any // Allow any additional props for destructuring
}

const Alert: React.FC<AlertProps> = ({
    status,
    variant,
    id,
    title,
    isClosable,
    onClose,
    description,
    iconStyle,
    ...props
}) => {
    useEffect(() => {
        return () => {
            if (onClose) onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ChakraAlert
            status={status}
            variant={variant}
            id={id}
            textAlign="left"
            boxShadow="lg"
            borderRadius="md"
            alignItems="start"
            margin={2}
            paddingRight={8}
            {...props}
        >
            <AlertIcon {...iconStyle} />
            <Box flex="1">
                {title && <AlertTitle lineHeight={1.8}>{title}</AlertTitle>}
                {description && (
                    <AlertDescription marginTop="px" lineHeight="short">
                        {description}
                    </AlertDescription>
                )}
            </Box>
            {isClosable && (
                <CloseButton
                    size="sm"
                    onClick={onClose}
                    position="absolute"
                    right="4px"
                    top="4px"
                    border={0}
                    bg="transparent"
                    cursor="pointer"
                />
            )}
        </ChakraAlert>
    )
}

export default Alert
