import { InlineFilterOperation } from 'features/records/components/RecordFilters/constants'

export function getDateInlineFilterLabelsForValue(
    value: Filter['options']['value'],
    operation: InlineFilterOperation
): string[] | undefined {
    switch (operation) {
        case 'withinLast':
        case 'withinNext': {
            const dayCount = formatDayCountValue(value)
            if (dayCount.length > 0) return dayCount
        }
    }

    return undefined
}

function formatDayCountValue(value: Filter['options']['value']): string[] {
    let dayCount = ''
    if (typeof value === 'string') {
        dayCount = value
    } else if (Array.isArray(value) && value.length > 0) {
        dayCount = value[0]
    }

    if (dayCount) {
        const dayLabel = dayCount === '1' ? 'day' : 'days'
        return [`${dayCount} ${dayLabel}`]
    }

    return []
}

export function isRelativeDateFilterOperation(op: InlineFilterOperation): boolean {
    return op === 'beforeDate' || op === 'afterDate' || op === 'sameDay'
}
