export const appSettingsModalSubscribers = []
export const manageUserSettingsModalSubscribers = []
export const manageRegistrationModalSubscribers = []
export const expiredSessionModalSubscribers = []
export const importCSVModalSubscribers = []
/**
 * call this to open the modal from anywhere. No context or hooks needed
 * @param {{page: AppSettingsPage}} defaultState
 */
export function openImportCSVModal(defaultState = {}) {
    importCSVModalSubscribers.map((fn) => fn(defaultState))
}
export function closeImportCSVModal() {
    importCSVModalSubscribers.map((fn) => fn(false))
}

export const openManageUserSettingsModal = (defaultState = true) => {
    manageUserSettingsModalSubscribers.map((fn) => fn(defaultState))
}

export const openManageRegistrationModal = (defaultState = true) => {
    manageRegistrationModalSubscribers.map((fn) => fn(defaultState))
}

export const closeManageUserSettingsModal = (defaultState = false) => {
    manageUserSettingsModalSubscribers.map((fn) => fn(defaultState))
}

export const openExpiredSessionModal = (defaultState = true) => {
    expiredSessionModalSubscribers.map((fn) => fn(defaultState))
}

// defaultState is kept to have correct TS type definition. To be removed after TS conversion
// eslint-disable-next-line unused-imports/no-unused-vars
export const closeExpiredSessionModal = (defaultState = false) => {
    expiredSessionModalSubscribers.map((fn) => fn(false))
}
