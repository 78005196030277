import React, { FC } from 'react'

import type { RenderRowAttributeRenderProps } from 'features/pages/blocks/settings/attributes/RenderRowAttribute'

import { AggregationBlockEditor } from '../common/AggregationBlockEditor'
import useAggregationBlockEditorHandler from '../common/useAggregationBlockEditorHandler'
import isValidAggregation from '../utils/isValidAggregation'

import { NEW_METRIC_ATTRIBUTES } from './constants'
import { ListMetricsEditor } from './ListMetricsEditor'
import { MetricEditor } from './MetricEditor'

type MetricsBlockEditorProps = Pick<
    RenderRowAttributeRenderProps<MetricsBlock['config']['attributes']>,
    'value' | 'context' | 'onChange'
> & {}

export const MetricsBlockEditor: FC<MetricsBlockEditorProps> = ({
    context,
    value: metrics,
    onChange,
}) => {
    const {
        editingWidgetOrder,
        onStartEditingWidget,
        onAddWidget,
        onEditWidget,
        onDeleteWidget,
        onReorderWidgets,
    } = useAggregationBlockEditorHandler({
        widgets: metrics,
        onChange,
        addEventName: 'Metric Added',
        configuredEventName: 'Metric Configured',
        defaultWidgetAttributes: NEW_METRIC_ATTRIBUTES,
        isValid: isValidAggregation,
    })

    return (
        <AggregationBlockEditor
            isEditing={editingWidgetOrder !== null && editingWidgetOrder !== undefined}
            listChildren={
                <ListMetricsEditor
                    metrics={metrics}
                    onStartEditingMetric={onStartEditingWidget}
                    onAddMetric={onAddWidget}
                    onDeleteMetric={onDeleteWidget}
                    onReorderMetrics={onReorderWidgets}
                />
            }
            editorChildren={
                <>
                    {editingWidgetOrder !== null && editingWidgetOrder !== undefined && (
                        <MetricEditor
                            metric={metrics[editingWidgetOrder]}
                            onChange={onEditWidget}
                            currentObject={context.object}
                            context={context}
                        />
                    )}
                </>
            }
        />
    )
}
