import * as React from 'react'

import styled from '@emotion/styled'

import Box from 'v2/ui/components/Box'
import Icon from 'v2/ui/components/Icon'
import PopoverButton from 'v2/ui/components/PopoverButton'
import stackerTheme from 'v2/ui/theme/styles/default'
export const colors = stackerTheme().colors

export default function PickerButton({
    children,
    place = null,
    buttonContent,
    bodyPadding = '12px',
    hideArrow = false,
    buttonHeight = '38px',
    ...props
}) {
    return (
        <PopoverButton
            ButtonComponent={PickerTrigger}
            label={
                <>
                    {buttonContent}
                    {!hideArrow && (
                        <Icon icon="arrowDown" style={{ color: 'rgba(147, 148, 150, 1)' }} />
                    )}
                </>
            }
            icon="downCaret"
            iconAlign="right"
            place={place}
            buttonHeight={buttonHeight}
            {...props}
        >
            {
                <Box
                    bg="white"
                    style={{
                        padding: bodyPadding,
                        boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.14)',
                        borderRadius: '5px',
                    }}
                >
                    {children}
                </Box>
            }
        </PopoverButton>
    )
}

const PickerTrigger = styled('div')`
    height: ${(props) => props.buttonHeight};
    width: 70px;
    padding: 5px;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 5px;
    border: ${(props) => (props.border ? props.border : `1px solid ${colors.gray[300]}`)};
    align-items: center;
    justify-content: ${(props) => (props.justify ? props.justify : 'space-around')};
    cursor: ${(p) => (p.disabled ? 'unset' : 'pointer')};
    transition: 0.1s all ease-in-out;
    &:hover {
        border-color: ${(p) => (p.disabled ? colors.gray[200] : colors.gray[200])};
    }
`
