import isEqual from 'lodash/isEqual'

// This allows us to optimistically update records before we have fetched
// the data. This is helpful for both EC indexing delays and to ensure that
// it looks less glitchy
const optimisticallyUpdateRelatedRecord = (
    parentRecord,
    relatedRecord,
    recordsAdded,
    recordsRemoved,
    recordActions
) => {
    if (!parentRecord || !relatedRecord) return

    // Check if this record was added or removed from the parent
    // it can be added/removed from multiple symmetric fields so we
    // need to loop through each
    const addedFields = recordsAdded.filter((r) => r.record_sid == relatedRecord?._sid)
    const removedFields = recordsRemoved.filter((r) => r.record_sid == relatedRecord?._sid)
    let updatedRecord = { ...relatedRecord }

    // Record was added to fields
    addedFields.forEach((added) => {
        // The field should now contain a reference to the related record so make
        // sure that it's added if it doesn't exist
        const field = added?.field
        const field_api_name = field?.api_name
        if (field_api_name) {
            let currentValue = relatedRecord?.[field_api_name]
            if (
                field.type == 'multi_lookup' &&
                (!currentValue ||
                    (Array.isArray(currentValue) && !currentValue.includes(parentRecord._sid)))
            ) {
                if (!currentValue) {
                    currentValue = []
                }
                updatedRecord = {
                    ...updatedRecord,
                    [field_api_name]: [...currentValue, parentRecord._sid],
                }
            } else if (field.type == 'lookup' && currentValue !== parentRecord._sid) {
                updatedRecord = {
                    ...updatedRecord,
                    [field_api_name]: parentRecord._sid,
                }
            }
        }
    })

    //record removed from fields
    removedFields.forEach((removed) => {
        // The field should no longer contain a reference to the related record so make
        // sure that it's removed
        const field = removed?.field
        const field_api_name = field?.api_name
        if (field_api_name) {
            const currentValue = relatedRecord?.[field_api_name]
            if (Array.isArray(currentValue) && currentValue.includes(parentRecord._sid)) {
                updatedRecord = {
                    ...updatedRecord,
                    [field_api_name]: currentValue.filter((sid) => sid !== parentRecord._sid),
                }
            } else if (!Array.isArray(currentValue) && currentValue == parentRecord._sid) {
                updatedRecord = {
                    ...updatedRecord,
                    [field_api_name]: null,
                }
            }
        }
    })

    // If we've made some updates then update the redux record
    if (!isEqual(updatedRecord, relatedRecord)) {
        recordActions.updateRecordCache(updatedRecord)
    }

    return updatedRecord
}

export default optimisticallyUpdateRelatedRecord
