import * as Sentry from '@sentry/react'

import { Authentication } from 'app/Authentication'

export type Token = string | undefined

export const getUserApiToken = async (user?: UserDto): Promise<Token> => {
    const apiToken: Token = user?.api_token
    let auth0AccessToken: Token

    if (Authentication.isAuth0Authenticated) {
        try {
            auth0AccessToken = await Authentication?.getAccessTokenSilently?.()
        } catch (ex) {
            Sentry.captureException(ex)
            return undefined
        }
    }

    return auth0AccessToken ? `auth0_${auth0AccessToken}` : apiToken
}
