import React from 'react'
import { Helmet } from 'react-helmet'

import isEmpty from 'lodash/isEmpty'

import { useAppContext } from 'app/AppContext'
import { getStackTheme } from 'app/Branding'
import { isImpersonatingEndUser } from 'data/utils/isAdmin'
import { withStack } from 'data/wrappers/WithStacks'
import publicAsset from 'utils/publicAsset'

const DefaultHeaderMeta = ({ stack, page, view, title: customTitle }) => {
    const { workspaceAccount } = useAppContext()
    const theme = getStackTheme(stack)
    const V4Icon = theme?.logo_icon || workspaceAccount?.options?.theme?.logo_icon
    const stackOptions = stack?.options || {}

    // if we're in a workspace app and we're not impersonating an end user
    // use the v4 icon, otherwise use the external access icon (if specified), and
    // fall back to the v4 icon.
    const workspace_icon =
        workspaceAccount && !isImpersonatingEndUser()
            ? V4Icon
            : stackOptions?.external_access?.icon || V4Icon

    let icon
    if (isEmpty(stackOptions) || stackOptions?.workspace_app) {
        icon = workspace_icon
    } else {
        icon = stackOptions?.theme?.icon
    }
    if (!icon) {
        icon = publicAsset('/static/media/stacker-logo-s-only.png')
    }

    let title = stack.name
    if (customTitle) {
        title = customTitle
    } else if (view && (view.type === 'list' || view.type === 'blankpage')) {
        // if the list is in single record display then do not show the overwritten title as they
        // cannot edit this
        if (view.options?.display === 'single_record' || view.type === 'blankpage') {
            title = view.name
        } else {
            title = view.options?.title || view.name
        }
    } else if (page) {
        title = page.options?.title || page.name
    }
    return (
        <Helmet>
            <link key={icon} rel="shortcut icon" href={icon} type="image/png" />
            <link rel="apple-touch-icon" href={icon}></link>
            <meta charSet="utf-8" />
            <title>{title}</title>
        </Helmet>
    )
}

export default React.memo(withStack(DefaultHeaderMeta))
