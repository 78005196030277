export default function fieldTypeAggregateFilter(
    aggregationType: ChartAggregationType['type'],
    field: FieldDto
) {
    switch (aggregationType) {
        case 'count':
            return true
        case 'avg':
        case 'sum':
            return ['currency', 'number', 'percentage'].includes(field.type)
        default:
            return false
    }
}
