import React, { FC, ReactNode } from 'react'

import { Box, Icon, Text } from 'v2/ui'
import { CustomRadioGroup } from 'v2/ui/components/CustomRadio'
import { Bar, Donut, LineChartBlue } from 'v2/ui/svgs'

import { DataPanel as AggregationDataPanel } from '../../../common/panels/DataPanel'

import { BarChartDataPanel } from './BarChartDataPanel'
import { DonutChartDataPanel } from './DonutChartDataPanel'
import { LineChartDataPanel } from './LineChartDataPanel'
import type { DataPanelProps } from './types'

const CHART_TYPE_OPTIONS: {
    label: string
    value: string
    icon: ReactNode
}[] = [
    {
        value: 'Pie',
        label: 'Donut chart',
        icon: <Icon svg={<Donut />} />,
    },
    {
        value: 'Bar',
        label: 'Bar chart',
        icon: <Icon svg={<Bar />} />,
    },
    {
        value: 'Line',
        label: 'Line chart',
        icon: <Icon svg={<LineChartBlue />} />,
    },
]

export const DataPanel: FC<DataPanelProps> = ({ chart, onChange, currentObject, context }) => {
    const onChangeType = (newChartType: ChartBlockAttributes['type']) => {
        onChange({ ...chart, type: newChartType })
    }

    return (
        <>
            <AggregationDataPanel
                aggregationWidget={chart}
                onChange={onChange}
                currentObject={currentObject}
                context={context}
            />
            <Box>
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="chart-style"
                    display="block"
                >
                    Style
                </Text>
                <CustomRadioGroup
                    selectedOption={chart.type}
                    options={CHART_TYPE_OPTIONS}
                    onChange={onChangeType}
                />
            </Box>
            {(chart.type === 'Pie' || !chart.type) && (
                <DonutChartDataPanel
                    chart={chart}
                    onChange={onChange}
                    currentObject={currentObject}
                    context={context}
                />
            )}
            {chart.type === 'Bar' && (
                <BarChartDataPanel
                    chart={chart}
                    onChange={onChange}
                    currentObject={currentObject}
                    context={context}
                />
            )}
            {chart.type === 'Line' && (
                <LineChartDataPanel
                    chart={chart}
                    onChange={onChange}
                    currentObject={currentObject}
                    context={context}
                />
            )}
        </>
    )
}
