import React, { FC } from 'react'

import { Icon } from 'v2/ui'

import FloatingButton, {
    FloatingContainer,
    SlidingFloatingButton,
} from '../ui/components/FloatingButton'

type Props = {
    isEditing: boolean
    saveTree: () => void
}

const EditOrSaveButton: FC<Props> = ({ isEditing, saveTree }) => {
    if (!isEditing) {
        return null
    }

    return (
        <FloatingContainer>
            <SlidingFloatingButton href="#view">
                <Icon icon="times" />
            </SlidingFloatingButton>
            <FloatingButton
                color="green"
                onClick={() => {
                    saveTree()
                    window.location.hash = '#view'
                }}
            >
                <Icon icon="check" />
            </FloatingButton>
        </FloatingContainer>
    )
}

export default EditOrSaveButton
