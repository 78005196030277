import React from 'react'

const BackgroundAlignBottom: React.FC<React.SVGProps<SVGSVGElement>> = ({
    fill = '#B6BEC5',
    ...props
}) => {
    return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m9.146 8.672-1.927 1.927-1.927-1.927M7.219 10.277V1.606M13 12.526H1.438"
                stroke={fill}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default BackgroundAlignBottom
