import React, { useEffect, useState, VFC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useDataConnection } from 'data/hooks/dataConnections'

import { Box, Input } from 'v2/ui'
import { FieldTypeDescription } from 'v2/ui/components/FieldsEditor/FieldTypeDropdown/FieldTypeDescription'
import FieldTypeSearchableSelector from 'v2/ui/components/FieldsEditor/FieldTypeSearchableSelector/FieldTypeSearchableSelector'

import { useAppContext } from '../../../app/AppContext'
import { FormField } from '../../../ui/forms/FormField'

import { fieldTypeComponentList, toFieldTypeComponentData } from './definitions/fieldTypeComponents'
import { getFieldEditionData } from './logic/availableFieldOperationUtils'

type Props = {
    field?: FieldDto | null
    object?: ObjectDto
    usePortal?: boolean
    onSubmit?: () => void
}

const FieldEditorForm: VFC<Props> = ({ field, object, usePortal, onSubmit }) => {
    const [isSelectorOpen, setIsSelectorOpen] = useState(!field)

    const { selectedStack: stack, workspaceAccount: account } = useAppContext()
    const { data: dataConnection } = useDataConnection(object?.data_connection)
    const { setValue } = useFormContext()
    const data = useWatch({})

    const fieldType = fieldTypeComponentList.find((x) => x.value === data?.selectedType)
    const ConfigurationComponent = fieldType?.configurationComponent

    const { allowedTypes: rawAllowedTypes, canChangeFieldType } =
        stack && account
            ? getFieldEditionData({
                  account,
                  dataConnection: dataConnection ?? undefined,
                  field: field ?? undefined,
                  stack,
              })
            : { allowedTypes: [], canChangeFieldType: false }

    const allowedTypes = rawAllowedTypes.map(toFieldTypeComponentData)

    // select the short text field type if it's available
    const defaultType = allowedTypes.find((x) => x.value === 'string')?.value

    useEffect(() => {
        if (field && field.type !== data.selectedType) {
            setValue('connection_options', {})
            setValue('options', {})
        }
    }, [data.selectedType, field, fieldType, setValue])

    return (
        <>
            <FormField
                name="label"
                as={Input}
                variant="settings"
                placeholder="Enter a field name"
                data-testid="add-field.name"
            />
            <FormField
                name="selectedType"
                as={FieldTypeSearchableSelector}
                supportedFieldTypes={allowedTypes}
                style={{ marginTop: '8px' }}
                controlled
                required
                errorMessages={{ required: 'Select a data type' }}
                placeholder="Select a data type"
                initialOpen={!field}
                disabled={!canChangeFieldType}
                usePortal={usePortal}
                onToggled={setIsSelectorOpen}
                onSubmit={onSubmit}
                controlledDefaultValue={defaultType}
            />
            {!isSelectorOpen && <FieldTypeDescription type={data.selectedType} mt={1} />}
            {!isSelectorOpen && ConfigurationComponent && (
                <>
                    <Box height={2} />
                    <ConfigurationComponent
                        dataConnection={dataConnection}
                        canChangeFieldType={canChangeFieldType}
                        field={field as FieldDto}
                        object={object}
                    />
                </>
            )}
        </>
    )
}

export default FieldEditorForm
