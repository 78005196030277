import React, {
    FC,
    ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { invalidatePages } from 'data/hooks/pages'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import stackerDarkTheme from 'legacy/v1/ui/styleHelpers/stackerDarkTheme'

import { TreeInstance } from '../../tree/tree'

import EditorFrame from './EditorFrame'
import EditOrSaveButton from './EditOrSaveButton'
import { DeviceType, EditorContext } from './types'
import useEditorHandler from './useEditorHandler'
import { isFocusedElementFormElement, LOCAL_STORAGE_BLOCK_KEY } from './utils'

type Props = {
    page: PageDto
    blocks: Block[]
    context: {
        deviceType: DeviceType
    }
    isEditing: boolean
    tree: any
    treeIndex: number
    noEditButton: boolean
    frameless: boolean
    hideAddPaneTitle: boolean
    menuTheme: typeof stackerDarkTheme
    showBlockSelector: boolean
    children: ({ tree, context }: { tree: Block[]; context: EditorContext }) => ReactNode
    updatePage: ({ blocks }: { blocks: Block[] }) => void
    onChange?: () => void
}

/*
    The editor provides the interface panes around the page when edit mode is on.

    It takes a variety of methods for the page (e.g. save, update) and the tree (e.g. undo, paste)
    and creates buttons which perform those actions.

    It then provides a suite of callbacks that allow the blocks to e.g. update their own properties.
*/
const Editor: FC<Props> = ({
    page,
    blocks,
    context,
    isEditing: initialIsEditing,
    tree,
    treeIndex,
    children,
    noEditButton,
    frameless,
    hideAddPaneTitle,
    menuTheme,
    showBlockSelector,
    onChange,
    updatePage,
}) => {
    const styleNodeRef = useRef<HTMLStyleElement>(document.createElement('style'))
    const secondStyleNodeRef = useRef<HTMLStyleElement>(document.createElement('style'))

    const layoutEditorContext = useContext(LayoutEditorContext)

    // Store the tree into a "Tree instance" which provides helper methods to update the tree.
    // Also pass a callback so we can request updates when the tree is mutated
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const treeInstance = useMemo(() => new TreeInstance(tree, forceUpdate), [tree])

    // Retrieve the parent block id of each tab, based on the treeIndex.
    const targetId = useMemo(
        () => treeInstance?.tree[treeIndex]?.childBlocks[0]?.id,
        [treeIndex, treeInstance?.tree]
    )

    // Retrieve the position to add the new block based on the number of blocks added to each tab
    const targetPosition = useMemo(
        () => treeInstance?.tree[treeIndex]?.childBlocks[0]?.childBlocks?.length || 0,
        [treeIndex, treeInstance?.tree]
    )

    const { state, actions } = useEditorHandler({
        styleNodeRef,
        secondStyleNodeRef,
        deviceType: context.deviceType,
        treeInstance,
        isEditing: initialIsEditing,
    })

    const saveTree = () => {
        updatePage({ blocks: treeInstance.getBlocks() })
    }

    useHotkeys('alt+up', actions.moveBlockUp)
    useHotkeys('alt+down', actions.moveBlockDown)
    useHotkeys('ctrl+z, meta+z', actions.undo)
    useHotkeys('ctrl+shift+s, meta+shift+s', saveTree)
    useHotkeys('ctrl+shift+f, meta+shift+f', actions.formatPaintFromSelected)
    useHotkeys('ctrl+x, meta+x', () => {
        if (isFocusedElementFormElement()) {
            return
        }

        actions.cutSelected()
        // If we do not wait for the next frame with the updated state,
        // the editor closes before the block being deleted
        requestAnimationFrame(() => {
            layoutEditorContext.closeEditor()
        })
    })
    useHotkeys('ctrl+c, meta+c', () => {
        if (isFocusedElementFormElement()) {
            return
        }

        actions.copySelected()
    })
    useHotkeys('ctrl+v, meta+v', () => {
        if (isFocusedElementFormElement()) {
            return
        }

        actions.pasteAtSelected()
    })

    useEffect(() => {
        if (state.isEditing) {
            // Get the lastest version of the page/block to prevent overwrites
            invalidatePages()
        }
    }, [state.isEditing])

    useEffect(() => {
        if (onChange && treeInstance) {
            treeInstance.subscribe(onChange)
            forceUpdate()
        }
    }, [onChange, page?._sid, treeInstance])

    useLayoutEffect(() => {
        const styleNode = styleNodeRef.current
        const secondStyleNode = secondStyleNodeRef.current

        document.body.appendChild(styleNode)
        document.body.appendChild(secondStyleNode)

        return () => {
            document.body.removeChild(styleNode)
            document.body.removeChild(secondStyleNode)

            // This clears the clipbord to prevent pasting into different objects
            localStorage.removeItem(LOCAL_STORAGE_BLOCK_KEY)
        }
    }, [])

    return (
        <>
            <EditorFrame
                blocks={blocks}
                frameless={frameless}
                hideTitle={hideAddPaneTitle}
                isEditing={state.isEditing}
                isFormatPainting={state.isFormatPainting}
                menuTheme={menuTheme}
                showBlockSelector={showBlockSelector}
                targetId={targetId}
                targetPosition={targetPosition}
                onClick={actions.onClick}
                onDrop={actions.onDrop}
            >
                {children({
                    tree: treeInstance.tree,
                    context: {
                        ...context,
                        treeInstance,
                        deviceType: state.deviceType,
                        editor: {
                            ...state,
                            actions: {
                                ...actions,
                                updatePage,
                            },
                        },
                    },
                })}
            </EditorFrame>
            {!noEditButton && <EditOrSaveButton isEditing={state.isEditing} saveTree={saveTree} />}
        </>
    )
}

export default Editor
