export function isStackerDataRichTextField(field: FieldDto | undefined) {
    return field?.options?.render_variant === 'richText'
}

export function isAirtableRichTextField(field: Partial<FieldDto> | undefined) {
    return field?.connection_options?.airtable_column_type === 'richText'
}

export default function isRichTextField(field?: FieldDto) {
    return isStackerDataRichTextField(field) || isAirtableRichTextField(field)
}
