import React from 'react'

import ViewHeaderEditor from './ViewHeaderEditor'

type BlankPageHeaderEditorProps = {
    onChange: (patch: Partial<ViewDto>) => void
    view?: ViewDto
}

export const BlankPageHeaderEditor: React.FC<BlankPageHeaderEditorProps> = ({ onChange, view }) => {
    const pageHeader = view?.options.pageHeader

    const handleOnChange = (patch: Partial<PageHeader>) => {
        onChange({
            options: {
                ...(view?.options as ViewDto['options']),
                pageHeader: {
                    ...pageHeader,
                    ...patch,
                } as PageHeaderDefault | PageHeaderImage,
            },
        })
    }

    return <ViewHeaderEditor onChange={handleOnChange} pageHeader={pageHeader} isBlankPage />
}
