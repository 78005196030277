import React, { useCallback, useMemo, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useFeatures } from 'data/hooks/features'
import { useSetDataSyncRequired } from 'features/admin/fields/useSetDataSyncRequired'
import AppSettingsModalDataActions from 'features/AppSettings/Data/AppSettingsModalDataActions'
import AppSettingsModalDataFields from 'features/AppSettings/Data/AppSettingsModalDataFields'
import AppSettingsModalDataLayouts from 'features/AppSettings/Data/AppSettingsModalDataLayouts'
import AppSettingsModalDataPermissions from 'features/AppSettings/Data/AppSettingsModalDataPermissions'
import AppSettingsModalDataSettings from 'features/AppSettings/Data/AppSettingsModalDataSettings'

import SimpleModal from 'v2/ui/components/SimpleModal'

type ModalData = { title: string; contents: React.ReactNode; width?: string }

type UseToolbarActionsProps = {
    object: ObjectDto
    onModalToggled?: (isOpen: boolean) => void
}

export const useToolbarActions = ({ object, onModalToggled }: UseToolbarActionsProps) => {
    const [modalContents, setModalContents] = useState<ModalData | undefined>()
    const { selectedStack } = useAppContext()
    const objectId = object?._sid
    const { data: features } = useFeatures()
    const [dataSyncRequired, setDataSyncRequired] = useState(false)
    const triggerDataSync = useSetDataSyncRequired(objectId)

    const feature = useMemo(
        () => objectId && features?.find(({ options: { object_id } }) => objectId === object_id),
        [objectId, features]
    )

    const isOpen = !!modalContents

    const componentProps = useMemo(
        () => ({
            objectId: object?._sid,
            object,
            stack: selectedStack,
            stackId: selectedStack?._sid,
            feature,
        }),
        [feature, object, selectedStack]
    )

    const showSettings = useCallback(() => {
        setModalContents({
            title: 'Settings',
            contents: <AppSettingsModalDataSettings noPadding {...componentProps} />,
        })
        onModalToggled?.(true)
    }, [componentProps, onModalToggled])

    const showFields = useCallback(() => {
        setModalContents({
            title: 'Manage Fields',
            contents: (
                <AppSettingsModalDataFields
                    setDataSyncRequired={() => setDataSyncRequired(true)}
                    noPadding
                    {...componentProps}
                />
            ),
        })
        onModalToggled?.(true)
    }, [componentProps, onModalToggled])

    const showPermissions = useCallback(() => {
        setModalContents({
            title: 'Permissions',
            contents: <AppSettingsModalDataPermissions noPadding {...componentProps} />,
        })
        onModalToggled?.(true)
    }, [componentProps, onModalToggled])

    const showActions = useCallback(() => {
        setModalContents({
            title: 'Actions',
            contents: <AppSettingsModalDataActions noPadding {...componentProps} />,
            width: '600px',
        })
        onModalToggled?.(true)
    }, [componentProps, onModalToggled])

    const showLayouts = useCallback(() => {
        setModalContents({
            title: 'Layouts',
            contents: (
                <AppSettingsModalDataLayouts
                    noPadding
                    {...componentProps}
                    onCloseSettingsModal={() => {
                        setModalContents(undefined)
                    }}
                />
            ),
        })
        onModalToggled?.(true)
    }, [componentProps, onModalToggled])

    const onClose = useCallback(async () => {
        if (dataSyncRequired) {
            await triggerDataSync()
            setDataSyncRequired(false)
        }
        setModalContents(undefined)
        onModalToggled?.(false)
    }, [dataSyncRequired, onModalToggled, triggerDataSync])

    const toolbarModals = useMemo(
        () => (
            <>
                <SimpleModal
                    title={modalContents?.title}
                    onClose={onClose}
                    open={isOpen}
                    showCloseButton
                    width={modalContents?.width || '800px'}
                    height="500"
                    style={{ maxHeight: '90%' }}
                >
                    {modalContents?.contents}
                </SimpleModal>
            </>
        ),
        [isOpen, modalContents?.contents, modalContents?.title, modalContents?.width, onClose]
    )

    return useMemo(
        () => ({
            toolbarModals,
            showSettings,
            showFields,
            showPermissions,
            showActions,
            showLayouts,
            setDataSyncRequired,
        }),
        [
            showActions,
            showFields,
            showLayouts,
            showPermissions,
            showSettings,
            toolbarModals,
            setDataSyncRequired,
        ]
    )
}
