import React, { useRef, useState } from 'react'
export const LayoutEditorContext = React.createContext<layoutContext>({} as layoutContext)
export const BlockSelectorPortalContext = React.createContext(null)

type activeEditorType = {
    onClose?: () => void
    title?: string
    id?: string
}

type buttonType = {
    onClick?: () => void
    label?: string
    isDirty?: boolean
}

type setActiveEditorType = (editor: activeEditorType | null, f?: (() => void) | null) => void
type setButtonType = (label: string, isDirty: boolean, onClick?: () => void) => void
type clearButtonType = () => void
type closeEditorType = (closeOverride?: boolean) => void
type setcloseHandlerType = (f: (() => void) | null | undefined) => void
type setPortalType = (node: HTMLElement | null, name?: string) => void
type unsetPortalType = (id: string) => void
type setActiveEditorTitleType = (title: string) => void
type setSelectedWidgetType = (widgetId: string) => void
type clearSelectedWidgetType = () => void

type layoutContext = {
    contextVersion: number
    activeEditor: activeEditorType | null
    button: buttonType | null
    setActiveEditor: setActiveEditorType
    setButton: setButtonType
    clearButton: clearButtonType
    closeEditor: closeEditorType
    setcloseHandler: setcloseHandlerType
    setPortal: setPortalType
    unsetPortal: unsetPortalType
    setActiveEditorTitle: setActiveEditorTitleType
    selectedWidgetId: string | null
    setSelectedWidget: setSelectedWidgetType
    clearSelectedWidget: clearSelectedWidgetType
    updateContextVersion: () => void
    [key: string]: any
}

export const LayoutEditorContextProvider: React.FC = ({ children }) => {
    const closeHandler = useRef<(() => void) | null>()

    const updateContextVersion = () =>
        setState((s) => ({ ...s, contextVersion: s.contextVersion + 1 }))

    const setActiveEditor: setActiveEditorType = (editor, newCloseHandler) => {
        setState((x: layoutContext) => {
            // If we have an active editor, reset
            // our back-handler if any
            // and call onClose() on our current editor
            if (x.activeEditor && x.activeEditor.onClose) {
                closeHandler.current = newCloseHandler
                x.activeEditor.onClose()
            }

            let selectedWidgetId = x.selectedWidgetId

            // Clear the selected widget if it's for a different editor
            if (x.selectedWidgetId && (!editor?.id || !x.selectedWidgetId.startsWith(editor.id))) {
                selectedWidgetId = null
            }

            return { ...x, ...{ activeEditor: editor, selectedWidgetId } }
        })
    }

    const setButton: setButtonType = (label, isDirty, onClick) => {
        setState((x: layoutContext) => ({ ...x, button: { label, isDirty, onClick } }))
    }

    const clearButton: clearButtonType = () => {
        setState((x: layoutContext) => ({ ...x, button: null }))
    }

    const closeEditor: closeEditorType = (allowCloseOverride) => {
        if (allowCloseOverride && closeHandler.current) {
            closeHandler.current()
        } else {
            setActiveEditor(null)
            clearButton()
            clearButton()
        }

        clearSelectedWidget()
    }

    const setcloseHandler: setcloseHandlerType = (f) => {
        closeHandler.current = f
    }

    const setPortal: setPortalType = (node, name) => {
        // Cleans up the portal when the component is unmounted
        if (!node && name) {
            return unsetPortal(name)
        }
        if (!node) return
        let key = name || node.id
        setState((x: layoutContext) => ({ ...x, ...{ [key]: node } }))
    }

    const unsetPortal: unsetPortalType = (id) => {
        setState((x: layoutContext) => {
            if (!x[id]) return x
            const newState = { ...x }
            delete newState[id]
            return newState
        })
    }

    const setActiveEditorTitle: setActiveEditorTitleType = (title) => {
        setState((x: layoutContext) => {
            return { ...x, ...{ activeEditor: { ...x.activeEditor, title } } }
        })
    }

    const setSelectedWidget = (widgetId: string) => {
        setState((prevState) => ({ ...prevState, selectedWidgetId: widgetId }))
    }

    const clearSelectedWidget = () => {
        setState((prevState) => ({ ...prevState, selectedWidgetId: null }))
    }

    const [state, setState] = useState<layoutContext>({
        contextVersion: 0,
        activeEditor: null,
        button: null,
        selectedWidgetId: null,
        setPortal,
        setButton,
        clearButton,
        setActiveEditor,
        closeEditor,
        setcloseHandler,
        setActiveEditorTitle,
        setSelectedWidget,
        clearSelectedWidget,
        unsetPortal,
        updateContextVersion,
    })

    return <LayoutEditorContext.Provider value={state}>{children}</LayoutEditorContext.Provider>
}
