import React, { useCallback } from 'react'

import { LinkList } from './LinkList'
import { LinkListItem } from './LinkListItem'

export const LinkListView = ({
    actions,
    links,
    onEdit = () => undefined,
}: {
    actions: LinkCollectionActions
    links: LinkView[]
    onEdit: (link: LinkView) => void
}) => {
    const handleDeleteClick = useCallback(
        (link) => {
            actions.deleteLink(link.linkId)
        },
        [actions]
    )
    return (
        <LinkList>
            {links.map((link) => (
                <LinkListItem
                    key={link.linkId}
                    link={link}
                    onEdit={onEdit.bind(null, link)}
                    onDelete={handleDeleteClick.bind(null, link)}
                />
            ))}
        </LinkList>
    )
}
