import React from 'react'

import { Menu, MenuButton, MenuDivider, MenuGroup, MenuList } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import settings from 'app/settings'

import { Button, Icon, MenuItem, Text } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

const HelpMenu = ({ as, style, children, ...props }) => {
    const { selectedStack } = useAppContext()
    const isV3App = !selectedStack?.options?.workspace_app
    const isMobile = useIsMobile()

    const readDocLink = 'https://support.stackerhq.com/'

    const menu = (
        <>
            <MenuGroup
                title={
                    <Text flexGrow={1} color="gray.400">
                        Learn
                    </Text>
                }
            >
                {!isV3App && !isMobile && (
                    <MenuItem
                        as="a"
                        href="#"
                        icon="rocket"
                        onClick={() => {
                            if (window.userflow)
                                window.userflow.start(settings.USERFLOW_CHECKLIST_KEY)
                        }}
                    >
                        Getting started checklist
                    </MenuItem>
                )}

                <MenuItem as="a" href={readDocLink} icon="bookOpen" target="_blank">
                    Read documentation
                </MenuItem>
                <MenuItem
                    as="a"
                    icon="play"
                    href="https://www.stackerhq.com/help-categories/tutorials"
                    target="_blank"
                >
                    Watch video tutorials
                </MenuItem>
                <MenuItem
                    as="a"
                    icon="megaphone"
                    href="https://feedback.stackerhq.com/changelog"
                    target="_blank"
                >
                    What&apos;s new?
                </MenuItem>
            </MenuGroup>

            <MenuDivider color="gray.300" />

            <MenuGroup
                title={
                    <Text flexGrow={1} color="gray.400">
                        Community
                    </Text>
                }
            >
                <MenuItem
                    as="a"
                    icon="lightbulb"
                    href="https://feedback.stackerhq.com/feature-requests"
                    target="_blank"
                >
                    Request a feature
                </MenuItem>

                <MenuItem
                    as="a"
                    href="https://community.stackerhq.com/"
                    icon="users"
                    target="_blank"
                >
                    Ask the community
                </MenuItem>
            </MenuGroup>

            <MenuDivider color="gray.300" />

            <MenuItem
                as="a"
                icon="comment"
                href="#"
                className="help-menu-open-intercom"
                onClick={() => {
                    window.Intercom('showNewMessage')
                }}
            >
                Contact Us
            </MenuItem>
        </>
    )

    return (
        <Menu>
            <MenuButton
                as={as || Button}
                {...props}
                style={{
                    ...style,
                }}
                variant="help"
            >
                {children || <Icon size="16" icon="question" />}
            </MenuButton>
            <MenuList
                style={{
                    boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.25)',
                    marginRight: '20px',
                    marginBottom: '10px',
                }}
            >
                {menu}
            </MenuList>
        </Menu>
    )
}

export default HelpMenu
