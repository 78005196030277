// @ts-strict-ignore
import React, { useRef, useState } from 'react'

import { ButtonProps } from '@chakra-ui/react'

import { useObject } from 'data/hooks/objects'
import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'

import { Button } from 'v2/ui'

import { FieldEditorProps } from './FieldEditor'

type Props = FieldEditorProps &
    Omit<ButtonProps, 'children' | 'variant'> & {
        as?: React.ElementType
        onSuccess?: (field: any) => Promise<(dispatch: any) => any>
        usePortal?: boolean
        className?: string
        variant?: string
        icon?: any
        children?: React.ReactNode
        'data-testid'?: string
    }

export const FieldEditorPopoverButton: React.VFC<Props> = ({
    as,
    field,
    objectId,
    onSuccess,
    className,
    children,
    icon = 'add',
    ...props
}) => {
    const [isOpen, setIsOpen] = useState<boolean>()

    const { fetch: _fetchObjects } = useObject(objectId)
    const targetRef = useRef()

    const handleAddField = (e): void => {
        setIsOpen(!isOpen)
        e.preventDefault()
        e.stopPropagation()
    }

    const onClose = () => {
        setIsOpen(false)
    }

    const handleFieldCreated = async (newField: FieldDto): Promise<void> => {
        await _fetchObjects()
        await onSuccess?.(newField)
        setIsOpen(false)
    }

    const ButtonComponent = as || Button
    return (
        <>
            <ButtonComponent
                ref={targetRef}
                onClick={handleAddField}
                style={{
                    height: 30,
                }}
                variant="Primary"
                p={2}
                className={className}
                size="small"
                icon={icon}
                {...props}
            >
                {children}
            </ButtonComponent>
            <FieldEditorPopover
                field={field}
                objectId={objectId}
                open={isOpen}
                target={targetRef.current}
                onSuccess={handleFieldCreated}
                onCancel={onClose}
                onClose={onClose}
                placement="bottom-start"
            />
        </>
    )
}

export default FieldEditorPopoverButton
