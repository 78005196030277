import React, { useEffect } from 'react'

import AppContext from 'app/AppContext'
import settings from 'app/settings'
import { useUpdateAccount } from 'data/hooks/accounts'
import Frame from 'features/core/Frame'
import { isSubscriptionCancelled } from 'features/workspace/stackerUserUtils'
// @ts-ignore
import { Card, Container, SectionHeader } from 'legacy/v1/ui'

import AttributeList from 'v2/ui/components/Attribute/AttributeList'

import { AutomaticAuth0Settings } from './AutomaticAuth0Settings'
import {
    additionalAccountSettings,
    experimentalFeatures,
    mainAccountSettings,
    premiumFeatures,
} from './config'
import { MigrateAccountToV4Button } from './MigrateAccountButton'

const AccountSettings = ({}) => {
    // @ts-ignore
    const { workspaceAccount }: { workspaceAccount: Account } = React.useContext(AppContext)
    const { mutate: updateAccount } = useUpdateAccount()

    useEffect(() => {
        if (workspaceAccount) {
            if (
                isSubscriptionCancelled(workspaceAccount) &&
                !mainAccountSettings.find((api) => api.api_name === 'subscription_end_date')
            ) {
                mainAccountSettings.push({
                    api_name: 'subscription_end_date',
                    label: 'Subscription End Date',
                    type: 'datetime',
                })
            }
        }
    }, [workspaceAccount])

    if (!localStorage.getItem('support_login') && settings.IS_PROD)
        return (
            <Frame background="#2a333c">
                =
                <SectionHeader style={{ color: '#e9e9ea' }}>
                    You are not authorized to view this page.
                </SectionHeader>
            </Frame>
        )

    return (
        <Frame background="#2a333c">
            =<SectionHeader style={{ color: '#e9e9ea' }}>Account Settings</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount}
                        onChange={(vals) =>
                            updateAccount({ id: workspaceAccount._sid, patch: { ...vals } })
                        }
                        fields={mainAccountSettings}
                    />
                    {workspaceAccount?.options?.migrated_from_v3 && (
                        <MigrateAccountToV4Button rollback />
                    )}
                    <AttributeList
                        editable
                        data={workspaceAccount.options}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: { options: { ...workspaceAccount.options, ...vals } },
                            })
                        }
                        fields={additionalAccountSettings}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>Account Premium Features</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount.optional_features}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: {
                                    optional_features: {
                                        ...workspaceAccount.optional_features,
                                        ...vals,
                                    },
                                },
                            })
                        }
                        fields={premiumFeatures}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>Experimental Features</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount.optional_features}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: {
                                    optional_features: {
                                        ...workspaceAccount.optional_features,
                                        ...vals,
                                    },
                                },
                            })
                        }
                        fields={experimentalFeatures}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>Legacy V3 Settings</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount}
                        onChange={(vals) =>
                            updateAccount({ id: workspaceAccount._sid, patch: { ...vals } })
                        }
                        fields={[
                            {
                                api_name: 'team_plan',
                                label: 'Enable Team Plan',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                        ]}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>V4 template settings</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount.options}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: { options: { ...workspaceAccount.options, ...vals } },
                            })
                        }
                        fields={[
                            {
                                api_name: 'permit_template_authoring',
                                label: 'Allow Apps in this account to appear in the template library',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                        ]}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>Email settings</SectionHeader>
            <Card style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount.options}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: { options: { ...workspaceAccount.options, ...vals } },
                            })
                        }
                        fields={[
                            {
                                api_name: 'default_from_email',
                                label: 'Default from email',
                                type: 'text',
                            },
                        ]}
                    />
                </Container>
            </Card>
            <SectionHeader style={{ color: '#e9e9ea' }}>Auth0 Settings</SectionHeader>
            <div style={{ marginTop: 16, marginBottom: 16, color: '#e9e9ea', fontSize: 13 }}>
                Warning: before touching these settings please ensure you&apos;re fully aware of the
                process of setting up and configuring Auth0 for a customer. It involves manual steps
                in the Auth0 dashboard. Changing these settings *without* also changing the config
                in Auth0 as appropriate will very likely break a customer&apos;s workspace.
            </div>
            <AutomaticAuth0Settings workspaceAccount={workspaceAccount} />
            <div style={{ color: '#e9e9ea', fontSize: 15 }}>MANUAL CONTROLS</div>
            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                <Container wrapItems>
                    <AttributeList
                        editable
                        data={workspaceAccount.optional_features}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: {
                                    optional_features: {
                                        ...workspaceAccount.optional_features,
                                        ...vals,
                                    },
                                },
                            })
                        }
                        fields={[
                            {
                                api_name: 'auth0_enabled',
                                label: 'Enable Auth0',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                            {
                                api_name: 'auth0_open_signup_enabled',
                                label: 'Enable Auth0 open signup',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                            {
                                api_name: 'auth0_minimum_access_level',
                                label: 'Minimum Access Level',
                                type: 'dropdown',
                                options: {
                                    options: [
                                        { label: 'Member', value: 'member' },
                                        { label: 'Guest', value: 'guest' },
                                        { label: 'Owner', value: 'owner' },
                                    ],
                                },
                            },
                            {
                                api_name: 'auth0_show_login_page',
                                label: 'Show Login Page',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                            {
                                api_name: 'disable_api_tokens',
                                label: 'Disable API tokens',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                        ]}
                    />
                    <AttributeList
                        editable
                        data={workspaceAccount}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: {
                                    ...vals,
                                },
                            })
                        }
                        fields={[
                            {
                                api_name: 'supertokens_sso_enabled',
                                label: 'Use Supertokens SSO',
                                type: 'checkbox',
                                options: { is_toggle: true },
                            },
                        ]}
                    />
                </Container>
            </Card>
        </Frame>
    )
}

export default AccountSettings
