import { getCachedField } from 'data/hooks/fields'
import { getCachedObject } from 'data/hooks/objects/objectOperations'

import { FieldPatch } from '../common'

export const getRollupDynamicFieldLabel = (field: FieldPatch): string | undefined => {
    const LinkedObjectSid = field?.connection_options?.rollup_target_object
    if (!LinkedObjectSid) return
    const linkedObjectName = getCachedObject(LinkedObjectSid)?.name
    if (!linkedObjectName) return

    let rollupTargetLabel = ''
    if (field?.connection_options?.rollup_operator === 'count') {
        rollupTargetLabel = 'Count'
    } else {
        const rollupFieldSid = field?.connection_options?.rollup_field
        if (!rollupFieldSid) return
        const rollupField = getCachedField(rollupFieldSid)
        if (!rollupField) return

        rollupTargetLabel = rollupField?.label
        // This should never happen, but just in case, it'll return an error
        if (!rollupTargetLabel) return
    }
    return `${rollupTargetLabel} (from ${linkedObjectName})`
}
