import React from 'react'

import { SettingsContent, SettingsWrapper } from 'features/admin/settings/ui/SettingsFrame'
import Frame from 'features/core/Frame'
import { Heading, Section } from 'legacy/v1/ui'

const NoAdminRightsWarningPage = () => (
    <Frame header={false}>
        <SettingsWrapper>
            <SettingsContent style={{ margin: '20px 0' }}>
                <Section style={{ alignItems: 'center', width: '100%' }}>
                    <Heading size="medium">
                        <span style={{ marginRight: 16 }}>🔒</span> Admin permissions are required
                        to view this page
                    </Heading>

                    <Heading size="small" style={{ marginTop: 24, color: 'rgba(0,0,0,0.6)' }}>
                        Please contact the app administrator if you need access to this page
                    </Heading>
                </Section>
            </SettingsContent>
        </SettingsWrapper>
    </Frame>
)

export default NoAdminRightsWarningPage
