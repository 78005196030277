import React from 'react'

import { InlineFilterInput } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterInput'

import { InlineFilterInputComponent } from './types'

type TextInputProps = {}

export const TextInput: InlineFilterInputComponent<TextInputProps> = ({ value = '', onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value)
    }
    return <InlineFilterInput placeholder="Enter a value" value={value} onChange={handleChange} />
}
