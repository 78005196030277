import React from 'react'

import Icon from './Icon'

const CircleIconButton = ({ onClick, icon, ...props }) => {
    return (
        <Icon
            icon={icon}
            button
            onClick={onClick}
            fontSize="16px"
            width="24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="24px"
            color="white"
            bg="rgba(0,0,0,.8)"
            _hover={{ bg: 'rgba(100,100,100,.8)' }}
            {...props}
        />
    )
}

export default React.memo(CircleIconButton)
