/* Code Quality: OK */

import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { getCollection } from 'data/githubFeatures'
import { withStack } from 'data/wrappers/WithStacks'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import ThemeWrapper from 'features/pages/blocks/styles/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import { Heading, Section } from 'legacy/v1/ui'

import { Button, Input, Text } from 'v2/ui'

import { CenterPanel, CenterPanelWrapper } from '../studio/ui/Layout'

import { PasswordApi, setFeatureLogo } from './utils/index'

const STATUS_NEW = 'STATUS_NEW'
const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS'
const STATUS_SUBMITTED = 'STATUS_SUBMITTED'
const STATUS_REJECTED = 'STATUS_REJECTED'

class UserForgottenPasswordPage extends React.Component {
    state = { title: 'Stacker', logo: '', icon: '' }

    constructor(props) {
        super(props)
        this.state.status = STATUS_NEW
    }

    UNSAFE_componentWillMount() {
        const query = queryString.parse(this.props.location.search.slice(1)) // Slice to remove the "?"
        this.setState({ query })
        const collection = getCollection()

        localStorage.removeItem(`feature_config:${collection}`) // First step, we remove the feature_config item
        setFeatureLogo(this, collection) // Save the logo and the title in the state
    }

    reset = (e) => {
        e.preventDefault()
        const { email } = this.state
        this.setState({ status: STATUS_IN_PROGRESS }, () => {
            PasswordApi.requestEndUserPasswordReset(email).then((response) => {
                if (response.status >= 400) this.setState({ status: STATUS_REJECTED })
                else this.setState({ status: STATUS_SUBMITTED })
            })
        })
    }

    render() {
        if (!this.props.stack._sid) return null
        const logo = getLogoUrl(this.props.stack, true)

        return (
            <>
                <DefaultHeaderMeta title="Reset your password" />
                <ThemeWrapper loadFromStack>
                    {() => (
                        <CenterPanelWrapper>
                            <CenterPanel>
                                <Section margin="large" padding="none" centered>
                                    <Logo src={logo} />
                                </Section>
                                {this.state.status !== STATUS_SUBMITTED && (
                                    <form>
                                        <Section margin="medium" padding="none" centered>
                                            <Heading size="medium" margin="none">
                                                Reset your password
                                            </Heading>
                                        </Section>
                                        <Section margin="medium" padding="none">
                                            <Input
                                                onChange={(e) =>
                                                    this.setState({ email: e.target.value })
                                                }
                                                padding="medium"
                                                placeholder="What email address did you register with?"
                                                defaultValue={this.state.query.email}
                                            />
                                        </Section>
                                        <Button
                                            onClick={this.reset}
                                            width="100%"
                                            variant="primary"
                                            buttonSize="md"
                                            type="submit"
                                            isDisabled={this.state.status === STATUS_IN_PROGRESS}
                                        >
                                            {this.state.status === STATUS_IN_PROGRESS
                                                ? 'Resetting your password'
                                                : 'Reset password'}
                                        </Button>
                                        {this.state.status === STATUS_REJECTED && (
                                            <Text size="medium" mt={4}>
                                                We weren&apos;t able to send you a new password.
                                                Please check that you&apos;ve already{' '}
                                                <Link to={getUrl(Urls.Register)}>registered</Link>{' '}
                                                for the service.
                                            </Text>
                                        )}
                                        <Section
                                            style={{ alignItems: 'center' }}
                                            margin="none"
                                        ></Section>
                                    </form>
                                )}
                                {this.state.status === STATUS_SUBMITTED && (
                                    <Section margin="medium" padding="none" centered>
                                        <Text
                                            centered
                                            style={{ fontSize: '1.2em', lineHeight: '1.4em' }}
                                        >
                                            <strong>We&apos;ve sent you an email.</strong> <br />
                                            Click on the link in it to continue.
                                        </Text>
                                    </Section>
                                )}
                            </CenterPanel>
                        </CenterPanelWrapper>
                    )}
                </ThemeWrapper>
            </>
        )
    }
}

UserForgottenPasswordPage.propTypes = {
    location: PropTypes.object.isRequired, // from WithRouter
    stackOptions: PropTypes.object.isRequired, // from withStack
    stack: PropTypes.object.isRequired, // from withStack
}

const Logo = styled('img')`
    height: 60px;
    object-fit: contain;
    max-width: 90%;

    @media (max-width: 420px) {
        height: 35px;
    }
`

export default withStack(withRouter(UserForgottenPasswordPage))
