import React, { memo } from 'react'

import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import { layouts as layoutSettings } from 'v2/ui/utils/attributeSettings'

import { useFeatures } from '../../../../data/hooks/features'
import { useObjects } from '../../../../data/hooks/objects'

import DisplayText from './DisplayText'
import { getLinkComponentFromUrl } from './link_utils'

type UserRefAttributeProps = {
    children: React.ReactChildren
    layout: keyof typeof layoutSettings
}

export const UserRefAttribute = memo(
    ({ children, layout }: UserRefAttributeProps): React.ReactElement => {
        const { data: objects = [] } = useObjects()
        const { data: features } = useFeatures() as { data: FeatureDto[] }

        const value: UserRefDto | undefined = children as any

        const isByStackerSystem = !!value && !!value.by_stacker_system
        const hasUserListAndNoUserListUserSid =
            !!value &&
            value.has_user_list &&
            (!value.user_list_api_name || !value.user_list_user_sid)
        const hasNoUserListAndNoStackerUser = !!value && !value.has_user_list && !value.sid
        const shouldRenderTextOnly =
            isByStackerSystem || hasUserListAndNoUserListUserSid || hasNoUserListAndNoStackerUser

        if (!value || shouldRenderTextOnly) {
            return <DisplayText>{value ? value.name : '-'}</DisplayText>
        }

        const object = value.has_user_list
            ? objects.find((object) => object.api_name === value.user_list_api_name)
            : objects.find((object) => getIsStackerUserObject(object))

        const feature = features.find((f) => f._sid === object?.feature_id)
        const featureUrl = feature?.url
        const sid = value.has_user_list ? value.user_list_user_sid : value.sid
        const url = `${featureUrl}/view/${sid}`

        return getLinkComponentFromUrl({ display: value.name, url, layout })
    }
)
