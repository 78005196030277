import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { useOAuthTokens, useOAuthTokenValidate } from 'data/hooks/oauthTokens'

import { getReturnUrlForOAuthFlow } from './utils/getReturnUrlForOAuthFlow'
import { useAirtableConnectionParams } from './utils/useAirtableConnectionParams'
import {
    AirtableUpgradeResult,
    useAirtableUpgradeMutation,
} from './utils/useAirtableUpgradeMutation'
import { InitialAirtableUpgradeModal } from './InitialAirtableUpgradeModal'
import { IsMigratingModal } from './IsMigratingModal'
import { UpgradeFailureModal } from './UpgradeFailureModal'
import { UpgradeFailureWithoutPermissionsModal } from './UpgradeFailureWithoutPermissionsModal'
import { UpgradeSuccessfulModal } from './UpgradeSuccessfulModal'
import { useOAuthFlow } from './useOAuthFlow'

export type UseUpgradeFlowResult = {
    onStart: () => void

    modal: ReactElement
}

export type UpgradeFlowParams = {
    /**
     * The data connection we want to start upgrading.
     */
    dataConnectionSid?: string

    /**
     * If the flow should continue automatically for a user coming back from the oauth flow.
     */
    continueFlow?: boolean
}

/**
 * Used for managing the Airtable upgrade flow.
 * @param params
 */
export const useUpgradeFlow = (params: UpgradeFlowParams): UseUpgradeFlowResult => {
    const {
        upgradingAirtableV1,
        dataConnectionSid: upgradingDataConnectionSid,
        resetParams,
    } = useAirtableConnectionParams()
    const { data: dataConnection } = useDataConnection(
        params.dataConnectionSid || upgradingDataConnectionSid
    )
    const { data: oauthTokens, isLoading: isLoadingTokens } = useOAuthTokens()
    const { selectedStack } = useAppContext()
    const [isStarted, setIsStarted] = useState(false)
    const airtableToken = useMemo(
        () => oauthTokens?.find((t) => t.provider_name == 'airtable'),
        [oauthTokens]
    )
    const { data: isTokenValid, isLoading: isValidatingToken } = useOAuthTokenValidate(
        airtableToken?._sid
    )
    const upgradeTried = useRef(false)

    const returnUrl =
        selectedStack && dataConnection
            ? getReturnUrlForOAuthFlow({ dataConnection, mode: 'upgrade', stack: selectedStack })
            : undefined

    const {
        mutate: doUpgrade,
        data: upgradeResult,
        isLoading,
        reset,
    } = useAirtableUpgradeMutation(upgradingDataConnectionSid || params.dataConnectionSid)

    const cancelUpgrade = useCallback(() => {
        resetParams()
    }, [resetParams])

    const onClose = useCallback(() => {
        reset() // reset the mutation to show the correct modals
        setIsStarted(false)
        cancelUpgrade()
    }, [reset, cancelUpgrade])

    const {
        onStart: onStartOAuthFlow,
        isOpen: isOAuthFlowOpened,
        modal: oauthModal,
    } = useOAuthFlow({
        returnUrl,
        dataConnectionSid: dataConnection?._sid,
        shouldOverwriteToken: true,
        onCancel: onClose,
    })

    const onStart = useCallback(() => {
        setIsStarted(true)
    }, [setIsStarted])

    const onTryAgain = useCallback(() => {
        reset() // reset the mutation to show the correct modals
        doUpgrade()
    }, [doUpgrade, reset])

    const onClickUpgrade = useCallback(async () => {
        if (!isTokenValid) {
            onStartOAuthFlow()
        } else {
            doUpgrade()
        }
    }, [isTokenValid, onStartOAuthFlow, doUpgrade])

    useEffect(() => {
        // This is triggered when user comes back from the oauth flow and we need to try upgrading
        // we should start the flow automatically and try upgrading the data connection (only once)
        if (
            upgradingDataConnectionSid &&
            upgradingAirtableV1 &&
            params.continueFlow &&
            !upgradeTried.current
        ) {
            setIsStarted(true)
            upgradeTried.current = true
            doUpgrade()
        }
    }, [doUpgrade, upgradingDataConnectionSid, upgradingAirtableV1, params])

    useEffect(() => {
        if (!upgradeResult?.isSuccess && upgradeResult?.error === 'invalidToken') {
            onStartOAuthFlow()
        }
    }, [upgradeResult, onStartOAuthFlow])

    const modal = (
        <>
            {oauthModal}
            {_getUpgradeFlowStep({
                isOAuthFlowOpened,
                isLoading,
                isStarted,
                upgradeResult,
                onClose,
                onTryAgain,
                // right now the first step when upgrade is clicked is to actually go through the oauth flow
                upgradeDisabled: isLoadingTokens || isValidatingToken,
                onClickUpgrade,
            })}
        </>
    )

    return {
        modal,
        onStart,
    }
}

export const _getUpgradeFlowStep = (params: {
    isOAuthFlowOpened?: boolean
    isLoading?: boolean
    isStarted?: boolean
    upgradeResult?: AirtableUpgradeResult
    upgradeDisabled?: boolean
    onClose: () => void
    onTryAgain: () => void
    onClickUpgrade: () => void
}): ReactElement | null => {
    if (params.isOAuthFlowOpened) {
        return null
    }

    if (params.isLoading) {
        return <IsMigratingModal isOpen onClose={params.onClose} />
    } else if (params.upgradeResult) {
        const { isSuccess, error } = params.upgradeResult
        if (isSuccess) {
            return <UpgradeSuccessfulModal isOpen onClose={params.onClose} />
        } else if (error === 'noPermissions') {
            // When upgrade fails with noPermissions that means we can instruct the user to fix the token and try again
            return (
                <UpgradeFailureWithoutPermissionsModal
                    isOpen
                    onClose={params.onClose}
                    onTryAgain={params.onTryAgain}
                />
            )
        } else if (error === 'invalidToken') {
            return null
        } else {
            // The upgrade failed and we don't know the reason
            return <UpgradeFailureModal isOpen onClose={params.onClose} />
        }
    } else if (params.isStarted) {
        // This is the first step where we show what will happen if the user upgrades
        return (
            <InitialAirtableUpgradeModal
                isOpen
                onClose={params.onClose}
                onClickUpgrade={params.onClickUpgrade}
                disabled={params.upgradeDisabled}
            />
        )
    }

    // flow is not started yet
    return null
}
