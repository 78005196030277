import { createSelector } from 'reselect'

import { queryClient } from 'data/hooks/_helpers'
import { invalidateAppErrors } from 'data/hooks/appErrors'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'

import {
    PERMISSION_GROUP_CREATED,
    PERMISSION_GROUP_FETCHED,
    PERMISSION_GROUP_REMOVED,
    PERMISSION_GROUP_UPDATED,
    PERMISSION_RULE_CREATED,
    PERMISSION_RULE_FETCHED,
    PERMISSION_RULE_REMOVED,
    PERMISSION_RULE_UPDATED,
    REACT_QUERY,
} from '../utils/constants'
import { StackerActions } from '../utils/stackerActions'
import { StackerAPI } from '../utils/utils'

// API
class PermissionGroupsApi extends StackerAPI {
    path = 'permissions/groups/'
    bypassPreviewAndImpersonation = true
}
const permissionGroupsApi = new PermissionGroupsApi()

class PermissionGroupActions extends StackerActions {
    api = permissionGroupsApi

    reducer = 'permissionGroups'

    name = 'permissionGroups'
    actionTypes = {
        fetched: PERMISSION_GROUP_FETCHED,
        removed: PERMISSION_GROUP_REMOVED,
        created: PERMISSION_GROUP_CREATED,
        updated: PERMISSION_GROUP_UPDATED,
    }
}

const PGA = new PermissionGroupActions()
PGA.actions = { ...PGA.actions, fetchPermissionGroups: PGA.fetchPermissionGroups }
export const permissionGroupActions = PGA.actions

class PermissionRulesApi extends StackerAPI {
    path = 'permissions/rules/'
    bypassPreviewAndImpersonation = true
}
const permissionRulesApi = new PermissionRulesApi()

class PermissionRuleActions extends StackerActions {
    api = permissionRulesApi

    reducer = 'permissionRules'

    name = 'permissionRules'

    actionTypes = {
        fetched: PERMISSION_RULE_FETCHED,
        removed: PERMISSION_RULE_REMOVED,
        created: PERMISSION_RULE_CREATED,
        updated: PERMISSION_RULE_UPDATED,
    }

    // When a rule is created, the group data is stale.
    afterCreate = (dispatch) => {
        dispatch(permissionGroupActions.fetch())
        invalidateObjects()
        return queryClient.invalidateQueries(REACT_QUERY.roles.listName)
    }

    // When a rule is updated the group data MAY be stale.
    afterUpdate = (dispatch) => {
        invalidateAppErrors()
        dispatch(permissionGroupActions.fetch())
        invalidateObjects()
        return queryClient.invalidateQueries(REACT_QUERY.roles.listName)
    }
}

const PRA = new PermissionRuleActions()
PRA.actions = { ...PRA.actions, fetchPermissionRules: PRA.fetchPermissionRules }
export const permissionRuleActions = PRA.actions

// SELECTORS
export const getPermissionGroupEntities = (state) => state.permissionGroups.entities
export const getPermissionGroupsList = (state) => state.permissionGroups.permissionGroups

export const getPermissionGroupsMemoized = createSelector(
    getPermissionGroupsList,
    getPermissionGroupEntities,
    (permissionGroups, entities) =>
        permissionGroups.map((permissionGroupId) => entities[permissionGroupId])
)
export const getPermissionGroupEntitiesMemoized = createSelector(
    getPermissionGroupEntities,
    (item) => item
)

export const getPermissionRuleEntities = (state) => state.permissionRules.entities
export const getPermissionRulesList = (state) => state.permissionRules.permissionRules

export const getPermissionRulesMemoized = createSelector(
    getPermissionRulesList,
    getPermissionRuleEntities,
    (permissionRules, entities) =>
        permissionRules.map((permissionRuleId) => entities[permissionRuleId])
)
export const getPermissionRuleEntitiesMemoized = createSelector(
    getPermissionRuleEntities,
    (item) => item
)
