import React, { useState } from 'react'

import { useLinkCollection } from 'data/hooks/links'
import { withStack } from 'data/wrappers/WithStacks'
import { LinkListView } from 'features/links'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { LinkEditModalView } from './links/LinkEditView'
import { LinkModalListView } from './links/LinkListView'
import { LinkNewModalView } from './links/LinkNewView'

function AppModalLinks({ children }) {
    const { linksById, getLinks, actions } = useLinkCollection()
    const links = getLinks()

    const [editingFieldId, setEditingFieldId] = useState(null)
    const newModal = useModalToggle('new-modal')

    // Getters

    const getLink = (linkId) => linksById[linkId]

    // Handlers

    const handleCreateLinkSubmit = async (data) => {
        await actions.createLink(data)
        newModal.setIsOpen(false)
    }

    const handleUpdateLinkSubmit = async (data) => {
        await actions.updateLink(editingFieldId, data)
        setEditingFieldId(null)
    }

    const handleNewClick = () => {
        newModal.setIsOpen(true)
    }

    const handleEditClick = (link) => {
        setEditingFieldId(link.linkId)
    }

    const handleModalClose = () => {
        setEditingFieldId(null)
        newModal.setIsOpen(false)
    }

    return (
        <div style={{ display: 'flex', flex: 1, padding: 20, overflowY: 'auto', height: '100%' }}>
            <LinkModalListView links={links} onNewClicked={handleNewClick}>
                <LinkListView links={links} actions={actions} onEdit={handleEditClick} />
                {children}
            </LinkModalListView>
            <LinkNewModalView
                key={newModal.isOpen}
                isOpen={newModal.isOpen}
                onClose={handleModalClose}
                onSubmit={handleCreateLinkSubmit}
            />
            <LinkEditModalView
                key={editingFieldId}
                isOpen={!!editingFieldId}
                onClose={handleModalClose}
                link={getLink(editingFieldId)}
                onSubmit={handleUpdateLinkSubmit}
            />
        </div>
    )
}

export default withStack(AppModalLinks)
