// @ts-strict-ignore
import React, { FC, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import styled from '@emotion/styled'
import { capitalize } from 'lodash'

import { ConditionalWrapper, Icon, Tooltip } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { STICKY_ADD_FIELD_BUTTON_ID } from '../constants'

const { colors } = stackerTheme()

const Wrapper = styled.div<{ isVisible: boolean }>`
    pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
    cursor: pointer;
    height: 100%;

    background-color: ${colors.userInterface.neutral[400]};
    color: ${colors.userInterface.accent[1000]};

    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    padding: 12px;

    border-left: 1px solid ${colors.userInterface.neutral[600]};

    transition: background-color 0.25s ease, color 0.25s ease, opacity 0.25s ease;

    &:hover {
        background-color: ${colors.userInterface.neutral[200]};
        color: ${colors.userInterface.accent[1200]};
    }
`

type Props = {
    onClick: () => void
    container?: HTMLElement | null
    fieldName: string
    onHide?: () => void
}

const AddFieldColumn: FC<Props> = ({ onClick, container, fieldName, onHide }) => {
    const { ref: registerDetectorElement, inView } = useInView({ root: container })
    const [isLoaded, setIsLoaded] = useState(false)
    const parentRef = useRef<HTMLElement | null>(null)

    // When the component gets mounted by the data grid, we find the parent
    // scroll container and add another child element to it. This child element
    // is positioned just to the right of the last column. We use it to and
    // IntersectionObserver to monitor when that element is on screen.
    // This in turn is used to control whether our "sticky" add field column is visible.
    const onLoaded = (element: HTMLDivElement) => {
        if (parentRef.current || !element) return
        let parent = element.parentElement
        while (parent) {
            // find the scroll container
            if (parent.classList.contains('dvn-scroll-inner')) {
                // Create an element to add to the scroll container as a child
                const elm = document.createElement('div')
                elm.style.position = 'relative'
                elm.style.width = '0px'
                // add a second element as a child of that element which is absolutely
                // positioned -100px to the left. This will overlap into the grid
                // onto the last column. This is our "is visible" tracking element
                const tracker = document.createElement('div')
                tracker.style.position = 'absolute'
                tracker.style.left = '-100px'
                tracker.style.width = '10px'
                tracker.style.height = '100%'
                tracker.style.pointerEvents = 'none'

                elm.appendChild(tracker)
                parent.appendChild(elm)

                // Watch the tracking element for being in view
                registerDetectorElement(tracker)

                parentRef.current = parent
                setIsLoaded(true)
                return
            }
            parent = parent.parentElement
        }
    }
    const isVisible = !inView && isLoaded

    useEffect(() => {
        if (!isVisible) onHide?.()
    }, [isVisible, onHide])

    return (
        <ConditionalWrapper
            condition={isVisible}
            wrapper={(child) => (
                <Tooltip
                    placement="left"
                    label={`Add ${capitalize(fieldName)}`}
                    style={{ height: '100%' }}
                    arrow={false}
                    showDelay={50}
                    animation="shift"
                    disabled={!isVisible}
                >
                    {child}
                </Tooltip>
            )}
        >
            <Wrapper
                ref={onLoaded}
                data-testid={STICKY_ADD_FIELD_BUTTON_ID}
                role="button"
                aria-label="Add new field"
                onClick={onClick}
                isVisible={isVisible}
            >
                <Icon icon="add" size="xs" />
            </Wrapper>
        </ConditionalWrapper>
    )
}

export default AddFieldColumn
