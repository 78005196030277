import React, { useEffect, useState } from 'react'

import loadingRocket from 'assets/lottie/loading-rocket.json'
import Lottie from 'lottie-web'

import { Box, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { StyledFlex } from './StyledComponents'

type IsMigratingModalProps = {
    isOpen: boolean
    onClose: () => void
}

export const IsMigratingModal = ({ onClose, isOpen }: IsMigratingModalProps) => {
    const [animationContainer, setAnimationContainer] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        if (isOpen && animationContainer) {
            Lottie.loadAnimation({
                container: animationContainer,
                animationData: loadingRocket,
            })
        }
    }, [animationContainer, isOpen])

    return (
        <SimpleModalCompat
            size="400px"
            height="405px"
            isSecondLayer
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            data-testid="airtable-upgrade.is-migrating-modal"
        >
            <StyledFlex>
                <Box className="chromatic-ignore" ref={setAnimationContainer} height="300px"></Box>
                <Text variant="heading" marginBottom={4}>
                    We are migrating your Airtable base...
                </Text>
                <Text fontSize="14px">This will take less than a minute</Text>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
