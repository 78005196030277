import { useAppContext } from 'app/AppContext'

import { getNavigationColor } from '../../v2/ui/hooks/useNavigationColor'

export default function useWorkspaceTheme() {
    const { workspaceAccount } = useAppContext()
    const workspaceTheme = workspaceAccount?.options?.theme
    const theme = getNavigationColor({
        navColor: workspaceTheme?.navColor === 'brand' ? 'dark' : workspaceTheme?.navColor,
        brandColor: workspaceTheme?.brandColor,
    })

    return workspaceAccount ? theme : null
}
