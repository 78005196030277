import get from 'lodash/get'

import STYLE_CLASSES, { FIELD_CLASSES, OBJECT_CLASSES, PAGE_CLASSES } from 'v2/ui/styleClasses'

// Generates auto complete class names
const getAutocompleteClasses = (objects, pages) => {
    let items = []

    if (STYLE_CLASSES) {
        items = Object.values(STYLE_CLASSES).map((value) => value)

        //object classes
        objects &&
            Object.values(objects).forEach((object) => {
                OBJECT_CLASSES.forEach((c) => {
                    const classString = get(STYLE_CLASSES, c)
                    items.push(`${classString}-${object.api_name}`)
                })

                //field classes
                object.fields.forEach((field) => {
                    FIELD_CLASSES.forEach((c) => {
                        const classString = get(STYLE_CLASSES, c)
                        items.push(`${classString}-${field.api_name}`)
                    })
                })
            })

        //page classes
        pages &&
            Object.values(pages).forEach((page) => {
                PAGE_CLASSES.forEach((c) => {
                    const classString = get(STYLE_CLASSES, c)
                    items.push(`${classString}-${page.api_name}`)
                })
            })
    }

    return {
        getCompletions: (editor, session, pos, prefix, callback) => {
            callback(null, [
                ...items.map(function (word) {
                    return {
                        caption: word,
                        value: word,
                        meta: 'static',
                    }
                }),
                ...session.$mode.$highlightRules.$keywordList.map(function (word) {
                    return {
                        caption: word,
                        value: word,
                        meta: 'keyword',
                    }
                }),
            ])
        },
    }
}

export default getAutocompleteClasses
