import React, { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'

const portal = document.createElement('div')
document.body.appendChild(portal)

const DragAndDropList = ({
    ContainerElement = 'div',
    provideKey,
    children,
    onReorder,
    onDragStart,
    onDragEnd,
    dragDisabled,
    disabledDestinationIndexes = [],
    ...props
}) => {
    const handleDragStart = useCallback(
        (result) => {
            if (onDragStart) onDragStart(result)
        },
        [onDragStart]
    )

    const handleDragEnd = useCallback(
        (result) => {
            // If client returns true from onDragEnd, then we don't want to
            // do anything with the reordering
            if (onDragEnd && onDragEnd(result)) return

            // dropped outside the list
            if (
                !result.destination ||
                disabledDestinationIndexes.includes(result.destination.index)
            )
                return

            if (onReorder) onReorder(result.source.index, result.destination.index)
        },
        [onReorder, onDragEnd, disabledDestinationIndexes]
    )
    return (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <ContainerElement
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}
                        {...props}
                    >
                        {children.map((item, index) => (
                            <Draggable
                                isDragDisabled={
                                    dragDisabled || item?.props?.item?.props?.cannotBeDragged
                                }
                                key={provideKey(index)}
                                draggableId={provideKey(index)}
                                index={index}
                            >
                                {(provided, snapshot) => {
                                    if (item.ref) {
                                        throw new Error(
                                            'A child of DragAndDropList cannot have a ref as it is required for the DnD functionality'
                                        )
                                    }

                                    const child = React.cloneElement(item, {
                                        ref: provided.innerRef,
                                        draggableProps: provided.draggableProps,
                                        dragHandleProps: provided.dragHandleProps,
                                        isDragging: snapshot.isDragging,
                                        style: {
                                            ...item.props.style,
                                            ...provided.draggableProps.style,
                                        },
                                    })

                                    if (snapshot.isDragging) {
                                        return ReactDOM.createPortal(child, portal)
                                    } else {
                                        return child
                                    }
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ContainerElement>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DragAndDropList
