import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { BoxProps } from '@chakra-ui/react'
import {
    CellClickedEventArgs,
    DataEditor,
    DataEditorRef,
    GridCell,
    GridCellKind,
    GridMouseEventArgs,
    Item,
    Rectangle,
    Theme as GlideTheme,
} from '@glideapps/glide-data-grid'
import { isEmpty, isEqual, range } from 'lodash'

import { Banner, Box, Button, SlideInBox } from 'v2/ui'
import { Variants } from 'v2/ui/components/Banner'
import stackerTheme from 'v2/ui/theme/styles/default'
import useDimension from 'v2/ui/utils/useDimension'

import AddFieldColumnComponent from './components/AddFieldColumn'
import {
    AddRowThemeOverride,
    CellThemeOverride,
    useDataGridTheme,
} from './components/dataGridThemeUtils'
import { DataGridTooltip } from './components/DataGridTooltip'
import PastingBanner from './components/PastingBanner'
import useDataGridColumns from './hooks/useDataGridColumns'
import useDataGridDrawUtils from './hooks/useDataGridDrawUtils'
import useDataGridHandler from './hooks/useDataGridHandler'
import {
    createVirtualElement,
    positionElement,
    useVirtualDataGridElements,
} from './hooks/useDataGridVirtualElements'
import useFieldCells from './hooks/useFieldCells'
import useRecordEditManager from './hooks/useRecordEditManager'
import useShowConfirmPasting from './hooks/useShowConfirmPasting'
import { DOUBLE_CLICK_INTERVAL, EDIT_FIELD_HEADER_COLUMN_ID } from './constants'
import type { DataGridEditorProps } from './types'
import { getCell } from './utils'

import '@glideapps/glide-data-grid/dist/index.css'

const { colors } = stackerTheme()

type Props = BoxProps & DataGridEditorProps

export type DataGridEditorHandle = {
    onFieldCreated: (field: FieldDto) => void
    deleteSelectedRecords: () => void
}

export const DataGridEditor = forwardRef<DataGridEditorHandle, Props>(
    (
        {
            object,
            records,
            orderBy,
            setOrderBy,
            updateRecord,
            importRawRecords,
            handleAddRecord,
            addRecord,
            deleteRecords,
            addField,
            onHideAddField,
            width = '100%',
            height = '100%',
            minWidth = '100px',
            minHeight = '100px',
            maxColumnWidth = 600,
            cellDataProvider,
            editField,
            onRecordDoubleClicked,
            dataSourceSupportsPasting,
            dataSourceLabel,
            onDataCopied,
            onDataPasted,
            onColumnHeaderClick,
            setIsDirty,
            bypassPreviewAndImpersonation,
            requiredFields,
            addFieldButtonConfig = { width: 125, title: 'Add field' },
            fieldName = 'field',
            onColumnsOrderChanged,
            onRecordsSelected,
            ...containerProps
        },
        ref
    ) => {
        const containerRef = useRef<HTMLDivElement | null>(null)
        const editorRef = useRef<DataEditorRef>(null)

        const { ref: isActiveTrackerRef, inView } = useInView()
        const { width: actualWidth, height: actualHeight } = useDimension(containerRef)
        const { columns, columnIsSynced, displayedFields, setColumnWidth, headerIcons } =
            useDataGridColumns({
                object,
                orderBy,
                allowConfigureFields: !!editField,
                showAddFieldColumn: !!addField,
                addFieldButtonConfig,
            })

        const theme = useDataGridTheme()

        const [pastingIndicator, setPastingIndicator] = useState<{
            show: boolean
            text: string
            icon: string
            variant: Variants
            useTimer: boolean
        }>({
            show: false,
            text: '',
            icon: 'info',
            variant: 'ImportantInformation',
            useTimer: false,
        })

        const lastRowClick = useRef<{ col: number; row: number; timestamp: number } | undefined>()

        // this manager handles collecting pending edits/deletes/creations,
        // posting them to the server, persisting them while waiting for response, etc
        const {
            records: mergedRecords,
            editRecord,
            deleteRecords: doDeleteRecords,
            addRecord: doAddRecord,
            pendingNewRecords,
            pendingDeletes,
            pendingEdits,
            failedRecords,
            retryFailures,
        } = useRecordEditManager({
            object,
            records,
            updateRecord,
            addRecord,
            deleteRecords,
        })

        const {
            errorMessage,
            gridSelection,
            pinAddNewRow,
            showAddNewRow,
            handleCellEdited,
            handleColumnClick,
            handleDelete,
            handleVisibleRegionChange,
            onColumnMoved,
            onFieldCreated,
            onGridSelectionChange,
        } = useDataGridHandler({
            editorRef,
            object,
            records: mergedRecords,
            failedRecords,
            pendingDeletes,
            displayedFields,
            columns,
            addFieldButtonConfig,
            editRecord,
            deleteRecords: doDeleteRecords,
            orderBy,
            setOrderBy,
            addField,
            onColumnsOrderChanged,
            onColumnHeaderClick,
            onRecordsSelected,
        })

        const deleteSelectedRecords = useCallback(() => {
            gridSelection && handleDelete(gridSelection)
        }, [handleDelete, gridSelection])

        const { drawHeader, drawCell } = useDataGridDrawUtils(object)

        // evalaute the isDirty state out here, rather than in the useEffect
        // because it appears that sometimes while the contents of these objects
        // may change, they are being mutated, not replaced.
        const isDirty =
            !isEmpty(pendingEdits) || !isEmpty(pendingDeletes) || !isEmpty(failedRecords)

        useEffect(() => {
            setIsDirty?.(isDirty)
        }, [isDirty, setIsDirty])

        // This is our custom cell provider for non-native cell types
        const { provideEditor, coercePasteValue } = useFieldCells()

        useVirtualDataGridElements({
            isActive: inView,
            canAddFields: !!addField,
            columnCount: displayedFields.length,
            rowCount: mergedRecords.length,
            editorRef: editorRef.current,
            containerRef: containerRef,
            onHideAddField,
        })

        const showConfirmPasting = useShowConfirmPasting()

        const canAddRecord = addRecord || handleAddRecord

        const getRowThemeOverride = React.useCallback(
            (row: number): Partial<GlideTheme> | undefined => {
                // If this is our Add Row placeholder, then provider a different theme
                if (row === mergedRecords.length && canAddRecord) {
                    return AddRowThemeOverride
                }
                return undefined
            },
            [canAddRecord, mergedRecords.length]
        )

        // Creates the cell for a given row and column
        const getCellContent = useCallback(
            ([col, row]: readonly [number, number]): GridCell => {
                const column = columns[col]
                // If this is the add field column,
                // or the row/col index is outside the bounds of our arrays
                // of fields and rows (which can temporarilly happen when removing lots of rows
                // or columns at once) then just show a blank cell.
                if (
                    isEqual(column, addFieldButtonConfig) ||
                    col > displayedFields.length - 1 ||
                    row > mergedRecords.length - 1
                ) {
                    return { kind: GridCellKind.Loading, allowOverlay: false }
                }

                const record = mergedRecords[row]
                // don't allow edits on rows that have been marked for deletion
                const isDeleting =
                    !!record && !!pendingDeletes.find((x) => x.recordId === record._sid)
                const cell = getCell(
                    record,
                    displayedFields[col],
                    cellDataProvider,
                    !updateRecord || isDeleting, // only editable if we have an updateRecord handler
                    !onRecordDoubleClicked, // don't show overlay if the client is handling doubleclicks
                    bypassPreviewAndImpersonation
                )

                const cellThemeAdditions = {
                    bgCell: isDeleting ? colors.userInterface.error[200] : CellThemeOverride.bgCell,
                }
                return { ...cell, themeOverride: { ...CellThemeOverride, ...cellThemeAdditions } }
            },
            [
                columns,
                addFieldButtonConfig,
                displayedFields,
                mergedRecords,
                pendingDeletes,
                cellDataProvider,
                updateRecord,
                onRecordDoubleClicked,
                bypassPreviewAndImpersonation,
            ]
        )

        const [tooltip, setTooltip] = useState<
            | {
                  title: string
                  bounds: { x: number; y: number; width: number; height: number }
              }
            | undefined
        >()

        const onItemHovered = useCallback(
            (args: GridMouseEventArgs) => {
                if (args.kind === 'header' && columnIsSynced[args.location[0]]) {
                    setTooltip({
                        title: 'Synced field',
                        bounds: args.bounds,
                    })
                } else {
                    setTooltip(undefined)
                }
            },
            [columnIsSynced]
        )

        const showFieldMenu = (col: number, position: Rectangle) => {
            // We create the virtual element to picked up later on by the Popover
            const virtualElement = createVirtualElement(EDIT_FIELD_HEADER_COLUMN_ID)
            positionElement(virtualElement, position.x, position.y, position.width, position.height)

            const field = displayedFields[col]
            if (editField) {
                return editField(field)
            }
        }

        const recordCount = mergedRecords.length

        useImperativeHandle(ref, () => ({ onFieldCreated, deleteSelectedRecords }))

        // When a cell is clicked, and we have have been supplied a double click handler
        const onCellClicked = (cell: Item, event: CellClickedEventArgs) => {
            const [col, row] = cell
            if (row >= records.length) return false

            const lastClick = lastRowClick.current

            // If this is our second click on this row in <500ms, call it a double click
            // and call the specified handler (if any)
            const rowPreviouslyClicked =
                lastClick &&
                lastClick.row === row &&
                window.performance.now() - lastClick.timestamp < DOUBLE_CLICK_INTERVAL
            if (rowPreviouslyClicked) {
                if (onRecordDoubleClicked) {
                    onRecordDoubleClicked(records[row], displayedFields[col])
                    event.preventDefault()
                }
            }
            lastRowClick.current = { col, row, timestamp: window.performance.now() }
        }

        const handleGetCellsForSelection = (selectedRectangle: Rectangle) => {
            const copiedCells: GridCell[][] = range(
                selectedRectangle.y,
                selectedRectangle.y + selectedRectangle.height
            ).map((row) =>
                range(selectedRectangle.x, selectedRectangle.x + selectedRectangle.width).map(
                    (col) => getCellContent([col, row])
                )
            )

            onDataCopied?.()
            return copiedCells
        }

        const showSuccessBanner = (numberRecordsUpdated: number, numberRecordsCreated: number) => {
            setPastingIndicator({
                show: true,
                text: `${numberRecordsUpdated} rows updated${
                    numberRecordsCreated > 0 ? `, ${numberRecordsCreated} rows created` : ''
                }.`,
                icon: 'info',
                variant: 'ImportantInformation',
                useTimer: true,
            })
        }

        const processImportRawRecords = async (
            [col, row]: Item,
            values: readonly (readonly string[])[],
            numberRecordsUpdated: number,
            numberRecordsCreated: number
        ): Promise<void> => {
            if (
                pendingNewRecords.length > 0 ||
                pendingDeletes.length > 0 ||
                Object.keys(pendingEdits).length > 0 ||
                Object.keys(failedRecords).length > 0
            ) {
                setPastingIndicator({
                    show: true,
                    text: 'Cannot paste while records are being updated…',
                    icon: 'info',
                    variant: 'Warning',
                    useTimer: true,
                })

                return
            }

            setPastingIndicator({
                show: true,
                text: 'Pasting...',
                icon: 'info',
                variant: 'ImportantInformation',
                useTimer: false,
            })

            const updates = values.map((rowValues, currentRow) => {
                const record = mergedRecords[currentRow + row]
                const fieldsToUpdate: { [keyof: string]: string } = {}

                rowValues.forEach((toPaste, currentCol) => {
                    const field = displayedFields[currentCol + col]
                    if (!!field) {
                        fieldsToUpdate[field.api_name] = toPaste
                    }
                })

                return { _sid: record?._sid || null, data: fieldsToUpdate }
            })

            try {
                await importRawRecords?.(updates)
                showSuccessBanner(numberRecordsUpdated, numberRecordsCreated)
                onDataPasted?.()
            } catch (error) {
                setPastingIndicator({
                    show: true,
                    text: 'Failed to paste',
                    icon: 'warning',
                    variant: 'Error',
                    useTimer: true,
                })
            }
        }

        const handlePaste = ([col, row]: Item, values: readonly (readonly string[])[]) => {
            if (!importRawRecords) {
                return false
            }

            if (!dataSourceSupportsPasting) {
                setPastingIndicator({
                    show: true,
                    text: `Bulk pasting is not supported for ${dataSourceLabel}...`,
                    icon: 'warning',
                    variant: 'Error',
                    useTimer: true,
                })
                return false
            }

            // This logic removes any empty lines that live at the end of the pasted data
            // It prevents the creation of (many) empty records when pasting

            // Iterate through the pasted rows from the end to the start and stop at the first non-empty row or when there's no row any more
            let rowIsEmpty = true
            let i = values.length
            while (i > 0 && rowIsEmpty) {
                i -= 1
                const rowValues = values[i]
                rowIsEmpty = !rowValues.some((toPaste) => toPaste.length > 0)
            }

            // If the last tested row is empty, we reached the end and there is only empty rows, nothing to do
            if (rowIsEmpty) {
                return false
            }

            // Remove all the empty rows
            const valuesToPaste = values.slice(0, i + 1)
            if (valuesToPaste.length === 0) {
                return false
            }

            if (!!gridSelection?.current) {
                const {
                    current: {
                        range: {
                            x: selectionX,
                            y: selectionY,
                            width: selectionWidth,
                            height: selectionHeight,
                        },
                    },
                } = gridSelection

                // If we have more rows selected than rows to paste,
                // we're just going to repeat the last pasted row until we have
                // enough rows to cover the selection. This allows the user
                // to paste a single row into a selection of multiple rows, etc.
                if (selectionHeight > valuesToPaste.length) {
                    const rowsToAdd = selectionHeight - valuesToPaste.length
                    for (let i = 0; i < rowsToAdd; i++) {
                        valuesToPaste.push([...valuesToPaste[values.length - 1]])
                    }
                }

                const isPastingOverflowingSelection =
                    row + valuesToPaste.length > selectionY + selectionHeight ||
                    col + values[0].length > selectionX + selectionWidth

                const willAddRecords = recordCount < row + valuesToPaste.length

                const numberRecordsToAdd = willAddRecords
                    ? row + valuesToPaste.length - recordCount
                    : 0

                const numberRecordsUpdated = willAddRecords
                    ? valuesToPaste.length - numberRecordsToAdd
                    : valuesToPaste.length
                const numberRecordsCreated = willAddRecords ? numberRecordsToAdd : -1

                if (isPastingOverflowingSelection) {
                    showConfirmPasting(
                        numberRecordsUpdated,
                        numberRecordsCreated,
                        () =>
                            processImportRawRecords(
                                [col, row],
                                valuesToPaste,
                                numberRecordsUpdated,
                                numberRecordsCreated
                            ),
                        () => undefined
                    )

                    return false
                }

                processImportRawRecords(
                    [col, row],
                    valuesToPaste,
                    numberRecordsUpdated,
                    numberRecordsCreated
                )
            }

            return false
        }

        return (
            <Box
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
                {...containerProps}
                ref={containerRef}
            >
                <div
                    // Tracks whether the datagrid is currently displayed on screen.
                    // It may be scrolled off screen, or on an inactive tab.
                    ref={isActiveTrackerRef}
                    style={{ width: '0px', height: '0px', position: 'absolute' }}
                />
                <DataEditor
                    theme={theme}
                    maxColumnWidth={maxColumnWidth}
                    ref={editorRef}
                    width={actualWidth}
                    height={actualHeight}
                    headerIcons={headerIcons}
                    drawCell={(args) =>
                        drawCell(
                            args,
                            columns,
                            records,
                            failedRecords,
                            requiredFields,
                            addFieldButtonConfig
                        )
                    }
                    drawHeader={drawHeader}
                    provideEditor={provideEditor}
                    coercePasteValue={coercePasteValue}
                    getCellContent={getCellContent}
                    rowSelectionMode="multi"
                    getRowThemeOverride={getRowThemeOverride}
                    columns={columns}
                    freezeColumns={1}
                    rows={recordCount}
                    rowMarkers={deleteRecords ? 'both' : 'number'}
                    getCellsForSelection={handleGetCellsForSelection}
                    onPaste={handlePaste}
                    trailingRowOptions={
                        showAddNewRow && canAddRecord
                            ? {
                                  hint: 'Add new',
                                  sticky: pinAddNewRow,
                              }
                            : undefined
                    }
                    onCellEdited={updateRecord ? handleCellEdited : undefined}
                    onRowAppended={addRecord ? handleAddRecord || doAddRecord : undefined}
                    onColumnResize={setColumnWidth}
                    onDelete={deleteRecords ? handleDelete : undefined}
                    onHeaderClicked={handleColumnClick}
                    onHeaderMenuClick={showFieldMenu}
                    onVisibleRegionChanged={handleVisibleRegionChange}
                    onCellClicked={onCellClicked}
                    gridSelection={gridSelection}
                    onColumnMoved={onColumnMoved}
                    onItemHovered={onItemHovered}
                    onGridSelectionChange={onGridSelectionChange}
                    smoothScrollX
                    rightElement={
                        !!addField ? (
                            <AddFieldColumnComponent
                                onClick={addField}
                                container={containerRef.current}
                                fieldName={fieldName}
                                onHide={onHideAddField}
                            />
                        ) : null
                    }
                    rightElementProps={{
                        sticky: true,
                    }}
                />
                <DataGridTooltip tooltip={tooltip} />
                <PastingBanner
                    show={pastingIndicator.show}
                    text={pastingIndicator.text}
                    icon={pastingIndicator.icon}
                    variant={pastingIndicator.variant}
                    useTimer={pastingIndicator.useTimer}
                    onHide={() =>
                        setPastingIndicator({
                            show: false,
                            text: '',
                            icon: 'info',
                            variant: 'Information',
                            useTimer: false,
                        })
                    }
                />
                {!!Object.keys(failedRecords).length && (
                    <SlideInBox
                        slideIn={Object.keys(failedRecords).length > 0}
                        slideOut={Object.keys(failedRecords).length === 0}
                    >
                        <Box ml={3} mb={3}>
                            <Banner icon="info" variant="Error" justifyContent="center">
                                {errorMessage}
                                <Button
                                    ml={2}
                                    variant="Secondary"
                                    buttonSize="small"
                                    onClick={() => retryFailures()}
                                >
                                    Try again
                                </Button>
                            </Banner>
                        </Box>
                    </SlideInBox>
                )}
            </Box>
        )
    }
)

export default DataGridEditor
