// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'

// @ts-ignore
import { Collapse, Input, Text } from 'v2/ui'

import { DataConnectionEditorContextType } from '../dataConnectionEditorContext'

import ConnectionComplete from './ConnectionComplete'
import SimpleSchemaSync from './SimpleSchemaSync'

const StripeConnectionEditor: React.FC<{ context: DataConnectionEditorContextType }> = (props) => {
    const context = props.context
    const { dataConnection, initializationComplete } = context

    // If we don't yet have a data connection, then we are creating a new
    // connection. Render the UI for that now
    if (!dataConnection) {
        return <InitializeNewConnection context={context} />
    } else if (!initializationComplete && !dataConnection.options?.initial_config_complete) {
        // We have a connection, but it hasn't done the initial schema sync.
        // Do it now.
        return (
            <SimpleSchemaSync
                dataConnection={dataConnection}
                onSyncComplete={context.onSyncComplete}
                onSyncError={context.onSyncError}
                onSyncStart={context.onStartSync}
            />
        )
    } else if (initializationComplete) {
        return <ConnectionComplete provider={context.provider} />
    } else {
        // We're just editing an existing connection.
        // We don't have any fields that are editable, so just return null.
        return null
    }
}

const InitializeNewConnection = ({ context }) => {
    const { setNextHandler, setIsSaving, createDataConnection, setDataConnection } = context
    const [apiKey, setApiKey] = useState<string | undefined>()
    const [showError, setShowError] = useState(false)
    const isValid = apiKey && (apiKey.startsWith('sk_test_') || apiKey.startsWith('sk_live_'))

    // Handler for creating the connection
    const initializeConnection = useCallback(() => {
        if (!isValid) {
            setShowError(true)
            return
        }
        setShowError(false)
        setIsSaving(true)
        createDataConnection({ updated_secret_options: { stripe_api_key: apiKey } })
            .then(setDataConnection)
            .catch(Sentry.captureException)
    }, [createDataConnection, apiKey, setDataConnection, setIsSaving, isValid])

    // Register to handle it when the Next button gets clicked
    useEffect(() => {
        setNextHandler(initializeConnection)
    }, [initializeConnection, setNextHandler])

    return (
        <>
            <Text>Your Stripe API key</Text>
            <Input
                variant="admin"
                onChange={(e) => {
                    setApiKey(e.target.value)
                }}
                placeholder={'Paste the API key here'}
                value={apiKey}
                mt={2}
                mb={2}
            />
            <Collapse isOpen={!!(showError && !isValid)}>
                <Text variant="error" mb={2} fontSize="sm">
                    The API key should start with &apos;sk_test_&apos; or &apos;sk_live_&apos;
                </Text>
            </Collapse>
        </>
    )
}
export default StripeConnectionEditor
