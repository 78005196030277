import React, { createContext, useContext, useMemo, useState } from 'react'

type DateFilterContext = {
    specificDate: string | string[] | undefined
    setSpecificDate: (newDate: string | string[] | undefined) => void
}

const dateFilterContext = createContext<DateFilterContext>({
    specificDate: '',
    setSpecificDate: () => {},
})

type DateFilterContextProviderProps = React.PropsWithChildren<{}>

export const DateFilterContextProvider: React.FC<DateFilterContextProviderProps> = ({
    children,
}) => {
    const [specificDate, setSpecificDate] = useState<string | string[] | undefined>(undefined)

    const value = useMemo(
        () => ({
            specificDate,
            setSpecificDate,
        }),
        [specificDate, setSpecificDate]
    )

    return <dateFilterContext.Provider value={value}>{children}</dateFilterContext.Provider>
}

export const useDateFilter = () => {
    const { specificDate, setSpecificDate } = useContext(dateFilterContext)

    return useMemo(
        () => ({
            specificDate,
            setSpecificDate,
        }),
        [specificDate, setSpecificDate]
    )
}
