import React, { FC } from 'react'

import { ChartPie } from 'v2/ui/svgs'

import { InvalidBlock, InvalidBlockProps } from '../common/InvalidBlock'

type InvalidChartBlockProps = Pick<InvalidBlockProps, 'numberOfMetrics' | 'isEditing' | 'isActive'>

import { CHART_BLOCK_HEIGHT } from './constants'

export const NoVisibleRecordsChartBlock: FC<InvalidChartBlockProps> = ({
    numberOfMetrics,
    isEditing,
    isActive,
}) => (
    <InvalidBlock
        blockType="chart"
        height={CHART_BLOCK_HEIGHT}
        numberOfMetrics={numberOfMetrics}
        isEditing={isEditing}
        isActive={isActive}
        narrowTitle="No record to display in this chart."
        wideTitle="No records visible"
        wideSubtitle="No records to display in this chart."
        Illustration={ChartPie}
    />
)
