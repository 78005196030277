import React from 'react'

import styled from '@emotion/styled'

import HelpMenu from 'features/help/HelpMenu'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Icon } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

const Wrapper = styled.div`
    position: fixed;
    bottom: 17px;
    right: 20px;

    z-index: ${({ zIndex }) => zIndex};
    margin-left: 30px;
`

const HelpCenter = () => {
    const SvgComponent = SvgIcons.QuestionMarkBlack

    const { state } = useSlidingPane()
    const isSlidingPaneOpen = Boolean(state.key)

    return (
        <Wrapper zIndex={isSlidingPaneOpen ? 20 : 1400}>
            <HelpMenu
                style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#ffffff',
                    boxShadow:
                        'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px',
                }}
                buttonSize="mdDense"
            >
                <Icon svg={<SvgComponent />} />
            </HelpMenu>
        </Wrapper>
    )
}

export default HelpCenter
