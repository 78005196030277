import { useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'lodash'
import moment from 'moment'
import userflow from 'userflow.js'

import settings from 'app/settings'
import { useObject } from 'data/hooks/objects'
import { useLDFlagsWithReadyStatus } from 'data/hooks/useLDFlags'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

const useShowNewFieldsUserFlow = (
    fields: FieldDto[],
    view?: ViewDto,
    stack?: StackDto,
    isEmptyList?: boolean
) => {
    const { showAllFields } = view?.options ?? { showAllFields: false }
    const { object }: { object: ObjectDto | undefined } = useObject(view?.object_id)
    const { flags, isClientReady } = useLDFlagsWithReadyStatus()
    const {
        state: { key: slidingPaneKey },
    } = useSlidingPane()

    const {
        max_prompt_limit: maxPromptsLimit,
        time_delay_amount: timeDelayAmount,
        time_delay_unit: timeDelayUnit,
    } = flags?.addFieldUserFlowEnabled ?? {}

    const [triggerUserFlow, setTriggerUserFlow] = useState<boolean>(false)

    const isStackerData = object?.connection_options?.stacker_native_object
    const COUNTER_KEY = `add_field_user_flow_prompt_count_${view?.type}`
    const LAST_SEEN_KEY = `add_field_user_flow_last_seen_on_${view?.type}`

    // date this user flow logic was first initialized. We default to "now" until the
    // useEffect below runs
    const [userFlowInitializedDate, setUserFlowInitializedDate] = useState<moment.Moment>(moment())

    const userFlowPromptsCount = Number.parseInt(localStorage.getItem(COUNTER_KEY) || '') || 0
    const userFlowLastSeen = moment(localStorage.getItem(LAST_SEEN_KEY) || '0001-01-01')
    const userFlowLastSeenForView = moment(
        localStorage.getItem(`${stack?._sid}_${view?._sid}_add_field_user_flow_last_seen_on`) ||
            '0001-01-01'
    )
    // Last time the view was modified for this specific view
    const viewLastModifiedOn = moment(view?.modified_date || '0001-01-01')

    // Datetime threshold used to determine whether we show the userflow:
    // last modified date of the view or last time the flow as shown for this view,
    // whichever is later.
    const threshold = moment.max(
        viewLastModifiedOn,
        userFlowLastSeenForView,
        userFlowInitializedDate
    )

    // Number of hours since the user flow was last seen
    const sinceLastUserFlow = moment().diff(moment(userFlowLastSeen))
    const durationSinceUserFlowSeen = timeDelayUnit
        ? moment.duration(sinceLastUserFlow).as(timeDelayUnit)
        : null

    // List of new fields since last view was modified or userflow was shown
    const newFields = useMemo(
        () =>
            flags?.addFieldUserFlowEnabled?.enabled
                ? fields?.filter((f) => moment(f.created_date || '0001-01-01').isAfter(threshold))
                : [],
        [fields, threshold, flags?.addFieldUserFlowEnabled?.enabled]
    )

    const [isLDReady, setIsLDReady] = useState<boolean>(isClientReady)
    useEffectOnlyOnUpdate(() => {
        if (isClientReady && flags?.addFieldUserFlowEnabled?.enabled) {
            setIsLDReady(true)
        }
    }, [isClientReady, flags?.addFieldUserFlowEnabled?.enabled])

    useEffect(() => {
        let rawValue = localStorage.getItem(`add_field_user_flow_initialized`)
        const value = moment(rawValue || undefined)
        // If we haven't initailzied yet, then save the current date/time into local storage.
        // That will be our official start date from here onward.
        if (!rawValue) {
            localStorage.setItem(`add_field_user_flow_initialized`, value.toISOString())
        }

        setUserFlowInitializedDate(value)
    }, [])

    useEffect(() => {
        if (!durationSinceUserFlowSeen || !maxPromptsLimit || !timeDelayAmount) return
        if (!isLDReady) {
            setTriggerUserFlow(false)
            return
        }
        if (
            userFlowPromptsCount < maxPromptsLimit &&
            !isEmpty(newFields) &&
            durationSinceUserFlowSeen > timeDelayAmount
        ) {
            setTriggerUserFlow(true)
            return
        } else {
            setTriggerUserFlow(false)
        }
    }, [
        newFields,
        stack?._sid,
        durationSinceUserFlowSeen,
        view?._sid,
        threshold,
        userFlowPromptsCount,
        maxPromptsLimit,
        timeDelayAmount,
        isLDReady,
    ])

    useEffect(() => {
        if (!flags?.addFieldUserFlowEnabled?.enabled || !isLDReady) return
        if (!isStackerData || isEmptyList) return
        if (showAllFields) return
        if (
            triggerUserFlow &&
            view &&
            (slidingPaneKey === null || slidingPaneKey === 'edit-layout')
        ) {
            exported.startUserFlow(view.type)
            // Store last seen and counter after we show the flow
            localStorage.setItem(COUNTER_KEY, (userFlowPromptsCount + 1).toString())
            localStorage.setItem(LAST_SEEN_KEY, moment().toISOString())
            localStorage.setItem(
                `${stack?._sid}_${view?._sid}_add_field_user_flow_last_seen_on`,
                moment().toISOString()
            )
        }
    }, [
        showAllFields,
        triggerUserFlow,
        COUNTER_KEY,
        LAST_SEEN_KEY,
        view?.type,
        slidingPaneKey,
        flags?.addFieldUserFlowEnabled?.enabled,
        userFlowPromptsCount,
        view?._sid,
        stack?._sid,
        isStackerData,
        isEmptyList,
        isLDReady,
        view,
    ])

    return
}

const startUserFlow = (type: ViewDto['type']) => {
    if (type === 'list') {
        userflow.start(settings.USERFLOW_ADD_FIELD_ON_LIST_KEY, { once: true })
    }
    userflow.updateUser({
        has_seen_new_fields_flow: true,
    })
}

const exported = {
    useShowNewFieldsUserFlow,
    startUserFlow,
}
export default exported
