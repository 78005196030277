import { StepTypes } from 'data/hooks/actions'

export const validateStepFieldsConditionalVisibility = (
    step: ActionStep,
    record: RecordDto,
    processFilter: (data: RecordDto[], filters: Filter[], lookupRecord?: RecordDto) => any
) => {
    if (step.type !== StepTypes.UpdateRecord) {
        return true
    }
    return step.fields.some(
        (field) =>
            !step.fieldConditionalVisibilityFilters?.[field.fieldId] ||
            processFilter([record], step.fieldConditionalVisibilityFilters[field.fieldId])?.length >
                0
    )
}
