import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidInfoProps = {
    color?: string
    size?: number
}
const SolidInfo: React.FC<SolidInfoProps> = ({
    color = colors.userInterface.neutral[800],
    size = 14,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 15) / 14}
            viewBox="0 0 14 15"
            fill="none"
        >
            <path
                d="M6.821.5a6.821 6.821 0 1 0 0 13.642A6.821 6.821 0 0 0 6.821.5zm.682 9.55a.682.682 0 1 1-1.364 0V6.639a.682.682 0 1 1 1.364 0v3.41zm-.682-4.775a.682.682 0 1 1 0-1.364.682.682 0 0 1 0 1.364z"
                fill={color}
            />
        </svg>
    )
}

export default SolidInfo
