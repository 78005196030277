/* Code Quality: Not audited */

import React, { useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import {
    invalidateNavigation,
    refetchNavigation,
    useCreateNavigation,
    useDeleteNavigation,
    useNavigation,
    useUpdateNavigation,
} from 'data/hooks/navigation'

export default function WithNavigation({ children, allStacks }) {
    const { data } = useNavigation()
    const { mutateAsync: updateNavigation } = useUpdateNavigation()
    const { mutateAsync: createNavigation } = useCreateNavigation()
    const { mutateAsync: deleteNavigation } = useDeleteNavigation()
    const { selectedStack } = useContext(AppContext)

    const navigation = data || []
    const filteredData = useMemo(
        () => navigation.filter((item) => allStacks || item.stack_id === selectedStack?._sid),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigation, selectedStack]
    )

    const navigationActions = useMemo(
        () => ({
            update: (sid, patch) => updateNavigation({ id: sid, patch }),
            clear: invalidateNavigation,
            fetch: refetchNavigation,
        }),
        [updateNavigation]
    )

    return children({
        navigation: filteredData,
        navigationActions,
        onChangeNavigation: updateNavigation,
        createNavigation,
        removeNavigation: deleteNavigation,
    })
}

export const withNavigation = (Child) => (props) =>
    (
        <WithNavigation>
            {(navigationProps) => <Child {...navigationProps} {...props} />}
        </WithNavigation>
    )
