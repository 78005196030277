import React, { Fragment, useMemo, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { invalidateNavigation } from 'data/hooks/navigation'
import { useObject } from 'data/hooks/objects'
import { useUserLists } from 'data/hooks/userLists'
import { withObjects } from 'data/wrappers/WithObjects'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { CollapsableSection } from 'features/workspace/WorkspaceSettingsModalUi'

import { ActionList, Box, Button, ConditionalWrapper, Flex, Icon, Text, Tooltip } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import DATA_PROVIDERS from '../../admin/data-connector/dataProviderConfig'
import { ProviderIcon } from '../../admin/data-connector/ProviderIcon'

import { AirtableV2UpgradeButton } from './Airtable/AirtableV2UpgradeButton'
import { AirtableV2RepairButton } from './Airtable/repair/AirtableV2RepairButton'
import SyncingTimesLabel from './SyncingTimesLabel'
import SyncSchemaButtons from './SyncSchemaButtons'
import { useDeleteDataSource } from './utils'

export const _DataSourceDisplay = ({
    dataConnection,
    editDataConnection,
    objects: allObjects,
    removeDataConnection,
    expanded = false,
}) => {
    const { type, connected_objects: objectWhitelist } = dataConnection
    const {
        name: providerName,
        disabled,
        extraControls: ProviderControls,
    } = DATA_PROVIDERS[type] || {}
    const { showDeleteModal } = useDeleteDataSource({ dataConnection, removeDataConnection })
    if (!providerName) {
        return null
    }

    if (disabled) {
        return <MissingDataSourceConfig dc={dataConnection} />
    }

    const objects = allObjects ? allObjects.filter((o) => objectWhitelist?.includes(o._sid)) : []

    return (
        <CollapsableSection
            defaultIsOpen={expanded}
            header={
                <DataSourceTitleBar
                    objects={objects}
                    dataConnection={dataConnection}
                    editDataConnection={editDataConnection}
                    onDelete={showDeleteModal}
                />
            }
            Toggle={({ isOpen }) => {
                return (
                    <>
                        <span
                            style={{
                                color: V4DesignSystem.colors.blue['1000'],
                                fontSize: 12,
                                marginRight: 6,
                                userSelect: 'none',
                            }}
                        >
                            View tables
                        </span>
                        <Icon
                            icon={isOpen ? 'arrowUp' : 'arrowDown'}
                            size="sm"
                            color={V4DesignSystem.colors.blue['1000']}
                            mr={3}
                        />
                    </>
                )
            }}
        >
            {ProviderControls && (
                <div
                    style={{
                        margin: '12px 58px 12px 24px',
                        padding: '0 1rem',
                    }}
                >
                    <ProviderControls dataConnection={dataConnection} />{' '}
                </div>
            )}

            <Box bg="neutral.100">
                <div
                    style={{
                        marginTop: '12px',
                        marginLeft: '9px',
                    }}
                >
                    {objects?.length > 0 ? (
                        <ObjectList objects={objects} objectWhitelist={objectWhitelist} />
                    ) : (
                        <span
                            style={{
                                color: '#9099a0',
                                fontSize: '12px',
                                lineHeight: '32px',
                            }}
                        >
                            No tables added yet
                        </span>
                    )}
                </div>
            </Box>
        </CollapsableSection>
    )
}

export const DataSourceDisplay = withObjects(_DataSourceDisplay)

function DataSourceTitleBar({ objects, dataConnection, editDataConnection, stack, onDelete }) {
    const {
        type: connectionType,
        label: title,
        service_name: serviceName,
        version,
    } = dataConnection
    const { openSourceText, getSourceLink, getSubtitle, disallowConfigChanges, icon } =
        DATA_PROVIDERS[connectionType] || {}

    const openButtonUrl = useMemo(
        () => dataConnection && getSourceLink?.(dataConnection),
        [getSourceLink, dataConnection]
    )
    const subtitle = useMemo(
        () => dataConnection && getSubtitle?.(dataConnection),
        [getSubtitle, dataConnection]
    )

    const actions = [
        { label: 'Edit settings', icon: 'cog', action: editDataConnection },
        {
            label: 'Delete data source',
            icon: 'trash',
            action: onDelete,
        },
    ]

    if (!stack?.options?.is_demo && openSourceText) {
        actions.unshift({
            label: openSourceText,
            as: 'a',
            href: openButtonUrl,
            target: '_blank',
            action: (e) => e.preventDefault(),
            icon: ProviderIcon({
                type: connectionType,
                serviceName: serviceName,
                size: '14px',
                width: '24px',
                pr: 2,
            }),
        })
    }

    return (
        <Flex wrap="nowrap" align="center">
            <ProviderIcon
                type={connectionType}
                serviceName={serviceName}
                size={typeof icon === 'string' ? 'md' : '24px'}
                verticalAlign="middle"
                alignSelf="start"
                ml={1}
                mr={3}
            />
            <Flex column wrap="nowrap" flexGrow={1} align="start">
                <div>
                    <Text variant="dataSourceTitle" display="inline" verticalAlign="middle">
                        {title}
                    </Text>
                    {subtitle && (
                        <Text
                            display="inline"
                            variant="dataSourceSubTitle"
                            verticalAlign="middle"
                            ml={2}
                        >
                            ({subtitle})
                        </Text>
                    )}
                </div>
                <SyncingTimesLabel
                    textStyle={{ display: 'inline', size: 'xs' }}
                    objects={objects}
                    dataConnection={dataConnection}
                />
            </Flex>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    marginRight: '16px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <>
                    {connectionType === 'airtable' && (
                        <AirtableV2UpgradeButton dataConnection={dataConnection} buttonSize="sm" />
                    )}
                </>
                <>
                    {connectionType === 'airtable' && version === 2 && (
                        <AirtableV2RepairButton dataConnection={dataConnection} />
                    )}
                </>
                <SyncSchemaButtons dataConnection={dataConnection} buttonSize="sm" />
                {!disallowConfigChanges && <ActionList icon="dotsH" actions={actions} />}
            </div>
        </Flex>
    )
}

function ObjectList({ objects, objectWhitelist }) {
    // Check if a given object is used for a user list
    const { data: userLists = [] } = useUserLists()
    const isUserList = (object, userLists) => {
        if (userLists?.find((list) => list?.object_id === object?._sid)) return true
        return false
    }

    const visibleObjects = orderBy(
        objects.filter((o) => objectWhitelist.includes(o._sid)),
        (object) => object.name
    )

    return (
        <Flex column align="stretch" p={2} borderRadius="3px">
            {visibleObjects.map((object) => (
                <Fragment key={object._sid}>
                    <ObjectListItem isUserList={isUserList(object, userLists)} object={object} />
                </Fragment>
            ))}
        </Flex>
    )
}

function ObjectListItem({ isUserList, object }) {
    const [saving, setSaving] = useState()
    const { changeObject } = useObject(object._sid)

    const { showManageData } = useSlidingPane()

    // Do not allow objects used in user table or customer lists to be disabled.
    // But if for some reason they are disabled, make sure the user can enable them
    const lockedUserTable = isUserList && !object?.connection_options?.data_mapping_disabled

    const handleToggle = () => {
        setSaving(true)
        changeObject({
            connection_options: {
                ...object.connection_options,
                data_mapping_disabled: !object.connection_options.data_mapping_disabled,
            },
        }).then(() => {
            invalidateNavigation()
            setSaving(false)
        })
    }

    const handleManageTable = () => {
        showManageData(object?._sid)
    }

    return (
        <Flex wrap="nowrap" align="center">
            <ConditionalWrapper
                condition={lockedUserTable}
                wrapper={(children) => (
                    <Tooltip
                        label="You cannot disable a connected user table. Please first disconnect it to continue."
                        position="bottom"
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <Switch
                    size="sm"
                    isChecked={!object.connection_options.data_mapping_disabled}
                    onChange={handleToggle}
                    isDisabled={saving || lockedUserTable}
                    mt="2px"
                />
            </ConditionalWrapper>

            <Text flexGrow={1} mx={2} my={2} size="sm">
                {object.name}
            </Text>
            {!object.connection_options.data_mapping_disabled && (
                <Button
                    padding="5px"
                    variant="Secondary"
                    size="small"
                    icon="table"
                    data-testid="app-settings.data-sources.manage-table"
                    onClick={handleManageTable}
                >
                    Manage table
                </Button>
            )}
        </Flex>
    )
}

const MissingDataSourceConfig = ({ dc }) => {
    const { name: providerName } = DATA_PROVIDERS[dc.type]

    return (
        <Box mt={4}>
            <Flex>
                <Icon
                    size="24px"
                    icon="table"
                    display="inline-block"
                    verticalAlign="middle"
                    opacity={0.5}
                    mr={3}
                />
                <Text variant="dataSourceTitle">{providerName}</Text>
                <Text display="inline" variant="dataSourceSubTitle" ml={2}>
                    (not yet supported)
                </Text>
            </Flex>
        </Box>
    )
}
