import { useMemo } from 'react'

import { compact, sortBy } from 'lodash'

export const getDefaultFieldsOrder = (object?: ObjectDto): string[] => {
    const primaryField = object?.fields?.find((f: FieldDto) => f.is_primary)

    const otherFields = sortBy(object?.fields, 'created_date')?.filter(
        (f) => !f.is_primary && !f.connection_options?.is_disabled
    )

    return compact([primaryField?.api_name, ...otherFields?.map((f) => f.api_name)])
}

export const sortFieldsByOrder = (
    fields: FieldDto[] | undefined,
    object?: ObjectDto | undefined,
    apiNameKey: string = 'api_name'
) => {
    const fieldsOrder = object?.options?.fields_order || getDefaultFieldsOrder(object)
    return sortBy(
        fields,
        (f) => {
            // @ts-ignore
            const apiName: FieldDto['api_name'] = f[apiNameKey]
            return fieldsOrder.findIndex((x) => x === apiName) > -1
                ? fieldsOrder.findIndex((x) => x === apiName)
                : Number.MAX_VALUE
        },
        'asc'
    )
}

export const useDefaultFieldsOrder = (object?: ObjectDto): string[] => {
    return useMemo(() => getDefaultFieldsOrder(object), [object])
}

export const useSortingByFieldsOrder = (object?: ObjectDto) => {
    return (fields: FieldDto[], apiNameKey: string = 'api_name') =>
        sortFieldsByOrder(fields, object, apiNameKey)
}
