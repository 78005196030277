import { useEffect } from 'react'

import Cohere from 'cohere-js'

const CohereVoiceWidget = () => {
    useEffect(() => {
        Cohere.isAvailable((available) => {
            if (available) {
                Cohere.widget('show')
            }
        })
    }, [])

    return () => {
        Cohere.widget('hide')
    }
}

export default CohereVoiceWidget
