import React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { Input } from 'v2/ui'

import { RenderInlineElement } from './ui'

/**
 * @param {{name: string, label?: string, defaultValue?: any, subtitle? :string, errorMessages?: {[keyof: string]: string}} & Partial<import('react-hook-form').RegisterOptions> & any} param0
 */
export default function InputForm({
    name,
    label,
    defaultValue = null,
    required = false,
    validate,
    pattern = undefined,
    errorMessages = {},
    subtitle = '',
    children,
    innerStyle,
    style,
    customSubtitleRender,
    ...props
}) {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <RenderInlineElement
            label={label}
            error={error}
            errorMessages={errorMessages}
            subtitle={subtitle}
            innerStyle={innerStyle}
            style={style}
            customSubtitleRender={customSubtitleRender}
        >
            <Input
                defaultValue={defaultValue}
                {...register(name, { required, pattern, validate })}
                {...props}
                className={error ? 'invalid' : ''}
            />
            {children}
        </RenderInlineElement>
    )
}
