type getNextWorkspaceUrlProps = {
    accounts?: Account[]
    studioUser?: UserDto
}

export const NO_ACCOUNT_REDIRECT_URL = 'https://stackerhq.com'

export const getNextWorkspaceUrl = ({ accounts, studioUser }: getNextWorkspaceUrlProps) => {
    // if there are no workspaces, then go back to the stacker homepage
    if (!accounts || !accounts.length || !studioUser) return NO_ACCOUNT_REDIRECT_URL

    const nextAccount = accounts[0]

    let url = `//${nextAccount.url}/`

    // if the workspace is using auth0 or we don't have an api_token on hand,
    // send them to login page with our current user ID in the check_user param so it
    // will redirect them to home if the right user is logged in.
    //
    // Otherwise we can log them in using the api_token automatically.
    if (!nextAccount.optional_features?.auth0_enabled && studioUser.api_token) {
        url += `auth?ignoreProfile=1&token=${studioUser.api_token}`
    } else {
        url += `login?check_user=${studioUser._sid}`
    }

    return url
}
