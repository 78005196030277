import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import Button from 'ui/deprecated/atoms/Button'

/**
 *
 * @param {{hideByDefault?: boolean, children: any, style?: any}} props
 */
export default function DiscardButton(props) {
    const { formState, reset } = useFormContext()

    const hasChanges = useMemo(() => {
        return Object.keys(formState.dirtyFields).length > 0
    }, [formState])

    if (props.hideByDefault && !hasChanges) return null

    return (
        <Button
            type="secondary"
            disabled={!hasChanges || formState.isSubmitting}
            onClick={() => reset()}
            {...props}
        />
    )
}
