import React from 'react'

import { forwardRef, MenuItem, useMenuItem } from '@chakra-ui/react'

import { InlineFilterInput } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterInput'

import { InlineFilterInputComponentProps } from './types'
import { validateDayCounterValue } from './utils'

function omitMenuItemProps(menuItemProps: ReturnType<typeof useMenuItem>) {
    const {
        // These props break the input's functionality.
        /* eslint-disable @typescript-eslint/no-unused-vars */
        /* eslint-disable unused-imports/no-unused-vars */
        onKeyUp,
        onKeyDown,
        role,
        type,
        onClick,
        onMouseMove,
        onMouseLeave,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        /* eslint-enable unused-imports/no-unused-vars */
        ...rest
    } = menuItemProps

    return rest
}

type DayCounterInputProps = React.ComponentPropsWithoutRef<typeof MenuItem> &
    InlineFilterInputComponentProps & {}

export const DayCounterInput = forwardRef<DayCounterInputProps, 'input'>(
    ({ value = '', onChange, ...props }, ref) => {
        const menuItemProps = useMenuItem(props, ref)
        const { onMouseEnter, ...rest } = omitMenuItemProps(menuItemProps)

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            const isValid = validateDayCounterValue(value)
            if (!isValid) return

            onChange?.(value)
        }

        return (
            <InlineFilterInput
                {...rest}
                onFocus={onMouseEnter}
                placeholder="Enter a value"
                rightAddonText="days"
                onChange={handleChange}
                inputMode="numeric"
                value={value}
                groupProps={{ onChange: undefined }}
                {...props}
            />
        )
    }
)
