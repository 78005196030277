import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import AppContext from 'app/AppContext'
import { useObjects } from 'data/hooks/objects'
import { useStackPages } from 'data/hooks/pages'
import { useUpdateStack } from 'data/hooks/stacks'
import CodeEditor from 'features/admin/settings/appearance/CodeEditor'
import getAutocompleteClasses from 'features/admin/settings/appearance/getAutocompleteClasses'
import { ColorPickerDropdown } from 'features/workspace/forms/ColorPicker'
import ImageUploadForm from 'features/workspace/forms/ImageUpload'

import { Banner, Box, Button, Dropdown, Flex, Text } from 'v2/ui'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

import { getNavigationColor } from '../../../v2/ui/hooks/useNavigationColor'
import { useAppSettings } from '../../workspace/AdminSideTray/hooks/useAppSettings'
import {
    CollapsableSection,
    Divider,
    FormFrame,
    SettingLabel,
    SettingRow,
} from '../../workspace/WorkspaceSettingsModalUi'

import MigrateCssModal from './MigrateCssModal'

const CustomerAccessAppearance = ({ formRef, children }) => {
    const { selectedStack } = React.useContext(AppContext)
    const { mutateAsync: updateStack } = useUpdateStack()
    const { open: openAppSettings } = useAppSettings()

    const isCustomerAccessCustomCssLocked = isFeatureLocked(FEATURES.customCss, selectedStack)
    const [isMigrateModalOpen, setIsMigrateModalOpen] = useState(false)
    const handleGoToAppearance = () => {
        openAppSettings({ page: { name: 'appearance' } })
    }
    const handleMigrateCss = () => setIsMigrateModalOpen(true)

    const handleSave = (data) => {
        // Unclear why external_access is not included in patch. No capacity to investigate.
        // eslint-disable-next-line unused-imports/no-unused-vars
        let { external_access, ...patch } = data

        return updateStack({
            id: selectedStack._sid,
            patch: {
                options: {
                    ...selectedStack.options,
                    vertical_nav: patch.navPosition
                        ? patch.navPosition === 'left'
                            ? true
                            : false
                        : selectedStack.options?.vertical_nav,
                    custom_css: patch.custom_css,
                    external_access: { ...selectedStack.options?.external_access, ...patch.theme },
                },
            },
        })
    }

    return (
        <>
            <Form
                onSubmit={handleSave}
                options={{
                    defaultValues: {
                        theme: {
                            logo:
                                selectedStack.options?.external_access?.logo ||
                                selectedStack.options?.theme?.logo,
                            icon: selectedStack.options?.external_access?.icon,
                            navColor:
                                selectedStack.options?.external_access?.navColor ||
                                selectedStack.options?.theme?.navColor ||
                                'light',
                            brandColor:
                                selectedStack.options?.external_access?.brandColor ||
                                selectedStack.options?.theme?.brandColor,
                        },

                        navPosition:
                            (selectedStack.options?.vertical_nav ? 'left' : 'top') || 'top',
                        custom_css: selectedStack.options?.custom_css,
                    },
                }}
                style={{ flex: 1 }}
                resetOnSuccess
            >
                <InnerContent
                    isCustomerAccessCustomCssLocked={isCustomerAccessCustomCssLocked}
                    handleGoToAppearance={handleGoToAppearance}
                    handleMigrateCss={handleMigrateCss}
                    formRef={formRef}
                >
                    {children}
                </InnerContent>
            </Form>
            <MigrateCssModal
                isOpen={isMigrateModalOpen}
                onClose={(e) => {
                    e.preventDefault()
                    setIsMigrateModalOpen(false)
                }}
            />
        </>
    )
}

const imageRowProps = {
    leftSideStyle: { flexBasis: '30%' },
    rightSideStyle: { flexBasis: '70%', alignItems: 'flex-end' },
}

function InnerContent({
    formRef,
    isCustomerAccessCustomCssLocked,
    handleGoToAppearance,
    handleMigrateCss,
    children,
}) {
    const formContext = useFormContext(formRef)
    let {
        theme: { logo, icon },
    } = formContext.watch()

    function deleteField(name) {
        formContext.setValue(name, undefined, { shouldDirty: true })
    }

    const { pages } = useStackPages()
    const { objects } = useObjects()

    return (
        <>
            <FormFrame title="Appearance" formRef={formRef}>
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Logo</SettingLabel>}
                    rightSide={
                        <ImageField field="theme.logo" value={logo} onClearField={deleteField} />
                    }
                    {...imageRowProps}
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Icon</SettingLabel>}
                    rightSide={
                        <ImageField field="theme.icon" value={icon} onClearField={deleteField} />
                    }
                    {...imageRowProps}
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Brand color</SettingLabel>}
                    rightSide={
                        <FormField
                            as={ColorPickerDropdown}
                            name="theme.brandColor"
                            formRef={formRef}
                            place="below"
                            controlled
                        />
                    }
                    {...imageRowProps}
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Navigation bar color</SettingLabel>}
                    rightSide={
                        <FormField
                            as={Dropdown}
                            name="theme.navColor"
                            formRef={formRef}
                            variant="settings"
                            options={[
                                { value: 'brand', label: 'Brand color' },
                                { value: 'dark', label: 'Dark grey' },
                                { value: 'light', label: 'White' },
                            ]}
                            controlled
                            width="200px"
                        />
                    }
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Navigation bar position</SettingLabel>}
                    rightSide={
                        <FormField
                            as={Dropdown}
                            name="navPosition"
                            formRef={formRef}
                            variant="settings"
                            options={[
                                { label: 'Top', value: 'top' },
                                { label: 'Left', value: 'left' },
                            ]}
                            controlled
                            width="200px"
                        />
                    }
                />
                {!isCustomerAccessCustomCssLocked ? (
                    <>
                        <Divider dense />
                        <CollapsableSection header={<SettingLabel>Custom CSS</SettingLabel>}>
                            <Banner icon="info">
                                <Flex>
                                    <Text>
                                        Custom CSS will move to{' '}
                                        <Button
                                            variant="clear"
                                            color="clear"
                                            size="sm"
                                            style={{
                                                padding: 0,
                                                textDecoration: 'underline',
                                                lineHeight: 1,
                                                color: '#2980b9',
                                            }}
                                            onClick={handleGoToAppearance}
                                        >
                                            Appearance
                                        </Button>{' '}
                                        and will be applied app level
                                    </Text>
                                    <Button
                                        variant="adminSecondary"
                                        buttonSize="smDense"
                                        style={{ padding: '5px', marginLeft: '5px' }}
                                        onClick={handleMigrateCss}
                                    >
                                        Migrate CSS
                                    </Button>
                                </Flex>
                            </Banner>
                            <Box border="1px solid #ccc" mt={1}>
                                <FormField
                                    as={CodeEditor}
                                    language="css"
                                    name="custom_css"
                                    formRef={formRef}
                                    autoCompleters={getAutocompleteClasses(objects, pages)}
                                    controlled
                                />
                            </Box>
                        </CollapsableSection>
                    </>
                ) : (
                    <>
                        <Divider dense />
                        <Banner icon="info">
                            Looking for Custom CSS? You can now apply CSS globally from{' '}
                            <Button
                                variant="clear"
                                color="clear"
                                size="sm"
                                style={{
                                    padding: 0,
                                    textDecoration: 'underline',
                                    lineHeight: 1,
                                    color: '#2980b9',
                                }}
                                onClick={handleGoToAppearance}
                            >
                                Appearance
                            </Button>
                        </Banner>
                    </>
                )}
            </FormFrame>
            {children}
        </>
    )
}

function ImageField({ value, field, onClearField }) {
    return (
        <Flex wrap="nowrap" justifyContent="flex-end">
            {value && (
                <>
                    <PreviewBg>
                        <PreviewImage field={field} />
                    </PreviewBg>
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        ml={3}
                        width={100}
                        onClick={() => onClearField(field)}
                    >
                        Remove
                    </Button>
                </>
            )}
            <ImageUploadForm name={field} icon="add">
                {({ onClick }) =>
                    value ? null : (
                        <Button
                            variant="adminSecondary"
                            buttonSize="sm"
                            ml={3}
                            width={100}
                            onClick={onClick}
                            icon="add"
                        >
                            Upload
                        </Button>
                    )
                }
            </ImageUploadForm>
        </Flex>
    )
}

function PreviewBg({ children, style = {} }) {
    const formContext = useFormContext()
    let {
        theme: { brandColor, navColor },
    } = formContext.watch()

    const navTheme = getNavigationColor({ navColor, brandColor })

    return (
        <Flex
            column
            style={{
                background: navTheme.bgScdNav,
                padding: 10,
                borderRadius: 5,
                width: 224,
                alignItems: 'center',
                border:
                    navColor === 'light' ? '1px solid ' + V4DesignSystem.colors.gray[100] : 'none',
                ...style,
            }}
        >
            {children}
        </Flex>
    )
}

/**
 * @param {{field: "logo" | "icon"}} param0
 */
function PreviewImage({ field }) {
    const formContext = useFormContext()
    const logo = formContext.watch(field)

    return <img alt={field} src={logo} style={{ height: 30, maxWidth: 200 }} />
}

export default CustomerAccessAppearance
