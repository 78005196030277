import React from 'react'

import { InlineFilterMultiValueInput } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterMultiValueInput'

import { InlineFilterInputComponent } from './types'

type SingleValueDropdownInputProps = {}

export const SingleValueDropdownInput: InlineFilterInputComponent<
    SingleValueDropdownInputProps
> = ({ value = [], onChange, options }) => {
    return (
        <InlineFilterMultiValueInput
            key="singleValueDropdown"
            options={options}
            onChange={onChange}
            value={value as string[]}
            singleValue={true}
        />
    )
}
