import { getToday } from './getToday'

export const TIME_PERIOD_FILTERS = [
    {
        label: 'Last 24 hours',
        currentPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday()
            const startDate = today.clone().subtract(23, 'hours').toISOString()
            const endDate = today.toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        previousPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday()
            const startDate = today.clone().subtract(47, 'hours').toISOString()
            const endDate = today.clone().subtract(24, 'hours').toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        value: 'last24hours',
    },
    {
        label: 'last 7 days',
        currentPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday(true)
            const startDate = today.clone().subtract(6, 'days').toISOString()
            const endDate = today.clone().endOf('day').toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        previousPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday(true)
            const startDate = today.clone().subtract(13, 'days').toISOString()
            const endDate = today.clone().endOf('day').subtract(7, 'days').toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        value: 'last7days',
    },
    {
        label: 'last 30 days',
        currentPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday(true)
            const startDate = today.clone().subtract(29, 'days').toISOString()
            const endDate = today.clone().endOf('day').toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        previousPeriod: (fieldApiName: string): ChartFilter[] => {
            const today = getToday(true)
            const startDate = today.clone().subtract(59, 'days').toISOString()
            const endDate = today.clone().endOf('day').subtract(30, 'days').toISOString()
            return [
                {
                    target: fieldApiName,
                    operation: 'gte',
                    value: startDate,
                },
                {
                    target: fieldApiName,
                    operation: 'lte',
                    value: endDate,
                },
            ]
        },
        value: 'last30days',
    },
    {
        label: 'Current day',
        currentPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_today',
            },
        ],
        previousPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_yesterday',
            },
        ],
        value: 'currentDay',
    },
    {
        label: 'Current week',
        currentPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_this_week',
            },
        ],
        previousPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_last_week',
            },
        ],
        value: 'currentWeek',
    },
    {
        label: 'Current month',
        currentPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_this_month',
            },
        ],
        previousPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_last_month',
            },
        ],
        value: 'currentMonth',
    },
    {
        label: 'Current year',
        currentPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_this_year',
            },
        ],
        previousPeriod: (fieldApiName: string): ChartFilter[] => [
            {
                target: fieldApiName,
                operation: null,
                value: '_last_year',
            },
        ],
        value: 'currentYear',
    },
]
