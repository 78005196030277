import { getIsRelationshipField, getIsSymmetricRelationshipField } from '../../utils/fieldUtils'

export const fieldHasError = (field, errors) => {
    if (errors?.find((err) => err.field_id === field._sid)) return true
    return false
}

export const checkRuleErrors = (rule, errors) => {
    if (errors?.find((err) => err.field_id === rule.sid)) return true
    return false
}

export const checkFilterErrors = (filter, errors) => {
    if (errors?.find((err) => err.filter_id === filter._id)) return true
    return false
}

export const isSyntheticLink = (field) => {
    return getIsRelationshipField(field) || getIsSymmetricRelationshipField(field)
}
