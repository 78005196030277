// @ts-strict-ignore
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { processStaticFilter } from 'features/records/components/logic/recordLogic'
import { SettingLabel } from 'features/workspace/WorkspaceSettingsModalUi'

import { Text } from 'v2/ui'

import UserList from '../shared/UserList'

type AutomaticUserListProps = {
    emailFieldApiName: string
    records: any[]
}

const AutomaticUserList: React.FC<AutomaticUserListProps> = ({ emailFieldApiName, records }) => {
    const { watch } = useFormContext()
    const filters = watch('options.filters')
    const filteredRecords = useMemo(
        () =>
            processStaticFilter(
                records.filter((record) => record[emailFieldApiName]),
                filters
            ).map((record) => ({
                _sid: record._sid,
                name: record._primary,
                email: record[emailFieldApiName],
            })),
        [records, filters, emailFieldApiName]
    )

    return (
        <>
            <SettingLabel>
                Who can access
                <Text color="neutral.800" display="inline" ml={2}>
                    ({filteredRecords.length})
                </Text>
            </SettingLabel>
            <UserList users={filteredRecords} hideGroups disableScrollBox />
        </>
    )
}

export default AutomaticUserList
