import { fetchAndReturn } from '../utils/utils'

export const importClipboardData = (
    data: string,
    tableName: string,
    generateHeaders: boolean
): Promise<any> => {
    return fetchAndReturn(`clipboard/import/`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            data: data,
            name: tableName,
            generate_headers: generateHeaders,
        }),
    }) as Promise<any>
}
export const previewClipboardData = (data: string, generateHeaders: boolean): Promise<any> => {
    return fetchAndReturn(`clipboard/preview/`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            data: data,
            generate_headers: generateHeaders,
        }),
    }) as Promise<any>
}
