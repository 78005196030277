// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { findIcon } from 'features/workspace/forms/IconPicker'

// @ts-ignore
import { Flex, Text } from 'v2/ui'

import { ProviderTypes } from '../../admin/data-connector/dataConnectorTypes'

import { ProviderPickerItem } from './providerPickerTypes'

type ResponsiveProviderPickerProps = {
    items: ProviderPickerItem<string>[]
    onClick: (id: ProviderTypes) => void
}

export const ResponsiveProviderPickerRowContainer = styled(Flex)`
    border: 1px solid #dedee0;
    cursor: pointer;
    border-radius: 4px;
    height: 60px;
    margin-bottom: 12px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
`

export const ResponsiveProviderPicker: React.FC<ResponsiveProviderPickerProps> = (props) => {
    return (
        <>
            {props.items.map((provider) => (
                <ResponsiveProviderRow key={provider.id} item={provider} onClick={props.onClick} />
            ))}
        </>
    )
}

export const ResponsiveProviderRow: React.FC<{
    item: ProviderPickerItem<string>
    onClick: (id: string) => void
}> = (props) => {
    const isStringIcon: boolean = typeof props.item.icon === 'string'
    const [icon, setIcon] = React.useState<'LOADING' | any>('LOADING')
    if (isStringIcon) {
        const fetchedIcon = findIcon(props.item.icon)
        if (fetchedIcon) {
            setIcon(fetchedIcon)
        }
    }
    return (
        <ResponsiveProviderPickerRowContainer onClick={() => props.onClick(props.item.id)}>
            <Flex>
                {!isStringIcon ? (
                    <props.item.icon style={{ height: 36, width: 36 }} />
                ) : icon === 'LOADING' ? (
                    'Loading'
                ) : (
                    <FontAwesomeIcon
                        icon={icon}
                        style={{
                            height: 36,
                            width: 36,
                            padding: 6,
                            borderRadius: 4,
                            background: '#e27a1b',
                            color: 'white',
                        }}
                    />
                )}
                <Text style={{ fontWeight: 'bold', marginLeft: 16 }}>{props.item.name}</Text>
            </Flex>
            {/* @ts-igFre */}
            <FontAwesomeIcon icon={faArrowRight} />
        </ResponsiveProviderPickerRowContainer>
    )
}
