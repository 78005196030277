import React, { useMemo } from 'react'

import { withPages } from 'data/wrappers/WithPages'
import { withViews } from 'data/wrappers/WithViews'

const rolePageTypes = new Set(['list', 'dashboard', 'blankpage'])

function isRolePage(page?: PageDto) {
    return page?.options?.view_type && rolePageTypes.has(page.options.view_type)
}

function getViewBySid(sid: string, views?: ViewDto[]): ViewDto | undefined {
    return views?.find((view) => view._sid === sid)
}

type RolePagesProps = {
    pages?: PageDto[]
    views?: ViewDto[]

    children: (props: { pages: PageDto[] }) => React.ReactElement | null
}

export const RolePages: React.FC<RolePagesProps> = ({ pages, children, views }) => {
    const availablePages = useMemo(() => {
        if (!pages) return []

        return pages.reduce<PageDto[]>((acc, curr) => {
            if (isRolePage(curr) && curr?.options?.view_id && !curr.is_feature_settings) {
                acc.push({
                    ...curr,
                    name: getViewBySid(curr.options.view_id, views)?.name ?? '',
                })
            }

            return acc
        }, [])
    }, [pages, views])

    if (!pages) return null

    return children({ pages: availablePages })
}

export default withPages(withViews(RolePages))
