// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { invalidateNavigation } from 'data/hooks/navigation'
import { deleteObject, invalidateObjects, updateObject } from 'data/hooks/objects/objectOperations'
import { getDeleteTableModalData } from 'features/admin/settings/object/utils'

import { OrderableListSelector } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

export type TableOrderableListProps = {
    closeDropdown: () => void
    tables: ObjectDto[]
    selectedTableId: string | undefined
    onSelectTable: (table: ObjectDto) => void
    onTableActivated?: (table: ObjectDto) => void
    onTableDeactivated?: (table: ObjectDto) => void
    dataConnection?: DataConnectionDto
}

export const TableOrderableList = ({
    dataConnection,
    closeDropdown,
    tables,
    selectedTableId,
    onSelectTable,
    onTableActivated,
    onTableDeactivated,
}: TableOrderableListProps): React.ReactElement => {
    const provideTableKey = useCallback((table: ObjectDto & { id: string }) => table._sid, [])
    const provideTableLabel = useCallback(
        (table: ObjectDto & { id: string; label: string }) => table.name,
        []
    )

    const selectTable = useCallback(
        (table: ObjectDto & { id: string; label: string }) => {
            if (!table.connection_options.data_mapping_disabled) {
                closeDropdown()
                onSelectTable(table)
            }
        },
        [closeDropdown, onSelectTable]
    )

    const { show: showConfirm, setData } = useConfirmModal()
    const isStackerTable = dataConnection?.type === 'native_tables'
    const allTableItems = useMemo(
        () =>
            tables.map((table) => {
                const data = getDeleteTableModalData(table)

                const onConfirm = (modal) => {
                    setData({ ...data, isSaving: true })
                    deleteObject(table?._sid)
                        .then(() => {
                            invalidateObjects()
                        })
                        .then(() => {
                            setData({ ...data, isSaving: false })
                            modal.toggle()
                        })
                }

                const item = {
                    ...table,
                    id: table._sid,
                    isSelected: selectedTableId === table._sid,
                    labelColor:
                        selectedTableId === table._sid
                            ? colors.userInterface.accent[1000]
                            : undefined,
                    props: {
                        onClick: !table.connection_options.data_mapping_disabled
                            ? // @ts-ignore
                              () => selectTable(table)
                            : undefined,
                        hideBorder: true,
                        hoverBackgroundColor: colors.userInterface.neutral[150],
                        ignoreHoverStates: true,
                        style: {
                            borderRadius: '5px',
                            margin: 0,
                        },
                    },
                }

                if (isStackerTable) {
                    item['actions'] = [
                        {
                            icon: 'trash',
                            onClick: () => {
                                showConfirm({ ...data, onConfirm })
                            },
                            title: 'Delete table',
                            color: 'colors.neutral[600]',
                            style: {
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                marginLeft: '10px',
                                backgroundColor: 'transparent',
                                fontSize: '14px',
                            },
                        },
                    ]
                }

                return item
            }),
        [tables, selectedTableId, isStackerTable, setData, selectTable, showConfirm]
    )

    const selectedTablesItems = useMemo(
        () => allTableItems.filter((table) => !table.connection_options.data_mapping_disabled),
        [allTableItems]
    )

    const toggleTable = async (table: ObjectDto) => {
        if (!table) return
        try {
            await updateObject(
                table._sid,
                {
                    connection_options: {
                        ...table.connection_options,
                        data_mapping_disabled: !table.connection_options.data_mapping_disabled,
                    },
                },
                { allowOptimisticUpdates: true }
            )

            await invalidateNavigation()
        } catch (error) {}
    }

    const onActivateTable = (tableToActivate: ObjectDto & { id: string; label: string }) => {
        toggleTable(tableToActivate)
        onTableActivated?.(tableToActivate)
    }

    const onDeactivateTable = (
        newSelectedTableItems: (ObjectDto & { id: string; label: string })[]
    ) => {
        const newSelectedTableItemsId = newSelectedTableItems.map((tableItem) => tableItem._sid)
        const tableToDeactivate = selectedTablesItems.find(
            ({ _sid }) => !newSelectedTableItemsId.includes(_sid)
        )

        if (!tableToDeactivate) {
            return
        }

        toggleTable(tableToDeactivate)
        onTableDeactivated?.(tableToDeactivate)
    }

    return (
        <OrderableListSelector
            maxHeight="calc(60vh - 100px)"
            items={allTableItems}
            selectedItems={selectedTablesItems}
            searchInputProps={{
                placeholder: 'Search tables',
            }}
            emptySearch={{
                label: (filterValue: string) =>
                    `No tables match the search '${filterValue}'. Please try again.`,
            }}
            provideKey={provideTableKey}
            provideLabel={provideTableLabel}
            onAdd={onActivateTable}
            onUpdate={onDeactivateTable}
            onKeyboardItemSelected={selectTable}
            autofocus
            disableReorder
        />
    )
}
