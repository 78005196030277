import { invalidateObjectCache } from 'data/hooks/cacheInfo/cacheInfoOperations'
import { invalidateFeatures, refetchFeatures } from 'data/hooks/features'
import { invalidateNavigation, refetchNavigation } from 'data/hooks/navigation'
import { invalidatePages, refetchPages } from 'data/hooks/pages'
import { invalidateViews, refetchViews } from 'data/hooks/views'

import { invalidatePermissions, refetchPermissions } from './permissions/permissionOperations'
import { invalidateDataConnections, refetchDataConnections } from './dataConnections'
import { invalidateFields, refetchFields } from './fields'

export const reloadMetadataAfterObjectChange = async (waitForNavObjects = false) => {
    // After adding a new object, there is lots of metadata to be refreshed.
    invalidateObjectCache()

    if (!waitForNavObjects) {
        invalidatePermissions()
        invalidateDataConnections()
        invalidateFeatures()
        invalidateViews()
        invalidateNavigation()
        invalidatePages()
        invalidateFields()
    } else {
        const promises = [
            refetchPermissions(),
            refetchDataConnections(),
            refetchFeatures(),
            refetchViews(),
            refetchNavigation(),
            refetchPages(),
            refetchFields(),
        ]
        await Promise.all(promises)
    }
}
