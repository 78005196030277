import React from 'react'

import { ButtonProps } from '@chakra-ui/react'

import { Button } from 'v2/ui'

type ToolbarButtonProps = {
    icon?: string
    svgIcon?: JSX.Element
    color?: string
    iconColor?: string
} & ButtonProps

export const ToolbarButton: React.FC<ToolbarButtonProps> = React.forwardRef(
    (
        {
            children,
            color = 'userInterface.neutral.800',
            iconColor = 'userInterface.accent.1000',
            ...props
        },
        ref
    ) => {
        return (
            <Button
                ref={ref}
                variant="Tertiary"
                buttonSize="medium"
                iconColor={iconColor}
                {...props}
                color={color}
            >
                {children}
            </Button>
        )
    }
)
