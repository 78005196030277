// @ts-strict-ignore
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { arrayMove } from 'react-sortable-hoc'

import { Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { isEqual } from 'lodash'
import { CreateDashboardButton } from 'v2/views/Dashboard/CreateDashboard'

import { AppContext } from 'app/AppContext'
import {
    invalidateNavigation,
    refetchNavigation,
    useNavigation,
    useUpdateNavigation,
} from 'data/hooks/navigation'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import { fetchWithAuth } from 'data/utils/utils'
import { openManageUserSettingsModal } from 'utils/modalOpeners'

import { Box, Button, Divider, Dropdown, Flex, Text, Toast } from 'v2/ui'

import { findFirstEnablednavItem, useNavTree } from '../NavigationUtils'

import NavigationEmptyState from './NavigationEmptyState'
import NavigationPicker from './NavigationPicker'

type MenuStyle = 'top' | 'left'
const menuStyleOptions = [
    { label: 'Top', value: 'top' },
    { label: 'Left', value: 'left' },
]

type MenuColor = 'DEFAULT' | 'brand' | 'dark' | 'light'
const menuColorOptions = [
    { value: 'DEFAULT', label: 'Workspace default' },
    { value: 'brand', label: 'App color' },
    { value: 'dark', label: 'Dark grey' },
    { value: 'light', label: 'Light' },
]

const Header = styled(Flex)`
    flex: 1;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 10px;
`

type Props = {
    onCloseSettingsModal: () => void
}

const NewNavigationEditor: FC<Props> = ({ onCloseSettingsModal }) => {
    const [navigation, setNavigation] = useState<NavigationDto[]>([])
    const [menuStyle, setMenuStyle] = useState<MenuStyle>('top')
    const [menuColor, setMenuColor] = useState<MenuColor>('DEFAULT')
    const [error, setError] = useState<boolean>(false)
    const navTree = useNavTree()
    const { selectedStack, workspaceAccount } = useContext(AppContext)

    let firstEnabledNavItem = findFirstEnablednavItem(navTree)

    const navIcons = selectedStack?.options?.enable_nav_icons

    const { data: allNavigation, isFetching } = useNavigation() as UseQueryResult<NavigationDto[]>
    const { mutateAsync: updateNavigation } = useUpdateNavigation()

    const updateStackOptions = useUpdateStackOptions()

    const patchCurrentStackOptions = useCallback(
        (patch: object, onSuccess?: () => void) => {
            setError(false)
            return updateStackOptions(selectedStack as StackDto, patch)
                .then(() => {
                    if (onSuccess) onSuccess()
                })
                .catch(() => setError(true))
        },
        [selectedStack, updateStackOptions]
    )

    const logSentryError = (msg: string, data: object): void => {
        Sentry.withScope((scope) => {
            Object.keys(data).forEach((key) => {
                scope.setExtra(key, data[key])
            })
            scope.setLevel('error')
            Sentry.captureMessage(`Navigation error: ${msg}`)
        })
    }
    const onUpdateNavigation = useCallback(
        (item: any): void => {
            const updatedNavigation = navigation

            const index = navigation.findIndex((i) => i._sid === item._sid)
            if (index < 0) {
                logSentryError('Unable to find sid on navigation', {
                    sid: item._sid,
                    navigation,
                })
                return
            }

            updatedNavigation[index] = item

            if (item.object_id === null) delete item.object_id
            updateNavigation({ id: item._sid, patch: item })

            setNavigation(updatedNavigation)
        },
        [updateNavigation, navigation]
    )

    const updateNavIcon = useCallback(
        (icon, navItem) => {
            const updatedNavigation = navigation
            const index = navigation.findIndex((i) => i._sid === navItem._sid)
            updatedNavigation[index].options.icon = icon
            onUpdateNavigation(updatedNavigation[index])
        },
        [navigation, onUpdateNavigation]
    )

    const changeHidden = (item: any): void => {
        const updatedItem = { ...item, hidden: !item.hidden }
        onUpdateNavigation(updatedItem)
    }

    const changeLabel = (item: any, newLabel: string): void => {
        if (!newLabel) {
            return
        }

        const updatedItem = { ...item, label: newLabel }
        onUpdateNavigation(updatedItem)
    }

    const changeOrder = (items: NavigationDto[], oldIndex: number, newIndex: number): void => {
        // Now sort by display_order so we have an array that recreates the
        // same list rendered in the nav tree, and can do arrayMove below
        // on this array and have it accurately reflect the reordering that
        // the user has done
        const sortedNav = arrayMove(items, oldIndex, newIndex)
        const updatedDisplayOrder = navigation.map((navigationItem) => {
            const index = sortedNav.findIndex((ni) => ni._sid === navigationItem._sid)
            if (index < 0 || navigationItem.display_order === index + 1) {
                return navigationItem
            }

            const updatedNavItem = { ...navigationItem, display_order: index + 1 }
            updateNavigation({ id: updatedNavItem._sid, patch: updatedNavItem })

            return updatedNavItem
        })

        setNavigation(updatedDisplayOrder)
    }

    const onChangeMenuStyle = (style: MenuStyle, forV3: boolean): void => {
        setMenuStyle(style)

        if (!selectedStack) {
            return
        }

        const isVertical = style === 'left'

        if (forV3) {
            patchCurrentStackOptions({ vertical_nav: isVertical })
        } else {
            patchCurrentStackOptions({ vertical_nav_v4: isVertical })
        }
    }

    const setStackNavColor = (value: MenuColor): void => {
        setMenuColor(value)

        if (!selectedStack) {
            return
        }

        patchCurrentStackOptions({
            theme: {
                ...selectedStack.options.theme,
                navColor: value === 'DEFAULT' ? undefined : value,
            },
        })
    }

    const setStackNavIcons = (): void => {
        if (!selectedStack) {
            return
        }

        patchCurrentStackOptions({ enable_nav_icons: !navIcons }, () => {
            invalidateNavigation()
        })
    }

    const toggleAppName = (): void => {
        if (!selectedStack) {
            return
        }

        patchCurrentStackOptions({
            navbar_hide_app_name: !selectedStack.options.navbar_hide_app_name,
        })
    }

    const onResetNavigation = async (): Promise<void> => {
        await fetchWithAuth('navigation-reset/', {})
        refetchNavigation()
    }

    useEffect(() => {
        if (!allNavigation) {
            return
        }

        const stackNavigation = allNavigation.filter(
            (item: any) => item.stack_id === selectedStack?._sid
        )

        if (!isEqual(stackNavigation, navigation)) {
            setNavigation(stackNavigation)
        }
    }, [allNavigation, navigation, selectedStack])

    useEffect(() => {
        const isVertical = workspaceAccount
            ? selectedStack?.options?.vertical_nav_v4 ?? true
            : selectedStack?.options?.vertical_nav

        setMenuStyle(isVertical ? 'left' : 'top')

        setMenuColor(selectedStack?.options?.theme?.navColor ?? 'DEFAULT')
    }, [workspaceAccount, selectedStack])

    if (navigation.length === 0) {
        return <NavigationEmptyState onReset={onResetNavigation} />
    }

    return (
        <>
            <Header>
                <Text variant="adminHeading">Navigation</Text>
                <Flex>
                    {/* @ts-ignore */}
                    <CreateDashboardButton
                        mr="5px"
                        onCloseSettingsModal={onCloseSettingsModal}
                        isSecondLayer
                    />
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        icon="cog"
                        mr="5px"
                        onClick={openManageUserSettingsModal}
                    >
                        Advanced
                    </Button>
                </Flex>
            </Header>
            <Flex mb={2}>
                {selectedStack?.options?.workspace_app && (
                    <>
                        <Divider style={{ width: '100%', margin: '8px 0' }} />
                        <Flex style={{ marginBottom: '8px' }}>
                            <Flex column alignItems={'start'} mr={[10]}>
                                <Text style={{ fontWeight: 'bold', fontSize: '10pt' }} margin={[0]}>
                                    Navigation placement
                                </Text>
                                <Dropdown
                                    options={menuStyleOptions}
                                    value={menuStyle}
                                    onChange={(value: MenuStyle) => onChangeMenuStyle(value, false)}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </Flex>
                            <Flex column alignItems={'start'}>
                                <Text style={{ fontWeight: 'bold', fontSize: '10pt' }} margin={[0]}>
                                    Color
                                </Text>
                                <Dropdown
                                    options={menuColorOptions}
                                    value={menuColor}
                                    onChange={setStackNavColor}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </Flex>
                        </Flex>
                        <Flex
                            column
                            alignItems={'start'}
                            ml={[10]}
                            style={{ marginBottom: '19px' }}
                        >
                            <Text style={{ fontWeight: 'bold', fontSize: '10pt' }} margin={[0]}>
                                Hide App Name
                            </Text>
                            <Switch
                                size="sm"
                                isChecked={selectedStack?.options?.navbar_hide_app_name}
                                onChange={toggleAppName}
                                mt="10px"
                            />
                        </Flex>
                    </>
                )}
                {!selectedStack?.options?.workspace_app && (
                    <>
                        <Box mr={2}>
                            <Text>Navigation placement: </Text>
                        </Box>
                        <Dropdown
                            options={menuStyleOptions}
                            value={menuStyle}
                            onChange={(value: MenuStyle) => onChangeMenuStyle(value, true)}
                            isSearchable={false}
                            isClearable={false}
                        />
                    </>
                )}
            </Flex>
            <Divider />
            <Flex>
                <Text style={{ fontWeight: 'bold', fontSize: '10pt' }} margin={[2]}>
                    Show Menu Icons
                </Text>
                <Switch size="sm" isChecked={navIcons} onChange={setStackNavIcons} />
            </Flex>
            <NavigationPicker
                firstEnabledNavItem={firstEnabledNavItem}
                items={navTree}
                changeHidden={changeHidden}
                changeLabel={changeLabel}
                changeOrder={changeOrder}
                isTopLevel
                navIcons={navIcons}
                updateNavIcon={updateNavIcon}
                isFetching={isFetching}
            />
            {localStorage.getItem('reset_navigation') && (
                <NavigationEmptyState onReset={onResetNavigation} />
            )}
            <Toast
                title="There was an error, please try again."
                show={error}
                onCloseComplete={() => setError(false)}
                status="error"
            />
        </>
    )
}

export default NewNavigationEditor
