// @ts-strict-ignore
import React, { FC } from 'react'

import { BoxProps, ButtonProps, FlexProps } from '@chakra-ui/react'

import Box from 'v2/ui/components/Box'
import Button from 'v2/ui/components/Button'
import Flex from 'v2/ui/components/Flex'
import Icon from 'v2/ui/components/Icon'
import Text from 'v2/ui/components/Text'

import { IconProps } from './Icon'

type Action = Omit<ButtonProps, 'children' | 'variant'> & {
    buttonSize?: string
    content: string
    variant: string
    onClick: () => void
}

type Props = FlexProps & {
    icon: string
    iconProps?: IconProps
    title: string
    titleProps?: BoxProps
    subtitle: string
    actions: Action[]
    svg?: string
    bigIcon?: any
}

const FeatureBanner: FC<Props> = ({
    icon,
    title,
    subtitle,
    titleProps,
    actions = [],
    svg,
    iconProps,
    bigIcon,
    ...props
}) => (
    <Flex border="1px solid" borderColor="gray.200" borderRadius="5px" px={4} py={3} {...props}>
        {bigIcon && (
            <Icon icon={icon} svg={svg} size="36px" color="adminBrand" mr={4} {...iconProps} />
        )}
        <Box flexGrow={1} flexShrink={1} minWidth={0}>
            <Box>
                {(icon || svg) && !bigIcon && (
                    <Icon
                        icon={icon}
                        svg={svg}
                        size="sm"
                        color="adminBrand"
                        display="inline"
                        mr={1}
                        verticalAlign="text-bottom"
                        {...iconProps}
                    />
                )}
                <Text
                    display="inline"
                    fontSize={props.fontSize || 'md'}
                    fontWeight="bold"
                    {...titleProps}
                >
                    {title}
                </Text>
            </Box>
            <Text color="gray.500" mt={1}>
                {subtitle}
            </Text>
        </Box>
        {Boolean(actions?.length) && (
            <Flex ml={4} wrap="noWrap" flexShrink={0}>
                {actions.map(({ content, variant, buttonSize = 'sm', ...props }, idx) => (
                    <Button
                        key={idx}
                        variant={
                            variant ||
                            (idx === actions.length - 1 ? 'adminPrimaryV4' : 'adminSecondaryV4')
                        }
                        buttonSize={buttonSize}
                        ml={4}
                        {...props}
                        onClick={(event) => {
                            if (typeof props.onClick !== 'undefined') {
                                // Prevent calling the outer onClick on Flex.
                                event.stopPropagation()
                                props.onClick()
                            }
                        }}
                    >
                        {content}
                    </Button>
                ))}
            </Flex>
        )}
    </Flex>
)

export default FeatureBanner
