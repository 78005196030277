// @ts-strict-ignore
import React from 'react'

import V4DesignSystem from '../../ui/deprecated/V4DesignSystem'
import { ScrollBox, SidebarItem } from '../../v2/ui'

export const TEMPLATE_CATEGORIES = [
    { label: 'Featured', value: 'featured', icon: 'stars', aboveFold: true },
    { label: 'Operations', value: 'operations' },
    { label: 'HR & Recruiting', value: 'hr_recruiting' },
    { label: 'Local Business', value: 'local_business' },
    { label: 'Non-Profit', value: 'nonprofit' },
]

function TemplateLibrarySidebar({ onCategorySelected, category }) {
    return (
        <ScrollBox
            display="flex"
            flexDirection="column"
            overflowY="auto"
            align="left"
            style={{
                width: 250,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                padding: 20,
            }}
        >
            <SidebarItem
                active={category === 'all'}
                onChange={() => onCategorySelected('all')}
                icon={'brush'}
                key={'category-all'}
            >
                All templates
            </SidebarItem>
            <div style={{ height: 1 }} />
            {TEMPLATE_CATEGORIES.filter((category) => category?.aboveFold).map(
                ({ label, value, icon }) => (
                    <>
                        <SidebarItem
                            active={category === value}
                            onChange={() => onCategorySelected(value)}
                            icon={icon}
                            key={`category-${value}`}
                        >
                            {label}
                        </SidebarItem>
                        <div style={{ height: 1 }} />
                    </>
                )
            )}

            <div style={{ height: 15 }} />
            <div style={{ ...V4DesignSystem.label, paddingLeft: 10 }}>Categories</div>
            <div style={{ height: 5 }} />

            {TEMPLATE_CATEGORIES.filter((category) => !category?.aboveFold).map(
                ({ label, value, icon }) => (
                    <>
                        <SidebarItem
                            active={category === value}
                            onChange={() => onCategorySelected(value)}
                            icon={icon}
                            key={`category-${value}`}
                        >
                            {label}
                        </SidebarItem>
                        <div style={{ height: 1 }} />
                    </>
                )
            )}
        </ScrollBox>
    )
}
export default TemplateLibrarySidebar
