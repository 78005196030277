// @ts-strict-ignore
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Switch, SwitchProps } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { useAppContext } from 'app/AppContext'
import { useRoleListOptions } from 'data/hooks/roleHelpers'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { SettingLabel } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Dropdown, Flex, Icon, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { FormField } from '../../../ui/forms/FormField'
import { encodeFieldKey } from '../../../ui/forms/utils'
import GroupListItem, { GroupListItemProps } from '../shared/GroupListItem'
import UserList from '../shared/UserList'
import UserListItem, { UserListItemProps } from '../shared/UserListItem'

import { UserTableFormData } from './userTableTypes'

const colors = stackerTheme().colors

type ManualUserListProps = {
    emailFieldApiName: string
    records: any[]
    isNewList: boolean
}

const ManualUserList: React.FC<ManualUserListProps> = ({
    emailFieldApiName,
    records,
    isNewList,
}) => {
    const { data: users, isLoading } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: true,
    })

    const { watch } = useFormContext<UserTableFormData>()
    const values = watch()

    const appUsers = useMemo(() => {
        const appUsers = orderBy(
            users
                .filter((user) => user?.role !== 'internal_admin' && user.specified_role)
                // note whether this user has a corresponding record in the user table
                .map((user) => {
                    const hasRecord = records.find(
                        (record) =>
                            record[emailFieldApiName]?.toLowerCase() === user.email?.toLowerCase()
                    )
                    return {
                        ...user,
                        hasRecord,
                        hasAccess: true,
                        defaultDisabled: false,
                    }
                })
                // When editing an existing list, don't show users who aren't in the
                // table. We only do that on creation of the list
                .filter((user) => user.hasRecord || isNewList),
            (user) => user.name || user.email
        )

        const otherUserTableRecords = orderBy(
            records
                .map((record) => ({
                    _sid: record._sid,
                    name: record._primary,
                    email: record[emailFieldApiName]?.toLowerCase(),
                    hasAccess: false,
                    hasRecord: true,
                    defaultDisabled: true,
                }))
                .filter(
                    (record) =>
                        record.email &&
                        !users.find(
                            (user) =>
                                user.email?.toLowerCase() === record.email && user.specified_role
                        )
                ),
            (user) => user.name || user.email
        )

        return appUsers.concat(otherUserTableRecords)
    }, [users, records, emailFieldApiName, isNewList])

    if (isLoading) return null
    const usersWithAccess = appUsers.filter((user) => !isUserDisabled(user, values))

    return (
        <>
            <SettingLabel>
                <Flex justify="space-between">
                    <Box>
                        Who can access
                        <Text color="neutral.800" display="inline" ml={2}>
                            ({usersWithAccess.length})
                        </Text>
                    </Box>
                </Flex>
            </SettingLabel>
            <UserList
                users={appUsers}
                GroupComponent={GroupComponent}
                UserComponent={UserComponent}
                disableSort
            />
        </>
    )
}

const GroupComponent: React.FC<GroupListItemProps> = (props) => {
    const { watch } = useFormContext<UserTableFormData>()
    const values = watch()
    const usersToAdd = props.group.users.filter(
        (user) => !isUserDisabled(user, values) && !user.hasRecord
    )
    const isDisabled = isItemDisabled(props.group._sid, values)
    return (
        <GroupListItem
            {...props}
            rowContainerStyle={{ textDecoration: isDisabled ? 'line-through' : 'none' }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                    {usersToAdd.length > 0 && (
                        <>
                            <Icon icon="addCircle" size="small" color="alertIcon" mr={1} />
                            <Text size="xs" fontWeight="bold" color="neutral.800" mr={2}>
                                {usersToAdd.length}
                            </Text>
                        </>
                    )}
                    <EnabledSwitch sid={props.group._sid} />
                </>
            }
            UserComponent={GroupedUserComponent}
        />
    )
}

const UserComponent: React.FC<UserListItemProps> = React.memo((props) => {
    const { watch, register } = useFormContext<UserTableFormData>()
    const values = watch()
    const { selectedStack } = useAppContext()
    const showRoles = selectedStack?.options?.roles__enabled
    const { data: options } = useRoleListOptions()
    const userKey = encodeFieldKey(props.user._sid)
    const isDisabled = isUserDisabled(props.user, values)
    const showRolesField = !props.user.hasAccess && !isDisabled && showRoles

    return (
        <UserListItem
            {...props}
            limitWidth={showRoles}
            rowContainerStyle={{
                color: !props.user.hasAccess ? colors.neutral[600] : undefined,
                textDecoration: isDisabled && props.user.hasAccess ? 'line-through' : 'none',
            }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                    {showRolesField ? (
                        <FormField
                            maxWidth="150px"
                            width="150px"
                            as={Dropdown}
                            controlledDefaultValue="user"
                            name={`items.${userKey}.role`}
                            options={options}
                            variant="settings"
                            isClearable={false}
                            isSearchable={false}
                            controlled
                        />
                    ) : (
                        <input
                            type="hidden"
                            {...register(`items.${userKey}.role`, {
                                value: props?.user?.specified_role || 'user',
                            })}
                        />
                    )}
                    {!props.user.hasRecord && !isDisabled && (
                        <Icon icon="addCircle" size="small" color="alertIcon" />
                    )}
                </>
            }
            rowActions={
                <EnabledSwitch
                    ml={2}
                    sid={props.user._sid}
                    defaultValue={props.user.defaultDisabled}
                />
            }
        />
    )
})

const GroupedUserComponent: React.FC<UserListItemProps> = (props) => {
    const { watch } = useFormContext<UserTableFormData>()
    const values = watch()
    const isDisabled = isUserDisabled(props.user, values)
    return (
        <UserListItem
            {...props}
            rowContainerStyle={{ textDecoration: isDisabled ? 'line-through' : 'none' }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                    {!props.user.hasRecord && !isDisabled && (
                        <Icon icon="addCircle" size="small" color="alertIcon" />
                    )}
                </>
            }
        />
    )
}

const isItemDisabled = (_sid, values) => values.items?.[encodeFieldKey(_sid)]?.deleted

const isUserDisabled = (user: any, values: UserTableFormData) => {
    let disabledInValues = values.items?.[encodeFieldKey(user._sid)]?.deleted

    if (disabledInValues === undefined && user.group) {
        disabledInValues = values.items?.[encodeFieldKey(user.group)]?.deleted
    }
    return disabledInValues !== undefined ? disabledInValues : user.defaultDisabled
}

type EnabledSwitchProps = Omit<SwitchProps, 'defaultValue'> & {
    sid: string
    defaultValue?: boolean
}
const EnabledSwitch = ({ sid, defaultValue = false, ...props }: EnabledSwitchProps) => {
    return (
        <FormField
            as={({ value, onChange }) => {
                return (
                    <Switch
                        isChecked={!value}
                        onChange={(e) => {
                            // @ts-ignore
                            onChange(!e.target.checked)
                        }}
                        size="sm"
                        {...props}
                    />
                )
            }}
            name={`items.${encodeFieldKey(sid)}.deleted`}
            controlledDefaultValue={defaultValue}
            controlled
        />
    )
}
export default ManualUserList
