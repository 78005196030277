import React, { useEffect, useState } from 'react'
import LightboxBase from 'react-image-lightbox'

import Button from './Button'
const modalStyle = {
    overlay: {
        zIndex: 2000,
    },
}

const stopBubbling = (e) => {
    e.stopPropagation()
}

const Lightbox = ({ images, selectedImage, isOpen, onClose, allowOpen = true }) => {
    const [currentImage, setCurrentImage] = useState(
        selectedImage || (images && images.length && images[0])
    )
    useEffect(() => {
        setCurrentImage(selectedImage)
    }, [selectedImage])

    if (!images || !images.length || !isOpen || !currentImage) return null
    const currentIndex = images.indexOf(currentImage)
    const prevImage =
        images.length > 1
            ? currentIndex > 0
                ? images[currentIndex - 1]
                : images[images.length - 1]
            : null
    const nextImage =
        images.length > 1
            ? currentIndex < images.length - 1
                ? images[currentIndex + 1]
                : images[0]
            : null

    const imageUrl = currentImage.url || currentImage

    const toolbarButtons = allowOpen
        ? [
              <Button
                  key={imageUrl}
                  href={imageUrl}
                  icon="link"
                  variant="clearWhite"
                  buttonSize="sm"
                  opacity={0.8}
                  _hover={{ opacity: 1 }}
              >
                  Open
              </Button>,
          ]
        : []

    return (
        <div onClick={stopBubbling}>
            <LightboxBase
                mainSrc={imageUrl}
                prevSrc={prevImage?.url || prevImage}
                nextSrc={nextImage?.url || nextImage}
                toolbarButtons={toolbarButtons}
                onCloseRequest={onClose}
                reactModalStyle={modalStyle}
                onMovePrevRequest={prevImage && (() => setCurrentImage(prevImage))}
                onMoveNextRequest={nextImage && (() => setCurrentImage(nextImage))}
                wrapperClassName="click-outside-ignore"
            />
        </div>
    )
}

export default Lightbox
