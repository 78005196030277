export const FETCH_STARTED = 'FETCH_STARTED'
export const FETCH_COMPLETED = 'FETCH_COMPLETED'
export const CLEAR_STORE = 'CLEAR_STORE'

export const ACTIONS_FETCHED = 'ACTIONS_FETCHED'
export const ACTION_CREATED = 'ACTION_CREATED'
export const ACTION_UPDATED = 'ACTION_UPDATED'
export const ACTION_REMOVED = 'ACTION_REMOVED'

export const APPS_FETCHED = 'APPS_FETCHED'
export const APP_CREATED = 'APP_CREATED'
export const APP_UPDATED = 'APP_UPDATED'
export const APP_REMOVED = 'APP_REMOVED'
export const APP_SELECTED = 'APP_SELECTED'

export const COMPOUNDS_FETCHED = 'COMPOUNDS_FETCHED'

export const DATA_CONNECTIONS_FETCHED = 'DATA_CONNECTIONS_FETCHED'
export const DATA_CONNECTION_CREATED = 'DATA_CONNECTION_CREATED'
export const DATA_CONNECTION_UPDATED = 'DATA_CONNECTION_UPDATED'
export const DATA_CONNECTION_REMOVED = 'DATA_CONNECTION_REMOVED'

export const EMAIL_TEMPLATES_FETCHED = 'EMAIL_TEMPLATES_FETCHED'
export const EMAIL_TEMPLATE_CREATED = 'EMAIL_TEMPLATE_CREATED'
export const EMAIL_TEMPLATE_UPDATED = 'EMAIL_TEMPLATE_UPDATED'
export const EMAIL_TEMPLATE_REMOVED = 'EMAIL_TEMPLATE_REMOVED'

export const OBJECTS_FETCHED = 'OBJECTS_FETCHED'
export const OBJECTS_CREATED = 'OBJECTS_CREATED'
export const OBJECTS_UPDATED = 'OBJECTS_UPDATED'
export const OBJECTS_REMOVED = 'OBJECTS_REMOVED'
export const OBJECTS_CLEAR = 'OBJECTS_CLEAR'
export const FIELDS_FETCHED = 'FIELDS_FETCHED'
export const FIELDS_CREATED = 'FIELDS_CREATED'
export const FIELDS_UPDATED = 'FIELDS_UPDATED'
export const FIELDS_BULK_UPDATED = 'FIELDS_BULK_UPDATED'
export const FIELDS_REMOVED = 'FIELDS_REMOVED'

export const PERMISSION_GROUP_FETCHED = 'PERMISSION_GROUP_FETCHED'
export const PERMISSION_GROUP_CREATED = 'PERMISSION_GROUP_CREATED'
export const PERMISSION_GROUP_UPDATED = 'PERMISSION_GROUP_UPDATED'
export const PERMISSION_GROUP_REMOVED = 'PERMISSION_GROUP_REMOVED'

export const PERMISSION_RULE_FETCHED = 'PERMISSION_RULE_FETCHED'
export const PERMISSION_RULE_CREATED = 'PERMISSION_RULE_CREATED'
export const PERMISSION_RULE_UPDATED = 'PERMISSION_RULE_UPDATED'
export const PERMISSION_RULE_REMOVED = 'PERMISSION_RULE_REMOVED'

export const RECORDS_FETCHED = 'RECORDS_FETCHED'
export const RECORD_CREATED = 'RECORD_CREATED'
export const RECORD_UPDATED = 'RECORD_UPDATED'
export const RECORD_REMOVED = 'RECORD_REMOVED'
export const CLEAR_RECORD_STORE = 'CLEAR_RECORD_STORE'

export const AUTHS_FETCHED = 'AUTHS_FETCHED'
export const AUTH_CREATED = 'AUTH_CREATED'
export const AUTH_UPDATED = 'AUTH_UPDATED'
export const AUTH_REMOVED = 'AUTH_REMOVED'

export const BLOCKS_FETCHED = 'BLOCKS_FETCHED'
export const BLOCK_CREATED = 'BLOCK_CREATED'
export const BLOCK_UPDATED = 'BLOCK_UPDATED'
export const BLOCK_REMOVED = 'BLOCK_REMOVED'

export const TEMPLATES_FETCHED = 'TEMPLATES_FETCHED'

export const TRANSLATIONS_FETCHED = 'TRANSLATIONS_FETCHED'
export const TRANSLATION_CREATED = 'TRANSLATION_CREATED'
export const TRANSLATION_UPDATED = 'TRANSLATION_UPDATED'
export const TRANSLATION_REMOVED = 'TRANSLATION_REMOVED'
export const ACCEPTED_TRANSLATIONS_FETCHED = 'ACCEPTED_TRANSLATIONS_FETCHED'

export const WORKFLOWS_FETCHED = 'WORKFLOWS_FETCHED'
export const WORKFLOW_CREATED = 'WORKFLOW_CREATED'
export const WORKFLOW_UPDATED = 'WORKFLOW_UPDATED'
export const WORKFLOW_REMOVED = 'WORKFLOW_REMOVED'

export const STACK_SELECTED = 'STACK_SELECTED'

export const STUDIO_USER_LOGGED_IN = 'STUDIO_USER_LOGGED_IN'
export const STUDIO_USER_LOGGED_OUT = 'STUDIO_USER_LOGGED_OUT'
export const END_USER_LOGGED_IN = 'END_USER_LOGGED_IN'
export const END_USER_LOGGED_OUT = 'END_USER_LOGGED_OUT'
export const IMPERSONATION_STARTED = 'IMPERSONATION_STARTED'
export const IMPERSONATION_STOPPED = 'IMPERSONATION_STOPPED'
export const EDITING_STARTED = 'EDITING_STARTED'
export const EDITING_STOPPED = 'EDITING_STOPPED'
export const EDITING_STOP_REQUESTED = 'EDITING_STOP_REQUESTED'
export const EDITING_START_REQUESTED = 'EDITING_START_REQUESTED'
export const DISCARD_STOP_EDITING_REQUEST = 'DISCARD_STOP_EDITING_REQUEST'
export const SET_USED_TEMP_AUTH_TOKEN = 'SET_USED_TEMP_AUTH_TOKEN'
export const USER_UPDATED = 'USER_UPDATED'
export const STUDIO_USER_UPDATED = 'STUDIO_USER_UPDATED'
export const USER_DATA_FETCHED = 'USER_DATA_FETCHED'
export const SET_REDIRECT = 'SET_REDIRECT'
export const BLOCK_EDITING_START = 'BLOCK_EDITING_START'
export const UNBLOCK_EDITING_START = 'UNBLOCK_EDITING_START'
export const BLOCK_EDITING_STOP = 'BLOCK_EDITING_STOP'
export const UNBLOCK_EDITING_STOP = 'UNBLOCK_EDITING_STOP'
export const DISCARD_EDITING_REQUEST = 'DISCARD_EDITING_REQUEST'
export const SET_STREAM_TOKEN = 'SET_STREAM_TOKEN'

export const USER_LIST_FETCHED = 'USER_LIST_FETCHED'
export const USER_LIST_ITEM_ADDED = 'USER_LIST_ITEM_ADDED'

export const SLIDING_PANE_CLOSE = 'SLIDING_PANE_CLOSE'
export const SLIDING_PANE_CLEAR_CONTENT = 'SLIDING_PANE_CLEAR_CONTENT'
export const SLIDING_PANE_OPEN = 'SLIDING_PANE_OPEN'
export const SLIDING_PANE_TRANSITION_TO_WIDTH = 'SLIDING_PANE_TRANSITION_TO_WIDTH'
export const SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO =
    'SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO'
export const SLIDING_PANE_SET_ANIMATION_COMPLETE = 'SLIDING_PANE_SET_ANIMATION_COMPLETE'
export const SLIDING_PANE_SET_CONTENT_DIRTY = 'SLIDING_PANE_SET_CONTENT_DIRTY'
export const SLIDING_PANE_UNSET_CONTENT_DIRTY = 'SLIDING_PANE_UNSET_CONTENT_DIRTY'
export const SLIDING_PANE_BLOCK_CONTENT = 'SLIDING_PANE_BLOCK_CONTENT'
export const SLIDING_PANE_CLEAR_BLOCKED_CONTENT = 'SLIDING_PANE_CLEAR_BLOCKED_CONTENT'
export const SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL = 'SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL'
export const SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL = 'SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL'
export const SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK = 'SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK'

export const PENDING_CHANGES_ADD = 'PENDING_CHANGES_ADD'
export const PENDING_CHANGES_REMOVE = 'PENDING_CHANGES_REMOVE'
export const PENDING_CHANGES_CLEAR = 'PENDING_CHANGES_CLEAR'

export const REACT_QUERY: {
    [key: string]: { listName: REACT_QUERY_DEPS_NAME; endpoint: string }
} = {
    roles: {
        listName: 'useRoles',
        endpoint: 'roles/',
    },
}

export const DATA_CONNECTION_TYPE_VALUES = [
    'airtable',
    'gsheets',
    'stripe',
    'intercom',
    'postgresV2',
    'fivetran',
    'stacker_users',
    'salesforce',
    'stacker',
    'native_tables',
] as const
