import React, { useContext, useMemo } from 'react'

import { CustomListRendererComponent } from 'v2/views/List/types'

import useLDFlags from 'data/hooks/useLDFlags'

import Cards from 'v2/ui/components/Cards'

import { ActionButtonsList } from '../../FieldContainerBlock'
import { DataListBlockContext } from '../DataListBlockContext'
import { DataListBlockEmptyState } from '../DataListBlockEmptyState'
import { DataListBlockFilters } from '../DataListBlockFilters'
import { DataListBlockHeader } from '../DataListBlockHeader'
import { DataListBlockPagination } from '../DataListBlockPagination'
import DataListBlockSearchEmptyState from '../DataListBlockSearchEmptyState'
import { DataListBlockWrapper } from '../DataListBlockWrapper'
import { getPageSizesVariants } from '../utils'

type CardsRendererProps = {}

export const CardsRenderer: CustomListRendererComponent<CardsRendererProps> = ({
    data,
    headers,
    object,
    feature,
    rowLinkFunction,
    additionalContainerContent,
    viewOptions,
    setViewOptions,
    pageCount,
    pageIndex,
    pageSize,
    setPageSize,
    canGoToPreviousPage,
    canGoToNextPage,
    goToPage,
    showPageSizeOptions,
    isServerLoading,
    totalResultCount,
    title,
    showFilters,
    tableColumns,
    setAllFilters,
    filters,
    inlineFilters,
    setInlineFilters,
    userFilterFieldIds,
    searchQuery,
    handleSearch,
    searchColumns,
    records,
    dereferencedRecords,
}) => {
    const { flags } = useLDFlags()
    const dataListBlockContext = useContext(DataListBlockContext)
    const { attrs, context } = dataListBlockContext ?? {}
    const pageSizeOptions = useMemo(() => getPageSizesVariants(attrs?.display), [attrs?.display])

    const isEditing = context?.editor?.isEditing

    const modifiedData = useMemo(() => {
        return data?.map((d) => {
            const newData = { ...d }
            if (
                React.isValidElement<React.ComponentPropsWithoutRef<typeof ActionButtonsList>>(
                    newData.actionButtons
                )
            ) {
                newData.actionButtons = React.cloneElement(newData.actionButtons, {
                    //@ts-expect-error
                    showControls: isEditing,
                })
            }
            return newData
        })
        // Override the action buttons' isEditing state.
    }, [isEditing, data])

    if (!attrs || !context) return null

    const hasData = Boolean(data?.length)
    const hasFilters = flags?.newInlineFilters
        ? (inlineFilters?.length ?? 0) > 0
        : filters && Boolean(Object.values(filters).length)

    const hideCreateButton = attrs.hide_create_button
    const hideSearch = attrs.hide_search_bar
    const subtitle = attrs.subtitle

    const clearSearch = () => {
        handleSearch && handleSearch('', records ?? [])
    }

    return (
        <DataListBlockWrapper>
            <DataListBlockHeader
                title={title}
                subtitle={subtitle}
                hideSearch={hideSearch}
                hideCreateButton={hideCreateButton}
                object={object}
                feature={feature}
                viewOptions={viewOptions}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                searchColumns={searchColumns}
                dereferencedRecords={dereferencedRecords}
                records={records}
                showDivider={false}
                showDataCounter={true}
                totalResultCount={totalResultCount}
            >
                {showFilters && (
                    <DataListBlockFilters
                        tableColumns={tableColumns}
                        setAllFilters={setAllFilters}
                        filters={filters}
                        userFilterFieldIds={userFilterFieldIds}
                        inlineFilters={inlineFilters}
                        setInlineFilters={setInlineFilters}
                        object={object}
                        mt={2}
                    />
                )}
            </DataListBlockHeader>

            {hasData && (
                <>
                    {/* @ts-expect-error */}
                    <Cards
                        mr={4}
                        ml={4}
                        header={headers}
                        data={modifiedData}
                        object={object}
                        rowLink={rowLinkFunction}
                        additionalListContainerContent={additionalContainerContent}
                        viewOptions={viewOptions}
                        setConfig={setViewOptions}
                        gridSpacing="5px"
                        borderStyle="solid"
                    />
                    <DataListBlockPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        canGoToPreviousPage={canGoToPreviousPage}
                        canGoToNextPage={canGoToNextPage}
                        goToPage={goToPage}
                        showPageSizeOptions={showPageSizeOptions}
                        isLoading={isServerLoading}
                        pageSizeOptions={pageSizeOptions}
                    />
                </>
            )}

            {!hasData && !searchQuery && (
                <DataListBlockEmptyState
                    hasFilters={hasFilters}
                    onClearFilters={() =>
                        flags?.newInlineFilters ? setInlineFilters?.([]) : setAllFilters?.([])
                    }
                />
            )}
            {!hasData && searchQuery && <DataListBlockSearchEmptyState clearSearch={clearSearch} />}
        </DataListBlockWrapper>
    )
}

export default CardsRenderer
