import React, { forwardRef } from 'react'

import { Grid } from '@chakra-ui/react'

const countToColumns = (count, spacing) => {
    if (Array.isArray(count)) {
        return count.map((c) => countToColumns(c, spacing))
    }

    // We expect spacing to be a string "12px" or "1.5rem"
    // We split the amount and the untits so we can calculate the total column gaps
    let accountForGap = () => '0px' // Fallback
    if (spacing) {
        const spacingSplit = spacing.match(/^(\d+(\.\d*)?)(.*)$/)
        if (spacingSplit) {
            const spacingAmount = parseFloat(spacingSplit[1])
            const spacingUnits = spacingSplit[3]
            // Account for a total gap of 'spacingAmount*(c-1)' spread across a total of 'c' columns.
            accountForGap = (c) => `${(spacingAmount * (c - 1)) / c}${spacingUnits}`
        }
    }

    if (count !== null && typeof count === 'object' && Object.keys(count).length > 0) {
        const acc = {}
        for (let key in count) {
            acc[key] = `repeat(${count[key]}, calc(${100 / count[key]}% - ${accountForGap(
                count[key]
            )}))`
        }
        return acc
    }

    if (count != null) {
        return `repeat(${count}, calc(${100 / count}% - ${accountForGap(count)}))`
    }

    return null
}

const FixedGrid = forwardRef(({ columns, spacingX, spacingY, spacing, ...props }, ref) => {
    const templateColumns = countToColumns(columns, spacing)
    return (
        <Grid
            ref={ref}
            gap={spacing}
            columnGap={spacingX}
            rowGap={spacingY}
            templateColumns={templateColumns}
            {...props}
        />
    )
})

FixedGrid.displayName = 'FixedGrid'

export default React.memo(FixedGrid)
