import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type EmptySearchProps = {
    size?: number
    lineFill?: string
    blobFill?: string
    magnifierFill?: string
}

const EmptySearch: React.VFC<EmptySearchProps> = ({
    size = 100,
    lineFill = colors.userInterface.accent[400],
    blobFill = colors.userInterface.accent[100],
    magnifierFill = colors.userInterface.accent[1000],
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox={`0 0 100 100`}
        >
            <path
                fill={blobFill}
                d="M10.524 43.77s17.6-.684 21.402-28.731C35.304-9.87 64.981 1.468 73.56 11.397c11.113 12.862 5.19 34.989 19.565 37.784 14.376 2.795 4.988 33.872-16.833 30.898-27.145-3.7-20.578 11.678-28.727 18.187-5.848 4.671-29.828.174-30.627-16.784-.673-14.27-6.882-14.225-11.282-15.843-6.347-2.333-10.347-19.234 4.869-21.87z"
            />
            <path fill={lineFill} d="M48.117 45.847H81.25399999999999V50.386H48.117z" />
            <path fill={lineFill} d="M48.117 45.847H81.25399999999999V50.386H48.117z" />
            <path fill={lineFill} d="M48.117 45.847H81.25399999999999V50.386H48.117z" />
            <path fill={lineFill} d="M48.117 45.847H81.25399999999999V50.386H48.117z" />
            <path
                fill={lineFill}
                d="M48.117 45.847H81.25399999999999V50.386H48.117zM44.032 37.222H97.142V41.761H44.032z"
            />
            <path fill={lineFill} d="M44.032 37.222H97.142V41.761H44.032z" />
            <path fill={lineFill} d="M44.032 37.222H97.142V41.761H44.032z" />
            <path fill={lineFill} d="M44.032 37.222H97.142V41.761H44.032z" />
            <path fill={lineFill} d="M44.032 37.222H97.142V41.761H44.032z" />
            <path
                fill={magnifierFill}
                d="M68.62 75.563L54.792 61.9c-.956-.946-2.498-.937-3.444.02-.945.957-.936 2.5.02 3.445l13.826 13.662c.957.945 2.5.936 3.445-.021.945-.957.936-2.499-.02-3.444z"
            />
            <path
                fill={magnifierFill}
                fillRule="evenodd"
                d="M59.275 47.243c0 11.895-9.643 21.539-21.539 21.539s-21.539-9.644-21.539-21.54c0-11.895 9.643-21.538 21.539-21.538s21.539 9.643 21.539 21.539zm-3.515 0c0 9.954-8.07 18.024-18.024 18.024-9.955 0-18.024-8.07-18.024-18.024 0-9.955 8.07-18.025 18.024-18.025 9.955 0 18.024 8.07 18.024 18.025z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default EmptySearch
