import React, { useCallback } from 'react'

import FieldsEditor from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditor'

import { Box } from 'v2/ui'

type Props = {
    object: ObjectDto
    config: any
    setConfig: (config: any, setDirty?: boolean) => void
}
const ProfileCardEditor: React.FC<Props> = ({ object, setConfig, config }) => {
    const conditionalVisibilityFilters = config?.profileFieldConditionalVisibilityFilters
    const updateConditionalVisibilityFilters = useCallback(
        (filtersMap) => {
            setConfig({ profileFieldConditionalVisibilityFilters: filtersMap })
        },
        [setConfig]
    )
    return (
        <Box height="100%" mt={4}>
            <FieldsEditor
                object={object}
                items={config.profileFields}
                setItems={(items) =>
                    setConfig({
                        profileFields: items,
                    })
                }
                disallowSections
                hideFieldFullWidthSetting
                hideColumns
                isLayoutEditorComponent
                showConditionalVisibility
                conditionalVisibilityFilters={conditionalVisibilityFilters}
                onConditionalVisibilityFiltersChange={updateConditionalVisibilityFilters}
            />
        </Box>
    )
}

export default ProfileCardEditor
