import emojiRegex from 'emoji-regex'

const getInitials = (name) => {
    if (!name || typeof name !== 'string') return null

    // get rid of any emojis and leading spaces
    const regex = emojiRegex()
    const cleanName = name.replace(regex, '').trim()

    // splits words to array
    let nameArray
    try {
        nameArray = cleanName.split(' ')
    } catch {
        nameArray = [' ']
    }

    let initials = ''
    // if it's a single word, return 1st and 2nd character
    if (nameArray.length === 1) {
        return `${nameArray[0].charAt(0)}${nameArray[0].charAt(1)}`.toUpperCase()
    }

    // otherwise return first and last letters
    initials = `${nameArray[0].charAt(0)}${nameArray[nameArray.length - 1].charAt(0)}`

    return initials.toUpperCase()
}

export default getInitials
