import React, { FC } from 'react'

import { AdvancedOptionsItem } from './AdvancedOptionsItem'

type Props = React.ComponentPropsWithoutRef<typeof AdvancedOptionsItem> & {}

const DownloadOption: FC<Props> = ({ onChange, value, ...props }) => {
    return (
        <AdvancedOptionsItem
            label="Allow users to download data"
            id="allow-download"
            helpText="Users will only be able to download records and fields they have permission to view."
            value={value}
            onChange={onChange}
            {...props}
        />
    )
}

export default DownloadOption
