/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { HorizontalScrollContainer, HorizontalScrollItem } from 'react-simple-horizontal-scroller'

import { jsx } from '@emotion/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import NavigationEditor from 'features/admin/settings/navigation/NavigationEditor'
import { navigationColor, useNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { isBlankPagePath } from 'features/blank-pages/utils'
import { ButtonModal, Heading, Icon8 } from 'legacy/v1/ui/index'

import { Flex, Icon, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDimension from 'v2/ui/utils/useDimension'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export const SECOND_NAV_HEIGHT = 48

const isDetailPage = (workspaceStyleUrl) => {
    const path = window.location.pathname

    // Dashbards are at /dash/mydash for V3 and /myapp/dash/mydash for V4 style URLs.
    const dashboardUrl = workspaceStyleUrl
        ? path.match(/^\/[^\/]+\/dash\//)
        : path.match(/^\/dash\//)
    if (dashboardUrl) {
        return false
    }

    if (isBlankPagePath(window.location.pathname, workspaceStyleUrl)) return false

    // List pages are at /mylist for V3 or /myapp/mylist for V4
    // Detail pages are at /myobj/view/recordid and /myapp/myobj/view/recordid resp.
    const len = workspaceStyleUrl ? 3 : 2
    return path.split('/').length > len
}

const isFolderSelected = (views, features, tree, workspaceStyleUrl) => {
    if (!views) return false

    // Rewrite the actual URL if we are on a V4 workspace URL.
    // Ideally this should reuse the logic from useNavTree instead of re-implementing a simlar check.
    // But this is left as-is for now to reduce regression potential for V3 apps.

    let pathChunks = window.location.pathname.split('/')
    if (workspaceStyleUrl) {
        // /myapp/mylist -> ["mylist"]
        // /myapp/dash/mydash -> ["dash", "mydash"]
        pathChunks = pathChunks.slice(2)
    } else {
        // /mylist -> ["mylist"]
        // /dash/mydash -> ["dash", "mydash"]
        pathChunks = pathChunks.slice(1)
    }
    const baseUrl = pathChunks[0]
    const pathTail = '/' + pathChunks.join('/')

    if (isDetailPage(workspaceStyleUrl)) {
        const feature = features.find((f) => f.url && f.url.split('/')[1] === baseUrl)
        const detailView = views
            .filter((v) => v.type === 'detail')
            .find((v) => feature && feature._sid === v.feature_id)
        return detailView && detailView.object_id === tree.object_id
    } else {
        return tree.children.some((c) => c.url === pathTail)
    }
}

const getSelectedItemId = (views, navTree, features, usingWorkspaceUrl) => {
    for (const tree of navTree) {
        if (tree.children && isFolderSelected(views, features, tree, usingWorkspaceUrl)) {
            return tree.children.find((n) => !n.hidden)?._sid
        }
    }
}

const MenuContainer = styled('div')`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: ${(props) => (props.isMobile ? '3px' : 'auto')};
    margin-right: auto;
    align-items: center;
    justify-content: center;
    ${(props) => (props.desktop ? 'justify-content: center;' : '')}
`

const MenuItem = styled(Link)`
    color: inherit;
`

const MenuItemLabel = styled(Text)`
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-size: 14px;
    margin: ${(props) => (props.iconsEnabled ? '0px 0px 0px 9px' : '0px 9px')};
    color: inherit;
    white-space: nowrap;
    height: ${(props) => props.navTheme?.height};
    padding-top: ${(props) => (props.isMobile ? '15px' : '25px')};
    letter-spacing: -0.31px;
`

const _TopNavigation = ({
    navigation,
    theme,
    views,
    desktop,
    features,
    navTheme = null,
    isWorkspace,
    onWidthChanged = null,
}) => {
    const { selectedStack, workspaceAccount } = useAppContext()
    const usingWorkspaceUrl = !!workspaceAccount
    const menuRef = React.useRef()
    const { width: menuWidth } = useDimension(menuRef)

    React.useEffect(() => {
        onWidthChanged?.(menuWidth)
    }, [menuWidth, onWidthChanged])
    if (!navTheme) {
        navTheme = navigationColor(theme, false, isWorkspace)
    }
    const PrimaryNav = ({ treeLabel, visibleChildren, options }) => {
        const child = visibleChildren[0]
        // The folder will point to the first label url
        // if we have more than 1 children we will show the folder label
        // otherwise we show the only child label
        const { url } = child
        const label = visibleChildren.length > 1 ? treeLabel : child.label
        // When we have secondary navigation items, the first of these is linked
        // to the top navigation item i.e. it's the same, so we need this line to
        // have different icons
        const iconToShow = visibleChildren.length > 1 ? options?.icon : child.options?.icon
        return (
            <>
                <MenuItem
                    navTheme={navTheme}
                    isMobile={isMobile}
                    className={STYLE_CLASSES.NAVIGATION_ITEM}
                    to={getUrl(url)}
                    iconsEnabled={selectedStack?.options?.enable_nav_icons}
                >
                    <Flex justify="space-around" wrap="nowrap">
                        {selectedStack?.options?.enable_nav_icons && (
                            <Icon
                                iconPack="far"
                                icon={iconToShow}
                                size="sm"
                                opacity={0.7}
                                topNav={true}
                            />
                        )}
                        <MenuItemLabel isMobile={isMobile}>{label}</MenuItemLabel>
                    </Flex>
                </MenuItem>
            </>
        )
    }

    const isMobile = window.innerWidth < 768

    const navTree = useNavTree()

    if (!navigation || navigation.length === 0) return <MenuContainer>{[]}</MenuContainer>

    if (navTree.length === 0) return ''

    const selectedItemId = getSelectedItemId(views, navTree, features, usingWorkspaceUrl)
    const menuItems = navTree.map((tree) => {
        if (!tree.children) return ''
        const visibleChildren = tree.children.filter((n) => !n.hidden)

        if (visibleChildren.length === 0) return ''
        // So that they are never in the situation where they have turned off their parent item
        // and have the child turned on
        if (tree.hidden && tree.children.length > 1) return ''

        const firstChild = visibleChildren[0]
        const selectedStyle = selectedItemId === firstChild._sid ? navTheme.selectedStyle : {}

        return (
            <HorizontalScrollItem
                key={firstChild._sid}
                id={firstChild._sid}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: navTheme.height,
                    marginLeft: `${selectedStack?.options?.enable_nav_icons ? '10px' : ''}`,
                    padding: `${selectedStack?.options?.enable_nav_icons ? '0 8px 0 8px' : ''}`,
                    ...selectedStyle,
                }}
            >
                <PrimaryNav
                    options={tree.options}
                    treeLabel={tree.label}
                    visibleChildren={visibleChildren}
                />
            </HorizontalScrollItem>
        )
    })
    return (
        <MenuContainer
            ref={menuRef}
            isMobile={isMobile}
            desktop={desktop}
            className={STYLE_CLASSES.NAVIGATION}
        >
            <HorizontalScrollContainerWrapper selectedItemId={selectedItemId} navTheme={navTheme}>
                {menuItems}
            </HorizontalScrollContainerWrapper>
        </MenuContainer>
    )
}

// This wrapper is necessery because when updating the state _TopNavigation memo breaks
const HorizontalScrollContainerWrapper = (props) => {
    const isMobile = useIsMobile()
    const [overflowEndReached, setOverflowEndReached] = React.useState('LEFT')
    return (
        <HorizontalScrollContainer
            childPosition={isMobile ? 'start' : 'center'}
            selectedItemId={props.selectedItemId}
            controlsConfig={{
                left: {
                    defaultIconStyle: {
                        borderColor: props.navTheme?.button.color,
                        opacity: overflowEndReached === 'LEFT' ? '0.4' : undefined,
                    },
                },
                right: {
                    defaultIconStyle: {
                        borderColor: props.navTheme?.button.color,
                        opacity: overflowEndReached === 'RIGHT' ? '0.4' : undefined,
                    },
                },
            }}
            onScrollEnd={setOverflowEndReached}
        >
            {props.children}
        </HorizontalScrollContainer>
    )
}

export const TopNavigation = React.memo(_TopNavigation)

export const EditNavigationPopup = () => {
    return (
        <ButtonModal
            dialog
            header={() => (
                <Heading style={{ padding: 0, margin: 0, paddingLeft: 16 }} size="medium">
                    Edit navigation
                </Heading>
            )}
            button={({ open }) => (
                <Icon8
                    onClick={open}
                    icon="menu"
                    size="100"
                    displaySize="15"
                    iconStyle="ios-filled"
                    color="C7CED6"
                    style={{
                        cursor: 'pointer',

                        height: '30px',
                        padding: '5px',
                        width: '30px',
                        borderRadius: '5px',
                        backgroundColor: 'black',
                        opacity: '0.6',
                    }}
                />
            )}
        >
            {() => <NavigationEditor />}
        </ButtonModal>
    )
}

const SecondaryNavStyled = styled('div')`
    width: 100%;
    height: ${(props) => (props.isMobile ? 40 : SECOND_NAV_HEIGHT)}px;
    overflow: auto;
    overflow-y: hidden;
    margin-top: -1px;
    background: ${(props) => props.navTheme.secondNavColor};
    border-top: ${(props) => (props.isMobile ? '' : props.navTheme.borderTop)};
`
const SecondaryMenuItem = styled(Link)`
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-size: 14px;
    margin: ${(props) => (props.iconsEnabled ? '0px 0px 0px 9px' : '0px 9px')};
    color: ${(props) => props.navTheme.textColor};
    white-space: nowrap;
    letter-spacing: -0.31px;
    display: flex;
    align-items: center;
`

const _secondNavigation = ({
    navigation,
    theme,
    views,
    connectScrollTarget,
    features,
    history,
    onSecondaryHeaderStateChange,
    isWorkspace,
}) => {
    const navTheme = navigationColor(theme, true, isWorkspace)
    const { workspaceAccount, selectedStack } = useAppContext()
    const usingWorkspaceUrl = !!workspaceAccount
    const navTree = useNavTree()

    const [visibleChildren, selectedTree] = getVisibleChildren(
        navigation,
        views,
        features,
        usingWorkspaceUrl,
        navTree
    )

    const shouldRender = visibleChildren.length > 1 && !selectedTree.hidden

    React.useEffect(() => {
        if (onSecondaryHeaderStateChange) {
            onSecondaryHeaderStateChange(shouldRender)
        }
    }, [shouldRender, onSecondaryHeaderStateChange])

    if (!shouldRender) {
        return ''
    }

    function appLocalUrl(path) {
        if (path && usingWorkspaceUrl) {
            return '/' + path.split('/').slice(2).join('/')
        } else {
            return path
        }
    }

    const isMobile = window.innerWidth < 768

    const secondaryNav = visibleChildren.map((n) => {
        // We don't show a second-level item selected on detail or create pages
        // Unless the history tells us we just came from one
        const isSelected =
            (!isDetailPage(usingWorkspaceUrl) && n.url === appLocalUrl(window.location.pathname)) ||
            appLocalUrl(history?.location?.state?.prev?.pathname) == n.url
        const style = isSelected ? navTheme.selectedStyle : {}
        return (
            <HorizontalScrollItem
                key={n._sid}
                id={isSelected ? 'selected' : n._sid}
                style={{
                    ...style,
                    height: isMobile ? '40px' : SECOND_NAV_HEIGHT - 1,
                    marginLeft: `${selectedStack?.options?.enable_nav_icons ? '10px' : ''}`,
                    padding: `${selectedStack?.options?.enable_nav_icons ? '0 8px 0 8px' : ''}`,
                }}
            >
                <Flex>
                    {selectedStack?.options?.enable_nav_icons && (
                        <Icon
                            style={{ color: navTheme.textColor }}
                            iconPack="far"
                            icon={n.options?.icon}
                            size="sm"
                            opacity={0.7}
                        />
                    )}
                    <SecondaryMenuItem
                        className={STYLE_CLASSES.NAVIGATION_ITEM}
                        to={getUrl(n.url)}
                        navTheme={navTheme}
                        style={{
                            height: isMobile ? '40px' : SECOND_NAV_HEIGHT - 1,
                        }}
                        iconsEnabled={selectedStack?.options?.enable_nav_icons}
                    >
                        {n.label || '-'}
                    </SecondaryMenuItem>
                </Flex>
            </HorizontalScrollItem>
        )
    })

    return (
        <SecondaryNavStyled
            isMobile={isMobile}
            className={STYLE_CLASSES.SECONDARY_NAVIGATION}
            ref={connectScrollTarget}
            navTheme={navTheme}
        >
            <HorizontalScrollContainerWrapper navTheme={navTheme} selectedItemId="selected">
                {secondaryNav}
            </HorizontalScrollContainerWrapper>
        </SecondaryNavStyled>
    )
}
const getVisibleChildren = (navigation, views, features, usingWorkspaceUrl, navTree) => {
    if (!navigation || navigation.length === 0) return [[], {}]

    if (!navTree || navTree.length === 0) return [[], {}]

    const selectedTree = navTree.find((t) =>
        isFolderSelected(views, features, t, usingWorkspaceUrl)
    )
    if (!selectedTree) {
        // Not a view url (admin, studio, etc)
        return [[], {}]
    }
    return [selectedTree.children?.filter((n) => !n.hidden) ?? [], selectedTree]
}
export const SecondNavigation = withRouter(_secondNavigation)
