import React from 'react'

import { FlexProps } from '@chakra-ui/react'

import { Button, Flex } from 'v2/ui'

import { useUpgradeFlow } from './useUpgradeFlow'

type AirtableV2UpgradeButtonProps = FlexProps & {
    dataConnection: DataConnectionDto
    buttonSize?: string
    buttonStyle?: any
}

export const AirtableV2UpgradeButton: React.FC<AirtableV2UpgradeButtonProps> = ({
    dataConnection,
    buttonSize = 'sm',
    buttonStyle,
    ...props
}) => {
    const { onStart, modal } = useUpgradeFlow({ dataConnectionSid: dataConnection._sid })

    // we don't want to unmount the modal after the version changes, we always render it
    return (
        <>
            {modal}
            {dataConnection.version == 1 && (
                <Flex flexWrap="nowrap" {...props}>
                    <Button
                        flexShrink={0}
                        variant="Primary"
                        buttonSize={buttonSize}
                        ml={2}
                        icon="faMagicWandSparkles"
                        onClick={onStart}
                        data-testid="airtable-oauth.upgrade-button"
                        {...buttonStyle}
                    >
                        Upgrade
                    </Button>
                </Flex>
            )}
        </>
    )
}
