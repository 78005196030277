// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as Sentry from '@sentry/react'

import { useAppContext } from 'app/AppContext'
import { convertBase } from 'utils/utils'

import { Text } from 'v2/ui'

import { DataConnectionEditorContextType } from '../dataConnectionEditorContext'
import InputGroup from '../InputGroup'

import FullSchemaSync from './FullSchemaSync'

const STEPS = {
    initial: '',
    syncSchema: 'sync',
}

type FivetranConnectionEditorProps = {
    context: DataConnectionEditorContextType
}

const FivetranConnectionEditor: React.FC<FivetranConnectionEditorProps> = (props) => {
    const context = props.context
    const { dataConnection, step, schemaSyncOnly } = context

    if (!dataConnection) {
        return <InitializeNewConnection context={context} />
    } else if (step === STEPS.syncSchema || schemaSyncOnly) {
        // Ready to sync the schema
        return (
            <FullSchemaSync
                dataConnection={dataConnection}
                onStartSync={context.onStartSync}
                onComplete={context.onSyncComplete}
                onError={context.onSyncError}
            />
        )
    } else {
        // We're just editing an existing connection and we don't show anything
        return null
    }
}

const getSchemaName = function (stackId, connectionReference) {
    // This process is messy because:
    // - Fivetran schema names can only have lowercase letters, numbers and underscores and cannot
    //   start with a number
    // - Stack IDs have upper- and lower-case letters in them (kind of like base 62)
    // - The python lib we use for base changes assumes you want to use uppercase letters first
    //
    // So, convert the 'base 62' stack id (0-9, A-Z, a-z) to base 32 with the capsFirst option
    // passed to convertBase, then lowercase it to get to the actual base 32 variant we want (0-9,
    // a - z). This logic is replicated on the backend as we only send over the connection reference
    // so users cannot guess a schema name and get another user's Fivetran data.
    const stackIdBase62 = stackId.split('.')[1]
    const stackIdBase36 = convertBase(stackIdBase62, 62, 36, true)
    return '_' + stackIdBase36.toLowerCase() + '_' + connectionReference.toLowerCase()
}

const InitializeNewConnection = ({ context }) => {
    const { setNextHandler, setIsSaving, createDataConnection, setDataConnection, setStep } =
        context
    const { register, formState, getValues, handleSubmit } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: 'firstError',
        defaultValues: { sql_connection_reference: '' },
    })
    const { selectedStack } = useAppContext()
    const [fullSchema, setFullSchema] = useState(getSchemaName(selectedStack?._sid, ''))

    // Handler for creating the connection
    const onSubmit = useCallback(() => {
        const values = getValues()
        setIsSaving(true)
        createDataConnection({ updated_secret_options: values })
            .then((connection) => {
                setIsSaving(false)
                setDataConnection(connection)
                // Move on to the next step
                setStep(STEPS.syncSchema)
            })
            .catch(Sentry.captureException)
    }, [setIsSaving, setDataConnection, createDataConnection, getValues, setStep])

    // Register to handle it when the Next button gets clicked
    useEffect(() => {
        const handler = handleSubmit(onSubmit)
        setNextHandler(handler)
    }, [onSubmit, handleSubmit, setNextHandler])

    return (
        <>
            <InputGroup
                errors={formState.errors}
                title="Connection reference"
                register={register('sql_connection_reference', {
                    required: 'Connection reference is required',
                    pattern: {
                        value: /^[a-z0-9]+$/,
                        message: 'Can only contain lowercase letters, numbers and underscores',
                    },
                })}
                placeholder=""
                onChange={(e) => {
                    setFullSchema(getSchemaName(selectedStack?._sid, e.target.value))
                }}
            />
            <Text size="sm">
                Destination schema: <i>{fullSchema}</i>
            </Text>
        </>
    )
}

export default FivetranConnectionEditor
