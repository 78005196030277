import { Location } from 'history'
// @ts-ignore
import queryString from 'query-string'

import { ORDER_BY_QUERY_ID } from './orderByQueryId'
import { setNewOrderByValue } from './setNewOrderByValue'
import { ListViewOrderBy, SetOrderBy } from './types'

type updateOrderByFromQueryParamsProps = {
    orderBy: ListViewOrderBy
    setOrderBy: SetOrderBy
    location: Location
}

export const updateOrderByFromQueryParams = ({
    orderBy,
    setOrderBy,
    location,
}: updateOrderByFromQueryParamsProps): undefined => {
    const query = queryString.parse(location?.search)
    const orderByValue = query[ORDER_BY_QUERY_ID] as string

    if (!orderByValue) return

    const desc = orderByValue.slice(0, 1) === '-'
    let id = orderByValue
    if (desc) id = orderByValue.slice(1)

    const newOrderBy = {
        id,
        desc,
    }

    setNewOrderByValue({ newValue: newOrderBy, orderBy, setOrderBy })
}
