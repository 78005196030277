import { recordApi } from 'data/api/recordApi'

import { getSchemaTimestamp } from './getSchemaTimestamp'

/**
 * The function for fetching a new record from the server.
 * @param {string} recordId
 * @param {any} options
 */

export function getRecordQueryFunction(recordId: string, options: any): Promise<RecordDto> {
    return recordApi.getRecord(recordId, options).then((response) => {
        const schemaTimestamp = getSchemaTimestamp((response as any)?._object_id)
        return (
            response
                ? // TODO: fix types
                  { ...response, _partial: !!(response as any)?._partial, schemaTimestamp }
                : response
        ) as RecordDto
    })
}
