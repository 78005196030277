import React, { useMemo } from 'react'

import { Spinner } from '@chakra-ui/react'

import { Box, Button, Dropdown, Text } from 'v2/ui'

function formatCurrentPageLabel(pageCount: number, pageIndex: number): string {
    const currentPageIdx = pageCount > 0 ? pageIndex + 1 : 0

    return `${currentPageIdx} of ${pageCount}`
}

type DataListBlockPaginationProps = {
    pageCount?: number
    pageIndex?: number
    pageSize?: number
    setPageSize?: (size: number) => void
    canGoToPreviousPage?: boolean
    canGoToNextPage?: boolean
    goToPage?: (page: number) => void
    showPageSizeOptions?: boolean
    isLoading?: boolean
    pageSizeOptions?: number[]
}

export const DataListBlockPagination: React.FC<DataListBlockPaginationProps> = ({
    pageIndex = 0,
    pageCount = 0,
    pageSize = 0,
    setPageSize,
    canGoToPreviousPage,
    canGoToNextPage,
    goToPage,
    showPageSizeOptions,
    isLoading,
    pageSizeOptions,
}) => {
    const sizeOptions = useMemo(() => {
        if (!showPageSizeOptions) return undefined

        return pageSizeOptions?.map((x) => ({ label: x, value: x }))
    }, [showPageSizeOptions, pageSizeOptions])

    const currentPageLabel = formatCurrentPageLabel(pageCount, pageIndex)

    const goToPrev = () => {
        goToPage?.(pageIndex - 1)
    }

    const goToNext = () => {
        goToPage?.(pageIndex + 1)
    }

    const shouldDisplayControls = pageCount > 1

    return (
        <Box
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            px={6}
            py={4}
            minHeight="3.8rem"
        >
            <Box display="flex" alignItems="center">
                {shouldDisplayControls && (
                    <Text
                        fontWeight="bold"
                        color="userInterface.neutral.900"
                        fontSize={['pagination.text', null, 'pagination.textLg']}
                    >
                        {currentPageLabel}
                    </Text>
                )}

                {sizeOptions && (
                    <Box
                        display="flex"
                        alignItems="center"
                        mr={4}
                        ml={shouldDisplayControls ? 4 : 0}
                    >
                        <Text mr={2}>Show</Text>

                        <Dropdown
                            value={pageSize}
                            options={sizeOptions}
                            onChange={setPageSize}
                            width={50}
                            padding="2px"
                            isSearchable={false}
                            isClearable={false}
                            optionHeight={24}
                        />
                        <Text ml={2}>records per page</Text>
                    </Box>
                )}

                {isLoading && <Spinner size="sm" color="gray.300" ml={2} />}
            </Box>

            {shouldDisplayControls && (
                <Box justifyContent="space-between" alignItems="center" display="flex">
                    <Button
                        variant="clear"
                        icon="arrowLeft"
                        pl={0}
                        pr={2}
                        disabled={!canGoToPreviousPage}
                        onClick={goToPrev}
                    >
                        <Text
                            p={0}
                            fontWeight="bold"
                            fontSize={['pagination.textArrow', null, 'pagination.textArrowLg']}
                            color="userInterface.neutral.900"
                            as="span"
                        >
                            Back
                        </Text>
                    </Button>
                    <Button
                        variant="clear"
                        icon="arrowRight"
                        pl={2}
                        pr={0}
                        iconAlign="right"
                        disabled={!canGoToNextPage}
                        onClick={goToNext}
                    >
                        <Text
                            p={0}
                            fontWeight="bold"
                            fontSize={['pagination.textArrow', null, 'pagination.textArrowLg']}
                            color="userInterface.neutral.900"
                            as="span"
                        >
                            Next
                        </Text>
                    </Button>
                </Box>
            )}
        </Box>
    )
}
