import React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { Input } from 'v2/ui'

/**
 *
 * @param {{name: string, label?: string, defaultValue?: any, subtitle?:string, errorMessages?: {[keyof: string]: string}} & Partial<import('react-hook-form').RegisterOptions>} param0
 */
export default function InputForm({
    name,
    defaultValue = null,
    required = false,
    pattern = undefined,
    ...props
}) {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    return (
        <Input
            defaultValue={defaultValue}
            {...register(name, { required, pattern })}
            {...props}
            className={error ? 'invalid' : ''}
        />
    )
}
