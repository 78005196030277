import type { CustomCell } from '@glideapps/glide-data-grid'

import { drawRoundedValues } from '../drawUtils'
import { CellData, CustomCellRenderer } from '../types'

export const MAGIC_USER_FIELD_CELL = 'magic-user-field-cell'

export type MagicUserFieldCellData = Exclude<CellData, 'provider'> & {
    readonly kind: typeof MAGIC_USER_FIELD_CELL
    value?: string
}

export type MagicUserFieldCell = CustomCell<MagicUserFieldCellData>

export const MagicUserFieldCellRenderer: CustomCellRenderer<MagicUserFieldCell> = {
    isMatch: (cell): cell is MagicUserFieldCell =>
        (cell.data as any).kind === MAGIC_USER_FIELD_CELL,
    draw: (args, cell) => {
        const { value } = cell.data
        if (value) {
            drawRoundedValues(args, [{ label: value }])
        }
        return true
    },
    // Magic user fields are just readonly, so no need to provide an editor
    provideEditor: () => undefined,
}
