// @ts-strict-ignore
// @ts-check
import * as React from 'react'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { get } from 'lodash'
import useViewBreadcrumbTitle from 'v2/views/useViewBreadcrumbTitle'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import AppContext from 'app/AppContext'
import AppUserContext, { Rights } from 'app/AppUserContext'
import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { _transformOldConfigFormatToNew } from 'data/hooks/metrics/legacyMetrics'
import { EDITING_STARTED } from 'data/utils/constants'
import RolesSelect from 'features/pages/blocks/settings/attributes/items/form/RolesSelect'
import { useDuplicateViewModal } from 'features/views/DuplicateViewModal'
import MenuToggle from 'features/views/List/MenuToggle'
import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'
import ViewEditPane from 'features/views/ViewEditPane'
import { ListViewHeader } from 'features/views/ViewHeader/ListViewHeader'
import { ListViewHeaderEditor } from 'features/views/ViewHeader/ListViewHeaderEditor'

import Button from 'ui/deprecated/atoms/Button'

import useViewConfig from '../utils/useViewConfig'

import { BREAKPOINT } from './context'
import RenderDashboard, { renderDashboardContainerStyles } from './RenderDashboardFromView'

export default DashboardView

// @TODO see comments on _transformOldConfigFormatToNew
// delete when no longer needed for dev
const _transformExistingChartsConfigs = (config) => {
    if (config.charts) {
        const newCharts = {}
        Object.entries(config.charts).forEach(([key, value]) => {
            newCharts[key] = {
                ...value,
                config: _transformOldConfigFormatToNew(value.config),
            }
        })
        config.charts = newCharts
    }

    return config
}

function DashboardView({ view, context }) {
    const isEditing = useSelector((state) => state.user.isEditing)
    const dispatch = useDispatch()
    const { hasRight } = useContext(AppUserContext)
    const { isStudioUser, isImpersonating } = useContext(AppUserContext)
    const isAdmin = isStudioUser && !isImpersonating && hasRight(Rights.Admin.Any)
    const objectId = view?.object_id
    const breadcrumbTitle = useViewBreadcrumbTitle(view)
    useHistoryBreadcrumb({ title: breadcrumbTitle, type: 'dashboard', objectId })
    const { selectedStack: stack } = useContext(AppContext)
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    const stackOptions = stack.options

    if (!view.options.dashboard) {
        view.options.dashboard = { ...EMPTY_CONFIG }
    }

    /** @type {CHARTS_CONFIG} */
    const config = view.options.dashboard

    // activate edit mode if there ain't any chart yet
    useEffect(() => {
        if (isAdmin && config.charts && Object.entries(config.charts).length === 0)
            dispatch({ type: EDITING_STARTED })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const viewConfig = useViewConfig(view, context?.page)

    const style =
        window.innerWidth >= BREAKPOINT ? { ...renderDashboardContainerStyles } : { marginTop: 25 }

    const Container = useCallback(
        ({ children }) => (
            <div
                style={{
                    ...style,
                    width: '100%',
                }}
            >
                {children}
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    function onChange(layouts, currentBreakpoint) {
        if (!isEditing) {
            return null
        }

        return viewConfig.setConfig({
            dashboard: {
                ..._transformExistingChartsConfigs(view.options.dashboard),
                layouts: {
                    ...view.options.dashboard?.layouts,
                    [currentBreakpoint]: layouts[currentBreakpoint],
                },
            },
        })
    }

    return (
        <>
            {isEditing && (
                <DashboardSidebar
                    view={view}
                    {...viewConfig}
                    rolesEnabled={get(stackOptions, 'roles__enabled', false)}
                />
            )}
            <div style={{ margin: '0 -10px', maxWidth: '100vw' }}>
                <ListViewHeader
                    listOptions={viewConfig.viewState?.view.options ?? view.options}
                    renderInPlace={true}
                    parentIsMounted={isMounted.current}
                />
            </div>
            <div style={{ width: '100%', maxWidth: 1250, margin: '0 auto' }}>
                <RenderDashboard
                    config={config}
                    onChange={onChange}
                    allowEdits={isEditing}
                    mainObjectId={view.object_id}
                    viewId={view._sid}
                    container={Container}
                    viewContext="dashboard"
                />
            </div>
            <UnsavedChangesModal
                isDirty={viewConfig.isConfigDirty || viewConfig.rolesDirty}
                onSave={viewConfig.saveConfig}
                revertChanges={viewConfig.revertChanges}
            />
        </>
    )
}

/** @type {CHARTS_CONFIG} */
const EMPTY_CONFIG = {
    charts: {},
    layouts: { md: [], xs: [] },
}

function DashboardSidebar({
    viewState,
    setViewData,
    isConfigDirty,
    rolesDirty,
    saveConfig,
    setConfig,
    setPageRoles,
    pageRoles,
    rolesEnabled,
}) {
    const showConfirmDeleteModal = useConfirmDeleteViewModal()
    const showDuplicateViewModal = useDuplicateViewModal()

    return (
        <ViewEditPane
            viewName={viewState?.view.name}
            view={viewState?.view}
            saveView={saveConfig}
            setViewData={setViewData}
            isConfigDirty={isConfigDirty || rolesDirty}
            additionalActions={
                <Button
                    label="Duplicate dashboard"
                    variant="clear"
                    p={0}
                    onClick={() => {
                        showDuplicateViewModal({ view: viewState?.view })
                    }}
                    icon="copy"
                />
            }
            actions={[
                {
                    action: () =>
                        showConfirmDeleteModal({
                            view: viewState?.view,
                        }),
                    label: 'Delete Dashboard',
                },
            ]}
        >
            <MenuToggle
                icon="document-header"
                label="Header"
                contentProps={{ width: '320px', maxHeight: '95vh', overflowY: 'auto' }}
            >
                <ListViewHeaderEditor listOptions={viewState?.view?.options} onChange={setConfig} />
            </MenuToggle>
            {rolesEnabled && (
                <MenuToggle icon="lock" label="Roles" noBackground>
                    <div style={{ 'min-width': '300px' }}>
                        <RolesSelect
                            onChange={(pageRoles) => {
                                setPageRoles(pageRoles)
                            }}
                            value={pageRoles}
                        />
                    </div>
                </MenuToggle>
            )}
        </ViewEditPane>
    )
}
