import React from 'react'

import { Icon, Tooltip } from 'v2/ui'

type IframeBlockUrlHelpProps = {}

const IframeBlockUrlHelp: React.FC<IframeBlockUrlHelpProps> = () => {
    return (
        <Tooltip label="Supports YouTube, image URLs, including any URL that can be embedded within an HTML iframe element.">
            <Icon color="neutral.700" icon="info" size="sm" aria-hidden={true} />
        </Tooltip>
    )
}

export default IframeBlockUrlHelp
