import React from 'react'

// @ts-ignore
import { Icon, Text } from 'v2/ui'

import { DataConnector } from '../dataConnectorTypes'

type ConnectionCompleteProps = {
    provider: DataConnector
}

const ConnectionComplete: React.FC<ConnectionCompleteProps> = (props) => {
    return (
        <Text>
            <Icon
                icon="checkmarkCircleSolid"
                size="lg"
                display="inline"
                verticalAlign="middle"
                color="success"
                mr={2}
            />
            Your {props.provider.name} connection is complete!
        </Text>
    )
}

export default ConnectionComplete
