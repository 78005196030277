import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type BigRocketProps = {
    size?: number
    backgroundColor?: string
}
const BigRocket: React.FC<BigRocketProps> = ({
    backgroundColor = colors.userInterface.accent[200],
    size = 98,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                minWidth: size,
                minHeight: size,
            }}
        >
            <circle cx="47.555" cy="50.445" r="47.555" fill={backgroundColor} />
            <circle cx="82.877" cy="17.631" r="3.223" fill="#465DD8" />
            <circle cx="92.924" cy="68.249" r="4.55" fill="#465DD8" />
            <circle cx="11.405" cy="3.412" r="3.412" fill="#465DD8" />
            <circle cx="12.921" cy="35.641" r="1.896" fill="#465DD8" />
            <circle cx="3.296" cy="63.699" r="3.033" fill="#465DD8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.316 17.988a.885.885 0 0 1 .248.487 38.08 38.08 0 0 1-1.098 12.823L63.121 18.82a37.682 37.682 0 0 1 12.708-1.08c.184.03.355.116.487.248zM44.835 30.767 32.88 49.877a.885.885 0 0 0 .124 1.098L43.329 61.3a.887.887 0 0 0 .629.266.832.832 0 0 0 .47-.142L63.6 49.416a31.748 31.748 0 0 0 11.344-16.17L61.174 19.36a31.924 31.924 0 0 0-16.34 11.407z"
                fill="#465DD8"
            />
            <path
                d="M49.979 56.62c-1.55 1.278-2.119 3.358-1.91 5.357a17.79 17.79 0 0 1-2.002 10.226.885.885 0 0 0 .144 1.106.886.886 0 0 0 1.116.07c4.32-3.099 12.502-9.966 14.311-18.561.747-3.545-3.372-5.034-6.167-2.729l-5.492 4.53z"
                fill="#4B55B0"
            />
            <path
                d="M49.979 56.62c-1.55 1.278-2.119 3.358-1.91 5.357a17.79 17.79 0 0 1-2.002 10.226.885.885 0 0 0 .144 1.106.886.886 0 0 0 1.116.07c4.32-3.099 12.502-9.966 14.311-18.561.747-3.545-3.372-5.034-6.167-2.729l-5.492 4.53z"
                fill="#000"
                fillOpacity=".2"
            />
            <path
                d="M38.118 45.277c-1.315 1.521-3.407 2.04-5.401 1.787a17.79 17.79 0 0 0-10.27 1.766.886.886 0 0 1-1.102-.17.884.884 0 0 1-.043-1.117c3.197-4.248 10.25-12.27 18.885-13.88 3.561-.665 4.955 3.487 2.586 6.228l-4.655 5.386z"
                fill="#4B55B0"
            />
            <path
                d="M38.118 45.277c-1.315 1.521-3.407 2.04-5.401 1.787a17.79 17.79 0 0 0-10.27 1.766.886.886 0 0 1-1.102-.17.884.884 0 0 1-.043-1.117c3.197-4.248 10.25-12.27 18.885-13.88 3.561-.665 4.955 3.487 2.586 6.228l-4.655 5.386z"
                fill="#000"
                fillOpacity=".2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.303 50.287a.02.02 0 0 0 .002-.025.02.02 0 0 1 .002-.024.886.886 0 1 1 1.238 1.26l-1.974 1.974a.885.885 0 0 1-.611.293.886.886 0 0 1-.62-1.514l1.963-1.964zM24.82 67.951l6.093-6.119a.898.898 0 1 1 1.284 1.258l-6.128 6.119a.886.886 0 0 1-1.249-1.258zm13.082-12.515a.02.02 0 0 1-.003.026l-1.963 1.963a.886.886 0 0 0 .62 1.514.886.886 0 0 0 .611-.292l1.975-1.975a.885.885 0 1 0-1.239-1.26.02.02 0 0 0-.001.024zm4.689 3.14a.02.02 0 0 1 .025-.003.02.02 0 0 0 .024-.002.886.886 0 0 1 1.237 1.269l-1.974 1.974a.886.886 0 0 1-1.25-1.257l1.938-1.981zm-5.789 5.798-3.64 3.63a.885.885 0 0 0 .63 1.515.886.886 0 0 0 .628-.257l3.63-3.64a.886.886 0 0 0-1.248-1.248zm-13.129-3.856 3.64-3.63a.885.885 0 0 1 1.249 1.248l-3.631 3.64a.885.885 0 0 1-.629.256.884.884 0 0 1-.629-1.514z"
                fill="#4B55B0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.303 50.287a.02.02 0 0 0 .002-.025.02.02 0 0 1 .002-.024.886.886 0 1 1 1.238 1.26l-1.974 1.974a.885.885 0 0 1-.611.293.886.886 0 0 1-.62-1.514l1.963-1.964zM24.82 67.951l6.093-6.119a.898.898 0 1 1 1.284 1.258l-6.128 6.119a.886.886 0 0 1-1.249-1.258zm13.082-12.515a.02.02 0 0 1-.003.026l-1.963 1.963a.886.886 0 0 0 .62 1.514.886.886 0 0 0 .611-.292l1.975-1.975a.885.885 0 1 0-1.239-1.26.02.02 0 0 0-.001.024zm4.689 3.14a.02.02 0 0 1 .025-.003.02.02 0 0 0 .024-.002.886.886 0 0 1 1.237 1.269l-1.974 1.974a.886.886 0 0 1-1.25-1.257l1.938-1.981zm-5.789 5.798-3.64 3.63a.885.885 0 0 0 .63 1.515.886.886 0 0 0 .628-.257l3.63-3.64a.886.886 0 0 0-1.248-1.248zm-13.129-3.856 3.64-3.63a.885.885 0 0 1 1.249 1.248l-3.631 3.64a.885.885 0 0 1-.629.256.884.884 0 0 1-.629-1.514z"
                fill="#000"
                fillOpacity=".2"
            />
            <g filter="url(#a)">
                <circle cx="59.978" cy="33.433" r="6.689" fill="#fff" />
            </g>
            <defs>
                <filter
                    id="a"
                    x="33.289"
                    y="6.745"
                    width="62.378"
                    height="58.378"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImage" stdDeviation="10" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1270:29845"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="9" dy="5" />
                    <feGaussianBlur stdDeviation="10" />
                    <feColorMatrix values="0 0 0 0 0.0690278 0 0 0 0 0.295833 0 0 0 0 0.11166 0 0 0 0.08 0" />
                    <feBlend
                        in2="effect1_backgroundBlur_1270:29845"
                        result="effect2_dropShadow_1270:29845"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1270:29845"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-10" dy="10" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
                    <feBlend in2="shape" result="effect3_innerShadow_1270:29845" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="3" dy="-6" />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix values="0 0 0 0 0.0199306 0 0 0 0 0.341667 0 0 0 0 0.0521041 0 0 0 0.05 0" />
                    <feBlend
                        in2="effect3_innerShadow_1270:29845"
                        result="effect4_innerShadow_1270:29845"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default BigRocket
