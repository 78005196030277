import { FieldType } from 'data/utils/fieldDefinitions'

const PLACEHOLDER_SUPPORTED_FIELD_TYPES = new Set<FieldType>([
    'string',
    'long_text',
    'currency',
    'currency_varying',
    'percentage',
    'dropdown',
    'multi_select',
    'lookup',
    'multi_lookup',
    'url',
    'number',
    'date',
    'datetime',
])

export function isFieldTypePlaceholderSupported(type: FieldType): boolean {
    return PLACEHOLDER_SUPPORTED_FIELD_TYPES.has(type)
}
