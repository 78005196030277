import React, { FC, useMemo } from 'react'

import { useDashboardContext } from 'v2/views/Dashboard/context'

import { useViewFromId } from 'data/hooks/views'
import { useObjectFromId } from 'features/actions/helpers'
import { formatFilters } from 'features/records/components/RecordFilters'

import Bar from './Bar'
import Number from './Number'
import Pie from './Pie'

const Chart: FC<ChartParameters> = ({ chart_type, config, ...props }) => {
    const { viewFilters } = useDashboardContext()
    const { object } = useObjectFromId(config.object_sid)

    const ChartComp = mapChartTypeToElement[chart_type as Exclude<ChartType, 'Line'>]

    if (object?.connection_options?.data_mapping_disabled) {
        throw { message: 'Table has been unselected since chart set up' }
    }

    if (!ChartComp) {
        throw { message: 'Unknown chart type ' + chart_type }
    }
    const inheritedView = useViewFromId(config.inherit_filters_from_view_id)

    // add filters from the view, if the option is selected
    const updatedConfig = useMemo(() => {
        if (!inheritedView || !viewFilters?.length) return config

        const filters = viewFilters.filter(({ field }) => field.api_name !== '_search')
        return {
            ...config,
            filters: [...(formatFilters(filters ?? []) as ChartFilter[]), ...config.filters],
        }
    }, [inheritedView, config, viewFilters])

    return <ChartComp chart_type={chart_type} {...props} config={updatedConfig} />
}

export default Chart

export const mapChartTypeToElement = {
    Number,
    Pie,
    Bar,
}
