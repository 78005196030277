import React, { memo } from 'react'

import DetailView from 'v2/views/Detail/DetailView'

import { withStack } from 'data/wrappers/WithStacks'

const DetailViewWrapper = ({ ...props }) => {
    return <DetailView {...props} />
}

export default memo(withStack(DetailViewWrapper))
