import React, { KeyboardEvent, memo, useCallback } from 'react'

import { InputProps } from '@chakra-ui/react'

import Button from './Button'
import Icon from './Icon'
import Input from './Input'

type SearchInputProps = {
    value?: string
    onChange: (value: string) => void
    onSubmit?: (value: string) => void
    variant?: string
    containerStyle?: React.CSSProperties
} & Omit<InputProps, 'variant' | 'onChange'>

const SearchInput = memo(
    React.forwardRef(
        (
            {
                value,
                onChange,
                onSubmit,
                variant = 'admin',
                placeholder = 'Search',
                ...props
            }: SearchInputProps,
            ref
        ) => {
            const handleChange = useCallback((e) => onChange(e.target.value), [onChange])
            const handleClear = useCallback(() => {
                onChange('')
            }, [onChange])

            return (
                <Input
                    ref={ref}
                    leftAdorner={
                        /* @ts-ignore */
                        <Icon size={['input.icon', null, null, 'input.iconLg']} icon="search" />
                    }
                    placeholder={placeholder}
                    size="sm"
                    variant={variant}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={(e: KeyboardEvent) => {
                        if (e.key === 'Enter' && value) {
                            onSubmit && onSubmit(value)
                        }
                        if (e.key === 'Escape') {
                            handleClear()
                        }
                    }}
                    rightAdorner={
                        value && (
                            <Button
                                data-testid={'clearSearch'}
                                variant="clear"
                                label="Clear search"
                                icon="x"
                                size={['input.icon', null, null, 'input.iconLg']}
                                h={['input.icon', null, null, 'input.iconLg']}
                                onClick={handleClear}
                            />
                        )
                    }
                    style={{}}
                    {...props}
                />
            )
        }
    )
)

export default SearchInput
