/* Code Quality: Not audited */

import React from 'react'

import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

const Input = styled('input')`
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.fontColor};
    font-size: ${(props) => props.theme.fontSize};
    background-color: ${(props) => props.theme.inputBackgroundColor};
    border-radius: ${(props) => props.theme.elementBorderRadius};
    padding: ${(props) => props.theme[props.padding || 'small']};
    border: ${(props) => props.theme.dividerThickness}px solid
        ${(props) => props.theme.outlineColor};

    &:focus {
        outline: none;
    }
    &:disabled {
        opacity: 0.9;
        cursor: not-allowed;
    }
`

export class StatefulInput extends React.Component {
    constructor(props) {
        super(props)
    }

    state = {}

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.value !== this.props.value && newProps.value !== this.state.value) {
            this.setState({ value: newProps.value })
        }
    }

    componentDidMount() {
        if (this.props.hasFocus) {
            console.log('focus')
            this.myRef.focus()
        }
    }

    onChange = (ev) => {
        this.setState({ value: ev.target.value })
        this.props.onChange && this.props.onChange(ev)
    }

    onChangeDebounced = debounce(
        this.onChange,
        this.props.debounce,
        this.props.debounceOptions || {}
    )

    onBlur = (ev) => {
        this.props.onChange && this.props.onChange(ev)
        this.props.onBlur && this.props.onBlur(ev)
        //
        //this.myRef.getRenderedComponent().blur()
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log(this.props.value, nextProps.value, this.state.value, nextState.value)
    //     console.log(
    //         nextProps.value &&
    //             nextProps.value !== this.props.value &&
    //             nextProps.value !== this.state
    //     )
    //     return true
    //     return (
    //         nextProps.value &&
    //         nextProps.value !== this.props.value &&
    //         nextProps.value !== this.state
    //     )
    // }

    render() {
        const onChange = this.props.debounce ? () => {} : this.onChange

        console.log('renderInput')
        return (
            <Input
                ref={this.myRef}
                {...this.props}
                defaultValue={this.state.value}
                onChange={onChange}
                onBlur={this.onBlur}
            />
        )
    }
}

Input.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}
Input.displayName = 'Input'
export default Input
