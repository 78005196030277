// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { css, Global } from '@emotion/react'
import { registerLicense } from '@syncfusion/ej2-base'
import {
    Agenda,
    Day,
    Inject,
    Month,
    ScheduleComponent,
    View,
    Week,
    WorkWeek,
} from '@syncfusion/ej2-react-schedule'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import AppContext from 'app/AppContext'
import { SYNCFUSION_KEY } from 'app/settings'
import { deleteRecords } from 'data/hooks/records/recordOperations'
import { recordDeletionAvailable } from 'data/utils/utils'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import useTrack from 'utils/useTrack'

import { Button, Toast } from 'v2/ui'
import useDimension from 'v2/ui/utils/useDimension'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import getCalendarData, { CalData } from './getCalendarData'

import 'v2/ui/syncfusionStyle.css'

registerLicense(SYNCFUSION_KEY)

type DataRow = {
    original: any
    [x: string]: any
}

type Data = {
    row: DataRow
    [x: string]: any
}

type FilterObject = {
    startDate: string
    endDate: string
    selectedDate: string
    currentView: View
}

type CalendarProps = {
    object: any
    data: any
    startDate: string
    endDate: string
    setCalendarFilter: (data: FilterObject) => void
    calendarFilter: FilterObject
    rowLink: (row: any) => void
    weekStartDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
}

const CalendarView = ({
    object,
    data,
    startDate,
    endDate,
    setCalendarFilter,
    calendarFilter,
    rowLink,
    weekStartDay = 0,
}: CalendarProps) => {
    const [scheduleObj, setScheduleObj] = useState<any>()
    const [error, setError] = useState<string>()
    // Note: this id is only set on mount and shouldn't be changed. It's to make the CSS styling easier
    // as ids take priority over classes
    const calendarId = useRef(`Calendar${Date.now()}`)
    const canEdit = getAreObjectRecordsEditable(object)
    const { selectedStack } = useContext(AppContext)
    const { track } = useTrack()
    const [containerRef, setContainerRef] = useRefCallback()
    const { height: containerHeight, width: containerWidth } = useDimension(containerRef)

    useEffect(() => {
        // Trigger a window resize event when the container height or width changes
        window.dispatchEvent(new Event('resize'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerHeight, containerWidth])

    const calendarStyle = useMemo(
        () => css`
            #${calendarId.current} {
                .e-disable .e-more-indicator {
                    display: none;
                }

                .e-appointment {
                    border-radius: 5px;
                    text-align: center;
                    align-items: center;
                    cursor: pointer;
                    background-color: var(--primary-color-400);

                    .e-subject {
                        color: white;
                    }

                    .e-date-time {
                        color: white;
                    }
                }

                .e-date-header-wrap table tbody td {
                    text-align: center;

                    & > div {
                        display: block;
                    }
                }

                .e-date-header {
                    display: block;
                    margin: 8px auto;
                }

                .e-quick-popup-wrapper .e-popup-footer .e-event-delete {
                    display: none;
                }

                .e-quick-popup-wrapper .e-popup-footer .e-event-edit {
                    width: 100%;
                }

                .e-add {
                    display: none;
                }

                .e-appointment:focus,
                .e-appointment-border {
                    box-shadow: none;
                    background: var(--primary-color-500);
                }
            }
            .e-schedule-dialog {
                .e-repeat-parent-row,
                .e-recurrenceeditor,
                .e-description-row,
                .e-location-container,
                .e-all-day-time-zone-row,
                .e-time-zone-container {
                    display: none;
                }

                .e-subject-container {
                    width: 100%;
                    padding: 0;
                }

                .e-end-container {
                    display: ${endDate ? '' : 'none'};
                }
            }

            .e-footer-content {
                .e-event-delete {
                    display: none;
                }
            }
        `,
        [endDate]
    )

    const history = useHistory()

    const startDateField = object.fields.find((x) => x._sid === startDate)
    const endDateField = object.fields.find((x) => x._sid === endDate)

    const startDateApiName = startDateField?.api_name
    const startDateTimezone = startDateField?.options?.timezone === 'UTC' ? 'UTC' : undefined
    const endDateApiName = endDateField?.api_name

    // The user has updated the view, so get the current dates for record filters
    const onActionComplete = useCallback((): void => {
        if (scheduleObj) {
            var currentViewDates = scheduleObj.getCurrentViewDates()
            var selectedDate = scheduleObj.properties.selectedDate
            var currentView = scheduleObj.properties.currentView
            var startDate = currentViewDates[0]
            var endDate = currentViewDates[currentViewDates.length - 1]
            if (selectedDate) {
                // We need to add/subtract a day from the ranges and include the offset
                // e.g. if an event was the 25/06/22 12:00:00 it would not show if our end date was
                // 25/06/22 00:00:00 so we make it 26/06/22 00:00:00
                setCalendarFilter({
                    startDate: moment(startDate).subtract('1', 'd').toISOString(true),
                    endDate: moment(endDate).add('1', 'd').toISOString(true),
                    selectedDate: moment(selectedDate).toISOString(true),
                    currentView,
                })
            }
        }
    }, [scheduleObj, setCalendarFilter])

    // When opening the schedule popup
    const onOpenPopup = useCallback((args = {}) => {
        const ALLOWED_ALERTS = ['EventContainer', 'DeleteAlert']
        const EVENT_ALERTS = ['QuickInfo', 'ViewEventInfo']
        if (EVENT_ALERTS.includes(args.type) && args.element) {
            // Only if clicking on an existing event
            if (!args.data?.sid) {
                args.cancel = true
                return
            }
            const detailsButton = args.element.querySelectorAll('.event-footer button')[0]
            // Stops the edit icon getting focus
            args.element.ej2_instances[0].open = () => {
                detailsButton.focus()
            }
            // Hide the quickinfo edit and delete buttons if the user doesn't have permissions
            const editButton = args.element.getElementsByClassName('e-edit')[0]
            const deleteButton = args.element.getElementsByClassName('e-delete')[0]
            if (!args?.data?.mayUpdate && editButton) {
                editButton.style.display = 'none'
            }
            if (!args?.data?.mayDelete && deleteButton) {
                deleteButton.style.display = 'none'
            }
            return
        }
        if (!ALLOWED_ALERTS.includes(args.type)) args.cancel = true
    }, [])

    const calendarData = useMemo((): CalData[] => {
        // Build up the data needed from the records
        return data.map((d: Data) => {
            const record = d?.row?.original || {}
            return {
                ...record,
                link: rowLink(d.row),
                mayUpdate: false && !canEdit && record?._permissions?.may_update, //disabled always for now
                mayDelete:
                    false &&
                    recordDeletionAvailable(object, selectedStack) &&
                    record?._permissions?.may_delete, //disabled always for now
            }
        })
    }, [data, object, canEdit, rowLink, selectedStack])

    const calendarDataSource = useMemo(() => {
        return getCalendarData(calendarData, startDateApiName, endDateApiName)
    }, [calendarData, endDateApiName, startDateApiName])

    //The data source sometimes does not update correctly if just using the prop
    useEffect(() => {
        if (scheduleObj && !isEqual(scheduleObj.eventSettings.dataSource, calendarDataSource))
            scheduleObj.eventSettings.dataSource = calendarDataSource
    }, [calendarDataSource, scheduleObj])

    const onActionBegin = useCallback(
        (params) => {
            setError('')
            if (params.requestType == 'eventRemove' && params.deletedRecords.length) {
                const recordIds = params.deletedRecords.map((record) => record.id)
                deleteRecords(object?._sid, recordIds).catch(() => {
                    setError(`Failed to delete event${recordIds.length > 1 ? 's' : ''}`)
                })
            }
        },
        [object?._sid]
    )

    const quickInfoFooter: string | any = useCallback(() => {
        const detailsOnClick = () => {
            const eventDetails = scheduleObj?.activeEventData?.event
            const eventLink = eventDetails?.link
            track('Calendar view - clicked on event see details')
            if (eventLink) history.push(eventLink)
        }
        return (
            <div className="quick-info-footer">
                <div className="event-footer">
                    <Button
                        variant="App"
                        buttonSize="medium"
                        w="100%"
                        id="more-details"
                        cssClass="e-flat"
                        content="More Details"
                        isPrimary={true}
                        onClick={detailsOnClick}
                    >
                        See Details
                    </Button>
                </div>
            </div>
        )
    }, [history, scheduleObj, track])

    return (
        <div ref={setContainerRef} style={{ height: '80vh' }}>
            <Global styles={calendarStyle} />
            <ScheduleComponent
                timezone={startDateTimezone}
                enableAdaptiveUI={containerWidth < 750}
                firstDayOfWeek={weekStartDay}
                showTimeIndicator={false}
                ref={setScheduleObj}
                id={calendarId.current}
                selectedDate={
                    calendarFilter.selectedDate ? new Date(calendarFilter.selectedDate) : undefined
                }
                currentView={calendarFilter.currentView || 'Month'}
                actionComplete={onActionComplete}
                popupOpen={onOpenPopup}
                popupClose={onOpenPopup}
                height="80vh"
                quickInfoTemplates={{ footer: quickInfoFooter }}
                eventSettings={{
                    dataSource: calendarDataSource,
                    fields: {
                        id: 'id',
                        subject: { name: 'subject', default: 'No Title' },
                        startTime: { name: 'start' },
                        endTime: { name: 'end' },
                        isAllDay: { name: 'isAllDay' },
                    },
                }}
                actionBegin={onActionBegin}
                cellDoubleClick={onCellDoubleClick}
            >
                <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
            </ScheduleComponent>
            {error && (
                <Toast status="error" show={!!error} title={error} onCloseComplete={() => {}} />
            )}
        </div>
    )
}

export default React.memo(CalendarView)

const onCellDoubleClick = (args) => {
    args.cancel = true
}
