import React, { useEffect, useState } from 'react'

import { BoxProps } from '@chakra-ui/react'

import { editableFieldTypeDefinitionList } from 'features/admin/fields/definitions/editableFieldTypeDefinitions'

import { Box, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type FieldTypeDescriptionProps = BoxProps & {
    type?: string | null
}

export const FieldTypeDescription: React.FC<FieldTypeDescriptionProps> = ({ type, ...props }) => {
    const [description, setDescription] = useState<string>()

    useEffect(() => {
        if (!type) return
        setDescription(editableFieldTypeDefinitionList.find((t) => t.value === type)?.description)
    }, [type])

    return (
        <Box {...props}>
            <Text fontSize="12px" color={colors.userInterface.neutral[800]}>
                {description}
            </Text>
        </Box>
    )
}
