import * as React from 'react'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { useUpdateAccount } from 'data/hooks/accounts'

import { PreviewBackground, Text } from 'v2/ui'

import Button from 'ui/deprecated/atoms/Button'
import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import DropdownForm from 'ui/forms/DropdownForm'
import Form from 'ui/forms/Form'
import SelectForm from 'ui/forms/SelectForm'

import analytics from '../../utils/analytics'
import LogoPreview, { LogoPreviewImg } from '../../v2/ui/components/LogoPreview'
import { getNavigationColor } from '../../v2/ui/hooks/useNavigationColor'

import ColorPickerForm, { defaultColor } from './forms/ColorPicker'
import ImageUploadForm from './forms/ImageUpload'
import { Divider, FormFrame } from './WorkspaceSettingsModalUi'

// @TODO: use margin/padding instead of a component
function Spacer({ style = {} }) {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}

export default function WorkspaceModalAppearanceSettings({ formRef, children }) {
    const { workspaceAccount } = useContext(AppContext)
    const { mutateAsync: updateAccount } = useUpdateAccount()

    return (
        <Form
            onSubmit={(theme) =>
                updateAccount({
                    id: workspaceAccount._sid,
                    patch: { options: { ...workspaceAccount.options, theme } },
                })
            }
            options={{
                defaultValues: {
                    brandColor: defaultColor,
                    navColor: 'dark',
                    branding: 'name',
                    ...workspaceAccount.options?.theme,
                },
            }}
            style={{ flex: 1 }}
            resetOnSuccess
            onSuccess={(formContext) => {
                if (!!formContext.formState.dirtyFields.logo) {
                    analytics.track('WIP - Frontend - Workspace Settings - Logo - Updated', {})
                }
            }}
        >
            <FormFrame title="Appearance" formRef={formRef}>
                <WorkspaceModalAppearanceSettingsContent workspaceName={workspaceAccount.name} />
            </FormFrame>
            {children}
        </Form>
    )
}

export const UploadIcon = ({ disabled, isSmall }) => {
    const formContext = useFormContext()
    const { logo, logo_icon, navColor, brandColor } = formContext.watch()

    function deleteLogo(name) {
        formContext.setValue(name, undefined, { shouldDirty: true })
    }

    const style = isSmall ? { ...V4DesignSystem.h4 } : { ...V4DesignSystem.h2 }
    return (
        <>
            <Divider />
            <Flex style={{ alignItems: 'center', ...style }}>
                <div style={{ flex: 1 }}>Logo</div>
                {logo && (
                    <>
                        <Spacer style={{ flexShrink: 1 }} />
                        <LogoPreview
                            navColor={navColor}
                            brandColor={brandColor}
                            url={logo}
                            showRemove={true}
                            onRemove={() => deleteLogo('logo')}
                        />
                    </>
                )}
                <ImageUploadForm name="logo" icon="add">
                    {({ onClick }) =>
                        logo ? null : (
                            <Button
                                v4
                                type="secondary"
                                onClick={onClick}
                                icon="add"
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                        )
                    }
                </ImageUploadForm>
            </Flex>
            <Divider />
            <Flex style={{ alignItems: 'center' }}>
                <div style={{ flex: 1, ...style }}>Logo Icon</div>
                {logo_icon && (
                    <>
                        <Spacer style={{ flexShrink: 1 }} />
                        <LogoPreview
                            navColor={navColor}
                            brandColor={brandColor}
                            url={logo_icon}
                            showRemove={true}
                            onRemove={() => deleteLogo('logo_icon')}
                        />
                    </>
                )}
                <ImageUploadForm name="logo_icon" icon="add">
                    {({ onClick }) =>
                        logo_icon ? null : (
                            <Button
                                v4
                                type="secondary"
                                onClick={onClick}
                                icon="add"
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                        )
                    }
                </ImageUploadForm>
            </Flex>
            <Divider />
        </>
    )
}

export function WorkspaceModalAppearanceSettingsContent({
    isMobile = window.innerWidth < 768,
    workspaceName,
    ignoreRightsCheck = false,
}) {
    const formContext = useFormContext()
    const { hasRight } = useAccountUserContext()

    const disabled = !ignoreRightsCheck && !hasRight(Rights.ManageSettings)
    const { logo, logo_icon, navColor, brandColor } = formContext.watch()
    return (
        <>
            <UploadIcon disabled={disabled} />
            <div style={{ height: 4 }} />
            <div style={V4DesignSystem.h2}>App</div>
            <Flex style={{ flexWrap: isMobile ? 'wrap' : 'nowrap', alignItems: 'flex-start' }}>
                <Flex column style={{ flex: 1 }}>
                    <Flex>
                        <ColorPickerForm
                            name="brandColor"
                            required
                            label="Default color"
                            disabled={disabled}
                        />
                        <div style={{ flex: 1 }} />
                        <DropdownForm
                            name="navColor"
                            label="Background"
                            style={{ width: 190 }}
                            isClearable={false}
                            required
                            options={[
                                { value: 'brand', label: 'App color' },
                                { value: 'dark', label: 'Dark grey' },
                                { value: 'light', label: 'Light' },
                            ]}
                            disabled={disabled}
                        />
                    </Flex>
                    <Spacer />
                    <SelectForm
                        name="branding"
                        label="Branding"
                        options={[
                            { value: 'name', label: 'Workspace name', disabled: disabled },
                            { value: 'logo', label: 'Logo', disabled: !logo || disabled },
                            {
                                value: 'logo_icon',
                                label: 'Logo icon',
                                disabled: !logo_icon || disabled,
                            },
                        ]}
                    />
                </Flex>
                <Spacer />
                <Spacer />
                <PreviewBackground
                    navColor={navColor}
                    brandColor={brandColor}
                    style={{ marginTop: isMobile ? 30 : 0 }}
                >
                    <PreviewSelectedHeader workspaceName={workspaceName} />
                    <PreviewNav />
                </PreviewBackground>
            </Flex>
        </>
    )
}

// Show workspace name or logo or logo_icon based on selection and what is available
function PreviewSelectedHeader({ workspaceName }) {
    const formContext = useFormContext()
    const watchedValues = formContext.watch()
    const [navColor, brandColor] = formContext.watch(['navColor', 'brandColor'])
    const navTheme = getNavigationColor({ navColor, brandColor })

    if (watchedValues.branding === 'name' || !watchedValues[watchedValues.branding]) {
        return (
            <div style={{ ...V4DesignSystem.h1, color: navTheme.textBright }}>{workspaceName}</div>
        )
    } else {
        const url = watchedValues.branding === 'logo' ? watchedValues.logo : watchedValues.logo_icon
        return <LogoPreviewImg url={url} />
    }
}

// try to simulate how the nav would look like
function PreviewNav() {
    const formContext = useFormContext()
    const [navColor, brandColor] = formContext.watch(['navColor', 'brandColor'])
    const navTheme = getNavigationColor({ navColor, brandColor })

    return (
        <div style={{ textAlign: 'left', width: '100%' }}>
            <div style={{ background: `#AAAAAA33`, height: 1, margin: '15px -10px' }} />
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text
                    variant="internalNavLabel"
                    style={{ ...V4DesignSystem.h2, color: navTheme.textBright }}
                >
                    App
                </Text>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text variant="internalChildNavLabel" color={navTheme.text}>
                    One
                </Text>
            </div>
            <div
                style={{
                    padding: '5px 8px',
                    borderRadius: 3,
                    background: navTheme.highlightBg,
                }}
            >
                <Text variant="internalChildNavLabelActive" color={navTheme.highlightColor}>
                    Two
                </Text>
            </div>
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text variant="internalChildNavLabel" color={navTheme.text}>
                    Three
                </Text>
            </div>
        </div>
    )
}
