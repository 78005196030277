import React, { useContext } from 'react'
import { StreamContext } from 'react-activity-feed'

/** simple wrapper to check that we have an initaliazed stream context
 * before showing the child
 */
export const withStream = (Child) => (props) => {
    const context = useContext(StreamContext)

    // If we're not inside the stream context (yet) then
    // return null
    if (!context || !context.client) return null

    return <Child {...props} />
}

export default withStream
