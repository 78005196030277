type AirtableConnectionEditorStepsVals = '' | 'sync' | 'error' | 'edit' | 'oauth' | 'repair'

type AirtableConnectionEditorSteps =
    | 'initial'
    | 'syncSchema'
    | 'importError'
    | 'edit_connection'
    | 'oauthFlow'
    | 'repair'

export const AIRTABLE_CONNECTION_EDITOR_STEPS: {
    [key in AirtableConnectionEditorSteps]: AirtableConnectionEditorStepsVals
} = {
    initial: '',
    oauthFlow: 'oauth',
    syncSchema: 'sync',
    importError: 'error',
    edit_connection: 'edit',
    repair: 'repair',
}
