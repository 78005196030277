import React from 'react'

import ContainerLabel from 'v2/ui/components/ContainerLabel'

type ListViewTitleProps = {
    setConfig: (partial: Partial<ViewDto>, shouldSave?: boolean) => void
    display?: string
    showControls?: boolean
    title?: string
    isMobile?: boolean
}

const ListViewTitle: React.FC<ListViewTitleProps> = ({
    setConfig,
    display,
    showControls,
    title,
    isMobile,
}) => {
    let variant = 'pageHeading'
    if (display === 'inbox') {
        variant = isMobile ? 'inboxViewHeadingMobile' : 'inboxViewHeading'
    }

    return (
        <ContainerLabel
            borderStyle="noBorder"
            variant={variant}
            inboxMode={display === 'inbox'}
            isEditable={showControls}
            value={title}
            onChange={(t?: string) => {
                if (t) setConfig({ title: t })
            }}
        />
    )
}

export default ListViewTitle
