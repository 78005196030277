const pageInWorkspaceUrlRegexp = /^\/[^\/]+\/page\//
const pageInUrlRegexp = /^\/page\//

export function isBlankPagePath(url: string, isWorkspaceStyleUrl: boolean = false): boolean {
    return isWorkspaceStyleUrl ? pageInWorkspaceUrlRegexp.test(url) : pageInUrlRegexp.test(url)
}

export function isBlankPageView(view: ViewDto): boolean {
    return view.type === 'blankpage'
}

export function isBlankPageObject(object: ObjectDto, viewsForObject?: ViewDto[]): boolean {
    if (object.options?.is_blank_page) return true

    return Boolean(viewsForObject?.find(isBlankPageView))
}
