import { createSelector } from 'reselect'

import {
    APP_CREATED,
    APP_REMOVED,
    APP_SELECTED,
    APP_UPDATED,
    APPS_FETCHED,
} from '../utils/constants'
import { StackerActions } from '../utils/stackerActions'
import { StackerAPI } from '../utils/utils'

// API
class AppApi extends StackerAPI {
    path = 'apps/'

    transformFromLocal = (data) => {
        const keys = Object.keys(data)
        const out = {}
        keys.forEach((key) => {
            if (key.indexOf('options.') === -1) {
                if (key === 'options') {
                    out.options = { ...out.options, ...data.options }
                } else {
                    out[key] = data[key]
                }
            } else {
                const keyName = key.split('.')[1]
                out.options = { ...out.options, [keyName]: data[key] }
            }
        })
        return out
    }
}
const appApi = new AppApi()

class AppActions extends StackerActions {
    api = appApi

    reducer = 'apps'

    name = 'app'

    actionTypes = {
        fetched: APPS_FETCHED,
        removed: APP_REMOVED,
        created: APP_CREATED,
        updated: APP_UPDATED,
    }

    selectApp = (id) => {
        return (dispatch) => {
            dispatch({ type: APP_SELECTED, id })
        }
    }
}
const AA = new AppActions()
AA.actions = { ...AA.actions, selectApp: AA.selectApp }
export const appActions = AA.actions

// SELECTORS
export const getAppEntities = (state) => state.apps.entities
export const getAppList = (state) => state.apps.apps

export const getSelectedApp = (state) => state.apps.entities[state.apps.selected] || {}

export const getMainApp = (state) => {
    let mainApp = null
    Object.values(state.apps.entities).forEach((app) => {
        if (app.name !== 'Admin') {
            mainApp = app
        }
    })
    return mainApp
}

export const getAdminApp = (state) => {
    let adminApp = null
    Object.values(state.apps.entities).forEach((app) => {
        if (app.name === 'Admin') {
            adminApp = app
        }
    })
    return adminApp
}

export const getAppsMemoized = createSelector(getAppList, getAppEntities, (apps, entities) =>
    apps.map((appId) => entities[appId])
)
export const getAppEntitiesMemoized = createSelector(getAppEntities, (apps) => apps)

// HELPERS
