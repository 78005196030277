import cloneDeep from 'lodash/cloneDeep'

export const evaluateFormulas =
    (context) =>
    (input, extras = {}) => {
        let originalInput = input
        let { shouldTranslate } = extras
        if (shouldTranslate) {
            // Translate this string before we evaluate it
            const translations = context.translations || {}
            const language = context.language
            const dictionary = translations[language]
            if (dictionary) {
                originalInput = dictionary[originalInput] || originalInput
            }
        }

        var string = originalInput
        var wasObject = typeof string === 'object'
        var wasArray = Array.isArray(string)
        if (wasObject || wasArray) {
            string = JSON.stringify(string)
        }
        if (!string) {
            return string
        }

        //    string = String(string)
        const re =
            /({(object|block|input|data|record|user|query|url|date|image|JSON|theme|language|feature|repeater).*?})/g
        const matches = string.match(re)
        if (matches && matches.length) {
            matches.forEach((match) => {
                // Remove the { }
                try {
                    const formula = match.slice(1, match.length - 1)
                    var feature = cloneDeep(context.feature) || {}
                    // Reformat the feature settings from dicts {"default": "X", "value": "Y"} to single strings, so they can be accessed directly as feature.settings.my_setting => Y.
                    feature.settings = Object.keys(feature.settings || {}).reduce(
                        (dict, setting) => {
                            dict[setting] =
                                feature.settings[setting].value ||
                                feature.settings[setting].default ||
                                ''
                            return dict
                        },
                        {}
                    )
                    // Object.keys(context.propertyStore).forEach(path => {
                    //     set(block, path, context.propertyStore[path])
                    // })
                    var result = eval(formula)

                    if (Array.isArray(result)) {
                        result = JSON.stringify(result)
                    }
                    if (wasObject) {
                        // Escape quotes, handle nulls well in objects
                        var stringResult = JSON.stringify(result)
                        if (stringResult && stringResult[0] === '"' && stringResult[-1] === '"') {
                            result = stringResult.slice(1, -1)
                        }
                    } else if (result == null) {
                        // Don't return the string "null"
                        result = ''
                    }

                    string = string.replace(match, result)
                } catch (e) {
                    // Yeah, something didn't work.
                    // Maybe the user hasn't finished typing it?
                    // Anyway, don't show the formula to the world, please
                    if (!context.editor.isEditing) string = string.replace(match, '')
                }
            })
        }
        if (wasObject) {
            try {
                string = JSON.parse(string)
            } catch (e) {
                // We've done something bad. Let's just return the original
                return originalInput
            }
        }
        // Also do any config formulas
        return evaluateConfigFormulas(string, context)
    }

export const evaluateStyleFormulas = (context, style) => {
    const evalF = evaluateFormulas(context)
    return evalF(style)
}

export const evaluateConfigFormulas = (input, context) => {
    // console.log("Evaluating ", input)
    let originalInput = input

    var string = originalInput
    var wasObject
    if (typeof string === 'object' || Array.isArray(string)) {
        wasObject = true
        string = JSON.stringify(string)
    }
    if (!string) {
        return string
    }

    // Match on [[ ]]
    const re = /(\[\[.*?\]\])/g
    const matches = string.match(re)
    if (matches && matches.length) {
        matches.forEach((match) => {
            try {
                // Remove the [[ ]]
                const formula = match.slice(2, match.length - 2)
                console.log('[FORMULAS]: Config – Context ', context)
                console.log('[FORMULAS]: Config – Evaluating ', formula)
                var result = eval(formula)
                console.log('[FORMULAS]: Config – result ', result)

                if (Array.isArray(result)) {
                    result = JSON.stringify(result)
                }
                if (wasObject) {
                    // Escape quotes, handle nulls well in objects
                    result = JSON.stringify(result)
                    if (result[0] === `"`) result = result.slice(1, -1)
                } else if (result === null) {
                    // Don't return the string "null"
                    result = ''
                }
                string = string.replace(match, result)
            } catch (e) {
                console.log('[FORMULAS]: Config – Error ', e)
                string = string.replace(match, '')
            }
        })
    }
    if (wasObject) {
        try {
            string = JSON.parse(string)
        } catch (e) {
            // We've done something bad. Let's just return the original
            return originalInput
        }
    }
    return string
}
