import React from 'react'

import { Icon, Text } from 'v2/ui'

import { CustomRadioGroup } from '../CustomRadio'

type PercentAttributeEditorProps = {
    selectedOption?: string
    setState: (state: { [key: string]: any }) => void
    sendUpdate: any
    queuedChanges: any
    bgChecked?: string
    bgNotChecked?: string
    textColor?: string
    customRadioStyles?: React.CSSProperties
}

export const PERCENTAGE_FIELD_OPTIONS = [
    {
        value: 'percentNumber',
        label: 'Number',
        icon: <Icon icon="fa-3" size="20px" color="userInterface.accent.1000" />,
    },
    {
        value: 'percentBar',
        label: 'Bar',
        icon: <Icon icon="faBarsProgress" size="20px" color="userInterface.accent.1000" />,
    },
    {
        value: 'percentRing',
        label: 'Ring',
        icon: <Icon icon="faSpinnerThird" size="20px" color="userInterface.accent.1000" />,
    },
]

const PercentAttributeEditor = ({
    selectedOption,
    setState,
    sendUpdate,
    queuedChanges,
    bgChecked,
    bgNotChecked,
    textColor,
    customRadioStyles,
}: PercentAttributeEditorProps) => {
    const handlePercentageDisplayType = (value: 'percentNumber' | 'percentBar' | 'percentRing') => {
        setState((state: { [key: string]: any }) => {
            const newState = { ...state, percentageDisplay: value }
            // since sendUpdate is debounced, we want to batch up any changes together
            // to be processed/sent when the debounced function fires
            queuedChanges.current = { ...queuedChanges.current, percentageDisplay: value }
            sendUpdate()
            return newState
        })
    }
    return (
        <>
            <Text marginTop={1} marginBottom={1} variant="paletteSectionLabel">
                Show as
            </Text>
            <CustomRadioGroup
                selectedOption={selectedOption ?? 'percentNumber'}
                options={PERCENTAGE_FIELD_OPTIONS}
                onChange={handlePercentageDisplayType}
                bgChecked={bgChecked}
                bgNotChecked={bgNotChecked}
                textColor={textColor}
                customRadioStyles={customRadioStyles}
            />
        </>
    )
}

export default PercentAttributeEditor
