const fontWeights = (config) => {
    const { weights } = config

    return {
        pagination: {
            textArrow: weights.bold,
            text: weights.bold,
        },
        button: weights.bold,
    }
}

export default fontWeights
