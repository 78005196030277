import React, { useContext } from 'react'

import AppContext from 'app/AppContext'
import { resumeSubscription } from 'data/api/subscriptionApi'
import { formatSubscriptionCancelDate, shouldDisplayCancelDate } from 'features/billing/utils'
import { Divider, Frame } from 'features/workspace/WorkspaceSettingsModalUi'

import { Button } from 'v2/ui'

import BillingInfo from './BillingInfo'
import { isSubscriptionCancelled } from './stackerUserUtils'

export default function WorkspaceBillingSettings({ children }) {
    const { workspaceAccount } = useContext(AppContext)
    const customerId = workspaceAccount?.stripe_customer_id
    const title = customerId ? 'Billing' : 'Choose a plan'

    const subscriptionEndDateISO = workspaceAccount?.subscription_end_date
    const displayCancelDate = shouldDisplayCancelDate(workspaceAccount)
    const formattedCancelDate = formatSubscriptionCancelDate(subscriptionEndDateISO)

    return (
        <Frame title={title} flex={1}>
            <BillingInfo />
            <Divider />
            {displayCancelDate && <p>{formattedCancelDate}</p>}
            {isSubscriptionCancelled(workspaceAccount) && (
                <Button
                    buttonSize="medium"
                    disabled={false}
                    aria-label="Resume subscription"
                    mb={2}
                    width="min-content"
                    variant="Primary"
                    onClick={() => resumeSubscription()}
                >
                    Resume Subscription
                </Button>
            )}
            {children}
        </Frame>
    )
}
