import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import { permissionRuleActions } from 'data/api/permissionsApi'

export function usePermissionRules({ objectId }) {
    const dispatch = useDispatch()
    const actions = bindActionCreators(permissionRuleActions, dispatch)
    const isFetched = useSelector((state) => state.permissionRules?.fetched)
    const data = useSelector((state) => {
        if (!state.permissionRules?.permissionRules?.length) {
            return []
        }

        const allPermissionRules = state.permissionRules.permissionRules.map(
            (permissionRule) => state.permissionRules.entities[permissionRule]
        )

        return objectId
            ? allPermissionRules.filter((permissionRule) => {
                  return objectId === permissionRule?.object_id
              })
            : allPermissionRules
    })

    useEffect(() => {
        if (!isFetched) {
            actions.fetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        data,
        status: isFetched ? 'success' : 'loading',
        permissionRuleActions: actions,
    }
}
