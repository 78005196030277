import React, { FC, useEffect, useMemo } from 'react'

import { useObjects } from 'data/hooks/objects'

import { Modal } from 'v2/ui'

import usePatchableObject from './hooks/__usePatchableObject'
import { patchableActionContext } from './hooks/usePatchableActionContext'
import { useActionsContext } from './ActionsContextProvider'
import { AddAStep as AddStep } from './AddAStep'
import { EditAction } from './EditAction'
import { EditActionStep } from './EditActionStep'
import { EditField } from './EditField'
import type { ActionsContext } from './types'

type Props = {
    item: ActionDto
    object?: ObjectDto
    onRequestClose: () => void
}

const ActionEditor: FC<Props> = ({ item: __dontUseDirectlyAction, onRequestClose, object }) => {
    const {
        activeModalData,
        activeModal,
        addModalToStack,
        removeModalFromStack,
        modalStack,
        objectId: contextObjectId,
    } = useActionsContext()
    const patchableAction = usePatchableObject(__dontUseDirectlyAction)
    const action = patchableAction.draftObject

    // Normally it's ok to get `object` from useActionsContext but sometimes, e.g. in a related
    // record block, we need a different object which will be passed in as a prop
    const { data: objects } = useObjects()
    const contextObject = objects.find(({ _sid }) => _sid === contextObjectId)

    useEffect(() => {
        if (action) {
            addModalToStack('editAction')
        } else if (!action && activeModal === 'editAction') {
            removeModalFromStack()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const step = useMemo(() => {
        if (!activeModalData?.stepId) return
        return action.options.steps.find(({ id }) => id === activeModalData.stepId)
    }, [activeModalData?.stepId, action.options.steps])

    const field = useMemo(() => {
        if (!step || !activeModalData?.fieldId) return
        return step?.fields.find(({ fieldId }) => activeModalData.fieldId === fieldId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, activeModalData?.fieldId])

    function checkForUnsavedChanges(shouldCheckForChanges?: string) {
        if (
            shouldCheckForChanges &&
            (patchableAction.hasUncommittedChanges || patchableAction.hasChanges)
        ) {
            if (
                window.confirm(
                    'You have unsaved changes, are you sure you want to continue? Unsaved changes will be lost'
                )
            )
                return onRequestClose()
        } else {
            onRequestClose()
        }
    }

    return (
        <patchableActionContext.Provider value={patchableAction}>
            <Modal
                isOpen
                noDividers
                onClose={() =>
                    activeModal === 'editAction'
                        ? checkForUnsavedChanges('check-for-changes')
                        : removeModalFromStack()
                }
                size="600"
                height="700px"
                title={modalTitles[activeModal]?.({ step })}
                isSecondLayer
            >
                {modalInStack(modalStack, 'editAction') && (
                    <EditAction onRequestClose={checkForUnsavedChanges} />
                )}

                {modalInStack(modalStack, 'editStep') && step && (
                    <EditActionStep
                        item={step}
                        onRequestClose={removeModalFromStack}
                        object={(object || contextObject) as ObjectDto}
                    />
                )}

                {modalInStack(modalStack, 'AddAStep') && (
                    <AddStep onRequestClose={removeModalFromStack} />
                )}

                {modalInStack(modalStack, 'EditField') && field && step && (
                    <EditField
                        item={field}
                        step={step}
                        onRequestClose={removeModalFromStack}
                        object={(object || contextObject) as ObjectDto}
                    />
                )}
            </Modal>
        </patchableActionContext.Provider>
    )
}

export default ActionEditor

const modalTitles = {
    editAction: () => 'Action Button',
    editStep: ({ step }: { step: ActionStep | undefined }) => step?.displayName,
    AddAStep: () => 'Add a new step',
    EditField: () => 'Field options',
}

function modalInStack(stacks: ActionsContext['modalStack'], modalName: string) {
    return stacks.find(({ name }) => name === modalName)
}
