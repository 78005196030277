// @ts-strict-ignore
import { useEffect, useRef, VFC } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import { fetchWithAuth } from 'data/utils/utils'

import { useAppUserContext } from './AppUserContext'

const INTERVAL_MS = 60000
const ENDPOINT = 'track-events/'
const EVENT_NAME = 'WIP - Frontend - User Active'

const EVENTS_TO_TRACK: (keyof DocumentEventMap)[] = [
    'keydown',
    'mousemove',
    'click',
    'dblclick',
    'touchmove',
    'touchstart',
    'touchend',
]

const UserActivityTracker: VFC = () => {
    const isUserActiveRef = useRef(false)
    const { flags } = useLDFlags()

    const { user, studioUser } = useAppUserContext()

    useEffect(() => {
        const onUserAction = () => {
            isUserActiveRef.current = true
        }

        EVENTS_TO_TRACK.forEach((eventName) => document.addEventListener(eventName, onUserAction))

        const intervalId = setInterval(() => {
            if (!isUserActiveRef.current) {
                return
            }

            // Reset as soon as possible the ref to prevent sending wrong user activity
            // in case a call takes longer than INTERVAL_MS
            isUserActiveRef.current = false

            // Nothing to tracker if no user is authenticated
            if (!user) {
                return
            }
            // If we don't have a studioUser, and we're not supposed to track
            // events for Auth users, then bail now.
            if (!studioUser && !flags.enableEventTrackingForAuths) {
                return
            }

            fetchWithAuth(ENDPOINT, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ event_name: EVENT_NAME }),
            })
        }, INTERVAL_MS)

        return () => {
            clearInterval(intervalId)

            EVENTS_TO_TRACK.forEach((eventName) =>
                document.removeEventListener(eventName, onUserAction)
            )
        }
    }, [user, flags.enableEventTrackingForAuths, studioUser])

    return null
}

export default UserActivityTracker
