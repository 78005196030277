/* Code Quality: Good */

import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import { CreateDashboardButton } from 'v2/views/Dashboard/CreateDashboard'

import { getUrl } from 'app/UrlService'
import WithObject from 'data/wrappers/WithObject'
import WithPages from 'data/wrappers/WithPages'
import WithRecords from 'data/wrappers/WithRecords'
import { withUser } from 'data/wrappers/WithUser'
import { withViews } from 'data/wrappers/WithViews'
import { Icon8, Section, Tooltip } from 'legacy/v1/ui'

import { Button, Flex } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Icon from 'ui/deprecated/atoms/Icon'

const colors = stackerTheme().colors

class AppModalDataLayouts extends React.Component {
    state = {}

    render() {
        const { feature, objectId, onCloseSettingsModal, noPadding } = this.props
        return (
            <Section style={{ width: '100%', marginBottom: 0, padding: noPadding ? 0 : undefined }}>
                <ViewPagesList feature={feature} onCloseSettingsModal={onCloseSettingsModal} />
                <div>
                    <CreateDashboardButton
                        isSecondLayer={true}
                        objectId={objectId}
                        featureId={feature._sid}
                    />
                </div>
            </Section>
        )
    }
}

export default AppModalDataLayouts

const cogIconPositionStyle = { marginLeft: 12, marginTop: -4 }

const CogIcon = ({ onClick }) => {
    return (
        <Button variant="adminSecondary" buttonSize="sm" onClick={onClick} icon="cog">
            Edit
        </Button>
    )
}
const _ViewPagesList = ({ feature, userActions, history, views, onCloseSettingsModal }) => {
    const viewDict = useMemo(() => mapKeys(views, (view) => view._sid), [views])

    const getViewName = (view, page) => {
        switch (get(view, 'type')) {
            case 'list':
                return get(view, 'name', page.name)
            case 'create':
                return 'Create'
            case 'detail':
                return 'Detail'
            case 'dashboard':
                return get(view, 'name', page.name)
            default:
                return page.name
        }
    }
    const loadDetailSetting = (view) => {
        if (get(view, 'type') === 'detail') {
            // For detail view, we will redirect to the first record
            // if there are no records, we will suggest to add one
            const objectId = get(feature, 'options.object_id')
            return (
                <WithRecords objectId={objectId}>
                    {({ records }) => {
                        if (records && records.length > 0) {
                            // There is a record, we redirect to that URL
                            const url = getUrl(`${feature.url}/view/${records[0]._sid}`)
                            return (
                                <CogIcon
                                    onClick={() => {
                                        userActions.startEditing()
                                        history.push(url)

                                        onCloseSettingsModal()
                                    }}
                                />
                            )
                        } else {
                            // There are no records, we will show a tooltip asking the user to add a record
                            return (
                                <Tooltip
                                    id="save-view-name"
                                    tooltip="This table is empty, you must first add a record to update the detail layout"
                                    align="right"
                                >
                                    <Icon8
                                        icon="settings"
                                        size="88"
                                        displaySize="22"
                                        iconStyle="ios"
                                        style={cogIconPositionStyle}
                                    />
                                </Tooltip>
                            )
                        }
                    }}
                </WithRecords>
            )
        }
        return ''
    }
    const objectId = get(feature, 'options.object_id')

    const getEditingUrl = (view, feature, page) => {
        const type = view.type
        const featureUrl = `${feature.url}${page.url}`
        const viewUrl = view.url
        //this logic is unified with getPageFromUrl. It's a tech debt
        // and there are plans to always use view url instead of feature url
        return getUrl(type === 'list' ? viewUrl ?? featureUrl : featureUrl)
    }

    return (
        <WithPages showFeatureConfigData>
            {({ pages }) => {
                return (
                    <WithObject objectId={objectId}>
                        {({ object }) => {
                            const pagesToShow = pages
                                .filter((page) => page.feature_id === feature._sid)
                                .filter((page) => !page.is_feature_settings)
                                .filter((page) =>
                                    object?.connection_options?.read_only ?? false
                                        ? page.options.view_type !== 'create'
                                        : true
                                )

                            return pagesToShow.map((page, index) => {
                                const view = get(viewDict, get(page, 'options.view_id')) || ''
                                return (
                                    <Flex column alignItems="flex-start" key={index}>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="space-between"
                                            style={{ width: '100%' }}
                                        >
                                            <b style={{ minWidth: 300, fontSize: '1.1rem' }}>
                                                {getViewName(view, page)}
                                                {get(view, 'type') === 'dashboard' && (
                                                    <span
                                                        style={{
                                                            paddingLeft: 8,
                                                            color: colors.gray[700],
                                                        }}
                                                    >
                                                        <Icon icon="chart" />
                                                    </span>
                                                )}
                                            </b>
                                            {get(view, 'type') === 'detail' ? (
                                                loadDetailSetting(view)
                                            ) : (
                                                <CogIcon
                                                    onClick={() => {
                                                        userActions.startEditing()
                                                        history.push(
                                                            getEditingUrl(view, feature, page)
                                                        )

                                                        onCloseSettingsModal()
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                        <div style={{ flexBasis: 15 }} />
                                    </Flex>
                                )
                            })
                        }}
                    </WithObject>
                )
            }}
        </WithPages>
    )
}

const ViewPagesList = withUser(withRouter(withViews(_ViewPagesList)))
