import React from 'react'

import { ControlBox, useRadio, useRadioGroup } from '@chakra-ui/react'

import Box from 'v2/ui/components/Box'

type RadioProps = ReturnType<ReturnType<typeof useRadioGroup>['getRadioProps']>

type RadioButtonProps = RadioProps & {
    size?: 'sm' | 'md' | 'lg'
    checkedRadioColor?: string
}

const RadioButton: React.FC<RadioButtonProps> = ({
    size = 'md',
    children,
    checkedRadioColor,
    ...radioProps
}) => {
    const sizes = { sm: '12px', md: '16px', lg: '20px' }
    const styleProps = {
        boxSize: sizes[size],
        bg: 'white',
        border: '2px',
        rounded: 'full',
        type: 'radio',
        borderColor: 'grey.200',
        _focus: { boxShadow: 'outline' },
        _hover: { borderColor: 'gray.300' },
        _disabled: { opacity: '40%' },
        _checked: {
            borderColor: checkedRadioColor ? checkedRadioColor : 'grey.400',
            color: checkedRadioColor ? checkedRadioColor : 'grey.400',
        },
    }

    const { getInputProps } = useRadio(radioProps)
    const { disabled: isDisabled } = radioProps

    const inputProps = getInputProps()

    return (
        <Box
            as="label"
            display="inline-flex"
            verticalAlign="top"
            alignItems="center"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
        >
            <input {...inputProps} />
            <ControlBox {...styleProps} type="radio" rounded="full">
                <Box bg="currentColor" as="span" rounded="full" boxSize="50%" />
            </ControlBox>
            {children && (
                <Box ml={2} fontSize={size} userSelect="none" opacity={isDisabled ? 0.32 : 1}>
                    {children}
                </Box>
            )}
        </Box>
    )
}

RadioButton.displayName = 'Radio'

export default RadioButton
