import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { UserApi } from 'data/api/userApi'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { buildUrl } from 'data/utils/utils'
import { hexToString } from 'utils/utils'

import { Banner, Box, Collapse, Flex, Icon, Link } from 'v2/ui'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import Form from 'ui/forms/Form'
import SubmitButton from 'ui/forms/SubmitButton'

import analytics from '../../../utils/analytics'

import AuthPageFrame from './AuthPageFrame'
import { Button, InputForm, Text } from './AuthUIElements'
import { StyledGoogleLoginButton } from './GoogleLoginButton'

const OpenSignupPage = ({ stack_details }) => {
    const { isFetching: loadingMetadata } = useInitialMetadata()
    const emailFormContext = useRef()
    const [email, setEmail] = useUrlParam('email', '')
    const [disabled, setDisabled] = useState(false)
    const [openSignupStackSid, setOpenSignupStackSid] = useState('')
    const [failureMessage, setFailureMessage] = useState()
    const history = useHistory()
    const { workspaceAccount } = useAppContext()

    useMemo(() => {
        if (!stack_details) return
        setDisabled(!stack_details.open_signup.is_enabled || !stack_details.open_signup.is_eligible)
        setOpenSignupStackSid(stack_details._sid)
    }, [stack_details])

    // Handles the case where an google signin fails and the user is redirected
    // back here with an error message in the URL
    let query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    const queryError = query.error
    const handleFailure = (response) => {
        if (response && response.exception) {
            if (response.exception === 'UserAlreadyExists') {
                setFailureMessage('This Google account is already registered for this app.')
            } else {
                setFailureMessage('An error occured trying to register with this Google Account.')
            }
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra('response', response)
                scope.setLevel('error')
                Sentry.captureMessage('Unable to register')
            })
        }
    }

    useEffect(() => {
        if (queryError) {
            const { error, ...rest } = query
            history.replace(buildUrl(window.location.pathname, rest))
            const errorJson = hexToString(error)
            const response = JSON.parse(errorJson)
            handleFailure(response)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryError])

    const handleSubmitOpenSignup = (data) => {
        return UserApi.handleOpenSignUp(data.email, openSignupStackSid)

            .then((response) => {
                if (response.error) {
                    emailFormContext.current?.setError('email', {
                        type: 'manual',
                        message: response.error,
                    })
                    analytics.track('open signup registration failed', {
                        event_category: 'user',
                        event_description: 'Open signup registration failed',
                        error: response.error,
                        authentication_method: 'email',
                    })
                } else if (!response.success & response?.google) {
                    emailFormContext.current?.setError('email', {
                        type: 'manual',
                        message:
                            'This email is already registered with Google. Please sign in with Google above to continue.',
                    })
                } else if (!response.success) {
                    // Form should be disabled on ineligible apps
                    // But we should keep this here as a failsafe
                    emailFormContext.current?.setError('email', {
                        type: 'manual',
                        message: 'Stack is not eligible for open signup.',
                    })
                    analytics.track('open signup registration failed', {
                        event_category: 'user',
                        event_description: 'Stack is ineligible for open signup registration',
                        error: response.error,
                        authentication_method: 'email',
                    })
                } else {
                    setEmail(data.email)
                    if (response.is_new_user) {
                        analytics.identify(response.user_sid, {
                            email: data.email,
                        })
                    }
                    if (response.status === 'registration_started') {
                        analytics.track('open signup registration started', {
                            workspace_id: workspaceAccount._sid,
                            user_id: response.user_sid,
                            event_description: 'Open signup registration started',
                            event_category: 'user',
                            is_new_user: response.is_new_user,
                        })
                    }
                }

                if (response.status === 'already_registered_send_email') {
                    analytics.track('login email sent via open signup', {
                        workspace_id: workspaceAccount._sid,
                        user_id: response.user_sid,
                        event_description: 'Login email sent via open signup',
                        event_category: 'user',
                    })
                    history.push(
                        `${getUrl(Urls.Login)}?r=${encodeURIComponent(
                            stack_details?.url_slug
                        )}&email=${encodeURIComponent(data.email)}`
                    )
                }
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                emailFormContext.current?.setError('email', {
                    type: 'manual',
                    message: 'An unknown error occurred.',
                })
            })
    }

    return (
        <AuthPageFrame title="Create an account">
            <Collapse isOpen={!!loadingMetadata}>
                <Flex height="100%" align="center" column py="100px">
                    <Spinner />
                </Flex>
            </Collapse>
            <Collapse isOpen={!!!loadingMetadata}>
                <Flex column align="stretch" mb={3}>
                    <Collapse isOpen={!!failureMessage}>
                        <Text my={6} bg="#22222233" p={4} rounded="md">
                            <Icon icon="alert" mr={2} display="inline" />
                            {failureMessage}
                        </Text>
                    </Collapse>
                    <GoogleSignUpButton
                        openSignupForStack={openSignupStackSid}
                        stackSlug={stack_details?.url_slug}
                        disabled={disabled}
                    />
                    <Text my={6} alignSelf="center">
                        - or -
                    </Text>
                    <Collapse isOpen={!!!email}>
                        <Form
                            ref={emailFormContext}
                            options={{
                                mode: 'onSubmit',
                            }}
                            onSubmit={handleSubmitOpenSignup}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                            }}
                        >
                            <InputForm
                                autoFocus
                                noMargin
                                name="email"
                                type="email"
                                placeholder="Enter email address"
                                errorMessages={{
                                    required: 'Please enter your email address',
                                    pattern: 'Please enter a valid email address',
                                }}
                                required
                                pattern={/^\S+@\S+$/i}
                                disabled={disabled}
                            />
                            <SubmitButton
                                mt={3}
                                buttonSize="sm"
                                as={Button}
                                disabled={disabled}
                                aria-label="Continue with email"
                            >
                                Continue with email
                            </SubmitButton>
                        </Form>
                        {!disabled ? (
                            <Text mt={3} style={{ alignSelf: 'center' }}>
                                <span>Already have an account? </span>
                                <Link to={`${Urls.Login}`}>Login</Link>
                            </Text>
                        ) : (
                            <Banner mt={3}>
                                <span>
                                    Registration is not available for this app. Please contact the
                                    app administrator for access. If you already have an account or
                                    if you are the administrator then please{' '}
                                    <Link to={`${Urls.Login}`}>log in to this workspace</Link>.
                                </span>
                            </Banner>
                        )}
                    </Collapse>

                    <Collapse isOpen={!!email}>
                        <Box textAlign="left">
                            <Icon
                                icon="emailSolid"
                                size="smmd"
                                display="inline"
                                color="brand.200"
                            />
                            <Text ml={2} display="inline">
                                <strong>Check your email</strong>
                            </Text>
                            <Text mt={2}>
                                Click the link in the email to complete registration.
                            </Text>
                        </Box>
                    </Collapse>
                </Flex>
            </Collapse>
        </AuthPageFrame>
    )
}

export const GoogleSignUpButton = ({ openSignupForStack, stackSlug, disabled }) => {
    const return_url = `${window.location.origin}/${stackSlug}`
    const failUrl = `${window.location.origin}/${stackSlug}/register`

    return (
        <StyledGoogleLoginButton
            disabled={disabled}
            href={`${
                settings.OAUTH_REDIRECT_URL
            }authenticate/google?open_signup_for_stack=${encodeURIComponent(
                openSignupForStack
            )}&return_url=${encodeURIComponent(return_url)}&fail_url=${encodeURIComponent(
                failUrl
            )}`}
            openInNewTab={false}
        >
            Sign up with Google
        </StyledGoogleLoginButton>
    )
}

export default OpenSignupPage
