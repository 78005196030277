import React, { FC, useMemo } from 'react'

import { ResponsiveContainer } from 'recharts'
import { ViewLayoutContext } from 'v2/blocks/types'

import { useAppContext } from 'app/AppContext'
import { useInvalidateObjectAggregates } from 'data/hooks/aggregates'
import { useMetrics } from 'data/hooks/metrics'
import { useObject } from 'data/hooks/objects'
import { useViewFromId } from 'data/hooks/views'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'
import { RechartsBar } from 'features/charts/recharts/Bar'
import { RechartsLine } from 'features/charts/recharts/Line'
import RechartsPie from 'features/charts/recharts/Pie'

import { Box, Text } from 'v2/ui'

import { Wrapper } from '../common/Wrapper'

import { useChartConfiguration } from './hooks/useChartConfiguration'
import { getBarChartOptions } from './utils/getBarChartOptions'
import { getChartDisplayConfig } from './utils/getChartDisplayConfig'
import { getLineChartOptions } from './utils/getLineChartOptions'
import { getPieChartOptions } from './utils/getPieChartOptions'
import { ChartBlockLoadingError } from './ChartBlockLoadingError'
import { CHART_BLOCK_HEIGHT } from './constants'
import { InvalidChartBlock } from './InvalidChartBlock'
import { NoVisibleRecordsChartBlock } from './NoVisibleRecordsChartBlock'

type ChartBlockProps = {
    metric: ChartBlockAttributes
    numberOfMetrics: number
    isEditing: boolean
    isActive: boolean
    context: ViewLayoutContext
    isOnListView?: boolean
    onClick?: () => void
}

export const ChartBlock: FC<ChartBlockProps> = ({
    metric,
    numberOfMetrics,
    isActive,
    isEditing,
    context,
    isOnListView,
    onClick,
}) => {
    const view = useViewFromId(metric.inheritFiltersFromViewId)

    const metricConfiguration = useChartConfiguration(metric, view, context?.record)
    const { object: chartObject } = useObject(metricConfiguration.object_sid)

    const { selectedStack } = useAppContext()

    const invalidateObjectAggregates = useInvalidateObjectAggregates()
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: chartObject ? [chartObject?._sid] : [],
        handler: () => {
            chartObject && invalidateObjectAggregates(chartObject?._sid)
        },
    })

    const { data: metrics, error, isFetching } = useMetrics(metricConfiguration)

    const displayConfig = useMemo(() => getChartDisplayConfig(metric), [metric])

    const pieChartOptions = useMemo(() => getPieChartOptions(metric), [metric])
    const barChartOptions = useMemo(() => getBarChartOptions(metric), [metric])
    const lineChartOptions = useMemo(() => getLineChartOptions(metric), [metric])

    const isBucketRequired = ['date', 'datetime'].includes(
        metricConfiguration.group.fieldType ?? ''
    )

    const isConfigValid =
        (isBucketRequired && !metricConfiguration.group.bucketBy) ||
        !metricConfiguration.group.field_sid
            ? false
            : true

    return (
        <>
            {!isConfigValid && (
                <InvalidChartBlock
                    numberOfMetrics={numberOfMetrics}
                    isActive={isActive}
                    isEditing={isEditing}
                    isOnListView={isOnListView}
                />
            )}
            {isConfigValid && metrics && metrics?.data.length === 0 && (
                <NoVisibleRecordsChartBlock
                    numberOfMetrics={numberOfMetrics}
                    isActive={isActive}
                    isEditing={isEditing}
                />
            )}
            {isConfigValid && (!metrics || metrics?.data.length > 0) && (
                <Wrapper
                    p={6}
                    numberOfMetrics={numberOfMetrics}
                    isEditing={isEditing}
                    isActive={isActive}
                    height={CHART_BLOCK_HEIGHT}
                    isOnListView={isOnListView}
                    onClick={onClick}
                    position="relative"
                    zIndex={3}
                >
                    {isConfigValid && metrics && (
                        <>
                            {!metric.hideTitle && (
                                <Text
                                    fontWeight="bold"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    w="100%"
                                    mb={3}
                                    alignSelf="flex-start"
                                    color="userInterface.neutral.1000"
                                    fontSize={18}
                                    flexShrink={0}
                                >
                                    {metric.title}
                                </Text>
                            )}
                            <Box height={metric.hideTitle ? '100%' : `calc(100% - 20px)`} flex={1}>
                                {(metric.type === 'Pie' || !metric.type) && !isFetching && (
                                    <ResponsiveContainer width="100%" height="100%">
                                        <RechartsPie
                                            metrics={metrics}
                                            chartObject={chartObject}
                                            config={metricConfiguration}
                                            display={displayConfig}
                                            options={pieChartOptions}
                                            legacy={false}
                                        />
                                    </ResponsiveContainer>
                                )}
                                {metric.type === 'Bar' && !isFetching && (
                                    <ResponsiveContainer width="100%" height="100%">
                                        <RechartsBar
                                            metrics={metrics}
                                            chartObject={chartObject}
                                            config={metricConfiguration}
                                            display={displayConfig}
                                            options={barChartOptions}
                                            legacy={false}
                                        />
                                    </ResponsiveContainer>
                                )}
                                {metric.type === 'Line' && !isFetching && (
                                    <ResponsiveContainer width="100%" height="100%">
                                        <RechartsLine
                                            metrics={metrics}
                                            chartObject={chartObject}
                                            config={metricConfiguration}
                                            display={displayConfig}
                                            options={lineChartOptions}
                                        />
                                    </ResponsiveContainer>
                                )}
                            </Box>
                        </>
                    )}

                    {!metrics && error?.length > 0 && (
                        <ChartBlockLoadingError metric={metric} error={error} />
                    )}
                </Wrapper>
            )}
        </>
    )
}
