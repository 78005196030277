import React from 'react'

import { Button, Flex } from 'v2/ui'
import { SolidDuplicate } from 'v2/ui/svgs'

type BlankPageViewEditorAdditionalActionsProps = React.ComponentPropsWithoutRef<'div'> &
    BlankPage & {
        onDuplicatePage?: () => void
    }

export const BlankPageViewEditorAdditionalActions: React.FC<
    BlankPageViewEditorAdditionalActionsProps
> = ({ onDuplicatePage, ...props }) => {
    return (
        <Flex m={0} p={0} justifyContent="space-between" zIndex={1} alignItems="center" {...props}>
            <Button
                label="Duplicate page"
                variant="clear"
                p={0}
                onClick={onDuplicatePage}
                icon={<SolidDuplicate />}
            />
        </Flex>
    )
}
