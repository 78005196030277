import * as Sentry from '@sentry/react'
import merge from 'lodash/merge'

import { getCurrentStack } from 'app/AppContextStore'
import { AUTH0_ACCESS_TOKEN_LOCALSTORAGE_KEY } from 'app/settings'
import { getPreviewingUser } from 'features/auth/utils/roleUtils'

import analytics from '../../utils/analytics'
import {
    DISCARD_EDITING_REQUEST,
    DISCARD_STOP_EDITING_REQUEST,
    EDITING_START_REQUESTED,
    EDITING_STARTED,
    EDITING_STOP_REQUESTED,
    EDITING_STOPPED,
    END_USER_LOGGED_IN,
    END_USER_LOGGED_OUT,
    FETCH_COMPLETED,
    FETCH_STARTED,
    IMPERSONATION_STARTED,
    IMPERSONATION_STOPPED,
    RECORD_UPDATED,
    RECORDS_FETCHED,
    SET_REDIRECT,
    SET_STREAM_TOKEN,
    SET_USED_TEMP_AUTH_TOKEN,
    STACK_SELECTED,
    STUDIO_USER_LOGGED_IN,
    STUDIO_USER_LOGGED_OUT,
    STUDIO_USER_UPDATED,
    USER_DATA_FETCHED,
    USER_LIST_FETCHED,
    USER_LIST_ITEM_ADDED,
    USER_UPDATED,
} from '../utils/constants'

export const getInitialEndUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user'))
    } catch (e) {
        return null
    }
}

export const getInitialStudioUser = () => {
    try {
        if (localStorage.getItem('studio_user') === 'undefined') return null
        return JSON.parse(localStorage.getItem('studio_user'))
    } catch (e) {
        return null
    }
}

const getInitialUser = () => {
    return getInitialEndUser() || getInitialStudioUser() || null
}

const setStudioUser = (user) => {
    if (user) {
        localStorage.setItem('studio_user', JSON.stringify(user))
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user._sid,
                email: user.email,
                username: user.name,
                integration_key: user.integration_key,
            })
        })
        analytics.identify(user._sid)
    } else {
        localStorage.setItem('studio_user', '')
        Sentry.configureScope((scope) => {
            scope.setUser(null)
        })
    }
}
const setStudioUserToken = (token) => {
    if (token) {
        localStorage.setItem('studio_token', token)
    } else {
        localStorage.setItem('studio_token', '')
    }
}
const clearStudioToken = () => localStorage.setItem('studio_token', '')

const setUser = (user) => localStorage.setItem('user', JSON.stringify(user))
const setUserToken = (token) => localStorage.setItem('user_token', token)
const clearUserToken = () => localStorage.setItem('user_token', '')
const clearStudioUser = () => localStorage.setItem('studio_user', '')
const clearUser = () => localStorage.setItem('user', '')

// STATE
const initialState = {
    user: getInitialUser(),
    studioUser: getInitialStudioUser(),
    entities: {},
    fetching: [],
    selected: null,
    isEditing: false,
    usersList: [],
    userListFetched: false,
    allUserDataFetched: false,
    currentUserRecord: {},
}

// REDUCERS
export function userReducer(state = initialState, action) {
    const selectedStack = getCurrentStack()
    let fetching
    let studioUser
    let user
    let records
    switch (action.type) {
        case SET_USED_TEMP_AUTH_TOKEN:
            return { ...state, usedTempAuthToken: action.payload }

        case END_USER_LOGGED_IN:
            setUser(action.payload)
            setUserToken(action.payload.api_token)
            return { ...state, user: action.payload, isEditing: false }

        case END_USER_LOGGED_OUT:
            setUser(state.studioUser || null)
            clearUserToken()
            return { ...state, user: state.studioUser }
        case IMPERSONATION_STARTED:
            // If this isn't a workspace app, then we update Local storage with the
            // impersonated user. However, for workspace apps, we just update redux state.
            if (!selectedStack?.options?.workspace_app) {
                setUser(action.payload)
            }
            return { ...state, user: action.payload, isEditing: false }

        case IMPERSONATION_STOPPED:
            // If this isn't a workspace app, then we update Local storage with the
            // impersonated user. However, for workspace apps, we just update redux state.
            if (!selectedStack?.options?.workspace_app) {
                setUser(state.studioUser)
            }
            return { ...state, user: state.studioUser }

        case STUDIO_USER_LOGGED_IN:
            setStudioUser(action.payload)
            setStudioUserToken(action.payload.api_token)
            setUser(action.payload)
            clearUserToken()
            return { ...state, studioUser: action.payload, user: action.payload }

        case STUDIO_USER_LOGGED_OUT:
            clearStudioUser()
            clearUser()
            clearUserToken()
            clearStudioToken()
            localStorage.removeItem(AUTH0_ACCESS_TOKEN_LOCALSTORAGE_KEY)
            localStorage.removeItem('support_login')
            return { ...state, studioUser: null, user: null }

        case SET_STREAM_TOKEN:
            user = state.user
            studioUser = state.studioUser

            // Update both the user and the studioUser where the ids match.
            // (don't update if the value isn't changing, as updates to the user
            // object trigger app level query reloads!)
            if (
                action.payload.userId === user?._sid &&
                user?.stream_user_token !== action.payload.token
            ) {
                user = { ...user, stream_user_token: action.payload.token }
                setUser(user)
            }
            if (
                action.payload.userId === studioUser?._sid &&
                studioUser?.stream_user_token !== action.payload.token
            ) {
                studioUser = { ...studioUser, stream_user_token: action.payload.token }
                setStudioUser(studioUser)
            }
            return { ...state, user, studioUser }
        case USER_UPDATED:
            // if we have a valid user object, update local storage-- this way we keep
            // local storage in sync with reality.
            if (action.payload?._sid) {
                setUser(action.payload)
            }
            // Merge because if this ever brings back nothing, we don't want to log
            // them out
            return { ...state, user: merge({ ...state.user }, action.payload) }
        case STUDIO_USER_UPDATED:
            const isImpersonating = state.studioUser && state.studioUser?._sid !== state.user?._sid
            // Merge because if this ever brings back nothing, we don't want to log
            // them out
            const newUser = merge({ ...state.studioUser }, action.payload)
            // Update local storage
            setStudioUser(newUser)
            if (!isImpersonating) {
                setUser(newUser)
            }
            return {
                ...state,
                studioUser: newUser,
                user: isImpersonating ? state.user : newUser,
            }
        // so that we know when we've fetched all of the user's data
        case USER_DATA_FETCHED:
            return { ...state, allUserDataFetched: true }

        case RECORD_UPDATED:
            // If we ever update our own record, then update the user
            studioUser = state.studioUser
            user = state.user

            if (!Array.isArray(action.payload)) {
                records = [action.payload]
            } else {
                records = action.payload
            }

            records.forEach((record) => {
                // depends if an internal or customer access user
                if (!record) return
                if (studioUser && record._sid === studioUser?._sid) {
                    studioUser = { ...studioUser, ...record }
                }
                if (user && record._sid === user?._sid) {
                    user = { ...user, ...record }
                }
            })
            return { ...state, studioUser, user }

        case RECORDS_FETCHED:
            // If we ever fetch a new version of our own record, then update the user
            studioUser = state.studioUser
            user = state.user

            if (!Array.isArray(action.payload)) {
                records = [action.payload]
            } else {
                records = action.payload
            }

            records.forEach((record) => {
                if (!record) return
                if (studioUser && record._sid === studioUser._sid) {
                    studioUser = { ...studioUser, ...record }
                }
                if (user && record._sid === user._sid) {
                    user = { ...user, ...record }
                }
            })
            return { ...state, studioUser, user }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }

        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }

        case EDITING_STARTED:
            return {
                ...state,
                isEditing: true,
                editingStartRequested: false,
                editingStopRequested: false,
            }
        case EDITING_STOP_REQUESTED:
            return {
                ...state,
                editingStopRequested: true,
            }
        case EDITING_START_REQUESTED:
            return {
                ...state,
                editingStartRequested: true,
            }
        case DISCARD_STOP_EDITING_REQUEST:
            return {
                ...state,
                editingStopRequested: false,
            }
        case EDITING_STOPPED:
            return {
                ...state,
                isEditing: false,
                editingStartRequested: false,
                editingStopRequested: false,
            }

        case DISCARD_EDITING_REQUEST:
            return {
                ...state,
                editingStartRequested: false,
                editingStopRequested: false,
            }

        case USER_LIST_FETCHED:
            return { ...state, userList: action.payload, userListFetched: true }
        case USER_LIST_ITEM_ADDED:
            return { ...state, userList: [...state.userList, ...[action.payload]] }
        case SET_REDIRECT:
            return { ...state, redirect: action.value }
        case STACK_SELECTED:
            return { ...state, isEditing: false, user: getPreviewingUser() || getInitialUser() }
        default:
            return state
    }
}
