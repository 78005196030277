export const AIRTABLE_REPAIR_ERROR_MODAL_TEST_ID = 'airtable-repair.failure-modal'
export const AIRTABLE_REPAIR_LOADING_MODAL_TEST_ID = 'airtable-repair.loading-modal'
export const AIRTABLE_REPAIR_SUCCESS_MODAL_TEST_ID = 'airtable-repair.success-modal'
export const AIRTABLE_UPGRADE_NO_PERMISSION_MODAL_TEST_ID =
    'airtable-upgrade.failure-no-permission-modal'
export const AIRTABLE_OAUTH_INSTRUCTIONS_MODAL_TEST_ID = 'airtable-oauth.instructions-modal'

const AIRTABLE_REPAIR_MODALS = [
    AIRTABLE_REPAIR_ERROR_MODAL_TEST_ID,
    AIRTABLE_REPAIR_LOADING_MODAL_TEST_ID,
    AIRTABLE_REPAIR_SUCCESS_MODAL_TEST_ID,
    AIRTABLE_UPGRADE_NO_PERMISSION_MODAL_TEST_ID,
    AIRTABLE_OAUTH_INSTRUCTIONS_MODAL_TEST_ID,
] as const

export type AirtableRepairModalTestId = typeof AIRTABLE_REPAIR_MODALS[number]

export const AIRTABLE_REPAIR_MODALS_SET = new Set(AIRTABLE_REPAIR_MODALS)

export const AIRTABLE_REPAIR_BUTTON_TEST_ID = 'airtable-oauth.repair-button'

export const AIRTABLE_NO_PERMISSION_RETRY_BUTTON_TEST_ID = 'airtable-no-permission.retry-button'
