import styled from '@emotion/styled'

import { Flex } from 'v2/ui'

export const ChartLabel = styled(Flex)`
    color: #21232b;
    font-weight: 600;
    font-size: 18px;
`

export const PieChartContainer = styled(Flex)`
    justify-content: center;
    align-items: center;

    .recharts-wrapper svg {
        overflow: inherit !important;
    }
`
