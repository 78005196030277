/* Code Quality: Not audited */

import React from 'react'

import WithApps from './WithApps'

const WithApp = (props) => {
    return (
        <WithApps>
            {({ apps, onChange, createApp }) => {
                const matches = apps.filter((app) => app._sid === props.appId)
                var app

                if (props.ignoreLoading) {
                    app = matches.length ? matches[0] : { options: {} }
                } else {
                    if (!matches.length) return props.loading ? this.props.loading : ''
                    app = matches[0]
                }

                return props.children({
                    app,
                    onChange: (dict) => onChange(app._sid, dict),
                    createApp,
                })
            }}
        </WithApps>
    )
}

export default WithApp
