import React, { FC, useContext, useMemo } from 'react'

import sortBy from 'lodash/sortBy'
import { ViewLayoutContext } from 'v2/blocks/types'

import { useObjects } from 'data/hooks/objects'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'

import { Flex } from 'v2/ui'

import { AggregationBlockContext } from './AggregationBlockContext'
import type { InvalidBlockProps } from './InvalidBlock'
import type { BlockProps } from './types'

type Props = {
    attrs: { metrics: (ChartBlockAttributes | MetricsBlockAttributes)[] }
    blockId: string | undefined
    context: ViewLayoutContext
    isOnListView?: boolean
    Block: FC<BlockProps>
    BlockEmptyState: FC
    BlockInvalidState: FC<
        Pick<
            InvalidBlockProps,
            'numberOfMetrics' | 'isEditing' | 'isActive' | 'isOnListView' | 'onClick'
        >
    >
    isValid: (
        aggregate: ChartBlockAttributes | MetricsBlockAttributes,
        object?: ObjectDto
    ) => boolean
}

export const AggregationBlock: FC<Props> = ({
    attrs,
    blockId,
    context,
    isOnListView,
    Block,
    BlockEmptyState,
    BlockInvalidState,
    isValid,
}) => {
    const { metrics } = attrs

    const { data: objects } = useObjects()

    const { onStartEditingWidget } = useContext(AggregationBlockContext)
    const { selectedWidgetId } = useContext(LayoutEditorContext)
    const { isOpen: isEditing } = useEditMode()

    const sortedMetrics = useMemo(
        () =>
            sortBy(
                metrics
                    .map((metric) => ({
                        ...metric,
                        isValid: isValid(
                            metric,
                            objects?.find((o) => o._sid === metric.objectId)
                        ),
                    }))
                    .filter(({ isValid }) => isEditing || isValid),
                ({ order }) => order
            ),
        [isEditing, isValid, metrics, objects]
    )

    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    if (passesFilters && !isEditing) {
        return null
    }

    if (sortedMetrics.length === 0) {
        return isEditing ? <BlockEmptyState /> : null
    }

    return (
        <Flex
            flexDirection="row"
            justifyContent="space-between"
            columnGap={3}
            rowGap={3}
            flexWrap="wrap"
            mb={4}
            width="100%"
        >
            {sortedMetrics.map((metric) =>
                metric.isValid ? (
                    <Block
                        onClick={
                            isEditing
                                ? () => {
                                      onStartEditingWidget?.(metric)
                                  }
                                : undefined
                        }
                        context={context}
                        key={metric.order}
                        metric={metric}
                        numberOfMetrics={sortedMetrics.length}
                        isEditing={isEditing}
                        isActive={selectedWidgetId === `${blockId}-${metric.order}`}
                        isOnListView={isOnListView}
                    />
                ) : (
                    <BlockInvalidState
                        onClick={
                            isEditing
                                ? () => {
                                      onStartEditingWidget?.(metric)
                                  }
                                : undefined
                        }
                        key={metric.order}
                        numberOfMetrics={sortedMetrics.length}
                        isEditing={isEditing}
                        isActive={selectedWidgetId === `${blockId}-${metric.order}`}
                        isOnListView={isOnListView}
                    />
                )
            )}
        </Flex>
    )
}
