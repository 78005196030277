// @ts-strict-ignore
import React, { useEffect, useRef, useState, VFC } from 'react'

import { isEqual } from 'lodash'
import { filtersToValue } from 'v2/views/Dashboard/forms/FiltersForm'

import useLDFlags from 'data/hooks/useLDFlags'
import {
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Checkbox, Collapse, Text } from 'v2/ui'

type Props = {
    object: ObjectDto | undefined
    field: FieldDto | null
    value: any
    onChange: (filters: any) => void
    contextRecordObject: ObjectDto
}

const FiltersConfiguration: VFC<Props> = ({
    object,
    field,
    value,
    onChange,
    contextRecordObject,
}) => {
    const { flags } = useLDFlags()
    const lastUpdatedValue = useRef<ReturnType<typeof formatFilters>>()

    const [showFilters, setShowFilters] = useState(
        !!field?.connection_options?.relationship_target_lookup_filters &&
            field?.connection_options?.relationship_target_lookup_filters?.length > 0
    )

    const [filters, setFilters] = useState(
        !!object && !!field?.connection_options?.relationship_target_lookup_filters
            ? filtersToValue(field?.connection_options?.relationship_target_lookup_filters, object)
            : []
    )

    useEffect(() => {
        if (isEqual(!lastUpdatedValue.current, value)) {
            setFilters(object ? filtersToValue(value, object) : [])
        }
    }, [object, value])

    const updateFilters = (rawFilters) => {
        const formattedFilters = formatFilters(rawFilters)
        lastUpdatedValue.current = formattedFilters
        onChange(formattedFilters)
    }

    const handleToggleShowFilters = () => {
        if (showFilters) {
            onChange([])
        } else {
            updateFilters(filters)
        }
        setShowFilters((prev) => !prev)
    }

    return (
        <>
            <Checkbox
                style={{ marginTop: '8px' }}
                isDisabled={!object}
                isChecked={showFilters}
                onChange={handleToggleShowFilters}
            >
                <Text fontSize="13px">Limit the records which can be selected</Text>
            </Checkbox>
            <Collapse isOpen={showFilters} marginTop={2}>
                {/* @ts-ignore */}
                <Filters
                    object={object}
                    fields={object?.fields}
                    value={filters}
                    onChange={updateFilters}
                    isCreate={!field}
                    addButtonVariant="Secondary"
                    contextRecordObject={
                        flags.contextRecordFilter ? contextRecordObject : undefined
                    }
                />
            </Collapse>
        </>
    )
}

export default FiltersConfiguration
