// @ts-strict-ignore
import React, { useState } from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'

import { Button, Flex, Input } from 'v2/ui'

import { getDeleteTableModalData } from './utils'

export const DeleteNativeTableButton = ({ object, onDeleteObject, buttonComponent }) => {
    getDeleteTableModalData(object)

    const { show: showConfirm, setData } = useConfirmModal({
        ...getDeleteTableModalData(object),
        onConfirm: (modal) => {
            setData({ ...getDeleteTableModalData(object), isSaving: true })
            onDeleteObject(object?._sid)
                .then(() => {
                    refetchObjects()
                })
                .then(() => {
                    setData({ ...getDeleteTableModalData(object), isSaving: false })
                    modal.toggle()
                })
        },
    })

    if (!object?.connection_options?.stacker_native_object) {
        return null
    }

    const boundComponent = buttonComponent
        ? React.cloneElement(buttonComponent, {
              onClick: showConfirm,
          })
        : null

    return (
        boundComponent || (
            <div>
                <Button icon="trash" onClick={showConfirm} variant="Primary" buttonSize="sm">
                    Delete this table
                </Button>
            </div>
        )
    )
}

export const RenameNativeTableForm = ({ object, onChangeObject }) => {
    const [objectName, setObjectName] = useState(object?.name)

    return (
        <Flex>
            <Input w="310px" onChange={(e) => setObjectName(e.target.value)} value={objectName} />
            <Button
                variant="Primary"
                buttonSize="sm"
                style={{ marginLeft: '10px' }}
                isDisabled={!objectName || objectName === object?.name}
                onClick={() => {
                    onChangeObject({ name: objectName })
                }}
            >
                Save
            </Button>
        </Flex>
    )
}
