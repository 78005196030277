import React, { ChangeEvent } from 'react'

import { Checkbox } from 'v2/ui'

import { RenderRowAttributeRenderProps } from '../../RenderRowAttribute'

type CheckboxAttributeProps = RenderRowAttributeRenderProps & {
    value?: boolean
}

const CheckboxAttribute: React.VFC<CheckboxAttributeProps> = ({ value, onChange, ...props }) => {
    return (
        /* @ts-ignore */
        <Checkbox
            isChecked={!!value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
            {...props}
        />
    )
}

export default CheckboxAttribute
