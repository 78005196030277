import React from 'react'

export const PermissionRuleLabel = ({
    text,
    className = '',
}: {
    text: string
    className?: string
}): React.ReactElement => {
    return (
        <div
            style={{
                fontSize: 14,
                fontWeight: 600,
                marginBottom: 6,
            }}
            className={className}
        >
            {text}
        </div>
    )
}
