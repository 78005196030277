import { getCurrentStackId } from 'app/AppContextStore'
import type { SlidingPaneContextInfo, SlidingPaneKey } from 'features/workspace/AdminSideTray/types'

import {
    EDITING_STARTED,
    EDITING_STOPPED,
    SLIDING_PANE_BLOCK_CONTENT,
    SLIDING_PANE_CLEAR_BLOCKED_CONTENT,
    SLIDING_PANE_CLEAR_CONTENT,
    SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK,
    SLIDING_PANE_CLOSE,
    SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL,
    SLIDING_PANE_OPEN,
    SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL,
    SLIDING_PANE_SET_ANIMATION_COMPLETE,
    SLIDING_PANE_SET_CONTENT_DIRTY,
    SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO,
    SLIDING_PANE_TRANSITION_TO_WIDTH,
    SLIDING_PANE_UNSET_CONTENT_DIRTY,
    STACK_SELECTED,
} from '../utils/constants'

export type SlidingPaneState = {
    key: SlidingPaneKey | null
    // Holds the key of the pane display (if any) before the current state.
    // If the tray was closed prior to the current state, then this value should be null.
    previousKey: SlidingPaneKey | null
    contentProps?: any
    contentWidth: number | null
    transitionToWidth: number | null
    transitionToKey: SlidingPaneKey | null
    contextInfo: SlidingPaneContextInfo
    animationComplete: boolean
    isDirty: boolean
    isUnsavedChangesModalOpen: boolean
    onContinueWithoutSaving?: () => void
    blockedContent: {
        key: SlidingPaneKey
        contentProps?: any
        contentWidth?: number
    } | null
}

export const initialState: SlidingPaneState = {
    key: null,
    previousKey: null,
    contentProps: null,
    contentWidth: null,
    contextInfo: {},
    transitionToWidth: null,
    transitionToKey: null,
    animationComplete: true,
    isDirty: false,
    isUnsavedChangesModalOpen: false,
    blockedContent: null,
}

type CloseAction = {
    type: typeof SLIDING_PANE_CLOSE
}

type SetSlidingPaneContextInfoAction = {
    type: typeof SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO
    payload: SlidingPaneContextInfo
}

type ClearContentAction = {
    type: typeof SLIDING_PANE_CLEAR_CONTENT
}

type SetContentDirtyAction = {
    type: typeof SLIDING_PANE_SET_CONTENT_DIRTY
}

type UnsetContentDirtyAction = {
    type: typeof SLIDING_PANE_UNSET_CONTENT_DIRTY
}

type TransitionToWidthAction = {
    type: typeof SLIDING_PANE_TRANSITION_TO_WIDTH
    payload: { key: SlidingPaneKey; contentWidth: number }
}

type StackSelectedAction = {
    type: typeof STACK_SELECTED
}

type SetAnimationCompleteAction = {
    type: typeof SLIDING_PANE_SET_ANIMATION_COMPLETE
}

type EditingStoppedAction = {
    type: typeof EDITING_STOPPED
    payload: { leaveSlidingPaneOpen?: boolean }
}

type EditingStartedAction = {
    type: typeof EDITING_STARTED
}

type OpenPayload = {
    key: SlidingPaneKey
    contentProps?: any
    contentWidth?: number
    isNotAnimating?: boolean
}

type OpenAction = {
    type: typeof SLIDING_PANE_OPEN
    payload: OpenPayload
}

type BlockNextContentAction = {
    type: typeof SLIDING_PANE_BLOCK_CONTENT
    payload: OpenPayload
}

type ClearBlockedContentAction = {
    type: typeof SLIDING_PANE_CLEAR_BLOCKED_CONTENT
}

type OpenUnsavedChangesModalAction = {
    type: typeof SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL
    payload?: {
        onContinueWithoutSaving: () => void
    }
}

type CloseUnsavedChangesModalAction = {
    type: typeof SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL
}

type ClearOnContinueCallbackAction = {
    type: typeof SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK
}

type SlidingPaneAction =
    | CloseAction
    | ClearContentAction
    | SetContentDirtyAction
    | UnsetContentDirtyAction
    | OpenAction
    | StackSelectedAction
    | SetSlidingPaneContextInfoAction
    | EditingStoppedAction
    | TransitionToWidthAction
    | SetAnimationCompleteAction
    | EditingStartedAction
    | BlockNextContentAction
    | ClearBlockedContentAction
    | OpenUnsavedChangesModalAction
    | CloseUnsavedChangesModalAction
    | ClearOnContinueCallbackAction

export function slidingPaneReducer(
    state: SlidingPaneState = initialState,
    action: SlidingPaneAction
): SlidingPaneState {
    switch (action.type) {
        case SLIDING_PANE_CLOSE:
            localStorage.setItem(`${getCurrentStackId()}_side_pane_closed`, 'true')
            return {
                ...state,
                key: null,
                // Keep track of previous pane so other code can know when we're
                // coming from a previous pane or opening from closed state
                previousKey: state.key,
                animationComplete: false,
                isDirty: false,
                isUnsavedChangesModalOpen: false,
                blockedContent: null,
            }
        case SLIDING_PANE_CLEAR_CONTENT:
            return {
                ...state,
                isDirty: false,
                isUnsavedChangesModalOpen: false,
                blockedContent: null,
            }
        case SLIDING_PANE_SET_CONTENT_DIRTY:
            return { ...state, isDirty: true }
        case SLIDING_PANE_UNSET_CONTENT_DIRTY:
            return { ...state, isDirty: false }
        case SLIDING_PANE_OPEN:
            localStorage.setItem(`${getCurrentStackId()}_side_pane_closed`, 'false')
            return {
                ...state,
                key: action.payload.key,
                contentProps: action.payload.contentProps,
                // Keep track of previous pane so other code can know when we're
                // coming from a previous pane or opening from closed state
                previousKey: state.key,
                contentWidth: action.payload.contentWidth || null,
                transitionToWidth: null,
                transitionToKey: null,
                // If we're not animating, preserve the existing animationComplete state
                animationComplete:
                    action.payload.isNotAnimating ||
                    action.payload.contentWidth === state.contentWidth
                        ? state.animationComplete
                        : false,
                isDirty: false,
                isUnsavedChangesModalOpen: false,
                blockedContent: null,
            }
        case STACK_SELECTED:
            return { ...state, key: null }
        case SLIDING_PANE_SET_SLIDING_PANE_CONTEXT_INFO:
            return {
                ...state,
                contextInfo: {
                    ...state.contextInfo,
                    ...action.payload,
                    previousViewId: state.contextInfo.viewId,
                },
            }
        case EDITING_STOPPED:
            return state.key === 'edit-layout' && !action.payload?.leaveSlidingPaneOpen
                ? { ...state, key: null }
                : state
        case EDITING_STARTED:
            // when editing starts, and the edit-layout pane isn't already the active one
            // we know we'll be transitioning to opening the sliding pane, so go ahead and set the animationComplete
            // to false here, as Frame.js looks at both animationComplete and isEditing
            // to adjust content width.
            return {
                ...state,
                animationComplete: state.key !== 'edit-layout' ? false : state.animationComplete,
            }
        case SLIDING_PANE_SET_ANIMATION_COMPLETE:
            return { ...state, animationComplete: true }
        case SLIDING_PANE_TRANSITION_TO_WIDTH:
            return {
                ...state,
                transitionToWidth: action.payload.contentWidth,
                transitionToKey: action.payload.key,
            }
        case SLIDING_PANE_BLOCK_CONTENT:
            return {
                ...state,
                isUnsavedChangesModalOpen: true,
                blockedContent: { ...action.payload },
            }
        case SLIDING_PANE_CLEAR_BLOCKED_CONTENT:
            return {
                ...state,
                blockedContent: null,
            }
        case SLIDING_PANE_OPEN_UNSAVED_CHANGES_MODAL:
            return {
                ...state,
                isUnsavedChangesModalOpen: true,
                onContinueWithoutSaving: action.payload?.onContinueWithoutSaving,
            }
        case SLIDING_PANE_CLOSE_UNSAVED_CHANGES_MODAL:
            return { ...state, isUnsavedChangesModalOpen: false }
        case SLIDING_PANE_CLEAR_ON_CONTINUE_CALLBACK:
            return { ...state, onContinueWithoutSaving: undefined }
        default:
            return state
    }
}
