import { fetchAndReturn } from '../utils/utils'

export const startImportCsvTableJob = (url: string, tableName: any): Promise<any> => {
    return fetchAndReturn(`csv/import`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            name: tableName,
        }),
    }) as Promise<any>
}
