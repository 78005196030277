import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import FieldPicker from 'features/studio/ui/FieldPicker'

import { RenderInlineElement } from 'ui/forms/ui'
export default function FieldPickerForm({
    objectId,
    placeholder,
    label,
    name,
    subtitle,
    filter,
    defaultValue,
    required = false,
    errorMessages = {},
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)
    return (
        <RenderInlineElement
            label={label}
            subtitle={subtitle}
            error={error}
            errorMessages={errorMessages}
        >
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <>
                        {' '}
                        <FieldPicker
                            objectId={objectId}
                            onChange={(object) => field.onChange(object?._sid)}
                            value={field.value}
                            filter={filter}
                            placeholder={placeholder}
                            returnField
                        />
                    </>
                )}
            />
        </RenderInlineElement>
    )
}
