import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidChevronRightProps = {
    color?: string
    size?: number
}
const SolidChevronRight: React.VFC<SolidChevronRightProps> = ({
    color = colors.userInterface.neutral[700],
    size = 6,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={6}
            height={(size * 11) / 6}
            viewBox="0 0 6 11"
            fill="none"
        >
            <path
                d="M1.27027 10.1028C1.14763 10.1036 1.02606 10.0801 0.912524 10.0337C0.798988 9.98731 0.695723 9.91898 0.60865 9.83261C0.521309 9.74598 0.451984 9.64292 0.404674 9.52936C0.357365 9.41581 0.333008 9.29401 0.333008 9.17099C0.333008 9.04797 0.357365 8.92617 0.404674 8.81262C0.451984 8.69906 0.521309 8.596 0.60865 8.50937L3.69311 5.44355L0.729792 2.3591C0.556232 2.1845 0.458814 1.94832 0.458814 1.70213C0.458814 1.45595 0.556232 1.21977 0.729792 1.04517C0.816421 0.957832 0.919485 0.888507 1.03304 0.841197C1.1466 0.793888 1.2684 0.769531 1.39141 0.769531C1.51443 0.769531 1.63623 0.793888 1.74978 0.841197C1.86334 0.888507 1.9664 0.957832 2.05303 1.04517L5.65001 4.77261C5.82075 4.9468 5.91639 5.181 5.91639 5.42491C5.91639 5.66883 5.82075 5.90302 5.65001 6.07722L1.92257 9.80466C1.83893 9.89498 1.73822 9.96784 1.62626 10.019C1.5143 10.0702 1.39331 10.0987 1.27027 10.1028Z"
                fill={color}
            />
        </svg>
    )
}

export default SolidChevronRight
