import React from 'react'

import styled from '@emotion/styled'

import { Text } from 'v2/ui'

const Label = styled(Text)`
    font-size: 14px;
    font-weight: bold;
    display: block;
`

type ViewHeaderEditorLabelProps = React.ComponentPropsWithoutRef<typeof Label> & {}

export const ViewHeaderEditorLabel: React.FC<ViewHeaderEditorLabelProps> = (props) => {
    return <Label as="label" color="userInterface.neutral.800" {...props} />
}
