import React, { FC } from 'react'

import { Box, Dropdown, Flex, Text, Tooltip } from 'v2/ui'
import { SolidInfo } from 'v2/ui/svgs'

const BUCKET_OPTIONS: { label: string; value: Bucket }[] = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Year', value: 'year' },
]

type BucketByDropdownProps = {
    value?: Bucket
    onChange: (value?: Bucket) => void
}
const BucketByDropdown: FC<BucketByDropdownProps> = ({ value, onChange }) => {
    return (
        <Box>
            <Flex
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                columnGap={1}
            >
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="bar-chart-bucket-by"
                    display="block"
                >
                    Bucket values by
                </Text>
                <Tooltip
                    label="Bucketing allows you to group date fields into time periods such as Week and Month"
                    placement="bottom"
                    maxWidth="170px"
                    labelStyle={{ fontSize: '0.85rem' }}
                >
                    <Box
                        color="userInterface.neutral.700"
                        transition="color 0.1s ease-out"
                        _hover={{
                            color: 'userInterface.accent.1000',
                        }}
                    >
                        <SolidInfo size={11} color="currentColor" />
                    </Box>
                </Tooltip>
            </Flex>
            <Dropdown
                isClearable={false}
                placeholder="Select a period to bucket values by"
                options={BUCKET_OPTIONS}
                value={value}
                adminTheme
                usePortal
                id="bar-chart-bucket-by"
                onChange={onChange}
            />
        </Box>
    )
}

export default BucketByDropdown
