import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type SolidStar = {
    color?: string
    size?: number
}
const SolidStar: React.FC<SolidStar> = ({
    color = colors.userInterface.neutral[1000],
    size = 16,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_755_686)">
                <path
                    d="M7.00625 0.617302C7.09773 0.432 7.23924 0.275987 7.41475 0.166899C7.59027 0.0578115 7.79281 0 7.99946 0C8.20612 0 8.40866 0.0578115 8.58417 0.166899C8.75969 0.275987 8.90119 0.432 8.99268 0.617302L10.8647 4.41048L15.0504 5.01847C15.2551 5.04799 15.4474 5.13418 15.6055 5.2673C15.7637 5.40042 15.8815 5.57516 15.9455 5.77174C16.0096 5.96832 16.0173 6.1789 15.9679 6.37965C15.9184 6.5804 15.8138 6.76331 15.6658 6.90768L12.6357 9.86148L13.3508 14.03C13.3855 14.2337 13.3626 14.443 13.2847 14.6344C13.2067 14.8257 13.0768 14.9914 12.9096 15.1128C12.7424 15.2341 12.5446 15.3063 12.3385 15.3212C12.1324 15.336 11.9263 15.2929 11.7434 15.1968L7.99946 13.2301L4.25551 15.1993C4.07244 15.2956 3.86604 15.3388 3.65969 15.3239C3.45334 15.3089 3.2553 15.2365 3.08799 15.1148C2.92069 14.9931 2.79081 14.827 2.71307 14.6353C2.63533 14.4436 2.61285 14.2339 2.64815 14.03L3.36199 9.86148L0.334336 6.90891C0.186371 6.76462 0.081726 6.58181 0.032232 6.38115C-0.017262 6.18049 -0.00963047 5.96999 0.0542638 5.77344C0.118158 5.57689 0.235766 5.40214 0.393791 5.26894C0.551816 5.13574 0.743955 5.04941 0.948482 5.0197L5.13304 4.41171L7.00625 0.618532V0.617302Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_755_686">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SolidStar
