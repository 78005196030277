import stackerTheme from './stackerTheme'

const vars = {
    ...stackerTheme,

    fontColor: '#fff',
    lightFontColor: '#c0c8d0',
    fontSize: '12pt',

    fontS: '10pt',
    fontM: '12pt',
    fontL: '14pt',
    fontXL: '18pt',

    // Global Colors
    backgroundColor: '#f9f9f9', // This is the very back of the app
    surfaceColor: '#23262f', // This is the color of all the components
    inputBackgroundColor: '#2c313c', // This is the color of all the components

    elementHighlightColor: '#2f3544', // This is the color of all the components
    linkTextColor: '#2980b9',
    outlineColor: '#2c313c',
    dividerColor: '#2b2e35;',

    successColor: '#454b58',
}

const stackerDarkTheme = {
    ...vars,

    // Dividers, e.g. used in Tables

    // Spacing
    spaceXXS: '2px',
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '32px',
    xlarge: '64px',
    spaceXXL: '128px',

    // Tables
    columnHeadingWeight: 'bold',
    columnHeadingColor: 'unset',
    columnHeadingSize: '0.9em',
    rowHighlightColor: '#f6f8f9',

    // Element display

    // Headings etc.
    pageTitleSize: '32pt',
    pageTitleWeight: 'bold',
    pageTitleFont: vars.fontFamily,

    // Buttons
    buttonFontColor: vars.fontColor,
    buttonColor: vars.surfaceColor,
    buttonBorderColor: vars.outlineColor,
    buttonBorderRadius: '5px',
    buttonBorderThickness: vars.borderThickness,

    // Headings
    headingOneSize: '24pt',
    headingTwoSize: '16pt',
    headingWeight: 'bold',
    headingFont: vars.fontFamily,
    headingColor: vars.fontColor,
}

export default stackerDarkTheme
