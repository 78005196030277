import React from 'react'

import styled from '@emotion/styled'

import { Icon, Text } from 'v2/ui'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

type QuickLinksBlockItemLinkLabelProps = Pick<
    QuickLinksBlockItemExternalLink | QuickLinksBlockItemInternalLink,
    'label'
> & {
    isExternal?: boolean
    brandColor?: string
}

const QuickLinksBlockItemLinkLabel: React.FC<QuickLinksBlockItemLinkLabelProps> = ({
    label,
    isExternal,
    brandColor,
}) => {
    const icon = isExternal ? 'faExternalLink' : 'faArrowRightLong'

    return (
        <Wrapper>
            <Text color={brandColor} fontWeight="bold" mr={2} size="sm">
                {label}
            </Text>
            <Icon icon={icon} size="xs" color={brandColor} aria-hidden="true" />
        </Wrapper>
    )
}

export default QuickLinksBlockItemLinkLabel
