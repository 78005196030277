import * as React from 'react'

import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useLegacyMetrics } from 'data/hooks/metrics/legacyMetrics'

import { Box } from 'v2/ui'

import NumberValue from './NumberValue'

const Content = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 8px 24px 0px;

    .value {
        font-weight: 600;
        font-size: 42px;
        color: #24262e;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        &.big {
            font-size: 60px;
        }

        .currency-symbol {
            font-size: 32px;
            margin-right: 2px;
            color: #383b47;
        }

        .number-abbreviation-symbol {
            color: #383b47;
            font-size: 26px;
            font-weight: 500;
        }
    }

    .label {
        width: 100%;
        margin-top: 2px;
        color: #94979c;
        font-weight: 600;
        font-size: 18px;
        height: 42px;
        overflow: hidden;
        text-align: center;
        &.big {
            height: 63px;
        }
    }
`

/**
 * @param {ChartParameters} param0
 */
export default function NumberChart({ config, display, dataGrid }) {
    const { data: metrics } = useLegacyMetrics(config, { suspense: true, retry: 0 })

    return (
        <Content>
            <Box className={`value ${dataGrid.h > 1 ? 'big' : ''}`}>
                <NumberValue display={display} metrics={metrics} config={config} />
            </Box>
            <Box className={`label ${dataGrid.h > 1 ? 'big' : ''}`}>{display.label}</Box>
        </Content>
    )
}

NumberChart.gridParams = {
    maxW: 1,
    maxH: 2,
    minW: 1,
    minH: 1,
}
