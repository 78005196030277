export function formatEditNameLabel(view?: ViewDto): string {
    if (view?.type === 'blankpage') return 'Edit Page Name'

    return 'Edit Layout Name'
}

export function formatSaveButtonLabel(view?: ViewDto, isViewDirty?: boolean): string {
    if (!isViewDirty) return 'Saved'

    if (view?.type === 'blankpage') return 'Save page'

    return 'Save layout'
}

export function formatDeleteViewLabel(view?: ViewDto) {
    let contentType = 'layout'
    if (view?.type === 'blankpage') {
        contentType = 'custom page'
    }

    return `Are you sure that you wish to delete this ${contentType}? This action cannot be undone`
}

export function formatDeleteViewErrorLabel(view?: ViewDto) {
    let contentType = 'layout'
    if (view?.type === 'blankpage') {
        contentType = 'page'
    }

    return `Sorry, there was an error deleting this ${contentType}. Please try again`
}

export function formatDuplicateViewLabel(view?: ViewDto) {
    let contentType = 'layout'
    if (view?.type === 'blankpage') {
        contentType = 'custom page'
    }

    return `Enter new ${contentType} name`
}

export function formatDuplicateViewPlaceholderLabel(view?: ViewDto) {
    let contentType = 'Layout'
    if (view?.type === 'blankpage') {
        contentType = 'Page'
    }

    return `${contentType} name...`
}

export function formatDuplicateViewErrorLabel(view?: ViewDto) {
    let contentType = 'layout'
    if (view?.type === 'blankpage') {
        contentType = 'page'
    }

    return `There was an error duplicating this ${contentType}. Please try again`
}

export function formatDuplicateViewValidationLabels(view?: ViewDto) {
    let contentType = 'layout'
    if (view?.type === 'blankpage') {
        contentType = 'page'
    }

    return {
        nameTooShort: `You must enter a new ${contentType} name`,
    }
}
