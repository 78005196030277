/* Code Quality: Good */

import React from 'react'

import PropTypes from 'prop-types'

import { withUser } from 'data/wrappers/WithUser'
import { SettingsContent, SettingsWrapper } from 'features/admin/settings/ui/SettingsFrame'
import SettingsHeading from 'features/admin/settings/ui/SettingsHeading'
import Frame from 'features/core/Frame'
import useImpersonation from 'features/core/useImpersonation'
import { Heading, Section } from 'legacy/v1/ui'

const ImpersonatingWarningPage = ({ user }) => {
    const { stopImpersonating } = useImpersonation()
    return (
        <Frame header={false}>
            <SettingsHeading name="Users" />
            <SettingsWrapper>
                <SettingsContent style={{ marginBottom: 20 }}>
                    <Section>
                        <Heading onClick={() => stopImpersonating({})} size="small">
                            You are currently impersonating {user.name}. Click{' '}
                            <u style={{ cursor: 'pointer' }}>here</u> to stop impersonating.
                        </Heading>
                    </Section>
                </SettingsContent>
            </SettingsWrapper>
        </Frame>
    )
}

ImpersonatingWarningPage.propTypes = {
    userActions: PropTypes.object.isRequired, // From withUser
    user: PropTypes.object.isRequired, // From withUser
}

export default withUser(ImpersonatingWarningPage)
