import React from 'react'

import { BoxProps } from '@chakra-ui/react'
import { AdvancedOptionsItem } from 'v2/views/List/AdvancedOptionsItem'

type HideSearchBarProps = {
    hideSearchBar?: boolean
    onChange: (hideSearchBar: boolean) => void
} & Omit<BoxProps, 'onChange'>

const HideSearchBar: React.FC<HideSearchBarProps> = ({
    onChange,
    hideSearchBar = false,
    ...boxProps
}) => {
    return (
        <AdvancedOptionsItem
            label="Display search bar"
            id="display-search"
            value={!hideSearchBar}
            onChange={(value) => onChange(!value)}
            {...boxProps}
        />
    )
}

export default HideSearchBar
