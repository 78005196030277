import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

import { useAppContext } from '../../app/AppContext'
import { createBillingPortalSession } from '../../data/api/subscriptionApi'
import { Button, Flex, Text } from '../../v2/ui'

const BillingInfo = () => {
    const { workspaceAccount } = useAppContext()
    const { colors } = stackerTheme()
    const handleManagePlan = () => {
        createBillingPortalSession(
            `${window.location.origin}${window.location.pathname}?__la=openBilling`
        ).then((res) => {
            if (res.session_url) {
                window.location.assign(res.session_url)
            }
        })
    }

    return (
        <Flex direction="column" alignItems="flex-start">
            <Text fontSize="18px" color="neutral.800" mb={1}>
                Current plan
            </Text>

            <Text
                fontSize="16px"
                fontWeight="bold"
                mb={4}
                color={colors.userInterface.accent[1000]}
            >
                {workspaceAccount?.plan_tier}
            </Text>

            <Button
                variant="Primary"
                buttonSize="large"
                disabled={false}
                aria-label="Manage Billing"
                mb={2}
                isDisabled={false}
                onClick={handleManagePlan}
            >
                Manage Billing
            </Button>

            <Text fontSize="12px" color="neutral.800">
                You will be able to manage your payment methods, see and download your invoices. If
                you would like to pause your subscription, please contact support.
            </Text>
        </Flex>
    )
}

export default BillingInfo
