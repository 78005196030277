import { useMemo } from 'react'

import { AccessibleView, useAccessibleViews } from 'v2/views/utils/useAccessibleViews'

import { getUrl } from 'app/UrlService'

export function useDataListBlockViewUrl(objectId?: string): string {
    const { list } = useAccessibleViews()

    return useMemo(() => {
        const filteredViews = filterViews(list, objectId)

        const primaryView = determinePrimaryView(filteredViews)
        if (!primaryView?.navItem?.url) return ''

        return getUrl(primaryView.navItem.url)
    }, [list, objectId])
}

function filterViews(views: AccessibleView[], objectId?: string): AccessibleView[] {
    if (!objectId) return []

    return views.filter(({ navItem }) => {
        if (!navItem || navItem.hidden || navItem.enabled === false) return false

        return navItem.object_id === objectId
    })
}

function determinePrimaryView(views: AccessibleView[]): AccessibleView | undefined {
    if (views.length < 1) return undefined

    const newViews = [...views]
    newViews.sort((a, b) => {
        const displayOrderA = a.navItem?.display_order ?? 0
        const displayOrderB = b.navItem?.display_order ?? 0

        return displayOrderA - displayOrderB
    })

    return newViews[0]
}
