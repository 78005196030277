// @ts-strict-ignore
import { objectApi } from '../../api/objectApi'

import { updateObjectQuery } from './objectOperations'
import { refetchObjects } from './refetchObjects'

/**
 * Gets an object and adds to the query cache
 */
export const getObject = (objectId?: string | undefined) => {
    // If we need to refetch all objects, we can just invalidate the query
    if (!objectId) return refetchObjects()
    // Otherwise, get the object from the api and update the query cache
    return objectApi.get(objectId).then((object: ObjectDto) => {
        return updateObjectQuery(object)
    })
}
