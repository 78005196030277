/* Code Quality: OK */

import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { Switch as Toggle } from '@chakra-ui/react'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getCurrentStackId } from 'app/AppContextStore'
import { getUrl, Urls } from 'app/UrlService'
import { withNavigation } from 'data/wrappers/WithNavigation'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { Logo } from 'features/auth/admin-registration/ui/SignupFrame'
import { Container, Heading, Icon8, Label, Section } from 'legacy/v1/ui'
import { bootIntercom } from 'utils/intercom'

import { FixedGrid, Icon, Text } from 'v2/ui'

import analytics from '../../../utils/analytics'

import OnboardingFrame from './ui/OnboardingFrame'
import { setOnboardingStep } from './utils'

class OnboardingTablesPage extends React.Component {
    constructor() {
        super()
        this.state = {
            initialized_default_active_tables: false,
            tables_disabled: {},
        }
    }

    UNSAFE_componentWillMount() {
        const studioUser = localStorage.getItem('studio_user')
        bootIntercom({
            user: studioUser,
            stackId: getCurrentStackId(),
        })
        setOnboardingStep('setTables', this.props)
    }

    initializeDefaultActiveTables = () => {
        const { objects } = this.props
        const userTableId = this.props.stackOptions.data_mapping.user_object
        // Disable some tables by default
        // Run this once on objects load
        const MAX_DEFAULT_ACTIVE_TABLES = 5 // +1 for user table (never disabled)

        if (!get(this.props, 'objects.length') || this.state.initialized_default_active_tables)
            return

        const tablesDisabled = this.state.tables_disabled
        // never disable user table
        for (let i = 0; i < objects.length; i++) {
            const object = objects[i]
            if (object._sid === userTableId) {
                tablesDisabled[object._sid] = false
            } else if (i < MAX_DEFAULT_ACTIVE_TABLES) {
                tablesDisabled[object._sid] = false
            } else {
                tablesDisabled[object._sid] = true
            }
        }
        this.setState({
            tables_disabled: tablesDisabled,
            initialized_default_active_tables: true,
        })
    }

    disableTables() {
        const { objects, onChange } = this.props
        // Only disable tables on the backend once this step is done (clicked next button)
        for (const sid in this.state.tables_disabled) {
            const disabled = this.state.tables_disabled[sid]
            if (disabled) {
                const table = objects.find((object) => object._sid === sid)
                onChange(sid, {
                    connection_options: {
                        ...table.connection_options,
                        data_mapping_disabled: true,
                    },
                }).then(() => {
                    // we request a fetch on every object change
                    // but thanks to the debounce, we will only
                    // make one request to navigation when
                    // objects are done updating...
                    this.fetchNavigation()
                })
            }
        }
    }

    fetchNavigation = debounce(() => {
        this.props.navigationActions.clear()
        this.props.navigationActions.fetch()
    }, 500)

    handleNextClick = () => {
        analytics.track('onboarding progressed', {
            step: 'step_setup_tables',
        })
        this.disableTables()
        this.props.history.push(getUrl(Urls.AdminOnboardingBrand))
    }

    componentDidMount() {
        if (this.props.objects) {
            this.initializeDefaultActiveTables()
        }
    }

    componentDidUpdate(prevProps) {
        if (!get(prevProps.objects, 'length') && get(this.props.objects, 'length')) {
            this.initializeDefaultActiveTables()
        }
    }

    render() {
        const objects = this.props.objects
        const userTableId = this.props.stackOptions.data_mapping.user_object
        return (
            <OnboardingFrame
                rightContents={
                    <MenuPreview
                        tablesDisabled={this.state.tables_disabled}
                        objects={this.props.objects}
                    />
                }
                title="Set up your app"
                onNextClick={this.handleNextClick}
            >
                <Text variant="onboardingSubtitle">
                    Which tables would you like to use with Stacker?
                </Text>

                <FixedGrid columns={[1, 1, 2, 2]} maxWidth="100%">
                    {Object.keys(objects).map((key) => {
                        return (
                            <ObjectCard
                                key={key}
                                isUserTable={userTableId === objects[key]._sid}
                                object={objects[key]}
                                tablesDisabled={this.state.tables_disabled}
                                forceDisabled={!this.state.initialized_default_active_tables}
                                onClick={() => {
                                    const objectSid = objects[key]._sid
                                    const tablesDisabled = this.state.tables_disabled

                                    tablesDisabled[objectSid] =
                                        !this.state.tables_disabled[objectSid]

                                    this.setState({
                                        tables_disabled: tablesDisabled,
                                    })
                                }}
                            />
                        )
                    })}
                </FixedGrid>
            </OnboardingFrame>
        )
    }
}

export default withNavigation(withStack(withObjects(withUser(withRouter(OnboardingTablesPage)))))

const _MenuPreview = ({ tablesDisabled, objects }) => {
    if (!objects || !objects.length) return null

    return (
        <Phone>
            <PhoneContents>
                <Container centered>
                    <Logo />

                    <Icon8 icon="menu" style={{ marginLeft: 'auto' }} />
                </Container>
                <Section
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        height: '90%',
                        marginTop: 20,
                        padding: 0,
                        overflow: 'auto',
                    }}
                >
                    {objects
                        .filter((object) => {
                            const tableDisabled = tablesDisabled[object._sid]
                            const disabled =
                                typeof tableDisabled !== 'undefined' ? tableDisabled : true
                            return !disabled
                        })
                        .map((object) => (
                            <Heading key={object.name} style={{ fontSize: 15, padding: 0 }}>
                                {object.name}
                            </Heading>
                        ))}
                </Section>
            </PhoneContents>
        </Phone>
    )
}

const MenuPreview = withNavigation(withStack(_MenuPreview))

const PhoneContents = styled('div')`
    background-color: white;
    border-radius: 12px;
    height: 100%;
    padding: 20px;
`

const Phone = styled('div')`
    background-color: black;
    border: 10px solid black;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.55) 0 0 68px;
    height: 500px;
    margin: 0 auto;
    width: 275px;
`

export const ObjectCard = (props) => {
    // const disabled = props.object.connection_options.data_mapping_disabled
    // Set toggle to disabled if data hasn't loaded yet
    // so we can show an "inactive->active" animation instead of "active->inactive"
    const tableDisabled = props.tablesDisabled[props.object._sid]
    const disabled = typeof tableDisabled !== 'undefined' ? tableDisabled : true

    const tooltipId = `tooltip__${props.object._sid}`
    return (
        <Container
            centered
            onClick={() => {
                if (!props.isUserTable) props.onClick()
            }}
            style={{
                cursor: props.isUserTable ? 'initial' : 'pointer',
                background: `${disabled && !props.isUserTable ? '#F6F6F6' : '#e3f9db'}`,
                // paddingLeft: '20px',
                margin: props.margin ? props.margin : '0px 10px 10px 0px',
                borderRadius: 5,
                paddingLeft: 15,
                paddingTop: 4,
                paddingBottom: 2,
            }}
            margin="none"
            padding="none"
        >
            <Text
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                title={props.object.name}
            >
                {props.object.name}
            </Text>
            <Section
                style={{ margin: '0px 4px 0px auto' }}
                noPadding
                onClick={(e) => {
                    // Add this to prevent onChange function on the toggle triggering twice
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                {props.isUserTable ? (
                    <>
                        <ReactTooltip id={tooltipId} effect="solid">
                            <Label style={{ color: 'white' }}>
                                {' '}
                                You cannot disable your user table.
                            </Label>
                        </ReactTooltip>
                        <div data-tip data-for={tooltipId}>
                            <Icon icon="check" mr="8px" color="green.500" />
                        </div>
                    </>
                ) : (
                    <Toggle
                        colorScheme="green"
                        onClick={() => {
                            if (!props.isUserTable) props.onClick()
                        }}
                        small
                        isChecked={!disabled}
                    />
                )}
            </Section>
        </Container>
    )
}

ObjectCard.propTypes = {
    object: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    isUserTable: PropTypes.bool.isRequired,
}
