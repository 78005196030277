import * as React from 'react'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { useAppContext } from 'app/AppContext'
import useConfirmModal from 'app/ConfirmModal'
import { invalidateFields } from 'data/hooks/fields'
import { useObject, useObjects } from 'data/hooks/objects'
import { useStackRoles } from 'data/hooks/roleHelpers'
import {
    useCreateUserList,
    useDeleteUserList,
    useUpdateUserList,
    useUserLists,
} from 'data/hooks/userLists'
import {
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import RolePicker from 'features/studio/ui/RolePicker'

import { Box, Button, Collapse, Input, Text } from 'v2/ui'

import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

import {
    CollapsableSection,
    Divider,
    FormFrame,
    SettingLabel,
    SettingRow,
} from '../../workspace/WorkspaceSettingsModalUi'

import ConditionalRoles from './ConditionalRoles'

export default function CustomerAccessEditList({ formRef, children, userList, onChangePage }) {
    const { mutateAsync: createList } = useCreateUserList()
    const { mutateAsync: updateList } = useUpdateUserList()
    const { mutateAsync: deleteList } = useDeleteUserList()
    const { selectedStack } = useAppContext()
    const { data: roles } = useStackRoles()
    const { data: objects } = useObjects()

    const save = (patch) => {
        if (userList) {
            return updateList({
                id: userList._sid,
                patch: {
                    ...patch,
                    options: {
                        ...patch.options,
                        filters_formatted: formatFilters(patch.options?.filters),
                    },
                },
            })
        } else {
            const obj = objects.find((o) => o._sid === patch.object_id)
            patch.name = obj.name
            patch.options.filters_formatted = formatFilters(patch.options?.filters)
            return createList(patch).then((result) => {
                // refetch the field definitions, as an automatic
                // relationship field matching this customer list
                // may have been created on the Stacker users table.
                invalidateFields()
                return onChangePage(result?._sid)
            })
        }
    }

    const handleDelete = () => {
        return deleteList(userList._sid).then(() => onChangePage('general'))
    }

    const defaultUserRole = roles.find(
        (x) => x._sid === selectedStack?.options?.roles__default_user_role
    )
    // select the default user role by default
    const defaultValues = {
        options: {
            role: defaultUserRole?.api_name,
        },
    }

    return (
        <Form
            onSubmit={save}
            options={{
                defaultValues: { ...defaultValues, ...userList },
            }}
            style={{ flex: 1, minWidth: 0 }}
            resetOnSuccess
        >
            <InnerContent
                userList={userList}
                formRef={formRef}
                onDelete={handleDelete}
                defaultUserRole={defaultUserRole}
            >
                {children}
            </InnerContent>
        </Form>
    )
}

function InnerContent({ formRef, children, userList, onDelete, defaultUserRole }) {
    const { setValue, watch } = useFormContext()
    const values = watch()
    const previousObjectId = useRef(values.object_id)
    const isCreating = !userList
    const { object } = useObject(values.object_id)
    const { data: userLists } = useUserLists()

    const validateTableTaken = (tableId) => {
        if (isCreating && userLists.find((l) => l.object_id === tableId)) {
            return 'Customer list already created for this table'
        }
        return true
    }

    const { show: showConfirm } = useConfirmModal({
        message: (
            <>
                <Text>Are you sure you want to delete this customer list?</Text>

                <Text mt={4} fontWeight="bold">
                    This action cannot be undone.
                </Text>
            </>
        ),
        onConfirm: (modal) => {
            onDelete().then(() => modal.toggle())
        },
    })
    const handleRemove = () => {
        showConfirm()
    }

    // Clear the filters if the user changes objects
    useEffect(() => {
        if (previousObjectId.current !== values.object_id) {
            setValue('options.filters', [])
        }
    }, [values.object_id, setValue])
    return (
        <>
            <FormFrame
                title={isCreating ? 'Add customer list' : 'Edit customer list'}
                formRef={formRef}
                subtitle="Users in this list will be able to access this app."
                titleBarRightContent={
                    !isCreating && (
                        <Button
                            variant="adminSecondary"
                            icon="trash"
                            onClick={handleRemove}
                            buttonSize="sm"
                            alignSelf="start"
                            mt="-4px"
                        >
                            Remove
                        </Button>
                    )
                }
            >
                <Divider dense />
                {!isCreating && (
                    <>
                        <SettingRow
                            leftSide={<SettingLabel>List name</SettingLabel>}
                            rightSide={
                                <FormField
                                    as={Input}
                                    name="name"
                                    placeholder="enter a name for this list"
                                    variant="settings"
                                    required
                                ></FormField>
                            }
                        />
                        <Divider dense />
                    </>
                )}
                <SettingRow
                    leftSide={<SettingLabel>Users are in table</SettingLabel>}
                    rightSide={
                        <FormField
                            as={ObjectPicker}
                            name="object_id"
                            placeholder="select a table"
                            variant="settings"
                            filter={(obj) =>
                                obj.connection_options?.simpleconn_object !== 'STACKER_USERS'
                            }
                            disabled={!isCreating}
                            required
                            registerOptions={{ validate: { validateTableTaken } }}
                            controlled
                        ></FormField>
                    }
                />
                <Collapse isOpen={!!values.object_id}>
                    <Box>
                        <Divider dense />{' '}
                        <SettingRow
                            leftSide={<SettingLabel>Email addresses are in field</SettingLabel>}
                            rightSide={
                                <FormField
                                    as={FieldPicker}
                                    name="email_field_id"
                                    variant="settings"
                                    required
                                    controlled
                                    filter={(field) =>
                                        ['string', 'long_text', 'copy'].includes(field.type)
                                    }
                                    disabled={!isCreating}
                                    placeholder="Select email field"
                                    objectId={values.object_id}
                                ></FormField>
                            }
                        />
                        <Divider dense />
                        <CollapsableSection
                            defaultIsOpen={
                                values.options?.filters?.length > 0 ||
                                values.options?.conditional_roles?.length > 0 ||
                                values.options?.role !== defaultUserRole?.api_name
                            }
                            header={<SettingLabel>Advanced</SettingLabel>}
                        >
                            <div>
                                <Divider dense />
                                <SettingRow
                                    leftSide={<SettingLabel>Users can access if</SettingLabel>}
                                    leftSideStyle={{
                                        alignSelf: 'start',
                                        marginTop: '3px',
                                        marginbottom: '3px',
                                    }}
                                    rightSide={
                                        <FormField
                                            as={Filters}
                                            name="options.filters"
                                            controlled
                                            key={values.object_id}
                                            object={object}
                                            fields={object?.fields}
                                            hideCurrentUserOption
                                            hideTheRecordFilter
                                            overhangOperators
                                        />
                                    }
                                />
                                <Divider dense />
                                <SettingRow
                                    leftSide={<SettingLabel>Default role</SettingLabel>}
                                    rightSide={
                                        <FormField
                                            as={RolePicker}
                                            name="options.role"
                                            variant="settings"
                                            idField="api_name"
                                            required
                                            controlled
                                        ></FormField>
                                    }
                                />
                                <Divider dense />
                                <FormField
                                    as={ConditionalRoles}
                                    name="options.conditional_roles"
                                    variant="settings"
                                    controlled
                                    object={object}
                                    fields={object?.fields}
                                    defaultUserRole={defaultUserRole?.api_name}
                                />
                            </div>
                        </CollapsableSection>
                    </Box>
                </Collapse>
            </FormFrame>
            {children}
        </>
    )
}
