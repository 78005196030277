import { ViewColumn } from '../List/types'

import { getFilter } from './getFilter'
import { getIcon } from './getIcon'

export const getHiddenColumnsForUserFilter = (fields: FieldDto[]): ViewColumn[] => {
    return fields.map((field) => {
        return {
            accessor: field.api_name,
            id: field.api_name,
            type: field.type,
            filter: getFilter(field.type),
            icon: getIcon(field.type),
            field: field,
            Header: field.label,
            show: false,
        }
    })
}
