import moment from 'moment-timezone'

import { getCurrentStackId, getWorkspaceAccount } from '../../app/AppContextStore'
import { Authentication } from '../../app/Authentication'
import settings from '../../app/settings'
import { getUrl, Urls } from '../../app/UrlService'
import { getPreviewingRoleId } from '../../features/auth/utils/roleUtils'
import {
    unsynched__getStudioUser,
    unsynched__getUser,
} from '../../features/auth/utils/unsynchedUserUtils'
import { getCustomBackendConfig } from '../../utils/getCustomBackendConfig'
import { openExpiredSessionModal } from '../../utils/modalOpeners'

type FetchWithAuthOptions = {
    bypassMatchingStackCheck?: boolean
    bypassPreviewAndImpersonation?: boolean
    stackId?: string
}

export function fetchWithAuth(
    url: string,
    params?: Record<string, any>,
    options: FetchWithAuthOptions = {}
): Promise<Response> {
    const workspaceAccount = getWorkspaceAccount()

    if (!params) {
        params = {
            method: 'GET',
        }
    }
    const user = unsynched__getUser()
    const studioUser = unsynched__getStudioUser()
    // configure headers (which may require a call to Auth0 for a token).
    // Then submit the request.
    return configureHeaders(url, params, options, user, studioUser).then((headers) => {
        params = { ...params, headers }

        // We send through X-Ignore-Stack to let the backend know that we want a list for the whole workspace
        if (options?.bypassMatchingStackCheck) {
            params.headers = { ...params.headers, 'X-Ignore-Stack': true }
        }

        return fetch(settings.API_ROOT + url, params).then((response) => {
            // if this request failed due to authorization
            if (response.status === 403) {
                const isThirdPartyAuthenticated =
                    Authentication.isAuth0Authenticated || Authentication.isSupertokensAuthenticated
                // and this workspace account is now set to use Auth0 and api tokens
                // are disabled, and we tried to authorize this request with an API token
                // not from an Auth0 session, then display the session expired message.
                if (
                    workspaceAccount &&
                    workspaceAccount.optional_features.auth0_enabled &&
                    workspaceAccount.optional_features.disable_api_tokens &&
                    headers['X-Api-Token'] &&
                    !isThirdPartyAuthenticated
                ) {
                    openExpiredSessionModal()
                    return Promise.reject(null)
                }
            }
            const currentStackId = getCurrentStackId()
            if (
                !options?.stackId &&
                !options?.bypassMatchingStackCheck &&
                currentStackId &&
                response.headers.get('X-Stack-Id') !== currentStackId
            ) {
                return Promise.reject(null)
            } else {
                return response
            }
        })
    })
}

function configureHeaders(
    url: string,
    params: Record<string, any>,
    options: FetchWithAuthOptions,
    user: UserDto,
    studioUser: Record<string, any>
): Promise<Record<string, string>> {
    const userToken = user?.api_token
    const studioUserToken = studioUser?.api_token
    return new Promise(async (resolve, reject) => {
        // url = url.includes("?") ? url + "&" : url + "?"
        const stackId = options?.stackId || getCurrentStackId()
        let apiToken

        apiToken = studioUserToken || userToken

        // For admins impersonating an end user, add an extra header to notify the backend.
        let optional_headers: Record<string, any> = {}
        if (studioUserToken && userToken !== studioUserToken) {
            optional_headers['X-Admin-Impersonating'] = studioUserToken
        }

        // If an admin is already logged in before going to the support login then
        // this can cause the admin activity date to incorrectly be updated
        const isSupportUrl = window.location.pathname.indexOf(getUrl(Urls.AdminSupportLogin)) !== -1

        if (localStorage.getItem('support_login') || isSupportUrl) {
            optional_headers['X-Support-Login'] = true
        }

        // If we are authenticated via Auth0, then get the access token
        let auth0AccessToken
        if (Authentication.isAuth0Authenticated && Authentication.getAccessTokenSilently) {
            try {
                auth0AccessToken = await Authentication.getAccessTokenSilently()
            } catch (ex) {
                // We have an active session with Auth0, but we failed to get a new token.
                // This means the user has probably lost access to this workspace.
                openExpiredSessionModal()

                reject(ex)
            }
        }

        apiToken = auth0AccessToken ? `auth0_${auth0AccessToken}` : apiToken
        const tz = moment.tz.guess()
        const headers = {
            ...params.headers,
            ...getCustomBackendConfig(),
            ...optional_headers,
            'X-Timezone': tz,
        }

        // We're previewing as the current user if we have a studioUser and the
        // sid is different than the user sid
        const previewingUser = studioUser && user._sid !== studioUser._sid ? user : null
        if (previewingUser && !options?.bypassPreviewAndImpersonation) {
            // If this is an end user auth, we send the api token
            if ((previewingUser as Record<string, any>)._object_id) {
                headers['X-Previewing-As-Auth'] = previewingUser.api_token
                // Otherwise, we only send the user's sid for security reasons.
            } else {
                headers['X-Previewing-As-User'] = previewingUser._sid
            }
        }

        if (apiToken) {
            headers['X-Api-Token'] = apiToken
        }
        if (getPreviewingRoleId() && !options?.bypassPreviewAndImpersonation) {
            headers['X-Previewing-As-Role'] = getPreviewingRoleId()
        }

        if (stackId) {
            headers['X-Stack-Id'] = stackId
        }

        resolve(headers)
    })
}
