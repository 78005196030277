import React from 'react'

import { FlexProps, useDisclosure } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { useUpdateUserList } from 'data/hooks/userLists'

import { Flex, Icon, Text } from 'v2/ui'

import ObjectLabel from '../../utils/ObjectLabel'
import EditUserTableModal from '../edit-user-table/EditUserTableModal'
import { AccessEnabledSwitch } from '../shared/AppUsersUI'

import AutomaticUserTableUsers from './AutomaticUserTableUsers'
import ManualAppUsers from './ManualAppUsers'

type ConnectedUserTableProps = {
    userList: UserListDto
    children: any
    formRef: any
    singleListMode?: boolean
}
const ConnectedUserTable: React.FC<ConnectedUserTableProps> = (props) => {
    const { isOpen: isEditorOpen, onClose: closeEditor, onOpen: openEditor } = useDisclosure()

    return (
        <>
            <Flex
                column
                align="stretch"
                width="100%"
                height="100%"
                maxHeight="100%"
                wrap="nowrap"
                flexGrow={1}
            >
                <UserTableHeader
                    userList={props.userList}
                    mb={3}
                    onOpenEditor={openEditor}
                    singleListMode={props.singleListMode}
                />
                {props.userList.options.mode === 'manual' ? (
                    <ManualAppUsers formRef={props.formRef} userList={props.userList}>
                        {props.children}
                    </ManualAppUsers>
                ) : (
                    <AutomaticUserTableUsers userList={props.userList} />
                )}
            </Flex>
            {/* need to recreate the modal each time we open it
            to avoid some strangness with react-form-hooks */}
            {isEditorOpen && (
                <EditUserTableModal userList={props.userList} isOpen onClose={closeEditor} />
            )}
        </>
    )
}

type UserTableHeaderProps = FlexProps & {
    userList: UserListDto
    onOpenEditor: () => void

    singleListMode?: boolean
}
const UserTableHeader: React.FC<UserTableHeaderProps> = ({
    userList,
    onOpenEditor,
    singleListMode,
    ...props
}) => {
    const { mutateAsync: updateList } = useUpdateUserList()

    const { selectedStack } = useAppContext()
    const appAccessDisabled = selectedStack?.user_access_disabled
    const handleSaveAccessEnabled = (value: boolean) =>
        updateList({
            id: userList._sid,
            patch: { user_access_disabled: !value },
        })

    return (
        <Flex wrap="nowrap0" justify="space-between" {...props}>
            <Text fontWeight="bold">
                <span style={{ verticalAlign: 'middle' }}>Users from table</span>
                <ObjectLabel objectId={userList.object_id} display="inline" ml={2} />
                <Icon
                    icon="cog"
                    button
                    color="neutral.800"
                    aria-label="Configure connected user table"
                    display="inline"
                    verticalAlign="middle"
                    onClick={onOpenEditor}
                    ml={1}
                />
            </Text>

            {!singleListMode && (
                <AccessEnabledSwitch
                    isChecked={!userList.user_access_disabled}
                    onChange={handleSaveAccessEnabled}
                    isDisabled={appAccessDisabled}
                />
            )}
        </Flex>
    )
}
export default ConnectedUserTable
