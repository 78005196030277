import { useCallback, useMemo, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'

import { getRecordFromState } from './records/getRecordFromState'
import { invalidateRecord } from './records/recordOperations'
import { useRecordActions } from './objects'

/*
    The purpose of this hook is to allow the client to load one or more records into
    state, handling the fetch if necessary, allow staging changes to those records into
    state and have multiple components share this state.

    The primary use case is the ExecuteActionModal where we may have multiple action steps
    relating to one or more records (including new records). As changes are made to records
    in one action step, other action steps need to be able to react accordingly (ie., conditional
    visibility changes).
*/

export type RecordManager = {
    record: RecordDto
    loadRecord: (recordId: string) => Promise<void>
    updateRecord: (patch: Partial<RecordDto>) => void
    reset: () => void
}

export const useRecordManager = (recordId: string, objectId: string): RecordManager => {
    const [record, setRecord] = useState({} as RecordDto)
    const { selectedStack } = useAppContext()
    const actions = useRecordActions()

    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [objectId],
        handler: () => {
            invalidateRecord(recordId)
        },
    })

    const retrieveRecord = useCallback(
        async (recordId: string): Promise<RecordDto> => {
            let record = getRecordFromState(recordId)
            if (record && !(record as any)._partial) {
                return Promise.resolve(record)
            }

            return actions.fetch(recordId).then((payload) => {
                return Promise.resolve(payload)
            })
        },
        [actions]
    )

    /**
     * Fetch record data asynchronously.
     */
    const loadRecord = useCallback(
        async (recordId) => {
            const record = await retrieveRecord(recordId)
            setRecord(record)
        },
        [retrieveRecord]
    )

    /**
     * Update the record state, so that record reflects the latest data with staged changes
     * (does not actually make a call to update the record)
     */
    const updateRecord = useCallback((patch: Partial<RecordDto>) => {
        setRecord((record) => ({ ...record, ...patch }))
    }, [])

    /**
     * Clear all record values
     */
    const reset = useCallback(() => {
        setRecord({} as RecordDto)
    }, [])

    return useMemo(
        () => ({
            record,
            loadRecord,
            updateRecord,
            reset,
        }),
        [record, loadRecord, updateRecord, reset]
    )
}
