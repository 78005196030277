import React from 'react'

import { Spinner } from '@chakra-ui/react'

import publicAsset from 'utils/publicAsset'

import { Flex, Text } from '../../../v2/ui'
import { useIsMobile } from '../../../v2/ui/utils/useIsMobile'

const OnboardingLoadingSplash = () => {
    const isMobile = useIsMobile()
    const imageUrl = isMobile
        ? publicAsset('/static/media/installing-app-mobile.png')
        : '/static/media/installing-app.png'
    return (
        <Flex direction="column" justifyContent="Center">
            <img src={imageUrl} />
            <Text mt="50px" size="large" fontWeight="bold">
                Hold tight...
            </Text>
            <Text mt="8px" size="medium">
                We are setting your workspace up.
            </Text>
            <Text size="medium">
                In a few seconds you will be able to start building your app.{' '}
            </Text>
            <Spinner mt="15px" />
        </Flex>
    )
}

export default OnboardingLoadingSplash
