import { isEmpty, isEqual } from 'lodash'

import isEmptyValue from 'v2/ui/utils/isEmptyValue'

import { RenderOptions } from './types'

export type AttributeState = {
    isQuickButtonVisible: boolean
    currentValue: React.ReactNode
    usingDefault: boolean
    key: number
}

export type AttributeProps = {
    children: React.ReactNode
    renderOptions: RenderOptions
    isVisible: boolean
    editable: boolean
    onChange: (value: any, key: string, options?: { ignoreIsDirty?: boolean }) => void
    [x: string]: any
}

type SetDefaultValueProps = {
    props: AttributeProps
    prevProps?: AttributeProps | null
    state: AttributeState
    setState: React.Dispatch<React.SetStateAction<AttributeState>>
    isMounting?: boolean
}

type UpdateStateProps = {
    value: any
    updateKey?: boolean
    usingDefault?: boolean
}

type UpdateValueProps = UpdateStateProps & {
    ignoreState?: boolean
}

export const setDefaultValue = ({
    props,
    prevProps,
    setState,
    isMounting = false,
    state,
}: SetDefaultValueProps) => {
    const { renderOptions, editable, children } = props

    if (!editable) return

    const defaultValue = renderOptions?.defaultValue
    const defaultValueIsEmpty = isEmptyValue(defaultValue)
    const prevDefaultValue = prevProps?.renderOptions?.defaultValue
    const defaultValueUpdated = !isEqual(prevDefaultValue, defaultValue)
    const fieldIsEmpty = !children || isEmpty(children)
    const usingDefault = state.usingDefault
    const valueUpdated =
        prevProps &&
        !isEqual(prevProps.children, children) &&
        !isEqual(state.currentValue, children)

    const updateValue = ({
        value,
        ignoreState = false,
        updateKey = false,
        usingDefault = false,
    }: UpdateValueProps) => {
        // Update the field value
        // Do not mark as dirty if we're just setting the default value
        props.onChange(value, props.fieldKey || props.field.api_name, { ignoreIsDirty: true })
        if (!ignoreState) updateState({ value, updateKey, usingDefault })
    }

    const updateState = ({ value, updateKey = false, usingDefault = false }: UpdateStateProps) => {
        // Updating the key forces a re-render of the field
        // This is necessary to update the default value for some attribute types
        setState((state) => ({
            ...state,
            currentValue: value,
            key: updateKey ? state.key + 1 : state.key,
            usingDefault,
        }))
    }

    // No need to do anything if all of the following are true:
    // - the value hasn't changed
    // - the default value hasn't changed
    // - this isn't a component mount
    if (!valueUpdated && !defaultValueUpdated && !isMounting) return
    // Set the default value if:
    // - The default value is not empty & the field is isMounting
    // - The default value has been updated & the field is using the default value or the field is empty
    else if (
        (!defaultValueIsEmpty && isMounting) ||
        (defaultValueUpdated && (usingDefault || fieldIsEmpty))
    ) {
        updateValue({ value: defaultValue, updateKey: true, usingDefault: true })
    }
    // The child props value has been updated
    // Just update the state
    else if (valueUpdated) {
        updateState({
            value: children,
            usingDefault: !defaultValueIsEmpty && isEqual(children, defaultValue),
        })
    }
}
