// Encode commas to send through to the backend
export const encodeCommas = (value) => {
    if (!value) return value
    const currentValue = Array.isArray(value) ? value : [value]
    const returnValue = currentValue.map((v) => {
        if (typeof v !== 'string') return v
        return v.replace(/,/g, '%5C%2C')
    })
    return Array.isArray(value) ? returnValue : returnValue[0]
}

// Decode commas for the frontend
export const decodeCommas = (value) => {
    if (!value) return value
    const currentValue = Array.isArray(value) ? value : [value]
    const returnValue = currentValue.map((v) => {
        if (typeof v !== 'string') return v
        return v.replace(/%5C%2C/g, ',')
    })
    return Array.isArray(value) ? returnValue : returnValue[0]
}
