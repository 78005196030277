import { queryClient } from '../_helpers'

import { getCachedRecord } from './getCachedRecord'
import { getMergedRecord } from './getMergedRecord'
import { getRecord } from './getRecord'
import { getSchemaTimestamp } from './getSchemaTimestamp'
import { RECORD_QUERY_CONFIG } from './recordConstants'
import { updateObjectRecordQuery } from './updateObjectRecordQuery'

/**
 * Updates records in the query cache
 * setting updateObjectQueries to true will also update in all of the object record queries
 *
 * @param {array} records
 * @param {string} objectId
 * @param {bool} updateObjectQueries
 */

export const updateRecordQuery = (records: RecordDto[], updateObjectQueries = false) => {
    if (!Array.isArray(records)) return
    records.forEach(async (rec) => {
        // We just directly update the record cache here to save another request
        const schemaTimestamp = getSchemaTimestamp(rec._object_id)
        const record = { ...rec, schemaTimestamp }
        if ((record as any)._dereferenced) {
            delete (record as any)._dereferenced
        }
        const currentRecord = getCachedRecord(record._sid)
        // No current record exists so we need to setup the query first
        if (!currentRecord) {
            getRecord(record._sid, { initialData: record })
        } else {
            // Otherwise directly set the data in the cache
            const queryKey = ['record', record._sid]
            queryClient.setQueryData(
                queryKey,
                (old: any) => {
                    return getMergedRecord(record, old)
                },
                RECORD_QUERY_CONFIG as any
            )
        }
        if (updateObjectQueries) updateObjectRecordQuery(record)
    })
}
