import styled from '@emotion/styled'

export const CenterSection = styled.div`
    flex-grow: 1;
    height: 100%;
    max-width: 100%;
`
CenterSection.displayName = 'CenterSection'

export const PageContentArea = styled.div<{ framed: boolean; isFormatPainting: boolean }>`
    background-color: ${(props) => (props.framed ? 'rgb(241, 241, 242)' : 'transparent')};
    flex-grow: 1;
    box-shadow: ${(props) => (props.framed ? 'inset 0 0 3px #000000' : 'none')};
    height: ${(props) => (props.framed ? 'calc(100% - 60px)' : '100%')};
    width: 100%;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;

    ${(props) => props.isFormatPainting && `cursor: copy`};
`

PageContentArea.displayName = 'PageContentArea'

export const Frame = styled.div<{ editing: boolean }>`
    display: flex;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
    flex-direction: ${(props) => (props.editing ? 'row' : 'column')};
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
`

Frame.displayName = 'Frame'
