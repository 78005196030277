const outerProperties = [
    'height',
    'width',
    'alignSelf',
    'layout',
    'margin',
    'marginLeft',
    'marginRight',
    'marginTop',
    'marginBottom',
    'display',
    'maxHeight',
    'minHeight',
    'maxWidth',
    'minWidth',
    'overflow',
    'boxShadow',
    'zIndex',
    'grid-column',
    'gridColumn',
]

export function getOuterStyle(userStyle, configStyle, viewport, direction, excludedStyles = []) {
    // These styles should only apply to a block's surrounding div
    let style
    if (configStyle.default && configStyle[viewport]) {
        style = { ...configStyle.default, ...configStyle[viewport] }
    } else if (configStyle.default) {
        style = configStyle.default
    } else if (configStyle[viewport]) {
        style = configStyle[viewport]
    }

    if (userStyle && style) {
        style = { ...style, ...userStyle }
    } else if (userStyle) {
        style = userStyle
    }

    // Apply the "extra" styles
    if (style && style.extras) style = { ...style, ...style.extras }

    // We should only be keeping references to e.g. position, size, z-index etc.
    const permittedProperties = outerProperties.filter(
        (property) => excludedStyles.indexOf(property) === -1
    )
    const filteredStyles = Object.keys(style || {})
        .filter((key) => permittedProperties.includes(key))
        .reduce((obj, key) => {
            obj[key] = style[key]
            return obj
        }, {})

    // Adjust the styles based on our custom helper attributes

    if (filteredStyles.layout === 'clear') {
        filteredStyles.width = '100%'
    } else if (filteredStyles.layout === 'spread') {
        filteredStyles.flexGrow = 1
    } else {
        // if (filteredStyles.layout === "tight") {
        filteredStyles.flexGrow = 0
    }

    Object.keys(filteredStyles).forEach((property) => {
        if (property.includes('-')) {
            let val = filteredStyles[property]
            filteredStyles[property] = undefined
            filteredStyles[transformCssStyleToReactStyle(property)] = val
        }
    })
    ;[
        'marginLeft',
        'marginRight',
        'marginTop',
        'marginBottom',
        'width',
        'minWidth',
        'maxWidth',
        'height',
        'minHeight',
        'maxHeight',
    ].forEach((property) => {
        let val = filteredStyles[property]

        if (parseInt(val).toString() === val) {
            filteredStyles[property] = val + 'px'
        }
    })

    return filteredStyles
}

export function getInnerStyle(userStyle, configStyle, viewport, direction, includedStyles = []) {
    // These styles are used
    let style
    if (configStyle.default && configStyle[viewport]) {
        style = { ...configStyle.default, ...configStyle[viewport] }
    } else if (configStyle.default) {
        style = configStyle.default
    } else if (configStyle[viewport]) {
        style = configStyle[viewport]
    }

    if (userStyle && style) {
        style = { ...style, ...userStyle }
    } else if (userStyle) {
        style = userStyle
    }

    // Apply the "extra" styles
    if (style && style.extras) style = { ...style, ...style.extras }

    const forbiddenProperties = outerProperties.filter(
        (property) => includedStyles.indexOf(property) === -1
    )
    const filteredStyles = Object.keys(style || {})
        .filter((key) => !forbiddenProperties.includes(key))
        .reduce((obj, key) => {
            obj[key] = style[key]
            return obj
        }, {})

    if (filteredStyles.flexDirection) {
        direction = filteredStyles.flexDirection
    }

    // if (direction === "row") {
    //     filteredStyles.flexBasis = filteredStyles.width
    //     filteredStyles.width = undefined
    // }

    // console.log(`Direction: ${direction}, HA: ${filteredStyles.horizontalAlignment}`)
    if (filteredStyles.horizontalAlignment) {
        filteredStyles[direction === 'column' ? 'alignItems' : 'justifyContent'] =
            filteredStyles.horizontalAlignment
    }

    if (filteredStyles.verticalAlignment) {
        filteredStyles[direction === 'column' ? 'justifyContent' : 'alignItems'] =
            filteredStyles.verticalAlignment
    }

    // Adjust the styles based on our custom helper attributes
    if (filteredStyles.horizontalSpacing || filteredStyles.horizontalAlignment) {
        filteredStyles[direction === 'column' ? 'alignContent' : ''] =
            filteredStyles.horizontalSpacing || filteredStyles.horizontalAlignment
    }

    if (filteredStyles.verticalSpacing || filteredStyles.verticalAlignment) {
        filteredStyles[direction === 'column' ? 'justifyContent' : 'alignContent'] =
            filteredStyles.verticalSpacing || filteredStyles.verticalAlignment
    }

    ;['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'].forEach((property) => {
        let val = filteredStyles[property]

        if (parseInt(val).toString() === val) {
            filteredStyles[property] = val + 'px'
        }
    })

    Object.keys(filteredStyles).forEach((property) => {
        if (property.includes('-')) {
            let val = filteredStyles[property]
            filteredStyles[property] = undefined
            filteredStyles[transformCssStyleToReactStyle(property)] = val
        }
    })

    return filteredStyles
}

const transformCssStyleToReactStyle = (styleName) => {
    // Transform background-color -> backgroundColor
    let dashIndex = styleName.indexOf('-')
    while (dashIndex !== -1) {
        styleName = styleName.replace('-', '')
        styleName = replaceAt(styleName, dashIndex, styleName[dashIndex].toUpperCase())
        dashIndex = styleName.indexOf('-')
    }
    return styleName
}

const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
}
