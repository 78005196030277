/* Code Quality: Not audited */

import React from 'react'
import RDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

import { useTheme } from '@chakra-ui/react'
import { enGB, enUS } from 'date-fns/locale'
import { css } from 'emotion'
import moment from 'moment'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

registerLocale('en-GB', enGB)
registerLocale('en-US', enUS)
setDefaultLocale('en-GB')

// Adding the styles below to remove an extra "triangle"
// on the year dropdown
const DatePickerWrapperStyles = createGlobalStyle`
.react-datepicker__year-read-view--down-arrow::before {
    content: none;
}
/* workaround to fix year picker arrows https://github.com/Hacker0x01/react-datepicker/issues/3672 */
.react-datepicker__navigation--years::before {
  border-color: #666;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  left: 11px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous::before {
  top: 6px;
  transform: rotate(135deg);
}
`

const DatePicker = (props) => {
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const theme = useTheme()
    const styles = css`
        font-family: ${props.width || 'unset'};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSize.sm};
        background-color: ${theme.inputBackgroundColor};
        border-radius: ${theme.elementBorderRadius};
        padding: ${theme[props.padding || 'small']};
        ${!props.hideBorder && `border: ${theme.dividerThickness}px solid ${theme.outlineColor};`}

        &:focus {
            outline: none;
        }
    `
    const utcDate = (date) => {
        // toDate always gets local timezone, so we need to substract the offset to the date
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    }
    let selected = props.selected

    if (selected && typeof selected === 'string') {
        selected = moment(selected)
    }

    if (selected && moment.isMoment(selected)) {
        if (props.isUTC) {
            selected = utcDate(selected.toDate())
        } else {
            selected = selected.toDate()
        }
    }
    const treatAsLocal = (date) => {
        if (!date) return date
        const format = 'YYYY-MM-DDTHH:mm:ss'
        if (props.isUTC) {
            return moment(date).local().format(format)
        } else {
            const stringDate = moment(date).format(format) // Remove the timezone first
            return moment(stringDate).local()
        }
    }

    const onChange = (value) => {
        if (!selected) {
            setStartDate(value)
        }
        if (Array.isArray(value)) {
            const [startDate, endDate] = value
            if (!endDate) {
                setStartDate(startDate)
                return
            } else {
                setStartDate(startDate)
                setEndDate(endDate)
            }
            let localValues = [treatAsLocal(startDate), treatAsLocal(endDate)]
            props.onChange && props.onChange(localValues)
            props.afterChange && props.afterChange(localValues)
            return
        }

        let localValue = treatAsLocal(value)
        props.onChange && props.onChange(localValue)
        props.afterChange && props.afterChange(localValue)
    }
    return (
        <>
            <RDatePicker
                selectsRange={props.selectsRange}
                showYearDropdown
                startDate={startDate}
                endDate={endDate}
                {...props}
                showTimeSelect={props.showTimeSelect && !props.selectsRange}
                onChange={onChange}
                selected={selected || startDate}
                className={styles}
                portalId="react-datepicker"
                popperClassName="date-picker-popper click-outside-ignore"
                popperPlacement="top-end"
                popperModifiers={[
                    {
                        name: 'flip',
                        options: {
                            padding: { top: 50 },
                        },
                    },
                ]}
                fixedHeight={true}
                placeholderText={props.placeholder}
            />
            <DatePickerWrapperStyles />
        </>
    )
}

DatePicker.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
}

export default React.memo(DatePicker)
