// @ts-strict-ignore
/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useState } from 'react'

import { BoxProps, Spinner, useTheme } from '@chakra-ui/react'
import { jsx } from '@emotion/react'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'

import Box from './Box'

type ToggleButtonProps = Omit<BoxProps, 'onChange'> & {
    isChecked?: boolean
    variant?: 'default' | 'bubble'
    onChange: (value: boolean) => void | Promise<void> | null | undefined
    isDisabled?: boolean
}

const DefaultBaseStyle = {
    cursor: 'pointer',
    transition: 'background 0.2s, border 0.2s',
}
const VariantStyles = (props, theme) => ({
    default: {
        base: {
            ...DefaultBaseStyle,
            borderRadius: '5px',
            color: 'body.text',
            '&:hover': {
                background: theme.colors.neutral[200],
            },
        },
        checked: {
            border: `2px solid ${theme.colors.v4Blue}`,
            background: theme.colors.neutral[150],
            color: 'body.text',
            '&:hover': {
                background: theme.colors.neutral[400],
            },
        },
        unchecked: {
            border: `2px solid ${theme.colors.neutral[400]}`,
        },
        checked_disabled: {
            border: `2px solid ${theme.colors.neutral[600]}`,
            cursor: 'default',
            color: theme.colors.neutral[900],
            background: theme.colors.neutral[150],
            '&:hover': {
                background: theme.colors.neutral[150],
            },
        },
        unchecked_disabled: {
            cursor: 'default',
            color: theme.colors.neutral[800],
            border: `2px solid ${theme.colors.neutral[400]}`,
            '&:hover': {
                background: null,
            },
        },
    },
    bubble: {
        base: {
            cursor: 'pointer',
            fontSize: '10px',
            fontWeight: 'bold',
            borderRadius: '20px',
            color: theme.colors.neutral[900],
            border: '1px solid',
            borderColor: theme.colors.neutral[400],
            padding: '6px 22px',
        },
        checked: {
            color: V4DesignSystem.colors.stacker,
            borderColor: V4DesignSystem.colors.stacker,
            background: V4DesignSystem.colors.blue[200],
        },
        unchecked: {},
        checked_disabled: {},
        unchecked_disabled: {},
    },
})

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
    const {
        isChecked,
        onChange,
        style,
        variant = 'default',
        children,
        isDisabled,
        ...boxProps
    } = props
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme()
    const variantStyle = VariantStyles(props, theme)[variant]
    const finalStyle = {
        ...variantStyle.base,
        ...(isChecked ? variantStyle.checked : variantStyle.unchecked),
        ...(isDisabled
            ? isChecked
                ? variantStyle.checked_disabled
                : variantStyle.unchecked_disabled
            : {}),
        ...style,
    }

    const handleClick = useCallback(() => {
        if (isDisabled) return
        const result = onChange(!isChecked)
        if (result && result.then) {
            setIsLoading(true)
            result.then(() => setIsLoading(false))
        }
    }, [onChange, isChecked, isDisabled])
    return (
        <Box
            role="button"
            aria-pressed={isChecked}
            //@ts-expect-error
            css={finalStyle}
            {...boxProps}
            onClick={handleClick}
        >
            {isLoading ? <Spinner size="sm" /> : children}
        </Box>
    )
}
export default ToggleButton
