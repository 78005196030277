import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type UserProps = {
    color?: string
    size?: number
}
const Users: React.FC<UserProps> = ({ color = colors.userInterface.accent[1000], size = 20 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 8.5c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm14 0c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm1 1h-2c-.55 0-1.047.222-1.41.581a4.57 4.57 0 0 1 2.348 3.419H19c.553 0 1-.447 1-1v-1c0-1.103-.897-2-2-2zm-8 0c1.934 0 3.5-1.566 3.5-3.5S11.934 2.5 10 2.5A3.498 3.498 0 0 0 6.5 6c0 1.934 1.566 3.5 3.5 3.5zm2.4 1h-.26c-.65.313-1.371.5-2.14.5a4.933 4.933 0 0 1-2.14-.5H7.6A3.6 3.6 0 0 0 4 14.1v.9a1.5 1.5 0 0 0 1.5 1.5h9A1.5 1.5 0 0 0 16 15v-.9a3.6 3.6 0 0 0-3.6-3.6zm-6.99-.419A1.994 1.994 0 0 0 4 9.5H2c-1.103 0-2 .897-2 2v1c0 .553.447 1 1 1h2.06a4.582 4.582 0 0 1 2.35-3.419z"
                fill={color}
            />
        </svg>
    )
}

export default Users
