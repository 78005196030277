import React from 'react'

import styled from '@emotion/styled'

import airtablePermissionsImage from './airtablePermissionsImage.png'

export const AirtableUpgradePermissionsImage = (): React.ReactElement => (
    <Image src={airtablePermissionsImage} />
)

const Image = styled('img')`
    margin-bottom: 20px;
    display: block;
    align-self: center;
    width: 80px;
    height: 80px;
`
