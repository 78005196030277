// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { Box, Button, Checkbox, Collapse, Flex, Input, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { previewClipboardData } from '../../../data/api/importDataApi'
import TextArea from '../../../v2/ui/components/Textarea'
import useDebounce from '../../../v2/ui/utils/useDebounce'
import { convertFromBytesToMB } from '../utils'

import Counter from './Counter'
import FieldsPreview from './FieldsPreview'

const { colors } = stackerTheme()
export const PASTED_DATA_LIMIT_MB = 2

type ImportTableDataContentProps = {
    onComplete: (
        pasteData: string | undefined,
        tableName: string | undefined,
        generateHeaders: boolean
    ) => void
    isSaving: boolean
    // optionally pass in a table name which also hides the input asking for table name
    inputTableName?: string
}

const ImportTableDataContent = ({
    onComplete,
    isSaving,
    inputTableName,
}: ImportTableDataContentProps) => {
    const [pasteData, setPasteData] = useState<string>('')
    const [tableName, setTableName] = useState<string | undefined>(inputTableName)
    const [hasHeader, setHasHeader] = useState(true)
    const [previewFields, setPreviewFields] = useState<string[]>()
    const [previewRowsCount, setPreviewRowsCount] = useState<number | undefined>()
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)

    const [tableNameError, setTableNameError] = useState(false)
    const [tableDataError, setTableDataError] = useState(false)

    const isDataValid = useCallback(() => pasteData && pasteData.length > 0, [pasteData])
    const isNameValid = () => tableName && tableName.length > 0

    const previewDataCallback = useCallback(
        (data, generateHeaders) => {
            previewClipboardData(data, generateHeaders)
                .then((result) => {
                    setPreviewFields(result.fields)
                    setPreviewRowsCount(result.rows_count)
                    setIsPreviewLoading(false)
                })
                .catch(() => {
                    setPreviewFields([])
                    setPreviewRowsCount(undefined)
                })
        },
        [setPreviewFields, setPreviewRowsCount]
    )

    const debouncedPreview = useDebounce(previewDataCallback, 500, [])

    useEffect(() => {
        if (debouncedPreview && isDataValid()) {
            setIsPreviewLoading(true)
            debouncedPreview(pasteData, !hasHeader)
        }
    }, [isDataValid, debouncedPreview, pasteData, hasHeader])

    const handleImport = () => {
        if (isDataValid() && isNameValid()) {
            onComplete(pasteData, tableName, !hasHeader)
        }

        if (!isNameValid()) {
            setTableNameError(true)
        }

        if (!isDataValid()) {
            setTableDataError(true)
        }
    }

    const handleTableDataInputOnChange = (value) => {
        setPasteData(value)
        setTableDataError(false)
    }

    const handleTableNameInputOnChange = (value) => {
        setTableName(value)
        setTableNameError(false)
    }

    const hasExceededDataSize = () => {
        const convertedCount = convertFromBytesToMB(pasteData?.length ?? 0)
        return convertedCount > PASTED_DATA_LIMIT_MB
    }

    return (
        <Flex direction="column" width="100%" mb={1} alignItems="flex-start">
            <Text variant="title" width="100%">
                Copy existing table data from a spreadsheet and paste it in the field below.
            </Text>

            <TextArea
                value={pasteData}
                onChange={(e) => handleTableDataInputOnChange(e.target.value)}
                mt={3}
                width="100%"
                maxHeight="165px"
                height="165px"
                placeholder="Table data here"
                border={`1px solid neutral.500`}
                borderRadius={5}
                autoFocus
                disabled={isSaving}
            />

            <Collapse isOpen={tableDataError} mt={2}>
                <ErrorText>Please enter your table data from clipboard</ErrorText>
            </Collapse>

            <Flex row width="100%" justifyContent="space-between">
                <Checkbox
                    size="sm"
                    isChecked={hasHeader}
                    isDisabled={isSaving}
                    onChange={(event) => {
                        setHasHeader(event.target.checked)
                    }}
                    data-testid="import-table-data-checkbox"
                >
                    First row is header
                </Checkbox>
                <Counter count={pasteData?.length ?? 0} total={PASTED_DATA_LIMIT_MB} />
            </Flex>
            <Box width="100%">
                <Collapse
                    isOpen={
                        !isPreviewLoading &&
                        !!isDataValid() &&
                        !!previewFields &&
                        previewFields.length > 0
                    }
                    mt={2}
                >
                    <Text fontSize="sm" my={2}>
                        {previewRowsCount} records and {previewFields?.length ?? 0} fields with
                        headers:
                    </Text>
                    {previewFields && <FieldsPreview fields={previewFields} />}
                </Collapse>
            </Box>

            {!inputTableName && (
                <>
                    <Text variant="title" mt={3} width="100%">
                        Give your table a name.
                    </Text>

                    <Input
                        width="100%"
                        mt={3}
                        flexGrow={1}
                        value={tableName}
                        placeholder="Table name here"
                        onChange={(e) => handleTableNameInputOnChange(e.target.value)}
                        size="sm"
                        disabled={isSaving}
                    />
                </>
            )}

            <Collapse isOpen={tableNameError} mt={2}>
                <ErrorText>Please provide a valid table name</ErrorText>
            </Collapse>
            <Flex direction="row" justifyContent="right" mt={4} style={{ width: '100%' }}>
                <Button
                    variant="Primary"
                    p={2}
                    size="sm"
                    onClick={handleImport}
                    isLoading={isSaving}
                    isDisabled={hasExceededDataSize()}
                    data-testid="import-progress.done-button"
                >
                    Import
                </Button>
            </Flex>
        </Flex>
    )
}

export default ImportTableDataContent

const ErrorText = styled(Text)`
    font-size: 12px;
    color: ${colors.userInterface.error[600]};

    margin-bottom: 10px;
`
