import { useMemo } from 'react'

import { cloneDeep } from 'lodash'
import matchSorter from 'match-sorter'
import { ViewColumn } from 'v2/views/List/types'

const RECORD_ID_FIELDS = ['lookup', 'multi_lookup']
const FILTERED_FIELDS = [
    'string',
    'long_text',
    'dropdown',
    'number',
    'lookup',
    'multi_lookup',
    'url',
    'dropdown',
    'currency',
    'percentage',
    'multi_select',
]

export const useSearchRecords = (
    data: RecordDto[],
    columns: ViewColumn[],
    dereferencedRecords: RecordDto[]
) => {
    const { searchCols, recordLinkColumns } = useMemo(() => {
        const recordLinkColumns: ViewColumn['accessor'][] = []
        const searchCols: ViewColumn['accessor'][] = []
        columns.forEach((column) => {
            if (FILTERED_FIELDS.includes(column.type) && column.id) {
                searchCols.push(column.accessor)
            }
            if (RECORD_ID_FIELDS.includes(column.type)) {
                recordLinkColumns.push(column.accessor)
            }
        })
        return { searchCols, recordLinkColumns }
    }, [columns])

    const searchData = useMemo(() => {
        const searchData = cloneDeep(data)
        if (!recordLinkColumns.length) return searchData
        // Update record id to their value for searching
        searchData.forEach((row) => {
            recordLinkColumns.forEach((column) => {
                if (typeof column !== 'string') {
                    return
                }

                const val = row[column]
                if (Array.isArray(val)) {
                    row[column] = val.map(
                        (v) => dereferencedRecords.find((r) => r._sid === v)?._primary
                    )
                } else {
                    const linkSearchValue = dereferencedRecords.find(
                        (r) => r._sid === val
                    )?._primary
                    if (linkSearchValue) {
                        row[column] = linkSearchValue
                    }
                }
            })
        })
        return searchData
    }, [data, recordLinkColumns, dereferencedRecords])

    const searchResults = (searchValue?: string) => {
        if (!searchValue) {
            return data
        }
        const searchResults = matchSorter(searchData, searchValue, {
            //@ts-expect-error it's hard to map column.accessor type to RecordDto keys
            keys: searchCols,
            threshold: matchSorter.rankings.CONTAINS,
        })

        // We do the search on the actual record values but we need to return the
        // data in the correct form (ids for lookups) - so this will return the
        // unaltered data that matches the search criteria
        return data.filter((x) => searchResults.some((y) => y._sid == x._sid))
    }

    return searchResults
}
