import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export const LinkList = ({ children }) => {
    return (
        <ul style={{ margin: 0, padding: 0 }}>
            {React.Children.map(children, (child) => {
                return (
                    <li style={{ margin: 0, padding: 0, marginBottom: 10, listStyleType: 'none' }}>
                        {child}
                    </li>
                )
            })}
        </ul>
    )
}
