import React, { VFC } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

const SolidExpand: VFC<{
    color?: string
    size?: number
}> = ({ color = colors.userInterface.neutral[700], size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.125 11.0156C8.61719 10.5078 7.83594 10.5078 7.32812 11.0156L4.28125 14.0625L3.10938 12.8906C2.875 12.6562 2.5625 12.5391 2.21094 12.5391C2.05469 12.5391 1.89844 12.5391 1.74219 12.6172C1.27344 12.8125 0.960938 13.2812 0.960938 13.7891V19.0625C1 19.6094 1.39062 20 1.9375 20H7.25C7.71875 20 8.1875 19.7266 8.38281 19.2578C8.57812 18.7891 8.46094 18.2422 8.10938 17.8906L6.9375 16.7188L9.98438 13.6719C10.4922 13.1641 10.4922 12.3828 9.98438 11.875L9.125 11.0156ZM20.0234 0H14.7109C14.2031 0 13.7344 0.3125 13.5391 0.78125C13.3438 1.25 13.4609 1.79688 13.8125 2.14844L14.9844 3.32031L11.9375 6.36719C11.4297 6.875 11.4297 7.65625 11.9375 8.16406L12.8359 9.02344C13.3047 9.53125 14.0859 9.53125 14.5938 9.02344L17.6406 5.97656L18.8125 7.14844C19.1641 7.5 19.7109 7.61719 20.1797 7.42188C20.6875 7.22656 21 6.75781 21 6.25V0.9375C21 0.429688 20.5703 0 20.0234 0Z"
            fill={color}
        />
    </svg>
)

export default SolidExpand
