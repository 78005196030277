const sizes = (config) => {
    const { iconSizes, imageSizes, buttonSizes, fontSizes } = config

    return {
        container: {
            icon: iconSizes.sm,
            iconOuter: imageSizes.sm,
        },
        table: {
            coverImage: imageSizes.sm,
            coverImageLg: imageSizes.md,
        },

        kanban: {
            coverImage: imageSizes.xs,
            coverImageLg: imageSizes.xs,
        },
        cards: {
            coverImage: '158px',
            coverImageLg: '180px',
        },
        templateLibrary: {
            coverImage: '120px',
            coverImageLg: '140px',
        },
        rows: {
            coverImage: '80px',
            coverImageLg: '80px',
        },
        thumbnail: {
            sm: '40px',
            md: '48px',
            lg: '96px',
        },
        button: {
            squareSm: {
                size: buttonSizes.sm,
                sizeLg: buttonSizes.sm,
            },
            squareMd: {
                size: buttonSizes.md,
                sizeLg: buttonSizes.md,
            },
            sm: {
                h: buttonSizes.sm,
                hLg: buttonSizes.sm,
            },
            md: {
                h: buttonSizes.md,
                hLg: buttonSizes.md,
            },
            filter: {
                h: '20px',
                hLg: '20px',
            },
        },
        input: {
            icon: buttonSizes.sm,
            iconLg: buttonSizes.sm,
        },
        admin: {
            sideMenu: {
                buttonHeight: '40px',
                buttonHeightLg: '40px',
            },
            palette: {
                widget: {
                    icon: fontSizes.md,
                    iconLg: fontSizes.lg,
                },
            },
        },
        profileImage: {
            detail: '60px',
            detailLg: '80px',
        },
        installableFeatureLibrary: {
            coverImage: '100px',
        },
    }
}

export default sizes
