import React from 'react'

import type { PatchableObjectState } from './__usePatchableObject'

export const patchableActionContext = React.createContext<PatchableObjectState | undefined>(
    undefined
)

export function usePatchableActionContext() {
    return React.useContext(patchableActionContext)
}
