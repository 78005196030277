/* Code Quality: Bad */

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'qs'

import HelpCenter from 'app/HelpCenter'
import HELP_LINKS from 'app/helpLinks'
import { getUrl, Urls } from 'app/UrlService'
import { withAccounts } from 'data/wrappers/WithAccounts'
import { withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import SubscriptionBadge from 'features/admin/account/SubscriptionBadge'
import { navigationColor } from 'features/admin/settings/navigation/NavigationUtils'
import Frame from 'features/core/Frame'
import { AbsoluteLogo, HeaderBar, Logo } from 'features/core/nav/navUtils'
import { useLogo } from 'features/utils/getLogo'
import { Card, Container, Heading, Icon8 } from 'legacy/v1/ui'
import StackColorButton from 'legacy/v1/ui/components/StackColorButton'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

import { Dropdown, Flex, Text } from 'v2/ui'

import { Content, ContentWrapper, Page } from '../ui/Layout'

import AddNewAdminButton from './AddNewAdminButton'

const StacksPage = ({ user, selectedStack, accounts, stacks, workspaceAccount }) => {
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })
    const history = useHistory()

    const defaultAccount = query.account || get(selectedStack, 'account_id') || accounts[0]?._sid
    const [userSelectedAccount, setUserSelectedAccount] = useState(defaultAccount)
    const selectedAccount = userSelectedAccount || defaultAccount
    const selectedAccountObject = accounts?.find((account) => account._sid === selectedAccount)
    const redirectToWorkspaceOnboarding =
        selectedAccountObject?.is_workspace &&
        selectedAccountObject.options?.signup_params?.flow === '4.1' &&
        !selectedAccountObject.options?.signup_params.simple_builder_flow &&
        !selectedAccountObject.options?.onboarding_status?.tables_set

    const workspaceApps =
        stacks?.filter((x) => (x.account_id === selectedAccount) & !x.options?.is_demo) ?? []

    const redirectToAccountOnboarding =
        selectedAccountObject?.is_workspace && !selectedStack?._sid
            ? false
            : workspaceApps.length === 0

    useEffect(() => {
        // for some reason this redirect loses the query params if done
        // inside the functional component itself, but works correctly from this useEffect
        if (redirectToWorkspaceOnboarding) {
            const url = `${getUrl(Urls.WorkspaceOnboarding)}?id=${selectedAccount}`
            history.replace(url)
            return
        }

        if (redirectToAccountOnboarding) {
            const url = getUrl(Urls.AccountOnboarding)
            history.replace(url)
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const stackColor = get(stackerTheme, 'brandColor')

    const navTheme = navigationColor({ navColor: 'dark' })
    navTheme.height = '64px'

    const logo = useLogo(navTheme.stackerLogo)

    if (redirectToWorkspaceOnboarding) {
        return null
    }

    // If we have one or more apps that is flagged as a workspace app, just launch right into that
    // workspace by navigating to the app
    const workspaceStack = stacks && stacks.find((x) => get(x.options, 'workspace_app'))
    if (workspaceStack && !get(selectedStack, '_sid') && !query.account) {
        const account = accounts.find((account) => account._sid === workspaceStack.account_id)
        if (account?.url) {
            window.location.replace(`//${account.url}/auth?token=${user.api_token}`)
            return null
        }
    }
    const externalLinkIcon = (
        <Icon8
            icon="external-link"
            color="AAAAAA"
            style={{
                height: 15,
                marginLeft: 2,
            }}
            size={40}
        />
    )

    const StackLink = ({ selected, children, stackUrl, sid, useLink }) => {
        const style = {
            display: 'inline-block',
            flex: '0 1 300px',
            flexWrap: 'wrap',
            marginRight: '20px',
            margin: '20px 20px 0 0',
        }
        if (!selected) {
            if (useLink)
                return (
                    <span style={style}>
                        <Link to={stackUrl} key={`link${sid}`}>
                            {children}
                        </Link>
                    </span>
                )
            return (
                <a target="_self" href={stackUrl} key={`link${sid}`} style={style}>
                    {children}
                </a>
            )
        }

        return <div style={style}>{children}</div>
    }

    const CardButton = ({ selected, url }) => {
        if (selected) {
            return (
                <Text
                    margin="none"
                    style={{
                        color: stackColor,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        alignSelf: 'flex-end',
                        marginLeft: 'auto',
                    }}
                >
                    Current app
                </Text>
            )
        }

        return (
            <StackColorButton
                color="stacker"
                screenReaderText={selected ? '' : ` ${url}`}
                disabled={!!selected}
                style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}
            >
                {selected ? 'Current app' : 'Open'}
            </StackColorButton>
        )
    }

    // Only list non v4 accounts.
    // Note: if the current selected account is a workspace account, then
    // thats an exception
    const accountOptions = accounts
        .filter((x) => !x.is_workspace || x === selectedAccountObject)
        .map((x) => ({
            label: x.name || x._sid,
            value: x._sid,
        }))

    // no selected stack or is workspace account ( = onboarding )
    const displayHeaderBar =
        (!selectedStack || !selectedStack._sid) && selectedAccountObject?.is_workspace

    return (
        <Page>
            {displayHeaderBar && (
                <HeaderBar {...navTheme}>
                    <AbsoluteLogo>
                        <Logo src={logo} />
                    </AbsoluteLogo>
                </HeaderBar>
            )}

            <Frame>
                <HelpCenter />

                <ContentWrapper>
                    <Content>
                        <Flex
                            style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Heading helpUrl={HELP_LINKS.MULTIPLE_PORTALS} size="large">
                                My Apps
                            </Heading>

                            {accounts.length > 1 && (
                                <Flex>
                                    <Text mr={2}>Account:</Text>
                                    <Dropdown
                                        options={accountOptions}
                                        value={selectedAccount}
                                        onChange={setUserSelectedAccount}
                                        isSearchable={false}
                                        isClearable={false}
                                    />
                                </Flex>
                            )}

                            {!workspaceAccount && selectedStack && selectedStack._sid && (
                                <AddNewAdminButton />
                            )}
                        </Flex>
                        <Text mt={4}>
                            These are the apps linked to your account. You can navigate to another
                            app, or create a new one.
                        </Text>
                        <StackContainer wrapItems>
                            {workspaceApps.length > 0 &&
                                workspaceApps.map((stack) => {
                                    const stackAccount =
                                        stack.account_id &&
                                        accounts.find(
                                            (account) => account._sid === stack.account_id
                                        )

                                    const selected = selectedStack._sid === stack._sid

                                    const isSameDomain =
                                        stack.options?.workspace_app &&
                                        stackAccount.base_url === window.location.host

                                    let stackUrl
                                    if (stack.options?.workspace_app) {
                                        stackUrl = isSameDomain
                                            ? `/${stack.url_slug}${Urls.Home}`
                                            : `//${stackAccount.base_url}/${stack.url_slug}${Urls.Home}`
                                    } else {
                                        stackUrl = `//${stack.url}/admin`
                                    }

                                    const urlToDisplay = stack.options?.workspace_app
                                        ? stackAccount.base_url + '/' + stack.url_slug
                                        : stack.url

                                    return (
                                        <StackLink
                                            key={stack._sid}
                                            stackUrl={`${stackUrl}${
                                                isSameDomain ? '' : `?api_token=${user.api_token}`
                                            }`}
                                            sid={stack._sid}
                                            selected={selected}
                                            useLink={isSameDomain}
                                        >
                                            <StackCard
                                                style={{
                                                    outline: selected
                                                        ? `4px solid ${stackColor}`
                                                        : '',
                                                }}
                                                flushTopLeft
                                                margin="large"
                                                padding="large"
                                                key={stack._sid}
                                            >
                                                <SubscriptionBadge
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                    }}
                                                    stack={stack}
                                                    account={selectedAccountObject}
                                                />
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            justifyContent: 'space-between',
                                                            alignItems: 'flex-start',
                                                            flexWrap: 'wrap',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                flexBasis: '100%',
                                                                maxWidth: '250px',
                                                            }}
                                                        >
                                                            <Heading
                                                                padding="none"
                                                                size="medium"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {stack.name}
                                                            </Heading>
                                                            <TruncatedText size="fontS">
                                                                {urlToDisplay}
                                                            </TruncatedText>
                                                        </div>
                                                        <Container
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'flex-end',
                                                            }}
                                                        >
                                                            {get(stack, 'options.theme.logo') && (
                                                                <StackIcon
                                                                    style={{
                                                                        backgroundImage: `url(${
                                                                            get(
                                                                                stack,
                                                                                'options.theme.logo'
                                                                            ) ||
                                                                            'https://img.icons8.com/dotty/80/000000/file.png'
                                                                        })`,
                                                                    }}
                                                                />
                                                            )}
                                                            <CardButton
                                                                selected={selected}
                                                                url={stack.url}
                                                            />
                                                        </Container>
                                                    </div>
                                                    <Text
                                                        style={{
                                                            color: '#AAAAAA',
                                                            fontWeight: 'bold',
                                                            width: '100%',
                                                            fontSize: '1em',
                                                            display: 'none',
                                                        }}
                                                    >
                                                        {stack.url}
                                                        {externalLinkIcon}
                                                    </Text>
                                                    <Text>{stack.options.description || ''}</Text>
                                                </div>
                                            </StackCard>
                                        </StackLink>
                                    )
                                })}
                        </StackContainer>
                    </Content>
                    )
                </ContentWrapper>
            </Frame>
        </Page>
    )
}

StacksPage.propTypes = {
    children: PropTypes.node,
}

export default withUser(withAccounts(withStacks(StacksPage)))

const StackCard = styled(Card)`
    height: 200px;
    width: 100%;
    padding: 15px;
    margin: 0;
    position: relative;
`
const StackIcon = styled('div')`
    width: 100px;
    height: 80px;
    vertical-align: bottom;
    display: flex;
    align-self: flex-end;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
`

const StackContainer = styled(Container)`
    margin-right: -20px;
`
const TruncatedText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
