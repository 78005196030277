import React, { useEffect } from 'react'

import { useRepairAirtableFlow } from 'features/AppSettings/DataSources/Airtable/repair/useRepairAirtableFlow'

type AirtableConnectionRepairStepParams = {
    dataConnection: DataConnectionDto
    onClose?: () => void
}

export const AirtableConnectionRepairStep: React.FC<AirtableConnectionRepairStepParams> = ({
    dataConnection,
    onClose,
}) => {
    const { showModal, modal } = useRepairAirtableFlow(dataConnection, onClose)

    useEffect(() => {
        showModal('missing_permissions')
    }, [showModal])

    return <>{modal}</>
}
