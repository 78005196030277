import React from 'react'

import getInitials from 'v2/views/utils/getInitials'

import { BackgroundImage, Box } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

const ProfileImageTemplate = ({ children, size, ...props }) => {
    return (
        <Box
            size={size || ['profileImage.detail', null, null, 'profileImage.detailLg']}
            borderRadius="full"
            flexShrink={0}
            bg={props.src ? '' : 'brand.300'}
            color="text.brand"
            fontSize="lg"
            fontWeight="bold"
            className={STYLE_CLASSES.PROFILE_IMAGE}
            {...props}
        >
            {children}
        </Box>
    )
}

const ProfileImage = ({ image, name = {}, children, ...props }) => {
    if (!image)
        return (
            <ProfileImageTemplate {...props} position="relative">
                <Box position="absolute" width="100%" height="100%" top={0}>
                    <Box
                        position="absolute"
                        width="100%"
                        pt="100%"
                        transform="translateY(-50%)"
                        textAlign="center"
                    >
                        {getInitials(name)}
                    </Box>
                </Box>
                {children}
            </ProfileImageTemplate>
        )
    return (
        <ProfileImageTemplate as={BackgroundImage} src={image} {...props}>
            {children}
        </ProfileImageTemplate>
    )
}

export default ProfileImage
