// CONSTANTS
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_IS_OPEN = 'SET_IS_OPEN'
export const SET_MODAL_DATA = 'SET_MODAL_DATA'

const initialState = {
    open: {},
    data: {},
}
// REDUCERS
export default function modalsReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MODAL:
            return {
                ...state,
                open: { ...state.open, [action.key]: !state.open[action.key] },
            }
        case SET_IS_OPEN:
            return {
                ...state,
                open: { ...state.open, [action.key]: action.value },
            }
        case SET_MODAL_DATA:
            return {
                ...state,
                data: { ...state.data, [action.key]: action.value },
            }
        default:
            return state
    }
}
