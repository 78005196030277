/* Code Quality: OK */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import queryString from 'qs'
import { bindActionCreators } from 'redux'

import { getWorkspaceAccount } from 'app/AppContextStore'
import settings from 'app/settings'
import { getUrl, trimRootPathFromUrl, Urls } from 'app/UrlService'
import { blockActions } from 'data/api/blockApi'
import { translationActions } from 'data/api/translationApi'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { withFeatures } from 'data/wrappers/WithFeatures'
import { withNavigation } from 'data/wrappers/WithNavigation'
import { withPages } from 'data/wrappers/WithPages'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { withViews } from 'data/wrappers/WithViews'
import { featureToMenuRank } from 'features/admin/settings/menu/MenuItemPicker'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import Frame from 'features/core/Frame'
import { viewHasAssociatedPage } from 'features/core/nav/viewHasAssociatedPage'
import { PathContext } from 'features/utils/PathContext'
import { getRedirectParam } from 'utils/utils'

import { Collapse, Flex, Icon, Text } from 'v2/ui'
import Button from 'v2/ui/components/Button'

import getPageFromUrl from '../utils/getPageFromUrl'

import Page from './Page'

class PageByUrl extends React.Component {
    /*
        PageByUrl works out which page to view based on the url, and displays it, potentially in a studio surround
    */

    constructor(props) {
        super(props)
        // debugger
        invalidateObjects()
        this.state = { isConnectionEditorOpen: false }
    }

    componentDidMount() {
        // If user is not logged in, we check if there is an auth_token,
        // to automatically authenticate the user, in case he is trying to
        // access a private page (this is part of the portal signup flow)
        const { user } = this.props
        if (!user) {
            const searchString = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
            })
            const token = searchString.auth_token
            if (token)
                this.props.userActions.logInStudioUserByTemporaryAuthToken(token).then(() => {
                    window.location = window.location.pathname // Force refresh without auth_token
                })
        } else {
            //get extra user information
            this.props.userActions.getUser()
        }
    }

    showMainPageForFeature = (feature) => {
        const featurePages = this.props.pages
            .filter((page) => !page.is_feature_settings)
            .filter((page) => page.feature_id === feature._sid)
            .filter((page) => page.url.indexOf('{') === -1) // don't include detail pages anyway
            .filter((page) => {
                const view = this.props.views.find(({ _sid }) => _sid === page.options.view_id)
                return view?.type === 'list'
            }) // Only show a list page
        return featurePages.length ? featurePages[0] : null
    }

    getViewPage = (view) => {
        const viewPage = this.props.pages.filter(
            (page) => page.options.view_id == view._sid && !page.is_feature_settings
        )

        return viewPage.length ? viewPage[0] : null
    }

    homePage = () => {
        // We return the first page displayed on the item menu
        const { views, features, stackOptions, navigation, pages } = this.props

        const isSecondaryNavigation =
            localStorage.getItem('secondary_navigation') || stackOptions.secondary_navigation

        if (isSecondaryNavigation) {
            if (navigation && navigation.length > 0) {
                const navTree = buildNavTree(navigation, views, pages).filter((n) => !n.hidden)

                for (let i = 0; i < navTree.length; i++) {
                    const visibleChildren = navTree[i].children.filter((x) => !x.hidden)
                    if (visibleChildren?.length > 0) {
                        return visibleChildren[0]
                    }
                }
            }
            // all pages are hidden or unavailable
            return undefined
        } else {
            const featureList = mapKeys(features, (feature) => feature._sid)
            const rankedViews = sortBy(views, [featureToMenuRank]).filter(
                (view) =>
                    !view.options.hide_from_menu &&
                    view.type == 'list' &&
                    view.feature_id in featureList &&
                    viewHasAssociatedPage(view, pages)
            )
            return rankedViews.length ? rankedViews[0] : null
        }
    }

    setConnectionEditorOpen = (isConnectionEditorOpen) => {
        this.setState({ isConnectionEditorOpen })
    }

    render() {
        const route = trimRootPathFromUrl(this.props.location.pathname).slice(1)

        const pages = this.props.pages.filter(
            (page) => !page.is_feature_settings && !page.is_feature_template
        )
        // eslint-disable-next-line prefer-const
        let { page, feature, captures, pageUrl, view } = getPageFromUrl({
            features: this.props.features,
            pages,
            route,
            views: this.props.views,
        })

        const wrap = (contents) => <Frame>{contents}</Frame>

        const { isConnectionEditorOpen } = this.state
        if (!page) {
            if (
                this.props.pages.length === 0 ||
                this.props.features.length === 0 ||
                this.props.views.length === 0
            ) {
                // Page is still loading
                if (!this.props.user) {
                    // If we're trying to log into a workspace, redirect to the
                    // studio for login. It will redirect back to the workspace.
                    const workspaceAccount = getWorkspaceAccount()
                    const redirectTo = getRedirectParam()
                    if (workspaceAccount) {
                        window.location.assign(
                            `${settings.STUDIO_URL}/login?workspace=${workspaceAccount._sid}&domain=${window.location.host}&r=${redirectTo}`
                        )
                    } else {
                        window.location.assign(`${getUrl(Urls.Login)}?r=${redirectTo}`)
                    }
                }
                return wrap('')
            } else {
                // If we're at the root of the feature, then we literally just choose one of the pages
                // TODO: find a better way to do this

                // eslint-disable-next-line no-lonely-if
                if (view) {
                    page = this.getViewPage(view)
                } else if (feature && pageUrl === '/') {
                    const featureMainPage = this.showMainPageForFeature(feature)
                    if (featureMainPage) {
                        page = featureMainPage
                    }
                } else if (!feature && (pageUrl === Urls.Home || pageUrl === '/')) {
                    // Home is our homepage, and / is for catchall
                    // We've not found a page or a root feature, so let's choose the first page that appears on the menu bar
                    const homeView = this.homePage()
                    if (homeView && !isConnectionEditorOpen) {
                        this.props.history.replace(getUrl(homeView.url))
                        // the page will re-render now that the location is changing
                        // but in the meantime, we want to return null and not render
                        // <Page /> below or it may initiate a redirect itself because we don't
                        // yet have a page
                        return null
                    }
                } else {
                    // Otherwise probably just show a four oh four
                    const pageIsEditing =
                        this.props.location.hash === '#edit' && this.props.isStudioUser

                    if (pageIsEditing) {
                        return wrap(`Page ${route} does not exist`)
                    } else {
                        // Finished loading pages but page is not valid, so we load 404 page instead
                        // Get /stacker/no-access or /stacker/page-not-found page
                        const route404 = this.props.isLoggedIn
                            ? '/stacker/page-not-found'
                            : '/stacker/no-access'
                        const result404 = getPageFromUrl({
                            features: this.props.features,
                            pages,
                            route: route404,
                        })
                        if (!result404.page) {
                            // Just redirect to home if we have no page that matches this URL any more
                            return <Redirect to={getUrl(Urls.Home)} />
                        } else {
                            // Replace the page we were going to load with the 404 page
                            page = result404.page
                            captures = result404.captures
                        }
                    }
                }
            }
        }

        return (
            <PathContext.Provider
                value={{
                    pageUrl: '/' + route,
                    page: page,
                    feature: feature,
                    listView: get(view, 'type') === 'list' ? view : null,
                    recordId: get(captures, 'id'),
                    view: view,
                }}
            >
                <Page
                    updatePage={this.props.updatePage}
                    view={view}
                    page={page}
                    route={route}
                    captures={captures}
                    feature={feature}
                    features={this.props.features}
                    isWorkspaceApp={this.props.stackOptions?.workspace_app}
                    setConnectionEditorOpen={this.setConnectionEditorOpen}
                    isConnectionEditorOpen={isConnectionEditorOpen}
                />
                {this.props.studioUser &&
                    !this.props.isImpersonating &&
                    !get(this.props.stackOptions, 'workspace_app') && <MobileAdminBanner />}
            </PathContext.Provider>
        )
    }
}

PageByUrl.propTypes = {
    blockActions: PropTypes.object.isRequired, // From redux
    features: PropTypes.array.isRequired, // From redux
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired, // From withPages
    isStudioUser: PropTypes.bool.isRequired, // From withUser
    isLoggedIn: PropTypes.bool.isRequired, // From withUser
    translationActions: PropTypes.object.isRequired, // From redux
    userActions: PropTypes.object.isRequired, // From withUser
    user: PropTypes.object.isRequired, // From withUser
}

function mapDispatchToProps(dispatch) {
    return {
        // Load these in advance so that we don't end up with the pages with no content in them
        blockActions: bindActionCreators(blockActions, dispatch),
        translationActions: bindActionCreators(translationActions, dispatch),
        dispatch,
    }
}

const MobileAdminBanner = () => {
    const [isLoaded, setIsLoaded] = useState()

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    const mobile = window.innerWidth <= 768

    if (!mobile) return null

    return (
        <Collapse isOpen={!!isLoaded}>
            <Flex
                bg="#09283B"
                p={3}
                position="fixed"
                bottom={0}
                right={0}
                left={0}
                shadow="lg"
                wrap="noWrap"
                borderTop="3px solid"
                borderTopColor="adminBrand"
                align="center"
            >
                <Icon icon="desktop" fontSize="lg" color="#7AADCC" mr={3} />
                <Text mr="70px" color="#7AADCC" fontSize="smmd">
                    To configure your app, log in on a device with a larger&nbsp;screen.
                    <Button
                        variant="clearWhite"
                        icon="x"
                        iconAlign="right"
                        iconColor="white"
                        buttonSize="sm"
                        onClick={() => setIsLoaded(false)}
                    >
                        Close
                    </Button>
                </Text>
            </Flex>
        </Collapse>
    )
}
export default withNavigation(
    withStack(
        withUser(
            withRouter(
                withFeatures(withPages(withViews(connect(null, mapDispatchToProps)(PageByUrl))))
            )
        )
    )
)
