import { EmbeddableUrlKind } from './types'
import {
    getEmbeddableYouTubePlaylistUrl,
    getEmbeddableYouTubeVideoUrl,
    isValidYouTubePlaylistUrl,
    isValidYouTubeVideoUrl,
} from './youtube'

export function detectEmbeddableUrlKind(url: string): EmbeddableUrlKind {
    if (isValidYouTubeVideoUrl(url)) return EmbeddableUrlKind.YouTubeVideo
    if (isValidYouTubePlaylistUrl(url)) return EmbeddableUrlKind.YouTubePlaylist

    return EmbeddableUrlKind.Unknown
}

export function getEmbeddableUrl(url: string): string | null {
    const urlKind = detectEmbeddableUrlKind(url)
    switch (urlKind) {
        case EmbeddableUrlKind.Unknown:
            return url
        case EmbeddableUrlKind.YouTubeVideo:
            return getEmbeddableYouTubeVideoUrl(url)
        case EmbeddableUrlKind.YouTubePlaylist:
            return getEmbeddableYouTubePlaylistUrl(url)
        default:
            return null
    }
}
