import React from 'react'

import { getUrl, Urls } from '../../../app/UrlService'
import { useDataConnection } from '../../../data/hooks/dataConnections'
import { DATA_CONNECTION_TYPE_VALUES } from '../../../data/utils/constants'
import { fetchWithAuth } from '../../../data/utils/utils'
import useUrlParam from '../../../v2/ui/utils/useUrlParam'
import NewConnectionModal from '../../AppSettings/DataSources/NewConnectionModal'
import { ProviderPickerItem } from '../../AppSettings/DataSources/providerPickerTypes'

type DataConnectionStepProps = {
    workspaceAccount: Account
    stack: StackDto
    redirectToWorkspace: () => void
    updateAccountOnboardingStatus: (key: string, value: any) => Promise<void>
    onClose: (data?: DataConnectionDto) => void
}
export const DataConnectionStep: React.FC<DataConnectionStepProps> = (
    props: DataConnectionStepProps
) => {
    const [connected] = useUrlParam<string>('connected', '')
    const { data: dc } = useDataConnection(connected)
    const [demoApps, setDemoApps] = React.useState<ProviderPickerItem<string>[] | 'LOADING'>(
        'LOADING'
    )

    React.useEffect(() => {
        fetchWithAuth('onboarding-demo-apps').then((response) => {
            response.json().then((json) => {
                setDemoApps(json)
            })
        })
    }, [])

    const initialDataConnectorType =
        props.workspaceAccount.options?.signup_params?.initial_data_connector_type ?? ''

    const initialProvider = isValidDataConnectionType(initialDataConnectorType)
        ? initialDataConnectorType
        : 'airtable'
    return (
        <NewConnectionModal
            demoApps={{
                apps: demoApps,
                onFinishedCreatingDemoApp: props.redirectToWorkspace,
            }}
            stack={props.stack}
            redirectUrl={getUrl(Urls.WorkspaceOnboarding, undefined)}
            provider={initialProvider}
            isOpen={true}
            disableClose={true}
            onClose={props.onClose}
            onSyncStarted={() => props.updateAccountOnboardingStatus('data_connected', true)}
            dataConnection={dc}
            isAppCreateFlow={true}
            workspaceAccount={props.workspaceAccount}
        />
    )
}

const isValidDataConnectionType = (dcType: string): dcType is DataConnectionType => {
    return (DATA_CONNECTION_TYPE_VALUES as readonly string[]).includes(dcType)
}
