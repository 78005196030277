import React, { Component, memo, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { withObject } from 'data/wrappers/WithObject'
import { isBlank } from 'utils/utils'

import { Text } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'
import LabelledAttribute from 'v2/ui/components/Attribute/LabelledAttribute'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDebounce from 'v2/ui/utils/useDebounce'

import { attributeShouldDisplayLabel } from './attributeShouldDisplayLabel'

const labelStyle = { margin: 'none', padding: 'none' }

// A wrapper around Attribute and LabelledAttribute which accounts for the extra display logic.
const AttributeDisplay = ({
    object,
    fieldId,
    record,
    recordUpdates,
    required,
    fullWidth,
    enableCopyPaste,
    readOnly,
    editing,
    labelOverride,
    editDescription,
    variant,
    layout,
    isVisible,
    isCreate,
    isInlineCreate,
    showErrors,
    setValue,
    setValid,
    valid,
    blockId,
    placeholder,
    isLoading,
    ...renderOptions
}) => {
    // tracks the state of the value as it is edited, used for showing
    // if the *current* value is different from the value in the update
    // in the case that someone else edited the record before the user saves
    const [currentEditValue, setCurrentEditValue] = useState(undefined)

    // set back to undefined when editing toggled off
    useEffect(() => {
        if (!editing) {
            setCurrentEditValue(undefined)
        }
    }, [editing])

    const handleOnChange = useDebounce(
        (value, name, options = undefined) => {
            setCurrentEditValue(value)
            setValue(name, value, options)
        },
        200,
        [setValue]
    )

    const field = object.fields.find((field) => field._sid === fieldId)

    let value = null
    // Check field here rather than making use of the check below. We can't put the line
    //   if (!field) return ''
    // before here because is has to be after the useEffect(), which has to come _after_ this block
    // becasue it uses the variable `value`
    if (record && field) {
        // Want to return null here instead of undefined,
        // as would be the case with a new record
        value = record[field?.api_name] !== undefined ? record[field?.api_name] : null
    }

    if (!field) return ''
    if (get(field, 'connection_options.is_disabled', false)) return ''

    const isValid = !isVisible || !required || !isBlank(value)

    const showError = showErrors && !isValid
    const isEditing = editing && !readOnly

    let cellStyle = { maxWidth: '100%' }
    if (fullWidth) {
        cellStyle.gridColumn = '1 / -1'
    }

    const hideLabel =
        renderOptions?.hideLabel || !attributeShouldDisplayLabel(field, renderOptions, isEditing)
    const className = `${STYLE_CLASSES.ATTRIBUTE} ${STYLE_CLASSES.ATTRIBUTE}-${field.api_name}`

    const isFieldUpdated = !!recordUpdates?.diff[field.api_name]

    // in edit mode, we show which fields have been updated in a record
    // by highlighting the label
    const highlightFieldUpdated =
        false && // NOTE hardcoding field-level conflict highlighting off for now to ship the simple version of the functionality - remove this line to turn back on
        editing &&
        isFieldUpdated &&
        // if the user has input the same value as the update,
        // don't highlight, as there is no conflict
        recordUpdates.diff[field.api_name]?.valueB !== currentEditValue?.value

    return (
        <span style={cellStyle} className={className}>
            <LabelledAttribute
                dontHideOverflow
                maxWidth={fullWidth && '100%'}
                label={labelOverride || field.label}
                hideLabel={hideLabel}
                editing={isEditing}
                editDescription={editDescription}
                required={editing && required}
                style={labelStyle}
                variant={variant}
                layout={layout}
                noPadding
                highlightFieldUpdated={highlightFieldUpdated}
                recordId={record?._sid}
            >
                <Attribute
                    isInlineCreate={isInlineCreate}
                    isCreate={isCreate}
                    field={field}
                    editable={isEditing}
                    onChange={handleOnChange}
                    renderOptions={renderOptions}
                    layout={layout}
                    animateOnValueChange={value}
                    enableCopyPaste={enableCopyPaste}
                    recordId={record?._sid}
                    contextRecord={record}
                    placeholder={placeholder}
                    isLoading={isLoading}
                >
                    {value}
                </Attribute>
                {showError && (
                    <Error className={STYLE_CLASSES.FIELD_ERROR}>This field is required</Error>
                )}
            </LabelledAttribute>
            {isEditing && (
                <ValidityReporter
                    key={isValid}
                    name={field.api_name}
                    isValid={isValid}
                    setValid={setValid}
                    validStore={valid}
                    blockId={blockId}
                />
            )}
        </span>
    )
}

AttributeDisplay.propTypes = {
    objectId: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    enableCopyPaste: PropTypes.bool,
    readOnly: PropTypes.bool,
}

AttributeDisplay.defaultProps = {
    required: false,
    fullWidth: false,
    readOnly: false,
    isLoading: false,
}

export default memo(withObject(AttributeDisplay))

class ValidityReporter extends Component {
    render = () => null

    constructor(props) {
        super(props)
    }

    fieldName = () => {
        const { name, blockId } = this.props
        return `${name}${blockId ? '___' + blockId : ''}`
    }

    // call setValid on mount and on update
    componentDidMount() {
        const { isValid, setValid } = this.props
        setValid(this.fieldName(), isValid)
    }
    componentDidUpdate(prevProps) {
        const { isValid, setValid } = prevProps
        if (isValid === this.props.isValid) return
        setValid && setValid(this.fieldName(), this.props.isValid)
    }
    componentWillUnmount() {
        // If the component unmounts due to conditional visibility then mark the field as
        // valid so that the form will submit
        const { setValid } = this.props
        setValid && setValid(this.fieldName(), true)
    }
}

const Error = styled(Text)`
    color: red;
    margin: 0.5rem;
`
