import { getCachedObject } from 'data/hooks/objects/objectOperations'

import { FieldPatch } from '../common'

// Get the linked object name from a field
export const getLinkedFieldObjectName = (field: FieldPatch): string | undefined => {
    // Get the linked object _sid
    const lookupTarget = field?.options?.lookup_target
    if (!lookupTarget) return
    // Get the linked object
    const linkedObject = getCachedObject(lookupTarget)
    if (linkedObject) {
        // Return the linked object name
        return linkedObject.name
    }
}
