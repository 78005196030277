// @ts-nocheck
import React, { forwardRef } from 'react'

import { Modal, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react'

import { Button, Flex, Icon, Text } from 'v2/ui'

const CloseButton = forwardRef(({ onClick }) => {
    return (
        <Button onClick={onClick} variant="iconButton" buttonSize="smDense" color="grey.400">
            <Icon icon="x" />
        </Button>
    )
})

/**
 *
 * @param {*} props
 * @returns
 */
export const LinkModalLayout = ({
    title,
    isOpen,
    onClose,
    size = '370px',
    showCloseButton = true,
    scrollBehavior = 'inside',
    children,
    isCentered = true,
    height,
    action,
    isHidden,
    legacy = false,
    isSecondLayer = false,
    minHeight = '58%',
    overflowY = 'auto',
    modalProps = {},
    containerStyles = {},
}) => {
    return (
        <Modal motionPreset="slideInBottom" in={isOpen} duration={400}>
            {(styles) => (
                <Modal
                    size={Array.isArray(size) ? size : ['95%', null, null, size]}
                    scrollBehavior={scrollBehavior}
                    isCentered={isCentered}
                    isOpen={!isHidden}
                    onClose={onClose}
                    {...modalProps}
                    blockScrollOnMount={false}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay
                        style={{ zIndex: isSecondLayer ? 1400 : 1300 }}
                        opacity={styles.opacity}
                    />
                    <ModalContent
                        overflowY={overflowY}
                        rounded="lg"
                        height={height && ['95%', null, null, height]}
                        minHeight={legacy ? '65%' : minHeight}
                        maxHeight={'95%'}
                        {...styles}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                paddingTop: '1.5rem',
                                paddingLeft: '2.5rem',
                                paddingRight: '2.5rem',
                                paddingBottom: '2.5rem',
                                ...containerStyles,
                            }}
                        >
                            <Flex wrap="nowrap" justify="space-between" mt={-3} mr={-6}>
                                <Text variant="modalTitle">{title}</Text>

                                {showCloseButton && <CloseButton onClick={onClose} />}
                            </Flex>
                            {children}
                            {action && (
                                <ModalFooter marginTop={10} padding={0}>
                                    {action}
                                </ModalFooter>
                            )}
                        </div>
                    </ModalContent>
                </Modal>
            )}
        </Modal>
    )
}
