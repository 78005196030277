// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react'
import { useForm, useFormContext, UseFormReturn } from 'react-hook-form'

import { useAppContext } from 'app/AppContext'
import useConfirmModal from 'app/ConfirmModal'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateFields, useField } from 'data/hooks/fields'
import { invalidateNavigation } from 'data/hooks/navigation'
import { useObject, useObjects } from 'data/hooks/objects'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { invalidatePages } from 'data/hooks/pages'
import { useRecords } from 'data/hooks/records'
import { useStackRoles } from 'data/hooks/roleHelpers'
import {
    SharingSettingsPatch,
    useSyncUsers,
    useUpdateStackOptions,
    useUpdateStackSharingSettings,
} from 'data/hooks/stacks'
import {
    useCreateUserList,
    useDeleteUserList,
    useUpdateUserList,
    useUserLists,
} from 'data/hooks/userLists'
import { refetchAppUsersForAdmin, useAddWorkspaceUser } from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { invalidateViews } from 'data/hooks/views'
import { assertIsDefined } from 'data/utils/ts_utils'
import ConditionalRoles from 'features/AppSettings/CustomerAccess/ConditionalRoles'
import { getFilterFields } from 'features/records/components/logic/recordLogic'
import {
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import RolePicker from 'features/studio/ui/RolePicker'
import ObjectLabel from 'features/utils/ObjectLabel'
import { Divider, SettingLabel, SettingRow } from 'features/workspace/WorkspaceSettingsModalUi'

import {
    Banner,
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    LoadingScreen,
    ScrollBox,
    Text,
    ToggleButton,
} from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { FormWrapper } from '../../../ui/forms/Form'
import { FormField } from '../../../ui/forms/FormField'
import SubmitButton from '../../../ui/forms/SubmitButton'
import { isFormDirty } from '../../../ui/forms/useIsFormDirty'
import { decodeFieldKey, encodeFieldKey } from '../../../ui/forms/utils'
import { PlaceholderBox } from '../shared/AppUsersUI'

import { UserTableFormData, UserTableOptions } from './userTableTypes'
import UserTableUserList from './UserTableUserList'

type EditUserTableModalProps = {
    userList?: UserListDto
    isOpen: boolean
    onClose: () => void
    defaultValues?: UserTableOptions
}

type Progress = { progress?: number; text?: string }
export const EditUserTableModal: React.FC<EditUserTableModalProps> = (props) => {
    const { selectedStack } = useAppContext()
    const updateStackOptions = useUpdateStackOptions()
    const showRoles = selectedStack?.options?.roles__enabled
    // If this is generating old style v3 auths, then we don't allow changing
    // out of automatic mode.
    const forceAutomaticMode = selectedStack?.options?.sync_users_as_auths
    const { data: roles } = useStackRoles()
    const { data: objects } = useObjects()
    const { data: userLists = [] } = useUserLists()
    const { mutateAsync: createList } = useCreateUserList()
    const { mutateAsync: updateList } = useUpdateUserList()
    const { mutateAsync: deleteList } = useDeleteUserList()
    const updateStackSharingSettings = useUpdateStackSharingSettings()
    const defaultUserRole = roles.find(
        (x) => x._sid === selectedStack?.options?.roles__default_user_role
    )
    const syncUsers = useSyncUsers()
    const [saveProgress, setSaveProgress] = useState<Progress | undefined>()
    const [saveError, setSaveError] = useState('')
    // select the default user role by default
    const defaultValues: UserTableFormData = {
        object_id: '',
        email_field_id: '',
        options: {
            mode: !forceAutomaticMode ? 'manual' : 'automatic',
            role: defaultUserRole?.api_name,
            ...props.defaultValues,
        },
        items: {},
    }

    const formContext = useForm<UserTableFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: { ...defaultValues, ...props.userList },
    })

    const createMissingRecords = useCreateMissingRecords(formContext)
    const createNewUsers = useCreateNewUsers(formContext)
    const values = formContext.watch()
    const { object } = useObject(values.object_id)

    const previousObjectId = useRef(values.object_id)
    const isCreating = !props.userList

    const updateSaveProgress = (value: Progress): Promise<void> => {
        const promise = new Promise<void>((resolve) => {
            setSaveProgress((existing) => ({ ...existing, ...value }))
            setTimeout(resolve, 100)
        })

        return promise
    }
    const { show: handleDelete } = useConfirmModal({
        message: (
            <>
                <Text>Are you sure you want to disconnect this user table?</Text>
            </>
        ),
        onConfirm: async (modal) => {
            assertIsDefined(props.userList)
            await deleteList(props.userList._sid)
                .then(() => {
                    if (selectedStack) {
                        updateStackOptions(selectedStack, {
                            open_sign_up: { user_list: null },
                            whitelist_new_users: true,
                            sharing_link_export: false,
                            data_mapping: {
                                ...selectedStack?.options.data_mapping,
                                sharing_link_field: null,
                            },
                        })
                    }
                })
                .then(() => syncUsers())
                .then(() => {
                    invalidateFeatures()
                    invalidateViews()
                    invalidateNavigation()
                    invalidatePages()
                    invalidateObjects()
                    return refetchAppUsersForAdmin()
                })
            modal.toggle()
        },
    })

    const validateTableTaken = (tableId) => {
        if (isCreating && userLists.find((l) => l.object_id === tableId)) {
            return 'Customer list already created for this table'
        }
        return true
    }

    const saveList = (patch) => {
        let promise
        if (props.userList) {
            promise = updateList({
                id: props.userList._sid,
                patch: {
                    ...patch,
                    options: {
                        ...props.userList.options,
                        ...patch.options,
                        filters_formatted: formatFilters(patch.options?.filters || []),
                    },
                    skip_users_sync: true,
                },
            })
        } else {
            const obj = objects?.find((o) => o._sid === patch.object_id)
            if (obj) {
                patch.name = obj.name
                patch.options.filters_formatted = formatFilters(patch.options?.filters)
                promise = createList({ ...patch, skip_users_sync: true }).then((result) => {
                    // refetch the field definitions, as an automatic
                    // relationship field matching this customer list
                    // may have been created on the Stacker users table.
                    // also, invalidate the nav related items as the stacker users
                    // table will now be hidden
                    invalidateFields()
                    invalidateFeatures()
                    invalidateViews()
                    invalidateNavigation()
                    invalidatePages()
                    invalidateObjects()
                    return result as UserListDto
                })
            } else {
                promise = Promise.reject()
            }
        }

        return promise as Promise<UserListDto>
    }

    const handleSave = (patch: UserTableFormData) => {
        setSaveError('')
        if (!isFormDirty(formContext)) {
            props.onClose()
            return
        }
        assertIsDefined(selectedStack)
        updateSaveProgress({ progress: 5, text: 'Saving changes...' })
        return saveList(patch)
            .then((userList: UserListDto) => {
                updateSaveProgress({
                    progress: 10,
                    text: 'Saving changes...',
                })
                return userList
            })

            .then((userList) => {
                if (selectedStack) {
                    updateStackOptions(selectedStack, {
                        open_sign_up: { user_list: userList._sid },
                    })
                }
            })
            .then(() => {
                if (patch.options.mode === 'manual') {
                    // decode the group/userIds from the patch
                    const updatedSharingSettings: SharingSettingsPatch = Object.keys(
                        patch.items
                    ).reduce((result, key) => {
                        result[decodeFieldKey(key)] = patch.items[key]
                        return result
                    }, {})

                    return createMissingRecords(updatedSharingSettings, updateSaveProgress, 10, 50)
                        .then(() => {
                            return createNewUsers(
                                updatedSharingSettings,
                                updateSaveProgress,
                                50,
                                90
                            ).then((recordUserDict) => {
                                // We get a dictionary of record ids to newly created user ids.
                                // We need to update the updatedSharingSettings dictionary to replace
                                // the record is with the new user id so in the updateStackSharingSettings call below,
                                // these new users are correctly shared with.
                                Object.keys(recordUserDict).forEach((key) => {
                                    updatedSharingSettings[recordUserDict[key]] =
                                        updatedSharingSettings[key]
                                    delete updatedSharingSettings[key]
                                })
                            })
                        })
                        .then(() =>
                            updateSaveProgress({
                                progress: 90,
                                text: 'Setting up users...',
                            })
                        )
                        .then(() => {
                            return updateStackSharingSettings(
                                selectedStack,
                                updatedSharingSettings,
                                {
                                    skip_users_sync: true,
                                }
                            )
                        })
                }
            })
            .then(() => {
                updateSaveProgress({
                    text: 'Setting up users...',
                })
            })
            .then(() => syncUsers())
            .then(() => updateSaveProgress({ progress: 95, text: 'Refreshing...' }))
            .then(refetchAppUsersForAdmin)
            .then(() => updateSaveProgress({ progress: 100, text: 'Finished!' }))
            .then(() => {
                props.onClose()
            })
            .catch((err) => {
                setSaveError(
                    'An error occured while saving. Please try again or contact support for assistance.'
                )
                throw err
            })
            .finally(() => setSaveProgress(undefined))
    }

    // Clear the filters if the user changes objects
    useEffect(() => {
        if (previousObjectId.current !== values.object_id) {
            formContext.setValue('options.filters', [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.object_id, formContext.setValue])

    const hideAddFilter =
        selectedStack?.options?.enable_open_signup_for_stacker_users &&
        !(props.userList?.options?.filters?.length > 0)

    return (
        <SimpleModalCompat
            isOpen={props.isOpen}
            onClose={props.onClose}
            title="Setup user table"
            height="80%"
            size="800px"
            isSecondLayer
        >
            <LoadingScreen
                isLoading={!!saveProgress}
                progress={saveProgress?.progress}
                loadingText={saveProgress?.text}
                keepChildrenMounted
                showProgressBar
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                    maxHeight: '100%',
                }}
                size="sm"
            >
                <FormWrapper
                    formContext={formContext}
                    onSubmit={handleSave}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minHeight: 0,
                        maxHeight: '100%',
                        marginTop: '8px',
                    }}
                >
                    {/* @ts-ignore */}
                    <ScrollBox
                        overflowY="auto"
                        hideScrollbars={!!saveProgress}
                        flexGrow={1}
                        scrollTrackSideMargin={6}
                        display="flex"
                        flexDirection="column"
                        flexWrap="nowrap"
                    >
                        <Flex column flexGrow={1} align="stretch" wrap="nowrap">
                            <SettingRow
                                leftSide={<SettingLabel>User table</SettingLabel>}
                                rightSide={
                                    <Flex wrap="nowrap" align="stretch" width="100%">
                                        <FormField
                                            as={ObjectPicker}
                                            name="object_id"
                                            placeholder="Select table"
                                            variant="settings"
                                            filter={(obj) =>
                                                obj.connection_options?.simpleconn_object !==
                                                'STACKER_USERS'
                                            }
                                            disabled={!isCreating}
                                            required
                                            errorMessages={{
                                                required: 'Please select a table',
                                            }}
                                            errorMessageStyle={{
                                                marginBottom: '4px',
                                            }}
                                            registerOptions={{
                                                validate: {
                                                    validateTableTaken,
                                                },
                                            }}
                                            controlled
                                            containerStyle={{ flexGrow: 1 }}
                                        />
                                        {!isCreating && (
                                            <Button
                                                variant="adminSecondaryV4NoHeight"
                                                buttonSize="smNoHeight"
                                                onClick={handleDelete}
                                                height="auto"
                                            >
                                                Disconnect
                                            </Button>
                                        )}
                                    </Flex>
                                }
                            />
                            <SettingRow
                                leftSide={<SettingLabel>Email addresses are in field</SettingLabel>}
                                rightSide={
                                    <FormField
                                        as={FieldPicker}
                                        name="email_field_id"
                                        variant="settings"
                                        required
                                        controlled
                                        filter={(field) =>
                                            !field.connection_options?.is_disabled &&
                                            ['string', 'copy'].includes(field.type)
                                        }
                                        disabled={!isCreating}
                                        errorMessages={{
                                            required: 'Please select a field',
                                        }}
                                        placeholder="Select field"
                                        objectId={values?.object_id}
                                    ></FormField>
                                }
                            />
                            {!forceAutomaticMode && (
                                <SettingRow
                                    containerStyle={{
                                        marginTop: '8px',
                                    }}
                                    leftSideStyle={{
                                        alignSelf: 'start',
                                    }}
                                    leftSide={<SettingLabel>Access to app</SettingLabel>}
                                    rightSide={
                                        <FormField
                                            name="options.mode"
                                            as={SelectMode}
                                            controlled
                                            isDisabled={!values.email_field_id}
                                        />
                                    }
                                />
                            )}
                            <Collapse
                                isOpen={
                                    !!(values.options?.mode !== 'manual') && !!values.email_field_id
                                }
                            >
                                <SettingRow
                                    leftSide={<SettingLabel>Users can access if</SettingLabel>}
                                    leftSideStyle={{
                                        alignSelf: 'start',
                                    }}
                                    rightSide={
                                        !hideAddFilter ? (
                                            <FormField
                                                as={Filters}
                                                name="options.filters"
                                                controlled
                                                key={values.object_id}
                                                object={object}
                                                fields={object?.fields}
                                                hideCurrentUserOption
                                                hideTheRecordFilter
                                                overhangOperators
                                                closeOnOuterAction={true}
                                            />
                                        ) : (
                                            <Banner icon="info" mt={2}>
                                                User table filters cannot be used with open signup.
                                                If you would like to add a user table filter, please
                                                disable open signup.
                                            </Banner>
                                        )
                                    }
                                    containerStyle={{
                                        marginTop: '8px',
                                    }}
                                />
                            </Collapse>

                            {!showRoles && (
                                <input type="hidden" {...formContext.register('options.role')} />
                            )}
                            <Collapse
                                isOpen={
                                    values.options?.mode !== 'manual' &&
                                    !!values.email_field_id &&
                                    !!showRoles
                                }
                                containerStyle={{
                                    marginTop: '8px',
                                }}
                            >
                                <Divider dense />
                                <SettingRow
                                    leftSide={<SettingLabel>Default role</SettingLabel>}
                                    rightSide={
                                        <FormField
                                            as={RolePicker}
                                            name="options.role"
                                            variant="settings"
                                            idField="api_name"
                                            required
                                            controlled
                                        ></FormField>
                                    }
                                />
                                <FormField
                                    as={ConditionalRoles}
                                    name="options.conditional_roles"
                                    variant="settings"
                                    controlled
                                    object={object}
                                    fields={object?.fields}
                                    defaultUserRole={defaultUserRole?.api_name}
                                />
                            </Collapse>

                            <Divider
                                dense
                                style={{
                                    marginTop: '12px',
                                }}
                            />
                            {values.object_id && values.email_field_id ? (
                                <UserTableUserList
                                    objectId={values.object_id}
                                    emailFieldId={values.email_field_id}
                                    filters={values.options?.filters}
                                    isNewList={isCreating}
                                />
                            ) : (
                                <UserListPlaceholder />
                            )}
                        </Flex>
                    </ScrollBox>
                    {values.object_id &&
                        values.email_field_id &&
                        values.options.mode === 'manual' && <UsersBeingAddedNotice />}
                    <Collapse isOpen={!!saveError}>
                        <Text variant="error" size="sm" mt={4}>
                            {saveError}
                        </Text>
                    </Collapse>
                    <SubmitButton
                        disabled={false}
                        variant="adminPrimaryV4"
                        buttonSize="md"
                        mt={4}
                        flexShrink={0}
                    >
                        {isCreating ? 'Connect' : 'Save'}
                    </SubmitButton>
                </FormWrapper>
            </LoadingScreen>
        </SimpleModalCompat>
    )
}

const SelectMode = ({ value, onChange, isDisabled }) => {
    const setManualMode = (value) => {
        if (value) {
            onChange('manual')
        }
    }
    const setAutomaticMode = (value) => {
        if (value) {
            onChange('automatic')
        }
    }
    return (
        <Flex>
            <Box flexBasis="50%" pr={1}>
                <ModeToggle
                    isChecked={value === 'manual'}
                    onChange={setManualMode}
                    title="Manual"
                    text="Only the users and groups you choose."
                    isDisabled={isDisabled}
                />
            </Box>
            <Box flexBasis="50%" pl={1}>
                <ModeToggle
                    isChecked={value !== 'manual'}
                    onChange={setAutomaticMode}
                    title="Automatic"
                    text="All or filtered records in the user table."
                    isDisabled={isDisabled}
                />
            </Box>
        </Flex>
    )
}
const ModeToggle = ({ isChecked, onChange, title, text, isDisabled }) => (
    <ToggleButton isChecked={isChecked} onChange={onChange} px={3} py={2} isDisabled={isDisabled}>
        <Flex mb={2} align="center" justify="space-between">
            <Text size="sm" fontWeight="bold" color="unset">
                {title}
            </Text>
            {/* @ts-ignore */}
            {isChecked && (
                <Icon
                    icon="checkmarkCircleSolid"
                    color={isDisabled ? 'neutral.600' : 'v4Blue'}
                    size="sm"
                />
            )}
        </Flex>

        <Text size="sm" color="unset">
            {text}
        </Text>
    </ToggleButton>
)

const UserListPlaceholder = () => {
    return (
        <PlaceholderBox>
            <Icon icon="users" size="32px" color="neutral.500" mb={3} />
            <Text color="neutral.700" textAlign="center">
                Users who will get access <br /> will appear here.
            </Text>
        </PlaceholderBox>
    )
}

const useCreateNewUsers = (formContext: UseFormReturn<UserTableFormData>) => {
    const values = formContext.watch()
    const emailField = useField(values.email_field_id)
    const filterFields = getFilterFields(values.options?.filters)
    const { data: { records } = {}, refetch: refetchRecords } = useRecords({
        objectId: values.object_id,
        fetchOptions: emailField
            ? { includeFields: [emailField.api_name, ...filterFields] }
            : undefined,
    })
    const { data: existingUsers } = useAppUsersForAdmin(true)
    const { mutateAsync: addUser } = useAddWorkspaceUser()

    const createUsers = (
        items: SharingSettingsPatch,
        updateProgress: (progress: Progress) => void,
        startProgressPercent: number,
        endProgressPercent: number
    ): Promise<{ [key: string]: string }> => {
        // get a list of all the users of our app who have added to the sharing
        // settings either individually or via a group
        const usersSharedWith = existingUsers.filter((user) => user.specified_role)

        const usersToCreate = records.reduce((result, record) => {
            const email = emailField ? record[emailField.api_name]?.toLowerCase() : ''
            // if this record has an email address and has been marked
            // as not-deleted, and we don't already have a user for the app
            // with this email, then the user is wanting to add this record to the
            // app as a user
            if (
                email &&
                items?.[record._sid]?.deleted === false &&
                !usersSharedWith.find((user) => user.email?.toLowerCase() === email)
            ) {
                result[record._sid] = email
            }
            return result
        }, {})

        const countOfRecords = Object.keys(usersToCreate).length
        if (countOfRecords) {
            let completed = 0
            const promises = Object.keys(usersToCreate).map((key) => {
                return addUser({
                    email: usersToCreate[key],
                    options: { role: 'guest' },
                    send_email: false,
                    validate_email: false,
                }).then((user) => {
                    usersToCreate[key] = user._sid
                    completed++
                    updateProgress({
                        progress:
                            (completed / countOfRecords) * endProgressPercent +
                            startProgressPercent,
                        text: `Created ${completed} of ${countOfRecords} new user${
                            countOfRecords > 1 ? 's' : ''
                        }...`,
                    })
                })
            })

            return Promise.all(promises).then(() => {
                // Don't return this promise as we don't need to wait for
                // it we just want to kick off a refresh of the records.
                refetchRecords()
                return usersToCreate
            })
        } else {
            updateProgress({ progress: endProgressPercent })

            return Promise.resolve(usersToCreate)
        }
    }

    return createUsers
}
const useCreateMissingRecords = (formContext: UseFormReturn<UserTableFormData>) => {
    const values = formContext.watch()
    const { object, createRecord } = useObject(values.object_id)
    const emailField = useField(values.email_field_id)
    const { data: { records } = {}, refetch: refetchRecords } = useRecords({
        objectId: values.object_id,
        fetchOptions: emailField ? { includeFields: [emailField.api_name] } : undefined,
    })
    const { data: users } = useAppUsersForAdmin(true)

    const createRecords = (
        items: SharingSettingsPatch,
        updateProgress: (progress: Progress) => void,
        startProgressPercent: number,
        endProgressPercent: number
    ): Promise<void> => {
        const recordsToCreate = users.reduce((result, user) => {
            const email = user.email.toLowerCase()
            const isUserDisabled = !!user.group
                ? items?.[user.group]?.deleted
                : items?.[user._sid]?.deleted
            if (
                user.role !== 'internal_admin' &&
                isUserDisabled === false &&
                emailField &&
                !records.find((record) => record[emailField.api_name]?.toLowerCase() === email)
            ) {
                result[user._sid] = email
            }
            return result
        }, {})

        const countOfRecords = Object.keys(recordsToCreate).length
        if (countOfRecords) {
            updateProgress({
                text: `Adding ${countOfRecords} user${countOfRecords > 1 ? 's' : ''} to ${
                    object?.name
                } table...`,
            })
            let completed = 0
            const promises = emailField
                ? Object.keys(recordsToCreate).map((key) => {
                      return createRecord({
                          [emailField.api_name]: recordsToCreate[key],
                          // @ts-ignore
                      }).then(() => {
                          completed++
                          updateProgress({
                              progress:
                                  (completed / countOfRecords) * endProgressPercent +
                                  startProgressPercent,
                              text: `Added ${completed} of ${countOfRecords} user${
                                  countOfRecords > 1 ? 's' : ''
                              } to ${object?.name} table...`,
                          })
                      })
                  })
                : []

            return Promise.all(promises).then(() => {
                // Don't return this promise as we don't need to wait for
                // it we just want to kick off a refresh of the records.
                refetchRecords()
            })
        } else {
            updateProgress({ progress: endProgressPercent })

            return Promise.resolve()
        }
    }

    return createRecords
}

function UsersBeingAddedNotice() {
    const { watch } = useFormContext()
    const values = watch()
    const emailField = useField(values.email_field_id)
    const emailFieldApiName = emailField?.api_name
    const { data: { records } = {} } = useRecords({
        objectId: values.object_id,
        fetchOptions: emailFieldApiName ? { includeFields: [emailFieldApiName] } : undefined,
    })
    const { data: users } = useAppUsersForAdmin(true, undefined)
    const items = values.items

    const recordsToCreate = users.filter((user) => {
        const email = user.email.toLowerCase()
        const isUserDisabled = !!user.group
            ? items?.[user.group]?.deleted
            : items?.[encodeFieldKey(user._sid)]?.deleted
        return (
            isUserDisabled === false &&
            emailFieldApiName &&
            !records?.find((record) => record[emailFieldApiName]?.toLowerCase() === email)
        )
    })

    return (
        <Collapse isOpen={recordsToCreate.length > 0} flexGrow={1}>
            <Box pt={2}>
                <Icon icon="addCircle" size="small" color="alertIcon" mr={2} display="inline" />
                <Text display="inline">
                    {recordsToCreate.length} new record
                    {recordsToCreate.length > 1 ? 's' : ''} will be created in
                    <ObjectLabel objectId={values.object_id} ml={2} fontWeight="bold" />
                </Text>
            </Box>
        </Collapse>
    )
}

export default EditUserTableModal
