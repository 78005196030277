import React, { CSSProperties, FC } from 'react'

import { ButtonProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { getAreRolesEnabled } from 'features/utils/getAreRolesEnabled'

import { ConditionalWrapper } from 'v2/ui'

import { AddFilterButton } from '../AddFilterButton'
import { InlineLabel } from '../InlineLabel'
import { RecordFiltersProvider } from '../provider/RecordFiltersProvider'

import { FiltersHeader } from './components/FiltersHeader'
import { FieldsFilters } from './FieldsFilters'
import { SecurityInfoMessage } from './SecurityInfoMessage'

const FieldsFiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

type Props = {
    object: ObjectDto | undefined
    fields: FieldDto[] | undefined
    value: Filter[] | undefined
    onChange: (filters: Filter[]) => void
    isCreate?: boolean
    hideTheRecordFilter?: boolean
    hideCurrentUserOption?: boolean
    showRoleFilter?: boolean
    hideAddButton?: boolean
    overhangOperators?: string[]
    wrapItems?: boolean
    itemStyle?: CSSProperties
    buttonLabel?: string
    prefix?: boolean
    color?: string
    addButtonVariant?: string
    withHeader?: boolean
    includeIncompleteFilters?: boolean
    closeOnOuterAction?: boolean
    place?: string
    usePortal?: boolean
    securityInfoMessage?: string
    showRelativeDateFilters?: boolean
    contextRecordObject?: ObjectDto
    buttonProps?: ButtonProps
}

export const ObjectFieldsFilterV4: FC<Props> = ({
    object,
    fields,
    isCreate,
    hideTheRecordFilter,
    hideCurrentUserOption,
    showRoleFilter,
    onChange,
    value = [],
    hideAddButton,
    overhangOperators,
    wrapItems = false,
    itemStyle = {},
    buttonLabel,
    prefix,
    color = 'gray',
    addButtonVariant = 'adminTertiary',
    withHeader = false,
    includeIncompleteFilters = false,
    closeOnOuterAction = false,
    place,
    usePortal = true,
    securityInfoMessage,
    showRelativeDateFilters = false,
    contextRecordObject,
    buttonProps = {},
}) => {
    const { selectedStack } = useAppContext()

    const areRolesEnabled = getAreRolesEnabled(selectedStack)

    let filters = value

    // Filter out role filters if roles are not enabled
    if (!areRolesEnabled && value.length > 0) {
        filters = filters.filter((filter) => filter?.field?.type !== 'user_role')
    }

    const shouldShowHeader = withHeader && selectedStack && selectedStack?.options?.workspace_app

    return (
        <RecordFiltersProvider
            initialFilters={filters}
            object={object}
            includeIncompleteFilters={includeIncompleteFilters}
            onChange={onChange}
        >
            {shouldShowHeader && <FiltersHeader />}
            <SecurityInfoMessage
                message={securityInfoMessage}
                shouldShowHeader={shouldShowHeader}
            />
            <ConditionalWrapper
                condition={wrapItems}
                wrapper={(children) => <FieldsFiltersWrapper>{children}</FieldsFiltersWrapper>}
            >
                {prefix && filters.length === 0 && <InlineLabel mb="5px">{prefix}</InlineLabel>}
                <FieldsFilters
                    color={color}
                    place={place}
                    prefix={prefix}
                    usePortal={usePortal}
                    wrapItems={wrapItems}
                    itemStyle={itemStyle}
                    overhangOperators={overhangOperators}
                    closeOnOuterAction={closeOnOuterAction}
                    hideCurrentUserOption={hideCurrentUserOption}
                    showRelativeDateFilters={showRelativeDateFilters}
                    contextRecordObject={contextRecordObject}
                />
            </ConditionalWrapper>
            {!hideAddButton && (
                <AddFilterButton
                    object={object}
                    fields={sortFieldsByOrder(fields, object)}
                    isCreate={isCreate}
                    hideTheRecordFilter={hideTheRecordFilter}
                    showRoleFilter={showRoleFilter}
                    hideCurrentUserOption={hideCurrentUserOption}
                    alignSelf="start"
                    label={buttonLabel}
                    addButtonVariant={addButtonVariant}
                    usePortal={usePortal}
                    contextRecordObject={contextRecordObject}
                    {...buttonProps}
                />
            )}
        </RecordFiltersProvider>
    )
}
