const radii = (config) => {
    const { chakraTheme } = config

    return {
        xl: chakraTheme.radii.lg,
        lg: '0.375rem',
        input: '6px',
        button: '6px',
        admin: { input: '6px' },
    }
}

export default radii
