/* Code Quality: Good */

import React from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import { withStack } from 'data/wrappers/WithStacks'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import ThemeWrapper from 'features/pages/blocks/styles/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import { Heading, Section } from 'legacy/v1/ui'

import { Button, Input, Text } from 'v2/ui'

import { CenterPanel, CenterPanelWrapper } from '../studio/ui/Layout'

import { PasswordApi } from './utils/index'

class UserSetPasswordPage extends React.Component {
    state = {}

    constructor(props) {
        super(props)
        this.state.authToken = this.props.match.params.authToken
    }

    changePassword = (e) => {
        e.preventDefault()
        const { authToken } = this.props.match.params
        const { newPassword } = this.state
        if (!newPassword || newPassword.length < 8) {
            this.setState({ passwordTooShort: true })
            return
        } else if (newPassword && newPassword.length >= 8) {
            this.setState({ passwordTooShort: false })
        }
        this.setState({ processing: true }, () => {
            PasswordApi.endUserResetPassword(authToken, newPassword).then((rawResponse) => {
                rawResponse.json().then((response) => {
                    if (response.detail === 'Unable to find auth token') {
                        this.setState({ invalidToken: true, processing: false })
                        return
                    }
                    const token = response.api_token
                    if (!token) {
                        this.setState({ error: true, processing: false })
                        return
                    }

                    // Reset password can return endUser or admin users
                    if (response._sid.startsWith('user.')) {
                        localStorage.setItem('studio_user', JSON.stringify(response))
                        localStorage.setItem('studio_token', token)

                        window.location = getUrl(Urls.Admin)
                    } else {
                        localStorage.setItem('user_token', token)
                        localStorage.setItem('user', JSON.stringify(response))

                        window.location = '/'
                    }
                })
            })
        })
    }

    render() {
        const logo = getLogoUrl(this.props.stack, true)

        return (
            <>
                <DefaultHeaderMeta title="Set your password" />
                <ThemeWrapper loadFromStack>
                    {() => (
                        <CenterPanelWrapper>
                            <CenterPanel>
                                {this.state.authToken && (
                                    <form>
                                        <Section margin="large" padding="none" centered>
                                            <Logo src={logo} />
                                        </Section>
                                        <Section centered>
                                            <Heading size="medium" margin="none">
                                                Set your password
                                            </Heading>
                                        </Section>
                                        <Section margin="medium" padding="none">
                                            <Input
                                                onChange={(e) =>
                                                    this.setState({ newPassword: e.target.value })
                                                }
                                                padding="medium"
                                                placeholder="Enter a new password..."
                                                type="password"
                                            />
                                        </Section>
                                        <Button
                                            onClick={this.changePassword}
                                            width="100%"
                                            variant="primary"
                                            buttonSize="md"
                                            type="submit"
                                            isDisabled={this.state.processing}
                                        >
                                            {this.state.processing ? 'Saving' : 'Save'}
                                        </Button>{' '}
                                        {this.state.passwordTooShort && (
                                            <Text style={{ color: 'red' }}>
                                                Your new password must be at least 8 characters
                                                long.
                                            </Text>
                                        )}
                                        {this.state.invalidToken && (
                                            <Text variant="error" mt={4}>
                                                Unable to set your password, your link has expired.
                                            </Text>
                                        )}
                                    </form>
                                )}
                                {!this.state.authToken && (
                                    <Text>This isn&apos;t a valid link.</Text>
                                )}
                            </CenterPanel>
                        </CenterPanelWrapper>
                    )}
                </ThemeWrapper>
            </>
        )
    }
}

UserSetPasswordPage.propTypes = {
    history: PropTypes.object.isRequired, // From withRouter
    match: PropTypes.object.isRequired, // From withRouter
    stackOptions: PropTypes.object.isRequired, // From withStack
}

const Logo = styled('img')`
    height: 60px;
    object-fit: contain;
    max-width: 90%;

    @media (max-width: 420px) {
        height: 35px;
    }
`

export default withStack(withRouter(UserSetPasswordPage))
