import React, { ChangeEvent, FC, useMemo } from 'react'

import { Input } from 'v2/ui'

import type { AdminFilterProps } from './types'

export const FilterText: FC<AdminFilterProps> = ({
    value,
    onChange,
    field,
    editMode = true,
    ...props
}) => {
    const inputValue = useMemo(() => {
        if (field?.type !== 'percentage') {
            return value
        }

        const parsedValue = parseFloat(value as string)
        if (isNaN(parsedValue) || parsedValue === 0) {
            return value
        }

        return parsedValue * 100
    }, [field?.type, value])

    const formattedValue = useMemo(() => {
        switch (field?.type) {
            case 'currency':
                return `${value || ''}${field.options?.currency_symbol}`
            case 'percentage':
                return `${inputValue}%`
            default:
                return value || ''
        }
    }, [field?.options?.currency_symbol, field?.type, inputValue, value])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value: newValue },
        } = event

        if (
            field?.type !== 'percentage' ||
            isNaN(parseFloat(newValue)) ||
            parseFloat(newValue) === 0
        ) {
            onChange?.(newValue)
            return
        }

        onChange?.(parseFloat(newValue) / 100)
    }

    // If we're not in edit mode, then just display the selected values
    // not the input control
    if (!editMode) {
        return <>{formattedValue}</>
    }

    return (
        <Input
            {...props}
            onChange={handleInputChange}
            value={inputValue || ''}
            style={{ flex: 1 }}
        />
    )
}
