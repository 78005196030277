import React from 'react'

import { ActionListItem } from 'v2/ui/components/ActionList'

import { IMAGE_FIELD_TYPES } from '../../../data/utils/fieldDefinitions'
import { RecordActionsTableCell } from '../List/RecordActionsTableCell'
import { RemoveRecordTableCell } from '../List/RemoveRecordTableCell'
import { TableCell } from '../List/TableCell'
import { ViewCellBaseProps, ViewColumn } from '../List/types'
import { ViewState } from '../List/viewStateType'

import { getFilter } from './getFilter'
import { getIcon } from './getIcon'

function getHeaderStyles(field: FieldDto, index: number) {
    const style: React.CSSProperties = {}
    if (field.type === 'image') {
        style.width = 35
        if (index === 0) {
            style.opacity = 0
        }
    }

    return style
}

function getThumbnail(row: RecordDto, coverImageField: string): string | null {
    const files = row[coverImageField]
    if (!files) return null

    const isFilesArray = Array.isArray(files)

    const thumbnail = isFilesArray ? files[0]?.thumbnails?.large?.url : files
    if (!thumbnail) return isFilesArray ? files[0]?.url : null

    return thumbnail
}

const imageFieldTypes = new Set(IMAGE_FIELD_TYPES)

function getCoverImageFieldColumns(fields: FieldDto[], viewState?: ViewState): ViewColumn[] {
    const coverImageField = viewState?.view?.options?.coverImage?.id
    if (!coverImageField) return []

    // We look for the thumbnail on the list of all the fields, so we can display it even if it is disabled (not the first 4 by default)
    return fields.reduce<ViewColumn[]>((agg, curr) => {
        if (
            curr.api_name === coverImageField &&
            !curr.connection_options?.is_disabled &&
            imageFieldTypes.has(curr.type)
        ) {
            agg.push({
                Header: 'Cover Image',
                id: 'stackCoverImageSetting',
                accessor: (row) => getThumbnail(row, coverImageField as string),
                field: curr,
                type: curr.type,
                Cell: 'Placeholder',
                show: false,
                fitImage: viewState?.view?.options?.fitCoverImage,
            })
        }

        return agg
    }, [])
}

export const getColumns = (params: {
    columnConfig: ListViewColumnConfig[]
    fieldsToHideNames: string[]
    fieldsToHideIDs: string[]
    objectFields: FieldDto[]
    getRecordActions?: (record: RecordDto) => ActionListItem[]
    onRemoveItem?: (sid: string) => void
    viewState?: ViewState
}): ViewColumn[] => {
    const {
        columnConfig,
        fieldsToHideNames,
        fieldsToHideIDs,
        objectFields,
        getRecordActions,
        onRemoveItem,
        viewState,
    } = params

    const display = viewState?.view?.options?.display ?? 'table'

    const fieldsToHideNamesSet = new Set(fieldsToHideNames)
    const fieldsToHideIDsSet = new Set(fieldsToHideIDs)

    const fieldsByID = objectFields.reduce<Map<string, FieldDto>>(
        (agg, curr) => agg.set(curr._sid, curr),
        new Map()
    )

    const filteredColumns = columnConfig.filter((col) => {
        const field = fieldsByID.get(col.fieldId)
        return (
            col.selected &&
            field &&
            !field.connection_options?.is_disabled &&
            !fieldsToHideNamesSet.has(col.fieldApiName) &&
            !fieldsToHideIDsSet.has(col.fieldId)
        )
    })

    // The table has a scrollbar per default, we can have a consistent max-width that doesn't depend on number of fields
    const optimizedLayout = viewState?.view?.options?.optimizedLayout ?? false
    const maxColumnWidth = optimizedLayout ? 350 : 1000 / filteredColumns.length

    const columns = filteredColumns.map<ViewColumn>((col, index) => {
        const field = fieldsByID.get(col.fieldId)!

        return {
            headerStyles: getHeaderStyles(field, index),
            Header: col.label || field.label,
            accessor: field.api_name,
            id: field.api_name,
            type: field.type,
            index,
            field,
            display,
            maxColumnWidth,
            Cell: TableCell,
            filter: getFilter(field.type),
            icon: getIcon(field.type),
            renderOptions: col,
        }
    })

    // Add in a cover field column if one has been selected
    columns.push(...getCoverImageFieldColumns(objectFields, viewState))

    // If actions are set in the props, then show an action menu
    if (getRecordActions) {
        columns.push({
            accessor: 'id',
            type: 'string',
            Header: '',
            width: 50,
            maxWidth: 50,
            Cell: (cellProps: ViewCellBaseProps) => {
                return RecordActionsTableCell({ ...cellProps, getRecordActions })
            },
        })
    }

    // If onRemoveItem is set in the props, then add a final column with a delete icon which calls it.
    if (onRemoveItem) {
        columns.push({
            accessor: 'id',
            type: 'string',
            Header: '',
            width: 50,
            maxWidth: 50,
            Cell: (cellProps: ViewCellBaseProps) => {
                return RemoveRecordTableCell({ ...cellProps, onRemoveItem })
            },
        })
    }
    return columns
}
