import React, { useState } from 'react'

import { Switch } from '@chakra-ui/react'
import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'
import ProfileCardEditor from 'v2/views/Detail/ProfileCardEditor'
import TabsSelector from 'v2/views/DetailsTabsSelector'
import MenuToggle from 'v2/views/MenuToggle'

import { recordDeletionAvailable } from 'data/utils/utils'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import CoverImage from 'features/pages/blocks/settings/attributes/items/form/CoverImage'

import { Box, Dropdown, Flex, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import { RecordHeaderControls } from './RecordHeader'

export const SYSTEM_BUTTONS = [
    { _sid: 'edit', name: 'Edit' },
    { _sid: 'delete', name: 'Delete' },
]
const DetailViewControls = ({
    stack,
    config,
    setConfig,
    object,
    showCommentsOption,
    showActionButtonsOption,
    showTabsOption,
}) => {
    // Only show the delete button for 'Stacker Data' native tables
    const systemButtons = recordDeletionAvailable(object, stack)
        ? SYSTEM_BUTTONS
        : SYSTEM_BUTTONS.filter((b) => b._sid !== 'delete')

    const [initialConfig] = useState({ ...config })
    const tabsEnabled = !isFeatureLocked(FEATURES.tabs, stack)
    if (!config) return ''

    const displayOptions = [
        {
            value: 'default',
            label: 'Default',
            onClick: () => {
                setConfig({
                    display: 'default',
                })
            },
        },
        {
            value: 'list',
            label: 'Listing',
            onClick: () => {
                {
                    if (!config.listImage) {
                        // If we select "list view" and no image is set, we will use the first attachement by default
                        const defaultImageField = object.fields.find(
                            (field) => field.type === 'multi_file'
                        ) // could be null
                        setConfig({
                            display: 'list',
                            listImage: defaultImageField
                                ? { id: defaultImageField.api_name }
                                : undefined,
                        })
                    } else {
                        setConfig({
                            display: 'list',
                        })
                    }
                }
            },
        },
        {
            value: 'profile',
            label: 'Profile',
            onClick: () => {
                {
                    if (!config.profileImage) {
                        // If we select profile and no image is set, we will use the first attachement by default
                        const defaultImageField = object.fields.find(
                            (field) => field.type === 'multi_file'
                        ) // could be null
                        setConfig({
                            display: 'profile',
                            profileImage: defaultImageField
                                ? { id: defaultImageField.api_name }
                                : undefined,
                        })
                    } else {
                        setConfig({
                            display: 'profile',
                        })
                    }
                }
            },
        },
    ]

    return (
        <Box pb={2}>
            <Text variant="paletteSectionLabel">Template</Text>
            <Dropdown
                adminTheme
                value={config.display || 'default'}
                options={displayOptions}
                returnObject
                isClearable={false}
                onChange={(value) => {
                    if (value) return value.onClick()
                    setConfig({
                        display: 'default',
                    })
                }}
                className={ONBOARDING_CLASSES.EDIT_LAYOUT_TEMPLATE}
            />
            <Box my={2}>
                <MenuToggle icon="profile" label="Record header" title="Record Header">
                    <RecordHeaderControls
                        objectId={object._sid}
                        headerValue={config.recordHeader}
                        onChangeHeader={(obj) => setConfig({ recordHeader: obj })}
                        profileValue={config.profileImage}
                        onChangeProfile={(val) => setConfig({ profileImage: val })}
                    />
                </MenuToggle>
                {config.display === 'list' && (
                    <MenuToggle icon="image" label="Listing Image" title="Listing Image">
                        <div id="coverImage">
                            <CoverImage
                                objectId={object._sid}
                                onChange={(listImage) =>
                                    setConfig({
                                        listImage,
                                    })
                                }
                                onChangeFit={(fitListImage) =>
                                    setConfig({
                                        fitListImage,
                                    })
                                }
                                value={config.listImage}
                                fitImage={config.fitListImage}
                                showImageDisplayOptions
                            />
                        </div>
                    </MenuToggle>
                )}
                {config.display === 'profile' && (
                    <MenuToggle
                        icon="list"
                        label="Profile fields"
                        title="Profile fields"
                        tooltipStartOpen={initialConfig.display !== 'profile'}
                        tooltipText="Click here to choose the fields for the profile card"
                        buttonStyle={{ marginTop: '-1px' }}
                    >
                        <ProfileCardEditor object={object} config={config} setConfig={setConfig} />
                    </MenuToggle>
                )}
                {showActionButtonsOption && getAreObjectRecordsEditable(object) && (
                    <MenuToggle
                        icon="cogs"
                        label="Page buttons"
                        title="Page buttons"
                        data-testid="detail-view.action-button-selector"
                        tooltipText="Choose the action buttons that are displayed on the page"
                        buttonStyle={{ marginTop: '-1px' }}
                    >
                        <ActionButtonsSelector
                            object={object}
                            selectedButtons={
                                config.pageButtons ||
                                systemButtons
                                    .filter((x) => x._sid !== 'delete')
                                    .map((x) => ({ id: x._sid }))
                            }
                            setConfig={setConfig}
                            additionalActions={systemButtons}
                        />
                    </MenuToggle>
                )}
                {showTabsOption && (
                    <MenuToggle
                        icon="tabs"
                        label="Tabs"
                        title="Tabs"
                        data-testid="detail-view.tabs-selector"
                        buttonStyle={{ marginTop: '-1px' }}
                        activityLabel={stack.options?.activity_label}
                        enableComments={config?.enableComments && showCommentsOption}
                    >
                        <TabsSelector
                            object={object}
                            blocks={config.blocks}
                            enableComments={config?.enableComments && showCommentsOption}
                            allowComments={showCommentsOption}
                            tabs={
                                config.tabs || [
                                    {
                                        name: 'Details',
                                        id: 'page',
                                        type: 'default',
                                        treeIndex: 0,
                                        active: true,
                                    },
                                    {
                                        id: 'activity',
                                        type: 'activity',
                                        name: stack.options?.activity_label || 'Activity',
                                        active: config?.enableComments && showCommentsOption,
                                    },
                                ]
                            }
                            setConfig={setConfig}
                        />
                    </MenuToggle>
                )}
            </Box>
            {showCommentsOption && !tabsEnabled && (
                <>
                    <Text variant="paletteSectionLabel">Options</Text>
                    <Flex wrap="noWrap">
                        <Text variant="paletteOptionLabel" flexGrow={1}>
                            Enable comments
                        </Text>
                        <Switch
                            mr={2}
                            isChecked={config.enableComments}
                            onChange={(e) => {
                                setConfig({
                                    enableComments: e.target.checked,
                                })
                            }}
                        />
                    </Flex>
                </>
            )}
        </Box>
    )
}

export default DetailViewControls
