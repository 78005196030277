import { Modifier } from 'react-popper'

export const autoSizeModifier: Modifier<'autoSize', {}> = {
    name: 'autoSize',
    enabled: true,
    phase: 'beforeRead',
    effect: ({ state, instance }) => {
        const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            for (const entry of entries) {
                const { width: cachedWidth, height: cachedHeight } = state.rects.popper
                const { width, height } = entry.contentRect

                const areWidthsDifferent = width !== cachedWidth
                const areHeightsDifferent = height !== cachedHeight

                if (areWidthsDifferent || areHeightsDifferent) {
                    instance.forceUpdate()
                }
            }
        })

        observer.observe(state.elements.popper)

        return () => {
            observer.disconnect()
        }
    },
}
