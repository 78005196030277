import React, { useState } from 'react'

import styled from '@emotion/styled'

import HELP_LINKS from 'app/helpLinks'
import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection } from 'legacy/v1/ui'

import { Button, Flex, Input, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'
import { FEATURES, ProtectedFeature, ProtectedFeatureIndicator } from 'v2/ui/utils/ProtectedFeature'

type TagUpdateIndex = 'intercom_app_id' | 'fullstory_org_id'

type ThirdPartyTagsSettingProps = {
    selectedStack: StackDto
    showUpgradeButton: boolean
    isThirdPartyTagsLocked: boolean
    onSave: () => void
    onSaveError: () => void
}

const { colors } = stackerTheme()

export const ThirdPartyTagsSetting = ({
    selectedStack,
    showUpgradeButton,
    isThirdPartyTagsLocked,
    onSave,
    onSaveError,
}: ThirdPartyTagsSettingProps) => {
    const [showThirdPartyTagsUpgrade, setThirdPartyTagsUpgrade] = useState<boolean>(false)
    const [intercomAppId, setIntercomAppId] = useState<string>(
        selectedStack?.options?.intercom_app_id
    )
    const [fullstoryOrgId, setFullstoryOrgId] = useState<string | undefined>(
        selectedStack?.options?.fullstory_org_id
    )

    const { mutateAsync: updateStack } = useUpdateStack()

    const updateStackOptionAndSave = (patch: { [key in TagUpdateIndex]?: string }) => {
        updateStack({
            id: selectedStack._sid,
            patch: {
                options: {
                    ...selectedStack.options,
                    ...patch,
                },
            },
        })
            .then(() => {
                onSave()
            })
            .catch(() => {
                onSaveError()
            })
    }
    return (
        <>
            {/* @ts-expect-error */}
            <ExpandSection
                always
                className="admin-app-settings-third_party_tags"
                //@ts-expect-error
                heading={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Third-party tags
                        <ProtectedFeatureIndicator
                            onClick={() => setThirdPartyTagsUpgrade(true)}
                            feature={FEATURES.thirdPartyTags}
                            ml={3}
                        />
                    </div>
                }
                helpText="How to set up your third-party tags"
                text={null}
            >
                {
                    <>
                        <Text>
                            Add credentials from Intercom or FullStory. Learn more about how to
                            connect{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={HELP_LINKS.INTERCOM_LEARN_MORE}
                            >
                                Intercom
                            </a>{' '}
                            and{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={HELP_LINKS.FULLSTORY_LEARN_MORE}
                            >
                                FullStory.
                            </a>
                        </Text>
                        {showUpgradeButton && isThirdPartyTagsLocked && (
                            <Button
                                marginRight="auto"
                                variant="upgradePrimary"
                                onClick={() => setThirdPartyTagsUpgrade(true)}
                            >
                                Upgrade
                            </Button>
                        )}
                    </>
                }
                <Flex column align="flex-start">
                    <StyledLabel htmlFor="intercom-input">Intercom</StyledLabel>
                    <FlexWrapper>
                        <Input
                            id="intercom-input"
                            style={{ width: '310px' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIntercomAppId(e.target.value)
                            }
                            value={intercomAppId}
                            data-testid="general-settings.third_party_tags.intercom-input"
                            disabled={isThirdPartyTagsLocked}
                        />
                        <StackColorButton
                            primary
                            color="stacker"
                            padding="small"
                            margin="none"
                            style={{ marginLeft: '10px' }}
                            isDisabled={
                                intercomAppId === selectedStack?.options?.intercom_app_id ||
                                isThirdPartyTagsLocked
                            }
                            onClick={() => {
                                updateStackOptionAndSave({ intercom_app_id: intercomAppId })
                            }}
                        >
                            Save
                        </StackColorButton>
                    </FlexWrapper>
                    <Text fontSize={12} mt={2} color="userInterface.neutral.900">
                        Your Intercom app ID, e.g. gkeb4bs
                    </Text>
                    <StyledLabel htmlFor="fullstory-input" style={{ marginTop: '16px' }}>
                        FullStory
                    </StyledLabel>
                    <FlexWrapper>
                        <Input
                            id="fullstory-input"
                            style={{ width: '310px' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFullstoryOrgId(e.target.value)
                            }
                            value={fullstoryOrgId}
                            data-testid="general-settings.third_party_tags.fullstory-input"
                            disabled={isThirdPartyTagsLocked}
                        />
                        <StackColorButton
                            primary
                            color="stacker"
                            padding="small"
                            margin="none"
                            style={{ marginLeft: '10px' }}
                            isDisabled={
                                fullstoryOrgId === selectedStack?.options?.fullstory_org_id ||
                                isThirdPartyTagsLocked
                            }
                            onClick={() => {
                                updateStackOptionAndSave({ fullstory_org_id: fullstoryOrgId })
                            }}
                        >
                            Save
                        </StackColorButton>
                    </FlexWrapper>
                    <Text fontSize={12} mt={2} color="userInterface.neutral.900">
                        Your FullStory Organisation ID, e.g. 1ENq
                    </Text>
                </Flex>
            </ExpandSection>
            <ProtectedFeature
                feature={FEATURES.thirdPartyTags}
                showModal={showThirdPartyTagsUpgrade}
                onModalClosed={() => setThirdPartyTagsUpgrade(false)}
            />
        </>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const StyledLabel = styled.label`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.userInterface.neutral[1000]};
    margin: 0px 5px 8px 0px;
`

const StackColorButton = (props: any) => (
    <Button
        variant="adminPrimary"
        buttonSize="sm"
        {...props}
        style={{ ...props.style, flexShrink: 0 }}
    />
)
