import React from 'react'

import styled from '@emotion/styled'

import STYLE_CLASSES from 'v2/ui/styleClasses'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const StyledSpan = styled('span')<{ whiteSpace?: string }>`
    white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
    align-items: center;
    max-width: 100%;
    border-radius: 0.25rem;
    margin-right: 0.24rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background: ${V4DesignSystem.colors.gray[100]};
    display: inline-flex;
`

type StackerTagProps = React.ComponentPropsWithoutRef<typeof StyledSpan> & {}

const StackerTag: React.FC<StackerTagProps> = ({ children, className, ...props }) => (
    <StyledSpan {...props} className={`${className ? className + ' ' : ''}${STYLE_CLASSES.TAG}`}>
        {children}
    </StyledSpan>
)

export default StackerTag
