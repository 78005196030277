/* eslint-disable react/no-access-state-in-setstate */
/* Code Quality: OK */

import React from 'react'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ReactTooltip from 'react-tooltip'

import deepcopy from 'deepcopy'
import { css } from 'emotion'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { Icon, Label, Toggle } from 'legacy/v1/ui'

/*
This component takes a list of items, whether or not they're selected and
allows the user to sort them and toggle them

Params:
    items:      an array of items to be sorted and toggled, consisting of:
    renderItem: a function to render the display of the item next to the toggle box
    isSelected: the function to detemine if the item is "toggled on"
    toggleItem: a function which will be called after an item has been toggled.
    onChange:   if triggered on sort, returns the new sorted array
                if triggered after toggle, returns a copy of the array with the toggled
                item replaced by toggleItem(item)
    onReorder:  called just after a re-order
    onToggle:   called just after item is toggled
    ItemWrapper: component that goes around the handle, toggle and rendered item
    itemToId:   used to generate nice keys to stop array complaints

The intended usage is that assuming that all the props are passed, the calling component
should just be able to use the output of onChange without further processing.
*/

export default class ToggleSortPicker extends React.Component {
    constructor(props) {
        super(props)
        if (props.items) this.state = { items: deepcopy(props.items) }
    }

    componentDidUpdate() {
        if (!isEqual(this.state.items, this.props.items)) {
            this.setState({ items: deepcopy(this.props.items) })
        }
    }

    onChange(values) {
        if (this.props.onChange) this.props.onChange(values)
    }

    toggleSelected = (index) => {
        const items = deepcopy(this.state.items)
        if (this.props.onToggle) this.props.onToggle(index)
        items[index] = this.props.toggleItem(items[index])
        this.setState({ items }, () => this.onChange(items))
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'inherit'
        const items = deepcopy(this.state.items)
        if (this.props.onReorder) this.props.onReorder(oldIndex, newIndex)
        const values = arrayMove(items, oldIndex, newIndex)
        this.setState({ items: values }, () => this.onChange(values))
    }

    onSortStart = () => {
        document.body.style.cursor = 'grabbing'
    }

    render() {
        return (
            <SortableList
                items={this.state.items}
                renderItem={this.props.renderItem}
                onSortEnd={this.onSortEnd}
                onSortStart={this.onSortStart}
                toggle={this.toggleSelected}
                distance={10}
                useDragHandle
                isSelected={this.props.isSelected}
                itemToId={this.props.itemToId}
                ItemWrapper={this.props.ItemWrapper}
                isMenu={this.props.isMenu}
                isInSettingsModal={this.props.isInSettingsModal}
            />
        )
    }
}

ToggleSortPicker.propTypes = {
    ItemWrapper: PropTypes.func,
    itemToId: PropTypes.func,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onReorder: PropTypes.func,
    onToggle: PropTypes.func,
    renderItem: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
}

ToggleSortPicker.defaultProps = {
    // eslint-disable-next-line react/prop-types
    ItemWrapper: ({ checked, children }) => (
        <div
            className={`sortable-items-item ${checked ? 'selected' : ''}`}
            style={{ zIndex: 999, display: 'flex', alignItems: 'center' }}
        >
            {children}
        </div>
    ),
    itemToId: (item) => JSON.stringify(item),
    onChange: null,
    onReorder: null,
    onToggle: null,
}

const SortableItem = SortableElement(
    ({ item, toggle, checked, ItemWrapper, isHome, isInSettingsModal }) => (
        <ItemWrapper checked={checked}>
            {!isInSettingsModal ? (
                <>
                    <Handle />
                    <Toggle small onChange={toggle} value={checked} />
                </>
            ) : null}
            {item}

            {isHome && (
                <>
                    <div
                        data-tip
                        data-for="home-item"
                        style={{ marginTop: '15px', float: 'right', color: 'gray' }}
                    >
                        <Icon icon="home" size={48} />
                    </div>

                    <ReactTooltip id="home-item" effect="solid">
                        <Label style={{ color: 'white' }}>
                            The first item in your navigation menu will be your homepage, even if it
                            isn&apos;t enabled.
                        </Label>
                    </ReactTooltip>
                </>
            )}
        </ItemWrapper>
    )
)

const Handle = SortableHandle(() => (
    <Icon icon="grip-vertical" style={{ cursor: 'pointer', color: '#c1cee2' }} />
))

const SortableList = SortableContainer(
    ({
        items,
        ItemWrapper,
        itemToId,
        renderItem,
        toggle,
        isSelected,
        isMenu,
        isInSettingsModal,
    }) => {
        return (
            <div
                className={css`
                    scrollbar-width: thin;
                    scrollbar-height: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.5);
                    ::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }
                    ::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    ::-webkit-scrollbar-thumb {
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 20px;
                    }
                `}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden scroll',
                    paddingBottom: '5px',
                }}
            >
                {items.map((item, index) => {
                    // For settings modal.
                    const checked = isSelected(item)
                    // For settings modal.
                    const _toggle = (
                        <Toggle
                            small
                            theme={{
                                toggleBackgroundColor: isInSettingsModal ? '#465DD8' : 'initial',
                            }}
                            onChange={() => toggle(index, !item.isSelected)}
                            value={checked}
                        />
                    )

                    return (
                        <SortableItem
                            key={itemToId(item)}
                            index={index}
                            item={renderItem(item, _toggle)}
                            toggle={() => toggle(index, !item.isSelected)}
                            checked={checked}
                            ItemWrapper={ItemWrapper}
                            isHome={isMenu && items.indexOf(item) === 0}
                            isInSettingsModal={isInSettingsModal}
                        />
                    )
                })}
            </div>
        )
    }
)
