import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    DATA_CONNECTION_CREATED,
    DATA_CONNECTION_REMOVED,
    DATA_CONNECTION_UPDATED,
    DATA_CONNECTIONS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from '../utils/constants'

// CONSTANTS

// STATE
export const initialState = {
    dataConnections: [],
    entities: {},
    fetching: [],
    fetched: false,
}

// REDUCERS
export function dataConnectionReducer(state = initialState, action) {
    let dataConnections
    let dataConnection
    let fetching
    switch (action.type) {
        case DATA_CONNECTIONS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                dataConnections: unique([
                    ...state.dataConnections,
                    ...action.payload.map((dc) => dc._sid),
                ]),
                fetched: true,
                fetching: state.fetching.filter((item) => item !== action.key),
                entities: { ...state.entities, ...byID(action.payload) },
            }
        case DATA_CONNECTION_CREATED:
            if (!action.payload) return state
            dataConnections = unique([...state.dataConnections, action.payload._sid])
            return {
                ...state,
                dataConnections,
                fetched: true,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case DATA_CONNECTION_UPDATED:
            if (!action.payload) return state
            dataConnection = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                fetched: true,
                entities: { ...state.entities, [dataConnection._sid]: dataConnection },
            }
        case DATA_CONNECTION_REMOVED:
            dataConnections = state.dataConnections.filter((id) => id !== action.id)
            const entities = Object.fromEntries(
                Object.entries(state.entities).filter(([key, _]) => key !== action.id)
            )
            return {
                ...state,
                dataConnections,
                entities,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
