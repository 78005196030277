import React from 'react'

import get from 'lodash/get'

import { invalidateAggregates } from 'data/hooks/aggregates'
import WithObject from 'data/wrappers/WithObject'
import { withRecordActions } from 'data/wrappers/WithRecords'
import { withStack } from 'data/wrappers/WithStacks'
import { withUserLists } from 'data/wrappers/WithUserLists'
import CreatedByEditor from 'features/admin/settings/object/CreatedByEditor'
import DataFilterEditor from 'features/admin/settings/object/DataFiltersEditor'
import {
    DeleteNativeTableButton,
    RenameNativeTableForm,
} from 'features/admin/settings/object/NativeTableSettingsControls'
import { Divider, ExpandSection, Section } from 'legacy/v1/ui'
import { canBePrimaryField } from 'utils/fieldUtils'

import { Banner, Button, Dropdown } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
class AppModalDataSettings extends React.Component {
    state = {}

    getPrimaryFieldApi(object) {
        if (this.state.primaryField) return this.state.primaryField

        if (object && object.fields) {
            const field = object.fields.find((f) => f.is_primary)
            if (field) return field.api_name
        }
    }

    getIdField(object) {
        if (this.state.idField) return this.state.idField
        return get(object, 'connection_options.id_field')
    }

    shouldShowGSheetsOptions(object) {
        return get(object, 'connection_options.gsheets_worksheet_name')
    }

    render() {
        const { recordActions, noPadding, openSignupObject } = this.props

        const hideDataFilterSetting =
            this.props.stack?.options?.enable_open_signup_for_stacker_users &&
            openSignupObject?._sid === this.props.objectId &&
            !(this.props.object?.connection_options['data_mapping_filters']?.length > 0)

        return (
            <WithObject objectId={this.props.objectId}>
                {({ object, changeObject, changeField, removeObject }) => (
                    <Section
                        style={{
                            width: '100%',
                            marginBottom: 0,
                            padding: noPadding ? 0 : undefined,
                        }}
                    >
                        <ExpandSection
                            heading="Display Name"
                            text="Configure the primary field for this table."
                            classes={{
                                heading:
                                    ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM
                                        .DISPLAY_NAME,
                            }}
                        >
                            <Section noPadding noMargin>
                                <Dropdown
                                    value={this.getPrimaryFieldApi(object)}
                                    options={object.fields
                                        .filter(canBePrimaryField)
                                        .map((field) => {
                                            return {
                                                label: field.label,
                                                value: field.api_name,
                                            }
                                        })}
                                    onChange={(fieldApiName) => {
                                        this.setState({ primaryField: fieldApiName })
                                    }}
                                    isClearable={false}
                                />

                                <Button
                                    variant="Primary"
                                    buttonSize="sm"
                                    color={this.state.primaryField === undefined ? '' : 'success'}
                                    disabled={this.state.primaryField === undefined}
                                    width="70px"
                                    mt={1}
                                    onClick={() => {
                                        // Set the new primary field
                                        // We don't need to manually set the old primary field to False
                                        const primaryField = object.fields.find(
                                            (f) =>
                                                f.api_name === this.state.primaryField &&
                                                !f.is_primary
                                        )
                                        if (!primaryField) {
                                            return
                                        }

                                        changeField(primaryField._sid, {
                                            is_primary: true,
                                        })
                                    }}
                                >
                                    Save
                                </Button>
                            </Section>
                        </ExpandSection>
                        <Divider margin="none" />
                        {!object?.connection_options?.stacker_native_object && (
                            <ExpandSection
                                heading="Data Filters"
                                text="Limit the records from this table. Any records which do not match
                                            the filters will never be visible in your app (including to you!)."
                                classes={{
                                    heading:
                                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION
                                            .SETTINGS_ITEM.DATA_FILTERS,
                                }}
                            >
                                <Section noPadding noMargin className="small-filters">
                                    {hideDataFilterSetting ? (
                                        <Banner icon="info" mt={2}>
                                            Data filters on the user table cannot be used with open
                                            signup. If you would like to add a data filter, please
                                            disable open signup.
                                        </Banner>
                                    ) : (
                                        <DataFilterEditor
                                            object={object}
                                            onChange={(object) => {
                                                changeObject(object)
                                                // clear redux
                                                recordActions.clear(this.props.objectId)
                                                invalidateAggregates()
                                            }}
                                        />
                                    )}
                                </Section>
                            </ExpandSection>
                        )}
                        {!object?.connection_options?.read_only && (
                            <>
                                <Divider margin="none" />
                                <ExpandSection
                                    heading="Set Created By Field"
                                    text="Set one of your fields to list who created a record."
                                    helpUrl=""
                                    helpText=""
                                    classes={{
                                        heading:
                                            ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION
                                                .SETTINGS_ITEM.SET_CREATED_BY,
                                    }}
                                >
                                    <Section noPadding noMargin>
                                        <CreatedByEditor
                                            object={object}
                                            changeObject={changeObject}
                                            userTable={
                                                this.props.stack?.options?.data_mapping?.user_object
                                            }
                                        />
                                    </Section>
                                </ExpandSection>
                            </>
                        )}
                        {object?.connection_options?.stacker_native_object && (
                            <>
                                <Divider margin="none" />
                                <ExpandSection
                                    heading="Rename table"
                                    text="Rename the table within App Settings. To rename the table in the menus, use the Navigation Settings."
                                    helpUrl=""
                                    helpText=""
                                    classes={{
                                        heading:
                                            ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION
                                                .SETTINGS_ITEM.RENAME_OBJECT,
                                    }}
                                >
                                    <RenameNativeTableForm
                                        object={object}
                                        onChangeObject={changeObject}
                                    />
                                </ExpandSection>
                                <Divider margin="none" />
                                <ExpandSection
                                    heading="Delete table"
                                    text="Remove this table and all associated data."
                                    helpUrl=""
                                    helpText=""
                                    classes={{
                                        heading:
                                            ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION
                                                .SETTINGS_ITEM.DELETE_OBJECT,
                                    }}
                                >
                                    <Section noPadding noMargin>
                                        <DeleteNativeTableButton
                                            object={object}
                                            onDeleteObject={removeObject}
                                        />
                                    </Section>
                                </ExpandSection>
                                <Divider margin="none" />
                            </>
                        )}
                        {!noPadding && <Divider margin="none" />}
                    </Section>
                )}
            </WithObject>
        )
    }
}

export default withStack(withUserLists(withRecordActions(AppModalDataSettings)))
