export const viewHasAssociatedPage = (view: ViewDto, pages: PageDto[]) => {
    // Returns true iff there exists a page which corrersponds to the view
    // via page.options.view_id
    return pages.some((page) => {
        if (
            !page.is_feature_settings &&
            !page.is_feature_template &&
            page.options.view_id === view._sid
        ) {
            return true
        }
    })
}
