import type { FC, VFC } from 'react'

import AppSettingsSidePane from '../app-settings/AppSettingsSidePane'
import { EditLayoutSidePane } from '../edit-layout/EditLayoutSidePane'
import { ManageDataSidePane } from '../manage-data/ManageDataSidePane'
import { ManageUsersSidePane } from '../manage-users/ManageUsersSidePane'
import type { SlidingPaneKey } from '../types'

export const SLIDING_PANES = new Map<SlidingPaneKey | null, FC | VFC>()

SLIDING_PANES.set('app-settings', AppSettingsSidePane)
SLIDING_PANES.set('edit-layout', EditLayoutSidePane)
SLIDING_PANES.set('data-grid', ManageDataSidePane)
SLIDING_PANES.set('users', ManageUsersSidePane)
