import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type UserProps = {
    color?: string
    size?: number
}
const User: React.FC<UserProps> = ({ color = colors.userInterface.accent[1000], size = 12 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size * 17) / 16}
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M8 9.5a4.501 4.501 0 0 0 0-9 4.501 4.501 0 0 0 0 9zm4 1h-1.722a5.446 5.446 0 0 1-4.556 0H4a4 4 0 0 0-4 4v.5a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 15v-.5a4 4 0 0 0-4-4z"
                fill={color}
            />
        </svg>
    )
}

export default User
