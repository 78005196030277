// @ts-nocheck
import React from 'react'

import { getCachedField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'

import { Input } from 'v2/ui'

import { LinkFormDirectionRadioGroup } from './form/fragments/LinkFormDirectionRadioGroup'
import { LinkFormFieldDropdown } from './form/fragments/LinkFormFieldDropdown'
import { LinkFormFieldLabel } from './form/fragments/LinkFormFieldLabel'
import { LinkFormFieldNameInput } from './form/fragments/LinkFormFieldNameInput'
import { LinkFormModeDropdown } from './form/fragments/LinkFormModeDropdown'
import { LinkFormObjectDropdown } from './form/fragments/LinkFormObjectDropdown'
import { LinkFormObjectLabel } from './form/fragments/LinkFormObjectLabel'
import { LinkFormEmptyState } from './form/LinkFormEmptyState'
import { LinkFormFieldsPicker } from './form/LinkFormFieldsPicker'
import { LinkFormHeader } from './form/LinkFormHeader'
import { LinkFormLayout } from './form/LinkFormLayout'
import { LinkFormModePicker } from './form/LinkFormModePicker'
import { LinkFormSection } from './form/LinkFormSection'
import { LinkFormSectionList } from './form/LinkFormSectionList'
import { useLinkFormFields } from './hooks/useLinkForm'
import { LinkPreviewView } from './previews'

/**
 *
 * @param {{ form: import('react-hook-form').UseFormMethods<LinkUpdateData>, preview?: LinkPreview | null } } props
 * @returns
 */
export const LinkFormFields = ({ form, enableManyToMany = true }) => {
    const formFields = useLinkFormFields(form)
    const values = form.watch()

    const { object: sourceObject } = useObject(values.source)
    const { object: targetObject } = useObject(values.target)

    const sourceField = getCachedField(values.sourceField)
    const targetField = getCachedField(values.targetField)

    const showEmptyState = !values.source || !values.target
    const showExpanded = !showEmptyState

    // Legacy links does not have a symmetric field
    const showSourceLinkNameInput = !values.legacy || values.linkFieldObject === values.source
    const showTargetLinkNameInput = !values.legacy || values.linkFieldObject === values.target

    return (
        <LinkFormLayout>
            <LinkFormHeader
                style={{ marginBottom: 20 }}
                source={
                    <LinkFormObjectDropdown
                        {...formFields.source}
                        object={sourceObject}
                        name="source"
                        variant="primary"
                    />
                }
                target={
                    <LinkFormObjectDropdown
                        {...formFields.target}
                        object={targetObject}
                        variant="secondary"
                        name="target"
                    />
                }
            />
            {showEmptyState && (
                <LinkFormEmptyState>Select tables to configure the link</LinkFormEmptyState>
            )}
            {showExpanded && (
                <LinkFormSectionList>
                    <LinkFormSection title="Records will be linked based on existing data">
                        <LinkFormFieldsPicker
                            sourceObject={sourceObject}
                            targetObject={targetObject}
                            source={
                                <LinkFormFieldDropdown
                                    {...formFields.sourceField}
                                    object={sourceObject}
                                    variant="primary"
                                    name="sourceField"
                                />
                            }
                            target={
                                <LinkFormFieldDropdown
                                    {...formFields.targetField}
                                    object={targetObject}
                                    variant="secondary"
                                    name="targetField"
                                />
                            }
                        />
                    </LinkFormSection>
                    {values.sourceField && values.targetField && (
                        <LinkFormSection title="Which field will turn into a link field?">
                            <LinkFormDirectionRadioGroup
                                {...formFields.linkFieldObject}
                                sourceObject={sourceObject}
                                targetObject={targetObject}
                                sourceField={sourceField}
                                targetField={targetField}
                                values={values}
                                renderLabel={({ isSelected, object, field, index }) => (
                                    <LinkFormFieldLabel
                                        showLink={isSelected}
                                        variant={index === 0 ? 'primary' : 'secondary'}
                                        object={object}
                                        field={field}
                                    />
                                )}
                            />
                            {enableManyToMany && (
                                <LinkFormModePicker
                                    showDelimiter={values.mode === 'many-to-many'}
                                    modeInput={<LinkFormModeDropdown {...formFields.mode} />}
                                    delimiterInput={
                                        <Input variant="settings" {...formFields.delimiter} />
                                    }
                                />
                            )}
                            <LinkPreviewView
                                sourceObject={sourceObject}
                                targetObject={targetObject}
                                sourceField={sourceField}
                                targetField={targetField}
                                values={values}
                            />
                        </LinkFormSection>
                    )}
                    {values.sourceField && values.targetField && (
                        <LinkFormSection title="Field name">
                            <table>
                                <tbody>
                                    {showSourceLinkNameInput && (
                                        <tr>
                                            <td>
                                                <LinkFormObjectLabel object={sourceObject} />
                                            </td>
                                            <td width="100%">
                                                <LinkFormFieldNameInput
                                                    {...formFields.sourceFieldLinkName}
                                                    name="sourceFieldLinkName"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {showTargetLinkNameInput && (
                                        <tr>
                                            <td>
                                                <LinkFormObjectLabel
                                                    variant="secondary"
                                                    object={targetObject}
                                                />
                                            </td>
                                            <td width="100%">
                                                <LinkFormFieldNameInput
                                                    {...formFields.targetFieldLinkName}
                                                    name="targetFieldLinkName"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </LinkFormSection>
                    )}
                </LinkFormSectionList>
            )}
        </LinkFormLayout>
    )
}
