import { NONE_VALUE_SECTION_LABEL } from 'data/hooks/metrics/groupAggregateData'
import { formatValue } from 'data/utils/utils'
import { getBucketDate } from 'features/charts/utils/getBucketDate'
import { getBucketLabel } from 'features/charts/utils/getBucketLabel'

import { ThemeConfig } from 'v2/ui/theme/styles/types'

import { LABEL_GREY } from '../../constants'
import { getColoredCategories } from '../../utils/getColoredCategories'
import { CategoryColors, ChartData } from '../types'

const NONE_VALUE_SECTION_COLOR = LABEL_GREY
const NONE_VALUE_SECTION_LABEL_COLOR = '#204060'

export type Args = {
    metrics: MetricsQueryResponse
    config: ChartParamsConfig
    fields: FieldDto[] | undefined
}

export default function prepareData(
    data: MetricsQueryResponse['data'],
    { config, metrics, fields }: Args,
    theme: ThemeConfig,
    brandColor: string | undefined
): { chartData: ChartData; categoryColors: CategoryColors[] } {
    const groupByField = fields?.find(({ _sid }) => _sid === config.group.group_field_sid)
    const xAxisField = fields?.find(({ _sid }) => _sid === config.group.field_sid)

    const chartData = data.map(({ key, others, ...rest }) => ({
        name: key === null ? NONE_VALUE_SECTION_LABEL : transformKey(key, config, xAxisField),
        color: getColor(key, others, brandColor),
        key,
        ...rest,
    }))

    const categoryColors = getColoredCategories(metrics.categories, groupByField, theme)

    return { chartData, categoryColors }
}

function getColor(key: string, others: boolean | undefined, brandColor: string | undefined) {
    if (key === NONE_VALUE_SECTION_LABEL) {
        return NONE_VALUE_SECTION_LABEL_COLOR
    }

    if (others) {
        return NONE_VALUE_SECTION_COLOR
    }

    return brandColor as string
}

function transformKey(key: string, config: ChartParamsConfig, field?: FieldDto) {
    const formattedKey = (key && field && formatValue(field, key)) ?? key
    const bucketDate = getBucketDate(key, config?.group?.bucketBy)
    return config?.group?.bucketBy && bucketDate
        ? getBucketLabel(bucketDate, config?.group?.bucketBy)
        : String(formattedKey)
}
