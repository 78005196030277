import React, { useEffect, useRef } from 'react'

import * as Sentry from '@sentry/react'
import isEqual from 'lodash/isEqual'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

const intercomKey = () => {
    let key = ''

    const testKey = 'bu5482h5'
    const prodKey = 'txwvzciy'

    const isRealUser = settings.IS_PROD && !localStorage.getItem('support_login')
    key = isRealUser ? prodKey : testKey

    return key
}

const _bootIntercom = (intercomSettings, callback) => {
    try {
        if (window.Intercom) {
            // @ts-ignore
            window.Intercom('boot', intercomSettings)

            if (callback) callback(window.Intercom)
        } else {
            // if Intercom not yet available, retry every 3s until it is
            setTimeout(() => {
                _bootIntercom(intercomSettings, callback)
            }, 3000)
        }
    } catch (err) {
        console.error('Error while booting Intercom', err)
        Sentry.withScope((scope) => {
            scope.setExtra('message', 'Exception from bootIntercom')
            scope.setExtra('email', intercomSettings.email)
            scope.setExtra('name', intercomSettings.name)
            scope.setExtra('stack_id', intercomSettings.stackId)
            Sentry.captureException(err)
        })
    }
}

const parseIntercomSettingsFromUser = (user) => {
    if (!user) {
        return {}
    }

    let parsedUser = {}

    try {
        // the user may be passed directly from localstorage,
        // in which case it will just be a JSON string, so need to parse it
        parsedUser = typeof user === 'object' ? user : JSON.parse(user)
    } catch (err) {
        console.error(`Unable to parse user ${user}`, err)

        return {}
    }

    const { _sid, name, email, options } = parsedUser
    const signupFor = options?.signup_params?.for
    const viaWorkspaceInvite = !!options?.via_workspace_invite

    return {
        _sid,
        name,
        email,
        signupFor,
        viaWorkspaceInvite,
    }
}

const parseIntercomCompanyInfoFromAccount = (account) => {
    return {
        name: account.name,
        _sid: account._sid,
        createdByUserId: account.created_by_user_id,
    }
}

export const bootIntercom = async ({
    account = undefined,
    callback = undefined,
    extraSettings = {},
    stackId = undefined,
    user = undefined,
}) => {
    // Do not boot intercom for workspace guests
    if (user?.membership_options?.role === 'guest') {
        return
    }

    const app_id = intercomKey()
    const BaseUrl = window.location.hostname
    const includeBaseUrl = ![...settings.SUPPORTED_STUDIO_DOMAINS, 'airportal.app'].includes(
        BaseUrl
    )
    const {
        _sid: userSid,
        name,
        email,
        signupFor,
        viaWorkspaceInvite,
    } = parseIntercomSettingsFromUser(user)

    let userHash = undefined
    if (email) {
        await fetchWithAuth('intercom-token/', {
            method: 'GET',
        }).then(
            async (response) => {
                await response.json().then(
                    (obj) => (userHash = obj.user_hash),
                    () => null
                )
            },
            () => null
        )
    }

    let createdBySid
    let company
    if (account) {
        const {
            name: accountName,
            _sid: accountSid,
            createdByUserId,
        } = parseIntercomCompanyInfoFromAccount(account)
        createdBySid = createdByUserId

        company = {
            name: accountName,
            id: accountSid,
            ...(createdBySid && {
                workspace_creator_id: createdBySid,
            }),
        }
    }

    const isWorkspaceAccountCreator =
        signupFor === 'workspace' || signupFor === 'stacker_data' || createdBySid === userSid
    const isWorkspaceUser = viaWorkspaceInvite || isWorkspaceAccountCreator
    const intercomSettings = {
        app_id,

        ...(extraSettings && { ...extraSettings }),

        // these details are parsed out of user, if passed
        ...(email && { email }),
        ...(name && { name }),
        ...(isWorkspaceAccountCreator && { is_account_creator: true }),
        ...(isWorkspaceUser && { is_workspace: true }),

        ...(stackId && { stackId }),

        ...(includeBaseUrl && { BaseUrl }),
        hide_default_launcher: true,
        ...(company && { company }),
        ...(userHash && { user_hash: userHash }),
    }
    _bootIntercom(intercomSettings, callback)
}

export const updateIntercom = (data) => {
    try {
        if (window.Intercom.booted) {
            data = {
                ...data,
                hide_default_launcher: true,
            }
            window.Intercom('update', data)
        } else {
            // Intercom is not yet available, delaying update
            const delayUpdate = () => updateIntercom(data)
            setTimeout(delayUpdate, 3000)
        }
    } catch (e) {
        console.error('Error while updating Intercom', e)
        Sentry.withScope((scope) => {
            scope.setExtra('message', 'Exception from updateIntercom')
            scope.setExtra('data', data)
            Sentry.captureException(e)
        })
    }
}

export const shutdownIntercom = () => {
    if (window.Intercom) window.Intercom('shutdown')
}

export class IntercomWrapper extends React.Component {
    componentDidMount = () => {
        bootIntercom({
            user: this.props.user,
            stackId: this.props.stackId,
        })
    }

    render = () => this.props.children
}

export const withUnauthenticatedIntercom = (Child) => (props) =>
    (
        <IntercomWrapper>
            <Child {...props} />
        </IntercomWrapper>
    )

export const useCanAccessSupport = () => {
    const { isStudioUser, isImpersonating, user } = useAppUserContext()
    const { workspaceAccount } = useAppContext()
    const { userHasRight } = useAccountUserContext()

    const isWorkspaceAccount = Boolean(workspaceAccount)

    const hasContactSupportRight = isWorkspaceAccount
        ? userHasRight(user, Rights.ContactSupport)
        : isStudioUser && !isImpersonating

    const isNotStudioUser =
        !isStudioUser || user?.membership_options?.role === 'guest' || !user?.membership_options

    return !isNotStudioUser && hasContactSupportRight
}

export const withCanAccessSupport = (Child) => (props) => {
    const canAccessSupport = useCanAccessSupport()
    return <Child {...props} canAccessSupport={canAccessSupport} />
}

// Allows the customer to set a custom intercom chat
export const useCustomIntercom = () => {
    const { isImpersonating, user, isStudioUser } = useAppUserContext()
    const { data: users = [], isLoading } = useAppUsersForAdmin(!!isImpersonating)
    const { workspaceAccount, selectedStack } = useAppContext()
    const canAccessSupport = useCanAccessSupport()
    const currentIntercomSettings = useRef()
    const intercom_app_id =
        selectedStack?.options?.intercom_app_id || workspaceAccount?.options?.intercom_app_id
    const showOnLoginPage = selectedStack?.options?.show_intercom_on_login_page

    const isWorkspaceAccount = Boolean(workspaceAccount)

    // If we're impersonating, then we get the full user record here
    const impersonatingUser = isImpersonating ? users.find((x) => x._sid === user._sid) : null

    const currentUser = impersonatingUser || user
    const isNotStudioUser =
        !isStudioUser || user?.membership_options?.role === 'guest' || !user?.membership_options

    useEffect(() => {
        if (isLoading || (!currentUser && !showOnLoginPage)) return

        // Load the custom intercom if showing on login page, or if not a user with contact support rights
        if (intercom_app_id) {
            if (!currentUser) {
                const intercomSettings = {
                    app_id: intercom_app_id,
                    hide_default_launcher: false,
                }
                // Don't keep booting Intercom if the settings are already up to date
                if (!isEqual(intercomSettings, currentIntercomSettings.current)) {
                    currentIntercomSettings.current = intercomSettings
                    shutdownIntercom()
                    _bootIntercom(intercomSettings)
                }
            } else if (!canAccessSupport) {
                let { name, email } = currentUser
                // V3 end users
                if (!isWorkspaceAccount && isNotStudioUser) {
                    name = currentUser?._primary
                    email = currentUser?._email
                }

                const intercomSettings = {
                    app_id: intercom_app_id,
                    ...(email && { email }),
                    ...(name && { name }),
                    hide_default_launcher: false,
                }

                // Don't keep booting Intercom if the settings are already up to date
                if (!isEqual(intercomSettings, currentIntercomSettings.current)) {
                    currentIntercomSettings.current = intercomSettings
                    shutdownIntercom()
                    _bootIntercom(intercomSettings)
                }
            } else {
                // Use the default Stacker Intercom app
                // We have to trigger a shutdown after previewing so only trigger this if the user email is different
                // or we want to switch from the custom intercom app
                if (
                    currentIntercomSettings.current?.app_id ||
                    currentUser?.email !== currentIntercomSettings.current?.email
                ) {
                    currentIntercomSettings.current = currentUser
                    shutdownIntercom()
                    bootIntercom({ currentUser })
                }
            }
        }
    }, [
        intercom_app_id,
        currentUser,
        isWorkspaceAccount,
        isLoading,
        canAccessSupport,
        isNotStudioUser,
        showOnLoginPage,
    ])
}
