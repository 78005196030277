/* Code Quality: Bad */

import React, { useRef } from 'react'
import GooglePicker from 'react-google-picker'

import {
    GOOGLE_DRIVE_PICKER_API_KEY,
    GOOGLE_OAUTH_APP_ID,
    GOOGLE_OAUTH_CLIENT_ID,
} from 'app/settings'

import { Button, Flex } from 'v2/ui'

import useRunOnce from '../../v2/ui/utils/useRunOnce'

const PICKER_Z_INDEX = 5000 // Override our own modals

const GSheetsPicker = ({
    accessToken,
    onPicked,
    onCancelled,
    buttonLabel,
    onLoadFailed,
    onPickerOpened,
    autoOpen,
}) => {
    const loaded = useRef()
    const buttonRef = useRef()

    useRunOnce(() => {
        if (autoOpen) {
            setTimeout(() => buttonRef.current.click(), 1000)
        }
    }, autoOpen && buttonRef.current)

    if (!accessToken) return null
    onPicked = onPicked || console.log
    onCancelled = onCancelled || (() => undefined)
    // N.B. accessToken must be a valid OAuth access token with scope 'drive.files' granted.

    const handleAction = (data) => {
        if (data.action === 'loaded') {
            loaded.current = true
            if (onPickerOpened) onPickerOpened()
        } else if (data.action === 'picked' && data.docs && data.docs.length === 1) {
            const info = data.docs[0]
            onPicked({ sheetId: info.id, sheetUrl: info.url, sheetName: info.name })
        } else {
            onCancelled()
        }
    }

    const handleClicked = () => {
        if (!onLoadFailed) return

        loaded.current = false

        // if we still haven't received the loaded notification from the picker
        // after 3s, then chances are it failed to load and we want to record this
        setTimeout(() => {
            if (!loaded.current) {
                onLoadFailed()
            }
        }, 3000)
    }

    return (
        <Flex>
            <GooglePicker
                clientId={GOOGLE_OAUTH_CLIENT_ID}
                developerKey={GOOGLE_DRIVE_PICKER_API_KEY}
                oauthToken={accessToken}
                appId={GOOGLE_OAUTH_APP_ID}
                onChange={() => {}}
                multiselect={false}
                navHidden={true}
                viewId={'SPREADSHEETS'}
                createPicker={(google, oauthToken) => {
                    const googleViewId = google.picker.ViewId.SPREADSHEETS
                    const docsView = new google.picker.DocsView(googleViewId)
                        .setMode(google.picker.DocsViewMode.LIST)
                        .setMimeTypes('application/vnd.google-apps.spreadsheet')

                    const picker = new window.google.picker.PickerBuilder()
                        .addView(docsView)
                        .setOAuthToken(oauthToken)
                        .setDeveloperKey(GOOGLE_DRIVE_PICKER_API_KEY)
                        .setSize(1000, 650)
                        .setCallback(handleAction)
                        .setAppId(GOOGLE_OAUTH_APP_ID)

                    picker.build().setVisible(true)

                    // Override the default z-index
                    const pickerBg = document.getElementsByClassName('picker-dialog-bg')
                    for (let elt of pickerBg) {
                        elt.style.zIndex = PICKER_Z_INDEX
                    }
                    const pickerFg = document.getElementsByClassName('picker-dialog')
                    for (let elt of pickerFg) {
                        elt.style.zIndex = PICKER_Z_INDEX + 1
                    }
                }}
            >
                <Button
                    ref={buttonRef}
                    buttonSize="md"
                    variant="adminPrimaryV4"
                    onClick={handleClicked}
                >
                    {buttonLabel || 'Select Sheet'}
                </Button>
            </GooglePicker>
        </Flex>
    )
}

export default GSheetsPicker
