import get from 'lodash/get'
const getPageFromUrl = ({ pages, features, route, views = [] }) => {
    let pageUrl

    const routeParts = route.split('/')
    // Check for a view url first
    let path = '/' + routeParts[0]

    let viewMatches = []

    // Blank pages work with paths of the form `/page/page-slug`
    if (routeParts.length > 1 && routeParts[0] === 'page') {
        viewMatches = views.filter((view) => {
            if (view.type !== 'blankpage') return false

            return view.url === `/${routeParts[0]}/${routeParts[1]}`
        })
    }

    // only check views if this could be a top level view url
    // prevents the issue where a view url clashes with another feature url
    if (routeParts.length === 1 || routeParts[0] === 'dash') {
        viewMatches = views.filter((view) => {
            const isCorrectPath = view.url === path

            if (view.type === 'dashboard') {
                // some old dashboard urls don't have the /dashboard prefix
                return isCorrectPath || view.url === `/${routeParts[0]}/${routeParts[1]}`
            }

            const isSupportedViewType = view.type === 'list' || view.type === 'blankpage'

            return isCorrectPath && isSupportedViewType
        })
    }

    let featureMatches = []
    let view

    //if a view has been found, get the feature linked to the view
    if (viewMatches.length) {
        featureMatches = (features || []).filter(
            (feature) => feature._sid === viewMatches[0].feature_id
        )

        view = viewMatches[0]
    } else {
        //otherwise check if the url is a feature
        featureMatches = (features || []).filter((feature) => feature.url === path)
    }

    if (!featureMatches.length) {
        featureMatches = (features || []).filter((feature) => feature.url === '/')
        pageUrl = '/' + route
    } else {
        pageUrl = '/' + route.split('/').slice(1).join('/')
    }

    let page, captures, feature
    featureMatches.map((thisFeature) => {
        // We will return a feature even if we can't match to a page
        feature = thisFeature
        // See if we have any url params
        // console.log("Matching to pageUrl", pageUrl)
        const dbPageUrltoRegex = (page) => {
            var captureMatches = page.match(new RegExp('{.+?}', 'g'))
            var query = page
            if (captureMatches)
                captureMatches.forEach((capture) => (query = query.replace(capture, '(.+?)')))

            // console.log("matching against ", query)
            const regex = '^' + query + '$'
            return { regex, captureMatches }
        }

        const filterPageByReg = (page) => {
            if (!page) return false
            const { regex } = dbPageUrltoRegex(page.url)
            const results = pageUrl.match(new RegExp(regex))
            // console.log("trying to match to regex", regex, "got ", results)
            return !!results
        }

        let matches = pages
            .filter((page) => page.feature_id === thisFeature._sid)
            .filter(filterPageByReg)

        if (!matches.length) return ''

        page = matches[0]

        // console.log("found page ", page)
        const { regex, captureMatches } = dbPageUrltoRegex(page.url)

        const captureResults = pageUrl.match(new RegExp(regex))
        captures = {}
        if (captureMatches)
            captureMatches.forEach((label, i) => {
                let cleanLabel = label.slice(1, label.length - 1)
                captures[cleanLabel] = captureResults[i + 1]
            })
    })

    // Blank pages have no feature
    if (view && view.type === 'blankpage' && pages && !page) {
        page = pages.find((page) => page.options.view_id === view._sid)
    }

    if (!view && page) {
        const matchingViews = views.filter((x) => x._sid === get(page.options, 'view_id'))
        view = matchingViews.length ? matchingViews[0] : null
    }
    return { page, feature, captures, pageUrl, view }
}

export default getPageFromUrl
