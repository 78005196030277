import React from 'react'

import { LinkFormBackgroundContainer } from './LinkFormBackgroundContainer'

/**
 *
 * @param {{ variant: 'primary' | 'secondary', object: any}} param0
 * @returns
 */
export const LinkFormObjectLabel = ({ variant = 'primary', object }) => {
    return (
        <LinkFormBackgroundContainer variant={variant}>{object?.name}</LinkFormBackgroundContainer>
    )
}
