import React, { useCallback } from 'react'

import { RadioGroup, RadioGroupProps, Stack, useRadioGroup } from '@chakra-ui/react'

import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from './Flex'
import RadioButton from './RadioButton'
import Text from './Text'

const { colors } = stackerTheme()

type RadioOption = {
    label: string
    value: string
    tooltip?: JSX.Element
    disabled?: boolean
}

export type RadioProps = Omit<RadioGroupProps, 'onChange' | 'children'> & {
    options: RadioOption[]
    onChange: (value: string) => void
    size?: 'sm' | 'md' | 'lg'
    fontSize?: any
    value?: string
    isDisabled?: boolean
    style?: React.CSSProperties
}

const Radio = ({
    options,
    onChange,
    size = 'lg',
    fontSize,
    value,
    isDisabled,
    style,
}: RadioProps) => {
    const updateValue = useCallback((e) => onChange(e.target.value), [onChange])

    const { getRootProps, getRadioProps } = useRadioGroup({
        onChange,
        value,
        isDisabled,
    })

    const { css: _css, ...groupProps } = getRootProps()

    return (
        <RadioGroup value={value} onChange={updateValue} style={style}>
            <Stack {...groupProps}>
                {options.map(({ label, value: optionValue, tooltip, disabled }) => {
                    const radioProps = getRadioProps({ value: optionValue })
                    return (
                        <RadioButton
                            key={optionValue}
                            size={size}
                            value={optionValue}
                            disabled={isDisabled || disabled}
                            {...radioProps}
                        >
                            <Flex alignItems="center">
                                <Text
                                    color={colors.userInterface.neutral[900]}
                                    fontSize={
                                        fontSize || ['radio.text', null, null, 'radio.textLg']
                                    }
                                >
                                    {label}
                                </Text>
                                {tooltip}
                            </Flex>
                        </RadioButton>
                    )
                })}
            </Stack>
        </RadioGroup>
    )
}

export default React.memo(Radio)
