import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Switch } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { invalidateStackDetails, useStackDetails } from 'data/hooks/stackDetails'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'
import { NUMaSettings } from 'features/users/app-users-modal/AppUserModalNUMaSettings'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import { manageUserSettingsModalSubscribers } from 'utils/modalOpeners'

import { Banner, Box, Collapse, Flex, Icon, Modal, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { FormWrapper } from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'
import SubmitButton from 'ui/forms/SubmitButton'
import { SyncRefWithForm } from 'ui/forms/utils'

import { RolesSettings } from './RolesSettings'

let subscribers = manageUserSettingsModalSubscribers

// handle the open / closed state of the modal
export const ManageUserSettingsModal = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        manageUserSettingsModalSubscribers.push(setIsOpen)

        return () => (subscribers = subscribers.filter((fn) => fn !== setIsOpen))
    }, [])

    return isOpen ? (
        <ManageUserSettingsModalContent
            onRequestClose={() => {
                setIsOpen(false)
            }}
        />
    ) : null
}

/**
 *
 * @param {{onRequestClose: () => void , disableFocusLock: void, openWorkspaceSettingsAfterClose:void, requiredTier:void}} param0
 */
const ManageUserSettingsModalContent = ({ onRequestClose }) => {
    const { selectedStack } = useAppContext()

    const isNUMaApp =
        selectedStack.combined_optional_features['sync_stacker_users'] &&
        selectedStack.options['sync_users_as_auths']

    return (
        <Flex column align="left">
            {isNUMaApp ? (
                <NUMaSettings onRequestClose={onRequestClose} selectedStack={selectedStack} />
            ) : (
                <NUMSettings onRequestClose={onRequestClose} selectedStack={selectedStack} />
            )}
        </Flex>
    )
}

const NUMSettings = ({ onRequestClose, selectedStack }) => {
    const updateStackOptions = useUpdateStackOptions()
    const formRef = useRef()

    const formContext = useForm({
        defaultValues: {
            enable_open_signup_for_stacker_users:
                selectedStack?.options?.enable_open_signup_for_stacker_users,
        },
    })

    const handleSave = (patch) => {
        updateStackOptions(selectedStack, patch)
        onRequestClose()
    }

    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const onClose = () => {
        saveChanges(onRequestClose)
    }

    const isDirty = Object.keys(formContext.formState.dirtyFields).length > 0

    const { flags: ldflags } = useLDFlags()

    return (
        <Modal
            title="User Settings"
            headerVariant="regular"
            showCloseButton={true}
            isOpen={true}
            isSecondLayer={true}
            onClose={onClose}
            height={displayChangesModal ? 450 : null}
            size="700px"
            noPadding
        >
            <Box w="700px" textAlign="left" my={4} px={4}>
                <FormWrapper
                    ref={formRef}
                    formContext={formContext}
                    onSubmit={handleSave}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        marginTop: '8px',
                        paddingBottom: '10px',
                    }}
                >
                    <SyncRefWithForm formRef={formRef} />
                    {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                    <RolesSettings />
                    {ldflags?.allowOpenSignupNum && (
                        <>
                            <FormField
                                as={OpenSignupForNUM}
                                name="enable_open_signup_for_stacker_users"
                                stack={selectedStack}
                                variant="settings"
                                controlled
                            />
                            <SubmitButton
                                disabled={!isDirty}
                                variant="adminPrimaryV4"
                                buttonSize="md"
                                mt={4}
                                mb={4}
                                flexShrink={0}
                            >
                                Save
                            </SubmitButton>
                        </>
                    )}
                </FormWrapper>
            </Box>
        </Modal>
    )
}

const IneligibleReasonsMessage = ({ isEnabled, ineligibleReasons }) => {
    const str1 = isEnabled ? 'no longer' : 'not currently'
    const str2 = ineligibleReasons.length === 1 ? 'reason' : 'reasons'

    return (
        <>
            {!ineligibleReasons || ineligibleReasons?.length === 0 ? (
                <>
                    {' '}
                    This app is {str1} eligible for open signup. Please contact customer support for
                    more information.
                </>
            ) : (
                <>
                    This app is {str1} eligible for open signup for the following {str2}:{' '}
                    {ineligibleReasons.join(' ')}
                </>
            )}
        </>
    )
}

const OpenSignupForNUM = ({ onChange, value, stack }) => {
    const { data } = useStackDetails()
    const { workspaceAccount } = useAppContext()
    const { protocol } = location
    const registerUrl = `${protocol}//${workspaceAccount?.url}/${stack?.url_slug}/register`

    const [isEligible, setIsEligible] = useState(false)
    const [isEnabled, setIsEnabled] = useState(
        stack?.options?.enable_open_signup_for_stacker_users || false
    )
    const [ineligibleReasons, setIneligibleReasons] = useState([])

    useMemo(() => {
        if (!data?.open_signup) return

        setIsEnabled(data.open_signup.is_enabled)
        setIsEligible(data.open_signup.is_eligible)
        setIneligibleReasons(data.open_signup.ineligible_reasons)
    }, [data])

    // Refetch eligibility status when the stack or account is updated
    // to make sure we always have the latest status
    useEffect(() => {
        invalidateStackDetails()
    }, [stack, workspaceAccount])

    return (
        <Flex column my={4} align="left">
            <Text fontWeight="bold" mb={2}>
                Open Signup
            </Text>

            <Text size="small" color={V4DesignSystem.colors.gray[600]} mb={2}>
                Allow users to sign up with any email at {''}
                <a href={registerUrl} rel="noopener noreferrer" target="_blank">
                    {registerUrl}
                    <Icon style={{ color: '#CCC', fontSize: '0.7em' }} icon="external-link-alt" />
                </a>
            </Text>
            <Flex>
                <Collapse isOpen={isEligible || !!isEnabled}>
                    <Text color={V4DesignSystem.colors.gray[600]} mr={3}>
                        Enable open signup
                    </Text>
                    <Switch
                        id="open-signup-num"
                        size="sm"
                        isChecked={value}
                        onChange={onChange}
                        mt="3px"
                        isDisabled={false}
                    />
                    <Collapse isOpen={!isEligible && !!isEnabled}>
                        <Banner variant="Warning" icon="warning" mt={2}>
                            <IneligibleReasonsMessage
                                isEnabled={isEnabled}
                                ineligibleReasons={ineligibleReasons}
                            />
                        </Banner>
                    </Collapse>
                </Collapse>
            </Flex>
            <Collapse isOpen={!!(!isEligible && !isEnabled)}>
                <Text size="small" color={V4DesignSystem.colors.gray[600]} mb={2}>
                    <IneligibleReasonsMessage
                        isEnabled={isEnabled}
                        ineligibleReasons={ineligibleReasons}
                    />
                </Text>
            </Collapse>
        </Flex>
    )
}
