import TextareaAutosize from 'react-autosize-textarea'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const TextArea = styled(TextareaAutosize)`
    min-height: 100px;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.fontColor};
    font-size: ${(props) => props.theme.fontSize};
    background-color: ${(props) => props.theme.inputBackgroundColor};
    border-radius: ${(props) => props.theme.elementBorderRadius};
    padding: ${(props) => props.theme[props.padding || 'small']};
    border: ${(props) => props.theme.dividerThickness}px solid
        ${(props) => props.theme.outlineColor};
    resize: none;

    ${(props) => props.fullWidth && 'width: 100%;'}

    ${(props) => props.maxHeight && `max-height: ${props.maxHeight};`}
    &:focus {
        outline: none;
    }
`

TextArea.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}

export default TextArea
