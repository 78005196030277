import React from 'react'

import { BoxProps, FlexProps } from '@chakra-ui/react'

import { Flex } from 'v2/ui'

import { SlidingPaneHeader } from './SlidingPaneHeader'

type SlidingPaneFrameProps = BoxProps & {
    title: string
    description: string
    contentAreaProps?: FlexProps
}
export const SlidingPaneFrame: React.FC<SlidingPaneFrameProps> = ({
    title,
    description,
    children,
    contentAreaProps,
    ...props
}) => {
    return (
        <Flex
            column
            bg="userInterface.accent.1200"
            wrap="nowrap"
            width="100%"
            height="100%"
            align="stretch"
            borderTopLeftRadius="10px"
            pl={1}
            {...props}
        >
            <SlidingPaneHeader title={title} description={description} />

            <Flex
                column
                borderTopLeftRadius="5px"
                borderBottomLeftRadius="5px"
                wrap="nowrap"
                flexGrow={1}
                flexShrink={1}
                minHeight={0}
                align="stretch"
                bg="userInterface.neutral.0"
                data-testid="sliding-pane-content"
                {...contentAreaProps}
            >
                {children}
            </Flex>
        </Flex>
    )
}
