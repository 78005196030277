import { useAppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/AppContextStore'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import {
    authedUserDomainFilter,
    buildQueryKey,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from './_helpers'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useNavigation'
const ENDPOINT = 'navigation/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
function getQueryKey() {
    return buildQueryKey(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useNavigation(options = {}) {
    // When query key changes due to previewing another role or user,
    // instead of reverting to an empty/loading state, keep the previous
    // data and just updated it when the results come in.
    const query_config = { ...STACK_QUERY_CONFIG, keepPreviousData: true, ...options }
    const { selectedStack, workspaceAccount } = useAppContext()
    const queryResult = useQuery(useQueryKey(), ENDPOINT, query_config, {
        disabledFn: () => authedUserDomainFilter(selectedStack, workspaceAccount),
    })

    return queryResult
}

export function useCreateNavigation() {
    return useCreateItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateNavigation()
        },
    })
}
export function useUpdateNavigation() {
    return useUpdateItem(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateNavigation()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}
export function useDeleteNavigation() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateNavigation()
        },
    })
}
export function invalidateNavigation() {
    // Want to include the stack id here otherwise we'll be invalidating for all the apps
    // which have been loaded
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}
export async function refetchNavigation() {
    // invalidate all instances of these queries
    await invalidateNavigation()
    // but only refetch the one that is for the current user
    await queryClient.refetchQueries(getQueryKey())

    return getCachedNavigation()
}

export function getCachedNavigation() {
    return queryClient.getQueryData(getQueryKey())
}
