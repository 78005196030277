// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import moment from 'moment'
import queryString from 'query-string'

type GetCalendarFiltersProps = {
    object?: ObjectDto
    startDate?: string
    endDate?: string
    display?: string
    defaultView?: string
    defaultDateOption?: string
    defaultDate?: string | null
}

const useGetCalendarFilters = ({
    object,
    startDate,
    endDate,
    display,
    defaultView = 'Month',
    defaultDateOption,
    defaultDate: customDefaultDate,
}: GetCalendarFiltersProps) => {
    const startDateField = object?.fields?.find((x) => x._sid === startDate) as FieldDto | undefined
    const endDateField = object?.fields?.find((x) => x._sid === endDate) as FieldDto | undefined
    const isMount = useRef(true)

    const startDateApiName = startDateField?.api_name
    const endDateApiName = endDateField?.api_name

    const history = useHistory()
    const location = useLocation()
    const query = useMemo(() => queryString.parse(location?.search), [location?.search])

    // Set the default date based on the config
    const today = new Date()
    let defaultDate = moment(defaultDateOption === 'Custom' ? customDefaultDate || today : today)
    switch (defaultDateOption) {
        case 'LastWeek':
            defaultDate = defaultDate.subtract(1, 'week')
            break
        case 'LastMonth':
            defaultDate = defaultDate.subtract(1, 'month')
            break
        case 'NextWeek':
            defaultDate = defaultDate.add(1, 'week')
            break
        case 'NextMonth':
            defaultDate = defaultDate.add(1, 'month')
            break
    }
    const defaultDateString = defaultDate.toISOString(true)

    const selectedDate: string = query['__calSelectedDate'] as string

    const [calendarFilter, setCalendarFilter] = useState({
        startDate: selectedDate || defaultDateString,
        endDate: selectedDate || defaultDateString,
        selectedDate: selectedDate || defaultDateString,
        currentView: (query['__calSelectedView'] as string) || defaultView,
    })

    const updateUrlParams = useCallback(
        (selectedDate, selectedView) => {
            const newQuery = { ...query }
            newQuery['__calSelectedDate'] = moment(selectedDate).toISOString()
            newQuery['__calSelectedView'] = selectedView
            history?.push({
                pathname: location?.pathname,
                hash: location?.hash,
                search: queryString.stringify(newQuery),
                state: location?.state,
            })
        },
        [history, location?.hash, location?.pathname, location?.state, query]
    )

    useEffect(() => {
        // Ignore the first mount
        if (isMount.current) {
            isMount.current = false
            return
        }
        if (display === 'calendar') {
            setCalendarFilter({
                startDate: defaultDateString,
                endDate: defaultDateString,
                selectedDate: defaultDateString,
                currentView: defaultView,
            })

            updateUrlParams(defaultDateString, defaultView)
        }
        // Only update if the admin has changed the default views
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDateOption, defaultView, customDefaultDate])

    const filterStartDate = calendarFilter.startDate
    const filterEndDate = calendarFilter.endDate

    // These are used to filter the records
    const calendarRecordFilters = useMemo(() => {
        if (!startDateField) return []
        let filters: Filter[] = []
        const startDateStartFilter =
            startDateField.type === 'date'
                ? moment(filterStartDate).format('YYYY-MM-DD')
                : filterStartDate
        const startDateEndFilter =
            startDateField.type === 'date'
                ? moment(filterEndDate).format('YYYY-MM-DD')
                : filterEndDate
        if (startDateApiName && !endDateApiName) {
            // Only a start date set
            // get records where the start date is greater and equal to the view start date
            // And the start date is less than or equal to the view end date
            filters.push({
                _id: Math.random(),
                field: startDateField,
                options: { value: startDateStartFilter, option: 'equalsOrGreaterThan' },
            })
            filters.push({
                _id: Math.random(),
                field: startDateField,
                options: { value: startDateEndFilter, option: 'equalsOrLessThan' },
            })
        }
        if (endDateApiName) {
            // start and end date set
            // get records where the start date is before or up to the view end date
            // and the end date is equals or greater than the view start date
            const endDateStartFilter =
                endDateField?.type === 'date'
                    ? moment(filterStartDate).format('YYYY-MM-DD')
                    : filterStartDate
            filters.push({
                _id: Math.random(),
                field: startDateField,
                options: { value: startDateEndFilter, option: 'equalsOrLessThan' },
            })
            filters.push({
                _id: Math.random(),
                field: endDateField,
                options: { value: endDateStartFilter, option: 'equalsOrGreaterThan' },
            })
        }

        return filters
    }, [
        endDateApiName,
        endDateField,
        filterEndDate,
        filterStartDate,
        startDateApiName,
        startDateField,
    ])

    //Add the calendar start and end date to the url params when updatedstartDateField.type
    useEffect(() => {
        if (display === 'calendar') {
            if (
                calendarFilter?.selectedDate !== query['__calSelectedDate'] ||
                calendarFilter?.currentView !== query['__calSelectedView']
            ) {
                updateUrlParams(calendarFilter?.selectedDate, calendarFilter?.currentView)
            }
        }
        // We only want to update the filters if the currentView or selected date changes
        // Including all dependencies can cause a loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarFilter?.currentView, calendarFilter?.selectedDate])

    useEffect(() => {
        // Update the view and date if we have new query params
        if (display === 'calendar') {
            const newSelectedDate = query['__calSelectedDate'] as string
            const newSelectedView = query['__calSelectedView'] as string

            if (!newSelectedDate && !newSelectedView) return

            if (
                newSelectedDate !== calendarFilter.selectedDate ||
                newSelectedView !== calendarFilter.currentView
            ) {
                setCalendarFilter((s) => ({
                    ...s,
                    selectedDate: newSelectedDate,
                    currentView: newSelectedView,
                }))
            }
        }
        // We only want to update the filters if the query has changed
        // Including all dependencies can cause a loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return { calendarRecordFilters, calendarFilter, setCalendarFilter }
}

export default useGetCalendarFilters
