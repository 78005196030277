import { invalidateAccounts } from 'data/hooks/accounts'

import { acceptHeaders, fetchAndReturn } from '../utils/utils'

type BillingPortalSession = {
    session_url: string
}
export const createBillingPortalSession = (returnUrl: string): Promise<BillingPortalSession> => {
    return fetchAndReturn(`subscriptions/billing-portal`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            return_url: returnUrl,
        }),
    }) as Promise<BillingPortalSession>
}

type Checkout = { successUrl: string; cancelUrl: string; priceId: string }

type CheckoutSession = {
    checkout_url: string
}

export const createCheckoutSession = ({
    successUrl,
    cancelUrl,
    priceId,
}: Checkout): Promise<CheckoutSession> => {
    return fetchAndReturn(`subscriptions/checkout`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            success_url: successUrl,
            cancel_url: cancelUrl,
            //need to change signature of this function in the BE
            subscription_item_id: priceId,
        }),
    }) as Promise<CheckoutSession>
}

export type Line = {
    description: string
    amount: number
    quantity: number
}

export type EstimateData = {
    total_amount: number
    currency: string
    tax_amount: number
    subtotal_amount: number
    billing_period_end_date: number
}

export const getPlanUpgradeEstimate = (priceId: string): Promise<EstimateData> => {
    return fetchAndReturn(`subscriptions/estimation?price_id=${priceId}`, {
        method: 'GET',
        headers: acceptHeaders,
    }) as Promise<EstimateData>
}

export const upgradePlan = (priceId: string): Promise<{}> => {
    return fetchAndReturn(`subscriptions/update-price`, {
        method: 'POST',
        headers: acceptHeaders,
        body: JSON.stringify({
            price_id: priceId,
        }),
    }).then(() => {
        invalidateAccounts()
    }) as Promise<{}>
}

export const resumeSubscription = (): Promise<{}> => {
    return fetchAndReturn(`subscriptions/resume`, {
        method: 'POST',
        headers: acceptHeaders,
    }).then(() => {
        invalidateAccounts()
    }) as Promise<{}>
}
