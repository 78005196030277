// @ts-strict-ignore
import React, { useContext, useMemo, useState } from 'react'
import { components } from 'react-select'

import { useAvailableDataSources } from 'features/workspace/useAvailableDataSources'

import { Button, Dropdown, Flex, Icon, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import DATA_PROVIDERS from '../../../admin/data-connector/dataProviderConfig'
import { ProviderIcon } from '../../../admin/data-connector/ProviderIcon'

import { DataGridContext } from './DataGridContext'

const { colors } = stackerTheme()

type DataSourcesDropdownProps = {
    selectedDataSource?: string
    setSelectedDataSource: (selectedDataSource: string) => void
    onEditDataSource: (dataConnection: DataConnectionDto) => void
}

export const DataSourcesDropdown: React.VFC<DataSourcesDropdownProps> = ({
    selectedDataSource,
    setSelectedDataSource,
    onEditDataSource,
}) => {
    const availableDataSources: any = useAvailableDataSources()
    const options = useMemo(
        () =>
            availableDataSources.map((source) => ({
                label: source.label,
                value: source._sid,
                dataConnection: source,
            })),
        [availableDataSources]
    )
    const [isOption, setIsOption] = useState(false)

    const { setReadOnly } = useContext(DataGridContext)
    return (
        <Dropdown
            options={options}
            value={selectedDataSource}
            onChange={(e) => {
                setSelectedDataSource(e)
            }}
            isClearable={false}
            components={{
                MenuList: (props) => (
                    <components.MenuList {...props} setIsOption={setIsOption} maxHeight="50vh" />
                ),
                Option: (props) => (
                    <ListItem
                        {...props}
                        onMouseOver={() => {
                            setIsOption(true)
                        }}
                        onEditDataSource={onEditDataSource}
                    />
                ),
                SingleValue: SelectedListItem,
            }}
            disableComponentOverrides
            controlStyle={{
                border: 'unset',
                fontWeight: 'bold',
                marginLeft: '-8px',
                minWidth: '50px',
                color: 'white',
                maxWidth: '200px',
            }}
            singleValueStyle={{ color: 'white' }}
            dropdownIndicatorProps={{ padding: '4px 2px' }}
            isSearchable={false}
            menuProps={{ width: 'fit-content', maxWidth: '320px' }}
            valueContainerProps={{ flexWrap: 'nowrap' }}
            ignoreOptionFocused={!isOption}
            onMenuOpen={() => setReadOnly(true)}
            onMenuClose={() => setReadOnly(false)}
        />
    )
}

export const SelectedListItem: React.FC<any> = (props) => {
    return <ListItemContents {...props} style={{ color: colors.userInterface.neutral[0] }} />
}

type ListItemContentsProps = {
    onEditDataSource: (dc: DataConnectionDto) => void
}
export const ListItemContents: React.FC<ListItemContentsProps & any> = ({
    onEditDataSource,
    ...props
}) => {
    const { data, isSelected, onMouseOver, onFocus } = props
    const { dataConnection } = data
    const { type } = dataConnection

    const { disallowConfigChanges } = DATA_PROVIDERS[type]

    const onOpenSettings = () => {
        onEditDataSource(dataConnection)
    }

    const isSettingsEnabled = isSelected !== undefined && !disallowConfigChanges

    return (
        <Flex
            justifyContent="space-between"
            flexWrap="nowrap"
            cursor="pointer"
            pointerEvents="auto"
            maxWidth="100%"
            onMouseOver={onMouseOver}
            onFocus={onFocus}
        >
            <Text
                size="sm"
                style={{
                    lineHeight: 1,
                    ...props.style,
                }}
                fontWeight={isSelected ? 'bold' : 'normal'}
                trimText
                flexGrow={1}
                flexShrink={1}
                minWidth={0}
            >
                <ProviderIcon
                    type={props.data.dataConnection.type}
                    serviceName={props.data.dataConnection.service_name}
                    display="inline-flex"
                    verticalAlign="middle"
                    size="1.2rem"
                    mr={2}
                />
                <span
                    style={{
                        verticalAlign: 'middle',
                        marginTop: '1px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {props.data.label}
                </span>
            </Text>
            {isSettingsEnabled && (
                <Button
                    variant="clear"
                    buttonSize="unset"
                    p={0}
                    width="18px"
                    height="18px"
                    ml={2}
                    mr={1}
                    onClick={onOpenSettings}
                    flexShrink={0}
                >
                    <Icon icon="cog" size="16px" color={colors.userInterface.neutral[700]} />
                </Button>
            )}
        </Flex>
    )
}

const ListItem: React.FC<ListItemContentsProps & any> = (props) => {
    // dropping these two handlers for perf reasons.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props
    return (
        <components.Option {...rest}>
            <ListItemContents {...rest} />
        </components.Option>
    )
}
