/* Code Quality: Not audited */

import React from 'react'
import Modal from 'react-modal'

import styled from '@emotion/styled'
import { css } from 'emotion'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Button from './Button'
import Container from './Container'
import Divider from './Divider'
import Section from './Section'

class ButtonModal extends React.Component {
    state = {
        contents: this.props.defaultState || {},
        open: this.props.open || false,
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }

        this.setState({ open: false })
    }

    open = () => this.setState({ open: true })

    UNSAFE_componentWillReceiveProps = ({ defaultState, open }) => {
        if (defaultState) {
            if (defaultState !== this.props.defaultState)
                this.setState({ contents: { ...defaultState, ...this.state.contents } })
        }

        if (open) {
            if (open !== this.state.open) this.setState({ open })
        }
    }

    render() {
        const modalStyle = css`
            background-color: ${this.props.theme.surfaceColor};
            border-radius: ${this.props.theme.elementBorderRadius};
            padding: ${this.props.theme.large};
            padding-bottom: ${this.props.theme.medium};
            box-shadow: ${this.props.theme.elementShadow};
            border: ${this.props.theme.elementBorder};
            max-height: 80vh;
            min-height: 400px;
            max-width: 1000px;
            height: max-content;
            width: 100%;
            margin: auto auto;
            z-index: 10000;
            position: relative;
            display: flex;
            flex-direction: column;
            &:focus {
                outline: none;
            }

            @media screen and (max-height: 768px) {
                max-height: 500px;
                margin: calc((100vh - 500px) / 2 - 32px) auto;
            }

            ${this.props.dialog &&
            `
            height: max-content;
            max-width: 600px;
            min-height: unset;
            margin: calc((100vh - 500px) / 2 - 32px) auto;
            max-height: calc(100% - 400px);
            overflow: auto;

            & hr {
                display: none;
            }
            `};
        `
        const overlayStyle = css`
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background-color: rgba(100, 100, 100, 0.75);
            z-index: 5000;
            padding: ${this.props.theme.medium};
            display: flex;
        `
        const defaultButton = (
            <Button onClick={this.open} {...this.props}>
                {this.props.label}
            </Button>
        )
        const setContentsState = (state, callback) => {
            this.setState.bind(this)({ contents: { ...this.state.contents, ...state } }, callback)
        }
        const renderProps = {
            open: this.open,
            close: this.close,
            setState: setContentsState,
            state: this.state.contents,
        }

        const button = this.props.button ? this.props.button(renderProps) : defaultButton
        const header = this.props.header && this.props.header(renderProps)
        const footer = this.props.footer && this.props.footer(renderProps)
        const contents = this.props.children && this.props.children(renderProps)
        const marginSize = this.props.dialog ? 'none' : 'large'
        return (
            <>
                {this.props.button === false ? '' : button}
                <Modal
                    className={modalStyle}
                    overlayClassName={overlayStyle}
                    isOpen={this.state.open}
                    onRequestClose={this.close}
                >
                    <Container>
                        <ModalHeader className={this.props.headerClassName}>{header}</ModalHeader>
                        <Button
                            style={{
                                lineHeight: 0.96,
                            }}
                            margin="none"
                            round
                            onClick={this.close}
                        >
                            <i className="fal fa-times" />
                        </Button>
                    </Container>
                    <Divider />
                    <Section maxSize={1} margin="none" className={this.props.contentClassName}>
                        {contents}
                    </Section>
                    {footer && (
                        <Section bottom margin={footer ? 'none' : marginSize} padding={marginSize}>
                            <Divider />
                            <Container className={this.props.footerClassName}>{footer}</Container>
                        </Section>
                    )}
                </Modal>
            </>
        )
    }
}

ButtonModal.displayName = 'ButtonModal'

export default withTheme(ButtonModal)

const ModalHeader = styled('div')`
    font-size: ${(props) => props.theme.headingTwoSize};
    color: ${(props) => props.theme.headingColor};
    font-weight: ${(props) => props.theme.headingWeight};
    font-family: ${(props) => props.theme.headingFont};
    margin: ${(props) => props.theme.small} 0px;
    flex-grow: 1;
`
