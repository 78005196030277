import { RenderOptions } from './types'

export const attributeShouldDisplayLabel = (
    field: FieldDto,
    renderOptions: RenderOptions,
    isEditing: boolean
) => {
    if (field.type == 'url' || renderOptions?.treatAsUrl) {
        // URL fields hide the field label if they are buttons, unless we are in edit mode when they render as regular fields.
        const displayAsButton = renderOptions && renderOptions.displayAsButton

        return isEditing && displayAsButton ? displayAsButton : !displayAsButton
    }

    return true
}
