/* Code Quality: Not audited */

import React from 'react'

class Collapsible extends React.Component {
    state = {
        open: false,
    }
    toggle = () => this.setState({ open: !this.state.open })
    render() {
        const children = this.props.children
        return this.props.container({
            children,
            toggle: this.toggle,
            isOpen: this.state.open,
        })
    }

    constructor(props) {
        super(props)
        this.state = { open: !!props.defaultOpen }
    }
}
export default Collapsible
