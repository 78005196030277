import React, { useLayoutEffect, useRef, useState } from 'react'

import without from 'lodash/without'
import PropTypes from 'prop-types'

import {
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'
import RolePicker from 'features/studio/ui/RolePicker'
import { replaceWith } from 'utils/utils'

import { Box, Button, Flex, Icon } from 'v2/ui'

import { Divider, SettingLabel, SettingRow } from '../../workspace/WorkspaceSettingsModalUi'

const ConditionalRoleShape = PropTypes.shape({
    // name of the role that should be assigned to the user
    role: PropTypes.string.isRequired,

    // AND-chained list of filters that have to be satisfied to set the role
    filters: PropTypes.array.isRequired,

    // filters formatted as required for the backend
    filters_formatted: PropTypes.array.isRequired,
})

const propTypes = {
    value: PropTypes.arrayOf(ConditionalRoleShape),
    onChange: PropTypes.func,
}

const defaultProps = {
    value: [],
}

function RoleRow({ scrollTo, value, onChange, onDelete, object, fields, defaultUserRole }) {
    const nodeEl = useRef()
    useLayoutEffect(() => {
        if (scrollTo) {
            nodeEl.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }
    }, [scrollTo, nodeEl])
    return (
        <Box ref={nodeEl}>
            <Flex direction="row" mt="5px" mb="12px">
                <RolePicker
                    variant="settings"
                    idField="api_name"
                    required
                    value={value.role || defaultUserRole}
                    onChange={(role) => onChange({ ...value, role })}
                    style={{ flex: 1 }}
                />
                <Box
                    onClick={onDelete}
                    role="button"
                    _hover={{ cursor: 'pointer', bg: 'gray.300' }}
                    ml={1}
                >
                    <Icon
                        display="inline"
                        icon="x"
                        role="button"
                        size="xs"
                        mx={2}
                        color="gray.400"
                    />
                </Box>
            </Flex>
            <Filters
                value={value.filters}
                onChange={(filters) =>
                    onChange({ ...value, filters, filters_formatted: formatFilters(filters) })
                }
                object={object}
                fields={fields}
                hideCurrentUserOption
                hideTheRecordFilter
                prefix="When"
                closeOnOuterAction={true}
            />
            <Divider dense />
        </Box>
    )
}

export default function ConditionalRoles({
    value: roles,
    onChange,
    object,
    fields,
    defaultUserRole,
}) {
    const [scrollTo, setScrollTo] = useState(null)
    return (
        <>
            {roles.length > 0 && (
                <>
                    <SettingRow
                        leftSide={<SettingLabel>Role assignment</SettingLabel>}
                        leftSideStyle={{
                            alignSelf: 'start',
                        }}
                        rightSide={
                            <>
                                {roles.map((role) => (
                                    <RoleRow
                                        key={role._id}
                                        value={role}
                                        object={object}
                                        fields={fields}
                                        onChange={(value) => onChange(replaceWith(roles, value))}
                                        scrollTo={role._id === scrollTo}
                                        defaultUserRole={defaultUserRole}
                                        onDelete={() => onChange(without(roles, role))}
                                    />
                                ))}
                            </>
                        }
                    />
                </>
            )}
            <SettingRow
                rightSide={
                    <div>
                        <Button
                            icon="add"
                            variant="adminSecondaryV4"
                            buttonSize="smDense"
                            onClick={() => {
                                const _id = Math.random()
                                const newRole = { _id, role: defaultUserRole }
                                onChange([...roles, newRole])
                                setScrollTo(_id)
                            }}
                            py={1}
                            px={2}
                            mt={1}
                            color="gray.400"
                            fontSize="sm"
                        >
                            Add conditional role
                        </Button>
                    </div>
                }
            />
        </>
    )
}

ConditionalRoles.propTypes = propTypes
ConditionalRoles.defaultProps = defaultProps
