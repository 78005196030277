// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { objectApi } from 'data/api/objectApi'
import { useObject, useObjects } from 'data/hooks/objects'
import { getCachedObjects } from 'data/hooks/objects/objectOperations'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import { withDataConnections } from 'data/wrappers/WithDataConnections'
import WithObject from 'data/wrappers/WithObject'
import { withStack } from 'data/wrappers/WithStacks'
import FieldEditorPopoverButton from 'features/admin/fields/FieldEditorPopoverButton'
import NewFieldConfigurationEditor from 'features/admin/settings/object/NewFieldConfigurationEditor'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import { Heading, Section } from 'legacy/v1/ui'

import { Banner, Box, Button, Checkbox, Flex, Text } from 'v2/ui'
import StackerButton from 'v2/ui/components/Button'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import DATA_PROVIDERS from '../../admin/data-connector/dataProviderConfig'
import { showSyncDataSourceSchemaModal } from '../DataSources/SyncDataSourceSchemaModal'

const colors = stackerTheme().colors

const canConnectToObject = (containerTable) => (sourceTable) => {
    // Cannot connect a table to itself
    if (containerTable._sid === sourceTable._sid) {
        return false
    }
    // Cannot connect a table to a connected table... yet!
    if (sourceTable.connected_tables_row_provider) {
        return false
    }
    return true
}

const ConnectedTableBanner = ({
    object,
    connectedProviderObject,
}: {
    object: ObjectDto | undefined
    connectedProviderObject: { object: ObjectDto | undefined }
}) => {
    const [isStackerTable, setIsStackerTable] = useState<boolean>()
    const [isConnected, setIsConnected] = useState<boolean>()
    const [connecting, setConnecting] = useState(false)
    const [disconnecting, setDisconnecting] = useState(false)
    const [selectedObjectId, setSelectedObjectId] = useState(null)
    const [saving, setSaving] = useState(false)
    const [showCannotConnectDetails, setShowCannotConnectDetails] = useState(false)
    const [error, setError] = useState('')
    const { data: allObjects } = useObjects()

    useEffect(() => {
        setIsStackerTable(!!object?.connection_options?.stacker_native_object)
        setIsConnected(!!connectedProviderObject?.object)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object, connectedProviderObject?.object])

    if (!isStackerTable || !object) return null

    let content

    const tablesConnected =
        allObjects?.filter((o) => o.connected_tables_row_provider === object?._sid) || []

    const onConnect = () => {
        setSaving(true)
        objectApi
            .connect(object._sid, selectedObjectId)
            .then(() => {
                refetchObjects().then(() => {
                    const objects = getCachedObjects()
                    const newObject = objects?.find((x) => x._sid === object._sid)
                    if (newObject?.connected_tables_row_provider === selectedObjectId) {
                        setConnecting(false)
                    } else {
                        setError('Could not connect table. Please contact support.')
                    }
                    setSaving(false)
                })
            })
            .catch(() => {
                setError('Could not connect table. Please contact support.')
                setSaving(false)
            })
    }

    const onDisconnect = () => {
        setSaving(true)
        objectApi
            .disconnect(object._sid)
            .then(() => {
                refetchObjects().then(() => {
                    const objects = getCachedObjects()
                    const newObject = objects?.find((x) => x._sid === object._sid)
                    if (!newObject?.connected_tables_row_provider) {
                        setDisconnecting(false)
                    } else {
                        setError('Could not disconnect table. Please contact support.')
                    }
                    setSaving(false)
                })
            })
            .catch(() => {
                setError('Could not disconnect table. Please contact support.')
                setSaving(false)
            })
    }

    if (connecting) {
        content = (
            <>
                <Text mb={2}>Choose a source table</Text>
                <Text mb={1} fontWeight="normal">
                    The existing records in this table will be replaced with the records in the
                    source table, but the data structure of {object.name} will stay the same. You
                    can then choose to map some or all of the fields from the source table to fields
                    on {object.name}
                </Text>
                <Box my={2} maxWidth="350px">
                    <ObjectPicker
                        value={selectedObjectId}
                        onChange={(id) => setSelectedObjectId(id)}
                        filter={canConnectToObject(object)}
                        controlStyle={{ background: 'white' }}
                    />
                </Box>
                <StackerButton
                    variant="Primary"
                    buttonSize="small"
                    icon="chain"
                    mr={1}
                    isLoading={saving}
                    disabled={!selectedObjectId}
                    onClick={onConnect}
                >
                    Connect
                </StackerButton>
                <StackerButton
                    mr={1}
                    variant="Secondary"
                    buttonSize="small"
                    onClick={() => {
                        setConnecting(false)
                        setError('')
                    }}
                >
                    Cancel
                </StackerButton>
            </>
        )
    } else if (disconnecting && object.connected_tables_row_provider) {
        const providerName = connectedProviderObject?.object?.name
        content = (
            <>
                <Text mb={2}>
                    Are you sure you want to disconnect {object.name}
                    {providerName ? ` from ${providerName}?` : '?'}
                </Text>
                <Text mb={2} fontWeight="normal">
                    All fields will be unmapped and any records that existed before the table was
                    connected{providerName ? ` to ${providerName}?` : ''} will be restored.
                </Text>
                <StackerButton
                    variant="Primary"
                    buttonSize="small"
                    icon="noChain"
                    mr={1}
                    isLoading={saving}
                    onClick={onDisconnect}
                >
                    Disconnect
                </StackerButton>
                <StackerButton
                    mr={1}
                    variant="Secondary"
                    buttonSize="small"
                    onClick={() => {
                        setDisconnecting(false)
                        setError('')
                    }}
                >
                    Cancel
                </StackerButton>
            </>
        )
    } else if (!isConnected) {
        // The connection must be invalid somehow because the object has a connected table sid but
        // loading the corresponding object has failed
        if (object.connected_tables_row_provider) {
            content = (
                <>
                    <Text>
                        Invalid connection: the source table no longer exists or is invalid.{' '}
                        <Button
                            variant="link"
                            fontSize={14}
                            color={V4DesignSystem.colors.stacker}
                            onClick={() => {
                                setDisconnecting(true)
                                setError('')
                            }}
                        >
                            Disconnect
                        </Button>
                    </Text>
                </>
            )
        } else if (tablesConnected.length > 0) {
            content = (
                <>
                    {tablesConnected.length > 1 ? 'Other tables are ' : 'Another table is '}
                    connected to this table.{' '}
                    <Button
                        variant="link"
                        size="sm"
                        color={V4DesignSystem.colors.stacker}
                        onClick={() => {
                            setShowCannotConnectDetails(!showCannotConnectDetails)
                        }}
                    >
                        {showCannotConnectDetails ? 'Hide Details' : 'Details'}
                    </Button>
                    {showCannotConnectDetails && (
                        <>
                            <Text fontWeight="normal" my={2}>
                                It is not possible to connect a table to a table that is itself
                                connected to another table. To configure {object.name} to use rows
                                from another table, disconnect the following tables:
                            </Text>
                            <ul>
                                {tablesConnected.map((t) => (
                                    <Text as="li" fontWeight="normal" key={t.name}>
                                        {t.name}
                                    </Text>
                                ))}
                            </ul>
                        </>
                    )}
                </>
            )
        } else {
            content = (
                <Flex gap={1}>
                    <Text>Populate this table using rows from another table.</Text>
                    <Button
                        variant="link"
                        size="sm"
                        color={V4DesignSystem.colors.stacker}
                        onClick={() => {
                            setConnecting(true)
                            setError('')
                            setSelectedObjectId(null)
                        }}
                    >
                        Connect a table
                    </Button>
                </Flex>
            )
        }
    } else {
        content = (
            <>
                <Text mb={2}>
                    Connected to {connectedProviderObject?.object?.name}.{' '}
                    <Button
                        variant="link"
                        fontSize={14}
                        color={V4DesignSystem.colors.stacker}
                        onClick={() => {
                            setDisconnecting(true)
                            setError('')
                        }}
                    >
                        Disconnect
                    </Button>
                </Text>
                <Text fontWeight="normal">
                    Map fields to bring data from {connectedProviderObject?.object?.name} into{' '}
                    {object.name}. Leave fields unmapped to store the data in {object.name}.
                </Text>
            </>
        )
    }

    return (
        <Banner icon="chain" variant="InformationSecondary" mb={4} alignItems="flex-start">
            <Box flexGrow={1}>
                {content}
                {error && (
                    <Text variant="error" fontWeight="normal" mt={2}>
                        {error}
                    </Text>
                )}
            </Box>
        </Banner>
    )
}

type AppModalDataFieldSettingsProps = {
    stackOptions: StackDto['options']
    dataConnections: DataConnectionDto[]
    objectId: string
    noPadding: boolean
    setDataSyncRequired?: () => void
}

const AppModalDataFieldSettings: React.FC<AppModalDataFieldSettingsProps> = ({
    dataConnections,
    objectId,
    noPadding,
    setDataSyncRequired,
}: AppModalDataFieldSettingsProps) => {
    const getDataConnection = (object, dataConnections) => {
        return dataConnections.find((dc) => dc._sid === object.data_connection)
    }
    const [showDeveloperDetails, setShowDeveloperDetails] = useState(false)

    const { selectedStack } = useAppContext()

    const [providerObjectId, setProviderObjectId] = useState()
    const connectedProviderObject = useObject(providerObjectId)
    const isConnectedTablesEnabled = selectedStack?.combined_optional_features?.connected_tables

    return (
        <WithObject objectId={objectId}>
            {({ object, changeField, bulkChangeFields, deleteField }) => {
                const objectDataConnection = getDataConnection(object, dataConnections)

                setProviderObjectId(object?.connected_tables_row_provider)

                const { allowSchemaSync: schemaSync } =
                    DATA_PROVIDERS[objectDataConnection?.type] || {}

                return (
                    <Section
                        style={{ width: '100%', marginBottom: 0, marginTop: 0 }}
                        noMargin={noPadding}
                        noPadding={noPadding}
                    >
                        <Section
                            style={{
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 0,
                                overflow: 'hidden',
                            }}
                            noMargin={noPadding}
                            noPadding={noPadding}
                        >
                            <Flex mb={4} justifyContent="space-between">
                                <Heading
                                    helpAlign="right"
                                    margin="none"
                                    padding="none"
                                    size="small"
                                >
                                    Fields
                                </Heading>

                                <Flex>
                                    <Checkbox
                                        id="field-developer-name-checkbox"
                                        small
                                        onChange={() =>
                                            setShowDeveloperDetails(!showDeveloperDetails)
                                        }
                                        value={showDeveloperDetails ? 'true' : undefined}
                                    />
                                    <CheckboxText
                                        as="label"
                                        htmlFor="field-developer-name-checkbox"
                                        size="fontS"
                                    >
                                        Show developer name
                                    </CheckboxText>
                                    {schemaSync && (
                                        <Button
                                            flexShrink={0}
                                            variant="adminSecondaryV4"
                                            buttonSize="sm"
                                            mx={2}
                                            icon="refresh"
                                            onClick={() => {
                                                showSyncDataSourceSchemaModal(
                                                    objectDataConnection._sid
                                                )
                                            }}
                                        >
                                            Schema
                                        </Button>
                                    )}
                                    <FieldEditorPopoverButton
                                        objectId={object?._sid}
                                        className={ONBOARDING_CLASSES.APP_SETTINGS_TABLE_NEW_FIELD}
                                    >
                                        Add field
                                    </FieldEditorPopoverButton>
                                </Flex>
                            </Flex>
                            {isConnectedTablesEnabled && (
                                <ConnectedTableBanner
                                    object={object}
                                    connectedProviderObject={connectedProviderObject}
                                />
                            )}
                            <NewFieldConfigurationEditor
                                object={object}
                                connectedProviderObject={connectedProviderObject}
                                objectDataConnection={objectDataConnection}
                                changeField={changeField}
                                deleteField={deleteField}
                                bulkChangeFields={bulkChangeFields}
                                showDeveloperDetails={showDeveloperDetails}
                                isConnectedTablesEnabled={isConnectedTablesEnabled}
                                setDataSyncRequired={setDataSyncRequired}
                            />
                        </Section>
                    </Section>
                )
            }}
        </WithObject>
    )
}

export default withStack(withDataConnections(AppModalDataFieldSettings))

const CheckboxText = styled(Text)`
    margin-right: 20px;
    margin-left: 10px;
    color: ${colors.neutral[900]};
    font-weight: 900;
    font-size: 15px;
`
