/* Code Quality: Not audited */

import React, { useMemo } from 'react'

import { cloneDeep } from 'lodash'

import WithViews from './WithViews'

const WithViewInner = ({ views, onChange, createView, children, ...props }) => {
    const matches = views.filter((view) => view._sid === props.viewId)
    const view = matches?.[0]

    // Instead of passing along the view object from the cache, we clone it here
    // so that if bad code mutates the view object in any way, the cache will not be
    // corrupted with these unsaved mutations and subsequent request for the view
    // from the cache will be sure to get the correct, clean view object.
    const clonedView = useMemo(() => (view ? cloneDeep(view) : null), [view])
    if (!matches.length) return props.loading ? props.loading : ''

    return children({
        view: clonedView,
        onChange: (dict) => onChange(view._sid, dict),
        createView,
        views,
        changeViews: onChange,
    })
}
const WithView = (props) => {
    return (
        <WithViews>
            {({ views, onChange, createView }) => {
                return (
                    <WithViewInner
                        views={views}
                        onChange={onChange}
                        createView={createView}
                        {...props}
                    >
                        {props.children}
                    </WithViewInner>
                )
            }}
        </WithViews>
    )
}

export default WithView
