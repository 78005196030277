/* Code Quality: OK */

import React from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'qs'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { fetchWithAuth } from 'data/utils/utils'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { Section } from 'legacy/v1/ui'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'
import publicAsset from 'utils/publicAsset'

import { Text } from 'v2/ui'

import SignupFrame from './ui/SignupFrame'

class SignupCreatingStackPage extends React.Component {
    state = { portal: null, title: 'Stacker', logo: '', icon: '' }

    loadingGif = publicAsset('/static/media/loading-rubix.gif')

    UNSAFE_componentWillMount() {
        const query = queryString.parse(this.props.location.search.slice(1)) // Slice to remove the "?"
        const { portal, color, logo, icon, template, ...otherParams } = query

        const stackUrl = localStorage.getItem('stack_url')
        const stackName = localStorage.getItem('stack_name')

        if (stackName === portal && stackUrl) {
            // If there is already a portal with the same name,
            // we will redirect automatically to that stack
            // this is to prevent people from creating multiple stacks
            // by pressing back button or something similar
            console.log(`Stack '${portal}' already exists, redirecting to ${stackUrl}`)
            window.location = stackUrl
            return
        }

        const studioToken = localStorage.getItem('studio_token')
        if (!studioToken) window.location = getUrl(Urls.Register)

        const theme = {
            brandColor: stackerTheme.brandColor,
            enableDefaultStackLogo: true,
        } // Defaults
        if (color && color !== 'null') {
            theme.brandColor = color
        }
        if (logo && logo !== 'null') {
            theme.logo = logo
        }
        if (icon && icon !== 'null') {
            theme.icon = icon
        }

        const stackOptions = {} // add default config from feature or {}

        stackOptions.domain = settings.USER_DOMAIN
        stackOptions.is_portal = true
        stackOptions.whitelist_new_users = true
        stackOptions.theme = theme
        stackOptions.signup_params = otherParams

        if (query.wa === '1') {
            stackOptions.workspace_app = true
        }

        const processResponse = (response) =>
            response.json().then((res) => {
                const landingPage = this.stackLandingPage()
                const newStackUrl =
                    res.base_url + `${getUrl(Urls.AdminOnboardingAuth)}?redirect=` + landingPage
                const protocol = newStackUrl.includes('.local') ? 'http' : 'https'

                // get the amplitude device id to help track across domains
                // @ts-ignore
                const ampDeviceId = window.amplitude?.getInstance()?.options?.deviceId

                // get the user id
                const user =
                    JSON.parse(localStorage.getItem('studio_user')) ||
                    JSON.parse(localStorage.getItem('user'))
                const userId = user?._sid

                // Redirect to the stack on an absolute URL
                const redirect = `${protocol}://${newStackUrl}/?amp_device_id=${ampDeviceId}&ajs_uid=${userId}`

                localStorage.setItem('stack_name', portal)

                this.loginAndRedirect(studioToken, redirect)
            })

        if (template) {
            fetchWithAuth(`clone/?stack_name=${portal}&stack_id=${template}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(processResponse)
        } else {
            fetchWithAuth('stacks/', {
                method: 'POST',
                body: JSON.stringify({
                    name: portal,
                    origin: window.location.host,
                    options: stackOptions,
                    account: query.account,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(processResponse)
        }
    }

    stackLandingPage = () => {
        const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
        if (query.type === 'salesforce') {
            return getUrl(Urls.AdminOnboardingSalesforceSetup)
        } else if (query.type === 'gsheets') {
            return getUrl(Urls.AdminOnboardingGsheetsSetup)
        } else {
            if (get(this.props.stack, 'options.workspace_app')) {
                return getUrl(Urls.AdminOnboardingConnection)
            }
            return getUrl(Urls.AdminOnboardingPrep)
        }
    }

    loginAndRedirect = (studioToken, redirect) => {
        this.props.userActions
            .requestStudioTemporaryAuthToken(studioToken, redirect)
            .then((response) => {
                if (response.redirect) {
                    const joiner = response.redirect.indexOf('?') === -1 ? '?' : '&'
                    // Using the auth token directly here permits logging in directly to the dashboard, without the bounce through the login page.

                    const stackUrl = `${response.redirect}${joiner}auth_token=${response.auth_token}`
                    localStorage.setItem('stack_url', stackUrl)
                    window.location = stackUrl
                }
            })
    }

    render() {
        return (
            <SignupFrame hideLogo>
                <Section
                    style={{ alignItems: 'center', height: '100%', justifyContent: 'center' }}
                    margin="medium"
                    padding="none"
                >
                    <Image src={this.loadingGif} />
                    <Text variant="onboardingTitle" mt={[4, null, null, 8]} mb={[4, null, null, 8]}>
                        Creating your app
                    </Text>
                    <Text variant="onboardingText" textAlign="center" maxWidth="70%">
                        Hang tight. This might take a minute or two.
                    </Text>
                </Section>
            </SignupFrame>
        )
    }
}

SignupCreatingStackPage.propTypes = {
    location: PropTypes.object.isRequired, // From withRouter
    userActions: PropTypes.object.isRequired, // From withRouter
}

export default withUser(withRouter(withStack(SignupCreatingStackPage)))

const Image = styled('img')`
    vertical-align: middle;
    height: 128px;
    width: 128px;
    margin: -20px auto 0px;
`
