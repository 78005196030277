import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const Card = styled('div')`
    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.surfaceColor};
    border-radius: ${(props) => props.theme.elementBorderRadius};
    box-shadow: ${(props) => get(props.theme, 'shadows.sm') || props.theme.elementShadow};
    border: ${(props) => props.theme.elementBorder};

    margin: ${(props) => props.theme[props.margin || '']};
    padding: ${(props) => props.theme[props.padding || 'medium']};

    cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};

    ${(props) =>
        props.flushTopLeft &&
        `margin-left: 0;
         margin-top: 0;
        `};
`

Card.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}

Card.displayName = 'Card'

export default Card
