import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import styled from '@emotion/styled'
import { debounce } from 'lodash'

import { fetchWithAuth } from 'data/utils/utils'
import { StackIcon } from 'features/AppBar/StackIcon'
import { usePotentialBaseUrl } from 'features/studio/stacks/usePotentialBaseUrl'

// @ts-ignore
import { Collapse, Flex, Heading, Modal, Text } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import DropdownForm from '../../../ui/forms/DropdownForm'
// @ts-ignore
import Form from '../../../ui/forms/Form'
// @ts-ignore
import InputForm from '../../../ui/forms/InputForm'
// @ts-ignore
import SubmitButton from '../../../ui/forms/SubmitButton'
import useNavigationColor from '../../../v2/ui/hooks/useNavigationColor'
import ColorPickerForm, { defaultColor } from '../forms/ColorPicker'
import IconPickerForm from '../forms/IconPicker'

type Watchables = 'icon' | 'brandColor' | 'name' | 'sideBarColor' | 'workspace'

type WorkspaceOnboardingModalProps = {
    selectedStack: any
    workspaceAccount: any
    userName: string
    onChangeStack: (name: string, brandColor: string, icon: any) => void
    onChangeAccount: (name: string, navColor: string, brandColor: string) => void
    onSubmit: (toUpdate: { [key in Watchables]: any }) => void
}

const Divider = styled('div')`
    height: 1px;
    background: #f1f1f1;
    margin-top: 16px;
    margin-bottom: 16px;
`

export const getWsBaseUrl = (potentialBaseUrl: string | undefined) => {
    const [accountWorkspaceSubdomain, ...rest] = (potentialBaseUrl || '').split('.')
    return potentialBaseUrl ? `https://${accountWorkspaceSubdomain}.${rest.join('.')}` : ''
}

export const getPlaceholderWsUrl = () => {
    return window.location.origin.replace('studio', 'workspace')
}

export const WorkspaceAndStackOnboardingModal: React.FC<WorkspaceOnboardingModalProps> = (
    props
) => {
    const { selectedStack, workspaceAccount } = props
    return (
        <Modal size="450px" isOpen={true} showCloseButton={false}>
            <Form
                options={{
                    mode: 'onSubmit',
                    defaultValues: {
                        icon: selectedStack?.options?.theme.icon || 'faBook',
                        brandColor: selectedStack?.options?.theme?.brandColor || defaultColor,
                        name: selectedStack?.name,
                        sideBarColor: workspaceAccount?.options?.theme?.navColor || 'dark',
                        workspace: workspaceAccount?.name,
                    },
                }}
                onSubmit={props.onSubmit}
            >
                <Body
                    onChangeAccount={props.onChangeAccount}
                    onChangeStack={props.onChangeStack}
                    userName={props.userName}
                    selectedStack={selectedStack}
                    workspaceAccount={workspaceAccount}
                />

                <SubmitButton
                    variant="adminPrimaryV4"
                    disabled={false}
                    aria-label="Continue"
                    style={{ width: '100%' }}
                >
                    Continue
                </SubmitButton>
            </Form>
        </Modal>
    )
}

const PreviewRoot = styled(Flex)`
    border-radius: 16px;
    height: 120px;
    width: 120px;
    background: #f6f6f6;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const PreviewIcon = styled(Flex)`
    border-radius: 8px;
    height: 64px;
    width: 64px;
    justify-content: center;
    align-items: center;
`

const PreviewText = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    width: 100px;
`

const AppIconPreview: React.FC<{ stack: any }> = (props) => {
    const theme = useNavigationColor(props.stack.options?.theme ?? {})

    const context = useFormContext()
    const [brandColor, name] = context.watch(['brandColor', 'name'])
    return (
        <PreviewRoot>
            <PreviewIcon
                style={{
                    background: brandColor,
                    marginBottom: 12,
                    transition: 'background 0.3s',
                }}
            >
                <StackIcon isSelected={true} theme={theme} stack={props.stack} size={36} />
            </PreviewIcon>
            <PreviewText style={{ color: name ? '#3e3f41' : '#cfcfcf' }}>
                {name || 'App name'}
            </PreviewText>
        </PreviewRoot>
    )
}

const PreviewContainer = styled('div')`
    margin-left: 36px;
    align-self: flex-end;

    @media (max-width: 500px) {
        margin-left: 12px;
    }
`

type BodyProps = {
    selectedStack: any
    workspaceAccount: any
    userName: string
    onChangeStack: (name: string, brandColor: string, icon: any) => void
    onChangeAccount: (name: string, navColor: string, brandColor: string) => void
}

const Body: React.FC<BodyProps> = (props) => {
    const context = useFormContext()

    const [brandColor, icon, name, workspace, sideBarColor] = context.watch([
        'brandColor',
        'icon',
        'name',
        'workspace',
        'sideBarColor',
    ])

    const [domainError, setDomainError] = useState<string | undefined>(undefined)
    const [potentialBaseUrl, setPotentialBaseUrl] = useState<string | undefined>(undefined)
    usePotentialBaseUrl(workspace, setPotentialBaseUrl, setDomainError)
    const { appUrl } = usePotentialAppUrl(name)
    const placeholderUrl = getPlaceholderWsUrl()
    const baseUrl = getWsBaseUrl(potentialBaseUrl)

    useEffect(() => {
        props.onChangeStack(name, brandColor, icon)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, brandColor, icon])

    useEffect(() => {
        props.onChangeAccount(workspace, sideBarColor, brandColor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace, sideBarColor, brandColor])

    const getShownUrl = () => {
        if (workspace) {
            if (baseUrl) {
                return baseUrl + '/' + appUrl
            }
            return 'loading...'
        }

        return placeholderUrl + '/' + appUrl
    }

    const isMobile = useIsMobile()

    return (
        <>
            <Flex row>
                <Flex
                    direction={'column'}
                    style={{
                        width: '100%',
                        marginBottom: 4,
                        paddingBottom: 16,
                        borderBottom: '1px solid #f1f1f1',
                    }}
                >
                    <Heading variant="modal" value={`Welcome, ${props.userName}`} />
                    <Text style={{ textAlign: 'center' }}>
                        Create a first app in your new workspace.
                    </Text>
                </Flex>
                <Flex flexGrow={1} wrap="nowrap">
                    <Flex
                        flexGrow={1}
                        column
                        align="stretch"
                        wrap="nowrap"
                        overflowY="auto"
                        maxHeight={['90%', null, null, 600]}
                    >
                        <InputForm
                            autoFocus={true}
                            name="name"
                            label="App name"
                            placeholder="e.g. Projects"
                            errorMessages={{ required: 'Please enter a name for your app' }}
                            required
                            variant="authentication"
                        />
                        <Flex wrap={'nowrap'}>
                            <IconPickerForm name="icon" label="App Icon" />
                            <div style={{ maxWidth: 15, flexGrow: 1 }} />
                            <ColorPickerForm name="brandColor" label="Color" />
                        </Flex>
                    </Flex>
                    <PreviewContainer>
                        <AppIconPreview stack={props.selectedStack} />
                    </PreviewContainer>
                </Flex>
            </Flex>
            <Divider />
            <Flex>
                <InputForm
                    style={{ flexGrow: '1', marginRight: 16 }}
                    name="workspace"
                    label="Workspace or organization name"
                    placeholder="e.g. Bells Digital"
                    errorMessages={{ required: 'Please enter a value' }}
                    required
                    variant="authentication"
                />
                <DropdownForm
                    name="sideBarColor"
                    label="Sidebar color"
                    isClearable={false}
                    required
                    options={[
                        { value: 'brand', label: 'App color' },
                        { value: 'dark', label: 'Dark grey' },
                        { value: 'light', label: 'Light' },
                    ]}
                />
            </Flex>
            <Collapse isOpen={!!workspace}>
                {domainError ? (
                    <Text variant="error" style={{ marginTop: 12 }}>
                        {domainError}
                    </Text>
                ) : (
                    <Text
                        data-testid="workspace-onboarding.workspace-url-dsiplay"
                        style={{ marginTop: 12, color: workspace ? '#464646' : '#cfcfcf' }}
                    >
                        <span>App will be at{isMobile ? ':' : ' '}</span>
                        {isMobile ? <br /> : null}
                        <span
                            style={{
                                background: workspace ? '#efedfa' : undefined,
                                color: workspace ? '#5c69d5' : undefined,
                            }}
                        >
                            {getShownUrl()}
                        </span>
                    </Text>
                )}
            </Collapse>
            <Divider />
        </>
    )
}

const usePotentialAppUrl = (
    appName: string | undefined
): { appUrl: string | undefined; error: string | undefined } => {
    const [state, setState] = useState<{ appUrl: string | undefined; error: string | undefined }>({
        appUrl: undefined,
        error: undefined,
    })
    const { current: getPotentialAppUrl } = useRef(
        debounce<(appName: string) => Promise<void>>(async (appName) => {
            if ((appName || '').trim() === '') {
                return setState({ appUrl: '', error: undefined })
            }

            let errorMsg

            try {
                const res = await fetchWithAuth(
                    `stack/check-name/?name=${encodeURIComponent(appName)}`
                )

                const successful = res.status < 400

                if (successful) {
                    const { url_slug } = await res.json()
                    return setState({ appUrl: url_slug, error: undefined })
                }

                // specific error handling based on res status goes here
                //
                // if not handled, falls through to a generic error message
                if (res.status === 400) {
                    // a 400 ValidationError indicates that this particular org name would clash with a 'strict' reserved subdomain
                    // i.e. we just won't allow it at all
                    //
                    // NOTE purposefully obscuring any techy details about subdomains and reserved keywords,
                    // just say org name is taken. This is only really half true because outside of reserved subdomains
                    // org name duplication is fine (for now) but it's a lot easier to communicate!

                    errorMsg = 'This name is taken. Please choose another.'
                }
            } catch (err) {
                // log and fall through on any unexpected error
                console.error('Error getting url preview', err)
            }

            return setState({
                appUrl: undefined,
                error:
                    errorMsg ??
                    'There was an issue previewing the URL for this organisation name. Please wait a moment and try again.',
            })
        }, 500)
    )

    useEffect(() => {
        getPotentialAppUrl(appName ? appName.trim() : '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appName])

    return state
}
