// @ts-strict-ignore
import { useCallback } from 'react'
import { UseQueryOptions } from 'react-query'

import settings from 'app/settings'
import JobApi from 'data/api/jobApi'
import { REACT_QUERY } from 'data/utils/constants'
import { fetchWithAuth } from 'data/utils/utils'

import { STACK_LIST_NAME } from './stacks/stackConstants'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { workspaceGroups } from './accounts'

const ENDPOINT = 'stacks/'

export function useStacks(options: UseQueryOptions<StackDto[]> = {}) {
    const queryResult = useQuery<StackDto[]>(
        STACK_LIST_NAME,
        ENDPOINT,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS },
        {
            bypassMatchingStackCheck: true,
            bypassPreviewAndImpersonation: true,
        }
    )

    return queryResult
}

export function useCreateStack() {
    return useCreateItem<StackDto>(
        STACK_LIST_NAME,
        ENDPOINT,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(REACT_QUERY.roles.listName)
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}
export function useUpdateStack() {
    return useUpdateItem<StackDto>(
        STACK_LIST_NAME,
        ENDPOINT,
        {},
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
            // specify the stack id for the request based on the stack
            // we're updating, not the stack we currently are viewing (which may be none)
            provideFetchOptions: ({ id: stackId }) => ({ stackId }),
        },
        false
    )
}
export function useUpdateStackOptions() {
    const { mutateAsync: updateStack } = useUpdateStack()

    return useCallback(
        (stack: StackDto, optionsPatch: Partial<StackDto['options']>) => {
            const { _sid: id, options } = stack
            return updateStack({ id, patch: { options: { ...options, ...optionsPatch } } })
        },
        [updateStack]
    )
}
export function useDeleteStack() {
    return useDeleteItem<StackDto>(STACK_LIST_NAME, ENDPOINT, undefined, {
        // Submit this request using the studio user's token
        // and ignore any impersonation or role previewing.
        bypassPreviewAndImpersonation: true,
    })
}

export type SharingSettingsPatch = {
    [key: string]: { deleted?: boolean; role?: string }
}

export function useUpdateStackSharingSettings() {
    const { mutateAsync: updateStack } = useUpdateStack()

    const method = useCallback(
        (stack: StackDto, patch: SharingSettingsPatch, additionalData?: any): Promise<StackDto> => {
            const sharing = Object.keys(patch).reduce(
                (result, key) => {
                    const record = patch[key]
                    const value = record.deleted ? null : record.role
                    // if this is a group, update the groups list
                    if (workspaceGroups.find((x) => x._sid === key)) {
                        result.groups[key] = value
                        // Otherwise, update the users list
                    } else {
                        result.users[key] = value
                    }
                    return result
                },
                { groups: {}, users: {} }
            )

            return updateStack({
                id: stack._sid,
                patch: { sharing_patch: sharing, ...additionalData },
            })
        },
        [updateStack]
    )

    return method
}

// Triggers a sync_users task on the back end which syncs the users of a stack
// with any connected user tables. Returns a promise that resolves when the task completes.
export function useSyncUsers() {
    const method = useCallback((onProgress?: (job: any) => void): Promise<void> => {
        return fetchWithAuth('stack/sync-users/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                const jobId = data?.job
                // @ts-ignore
                return JobApi.waitForJob(jobId, { progress: onProgress }).result
            })
            .then(() => undefined)
    }, [])

    return method
}
export function createNewStackData(
    accountId: string,
    userId: string,
    name?: string,
    icon: string = 'faBook',
    logo?: string,
    navColor?: string,
    brandColor?: string,
    workspaceRole?: string
) {
    return {
        account_id: accountId,
        name,
        options: {
            domain: settings.USER_DOMAIN,
            is_portal: true,
            workspace_app: true,
            multi_data_source: true,
            roles__enabled: false,
            enable_comment_feed: true,
            stacker_relationships: true,
            enable_new_app_settings: true,
            enable_many_to_many: true,
            charts: false,
            show_app_users_splash: true,
            enable_nav_icons: true,
            data_location_modal_dismissed: false,
            theme: {
                logo,
                icon,
                navColor: undefined,
                brandColor,
            },
            external_access: { navColor: 'light' },
        },
        sharing: {
            users: { [userId]: 'internal_admin' },
            groups: workspaceRole ? { workspace: workspaceRole } : {},
        },
    }
}
