/* Code Quality: Not audited */

import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getUrl } from 'app/UrlService'
import WithApp from 'data/wrappers/WithApp'
import WithFeatures from 'data/wrappers/WithFeatures'
import WithObject from 'data/wrappers/WithObject'
import WithRecord from 'data/wrappers/WithRecord'
import { Text } from 'legacy/v1/ui'

const cancelDoubleSlash = (url) => url.replace('//', '/')

class RecordLookup extends React.Component {
    makeUrl = (display, url) => {
        const displayText = display && display.toString() ? display.toString() : '-'
        const contents = this.props.inline ? (
            displayText
        ) : (
            <Text {...this.props}>{displayText}</Text>
        )
        if (this.props.noLink) {
            return contents
        } else {
            return <Link to={getUrl(url)}>{contents} </Link>
        }
    }

    render() {
        return (
            <WithRecord recordId={this.props.recordId} loading="-">
                {({ record, primaryField }) => {
                    // If there was an error on the fetch, we don't try to load the object
                    if (record._fetch_failed || !record._object_id) return '-'

                    if (record._object_id === 'page') {
                        // If it's a page, we need to get the app's url to link directly to it
                        return (
                            <WithApp appId={record.app_id}>
                                {({ app }) => {
                                    return this.makeUrl(
                                        record[primaryField.api_name],
                                        cancelDoubleSlash(app.url + record.url)
                                    )
                                }}
                            </WithApp>
                        )
                    }
                    return (
                        <WithFeatures>
                            {({ features }) => (
                                <WithObject objectId={record._object_id}>
                                    {({ object }) => {
                                        let url
                                        if (object._sid === 'app') {
                                            // Go to the relevant studio page
                                            url = `/apps/${record._sid}`
                                        } else if (object._sid === 'object') {
                                            // Go to the relevant studio page
                                            url = `/table/${record._sid}`
                                        } else if (object._sid === 'data_connection') {
                                            // Go to the relevant studio page
                                            url = `/data/connection/${record._sid}`
                                        } else {
                                            // It's a record, so by convention its detail page will be
                                            // whatever app we're in /object.api_name/{id}

                                            const feature = features.find(
                                                (f) => f._sid === object.feature_id
                                            )
                                            const featureUrl = feature ? feature.url : '/'
                                            url = `${featureUrl}/view/${record._sid}`
                                        }
                                        return this.makeUrl(record[primaryField.api_name], url)
                                    }}
                                </WithObject>
                            )}
                        </WithFeatures>
                    )
                }}
            </WithRecord>
        )
    }
}

RecordLookup.propTypes = {
    recordId: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    noLink: PropTypes.bool,
}

RecordLookup.defaultProps = {
    inline: false,
    noLink: false,
}

export default RecordLookup
