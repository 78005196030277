/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */

import React from 'react'

import { jsx } from '@emotion/react'
import get from 'lodash/get'

import WithObject from 'data/wrappers/WithObject'
import { withObjects } from 'data/wrappers/WithObjects'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import RecordList from 'features/views/RecordList'

import { Box, Collapse, ConfigureWidgetPlaceholder } from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'

import RecordListEditor from './RecordListEditor'

// shows the placeholder in edit mode if an invalid list option is set
const RelatedListPlaceholder = ({ showAttributePopover, isEditing }) => {
    if (isEditing) {
        return (
            <ConfigureWidgetPlaceholder
                message="Related record list"
                subtitle="List data related to this record"
                Icon={SVGIcons.List}
                onClick={showAttributePopover}
            />
        )
    }

    return null
}

const _DetailViewRelatedList = (props) => {
    const { attrs, showAttributePopover, context } = props

    // Note: new style configs store the target object id in listObjectId regardless
    // of listType. However for backwards compatibility, we check the objectId property
    // if we are not in "all records" mode and we don't have a listObjectId.
    let listObjectId =
        attrs.listType === 'all' ? attrs.listObjectId : attrs.listObjectId || attrs.objectId
    let fieldObjectId = attrs.objectId

    const { parentListViewIds = [] } = context

    const isEditing = get(props, 'context.editor.isEditing')

    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    // stops endless loop if trying to request the same view as it's parent view
    if (parentListViewIds.includes(attrs.viewId) && !isEditing) {
        console.log(
            `Stopping record list rendering due to duplicate view id in the tree. View Id ${attrs.viewId}`
        )
        return null
    }

    let fieldId = attrs.fieldId
    let isExternalField = false
    // New data format is to store a field object that has the relevant
    // info about the relationshp field. If we have that, get the info out of it here.
    if (attrs.field) {
        fieldId = attrs.field.fieldId
        isExternalField = attrs.field.isExternalField || props.isExternalField
        fieldObjectId = attrs.field.objectId
    }

    if (
        isEditing &&
        ((attrs.listType !== 'all' && !fieldId) ||
            (attrs.listType === 'all' && !listObjectId) ||
            !attrs.viewId ||
            parentListViewIds.includes(attrs.viewId))
    ) {
        return (
            <RelatedListPlaceholder
                isEditing={isEditing}
                showAttributePopover={showAttributePopover}
            />
        )
    }

    if (hidden) return null

    return (
        <WithObject objectId={fieldObjectId}>
            {({ object }) => {
                let field = {}
                if (attrs.listType !== 'all') {
                    field = object.fields.find(
                        (f) => f._sid === fieldId && !f.connection_options?.is_disabled
                    )
                    if (!field)
                        return (
                            <RelatedListPlaceholder
                                isEditing={isEditing}
                                showAttributePopover={showAttributePopover}
                            />
                        )

                    // If the target object isn't available
                    // then don't show any content at all.
                    if (
                        !props.objects ||
                        !props.objects.find((obj) => obj._sid === field.options?.lookup_target)
                    ) {
                        return (
                            <RelatedListPlaceholder
                                isEditing={isEditing}
                                showAttributePopover={showAttributePopover}
                            />
                        )
                    }
                }

                return (
                    <Collapse isOpen={!hidden} duration={0.4}>
                        <Box mb={4}>
                            <RecordList
                                isExternalField={isExternalField}
                                title={attrs.title}
                                field={field}
                                record={context.record}
                                viewId={attrs.viewId}
                                editing={context.view.editing}
                                creating={context.view.creating}
                                mayCreateRecords={attrs.mayCreateRecords}
                                listType={attrs.listType}
                                objectId={listObjectId}
                                parentListViewIds={context.parentListViewIds}
                                parentDetailViewIds={context.parentDetailViewIds}
                                recordListAttrs={attrs}
                            />
                        </Box>
                    </Collapse>
                )
            }}
        </WithObject>
    )
}

const DetailViewRelatedList = withObjects(_DetailViewRelatedList)

DetailViewRelatedList.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        field: 'content',
        Element: RecordListEditor,
        simple: true,
        fullHeight: true,
    }),
]

export default DetailViewRelatedList
