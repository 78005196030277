import React from 'react'

import Container from 'v2/ui/components/Container'
import EmptyState from 'v2/ui/components/EmptyState'
import { EmptyView } from 'v2/ui/svgs'

import ListViewTitle from './ListViewTitle'

type ListViewObjectNotAccessibleProps = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    display?: string
    title?: string
    showControls?: boolean
    isRecordList?: boolean
    isMobile?: boolean
}

const ListViewObjectNotAccessible: React.FC<ListViewObjectNotAccessibleProps> = ({
    setConfig,
    display,
    title,
    showControls,
    isRecordList = false,
    isMobile,
}) => (
    <>
        <ListViewTitle
            display={display}
            showControls={showControls}
            title={title}
            setConfig={setConfig}
            isMobile={isMobile}
        />
        <Container>
            <EmptyState name="records" compact={isRecordList} svg={EmptyView} />
        </Container>
    </>
)

export default ListViewObjectNotAccessible
