// @ts-strict-ignore
/* Code Quality: Not audited */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import queryString from 'qs'
import CreateViewControls from 'v2/views/Create/CreateViewControls'
import processAutofill from 'v2/views/Create/processAutofill'
import FieldLayoutEditor from 'v2/views/FieldLayoutEditor'
import { determineIsBlockDisabled } from 'v2/views/utils/determineIsBlockDisabled'
import filterBlockTree from 'v2/views/utils/filterBlockTree'
import getUpdatedRecords from 'v2/views/utils/getUpdatedRecords'
import optimisticallyUpdateRelatedRecord from 'v2/views/utils/optimisticallyUpdateRelatedRecord'
import { scrollToInvalidField } from 'v2/views/utils/scrollToInvalidField'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { getUrl } from 'app/UrlService'
import { useRecordActions } from 'data/hooks/objects'
import { getCachedRecord } from 'data/hooks/records/getCachedRecord'
import { useUserRecord } from 'data/hooks/users/main'
import { withObject } from 'data/wrappers/WithObject'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { withViews } from 'data/wrappers/WithViews'
import { GlobalCallbackKeys } from 'features/commandbar/commandbar-keys'
import CommandBarShortcut from 'features/commandbar/CommandBarShortcut'
import BlockSelectorPortal from 'features/utils/BlockSelectorPortal'
import { LoadingState } from 'features/views/List/ui/utils'
import ViewEditPane from 'features/views/ViewEditPane'
import useTrack from 'utils/useTrack'

import { Box, Button, Collapse, ConditionalWrapper, ContainerLabel, Flex, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import { findWidgetBlockById } from '../utils/findWidgetBlockById'

import { useCreateViewParentUrl } from './useCreateViewParentUrl'

type Props = {
    config: any
    record: any
    showControls: boolean
    onChange: (data: any) => void
    recordActions: any
    view: any
    feature: any
    stackOptions: any
    doNotRedirect: boolean
    onCreate: (id: string, message?: string) => void
    hideTitle: boolean
    setModalActions: any
    inlineCreate: boolean
    queryParams: any
    fromDetailView: boolean
}

// props injected by HOC
type HocProps = {
    object: any
    objects: any
    views: any
    stack: any
}

const defaultFormState = {
    showErrors: false,
    isDirty: false,
    isSaving: false,
    valid: {},
    saveError: false,
    validationError: false,
    autofillDone: false,
    autoSave: false,
    triggeredSave: false,
}

const resetFieldValue = (value) => {
    if (Array.isArray(value)) {
        return []
    }
    return ''
}
const initRecordValues = (record) =>
    Object.entries(record).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: resetFieldValue(value) }),
        {}
    )

const isChanged = (before, after) =>
    Object.entries(after).some(([key, nextValue]) => {
        const originalValue = before[key]
        // The value is initially undefined for new records, but it becomes either a string or array once it
        // has been changed
        const fieldIsEmptyArray = Array.isArray(nextValue) && nextValue.length === 0
        const fieldIsEmptyString = nextValue === ''
        const fieldIsBlank = fieldIsEmptyArray || fieldIsEmptyString
        const valueIsBlankAndRecordIsNew = originalValue === undefined && fieldIsBlank
        return originalValue !== nextValue && !valueIsBlankAndRecordIsNew
    })

const CreateView = ({
    config,
    record = {},
    showControls,
    onChange,
    feature,
    stackOptions,
    doNotRedirect,
    onCreate,
    hideTitle = false,
    setModalActions,
    inlineCreate,
    queryParams,
    fromDetailView,

    // injected by HOCs
    objects,
    object,
    views,
    stack,
}: Props & HocProps) => {
    const objectId = object._sid

    const [recordState, setRecordState] = useState(defaultFormState)
    const [configState, setConfigState] = useState(defaultFormState)
    const { isLoading: userRecordLoading } = useUserRecord()
    const [waitingForUserRecord, setWaitingForUserRecord] = useState(false)
    const recordActions = useRecordActions()

    const loadedObjects = useRef(objects)
    const onChangeView = useRef(objects)

    const defaultData = {
        config: cloneDeep(config),
        record: initRecordValues(record),
        loadedConfig: config,
        loadedRecord: record,
    }

    const [data, setData] = useState(defaultData)

    const { track } = useTrack()

    const setRecordValue = useCallback(
        (key, nextValue, options = {}) => {
            const { ignoreIsDirty = false } = options
            const updatedRecord = { ...data.record, [key]: nextValue }
            const isDirty = isChanged(record, updatedRecord)
            setRecordState((state) => ({
                ...state,
                isDirty: ignoreIsDirty ? state.isDirty : isDirty,
            }))
            setData((data) => ({ ...data, record: { ...data.record, [key]: nextValue } }))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record]
    )
    const setValid = useCallback(
        (key, value) => {
            return setRecordState((state) => ({
                ...state,
                validationError: false,
                valid: { ...state.valid, [key]: value },
            }))
        },
        [setRecordState]
    )

    const onConfigChange = useCallback(
        (changes, blocks) => {
            changes?.forEach((changedBlock) => {
                const block = findWidgetBlockById(blocks, changedBlock.blockId)
                if (changedBlock.action === 'create') {
                    track('Frontend Widget Created', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'create_view',
                    })
                } else if (changedBlock.action === 'updateConfig') {
                    track('Frontend Widget Modified', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'create_view',
                    })
                }
            })

            setConfigState((state) => ({
                ...state,
                isDirty: true,
            }))
            setData((data) => ({
                ...data,
                config: { ...data.config, blocks },
            }))
        },
        [setConfigState, setData, track]
    )

    // Hide certain fields if they're disabled
    const isFieldDisabled = useCallback(
        (fieldId) => {
            const fields = objects.map((object) => object.fields).flat()
            const field = fields.find((f) => f._sid === fieldId)
            return field && field.connection_options.is_disabled === true
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEqual(loadedObjects.current, objects)]
    )

    const useNewCreateForm = get(stackOptions, 'new_create_form')

    const recordContext = useMemo(
        () => {
            return {
                record: data.record,
                view: {
                    actions: {
                        setValue: setRecordValue,
                        setValid,
                    },

                    valid: recordState.valid,
                    editing: true,
                    creating: true,
                    useNewCreateForm,
                    showErrors: recordState.showErrors,
                    isInlineCreate: inlineCreate,
                    isLoading: recordState.isSaving,
                },
                object,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data.record,
            object,
            recordState.valid,
            recordState.showErrors,
            setRecordValue,
            setValid,
            recordState.isSaving,
        ]
    )

    const history = useHistory()

    useEffect(() => {
        if (config && !isEqual(data.loadedConfig, config)) {
            setData((data) => ({ ...data, loadedConfig: config, config }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    useEffect(() => {
        if (record && !isEqual(data.loadedRecord, record)) {
            setData((data) => ({ ...data, loadedRecord: record, record }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    useEffect(() => {
        loadedObjects.current = objects
    }, [objects])

    useEffect(() => {
        onChangeView.current = onChange
    }, [onChange])

    const useLayoutFrom = get(data, 'config.use_layout_from')

    const buildBlockFromField = (field) => {
        return {
            type: 'field',
            fieldId: field?._sid,
            objectId: field?.object_id,
            fieldName: field?.api_name,
            required: field?.required,
            fullWidth: field?.type === 'long_text',
            isPrimary: field?.is_primary,
        }
    }

    const buildPrimaryFieldBlock = () => {
        const fields = objects.find((object) => object._sid === objectId)?.fields.flat()
        const primaryField = fields.find((f) => f.is_primary)

        const block = buildBlockFromField(primaryField)

        return block
    }

    const updateTreeWithBlock = (tree, block) => {
        if (!block?.fieldId) return tree
        const blocks = tree.childBlocks

        for (let i = 0; i < blocks.length; i++) {
            const currentBlock = blocks[i]
            if (!currentBlock?.childBlocks?.length && currentBlock.config?.attributes?.contents) {
                const hasPrimaryBlock = currentBlock.config?.attributes?.contents.find(
                    (b) => b.fieldId === block.fieldId
                )

                if (hasPrimaryBlock) return tree
                else {
                    currentBlock.config?.attributes?.contents?.splice(1, 0, block)
                    return tree
                }
            }
            updateTreeWithBlock(blocks[i], block)
        }
    }

    const tree = useMemo(() => {
        let createTree = cloneDeep(get(data, 'config.blocks'))

        if (useLayoutFrom) {
            const layoutFromView = cloneDeep(views.find((v) => v._sid === useLayoutFrom))
            if (layoutFromView) {
                createTree = get(layoutFromView, 'options.blocks', createTree)

                updateTreeWithBlock(
                    createTree.find((block) => block.id === 'page'),
                    buildPrimaryFieldBlock()
                )
            }
        }

        if (useNewCreateForm) {
            createTree = filterBlockTree(createTree, [
                'container',
                'attribute',
                'gridcard',
                'field_container',
                'banner',
                'text',
                'callout',
            ])
        }

        return createTree
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useLayoutFrom, data, objects, views])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const autoFillOnComplete = ({ autofillData, ...rest }) => {
        setRecordState((recordState) => ({ ...recordState, ...rest }))
        setData((data) => ({ ...data, record: { ...data.record, ...autofillData } }))
    }

    const revertRecordChanges = () => {
        setData((data) => ({
            ...data,
            record: initRecordValues(data.record),
        }))
        setRecordState((prevState) => ({ ...prevState, isDirty: false, isSaving: false }))
    }

    const revertConfigChanges = () => {
        // We do not want to reset the end user form
        const { ...defaultDataWithoutRecord } = defaultData
        setData((prevState) => ({ ...prevState, ...defaultDataWithoutRecord }))
        setConfigState((prevState) => ({ ...prevState, isDirty: false }))
    }

    useEffect(() => {
        const autoFillObject = objects.find((o) => o._sid === objectId)
        setWaitingForUserRecord(userRecordLoading && autoFillObject?.options?.created_by_field)
    }, [userRecordLoading, objectId, objects])

    useEffect(() => {
        if (!waitingForUserRecord) {
            processAutofill(
                objects,
                objectId,
                queryParams ?? window.location.search,
                recordState.autofillDone,
                autoFillOnComplete
            )
        }
    }, [
        waitingForUserRecord,
        objects,
        objectId,
        queryParams,
        recordState.autofillDone,
        autoFillOnComplete,
    ])

    const isRecordValid = () => {
        const validity = recordState.valid
        let isValid = true
        Object.keys(validity).forEach((key) => {
            isValid = isValid && validity[key]
        })

        return isValid
    }

    const saveConfig = useCallback(() => {
        track('layout updated', {
            view: 'create view',
        })
        onChange(data.config)
        setConfigState((state) => ({ ...state, isDirty: false }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEqual(onChangeView.current, onChange), onChange, setConfigState, data.config])

    const handleAddRecordModalSubmit = () => {
        // When submitting the change modal, we want to follow
        // the requested url change and skip the default redirect logic
        return saveRecord({ skipRedirect: true })
    }

    const handleAddRecordFormSubmit = () => {
        saveRecord()
    }

    // Scroll to the first error field when showErrors is set to true
    useEffect(() => {
        if (recordState.showErrors) scrollToInvalidField()
    }, [recordState.showErrors])

    const saveRecord = ({ skipRedirect = false } = {}) => {
        const shouldRedirect = !doNotRedirect && !skipRedirect

        // Don't actually save if we're in edit mode
        if (showControls) return setRecordState((state) => ({ ...state }))

        setRecordState((state) => ({ ...state, isSaving: true, saveError: false }))

        // See if we're valid
        if (!isRecordValid()) {
            setRecordState((state) => ({
                ...state,
                showErrors: true,
                validationError: true,
                isSaving: false,
            }))
            // Note: we need this in both a useEffect and here so that it triggers both for the first time showErrors is set to true
            // and every time submit is clicked when the record is still not valid (showErrors is already true)
            scrollToInvalidField()
            return Promise.reject()
        }

        return recordActions
            .create({ ...data.record, object_id: objectId })
            .then((persistedRecord) => {
                // don't flash up the unsaved changes modal when redirecting
                setRecordState((state) => ({ ...state, isDirty: false }))

                // This checks all lookup and multi lookup fields to see which
                // items have been updated and need to be refreshed
                // Note that we compare the persisted record value, not the local updated one
                // as we want to rely on the actual backend update logic
                const { recordsAdded, recordsRemoved, updatedRecordsIds } = getUpdatedRecords(
                    object.fields,
                    record,
                    persistedRecord
                )

                // Refresh the Redux cache for any new related items
                const pendingUpdates: any[] = []
                if (updatedRecordsIds && updatedRecordsIds.length) {
                    updatedRecordsIds.forEach((recordSid) => {
                        const currentRecord = getCachedRecord(recordSid)
                        // let's just optimistically update the record for now
                        // so that it looks seamless and doesn't have to wait
                        // for the update
                        if (currentRecord) {
                            optimisticallyUpdateRelatedRecord(
                                persistedRecord,
                                currentRecord,
                                recordsAdded,
                                recordsRemoved,
                                recordActions
                            )
                        }

                        pendingUpdates.push(
                            recordActions
                                .fetch(recordSid, {
                                    noCache: true,
                                })
                                .then(() => {})
                                // this is a real edge case, redirect anyway but we realise it
                                // may cause the record not to show until refreshed
                                .catch(() => {})
                        )
                    })
                }

                if (shouldRedirect) {
                    // Wait for all pending related record updates and then redirect
                    Promise.allSettled(pendingUpdates).then(() => {
                        redirectAfterCreate(persistedRecord)
                    })
                }

                // ensures that the new record is shown on the parent
                if (onCreate) {
                    onCreate(persistedRecord._sid)
                }

                // Only revert the changes if we're not redirecting
                if (!shouldRedirect) {
                    setRecordState((state) => ({
                        ...state,
                        showErrors: false,
                        validationError: false,
                        isSaving: false,
                    }))

                    revertRecordChanges()
                }
            })
            .catch((e) => {
                Sentry.captureMessage(`Error creating record. Error message: ${get(e, 'message')}`)
                setRecordState((state) => ({ ...state, isSaving: false, saveError: true }))
            })
    }

    const redirectAfterCreate = (record) => {
        const searchString = queryString.parse(window.location?.search, {
            ignoreQueryPrefix: true,
        })
        if (searchString?.previous) {
            // Redirect, e.g. back to a related list
            history.push(searchString.previous)
        } else if (recordState.autoSave && history.length) {
            // Go back from an auto-saving create link
            history.goBack()
        } else if (get(record, '_permissions.non_permitted_result', false)) {
            // Redirect back to the list view, as the created record is not accessible.
            const validViews = views.filter((v) => v.type === 'list' && v.object_id === objectId)
            if (validViews.length) {
                history.push(getUrl(validViews[0].url))
            } else {
                history.push(getUrl('/'))
            }
        } else if (searchString?.redirectToInbox) {
            // Redirect to inbox with new row selected
            history.push(`${searchString.redirectToInbox}?row_id_base=${record._sid}`)
        } else {
            // Redirect to the newly created record
            history.push({
                pathname: getUrl(`${feature.url}/view/${record._sid}`),
                state: { prev: history?.location },
            })
        }
    }

    let title = (record && record._primary) || 'Record title'

    // Sometimes, the _primary field could contain an object (eg: attachments), rather than a string or a number
    // if this happens, React throws an error 'Objects are not valid as a React child'
    // We don't know how we can get into this state, as we don't allow fields like attachments to be _primary,
    // but sometimes it happens.
    if (typeof title === 'object') title = get(title, 'id') || 'Record title'

    const setConfig = useCallback(
        (config) => {
            setConfigState((state) => ({
                ...state,
                isDirty: true,
            }))

            setData((data) => ({ ...data, config: { ...data.config, ...config } }))
        },
        [setConfigState, setData]
    )

    const detailView = useMemo(
        () => views.find((view) => view.object_id === objectId && view.type === 'detail'),
        [views, objectId]
    )

    const createControls = useMemo(
        () => (
            <>
                <CreateViewControls
                    mt={4}
                    setConfig={setConfig}
                    detailView={detailView && detailView._sid}
                    useLayoutFrom={useLayoutFrom}
                />

                <BlockSelectorPortal useLayoutFrom={useLayoutFrom} />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data.config, setConfig, detailView && detailView._sid, useLayoutFrom]
    )

    const tabsEnabled = !isFeatureLocked(FEATURES.tabs, stack)

    // if there are tabs, we only use the first one
    const usefullTree = useMemo(() => {
        if (!tabsEnabled) {
            return tree
        }

        const activeTabs =
            detailView.options?.tabs?.filter(({ active, type }) => type !== 'activity' && active) ??
            []

        if (activeTabs.length > 1 && tree[activeTabs[0].treeIndex]) {
            return [tree[activeTabs[0].treeIndex]]
        }

        return tree
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree, stack?.options?.workspace_app, detailView.config?.tabs, tabsEnabled])

    const onChangeTitle = useCallback((title) => setConfig({ title }), [setConfig])

    const viewName = (obj) => `${obj.name} create`

    title = data.config.title || `${object.name}: add new`
    const ignoreHistoryBreadcrumb = inlineCreate || fromDetailView

    useHistoryBreadcrumb({ title, type: 'create', objectId }, ignoreHistoryBreadcrumb)

    const readOnlyPortal = get(stackOptions, 'read_only_data')

    const parentUrl = useCreateViewParentUrl(feature)

    const saveButton = useMemo(() => {
        let props: any = {}
        if (useNewCreateForm) {
            props.w = '100%'
        }
        if (setModalActions && inlineCreate) {
            setModalActions([
                {
                    label: 'Save',
                    onClick: handleAddRecordModalSubmit,
                    icon: 'checkmark',
                    buttonSize: 'sm',
                    isDisabled: recordState.isSaving || readOnlyPortal,
                    isLoading: recordState.isSaving,
                },
            ])

            return null
        }

        return (
            <>
                <CommandBarShortcut name={GlobalCallbackKeys.Cancel} action={parentUrl} />
                <CommandBarShortcut
                    name={GlobalCallbackKeys.Save}
                    action={handleAddRecordFormSubmit}
                    isActive={!recordState.isSaving && !readOnlyPortal}
                />
                <Button
                    variant="sm"
                    icon="checkmark"
                    onClick={handleAddRecordFormSubmit}
                    isDisabled={recordState.isSaving || readOnlyPortal}
                    label={readOnlyPortal && 'Saving changes is disabled on this app'}
                    className={ONBOARDING_CLASSES.SAVE_RECORD_BUTTON}
                    isLoading={recordState.isSaving}
                    {...props}
                >
                    Save
                </Button>
            </>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, recordState, useNewCreateForm, showControls])

    const errorMessages = (
        <>
            <Collapse isOpen={recordState.saveError}>
                <Text
                    variant="error"
                    mb={useNewCreateForm ? 3 : 0}
                    textAlign={useNewCreateForm && 'center'}
                >
                    {stackOptions.is_demo
                        ? `Sorry, you can't create new records in a demo app!`
                        : 'Sorry, there was an error saving your record. Please try again.'}
                </Text>
            </Collapse>
            <Collapse
                isOpen={recordState.validationError}
                mb={useNewCreateForm ? 3 : 0}
                textAlign={useNewCreateForm && 'center'}
            >
                <Text variant="error">Please fill out the required fields above.</Text>
            </Collapse>
        </>
    )

    if (recordState.autoSave) {
        if (!recordState.saveError && !recordState.triggeredSave) {
            setRecordState((state) => ({ ...state, triggeredSave: true }))
            saveRecord()
        }

        if (recordState.saveError) {
            return (
                <Text variant="error">
                    Sorry, there was an error saving your record. Please{' '}
                    <a href={history.goBack()}>go back</a>.
                </Text>
            )
        }

        return (
            <>
                <ContainerLabel
                    borderStyle="noBorder"
                    variant="pageHeading"
                    value="Saving record"
                    textAlign="center"
                />

                {/* @ts-ignore */}
                <LoadingState />
            </>
        )
    }

    return (
        <>
            {showControls && (
                <ViewEditPane
                    isConfigDirty={configState.isDirty}
                    viewName={viewName(object)}
                    saveView={saveConfig}
                >
                    {createControls}
                </ViewEditPane>
            )}
            <>
                <ConditionalWrapper
                    wrapper={(children) => (
                        <Box w="100%" maxWidth="500px" m="0 auto">
                            {children}
                        </Box>
                    )}
                    condition={useNewCreateForm && !inlineCreate}
                >
                    {!hideTitle && (
                        // @ts-ignore
                        <ContainerLabel
                            isEditable={showControls && !useLayoutFrom}
                            onChange={onChangeTitle}
                            borderStyle="noBorder"
                            variant="pageHeading"
                            value={title}
                            buttons={!useNewCreateForm && saveButton}
                            textAlign={useNewCreateForm && 'center'}
                        />
                    )}

                    {!useNewCreateForm && errorMessages}

                    {waitingForUserRecord ? (
                        <Flex align="center" direction="column" mb={2}>
                            <Spinner size="md" color="neutral.800" mr={2} />
                        </Flex>
                    ) : (
                        // @ts-ignore
                        <FieldLayoutEditor
                            tree={usefullTree}
                            context={recordContext}
                            treeIndex={0}
                            object={object}
                            isCreate={true}
                            onChange={onConfigChange}
                            config={data.config}
                            showControls={showControls && !useLayoutFrom}
                            isFieldDisabled={isFieldDisabled}
                            recordPermissions={get(data, 'record._permissions')}
                            newCreateForm={useNewCreateForm}
                            hideFields={get(stackOptions, 'enable_field_widget')}
                            determineIsBlockDisabled={determineIsBlockDisabled}
                            showBlockSelector={true}
                        />
                    )}

                    {useNewCreateForm && errorMessages}
                    {useNewCreateForm && saveButton}
                </ConditionalWrapper>
                <UnsavedChangesModal
                    endUserThemed={true}
                    isDirty={recordState.isDirty}
                    onSave={handleAddRecordModalSubmit}
                    revertChanges={revertRecordChanges}
                />

                <UnsavedChangesModal
                    isDirty={configState.isDirty}
                    onSave={saveConfig}
                    revertChanges={revertConfigChanges}
                />
            </>
        </>
    )
}

export default withStack(withObjects(withViews(withObject(CreateView))))
