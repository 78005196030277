import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useField } from 'data/hooks/fields'
import { useRecords } from 'data/hooks/records'
import { getFilterFields } from 'features/records/components/logic/recordLogic'

import { LoadingPlaceholder } from '../shared/AppUsersUI'

import AutomaticUserList from './AutomaticUserList'
import ManualUserList from './ManualUserList'

type UserTableUserListProps = {
    objectId: string
    emailFieldId: string
    isNewList: boolean
    filters?: Filter[]
}

const UserTableUserList: React.FC<UserTableUserListProps> = ({
    emailFieldId,
    isNewList,
    objectId,
    filters,
}) => {
    const field = useField(emailFieldId)
    const filterFields = getFilterFields(filters)
    const { data: { records = [] } = {}, isLoading: isRecordsLoading } = useRecords({
        objectId,
        fetchOptions: field ? { includeFields: [field.api_name, ...filterFields] } : undefined,
    })
    const mode = useFormContext().watch('options.mode')
    if (isRecordsLoading) return <LoadingPlaceholder>Loading table records...</LoadingPlaceholder>

    if (mode === 'manual') {
        return (
            <ManualUserList
                emailFieldApiName={field ? field.api_name : ''}
                records={records}
                isNewList={isNewList}
            />
        )
    }

    return <AutomaticUserList emailFieldApiName={field ? field.api_name : ''} records={records} />
}

export default UserTableUserList
