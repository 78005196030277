import { Moment } from 'moment'
import { LINE_CHART_MAX_ITEMS } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/constants'

import { NONE_VALUE_SECTION_LABEL } from 'data/hooks/metrics/groupAggregateData'
import { getBucketDate } from 'features/charts/utils/getBucketDate'
import { getBucketLabel } from 'features/charts/utils/getBucketLabel'

export type LineChartDataPoint = {
    value: number
    name: string
    key: string
    isReal: boolean
}

export const prepareData = (
    data: MetricsQueryResponse['data'],
    bucketBy?: Bucket,
    limit = LINE_CHART_MAX_ITEMS
): LineChartDataPoint[] => {
    if (data.length == 0 || !bucketBy) {
        return []
    }
    const resortedData = data.sort((a, b) => a.key.localeCompare(b.key))
    const realPoints = resortedData.reduce((allLines, currentLineDataPoints) => {
        const { key, value, values } = currentLineDataPoints
        const keyDate = getBucketDate(key, bucketBy)
        const label = !keyDate ? NONE_VALUE_SECTION_LABEL : getBucketLabel(keyDate, bucketBy)

        allLines.set(label, {
            name: label,
            key: label,
            value,
            isReal: true,
            ...values,
        })

        return allLines
    }, new Map<string, LineChartDataPoint>())

    const allRangeKeys = getAllRangeKeys(
        resortedData[0].key,
        resortedData[resortedData.length - 1].key,
        bucketBy
    )

    return allRangeKeys
        .map((key) => {
            return realPoints.has(key)
                ? realPoints.get(key)!
                : {
                      name: key,
                      key,
                      value: 0,
                      isReal: false,
                  }
        })
        .slice(-limit)
}

const getAllRangeKeys = (start: string, end: string, bucketBy: Bucket) => {
    const startDate = getBucketDate(start, bucketBy)
    const endDate = getBucketDate(end, bucketBy)
    if (!startDate || !endDate) {
        return []
    }

    const allKeys: string[] = []
    for (let cursor = startDate; cursor < endDate; cursor = getNextBucket(cursor, bucketBy)) {
        allKeys.push(getBucketLabel(cursor, bucketBy))
    }
    allKeys.push(getBucketLabel(endDate, bucketBy))
    return allKeys
}

const getNextBucket = (date: Moment, bucketBy: Bucket) => {
    let newDate = date.clone()
    switch (bucketBy) {
        case 'day':
            return newDate.add(1, 'days')
        case 'week':
            return newDate.add(1, 'weeks')
        case 'month':
            return newDate.add(1, 'months')
        case 'year':
            return newDate.add(1, 'years')
        case 'quarter':
            return newDate.add(1, 'quarters')
    }
}
