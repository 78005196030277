// @ts-strict-ignore
import React from 'react'
import { useSelector } from 'react-redux'

import AppSettingsModalDataSourcesSetup from './AppSettingsModalDataSourcesSetup'

const AppSettingsModalDataSources = (): React.ReactElement | null => {
    const dataConnectionsFetched = useSelector((state) => state.dataConnections.fetched)
    return dataConnectionsFetched ? <AppSettingsModalDataSourcesSetup /> : null
}

export default AppSettingsModalDataSources
