import React, { FC } from 'react'

import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Box, Button, Flex, Icon } from 'v2/ui'

const fadeInAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const RecordUpdateNotificationAlert = styled(Alert)`
    animation: ${fadeInAnimation} 1s linear;
`

type Props = {
    onDiscardChanges: () => void
}

const EditsMadeAlert: FC<Props> = ({ onDiscardChanges }) => (
    <RecordUpdateNotificationAlert
        status="warning"
        className="record-updated-warning"
        textAlign="left"
        boxShadow="lg"
        borderRadius="md"
        flexDirection="column"
        alignItems="flex-start"
        mt={4}
    >
        <Flex mb={3} width="100%" alignItems="center">
            <AlertIcon />
            <AlertTitle lineHeight={1.8}>Edits made</AlertTitle>
            <Box ml="auto">
                <Button
                    flexShrink={0}
                    variant="adminSecondary"
                    buttonSize="smDense"
                    onClick={onDiscardChanges}
                >
                    <Icon
                        size="sm"
                        mr={2}
                        icon="refresh"
                        display="inline-block"
                        verticalAlign="middle"
                    />
                    Discard my changes
                </Button>
            </Box>
        </Flex>
        <AlertDescription lineHeight="short">
            Someone else just edited this record. Saving may overwrite their changes.
        </AlertDescription>
    </RecordUpdateNotificationAlert>
)

export default EditsMadeAlert
