import { useCallback, useContext, useState } from 'react'

import { useObjects } from 'data/hooks/objects'
import useTrack from 'utils/useTrack'

import { AggregationBlockContext } from './AggregationBlockContext'

type Args = {
    widgets: AggregateBlockAttributes[]
    addEventName: string
    addEventExtraInfo?: (widget: AggregateBlockAttributes) => any
    configuredEventName: string
    configuredEventExtraInfo?: (widget: ChartBlockAttributes & MetricsBlockAttributes) => any
    defaultWidgetAttributes: Omit<AggregateBlockAttributes, 'order'>
    onChange: (widgets: AggregateBlockAttributes[]) => void
    isValid: (widget: AggregateBlockAttributes, object?: ObjectDto) => boolean
}

function useAggregationBlockEditorHandler({
    widgets,
    addEventName,
    addEventExtraInfo,
    configuredEventName,
    configuredEventExtraInfo,
    defaultWidgetAttributes,
    onChange,
    isValid,
}: Args) {
    const { editingWidgetOrder, onStopEditingWidget, onStartEditingWidget } =
        useContext(AggregationBlockContext)

    const [widgetsHistory, setWidgetsHistory] = useState<Map<string, AggregateBlockAttributes>[]>(
        widgets.map((widget) => {
            const widgetMap = new Map<string, AggregateBlockAttributes>()
            widgetMap.set(widget.objectId, widget)
            return widgetMap
        })
    )

    const { track } = useTrack()

    const { data: objects } = useObjects()

    const onAddWidget = useCallback(
        (newWidget: AggregateBlockAttributes) => {
            onChange([...widgets, newWidget])
            onStartEditingWidget(newWidget)

            setWidgetsHistory((prevWidgetsHistory) => {
                const newWidgetHistory = new Map<string, AggregateBlockAttributes>()
                newWidgetHistory.set(newWidget.objectId, newWidget)
                prevWidgetsHistory.push(newWidgetHistory)

                return prevWidgetsHistory
            })

            let extraInfo = {}
            if (addEventExtraInfo) {
                extraInfo = { ...extraInfo, ...addEventExtraInfo(newWidget) }
            }

            track(addEventName, extraInfo)
        },
        [addEventName, addEventExtraInfo, onChange, onStartEditingWidget, track, widgets]
    )

    const onEditWidget = useCallback(
        (editedWidget: Pick<ChartBlockAttributes, 'objectId'> & Partial<ChartBlockAttributes>) => {
            const editedObject = objects.find((o) => o._sid === editedWidget.objectId)
            onChange(
                widgets.map((widget) => {
                    if (widget.order !== editingWidgetOrder) {
                        return widget
                    }

                    if (editedWidget.objectId && widget.objectId !== editedWidget.objectId) {
                        const editingWidgetHistory =
                            widgetsHistory[editingWidgetOrder].get(editedWidget.objectId) ||
                            defaultWidgetAttributes

                        return { ...editingWidgetHistory, ...editedWidget, order: widget.order }
                    }

                    return { ...widget, ...editedWidget }
                })
            )

            setWidgetsHistory((prevWidgetsHistory) =>
                prevWidgetsHistory.map((widgetHistory, index) => {
                    if (index !== editingWidgetOrder) {
                        return widgetHistory
                    }

                    const previousVersion =
                        widgetHistory.get(editedWidget.objectId) || defaultWidgetAttributes

                    widgetHistory.set(editedWidget.objectId, {
                        ...previousVersion,
                        ...editedWidget,
                        order: editingWidgetOrder,
                    })

                    return widgetHistory
                })
            )

            if (isValid(editedWidget as AggregateBlockAttributes, editedObject)) {
                let extraInfo = { metricType: editedWidget.aggregate?.type }
                if (configuredEventExtraInfo) {
                    extraInfo = {
                        ...extraInfo,
                        ...configuredEventExtraInfo(editedWidget as AggregateBlockAttributes),
                    }
                }
                track(configuredEventName, extraInfo)
            }
        },
        [
            configuredEventExtraInfo,
            configuredEventName,
            defaultWidgetAttributes,
            editingWidgetOrder,
            isValid,
            onChange,
            track,
            widgets,
            widgetsHistory,
            objects,
        ]
    )

    const onDeleteWidget = useCallback(
        (widgetOrder: number) => {
            const updatedWidgetList = (widgets as AggregateBlockAttributes[]).reduce<
                AggregateBlockAttributes[]
            >((updatedWidgets, currentWidget) => {
                if (currentWidget.order !== widgetOrder) {
                    updatedWidgets.push({ ...currentWidget, order: updatedWidgets.length })
                }

                return updatedWidgets
            }, [])

            setWidgetsHistory((prevWidgetsHistory) =>
                prevWidgetsHistory.reduce<Map<string, AggregateBlockAttributes>[]>(
                    (updatedHistory, currentHistory, index) => {
                        if (index !== widgetOrder) {
                            updatedHistory.push(currentHistory)
                        }

                        return updatedHistory
                    },
                    []
                )
            )

            onChange(updatedWidgetList)
        },
        [widgets, onChange]
    )

    const onReorderWidgets = useCallback(
        (reorderedWidgets: (AggregateBlockAttributes & { previousOrder: number })[]) => {
            setWidgetsHistory((prevWidgetsHistory) => {
                const newWidgetsHistory: Map<string, AggregateBlockAttributes>[] =
                    reorderedWidgets.map(({ previousOrder, order }) => {
                        const widgetHistory = new Map<string, AggregateBlockAttributes>()

                        const prevWidgetHistory = prevWidgetsHistory[previousOrder]
                        for (const [objectId, widget] of prevWidgetHistory.entries()) {
                            widgetHistory.set(objectId, { ...widget, order })
                        }

                        return widgetHistory
                    })

                return newWidgetsHistory
            })

            onChange(reorderedWidgets.map(({ previousOrder: __, ...widget }) => widget))
        },
        [onChange]
    )

    return {
        editingWidgetOrder,
        onStartEditingWidget,
        onStopEditingWidget,
        onEditWidget,
        onAddWidget,
        onReorderWidgets,
        onDeleteWidget,
    }
}

export default useAggregationBlockEditorHandler
