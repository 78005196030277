import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { Rights, useAppUserContext } from 'app/AppUserContext'
import { useStacks, useUpdateStack, useUpdateStackOptions } from 'data/hooks/stacks'
import { useUserLists } from 'data/hooks/userLists'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import { Header } from 'features/workspace/WorkspaceSettingsModalUi'
import { openManageUserSettingsModal } from 'utils/modalOpeners'

import {
    Banner,
    Box,
    Button,
    Divider,
    FeatureBanner,
    FeatureSplashScreen,
    Flex,
    LoadingScreen,
    Text,
} from 'v2/ui'
import {
    FEATURES,
    IndicatorPositions,
    isFeatureLocked,
    Plans,
    ProtectedFeature,
} from 'v2/ui/utils/ProtectedFeature'

import EditUserTableModal from '../edit-user-table/EditUserTableModal'
import { UserTableOptions } from '../edit-user-table/userTableTypes'
import { AccessEnabledSwitch } from '../shared/AppUsersUI'

import { useAdministratorsModal } from './AdministratorsModal'
import ConnectedUserTable from './ConnectedUserTable'
import ManualAppUsers from './ManualAppUsers'

type AppUsersModalContentProps = { isOpen: boolean; setIsOpen?: (value: boolean) => void }
export const AppUsersModalContent: React.FC<AppUsersModalContentProps> = ({
    isOpen,
    setIsOpen,
}) => {
    const [editingUserList, setEditingUserList] = useState<UserListDto | undefined>()
    const [isAppLimitUpgradeModalVisible, setAppLimitUpgradeModalVisible] = useState<false>()

    const { data: stacks } = useStacks()

    const {
        isOpen: isUserTableEditorOpen,
        onClose: closeUserTableEditor,
        onOpen: openUserTableEditor,
    } = useDisclosure()

    const { hasRight, role } = useAppUserContext()
    // don't enable this query unless the modal is open
    const { data: userLists, isLoading: userListsLoading } = useUserLists({
        enabled: isOpen,
    })
    const { data: users = [], isLoading: usersLoading } = useAppUsersForAdmin(true)

    const formRef = useRef({})
    const { modalState: displayChangesModal } = useUnsavedChangesModalCallback(formRef)
    const { selectedStack, workspaceAccount } = useAppContext()
    const updateStackOptions = useUpdateStackOptions()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { show: showAdministratorsModal } = useAdministratorsModal()
    const nativeTablesEnabled = selectedStack?.combined_optional_features?.stacker_native_tables
    const showSplash = selectedStack?.options?.show_app_users_splash && !nativeTablesEnabled

    // if the user's role changes and they are no longer an administrator, close the modal.
    useEffect(() => {
        if (!hasRight(Rights.Admin.Any)) {
            if (setIsOpen) {
                setIsOpen(false)
            }
        }
    }, [role, hasRight, setIsOpen])

    const onCloseUserTableEditor = () => {
        setEditingUserList(undefined)
        closeUserTableEditor()
    }
    const saveAccessEnabledChange = (value: boolean) => {
        assertIsDefined(selectedStack)
        return updateStack({
            id: selectedStack._sid,
            patch: {
                user_access_disabled: !value,
            },
        })
    }

    const userCount = users.filter(({ role }: { role: string }) => role !== 'internal_admin').length
    const userTableCount = userLists?.length || 0
    const isLoading = userListsLoading || usersLoading
    const workspaceMode = userLists?.length === 0
    const defaultUserTableValues: UserTableOptions = {
        mode:
            userCount === 0 || selectedStack?.options?.sync_users_as_auths ? 'automatic' : 'manual',
    }
    const unsavedChangesModal = displayChangesModal ? (
        // @ts-expect-error
        <UnsavedChangesModal {...displayChangesModal} />
    ) : null

    const dismissSplash = () => {
        if (selectedStack) {
            updateStackOptions(selectedStack, {
                show_app_users_splash: false,
            })
        }
    }

    const appCount = useMemo(
        () =>
            stacks?.filter(
                (stack) =>
                    stack.account_id === workspaceAccount?._sid && !stack.user_access_disabled
            ).length ?? 0,
        [stacks, workspaceAccount]
    )

    const isUnlimitedAppsLocked = useMemo(
        () => isFeatureLocked(FEATURES.unlimitedApps, selectedStack),
        [selectedStack]
    )

    const isCurrentAppEnabled = !selectedStack?.user_access_disabled
    const currentTier = workspaceAccount?.plan_tier
    const isEnableToggleLocked =
        isUnlimitedAppsLocked &&
        !isCurrentAppEnabled &&
        ((currentTier === Plans.plus && appCount >= 3) ||
            (currentTier === Plans.starter && appCount >= 1))

    const shouldProtectAppLimit = !selectedStack?.is_using_trial

    const appLimitFeature = useMemo(() => {
        return {
            name: 'app_limit',
            getPlan: () => {
                if (appCount >= 3) return Plans.pro
                else return Plans.plus
            },
            getHasAccess: () => !isEnableToggleLocked,
        }
    }, [isEnableToggleLocked, appCount])

    const accessEnabledSwitch = (
        <AccessEnabledSwitch
            isChecked={!selectedStack?.user_access_disabled}
            onChange={saveAccessEnabledChange}
        />
    )

    if (!hasRight(Rights.Admin.Any)) return null
    return (
        <>
            {showSplash ? (
                <FeatureSplashScreen
                    title="User Table"
                    icon="addressBook"
                    subtitle={
                        <>
                            Do you have a table which lists people
                            <br /> who you want to access this app?
                        </>
                    }
                    actions={[
                        {
                            content: 'Choose table',
                            variant: 'adminPrimaryV4',
                            buttonSize: 'md',
                            onClick: () => {
                                openUserTableEditor()
                                dismissSplash()
                            },
                        },
                        {
                            content: 'Skip for now',
                            variant: 'adminSecondaryV4',
                            onClick: dismissSplash,
                        },
                    ]}
                />
            ) : (
                <LoadingScreen
                    isLoading={isLoading}
                    flexShrink={1}
                    minHeight="0"
                    keepChildrenMounted
                >
                    <Flex column align="stretch" height="100%" maxHeight="100%" wrap="nowrap">
                        {isEnableToggleLocked && (
                            <Banner icon="lock" justifyContent="center">
                                You&apos;ve reached the app limit associated with your plan. Upgrade
                                to share app with your users
                            </Banner>
                        )}
                        <Flex mb={3} mt={2}>
                            <Header>
                                Users
                                {/* @ts-ignore */}
                                <Text ml={2} color="neutral.800" display="inline">
                                    ({userCount})
                                </Text>
                            </Header>
                            <div style={{ flexGrow: 1 }} />
                            {userTableCount < 2 && shouldProtectAppLimit ? (
                                <ProtectedFeature
                                    feature={appLimitFeature}
                                    indicatorPosition={IndicatorPositions.left}
                                    showModal={isAppLimitUpgradeModalVisible}
                                    onModalClosed={() => setAppLimitUpgradeModalVisible(false)}
                                >
                                    {accessEnabledSwitch}
                                </ProtectedFeature>
                            ) : (
                                accessEnabledSwitch
                            )}
                            <Button
                                icon="key"
                                variant="adminTertiaryV4"
                                buttonSize="smNoPadding"
                                ml={5}
                                onClick={showAdministratorsModal}
                            >
                                Administrators
                            </Button>
                            <Button
                                icon="cogs"
                                variant="adminTertiaryV4"
                                buttonSize="smNoPadding"
                                ml={3}
                                onClick={openManageUserSettingsModal}
                            >
                                User Settings
                            </Button>
                        </Flex>
                        {!workspaceMode && <Divider dense style={{ marginBottom: '12px' }} />}
                        <Box flexGrow={1} flexShrink={1} minHeight={0}>
                            {workspaceMode ? (
                                <ManualAppUsers formRef={formRef}>
                                    {unsavedChangesModal}
                                </ManualAppUsers>
                            ) : (
                                userLists?.map((userList) => (
                                    <ConnectedUserTable
                                        key={userList._sid}
                                        userList={userList}
                                        formRef={formRef}
                                        singleListMode={userTableCount < 2}
                                    >
                                        {unsavedChangesModal}
                                    </ConnectedUserTable>
                                ))
                            )}
                        </Box>
                        {workspaceMode && (
                            <FeatureBanner
                                icon="table"
                                mt={4}
                                title="User table"
                                subtitle="Do you have a table which lists people who you want to access this app?"
                                actions={[
                                    {
                                        content: 'Choose table',
                                        variant: 'adminSecondaryV4',
                                        onClick: openUserTableEditor,
                                    },
                                ]}
                            />
                        )}
                    </Flex>
                    {isUserTableEditorOpen && (
                        <EditUserTableModal
                            isOpen
                            onClose={onCloseUserTableEditor}
                            defaultValues={defaultUserTableValues}
                            userList={editingUserList}
                        />
                    )}
                </LoadingScreen>
            )}
        </>
    )
}

export default AppUsersModalContent
