import styled from '@emotion/styled'

const SectionHeader = styled('div')`
    font-size: ${(props) => props.theme.headingTwoSize};
    color: ${(props) => props.theme.headingColor};
    font-weight: ${(props) => props.theme.headingWeight};
    font-family: ${(props) => props.theme.headingFont};
    margin: ${(props) => props.theme.small} 0px;
`

SectionHeader.displayName = 'SectionHeader'
export default SectionHeader
