import React from 'react'

import DetailViewRelatedListBlock from './DetailViewRelatedListBlock'
// The DetailViewRelatedListBlock now handles all relationship types.
// For backwards compatibility, we have to leave this block in here, but we're just forwarding now to the other.
function ExternalFieldRelatedListBlock(props) {
    return <DetailViewRelatedListBlock {...props} isExternalField />
}

ExternalFieldRelatedListBlock.attributes = DetailViewRelatedListBlock.attributes

export default ExternalFieldRelatedListBlock
