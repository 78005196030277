// @ts-strict-ignore
import React from 'react'

import type { CustomCell } from '@glideapps/glide-data-grid'

import { DropdownOption } from 'features/admin/fields/DropdownFieldConfiguration/types'
import { getDropdownFieldOptionColor, getDropdownFieldOptionTextColor } from 'utils/fieldUtils'

import DropdownAttribute from 'v2/ui/components/Attribute/DropdownAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import EditorWrapper from '../components/EditorWrapper'
import { drawRoundedValues } from '../drawUtils'
import type { CellData, CustomCellRenderer } from '../types'

export const DROPDOWN_CELL = 'dropdown-cell'

export type DropdownCellData = CellData & {
    readonly kind: typeof DROPDOWN_CELL
    readonly options: any[]
    value: string[] | string | undefined | null
}

export type DropdownCell = CustomCell<DropdownCellData>

const renderer: CustomCellRenderer<DropdownCell> = {
    isMatch: (cell): cell is DropdownCell => (cell.data as any).kind === DROPDOWN_CELL,
    draw: (args, cell) => {
        const { value } = cell.data

        const values: string[] = []
        if (Array.isArray(value)) {
            values.push(...value)
        } else if (value) {
            values.push(value.toString())
        }

        const finalValues = values.map((value) => {
            const option = cell.data.options?.find(
                ({ value: optionValue }) => optionValue === value
            )

            if (!option) {
                return { label: value }
            }

            if (!cell.data.field.options?.allow_dropdown_colors) {
                return { label: option.label }
            }

            const optionColor = getDropdownFieldOptionColor(option)
            const textColor = getDropdownFieldOptionTextColor(option, args.appTheme)

            return {
                label: option.label,
                color: textColor,
                backgroundColor: optionColor,
            }
        })

        drawRoundedValues(args, finalValues)
        return true
    },
    provideEditor: (cell) => {
        const isMulti = cell.data.field.type === 'multi_select'

        const CustomEditor = (props) => {
            const { onChange, value: cell } = props

            const handleChange = (value: DropdownOption | DropdownOption[]) => {
                const newCell = { ...cell, copyData: value, data: { ...cell.data, value } }
                onChange(newCell)
            }

            return (
                <EditorWrapper>
                    {/* @ts-ignore */}
                    <DropdownAttribute
                        field={cell.data.field}
                        onChange={handleChange}
                        mode={modes.editing}
                        isMulti={isMulti}
                        controlStyle={{
                            border: '1px solid white',
                            '&:hover': {
                                border: '1px solid white',
                            },
                        }}
                        inDataGrid
                    >
                        {cell.data.value}
                    </DropdownAttribute>
                </EditorWrapper>
            )
        }

        return {
            editor: CustomEditor,
            disablePadding: true,
            deletedValue: (toDelete: DropdownCell) => ({
                ...toDelete,
                copyData: '',
                data: {
                    ...toDelete.data,
                    value: null,
                },
            }),
        }
    },
}

export default renderer
