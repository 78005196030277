import React, { FC } from 'react'

import { BoxProps } from '@chakra-ui/react'

import { Box, Text } from 'v2/ui'

export const Spacer: FC = () => <Box my={2} />

export const FlexSpacer: FC = () => <div style={{ flexBasis: 8 }} />

export const Title: FC<BoxProps> = ({ children, ...props }) => (
    <Box mt={2} {...props}>
        <Text variant="adminFieldLabel">{children}</Text>
    </Box>
)
