import React, { SyntheticEvent, useEffect, useState } from 'react'

import { Button, Flex, Input, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { loadPayloads } from './loadPayloads'

export const PayloadsBrowser = ({
    connection,
    isOpen,
    onClose,
}: {
    connection: DataConnectionDto
    isOpen: boolean
    onClose: () => void
}): React.ReactElement => {
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [fromCursor, setFromCursor] = useState('1')
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState<AirtablePayloadsSummary | null>(null)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        const now = new Date()
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        setFromDate(yesterday.toISOString())
        setToDate(now.toISOString())
    }, [])

    const browse = (isCursor: boolean): void => {
        setIsLoading(true)
        loadPayloads(
            isCursor
                ? { connection, fromCursor }
                : {
                      connection,
                      fromDatestring: fromDate,
                      toDatestring: toDate,
                  }
        )
            .then(
                (result) => {
                    setResult(result)
                    setError('')
                },
                (error) => {
                    setResult(null)
                    setError(error.toString())
                }
            )
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <SimpleModalCompat
            size="650px"
            height="650px"
            noDividers
            padding="10px 20px 10px 20px"
            zIndex={1502}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            showCloseButton={true}
        >
            <Text variant="heading">Payloads browser</Text>
            <Flex justify="space-between" style={{ margin: '1em 0' }}>
                <div>
                    <Text>From</Text>
                    <Input
                        onChange={(event: SyntheticEvent) =>
                            setFromDate((event.target as HTMLInputElement).value)
                        }
                        value={fromDate}
                        style={{ width: '220px' }}
                    />
                </div>
                <div>
                    <Text>To</Text>
                    <Input
                        onChange={(event: SyntheticEvent) =>
                            setToDate((event.target as HTMLInputElement).value)
                        }
                        value={toDate}
                        style={{ width: '220px' }}
                    />
                </div>
                <Button
                    color="clear"
                    size="small"
                    variant="Secondary"
                    style={{ padding: '10px', marginTop: '10px' }}
                    disabled={isLoading || !fromDate || !toDate}
                    onClick={() => browse(false)}
                >
                    Browse
                </Button>
            </Flex>
            <Flex justify="space-between" style={{ margin: '1em 0' }}>
                <div>
                    <Text>From cursor</Text>
                    <Input
                        onChange={(event: SyntheticEvent) =>
                            setFromCursor((event.target as HTMLInputElement).value)
                        }
                        value={fromCursor}
                        style={{ width: '220px' }}
                    />
                </div>
                <Button
                    color="clear"
                    size="small"
                    variant="Secondary"
                    style={{ padding: '10px', marginTop: '10px' }}
                    disabled={isLoading || !fromCursor}
                    onClick={() => browse(true)}
                >
                    Browse
                </Button>
            </Flex>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}
            {result && (
                <>
                    <Text>
                        Number of payloads in range: {result.payloads.length}{' '}
                        {result.payloads.length >= 100 ? (
                            <span style={{ fontStyle: 'italic' }}>
                                (capped at 100, there may be more - should you try a smaller range?)
                            </span>
                        ) : null}
                    </Text>
                    <Text>First cursor in range: {result.min_cursor_in_range ?? '-'}</Text>
                    <Text>
                        Current connection cursor (may be outside the requested range):{' '}
                        {result.webhook_cursor}
                    </Text>
                    <Text>
                        Schema changes within range: {result.has_schema_changes ? 'yes' : 'no'}
                    </Text>
                    {Object.keys(result.record_creations_per_table).length > 0 && (
                        <>
                            <Text>Summary per table:</Text>
                            <ul>
                                {Object.keys(result.record_creations_per_table).map((key) => {
                                    const [sid, airtableTableId] = key.split('#')
                                    const numCreates = result.record_creations_per_table[key]
                                    const numUpdates = result.record_updates_per_table[key]
                                    const numDeletes = result.record_deletes_per_table[key]
                                    return (
                                        <li key={key}>
                                            {sid} ({airtableTableId}): {numCreates} records created,{' '}
                                            {numUpdates} updated and {numDeletes} deleted.
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    )}
                    <textarea
                        disabled
                        value={JSON.stringify(result.payloads, null, 2)}
                        style={{ height: '320px', marginTop: '1em', minHeight: '320px' }}
                    />
                </>
            )}
        </SimpleModalCompat>
    )
}
