import get from 'lodash/get'

import { OwnershipFilterFields } from './permissionRuleForm.types'

export function getUserFieldOptions(
    userRecord: { label: string; value: string }[],
    userObject: ObjectDto,
    objects: ObjectDto[],
    allowedFieldTypes: FieldType[],
    allowedLookupObject: string | undefined,
    value: OwnershipFilterFields | undefined,
    useSids: boolean
): { label: string; value: string }[] {
    const filterByFieldType = (field: FieldDto) => allowedFieldTypes.includes(field.type)
    const isFieldEnabled = (field: FieldDto) =>
        !(field.connection_options && field.connection_options.is_disabled)
    const isFieldEnabledOrSelected = (field: FieldDto) =>
        isFieldEnabled(field) ||
        field.api_name === value?.userFieldSelected ||
        field.api_name === value?.objectFieldSelected
    const filterMatchingFields = (field: FieldDto) => {
        if (allowedLookupObject) {
            return get(field, 'options.lookup_target') === allowedLookupObject
        } else return true
    }
    const userFieldDropdownOptions: { label: string; value: string }[] = userRecord
        .concat(
            userObject?.fields
                .filter(filterByFieldType)
                .filter(isFieldEnabledOrSelected)
                .filter(filterMatchingFields)
                .map((field) => ({
                    label:
                        field.type === 'multi_lookup'
                            ? `👤 User → ${field.label}`
                            : `👤 ${field.label}`,
                    value: useSids ? field._sid : field.api_name,
                }))
        )
        // add in any compatible fields from any objects linked to by the user object
        .concat(
            userObject?.fields
                .filter((field) => field.type === 'lookup')
                .filter(isFieldEnabled)
                // For each relationship on our user object, add in any fields
                // on those objects which are compatible for filtering
                .reduce((fields, userField) => {
                    const object = objects.find((o) => o._sid === userField.options?.lookup_target)
                    if (!object) return fields

                    const targetFields = object.fields
                        .filter(filterByFieldType)
                        .filter(isFieldEnabledOrSelected)
                        .filter(filterMatchingFields)
                        .map((field) => {
                            return {
                                label: `👤 ${userField.label} → ${field.label}`,
                                // this key format gives the back end what it needs to
                                // build the format. it tells it the relationship field to start with,
                                // and then the next field to extract from that related object
                                value: `${userField[useSids ? '_sid' : 'api_name']},${
                                    field[useSids ? '_sid' : 'api_name']
                                }`,
                            }
                        })

                    return fields.concat(targetFields)
                }, [])
        )
    return userFieldDropdownOptions
}
