import { createSelector } from 'reselect'

import { BLOCK_CREATED, BLOCK_REMOVED, BLOCK_UPDATED, BLOCKS_FETCHED } from '../utils/constants'
import { StackerActions } from '../utils/stackerActions'
import { StackerAPI } from '../utils/utils'

// API
class BlockApi extends StackerAPI {
    path = 'blocks/'
}
const blockApi = new BlockApi()

class BlockActions extends StackerActions {
    api = blockApi

    reducer = 'blocks'

    name = 'block'

    actionTypes = {
        fetched: BLOCKS_FETCHED,
        removed: BLOCK_REMOVED,
        created: BLOCK_CREATED,
        updated: BLOCK_UPDATED,
    }
}
const BA = new BlockActions()
BA.actions = { ...BA.actions }
export const blockActions = BA.actions

// SELECTORS
export const getBlockEntities = (state) => state.blocks.entities
export const getBlocksList = (state) => state.blocks.blocks

export const getBlocksMemoized = createSelector(
    getBlocksList,
    getBlockEntities,
    (blocks, entities) => blocks.map((blockId) => entities[blockId])
)
export const getBlockEntitiesMemoized = createSelector(getBlockEntities, (item) => item)

// HELPERS
