// @ts-strict-ignore
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import type { CommandBarClientSDK } from 'commandbar'
import moment from 'moment'

import { useAppUserContext } from 'app/AppUserContext'
import { isImpersonatingEndUser } from 'data/utils/isAdmin'
import { orderAlphabeticallyStacks } from 'data/utils/utils'
import { withStack, withStacks } from 'data/wrappers/WithStacks'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Rights, useAccountUserContext } from '../../app/AccountUserContext'
import AppContext, { useAppContext } from '../../app/AppContext'
import settings from '../../app/settings'
import { useAccounts } from '../../data/hooks/accounts'
import { useFeatures } from '../../data/hooks/features'
import { useUpdateUser } from '../../data/hooks/users/main'
import { withDataConnections } from '../../data/wrappers/WithDataConnections'
import { withNavigation } from '../../data/wrappers/WithNavigation'
import { withObjects } from '../../data/wrappers/WithObjects'
import { withPages } from '../../data/wrappers/WithPages'
import { withViews } from '../../data/wrappers/WithViews'
import { useIsSupportLoginPermitted } from '../../utils/supportLogin'
import { useCreateWorkspaceModal } from '../AppBar/CreateWorkspaceModal'
import { appSettingsMenuItems } from '../AppSettings/AppSettingsModalSidebar'
import useImpersonation from '../core/useImpersonation'
import { useNavTree } from '../internal/nav/AppNavTree'
import { openWorkspaceSettingsModal } from '../utils/__hackyGlobalModalControls'
import { useAppSettings } from '../workspace/AdminSideTray/hooks/useAppSettings'
import { useOpenAppUsers } from '../workspace/AdminSideTray/hooks/useOpenAppUsers'

import formatters, { formatLists } from './commandbar-formatters'
import {
    fetchRecords,
    getListsForSettings,
    getStacksWithIcons,
    getWorkspaces,
    openDataGrid,
    redirectTo,
} from './commandbar-helpers'
import {
    AppCallbackKeys,
    ContextAttributeKeys,
    ContextRecordKeys,
    GlobalCallbackKeys,
    ListCallbackKeys,
    RecordCallbackKeys,
} from './commandbar-keys'
import useCommandBar from './useAddToCommandBar'

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        CommandBar: CommandBarClientSDK
    }
}

type CommandBarProps = {
    stack: StackDto
    stacks: StackDto[]
    views: any[]
    dataConnections: DataConnectionDto[]
    objects: ObjectDto[]
    pages: any
    navigation: any
}

const CommandBar = ({
    stack: currentStack,
    stacks,
    views,
    dataConnections,
    objects,
    pages,
    navigation,
}: CommandBarProps): null => {
    const {
        isAdmin,
        user: currentUser,
        isImpersonating,
        isStudioUser,
        studioUser,
        previewing,
    } = useAppUserContext()
    const { data: accounts } = useAccounts()
    const { hasRight, role: accountRole } = useAccountUserContext()
    const { workspaceAccount } = useContext(AppContext)
    const { data: features } = useFeatures()
    const history = useHistory()
    const { impersonateUser: impersonate, stopImpersonating } = useImpersonation()
    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    const { selectedStack } = useAppContext()
    const navTree = useNavTree({
        navigation,
        views,
        pages,
        objects,
    }).filter((item) => item.display !== false)
    const { mutateAsync: updateUser } = useUpdateUser()
    const { showManageData } = useSlidingPane()
    const { open: openAppSettingsAction } = useAppSettings()

    const [commandBarInit, setCommandBarInit] = useState(false)
    const { setCommandBarReady, useAddToCommandBar } = useCommandBar()
    const openAppUsersAction = useOpenAppUsers()

    const { show: showCreateWorkspaceModal } = useCreateWorkspaceModal()

    /**
     * Router
     * Link commands utilize this to avoid refreshing the page
     */
    const addRouterFunction = useCallback(() => {
        window.CommandBar.addRouter(history.push)
    }, [history.push])

    /**
     * Context
     */
    const addAppBarVisibleContext = useCallback(() => {
        const workspacesMode = isStudioUser && !isImpersonatingEndUser() && workspaceAccount
        const shouldHideAppBarForGuest =
            studioUser?.membership_options?.role === 'guest' &&
            stacks?.filter((stack) => stack.account_id === workspaceAccount?._sid)?.length === 1

        window.CommandBar.addContext(
            ContextAttributeKeys.AppBarVisible,
            () => workspacesMode && !shouldHideAppBarForGuest
        )
    }, [isStudioUser, studioUser, stacks, workspaceAccount])

    const addIsAdminContext = useCallback(() => {
        window.CommandBar.addContext(ContextAttributeKeys.IsAdmin, () => isAdmin)
    }, [isAdmin])

    const addCanViewWorkspaceSettingsContext = useCallback(() => {
        window.CommandBar.addContext(ContextAttributeKeys.CanViewWorkspaceSettings, () =>
            hasRight(Rights.ViewSettings)
        )
    }, [hasRight])

    const addCanViewStackerSupportContext = useCallback(() => {
        window.CommandBar.addContext(ContextAttributeKeys.CanViewStackerSupport, () =>
            hasRight(Rights.ContactSupport)
        )
    }, [hasRight])

    const addUserContext = useCallback(() => {
        window.CommandBar.addContext(
            ContextAttributeKeys.Role,
            () => currentUser?.membership_options?.role
        )
    }, [currentUser?.membership_options?.role])

    /**
     * Add all user stacks
     * Should be the same list that shows in the side panel
     */
    const addAppContext = useCallback(async () => {
        if ((!currentUser || !selectedStack) && location.pathname !== '/__account') {
            window.CommandBar.removeContext(ContextRecordKeys.Apps)
        } else {
            const accountId = selectedStack?.account_id
                ? selectedStack?.account_id
                : workspaceAccount?._sid

            window.CommandBar.addContext(
                ContextRecordKeys.Apps,
                await getStacksWithIcons(
                    currentStack,
                    workspaceAccount,
                    orderAlphabeticallyStacks(stacks, accountId)
                )
            )
        }
    }, [currentStack, currentUser, selectedStack, stacks, workspaceAccount])

    /**
     * Add whether currently impersonating a user
     */
    const addIsImpersonatingContext = useCallback(() => {
        window.CommandBar.addContext(
            ContextAttributeKeys.IsImpersonating,
            () => previewing || isImpersonating
        )
    }, [previewing, isImpersonating])

    /**
     * Add whether support login is currently enabled
     */
    const addIsSupportEnabledContext = useCallback(() => {
        window.CommandBar.addContext(
            ContextAttributeKeys.SupportEnabled,
            () => isSupportLoginPermitted
        )
    }, [isSupportLoginPermitted])

    /**
     * Add whether this is a studio user
     */
    const addIsStudioUserContext = useCallback(() => {
        window.CommandBar.addContext(ContextAttributeKeys.IsStudioUser, () => isStudioUser)
    }, [isStudioUser])

    /**
     * Add all lists for the current stack
     */
    const addListContext = useCallback(() => {
        window.CommandBar.addContext(ContextRecordKeys.Lists, () =>
            navTree.flatMap((navItem) => formatLists(navItem, views))
        )
    }, [navTree, views])

    const addListsForSettings = useCallback(() => {
        if (objects && features) {
            window.CommandBar.addContext(
                ContextRecordKeys.ListsWithSettings,
                getListsForSettings(objects, features, dataConnections)
            )
        }
    }, [dataConnections, features, objects])

    /**
     * Placeholder context for records
     * This needs to be setup ahead of time for the fetch records context to
     * work
     */
    const addRecordsContext = useCallback(() => {
        // Open record needs context to exist to show command even though records are dynamically populated
        window.CommandBar.addContext(ContextRecordKeys.Records, [{ id: '', label: '' }])
    }, [])

    /**
     * Add all user workspaces
     */
    const addWorkspaceContext = useCallback(() => {
        if (!currentUser) return

        window.CommandBar.addContext(
            ContextRecordKeys.Workspaces,
            getWorkspaces(accounts as Account[], currentUser)
        )
    }, [accounts, currentUser])

    /**
     * Callbacks
     */
    const createNewWorkspace = useCallback(() => {
        if (accounts?.length !== 1 || accountRole) {
            window.CommandBar.addCallback(GlobalCallbackKeys.CreateNewWorkspace, () => {
                // @ts-expect-error
                showCreateWorkspaceModal()
            })
        } else {
            window.CommandBar.removeCallback(GlobalCallbackKeys.CreateNewWorkspace)
        }
    }, [accountRole, accounts?.length, showCreateWorkspaceModal])

    const disableSupportLogin = useCallback(() => {
        if (!currentUser) return
        window.CommandBar.addCallback(GlobalCallbackKeys.DisableSupportLogin, () => {
            updateUser({
                id: currentUser._sid,
                patch: {
                    // Remove once useUpdateUser has been converted to TS
                    // @ts-ignore
                    options: {
                        ...currentUser.options,
                        permit_support_login: false,
                    },
                },
            })
        })
    }, [updateUser, currentUser])

    const enableSupportLogin = useCallback(() => {
        window.CommandBar.addCallback(
            GlobalCallbackKeys.EnableSupportLogin,
            ({ support_login_time_interval }) => {
                if (!currentUser) return
                let interval_in_minutes: string
                switch (support_login_time_interval) {
                    case '3 days':
                        interval_in_minutes = '4320'
                        break
                    case '1 week':
                        interval_in_minutes = '10080'
                        break
                    case '1 month':
                        interval_in_minutes = '43800'
                        break
                    default:
                        interval_in_minutes = '-1'
                }

                updateUser({
                    id: currentUser._sid,
                    patch: {
                        // Remove once useUpdateUser has been converted to TS
                        // @ts-ignore
                        options: {
                            ...currentUser.options,
                            permit_support_login: true,
                            support_login_start_date: moment(),
                            support_login_expiration_date:
                                interval_in_minutes !== '-1' &&
                                moment().add(interval_in_minutes, 'minutes'),
                        },
                    },
                })
            }
        )
    }, [updateUser, currentUser])

    const getAppSettingPages = useCallback(() => {
        window.CommandBar.addCallback(AppCallbackKeys.GetAppSettingPages, () =>
            appSettingsMenuItems
                .filter(({ disabled }) =>
                    typeof disabled === 'function'
                        ? !disabled(currentStack, workspaceAccount)
                        : true
                )
                .map(({ name, page }) => ({
                    id: page,
                    label: name,
                }))
        )
    }, [currentStack, workspaceAccount])

    const getListSettingPages = useCallback(() => {
        window.CommandBar.addCallback(ListCallbackKeys.GetListSettingPages, () => [
            { id: 'data-grid', label: 'Open Manage Data Pane' },
        ])
    }, [])

    const getRecords = useCallback(() => {
        window.CommandBar.addCallback(RecordCallbackKeys.FetchRecords, ({ object }) =>
            fetchRecords(object.recordNavId).then((response) =>
                response.records.map(formatters.record)
            )
        )
    }, [])

    const impersonateUser = useCallback(() => {
        window.CommandBar.addCallback(GlobalCallbackKeys.ImpersonateUser, ({ user }) => {
            impersonate({
                user: { _sid: user.id, type: user.type },
                redirectTo: redirectTo(history),
            })
        })
    }, [history, impersonate])

    const openAppSettings = useCallback(() => {
        window.CommandBar.addCallback(GlobalCallbackKeys.OpenAppSettings, ({ page }) => {
            openAppSettingsAction({ page: { name: page.id } })
        })
    }, [openAppSettingsAction])

    const openAppUsers = useCallback(() => {
        window.CommandBar.addCallback(GlobalCallbackKeys.OpenAppUsers, () => {
            openAppUsersAction()
        })
    }, [openAppUsersAction])

    const openListSettings = useCallback(() => {
        if (objects && features) {
            window.CommandBar.addCallback(
                GlobalCallbackKeys.OpenListSettings,
                openDataGrid(objects, showManageData)
            )
        }
    }, [features, objects, showManageData])

    const openWorkspaceSettings = useCallback(() => {
        window.CommandBar.addCallback(GlobalCallbackKeys.OpenWorkspaceSettings, () => {
            openWorkspaceSettingsModal({ page: 'general' })
        })
    }, [])

    const stopImpersonatingUser = useCallback(() => {
        window.CommandBar.addCallback(GlobalCallbackKeys.StopImpersonatingUser, () => {
            stopImpersonating({ redirectTo: 'home' })
        })
    }, [stopImpersonating])

    /**
     * Boot
     */
    useEffect(() => {
        import('commandbar').then(({ init }) => {
            init(settings.COMMANDBAR_ORG_ID)
            setCommandBarInit(true)
        })
    }, [setCommandBarInit])

    useEffect(() => {
        if (currentUser?._sid && commandBarInit) {
            window.CommandBar.boot(currentUser._sid).then(() => {
                setCommandBarReady(true)
            })

            return () => {
                window.CommandBar.shutdown()
            }
        }
    }, [commandBarInit, currentUser?._sid, setCommandBarReady])

    // router
    useAddToCommandBar(addRouterFunction)

    // context
    useAddToCommandBar(addAppBarVisibleContext)
    useAddToCommandBar(addAppContext)
    useAddToCommandBar(addCanViewStackerSupportContext)
    useAddToCommandBar(addCanViewWorkspaceSettingsContext)
    useAddToCommandBar(addIsAdminContext)
    useAddToCommandBar(addIsImpersonatingContext)
    useAddToCommandBar(addIsSupportEnabledContext)
    useAddToCommandBar(addIsStudioUserContext)
    useAddToCommandBar(addListContext)
    useAddToCommandBar(addListsForSettings)
    useAddToCommandBar(addRecordsContext)
    useAddToCommandBar(addUserContext)
    useAddToCommandBar(addWorkspaceContext)

    // callbacks
    useAddToCommandBar(createNewWorkspace)
    useAddToCommandBar(disableSupportLogin)
    useAddToCommandBar(enableSupportLogin)
    useAddToCommandBar(getAppSettingPages)
    useAddToCommandBar(getListSettingPages)
    useAddToCommandBar(getRecords)
    useAddToCommandBar(impersonateUser)
    useAddToCommandBar(openAppSettings)
    useAddToCommandBar(openAppUsers)
    useAddToCommandBar(openListSettings)
    useAddToCommandBar(openWorkspaceSettings)
    useAddToCommandBar(stopImpersonatingUser)

    return null
}

export default withStacks(
    withViews(withNavigation(withStack(withObjects(withPages(withDataConnections(CommandBar))))))
)
