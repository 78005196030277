function joinStrings(values) {
    return values.filter((x) => x && x !== '').join(' ')
}
const getPlainTextFromSlate = (node, length = -1) => {
    let result = ''
    if (Array.isArray(node)) {
        for (let item of node) {
            result = joinStrings([result, getPlainTextFromSlate(item, length - result.length)])
        }
    } else if (node && node.children) {
        result = joinStrings([result, getPlainTextFromSlate(node.children, length - result.length)])
    } else if (node && node.text) {
        let remainingLength = length - result.length
        if (remainingLength <= 0) return result

        let text = remainingLength > 0 ? node.text.substring(0, remainingLength) : node.text
        result = joinStrings([result, text])
    }

    return result
}
export default getPlainTextFromSlate
