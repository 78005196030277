import { useCallback, useMemo, useState } from 'react'

export const useUpdateDirtyPanes = () => {
    const [isDirtyPanes, setIsDirtyPanes] = useState<{ [sid: string]: boolean }>({})

    const updateDirtyPanes = useCallback(
        (objectSid: string, dirty: boolean) => {
            if (isDirtyPanes[objectSid] === dirty) {
                return
            }

            setIsDirtyPanes((prevIsDirtyPanes) => ({
                ...prevIsDirtyPanes,
                [objectSid]: dirty,
            }))
        },
        [isDirtyPanes]
    )

    return useMemo(
        () => ({ updateDirtyPanes, isDirtyPanes, setIsDirtyPanes }),
        [isDirtyPanes, updateDirtyPanes]
    )
}
