import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'

import styled from '@emotion/styled'

import { Icon } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

export const HANDLE_WIDTH = 5

const { colors } = stackerTheme()

const StyledIcon = styled(Icon)`
    cursor: pointer;

    width: ${HANDLE_WIDTH}px;
    margin-right: 5px;

    color: ${colors.userInterface.neutral[500]};
    font-size: 15px;
`

const Handle = SortableHandle(() => <StyledIcon icon="dragHandle" />)

export default Handle
