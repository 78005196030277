import { useCallback } from 'react'

import { useAppContext } from '../../../../app/AppContext'
import { useAppUsersForAdmin } from '../../../../data/hooks/users/useAppUsersForAdmin'
import {
    getPreviousPreviewingRoleId,
    getPreviousPreviewingUser,
} from '../../../auth/utils/roleUtils'
import useImpersonation from '../../../core/useImpersonation'
import { useIsBannerShowing } from '../../../core/useIsBannerShowing'

export const usePreviewAs = (): { open: () => Promise<void> } => {
    const [_isBannerShowing, setIsBannerShowing] = useIsBannerShowing()
    const { selectedStack } = useAppContext()
    const { impersonateRole, impersonateUser } = useImpersonation()
    const internalOptions = { includeUsersWithNoAccess: false }
    const { data, refetch, isFetched } = useAppUsersForAdmin(false, {}, internalOptions)

    const impersonateCallback = useCallback(
        (users) => {
            const defaultRoleSid = selectedStack?.options.roles__default_user_role
            const previousPreviewedRoleId = getPreviousPreviewingRoleId()
            const previousPreviewedUser = getPreviousPreviewingUser()

            if (previousPreviewedUser) {
                return impersonateUser({ user: previousPreviewedUser })
            }

            if (!selectedStack?.user_access_disabled) {
                // TODO: figure out the right type for this
                const firstRealUser = users.find((u: any) => u.role !== 'internal_admin')
                if (firstRealUser) {
                    return impersonateUser({ user: firstRealUser })
                }
            }
            const roleId = previousPreviewedRoleId || defaultRoleSid
            return roleId ? impersonateRole({ roleId: roleId }) : Promise.resolve()
        },
        [selectedStack, impersonateRole, impersonateUser]
    )

    const open = useCallback(async () => {
        const users = isFetched ? data : (await refetch()).data
        impersonateCallback(users)
        setIsBannerShowing(true)
    }, [refetch, data, isFetched, impersonateCallback, setIsBannerShowing])

    return { open }
}
