// @ts-strict-ignore
import React, { useState } from 'react'

import { importClipboardData } from 'data/api/importDataApi'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import useTrack from 'utils/useTrack'

import { Flex, Modal, Text, Toast } from 'v2/ui'

import ImportTableDataContent from './ImportTableDataContent'

type ImportTableDataModalProps = {
    isOpen: boolean
    onSuccess: (objectSid: string) => void
    onClose: () => void
    // optionally pass in a table name which also hides the input asking for table name
    inputTableName?: string
}

const ImportTableDataModal = ({
    isOpen,
    onSuccess,
    onClose,
    inputTableName,
}: ImportTableDataModalProps) => {
    const [error, setError] = useState<string | undefined>()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const { track } = useTrack()

    const cleanup = () => {
        setError(undefined)
    }

    const handleError = () => {
        setError('There was an error processing your data')
        setIsSaving(false)
    }

    const onComplete = async (
        pasteData: string,
        tableName: string,
        generateHeaders: boolean
    ): Promise<void> => {
        cleanup()
        setIsSaving(true)

        track('WIP - Frontend - DataGrid - Import Table Data - Clicked')

        let response = await importClipboardData(pasteData, tableName, generateHeaders).catch(
            handleError
        )

        if (response) {
            const { imported_object_sid: objectSid } = response
            await refetchObjects()
            setIsSaving(false)

            onSuccess(objectSid)
        }
    }

    const onCancel = (): void => {
        cleanup()
        onClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            size="600px"
            showCloseButton={true}
            errorMessage={error}
            isSecondLayer
            data-testid="import-table-data-modal"
            noDividers
            onClose={onCancel}
        >
            <Flex
                direction="row"
                alignItems="center"
                mb={3}
                justifyContent="center"
                style={{ width: '100%' }}
            >
                <Text variant="modalTitle">Paste data from spreadsheet</Text>
            </Flex>

            <ImportTableDataContent
                onComplete={onComplete}
                isSaving={isSaving}
                inputTableName={inputTableName}
            />

            <Toast
                status="error"
                show={error !== undefined}
                title={error}
                onCloseComplete={() => setError(undefined)}
            />
        </Modal>
    )
}

export default ImportTableDataModal
