import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { merge } from 'lodash'

import { useConfirmModalWithPromise } from 'app/ConfirmModal'
import { useObject } from 'data/hooks/objects'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import { invalidateViews } from 'data/hooks/views'
import { assertIsDefined } from 'data/utils/ts_utils'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import useTrack from 'utils/useTrack'
import { UserCancelledError } from 'utils/utils'

import { Box, Collapse, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { FormWrapper } from '../../../ui/forms/Form'
import { SyncRefWithForm } from '../../../ui/forms/utils'
import { getIsSyntheticField } from '../../../utils/fieldUtils'

import {
    editableFieldTypeDefinitionList,
    getEditableFieldTypeDefinition,
} from './definitions/editableFieldTypeDefinitions'
import { canConvertBetweenFieldTypes } from './logic/fieldConversionUtils'
import { getDefaultFieldLabel } from './logic/getDefaultFieldLabel'
import { FieldPatch } from './common'
import FieldEditorForm from './FieldEditorForm'
import Footer from './Footer'
import { useSetDataSyncRequired } from './useSetDataSyncRequired'

const { colors } = stackerTheme()

export type FieldEditorProps = {
    objectId?: string
    field?: FieldDto | null
    onSuccess?: (field: FieldDto) => void
    onDeleteSuccess?: () => void
    onFailure?: () => void
    onCancel?: () => void
    usePortal?: boolean
    ignorePreviewAndImpersonation?: boolean
}

export type FieldEditorHandle = {
    tryClose: (callback: () => void) => void
}

const preparePatch = (patch: FieldPatch, objectId: string, field?: FieldDto | null): FieldPatch => {
    const result = { ...patch }

    if (!field) {
        result.object_id = objectId
    } else {
        result._sid = field._sid
    }

    const fieldTypeDefinition = editableFieldTypeDefinitionList.find(
        (x) => x.value === result.selectedType
    )

    const selectedType = result.selectedType

    // If there is no label set and a type set, then get the default field
    if (!result.label && selectedType) {
        result.label = getDefaultFieldLabel(selectedType, objectId, field?._sid, patch)
    }

    // merge in the base values associated with this field type
    return merge(result, fieldTypeDefinition?.field_template)
}

const FieldEditor = React.forwardRef<FieldEditorHandle, FieldEditorProps>(
    (
        {
            objectId = '',
            field,
            onSuccess,
            onFailure,
            onCancel,
            usePortal,
            ignorePreviewAndImpersonation,
        },
        forwardedRef
    ) => {
        const [requestError, setRequestError] = useState<string | null>(null)
        const formRef = useRef()
        const formElementRef = useRef<HTMLFormElement | null>(null)
        const { modalState: displayChangesModal, saveChanges: checkSaveChanges } =
            useUnsavedChangesModalCallback(formRef)
        const setDataSyncRequired = useSetDataSyncRequired(objectId)
        const { object } = useObject(objectId)
        const { show: showConfirmConvert } = useConfirmConvertModal()

        React.useImperativeHandle(forwardedRef, () => ({
            tryClose(callback) {
                if (field) {
                    // only prompt for unsaved changes if editing an existing field.
                    checkSaveChanges(callback)
                } else {
                    callback()
                }
            },
        }))

        const formContext = useForm<FieldPatch>({
            defaultValues: !!field
                ? {
                      label: field.label,
                      connection_options: { ...field.connection_options },
                      options: { ...field.options },
                      selectedType: getEditableFieldTypeDefinition(field)?.value,
                  }
                : {},
            mode: 'onChange',
            reValidateMode: 'onChange',
        })

        const { createField, changeField } = useObject(objectId)
        const { track } = useTrack()

        const _createField = async (patch: FieldPatch): Promise<FieldDto> => {
            const newField = await createField(patch, {
                bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
            })
            track('WIP - Frontend - Field - Created', {
                // @ts-ignore
                type: newField.type,
                // @ts-ignore
                name: newField.label,
                // @ts-ignore
                options: newField.options,
            })

            // @ts-ignore
            return newField
        }

        const _editField = async (patch: FieldPatch): Promise<FieldDto> => {
            assertIsDefined(field)
            const sourceFieldType = getEditableFieldTypeDefinition(field)
            const targetFieldType = getEditableFieldTypeDefinition(patch)

            assertIsDefined(sourceFieldType)
            assertIsDefined(targetFieldType)
            // If the user is changing the field type to a type which is not
            // natively compatible with the current field type, then we need to
            // show a warning and get confirmation before continuing
            const canConvert = canConvertBetweenFieldTypes(sourceFieldType, targetFieldType)
            // Note: we only warn on Stacker Tables, as other sources will not experience
            // data loss, as we're only changing our representation of the field in the cache
            if (!canConvert.fullySupported && object?.connection_options?.stacker_native_object) {
                track('WIP - Frontend - Field - Type Conversion - Displayed', {
                    source_type: sourceFieldType.value,
                    target_type: targetFieldType.value,
                })
                let updatedField: FieldDto | undefined = undefined
                await showConfirmConvert(canConvert.warningMessage, async () => {
                    track('WIP - Frontend - Field - Type Conversion - Saved', {
                        source_type: field.type,
                        target_type: patch.type,
                    })
                    updatedField = await doCommitEdit(patch)
                })

                assertIsDefined(updatedField)
                return updatedField
            } else {
                const updatedField = await doCommitEdit(patch)

                // For non-stacker tables, if the field type is changing
                // and not to the exact same base data type(ie., string to string)
                // then the cache is going to need refilled.
                if (
                    !field?.is_stacker_augmented_field &&
                    !object?.connection_options?.stacker_native_object &&
                    !canConvert.exactMatch &&
                    !getIsSyntheticField(updatedField)
                ) {
                    await setDataSyncRequired()
                }
                return updatedField
            }
        }

        const doCommitEdit = async (patch: FieldPatch) => {
            assertIsDefined(field)
            const updatedField = await changeField(field._sid, patch, {
                bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
            })
            if (updatedField)
                track('WIP - Frontend - Field - Updated', {
                    type: updatedField.type,
                    name: updatedField.label,
                    options: updatedField.options,
                })

            return updatedField
        }

        const saveChanges = async (patch: FieldPatch) => {
            setRequestError(null)

            const finalPatch = preparePatch(patch, objectId, field)

            try {
                const fieldData = await (!field ? _createField(finalPatch) : _editField(finalPatch))
                await refetchObjects()
                invalidateViews()
                await onSuccess?.(fieldData)
            } catch (error) {
                if (!(error instanceof UserCancelledError)) {
                    console.error(error)
                    setRequestError(
                        !!field ? 'Failed to update the field' : 'Failed to create the field'
                    )
                    onFailure?.()
                }
                throw error
            }
        }

        const cancel = () => {
            formContext.reset()
            setRequestError(null)
            onCancel?.()
        }

        const submitForm = () => {
            formElementRef.current?.requestSubmit()
        }
        return (
            <FormWrapper
                formContext={formContext}
                onSubmit={saveChanges}
                formElementRef={formElementRef}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                    maxHeight: '100%',
                    fontSize: '14px',
                    width: '100%',
                    alignItems: 'stretch',
                    textAlign: 'left',
                }}
                resetOnSuccess
            >
                <FieldEditorForm
                    field={field}
                    object={object}
                    usePortal={usePortal}
                    onSubmit={submitForm}
                />
                <Box height="4" />
                <Footer
                    onCancel={cancel}
                    field={field}
                    object={object}
                    ignorePreviewAndImpersonation={ignorePreviewAndImpersonation}
                    fieldName={'field'}
                />
                <Collapse isOpen={!!requestError}>
                    <Text
                        style={{
                            color: colors.userInterface.error[600],
                            fontSize: '15px',
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        {requestError}
                    </Text>
                </Collapse>
                {/* SyncFormWithRef  is needed because it tracks the dirty state
                    and records it on the form context. Without it, UnsavedChangesModal component
                    can't detect any changes. */}
                <SyncRefWithForm formRef={formRef} />
                {displayChangesModal && (
                    //@ts-ignore
                    <UnsavedChangesModal
                        onSubmitClick={submitForm}
                        usePortal
                        {...displayChangesModal}
                    />
                )}
            </FormWrapper>
        )
    }
)

const getConvertConfirmMessage = (message: React.ReactNode | string | undefined) => {
    return (
        <>
            <Text>{message}</Text> <Text mt={4}>Do you want to continue?</Text>
            <Text mt={4}>
                <em>(This action cannot be undone.)</em>
            </Text>
        </>
    )
}

const useConfirmConvertModal = () => {
    const show = useConfirmModalWithPromise()
    const showConfirm = (message: React.ReactNode, onConfirm: () => Promise<void>) => {
        const data = {
            onConfirm: async () => {
                await onConfirm()
            },
            message: getConvertConfirmMessage(message),
            confirmButtonText: 'Continue',
        }

        return show(data)
    }
    return { show: showConfirm }
}

export default FieldEditor
