import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonGroup } from '@chakra-ui/react'

import { getUrl } from 'app/UrlService'
import useEditMode from 'features/admin/edit-mode/useEditMode'

import { Box, Button, Collapse, Icon, Input, Text } from 'v2/ui'

import { useCreateEmptyTable } from './useCreateEmptyTable'

type CreateEmptyTableModalContentProps = {
    onConfirm?: () => Promise<void>
    isOpen?: boolean
    onClose?: () => void
}

export const CreateEmptyTableModalContent: React.FC<CreateEmptyTableModalContentProps> = ({
    onConfirm,
    isOpen,
    onClose,
}) => {
    const [tableName, setTableName] = useState('')
    const [validationError, setValidationError] = useState('')

    const {
        mutateAsync: createTable,
        isLoading,
        reset,
    } = useCreateEmptyTable({
        onError: () => setValidationError('There was an error creating this table.'),
    })

    const cleanup = useCallback(() => {
        setTableName('')
        setValidationError('')
        reset()
    }, [reset])

    const validateForm = () => {
        if (tableName.length < 1) {
            return 'You must enter a table name'
        }

        return ''
    }

    const history = useHistory()
    const { open: openEditLayout } = useEditMode()

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const validationMessage = validateForm()
        if (validationMessage) {
            setValidationError(validationMessage)

            return
        }

        const { url } = await createTable({ name: tableName })
        history.push(getUrl(url))
        openEditLayout()

        await onConfirm?.()
        cleanup()
    }

    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        onClose?.()
    }

    useEffect(() => {
        if (!isOpen) {
            cleanup()
        }
    }, [isOpen, cleanup])

    return (
        <Box as="form" onSubmit={onSubmit}>
            <Icon icon="table" size="2.7rem" color="userInterface.neutral.700" />
            <Box textAlign="center" mt={3} mb={6}>
                <Text as="label" htmlFor="table-name-input" variant="modalTitle" fontWeight="bold">
                    Name your table
                </Text>
            </Box>

            <Input
                id="table-name-input"
                alignSelf="stretch"
                placeholder="e.g. Customers, Products, Events, Projects…"
                value={tableName}
                maxLength={30}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValidationError('')
                    setTableName(e.target.value)
                }}
                autoFocus={true}
                variant="admin_white"
                width="100%"
            />
            <Collapse isOpen={validationError.length > 0} mt={2} width="100%">
                <Text variant="error" size="sm" width="100%" textAlign="left">
                    {validationError}
                </Text>
            </Collapse>
            <ButtonGroup
                mt={5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                spacing={4}
            >
                <Button
                    variant="adminSecondary"
                    buttonSize="md"
                    type="reset"
                    disabled={isLoading}
                    flex={1}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="adminPrimary"
                    buttonSize="md"
                    type="submit"
                    isLoading={isLoading}
                    flex={1}
                >
                    Create table
                </Button>
            </ButtonGroup>
        </Box>
    )
}
