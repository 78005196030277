import { queryClient } from '../_helpers'

import { STACK_LIST_NAME } from './stackConstants'

export function invalidateStacks() {
    return queryClient.invalidateQueries(STACK_LIST_NAME)
}

export function refetchStacks() {
    return queryClient.refetchQueries(STACK_LIST_NAME)
}
