import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Label = styled('label')`
    color: ${(props) => props.theme.fontColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.theme[props.size || 'fontM']};
    padding: ${(props) => props.theme[props.padding || 'small']} 0px;
    margin: ${(props) => props.theme[props.margin || 'small']} 0px;
    margin-right: ${(props) => props.theme[props.margin || 'medium']};
    display: inline-block;
    text-align: right;
    ${(props) =>
        props.above &&
        `
        display: block;
        text-align: left;
    `};
    ${(props) =>
        props.light &&
        `
        color: ${props.theme.lightFontColor};
    `};
    ${(props) =>
        props.hidden &&
        `
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    `}
`

Label.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['fontS', 'fontM', 'fontL', 'fontXL']),
    above: PropTypes.bool,
    light: PropTypes.bool,
}

Label.displayName = 'Label'
export default Label
