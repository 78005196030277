import React from 'react'

import V4DesignSystem from '../../../../ui/deprecated/V4DesignSystem'
import { FormField } from '../../../../ui/forms/FormField'
import RadioWithContentForm from '../../../../ui/forms/RadioWithContentForm'
import { Text } from '../../../../v2/ui'

import { PermissionRuleLabel } from './PermissionRuleLabel'
import { RoleToggles } from './RoleToggles'

export function PermissionRuleRoleContent({ roles }: { roles: RoleDto[] }) {
    return (
        <div style={{ marginTop: 24 }}>
            <PermissionRuleLabel text="Permission Sharing" />
            <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                Select which users receive this permission
            </Text>

            <FormField
                as={RadioWithContentForm}
                controlled
                name="allRoles"
                options={[
                    {
                        label: 'Everyone',
                        text: 'All users, regardless of their role',
                        value: true,
                    },
                    {
                        label: 'Some users',
                        text: 'Only users with certain roles',
                        value: false,
                        alternativeValues: [null, undefined],
                        content: (
                            <FormField as={RoleToggles} name="roles" controlled roles={roles} />
                        ),
                    },
                ]}
            />
        </div>
    )
}
