import useTrack from 'utils/useTrack'

function getNewlyEnabledInlineFilterFields(
    oldOptions: ListViewOptions,
    newOptions: ListViewOptions
) {
    return newOptions.specificEndUserFilters?.filter(
        (filterField) => !oldOptions.specificEndUserFilters?.includes(filterField)
    )
}

function haveInlineFiltersOptionsChanged(oldOptions: ListViewOptions, newOptions: ListViewOptions) {
    const updatedFilterFieldIds = getNewlyEnabledInlineFilterFields(oldOptions, newOptions)

    return {
        wasToggled: oldOptions.enableEndUserFilters !== newOptions.enableEndUserFilters,
        updatedFilterFieldIds,
    }
}

type Args = {
    object: ObjectDto
    track: ReturnType<typeof useTrack>['track']
    oldOptions: ListViewOptions
    newOptions: ListViewOptions
}

export default function trackInlineFilterChanges({ object, track, oldOptions, newOptions }: Args) {
    const { wasToggled, updatedFilterFieldIds } = haveInlineFiltersOptionsChanged(
        oldOptions,
        newOptions
    )

    if (wasToggled) {
        if (newOptions.enableEndUserFilters) {
            track('frontend_inline_filter_enabled', {
                display_type:
                    newOptions.display === 'single_record'
                        ? 'One Record'
                        : newOptions.display.charAt(0).toUpperCase() + newOptions.display.slice(1),
            })
        } else {
            track('frontend_inline_filters_disabled')
        }
    }

    updatedFilterFieldIds?.forEach((fieldId) => {
        track('frontend_inline_filters_field_enabled', {
            field_name: object.fields.find(({ _sid }) => _sid === fieldId)?.label || 'Not found',
        })
    })

    return { trackInlineFilterChanges }
}
