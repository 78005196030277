import React from 'react'

import { Spinner } from '@chakra-ui/react'

import LabelledAttribute from '../../../../../legacy/v1/ui/components/LabelledAttribute'
import TopLabel from '../../../../../legacy/v1/ui/components/TopLabel'
import { Flex, Icon } from '../../../../../v2/ui'
import { Badge } from '../../../../../v2/ui/components/Badge'

import { StyledTextArea } from './StyledTextArea'

type AirtableConnectionStatusInfoProps = {
    isUpdating: boolean
    status?: string
    webhookData?: Record<string, any>
}

export const AirtableConnectionStatusInfo = ({
    isUpdating,
    status,
    webhookData,
}: AirtableConnectionStatusInfoProps): JSX.Element => (
    <>
        <LabelledAttribute label="Token Status">
            <Flex>
                {isUpdating ? (
                    <Spinner />
                ) : status == 'valid' ? (
                    <Icon icon="check" />
                ) : (
                    <Icon icon="alert" />
                )}
            </Flex>
        </LabelledAttribute>

        <LabelledAttribute label="Token Status Message">
            {isUpdating ? (
                <Spinner />
            ) : (
                <Badge color={status == 'valid' ? 'green' : 'red'}>{status}</Badge>
            )}
        </LabelledAttribute>
        <LabelledAttribute label="Webhook Status">
            <Flex>
                {webhookData ? (
                    <Badge color={webhookData.status === 'valid' ? 'green' : 'red'}>
                        {webhookData.status}
                    </Badge>
                ) : (
                    <>-</>
                )}
            </Flex>
        </LabelledAttribute>
        <Flex style={{ padding: '16px', margin: '8px' }}>
            <TopLabel>Webhook Data</TopLabel>
            <StyledTextArea
                disabled
                value={webhookData ? JSON.stringify(webhookData, undefined, 2) : ''}
            />
        </Flex>
    </>
)
