import React, { FC, useState } from 'react'

import shortid from 'shortid'

import { StepTypes } from 'data/hooks/actions'

import { Button, Flex } from 'v2/ui'

import { usePatchableActionContext } from './hooks/usePatchableActionContext'
import { addStep } from './helpers'

type Props = {
    onRequestClose: () => void
}

export const AddAStep: FC<Props> = ({ onRequestClose }) => {
    const [stepType, setStepType] = useState<string | undefined>()
    const patchableActionContext = usePatchableActionContext()
    if (!patchableActionContext) {
        throw new Error('usePatchableActionContext must be used within a patchableActionContext')
    }

    const { applyPatch, draftObject: action } = patchableActionContext

    return (
        <>
            {!stepType && <SelectStepType onSelect={setStepType} />}
            {stepType && (
                <Button
                    variant="adminPrimary"
                    onClick={() => {
                        applyPatch(
                            addStep(action as ActionDto, {
                                type: stepType,
                                displayName: stepType,
                                id: 'action_step_' + shortid.generate(),
                                fields: [],
                            })
                        )
                        onRequestClose()
                    }}
                >
                    Add Step
                </Button>
            )}
        </>
    )
}

const SelectStepType: FC<{ onSelect: (selectedType: string) => void }> = ({ onSelect }) => (
    <>
        {Object.entries(StepTypes).map(([key, value]) => (
            <Flex key={value}>
                {value}
                <Button
                    onClick={() => onSelect(key)}
                    variant="adminPrimary"
                    icon="add"
                    buttonSize="sm"
                >
                    Add
                </Button>
            </Flex>
        ))}
    </>
)
