import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

type SlideInBoxProps = {
    slideIn: boolean
    slideOut: boolean
}

const SlideInBox: React.FC<SlideInBoxProps> = ({ children, slideIn, slideOut, ...props }) => {
    return (
        <AnimatedBox slideIn={slideIn} slideOut={slideOut} {...props}>
            {children}
        </AnimatedBox>
    )
}

const slideInAnimation = keyframes`
    0% { transform: translateY(1000px) }
    100% { transform: translateY(0) }
`

const slideOutAnimation = keyframes`
    0% { transform: translateY(0) }
    100% { transform: translateY(1000px) }
`

const AnimatedBox = styled.div<{ slideIn: boolean; slideOut: boolean }>`
    position: absolute;
    bottom: 0;
    animation: ${(p) => (p.slideIn ? slideInAnimation : p.slideOut ? slideOutAnimation : 'none')};
    animation-duration: 0.3s;
    transform: ${(p) =>
        p.slideIn ? `translateY(0px)` : p.slideOut ? `translateY(1000px)` : 'none'};
`

export default SlideInBox
