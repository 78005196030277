import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { AppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'

import { Box, Divider, Flex, Icon, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

const AppNavItem = ({
    item,
    isTopLevel,
    label,
    isFolder,
    folderIcon,
    isActive,
    url,
    navTheme,
    showExpandIcon,
    index,
}) => {
    const { selectedStack } = useContext(AppContext)
    let linkUrl = getUrl(url || item.url)

    let labelVariant = isTopLevel ? 'internalNavLabel' : 'internalChildNavLabel'

    if (isActive) {
        labelVariant += 'Active'
    }
    // Determine active status by the real item's url, not the link url
    return (
        <Link
            style={{ width: '100%' }}
            to={`${linkUrl}`}
            className={index === 0 ? ONBOARDING_CLASSES.APP_NAV_MENU_ITEM : ''}
        >
            <NavLink
                isTopLevel={isTopLevel}
                className={isFolder ? 'top-level-folder-nav-item' : ''}
                isActive={isActive}
                navTheme={navTheme}
            >
                <Divider m={1} />

                {selectedStack?.options?.enable_nav_icons && (
                    <Icon
                        iconPack="far"
                        icon={item?.options?.icon}
                        size="sm"
                        opacity={0.7}
                        style={{ width: '15px' }}
                    />
                )}

                <Text
                    mb="0"
                    pl={isTopLevel ? 2 : 4}
                    pr={4}
                    my={2}
                    color={isActive ? navTheme.textColorBright : navTheme.textColor}
                    variant={labelVariant}
                    maxWidth="100%"
                >
                    {folderIcon}
                    {label}
                    {folderIcon}
                </Text>
                <Box flexGrow={1} color="" />
                {showExpandIcon && <Icon icon="caretDown" size="sm" mr={4} opacity={0.5} />}
            </NavLink>
        </Link>
    )
}

export default AppNavItem

const NavLink = styled(Flex)`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    transition: 0.2s background-color ease-in-out;
    color: ${(props) => props.navTheme.textColor} !important;
    max-width: 100%;
    ${(props) =>
        props.isActive &&
        `background-color: ${props.navTheme.highlightColor} !important;
    `}

    &:hover {
        background-color: ${(props) => props.navTheme.hoverColor};
    }

    &:hover .appNavMenuWrapper {
        visibility: visible;
    }
`
