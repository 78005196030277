import React from 'react'

import { Dropdown } from 'v2/ui'

import { cleanUpProps } from '../../cleanUpProps'
import type { RenderRowAttributeRenderProps } from '../../RenderRowAttribute'

type DropdownAttributeProps = RenderRowAttributeRenderProps & {
    options: { value: string; label?: string }[]
    value?: string
    menuPlacement?: string
    isClearable?: boolean
    id?: string
}

const DropdownAttribute: React.VFC<DropdownAttributeProps> = ({
    options,
    value,
    onChange,
    ...otherProps
}) => (
    <Dropdown
        className="interactive"
        onChange={onChange}
        options={options}
        value={value || ''}
        {...cleanUpProps(otherProps)}
    />
)

export default DropdownAttribute
