import React from 'react'

import { Menu, MenuButton, MenuList } from '@chakra-ui/react'

import { InlineFilterMenuButton } from './InlineFilterMenuButton'

type InlineFilterMenuProps = React.ComponentPropsWithoutRef<typeof Menu> & {
    label: React.ReactNode
    disabled?: boolean
    onClear?: () => void
    showClearButton?: boolean
    isActive?: boolean
    buttonProps?: Partial<React.ComponentPropsWithoutRef<typeof MenuButton>>
}

export const InlineFilterMenu = React.forwardRef<HTMLDivElement, InlineFilterMenuProps>(
    (
        { label, disabled, children, onClear, showClearButton, isActive, buttonProps, ...props },
        ref
    ) => {
        return (
            <Menu closeOnSelect={false} isLazy={true} {...props}>
                <MenuButton
                    as={InlineFilterMenuButton}
                    disabled={disabled}
                    onClear={onClear}
                    showClearButton={showClearButton}
                    isActive={isActive}
                    py={typeof label === 'string' ? 1.5 : 0}
                    {...buttonProps}
                >
                    {label}
                </MenuButton>
                <MenuList
                    ref={ref}
                    px={2}
                    py={2}
                    boxShadow="0px 34px 54px -26px rgba(89, 94, 106, 0.25), 0px 9px 24px -8px rgba(89, 94, 106, 0.28)"
                    border={0}
                    rounded="lg"
                    bgColor="userInterface.neutral.0"
                    minWidth="17rem"
                    _focus={{
                        boxShadow:
                            '0px 34px 54px -26px rgba(89, 94, 106, 0.25), 0px 9px 24px -8px rgba(89, 94, 106, 0.28)!important',
                    }}
                    zIndex={1300}
                >
                    {children}
                </MenuList>
            </Menu>
        )
    }
)
