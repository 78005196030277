/* Code Quality: Not audited */

import React from 'react'

import styled from '@emotion/styled'

import Section from './Section'
import Text from './Text'
import TopLabel from './TopLabel'

const AttributeWrapper = styled(Section)`
    margin: 8px;
    flex-basis: ${(props) => props.maxWidth || '250px'};
    max-width: ${(props) => props.maxWidth || '250px'};
    position: relative;

    ${(props) =>
        !props.dontHideOverflow &&
        `
        overflow: hidden;

        & > * {
        max-width: ${props.maxWidth || '250px'};
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > * > p {
        max-width: ${props.maxWidth || '250px'};
        overflow: hidden;
        text-overflow: ellipsis;
    }`};

    ${(props) => props.noPadding && `padding: 0px !important; `}
`

const LabelledAttribute = (props) => (
    <AttributeWrapper
        maxWidth={props.maxWidth}
        dontHideOverflow={props.dontHideOverflow}
        style={props.style}
        noPadding={props.noPadding}
    >
        <TopLabel>
            {props.label}
            {props.required && <RequiredStar>*</RequiredStar>}
        </TopLabel>
        <Text margin="none" padding="none">
            {props.children}
        </Text>
    </AttributeWrapper>
)

export default LabelledAttribute

const RequiredStar = styled.span`
    color: red;
`
