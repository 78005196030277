import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormulaEditor } from 'features/formulas/FormulaEditor'
import { StackerFormulaSyntaxTree } from 'features/formulas/parser/formulaParserTypes'
import { stringifyStackerAST } from 'features/formulas/parser/formulaParsingFunctions'
import { isFormulaField } from 'utils/fieldUtils'

import { FormField } from '../../../ui/forms/FormField'

import { ConfigurationComponentProps } from './common'

export const FormulaFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({
    object,
    field,
}) => {
    const formContext = useFormContext()
    const patchDestination = 'connection_options'

    if (!object) return null
    return (
        <FormField
            name={patchDestination}
            as={FormulaEditor}
            errorMessages={{ required: 'A valid formula is required' }}
            controlledRender={({ field: formField }) => {
                return (
                    <FormulaEditorWrapper
                        object={object}
                        field={field}
                        onChange={(parsed, formula) => {
                            formContext.clearErrors(patchDestination)
                            formField.onChange({
                                formula_parsed: parsed,
                                formula_string: formula,
                            })
                        }}
                        onError={() => {
                            formContext.setValue(patchDestination, null)
                        }}
                        onValid={() => {
                            formContext.clearErrors(patchDestination)
                        }}
                    />
                )
            }}
            controlled
            required
        />
    )
}

type FormulaEditorWrapperProps = {
    field: FieldDto
    object: ObjectDto
    onChange: (parsed: StackerFormulaSyntaxTree, formula: string) => void
    onError: () => void
    onValid: () => void
}

const FormulaEditorWrapper: React.FC<FormulaEditorWrapperProps> = ({
    field,
    object,
    onChange,
    onError,
    onValid,
}) => {
    const initialFormulaString = useMemo(() => {
        if (isFormulaField(field)) {
            return stringifyStackerAST(field?.connection_options?.formula_parsed, object)
        }
        return ''
    }, [field, object])

    return (
        <FormulaEditor
            field={field}
            initialFormulaString={initialFormulaString}
            object={object}
            onChange={(update) => {
                if (update.isValid) {
                    onChange(update.parsedFormula, update.formulaString)
                    onValid()
                } else {
                    onError()
                }
            }}
        />
    )
}
