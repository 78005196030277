import isRichTextField from 'utils/isRichTextField'

const fieldToIcon = (field) => {
    if (field.type === 'string') {
        if (isRichTextField(field)) {
            return { icon: 'text-box', iconStyle: 'ios-glyphs', typeLabel: 'Rich text' }
        }
        return { icon: 'text-color', iconStyle: 'ios-glyphs', typeLabel: 'Text' }
    }
    if (field.type === 'number')
        return { icon: '123', iconStyle: 'ios-glyphs', typeLabel: 'Number' }
    if (field.type === 'long_text')
        return { icon: 'text-box', iconStyle: 'ios-glyphs', typeLabel: 'Long text' }
    if (field.type === 'lookup')
        return { icon: 'link', iconStyle: 'ios-glyphs', typeLabel: 'Record link' }
    if (field.type === 'multi_lookup')
        return { icon: 'link', iconStyle: 'ios-glyphs', typeLabel: 'Multi record link' }
    if (field.type === 'address')
        return { icon: 'address', iconsStyle: 'ios-glyphs', typeLabel: 'Address' }
    if (field.type === 'checkbox')
        return { icon: 'checked-checkbox', iconsStyle: 'ios-glyphs', typeLabel: 'Checkbox' }
    if (field.type === 'autoid')
        return { icon: 'fingerprint', iconsStyle: 'ios-glyphs', typeLabel: 'Auto-id' }
    if (field.type === 'url')
        return { icon: 'domain', iconsStyle: 'ios-glyphs', typeLabel: 'Web URL' }
    if (field.type === 'date')
        return { icon: 'calendar-17', iconsStyle: 'ios-glyphs', typeLabel: 'Date' }
    if (field.type === 'datetime')
        return { icon: 'time-machine', iconsStyle: 'ios-glyphs', typeLabel: 'Date and time' }
    if (field.type === 'image')
        return { icon: 'image', iconsStyle: 'ios-glyphs', typeLabel: 'Image' }
    if (field.type === 'file')
        return { icon: 'file', iconsStyle: 'ios-glyphs', typeLabel: 'Attachment' }
    if (field.type === 'multi_file')
        return { icon: 'file', iconsStyle: 'ios-glyphs', typeLabel: 'Attachments' }
    if (field.type === 'copy') return { icon: 'copy', iconsStyle: 'ios-glyphs', typeLabel: 'Copy' }
    if (field.type === 'password')
        return { icon: 'password', iconsStyle: 'ios-glyphs', typeLabel: 'Password' }
    if (field.type === 'dropdown')
        return { icon: 'down-squared', iconsStyle: 'ios-glyphs', typeLabel: 'Dropdown' }
    if (field.type === 'currency')
        return { icon: 'currency-exchange', iconsStyle: 'ios-glyphs', typeLabel: 'Currency' }
    if (field.type === 'currency_varying')
        return {
            icon: 'currency-exchange',
            iconsStyle: 'ios-glyphs',
            typeLabel: 'Currency',
        }
    if (field.type === 'percentage')
        return { icon: 'percentage2', iconsStyle: 'ios-glyphs', typeLabel: 'Percentage' }
    if (field.type === 'multi_select')
        return { icon: 'down-squared', iconsStyle: 'ios-glyphs', typeLabel: 'Multi-select' }

    return { icon: 'gift', iconStyle: 'ios-glyphs', typeLabel: 'Gift' }
}

export default fieldToIcon
