/* Code Quality: Not audited */

import { Component } from 'react'

import PropTypes from 'prop-types'

export default class LazyLoader extends Component {
    constructor(props) {
        super(props)
        this.loadModule = this.loadModule.bind(this)
    }
    static propTypes = {
        getModule: PropTypes.func.isRequired,
    }

    failures = 0

    clearCurrentTimeout = () => {
        if (this.windowTimeout) {
            clearTimeout(this.windowTimeout)
            this.windowTimeout = null
        }
    }

    loadModule() {
        this.props
            .getModule()
            .then((Component) => {
                this.Component = Component
                if (!this.unmounted) this.setState({ isLoaded: true })
            })
            .catch(() => {
                if (!this.unmounted) {
                    this.failures++
                    // Retry up to 3 times to account for intermittent network errors.
                    // Increase the delay by 200ms for each time, in case the server
                    // is temporarily unresponsive.
                    if (this.failures <= 3) {
                        this.clearCurrentTimeout()
                        this.windowTimeout = setTimeout(this.loadModule, 200 * this.failures)
                    } else {
                        this.setState({ hasError: true })
                    }
                }
            })
    }

    state = { isLoaded: false }
    UNSAFE_componentWillMount() {
        this.loadModule()
    }
    componentWillUnmount() {
        this.unmounted = true
        this.clearCurrentTimeout()
    }

    render() {
        if (this.state.hasError)
            return 'This component failed to load. Please refresh to try again.'
        if (!this.state.isLoaded) return null

        return this.props.children(this.Component)
    }
}
