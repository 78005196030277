import { getBucketDate } from './getBucketDate'

export const getBucketRange = (
    value: string | undefined,
    defaultLabel: string,
    bucketBy: Bucket | undefined
) => {
    if (!value || !bucketBy) return defaultLabel
    const date = getBucketDate(value, bucketBy)
    if (!date) return defaultLabel
    switch (bucketBy) {
        case 'day':
            return date.format('MMM D YYYY')
        case 'week':
            return `${date.startOf('isoWeek').format('MMM D YYYY')} - ${date
                .endOf('isoWeek')
                .format('MMM D YYYY')}`
        case 'month':
            return `${date.startOf('month').format('MMM D YYYY')} - ${date
                .endOf('month')
                .format('MMM D YYYY')}`
        case 'year':
            return `${date.startOf('year').format('MMM D YYYY')} - ${date
                .endOf('year')
                .format('MMM D YYYY')}`
        case 'quarter':
            return `${date.startOf('quarter').format('MMM D YYYY')} - ${date
                .endOf('quarter')
                .format('MMM D YYYY')}`
        default:
            return defaultLabel
    }
}
