import { History, Location } from 'history'
// @ts-ignore
import queryString from 'query-string'

import { getOrderByQueryValue } from './getOrderByQueryValue'
import { ORDER_BY_QUERY_ID } from './orderByQueryId'
import { ListViewOrderBy } from './types'

type updateLocationWithOrderByProps = {
    orderBy: ListViewOrderBy
    location: Location
    history: History
}

export const updateLocationWithOrderBy = ({
    orderBy,
    location,
    history,
}: updateLocationWithOrderByProps): undefined => {
    const orderByValue = getOrderByQueryValue(orderBy)

    const query = queryString.parse(location?.search)

    if (query[ORDER_BY_QUERY_ID] === orderByValue) return

    const newQuery = { ...query }
    if (orderByValue) {
        newQuery[ORDER_BY_QUERY_ID] = orderByValue
    } else {
        delete newQuery[ORDER_BY_QUERY_ID]
    }

    const locationState = typeof location?.state === 'object' ? location?.state : {}

    history?.replace({
        pathname: location?.pathname,
        hash: location?.hash,
        search: queryString.stringify(newQuery),
        state: { ...locationState },
    })
}
