/* Code Quality: Not audited */

const AvailableActions = ({ context, children }) => {
    // For each item in the store, get out the item's actions
    const actions = getActionListFromContext(context)
    return children(actions)
}

export default AvailableActions

export const getActionListFromContext = (context) => {
    const actions = []
    const store = context.propertyStore.store
    Object.keys(store).map((id) => {
        const blockActions = store[id].action || {}
        Object.keys(blockActions).forEach((actionId) => actions.push(blockActions[actionId]))
    })
    return actions
}
