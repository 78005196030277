import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'

import { useAppContext } from 'app/AppContext'

export function useAuth0Redirect(): (options: RedirectLoginOptions | undefined) => void {
    const { workspaceAccount } = useAppContext()
    const { loginWithRedirect } = useAuth0()
    const { auth0_organization_id } = workspaceAccount || {}
    const redirectToAuth0 = (options = {}) => {
        loginWithRedirect({
            organization: auth0_organization_id,
            redirectUri: `${window.location.origin}/complete-login`,
            appState: {
                returnTo: `${window.location.origin}/complete-login${window.location.search}`,
            },
            prompt: 'login',
            ...options,
        })
    }

    return redirectToAuth0
}

export default useAuth0Redirect
