// @ts-strict-ignore
import React from 'react'

import { withObjects } from 'data/wrappers/WithObjects'
import { AppBarWidth, StackButton } from 'features/AppBar/AppBar'
import { AppBarButton } from 'features/AppBar/AppBarButton'
import { getLeftOffset } from 'features/core/getLeftOffset'

import { Box } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'
import { Divider, Flex } from '../../../v2/ui'
import { getNavigationColorByAccount } from '../../../v2/ui/hooks/useNavigationColor'
import AppNavItem from '../Sidebar/AppNavItem'
import { getIsStackerUserObject } from '../stackerUserUtils'
import { WorkspaceLogoInner as WorkspaceLogo } from '../WorkspaceLogo'

type MockFrameProps = {
    stack: StackDto
    account: Account
    disabledTables: any
}

export const MockFrame: React.FC<MockFrameProps> = ({
    stack,
    account,
    disabledTables,
    children,
}) => {
    const theme = stack?.options?.theme ?? {}

    const navTheme = getNavigationColorByAccount(theme, account)
    const width = getLeftOffset(false, true, true)
    return (
        <Flex width="100%" height="100%" align="stretch">
            <Flex
                column
                bg={navTheme.bgAppBar}
                py={[1, 1, 1, 2]}
                wrap="nowrap"
                height="100%"
                maxHeight="100%"
                width={AppBarWidth}
                align="center"
                flexShrink={0}
                id="AppBar"
                justify="space-between"
            >
                <AppBarButton icon="bell" navTheme={navTheme} />
                <Box height={4} />
                <StackButton stack={stack} isSelected isDisabled={false} navTheme={navTheme} />
                <Box flexGrow={1} />
                <AppBarButton icon="avatar" navTheme={navTheme} />
            </Flex>
            <Flex>
                <Flex
                    column
                    wrap="nowrap"
                    flexGrow={1}
                    minWidth={0}
                    width={width}
                    alignSelf="stretch"
                    align="stretch"
                    background={navTheme.bgScdNav}
                    maxWidth="100%"
                    style={
                        navTheme.theme === 'light'
                            ? { borderLeft: '1px solid #F5F7F8', borderRight: '1px solid #EEF0F1' }
                            : {}
                    }
                >
                    <Box
                        style={{ padding: '13px 5px 10px', minHeight: 66 }}
                        textAlign="center"
                        color={navTheme.textBright}
                        className={STYLE_CLASSES.LOGO}
                    >
                        <WorkspaceLogo workspaceAccount={account} />
                    </Box>

                    <Divider variant="custom" bg="#AAAAAA33" />

                    <Flex px={4} pt={4} pb={1} style={{ paddingRight: 5 }}>
                        <div style={{ ...V4DesignSystem.h2, color: navTheme.textBright }}>
                            {stack.name}
                        </div>
                    </Flex>

                    <Box overflowY="auto" flexGrow={1} flexShrink={1}>
                        {!account?.options?.onboarding_status?.data_connected ? (
                            Array.from(Array(3)).map((item, index) => (
                                // @ts-ignore
                                <AppNavItem
                                    key={index}
                                    url="/"
                                    isTopLevel
                                    label={`Page ${index + 1}`}
                                    navTheme={navTheme}
                                />
                            ))
                        ) : (
                            <MockNavBar navTheme={navTheme} disabledTables={disabledTables} />
                        )}
                    </Box>
                </Flex>
            </Flex>
            {children}
        </Flex>
    )
}

const MockNavBar = withObjects(({ disabledTables, navTheme, objects }) => {
    return objects.map((object, index) => {
        if ((disabledTables && disabledTables[object._sid]) || getIsStackerUserObject(object)) {
            return null
        }

        return (
            // @ts-ignore
            <AppNavItem key={index} url="/" isTopLevel label={object.name} navTheme={navTheme} />
        )
    })
})
