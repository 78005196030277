import React, { FC } from 'react'

import { ConfigureWidgetPlaceholder } from 'v2/ui'
import { WidgetCharts } from 'v2/ui/svgs'

export const ChartsBlockEmptyState: FC = () => (
    <ConfigureWidgetPlaceholder
        message="Charts"
        subtitle="Display a row of charts"
        Icon={() => <WidgetCharts size={32} />}
    />
)
