// @ts-nocheck
import React from 'react'

import { Flex } from 'v2/ui'

export const LinkPreviewContainer = ({ children }) => {
    return (
        <Flex borderRadius={6} padding={4} bg="gray.100" align="start" justify="center">
            {React.Children.map(children, (child, index) => {
                const isLast = index === children.length - 1
                return (
                    <Flex
                        borderRadius={4}
                        boxShadow="sm"
                        padding={4}
                        column
                        align="start"
                        flex={1}
                        bg="white"
                        mr={isLast ? 0 : 4}
                    >
                        {child}
                    </Flex>
                )
            })}
        </Flex>
    )
}
