import React, { ChangeEvent, forwardRef, KeyboardEvent, useLayoutEffect, useRef } from 'react'
import type {
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd'

import styled from '@emotion/styled'

import { getDropdownFieldOptionColor } from 'utils/fieldUtils'

import { Input } from 'v2/ui'
import { SolidClose, SolidDrag } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import ColorPicker from './ColorPicker'
import type { EditorDropdownOption } from './types'

const { colors } = stackerTheme()

const Wrapper = styled.div<{ hasError: boolean }>`
    cursor: grab;
    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: ${colors.userInterface.neutral[0]};

    border-width: ${(props) => (props.hasError ? 2 : 1)}px;
    border-style: solid;
    border-color: ${(props) =>
        props.hasError ? colors.userInterface.error[600] : colors.userInterface.neutral[500]};
    border-radius: 5px;

    padding: 0.25rem;

    transition: border-color 0.2s ease;

    &:hover {
        border-color: ${(props) =>
            props.hasError ? colors.userInterface.error[600] : colors.userInterface.neutral[800]};
    }

    &:not(:first-child) {
        margin-top: 0.5rem;
    }
`

const DragLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;

    width: 100%;
`

const CloseIconWrapper = styled.span`
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: -2px;
`

type Props = {
    allowDropdownColors: boolean
    option: EditorDropdownOption
    placeholder: string
    isFocused: boolean
    isDragging: boolean
    disabled: boolean
    onFocus: () => void
    onBlur: () => void
    onChange: (updatedOption: EditorDropdownOption) => void
    onDelete: () => void
    onFocusNext: () => void
    draggableProps: DraggableProvidedDraggableProps
    dragHandleProps: DraggableProvidedDragHandleProps
}

const FieldOption = forwardRef<HTMLDivElement, Props>(
    (
        {
            allowDropdownColors,
            option,
            placeholder,
            isFocused,
            isDragging,
            disabled,
            onFocus,
            onBlur,
            onChange,
            onDelete,
            onFocusNext,
            draggableProps,
            dragHandleProps,
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>()

        // We need to refocus the input if previously focused and is not being dragged
        // This is a side effect of DragAndDropList that clones the children
        // and render them. The underlying DOM node is a different instance, losing the focus.
        useLayoutEffect(() => {
            if (isDragging || !isFocused) {
                return
            }

            inputRef.current?.focus()
        }, [isDragging, isFocused])

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key !== 'Enter') {
                return
            }

            event.preventDefault()
            event.stopPropagation()
            onFocusNext()
        }

        return (
            <Wrapper
                ref={ref}
                hasError={!!option.hasError}
                {...draggableProps}
                {...dragHandleProps}
            >
                <DragLabelWrapper>
                    <SolidDrag size={16} />
                    {allowDropdownColors && (
                        <ColorPicker
                            color={getDropdownFieldOptionColor(option)!}
                            disabled={disabled}
                            onChange={(color) => onChange({ ...option, color })}
                        />
                    )}
                    <Input
                        ref={inputRef}
                        style={{ borderWidth: 0, padding: 2 }}
                        groupProps={{ style: { width: '100%' } }}
                        placeholder={placeholder}
                        variant="settings"
                        value={option.label}
                        disabled={disabled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            onChange({
                                ...option,
                                label: event.target.value,
                                value: event.target.value,
                            })
                        }
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </DragLabelWrapper>
                {!disabled && (
                    <CloseIconWrapper onClick={onDelete}>
                        <SolidClose color={colors.userInterface.neutral[700]} />
                    </CloseIconWrapper>
                )}
            </Wrapper>
        )
    }
)

export default FieldOption
