import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import { Heading, Icon8 } from 'legacy/v1/ui'

const SettingsHeading = ({ name, parents }) => (
    <Heading style={{ marginTop: 50, fontSize: 40, marginBottom: 20 }}>
        <span style={{ opacity: '0.2' }}>
            <Link to={getUrl(Urls.Admin)}>Setup</Link>
            {parents &&
                parents.map(({ link, name }) => (
                    <>
                        <Icon8 style={{ margin: 0 }} displaySize="25" icon="sort-right--v1" />
                        <Link to={link}>{name}</Link>
                    </>
                ))}
            <Icon8 style={{ margin: 0 }} displaySize="25" icon="sort-right--v1" />
        </span>
        <span>{name}</span>
    </Heading>
)

SettingsHeading.propTypes = {
    name: PropTypes.string.isRequired,
}
export default SettingsHeading
