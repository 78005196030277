import React, { useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { UserApi } from 'data/api/userApi'

import { Button, Flex, Input, LoadingScreen, Modal, Text } from 'v2/ui'
import { useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

const MODAL_KEY = 'SharingLink'
export const useSharingLinkModal = (data) => {
    return useModalDeclaration(MODAL_KEY, SharingLinkModal, data)
}

const SharingLinkModal = () => {
    const { selectedStack } = useAppContext()
    const [isLoading, setIsLoading] = useState(true)
    const [apiToken, setApiToken] = useState()
    const modal = useModalToggle(MODAL_KEY)
    const { isOpen, toggle, data } = modal

    const userId = data?.userId
    useEffect(() => {
        if (userId && isOpen) {
            setIsLoading(true)
            UserApi.impersonate(userId).then(({ api_token }) => {
                setApiToken(api_token)
                setIsLoading(false)
            })
        }
    }, [userId, isOpen])
    if (!data) return null

    const sharingLink = `${window.location.protocol}//${selectedStack.url}/login?api_token=${apiToken}`
    const regenerateApiTokenForAuth = () => {
        setIsLoading(true)
        // Sends a request to regenerate the user's api token in the db
        // Access with the previous token will therefore be revoked
        UserApi.regenerateApiTokenForAuth(userId)
            .then(({ api_token }) => {
                setApiToken(api_token)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={toggle}
            size={'450px'}
            title={`Share access to ${selectedStack.name} app`}
            noDividers
        >
            <LoadingScreen isLoading={isLoading} minHeight="100px">
                <Flex column wrap="nowrap" align="stretch">
                    <Text mt={4} mb={2} color="neutral.900">
                        Anyone with the link can log in as <b>{data.userName}</b>
                    </Text>
                    <Flex wrap="nowrap">
                        <Input
                            readonly
                            value={sharingLink}
                            variant="admin_white"
                            flexGrow={1}
                            style={{ cursor: 'auto' }}
                        />
                        <Button
                            icon="link"
                            href={sharingLink}
                            variant="iconButton"
                            color="neutral.800"
                            buttonSize="smDense"
                            openInNewTab
                            mr="-8px"
                        />
                    </Flex>
                    <>
                        <Button
                            label="Regenerating this sharing link means the user will no longer be able to access your app with the current sharing link."
                            labelPlacement="left"
                            onClick={regenerateApiTokenForAuth}
                            variant="link"
                            color="neutral.800"
                            buttonSize="smNoPadding"
                            mt="8px"
                        >
                            Regenerate Sharing Link
                        </Button>
                    </>
                </Flex>
            </LoadingScreen>
        </Modal>
    )
}

export default SharingLinkModal
