import { RefObject, useCallback, useState } from 'react'

// This hook allows us to trigger changes when a DOM ref changes
// useEffects will not work on standard react refs
const useRefCallback = <T = any>() => {
    const [ref, setRefState] = useState<RefObject<T | undefined>>({ current: undefined })
    const setRef = useCallback((node) => {
        // Save a reference to the node
        setRefState({ current: node })
    }, [])

    return [ref, setRef] as const
}

export default useRefCallback
