import React from 'react'

import get from 'lodash/get'

import { fetchWithAuth } from 'data/utils/utils'

import { Button, Heading, Input, Modal, Text } from 'v2/ui'

export default class AddNewAdminButton extends React.Component {
    state = {
        adminModalOpen: false,
        inviteSent: false,
    }

    restartForm = () => {
        this.setState({
            sendButtonLabel: undefined,
            inviteSent: false,
            disableSendButton: false,
            error: undefined,
            email: '',
        })
    }
    sendInvite = () => {
        if (!this.state.email) {
            this.setState({ error: 'You must specify an email address' })
            return
        }

        if (this.state.error) this.setState({ error: undefined }) //Clear the error if any

        this.setState({ disableSendButton: true, sendButtonLabel: 'Sending invite' })

        fetchWithAuth('account/add-user/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                is_workspace: false,
                options: { role: 'owner' },
            }),
        })
            .then((response) => {
                response.json().then((json) => {
                    if (response.status >= 400) {
                        this.setState({
                            error: get(json, '[0]', 'There was an error'),
                            sendButtonLabel: undefined,
                            disableSendButton: false,
                        })
                    } else {
                        this.setState({ inviteSent: true })
                    }
                })
            })
            .catch(() => {
                this.setState({
                    error: 'Something was wrong',
                    sendButtonLabel: undefined,
                    disableSendButton: false,
                })
            })
    }

    onClose = () => {
        this.setState({ adminModalOpen: false })
    }

    modalActions = () => {
        const closeAction = {
            label: 'Close',
            onClick: this.onClose,
            variant: 'clear',
            buttonSize: 'sm',
        }
        if (this.state.inviteSent) {
            return [
                {
                    label: 'Send another invitation',
                    onClick: this.restartForm,
                    variant: 'adminSecondary',
                    buttonSize: 'md',
                },
                closeAction,
            ]
        } else {
            return [
                {
                    label: this.state.sendButtonLabel || 'Send invite',
                    onClick: this.sendInvite,
                    variant: 'adminPrimary',
                    buttonSize: 'md',
                    disabled: this.state.disableSendButton,
                },
                closeAction,
            ]
        }
    }
    adminModal = () => {
        let body
        if (this.state.inviteSent) {
            body = (
                <>
                    <Heading variant="modal" value="Add a new admin" />
                    <Text variant="modalBody">Invite sent to:</Text>
                    <Text variant="modalBody">
                        <strong>{this.state.email}</strong>
                    </Text>
                </>
            )
        } else {
            body = (
                <>
                    <Heading variant="modal" value="Add a new admin" />
                    <Text variant="modalBody">
                        You can invite other users to access <strong>all your apps</strong>.
                    </Text>
                    <Text variant="modalBody">
                        If you add an email address we will create a new account, and send them an
                        email to set their password.
                    </Text>
                    <Input
                        m="20px 0px"
                        placeholder="email@domain.com"
                        variant="admin"
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                    />

                    <Text variant="error">{this.state.error}</Text>
                </>
            )
        }
        return (
            <Modal
                isOpen={this.state.adminModalOpen}
                showCloseButton={false}
                onClose={this.onClose}
                body={body}
                actions={this.modalActions()}
            ></Modal>
        )
    }
    handleClick = () => {
        this.restartForm()
        this.setState({ adminModalOpen: true })
    }
    render() {
        return (
            <>
                {this.props.render ? (
                    this.props.render(this.handleClick)
                ) : (
                    <Button
                        variant="adminSecondary"
                        mr="10px"
                        buttonSize="sm"
                        ml="auto"
                        onClick={this.handleClick}
                    >
                        Add new admin
                    </Button>
                )}
                {this.adminModal()}
            </>
        )
    }
}
