import { useMemo } from 'react'

import { useRecordFiltersForRelartedRecords } from 'v2/blocks/useRecordFiltersForRelatedRecords'

import { useFields } from 'data/hooks/fields'
import { formatFilters } from 'features/records/components/RecordFilters'

import { useTimePeriodFilters } from '../common/timePeriodFilters/useTimePeriodFilters'

export function useMetricConfiguration(
    metric: MetricsBlockAttributes,
    view: ViewDto,
    record: RecordDto | {},
    previousTimePeriod: boolean = false
) {
    const { data: fields } = useFields()

    const relationshipField = useMemo(
        () => fields?.find(({ _sid }) => _sid === metric?.relatedField?.fieldId),
        [fields, metric?.relatedField]
    )

    const relatedRecordFilters = useRecordFiltersForRelartedRecords({
        isExternalField: metric?.relatedField?.isExternalField,
        localRecord: record,
        field: relationshipField,
    })

    const timePeriodFilters = useTimePeriodFilters(metric, previousTimePeriod)

    return useMemo(
        () => ({
            chart_type: 'Number' as ChartType,
            object_sid: metric.objectId,
            group: { type: 'all' as ChartGroupType },
            aggr: metric.aggregate,
            filters: [
                ...metric.filters,
                ...timePeriodFilters,
                ...(metric.showRelated
                    ? (formatFilters(relatedRecordFilters) as ChartFilter[])
                    : ([] as ChartFilter[])),
                ...(metric.inheritFiltersFromViewId
                    ? (formatFilters(view?.options.filters ?? []) as ChartFilter[])
                    : []),
            ],
            previousTimePeriod,
        }),
        [
            metric.aggregate,
            metric.filters,
            metric.inheritFiltersFromViewId,
            metric.objectId,
            metric.showRelated,
            previousTimePeriod,
            relatedRecordFilters,
            timePeriodFilters,
            view?.options.filters,
        ]
    )
}
