import * as React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import 'react-grid-layout/css/styles.css'
import './GridLayout.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

/**
 *
 * @param {import('react-grid-layout').ResponsiveProps} props
 */
export default function GridLayout(props) {
    return <ResponsiveGridLayout isBounded measureBeforeMount useCSSTransforms={false} {...props} />
}
