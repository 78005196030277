import { RefObject, useLayoutEffect, useRef, useState } from 'react'

const useIntersection = (ref: RefObject<HTMLElement>, rootMargin?: string): boolean => {
    const [isVisible, setIsVisible] = useState(false)
    const observer = useRef<IntersectionObserver | null>(null)

    useLayoutEffect(() => {
        // The observer must be updated if the rootMargin changed
        // If any current observer, it needs to be disconnected first
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new IntersectionObserver(
            ([{ isIntersecting }]) => setIsVisible(isIntersecting),
            { rootMargin }
        )

        // We need to keep the current observer in a local variable for cleanup
        // observer.current is mutable so it may not be available at cleanup time, resulting in a memory leak
        const { current: currentObserver } = observer

        if (ref.current) {
            // Because `IntersectionObserver` works asynchronously,
            // it might take a couple of renders before `isVisible` is changed.
            // With this line here, we make sure that we set its
            // initial value in a synchronous way, to prevent elements
            // flashing randomly.
            setIsVisible(isInViewport(ref.current))

            currentObserver.observe(ref.current)
        }

        return () => {
            if (currentObserver) currentObserver.disconnect()
        }
    }, [ref, rootMargin])

    return isVisible
}

// Check if an element is in viewport in a synchronous way.
function isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect()

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= viewportHeight &&
        rect.right <= viewportWidth
    )
}

export default useIntersection
