import * as React from 'react'

import AppContext from 'app/AppContext'
import settings from 'app/settings'
import { useUpdateStack } from 'data/hooks/stacks'
import { fetchWithAuth } from 'data/utils/utils'
import { isUrlValid } from 'utils/utils'

import { Alert, Input, Text } from 'v2/ui'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

import {
    BaseUrlInput,
    Divider,
    FormFrame,
    SettingLabel,
    SettingRow,
} from '../../workspace/WorkspaceSettingsModalUi'

export default function CustomerAccessGeneralSettings({ formRef, children, isCompact }) {
    const { selectedStack } = React.useContext(AppContext)
    const { mutateAsync: updateStack } = useUpdateStack()

    const isCustomDomainLocked = isFeatureLocked(FEATURES.customDomain, selectedStack)

    const getDomain = () => {
        const baseUrl = selectedStack?.base_url

        // special case: if the existing stack url is an airportal.app domain
        // then we want to preserve that. Otherwise, we'll use the user_domain value
        // from settings
        if (baseUrl.endsWith('.airportal.app')) {
            return 'airportal.app'
        } else {
            return settings.USER_DOMAIN
        }
    }
    const checkBaseUrl = async (value) => {
        // if no change, return true
        if (value === selectedStack.base_url?.split('.')[0]) return

        const url = `stack/name/?name=${encodeURIComponent(value)}&domain=${encodeURIComponent(
            getDomain()
        )}`
        return await fetchWithAuth(url)
            .then((response) => {
                if (response.status >= 400) {
                    return false
                } else
                    return response.json().then((json) => {
                        return json && json.base_url && json.base_url.split('.')[0] === value
                    })
            })
            .catch(() => {
                return false
            })
            .then((result) => result || 'This URL is already taken')
    }

    const checkCustomBaseUrl = async (value) => {
        // if no change or user has cleared the field, return true
        if (value === selectedStack.custom_base_url || value === '') return

        // If custom URL is NOT valid, return error message.
        if (!isUrlValid(value)) return 'This URL is not valid'

        const url = `stack/name/?customBaseUrl=${encodeURIComponent(value)}`
        return await fetchWithAuth(url)
            .then((response) => {
                if (response.status >= 400) {
                    return false
                } else
                    return response.json().then((json) => {
                        return json && json.custom_base_url === value
                    })
            })
            .catch(() => {
                return false
            })
            .then((result) => result || 'This URL is already taken')
    }

    const handleSave = (data) => {
        let { external_access, ...patch } = data

        if (patch?.base_url) {
            patch.base_url = `${patch.base_url}.${getDomain()}`
        }
        return updateStack({
            id: selectedStack._sid,
            patch: { ...patch, options: { ...selectedStack.options, external_access } },
        })
    }
    return (
        <Form
            onSubmit={handleSave}
            options={{
                defaultValues: {
                    external_access: {
                        name:
                            selectedStack.options?.external_access?.name ||
                            (!isCompact && selectedStack?.name) ||
                            '',
                    },
                    base_url: selectedStack.base_url?.split('.')[0] || '',
                    custom_base_url: selectedStack.custom_base_url || '',
                },
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame title={isCompact ? undefined : 'General'} formRef={formRef}>
                <>
                    {!isCompact && <Divider dense />}
                    <SettingRow
                        leftSide={<SettingLabel>Name</SettingLabel>}
                        rightSide={
                            <FormField
                                as={Input}
                                name="external_access.name"
                                formRef={formRef}
                                placeholder={
                                    isCompact ? 'enter an external app name' : 'give a name'
                                }
                                variant="settings"
                                required={!isCompact}
                            ></FormField>
                        }
                    />
                </>

                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>URL</SettingLabel>}
                    rightSide={
                        <FormField
                            as={BaseUrlInput}
                            name="base_url"
                            formRef={formRef}
                            placeholder="customize your URL"
                            variant="settings"
                            required
                            registerOptions={{ validate: { asyncValidate: checkBaseUrl } }}
                            flexShrink={1}
                            flexGrow={1}
                            domain={getDomain()}
                        />
                    }
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Custom domain</SettingLabel>}
                    rightSide={
                        isCustomDomainLocked ? (
                            <Text variant="appSettingsBody" ml={2}>
                                Available with a Pro plan or above
                            </Text>
                        ) : (
                            <FormField
                                as={Input}
                                name="custom_base_url"
                                formRef={formRef}
                                placeholder="enter your custom domain"
                                variant="settings"
                                required={false}
                                registerOptions={{
                                    validate: { asyncValidate: checkCustomBaseUrl },
                                }}
                            ></FormField>
                        )
                    }
                />
                {isCompact && !selectedStack.options?.external_access?.name && (
                    <Alert
                        status="info"
                        description={
                            <>
                                No external app name has been set. By default, the general app name
                                will be used: {selectedStack?.name}
                            </>
                        }
                        m={0}
                        mt={2}
                        fontSize="sm"
                        w="100%"
                    />
                )}
            </FormFrame>
            {children}
        </Form>
    )
}
