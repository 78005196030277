import { useCallback, useMemo } from 'react'

import useSlidingPane from './useSlidingPane'

export const useAppSettings = (): { open: (paneState?: any) => void; close: () => void } => {
    const { showAppSettings: openAppSettingsPane, close: closeSlidingPane } = useSlidingPane()

    const open = useCallback(
        (paneState: any = { page: { name: 'data_sources' } }) => {
            openAppSettingsPane(paneState)
            return
        },
        [openAppSettingsPane]
    )

    const close = useCallback(() => {
        closeSlidingPane()
        return
    }, [closeSlidingPane])

    return useMemo(() => ({ open, close }), [open, close])
}
