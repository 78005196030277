import React from 'react'

import { isEmpty } from 'lodash'

import { Box } from 'v2/ui'

import { DataSourcesDropdown } from '../DataSourcesDropdown'

import ManageTablesDropdown from './ManageTablesDropdown'

type ManageTableDataSourcesDropdownProps = {
    selectedDataSource?: string
    handleChangeDataSource: (dataSource: any) => void
    onEditDataSource: (dataConnection: DataConnectionDto) => void
    allDataSourceTables: ObjectDto[]
    selectedDataConnection?: DataConnectionDto
    selectedObjectId?: string
    setSelectedObjectId: (value?: string) => void
    onTableDeactivated: (deactivatedTable: ObjectDto) => void
}

export const ManageTableDataSourcesDropdown: React.VFC<ManageTableDataSourcesDropdownProps> = ({
    selectedDataSource,
    handleChangeDataSource,
    onEditDataSource,
    allDataSourceTables,
    selectedDataConnection,
    setSelectedObjectId,
    onTableDeactivated,
    selectedObjectId,
}) => {
    return (
        <>
            <Box ml={3} flexShrink={0}>
                <DataSourcesDropdown
                    selectedDataSource={selectedDataSource}
                    setSelectedDataSource={handleChangeDataSource}
                    onEditDataSource={onEditDataSource}
                />
            </Box>
            {!isEmpty(allDataSourceTables) && selectedDataConnection?.type !== 'stacker_users' && (
                <Box ml={2} mr={3} flexShrink={0}>
                    <ManageTablesDropdown
                        tables={allDataSourceTables}
                        selectedTableId={selectedObjectId}
                        onSelectTable={(table) => setSelectedObjectId(table._sid)}
                        onTableDeactivated={onTableDeactivated}
                        dataConnection={selectedDataConnection}
                    />
                </Box>
            )}
        </>
    )
}
