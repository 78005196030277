import { GridCell, GridCellKind } from '@glideapps/glide-data-grid'
import removeMarkdown from 'remove-markdown'

import { formatValue } from 'data/utils/utils'
import { isReadOnlyField } from 'features/admin/data-connector/utils'
import { formatDate } from 'utils/dateUtils'
import { isAirtableRichTextField } from 'utils/isRichTextField'
import { ensureArray } from 'utils/utils'

import { ATTACHMENTS_CELL, AttachmentsCellData } from './cells/AttachmentsCell'
import { DATE_CELL, DateCellData } from './cells/DateCell'
import { DROPDOWN_CELL, DropdownCellData } from './cells/DropdownCell'
import { MAGIC_USER_FIELD_CELL } from './cells/MagicUserFieldCell'
import { PERCENTAGE_CELL, PercentageCellData } from './cells/PercentageCell'
import { RECORD_LINK_CELL, RecordLinkCellData } from './cells/RecordLinkCell'
import { RICHTEXT_CELL, RichTextCellData } from './cells/RichTextCell'
import { FailedCommit, FailedCommitReasons } from './hooks/useRecordEditManager'
import type { CellData, DataGridCellProvider } from './types'

export function getCell(
    record: any,
    field: FieldDto,
    provider: DataGridCellProvider,
    readonly?: boolean,
    allowOverlay: boolean = true,
    bypassPreviewAndImpersonation?: boolean
): GridCell {
    const recordValue: string[] | string | UserRefDto | undefined = record?.[field.api_name]
    const allowEdit = !readonly && !isReadOnlyField(field)
    switch (field.type) {
        case 'lookup':
        case 'multi_lookup':
            let copyData = ''
            if (recordValue) {
                const values = Array.isArray(recordValue) ? [...recordValue] : [recordValue]
                copyData = values
                    .map((value) => {
                        const linkedRecord = provider.getRecord(
                            field.options?.lookup_target,
                            value as string
                        )
                        return linkedRecord?._primary || ''
                    })
                    .join(',')
            }

            const recordLinkData: RecordLinkCellData = {
                kind: RECORD_LINK_CELL,
                value: (recordValue as string | string[] | undefined) ?? null,
                provider,
                targetObjectId: field.options?.lookup_target,
                field,
                bypassPreviewAndImpersonation,
                contextRecord: record,
            }
            return {
                kind: GridCellKind.Custom,
                copyData,
                data: recordLinkData,
                allowOverlay: allowEdit && allowOverlay,
            }
        case 'image':
        case 'multi_file':
            const attachmentsData: AttachmentsCellData = {
                kind: ATTACHMENTS_CELL,
                value: ensureArray(record?.[field.api_name]),
                provider,
                field,
            }

            return {
                kind: GridCellKind.Custom,
                data: attachmentsData,
                copyData: ensureArray(record?.[field.api_name])
                    .map(({ url }) => url)
                    .join(' '),
                allowOverlay: allowEdit && allowOverlay,
            }
        case 'checkbox':
            return {
                kind: GridCellKind.Boolean,
                data: Boolean(record?.[field.api_name]),
                allowOverlay: false,
                readonly: !allowEdit,
            }
        case 'currency':
        case 'number':
            return {
                kind: GridCellKind.Number,
                data: record?.[field.api_name] ?? null,
                displayData: formatValue(field, record?.[field.api_name])?.toString() || '',
                allowOverlay: allowOverlay,
                readonly: !allowEdit,
            }
        case 'percentage':
            let value: number | undefined = undefined
            try {
                value = parseFloat(recordValue as string)
            } catch (error) {}

            const percentageData: PercentageCellData = {
                kind: PERCENTAGE_CELL,
                value,
                displayValue: parseFloat(((value as number) * 100).toFixed(5)) || undefined,
                provider,
                field,
            }

            return {
                kind: GridCellKind.Custom,
                copyData: recordValue?.toString() || '',
                data: percentageData,
                allowOverlay: allowOverlay,
            }
        case 'dropdown':
        case 'multi_select':
            const dropdownData: DropdownCellData = {
                kind: DROPDOWN_CELL,
                value: (recordValue as string | string[] | undefined) ?? null,
                provider,
                options: field.options?.options ?? [],
                field,
            }

            return {
                kind: GridCellKind.Custom,
                copyData: recordValue?.toString() || '',
                data: dropdownData,
                allowOverlay: allowEdit && allowOverlay,
            }
        case 'date':
        case 'datetime':
            const timezone = field.options?.timezone
            const showTime = field.type === 'datetime'
            const { dateValue } = formatDate(recordValue, timezone, showTime)
            const dateString = dateValue?.format(showTime ? 'L LT' : 'L') || ''

            const dateData: DateCellData = {
                kind: DATE_CELL,
                displayValue: dateString,
                value: recordValue?.toString() ?? null,
                showTime,
                provider,
                field,
            }

            return {
                kind: GridCellKind.Custom,
                copyData: dateString,
                data: dateData,
                allowOverlay: allowEdit && allowOverlay,
            }

        case 'user_ref':
            return {
                allowOverlay: false,
                copyData: (recordValue as UserRefDto)?.name ?? '',
                data: {
                    kind: MAGIC_USER_FIELD_CELL,
                    value: (recordValue as UserRefDto)?.name ?? '',
                    field,
                },
                kind: GridCellKind.Custom,
                readonly: true,
            }

        case 'string':
        case 'long_text':
            let rawData = recordValue?.toString() || ''
            let data = rawData
            const isRichText =
                field.options?.render_variant === 'richText' || isAirtableRichTextField(field)

            if (field.type === 'long_text' || isRichText) {
                data = removeMarkdown(data).split(/\r?\n/).join(' ')
            }

            if (isRichText) {
                const richTextData: RichTextCellData = {
                    kind: RICHTEXT_CELL,
                    value: data,
                    rawValue: rawData,
                    provider,
                    field,
                }

                return {
                    kind: GridCellKind.Custom,
                    copyData: data,
                    data: richTextData,
                    allowOverlay: allowOverlay,
                    readonly: !allowEdit,
                }
            }

            return {
                kind: GridCellKind.Text,
                data: recordValue?.toString() || '',
                displayData: data,
                allowOverlay: allowOverlay,
                readonly: !allowEdit,
            }
        default:
            return {
                kind: GridCellKind.Text,
                data: record?.[field.api_name]?.toString() || '',
                displayData: formatValue(field, record?.[field.api_name])?.toString() || '',
                allowOverlay: allowOverlay,
                readonly: !allowEdit,
            }
    }
}

export function getCellValue(cell: GridCell): any {
    switch (cell.kind) {
        case GridCellKind.Boolean:
        case GridCellKind.Number:
        case GridCellKind.Text:
            return cell.data
        case GridCellKind.Custom:
            const cellData = cell.data as CellData
            return cellData.value
        default:
            throw new Error(`Unknown cell kind: ${cell.kind}`)
    }
}

export function isCellEditable(cell: GridCell): boolean {
    switch (cell.kind) {
        case GridCellKind.Boolean:
            return !cell.readonly
        case GridCellKind.Number:
        case GridCellKind.Text:
            return !cell.readonly
        case GridCellKind.Custom:
            const cellData = cell.data as CellData
            return !cellData.readonly
        default:
            return false
    }
}

export function getErrorMessage(failedRecords: { [keyof: string]: FailedCommit }): string {
    const reasonsCounter: { [key in FailedCommitReasons]: number } = Object.values(
        failedRecords
    ).reduce(
        ({ isDelete, isUpdate, isCreate }, failedCommit) => ({
            isDelete: isDelete + (failedCommit.isDelete ? 1 : 0),
            isUpdate: isUpdate + (failedCommit.isUpdate ? 1 : 0),
            isCreate: isCreate + (failedCommit.isCreate ? 1 : 0),
        }),
        { isDelete: 0, isUpdate: 0, isCreate: 0 }
    )

    if (reasonsCounter['isCreate'] > 0) {
        return "Sorry, your records couldn't be created."
    }
    if (reasonsCounter['isUpdate'] > 0) {
        return "Sorry, some of your changes couldn't be saved."
    }
    if (reasonsCounter['isDelete'] > 0) {
        return "Sorry, some of your records couldn't be deleted."
    }
    return "Sorry, some of your changes couldn't be saved."
}
