import { useCustomIntercom } from 'utils/intercom'

// Loads custom integrations
const CustomIntegrations = () => {
    useCustomIntercom()

    return null
}

export default CustomIntegrations
