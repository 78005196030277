import { useMemo } from 'react'

import Bowser from 'bowser'
const useCtrlKey = () => {
    return useMemo(() => {
        const browser = Bowser.parse(window.navigator.userAgent)
        return browser.os.name === 'macOS' ? '⌘' : 'ctrl'
    }, [])
}
export default useCtrlKey
