// @ts-strict-ignore
export const DATA_KB_SCALE = 1000
export const DATA_MB_SCALE = 1000000

export const convertFromBytesToKB = (count) => {
    const num = count / DATA_KB_SCALE
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export const convertFromBytesToMB = (count) => {
    const num = count / DATA_MB_SCALE
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export const prettifyCsvFileName = (name: string) => {
    const csvRegex = /\.csv$/i
    let result = name.replace(csvRegex, '')
    if (result.length > 0) {
        result = result[0].toUpperCase() + result.slice(1)
    }
    const delimiterRegex = /\.|\_|-/g
    result = result.replace(delimiterRegex, ' ')
    return result
}
