/**
 * Move an element within an array and shift all others
 * @param data Array of items
 * @param element Element to move
 * @param index Destination position
 * @returns
 */
export const moveItem = (data: string[], element: string, index: number) => {
    const from = data.findIndex((x) => x === element)
    const elm = data.splice(from, 1)[0]
    data.splice(index, 0, elm)
    return data
}
