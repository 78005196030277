import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useOutsideClick } from '@chakra-ui/react'

import { formatDateAsString } from 'utils/dateUtils'

import { InlineFilter } from '../InlineFilter'
import type { InlineFilterMultiValueOption } from '../types'
import { useInlineFilter } from '../useInlineFilter'
import { useUserFieldOptions } from '../useUserFieldOptions'
import { getInlineFilterValueLabels } from '../valueUtils'

import { DateFilterContextProvider, useDateFilter } from './DateFilterContext'
import type { InlineFilterComponent } from './types'
import { getDateInlineFilterLabelsForValue, isRelativeDateFilterOperation } from './utils'

const RELATIVE_DATE_OPTIONS: InlineFilterMultiValueOption[] = [
    {
        label: 'specific date',
        value: '_customDate',
    },
    {
        label: 'today',
        value: '_today',
    },
    {
        label: 'yesterday',
        value: '_yesterday',
    },
    {
        label: 'tomorrow',
        value: '_tomorrow',
    },
    {
        label: 'this week',
        value: '_this_week',
    },
    {
        label: 'last week',
        value: '_last_week',
    },
    {
        label: 'next week',
        value: '_next_week',
    },
    {
        label: 'this quarter',
        value: '_this_quarter',
    },
    {
        label: 'last quarter',
        value: '_last_quarter',
    },
    {
        label: 'next quarter',
        value: '_next_quarter',
    },
    {
        label: 'this month',
        value: '_this_month',
    },
    {
        label: 'next month',
        value: '_next_month',
    },
    {
        label: 'last month',
        value: '_last_month',
    },
    {
        label: 'this year',
        value: '_this_year',
    },
    {
        label: 'next year',
        value: '_next_year',
    },
    {
        label: 'last year',
        value: '_last_year',
    },
]

type DateInlineFilterInternalProps = {}

const DateInlineFilterInternal: InlineFilterComponent<DateInlineFilterInternalProps> = ({
    field,
    columns,
}) => {
    const menuRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLElement>(null)

    const [isOpen, setIsOpen] = useState(false)

    const { filter, operation, value, onValueChange, onClear, ...inlineFilterProps } =
        useInlineFilter({ field })
    const userFieldOptions = useUserFieldOptions(field)
    const { specificDate, setSpecificDate } = useDateFilter()
    const showTime = field?.type === 'datetime'

    const options = useMemo(() => {
        if (operation === 'isCurrentUserField' || operation === 'isNotCurrentUserField') {
            return userFieldOptions
        }
        if (operation === 'isInBetween') {
            return [
                {
                    label: 'specific dates',
                    value: '_inBetweenDates',
                },
            ]
        }

        if (isRelativeDateFilterOperation(operation)) return RELATIVE_DATE_OPTIONS

        return []
    }, [operation, userFieldOptions])

    const newValue = useMemo(() => {
        if (
            isRelativeDateFilterOperation(operation) &&
            value &&
            typeof value === 'string' &&
            !value.startsWith('_')
        ) {
            return '_customDate'
        }

        return value
    }, [value, operation])

    const valueLabels = useMemo(() => {
        const labels = getDateInlineFilterLabelsForValue(newValue, operation)
        if (labels) return labels

        if (newValue === '_customDate') {
            const formattedDate = formatDateAsString(specificDate, field, showTime)
            if (formattedDate) return [formattedDate]
        }

        return getInlineFilterValueLabels(filter, options)
    }, [newValue, operation, filter, options, specificDate, field, showTime])

    const handleValueChange = useCallback(
        (value: FilterOptions['value']) => {
            if (value === '_customDate') {
                if (specificDate) onValueChange(specificDate)
                return
            }

            onValueChange(value)
        },
        [onValueChange, specificDate]
    )

    const handeClear = useCallback(() => {
        setSpecificDate(undefined)
        onClear()
    }, [onClear, setSpecificDate])

    useOutsideClick({
        enabled: isOpen,
        ref: menuRef,
        handler: (e) => {
            const isOutsideButton = !buttonRef.current?.contains(e.target as HTMLElement)

            const isOutsideDatePicker = !document
                .querySelector('.react-datepicker')
                ?.contains(e.target as HTMLElement)

            if (isOutsideDatePicker && isOutsideButton) setIsOpen(false)
        },
    })

    useEffect(() => {
        onValueChange(specificDate)
        // If we add `onValueChange` here, this triggers an infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specificDate])

    useEffect(() => {
        if (value && newValue === '_customDate' && specificDate === undefined) {
            setSpecificDate(value as string)
        }
    }, [newValue, setSpecificDate, specificDate, value])

    return (
        <InlineFilter
            ref={menuRef}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            field={field}
            operation={operation}
            value={newValue}
            columns={columns}
            options={options}
            onValueChange={handleValueChange}
            onClear={handeClear}
            closeOnBlur={false}
            buttonProps={{
                ref: buttonRef,
                onClick: () => setIsOpen(true),
            }}
            {...inlineFilterProps}
            valueLabels={valueLabels}
        />
    )
}

export const DateInlineFilter: typeof DateInlineFilterInternal = (props) => {
    return (
        <DateFilterContextProvider>
            <DateInlineFilterInternal {...props} />
        </DateFilterContextProvider>
    )
}
