/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFilters, usePagination, useTable } from 'react-table'

import { Spinner } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'
import { default as styledComponent } from '@emotion/styled'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from 'legacy/v1/ui/components/Card'
import TopLabel from 'legacy/v1/ui/components/TopLabel'
import {
    Button,
    Container,
    Dropdown,
    Heading,
    Icon,
    Input,
    Label,
    Span,
    Text,
} from 'legacy/v1/ui/index'

import { DefaultColumnFilter, fuzzyTextFilterFn } from './Filters'
import Pagination from './Pagination'

const debouncer = debounce((target) => target(), 300)
function List({
    columns,
    data,
    display,
    rowLink,
    topFilters,
    buttons,
    title,
    onSearchValueChanged,
    onFilterChanged,
    hideLastPageInNav,
    isLoading,
}) {
    const [visibleData, setData] = useState([...data])
    const memoColumns = React.useMemo(() => columns, [columns])
    const memoData = React.useMemo(() => visibleData, [visibleData])

    const [searchValue, setSearchValue] = useState('')
    const [searchVisible, setsearchVisible] = useState('')
    const searchInput = useRef(null)

    let placeHolderText = []

    //Allow all search on these field types
    let searchCols = memoColumns.reduce(function (filtered, column) {
        if (['string', 'long_text', 'copy', 'dropdown'].includes(column.type)) {
            if (column.id) {
                filtered.push(column.accessor)
                placeHolderText.push(column.Header)
            }
        }
        return filtered
    }, [])

    const searchAll = () => {
        if (!searchValue) {
            setData(data)
        } else {
            var result = matchSorter(data, searchValue, {
                keys: searchCols,
                threshold: matchSorter.rankings.CONTAINS,
            })

            setData(result)
        }
        if (onSearchValueChanged) onSearchValueChanged(searchValue)
    }

    const SearchIcon = (props) => {
        if (searchVisible)
            return <StyledIcon {...props} icon="times" onClick={toggleSearch} isButton />

        return <StyledIcon {...props} icon="search" onClick={toggleSearch} isButton />
    }

    const tableSearch = () => {
        if (searchCols.length > 0) {
            return (
                <>
                    <div
                        css={css`
                            text-align: right;
                            margin: 20px 0;
                        `}
                    >
                        <SearchIcon
                            tooltipId="searchToolTip"
                            tooltip={`${searchVisible ? 'Hide' : 'Show'} search`}
                        />
                    </div>
                    {searchVisible && (
                        <div
                            css={css`
                                padding: 20px;
                                background: #ffffff;
                                text-align: left;
                                flex-basis: 100%;
                                flex-shrink: 0;
                                order: 999;
                                margin-top: 5px;
                            `}
                        >
                            <div
                                css={css`
                                    position: relative;
                                `}
                            >
                                <Input
                                    ref={searchInput}
                                    style={{ width: '100%', fontSize: '14px' }}
                                    placeholder={`Search`}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                {searchValue && (
                                    <ClearIconWrapper>
                                        <ClearIcon
                                            tooltipId="clearSeachTooltip"
                                            tooltip="Clear search"
                                            icon="times"
                                            isButton
                                            onClick={() => {
                                                setSearchValue('')
                                                searchInput.current.focus()
                                            }}
                                        />
                                    </ClearIconWrapper>
                                )}
                            </div>
                            <Text
                                size="fontS"
                                _css={css`
                                    padding: 5px;
                                `}
                            >
                                <span>You can search across the following fields: </span>
                                <span>{placeHolderText.join(', ')}</span>
                            </Text>
                        </div>
                    )}
                </>
            )
        }
        return null
    }

    const openSearch = () => {
        setsearchVisible(true)
    }

    const toggleSearch = () => {
        if (!searchVisible) {
            openSearch()
            return
        }
        setsearchVisible(false)
        setSearchValue('')
    }

    const onCtrlF = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
            e.preventDefault()
            openSearch()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onCtrlF)
        return () => window.removeEventListener('keydown', onCtrlF)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // If the search value is changing, use debounce
        // so we don't process on each keystroke
        if (searchValue) {
            debouncer(searchAll)
        } else {
            searchAll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, data])

    useEffect(() => {
        if (searchInput.current) {
            searchInput.current.focus()
        }
    }, [searchVisible])

    const StyledIcon = styledComponent(Icon)`
        color: #aaa;
        cursor: pointer;
        font-size: 20px;
        height: 20px;
        width: 20px;
    `

    const ClearIcon = styledComponent(Icon)`
    color: #aaa;
    cursor: pointer;
    height: 16px;
    width: 16px;
    `

    const ClearIconWrapper = styledComponent('div')`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
    `

    const noResults = () => {
        if (searchValue && memoData.length < 1 && data.length > 0) {
            return (
                <Text style={{ textAlign: 'center' }}>
                    <span>There are no search results. </span>
                    <Button size="small" padding="small" onClick={() => setSearchValue('')}>
                        Clear search?
                    </Button>
                </Text>
            )
        }

        return null
    }

    const noResultsLoading = () => {
        if (searchValue && memoData.length < 1 && data.length > 0) {
            return (
                <Text style={{ textAlign: 'center' }}>
                    <Spinner verticalAlign="middle" mr={2} />
                    <span>Checking the server for matching users.</span>
                    <Button size="small" padding="small" onClick={() => setSearchValue('')}>
                        Clear search?
                    </Button>
                </Text>
            )
        }

        return null
    }

    return (
        <>
            <Styles>
                <ListDisplay
                    columns={memoColumns}
                    data={memoData}
                    display={display}
                    rowLink={rowLink}
                    topFilters={topFilters}
                    searchAll={searchAll}
                    searchCols={searchCols}
                    tableSearch={tableSearch}
                    buttons={buttons}
                    title={title}
                    hideLastPageInNav={hideLastPageInNav}
                    onFilterChanged={onFilterChanged}
                    isLoading={isLoading}
                />
                {isLoading ? noResultsLoading() : noResults()}
            </Styles>
        </>
    )
}

List.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    display: PropTypes.string,
    rowLink: PropTypes.func,
}

List.defaultProps = {
    columns: [],
    data: [],
    display: 'table',
    rowLink: null,
}

// Our table component
function ListDisplay({
    columns,
    data,
    display,
    rowLink,
    topFilters,
    tableSearch,
    buttons,
    title,
    hideLastPageInNav,
    onFilterChanged,
    isLoading,
}) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        pageOptions,
        page,
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        setFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageSize: display === 'table' ? 10 : 12 },
            getResetPageDeps: ({ state: { filters } }) => [filters],
            disablePageResetOnDataChange: true,
        },
        useFilters,
        usePagination
    )

    const pagination = (
        <Pagination
            display={display}
            pageSize={pageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            hideLast={hideLastPageInNav}
            isLoading={isLoading}
        />
    )

    const pageRows = page || []

    const lastHeaderGroup = headerGroups.slice(-1)[0]
    const mainHeaders = lastHeaderGroup ? lastHeaderGroup.headers : []

    const clearAllFilters = () => {
        mainHeaders.forEach((column) => {
            column.setFilter(null)
        })
        setSelectedFilter(null)
    }

    const [selectedFilter, setSelectedFilter] = useState(null)

    const VerticalDivider = ({ leftPadding = 0, rightPadding = 0, hideBorder }) => (
        <Span
            css={css`
                width: 1px;
                background: ${hideBorder ? 'transparent' : '#c7ced6'};
                height: 20px;
                margin-left: ${leftPadding};
                margin-right: ${rightPadding};
            `}
        ></Span>
    )

    const Title = () => {
        return (
            <div
                css={css`
                    margin-right: auto;
                `}
            >
                {title}
            </div>
        )
    }

    const getTopFilters = () => {
        const options = []

        const topFilterSelect = () => {
            topFilters.forEach((item) => {
                options.push({ value: item.label })
            })

            return (
                <Dropdown
                    placeholder="Select Filter"
                    fontSize="0.9em"
                    color="black"
                    width="200px"
                    padding="4px"
                    value={selectedFilter}
                    options={topFilters}
                    returnObject={true}
                    onChange={(selected) => {
                        clearAllFilters()

                        if (selected == null) {
                            if (onFilterChanged) onFilterChanged(null)
                            return
                        }

                        const { value, id } = selected

                        if (id) {
                            setSelectedFilter(selected)
                            setFilter(id, value)
                            if (onFilterChanged) onFilterChanged(id, value)
                        }
                    }}
                    isSearchable={false}
                />
            )
        }

        if (topFilters && topFilters.length > 0) {
            return (
                <div
                    css={css`
                        margin-bottom: 16px;
                        margin-top: 20px;
                    `}
                >
                    <Container
                        css={css`
                            align-items: center;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                        `}
                    >
                        <Title />
                        {tableSearch()}
                        <VerticalDivider rightPadding="10px" />
                        {topFilterSelect()}
                        {buttons && (
                            <>
                                <VerticalDivider rightPadding="10px" leftPadding="10px" />
                                {buttons}
                            </>
                        )}
                    </Container>
                </div>
            )
        }

        return (
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 16px;
                    justify-content: flex-end;
                    margin-top: 20px;
                `}
            >
                <Title />
                {tableSearch()}
                {buttons && (
                    <>
                        <VerticalDivider rightPadding="10px" />
                        {buttons}
                    </>
                )}
            </div>
        )
    }

    //Check if there is a cover image column
    const coverImageEnabled = React.useMemo(() => {
        return find(columns, { id: 'stackCoverImageSetting' })
    }, [columns])

    //Check if there is a cover image column
    const shouldFitCoverImage = React.useMemo(() => {
        const cover = find(columns, { id: 'stackCoverImageSetting' })
        return cover && cover.fitImage
    }, [columns])

    //Render the card cover image if enabled
    const CardCoverImage = ({ row, asIcon, fitImage }) => {
        if (coverImageEnabled) {
            const coverImage = get(row, 'values.stackCoverImageSetting')
            let style = {}

            if (coverImage) {
                style.backgroundImage = `url(${coverImage})`
            }

            if (asIcon) {
                return <CoverImageIcon data-cover-image="icon" style={style} />
            }

            return <CoverImage data-cover-image="true" fitImage={fitImage} style={style} />
        }
        return null
    }

    const CardView = (
        <CardWrapper data-display-type="card" display={display} key={1}>
            <Grid {...getTableProps()}>
                {pageRows.map((row, i) => {
                    return (
                        prepareRow(row) || (
                            <LinkWrapper
                                rowLink={rowLink}
                                row={row}
                                key={`${i}-row`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    paddingBottom: 20,
                                }}
                            >
                                <Card
                                    {...row.getRowProps()}
                                    css={css`
                                        padding: 0;
                                        width: 100%;
                                        height: 100%;
                                        @media (max-width: 420px) {
                                            margin-bottom: 20px;
                                            width: 100%;
                                        }
                                    `}
                                >
                                    <CardCoverImage row={row} fitImage={shouldFitCoverImage} />
                                    <div
                                        css={css`
                                            padding: 16px;
                                        `}
                                    >
                                        {/* Style the first cell as a heading */}
                                        {row.cells.slice(0, 1).map((cell, j) => {
                                            return (
                                                <div {...cell.getCellProps()} key={`${j}-cell`}>
                                                    <Heading
                                                        style={{ fontSize: '20px' }}
                                                        margin="none"
                                                        padding="none"
                                                    >
                                                        {cell.render('Cell')}
                                                    </Heading>
                                                </div>
                                            )
                                        })}

                                        {/* Style the rest as attributes */}
                                        {row.cells.slice(1, 100).map((cell, j) => {
                                            return (
                                                <div key={j} {...cell.getCellProps()}>
                                                    <TopLabel
                                                        style={{
                                                            margin: `7px 0px ${
                                                                cell.column.type == 'multi_file'
                                                                    ? '0px'
                                                                    : '-7px'
                                                            }`,
                                                        }}
                                                    >
                                                        {(mainHeaders[j + 1] &&
                                                            mainHeaders[j + 1].render('Header')) ||
                                                            null}
                                                    </TopLabel>
                                                    {cell.render('Cell')}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Card>
                            </LinkWrapper>
                        )
                    )
                })}
            </Grid>
            {pagination}
        </CardWrapper>
    )

    // if (headerGroups[0].headers.length) debugger
    const TableView = (
        <TableWrapper data-display-type="list" display={display} key={2}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(
                        (headerGroup, i) =>
                            'getHeaderGroupProps' in headerGroup && (
                                <tr
                                    key={`${i}-header-groups`}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column, j) => (
                                        <th
                                            key={`${j}-header-group`}
                                            {...column.getHeaderProps()}
                                            style={column.headerStyles}
                                        >
                                            <Label
                                                style={{
                                                    color: '#687281',
                                                    textTransform: 'uppercase',
                                                    fontWeight: '600',
                                                    fontSize: '0.8em',
                                                    margin: 0,
                                                    padding: 0,
                                                    letterSpacing: '0.3px',
                                                }}
                                                // style={{  fontWeight: 600 }}
                                            >
                                                {column.render('Header')}
                                            </Label>
                                            {/* Render the columns filter UI */}
                                            {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                                        </th>
                                    ))}
                                </tr>
                            )
                    )}
                </thead>
                <tbody>
                    {pageRows.map(
                        (row, i) =>
                            prepareRow(row) || (
                                <LinkWrapper
                                    rowLink={rowLink}
                                    row={row}
                                    css={css`
                                        display: table-row;
                                        vertical-align: middle;
                                        border-radius: 5px;
                                        padding: 10px;
                                    `}
                                    {...row.getRowProps()}
                                    key={i}
                                    AltWrapper={({ children }) => <tr>{children}</tr>}
                                >
                                    <>
                                        {row.cells.map((cell, j) => {
                                            return (
                                                <td {...cell.getCellProps()} key={j}>
                                                    <Container>
                                                        {
                                                            //Add in the cover image icon to the first column
                                                            j == 0 && (
                                                                <CardCoverImage asIcon row={row} />
                                                            )
                                                        }
                                                        {cell.render('Cell')}
                                                    </Container>
                                                </td>
                                            )
                                        })}
                                    </>
                                </LinkWrapper>
                            )
                    )}
                </tbody>
            </table>
            {pagination}
        </TableWrapper>
    )
    return [getTopFilters(), TableView, CardView] // N.B. Card view is visible in mobile layouts
}

export default List

const LinkWrapper = ({ children, rowLink, row, AltWrapper, ...rest }) =>
    rowLink ? (
        <Link to={rowLink ? rowLink(row) : null} {...rest}>
            {children}
        </Link>
    ) : (
        <AltWrapper>{children}</AltWrapper>
    )

LinkWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    row: PropTypes.object.isRequired,
    rowLink: PropTypes.any,
    AltWrapper: PropTypes.elementType,
}
LinkWrapper.defaultProps = {
    rowLink: null,
    AltWrapper: ({ children }) => children,
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-bottom: 35px;
    @media (max-width: 420px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const Styles = styled.div`
    table {
        width: 100%;
        border-spacing: 0px 10px;
        padding-bottom: 50px;
        tbody td {
            background: white;
        }
        th {
            text-align: left;
            padding: 0px 15px;
        }
        td {
            text-align: left;
            padding: 15px;
        }

        :first-child td:first-child {
            border-top-left-radius: 3px;
        }
        :first-child td:last-child {
            border-top-right-radius: 3px;
        }
        :last-child td:first-child {
            border-bottom-left-radius: 3px;
        }
        :last-child td:last-child {
            border-bottom-right-radius: 3px;
        }
    }
`

// We use these two wrappers to switch to card view if in mobile mode anyway
const TableWrapper = styled.div`
    display: ${(props) => (props.display === 'list' ? 'inline-block' : 'none')} }
    max-width: 100%;
    overflow: auto;
    width: 100%;

    @media (max-width: 420px) {
        display: none;
    }
`
const CardWrapper = styled.div`
    display: ${(props) => (props.display === 'card' ? 'inline-block' : 'none')} }
    max-width: 100%;
    width: 100%;

    @media (max-width: 420px) {
        display: initial;
    }
`

const CoverImage = styled.div`
    height: 200px;
    background-color: ${(props) => (props.fitImage ? '#FFFFFF' : '#c7ced6')};
    background-size: ${(props) => (props.fitImage ? 'contain' : 'cover')};
    background-repeat: no-repeat;
    background-position: center center;
`

const CoverImageIcon = styled.div`
    height: 35px;
    width: 35px;
    border-radius: 50px;
    background-color: ${(props) => (props.fitImage ? '#FFFFFF' : '#c7ced6')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 10px;
    flex-shrink: 0;
`
