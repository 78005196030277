import React, { useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import isEmpty from 'lodash/isEmpty'

import { UserApi } from 'data/api/userApi'
import validateEmail from 'features/utils/validateEmail'
import analytics from 'utils/analytics'

import { Checkbox, Collapse } from 'v2/ui'

import { useAppContext } from '../../../app/AppContext'
import { getSignupParams, SignupApi } from '../utils'

import { Button, Input, Text } from './AuthUIElements'

const RegisterForm = ({
    onSuccess,
    onRejected,
    email = null,
    name = null,
    invitation = null,
    forStack = null,
    openSignup = false,
    emailOptIn,
    setEmailOptIn,
}) => {
    const { workspaceAccount } = useAppContext()
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        name: name,
        email: email || invitation?.email,
        errors: {},
    })
    const [error, setError] = useState(false)
    const [openSignupError, setOpenSignupError] = useState(false)

    const handleOpenSignup = (e) => {
        setIsLoading(true)
        e.preventDefault()
        UserApi.handleOpenSignUp(invitation?.user?.email, forStack, invitation?.token)
            .then((response) => {
                if (!response.success || response.status === 'registration_started') {
                    setOpenSignupError(true)
                    return
                }
                if (response.status == 'registration_confirmed') {
                    analytics.track('open signup registration completed', {
                        workspace_id: workspaceAccount._sid,
                        user_id: invitation?.user?._sid,
                        event_description: 'Open signup registration was completed',
                        is_new_user: invitation?.user?.is_new_user,
                        authentication_method: 'email',
                    })
                } else if (response.status == 'open_signup_already_registered') {
                    analytics.track('open signup registration already completed', {
                        workspace_id: workspaceAccount._sid,
                        user_id: invitation?.user?._sid,
                        event_description: 'Open signup registration already completed',
                        authentication_method: 'email',
                    })
                }
                handleRegister(e)
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setOpenSignupError(true)
                setIsLoading(false)
                return
            })
    }

    const handleRegister = (e) => {
        e.preventDefault()
        const errors = {}
        if (!state.name && !invitation) {
            errors.name = 'Please enter your full name'
        }

        if (!invitation && (!state.email || !validateEmail(state.email))) {
            errors.email = 'Please enter a valid email address'
        }
        if (!state.password) {
            errors.password = 'Please enter a password'
        } else if (state.password.length < 8) {
            errors.password = 'Password must be at least 8 characters'
        }

        setState((state) => ({ ...state, errors }))
        if (!isEmpty(errors)) {
            return
        }
        setIsLoading(true)

        analytics.track('user registration submitted', {
            event_category: 'user',
            event_description: 'New user registration was submitted',
            referred_user_id: invitation?.sender?._sid,
            authentication_method: 'email',
        })

        const signup_params = getSignupParams()
        const data = {
            ...state,
            options: { signup_params },
            portal: true,
            invitation_token: invitation?.token,
        }
        setError(false)
        SignupApi.signup(data)
            .then((response) => {
                const token = response.api_token
                if (!token) {
                    response
                        .json()
                        .then((data) => {
                            analytics.track('user registration failed', {
                                event_category: 'user',
                                event_description: 'New user registration failed',
                                error: data.error,
                                authentication_method: 'email',
                            })
                            onRejected(data, state.email)
                        })
                        .catch((ex) => {
                            Sentry.captureException(ex)
                        })

                    return
                }

                onSuccess(response)
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e) => {
        const field = e.target.id
        const value = e.target.value
        setState((state) => ({ ...state, [field]: value }))
    }

    // If in the invitation flow, this could either be the workspace invite, or the open signup stack invite (guest)
    // otherwise, we are on the main studio registration
    const isGuest = invitation ? invitation.user.membership_role == 'guest' : false

    return (
        <form
            onSubmit={openSignup ? handleOpenSignup : handleRegister}
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            {!invitation && (
                <>
                    <StyledLabel htmlFor="name">Full name</StyledLabel>
                    <InputElement
                        id="name"
                        state={state}
                        onChange={handleChange}
                        placeholder="enter full name"
                        aria-label="Full name"
                    />
                </>
            )}

            {!invitation && (
                <>
                    <StyledLabel htmlFor="email">Email</StyledLabel>
                    <InputElement
                        id="email"
                        type="email"
                        state={state}
                        onChange={handleChange}
                        placeholder="enter email"
                        aria-label="Email"
                    />
                </>
            )}
            <StyledLabel htmlFor="password">Password</StyledLabel>
            <InputElement
                id="password"
                type="password"
                state={state}
                onChange={handleChange}
                placeholder={invitation ? 'create password' : 'enter password'}
            />
            <Collapse isOpen={error || openSignupError}>
                <Text variant="error" mb={2}>
                    Sorry, an error occurred trying to register.
                </Text>
            </Collapse>
            {!isGuest && setEmailOptIn && (
                <Checkbox
                    isChecked={emailOptIn}
                    onChange={(e) => setEmailOptIn(e.target.checked)}
                    my={2}
                >
                    <Text fontSize="sm">I would like to receive marketing emails</Text>
                </Checkbox>
            )}
            <Button
                width="100%"
                buttonSize="sm"
                mt={1}
                mb={4}
                aria-label="Password"
                isLoading={isLoading}
                type="submit"
                isDisabled={isLoading}
            >
                Sign up
            </Button>
        </form>
    )
}

const StyledLabel = styled.label`
    margin-top: 1rem;
    padding-bottom: 0.2rem;
    font-weight: bold;
`

const InputElement = ({ id, state, onChange, ...props }) => (
    <>
        <Input
            variant="authentication"
            id={id}
            width="100%"
            mb={2}
            value={state[id]}
            onChange={onChange}
            {...props}
        />
        <Collapse isOpen={!!(state.errors && state.errors[id])}>
            <Text variant="error" mt="-0.5rem" mb={3}>
                {state.errors[id]}
            </Text>
        </Collapse>
    </>
)

export default RegisterForm
