/* Code Quality: Good */

import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { signOut as supertokensSignOut } from 'supertokens-auth-react/recipe/session'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { withUser } from 'data/wrappers/WithUser'
import { AuthPageFrame } from 'features/auth/stacker-auth/AuthPageFrame'
import { Button, Text } from 'features/auth/stacker-auth/AuthUIElements'
import { useSessionContext } from 'features/supertokens/supertokens'

import { LoadingSplash } from 'v2/ui'

import { useAppContext } from './AppContext'

const LogoutRoute = ({ isUserDomain, userActions, user, ...rest }) => {
    const { workspaceAccount } = useAppContext()
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const { isAuthenticated: isAuthenticatedViaAuth0, logout: logoutAuth0 } = useAuth0()
    const supertokensContext = useSessionContext()
    const logoutLocally = userActions.logOutAllUsers
    const isAuthenticatedViaSupertokens = !!supertokensContext.userId

    useEffect(() => {
        async function doLogout() {
            // We do this logout locally twice because of weird race conditions
            // where the localStorage gets reinitialized during the await supertokensSignOut()
            if (user) {
                logoutLocally()
            }

            await supertokensSignOut()

            // Do it again now just to be sure
            if (user) {
                logoutLocally()
            }

            if (isAuthenticatedViaAuth0) {
                // even if we don't have a user, as long as we have an auth0 session we want to run through their logout.
                logoutAuth0({ returnTo: `${window.location.origin}/logout` })
            } else {
                if (isUserDomain && !workspaceAccount) {
                    window.location.assign(settings.STUDIO_URL + '/logout')
                } else {
                    setRedirectToLogin(true)
                }
            }
        }

        doLogout()
    }, [isUserDomain, workspaceAccount, isAuthenticatedViaAuth0, logoutAuth0, user, logoutLocally])

    if (redirectToLogin) {
        return (
            <Route
                user={user}
                {...rest}
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )}
            />
        )
    }

    if (user || isAuthenticatedViaAuth0 || isAuthenticatedViaSupertokens) {
        return <LoadingSplash />
    } else if (workspaceAccount) {
        return (
            <AuthPageFrame title={'Logged out'}>
                <Text mb={8}>You have logged out of your session.</Text>
                <Button href={getUrl(Urls.Login)}>Login</Button>
            </AuthPageFrame>
        )
    }

    return null
}
LogoutRoute.propTypes = {
    isUserDomain: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    userActions: PropTypes.object.isRequired, // from withUser
}

export default withUser(LogoutRoute)
