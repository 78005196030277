import React from 'react'

import publicAsset from 'utils/publicAsset'

import Flex from './Flex'
const LoadingSplash = () => {
    return (
        <Flex width="100%" height="100vh" column justify="center">
            <img
                alt=""
                src={publicAsset('/static/media/Spinner.svg')}
                style={{
                    marginLeft: 0,
                    marginRight: 8,
                    height: 64,
                }}
            />
        </Flex>
    )
}

export default LoadingSplash
