import { useContext } from 'react'

import { useAppContext } from 'app/AppContext'
import { WorkspaceOnboardingContext } from 'features/workspace/onboarding/WorkspaceOnboardingContext'

export const useAccountIdFromAppContextOrOnboardingContext = () => {
    // This hook wraps up the three methods for fetching the account id, so you
    // can access it reliably.
    // * workspaceAccount: for workspace-level actions and NUM end users
    // * selectedStack: for NUMa / V4 CA end users
    // * workspaceOnboardingContext: during the onboarding flows on the studio domain

    const { workspaceAccount, selectedStack } = useAppContext()
    const workspaceOnboardingContext = useContext(WorkspaceOnboardingContext)

    const accountId =
        workspaceAccount?._sid || selectedStack?.account_id || workspaceOnboardingContext?.accountId

    return {
        accountId: accountId,
    }
}
