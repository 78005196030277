import { routerReducer } from 'react-router-redux'

import { combineReducers } from 'redux'
import modalsReducer from 'v2/reducers/ModalsReducer'

import { CLEAR_STORE, STUDIO_USER_LOGGED_IN } from 'data/utils/constants'
import { StableStateReducer } from 'utils/useStableState'

import { actionReducer } from './reducers/actionReducer'
import { appReducer } from './reducers/appReducer'
import { authReducer } from './reducers/authReducer'
import { blockReducer } from './reducers/blockReducer'
import { compoundReducer } from './reducers/compoundReducer'
import { dataConnectionReducer } from './reducers/dataConnectionReducer'
import { emailTemplateReducer } from './reducers/emailTemplateReducer'
import { permissionGroupReducer } from './reducers/permissionGroupReducer'
import { permissionRuleReducer } from './reducers/permissionRuleReducer'
import { slidingPaneReducer } from './reducers/slidingPaneReducer'
import { templateReducer } from './reducers/templateReducer'
import { translationReducer } from './reducers/translationReducer'
import { userReducer } from './reducers/userReducer'
import { workflowReducer } from './reducers/workflowReducer'

export const LOCATION_CHANGED = 'LOCATION_CHANGED'

const stackerReducer = combineReducers({
    routing: routerReducer,
    apps: appReducer,
    blocks: blockReducer,
    templates: templateReducer,
    compounds: compoundReducer,
    actions: actionReducer,
    translations: translationReducer,
    workflows: workflowReducer,
    emailTemplates: emailTemplateReducer,
    permissionGroups: permissionGroupReducer,
    permissionRules: permissionRuleReducer,
    dataConnections: dataConnectionReducer,
    auths: authReducer,
    user: userReducer,
    modals: modalsReducer,
    stable_state: StableStateReducer,
    slidingPane: slidingPaneReducer,
})

const rootReducer = (state, action) => {
    let newState = state
    if (action.type === CLEAR_STORE) {
        // Remove everything else from the store.
        /// Preserve stabe_state. See useStableState for more information.
        // Also preserve sliding pane as otherwise it closes when switching previewed user
        newState = {
            stacks: state.stacks,
            user: state.user,
            stable_state: state.stable_state,
            slidingPane: state.slidingPane,
        }
    }
    if (action.type === STUDIO_USER_LOGGED_IN) {
        // Remove everything from the store
        /// Preserve stabe_state. See useStableState for more information.
        newState = {
            user: state.user,
            stable_state: state.stable_state,
        }
    }
    return stackerReducer(newState, action)
}
export default rootReducer
