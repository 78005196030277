import styled from '@emotion/styled'

import { Flex } from 'v2/ui'

export const StyledFlex = styled(Flex)`
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`
