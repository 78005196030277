import React from 'react'

import { Button } from 'v2/ui'
import useModalToggle from 'v2/ui/utils/useModalToggle'

import { INLINE_CREATE_VIEW_MODAL } from './constants'

type Props = {
    objectId: string
    onCreate: (id: any, message: string) => void
    ButtonText: string
    open: boolean
    onClose: () => void
    showButton: boolean
    loading: boolean
    queryParams: any
    buttonVariant: string
    buttonProps: any
}

const InlineCreate = ({
    objectId,
    onCreate,
    ButtonText = 'Add New',
    showButton = true,
    loading,
    queryParams = {},
    buttonVariant = 'sm',
    buttonProps = {},
}: Props) => {
    const { toggle: toggleModal } = useModalToggle(INLINE_CREATE_VIEW_MODAL, {
        objectId,
        queryParams,
        onCreate,
    })

    if (!showButton) {
        return null
    }

    return (
        <Button
            variant={buttonVariant}
            size="small"
            icon="add"
            onClick={toggleModal}
            isLoading={loading}
            {...buttonProps}
        >
            {ButtonText}
        </Button>
    )
}

export default InlineCreate
