// @ts-nocheck
import React from 'react'

import { Icon, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export const LinkFieldLabel = ({
    style = {},
    showLink = false,
    muted = false,
    bold = false,
    children,
}) => {
    return (
        <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
            {showLink && (
                <div style={{ marginRight: 5 }}>
                    <Icon color={V4DesignSystem.colors.highlight} size="12px" icon="lookup" />
                </div>
            )}
            {muted ? (
                <Text fontWeight={bold ? 'bold' : null} color="grey.300">
                    {children}
                </Text>
            ) : (
                <Text fontWeight={bold ? 'bold' : null}>{children}</Text>
            )}
        </div>
    )
}
