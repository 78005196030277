// @ts-strict-ignore
import { startCase } from 'lodash'

import { getUrl } from '../../app/UrlService'
import { findIcon } from '../workspace/forms/IconPicker'

export type CommandBarRecord = {
    id: string // used in url routing
    label: string // label field set in the editor
    icon?: string // reserved field
    category?: string // reserved field
    description?: string // description field set in the editor
    __breadcrumbs?: string // Shown above the label
    __extraHTML?: string // html to add to the bottom of the row
    __preserveSVG?: boolean // preserves the provided svg color, even during hover
    [propName: string]: any
}

export type Formatter = (
    ...items: Record<string, any>[]
) => CommandBarRecord | Promise<CommandBarRecord>

const genSvg = ({ icon }: any, brandColor: string) => {
    try {
        const [width, height, , , [fst, snd]] = icon.icon
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"><path class="fa-secondary" fill=${brandColor} d="${fst}" /><path class="fa-primary" fill=${brandColor} d="${snd}" /></svg>`
    } catch (e) {
        return undefined
    }
}

/**
 *
 * Formatters are callbacks to map an object to a CommandBarRecord. This
 * lets us work with all objects in the editor the same way and gives us a
 * way to define custom properties or add calculated values.
 */
const formatters: Record<string, Formatter> = {
    app: async ({ name, url_slug, options }: StackDto) => ({
        id: url_slug,
        label: name,
        icon: genSvg(findIcon(options.theme.icon), options.theme.brandColor),
    }),

    record: (record) => {
        const digest = Object.entries(record).reduce(
            (acc, [currKey, currVal]) =>
                record._permissions.may_read_fields.includes(currKey)
                    ? `${startCase(
                          currKey.split('__')[1]?.replaceAll('_', ' ')
                      )}: ${currVal} | ${acc}`
                    : acc,
            ''
        )

        return {
            id: record._sid,
            label: record._primary,
            __breadcrumbs: digest,
        }
    },

    user: ({ name, email, _sid, type }) => ({
        id: _sid,
        label: `${name} - ${email}`,
        type,
    }),

    workspace: (
        { _sid: userId, api_token },
        { _sid: accountId, name, optional_features, url }: Account
    ) => ({
        id: accountId,
        label: name,
        /**
         * The ignoreProfile param here tells us not to prompt the user to
         * fill out missing profile information when switching to a
         * workspace via the switcher.
         *
         * If the workspace is using auth0, or we don't have an api_token
         * on
         * hand, send them to login page with our current user ID in the
         * check_user param, so it will redirect them to home if the right
         * user is logged in.
         *
         *  Otherwise, we can log them in using the api_token
         * automatically.
         */
        url: `//${url}/${
            !optional_features?.auth0_enabled && api_token
                ? `auth?ignoreProfile=1&token=${api_token}`
                : `login?check_user=${userId}`
        }`,
    }),
}

export const formatLists = (navItem, views) => {
    const currentView = views.find(
        (view) => view.object_id === navItem.topLevelItem?.object_id && view.type === 'list'
    )

    const searchModifier = currentView?.options?.display === 'inbox' ? '?row_id_base=' : '/view/'

    const childItems = navItem?.children?.map((child) => ({
        id: child._sid,
        label: child.label,
        url: getUrl(child.url),
        recordNavId: navItem.topLevelItem?.object_id,
        recordNavUrl: getUrl(navItem.topLevelUrl || navItem.topLevelItem?.url),
        __breadcrumbs: navItem.topLevelItem?.label,
        searchModifier,
    }))

    return [
        {
            id: navItem.topLevelItem?.object_id,
            label: navItem.topLevelItem?.label,
            url: getUrl(navItem.topLevelUrl || navItem.topLevelItem?.url),
            recordNavId: navItem.topLevelItem?.object_id,
            recordNavUrl: getUrl(navItem.topLevelUrl || navItem.topLevelItem?.url),
            searchModifier,
        },
        ...childItems,
    ]
}

export default formatters
