import React from 'react'

import Attribute from 'v2/ui/components/Attribute/Attribute'

import { ViewCellBaseProps } from './types'

type TableCellProps = ViewCellBaseProps & {
    dereferencedRecords?: RecordDto[]
}

export const TableCell: React.FC<TableCellProps> = ({
    column,
    value: cellValue,
    cell,
    dereferencedRecords,
    row,
}) => {
    const { field, renderOptions: col, maxColumnWidth, display } = column || {}
    let value = cellValue
    if (cell && (cell.value || cell.value === 0)) {
        value = cell.value
    }
    // This will stop the whole tree re-rendering if things come back in a different order
    if (Array.isArray(value)) {
        value = value.sort()
    }
    return (
        <Attribute
            dereferencedRecords={
                (field?.type === 'lookup' || field?.type === 'multi_lookup') &&
                Array.isArray(dereferencedRecords)
                    ? dereferencedRecords
                    : undefined
            }
            recordId={row?.original?._sid}
            contextRecord={row?.original}
            key={field?._sid}
            layout={display !== 'rows' && !(display === 'table' && col?.wrapText) && 'inline'}
            singleLine={
                display !== 'card' && display !== 'kanban' && display !== 'rows' && !col?.wrapText
            }
            baseMaxWidth={
                display !== 'card' &&
                display !== 'kanban' &&
                display !== 'rows' &&
                display !== 'inbox'
                    ? maxColumnWidth
                    : null
            }
            field={field}
            renderOptions={col}
        >
            {value}
        </Attribute>
    )
}
