import React from 'react'

import { InlineFilterMultiValueInput } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterMultiValueInput'

import { InlineFilterInputComponent } from './types'

type DropdownInputProps = {
    singleValue?: boolean
}

export const DropdownInput: InlineFilterInputComponent<DropdownInputProps> = ({
    value = [],
    onChange,
    singleValue,
    options,
}) => {
    return (
        <InlineFilterMultiValueInput
            key="dropdown"
            options={options}
            onChange={onChange}
            value={value as string[]}
            singleValue={singleValue}
        />
    )
}
