import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import some from 'lodash/some'

export class TreeInstance {
    subscribers = []
    changes = []
    constructor(defaultTree, callback) {
        this.updateTree(defaultTree)
        this.compactContainers = true
        this.subscribers.push(callback)
    }
    history = []
    describeChanges(obj) {
        this.changes.push(obj)
    }
    fork() {
        return new TreeInstance(this.tree)
    }
    serialise(prettyPrint) {
        const result = this.tree.map(serialise)

        if (prettyPrint) return JSON.stringify(result, null, 4)
        return result
    }
    subscribe(fn) {
        this.subscribers.push(fn)

        return () => (this.subscribers = this.subscribers.filter((_fn) => fn !== _fn))
    }
    validateBlocks() {
        forEach(this.indexedBlocks, (block) => {
            if (
                !block.config ||
                !block.config.attributes ||
                !block.config.style ||
                !block.config.broadcast ||
                !block.childBlocks
            ) {
                this.describeChanges({
                    blockId: block.id,
                    action: 'updateConfig',
                })

                if (!block.config)
                    block.config = {
                        attributes: {},
                        style: {},
                        broadcast: [],
                    }

                if (!block.config.attributes) {
                    block.config.attributes = {}
                }
                if (!block.config.style) {
                    block.config.style = {}
                }
                if (!block.config.broadcast) {
                    block.config.broadcast = []
                }
                if (!block.childBlocks) {
                    block.childBlocks = []
                }
            }
        })
    }
    updateTree(tree) {
        this.tree = tree

        const parentBlocks = {}
        this.tree.forEach((block) => _getParentBlocks(parentBlocks, block, null))

        this.parentBlocks = parentBlocks

        const indexedBlocks = {}
        this.tree.forEach((block) => _indexBlockById(indexedBlocks, block, null))

        this.indexedBlocks = indexedBlocks

        this.validateBlocks()

        return this.notifyChanges()
    }
    recordHistory() {
        this.history.push(cloneDeep(this.tree))
    }
    notifyChanges() {
        this.subscribers.map((fn) => fn(this.changes, this.tree))
        this.changes = []
        // console.log("Notify")
    }

    moveBlock(elementToBeMovedId, newParentElementId, newPosition) {
        const oldParentId = this.parentBlocks[elementToBeMovedId]
        const oldParent = this.indexedBlocks[oldParentId]
        const newParent = this.indexedBlocks[newParentElementId]
        const currentBlock = this.indexedBlocks[elementToBeMovedId]

        if (
            oldParentId === newParentElementId &&
            newPosition > oldParent.childBlocks.indexOf(currentBlock)
        ) {
            console.log('fixed position')
            newPosition--
        }

        if (!oldParent) {
            debugger
            throw new Error("ok, that's another bug")
        }

        if (oldParentId === newParent.id && oldParent.childBlocks.length === 1) {
            // nothing to do
            return
        }

        // remove child from old parent
        this.updateBlockChildren(
            oldParentId,
            oldParent.childBlocks.filter(({ id }) => id !== elementToBeMovedId)
        )

        // add child to new parent
        if (!newParent.childBlocks) {
            newParent.childBlocks = [currentBlock]
        } else {
            this.updateBlockChildren(newParentElementId, [
                ...newParent.childBlocks.slice(0, newPosition),
                currentBlock,
                ...newParent.childBlocks.slice(newPosition),
            ])
        }

        this.describeChanges({
            blockId: elementToBeMovedId,
            action: 'move',
        })

        this.checkForEmptyContainer(oldParentId)
        this.checkForEmptyContainer(newParentElementId)
        this.updateTree(this.tree)

        return this
    }

    moveBlockUp(elementToBeMovedId) {
        const parentId = this.parentBlocks[elementToBeMovedId]
        const parent = this.indexedBlocks[parentId]
        const currentBlock = this.indexedBlocks[elementToBeMovedId]
        const currentPosition = parent.childBlocks.indexOf(currentBlock)
        const newPosition = currentPosition - 1
        if (newPosition < 0) return

        return this.moveBlock(elementToBeMovedId, parentId, newPosition)
    }
    moveBlockDown(elementToBeMovedId) {
        const parentId = this.parentBlocks[elementToBeMovedId]
        const parent = this.indexedBlocks[parentId]
        const currentBlock = this.indexedBlocks[elementToBeMovedId]
        const currentPosition = parent.childBlocks.indexOf(currentBlock)
        let newPosition = currentPosition + 2
        if (newPosition > parent.childBlocks.length) newPosition--

        return this.moveBlock(elementToBeMovedId, parentId, newPosition)
    }
    removeBlock(blockId) {
        const parentId = this.parentBlocks[blockId]
        const parent = this.indexedBlocks[parentId]
        if (!parent) {
            return
        }
        this.updateBlockChildren(
            parentId,
            parent.childBlocks.filter(({ id }) => id !== blockId)
        )

        this.describeChanges({
            blockId,
            action: 'remove',
        })

        this.checkForEmptyContainer(parentId)
    }

    isBlockUnmodified(block) {
        // If the user has any modifications to a block, then we don't want
        // to remove it automatically.
        // That basically means: No style changes or properties changes
        if (
            Object.keys(block.config.attributes).filter((attr) => attr !== 'direction').length > 0
        ) {
            console.log(`Is modified ${block.id}`)
            return false
        }
        // laurent: I changed from JSON.stringify(block.config.style) !== "{}"
        // but I don't know if there was a legitimate reason to do it or not
        if (Object.keys(block.config.style).length) {
            console.log(`Is modified ${block.id}`)
            return false
        }
        console.log(`Is unmodified ${block.id}`)
        return true
    }

    checkForEmptyContainer(blockId) {
        if (!this.compactContainers) return
        const block = this.indexedBlocks[blockId]
        if (!block) return

        if (block.childBlocks.length === 0 && block.type === 'container') {
            if (this.isBlockUnmodified(block)) {
                console.log(`Removing ${block.id}`)
                this.removeBlock(blockId)
            }
        }

        if (block.childBlocks.length === 1 && block.type === 'container') {
            if (this.isBlockUnmodified(block)) {
                console.log(`Compacting ${block.id}`)
                this.compactContainer(blockId)
            }
        }
        this.checkForEmptyContainer(this.parentBlocks[blockId])
    }
    compactContainer(blockId) {
        if (!this.compactContainers) return
        const block = this.indexedBlocks[blockId]

        const parentId = this.parentBlocks[blockId]
        const parent = this.indexedBlocks[parentId]

        if (!parent || parent.type !== 'container') return

        if (block.config.attributes.direction !== parent.config.attributes.direction) {
            return
        }

        const position = parent.childBlocks.indexOf(block)

        console.log('compact', block.id, block.childBlocks[0].id, parentId, position)
        this.moveBlock(block.childBlocks[0].id, parentId, position)
        this.removeBlock(block.id)
        this.updateTree(this.tree)
    }

    updateBlockChildren(blockId, newChildren) {
        const block = this.indexedBlocks[blockId]

        this.describeChanges({
            blockId,
            action: 'updateChildren',
        })

        block.childBlocks = newChildren
    }
    getBlocks() {
        return this.tree
    }
    giveBlocksNewIds(baseBlock) {
        // Don't include version number for block
        const type = baseBlock.type.split(':')[0]

        let newId = baseBlock.default_id || this.generateIdForType(type)
        this.indexedBlocks[newId] = { id: null } // Placeholder so childBlocks don't get this ID too!
        return {
            ...baseBlock,
            childBlocks: (baseBlock.childBlocks || []).map((block) => this.giveBlocksNewIds(block)),
            local_id: baseBlock.local_id || newId,
            id: newId,
        }
    }

    createAndAddBlock(BlockBase, parentId, position) {
        const newBlock = this.giveBlocksNewIds(BlockBase)

        this.describeChanges({
            blockId: newBlock.id,
            action: 'create',
        })

        const parent = this.indexedBlocks[parentId]

        if (!parent) {
            debugger
            throw new Error('ok, another bug')
        }

        if (!parent.childBlocks) {
            this.updateBlockChildren(parentId, [newBlock])
        } else {
            this.updateBlockChildren(parentId, [
                ...parent.childBlocks.slice(0, position),
                newBlock,
                ...parent.childBlocks.slice(position),
            ])
        }

        this.updateTree(this.tree)

        return newBlock
    }
    generateIdForType(type) {
        let inc = 0

        while (this.indexedBlocks[type + '_' + inc]) {
            inc++
        }

        return type + '_' + inc
    }

    updateBlockAttributes(id, key, value) {
        const block = this.indexedBlocks[id]
        const attributes = block.config.attributes

        if (typeof key === 'string' && attributes[key] === value) return false

        this.describeChanges({
            blockId: id,
            action: 'updateConfig',
            type: 'attributes',
        })

        // If the first arg (key) is a string
        // then we are receiving a keyvalue/pair
        if (typeof key === 'string') {
            block.config.attributes = {
                ...attributes,
                [key]: value,
            }

            // Otherwise, we can assume we are receiving a "patch"
            // object with several values and we want to add all of them
            // at once
        } else {
            block.config.attributes = {
                ...attributes,
                ...key,
            }
        }

        this.notifyChanges()

        return true
    }

    updateBlockStyle(id, key, value, type = 'default') {
        const deviceType = type === 'desktop' ? 'default' : type
        const block = this.indexedBlocks[id]
        const style = block.config.style
        if (!style[deviceType]) style[deviceType] = {}
        if (style[deviceType][key] === value) return false

        style[deviceType] = { ...style[deviceType], [key]: value }

        this.describeChanges({
            blockId: id,
            action: 'updateConfig',
            type: 'styles',
        })

        this.notifyChanges()

        return true
    }

    updateBlockStyles(id, styles) {
        const block = this.indexedBlocks[id]
        block.config.style = styles

        this.describeChanges({
            blockId: id,
            action: 'updateConfig',
            type: 'styles',
        })

        this.notifyChanges()

        return true
    }

    addBroadcast(id, object) {
        const block = this.indexedBlocks[id]
        const { broadcast } = block.config

        const alreadyAdded = some(broadcast, (row) => object.id === row.id)
        if (alreadyAdded) return false

        broadcast.push(object)

        this.describeChanges({
            blockId: id,
            action: 'updateBroadcast',
            type: 'add',
        })

        this.notifyChanges()

        return true
    }

    removeBroadcast(blockId, broadcastId) {
        const block = this.indexedBlocks[blockId]

        if (!block) {
            console.log(
                'removing broadcast from unknown block',
                blockId,
                '. Maybe it has been removed already'
            )
            return
        }
        const { broadcast } = block.config

        const exists = some(broadcast, (row) => broadcastId === row.id)
        if (!exists) return false

        block.config.broadcast = broadcast.filter((row) => broadcastId !== row.id)

        this.describeChanges({
            blockId,
            action: 'updateBroadcast',
            type: 'remove',
        })

        this.notifyChanges()

        return true
    }

    updateBroadcast(blockId, patch) {
        const block = this.indexedBlocks[blockId]
        const { broadcast } = block.config

        const exists = some(broadcast, (row) => patch.id === row.id)
        if (!exists) return false

        block.config.broadcast = broadcast.map((row) => {
            if (patch.id !== row.id) return row
            return {
                ...row,
                ...patch,
            }
        })

        this.describeChanges({
            blockId,
            action: 'updateBroadcast',
            type: 'rename',
        })

        this.notifyChanges()

        return true
    }

    undo() {
        const tree = this.history.pop()
        if (!tree) return

        tree.forEach((block) =>
            this.describeChanges({
                blockId: block.id,
                action: 'updateConfig',
            })
        )
        this.updateTree(tree, false)
    }

    updateBlock(id, patch) {
        const block = this.indexedBlocks[id]
        if (!block) return false

        // Mutate original block instead of having to copy it.
        for (const [key, value] of Object.entries(patch)) {
            block[key] = value
        }

        this.describeChanges({
            blockId: id,
            action: 'updateConfig',
        })

        this.notifyChanges()

        return true
    }
}

function _indexBlockById(accu, block, parentId) {
    if (accu[block.id]) {
        console.log(
            'block with id',
            block.id,
            'is already defined, it means you may have the same block defined twice in your tree',
            'trying to redefine in block',
            parentId
        )
    }
    accu[block.id] = block
    block.childBlocks && block.childBlocks.map((child) => _indexBlockById(accu, child, block.id))

    return accu
}

function _getParentBlocks(accu, block, parent) {
    if (accu[block.id]) console.debug('block with id', block.id, 'is already defined')
    accu[block.id] = parent
    block.childBlocks && block.childBlocks.map((child) => _getParentBlocks(accu, child, block.id))

    return accu
}

function serialise(block) {
    return {
        id: block.id,
        childBlocks: block.childBlocks ? block.childBlocks.map(serialise) : [],
    }
}
