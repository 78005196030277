import React, { FC } from 'react'

import type { CustomCell, Rectangle } from '@glideapps/glide-data-grid'

import NumberAttribute from 'v2/ui/components/Attribute/NumberAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import EditorWrapper from '../components/EditorWrapper'
import type { CellData, CustomCellRenderer } from '../types'

export const PERCENTAGE_CELL = 'percentage-cell'

export type PercentageCellData = CellData & {
    readonly kind: typeof PERCENTAGE_CELL
    readonly value: number | null | undefined
    readonly displayValue: number | null | undefined
}

export type PercentageCell = CustomCell<PercentageCellData>

const renderer: CustomCellRenderer<PercentageCell> = {
    isMatch: (cell): cell is PercentageCell => (cell.data as any).kind === PERCENTAGE_CELL,
    draw: ({ ctx, theme, rect }, cell) => {
        if (!cell.data.value) {
            return true
        }

        const drawArea: Rectangle = {
            x: rect.x + theme.cellHorizontalPadding,
            y: rect.y + theme.cellVerticalPadding,
            width: rect.width - 2 * theme.cellHorizontalPadding,
            height: rect.height - 2 * theme.cellVerticalPadding,
        }

        ctx.fillStyle = theme.textDark
        ctx.fillText(`${cell.data.displayValue}%`, drawArea.x, drawArea.y + 13)

        return true
    },
    provideEditor: () => {
        const CustomEditor: FC<{
            value: PercentageCell
            onChange: (cell: PercentageCell) => void
        }> & { disableStyling?: boolean } = ({ onChange, value: cell }) => {
            const handleChange = (value: number) => {
                const percent = value / 100

                const newCell = {
                    ...cell,
                    copyData: percent.toString(),
                    data: {
                        ...cell.data,
                        value: percent,
                        displayValue: value,
                    },
                }

                onChange(newCell)
            }

            return (
                <EditorWrapper>
                    <NumberAttribute mode={modes.editing} onChange={handleChange}>
                        {cell.data.displayValue}
                    </NumberAttribute>
                </EditorWrapper>
            )
        }

        return {
            editor: CustomEditor,
            disablePadding: true,
            deletedValue: (toDelete: PercentageCell) => ({
                ...toDelete,
                copyData: '',
                data: {
                    ...toDelete.data,
                    value: null,
                    displayValue: null,
                },
            }),
        }
    },
}

export default renderer
