import React, { useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useJobPoller } from 'data/api/jobApi'
import { fetchWithAuth } from 'data/utils/utils'

import { Button } from 'v2/ui'

export const MigrateAccountToV4Button: React.FC<{ rollback: boolean }> = (props) => {
    const { selectedStack, workspaceAccount } = useAppContext()
    const [isLoading, setIsLoading] = useState(false)
    const [jobId, setJobId] = useState('')

    // Poll for the progress updates, but never time out.
    const polling = useJobPoller(jobId, () => undefined, 0)

    // When we finish polling, refresh the stack
    useEffect(() => {
        if (polling.isComplete) {
            if (!polling.isSuccessful) {
                console.log(polling)
                alert('Failed. See console log.')
            } else if (props.rollback) {
                alert('Successfully reverted to V3. Please close this window.')
            }
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polling.isComplete])

    const handleStartMigration = () => {
        setIsLoading(true)
        fetchWithAuth(`account/migrate/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                account_id: selectedStack?.account_id || workspaceAccount?._sid,
                rollback: props.rollback,
            }),
        })
            .then((response) => {
                // If we got a job ID then start polling it
                response
                    .json()
                    .then((result) => {
                        setJobId(result?.job)
                    })
                    .catch((ex) => {
                        console.error(ex)
                        setIsLoading(false)
                        alert('Failed, see console log.')
                    })
            })
            .catch((ex) => {
                console.error(ex)
                setIsLoading(false)
                alert('Failed, see console log.')
            })
    }

    return (
        <Button
            onClick={handleStartMigration}
            isLoading={isLoading}
            variant="Primary"
            buttonSize="small"
        >
            {workspaceAccount ? 'Rollback to V3' : 'Migrate to V4'}
        </Button>
    )
}
