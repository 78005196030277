// @ts-strict-ignore
import React from 'react'

import { ImageProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useIntegrations } from '../../../data/hooks/integrations'
import { Icon, Image } from '../../../v2/ui'
import { IconProps } from '../../../v2/ui/components/Icon'

import DATA_PROVIDERS from './dataProviderConfig'

type ProviderIconProps = {
    type: string
    serviceName?: string
} & ImageProps &
    IconProps

export function ProviderIcon({ type, serviceName, size = '18px', ...props }: ProviderIconProps) {
    const { integrations } = useIntegrations({ enabled: type === 'fivetran' })

    const fivetranImageUrl =
        type === 'fivetran' &&
        integrations.find((integration) => integration.service_name === serviceName)?.imageUrl

    if (fivetranImageUrl) {
        return (
            <Image
                w={size}
                h={size}
                alignSelf="flex-start"
                src={fivetranImageUrl}
                alt={serviceName}
                size={size}
                {...props}
            />
        )
    } else {
        const IconToRender = DATA_PROVIDERS.hasOwnProperty(type) ? DATA_PROVIDERS[type].icon : null
        //hack for backward compatibility with existing usages
        if (size === '18px' && IconToRender === 'users') {
            size = '16px'
        }
        return (
            <StyledIcon
                icon={typeof IconToRender === 'string' ? IconToRender : undefined}
                svg={
                    (typeof IconToRender === 'object' || typeof IconToRender === 'function') && (
                        <IconToRender />
                    )
                }
                size={size}
                {...props}
            />
        )
    }
}

const StyledIcon = styled(Icon)`
    width: ${(props) => props.size};
`
