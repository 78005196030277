import { useMemo } from 'react'

import Color from 'color'

import { useAppContext } from 'app/AppContext'

import useNavigationColor from './useNavigationColor'

export function useIsNavigationDark(): boolean {
    const { selectedStack } = useAppContext()
    const theme: StackDto['options']['theme'] =
        selectedStack?.options?.theme ?? ({} as StackDto['options']['theme'])
    const navTheme = useNavigationColor(theme)

    return useMemo(() => {
        if (!navTheme.bgScdNav) return false

        const navColor = Color(navTheme.bgScdNav)

        return navColor.isDark()
    }, [navTheme.bgScdNav])
}
