import { getCurrentStackId } from 'app/AppContextStore'

import { queryClient, useQuery } from './_helpers'
/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'stack-details'
const ENDPOINT = 'stack-details/'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */

export function useStackDetails(options = {}) {
    const stackId = getCurrentStackId()
    return useQuery([LIST_NAME, stackId], ENDPOINT, options)
}

export function invalidateStackDetails() {
    const stackId = getCurrentStackId()
    return queryClient.invalidateQueries([LIST_NAME, stackId])
}
