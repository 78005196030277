// @ts-strict-ignore
import { useCallback } from 'react'

import { useAccountUserContext } from 'app/AccountUserContext'

import { useAppContext } from '../app/AppContext'
import { useAppUserContext } from '../app/AppUserContext'

import analytics from './analytics'

const useTrack = () => {
    const { workspaceAccount, selectedStack } = useAppContext()
    const { user, studioUser, role: appRole } = useAppUserContext()
    const { role: accountRole } = useAccountUserContext()

    return {
        track: useCallback(
            (eventName, additionalPayload = {}) => {
                const basePayload = {
                    ...(workspaceAccount
                        ? {
                              workspace_id: workspaceAccount._sid,
                              subscription: workspaceAccount.plan_tier?.toLowerCase() ?? 'trial',
                          }
                        : {}),
                    ...(selectedStack ? { app_id: selectedStack._sid } : {}),
                    ...(user ? { user_id: studioUser?._sid || user._sid } : {}),
                    ...(accountRole?.api_name ? { account_role: accountRole?.api_name } : {}),
                    ...(appRole?.api_name ? { app_role: appRole?.api_name } : {}),
                    is_support_login: localStorage.getItem('support_login') ?? false,
                }
                analytics.track(eventName, { ...basePayload, ...additionalPayload })
            },
            [workspaceAccount, selectedStack, user, studioUser, accountRole, appRole]
        ),
    }
}
export default useTrack
