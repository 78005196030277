import { useCallback, useState } from 'react'

import { Text } from 'slate'

const usePlainTextSerializer = () => {
    const [editor, setEditor] = useState()
    const [text, setText] = useState()

    const initialize = useCallback(
        (editor) => {
            setEditor(editor)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor]
    )

    const serialize = (node) => {
        if (Text.isText(node)) {
            return node.text
        }

        return node.children.reduce((result, value) => {
            result = result + serialize(value)
            return result
        }, '')
        //const children = node.children.map(n => serialize(n)).join('')
    }
    const onChange = (value) => {
        setText(value.map((n) => serialize(n)).join('\n'))
    }

    return { onChange, initialize, text }
}

export default usePlainTextSerializer
