import React, { useCallback } from 'react'

import * as Sentry from '@sentry/react'

import useTrack from '../../../../../utils/useTrack'
import { DataConnectionEditorContextType } from '../../dataConnectionEditorContext'

import { PostgresConnectionDetailsForm } from './PostgresConnectionDetailsForm'
import { STEPS } from './postgresConnectorConstants'
import { PostgresConnectionDetailsFormData } from './postgresConnectorTypes'

export const PostgresInitializeNewConnection = ({
    context,
}: {
    context: DataConnectionEditorContextType
}) => {
    const { track } = useTrack()
    const {
        setNextHandler,
        setIsSaving,
        createDataConnection,
        setDataConnection,
        setStep,
        setNextButtonText,
        isTesting,
        setIsTesting,
    } = context

    // Handler for creating the connection
    const onSubmit = useCallback(
        (values: PostgresConnectionDetailsFormData) => {
            track('WIP - Frontend - Data Connection - Creation - Next button - Clicked', {
                data_connection_type: 'postgresV2',
            })
            const { is_read_only, ...secretOptions } = values
            setIsSaving(true)
            createDataConnection({
                is_read_only,
                updated_secret_options: secretOptions,
            })
                .then((connection) => {
                    setDataConnection(connection)
                    // Move on to the next step
                    setStep(STEPS.syncSchema)
                })
                .catch(Sentry.captureException)
                .finally(() => setIsSaving(false))
        },
        [track, setIsSaving, setDataConnection, createDataConnection, setStep]
    )

    return (
        <PostgresConnectionDetailsForm
            isCreate={true}
            onSubmit={onSubmit}
            setNextHandler={setNextHandler}
            setNextButtonText={setNextButtonText}
            isTesting={isTesting}
            setIsTesting={setIsTesting}
        />
    )
}
