import { useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import {
    authedUserDomainFilter,
    buildQueryKey,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useFeatures'
const ENDPOINT = 'features/'

function useQueryKey(stackId?: string) {
    return useQueryKeyBuilder(LIST_NAME, { includeStackId: true, stackId })
}

function getQueryKey(stackId?: string) {
    return buildQueryKey(LIST_NAME, { includeStackId: true, stackId })
}

export function useFeatures(options: UseQueryOptions<FeatureDto[]> = {}, stackId?: string) {
    const query_config = { ...STACK_QUERY_CONFIG, ...options } as UseQueryOptions<FeatureDto[]>
    const { selectedStack, workspaceAccount } = useAppContext()
    return useQuery<FeatureDto[]>(useQueryKey(stackId), ENDPOINT, query_config, {
        disabledFn: () => authedUserDomainFilter(selectedStack, workspaceAccount),
    })
}

export function useCreateFeature() {
    return useCreateItem(useQueryKey(), ENDPOINT)
}
export function useUpdateFeature() {
    return useUpdateItem(useQueryKey(), ENDPOINT)
}
export function useDeleteFeature() {
    return useDeleteItem(useQueryKey(), ENDPOINT)
}
export function invalidateFeatures() {
    return queryClient.invalidateQueries(getQueryKey())
}
export function refetchFeatures() {
    return queryClient.refetchQueries(getQueryKey())
}

export function useGetFeature(featureId: string) {
    const { data: features } = useFeatures()
    const feature = features?.find((feature) => feature._sid === featureId)
    return useMemo(() => feature, [feature])
}
