/* Code Quality: Not audited */

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getUrl, Urls, WorkspaceUrls } from 'app/UrlService'
import { withStacks } from 'data/wrappers/WithStacks'
import DataDebuggerPage from 'features/admin/data-connector/DataDebuggerPage'
import AcceptInvitePage from 'features/auth/AcceptInvitePage'
import SignupSetThemePage from 'features/auth/admin-registration/SignupSetThemePage'
import SignupFrame from 'features/auth/admin-registration/ui/SignupFrame'
import EditProfilePage from 'features/auth/EditProfilePage'
import SignUpPage from 'features/auth/SignUpPage'
import { ThirdPartyCallbackPage } from 'features/supertokens/ThirdPartyCallbackPage'
import InstallBundlePage from 'features/workspace/ImportBundle'
import AccountOnboarding from 'features/workspace/onboarding/AccountOnboarding'
import WorkspaceOnboardingManager from 'features/workspace/onboarding/WorkspaceOnboardingManager'

import AdminModal from '../features/admin/AdminModal'
import CacheInfoPage from '../features/admin/data-connector/CacheInfoPage'
import { ConnectionDebuggerPage } from '../features/admin/data-connector/ConnectionDebugger/ConnectionDebuggerPage'
// More Stuff
// Installable Features
import AutoSignupPage from '../features/auth/admin-registration/AutoSignupPage'
import SignupCreatingStackPage from '../features/auth/admin-registration/SignupCreatingStackPage'
import SignupSetNamePage from '../features/auth/admin-registration/SignupSetNamePage'
// Portal
import SignupStartPage from '../features/auth/admin-registration/SignupStartPage'
import Auth0CompleteLoginPage from '../features/auth/Auth0CompleteLoginPage'
import AuthWithToken from '../features/auth/AuthWithToken'
import LoginPage from '../features/auth/LoginPage'
// Auth
import ResetPasswordPage from '../features/auth/ResetPasswordPage'
import OpenSignupPage from '../features/auth/stacker-auth/OpenSignupPage'
import SupportLogin from '../features/auth/SupportLogin'
import UserForgottenPasswordPage from '../features/auth/UserForgottenPasswordPage'
// User Domain Auth Pages
import UserLoginPage from '../features/auth/UserLoginPage'
import UserSetPasswordPage from '../features/auth/UserSetPasswordPage'
import UserSignupPage from '../features/auth/UserSignupPage'
import PageByUrl from '../features/pages/page/PageByUrl'
import AccountSettings from '../features/stackerSettings/AccountSettings'
import StackSettingsPage from '../features/studio/stacks/StackSettingsPage'
// Account Pages
import StacksPage from '../features/studio/stacks/StacksPage'
import TemplateLibraryPage from '../features/templates/TemplateLibraryPage'
import WorkspaceHome from '../features/workspace/WorkspaceHome'

import { HelpLinksPage } from './HelpLinksPage'
import LogoutRoute from './LogoutRoute'
// Pages
import PrivateRoute, { StackerDomainPrivateRoute } from './PrivateRoute'

export const UserDomainRoutes = withStacks(({ workspaceAccount, selectedStack }) => {
    const workspaceRoutes = [
        { path: '/login', component: LoginPage },
        { path: '/complete-login', component: Auth0CompleteLoginPage },
        { path: '/register', component: SignUpPage },
        { path: '/accept', component: AcceptInvitePage },
        { path: '/reset-password/:authToken?', component: ResetPasswordPage },
        {
            path: `/auth/callback/:provider`,
            component: ThirdPartyCallbackPage,
        },
        { path: '/auth', component: AuthWithToken },
        { path: '/edit-profile', component: EditProfilePage },
        { path: '/__account', component: AccountSettings },
        { path: '/__install', component: InstallBundlePage },
        {
            path: `${WorkspaceUrls.TemplateLibrary}/:templateId?`,
            component: TemplateLibraryPage,
            isPrivate: true,
        },
        {
            path: '/',
            exact: selectedStack?._sid !== undefined,
            component: WorkspaceHome,
            isPrivate: true,
        },
    ]

    const isWorkspaceApp = selectedStack?.options?.workspace_app

    // If we have a workspace account, but no selected stack, just show the workspace routes
    if (workspaceAccount && !selectedStack?._sid) {
        return (
            <Switch>
                <LogoutRoute path={Urls.Logout} isUserDomain />
                {workspaceAccount?.stack_details
                    ?.filter((stack) => stack?.is_num_app)
                    ?.map((stack, index) => {
                        return (
                            <Route
                                key={index}
                                path={`${getUrl(Urls.Register, stack)}`}
                                render={(props) => (
                                    <OpenSignupPage stack_details={stack} {...props} />
                                )}
                            />
                        )
                    })}
                {workspaceRoutes.map(({ isPrivate, ...route }, index) =>
                    isPrivate ? (
                        <PrivateRoute key={index} {...route} />
                    ) : (
                        <Route key={index} {...route} />
                    )
                )}
            </Switch>
        )
    }
    return (
        <>
            <Switch>
                {workspaceAccount &&
                    workspaceAccount?.stack_details
                        ?.filter((stack) => stack?.is_num_app)
                        ?.map((stack, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={`${getUrl(Urls.Register, stack)}`}
                                    render={(props) => (
                                        <OpenSignupPage stack_details={stack} {...props} />
                                    )}
                                />
                            )
                        })}
                {workspaceAccount &&
                    workspaceRoutes.map(({ isPrivate, ...route }, index) =>
                        isPrivate ? (
                            <PrivateRoute key={index} {...route} />
                        ) : (
                            <Route key={index} {...route} />
                        )
                    )}
                <LogoutRoute path={Urls.Logout} isUserDomain={workspaceAccount} />
                {/* STUDIO PAGES */}
                <PrivateRoute
                    path={getUrl(Urls.StudioStackSettings)}
                    component={StackSettingsPage}
                />
                <LogoutRoute path={getUrl(Urls.StudioLogout)} isUserDomain />
                {/* PANEL ADMIN PAGES */}
                <PrivateRoute path={getUrl(Urls.AdminCache)} component={CacheInfoPage} />
                <PrivateRoute path={getUrl(Urls.AdminDataDebugger)} component={DataDebuggerPage} />
                <PrivateRoute
                    path={getUrl(Urls.AdminConnectionDebugger)}
                    component={ConnectionDebuggerPage}
                />
                <Route path={getUrl(Urls.AdminSupportLogin)} component={SupportLogin} />
                <PrivateRoute path={getUrl(Urls.AdminModal)} component={AdminModal} />
                {/* PUBLIC LOGINREGISTER PAGES - NOT VALID IN V4 */}
                {workspaceAccount && isWorkspaceApp ? (
                    <RedirectRoute path={getUrl(Urls.Login)} to="/home" />
                ) : (
                    <Route path={getUrl(Urls.Login)} component={UserLoginPage} />
                )}
                {workspaceAccount && isWorkspaceApp ? (
                    <RedirectRoute path={getUrl(Urls.Register)} to="/home" />
                ) : (
                    <Route path={getUrl(Urls.Register)} component={UserSignupPage} />
                )}
                {workspaceAccount && isWorkspaceApp ? (
                    <RedirectRoute path={getUrl(Urls.ForgotPassword)} to="/home" />
                ) : (
                    <Route
                        path={getUrl(Urls.ForgotPassword)}
                        component={UserForgottenPasswordPage}
                    />
                )}
                {workspaceAccount && isWorkspaceApp ? (
                    <RedirectRoute path={getUrl(Urls.SetPassword)} to="/home" />
                ) : (
                    <Route
                        path={`${getUrl(Urls.SetPassword)}/:authToken?`}
                        component={UserSetPasswordPage}
                    />
                )}
                {/* CATCH-ALL TO DISPLAY PAGES */}
                <Route component={PageByUrl} />
            </Switch>
        </>
    )
})

export const StackerDomainRoutes = () => (
    <Switch>
        <Route path={getUrl(Urls.RegisterAuto)} component={AutoSignupPage} />
        <Route path={getUrl(Urls.RegisterFrame)} component={SignupFrame} />
        <Route path={getUrl(Urls.Register4)} component={SignupCreatingStackPage} />
        <Route path={getUrl(Urls.Register3)} component={SignupSetThemePage} />
        <Route path={getUrl(Urls.Register2)} component={SignupSetNamePage} />
        <Route path={getUrl(Urls.Register)} component={SignupStartPage} />

        <Route path={getUrl(Urls.Auth)} component={AuthWithToken} />
        <Route path={getUrl(Urls.AcceptInvitation)} component={AcceptInvitePage} />
        <Route path={getUrl(Urls.Login)} component={LoginPage} />
        <LogoutRoute path={getUrl(Urls.Logout)} />
        <Route path={`${getUrl(Urls.ResetPassword)}/:authToken?`} component={ResetPasswordPage} />
        <Route path={`${getUrl(Urls.SetPassword)}/:authToken?`} component={ResetPasswordPage} />

        <StackerDomainPrivateRoute path={getUrl(Urls.Stacks)} component={StacksPage} />

        <Route path={getUrl(Urls.WorkspaceOnboarding)} component={WorkspaceOnboardingManager} />
        <Route path={getUrl(Urls.AccountOnboarding)} component={AccountOnboarding} />

        <Route path={getUrl(Urls.HelpLinks)} component={HelpLinksPage} />
        <RedirectRoute path={getUrl(Urls.Root)} to="/register" />
    </Switch>
)

// const RouteParamDebug = ({ match }) => JSON.stringify(match)

const RedirectRoute = ({ path, to }) => <Route path={path} component={() => <Redirect to={to} />} />
RedirectRoute.propTypes = {
    path: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}
