import React, { useEffect, useRef } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'

import {
    getHeaderOptionsFromListViewOptions,
    getModularHeaderOptionsFromListViewOptions,
} from './listViewHeaderUtils'
import ViewHeader from './ViewHeader'

type ListViewHeaderProps = {
    viewLayoutContext: ViewLayoutContext
    listOptions?: ListViewOptions
    renderInPlace?: boolean
    onChange: (patch: Partial<ListViewOptions>) => void
}

export const ListViewHeader: React.FC<ListViewHeaderProps> = ({
    viewLayoutContext,
    listOptions,
    renderInPlace,
    onChange,
}) => {
    const headerOptions =
        listOptions?.headerType === 'modular'
            ? getModularHeaderOptionsFromListViewOptions(listOptions)
            : getHeaderOptionsFromListViewOptions(listOptions)
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <ViewHeader
            viewLayoutContext={viewLayoutContext}
            parentIsMounted={isMounted.current}
            headerOptions={headerOptions}
            renderInPlace={renderInPlace}
            onChange={onChange}
        />
    )
}
