/* Code Quality: Not audited */

import React, { Component } from 'react'

import { boolean, options, text } from 'features/pages/blocks/settings/attributes/items/primitives'
import { Heading, UpdatingContentEditable } from 'legacy/v1/ui'

export default class HeadingBlockType extends Component {
    state = { edits: { edit: null, callback: this.props.updateAttributes } }

    render() {
        const { attrs, computedStyle, updateAttributes, selected, evaluateFormulas } = this.props
        return (
            <UpdatingContentEditable
                tagName={makeCurriedComponent(Heading, {
                    size: attrs.size,
                })}
                style={{ ...computedStyle, margin: 0, height: '100%', width: '100%' }}
                content={
                    selected
                        ? this.state.edit || attrs.text
                        : evaluateFormulas(this.state.edit || attrs.text, {
                              shouldTranslate: !attrs.dontTranslate,
                          })
                }
                editable={selected}
                multiLine={false}
                styled
                onChange={(_, value) => this.saveEditsUntilUnselected(value, updateAttributes)}
                onBlur={this.onBlur}
            />
        )
    }

    saveEditsUntilUnselected(edit, callback) {
        // Mutate directly so we don't update
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.edits.edit = edit
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.edits.callback = callback
    }

    onBlur = () => {
        if (this.state.edits.edit) {
            this.state.edits.callback('text', this.state.edits.edit)
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.edits = { edit: null, callback: null }
        }
    }

    UNSAFE_componentWillUpdate() {
        this.onBlur()
    }
}

HeadingBlockType.attributes = [
    text({ field: 'text', label: 'Text' }),
    options({
        field: 'size',
        label: 'Size',
        options: [
            { label: 'Small', value: 'small' },
            { label: 'Medium', value: 'medium' },
            { label: 'Large', value: 'large' },
        ],
        simple: true,
    }),
]

if (localStorage.getItem('enableTranslation') === 'on')
    HeadingBlockType.attributes.push(
        boolean({ field: 'dontTranslate', label: 'Exclude from translation' })
    )

const makeCurriedComponent = (Component, defaultProps) => (props) =>
    <Component {...defaultProps} {...props} />
