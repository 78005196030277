function isCountAggregate(aggregate: ChartAggregationType): aggregate is ChartAggregationCount {
    return aggregate?.type === 'count'
}

export default function isValidAggregation(metric: AggregateBlockAttributes, object?: ObjectDto) {
    if (
        !metric.objectId ||
        metric.objectId?.length === 0 ||
        !object ||
        (object?.fields ?? []).length === 0 ||
        (metric.showRelated && !metric.relatedField?.fieldId)
    ) {
        return false
    }
    const aggregationField = object.fields.find(
        (field) => !isCountAggregate(metric.aggregate) && field._sid === metric.aggregate?.field_sid
    )
    return isCountAggregate(metric.aggregate) || !!aggregationField
}
