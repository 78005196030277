import React, { FC } from 'react'

import { FilterDropdown } from './FilterDropdown'
import { FilterRelationship } from './FilterRelationship'
import { FilterUserRole } from './FilterUserRole'
import type { AdminFilterProps } from './types'

export const FilterMultiSelect: FC<AdminFilterProps> = (props) => {
    switch (props.field?.type) {
        case 'dropdown':
        case 'multi_select':
            return <FilterDropdown {...props} multi />
        case 'user_role':
            return <FilterUserRole {...props} multi />
        case 'lookup':
        case 'multi_lookup':
            return <FilterRelationship {...props} multi />
        default:
            return null
    }
}
