import React from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import HelpLink from './HelpLink'

const withHelp = (PassedComponent) => {
    return (props) => {
        const { helpUrl, helpText, helpAlign, helpTextAlign, children, helpId, ...childProps } =
            props
        return (
            <HeadingWrapper>
                <PassedComponent {...childProps}>{children}</PassedComponent>
                {helpUrl && (
                    <HelpLink
                        helpId={helpId}
                        url={helpUrl}
                        tooltip={helpText}
                        tooltipAlign={helpAlign}
                        tooltipTextAlign={helpTextAlign}
                        style={{
                            marginLeft: '10px',
                            verticalAlign: 'middle',
                            display: 'inline-flex',
                            margin: '0 8px',
                            padding: '0',
                        }}
                    />
                )}
            </HeadingWrapper>
        )
    }
}

const HeadingWrapper = styled('div')`
    display: flex;
    align-items: center;
`

const Heading = styled('div')`
    display: inline-block;
    font-size: ${(props) =>
        props.theme[
            (props.size === 'small' && 'headingThreeSize') ||
                (props.size === 'medium' && 'headingTwoSize') ||
                (props.size === 'large' && 'headingOneSize') ||
                'headingOneSize'
        ]};
    color: ${(props) => props.theme.headingColor};
    font-weight: ${(props) => props.theme.headingWeight};
    margin: ${(props) => props.theme.small} 0px;
    padding: ${(props) => props.theme[props.padding || 'medium']} 0px;
    margin: ${(props) => props.theme[props.margin || 'small']} 0px;
    text-transform: ${(props) => props.theme.headingTextTransform || 'none'};
    letter-spacing: ${(props) => props.theme.headingLetterSpacing || 'inherit'};
    line-height: 1em;
    ${(props) =>
        props.inline &&
        `
        display: inline-block;
    `};
    ${(props) =>
        props.light &&
        `
        color: ${props.theme.lightFontColor};
    `};
`

Heading.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    inline: PropTypes.bool,
    light: PropTypes.bool,
}

Heading.displayName = 'Heading'
export default withHelp(Heading)
