import React from 'react'

import { useDisclosure } from '@chakra-ui/react'

export type NavContextType = {
    isOpen: boolean
    onClose: () => any
    onToggle: () => any
}

export const NavContext = React.createContext<NavContextType>({} as NavContextType)

export const NavContextProvider: React.FC = ({ children }) => {
    const { isOpen, onClose, onToggle } = useDisclosure()

    const state = {
        isOpen,
        onClose,
        onToggle,
    }

    return <NavContext.Provider value={state}>{children}</NavContext.Provider>
}
