import React, { ChangeEvent, FC } from 'react'

import { Flex, Input, Text } from 'v2/ui'

import type { AdminFilterProps } from './types'

export const FilterDaysCount: FC<AdminFilterProps> = ({
    value,
    onChange,
    editMode = true,
    ...props
}) => {
    // If we're not in edit mode, then just display the selected values
    // not the input control
    if (!editMode) {
        return <>{`${value} days`}</>
    }

    const isValid = (str: string) => {
        return !str || /^\+?\d+$/.test(str)
    }

    return (
        <Flex direction="row">
            <Input
                dataTestId="record_filters.days_count"
                {...props}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChange?.(isValid(event.target.value) ? event.target.value : value || '')
                }
                value={value || ''}
                style={{ flex: 1 }}
            />
            <Text ml="10px">days</Text>
        </Flex>
    )
}
