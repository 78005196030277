import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'

import { getCachedFields, getCachedFieldsById, useFields } from 'data/hooks/fields'
import { useObjects } from 'data/hooks/objects'
import { getCachedObjectsById } from 'data/hooks/objects/objectOperations'
import { byID } from 'data/utils/byID'
import { getLinkViews } from 'data/utils/getLinks'

import { getIsSymmetricRelationshipField } from '../../utils/fieldUtils'

export const getConnectionsByObjectId = (state) => {
    return state.dataConnections.dataConnections.reduce(
        (acc, connectionId) => ({
            ...acc,
            ...state.dataConnections.entities[connectionId]?.connected_objects.reduce(
                (acc, objectId) => ({
                    ...acc,
                    [objectId]: state.dataConnections.entities[connectionId],
                }),
                {}
            ),
        }),
        {}
    )
}

export const getFieldsById = () => {
    const fieldQuery = getCachedFields()
    const { data: fields = [] } = fieldQuery || {}
    return fields.reduce((acc, field) => ({ ...acc, [field._sid]: field }), {})
}

export const getSymmetricLinkByLinkId = createSelector(getFieldsById, (fieldsById) => {
    const fields = Object.values(fieldsById)
    console.log('fields', fields.length, fields.filter(getIsSymmetricRelationshipField).length)
    return fields.filter(getIsSymmetricRelationshipField).reduce(
        (acc, field) => ({
            ...acc,
            [field.connection_options.relationship_synthetic_lookup_field]: field,
        }),
        {}
    )
})

// This method should only be used outside of react components
// It won't bring back the most up to date fields and objects if used with useSelector
// use the useGetLinksById for components that need to update when fields and objects are updated
export const getLinksById = createSelector(getConnectionsByObjectId, (connectionByObjectId) => {
    const fieldsById = getCachedFieldsById()
    const objectsById = getCachedObjectsById()
    const fields = Object.values(fieldsById)
    return getLinkViews(fields, {
        connectionByObjectId,
        objectsById,
        fieldsById,
    }).reduce((acc, link) => ({ ...acc, [link.linkId]: link }), {})
})

export const useGetLinksById = () => {
    const { data: fields } = useFields()
    const { data: objects } = useObjects()
    const fieldsById = byID(fields)
    const objectsById = byID(objects)
    return useSelector(
        createSelector(getConnectionsByObjectId, (connectionByObjectId) => {
            return getLinkViews(fields, {
                connectionByObjectId,
                objectsById,
                fieldsById,
            }).reduce((acc, link) => ({ ...acc, [link.linkId]: link }), {})
        })
    )
}
