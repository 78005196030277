import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import V4DesignSystem from '../../../ui/deprecated/V4DesignSystem'
import DropdownForm from '../../../ui/forms/DropdownForm'
import Form from '../../../ui/forms/Form'
import InputForm from '../../../ui/forms/InputForm'
import SubmitButton from '../../../ui/forms/SubmitButton'
import analytics from '../../../utils/analytics'
import { Button, Collapse, Flex, Heading, Modal, Text } from '../../../v2/ui'
import LogoPreview from '../../../v2/ui/components/LogoPreview'
import { useIsMobile } from '../../../v2/ui/utils/useIsMobile'
import { usePotentialBaseUrl } from '../../studio/stacks/usePotentialBaseUrl'
import { defaultColor } from '../forms/ColorPicker'
import ImageUploadForm from '../forms/ImageUpload'

import { getPlaceholderWsUrl, getWsBaseUrl } from './WorkspaceAndStackOnboardingModal'

const INACTIVE_NAME_TEXT_COLOR = '#cfcfcf'
const ACTIVE_NAME_TEXT_COLOR = '#464646'
const URL_BACKGROUND_COLOR = '#efedfa'
const URL_TEXT_COLOR = '#5c69d5'

export type WorkspaceOnboardingSettings = {
    name: string
    navColor: string
    brandColor: string
    logoUrl?: string
}

type BodyProps = {
    onChange: (wsSettings: WorkspaceOnboardingSettings) => void
    hideSidebarColor?: boolean
    setIsSubmitDisabled: (disabled: boolean) => void
}

const Body = (props: BodyProps) => {
    const { setIsSubmitDisabled } = props
    const { watch, setValue } = useFormContext()
    const [name, brandColor, navColor, logoUrl] = watch([
        'name',
        'brandColor',
        'navColor',
        'logoUrl',
    ])
    useEffect(() => {
        props.onChange({ name, brandColor, navColor, logoUrl })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, brandColor, navColor, logoUrl])

    const [domainError, setDomainError] = useState<string | undefined>()
    const [potentialBaseUrl, setPotentialBaseUrl] = useState<string | undefined>()
    usePotentialBaseUrl(name, setPotentialBaseUrl, setDomainError)

    const displayUrl = useMemo(() => {
        if (!name) {
            const placeholderUrl = getPlaceholderWsUrl()
            return `${placeholderUrl}/`
        }

        const baseUrl = getWsBaseUrl(potentialBaseUrl)

        return baseUrl ? `${baseUrl}/` : 'loading'
    }, [name, potentialBaseUrl])

    useEffect(() => {
        setIsSubmitDisabled?.(!!domainError)
    }, [domainError, setIsSubmitDisabled])

    const isMobile = useIsMobile()

    const deleteLogo = () => {
        setValue('logoUrl', null, { shouldDirty: true })
    }

    const mainTextColor = name ? ACTIVE_NAME_TEXT_COLOR : INACTIVE_NAME_TEXT_COLOR
    const urlBackgroundColor = name ? URL_BACKGROUND_COLOR : undefined
    const urlTextColor = name ? URL_TEXT_COLOR : undefined

    return (
        <>
            <Flex
                direction={'column'}
                style={{
                    width: '100%',
                    marginBottom: 4,
                    paddingBottom: 5,
                }}
            >
                <Heading variant="modal" value={`What's your organization called?`} />
            </Flex>
            <Flex direction={'column'} style={{ alignItems: 'flex-start' }}>
                <InputForm
                    style={{ width: '100%' }}
                    name="name"
                    label="Organization name"
                    placeholder="Enter name"
                    errorMessages={{
                        required: 'Please enter a value',
                    }}
                    required
                    variant="authentication"
                />
                <Collapse isOpen={!!name}>
                    {domainError ? (
                        <Text variant="error" style={{ marginTop: 12 }}>
                            {domainError}
                        </Text>
                    ) : (
                        <Text style={{ marginTop: 12, color: mainTextColor }}>
                            <span>Your apps will be at{isMobile ? ':' : ' '}</span>
                            {isMobile ? <br /> : null}
                            <span
                                style={{
                                    background: urlBackgroundColor,
                                    color: urlTextColor,
                                }}
                            >
                                {displayUrl}
                            </span>
                        </Text>
                    )}
                </Collapse>
                {!props.hideSidebarColor && (
                    <Flex
                        direction="row"
                        mt="15px"
                        style={{
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Flex direction="column" style={{ alignItems: 'flex-start' }}>
                            <DropdownForm
                                name="navColor"
                                label="Sidebar color"
                                isClearable={false}
                                required
                                options={[
                                    { value: 'brand', label: 'Workspace color' },
                                    { value: 'dark', label: 'Dark grey' },
                                    { value: 'light', label: 'Light' },
                                ]}
                                value={navColor}
                            />
                        </Flex>
                    </Flex>
                )}

                <Text
                    mt="20px"
                    style={{
                        ...V4DesignSystem.label,
                        color: V4DesignSystem.colors.text,
                    }}
                >
                    Logo
                </Text>
                <Text mt="2px" fontSize="100">
                    Max file size upload 1 MB.
                </Text>
                {logoUrl && (
                    <LogoPreview
                        style={{
                            marginTop: '15px',
                            marginBottom: '5px',
                        }}
                        showRemove={true}
                        onRemove={() => deleteLogo()}
                        url={logoUrl}
                        navColor={navColor}
                        brandColor={brandColor}
                    />
                )}
                {!logoUrl && (
                    <ImageUploadForm name="logoUrl">
                        {({ onClick }) => (
                            <Button
                                mt="10px"
                                variant="Secondary"
                                buttonSize="small"
                                icon="add"
                                onClick={onClick}
                            >
                                Upload
                            </Button>
                        )}
                    </ImageUploadForm>
                )}
            </Flex>
        </>
    )
}

type WorkspaceOnboardingModalProps = {
    onSubmit: (wsSettings: WorkspaceOnboardingSettings) => Promise<void>
    onChange: (wsSettings: WorkspaceOnboardingSettings) => void
    preloadedLogoUrl?: string
    preloadedNavColor?: string
    preloadedWorkspaceName?: string
    hideSidebarColor?: boolean
}

const WorkspaceOnboardingModal = (props: WorkspaceOnboardingModalProps) => {
    const {
        onSubmit,
        onChange,
        preloadedLogoUrl,
        preloadedNavColor,
        preloadedWorkspaceName,
        hideSidebarColor,
    } = props

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>()

    return (
        <Modal size="450px" isOpen={true} showCloseButton={false}>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                options={{
                    mode: 'onSubmit',
                    defaultValues: {
                        name: preloadedWorkspaceName,
                        brandColor: defaultColor,
                        navColor: preloadedNavColor || 'dark',
                        logoUrl: preloadedLogoUrl,
                    },
                }}
                onSubmit={onSubmit}
                onSuccess={(formContext) => {
                    if (!!formContext.formState.dirtyFields.logoUrl) {
                        analytics.track('WIP - Frontend - Onboarding - Logo - Updated', {})
                    }
                }}
            >
                <Body
                    hideSidebarColor={hideSidebarColor}
                    onChange={onChange}
                    setIsSubmitDisabled={setIsSubmitDisabled}
                />

                <SubmitButton
                    variant="adminPrimaryV4"
                    disabled={isSubmitDisabled}
                    aria-label="Continue"
                    width="250px"
                    m="20px auto 0"
                >
                    Next
                </SubmitButton>
            </Form>
        </Modal>
    )
}
export default WorkspaceOnboardingModal
