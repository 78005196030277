import React from 'react'

import { Box, Switch, useTheme } from '@chakra-ui/react'

import { Flex, Text, Tooltip } from 'v2/ui'
import { SolidInfo } from 'v2/ui/svgs'

type AdvancedOptionsItemProps = React.ComponentPropsWithoutRef<typeof Flex> & {
    label: string
    id: string
    onChange: (value: boolean) => void
    value?: boolean
    helpText?: string
    labelVariant?: string
    colorScheme?: string
    size?: string
}

export const AdvancedOptionsItem: React.FC<AdvancedOptionsItemProps> = ({
    label,
    id,
    value = false,
    onChange,
    helpText,
    labelVariant,
    colorScheme,
    size,
    ...props
}) => {
    const { colors } = useTheme()

    const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked)
    }

    return (
        <Flex w="100%" justify="space-between" alignItems="center" {...props}>
            <Flex justify="flex-start" alignItems="center">
                <Text
                    as="label"
                    htmlFor={id}
                    color="userInterface.neutral.900"
                    size="sm"
                    variant={labelVariant}
                >
                    {label}
                </Text>

                {helpText && (
                    <Tooltip
                        label={helpText}
                        placement="bottom-end"
                        maxWidth="170px"
                        labelStyle={{
                            fontSize: '0.85rem',
                        }}
                    >
                        <Box
                            ml={1}
                            aria-hidden="true"
                            role="button"
                            cursor="pointer"
                            mt="-0.3rem"
                            color={colors.userInterface.neutral[700]}
                            transition="color 0.1s ease-out"
                            _hover={{
                                color: colors.userInterface.accent[1000],
                            }}
                        >
                            <SolidInfo size={11} color="currentColor" />
                        </Box>
                    </Tooltip>
                )}
            </Flex>
            <Switch
                id={id}
                size={size || 'sm'}
                isChecked={value}
                onChange={onSwitchChange}
                colorScheme={colorScheme || 'adminSwitch'}
            />
        </Flex>
    )
}
