import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// This updates the current history item which is used for breadcrumbs
const useHistoryBreadcrumb = ({ title, type, objectId }, deactivated = false) => {
    const history = useHistory()
    const location = history.location

    useEffect(() => {
        const { state: historyState } = location || {}

        if (
            !deactivated &&
            (historyState?.title !== title ||
                historyState?.objectId !== objectId ||
                historyState?.type !== type) &&
            (type === 'blankpage' || objectId)
        ) {
            history.replace({
                ...location,
                state: {
                    ...(location.state || {}),
                    title,
                    type,
                    objectId,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, objectId, type, deactivated, location])
}

export default useHistoryBreadcrumb
