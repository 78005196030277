import React from 'react'
import { Redirect } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getUrl, Urls } from 'app/UrlService'
import { withDataConnections } from 'data/wrappers/WithDataConnections'
import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'

// This component will redirect a studio user to onboarding if they haven't completed it
const OnboardingRedirector = ({
    isStudioUser,
    stack,
    dataConnections,
    objects,
    fetchedDataConnections,
    fetchedObjects,
}) => {
    if (window.location.pathname.includes('studio')) return null // Pathname = subdomain.stacker.app/studio
    if (window.location.hostname.startsWith('studio.')) return null // Hostname = studio.stacker.app

    const logSentryRedirection = (msg, data) => {
        // https://github.com/getsentry/sentry-electron/issues/90#issuecomment-445422884
        Sentry.withScope((scope) => {
            Object.keys(data).forEach((key) => {
                scope.setExtra(key, data[key])
            })
            scope.setLevel('info')
            Sentry.captureMessage(msg)
        })
    }

    if (!isStudioUser) {
        return null
    }

    // Hard to tell if the data connections have not loaded or there's just none, so we'll assume that
    // They have actually created a data connection
    if (!fetchedDataConnections) {
        return null
    }

    // Check the stack has loaded.
    if (!stack) {
        return null
    }

    // In Salesforce stacks, we will disable these redirects.
    // In the end, we will need a second set of redirect rules for Salesforce.
    if (stack && stack.portal_type === 'salesforce') {
        return null
    }

    const userObject = get(stack, 'options.data_mapping.user_object')
    const emailField = get(stack, 'options.data_mapping.user_email_field')
    const workspaceApp = get(stack, 'options.workspace_app')
    // If there are data_mapping details it means the onboarding has finished
    // so we don't need to redirect
    // make sure we do this check first to avoid redirections caused by race conditions
    if (userObject && emailField) {
        return null
    }

    // default to airtable specific urls
    let prep_url = getUrl(Urls.AdminOnboardingPrep)
    let connection_url = getUrl(Urls.AdminOnboardingConnection)

    if (stack.portal_type == 'gsheets') {
        prep_url = `${getUrl(Urls.AdminOnboardingGsheetsSetup)}?update=true`
        connection_url = prep_url
    }

    if (get(stack, 'options.workspace_app')) {
        return null
    }

    if (dataConnections && !dataConnections.length) {
        logSentryRedirection(`Redirecting to ${prep_url}: missing dataConnections `, {
            dataConnections,
        })
        return <Redirect to={prep_url} />
    }
    // Have they not completed the connection step
    const dc = dataConnections[0]

    const isConnectionSetup = () => {
        if (stack.portal_type == 'gsheets') {
            return dc.options.sheet_id && dc.options.sheet_url
        }

        return dc.options.api_key && dc.options.base_url
    }

    if (!isConnectionSetup()) {
        logSentryRedirection(`Redirecting to ${prep_url}: missing connection credentials `, {
            'dc.options': dc.options,
        })
        return <Redirect to={prep_url} />
    }
    // Have they created any objects
    if (fetchedObjects && objects && !objects.length) {
        logSentryRedirection(`Redirecting to ${connection_url}: missing objects `, {
            fetchedObjects,
            objects,
        })
        return <Redirect to={connection_url} />
    }
    // Have they not completed the mapping step?
    if (!workspaceApp && (!userObject || !emailField)) {
        logSentryRedirection(`Redirecting to '/admin/onboarding/users': missing data mapping `, {
            data_mapping: stack.options.data_mapping,
        })
        return <Redirect to={getUrl(Urls.AdminOnboardingUsers)} />
    }
    // So there are some other steps, but they're actually not mandatory!
    return null
}

OnboardingRedirector.propTypes = {
    fetchedDataConnections: PropTypes.bool.isRequired,
    dataConnections: PropTypes.array.isRequired,
    isStudioUser: PropTypes.bool.isRequired,
    objects: PropTypes.array.isRequired,
    fetchedObjects: PropTypes.bool.isRequired,
    stack: PropTypes.object.isRequired,
}

export default withObjects(withDataConnections(withUser(withStack(OnboardingRedirector))))
