import React from 'react'

import { BoxProps, Switch } from '@chakra-ui/react'

import { Flex, Text } from 'v2/ui'

type DataListBlockEditorAllowCreateProps = {
    onChange: (config: Partial<DataListBlock['config']['attributes']>) => void
    attrs?: DataListBlock['config']['attributes']
} & Omit<BoxProps, 'onChange'>

const DataListBlockEditorAllowCreate: React.FC<DataListBlockEditorAllowCreateProps> = ({
    onChange,
    attrs,
    ...boxProps
}) => {
    const isEnabled = !attrs?.hide_create_button

    return (
        <Flex w="100%" alignItems="center" justifyContent="space-between" {...boxProps}>
            <Text as="label" color="neutral.1000" htmlFor="display-allowCreate" size="sm">
                Allow creating new records
            </Text>
            <Switch
                id="display-allowCreate"
                isChecked={isEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange({ hide_create_button: !e.target.checked })
                }
                size="sm"
                colorScheme="adminSwitch"
            />
        </Flex>
    )
}

export default DataListBlockEditorAllowCreate
