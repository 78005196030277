import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import queryString from 'qs'
import { signInAndUp } from 'supertokens-auth-react/recipe/thirdparty'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'

import { LoadingSplash } from 'v2/ui'

import { useSessionContext } from './supertokens'

export function ThirdPartyCallbackPage() {
    const { workspaceAccount: account } = useAppContext()
    const { user } = useAppUserContext()
    const session = useSessionContext()
    const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })

    const [loginFailed, setLoginFailed] = useState(false)

    const hasStarted = useRef(false)
    const history = useHistory()
    useEffect(() => {
        async function handleCallback() {
            try {
                hasStarted.current = true
                sessionStorage.setItem('processedCode', query.code?.toString() ?? '')
                const response = await signInAndUp()

                if (response.status !== 'OK') {
                    console.log('Login failed :', response.status)
                    setLoginFailed(true)
                }
            } catch (ex) {
                console.log('Login failed :', ex)
                setLoginFailed(true)
            }
        }
        // If we haven't already started processing this code.
        if (
            !hasStarted.current &&
            account &&
            sessionStorage.getItem('processedCode') !== query.code?.toString()
        ) {
            handleCallback()
        }
    }, [account, history, query.code])

    // Authenticated if we have a user and either we're not in a workspace or we have a workspace user.
    // @ts-expect-error
    const isAuthenticated = !!user && !!session?.userId
    useEffect(() => {
        if (isAuthenticated && !!account) {
            const redirectUri = sessionStorage.getItem('redirectAfterCallback')
            sessionStorage.setItem('redirectAfterCallback', '')
            history.push(redirectUri || getUrl(Urls.Root, undefined, account))
        }
    }, [history, isAuthenticated, account])

    if (loginFailed) {
        return <Redirect to={getUrl(Urls.Login, undefined, account)} />
    }

    return <LoadingSplash />
}
