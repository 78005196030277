export const getColumnConfig = (
    object: ObjectDto,
    columns?: ListViewColumnConfig[],
    showAllFields?: boolean
): ListViewColumnConfig[] => {
    const objectFields = object?.fields
    // Get the field config for the view
    const isShowAllFields: boolean = showAllFields ?? false
    const selectedColumns: ListViewColumnConfig[] = columns ?? []

    const selectedColumnsMap = selectedColumns.reduce((agg, curr) => {
        agg.set(curr.fieldId, curr)

        return agg
    }, new Map())

    if (isShowAllFields) {
        return (
            objectFields?.map((f) => ({
                fieldApiName: f.api_name,
                fieldId: f._sid,
                selected: true,
                // merge in any field options they have set for this field
                ...selectedColumnsMap.get(f._sid),
            })) ?? []
        )
    }

    return columns ?? []
}
