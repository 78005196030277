import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { AppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box, Divider, Flex, Icon, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export const AppNavItem = ({
    item,
    isTopLevel,
    label,
    isActive,
    url,
    navTheme,
    isFolder,
    onClick,
}) => {
    let linkUrl = getUrl(url || item.url)
    const {
        state: { key },
    } = useSlidingPane()
    const { selectedStack } = useContext(AppContext)
    const hideSelectedState = key && key !== 'edit-layout'
    const isHighlighted = !isFolder && isActive

    const handleClick = () => {
        onClick?.({
            item,
            isFolder,
        })
    }

    return (
        <Link
            style={{ width: '100%' }}
            to={`${linkUrl}`}
            className={`${ONBOARDING_CLASSES.APP_NAV_MENU_ITEM}`}
            onClick={handleClick}
        >
            <NavLink
                isActive={isActive && !hideSelectedState}
                isTopLevel={isTopLevel}
                className={isFolder ? 'top-level-folder-nav-item' : ''}
                navTheme={navTheme}
                isHighlighted={isHighlighted && !hideSelectedState}
            >
                {/*

                reverting this feature temporarily as we discovered emojiRegex also
                matches numbers as emojis, need to fix this before putting this back in

                <NavItemLabel label={label} navTheme={navTheme} />

                // */}
                {selectedStack?.options?.enable_nav_icons && (
                    <>
                        <Icon
                            iconPack="far"
                            icon={item?.options?.icon}
                            size="sm"
                            opacity={0.7}
                            style={{ width: '15px' }}
                        />
                        <Divider m={1} />
                    </>
                )}
                <Text noOfLines={1} color="inherit">
                    {label}
                </Text>
                <Box flexGrow={1} />
            </NavLink>
        </Link>
    )
}

export default AppNavItem

const NavLink = styled(Flex)`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    transition: 0.2s all ease-in-out;
    color: ${(props) =>
        props.isHighlighted
            ? props.navTheme.highlightColor
            : props.isActive
            ? props.navTheme.textBright
            : props.navTheme.text};
    max-width: 100%;
    margin: 1px ${(props) => (props.isTopLevel ? '5px' : '7px')};
    padding: ${(props) => (props.isTopLevel ? '6px 8px 4px 8px' : '6px 8px 4px 8px')};
    padding-left: 11px;
    font-size: 15px;
    font-weight: ${V4DesignSystem.label.fontWeight};

    border-radius: 5px;
    ${(props) =>
        props.isHighlighted &&
        `background-color: ${props.navTheme.highlightBg} !important;
    `}

    &:hover {
        color: ${(props) => !props.isHighlighted && props.navTheme.textBright};
    }

    &:hover .appNavMenuWrapper {
        visibility: visible;
    }
`
