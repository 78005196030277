export const buildSortingRequest = (
    sort: ChartSort | undefined,
    aggregationNames: string[],
    groupByKey?: string
) => {
    if (!sort) {
        return undefined
    }
    const baseSortingRequest = {
        order: sort.order,
    }
    if (sort.type === 'label' && groupByKey) {
        return {
            ...baseSortingRequest,
            sort_key: groupByKey,
        }
    } else {
        return {
            ...baseSortingRequest,
            aggregate: aggregationNames[0],
        }
    }
}
