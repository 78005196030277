import * as React from 'react'
import { useMemo } from 'react'

import { getCurrentStackId } from 'app/AppContextStore'
import { useFeatures } from 'data/hooks/features'
import { useNavigation } from 'data/hooks/navigation'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'

import { Dropdown } from 'v2/ui'

export default function DashboardDestinationPicker({ onChange: _onChange, value }) {
    const { data: features } = useFeatures()
    const { data: navigation } = useNavigation()
    const currentStackId = getCurrentStackId()

    const options = useMemo(() => {
        const items = buildNavTree(navigation)

        return items
            .filter(({ hidden }) => !hidden)
            .filter(({ stack_id }) => stack_id === currentStackId)
            .map((item) => ({
                label: 'Top Level > ' + item.label,
                value: item.object_id,
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation])

    return <Dropdown options={options} value={value} onChange={onChange} />

    function onChange(objectId) {
        const feature = features.find(({ options: { object_id } }) => object_id === objectId)

        _onChange({ objectId, featureId: feature._sid })
    }
}
