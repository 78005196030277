import { useLinkCollection } from 'data/hooks/links'
import { useObjects } from 'data/hooks/objects'

export function useUserProfileObjects(userObjectId) {
    const { getLinks } = useLinkCollection()
    const links = getLinks()
    const userProfilesObjectIds = links
        .filter((link) => link.source.objectId === userObjectId)
        .map((link) => link.target.objectId)
    const { data: allObjects = [] } = useObjects()
    const userProfileObjects = allObjects.filter((object) =>
        userProfilesObjectIds.includes(object._sid)
    )

    return userProfileObjects
}
