import React, { useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { withRoles } from 'data/wrappers/WithRoles'
import { withStack } from 'data/wrappers/WithStacks'
import { withUserTable } from 'data/wrappers/WithUserTable'
import Attribute from 'features/records/components/Attribute'
import { FormLabel, Input, Text } from 'legacy/v1/ui/index'

import { Button } from 'v2/ui'

export const PermissionsRolesPage = ({ roles, stackOptions, userTable, createRole, onSubmit }) => {
    const defaultState = {
        newRoleModalOpen: false,
        confirmModalError: false,
        confirmModalProcessing: false,
        newRoleLabel: '',
        newRoleValue: '',
        duplicateNameError: false,
        duplcateValueError: false,
    }

    const [state, setState] = useState(defaultState)

    const workspaceApp = stackOptions?.workspace_app
    const roleFieldId = get(stackOptions, 'data_mapping.role_field')

    const roleField = () => {
        if (!userTable || !userTable.fields.length) return
        return userTable.fields.find((field) => field._sid === roleFieldId)
    }

    const isNameDuplicate = (name) => {
        return roles.some((role) => role.label === name)
    }

    const isValueDuplicate = (value) => {
        return roles.some((role) => role.type_value === value)
    }

    return (
        <>
            <h3>Add a new role</h3>
            <FlexWrapper>
                <Label htmlFor="role-name">Name</Label>
                <Input
                    value={state.newRoleLabel}
                    onChange={(event) => setState({ ...state, newRoleLabel: event.target.value })}
                    id="role-name"
                    style={{ flex: 1 }}
                />
            </FlexWrapper>
            {!workspaceApp && (
                <FlexWrapper>
                    <Label htmlFor="role-value">Value</Label>
                    <Attribute
                        id="role-value"
                        editable
                        onChange={(value) => setState({ ...state, newRoleValue: value })}
                        field={roleField()}
                        style={{ flex: 1 }}
                    >
                        {state.newRoleValue}
                    </Attribute>
                </FlexWrapper>
            )}
            {state.confirmModalError && (
                <Text style={{ color: '#e74c3c' }}>
                    Sorry, there was an error creating this new role. Please try again.
                </Text>
            )}
            {state.duplicateNameError && (
                <Text style={{ color: '#e74c3c' }}>
                    A role already exists with this name. Please choose another name.
                </Text>
            )}
            {state.duplicateValueError && (
                <Text style={{ color: '#e74c3c' }}>
                    A role already exists with this value. Please choose another value.
                </Text>
            )}
            <Button
                variant="adminPrimary"
                buttonSize="sm"
                mt={4}
                onClick={() => {
                    setState({
                        ...state,
                        confirmModalError: false,
                        duplicateNameError: false,
                        duplicateValueError: false,
                        confirmModalProcessing: true,
                    })
                    if (isNameDuplicate(state.newRoleLabel)) {
                        setState({
                            ...state,
                            duplicateNameError: true,
                            confirmModalProcessing: false,
                        })
                        return
                    }
                    if (!workspaceApp && isValueDuplicate(state.newRoleValue)) {
                        setState({
                            ...state,
                            duplicateValueError: true,
                            confirmModalProcessing: false,
                        })
                        return
                    }
                    createRole({
                        label: state.newRoleLabel,
                        type_value: workspaceApp ? null : state.newRoleValue,
                        options: { type: workspaceApp ? 'internal' : null },
                    })
                        .then(onSubmit)
                        .catch((e) => {
                            Sentry.captureMessage(`Error adding a new role ${get(e, 'message')}`)
                            setState({
                                ...state,
                                confirmModalError: true,
                                confirmModalProcessing: false,
                            })
                        })
                }}
            >
                Confirm
            </Button>
        </>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const Label = styled(FormLabel)`
    text-align: left;
    margin-right: 20px;
    width: 60px;
`

PermissionsRolesPage.propTypes = {
    createRole: PropTypes.func.isRequired,
    userTable: PropTypes.object.isRequired,
    stackOptions: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
}

export default withStack(withUserTable(withRoles(PermissionsRolesPage)))
