import React from 'react'

import styled from '@emotion/styled'

import { Flex } from 'v2/ui'

import { ToolbarButton } from './ToolbarButton'

type SelectedRecordsToolbarProps = {
    onDeleteClick: () => void
}
const SelectedRecordsToolbar: React.FC<SelectedRecordsToolbarProps> = ({ onDeleteClick }) => {
    return (
        <Wrapper alignItems="center" wrap="nowrap" id="datagrid_buttonbar" pr={2}>
            <ToolbarButton
                icon="trash"
                onClick={onDeleteClick}
                data-testid="datagrid.delete.button"
                color="userInterface.error.600"
                iconColor="userInterface.error.600"
            >
                Delete selected records
            </ToolbarButton>
        </Wrapper>
    )
}
export default SelectedRecordsToolbar

const Wrapper = styled(Flex)`
    justify-content: flex-end;
    flex-direction: row;
    height: 44px;
`
