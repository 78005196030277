import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useDisclosure } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { WorkspaceUrls } from 'app/UrlService'
import useLDFlags from 'data/hooks/useLDFlags'

import { Button } from 'v2/ui'

import TemplateOrBlankAppModal from '../templates/TemplateOrBlankAppModal'

import CreateAppModal from './CreateAppModal'

type NewAppButtonProps = {
    as: FC<{ onClick: () => void }>
}
export const NewAppButton: FC<NewAppButtonProps> = ({ as, ...props }) => {
    const history = useHistory()
    const newApp = useDisclosure()
    const templateOrBlankModal = useDisclosure()

    const { flags } = useLDFlags()

    const { workspaceAccount } = useAppContext()

    const nativeTablesEnabled = workspaceAccount?.optional_features?.stacker_native_tables

    const preselectedDataSourceType = nativeTablesEnabled ? null : 'airtable'
    const Component = as || Button
    const isTemplatesLocked = !flags.templatesEnabled
    const onButtonClick = useCallback(() => {
        if (isTemplatesLocked) {
            newApp.onOpen()
        } else {
            templateOrBlankModal.onOpen()
        }
    }, [isTemplatesLocked, newApp, templateOrBlankModal])

    const openTemplateLibrary = useCallback(() => {
        history.push(WorkspaceUrls.TemplateLibrary)
    }, [history])

    return (
        <>
            <Component {...props} onClick={onButtonClick} />
            <TemplateOrBlankAppModal
                isOpen={templateOrBlankModal.isOpen}
                onClose={templateOrBlankModal.onClose}
                createBlankApp={() => {
                    newApp.onOpen()
                    templateOrBlankModal.onClose()
                }}
                selectTemplate={openTemplateLibrary}
            />
            <CreateAppModal
                isOpen={newApp.isOpen}
                onClose={newApp.onClose}
                preselectedDataSourceType={preselectedDataSourceType}
            />
        </>
    )
}
export default NewAppButton
