/*
This wrapper provides auto-positioning for custom editors.
It prevents UI overflow and flips to the left and/or top where the UI is displayed.

When the prop "ignoreEnterKey" prevents the default behaviour that closes the editor.
It is used for example for the rich text editor to enable multi-line text.
*/
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'

const BODY_MARGIN = 20
const CELL_HEIGHT = 36

const Wrapper = styled.div<{ x: number; y: number; width?: number; minWidth?: number }>`
    background-color: white;

    margin-left: ${(props) => props.x}px;

    ${(props) => (props.minWidth ? `min-width: ${props.minWidth}px;` : '')}
    ${(props) => (props.width ? `width: ${props.width}px;` : 'width: fit-content;')}

    border-radius: 2px;
    box-shadow: 0 0 0 1px #4f5dff, 0px 0px 1px rgb(62 65 86 / 40%), 0px 6px 12px rgb(62 65 86 / 15%);
`

type Props = {
    width?: number
    minWidth?: number
    ignoreEnterKey?: boolean
}

const EditorWrapper: FC<Props> = ({ width, minWidth, ignoreEnterKey, children }) => {
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)

    const wrapperRef = useRef<HTMLDivElement>(null)

    const updatePosition = useCallback((): void => {
        if (!wrapperRef.current) {
            return
        }

        const clipRegion = document.getElementsByClassName('clip-region')[0]
        if (!clipRegion) {
            return
        }

        const wrapperRect = wrapperRef.current.getBoundingClientRect()
        const bodyRect = document.body.getBoundingClientRect()
        const clipRegionRect = clipRegion.getBoundingClientRect()

        if (x === 0 && wrapperRect.x + wrapperRect.width > bodyRect.width - BODY_MARGIN) {
            setX(-wrapperRect.width + clipRegionRect.width)
        } else if (x < 0 && wrapperRect.x + wrapperRect.width - x <= bodyRect.width - BODY_MARGIN) {
            setX(0)
        }

        if (y === 0 && wrapperRect.y + wrapperRect.height > bodyRect.height - BODY_MARGIN) {
            setY(-wrapperRect.height + CELL_HEIGHT)
        } else if (
            y < 0 &&
            wrapperRect.y + wrapperRect.height - y <= bodyRect.height - BODY_MARGIN
        ) {
            setY(0)
        }
    }, [x, y])

    useEffect(() => {
        updatePosition()

        window.addEventListener('resize', updatePosition)

        return () => {
            window.removeEventListener('resize', updatePosition)
        }
    }, [updatePosition])

    useEffect(() => {
        if (!ignoreEnterKey || !wrapperRef.current) {
            return
        }

        wrapperRef.current.onkeydown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault()
                event.stopPropagation()
            }
        }
    }, [ignoreEnterKey])

    useEffect(() => {
        wrapperRef.current?.focus()
    }, [])

    return (
        <Wrapper ref={wrapperRef} x={x} y={y} width={width} minWidth={minWidth} tabIndex={0}>
            {children}
        </Wrapper>
    )
}

export default EditorWrapper
