import React, { FC } from 'react'

import { ConfigureWidgetPlaceholder } from 'v2/ui'
import { SolidMetrics } from 'v2/ui/svgs'

export const MetricsBlockEmptyState: FC = () => (
    <ConfigureWidgetPlaceholder
        message="Big Numbers"
        subtitle="Display summaries of your data"
        Icon={() => <SolidMetrics size={32} />}
    />
)
