import * as React from 'react'
import { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'

import AppContext from 'app/AppContext'
import { _transformOldConfigFormatToNew } from 'data/hooks/metrics/legacyMetrics'

import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import RenderDashboard, { renderDashboardContainerStyles } from './RenderDashboardFromView'

// @TODO see comments on _transformOldConfigFormatToNew
// delete when no longer needed for dev
const _transformExistingChartsConfigs = (config) => {
    if (config.charts) {
        const newCharts = {}
        Object.entries(config.charts).forEach(([key, value]) => {
            newCharts[key] = {
                ...value,
                config: _transformOldConfigFormatToNew(value.config),
            }
        })
        config.charts = newCharts
    }

    return config
}

export default function DashboardWidget({
    view,
    onChange: _onChange,
    viewContext = 'unknown',
    filters,
}) {
    const isEditing = useSelector((state) => state.user.isEditing)
    const { selectedStack: stack } = useContext(AppContext)
    const chartsEnabled = !isFeatureLocked(FEATURES.charts, stack)

    // no charts to display
    const chartCounts =
        (view?.options?.dashboard || 0) && Object.entries(view.options.dashboard.charts).length

    const Container = useCallback(
        ({ children }) => (
            <div
                style={{
                    ...renderDashboardContainerStyles,
                }}
            >
                {children}
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEditing, chartCounts]
    )

    if (!chartsEnabled) return null
    if (!view.options.enableCharts) return null

    if (!view.options.dashboard) {
        view.options.dashboard = { ...EMPTY_CONFIG }
    }

    /** @type {CHARTS_CONFIG} */
    const config = view.options.dashboard

    if (!isEditing && chartCounts === 0) {
        return null
    }

    function onChange(layouts, currentBreakpoint) {
        if (!isEditing) {
            return null
        }

        return _onChange({
            dashboard: {
                ..._transformExistingChartsConfigs(view.options.dashboard),
                layouts: {
                    ...view.options.dashboard?.layouts,
                    [currentBreakpoint]: layouts[currentBreakpoint],
                },
            },
        })
    }

    return (
        <RenderDashboard
            config={config}
            save={() => {}}
            onChange={onChange}
            allowEdits={isEditing}
            mainObjectId={view.object_id}
            viewId={view._sid}
            container={Container}
            viewContext={viewContext}
            viewFilters={filters}
        />
    )
}

/** @type {CHARTS_CONFIG} */
const EMPTY_CONFIG = {
    charts: {},
    layouts: { md: [], xs: [], sm: [] },
}
