import React, { FC } from 'react'

import { Dropdown } from 'v2/ui'

import type { AdminFilterProps } from './types'

export const FilterUserRole: FC<AdminFilterProps> = ({
    value,
    field,
    multi,
    roles,
    editMode = true,
    ...props
}) => {
    // If we're not in edit mode, then just display the selected values
    // not the input control
    if (!editMode) {
        return <>{roles?.find((role) => role._sid === value)?.label}</>
    }

    return (
        <Dropdown
            options={roles?.map((role) => ({
                value: role._sid,
                label: role.label,
            }))}
            isMulti={field?.type === 'multi_select' || multi}
            style={{ flex: 1 }}
            value={value}
            {...props}
        />
    )
}
