import React from 'react'

import Button from './Button'
import Icon from './Icon'
import PreviewBackground, { PreviewBackgroundProps } from './PreviewBackground'

type LogoPreviewImgProps = {
    url: string
    showRemove?: boolean
    onRemove?: () => void
}

type RemoveButtonPros = {
    onRemove: () => void
    additionalStyle: any
}

const RemoveButton = ({ onRemove, additionalStyle }: RemoveButtonPros) => {
    return (
        <Button
            onClick={onRemove}
            bg="neutral.900"
            width="14px"
            height="14px"
            _hover={{
                background: 'neutral.1100',
            }}
            style={{
                padding: 0,
                borderRadius: '3px',
                ...additionalStyle,
            }}
        >
            <Icon size="8px" icon="x" />
        </Button>
    )
}

export const LogoPreviewImg = (props: LogoPreviewImgProps) => {
    return <img alt="logo" src={props.url} style={{ height: 30 }} />
}

const LogoPreview = (props: LogoPreviewImgProps & PreviewBackgroundProps) => {
    const { url, onRemove, showRemove, ...backgroundProps } = props
    return (
        <PreviewBackground {...backgroundProps}>
            <LogoPreviewImg url={url} />
            {showRemove && (
                <RemoveButton
                    additionalStyle={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                    }}
                    onRemove={() => {
                        onRemove && onRemove()
                    }}
                />
            )}
        </PreviewBackground>
    )
}

export default LogoPreview
