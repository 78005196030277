import { queryClient } from 'data/hooks/_helpers'
import { invalidateAggregates } from 'data/hooks/aggregates'
import { invalidateObjectCache } from 'data/hooks/cacheInfo/cacheInfoOperations'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateMetrics } from 'data/hooks/metrics/legacyMetrics'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { invalidatePages } from 'data/hooks/pages'
import { invalidateStacks } from 'data/hooks/stacks/stackOperations'
import { invalidateViews } from 'data/hooks/views'
import { REACT_QUERY } from 'data/utils/constants'

export const reloadMetadataAndDataAfterInstall = async () => {
    // After adding a new object, there is lots of metadata to be refreshed.
    invalidateObjects()
    await Promise.all([
        queryClient.invalidateQueries(REACT_QUERY.roles.listName),
        invalidateFeatures(),
        invalidateViews(),
        invalidateStacks(),
        invalidateNavigation(),
        invalidateMetrics(),
        invalidateAggregates(),
        invalidateObjectCache(),
        invalidatePages(),
    ])
}
