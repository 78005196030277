import { useMemo } from 'react'
import { notifyManager, useQuery as _useQuery } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { SERVER_PAGE_SIZE } from 'app/settings'
import { recordApi } from 'data/api/recordApi'
import useLDFlags from 'data/hooks/useLDFlags'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'

import { getCurrentUserRecordId } from '../wrappers/withUserUtils'

import { getCachedRecord } from './records/getCachedRecord'
import { getRecordQueryFunction } from './records/getRecordQueryFunction'
import {
    RECORD_ENDPOINT,
    RECORD_LIST_QUERY_CONFIG,
    RECORD_QUERY_CONFIG,
} from './records/recordConstants'
import { invalidateAppUsersList } from './users/invalidateAppUsersList'
import { useUserRecord } from './users/main'
import { queryCache, queryClient, useUpdateItem } from './_helpers'

/**
 * Fetches records from the server for a given object + filters.
 * @param {any | undefined} fetchOptions
 * @param {import('react-query').UseQueryOptions } options
 */
export function useRecords({
    objectId,
    filters = undefined,
    fetchOptions = undefined,
    // until we are live resetting cache on notification from BE, we want to refetch on mount
    // otherwise the cache will go stale and never refresh
    options = { refetchOnMount: 'always' },
    internalOptions = {},
    schemaTimestamp = undefined,
}) {
    const { flags } = useLDFlags()
    const patchedOptions = {
        ...options,
        refetchOnMount: flags.recordsRefetchOnMountOff ? false : options.refetchOnMount,
    }
    const serializableFilters = filters?.map((filter) => ({ ...filter, _id: undefined }))
    const queryKey = ['records', objectId, serializableFilters, fetchOptions, schemaTimestamp]

    const { selectedStack } = useAppContext()
    const objectsToWatch = useMemo(() => {
        return [...(internalOptions.lookupObjects || []), objectId]
    }, [internalOptions.lookupObjects, objectId])

    const invalidateQueriesWithTrigger = (invalidateKey, trigger) => {
        const filters = {
            active: true,
            inactive: false,
            queryKey: invalidateKey,
        }

        if (!internalOptions?.disableRealtimeUpdates) {
            // invalidate all queries and set the trigger if provided so we can later send that to the API
            // this is then used in metrics to track how many API requests are caused by realtime updates
            return notifyManager.batch(() => {
                queryCache.findAll(filters).forEach((query) => {
                    query.setState({
                        _trigger: trigger,
                    })
                    query.invalidate()
                })

                // If tab is not visible/active, we mark as stale but do not refetch
                // Stale queries when be refetched when window comes back into focus
                if (document.visibilityState === 'visible') {
                    return queryClient.refetchQueries(filters)
                }
            })
        }
    }

    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: objectsToWatch,
        handler: (eventName) => {
            invalidateQueriesWithTrigger(['records', objectId], 'realtime_updates_' + eventName)
        },
    })

    // user record is required to properly transform user filters
    const { isFetched: isUserFetched } = useUserRecord()
    const currentUserRecordId = getCurrentUserRecordId()
    const query_options = {
        ...RECORD_LIST_QUERY_CONFIG,
        ...patchedOptions,
        enabled: (!currentUserRecordId || !!isUserFetched) && options.enabled !== false,
    }

    if (!internalOptions?.disableRealtimeUpdates) {
        query_options.refetchOnWindowFocus = true
    }

    return _useQuery(
        queryKey,
        (queryContext) => {
            const query = queryCache.find(queryContext.queryKey)

            // This option allows us to disable this query completely
            if (
                patchedOptions.disabled ||
                (patchedOptions.disabledFn && patchedOptions.disabledFn()) ||
                !objectId
            ) {
                return Promise.resolve(
                    patchedOptions.disabledValue !== undefined ? patchedOptions.disabledValue : []
                )
            }

            return recordApi
                .getObjectRecords(objectId, filters, {
                    ...fetchOptions,
                    trigger: query.state._trigger,
                })
                .then((response) => {
                    // We also set the data for individual record queries. This means that we can still dereference
                    let records = response.records

                    const dereferencedRecords = records
                        .filter((record) => record._dereferenced)
                        .map((r) => ({ ...r, _dereferencedFrom: objectId }))
                    // filter out dereferenced records
                    records = records.filter(
                        (record) => record._object_id === objectId && !record._dereferenced
                    )

                    return {
                        records,
                        dereferencedRecords,
                        resultInfo: response.result_info,
                    }
                })
                .finally(() => {
                    // consume the trigger so subsequent requests don't use the same trigger param
                    // we only want trigger to be sent once on the request immediately after invalidation
                    if (query.state._trigger) {
                        query.setState({ _trigger: null })
                    }
                })
        },
        query_options
    )
}

/**
 * Fetches the specified record Ids from the server. Breaks the request up into separate
 * queries as needed to fit within SERVER_PAGE_SIZE
 * @param {any | undefined} fetchOptions
 * @param {import('react-query').UseQueryOptions } options
 */
export function useRecordsByIds({
    objectId,
    recordIds,
    filters = undefined,
    fetchOptions = undefined,
    options = undefined,
}) {
    const queries = []
    let values = recordIds
    while (values.length > 0) {
        let idsToFetch = values.slice(0, SERVER_PAGE_SIZE)
        const queryFilters = [
            ...(filters || []),
            {
                field: {
                    api_name: '_sid',
                },
                options: {
                    value: idsToFetch,
                    option: 'oneOf',
                    operator: 'AND',
                },
            },
        ]
        queries.push(queryFilters)
        // chunk by page size just in case there are lots of values selected
        values = values.slice(SERVER_PAGE_SIZE)
    }

    const query_options = { ...RECORD_LIST_QUERY_CONFIG, ...options }
    const serializableFilters = filters?.map((filter) => ({ ...filter, _id: undefined }))
    return _useQuery(
        ['records', objectId, recordIds, serializableFilters, fetchOptions],
        () => {
            // This option allows us to disable this query completely
            if (options.disabled || (options.disabledFn && options.disabledFn()) || !objectId) {
                return Promise.resolve(
                    options.disabledValue !== undefined ? options.disabledValue : []
                )
            }

            const promises = queries.map((filters) =>
                recordApi.getObjectRecords(objectId, filters, fetchOptions).then((response) => {
                    let records = response.records
                    // filter out dereferenced records
                    records = records.filter(
                        (record) => record._object_id === objectId && !record._dereferenced
                    )
                    return records
                })
            )
            // wait for all queries to return, then combine the results and return
            return Promise.all(promises).then((values) => {
                return { records: values.reduce((combined, value) => [...combined, ...value], []) }
            })
        },
        query_options
    )
}

/**
 * Fetches a record from the server
 * @param {any | undefined} fetchOptions
 * @param {import('react-query').UseQueryOptions } options
 */
export function useGetRecord({ recordId, options = {} }) {
    const queryKey = ['record', recordId]

    return _useQuery(
        queryKey,
        () => {
            if (!recordId) return Promise.resolve(undefined)
            return getRecordQueryFunction(recordId, options)
        },
        RECORD_QUERY_CONFIG
    )
}

/**
 * Update record query hook
 * @param {string} objectId
 */
export function useUpdateRecord(objectId) {
    return useUpdateItem(['records', objectId], RECORD_ENDPOINT, {
        onSuccess: (data) => {
            if (data?.[0]?._reload_app_users) {
                invalidateAppUsersList()
            }
        },
    })
}

/**
 * Get a list of partial linked records for a field
 * @param {any} field
 * @param {string} parentRecordId
 * @param {string | array} parentRecordId
 */
export function useGetFieldRecords({
    field,
    parentRecordId,
    value,
    dereferencedRecords = [],
    bypassPreviewAndImpersonation,
}) {
    // We do an api fetch for all records in the field, this means that we won't be doing lots of
    // requests to get each individual record
    let invalid = false
    if (!value || !field) invalid = true
    const recordFieldFilter = useMemo(() => {
        if (field.type == 'multi_lookup') {
            if (parentRecordId) {
                return [
                    {
                        field: { api_name: '_sid' }, // A fake field object for the SID "field".
                        options: {
                            value: `${parentRecordId}___${field.api_name}`,
                            option: 'appearsIn',
                            operator: 'AND',
                        },
                    },
                ]
            }

            return [
                {
                    field: {
                        api_name: '_sid',
                    },
                    options: {
                        value,
                        option: 'oneOf',
                        operator: 'AND',
                    },
                },
            ]
        } else {
            return [
                {
                    field: { api_name: '_sid' },
                    options: {
                        value: value,
                        option: '',
                        operator: 'AND',
                    },
                },
            ]
        }
    }, [value, parentRecordId, field.api_name, field.type])

    const fetchOptions = useMemo(() => {
        return { includeFields: ['_primary'], bypassPreviewAndImpersonation }
    }, [bypassPreviewAndImpersonation])

    const recordValues = Array.isArray(value) ? value : [value]

    let cachedRecords = []
    recordValues.forEach((recId) => {
        // If we have dereferenced records, then check there first
        let cachedRecord = dereferencedRecords.find((r) => r._sid === recId)
        // Check the record query cache otherwise
        if (!cachedRecord) getCachedRecord(recId)
        if (cachedRecord) {
            cachedRecords.push(cachedRecord)
        }
    })

    const haveCachedRecords = cachedRecords.length === recordValues.length

    const options = useMemo(() => {
        return { enabled: !invalid && !haveCachedRecords }
    }, [invalid, haveCachedRecords])

    const {
        isLoading,
        data: { records = [] } = {},
        isError,
        isSuccess,
    } = useRecords({
        objectId: field.options?.lookup_target,
        filters: recordFieldFilter,
        fetchOptions,
        options,
    })

    // Check if we already have the cached version first
    if (haveCachedRecords) {
        return { isLoading: false, records: cachedRecords }
    }

    return { isLoading: isLoading || (!isSuccess && !isError && options?.enabled), records }
}
