import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { getUserApiToken, Token } from 'data/utils/getUserToken'

import { getOAuthBounceUrl } from './utils/getOAuthBounceUrl'
import { InstructionsModal } from './InstructionsModal'

export type UseOAuthFlowResult = {
    /**
     * Starts the authentication flow.
     */
    onStart: () => void

    /**
     * Modal contents. This should be rendered somewhere in react tree.
     */
    modal: ReactElement

    /**
     * If the oauth flow is opened and showing some content.
     */
    isOpen: boolean
}

/**
 * Manages the Airtable OAuth flow.
 *
 * Shows a modal with instructions before redirecting the user to Airtable.
 *
 * @param returnUrl where user should be sent after successful oauth flow
 * @param dataConnectionSid the target data connection SID.
 * @param shouldOverwriteToken indicates whether we should overwrite a user's existing token (default: false)
 * @param onCancel callback to run once the OAuth flow is cancelled
 */
export const useOAuthFlow = ({
    dataConnectionSid,
    onCancel,
    returnUrl,
    shouldOverwriteToken = false,
}: {
    dataConnectionSid?: string
    onCancel?: () => void
    returnUrl?: string
    shouldOverwriteToken?: boolean
}): UseOAuthFlowResult => {
    const [isOpen, setIsOpen] = useState(false)
    const { user } = useAppUserContext()
    const [apiToken, setApiToken] = useState<Token | undefined>(undefined)

    const onStart = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    useEffect(() => {
        getUserApiToken(user).then((token) => {
            setApiToken(token)
        })
    }, [user])

    const { data: dataConnection } = useDataConnection(dataConnectionSid)

    const bounceUrl =
        dataConnection && apiToken && returnUrl
            ? getOAuthBounceUrl({
                  dataConnection: dataConnection,
                  apiToken: apiToken,
                  returnUrl,
                  shouldOverwriteToken,
              })
            : undefined

    const modalContent = (
        <InstructionsModal
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false)
                onCancel?.()
            }}
            bounceUrl={bounceUrl}
        />
    )

    return {
        onStart,
        modal: modalContent,
        isOpen,
    }
}
