const emptyPageHeaderDefault: PageHeaderDefault = {
    type: 'default',
    title: '',
    subtitle: '',
}

const emptyPageHeaderImage: PageHeaderImage = {
    type: 'image',
    title: '',
    subtitle: '',
    size: 'small',
    colorMode: 'dark',
    showOverlay: true,
    showBackgroundColor: true,
    position: 'center',
    src: '',
}

const emptyPageHeaderModular: PageHeaderModular = {
    type: 'modular',
    title: '',
    subtitle: '',
    collapsibleWidgetsType: undefined,
    collapsibleWidgets: [],
}

export function getDefaultedPageHeader(pageHeader?: PageHeader): PageHeader {
    if (pageHeader?.type === 'image') {
        const defaultedHeader = { ...emptyPageHeaderImage, ...pageHeader }
        if (!defaultedHeader.src) {
            defaultedHeader.colorMode = emptyPageHeaderImage.colorMode
        }

        return defaultedHeader
    }

    if (pageHeader?.type === 'modular') {
        const defaultedHeader = { ...emptyPageHeaderModular, ...pageHeader }
        return defaultedHeader
    }

    return {
        ...emptyPageHeaderDefault,
        ...pageHeader,
    }
}
