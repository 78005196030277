import merge from 'lodash/merge'

/**
 * Merges the new and current record
 * @param {object} record
 * @param {object} currentRecord
 */

export const getMergedRecord = (record: RecordDto, currentRecord: RecordDto) => {
    const partial = !!record._partial && (!currentRecord || !!currentRecord?._partial)
    const updatedRecord = !!record._partial
        ? merge(currentRecord, record, { _partial: partial })
        : record
    return updatedRecord
}
