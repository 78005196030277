import React, { useEffect, useState } from 'react'

import { Urls } from 'app/UrlService'
import { closeExpiredSessionModal, expiredSessionModalSubscribers } from 'utils/modalOpeners'

import { Button, Flex, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

const SessionExpiredModal = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        expiredSessionModalSubscribers.push(setIsOpen)
    }, [])

    const handleLogin = () => {
        closeExpiredSessionModal()
        window.location.assign(`${window.location.origin}${Urls.Logout}`)
    }

    return (
        <SimpleModalCompat
            isOpen={isOpen}
            onClose={closeExpiredSessionModal}
            title="Session Expired"
            height="auto"
            size="400px"
            zIndex={9999}
        >
            <Flex column align="stretch" p={4}>
                <Text mb={4}>Your session has expired. Please click below to login again.</Text>
                <Flex justifyContent="space-between">
                    <Button
                        variant="Primary"
                        buttonSize="large"
                        width="160px"
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                    <Button
                        variant="Secondary"
                        buttonSize="large"
                        width="160px"
                        onClick={closeExpiredSessionModal}
                    >
                        Cancel
                    </Button>
                </Flex>
            </Flex>
        </SimpleModalCompat>
    )
}
export default SessionExpiredModal
