import type { Config } from '../types'

const lineHeights = (config: Config) => {
    const { lineHeights } = config

    const data = {
        text: {
            200: {
                lh: lineHeights.xxl,
                lhLg: lineHeights.xxxl,
            },
        },
        widgetContainer: {
            description: lineHeights.xxl,
        },
    }

    return data
}

export default lineHeights
