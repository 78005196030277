/* Code Quality: Not audited */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StreamApp as StreamAppBase } from 'react-activity-feed'
import { connect, useDispatch } from 'react-redux'

import { bindActionCreators } from 'redux'

import { AppUserContext, useAppUserContext } from 'app/AppUserContext'
import { STREAM_API_KEY, STREAM_APP_ID } from 'app/settings'
import { userActions } from 'data/api/userApi'
import { withStack } from 'data/wrappers/WithStacks'

import { isCollaborationEnabled } from 'v2/ui/utils/ProtectedFeature'

export const StreamAppContext = React.createContext({})

export function getCollaborationUser(user, studioUser) {
    const isImpersonating = studioUser?._sid !== user?._sid

    // If we have a studio user and we're not impersonating, or we're impersonating
    // an internal user (and thus we are in a workspace), then the studio user should be used
    // for collaboration. Otherwise, the "end user" (might be impersonating a v3 end user)
    const result = studioUser && (!isImpersonating || !user?._object_id) ? studioUser : user
    // if we have an object, but no sid, then this isn't a valid authed user
    if (!result?._sid) {
        return null
    }

    return result
}

// This  will decode the stream_user_token and will return a json with the user_id
// eg: {user_id: "user_Gz4Ndl"}
export function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

const SHARED_FEEDS = [{ feedGroup: 'notification', notify: true, options: { mark_seen: true } }]

export const useGetStreamToken = ({ userOverride, maxRetries = 10 }) => {
    const totalRetries = useRef(0)
    const dispatch = useDispatch()
    const boundUserActions = bindActionCreators(userActions, dispatch)
    const { user, studioUser } = useContext(AppUserContext)
    const sourceUser = userOverride || getCollaborationUser(user, studioUser)
    const isFetching = useRef(false)

    const getStreamToken = (force) => {
        if (isFetching.current) return
        isFetching.current = true
        // If force isn't set set the total number of retries so that we
        // don't get stuck in a loop
        if (force || totalRetries.current < maxRetries) {
            if (!force) {
                totalRetries.current++
            }
            boundUserActions.getStreamToken(sourceUser).finally(() => {
                if (isFetching) {
                    isFetching.current = false
                }
            })
        }
        return
    }

    const resetTokenRetries = () => {
        totalRetries.current = 0
    }

    return { getStreamToken, resetTokenRetries }
}

const getTokenRenewAtTime = (token) => {
    if (token) {
        const tokenParts = parseJwt(token)
        // renew an hour before
        const renewAt = (tokenParts.exp - 3600 - new Date().getTime() / 1000) * 1000
        return renewAt
    }
    return false
}

/** This wrapper should be put near the root of the app.
 * Once we have an authenticated user, and if the stack setting has been turned on,
 * if we don't already have a stream_user_token on the user object, then we will fetch a new one.
 *
 * It also handles renewing the token before expiration.
 */
export const StreamAppInternal = ({ user: endUser, studioUser, children, stack }) => {
    const { isStudioUser } = useAppUserContext()
    const user = getCollaborationUser(endUser, studioUser)
    const [streamToken, setStreamToken] = useState(user?.stream_user_token)
    const userRef = useRef()
    const timerRef = useRef()
    const enableComments = isCollaborationEnabled(stack)
    const { getStreamToken, resetTokenRetries } = useGetStreamToken({
        userOverride: userRef.current,
    })

    const isCurrentTokenValid = useCallback(() => {
        return getTokenRenewAtTime(streamToken) > 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTokenRenewAtTime, streamToken])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processToken = (token) => {
        if (!token) return

        const renewAt = getTokenRenewAtTime(token)
        const tokenValid = renewAt > 0

        if (!tokenValid) {
            //This means the token has already expired,
            //so we should get a new one now
            getStreamToken()
        } else {
            if (token !== streamToken) setStreamToken(token)

            // auto renew the stream token
            clearTimerRef()
            timerRef.current = setTimeout(() => {
                getStreamToken()
            }, renewAt)

            // reset the retries as we have a valid token
            resetTokenRetries()
        }
    }

    const clearTimerRef = () => {
        if (timerRef?.current) {
            clearTimeout(timerRef.current)
            timerRef.current = undefined
        }
    }

    useEffect(() => {
        // setup the initial auto-renewal
        if (streamToken) {
            processToken(streamToken)
        }
        return () => {
            clearTimerRef()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // If the user auth is changing, then we need to update our stream user token
    useEffect(() => {
        // If we should be enabled and we have at least one authed user
        const sourceUser = user

        // is from customer access or is a guest
        const isEndUser = !isStudioUser || sourceUser?.membership_options?.role === 'guest'

        // If we have a sourceUser, we check the following 2 cases.
        // If either 1 of these cases is true, we get stream token. Else, we set stream token to null.
        // Case 1: User is from customer access AND collaboration is enabled.
        // Case 2: User is NOT from customer access which means user is in a workspace.
        //    Note: We show the notification bell in a workspace even if collaboration is turned off.
        //          This is because notification bell show errors of ALL apps.
        if (sourceUser?._sid && ((isEndUser && enableComments) || !isEndUser)) {
            userRef.current = sourceUser
            // And that user doesn't have a stream token, get one
            if (!sourceUser.stream_user_token) {
                getStreamToken()
                // Or if the token has changed, then process that change
            } else if (sourceUser.stream_user_token !== streamToken) {
                processToken(sourceUser.stream_user_token)
            }
        } else {
            setStreamToken(null)
        }
    }, [user, enableComments, isStudioUser, getStreamToken, processToken, streamToken])

    const context = useMemo(
        () => ({ getStreamToken, isCurrentTokenValid, streamToken }),
        [getStreamToken, isCurrentTokenValid, streamToken]
    )

    if (!streamToken) {
        return children
    }

    const onError = (e) => {
        // Token expired
        if (e?.error_code === 403 && e?.code === 17) {
            getStreamToken()
        }
    }

    return (
        <StreamAppBase
            apiKey={STREAM_API_KEY}
            appId={STREAM_APP_ID}
            token={streamToken}
            sharedFeeds={SHARED_FEEDS}
            errorHandler={onError}
        >
            <StreamAppContext.Provider value={context}>{children}</StreamAppContext.Provider>
        </StreamAppBase>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        studioUser: state.user.studioUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export const StreamApp = connect(mapStateToProps, mapDispatchToProps)(withStack(StreamAppInternal))

export const useStreamAppContext = () => {
    return useContext(StreamAppContext)
}

export default StreamApp
