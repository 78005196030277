import isEqual from 'react-fast-compare'
import ContentEditable from 'react-sane-contenteditable'

const reduceTargetKeys = (target, keys, predicate) => Object.keys(target).reduce(predicate, {})

const pick = (target = {}, keys = []) =>
    reduceTargetKeys(target, keys, (acc, key) =>
        keys.some((pickKey) => pickKey === key) ? { ...acc, [key]: target[key] } : acc
    )

export default class UpdatingContentEditableComp extends ContentEditable {
    shouldComponentUpdate(nextProps) {
        const propKeys = Object.keys(nextProps)
        return !isEqual(pick(nextProps, propKeys), pick(this.props, propKeys))
    }
}
