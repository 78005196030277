import { isImpersonatingEndUser } from 'data/utils/isAdmin'

import { getWorkspaceAccount } from './AppContextStore'

export function getStackTheme(stack) {
    const { options, account_theme } = stack || {}
    const workspaceAccount = getWorkspaceAccount()

    // if we're not in a workspace, or we're impersonating an external user,
    // we want to merge any external_access
    // theme settings into our theme, as external users get different
    // theme settings than internal users.
    const theme =
        (workspaceAccount && !isImpersonatingEndUser()
            ? { ...options?.theme }
            : {
                  ...options?.theme,
                  ...options?.external_access,
              }) || {}

    theme.navColor = theme.navColor ?? account_theme?.navColor ?? 'dark'
    theme.brandColor = options?.theme?.brandColor || account_theme?.brandColor
    // Unless the stack was migrated from use the account theme's icon if none specified for the app
    if (!theme.logo_icon && !stack?.options?.migrated_from_v3) {
        theme.logo_icon = account_theme?.logo_icon
    }
    return theme
}
