/* Code Quality: OK */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import isUrl from 'is-url'
import PropTypes from 'prop-types'
import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { withUser } from 'data/wrappers/WithUser'
import { SupertokensSSOLoginScreen } from 'features/supertokens/SupertokensSSOLoginScreen'
import { useSupertokensSsoWithRedirect } from 'features/supertokens/useSupertokensSsoWithRedirect'

import { LoadingSplash } from 'v2/ui'

import Auth0LoginScreen from './stacker-auth/Auth0LoginScreen'
import AuthPageFrame from './stacker-auth/AuthPageFrame'
import SignInFlow from './stacker-auth/SignInFlow'
import { useAuth0Redirect } from './useAuth0Redirect'
function getRedirect() {
    let redirect = null
    const searchString = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })
    if (searchString && searchString.redirect) {
        redirect = decodeURI(searchString.redirect)
    }

    return isUrl(redirect) && redirect
}

function useRedirectAuth0Org(query) {
    const [organization, setOrganisation] = useState(query?.organization)

    useEffect(() => {
        if (!organization) return

        fetch(`${settings.API_ROOT}account-from-organization/${organization}/`)
            .then((response) => {
                return response.json()
            })
            .then(({ account_hostname }) => {
                if (account_hostname) {
                    window.location = `https://${account_hostname}/login`
                } else {
                    setOrganisation(null)
                }
            })
            .catch(() => {
                // We show the default login page if the organization is not found
                setOrganisation(null)
            })
    }, [organization])

    return !!organization
}

export function LoginPage(props) {
    const { workspaceAccount } = useAppContext()
    const redirectToAuth0 = useAuth0Redirect()

    const [redirecting, setRedirecting] = useState(false)
    const history = useHistory()
    const isAuth0 = workspaceAccount && workspaceAccount.optional_features.auth0_enabled
    const isSupertokensSSO = workspaceAccount && workspaceAccount.supertokens_sso_enabled
    const showAuth0Screen =
        workspaceAccount && isAuth0 && workspaceAccount.optional_features.auth0_show_login_page
    const { isAuthenticated: isAuth0Authenticated, isLoading: auth0IsLoading } = useAuth0()
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })
    const { isRedirecting: isRedirectingToSupertokens, isLoading: isSupertokensLoading } =
        useSupertokensSsoWithRedirect(workspaceAccount)

    const redirectingToAuth0Org = useRedirectAuth0Org(query)

    useEffect(() => {
        // let's wait until auth0IsLoading is loaded
        if (auth0IsLoading || redirectingToAuth0Org) {
            return
        }
        // Redirect straigh to auth0 if we're not authenticated,
        /// and we're in an auth0 account, and the account isn't configured
        // to show our own login page, or we have a prompt=none query param in which case, we should
        // send that on to the auth0 url

        if (isAuth0 && !isAuth0Authenticated && (!showAuth0Screen || query.prompt === 'none')) {
            redirectToAuth0({ prompt: query.prompt === 'none' ? 'none' : undefined })
            setRedirecting(true)
        }

        // The check_user param is used when redirecting from another workspace and we want
        // to check that the authenticated user (if any) on this domain is the specified user.
        // If it is, then we just redirect to home. If not, we show the login page with the
        // "you are logged in as..." message.
        if (query.check_user && query.check_user === props.user?._sid) {
            setRedirecting(true)
            history.push(getUrl(Urls.Home))
        }

        const redirect = getRedirect()
        const studioToken = localStorage.getItem('studio_token')
        if (redirect && studioToken) {
            props.userActions
                .requestStudioTemporaryAuthToken(studioToken, redirect)
                .then((response) => {
                    if (response.redirect) {
                        const joiner = response.redirect.indexOf('?') === -1 ? '?' : '&'
                        window.location = `${response.redirect}${joiner}auth_token=${response.auth_token}`
                    }
                })
            setRedirecting(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth0IsLoading, redirectingToAuth0Org])

    if (
        redirecting ||
        redirectingToAuth0Org ||
        isRedirectingToSupertokens ||
        isSupertokensLoading
    ) {
        return <LoadingSplash />
    }
    return (
        <AuthPageFrame title="Log in">
            {!showAuth0Screen ? (
                !isSupertokensSSO ? (
                    <SignInFlow />
                ) : (
                    <SupertokensSSOLoginScreen />
                )
            ) : (
                <Auth0LoginScreen />
            )}
        </AuthPageFrame>
    )
}

LoginPage.propTypes = {
    userActions: PropTypes.object.isRequired, // From withUser
}

export default withUser(LoginPage)
