import React, { FC } from 'react'

import { AppSettingsContent } from 'features/AppSettings/AppSettingsContent'

import { SlidingPaneFrame } from '../common/SlidingPaneFrame'
import { useAppSettings } from '../hooks/useAppSettings'

type Props = {
    paneState?: { page: AppSettingsPage }
}

const AppSettingsSidePane: FC<Props> = ({ paneState = { page: { name: 'data_sources' } } }) => {
    const { open: openAppSettings } = useAppSettings()

    const setPage = (page: AppSettingsPage) => {
        openAppSettings({ page })
    }

    return (
        <SlidingPaneFrame title="App Settings" description="Manage your app name, URL and more.">
            <AppSettingsContent modalState={paneState} setPage={setPage} />
        </SlidingPaneFrame>
    )
}

export default AppSettingsSidePane
