import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'

const environmentPrefix = () => {
    return settings.IS_PROD ? '' : `${settings.DOMAIN}#`
}

// This component updates the Datadog RUM integration with the app and user information.
const UserMonitoring = ({}) => {
    const { selectedStack, workspaceAccount, isLoading: appContextLoading } = useAppContext()
    const {
        user,
        studioUser,
        previewing,
        isImpersonating,
        isLoadingIncludingStack: userContextLoading,
    } = useAppUserContext()

    const userIdentifier = studioUser
        ? `${environmentPrefix()}${studioUser?._sid}`
        : `${environmentPrefix()}${selectedStack?._sid}#${user?._sid}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userAttributes = {
        sid: user?._sid,
        id: userIdentifier,
        email: user?._email,
        name: user?._primary ?? user?.name,
        previewing,
        isImpersonating,
    }

    useEffect(() => {
        if (!appContextLoading) {
            datadogRum.addRumGlobalContext('workspace_sid', workspaceAccount?._sid)
            datadogRum.addRumGlobalContext('workspace_url', workspaceAccount?.base_url)
        }
    }, [appContextLoading, workspaceAccount])
    useEffect(() => {
        if (!appContextLoading) {
            datadogRum.addRumGlobalContext('stack_sid', selectedStack?._sid)
            datadogRum.addRumGlobalContext('stack_url', selectedStack?.base_url)
        }
    }, [appContextLoading, selectedStack])
    useEffect(() => {
        if (!userContextLoading) {
            // We have changed user
            if (userAttributes?.sid) {
                datadogRum.setUser(userAttributes)
            } else {
                datadogRum.removeUser()
            }
        }
    }, [userContextLoading, userAttributes])

    return null
}

export default UserMonitoring
