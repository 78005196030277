import { linkApi } from 'data/api/linkApi'
import { useGetLinksById } from 'data/selectors/linkSelectors'

/**
 *
 * @returns {{ linksById: { [key: string]: LinkView }, getLinks: () => LinkView[], actions: LinkCollectionActions}}
 */
export function useLinkCollection() {
    const linksById = useGetLinksById()

    /**
     * Create a new link
     * @param {LinkCreateData} data
     * @returns {Promise<void>}
     */
    const create = (data) => {
        return linkApi.create(data)
    }

    /**
     * Partially update a link
     * @param {string} linkId
     * @param {LinkUpdateData} data
     * @returns {Promise<void>}
     */
    const update = (linkId, data) => {
        return linkApi.update(linkId, data)
    }

    /**
     * Delete a link
     * @param {string} linkId
     * @returns {Promise<void>}
     */
    const remove = (linkId) => {
        return linkApi.remove(linkId)
    }

    return {
        linksById,
        getLinks: () => Object.values(linksById),
        actions: { createLink: create, updateLink: update, deleteLink: remove },
    }
}
