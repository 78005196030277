import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tippy'

import { useTheme } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { hash } from 'utils/utils'

import Box from './Box'
import useBrowserInfo from './useBrowserInfo'

import 'react-tippy/dist/tippy.css'

const Hint = ({
    children,
    label,
    hintKey,
    desktopOnly,
    placement = 'left',
    textAlign = 'left',
    showDelay = 1000,
    hideDelay = 3000,
    repetitions = 3,
    disabled,
    icon = 'lightbulb',
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()
    const browser = useBrowserInfo()
    const windowTimeout = useRef()

    const clearCurrentTimeout = () => {
        if (windowTimeout.current) clearTimeout(windowTimeout.current)
        windowTimeout.current = null
    }

    useEffect(() => {
        const lookupKey = hintKey || hash(label)
        const alreadyShown = parseInt(localStorage.getItem(`showedHint_${lookupKey}`) || 0)
        if (
            !disabled &&
            alreadyShown < repetitions &&
            (!desktopOnly || browser.platform.type === 'desktop')
        ) {
            localStorage.setItem(`showedHint_${lookupKey}`, alreadyShown + 1)
            clearCurrentTimeout()
            windowTimeout.current = setTimeout(() => setIsOpen(true), showDelay)
        } else if (disabled) {
            setIsOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    const handleShown = () => {
        clearCurrentTimeout()
        windowTimeout.current = setTimeout(() => setIsOpen(false), hideDelay)
    }

    useEffect(() => {
        return clearCurrentTimeout
    }, [])

    return (
        <Tooltip
            trigger="mouseenter"
            zIndex={9999}
            arrow
            theme="light"
            popperOptions={{ modifiers: { preventOverflow: { enabled: false } } }}
            open={isOpen}
            html={
                <Box style={{ maxWidth: '300px', textAlign }}>
                    <FontAwesomeIcon
                        icon={theme?.stackerIcons?.[icon]}
                        style={{ marginRight: '6px', verticalAlign: 'middle', opacity: 0.5 }}
                    />
                    <span>{label}</span>
                </Box>
            }
            position={placement}
            style={{ display: 'block' }}
            onShown={handleShown}
            {...props}
        >
            {children}
        </Tooltip>
    )
}

export default React.memo(Hint)
