import { ReactNode, useEffect } from 'react'

import useLDFlags from '../data/hooks/useLDFlags'

type Props = {
    children: ReactNode
}
// This watcher copies all LaunchDarkly flags to localStorage, so they can be used in cypress for conditional testing
const LDFlagsWatcher = ({ children }: Props) => {
    const { flags } = useLDFlags()
    useEffect(() => {
        window?.localStorage?.setItem('ldFlags', JSON.stringify(flags))
    }, [flags])

    return children ?? null
}
export default LDFlagsWatcher
