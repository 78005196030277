import React, { memo, useEffect, useRef, useState } from 'react'
import Linkify from 'react-linkify'

import get from 'lodash/get'
import PropTypes from 'prop-types'

import { Box, Editable, Input, Link, RenderButtonOrLink } from 'v2/ui'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import DisplayText from './DisplayText'

function finalizeValue(value) {
    // chakra won't reset an input to blank when
    // null is passed as the new value, so we have to
    // use a blank string here.
    return value !== null ? value : ''
}
const TextAttribute = memo(
    ({
        mode,
        layout,
        size,
        singleLine,
        renderOptions = {},
        renderDisplay,
        onChange,
        disabled,
        password,
        massageInput,
        hasFocus,
        enableCopyPaste,
        ...props
    }) => {
        const textRef = useRef(null)

        useEffect(() => {
            if (hasFocus) textRef.current.focus()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        let value =
            'formattedValue' in renderOptions ? renderOptions.formattedValue : props.children
        const unpackForEditing =
            'unpackForEditing' in renderOptions ? renderOptions.unpackForEditing : (x) => x
        const repackForSaving =
            'repackForSaving' in renderOptions ? renderOptions.repackForSaving : (x) => x
        const treatAsUrl = get(renderOptions, 'treatAsUrl')

        const [editValue, setEditValue] = useState(finalizeValue(unpackForEditing(props.children)))

        // reload local state if the incoming state has changed
        useEffect(() => {
            // only update the edit value if the actual
            // corresponding save value has changed. This
            // prevents overwriting the users' input if they've
            // entered a number followed by a decimal point
            if (repackForSaving(editValue) !== finalizeValue(props.children)) {
                setEditValue(unpackForEditing(finalizeValue(props.children)))
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.children])

        if ((value || value === 0) && !(typeof value === 'string' || value instanceof String))
            value = JSON.stringify(value)

        const handleChange = (e) => {
            let value = e.target.value

            if (massageInput) {
                value = massageInput(value)
                e.target.value = value
            }

            onChange(repackForSaving(value))
            setEditValue(value)
        }

        const form = (
            <Input
                dataTestId="text-attribute.text-input"
                ref={textRef}
                width="100%"
                size={size}
                type={password ? 'password' : null}
                autoFocus={mode === modes.editable}
                onChange={handleChange}
                value={editValue}
                placeholder={props.placeholder}
                style={props.style}
                variant={props.variant || 'outline'}
                disabled={disabled}
                {...renderOptions.inputProps}
            />
        )

        const inline = layout === layouts.inline || layout === layouts.highlighted
        const display = renderDisplay ? (
            renderDisplay(value, props)
        ) : treatAsUrl && value ? (
            <Box
                width={enableCopyPaste ? '80%' : '100%'}
                maxWidth={enableCopyPaste ? '80%' : '100%'}
                textOverflow="ellipsis"
                overflow="hidden"
            >
                <RenderButtonOrLink
                    url={value}
                    button={get(renderOptions, 'displayAsButton')}
                    image={get(renderOptions, 'displayAsImage')}
                    imageAltText={get(renderOptions, 'imageAltText')}
                    openInNewTab={!!get(renderOptions, 'openInNewTab')}
                >
                    {get(renderOptions, 'buttonTitle')}
                </RenderButtonOrLink>
            </Box>
        ) : props.field?.connection_options?.airtable_column_type === 'phone' ? (
            <DisplayText
                singleLine={inline && singleLine}
                removeTableSpacer={layout === layouts.highlighted}
                whiteSpace={inline ? 'inherit' : 'pre-line'}
            >
                <Link href={'tel:' + value} target="_blank" {...props} />
            </DisplayText>
        ) : (
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a target="_blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}
            >
                <DisplayText
                    singleLine={inline && singleLine}
                    removeTableSpacer={layout === layouts.highlighted}
                    whiteSpace={inline ? 'inherit' : 'pre-line'}
                    color={props.color}
                    additionalStyles={props.additionalStyles}
                >
                    {value || (treatAsUrl && get(renderOptions, 'displayAsButton') ? '' : '-')}
                </DisplayText>
            </Linkify>
        )

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end }) =>
                        React.cloneElement(form, {
                            onBlur: end,
                        })
                    }
                    display={() => display}
                    onChange={(value) => onChange(repackForSaving(value))}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

TextAttribute.propTypes = {
    /** object containing options for rendering/editing the value */
    renderOptions: PropTypes.shape({
        /** formatted display value  */
        formattedValue: PropTypes.any,
        /**  returns the edit value */
        unpackForEditing: PropTypes.func,
        /**  takes the edit value and returns the save value */
        repackForSaving: PropTypes.func,
    }),
    /** handles saving the changed value */
    onChange: PropTypes.func,
}
export default TextAttribute
