import React from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button, Heading, Input, Section } from 'legacy/v1/ui'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

const AppPreview = ({ name, logo, color }) => (
    <Window>
        <WindowMenuBar>
            <WindowButton />
            <WindowButton />
            <WindowButton />
        </WindowMenuBar>
        <Section
            noPadding
            noMargin
            style={{
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center',
            }}
        >
            <TinyCard>
                <Section
                    noMargin
                    noPadding
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <LogoWrapper>
                        {logo ? (
                            <img src={logo} style={{ height: 25 }} alt="logo" />
                        ) : (
                            <TinyHeading
                                style={{
                                    opacity: 0.4,
                                }}
                            >
                                Your Logo Here
                            </TinyHeading>
                        )}
                    </LogoWrapper>
                    <TinyHeading
                        style={{
                            marginBottom: 5,
                        }}
                    >
                        Log into {name}
                    </TinyHeading>
                    <Section noMargin noPadding>
                        <TinyInput placeholder="Email" />
                        <TinyInput placeholder="Password" />
                        <TinyButton style={{ backgroundColor: color }}>Log In</TinyButton>
                    </Section>
                </Section>
            </TinyCard>
        </Section>
    </Window>
)

AppPreview.propTypes = {
    color: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
}
AppPreview.defaultProps = {
    color: stackerTheme.brandColor,
    logo: null,
    name: 'My App',
}
export default AppPreview

const Window = styled.div`
    width: 400px;
    height: 300px;
    background: #e7f0f9;
    border-radius: 3px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`
const WindowMenuBar = styled.div`
    padding: 6px 7px;
    border-radius: 3px 3px 0 0;
    background-image: linear-gradient(-180deg, #fafbfc, #f1f4f7);
    width: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
`

const WindowButton = styled.div`
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 4px;
    border-radius: 50%;
    background: #e2e5e5;
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    min-height: 30px;
`

const TinyCard = styled.div`
    display: flex;
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: white;
    padding: 5px;
    width: 170px;
    height: 230px;
    margin-top: -20px;
`

const TinyInput = styled(Input)`
    transform: scale(0.6);
    width: 140%;
    margin-bottom: -8px;
    margin-left: -34px;
`

const TinyButton = styled(Button)`
    transform: scale(0.6);
    color: white;
    width: 140%;
    margin-bottom: -8px;
    margin-left: -34px;
`

const TinyHeading = styled(Heading)`
    margin: 0px;
    padding: 0px;
    font-size: 12px;
`
