import React, { useState } from 'react'

import {
    MISSING_PERMISSIONS,
    SCHEMA_ERROR_AT_SERVICE,
    SCHEMA_ERROR_FETCHING,
    SCHEMA_ERROR_PASSWORD,
    SCHEMA_ERROR_WRONG_FORMAT,
} from 'features/admin/data-connector/ImportErrors'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import { DataConnectionEditorContextType } from '../../dataConnectionEditorContext'
import { AirtableSchemaSyncError } from '../AirtableSchemaSyncError'
import FullSchemaSync from '../FullSchemaSync'

import { AirtableConnectionEditorError } from './AirtableConnectionEditorError'
import { AirtableConnectionEditorOAuthStep } from './AirtableConnectionEditorOAuthStep'
import { AirtableConnectionEditorSettings } from './AirtableConnectionEditorSettings'
import { AIRTABLE_CONNECTION_EDITOR_STEPS } from './AirtableConnectionEditorSteps'
import { AirtableConnectionRepairStep } from './AirtableConnectionRepairStep'
import { EditExistingConnection } from './EditExistingConnection'

const HANDLED_ERRORS = [
    SCHEMA_ERROR_FETCHING,
    SCHEMA_ERROR_WRONG_FORMAT,
    SCHEMA_ERROR_PASSWORD,
    SCHEMA_ERROR_AT_SERVICE,
]

const RECOVERABLE_ERRORS = [MISSING_PERMISSIONS]

type AirtableConnectionEditorProps = {
    onChangeHintImage: (url: string | undefined) => void
    context: DataConnectionEditorContextType
}

const AirtableConnectionEditor: React.FC<AirtableConnectionEditorProps> = (props) => {
    const context = props.context
    const [connectionFields, setConnectionFields] = useState<any>()
    const { dataConnection, schemaSyncOnly, step } = context

    const onSaveSuccess = (dc: DataConnectionDto, fields: any) => {
        context.setDataConnection(dc)
        if (setConnectionFields) {
            setConnectionFields(fields)
        }
        if (dc.version >= 2 && !dc.has_credentials) {
            context.setStep(AIRTABLE_CONNECTION_EDITOR_STEPS.oauthFlow)
        } else {
            context.setStep(AIRTABLE_CONNECTION_EDITOR_STEPS.syncSchema)
        }
    }

    const [importError, setImportError] = useState<
        { error: string; errorDetails?: string } | undefined
    >()
    const isSupportLoginPermitted = useIsSupportLoginPermitted()

    // If we don't yet have a data connection, render the UI for creating one.
    if (!dataConnection) {
        context.setHideButtons(true)
        return (
            <InitializeNewConnection
                onSaveSuccess={onSaveSuccess}
                onChangeHintImage={props.onChangeHintImage}
                createDataConnection={context.createDataConnection}
                dataBlock={!isSupportLoginPermitted}
            />
        )
    } else if (step === AIRTABLE_CONNECTION_EDITOR_STEPS.syncSchema || schemaSyncOnly) {
        return (
            <FullSchemaSync
                dataConnection={dataConnection}
                onStartSync={context.onStartSync}
                onError={(error, errorDetails) => {
                    context.onSyncError()

                    if (HANDLED_ERRORS.includes(error)) {
                        context.setStep(AIRTABLE_CONNECTION_EDITOR_STEPS.importError)
                        setImportError({ error, errorDetails })
                    }

                    if (RECOVERABLE_ERRORS.includes(error)) {
                        context.setStep(AIRTABLE_CONNECTION_EDITOR_STEPS.repair)
                    }
                }}
                onComplete={context.onSyncComplete}
                renderError={(error, errorDetails) => (
                    <AirtableSchemaSyncError error={error} details={errorDetails} />
                )}
            />
        )
    } else if (step === AIRTABLE_CONNECTION_EDITOR_STEPS.repair) {
        return (
            <AirtableConnectionRepairStep
                dataConnection={dataConnection}
                onClose={context.onClose}
            />
        )
    } else if (step === AIRTABLE_CONNECTION_EDITOR_STEPS.importError) {
        context.setHideButtons(true)

        return (
            <AirtableConnectionEditorError
                onSaveSuccess={onSaveSuccess}
                onChangeHintImage={props.onChangeHintImage}
                updateDataConnection={context.updateDataConnection}
                connectionFields={connectionFields}
                setConnectionFields={setConnectionFields}
                error={importError?.error}
                errorDetails={importError?.errorDetails}
                dataBlock={!isSupportLoginPermitted}
            />
        )
    } else if (step == AIRTABLE_CONNECTION_EDITOR_STEPS.oauthFlow) {
        return (
            <AirtableConnectionEditorOAuthStep
                dataConnection={dataConnection}
                getOAuthReturnUrl={context.getOAuthReturnUrl}
            />
        )
    } else {
        // We're just editing an existing connection
        return (
            <EditExistingConnection
                onSaveSuccess={onSaveSuccess}
                updateDataConnection={context.updateDataConnection}
                step={step}
                dataBlock={!isSupportLoginPermitted}
                onChangeHintImage={props.onChangeHintImage}
                useOAuthToken={dataConnection.version >= 2}
            />
        )
    }
}

type InitializeNewConnectionProps = {
    onChangeHintImage: (url: string) => void
    dataBlock: boolean
    createDataConnection: (data?: any) => Promise<DataConnectionDto>
    onSaveSuccess: (dc: DataConnectionDto, fields: any) => void
}
const InitializeNewConnection: React.FC<InitializeNewConnectionProps> = (props) => {
    return (
        <AirtableConnectionEditorSettings
            onSaveSuccess={props.onSaveSuccess}
            onChangeHintImage={props.onChangeHintImage}
            saveFunction={props.createDataConnection}
            dataBlock={props.dataBlock}
            showSharingLink
            showApiKey={false}
            useOAuthToken={true}
        />
    )
}

export default AirtableConnectionEditor
