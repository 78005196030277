import React, { FC } from 'react'

import styled from '@emotion/styled'

import { Box, Icon } from 'v2/ui'

const Wrapper = styled(Box)`
    cursor: pointer;

    padding: 3px;
    margin-right: 0.2rem;
`

type Props = {
    icon: string
    onClick: () => void
    tooltip?: string
    isDelete?: boolean
}

const BlockLabelTooltip: FC<Props> = ({ icon, tooltip, onClick, isDelete }) => (
    <Wrapper
        as="button"
        className={`block-label ${isDelete ? 'block-delete-label' : ''}`}
        onClick={onClick}
    >
        <Icon icon={icon} label={tooltip} size="0.75rem" labelPlacement="top" margin="none" />
    </Wrapper>
)

export default BlockLabelTooltip
