import { useMutation, UseMutationOptions } from 'react-query'

import { refetchFields, useDefaultFields } from 'data/hooks/fields'
import { getCachedNavigation } from 'data/hooks/navigation'
import { createObject } from 'data/hooks/objects/objectOperations'
import { reloadMetadataAfterObjectChange } from 'data/hooks/refreshData'
import { assertIsDefined } from 'data/utils/ts_utils'

export type CreateEmptyTableDto = {
    name: string
}

export type CreateEmptyTableResponse = {
    object: ObjectDto
    url: string
}

export function useCreateEmptyTable(
    options?: UseMutationOptions<CreateEmptyTableResponse, unknown, CreateEmptyTableDto, unknown>
) {
    const { data: defaultFields } = useDefaultFields()

    return useMutation(async ({ name }: CreateEmptyTableDto) => {
        const object = await createObject({
            name,
            fields: defaultFields,
            no_default_records: false,
        })
        assertIsDefined(object)

        await Promise.all([refetchFields(), reloadMetadataAfterObjectChange(true)])

        const redirectUrl = getRedirectUrl(object)

        return {
            object,
            url: redirectUrl,
        }
    }, options)
}

function getRedirectUrl(object: ObjectDto): string {
    // This was already refetched as part of the invalidation above.
    const navigation = getCachedNavigation() as NavigationDto[]
    const navItem = navigation?.find((n) => n.object_id === object._sid && n.display_order === 1)

    return navItem ? navItem.url : ''
}
