import React, { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modifier, usePopper } from 'react-popper'

import { Placement } from '@popperjs/core'

type Props = {
    referenceElement: HTMLElement | null
    placement?: Placement
    closeOnOuterAction?: boolean
    closeOnEscape?: boolean
    onPopoverClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    onClose?: () => void
    usePortal?: boolean
    limitOuterActionsToDescendentsOf?: Node
    modifiers?: Modifier<unknown>[]
    zIndex?: number
}

const Popper: FC<Props> = ({
    referenceElement,
    placement,
    closeOnOuterAction = true,
    closeOnEscape = true,
    onPopoverClick,
    onClose,
    children,
    usePortal = true,
    limitOuterActionsToDescendentsOf,
    modifiers = [],
    zIndex,
}) => {
    const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: placement || 'right-start',
        modifiers,
    })

    useEffect(() => {
        const handleClick = (event: MouseEvent): void => {
            if (
                referenceElement?.contains(event.target as Node) ||
                popperElement?.contains(event.target as Node) ||
                !closeOnOuterAction ||
                (limitOuterActionsToDescendentsOf &&
                    !limitOuterActionsToDescendentsOf.contains(event.target as Node))
            ) {
                return
            }

            onClose?.()
            event.preventDefault()
        }

        const onKeyPress = (event: KeyboardEvent): void => {
            if (event.key === 'Escape' && (closeOnOuterAction || closeOnEscape)) {
                onClose?.()
            }
        }

        document.addEventListener('click', handleClick, { capture: true })
        document.addEventListener('keydown', onKeyPress)

        return () => {
            document.removeEventListener('click', handleClick, { capture: true })
            document.removeEventListener('keydown', onKeyPress)
        }
    }, [
        onClose,
        popperElement,
        referenceElement,
        closeOnOuterAction,
        closeOnEscape,
        portalContainer,
        limitOuterActionsToDescendentsOf,
    ])

    useEffect(() => {
        if (usePortal) {
            const container = document.createElement('div')
            document.body.appendChild(container)
            setPortalContainer(container)

            return () => {
                document.body.removeChild(container)
                setPortalContainer(null)
            }
        }
    }, [usePortal])

    useEffect(() => {
        update?.()
    }, [children, update])

    const content = (
        <div
            ref={setPopperElement}
            onClick={onPopoverClick}
            style={{ ...styles.popper, zIndex: zIndex ?? 1500, width: 'fit-content' }}
            {...attributes.popper}
        >
            {children}
        </div>
    )

    if (!usePortal) {
        return content
    } else if (portalContainer) {
        return createPortal(content, portalContainer)
    }
    return null
}

export default Popper
