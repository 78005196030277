import { queryClient, useQuery } from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useTemplates'
const ENDPOINT = 'bundle-templates/'
const LIST_NAME_UNPUBLISHED: REACT_QUERY_DEPS_NAME = 'useTemplates&_unpublished'

export type Template = {
    auto_id: string
    bundle_url: string
    name: string
    icon?: string
    icon_color?: string
    card_image?: string
    card_description?: string
    detail_description?: string
    gallery_images?: string
    created_date: string
    modified_date: string
    is_published: boolean
    options?: {
        type?: 'simple_builder'
    }
}

const getListName = () => {
    const showUnpublished = localStorage.getItem('stacker_show_unpublished_templates') === 'true'
    return showUnpublished ? LIST_NAME_UNPUBLISHED : LIST_NAME
}

export function useTemplates(options = {}) {
    const showUnpublished = localStorage.getItem('stacker_show_unpublished_templates') === 'true'
    return useQuery<Template[]>(getListName(), ENDPOINT, options, {
        bypassMatchingStackCheck: true,
        queryParams: {
            unpublished: showUnpublished,
        },
    })
}

export function invalidateTemplates() {
    return queryClient.invalidateQueries(getListName())
}

export function refetchTemplates() {
    return queryClient.refetchQueries(getListName())
}

export function resetTemplates() {
    queryClient.removeQueries(getListName())
}
