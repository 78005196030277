export const richTextStyles = {
    p: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    code: {
        backgroundColor: '#eee',
        borderRadius: '3px',
        px: 1,
    },
    'code:only-child': {
        display: 'block',
        padding: 3,
        whiteSpace: 'pre-line',
    },
    h1: {
        fontSize: ['md', null, null, 'lg'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },
    h2: {
        fontSize: ['smmd', null, null, 'mdlg'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },
    h3: {
        fontSize: ['sm', null, null, 'md'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },
    h4: {
        fontSize: ['xs', null, null, 'smmd'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },
    h5: {
        fontSize: ['xs', null, null, 'sm'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },
    h6: {
        fontSize: ['xs', null, null, 'sm'],
        marginBlockStart: '.9em',
        marginBlockEnd: '.9em',
    },

    blockquote: {
        marginInlineStart: '0px',
        borderLeft: '3px solid lightgray',
        paddingInlineStart: '12px',
    },

    table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
    },
    tr: {
        borderTop: '1px solid #c6cbd1',
        background: '#fff',
    },

    th: {
        padding: '6px 13px',
        border: '1px solid #dfe2e5',
    },
    td: {
        padding: '6px 13px',
        border: '1px solid #dfe2e5',
    },

    'tr:nth-child(2n)': {
        background: '#f6f8fa',
    },
    img: {
        maxWidth: '100%',
        display: 'inline',
    },
}

export default richTextStyles
