import { getCurrentStack, getWorkspaceAccount } from 'app/AppContextStore'

export const Urls = {
    Root: '/',
    AdminHome: '/admin',
    Home: '/home',
    Login: '/login',
    Auth: '/auth',
    Logout: '/logout',
    AuthThirdPartyCallback: '/auth/callback',
    StudioStackSettings: '/studio/stack/settings',
    AdminSetup: '/admin/setup',
    AdminCache: '/admin/cache',
    AdminDataDebugger: '/admin/data-debugger',
    AdminConnectionDebugger: '/admin/connection-debugger',
    AdminSupportLogin: '/admin/support-login',
    Admin: '/admin',
    AdminModal: '/admin-modal',
    Register: '/register',
    AcceptInvitation: '/accept',
    ForgotPassword: '/forgot-password',
    SetPassword: '/set-password',
    RegisterAuto: '/register/auto',
    RegisterFrame: '/register/frame',
    Register4: '/register/4',
    Register3: '/register/3',
    Register2: '/register/2',
    Stacks: '/stacks',
    HelpLinks: '/help-links',
    ResetPassword: '/reset-password',
    StudioLogout: '/studio/logout',
    TermsOfService: 'https://www.stackerhq.com/legal/terms',
    PrivacyPolicy: 'https://www.stackerhq.com/legal/privacy',
    EditProfile: '/edit-profile',
    WorkspaceOnboarding: '/workspace/onboarding',
    AccountOnboarding: '/account/onboarding',
    InstallBundle: '__install',
}

export function getAbsoluteUrl(url: string) {
    return `${window.location.protocol}//${window.location.host}${url}`
}

export const WorkspaceUrls = {
    TemplateLibrary: '/_templates',
}

export const getUrl = (url: string, stack?: StackDto | null, workspaceAccount?: Account | null) => {
    return `${getRootPath(stack, workspaceAccount)}${url}`
}

export const trimRootPathFromUrl = (url: string) => {
    const root = getRootPath()

    return root ? url.slice(root.length) : url
}

export const getRootPath = (stack?: StackDto | null, workspaceAccount?: Account | null) => {
    // We will have a root path if we are in a workspace account, and we have a
    // selected app.
    const account = workspaceAccount || getWorkspaceAccount()
    const selectedStack = stack || getCurrentStack()
    let rootPath = account && selectedStack ? selectedStack?.url_slug : ''

    return rootPath && `/${rootPath}`
}
