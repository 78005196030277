// @ts-strict-ignore
import * as React from 'react'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import ReactFilestackLazy from 'utils/lazyComponents/ReactFilestack'

type ImageUploadFormProps = {
    name: string
    defaultValue?: string
    required?: boolean
    children?: (onPick: any) => ReactNode
}

const ImageUploadForm = ({
    name,
    defaultValue,
    required = false,
    children,
}: ImageUploadFormProps) => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{ required }}
            render={({ field }) => (
                <ReactFilestackLazy
                    options={{
                        accept: 'image/*',
                    }}
                    onSuccess={(value) => {
                        field.onChange(value.filesUploaded[0].url)
                    }}
                    customRender={({ onPick }) =>
                        children &&
                        children({
                            onClick: (event) => {
                                onPick(event)
                                disableFocusLock()
                            },
                        })
                    }
                />
            )}
        />
    )
}

// chakra-ui modals has a focus-lock (using https://github.com/theKashey/react-focus-lock)
// meaning we can't focus anything from filestack-picker's modal
// we need to bail out of the focus-lock
function disableFocusLock() {
    function doIt() {
        const elm = document.getElementById('__filestack-picker')
        if (!elm)
            return setTimeout(() => {
                doIt()
            }, 100)
        //@ts-ignore
        elm.dataset.noFocusLock = true
    }
    doIt()
}

export default ImageUploadForm
