import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type LayoutProps = { size?: number; color?: string }

const Layout: React.FC<LayoutProps> = ({
    size = 16,
    color = colors.userInterface.accent[1000],
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M14.667 6.666v6.667A.667.667 0 0 1 14 14H8.667V6.666h6zm-7.334 4V14H2a.666.666 0 0 1-.667-.667v-2.667h6zm0-8.666v7.333h-6V2.667A.667.667 0 0 1 2 2h5.333zM14 2a.667.667 0 0 1 .667.667v2.666h-6V2H14z"
                fill={color}
            />
        </svg>
    )
}

export default Layout
