import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from 'ui/deprecated/atoms/Flex'

import { UnsavedChangesModal } from '../workspace/UnsavedChangesModal'

import GeneralSettings from './ProfileSettingsModalGeneralSettings'
import NotificationSettings from './ProfileSettingsModalNotificationSettings'
import { Sidebar } from './ProfileSettingsModalSidebar'
import ProfileSupportLoginAccess from './ProfileSupportLoginAccess'

export const colors = stackerTheme().colors
const GeneralSettingsMemo = React.memo(GeneralSettings)

let subscribers = []

/**
 * call this to open the modal from anywhere. No context or hooks needed
 * @param {{page: ProfileSettingsPage}} defaultState
 */
export function openProfileSettingsModal(defaultState = true) {
    subscribers.map((fn) => fn(defaultState))
}

// handle the open / closed state of the modal
export default function ProfileSettingsModal() {
    const [isOpen, setIsOpen] = useState()

    useEffect(() => {
        subscribers.push(setIsOpen)

        return () => (subscribers = subscribers.filter((fn) => fn !== setIsOpen))
    }, [])

    return isOpen ? (
        <ProfileSettingsContent
            onRequestClose={() => {
                setIsOpen(false)
            }}
        />
    ) : null
}

const pageComponents = {
    general: GeneralSettingsMemo,
    notifications: NotificationSettings,
    supportLogin: ProfileSupportLoginAccess,
}

/**
 *
 * @param {{onRequestClose: () => void, defaultState: {page: ProfileSettingsPage}}} param0
 */
function ProfileSettingsContent({ onRequestClose, defaultState = { page: 'general' } }) {
    const formRef = useRef({})
    const [displayChangesModal, setDisplayChangesModal] = useState(false)

    const [page, setPage] = useState(defaultState.page)

    // similar to onChangePage()
    // Can we close the modal yet?
    function onClose() {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: onRequestClose, // function called when the function finishes
                onCancel: () => setDisplayChangesModal(false), // function called when clicking on Cancel
            })
        }

        // there are changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: onRequestClose,
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        onRequestClose()
    }

    // similar to onClose
    // If there has changes, do not change pages
    function onChangePage(name) {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: () => {
                    // called after the save query // or when clicking on Discard and continue
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        // there are unsaved changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: () => {
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        setPage(name)
    }

    const Component = pageComponents[page] ?? GeneralSettingsMemo

    return (
        <SimpleModalCompat
            isOpen
            size={900}
            height={800}
            noPadding
            onClose={onClose}
            title="My Settings"
        >
            <Flex column style={{ height: '100%' }}>
                <Flex
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Sidebar onChange={onChangePage} active={page} onClose={onClose} />

                    {Component ? (
                        <Component formRef={formRef}>
                            {displayChangesModal && (
                                <UnsavedChangesModal {...displayChangesModal} />
                            )}
                        </Component>
                    ) : (
                        "can't render " + page
                    )}
                </Flex>
            </Flex>
        </SimpleModalCompat>
    )
}
