import React, { useCallback } from 'react'

import Box from 'v2/ui/components/Box'

import DragAndDropList from '../DragAndDropList'

import { OrderableItem } from './OrderableItem'
import { Item, OrderableItemsListProps } from './types'

export const OrderableItemsList: React.FC<OrderableItemsListProps> = ({
    items,
    activeItem,
    isChecked,
    onReorder,
    onEdit,
    onCheckedChanged,
    onDragStart,
    onDragEnd,
    onItemFocus,
    provideLabel,
    provideIcon,
    provideKey,
    autoHideEditButton,
    nonDraggableIndexes,
    showActionsOnDisabled,
    ...props
}) => {
    // provides the drag-and-drop container the unique id/key
    // for a particular index in our list of items
    const provideKeyFromIndex = useCallback((x) => provideKey?.(items[x]), [items, provideKey])

    const allowReorder = onReorder !== undefined

    const shouldAddClass = (index: number, item: Item) => {
        return (
            (index === 0 && item.type !== 'section') ||
            (index === 1 && item.type !== 'section' && isChecked)
        )
    }

    return (
        <DragAndDropList
            // @ts-expect-error until DragAndDropList is converted to tsx
            ContainerElement={Box}
            width="100%"
            provideKey={provideKeyFromIndex}
            onReorder={onReorder}
            dragDisabled={!allowReorder}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            disabledDestinationIndexes={nonDraggableIndexes}
        >
            {items.map((item, index) => (
                <OrderableItem
                    key={provideKey?.(item)}
                    itemKey={provideKey?.(item)}
                    data-testid={provideKey?.(item)}
                    disabled={!allowReorder}
                    allowReorder={allowReorder}
                    isActive={activeItem === item}
                    item={item}
                    onCheckedChanged={onCheckedChanged}
                    isChecked={isChecked}
                    onEdit={onEdit}
                    onFocus={onItemFocus}
                    provideLabel={provideLabel}
                    provideIcon={provideIcon}
                    autoHideEditButton={autoHideEditButton}
                    showActionsOnDisabled={showActionsOnDisabled}
                    className={shouldAddClass(index, item) ? props.className : ''}
                />
            ))}
        </DragAndDropList>
    )
}
