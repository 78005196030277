export function getPercentageChange(previousValue: number, currentValue: number) {
    let percent = 0
    if (previousValue === 0 && currentValue === 0) return 0
    if (currentValue !== 0) {
        if (previousValue !== 0) {
            percent = ((currentValue - previousValue) / previousValue) * 100
        } else {
            percent = currentValue * 100
        }
    } else {
        percent = -previousValue * 100
    }
    return Math.round(percent * 10) / 10
}
