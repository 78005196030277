import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { makeComponent } from '../../helpers/recipes'

import EmptyStyles from '../../helpers/emptyStyles.css'
import { ButtonStyles, ContainerStyles, IndicatorStyles, LabelStyles } from './Radio.css'

export const Root = makeComponent(RadioGroupPrimitive.Root, EmptyStyles)
export const Container = makeComponent('div', ContainerStyles)
export const Button = makeComponent(RadioGroupPrimitive.Item, ButtonStyles)
export const Indicator = makeComponent(RadioGroupPrimitive.Indicator, IndicatorStyles)
export const Label = makeComponent('label', LabelStyles)
