import React from 'react'

import { ParsedQs } from 'qs'

import { DataConnector, ProviderTypes } from './dataConnectorTypes'

export const DataConnectionEditorContext = React.createContext<DataConnectionEditorContextType>(
    null as any
)

export type DataConnectionEditorContextType = {
    providerKey: ProviderTypes
    provider: DataConnector
    dataConnection: DataConnectionDto | null
    step: undefined | string | string[] | ParsedQs | ParsedQs[]
    setStep: (
        step: undefined | string | string[] | ParsedQs | ParsedQs[],
        updateHistory?: boolean
    ) => void
    initializationComplete: boolean
    setInitializationComplete: (isComplete: boolean) => void
    setNextHandler: (handler: (() => void) | undefined) => void
    setNextButtonText: (text: string) => void
    isSaving: boolean
    setIsSaving: (isSaving: boolean) => void
    isTesting: boolean
    setIsTesting: (isTesting: boolean) => void
    setDataConnection: (dc: DataConnectionDto) => void
    updateDataConnection: (data?: any) => Promise<DataConnectionDto>
    createDataConnection: (data?: any) => Promise<DataConnectionDto>
    setNextButtonDisabled: (disabled: boolean) => void
    setModalHidden: (isHidden: boolean) => void
    hideButtons: boolean
    setHideButtons: (hideButtons: boolean) => void
    setShouldCloseOnSchemaSyncOnly: (shouldClose: boolean) => void
    onStartSync: (loadingText?: string) => void
    onSyncComplete: () => void
    onSyncError: () => void
    getOAuthReturnUrl: (dc: DataConnectionDto) => string
    schemaSyncOnly: boolean | undefined
    onClose: () => void
    initializeImmediately: boolean
    autoOpenGSheetPicker: boolean
}
