import styled from '@emotion/styled'

import WithMargin, { Size, WithMarginProps } from './WithMargin'

export type WithMarginAndPaddingPropTypes = WithMarginProps & {
    padding?: Size
    paddingRight?: Size
    paddingLeft?: Size
    paddingTop?: Size
    paddingBottom?: Size
    noPadding?: boolean
}

const WithMarginAndPadding = styled(WithMargin)<WithMarginAndPaddingPropTypes>`
    padding: ${(props) => props.theme[props.padding || '']};
    ${(props) =>
        props.paddingRight &&
        `
        padding-right: ${props.theme[props.paddingRight]};
    `};
    ${(props) =>
        props.paddingLeft &&
        `
        padding-left: ${props.theme[props.paddingLeft]};
    `};
    ${(props) =>
        props.paddingTop &&
        `
        padding-top: ${props.theme[props.paddingTop]};
    `};
    ${(props) =>
        props.paddingBottom &&
        `
        padding-bottom: ${props.theme[props.paddingBottom]};
    `};

    ${(props) => props.noPadding && 'padding: 0px !important; '}
`

WithMarginAndPadding.displayName = 'WithMarginAndPadding'

export default WithMarginAndPadding
