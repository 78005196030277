import { saveAs } from 'file-saver'

import { fetchAndReturn, fetchAndReturnRaw } from '../utils/utils'

type ResultType = StackDto | { object_sids: Array<string> }
export const importUrl = (
    url: string,
    account_id: string | undefined,
    stack_id: string | undefined = undefined,
    variables?: any
): Promise<ResultType> => {
    return fetchAndReturn(
        `bundles/import`,
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                url: url,
                ...(account_id ? { account_id: account_id } : {}),
                ...(stack_id ? { stack_id: stack_id } : {}),
                ...(variables ? { variables: variables } : {}),
            }),
        },
        undefined,
        undefined,
        { bypassPreviewAndImpersonation: true }
    ) as Promise<ResultType>
}

export const saveExport = async (ignoreSections: string[] = [], bundleName?: string) => {
    const ignoreSectionsValue = ignoreSections.join(',')
    const baseUrl = 'bundles/export'
    const endpoint = ignoreSectionsValue
        ? `${baseUrl}?ignored_sections=${ignoreSectionsValue}`
        : baseUrl

    const fileName = bundleName ? bundleName : 'export'
    await fetchAndReturnRaw(endpoint)
        .then((response) => response.blob())
        .then((blob) => saveAs(blob, `${fileName}.json`))
}
