// @ts-strict-ignore
import settings from '../../app/settings'
import { dataConnectionApi } from '../../data/api/dataConnectionApi'
import { Integration } from '../../data/hooks/integrations'

export const getUnauthorisedFivetranDataConnection = (
    dataConnections: DataConnectionDto[],
    serviceName: string
) => {
    return dataConnections.find(
        (connection) =>
            connection.type === 'fivetran' &&
            connection.service_name === serviceName &&
            !connection.options?.fivetran_connector_authorised
    )
}

export const authoriseFivetran = async (connectionToAuthorise: DataConnectionDto) => {
    const tokenResponse = await dataConnectionApi.authorise(connectionToAuthorise._sid)
    const returnUri = encodeURIComponent(`${settings.API_ROOT}integrations/sync/`)
    const redirectUri = `https://fivetran.com/connect-card/setup?redirect_uri=${returnUri}&auth=${tokenResponse.token}`
    window.location.assign(redirectUri)
}

export const createFivetranConnection = async (
    integration: Integration,
    createDataConnection: (dc: any) => Promise<DataConnectionDto>
) => {
    return await createDataConnection({
        label: integration.name,
        service_name: integration.service_name,
        type: 'fivetran',
    })
}
