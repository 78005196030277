/* Code Quality: Good */

import React from 'react'

import LazyLoader from './LazyLoading'

export default function ReactFilestackLazy(props) {
    return (
        <LazyLoader
            getModule={() => import('v2/ui/ReactFilestack')}
            // eslint-disable-next-line react/no-children-prop
            children={({ default: Component }) => <Component {...props} />}
        />
    )
}
