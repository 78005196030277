import React from 'react'

import EmptyState from 'v2/ui/components/EmptyState'
import { EmptyView } from 'v2/ui/svgs'

import { DataListBlockWrapper } from './DataListBlockWrapper'

type DataListBlockNoObjectStateProps = {}

export const DataListBlockNoObjectState: React.FC<DataListBlockNoObjectStateProps> = ({}) => {
    return (
        <DataListBlockWrapper>
            <EmptyState name="records" compact={true} svg={EmptyView} p={3} py={10} />
        </DataListBlockWrapper>
    )
}
