import without from 'lodash/without'

import { unique } from 'utils/utils'

import { byID } from '../utils/byID'
import {
    BLOCK_CREATED,
    BLOCK_REMOVED,
    BLOCK_UPDATED,
    BLOCKS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from '../utils/constants'

// STATE
export const initialState = {
    blocks: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function blockReducer(state = initialState, action) {
    let blocks
    let block
    let fetching
    switch (action.type) {
        case BLOCKS_FETCHED:
            if (!action.payload) return state
            blocks = unique([...state.blocks, ...action.payload.map((b) => b._sid)])
            return {
                ...state,
                blocks,
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case BLOCK_CREATED:
            if (!action.payload) return state
            blocks = unique([...state.blocks, action.payload._sid])
            return {
                ...state,
                blocks,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case BLOCK_UPDATED:
            if (!action.payload) return state
            block = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [block._sid]: block },
            }
        case BLOCK_REMOVED:
            blocks = state.blocks.map((bs) => without(bs, action.id))
            return {
                ...state,
                blocks,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}
