import { useContext, useMemo } from 'react'
import { useMutation } from 'react-query'

import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import { AppContext, useAppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/AppContextStore'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import {
    authedUserDomainFilter,
    buildQueryKey,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from './_helpers'
import { invalidateNavigation } from './navigation'
import { invalidatePages } from './pages'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useViews'
const ENDPOINT = 'views/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
function getQueryKey() {
    return buildQueryKey(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
/**
 *
 * @param {import('react-query').UseQueryOptions } options
 * @return {import('react-query').UseQueryResult<ViewDto[]>}
 */
export function useViews(options = {}) {
    const { selectedStack, workspaceAccount } = useAppContext()
    // When query key changes due to previewing another role or user,
    // instead of reverting to an empty/loading state, keep the previous
    // data and just updated it when the results come in.
    const query_config = { ...STACK_QUERY_CONFIG, keepPreviousData: true, ...options }
    const queryResult = useQuery(useQueryKey(), ENDPOINT, query_config, {
        disabledFn: () => authedUserDomainFilter(selectedStack, workspaceAccount),
    })

    return queryResult
}

export function useStackViews(options = {}) {
    const { data: views = [] } = useViews(options)
    const { selectedStack } = useContext(AppContext)
    const { allStacks } = options

    const stackViews = useMemo(
        () => views.filter((item) => allStacks || item.stack_id === selectedStack?._sid),
        [views, selectedStack, allStacks]
    )

    return stackViews
}

export function useCreateView() {
    return useCreateItem(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateViews()
                invalidatePages()
                invalidateNavigation()
            },
        },
        {
            bypassPreviewAndImpersonation: true,
        }
    )
}
export function useUpdateView() {
    return useUpdateItem(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateViews()
                invalidatePages()
                invalidateNavigation()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any impersonation or role previewing.
            bypassPreviewAndImpersonation: true,
        }
    )
}
export function useDeleteView() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateViews()
            invalidatePages()
            invalidateNavigation()
        },
    })
}
export function invalidateViews() {
    // Want to include the stack id here otherwise we'll be invalidating for all the apps
    // which have been loaded
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}
export function refetchViews() {
    // invalidate all instances of these queries
    invalidateViews()
    // but only refetch the one that is for the current user
    return queryClient.refetchQueries(getQueryKey())
}

/**
 *
 * @param {string | undefined} id
 * @return {ViewDto}
 */
export function useViewFromId(id) {
    const stackId = getCurrentStackId()
    const { data: views } = useViews()

    return useMemo(() => {
        return views?.find((view) => view._sid === id && stackId == view.stack_id)
    }, [views, id, stackId])
}

function cloneView(newName, view) {
    const newView = {
        options: {},
        ...cloneDeep(omit(view, ['_sid', 'api_name'])),
    }

    newView.name = newName
    newView.options.title = newName
    newView.options.menu_label = newName

    newView.options.cloned_from = view._sid

    if (view.options) {
        newView.options.optimizedLayout = view.options.optimizedLayout
    }

    return newView
}

export function useDuplicateView(options) {
    const { mutateAsync: createView } = useCreateView()

    return useMutation(async ({ name, view }) => {
        const newView = cloneView(name, view)
        return createView(newView)
    }, options)
}
