import React, { FC } from 'react'

import { Box, Flex, Icon } from 'v2/ui'

type Props = {
    handleDelete: () => void
}

export const InvalidFilter: FC<Props> = ({ handleDelete }) => (
    <Box
        as={Flex}
        // @ts-expect-error
        wrap="nowrap"
        align="center"
        bg="gray.100"
        rounded="sm"
        my="1px"
        width="100%"
        cursor="pointer"
        hover={{ bg: 'gray.200' }}
    >
        <Box fontSize="sm" d="flex" alignItems="center" pl={2} pr={1} flexGrow={1}>
            <Icon
                style={{ display: 'flex', marginRight: '5px' }}
                icon="warning"
                size="15px"
                color="#FF0000"
            />
            <strong>Invalid filter</strong>
        </Box>
        {/* @ts-expect-error */}
        <Box onClick={handleDelete} role="button" hover={{ bg: 'gray.300' }}>
            <Icon icon="minus" display="inline" role="button" size="xs" mx={2} color="gray.400" />
        </Box>
    </Box>
)
