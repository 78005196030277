import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'

/**
 * Track the size of an element.
 * @param ref A React `RefObject` that stores the element whose size is tracked.
 * @returns The width and height of the element.
 */
const useDimension = <T extends HTMLElement | undefined>(
    ref: React.RefObject<T> | React.MutableRefObject<T>,
    callback?: (width: number, height: number) => void
) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const resizeObserverRef = useRef<ResizeObserver | null>(null)

    useLayoutEffect(() => {
        resizeObserverRef.current = new ResizeObserver((entries: ResizeObserverEntry[] = []) => {
            for (const entry of entries) {
                const entryWidth = entry.contentRect.width
                const entryHeight = entry.contentRect.height

                setWidth(entry.contentRect.width)
                setHeight(entry.contentRect.height)

                callback?.(entryWidth, entryHeight)
            }
        })

        if (ref.current) {
            resizeObserverRef.current.observe(ref.current)

            // Set element size on the initial render.
            const elemRect = ref.current.getBoundingClientRect()
            setWidth(elemRect.width)
            setHeight(elemRect.height)
        }

        return () => {
            resizeObserverRef.current?.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.current])

    return useMemo(() => ({ width, height }), [width, height])
}

export default useDimension
