import React from 'react'

// @ts-ignore - Filestack v3.1.0 is untyped
import Filestack from 'filestack-react'

import settings from 'app/settings'

import { useAccountIdFromAppContextOrOnboardingContext } from '../../app/hooks/useAccountIdFromAppContextOrOnboardingContext'

// NB if upgrading to filestack-react 4.x.x - customRender and componentDisplayMode
// are deprecated. https://github.com/filestack/filestack-react#migration-from-3xx-and-4xx
type ReactFilestackProps = {
    options?: ReactFilestackOptions
    onSuccess?: () => void
    customRender?: () => void
    componentDisplayMode?: {}
}

type ReactFilestackOptions = {
    fromSources?: string[]
    maxFiles?: number
    maxSize?: number
    accept?: string
    onCancel?: () => void
}

const ReactFilestackDefaultOptions: ReactFilestackOptions = {
    fromSources: [
        'local_file_system',
        'url',
        'webcam',
        'imagesearch',
        'unsplash',
        'facebook',
        'instagram',
        'dropbox',
    ],
    maxFiles: 1,
}

const disableFocusLock = async () => {
    // Some of the modals use a focus lock and this will disable it so that users
    // can search for images within the filestack modal
    // The element isn't always there right at the start so this waits until
    // the element appears. If it's more than 10 attempts it stops as something has
    // likely gone wrong if it's taken this long

    let filestackContainer
    let tries = 0
    while (!(filestackContainer = document.getElementById('__filestack-picker')) && tries < 10) {
        await new Promise((r) => setTimeout(r, 500))
        tries++
    }
    if (filestackContainer) {
        filestackContainer.setAttribute('data-no-focus-lock', 'true')
        filestackContainer.className += ' click-outside-ignore'
    }
}

const ReactFilestack: React.FC<ReactFilestackProps> = ({
    options: optionsOverrides,
    onSuccess,
    customRender,
    componentDisplayMode,
}) => {
    const { accountId } = useAccountIdFromAppContextOrOnboardingContext()
    const filestackPath = `/${settings.DOMAIN}/${accountId}/`

    const clientOptions = {
        cname: settings.FILESTACK.cname,
    }

    const actionOptions = {
        ...ReactFilestackDefaultOptions,
        ...optionsOverrides,
        onOpen: disableFocusLock,
        storeTo: {
            location: settings.FILESTACK.location,
            path: filestackPath,
        },
    }

    return (
        <Filestack
            apikey={settings.FILESTACK.key}
            clientOptions={clientOptions}
            actionOptions={actionOptions}
            onSuccess={onSuccess}
            customRender={customRender}
            componentDisplayMode={componentDisplayMode}
        />
    )
}

export default ReactFilestack
