import React from 'react'

import styled from '@emotion/styled'

import { Flex, Heading, MarkdownText, Text } from 'v2/ui'

import ViewHeaderWrapper from './ViewHeaderWrapper'

type ViewHeaderDefaultProps = {
    headerOptions: PageHeaderDefault
}

const ViewHeaderDefault: React.FC<ViewHeaderDefaultProps> = ({ headerOptions }) => {
    const { title, subtitle } = headerOptions

    return (
        <ViewHeaderWrapper>
            <Flex column maxWidth="100%" mt="3.75rem" align="left">
                {title && <Title as="h1" fontSize={[500, null, null, 650]} value={title} my={0} />}
                {subtitle && (
                    <Text fontSize="300" mt="2">
                        <MarkdownText
                            showRichTextEditor={typeof subtitle === 'object'}
                            containerHeadingSize={false}
                        >
                            {subtitle}
                        </MarkdownText>
                    </Text>
                )}
            </Flex>
        </ViewHeaderWrapper>
    )
}

const Title = styled(Heading)`
    max-width: 100%;
`

export default ViewHeaderDefault
