import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'

import { Switch } from '@chakra-ui/react'

import useConfirmModal from 'app/ConfirmModal'
import { useObject } from 'data/hooks/objects'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import { useUserLists } from 'data/hooks/userLists'
import { isReadOnlyField } from 'features/admin/data-connector/utils'
import { RolesSettings } from 'features/AppSettings/Roles/RolesSettings'
import FieldPicker from 'features/studio/ui/FieldPicker'
import UpdateRegistrationWhitelist from 'features/users/app-users-modal/UpdateRegistrationWhitelist'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import { Icon } from 'legacy/v1/ui'

import { Box, Flex, Modal, ScrollBox, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { FormWrapper } from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'
import SubmitButton from 'ui/forms/SubmitButton'
import { SyncRefWithForm } from 'ui/forms/utils'

export const NUMaSettings = ({ onRequestClose, selectedStack }) => {
    const updateStackOptions = useUpdateStackOptions()
    const formRef = useRef()
    const { data: userLists = [] } = useUserLists()
    const userList = userLists[0] || {}
    const { object: userListObject } = useObject(userList.object_id)

    const defaultValues = {
        passwordless: selectedStack?.options?.passwordless,
        whitelist_new_users: selectedStack?.options?.whitelist_new_users
            ? 'whitelist'
            : 'open-signup',
        sharing_link_export: selectedStack?.options?.sharing_link_export,
        //  data_mapping is partial here and is re-merged with the existing object in handleSave
        data_mapping: {
            sharing_link_field: selectedStack?.options?.data_mapping.sharing_link_field,
        },
    }
    const formContext = useForm({
        defaultValues: defaultValues,
    })

    const isDirty = Object.keys(formContext.formState.dirtyFields).length > 0

    const handleSave = (patch) => {
        // this logic determines whether or not we need to show a sharing link export confirmation modal
        const sharingLinkFieldUpdated =
            formContext.formState.dirtyFields?.data_mapping?.sharing_link_field
        const selectedSharingLinkField = patch.data_mapping?.sharing_link_field

        const formattedPatch = {
            ...patch,
            whitelist_new_users: patch?.whitelist_new_users === 'whitelist' ? true : false,
            sharing_link_export: patch?.data_mapping.sharing_link_field !== null ? true : false,
            data_mapping: {
                ...selectedStack?.options?.data_mapping,
                ...patch?.data_mapping,
            },
        }

        if (sharingLinkFieldUpdated) {
            showConfirm({
                title: selectedSharingLinkField
                    ? 'Set Sharing Link Export'
                    : 'Remove Sharing Link Export',
                message: getMessage(selectedSharingLinkField),
                onConfirm: (modal) => {
                    updateStackOptions(selectedStack, formattedPatch), modal.toggle()
                },
            })
        } else {
            updateStackOptions(selectedStack, formattedPatch)
        }
        onRequestClose()
    }

    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const onClose = () => {
        saveChanges(onRequestClose)
    }

    const { show: showConfirm } = useConfirmModal({})
    const getMessage = (fieldSid) => {
        // If the sharing_link_field is null, then we are removing sharing link
        if (fieldSid == null) {
            return (
                <>
                    <Text mb={3}>
                        Removing the sharing link field will mean that sharing links will no longer
                        be kept up to date. Existing sharing links will not be removed.
                    </Text>
                    <Text>Please confirm that you wish to proceed.</Text>
                </>
            )
        }

        const field = userListObject?.fields?.find(({ _sid }) => fieldSid === _sid)
        return (
            <>
                <Text mb={3}>
                    This will cause the data in <b>{field?.label}</b> to be replaced with sharing
                    links. Make sure that you&apos;re not overwriting any important data.
                </Text>
                <Text>Please confirm that you wish to proceed.</Text>
            </>
        )
    }

    return (
        <Modal
            title="User Settings"
            isOpen={true}
            onClose={onClose}
            height="80%"
            size="800px"
            isSecondLayer={true}
        >
            <ScrollBox
                overflowY="auto"
                flexGrow={1}
                scrollTrackSideMargin={6}
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
            >
                <FormWrapper
                    ref={formRef}
                    formContext={formContext}
                    onSubmit={handleSave}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minHeight: 0,
                        maxHeight: '100%',
                        marginTop: '10px',
                    }}
                >
                    <SyncRefWithForm formRef={formRef} />
                    {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                    <RolesSettings />
                    <FormField
                        as={UserRegistrationSettings}
                        name="whitelist_new_users"
                        variant="settings"
                        controlled
                    />
                    <FormField
                        as={PasswordlessLoginSettings}
                        stack={selectedStack}
                        name="passwordless"
                        variant="settings"
                        controlled
                    />
                    {!selectedStack?.options?.disable_sharing_links && (
                        <FormField
                            as={SharingLinkExportSettings}
                            name="data_mapping.sharing_link_field"
                            controlled
                        />
                    )}
                    <SubmitButton
                        disabled={!isDirty}
                        variant="adminPrimaryV4"
                        buttonSize="md"
                        mt={4}
                        mb={4}
                        flexShrink={0}
                    >
                        Save
                    </SubmitButton>
                </FormWrapper>
            </ScrollBox>
        </Modal>
    )
}

const UserRegistrationSettings = ({ onChange, value }) => {
    return (
        <>
            <Flex column mt={4} mb={6} align="left">
                <Text fontWeight="bold" mb={2}>
                    User registration
                </Text>
                <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                    Control how your users sign up. Choose between User list only and Open signup.
                </Text>

                {
                    <Flex column>
                        <UpdateRegistrationWhitelist onChange={onChange} value={value} />
                    </Flex>
                }
            </Flex>
        </>
    )
}

const PasswordlessLoginSettings = ({ onChange, value, stack }) => {
    const { protocol } = location
    const loginUrl = `${protocol}//${stack?.url}/login`

    const loginDescription = stack?.options?.passwordless
        ? 'Your users will receive an email with a 6 digit password code they can use to login once instead of using a password at: '
        : 'Your users can log in with their email and password at '

    return (
        <Flex column mb={4} align="left">
            <Text fontWeight="bold" mb={2}>
                User login
            </Text>

            <Text size="small" color={V4DesignSystem.colors.gray[600]} mb={2}>
                {loginDescription}
                <a href={loginUrl} rel="noopener noreferrer" target="_blank">
                    {loginUrl}
                    <Icon style={{ color: '#CCC', fontSize: '0.7em' }} icon="external-link-alt" />
                </a>
            </Text>

            <Flex>
                <Text color={V4DesignSystem.colors.gray[600]} mr={3}>
                    Enable passwordless login
                </Text>
                <Switch
                    size="sm"
                    isChecked={value}
                    onChange={onChange}
                    mt="3px"
                    isDisabled={false}
                    id="passwordless"
                />
            </Flex>
        </Flex>
    )
}

const SharingLinkExportSettings = ({ onChange, value }) => {
    const { data: userLists } = useUserLists({})
    const userList = userLists[0] || {}

    const getFieldOptions = (field) => {
        if (field._sid !== value && field.connection_options && isReadOnlyField(field)) return false
        return ['string'].includes(field.type)
    }

    return (
        <Flex column my={6} align="left">
            <Text fontWeight="bold" mb={2}>
                Sharing link export
            </Text>

            <Text size="small" color={V4DesignSystem.colors.gray[600]} mb={2}>
                Sharing links allow anyone with the link to log in as that user. You can choose a
                field of the {userList.name} table where sharing links will be saved. We recommend
                that you create a new column for this purpose.
            </Text>

            <Box width="300px">
                <FieldPicker
                    id="sharing-link-select"
                    name="data_mapping.sharing_link_field"
                    filter={(field) => getFieldOptions(field)}
                    objectId={userList.object_id}
                    placeholder="Choose a column"
                    value={value}
                    onChange={onChange}
                />
            </Box>
        </Flex>
    )
}
