// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useDisclosure } from '@chakra-ui/react'
import { Placement } from '@popperjs/core'
import translateEndUserFilters from 'v2/views/utils/translateEndUserFilters'

import { useAppContext } from 'app/AppContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { useObject } from 'data/hooks/objects'
import { useRecords } from 'data/hooks/records'
import {
    createRecord,
    deleteRecords,
    importRawRecords,
    updateRecord,
} from 'data/hooks/records/recordOperations'
import { RECORD_REMOVED } from 'data/utils/constants'
import { recordDeletionAvailable } from 'data/utils/utils'
import {
    ADD_FIELD_HEADER_BUTTON_ID,
    EDIT_FIELD_HEADER_COLUMN_ID,
    STICKY_ADD_FIELD_BUTTON_ID,
} from 'features/datagrid/constants'
import { DataGridEditor, DataGridEditorHandle } from 'features/datagrid/DataGridEditor'
import useOverrideImage from 'features/datagrid/hooks/useOverrideImage'
import NewRecordModal from 'features/datagrid/modals/NewRecordModal'
import type { DataGridCellProvider, RequiredFields } from 'features/datagrid/types'
import { DataGridContext } from 'features/workspace/AdminSideTray/manage-data/DataGridContext'
import { isFormulaField } from 'utils/fieldUtils'
import useTrack from 'utils/useTrack'

import { Banner, Box, Flex, LoadingScreen } from 'v2/ui'
import { colors } from 'v2/ui/theme/styles/default'
import useDebounce from 'v2/ui/utils/useDebounce'

import { FieldEditorPopover } from '../fields/FieldEditorPopover'
import { getAreObjectRecordsEditable } from '../fields/logic/availableFieldOperationUtils'

import ErrorState from './ErrorState'
import {
    ObjectDataGridToolbar,
    ObjectDataGridToolbarHandle,
    ObjectDataGridToolbarProps,
} from './ObjectDataGridToolbar'
import SelectedRecordsToolbar from './SelectedRecordsToolbar'

type Props = {
    objectId: string
    hideLabel?: ObjectDataGridToolbarProps['hideLabel']
    setIsDirty?: (dirty: boolean) => void
    isActive: boolean
    containerStyle?: any
    hideToolbar?: boolean
    showOnlySyncButtons?: boolean
    ignorePreviewAndImpersonation?: boolean
    requiredFields?: RequiredFields
    onFieldCreated?: (field: FieldDto) => void
}

class CellProvider implements DataGridCellProvider {
    records: { [keyof: string]: RecordDto } = {}
    setRecords(records: RecordDto[]) {
        // put records into a dictionary keyed by record ID for more
        // performant lookups. We merge the incoming records
        // with what we already have on hand because we may have records
        // cached from the user selecting related records in record links
        this.records = {
            ...this.records,
            ...records.reduce((result, record) => {
                if (record) {
                    result[record._sid] = record
                }
                return result
            }, {}),
        }
    }
    getRecord = (objectId, recordId) => this.records[recordId]
    // When the user selectds records in related record fields we
    // want to add those selected records into our store (if they don't exist)
    onRecordsSelected = (records: RecordDto[]) => {
        records
            .filter((x) => !!x)
            .forEach((record) => (this.records[record._sid] = this.records[record._sid] || record))
    }
}

function useFetchOptions(
    account: Account | null,
    object: ObjectDto | undefined,
    orderBy?: { id: string; desc?: boolean },
    ignorePreviewAndImpersonation?: boolean
) {
    if (!object) return {}
    return {
        dereference: true,
        dereferenceMultiFields: '*',
        pageSize: account?.optional_features?.datagrid_max_records || 1000,
        orderBy,
        searchFields: '*',
        disablePartials: true,
        bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
    }
}

const ObjectDataGrid: React.VFC<Props> = ({
    objectId,
    hideLabel,
    setIsDirty,
    isActive,
    containerStyle,
    hideToolbar,
    showOnlySyncButtons,
    ignorePreviewAndImpersonation,
    requiredFields,
    onFieldCreated: onFieldCreatedCallback,
}) => {
    useOverrideImage()
    const { object, changeObject: updateObject } = useObject(objectId)
    const { selectedStack, workspaceAccount } = useAppContext()
    const { track } = useTrack()
    const { isOpen: newRecordIsOpen, onToggle: onToggleNewRecordModal } = useDisclosure()
    const { data: dataConnection } = useDataConnection(object?.data_connection)
    const containerRef = useRef<HTMLElement | undefined>()
    // ORDERING
    const [apiOrderBy, setAPIOrderBy] = useState<{ id: string; desc?: boolean }>()

    // EDIT/ADD FIELD
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>()
    const [popoverPlacement, setPopoverPlacement] = useState<Placement>()
    const [openPopover, setOpenPopover] = useState<boolean>(false)
    const [editedField, setEditedField] = useState<FieldDto | null>()

    // MODALS and Dropdowns

    const { readOnly, setReadOnly } = useContext(DataGridContext)

    const fetchOptions = useFetchOptions(
        workspaceAccount,
        object,
        apiOrderBy,
        ignorePreviewAndImpersonation
    )

    const [filters, setFilters] = useState<any[]>([])
    const {
        data: { records: allRecords = [], dereferencedRecords, resultInfo = {} } = {},
        refetch: refetchRecords,
        isLoading,
        isError,
    } = useRecords({
        objectId,
        filters,
        fetchOptions,
    })

    const { total_results: totalResults } = resultInfo
    const cellDataProvider = useMemo(() => new CellProvider(), [])
    useEffect(() => {
        cellDataProvider.setRecords(allRecords.concat(dereferencedRecords))
    }, [allRecords, cellDataProvider, dereferencedRecords])

    const dispatch = useDispatch()
    const isReadOnly = object?.connection_options?.read_only
    const isStackerTable = dataConnection?.type === 'native_tables'
    const canRecordsBeEdited = !!object && getAreObjectRecordsEditable(object)
    const allowDelete = recordDeletionAvailable(object, selectedStack)

    const dataGridRef = useRef<DataGridEditorHandle | null>(null)
    const objectDataGridToolbarRef = useRef<ObjectDataGridToolbarHandle | undefined>()

    const [selectedRecords, setSelectedRecords] = useState<RecordDto[]>([])

    useEffect(() => track('WIP - Frontend - DataGrid - Viewed'), [track])

    useEffect(() => {
        if (isActive) {
            refetchRecords()
        }
    }, [isActive, refetchRecords])

    // allRecords comes with the dereferenced linked records as well,
    // so we need to filter to just include records from this object.
    // NOTE: we're not currently supporting filters, but when we do, we'll need to
    // apply those filters locally too as we may have linked records of this
    // object type included in the list of all records, but which aren't included in the
    // filtered result set.
    const records = useMemo(
        () => allRecords.filter((record) => record._object_id === objectId),
        [allRecords, objectId]
    )

    const [search, setSearch] = useState<string | undefined>()

    const applySearch = (search?: string) => setFilters(translateEndUserFilters({}, [], search))
    const debounceApplySearch = useDebounce(applySearch)
    const onSearch = React.useCallback(
        (newVal?: string) => {
            setSearch(newVal)
            debounceApplySearch(newVal)
        },
        [debounceApplySearch]
    )

    /**
     * When the popover close, we remove the virtual element for editing
     */
    useEffect(() => {
        if (!openPopover) {
            document
                .querySelectorAll(`[data-testid="${EDIT_FIELD_HEADER_COLUMN_ID}"]`)
                .forEach((e) => e.remove())
        }
    }, [openPopover])

    const onFieldCreated = async (field: FieldDto) => {
        track('WIP - Frontend - DataGrid - Add Field - Saved')
        await refetchRecords()
        dataGridRef.current?.onFieldCreated(field)

        if (object?.options?.fields_order) {
            await updateObject(
                {
                    options: {
                        ...object.options,
                        fields_order: [...object.options.fields_order, field.api_name],
                    },
                },
                {
                    allowOptimisticUpdates: true,
                }
            )
        }

        onFieldCreatedCallback?.(field)
    }

    const onFieldEdited = async (field: FieldDto, updatedField?: FieldDto): Promise<void> => {
        track('WIP - Frontend - DataGrid - Field Editor - Saved')
        // If the formula changes, we retrieve all records again to show updated results
        if (!updatedField) return
        if (
            isFormulaField(field) ||
            updatedField.type !== field.type ||
            updatedField.options?.lookup_target !== field.options?.lookup_target
        ) {
            refetchRecords()
        }
    }

    const handleAddField = () => {
        track('WIP - Frontend - DataGrid - Add Field - Clicked')
        const addFieldHeaderButton = document.querySelector(
            `[data-testid="${ADD_FIELD_HEADER_BUTTON_ID}"]`
        ) as HTMLElement
        const stickyAddFieldButton = document.querySelector(
            `[data-testid="${STICKY_ADD_FIELD_BUTTON_ID}"]`
        ) as HTMLElement

        setEditedField(null)
        if (addFieldHeaderButton) {
            setPopoverAnchor(addFieldHeaderButton)
            setPopoverPlacement('bottom-start')
        } else if (stickyAddFieldButton) {
            setPopoverAnchor(stickyAddFieldButton)
            setPopoverPlacement('left-start')
        }
        setOpenPopover(!openPopover)
    }

    const handleHideAddField = useCallback(() => {
        setOpenPopover(false)
    }, [])

    const handleEditField = (field: FieldDto) => {
        track('WIP - Frontend - DataGrid - Field Editor - Clicked')
        const el = document.querySelector(
            `[data-testid="${EDIT_FIELD_HEADER_COLUMN_ID}"]`
        ) as HTMLElement
        if (!el) return
        setEditedField(field)
        setPopoverAnchor(el)
        setPopoverPlacement('bottom-start')
        setOpenPopover(!openPopover)
    }

    const handleUpdateRecord = async (id, patch) => {
        await updateRecord(id, patch, {
            deferStoreUpdate: true,
            bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
        })
    }

    const handleAddRecord = async (record: RecordDto): Promise<RecordDto> => {
        const newRecord = (await createRecord(
            { ...record, object_id: objectId },
            {
                bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
            }
        )) as RecordDto
        await refetchRecords()
        return newRecord
    }

    const handleImportRawRecords = async (
        changes: {
            _sid: string | null
            data: { [keyof: string]: string }
        }[]
    ): Promise<void> => {
        await importRawRecords(objectId, changes, {
            bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
        })
        await refetchRecords()
    }

    const handleDeleteRecords = async (recordIds: string[]) => {
        track('WIP - Frontend - DataGrid - Records - Deleted', { records: recordIds.length })
        if (object?._sid)
            await deleteRecords(object._sid, recordIds, {
                bypassPreviewAndImpersonation: ignorePreviewAndImpersonation,
            })
        // Remove records from redux
        recordIds.forEach((id) => dispatch({ type: RECORD_REMOVED, id }))
        await refetchRecords()
    }

    const handleFieldOrderChange = async (fieldsOrder: string[]) => {
        if (!object) return

        await updateObject(
            {
                options: {
                    ...object.options,
                    fields_order: fieldsOrder,
                },
            },
            {
                allowOptimisticUpdates: true,
            }
        )
    }

    const closeNewRecordModal = async (record: RecordDto) => {
        if (record) {
            track('WIP - Frontend - DataGrid - New Row - Created')
            await refetchRecords()
        }
        onToggleNewRecordModal()
    }

    const onDataCopied = () => {
        track('WIP - Frontend - DataGrid - Copy Data')
    }
    const onDataPasted = () => {
        track('WIP - Frontend - DataGrid - Paste Data')
    }

    const onColumnHeaderClick = (_field: FieldDto) => {
        track('WIP - Frontend - DataGrid - Column Header - Clicked')
    }

    useEffect(() => {
        if (apiOrderBy?.id) {
            track('WIP - Frontend - DataGrid - Column Header - Clicked - Sorting', {
                direction: apiOrderBy?.desc ? 'desc' : 'asc',
                field: apiOrderBy?.id,
            })
        }
    }, [apiOrderBy, track])
    const fieldName = 'field'

    if (!object) return null
    return (
        <Flex
            ref={containerRef}
            column
            wrap="nowrap"
            width="100%"
            height="100%"
            borderBottomRightRadius={8}
            align="stretch"
            data-dataconnectiontype={dataConnection?.type}
        >
            {dataConnection && !hideToolbar && selectedRecords.length === 0 && (
                <ObjectDataGridToolbar
                    ref={objectDataGridToolbarRef}
                    dataConnection={dataConnection}
                    hideLabel={hideLabel}
                    showOnlySyncButtons={showOnlySyncButtons}
                    object={object}
                    search={search}
                    onSearch={onSearch}
                    onModalToggled={setReadOnly}
                />
            )}

            {selectedRecords.length > 0 && (
                <SelectedRecordsToolbar
                    onDeleteClick={() => dataGridRef.current?.deleteSelectedRecords()}
                />
            )}

            <FieldEditorPopover
                open={openPopover}
                placement={popoverPlacement}
                container={containerRef.current}
                target={popoverAnchor}
                objectId={objectId}
                field={editedField}
                onSuccess={(field) => {
                    setOpenPopover(false)
                    if (editedField) {
                        onFieldEdited(field, editedField)
                    } else {
                        onFieldCreated(field)
                    }
                }}
                onCancel={() => {
                    setOpenPopover(false)
                }}
                onClose={() => {
                    setOpenPopover(false)
                }}
                ignorePreviewAndImpersonation={ignorePreviewAndImpersonation}
            />

            <Box position="relative" flexGrow={1} px={2} pb={2} {...containerStyle}>
                <Box
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    border={`1px solid ${colors.userInterface.neutral[500]} `}
                    borderRadius="5px"
                >
                    <LoadingScreen isLoading={isLoading}>
                        {isError ? (
                            <ErrorState
                                showSettings={() =>
                                    objectDataGridToolbarRef.current?.showSettings()
                                }
                                showFields={() => objectDataGridToolbarRef.current?.showFields()}
                            />
                        ) : (
                            <DataGridEditor
                                addFieldButtonConfig={{
                                    title: `Add ${fieldName}`,
                                    width: 102,
                                }}
                                fieldName={fieldName}
                                ref={dataGridRef}
                                object={object}
                                records={records}
                                orderBy={apiOrderBy}
                                setOrderBy={setAPIOrderBy}
                                addField={handleAddField}
                                onHideAddField={handleHideAddField}
                                cellDataProvider={cellDataProvider}
                                updateRecord={canRecordsBeEdited ? handleUpdateRecord : undefined}
                                onColumnsOrderChanged={handleFieldOrderChange}
                                addRecord={!isReadOnly ? handleAddRecord : undefined}
                                importRawRecords={!isReadOnly ? handleImportRawRecords : undefined}
                                editField={readOnly ? undefined : handleEditField}
                                dataSourceSupportsPasting={isStackerTable}
                                dataSourceLabel={dataConnection?.label || ''}
                                onDataCopied={onDataCopied}
                                onDataPasted={onDataPasted}
                                onColumnHeaderClick={onColumnHeaderClick}
                                deleteRecords={
                                    !isReadOnly && allowDelete ? handleDeleteRecords : undefined
                                }
                                setIsDirty={setIsDirty}
                                requiredFields={requiredFields}
                                bypassPreviewAndImpersonation={ignorePreviewAndImpersonation}
                                onRecordsSelected={setSelectedRecords}
                            />
                        )}
                    </LoadingScreen>
                </Box>
                {object && (
                    <NewRecordModal
                        isOpen={newRecordIsOpen}
                        onClose={closeNewRecordModal}
                        object={object}
                    />
                )}
            </Box>
            {totalResults > 1000 && (
                <Banner icon="info" m={2}>
                    Only showing the first 1000 of {totalResults} records.
                </Banner>
            )}
        </Flex>
    )
}

export default ObjectDataGrid
