import React, { useCallback, useMemo, useState } from 'react'
import { components } from 'react-select'

import { orderBy } from 'lodash'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { useUpdateStack } from 'data/hooks/stacks'
import { invalidateAppUsersList } from 'data/hooks/users/invalidateAppUsersList'
import {
    refechWorkspaceUsersList,
    useAddWorkspaceUser,
    useWorkspaceUsers,
} from 'data/hooks/users/main'
import { UserAccessEditor } from 'features/admin/settings/common/UserAccessEditor'

import { Avatar, Box, Button, Dropdown, Flex, Icon, LoadingScreen } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../utils/analytics'

export default AppSharingSettings

// Stubbed in support for user "groups". Currently we only have one group: workspace collaborators.
// But eventually we'll allow custom groups at the workspace level. This code will need to be extended
// then to include those groups.
const workspaceGroups = [{ _sid: 'workspace', name: 'Workspace Collaborators' }]

// returns the users filtered down to those belonging to the specified group
// (stubbed in for now, will need extended when we actually support custom groups)
const _getGroupUsers = (groupId, users) => {
    switch (groupId) {
        case 'workspace':
            return users
    }
}
const provideUserRole = (user) => user.role
function AppSharingSettings({ formRef, children }) {
    const { selectedStack: stack } = useAppContext()
    const { data: allUsers = [], isLoading } = useWorkspaceUsers()
    const { data: roles } = useStackRoles()
    const { mutateAsync: updateStack } = useUpdateStack()

    const users = useMemo(() => allUsers.filter(({ admin, _sid }) => admin), [allUsers])
    const roleOptions = useMemo(
        () => roles.map((role) => ({ value: role.api_name, label: role.label })),
        [roles]
    )

    const getGroupUsers = useCallback((groupId) => _getGroupUsers(groupId, allUsers), [allUsers])

    // Get the users who already have access:
    const usersWithRoles = useMemo(() => {
        let userList = users
            .map((item) => ({ ...item, role: stack?.sharing?.users?.[item._sid] }))
            .filter((item) => item.role)
        userList = orderBy(userList, (u) => u.name?.toLowerCase())

        let groupList = workspaceGroups
            .map((item) => ({
                ...item,
                name: `${item.name} (${getGroupUsers(item._sid).length})`,
                role: stack?.sharing?.groups?.[item._sid],
                isGroup: true,
            }))
            .filter((item) => item.role)
        groupList = orderBy(groupList, (u) => u.name?.toLowerCase())

        const result = [...groupList, ...userList]

        return result
    }, [stack, users, getGroupUsers])

    // The users without access which can be added
    const usersWithoutRoles = useMemo(() => {
        let userList = users.filter((user) => !stack?.sharing?.users?.[user._sid])
        userList = orderBy(userList, (u) => u.name?.toLowerCase())

        let groupList = workspaceGroups
            .map((item) => ({
                ...item,
                name: `${item.name} (${getGroupUsers(item._sid).length})`,
                role: stack?.sharing?.groups?.[item._sid],
                isGroup: true,
            }))
            .filter((item) => !item.role)
        groupList = orderBy(groupList, (u) => u.name?.toLowerCase())
        return [...groupList, ...userList]
    }, [stack, users, getGroupUsers])

    const saveChanges = (patch) => {
        const sharing = Object.keys(patch).reduce(
            (result, key) => {
                const record = patch[key]

                const value = record.deleted ? null : record.role
                // if this is a group, update the groups list
                if (workspaceGroups.find((x) => x._sid === key)) {
                    result.groups[key] = value
                    // Otherwise, update the users list
                } else {
                    result.users[key] = value
                }

                return result
            },
            { groups: {}, users: {} }
        )

        const admins = usersWithRoles.filter((user) =>
            patch[user._sid]
                ? patch[user._sid].role === 'internal_admin' && !patch[user._sid].deleted
                : stack?.sharing?.users?.[user._sid] === 'internal_admin'
        )

        if (admins.length === 0) {
            throw new Error('There must be at least one admin user.')
        }
        return updateStack({
            id: stack._sid,
            patch: { sharing_patch: sharing },
        })
    }
    if (isLoading) {
        return <LoadingScreen isLoading={true} width="100%" height="100%" />
    }
    return (
        <Flex column width="100%" height="100%" maxHeight="100%" wrap="nowrap" align="stretch">
            <div
                style={{
                    background: V4DesignSystem.colors.gray[10],
                    padding: '0px 20px',
                    paddingBottom: '15px',
                    borderBottom: '1px solid ' + V4DesignSystem.colors.gray[100],

                    marginBottom: '-5px',
                }}
            >
                <AddAppSharingSettingsUser
                    users={usersWithoutRoles}
                    usersCount={users.length}
                    stack={stack}
                    roles={roleOptions}
                    allUsers={allUsers}
                    width="100%"
                />
            </div>
            <UserAccessEditor
                formRef={formRef}
                onSave={saveChanges}
                users={usersWithRoles}
                roleOptions={roleOptions}
                provideUserRole={provideUserRole}
                provideGroupUsers={getGroupUsers}
                onlySubmitChangedUsers
            >
                {children}
            </UserAccessEditor>
        </Flex>
    )
}

const isEmailRegEx = /^(.+)@(.+)$/
function AddAppSharingSettingsUser({ users, allUsers, stack, roles }) {
    const [selectedUser, setSelectedUser] = useState()
    const { hasRight, user } = useAccountUserContext()
    const [selectedRole, setSelectedRole] = useState()
    const [emailAddress, setEmailAddress] = useState()
    const { selectedStack } = useAppContext()
    const { mutateAsync: updateStack } = useUpdateStack()

    const { mutateAsync: addUser } = useAddWorkspaceUser()
    const canInvite = hasRight(Rights.InviteUsers)
    const addUserToSharingSettings = (userId, role) => {
        const user = users.find((u) => u._sid === userId)
        const patch = updateStackSharingSettings(
            selectedStack,
            user?.isGroup ? 'groups' : 'users',
            {
                [userId]: role,
            }
        )
        return updateStack({ id: selectedStack._sid, patch }).then(() => {
            invalidateAppUsersList()
        })
    }
    const handleInvite = () => {
        // Add the user to the workspace first, then add to the app
        return addUser({ email: emailAddress, options: { role: 'member' } }).then((response) => {
            return refechWorkspaceUsersList().then(() =>
                addUserToSharingSettings(response._sid, selectedRole).then(() => {
                    setEmailAddress(null)
                    setSelectedUser(null)
                    // @TRACKING | app user invited
                    analytics.track('app user invited', {
                        app_id: selectedStack?._sid,
                        workspace_id: user.account_id,
                        user_id: user._sid,
                        event_category: 'app',
                        event_description: 'New app user was invited',
                        referred_user_email: emailAddress,
                        referred_user_role: selectedRole,
                    })
                    analytics.identify(user._sid, {
                        invites_sent: 1,
                    })
                    // note this is an email invite - at this point the user has been added to both the workspace and app successfully
                    // I am unclear whether we want to also track workspace invite as a distinct event as part of this flow
                    // but if so, we could do it either in the top .then() above (to be very accurate), or here (probably also fine)
                })
            )
        })
    }

    const handleSubmit = () => {
        if (emailAddress) {
            return handleInvite()
        } else {
            return addUserToSharingSettings(selectedUser, selectedRole).then(() =>
                setSelectedUser(null)
            )
        }
    }
    const userOptions = users.map((user) => ({
        user,
        value: user._sid,
        label: `${user.name} ${user.email}`,
    }))

    if (emailAddress) {
        userOptions.push({ isInvitation: true, value: emailAddress, label: emailAddress })
    }
    const getNoOptionsMessage = ({ inputValue }) => {
        const existingUser = allUsers.find((x) => x.email === inputValue)
        if (existingUser) {
            return `${existingUser.name} already has access to this app`
        }

        if (canInvite) {
            return 'Enter an email address to invite someone.'
        }

        return 'No matching users found.'
    }

    const handleInputChange = (value) => {
        if (isEmailRegEx.test(value) && !allUsers.find((x) => x.email === value)) {
            setEmailAddress(value)
        }
    }

    const placeholder = canInvite
        ? userOptions.length > 0
            ? 'Select user or enter email address'
            : 'Enter an email address'
        : 'Select user'
    return (
        <>
            <Flex wrap="nowrap" align="stretch">
                <Dropdown
                    placeholder={placeholder}
                    style={{ flexShrink: 1, flexGrow: 1, minWidth: 0, background: 'white' }}
                    onChange={(userId) => {
                        if (userId !== emailAddress) setEmailAddress(null)
                        setSelectedUser(userId)
                    }}
                    value={selectedUser || emailAddress}
                    padding="5px"
                    noOptionsMessage={getNoOptionsMessage}
                    optionComponent={UserDropdownOption}
                    options={userOptions}
                    onInputChange={handleInputChange}
                    renderValue={({ user, label }) =>
                        user ? (
                            <Flex align="center">
                                <Avatar src={user.avatar} name={user.name} size="xs" mr={2} />
                                <strong>{user.name}</strong>
                            </Flex>
                        ) : (
                            label
                        )
                    }
                />
                <Box width={2} height={2} />
                <SelectRole
                    // roles={[...roles, { label: 'No access', value: undefined }]}
                    roles={roles}
                    placeholder="Select a role"
                    selected={selectedRole}
                    onChange={setSelectedRole}
                    variant="settings"
                />
                <Button
                    ml={2}
                    variant="adminPrimaryV4"
                    alignSelf="center"
                    buttonSize="sm"
                    flexShrink={0}
                    disabled={
                        (!selectedUser && !emailAddress) ||
                        selectedRole === stack.sharing?.users?.[selectedUser]
                    }
                    onClick={() => {
                        analytics.track('app sharing user added', {
                            selectedUser,
                            selectedRole,
                        })

                        handleSubmit()
                    }}
                >
                    {emailAddress || userOptions.length === 0 ? 'Invite' : 'Add'}
                </Button>
            </Flex>
        </>
    )
}

function UserDropdownOption(props) {
    const user = props.data?.user
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props
    return (
        <components.Option {...rest}>
            {user ? (
                <Flex align="center">
                    {user.isGroup ? (
                        <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                            <Icon icon="users" size="sm" color="gray.400" />
                        </Flex>
                    ) : (
                        <Avatar src={user.avatar} name={user.name} size="xs" mr={2} />
                    )}
                    <strong>{user.name}</strong>
                </Flex>
            ) : (
                <Flex align="center" cursor="pointer">
                    <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                        <Icon icon="email" size="sm" color="gray.400" />
                    </Flex>
                    Invite {props.children}
                </Flex>
            )}
        </components.Option>
    )
}

export function SelectRole({ selected, onChange, roles, placeholder = 'No access', ...props }) {
    return (
        <Dropdown
            value={selected}
            options={roles}
            placeholder={placeholder}
            onChange={onChange}
            isSearchable={false}
            {...props}
        />
    )
}
function updateStackSharingSettings(stack, path, update) {
    return {
        sharing_patch: {
            [path]: {
                ...update,
            },
        },
    }
}
