import React, { FC, MouseEvent, useMemo } from 'react'

import { Menu, MenuButton, MenuList } from '@chakra-ui/react'

import { useProcessFilter } from 'features/records/components/RecordFilters'

import { Button, ConditionalWrapper, Flex } from 'v2/ui'

import { isSomeStepValid } from './utils/isSomeStepValid'
import { validateStepFieldsConditionalVisibility } from './utils/validateFieldConditionalVisibility'
import ActionButtonComponent, { ActionButtonProps } from './ActionButton'

const useAvailableActions = (
    actions: { action: ActionDto; config: ActionButton; Component?: FC<ActionButtonProps> }[],
    record: RecordDto
) => {
    const processFilter = useProcessFilter()
    return useMemo(
        () =>
            actions
                .filter((x) => x)
                .filter(
                    ({ config }) =>
                        !config.conditions?.length ||
                        (record && processFilter([record], config?.conditions).length > 0)
                )
                .filter(
                    ({ action }) =>
                        !action.options?.steps ||
                        isSomeStepValid(action, [
                            (step) =>
                                validateStepFieldsConditionalVisibility(
                                    step,
                                    record,
                                    processFilter
                                ),
                        ])
                ),
        [actions, record, processFilter]
    )
}

type Props = {
    actions: { action: ActionDto; config: ActionButton; Component?: FC }[]
    record: RecordDto
    maxVisible: number
    renderWrapper?: (children: React.ReactNode) => React.ReactElement
    hideMoreItemsDropdown?: boolean
    getActionProps?: (args: { index: number }) => object
}

const ActionButtonList: FC<Props> = ({
    actions,
    record,
    maxVisible,
    renderWrapper = undefined,
    hideMoreItemsDropdown = false,
    getActionProps = () => ({}),
}) => {
    const availableItems = useAvailableActions(actions, record)

    const stopBubbling = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    if (availableItems.length === 0) return null
    return (
        <ConditionalWrapper condition={!!renderWrapper} wrapper={renderWrapper!}>
            <>
                {availableItems
                    .slice(0, maxVisible)
                    .map(({ action, Component, ...props }, index) => {
                        const Render = Component || ActionButtonComponent

                        return (
                            <Render
                                key={action._sid}
                                item={action}
                                record={record}
                                recordId={record?._sid}
                                ml={2}
                                maxWidth={190}
                                {...props}
                                {...getActionProps({ index })}
                            />
                        )
                    })}
                {maxVisible < availableItems.length && !hideMoreItemsDropdown && (
                    <Flex onClick={stopBubbling}>
                        <Menu placement="bottom-end">
                            {({ isOpen }) => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        iconProps={{ pr: 0 }}
                                        // @ts-ignore
                                        isActive={isOpen}
                                        size="sm"
                                        icon="dotsV"
                                        variant="clear"
                                        ml={2}
                                    />

                                    <MenuList
                                        minW={2}
                                        p={2}
                                        zIndex={2}
                                        minWidth={150}
                                        maxWidth={400}
                                    >
                                        {availableItems
                                            .slice(maxVisible)
                                            .map(({ action, Component, ...props }) => {
                                                const Render = Component || ActionButtonComponent
                                                return (
                                                    <Render
                                                        key={action._sid}
                                                        item={action}
                                                        record={record}
                                                        recordId={record?._sid}
                                                        role="menuitem"
                                                        variant="actionListButton"
                                                        ml={0}
                                                        {...props}
                                                    />
                                                )
                                            })}
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                    </Flex>
                )}
            </>
        </ConditionalWrapper>
    )
}

export default ActionButtonList
