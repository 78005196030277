/* Code Quality: Not audited */

import React from 'react'

import WithBlocks from 'data/wrappers/WithBlocks'

import { Dropdown } from 'v2/ui'

class BlockPicker extends React.Component {
    render() {
        return (
            <WithBlocks>
                {({ blocks }) => {
                    return (
                        <Dropdown
                            onChange={this.props.onChange}
                            placeholder="Select block"
                            clearable={false}
                            options={blocksToOptions(blocks || [])}
                            value={this.props.value}
                        />
                    )
                }}
            </WithBlocks>
        )
    }
}

const blocksToOptions = (block) => {
    return block.map((record) => ({
        label: record.name,
        value: record._sid,
    }))
}

BlockPicker.propTypes = {}

export default BlockPicker
