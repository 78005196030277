import useSlidingPane from './useSlidingPane'

/**
 * Proxy call for opening the users UI i.e. in a modal or the side pane depending on LD flag
 */
export const useOpenAppUsers = () => {
    const { showManageUsers } = useSlidingPane()

    return showManageUsers
}
