import { queryClient } from '../_helpers'
/**
 * Gets the cached record from the query state
 * but only if the data has not been invalidated
 * @param {string} recordId
 */

export const getRecordFromState = (recordId: string): RecordDto | undefined => {
    const queryKey = ['record', recordId]
    const queryState = queryClient.getQueryState(queryKey)

    if (queryState?.data && !queryState.isFetching && !queryState.isInvalidated) {
        return queryState.data as RecordDto
    }

    return undefined
}
