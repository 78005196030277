import {
    getCurrentUserProfileRecordId,
    getCurrentUserRecordField,
    getCurrentUserRecordId,
} from 'data/wrappers/withUserUtils'
import { encodeCommas } from 'features/utils/escapeCommas'
import { currentUserOptions, userFieldOptions } from 'features/utils/filtersToQueryDict'

const emptyValueChartsOperations = new Set<ChartFilterOperation>(['isempty', 'isnotempty'])

const arrayChartsOperations = new Set<ChartFilterOperation>(['containsany', 'containsnone'])

// mimics filtersToQueryDict.preprocessFilterValue, but works with BE-style operations
export const preprocessChartsFilterValue = (filter: ChartFilter) => {
    let newValue: string | string[] = !Array.isArray(filter.value)
        ? String(filter.value)
        : filter.value

    // For the current user filters, send through the current user sid
    if (filter.option && currentUserOptions.includes(filter.option)) {
        newValue =
            ((!!filter.objectId && getCurrentUserProfileRecordId(filter.objectId)) ||
                getCurrentUserRecordId()) ??
            ''
    } else if (
        filter.option &&
        userFieldOptions.includes(filter.option) &&
        !Array.isArray(newValue)
    ) {
        newValue = getCurrentUserRecordField(newValue) ?? ''
    }

    if (arrayChartsOperations.has(filter.operation) && !Array.isArray(newValue)) {
        newValue = [newValue]
    } else if (emptyValueChartsOperations.has(filter.operation)) {
        newValue = 'true'
    }

    if (!filter.target?.startsWith('_')) {
        newValue = encodeCommas(newValue)
    }
    return newValue
}
