import React, { FC } from 'react'

import { useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Button, DropdownButton, Flex, Icon, Text } from 'v2/ui'
import { SolidMetrics, WidgetCharts } from 'v2/ui/svgs'

const Option = styled(Button)`
    width: 100%;
    padding: 0.5em;

    justify-content: start;
    gap: 0.25em;

    color: ${({ theme }) => theme.colors.userInterface.neutral[1000]};})}
    font-weight: normal;
`

type Props = {
    onAddMetrics: () => void
    onAddCharts: () => void
}

export const SelectWidgetArea: FC<Props> = ({ onAddMetrics, onAddCharts }) => {
    const theme = useTheme()

    return (
        <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="userInterface.accent.1000"
            borderRadius="6px"
            p={5}
        >
            <Icon
                icon="faObjectSubtract"
                size="20px"
                fill={theme.colors.userInterface.accent[1000]}
            />
            <DropdownButton
                variant="clear"
                buttonContent={
                    <>
                        <Text fontSize="smmd" mr={1}>
                            Select widget
                        </Text>
                        <Icon icon="arrowDown" />
                    </>
                }
                bodyPadding="0.5em"
                dropdownBoxStyle={{ width: '170px', borderRadius: '8px', marginTop: '35px' }}
                hideIcon
            >
                {({ close }) => (
                    <Flex
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Option
                            variant="Tertiary"
                            size="sm"
                            icon={<SolidMetrics />}
                            onClick={() => {
                                onAddMetrics()
                                close()
                            }}
                        >
                            <Text fontWeight="normal">Big numbers</Text>
                        </Option>
                        <Option
                            variant="Tertiary"
                            size="sm"
                            icon={<WidgetCharts />}
                            onClick={() => {
                                onAddCharts()
                                close()
                            }}
                        >
                            <Text fontWeight="normal">Charts</Text>
                        </Option>
                    </Flex>
                )}
            </DropdownButton>
        </Flex>
    )
}
