import React from 'react'

import { DropdownInput } from './DropdownInput'
import { InlineFilterInputComponent } from './types'

type MultiSelectInputProps = {}

export const MultiSelectInput: InlineFilterInputComponent<MultiSelectInputProps> = (props) => {
    switch (props.field.type) {
        case 'dropdown':
        case 'lookup':
        case 'multi_lookup':
        case 'multi_select':
            return <DropdownInput singleValue={false} {...props} />
        default:
            return null
    }
}
