export const getFieldsForConnectionType = (
    connectionType: DataConnectionType
): Record<string, string>[] => {
    if (connectionType === 'airtable') {
        return [
            {
                api_name: 'has_credentials',
                label: 'Connected',
                type: 'checkbox',
            },
            { api_name: '_sid', label: 'Connection SID', type: 'dropdown' },
            { api_name: 'options.app_id', label: 'Base ID' },
            { api_name: 'user_oauth_token', label: 'Global Token', type: 'checkbox' },
            { api_name: 'userDisplayName', label: 'Token Owner' },
        ]
    } else if (connectionType === 'postgresV2') {
        return [
            { api_name: '_sid', label: 'Connection SID', type: 'dropdown' },
            { api_name: 'is_read_only', label: 'Is Read-only?', type: 'checkbox' },
            {
                api_name: 'options.allow_multiple_primary_keys',
                label: 'Are multiple primary keys allowed?',
                type: 'checkbox',
            },
            {
                api_name: 'options.sql_ignore_relations',
                label: 'Ignored SQL tables/views',
            },
        ]
    }

    return [{ api_name: '_sid', label: 'Connection SID', type: 'dropdown' }]
}
