/* Code Quality: OK */
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAppUserContext } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'

import AuthPageFrame from './stacker-auth/AuthPageFrame'
import EditProfile from './stacker-auth/EditProfile'

export function EditProfilePage() {
    const { user } = useAppUserContext()
    const history = useHistory()

    const onComplete = () => {
        history.push(getUrl(Urls.Root))
    }
    return (
        <AuthPageFrame title="Create your profile">
            <EditProfile user={user} onSuccess={onComplete} />
        </AuthPageFrame>
    )
}

export default EditProfilePage
