import styled from '@emotion/styled'

const PageTitle = styled('div')`
    font-size: ${(props) => props.theme.pageTitleSize};
    color: ${(props) => props.theme.pageTitleColor};
    font-weight: ${(props) => props.theme.pageTitleWeight};
    font-family: ${(props) => props.theme.pageTitleFont};
    margin: ${(props) => props.theme.medium} 0px;
`

PageTitle.displayName = 'PageTitle'
export default PageTitle
