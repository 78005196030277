export function getPageSizeOptions(display?: string, showAllPagesSizes?: boolean): number[] {
    if (!showAllPagesSizes) {
        return PAGE_SIZES[display!] || PAGE_SIZES.default
    }
    return LONGER_PAGE_SIZES[display!] || LONGER_PAGE_SIZES.default
}

export function getDefaultPageSize(display?: string): number {
    return DEFAULT_PAGE_SIZE[display!] || DEFAULT_PAGE_SIZE.default
}

export const PAGE_SIZES: Record<string, number[]> = {
    default: [10, 20, 30],
    card: [12, 24, 36],
}

export const LONGER_PAGE_SIZES: Record<string, number[]> = {
    default: [10, 20, 30, 40, 50],
    card: [12, 24, 36, 48],
}
export const DEFAULT_PAGE_SIZE: Record<string, number> = {
    default: 10,
    card: 12,
}
