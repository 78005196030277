// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import * as Sentry from '@sentry/react'

import { getCurrentStackId } from 'app/AppContextStore'
import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'
import GSheetsPicker from 'data/gsheets/GSheetsPicker'
import { getUserApiToken, Token } from 'data/utils/getUserToken'
import { assertIsDefined } from 'data/utils/ts_utils'

// @ts-ignore
import { Button, Flex, Icon, Text } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import { DataConnectionEditorContextType } from '../dataConnectionEditorContext'
import DATA_PROVIDERS from '../dataProviderConfig'

import FullSchemaSync from './FullSchemaSync'

const STEPS = {
    initial: '',
    pickSheet: 'pick',
    picking: 'picking',
    syncSchema: 'sync',
}

type GSheetsConnectionEditorProps = {
    onChangeHintImage: (url: string | undefined) => void
    context: DataConnectionEditorContextType
}

const GSheetsConnectionEditor: React.FC<GSheetsConnectionEditorProps> = (props) => {
    const context = props.context
    const { dataConnection, step, schemaSyncOnly } = context
    const { user } = useAppUserContext()
    const [token, setToken] = useState<Token>(undefined)

    useMemo(() => {
        getUserApiToken(user).then((token) => setToken(token))
    }, [user])

    // If we don't yet have a data connection, or the one we have has never been authed
    // render the initialize UI
    if (
        !dataConnection ||
        (!dataConnection._auth_info?.gsheets_access_token && !dataConnection?.options?.sheet_id) ||
        context.step === STEPS.initial
    ) {
        context.setHideButtons(true)
        return <InitializeNewConnection context={context} token={token} />
    } else if (!dataConnection?.options?.sheet_id && context.step !== STEPS.initial) {
        context.setHideButtons(true)
        // We haven't picked a sheet yet, show that screen now
        return <PickSheet context={context} onChangeHintImage={props.onChangeHintImage} />
    } else if (
        // If we are in the synchSchema step,
        // or schemaSyncOnly is true which means we're starting
        // a schema sync for an existing connection,
        // or we have a sheet and we're in the "sheet picking" step,
        // go ahead and do the schema sync.
        step === STEPS.syncSchema ||
        (dataConnection?.options?.sheet_id && step === STEPS.picking) ||
        schemaSyncOnly
    ) {
        // Ready to sync the schema
        return (
            <FullSchemaSync
                dataConnection={dataConnection}
                onStartSync={context.onStartSync}
                onComplete={context.onSyncComplete}
                onError={context.onSyncError}
            />
        )
    } else {
        context.setHideButtons(false)
        // We're just editing an existing connection and we don't show anything
        return null
    }
}

type InitializeNewConnectionProps = {
    context: DataConnectionEditorContextType
    token?: Token
}
/**
 *
 * @param context context from DataConnectionEditor
 * @param token api token for user (if not auth0)
 */
const InitializeNewConnection: React.VFC<InitializeNewConnectionProps> = ({ context, token }) => {
    const {
        dataConnection,
        setNextHandler,
        isSaving,
        setIsSaving,
        createDataConnection,
        setNextButtonText,
        updateDataConnection,
        getOAuthReturnUrl,
        initializeImmediately,
        setModalHidden,
    } = context

    const startOAuth = useCallback(
        (dataConnection) => {
            if (!token) {
                setIsSaving(false)
                return
            }
            const bounceUrl = `${
                settings.OAUTH_REDIRECT_URL
            }authenticate/google?api_token=${token}&stack_id=${getCurrentStackId()}&connection=${
                dataConnection._sid
            }&return_url=${getOAuthReturnUrl(dataConnection)}`

            window.location.assign(bounceUrl)
        },
        [getOAuthReturnUrl, token, setIsSaving]
    )

    // create or update the connection, then start the OAuth flow
    const initializeConnection = useCallback(() => {
        // prevent accidentally creating multiple data connections if a create is already in progress
        if (isSaving) return
        setIsSaving(true)
        if (!dataConnection) {
            createDataConnection().then(startOAuth).catch(Sentry.captureException)
        } else {
            updateDataConnection().then(startOAuth).catch(Sentry.captureException)
        }
    }, [
        isSaving,
        setIsSaving,
        startOAuth,
        createDataConnection,
        updateDataConnection,
        dataConnection,
    ])

    useEffect(() => {
        if (initializeImmediately) {
            // don't show the modal while we're creating the data connection and redirecting
            setModalHidden(true)
            initializeConnection()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we want this to only run once and at the very first render
    }, [])

    useEffect(() => {
        setNextHandler(initializeConnection)
        setNextButtonText('Next')
    }, [initializeConnection, setNextHandler, setNextButtonText])

    return initializeImmediately ? null : (
        <>
            <Text>
                <strong>Proceed</strong> to connect your Google account
            </Text>
            <div style={{ height: 24 }} />
            <div>
                <Button onClick={initializeConnection} buttonSize="md" variant="adminPrimaryV4">
                    Proceed
                </Button>
            </div>
        </>
    )
}

const PickSheet: React.FC<{
    context: DataConnectionEditorContextType
    onChangeHintImage: (url: string) => void
}> = ({ context, ...props }) => {
    const {
        dataConnection,
        updateDataConnection,
        setNextButtonDisabled,
        setStep,
        setIsSaving,
        setModalHidden,
        onClose,
        autoOpenGSheetPicker,
    } = context

    const isMobile = useIsMobile()
    const getHintImage = (i: number): string => {
        assertIsDefined(DATA_PROVIDERS.gsheets.responsiveHintImageUrls)
        assertIsDefined(DATA_PROVIDERS.gsheets.hintImagesUrls)
        return isMobile
            ? DATA_PROVIDERS.gsheets.responsiveHintImageUrls[i]
            : DATA_PROVIDERS.gsheets.hintImagesUrls[i]
    }
    useEffect(() => {
        setNextButtonDisabled(true)
        props.onChangeHintImage(getHintImage(1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [, setConnected] = useUrlParam('connected', '')

    return (
        <div style={{ maxWidth: '350px' }}>
            <Text mb={4}>
                <Icon
                    icon="checkmarkCircleSolid"
                    size="md"
                    display="inline"
                    verticalAlign="middle"
                    color="#21A164"
                    mr={2}
                />
                Google account connected successfully.
            </Text>
            <Text mb={4}>Next, choose a Google Sheet to sync.</Text>
            <Flex
                wrap={'nowrap'}
                alignItems={'flex-start'}
                style={{
                    borderRadius: 3,
                    padding: '8px 11px',
                    background: '#DADFF7',
                    marginBottom: '16px',
                }}
            >
                <Icon icon="info" style={{ color: '#465DD8', marginRight: 11 }} />
                <Flex flexDirection={'column'} alignItems={'flex-start'}>
                    <div>Data must be formatted correctly</div>
                    <a
                        target="docs"
                        href="https://support.stackerhq.com/hc/en-us/articles/4409591852947-Importing-your-Google-Sheets-data"
                    >
                        Learn more
                    </a>
                </Flex>
            </Flex>
            <GSheetsPicker
                buttonLabel={undefined}
                accessToken={dataConnection?._auth_info.gsheets_access_token}
                onPicked={({ sheetId, sheetUrl, sheetName }) => {
                    updateDataConnection({
                        options: {
                            ...dataConnection?.options,
                            sheet_id: sheetId,
                            sheet_url: sheetUrl,
                            sheet_name: sheetName,
                        },
                    })
                        .then(() => {
                            setIsSaving(false)
                            // redisplay the modal now so we can begin the schema sync.
                            setModalHidden(false)
                        })
                        .catch(Sentry.captureException)
                }}
                onLoadFailed={() => setModalHidden(false)}
                // if we're auto opening the picker, if it's cancelled we want to close the modal fully and exit out the flow
                onCancelled={autoOpenGSheetPicker ? onClose : () => setModalHidden(false)}
                onPickerOpened={() => {
                    setStep(STEPS.picking, true)
                    // have to hide our modal as the gsheets picker
                    // won't be able to receive some input events otherwise.
                    setModalHidden(true)
                }}
                autoOpen={autoOpenGSheetPicker}
            />
            <Button
                style={{ left: 168, height: 48, bottom: 0, position: 'absolute' }}
                variant="adminSecondaryV4"
                buttonSize="md"
                onClick={() => {
                    props.onChangeHintImage(getHintImage(0))
                    setStep(STEPS.initial, false)
                    setConnected('')
                }}
            >
                Back
            </Button>
        </div>
    )
}
export default GSheetsConnectionEditor
