import React, { useState } from 'react'

import styled from '@emotion/styled'

import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection, Label } from 'legacy/v1/ui'

import { Button } from 'v2/ui'

import { Checkbox } from 'ui/components/Checkbox'

type ShowBreadCrumbsSettingsProps = {
    selectedStack: StackDto
    onSave: () => void
    onSaveError: () => void
}

export const ShowBreadcrumbsSettings = ({
    selectedStack,
    onSave,
    onSaveError,
}: ShowBreadCrumbsSettingsProps) => {
    const [showBreadcrumbs, setShowBreadcrumbs] = useState<boolean | undefined>(
        selectedStack?.options?.showBreadcrumbs
    )
    const { mutateAsync: updateStack } = useUpdateStack()
    return (
        <ExpandSection
            // @ts-expect-error
            heading={<div style={{ display: 'flex', alignItems: 'center' }}>Breadcrumbs</div>}
            text={null}
        >
            <FlexWrapper>
                {/* @ts-expect-error */}
                <Label as="label" htmlFor="showBreadcrumbs">
                    Show breadcrumbs
                </Label>
                <Checkbox
                    id="showBreadcrumbs"
                    onCheckedChange={() => {
                        setShowBreadcrumbs((prev) => !prev)
                    }}
                    checked={showBreadcrumbs}
                    value={showBreadcrumbs ? 'showbreadcrumbs_on' : 'showbreadcrumbs_off'}
                />
                <StackColorButton
                    primary
                    color="stacker"
                    padding="small"
                    margin="none"
                    style={{ marginLeft: '10px' }}
                    isDisabled={showBreadcrumbs === !!selectedStack?.options?.showBreadcrumbs}
                    onClick={() => {
                        updateStack({
                            id: selectedStack._sid,
                            patch: {
                                options: {
                                    ...selectedStack.options,
                                    showBreadcrumbs: showBreadcrumbs,
                                },
                            },
                        })
                            .then(() => {
                                onSave()
                            })
                            .catch(() => {
                                onSaveError()
                            })
                    }}
                >
                    Save
                </StackColorButton>
            </FlexWrapper>
        </ExpandSection>
    )
}

const FlexWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const StackColorButton = (props: any) => (
    <Button
        variant="adminPrimary"
        buttonSize="sm"
        {...props}
        style={{ ...props.style, flexShrink: 0 }}
    />
)
