import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'

// import { Textarea as Base } from '@chakra-ui/react'
// export const Textarea = props => {
//     return <Base borderColor="input.border" {...props} />
// }
// export default Textarea
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { color, compose, layout, space, typography, variant } from 'styled-system'

import stackerTheme from 'v2/ui/theme/styles/default'
const colors = stackerTheme().colors

// These are copied from Chakra's code
// as we want to imitate the sizing of a regular input
var inputSizes = {
    lg: {
        fontSize: ['input.text', null, null, 'input.textLg'],
        px: 4,
        minHeight: 12,
        py: 3,
        rounded: 'md',
    },
    md: {
        fontSize: ['input.text', null, null, 'input.textLg'],
        px: 4,
        minHeight: 10,
        py: 2,
        rounded: 'md',
    },
    sm: {
        fontSize: ['input.text', null, null, 'input.textLg'],
        px: 3,
        minHeight: 8,
        py: 2,
        rounded: 'sm',
    },
}

const baseStyle = {
    fontSize: ['input.text', null, null, 'input.textLg'],
    px: 3,
    minHeight: 8,
    py: 3,
    rounded: 'sm',
}
const Base = styled(TextareaAutosize)`
    ${compose(typography, space, color, layout)}
    border: 1px solid;
    border-color: ${() => colors.gray[200]};
    transition: border-color 0.2s;
    resize: none;
    border-radius: ${(props) => props.theme.radii.input};

    ${(props) => props.fullWidth && 'width: 100%;'}

    &:hover {
        border-color: ${(props) => props.theme.colors.input?.borderHover};
    }
    &:focus {
        outline: none;
        border: 2px solid;
        border-color: ${(props) => props.theme.colors.input?.borderActive};
    }

    ${variant({
        variants: {
            normal: {
                ...baseStyle,
            },
            admin: {
                ...baseStyle,
                fontSize: ['input.text', null, null, 'input.textLg'],
                borderColor: 'transparent',
                bg: 'admin.input.background',
                boxShadow: 'admin.input',
                '&:focus-within': {
                    borderColor: 'admin.input.borderActive',
                },
            },
        },
    })}
`

const TextArea = ({ size = 'md', ...props }) => {
    return <Base ref={props.forwardRef} {...props} {...inputSizes[size]} />
}

TextArea.defaultProps = { variant: 'normal' }

TextArea.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}

export default React.memo(TextArea)
