import React, { useContext, useMemo, useState } from 'react'

type EditModeListContextData = {
    disableEditControls?: boolean
    lastHoveredRecordId?: string
    hoveredEditorFieldId?: string
    activeEditorFieldId?: string
    setHoveredEditorFieldId: (key: string | undefined) => void
    setActiveEditorFieldId: (key: string | undefined) => void
    setLastHoveredRecordId: (key: string | undefined) => void
}

/* eslint-disable unused-imports/no-unused-vars */
export const defaultContext: EditModeListContextData = {
    setHoveredEditorFieldId: (key: string | undefined) => undefined,
    setActiveEditorFieldId: (key: string | undefined) => undefined,
    setLastHoveredRecordId: (key: string | undefined) => undefined,
}
/* eslint-enable */

export const EditModeListContext = React.createContext(defaultContext)

export const useEditModeListContext = () => {
    return useContext(EditModeListContext)
}

export const EditModeListContextProvider: React.FC<{ disableEditControls?: boolean }> = ({
    disableEditControls,
    children,
}) => {
    const [lastHoveredRecordId, setLastHoveredRecordId] = useState<string | undefined>()
    const [hoveredEditorFieldId, setHoveredEditorFieldId] = useState<string | undefined>()
    const [activeEditorFieldId, setActiveEditorFieldId] = useState<string | undefined>()

    const contextState = useMemo(
        () => ({
            disableEditControls,
            lastHoveredRecordId,
            setLastHoveredRecordId,
            hoveredEditorFieldId,
            setHoveredEditorFieldId,
            activeEditorFieldId,
            setActiveEditorFieldId,
        }),
        [
            disableEditControls,
            lastHoveredRecordId,
            setLastHoveredRecordId,
            hoveredEditorFieldId,
            setHoveredEditorFieldId,
            activeEditorFieldId,
            setActiveEditorFieldId,
        ]
    )

    return (
        <EditModeListContext.Provider value={contextState}>{children}</EditModeListContext.Provider>
    )
}
