import { RefObject, useState } from 'react'

import { cloneDeep } from 'lodash'
import { isBlockContainer } from 'v2/blocks/blockTypesHelpers'

import stackerTheme from 'v2/ui/theme/styles/default'

import type { TreeInstance } from '../../tree/tree'

import type { DeviceType, EditorContextActions, EditorContextState } from './types'
import { copyBlockToLocalstorage, getBlockFromLocalStorage, prepareBlockToAdd } from './utils'

const { colors } = stackerTheme()

type Args = {
    styleNodeRef: RefObject<HTMLStyleElement>
    secondStyleNodeRef: RefObject<HTMLStyleElement>
    deviceType: DeviceType
    treeInstance: TreeInstance
    isEditing: boolean
}

function useEditorHandler({
    styleNodeRef,
    secondStyleNodeRef,
    deviceType: initialDeviceType,
    treeInstance,
    isEditing,
}: Args): { state: EditorContextState; actions: Omit<EditorContextActions, 'updatePage'> } {
    const [deviceType, setDeviceType] = useState<DeviceType>(initialDeviceType)

    const [selected, setSelected] = useState<string | null>(null)
    const [selectedParent, setSelectedParent] = useState<string | null>(null)
    const [selectedBlock, setSelectedBlock] = useState<Block | null>(null)

    const [moving, setMoving] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [cutting, setCutting] = useState(false)
    const [pasting, setPasting] = useState(false)
    const [undoing, setUndoing] = useState(false)

    const [hoveredElement, setHoveredElement] = useState<string | null>(null)

    const [isFormatPainting, setIsFormatPainting] = useState(false)
    const [formatPaintStyles, setFormatPaintStyles] = useState<CSSStyleDeclaration | null>(null)

    const selectBlock: EditorContextActions['selectBlock'] = (blockId) => {
        if (!isEditing || selected === blockId) {
            return
        }

        if (isFormatPainting) {
            treeInstance.updateBlockStyles(blockId, formatPaintStyles)
        }

        // @ts-ignore
        const parentBlockId = treeInstance.parentBlocks?.[blockId]

        setSelected(blockId)
        // @ts-ignore
        setSelectedBlock(treeInstance.indexedBlocks?.[blockId] || null)
        setSelectedParent(parentBlockId)
        setIsFormatPainting(false)
    }

    const deselectBlock: EditorContextActions['deselectBlock'] = () => {
        setSelected(null)
        setSelectedParent(null)
        setSelectedBlock(null)
        setIsFormatPainting(false)
    }

    const moveBlockUp: EditorContextActions['moveBlockUp'] = () => {
        if (moving || !selected) {
            return
        }

        setMoving(true)

        treeInstance.recordHistory()
        treeInstance.moveBlockUp(selected)

        setMoving(false)
    }

    const moveBlockDown: EditorContextActions['moveBlockDown'] = () => {
        if (moving || !selected) {
            return
        }

        setMoving(true)

        treeInstance.recordHistory()
        treeInstance.moveBlockDown(selected)

        setMoving(false)
    }

    const moveBlockToAnotherParentTree: EditorContextActions['moveBlockToAnotherParentTree'] = (
        parentId
    ) => {
        if (moving) {
            return
        }

        setMoving(true)

        treeInstance.recordHistory()
        treeInstance.moveBlock(
            selected,
            parentId,
            // @ts-ignore
            treeInstance.indexedBlocks?.[parentId]?.childBlocks.length
        )

        setMoving(false)
    }

    const updateBlock: EditorContextActions['updateBlock'] = (blockId, patch) => {
        treeInstance.recordHistory()
        treeInstance.updateBlock(blockId, patch)
        treeInstance.updateTree(treeInstance.tree)

        // Update selected block if it's the one that just got updated.
        if (blockId === selected) {
            // @ts-ignore
            setSelectedBlock(treeInstance.indexedBlocks?.[blockId])
        }
    }

    const removeBlock: EditorContextActions['removeBlock'] = (blockId) => {
        if (removing) {
            return
        }

        setRemoving(true)
        setSelected(null)
        setSelectedParent(null)
        setSelectedBlock(null)
        setIsFormatPainting(false)

        treeInstance.recordHistory()
        treeInstance.removeBlock(blockId)
        treeInstance.updateTree(treeInstance.tree)

        setRemoving(false)
    }

    const removeSelected: EditorContextActions['removeSelected'] = () => {
        if (!selected) {
            return false
        }

        removeBlock(selected)
    }

    const formatPaintFromSelected: EditorContextActions['formatPaintFromSelected'] = () => {
        if (!selected) {
            return false
        }

        // @ts-ignore
        setFormatPaintStyles(cloneDeep(treeInstance.indexedBlocks?.[selected]?.config.style))
        setIsFormatPainting(true)
    }

    const copySelected: EditorContextActions['copySelected'] = () => {
        if (!selected) {
            return false
        }

        copyBlockToLocalstorage(treeInstance, selected)
    }

    const cutSelected: EditorContextActions['cutSelected'] = () => {
        setCutting(true)
        if (cutting || !selected) {
            return
        }

        copyBlockToLocalstorage(treeInstance, selected)

        treeInstance.recordHistory()
        removeBlock(selected)

        setCutting(false)
        setSelected(null)
    }

    const pasteAtSelected: EditorContextActions['pasteAtSelected'] = () => {
        if (!selected || pasting) {
            return
        }

        const blockToPaste = getBlockFromLocalStorage()
        if (!blockToPaste) {
            return
        }

        treeInstance.recordHistory()
        setPasting(true)

        // Find the target from the current block
        let pasteTarget: Block
        let pastePosition: number = 0

        // @ts-ignore
        const selectedBlock: Block = treeInstance.indexedBlocks?.[selected]
        const isContainer = isBlockContainer(selectedBlock.type)

        if (isContainer) {
            // If the element selected is a container, then add into it
            pasteTarget = selectedBlock
            pastePosition = pasteTarget.childBlocks.length
        } else {
            // Otherwise, we should paste into this block's parent
            // @ts-ignore
            const blockParent: Block = treeInstance.parentBlocks?.[selectedBlock.id]
            // @ts-ignore
            pasteTarget = treeInstance.indexedBlocks?.[blockParent]

            // Work out the position of the selected block in the parent
            pasteTarget.childBlocks.forEach((block, index) => {
                if (selectedBlock.id === block.id) {
                    pastePosition = index + 1
                }
            })
        }

        const pastedBlock: Block = treeInstance.createAndAddBlock(
            blockToPaste,
            pasteTarget.id,
            pastePosition
        )
        selectBlock(pastedBlock.id)
        setPasting(false)
    }

    const undo: EditorContextActions['undo'] = () => {
        if (undoing) {
            return
        }

        setUndoing(true)
        treeInstance.undo()
        setUndoing(false)
    }

    const updateAttributes: EditorContextActions['updateAttributes'] = (id, key, value) => {
        treeInstance.recordHistory()
        treeInstance.updateBlockAttributes(id, key, value)
    }

    const updateStyle: EditorContextActions['updateStyle'] = (id, key, value) => {
        treeInstance.recordHistory()
        treeInstance.updateBlockStyle(id, key, value, deviceType)
    }

    const updateTree: EditorContextActions['updateTree'] = (treeJSON) => {
        treeInstance.updateTree(treeJSON)
    }

    const addBlockToTarget = (block: Block, targetId: string, targetPosition: number) => {
        treeInstance.recordHistory()

        const newBlockInstance = treeInstance.createAndAddBlock(block, targetId, targetPosition)
        selectBlock(newBlockInstance.id)

        return newBlockInstance.id as string
    }

    const onClick: EditorContextActions['onClick'] = ({ payload, targetId, targetPosition }) => {
        const newBlock = prepareBlockToAdd(payload)
        if (!newBlock) {
            return null
        }

        return addBlockToTarget(newBlock, targetId, targetPosition)
    }

    const onDrop: EditorContextActions['onDrop'] = ({ dragPayload, dropPayload }) => {
        // Returns the ID of either the new block, or the block that was dragged
        const newBlock = prepareBlockToAdd(dragPayload)

        // If we're not dropping onto the page then we mean to drop after the object
        let targetId = dropPayload.id
        if ((dropPayload.position === 0 || dropPayload.position) && dropPayload.id !== 'page')
            // If it's not a page and we have a position, then we want to drop onto
            // the parent instead
            // @ts-ignore
            targetId = treeInstance.parentBlocks?.[dropPayload.id]

        if (dragPayload.id == targetId) {
            // Don't drop yourself into yourself, dummy
            return null
        }

        let targetPosition = dropPayload.position
        // If we're dropping into the bottom of a column item or the right of a row item,
        // add after the item
        if (dropPayload.direction === 'column' && dragPayload.dropYPosition === 'bottom') {
            targetPosition += 1
        }
        if (dropPayload.direction === 'row' && dragPayload.dropXPosition === 'right') {
            targetPosition += 1
        }

        if (newBlock) {
            return addBlockToTarget(newBlock, targetId, targetPosition)
        }

        // Move the block
        treeInstance.recordHistory()
        treeInstance.moveBlock(dragPayload.id, targetId, targetPosition)
        return dragPayload.id
    }

    const onHover: EditorContextActions['onHover'] = (id) => {
        if (hoveredElement === id) {
            return
        }

        setHoveredElement(id)

        if (!styleNodeRef.current) {
            return
        }

        styleNodeRef.current.innerHTML = `
            .isEditing .block-${id}:hover:not(.isDragging):not(.isHovered) {
                outline: 1px dashed ${colors.userInterface.accent[1000]} !important;
                border-radius: 6px;
            }

            .isEditing .block-${id}:hover:not(.isDragging):not(.isHovered) > .block-controls {
                display: flex;
            }

            .isEditing .block-${id}:hover:not(.isDragging):not(.isHovered) > .block-controls > .block-label {
                display: none;
            }

            .isEditing .block-${id}:hover:not(.isDragging):not(.isHovered) > .block-controls > .block-label.block-name {
                display: block;
            }
        `
    }

    const highlightElement: EditorContextActions['highlightElement'] = (id) => {
        if (!secondStyleNodeRef.current) {
            return
        }

        secondStyleNodeRef.current.innerHTML = `
            .isEditing .block-${id}:not(.isDragging):not(.isHovered) {
                border: 1px dashed green !important;
            }
        `
    }

    const stopHighlight: EditorContextActions['stopHighlight'] = () => {
        if (!secondStyleNodeRef.current) {
            return
        }

        secondStyleNodeRef.current.innerHTML = ``
    }

    const viewDesktop: EditorContextActions['viewDesktop'] = () => {
        setDeviceType('desktop')
    }

    const viewMobile: EditorContextActions['viewMobile'] = () => {
        setDeviceType('mobile')
    }

    const viewTablet: EditorContextActions['viewTablet'] = () => {
        setDeviceType('viewTablet')
    }

    return {
        state: {
            deviceType,
            selected,
            selectedParent,
            selectedBlock,
            isEditing,
            isFormatPainting,
            formatPaintStyles,
        },
        actions: {
            selectBlock,
            deselectBlock,
            moveBlockUp,
            moveBlockDown,
            moveBlockToAnotherParentTree,
            updateBlock,
            removeBlock,
            removeSelected,
            formatPaintFromSelected,
            copySelected,
            cutSelected,
            pasteAtSelected,
            undo,
            updateAttributes,
            updateStyle,
            updateTree,
            onClick,
            onDrop,
            onHover,
            highlightElement,
            stopHighlight,
            viewDesktop,
            viewMobile,
            viewTablet,
        },
    }
}

export default useEditorHandler
