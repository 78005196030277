const columnsWidthKey = (stack: StackDto, object: ObjectDto) =>
    `${stack._sid}_${object._sid}_columns_width`

export default {
    columnsWidth: {
        key: columnsWidthKey,
        get: (stack: StackDto, object: ObjectDto) => {
            try {
                return JSON.parse(localStorage.getItem(columnsWidthKey(stack, object)) || '{}')
            } catch {
                return {}
            }
        },
        set: (stack: StackDto, object: ObjectDto, widths: any) => {
            localStorage.setItem(columnsWidthKey(stack, object), JSON.stringify(widths))
        },
    },
}
