import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { useObject } from 'data/hooks/objects'

import { Input } from 'v2/ui'

import { LinkFormFieldDropdown } from '../links/form/fragments/LinkFormFieldDropdown'
import { LinkFormObjectDropdown } from '../links/form/fragments/LinkFormObjectDropdown'
import { LinkFormEmptyState } from '../links/form/LinkFormEmptyState'
import { LinkFormFieldsPicker } from '../links/form/LinkFormFieldsPicker'
import { LinkFormLayout } from '../links/form/LinkFormLayout'
import { LinkFormSection } from '../links/form/LinkFormSection'
import { LinkFormSectionList } from '../links/form/LinkFormSectionList'

import { useUserProfileForm } from './hooks/useUserProfileForm'

export const UserProfileFormFields = ({
    filterTargetObject,
    form,
}: {
    filterTargetObject: (obj: ObjectDto) => boolean
    form: UseFormReturn<any>
}): React.ReactElement => {
    const formFields = useUserProfileForm(form)
    const values = form.watch()

    const { object: sourceObject } = useObject(values.source)
    const { object: targetObject } = useObject(values.target)

    const showEmptyState = !values.source || !values.target
    const showExpanded = !showEmptyState

    return (
        <LinkFormLayout>
            <LinkFormSectionList>
                <LinkFormSection title="Choose a table">
                    <LinkFormObjectDropdown
                        value={formFields.target.value}
                        onChange={formFields.target.onChange}
                        object={targetObject}
                        variant="secondary"
                        filter={filterTargetObject}
                    />
                </LinkFormSection>
                {showExpanded && (
                    <LinkFormSection title="Matching rule to associate profile records with users">
                        <LinkFormFieldsPicker
                            source={
                                <LinkFormFieldDropdown
                                    value={formFields.sourceField.value}
                                    onChange={formFields.sourceField.onChange}
                                    object={sourceObject}
                                    variant="primary"
                                    name="sourceField"
                                />
                            }
                            target={
                                <LinkFormFieldDropdown
                                    value={formFields.targetField.value}
                                    onChange={formFields.targetField.onChange}
                                    object={targetObject}
                                    variant="secondary"
                                    name="targetField"
                                />
                            }
                        />
                    </LinkFormSection>
                )}
                {showExpanded && (
                    <LinkFormSection title="User Profile Name">
                        <Input
                            {...formFields.sourceFieldLinkName}
                            name="sourceFieldLinkName"
                            variant="settings"
                            placeholder="enter a name for this user profile"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}
                        />
                    </LinkFormSection>
                )}
            </LinkFormSectionList>
            {showEmptyState && (
                <LinkFormEmptyState>Select table to configure the link</LinkFormEmptyState>
            )}
        </LinkFormLayout>
    )
}
