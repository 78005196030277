import * as Sentry from '@sentry/react'
import get from 'lodash/get'

import { queryClient } from 'data/hooks/_helpers'
import { invalidateObjectCache } from 'data/hooks/cacheInfo/cacheInfoOperations'
import { invalidateDataConnections } from 'data/hooks/dataConnections'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateFields } from 'data/hooks/fields'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { refetchPages } from 'data/hooks/pages'
import { invalidatePermissions } from 'data/hooks/permissions/permissionOperations'
import { invalidateStacks } from 'data/hooks/stacks/stackOperations'
import { invalidateViews } from 'data/hooks/views'
import { REACT_QUERY } from 'data/utils/constants'
import { fetchWithAuth } from 'data/utils/utils'

// This is a simplified version of what we do in backend_import
// It is suitable for data connectors whihc we know will do the import synchronously during the /import/ request.
// Returns a promise that rejects if anything goes wrong.

export const quickImport = (dataConnection) => {
    return new Promise((resolve, reject) => {
        fetchWithAuth(`import/${dataConnection._sid}`, { method: 'POST' })
            .then((response) => {
                if (response.status >= 400) {
                    response.text().then((error) => {
                        let event_id
                        Sentry.withScope((scope) => {
                            scope.setTags({
                                stacker_error: 'import_error',
                                blocking_error: true,
                                data_source: get(dataConnection, 'type'),
                                interactive: true,
                            })
                            scope.setLevel('error')
                            event_id = Sentry.captureMessage(
                                `Error from import API (${response.status}): ${error}`
                            )
                        })

                        reject({ sentry_event_id: `F-${event_id}` })
                    })
                } else {
                    // Refresh various metadata after the schema sync
                    invalidateObjects()
                    invalidateFields()
                    invalidatePermissions()
                    invalidateDataConnections()
                    queryClient.invalidateQueries(REACT_QUERY.roles.listName)
                    invalidateFeatures()
                    invalidateViews()
                    invalidateStacks()
                    invalidateNavigation()
                    refetchPages()
                    invalidateObjectCache()

                    resolve()
                }
            })
            .catch((error) => {
                let event_id
                Sentry.withScope((scope) => {
                    scope.setTags({
                        stacker_error: 'import_error',
                        blocking_error: true,
                        data_source: get(dataConnection, 'type'),
                        interactive: true,
                    })
                    scope.setLevel('error')
                    event_id = Sentry.captureMessage(`Unable to import schema, error: ${error}`)
                })

                reject({ sentry_event_id: `F-${event_id}` })
            })
    })
}
