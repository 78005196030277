export const COLOR_PALETTE = [
    '#f9647d',
    '#fcc14c',
    '#5984f1',
    '#38c983',
    '#fe8c41',
    '#7b6bfc',
    '#d66bfc',
    '#59cdf1',
    '#d2cccc',
    '#ff765f',
    '#faee81',
    '#75f9b9',
]

export const LABEL_GREY = '#789'
