import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalFooter } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import changeUrl from 'v2/views/utils/changeUrl'

import AppContext from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import { refetchNavigation } from 'data/hooks/navigation'
import { refetchPages } from 'data/hooks/pages'
import { useCreateView } from 'data/hooks/views'
import { Input } from 'legacy/v1/ui'

import { Modal } from 'v2/ui'
import Flex from 'v2/ui/components/Flex'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import Button from 'ui/deprecated/atoms/Button'

import DashboardDestinationPicker from './DestinationPicker'

export default function CreateDashboard({
    objectId: _objectId,
    featureId: _featureId,
    onRequestClose,
    isSecondLayer, // Indicates that it is overlaying another modal.
    onCloseSettingsModal, // Closes settings modal.
}) {
    const { mutateAsync: createView } = useCreateView()
    const [name, setName] = useState('')
    const [objectId, setObjectId] = useState(_objectId)
    const [featureId, setFeatureId] = useState(_featureId)
    const history = useHistory()

    const createDashboard = (name) => {
        const clonedView = { name, label: name, options: {} }
        clonedView.options.title = name
        clonedView.options.menu_label = name
        clonedView.options.top_level_dashboard = true
        clonedView.type = 'dashboard'
        clonedView.object_id = objectId
        clonedView.feature_id = featureId

        return createView(clonedView)
            .then((response) => {
                return Promise.all([refetchPages(), refetchNavigation()]).then(() => {
                    changeUrl(getUrl('/'), getUrl(response.url), false, history)
                    onRequestClose()
                    onCloseSettingsModal()
                })
            })
            .catch((e) => {
                Sentry.captureMessage(
                    `Error creating dashboard. Error message: ${get(e, 'message')}`
                )
            })
    }

    return (
        <Modal
            width="500px"
            isSecondLayer={isSecondLayer}
            isOpen
            title="Create a dashboard"
            onClose={onRequestClose}
        >
            <div style={{ marginTop: 16 }}>
                <label>
                    Name your dashboard
                    <Input
                        onChange={(event) => setName(event.target.value)}
                        id="dup-layout-input"
                        placeholder="Pick a name"
                        style={{ width: '100%' }}
                    />
                </label>
            </div>
            {!_objectId && (
                <>
                    <div style={{ marginTop: 16 }}>Where to add it</div>
                    <DashboardDestinationPicker
                        onChange={({ objectId, featureId }) => {
                            setFeatureId(featureId)
                            setObjectId(objectId)
                        }}
                        value={objectId}
                    />
                </>
            )}

            <ModalFooter style={{ marginTop: 15 }}>
                <Flex>
                    <Button type="secondary" onClick={onRequestClose}>
                        Cancel
                    </Button>
                    <div style={{ width: 15 }} />
                    <Button disabled={!name || !objectId} onClick={() => createDashboard(name)}>
                        Create dashboard
                    </Button>
                </Flex>
            </ModalFooter>
        </Modal>
    )
}

export const CreateDashboardButton = ({
    objectId,
    featureId,
    isSecondLayer,
    onCloseSettingsModal,
    ...otherProps
}) => {
    const { selectedStack: stack } = useContext(AppContext)
    const [isOpen, setIsOpen] = useState(false)
    const chartsLocked = isFeatureLocked(FEATURES.charts, stack)

    if (chartsLocked) return null

    return (
        <>
            <Button type="secondary" onClick={() => setIsOpen(true)} icon="add" {...otherProps}>
                Add Dashboard
            </Button>
            {isOpen && (
                <CreateDashboard
                    onRequestClose={() => setIsOpen(false)}
                    objectId={objectId}
                    featureId={featureId}
                    isSecondLayer={isSecondLayer}
                    onCloseSettingsModal={onCloseSettingsModal}
                />
            )}
        </>
    )
}
