import React, { forwardRef, useState } from 'react'
import { Link as InternalLink, useHistory } from 'react-router-dom'

import { Button as ChakraButton, Link, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import { isExternal, wrapStringComponentWithSpan } from 'utils/utils'

import STYLE_CLASSES from 'v2/ui/styleClasses'
import { BackArrow } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import ConditionalWrapper from './ConditionalWrapper'
import Icon from './Icon'
import Tooltip from './Tooltip'
const { colors } = stackerTheme()

const BASE_CLASS = STYLE_CLASSES.BUTTON

const StyledButton = styled(ChakraButton)`
    transition: background 0.2s ease-in-out;
    &.focus-visible {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
    }
    ${variant({
        variants: {
            Primary: {
                fontWeight: 'bold',
                borderRadius: '5px',
                color: 'admin.button.primaryV4.color',
                bg: 'admin.button.primaryV4.background',
                '&:hover': {
                    bg: 'admin.button.primaryV4.backgroundHover',
                    textDecoration: 'none',
                },
            },
            PrimaryError: {
                fontWeight: 'bold',
                borderRadius: '5px',
                color: 'userInterface.neutral.0',
                bg: 'userInterface.error.600',
                '&:hover': {
                    bg: 'userInterface.error.600',
                    textDecoration: 'none',
                },
            },
            App: {
                fontWeight: 'bold',
                color: 'endUser.button.primary.color',
                border: '1px solid',
                borderColor: 'endUser.button.primary.borderColor',
                borderRadius: '5px',
                bg: 'endUser.button.primary.background',
                '&:hover': {
                    bg: 'endUser.button.primary.backgroundHover',
                    textDecoration: 'none',
                },
            },
            Secondary: {
                fontWeight: 'bold',
                color: 'admin.button.secondaryV4.color',
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'admin.button.secondaryV4.background',
                '&:hover': {
                    bg: 'admin.button.secondaryV4.backgroundHover',
                    textDecoration: 'none',
                },
            },

            SecondaryGray: {
                fontWeight: 'bold',
                color: 'userInterface.neutral.900',
                border: '1px solid',
                borderColor: 'userInterface.neutral.400',
                borderRadius: '5px',
                bg: 'userInterface.neutral.200',
                '&:hover': {
                    bg: 'userInterface.neutral.400',
                    textDecoration: 'none',
                },
            },
            Tertiary: {
                fontWeight: 'bold',
                bg: 'userInterface.neutral.0',
                borderRadius: '3px',
                '&:hover': {
                    bg: 'userInterface.neutral.200',
                    textDecoration: 'none',
                },
            },
            Option: {
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: 'normal',
                color: 'userInterface.neutral.1000',
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'userInterface.neutral.0',
                '&:hover': {
                    bg: 'userInterface.neutral.100',
                    textDecoration: 'none',
                },
            },
            OptionSelected: {
                pointer: 'normal',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: 'normal',
                color: 'userInterface.neutral.0',
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'userInterface.accent.1000',
                '&:hover': {
                    bg: 'userInterface.accent.1000',
                    textDecoration: 'none',
                },
            },
            Tag: {
                borderRadius: '4px',
                bg: 'userInterface.neutral.150',
                '&:hover': {
                    bg: 'filters.button.backgroundHover',
                },
                height: '16px',
                fontWeight: 'bold',
                lineHeight: '1.4',
                color: 'userInterface.accent.1000',
                fontSize: '10px',
            },
            squareSm: {
                size: ['button.squareSm.size', null, null, 'button.squareSm.sizeLg'],
            },
            squareMd: {
                size: ['button.squareMd.size', null, null, 'button.squareMd.sizeLg'],
            },
            sm: {
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            md: {
                height: ['button.md.h', null, null, 'button.md.hLg'],
                px: ['button.md.px', null, null, 'button.md.pxLg'],
                fontSize: ['button.md', null, null, 'button.mdLg'],
            },
            clear: {
                color: 'button.clear.text',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                },
                borderRadius: 0,
            },
            clearWhite: {
                color: 'white',
                bg: 'transparent',
                '&:hover': {
                    bg: 'rgba(255, 255, 255, 0.1)',
                },
                borderRadius: 0,
            },
            link: {
                bg: 'transparent',
                textDecoration: 'underline',
                '&:hover': {
                    bg: 'transparent',
                },
                borderRadius: 0,
                px: 0,
            },
            linkNoUnderline: {
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                },
                '&:hover:not(:disabled)': {
                    textDecoration: 'underline',
                },
                borderRadius: 0,
                px: 0,
            },
            sideNav: {
                bg: 'transparent',
                borderRadius: 'none',
            },

            adminTertiary: {
                color: 'admin.button.primary.background',
                bg: 'transparent',
                px: 2,
                '&:hover': {
                    bg: 'grey.100',
                },
            },
            adminTertiaryV4: {
                color: 'neutral.800',
                bg: 'transparent',
                px: 0,
                '&:hover': {
                    color: 'neutral.1000',
                    bg: 'transparent',
                },
            },
            adminDefault: {
                color: 'grey.400',
                bg: 'transparent',
                px: 2,
                '&:hover': {
                    bg: 'grey.100',
                },
            },
            secondary: {
                color: 'button.background',
                bg: 'transparent',
                px: 2,
                '&:hover': {
                    bg: 'grey.100',
                },
            },
            moderateSm: {
                color: 'grey.400',
                bg: 'white',
                border: '1px solid',
                borderColor: 'grey.200',
                fontSize: ['button.sm', null, null, 'button.smLg'],
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                '&:hover': {
                    bg: 'grey.100',
                },
            },
            sideMenu: {
                position: 'absolute',
                bottom: '12px',
                left: '12px',
                right: '12px',
                height: [
                    'admin.sideMenu.buttonHeight',
                    null,
                    null,
                    'admin.sideMenu.buttonHeightLg',
                ],
                borderRadius: '5px',
                color: 'admin.button.primaryV4.color',
                bg: 'admin.button.primaryV4.background',
                '&:hover': {
                    bg: 'admin.button.primaryV4.backgroundHover',
                    textDecoration: 'none',
                },
            },
            upgradePrimary: {
                fontWeight: 'bold',
                color: 'admin.upgradeButton.color',
                bg: 'admin.upgradeButton.background',
                '&:hover': {
                    bg: 'admin.upgradeButton.backgroundHover',
                },
                '&.focus-visible': {
                    boxShadow: 'admin.upgradeButton.focus',
                },
                py: 1,
            },
            endUserPrimary: {
                fontWeight: 'bold',
                color: 'endUser.button.primary.color',
                bg: 'endUser.button.primary.background',
                '&:hover': {
                    bg: 'endUser.button.primary.backgroundHover',
                },
            },
            endUserSecondary: {
                fontWeight: 'bold',
                color: 'endUser.button.secondary.color',
                border: '1px solid',
                borderColor: 'endUser.button.secondary.borderColor',
                bg: 'endUser.button.secondary.background',
                '&:hover': {
                    bg: 'endUser.button.secondary.backgroundHover',
                },
            },
            endUserClear: {
                color: 'endUser.button.primary.background',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                },
                borderRadius: 0,
            },
            adminClear: {
                color: 'admin.button.primary.background',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                },
                borderRadius: 0,
            },
            adminPrimary: {
                fontWeight: 'bold',
                color: 'admin.button.primary.color',
                bg: 'admin.button.primary.background',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                    textDecoration: 'none',
                },
            },
            adminPrimaryV4: {
                fontWeight: 'bold',
                borderRadius: '5px', // Per Adis, all v4 buttons and inputs should have 5px radius
                color: 'admin.button.primaryV4.color',
                bg: 'admin.button.primaryV4.background',
                '&:hover': {
                    bg: 'admin.button.primaryV4.backgroundHover',
                    textDecoration: 'none',
                },
                height: '36px',
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            adminSecondary: {
                fontWeight: 'bold',
                color: 'admin.button.secondary.color',
                border: '1px solid',
                borderColor: 'admin.button.secondary.borderColor',
                bg: 'admin.button.secondary.background',
                '&:hover': {
                    bg: 'admin.button.secondary.backgroundHover',
                    textDecoration: 'none',
                },
            },
            adminSecondaryV4: {
                fontWeight: 'bold',
                color: 'admin.button.secondaryV4.color',
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'admin.button.secondaryV4.background',
                '&:hover': {
                    bg: 'admin.button.secondaryV4.backgroundHover',
                    textDecoration: 'none',
                },
                height: '36px',
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            adminSecondaryV4NoHeight: {
                fontWeight: 'bold',
                color: 'admin.button.secondaryV4.color',
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'admin.button.secondaryV4.background',
                '&:hover': {
                    bg: 'admin.button.secondaryV4.backgroundHover',
                    textDecoration: 'none',
                },
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            authenticationPrimary: {
                fontWeight: 'bold',
                color: 'admin.button.primary.color',
                bg: 'admin.button.primary.background',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                },
            },
            authenticationSecondary: {
                fontWeight: 'bold',
                color: 'authentication.button.secondary.color',
                border: '1px solid',
                borderColor: 'authentication.button.secondary.borderColor',
                bg: 'authentication.button.secondary.background',
                '&:hover': {
                    bg: 'authentication.button.secondary.backgroundHover',
                },
            },
            onboardingPrimary: {
                fontWeight: 'bold',
                color: 'admin.button.primary.color',
                bg: 'admin.button.primary.background',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                },
            },
            onboardingSecondary: {
                fontWeight: 'bold',
                color: 'authentication.button.secondary.color',
                border: '1px solid',
                borderColor: 'authentication.button.secondary.borderColor',
                bg: 'authentication.button.secondary.background',
                '&:hover': {
                    bg: 'authentication.button.secondary.backgroundHover',
                },
            },
            fieldButton: {
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
                alignSelf: 'start',
            },
            actionListButton: {
                bg: 'transparent',
                borderRadius: 0,
                width: '100%',
                justifyContent: 'flex-start',
                p: 2,
                '&:hover': {
                    bg: 'gray.100',
                },
                fontSize: ['button.sm', null, null, 'button.smLg'],
                '&.focus-visible': {
                    boxShadow: 'none',
                    bg: 'gray.100',
                },
                fontWeight: 'normal',
                color: 'text.body',
                display: 'flex',
            },
            reactionButton: {
                bg: 'transparent',
                borderRadius: '3px',
                border: '1px solid',
                borderColor: 'grey.200',
                py: 1,
                px: 2,
                '&:hover': {
                    bg: 'gray.100',
                },
                fontSize: ['button.sm', null, null, 'button.smLg'],
                '&.focus-visible': {
                    boxShadow: 'none',
                    bg: 'gray.100',
                },
                fontWeight: 'bold',
                color: 'grey.300',
                display: 'flex',
            },
            menuToggle: {
                color: 'admin.menuToggle.text',
                fontSize: ['admin.menuToggle.text', null, null, 'admin.menuToggle.textLg'],
                py: 1,
                bg: 'transparent',
                '&:hover': {
                    bg: 'gray.100',
                    color: 'admin.menuToggle.textHover',
                },
                borderRadius: '4px',
                justifyContent: 'flex-start',
                width: '100%',
                fontWeight: 'normal',
            },
            bannerButton: {
                padding: '10px',
                color: 'admin.button.primary.color',
                bg: 'admin.button.primary.background',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                },
            },
            smallBannerButton: {
                padding: '10px',
                fontSize: ['button.sm', null, null, 'button.sm'],
                bg: 'admin.button.smallBanner.background',
                '&:hover': {
                    bg: 'admin.button.smallBanner.backgroundHover',
                },
            },
            bannerButtonLight: {
                padding: '10px',
                color: 'admin.button.secondary.color',
                border: '1px solid',
                borderColor: 'admin.button.secondary.borderColor',
                bg: 'admin.button.secondary.background',
                '&:hover': {
                    bg: 'admin.button.secondary.backgroundHover',
                },
            },
            bannerLabel: {
                w: '100px',
                ml: '10px',
                bg: 'admin.button.primary.background',
                fontSize: 'button.sm',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                },
            },
            filterButton: {
                fontWeight: 'normal',
                bg: 'filters.button.background',
                '&:hover': {
                    bg: 'filters.button.backgroundHover',
                },
                color: 'filters.button.text',
            },
            filterType: {
                color: 'button.clear.text',
                bg: 'filters.filterTypeButton.background',
                padding: 3,
                my: 1,
                '&:hover': {
                    bg: 'filters.filterTypeButton.backgroundHover',
                },
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            menuButton: {
                color: 'button.clear.text',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                },
                borderRadius: 0,
                width: '100%',
                padding: 0,
                height: 'auto',
                fontWeight: 'normal',
                display: 'inline',
                textAlign: 'left',
                margin: '8px 0',
                '&.focus-visible': {
                    outline: '-webkit-focus-ring-color auto 1px',
                },
            },
            accordion: {
                fontWeight: 'bold',
                color: 'accordion.header.color',
                border: '1px solid',
                borderColor: 'accordion.header.borderColor',
                bg: 'accordion.header.background',
                '&:hover': {
                    bg: 'accordion.header.backgroundHover',
                },
            },
            iconButton: {
                borderRadius: '100%',
                bg: 'transparent',
                '&:hover': {
                    bg: 'rgba(0,0,0,.2)',
                },
            },
            adminBackButton: {
                fontWeight: 'bold',
                color: 'neutral.800',
                border: 'none',
                borderRadius: '3px',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                    textDecoration: 'none',
                },
                pr: ['button.sm.px', null, null, 'button.sm.pxLg'],
                pl: 0,
                py: 0,
                fontSize: ['button.sm', null, null, 'button.smLg'],
                height: 'auto',
            },
            circle: {
                borderRadius: '100%',
                color: 'admin.button.primary.color',
                bg: 'admin.button.primary.background',
                '&:hover': {
                    bg: 'admin.button.primary.backgroundHover',
                    textDecoration: 'none',
                },
            },
            help: {
                borderRadius: '100%',
                color: 'admin.button.primary.color',
                bg: '#3333ff',
                '&:hover': {
                    bg: '#000089',
                    textDecoration: 'none',
                },
            },
            planPickerOn: {
                fontWeight: 'bold',
                color: `${colors.userInterface.accent[1000]}`,
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: 'admin.button.secondaryV4.background',
                '&:hover': {
                    color: `${colors.userInterface.accent[1000]}`,
                    bg: 'admin.button.secondaryV4.background',
                    textDecoration: 'none',
                },
            },
            planPickerOff: {
                fontWeight: 'bold',
                color: `${colors.userInterface.neutral[900]}`,
                border: '1px solid',
                borderColor: 'admin.button.secondaryV4.borderColor',
                borderRadius: '5px',
                bg: `${colors.userInterface.neutral[400]}`,
                '&:hover': {
                    bg: `${colors.userInterface.neutral[400]}`,
                    textDecoration: 'none',
                    color: `${colors.userInterface.neutral[900]}`,
                },
            },
            floatingLight: {
                fontWeight: 'bold',
                color: 'userInterface.accent.1000',
                fontSize: ['button.sm', null, null, 'button.smLg'],
                px: 10,
                py: 3,
                border: 0,
                borderRadius: '9999px',
                bg: 'userInterface.neutral.0',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.neutral.400',
                },
                '&:focus': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.neutral.0',
                },
                '&:active': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.neutral.500',
                },
            },
            floatingDark: {
                fontWeight: 'bold',
                color: 'userInterface.neutral.0',
                fontSize: ['button.sm', null, null, 'button.smLg'],
                px: 10,
                py: 3,
                border: 0,
                borderRadius: '9999px',
                bg: 'userInterface.accent.900',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.accent.1600',
                },
                '&:focus': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.accent.900',
                },
                '&:active': {
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    bg: 'userInterface.accent.1600',
                },
            },
            DataWidgetSearchButton: {
                border: `1px solid ${colors.appColors.lightGrey[400]}`,
                borderRadius: '5px',
                bg: 'transparent',
                '&:hover': {
                    bg: 'transparent',
                    textDecoration: 'none',
                    border: `1px solid ${colors.appColors.lightGrey[700]}`,
                },
            },
        },
    })}

    ${variant({
        prop: 'buttonSize',
        variants: {
            extraSmall: { height: '24px', px: 2, fontSize: '12px' },
            extraSmallSquare: { height: '24px', width: '24px', px: 0, py: 0 },
            small: { height: '28px', px: 2, fontSize: '12px' },
            smallSquare: { height: '28px', width: '28px', px: 0, py: 0 },
            medium: { height: '32px', px: 3, fontSize: '14px' },
            mediumSquare: { height: '32px', width: '32px', px: 0, py: 0 },
            large: { height: '36px', px: 4, fontSize: '14px' },
            largeSquare: { height: '36px', width: '36px', px: 0, py: 0 },
            extraLarge: { height: '40px', px: 5, fontSize: '14px' },
            extraLargeSquare: { height: '40px', width: '40px', px: 0, py: 0 },
            sm: {
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            smDense: {
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                px: [2, null, null, 3],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            smNoHeight: {
                px: ['button.sm.px', null, null, 'button.sm.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            smNoPadding: {
                height: ['button.sm.h', null, null, 'button.sm.hLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
            },
            md: {
                height: ['button.md.h', null, null, 'button.md.hLg'],
                px: ['button.md.px', null, null, 'button.md.pxLg'],
                fontSize: ['button.md', null, null, 'button.mdLg'],
            },

            mdDense: {
                height: ['button.md.h', null, null, 'button.md.hLg'],
                px: [2, null, null, 3],
                fontSize: ['button.md', null, null, 'button.mdLg'],
            },
            filter: {
                px: ['button.filter.px', null, null, 'button.filter.pxLg'],
                fontSize: ['button.sm', null, null, 'button.smLg'],
                mr: 2,
                textAlign: 'left',
            },
            unset: {},
        },
    })}
`
const Button = (
    {
        children,
        label,
        icon,
        iconAlign = 'left',
        variant,
        iconColor,
        labelPlacement = 'bottom',
        isLoading: isLoadingProp,
        disabled,
        className = '',
        hideSpinner,
        historyTitle,
        historyType,
        onClick,
        svgIcon,
        iconProps: suppliedIconProps,
        submit,
        truncateText = false,
        ...props
    },
    ref
) => {
    const newProps = { ...props }
    const history = useHistory()
    const [isLoadingInternal, setIsLoadingInternal] = useState(false)

    const mergedClassName = `${className} ${BASE_CLASS} ${
        variant ? BASE_CLASS + '-' + variant : ''
    }`

    // Note: isDisabled does not work for anchor links, so we'll just a button in this case
    if (props.href && !props.isDisabled) {
        if (isExternal(props.href)) {
            newProps.as = Link
            newProps.target = '_blank'
            newProps.href = props.href
        } else {
            newProps.as = InternalLink
            newProps.to = props.href
        }
    }

    if (newProps.to && typeof newProps.to == 'string') {
        try {
            const { pathname, search, hash } = new URL(newProps.to, window.location.href)
            newProps.to = {
                pathname,
                search,
                hash,
                state: { prev: history.location, title: historyTitle, type: historyType },
            }
        } catch (ex) {
            console.log(`Failed to parse URL: ${newProps.to}`)
            console.error(ex)
        }
    }

    // If openInNewTab is set either true or false (as opposed to undefined/null), this overrides the smart behaviour above
    if (typeof props.openInNewTab === 'boolean') {
        newProps.target = props.openInNewTab ? '_blank' : '_self'
    }

    const getIcon = (right = false) => {
        if (variant === 'adminBackButton' && !icon) {
            return (
                <Icon
                    svg={{ type: BackArrow }}
                    stroke="neutral.800"
                    size="10px"
                    pr={2}
                    mb="1px"
                    mt="-1px"
                />
            )
        }

        if (!icon && !svgIcon) return null

        if (typeof icon === 'function') {
            return icon()
        } else if (icon && typeof icon === 'object') {
            return icon
        }

        const iconProps = { ...suppliedIconProps }
        if (right && children) {
            iconProps.pl = 2
            iconProps.ml = 'auto'
        }

        if (!right && children && iconProps.pr === undefined) {
            iconProps.pr = 2
        }

        return <Icon {...iconProps} size={props.size} icon={icon} svg={svgIcon} color={iconColor} />
    }

    function handleClick(event) {
        if (isLoadingInternal) {
            return null
        }

        // if the onClick handler returned a promise, then
        // set our internal loading state to true and wait
        // for the promise to resolve, then set loading to false
        const promise = onClick?.(event)

        if (promise?.then) {
            setIsLoadingInternal(true)
            promise.finally(() => setIsLoadingInternal(false))
        }

        return promise
    }

    // This is a bit of hack, but we have instances (the data grid editor)
    // where UI is set to listen to the mouseUp event and if that UI is behind
    // a modal with a save/cancel/close button that gets clicked, that UI will
    // receive the mouseUp even though the click actually happened in the modal
    // which was open above it. This stops propagation of the mouseUp event
    // if we have an onClick handler.
    const handleMouseUp = (event) => {
        if (onClick) {
            event.stopPropagation()
        }
    }
    const isLoading = isLoadingInternal || isLoadingProp

    // Prevent Button from failing when translation is used.
    children = wrapStringComponentWithSpan(
        children,
        truncateText
            ? {
                  style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                  },
              }
            : undefined
    )

    return (
        <ConditionalWrapper
            condition={label}
            wrapper={(children) => (
                <Tooltip closeOnClick label={label} placement={labelPlacement}>
                    {children}
                </Tooltip>
            )}
        >
            <StyledButton
                className={mergedClassName}
                type={submit ? 'submit' : 'button'}
                fontWeight="button"
                height="auto"
                border={0}
                minW={0}
                color="button.text"
                bg="button.background"
                cursor="pointer"
                borderRadius="button"
                fontFamily="button"
                lineHeight="1"
                transition="background 2.s linear, color 3.s linear"
                _hover={{ bg: 'button.backgroundHover' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                variant={variant}
                isDisabled={isLoading || disabled}
                ref={ref}
                onClick={onClick ? handleClick : null}
                onMouseUp={handleMouseUp}
                {...newProps}
            >
                <>
                    {iconAlign === 'left' && !isLoading && getIcon()}

                    <span>
                        {!hideSpinner && isLoading && (
                            <Spinner
                                size="xs"
                                mr={2}
                                transition="all .3s ease-in"
                                verticalAlign="middle"
                            />
                        )}
                    </span>
                    {children}
                    {iconAlign === 'right' && getIcon(true)}
                </>
            </StyledButton>
        </ConditionalWrapper>
    )
}

export default forwardRef(Button)
