import { useQuery } from './_helpers'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useInvitation'
const ENDPOINT = 'invitation/'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useInvitation(id, forStack = null, options = {}) {
    const defaultOptions = { retry: (count, error) => error?.status !== 404 }
    const request_url = forStack
        ? `${ENDPOINT}?token=${id}&for_stack=${forStack}&`
        : `${ENDPOINT}?token=${id}&`
    return useQuery(
        LIST_NAME,
        request_url,
        { ...defaultOptions, ...options },
        {
            disabledFn: () => !id,
            disabledValue: null,
            bypassMatchingStackCheck: true, // Moot, as we are on the studio domain
        }
    )
}
