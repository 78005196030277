// @ts-strict-ignore
import React, { useContext } from 'react'

import styled from '@emotion/styled'

import { PopoverButton, Text } from 'v2/ui'
import { SolidMenuHamburger } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { DataGridContext } from '../DataGridContext'

import { TableOrderableList, TableOrderableListProps } from './TableOrderableList'

const { colors } = stackerTheme()

const PopoverContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 400px;
    max-height: 60vh;

    border-radius: 6px;

    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 10px;

    background-color: ${colors.userInterface.neutral[0]};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`

const TablesListWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
`

type ManageTablesDropdownProps = Omit<TableOrderableListProps, 'closeDropdown'> & {
    defaultIsOpen?: boolean
    dataConnection?: DataConnectionDto
}

const ManageTablesDropdown = ({
    dataConnection,
    tables,
    selectedTableId,
    onSelectTable,
    onTableActivated,
    onTableDeactivated,
    defaultIsOpen = false,
}: ManageTablesDropdownProps): React.ReactElement => {
    const { setReadOnly } = useContext(DataGridContext)
    return (
        <PopoverButton
            style={{ cursor: 'pointer', paddingTop: '3px' }}
            defaultIsOpen={defaultIsOpen}
            ButtonComponent={SolidMenuHamburger}
            place="bottom-start"
            onOpen={() => setReadOnly(true)}
            onClose={() => setReadOnly(false)}
        >
            {({ close }) => (
                <PopoverContentWrapper>
                    <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>Manage Tables</Text>
                    <Text style={{ fontSize: '13px' }} mt={1}>
                        Search, activate or deactivate tables.
                    </Text>
                    <TablesListWrapper>
                        <TableOrderableList
                            dataConnection={dataConnection}
                            tables={tables}
                            selectedTableId={selectedTableId}
                            onSelectTable={onSelectTable}
                            onTableActivated={onTableActivated}
                            onTableDeactivated={onTableDeactivated}
                            closeDropdown={close}
                        />
                    </TablesListWrapper>
                </PopoverContentWrapper>
            )}
        </PopoverButton>
    )
}

export default ManageTablesDropdown
