import React from 'react'

import { Button, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { AirtableUpgradeSuccess } from 'v2/ui/svgs'

import { AIRTABLE_REPAIR_SUCCESS_MODAL_TEST_ID } from '../airtableTestIds'

import { StyledFlex } from './StyledComponents'

export const AirtableRepairSuccessModal = ({
    onClose,
}: {
    onClose: () => void
}): React.ReactElement => (
    <SimpleModalCompat
        size="400px"
        height="400px"
        isSecondLayer
        noDividers
        padding="0 32px 40px 32px"
        zIndex={1501}
        actionsBorder={false}
        isOpen
        onClose={onClose}
        hideTitleBottomBorder
        data-testid={AIRTABLE_REPAIR_SUCCESS_MODAL_TEST_ID}
    >
        <StyledFlex>
            <AirtableUpgradeSuccess />
            <Text variant="heading" marginTop={8} marginBottom={4} width={'100%'}>
                Repair successful!
            </Text>
            <Text fontSize="14px">
                Your Airtable connection has been successfully repaired.
                <br />
                <br />
            </Text>
            <Button
                flexShrink={0}
                variant="Primary"
                ml={2}
                mt={8}
                width="50%"
                buttonSize="large"
                onClick={onClose}
            >
                OK
            </Button>
        </StyledFlex>
    </SimpleModalCompat>
)
