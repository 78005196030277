import React from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'
const { colors } = stackerTheme()

type BookProps = {
    color?: string
    size?: number
}
const Book: React.FC<BookProps> = ({ color = colors.userInterface.neutral[1000], size = 14 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                d="M12.075 0H2.975C2.1308 0 0.875 0.5593 0.875 2.1V11.9C0.875 13.4407 2.1308 14 2.975 14H13.475V12.6H2.9834C2.66 12.5916 2.275 12.4642 2.275 11.9C2.275 11.8293 2.2813 11.7663 2.2918 11.7089C2.3702 11.3057 2.7006 11.207 2.9834 11.2H13.475V1.4C13.475 1.0287 13.3275 0.672601 13.0649 0.41005C12.8024 0.1475 12.4463 0 12.075 0ZM12.075 6.3L10.675 5.6L9.275 6.3V1.4H12.075V6.3Z"
                fill={color}
            />
        </svg>
    )
}

export default Book
