import React, { FC } from 'react'

import { formatNumberValue } from './utils/utils'

const RenderNumberValue: FC<{
    value: number
    display: ChartParamsDisplay
    config: ChartParamsConfig
    metrics: MetricsQueryResponse
}> = ({ value, display, config, metrics }) => {
    const { value: valueAsString, abbreviation } = formatNumberValue({
        value,
        display,
        config,
        metrics,
    })

    if (abbreviation) {
        return (
            <span>
                <span>{valueAsString}</span>
                <span className="number-abbreviation-symbol">
                    {display.abbreviation.toUpperCase()}
                </span>
            </span>
        )
    }

    return <>{valueAsString}</>
}

const NumberValue: FC<{
    value: number
    display: ChartParamsDisplay
    config: ChartParamsConfig
    metrics: MetricsQueryResponse
}> = ({ display, config, metrics }) => {
    // for number charts there is always just a single record group
    let { value } = metrics.data[0]

    if (!metrics.field_display_meta) {
        return (
            <RenderNumberValue value={value} display={display} config={config} metrics={metrics} />
        )
    }

    // count is just a count of rows, no field type to consider
    if (config.aggr.type === 'count') {
        return (
            <RenderNumberValue value={value} display={display} config={config} metrics={metrics} />
        )
    }

    // for other aggr types, we format according to the field type
    switch (metrics.field_display_meta.field_type) {
        case 'currency': {
            // currency_symbol should always be present on field_display_meta,
            // for currency type fields, but just default to no symbol in case not
            const currencySymbol = metrics.field_display_meta.currency_symbol ?? ''

            return (
                <>
                    <span className="currency-symbol">{currencySymbol}</span>
                    <span>
                        <RenderNumberValue
                            value={value}
                            display={display}
                            config={config}
                            metrics={metrics}
                        />
                    </span>
                </>
            )
        }

        default:
            return (
                <RenderNumberValue
                    value={value}
                    display={display}
                    config={config}
                    metrics={metrics}
                />
            )
    }
}

export const renderNumberValueAsText = ({
    value,
    display,
    config,
    metrics,
}: {
    value: number
    display: ChartParamsDisplay
    config: ChartParamsConfig
    metrics: MetricsQueryResponse
}) => {
    let { value: formattedValue, abbreviation } = formatNumberValue({
        value,
        display,
        config,
        metrics,
    })

    const fieldType = metrics.field_display_meta?.field_type

    if (fieldType === 'currency') {
        // currency_symbol should always be present on field_display_meta,
        // for currency type fields, but just default to no symbol in case not
        const currencySymbol = metrics.field_display_meta?.currency_symbol ?? ''

        formattedValue = currencySymbol + formattedValue
    }

    if (abbreviation) {
        formattedValue += abbreviation
    }

    return formattedValue
}

export default NumberValue
