import React from 'react'

import { RowLinkFunction, ViewHeader, ViewRow } from 'v2/views/List/types'

import { Box, Container, ContainerLabel } from 'v2/ui'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import useHover from 'v2/ui/utils/useHover'

import { RowsList } from './RowsList'

type RowsProps = React.ComponentPropsWithoutRef<typeof Container> & {
    header?: ViewHeader[]
    data?: ViewRow[]
    pagination?: React.ReactNode
    title?: string
    onTitleChange?: (title: string) => void
    titleIsEditable?: boolean
    rowLink?: RowLinkFunction
    hideFieldLabels?: boolean
    showEmptyValues?: boolean
    inboxView?: boolean
    useClassNames?: boolean
    object?: ObjectDto
    viewOptions?: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    search?: React.ReactNode
    buttons?: React.ReactNode
    topFilters?: React.ReactNode
    additionalListContainerContent?: React.ReactNode
    emptyContent?: React.ReactNode
    brandColor?: string
    selectedRow?: ViewRow
}

const Rows: React.FC<RowsProps> = ({
    header = [],
    data = [],
    pagination,
    title,
    titleIsEditable,
    buttons,
    topFilters,
    rowLink,
    search,
    emptyContent,
    onTitleChange,
    hideFieldLabels = true,
    showEmptyValues,
    selectedRow,
    inboxView = false,
    brandColor,
    additionalListContainerContent,
    object,
    viewOptions,
    setConfig,
    useClassNames = true,
    ...props
}) => {
    const [isContainerHovered, hoverHandlers] = useHover()

    const getClassName = (className: string) => {
        if (!useClassNames) return undefined

        return className
    }

    if (!data.length) {
        return (
            <Container
                title={title}
                buttons={buttons}
                search={search}
                onChange={onTitleChange}
                className={getClassName(ONBOARDING_CLASSES.TABLE_CONTAINER)}
                style={{ margin: inboxView ? 12 : undefined }}
                {...props}
            >
                {emptyContent}
            </Container>
        )
    }

    return (
        <>
            {title && (
                <ContainerLabel
                    isEditable={titleIsEditable}
                    buttons={buttons}
                    topFilters={topFilters}
                    search={search}
                    value={title}
                    onChange={onTitleChange}
                />
            )}
            <Box maxWidth="100%" className={getClassName(ONBOARDING_CLASSES.TABLE_CONTAINER)}>
                <Container
                    style={{ boxShadow: inboxView ? 'unset' : undefined }}
                    className={getClassName(STYLE_CLASSES.ROWS)}
                    spacing="12px"
                    maxWidth="100%"
                    borderRadius={0}
                    position="relative"
                    {...hoverHandlers}
                    {...props}
                >
                    {additionalListContainerContent}

                    <RowsList
                        header={header}
                        data={data}
                        rowLink={rowLink}
                        hideFieldLabels={hideFieldLabels}
                        showEmptyValues={showEmptyValues}
                        inboxView={inboxView}
                        useClassNames={useClassNames}
                        object={object}
                        viewOptions={viewOptions}
                        setConfig={setConfig}
                        brandColor={brandColor}
                        selectedRow={selectedRow}
                        isHovered={isContainerHovered}
                    />
                </Container>
            </Box>

            {pagination}
        </>
    )
}

export default React.memo(Rows)
