import { useCallback, useRef, useState } from 'react'

/**
 * Hook for managing ConfirmModal state while using a Promise to track user confirmation state.
 *
 * The hook itself doesn't show the modal component. You are supposed to use a
 * component like ConfirmModal in combination with this hook.
 *
 * This is useful for when you need to inject the confirmation step into an
 * existing flow that uses Promises to keep track of state. One such example is
 * injecting the confirmation step in form submit handler.
 */
type Callbacks = {
    resolve: (value?: any) => void
    reject: (value?: any) => void
}
export default function useRequestConfirmModal() {
    const [showModal, setShowModal] = useState(false)
    const callbacks = useRef<Callbacks>()

    const onShowConfirmation = () => {
        setShowModal(true)
        return new Promise((resolve, reject) => {
            callbacks.current = { resolve, reject }
        })
    }

    const onAllow = useCallback(() => {
        setShowModal(false)
        callbacks.current?.resolve()
        callbacks.current = undefined
    }, [setShowModal])

    const onDeny = useCallback(() => {
        setShowModal(false)
        callbacks.current?.reject()
        callbacks.current = undefined
    }, [setShowModal])

    return { onShowConfirmation, showModal, onAllow, onDeny }
}
