import React from 'react'

import type { History, Location } from 'history'

import { useAppUserContext } from 'app/AppUserContext'
import { EditModeListContextProvider } from 'features/admin/edit-mode/ListView/EditModeListContextProvider'

import List from 'v2/ui/components/List/List'

import DashboardWidget from '../Dashboard/DashboardWidget'
import { ListViewOrderBy } from '../utils/orderBy/types'

import ListViewButtons from './ListViewButtons'
import { CustomListRendererComponent, RowLinkFunction, ViewColumn } from './types'
import { ViewState } from './viewStateType'

type ListViewEditModeProps = {
    setConfig: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    setEndUserFilters: (newFilters: Filter) => void
    setEndUserFilters__NotForBackend: (newFilters: Filter) => void
    rowLinkFunction: RowLinkFunction
    setCalendarFilter: (filter: {
        startDate: string
        endDate: string
        selectedDate: string
        currentView: string
    }) => void
    setOrderBy: (order?: ListViewEditModeProps['orderBy']) => void
    showControls?: boolean
    isRecordList?: boolean
    noLinks?: boolean
    enableBackendFiltering?: boolean
    isServerLoading?: boolean
    view?: ViewDto
    feature?: FeatureDto
    viewState?: ViewState
    object?: ObjectDto
    title?: string
    columns?: ViewColumn[]
    filteredRecords?: RecordDto[]
    overrideViewOptions?: ListViewOptions
    endUserFilters?: Filter[]
    allFilters__NotForBackend?: Filter[]
    dereferencedRecords?: RecordDto[]
    totalResults?: number
    relatedFieldMayCreateNewRecords?: boolean
    hideEditControls?: boolean
    isRecordListOnCreate?: boolean
    listEditControls?: React.ComponentType
    relatedListSymmetricColumnName?: string
    relatedListType?: string
    autoFilledRelatedListRecord?: string
    calendarFilter?: {
        startDate: string
        endDate: string
        selectedDate: string
        currentView: string
    }
    orderBy?: ListViewOrderBy
    relatedListAttrs?: { displayCharts: boolean }
    location?: Location
    history?: History
    relatedListField?: string
    hiddenColumnIds: string[]
    userFilterFieldIds: string[]
    hideSearch?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    customListRenderer?: CustomListRendererComponent
    allowDownload?: boolean
    onDownloadCsv?: () => Promise<void>
    setPageIndex: (pageIdx: number) => void
    setPageSize: (pageSize: number) => void
    pageSize?: number
    pageIndex: number
}

const ListViewEditMode: React.FC<ListViewEditModeProps> = ({
    setConfig,
    showControls,
    setEndUserFilters,
    setEndUserFilters__NotForBackend,
    rowLinkFunction,
    setCalendarFilter,
    setOrderBy,
    isRecordList,
    noLinks,
    enableBackendFiltering,
    isServerLoading,
    view,
    feature,
    viewState,
    object,
    title,
    columns,
    hiddenColumnIds,
    userFilterFieldIds,
    filteredRecords,
    overrideViewOptions,
    endUserFilters,
    allFilters__NotForBackend,
    dereferencedRecords,
    totalResults,
    relatedFieldMayCreateNewRecords,
    hideEditControls,
    isRecordListOnCreate,
    listEditControls,
    relatedListSymmetricColumnName,
    relatedListType,
    autoFilledRelatedListRecord,
    calendarFilter,
    orderBy,
    relatedListAttrs,
    history,
    location,
    hideSearch,
    getPageSizeOptions,
    getDefaultPageSize,
    customListRenderer,
    allowDownload,
    onDownloadCsv,
    setPageIndex,
    setPageSize,
    pageSize,
    pageIndex,
}) => {
    const { isAdmin } = useAppUserContext()

    // When in SB mode, we show an `Add new` button on any empty list for admins and end users
    const emptyStateAdditionalActions =
        object?.connection_options?.stacker_native_object && isAdmin ? (
            <ListViewButtons
                object={object}
                feature={feature}
                viewState={viewState}
                isRecordList={isRecordList}
                relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
                hideEditControls={hideEditControls}
                isRecordListOnCreate={isRecordListOnCreate}
                listEditControls={listEditControls}
                relatedListSymmetricColumnName={relatedListSymmetricColumnName}
                relatedListType={relatedListType}
                autoFilledRelatedListRecord={autoFilledRelatedListRecord}
            />
        ) : null

    return (
        <>
            {(!isRecordList || relatedListAttrs?.displayCharts) && (
                <DashboardWidget
                    view={viewState?.view || view}
                    onChange={setConfig}
                    filters={allFilters__NotForBackend}
                    viewContext={isRecordList ? 'relatedList' : 'list'}
                />
            )}
            <EditModeListContextProvider disableEditControls={isRecordList}>
                {/* @ts-expect-error */}
                <List
                    enableBackendFiltering={enableBackendFiltering}
                    key={viewState?.view?.options?.display}
                    object={object}
                    feature={feature}
                    isRecordList={isRecordList}
                    title={title}
                    columns={columns}
                    hiddenColumnIds={hiddenColumnIds}
                    userFilterFieldIds={userFilterFieldIds}
                    data={filteredRecords}
                    dereferencedRecords={dereferencedRecords}
                    totalResults={totalResults}
                    isServerLoading={isServerLoading}
                    endUserFilters={endUserFilters}
                    setEndUserFilters={setEndUserFilters}
                    setEndUserFilters__NotForBackend={setEndUserFilters__NotForBackend}
                    showFilters={viewState?.view?.options?.enableEndUserFilters}
                    display={viewState?.view?.options?.display}
                    viewOptions={overrideViewOptions}
                    showControls={showControls}
                    buttons={
                        <ListViewButtons
                            object={object}
                            feature={feature}
                            viewState={viewState}
                            isRecordList={isRecordList}
                            relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
                            hideEditControls={hideEditControls}
                            isRecordListOnCreate={isRecordListOnCreate}
                            listEditControls={listEditControls}
                            relatedListSymmetricColumnName={relatedListSymmetricColumnName}
                            relatedListType={relatedListType}
                            autoFilledRelatedListRecord={autoFilledRelatedListRecord}
                        />
                    }
                    rowLink={noLinks || !feature ? undefined : rowLinkFunction}
                    setConfig={setConfig}
                    history={history}
                    location={location}
                    showPageSizeOptions={viewState?.view?.options?.allowChangePageSize}
                    getPageSizeOptions={getPageSizeOptions}
                    getDefaultPageSize={getDefaultPageSize}
                    emptyStateAdditionalActions={emptyStateAdditionalActions}
                    setCalendarFilter={setCalendarFilter}
                    calendarFilter={calendarFilter}
                    onOrderByChange={setOrderBy}
                    orderBy={orderBy}
                    hideSearch={hideSearch}
                    customRenderer={customListRenderer}
                    allowDownload={allowDownload}
                    onDownloadCsv={onDownloadCsv}
                    setServerPageIndex={setPageIndex}
                    setServerPageSize={setPageSize}
                    serverPageSize={pageSize}
                    serverPageIndex={pageIndex}
                />
            </EditModeListContextProvider>
        </>
    )
}

export default ListViewEditMode
