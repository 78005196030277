import { getAutoNumberAbbreviation } from './getAutoNumberAbbreviation'

export function mutate_convertDesktopLayoutToMobile({
    charts,
    maxCols = 2,
}: {
    charts: ChartParameters[]
    maxCols?: number
}) {
    const layout = [
        { x: 0, last_y: -1 },
        { x: 1, last_y: -1 },
    ]

    // sort vertically (y) then, if y are equals, by x
    const sorted = charts.sort(({ dataGrid: dataGridA }, { dataGrid: dataGridB }) =>
        dataGridA.y < dataGridB.y
            ? -1
            : dataGridA.y === dataGridB.y && dataGridA.x < dataGridB.x
            ? -1
            : 1
    )

    const result: ChartParameters[] = []

    for (const chart of sorted) {
        // the column we are adding the chart to
        let targetColumn = layout[0]

        // if the chart is width=1 and if the second column is smaller, the target becomes the second column
        if (chart.dataGrid.w === 1 && layout[1].last_y < layout[0].last_y) {
            targetColumn = layout[1]
        } else if (chart.dataGrid.w > 1) {
            // if the chart is wider than 1 column, it's always added to the first column,
            // and we need to adjust the second column's height accordingly,
            // because the chart is also going to take space in that column
            layout[1].last_y = Math.max(
                layout[1].last_y + chart.dataGrid.h,
                layout[0].last_y + chart.dataGrid.h
            )
        }

        result.push({
            ...chart,
            dataGrid: {
                ...chart.dataGrid,
                x: targetColumn.x,
                y: targetColumn.last_y + 1,
                w: Math.min(maxCols, chart.dataGrid.w),
                layoutOptimisedForMobile: 'yes',
            },
        })

        // add the chart's height to the target column
        targetColumn.last_y += chart.dataGrid.h
    }

    return result
}

function getDecimalPlaces({
    display,
    config,
    fieldType,
}: {
    display: ChartParamsDisplay
    config: ChartParamsConfig
    fieldType: FieldType | undefined
}) {
    // count is always an integer
    if (config.aggr.type === 'count') {
        return 0
    }

    // if set to a numerical value, use that
    if (display.decimalPlaces !== undefined) {
        const asInteger = parseInt(display.decimalPlaces)

        // only return if a valid integer, otherwise fall through to logic below
        if (!isNaN(asInteger)) {
            return asInteger
        }
        return undefined
    }

    // if we're here, it means the value is not numeric
    // the default behaviour here depends upon the field type
    if (fieldType === 'currency') {
        return 2
    }

    // note, returning undefined just means 'handle a maxN format' in the caller
    // right now only max2 is supported
    return undefined
}

export function formatNumberValue({
    value,
    display,
    config,
    metrics,
}: {
    value: number
    display: ChartParamsDisplay
    config: ChartParamsConfig
    metrics: MetricsQueryResponse
}) {
    const fieldType = metrics.field_display_meta?.field_type as FieldType | undefined
    let abbreviation =
        display.abbreviation !== 'none' ? display.abbreviation?.toUpperCase?.() : undefined

    if (display.autoAbbreviation) {
        const { value: abbreviatedValue, abbreviation: autoAbbreviation } =
            getAutoNumberAbbreviation(value)
        value = abbreviatedValue
        abbreviation = autoAbbreviation
    } else {
        const abbreviation = display.abbreviation?.toUpperCase?.()

        if (abbreviation === 'K') {
            value = value / 1000
        } else if (abbreviation === 'M') {
            value = value / 1000000
        }
    }

    const decimalPlaces = getDecimalPlaces({ display, fieldType, config })

    // use Intl.NumberFormat() for number formatting which
    // automatically formats the number appropriately for the end user's locale
    //
    // i.e. this is a browser setting, it will render differently depending on the user's locale, it's
    // not something configured in the stack. We could override this later if we see a need for it
    // but I think it's the right default behaviour.
    //
    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    // for the options config

    const intlNumberFormatOptions = {
        maximumFractionDigits: 2, // show up to 2 decimal places (this is the default hardcoded max2 behaviour, see comment in the getDecimalPlaces function)
        useGrouping: true, // render thousands separators etc according to the locale
    }

    // if decimalPlaces is configured, always render the value to that number of decimal places
    if (decimalPlaces !== undefined) {
        // @ts-expect-error
        intlNumberFormatOptions.minimumFractionDigits = decimalPlaces
        intlNumberFormatOptions.maximumFractionDigits = decimalPlaces
    }

    // use the default locale by passing undefined
    // this would be where we could set a configured override, for example
    //
    // note you can also manually set this to test different locale renderings
    return { value: value.toLocaleString(undefined, intlNumberFormatOptions), abbreviation }
}
