import React, { FC, ReactNode } from 'react'

import { BoxProps, useRadioGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box } from 'v2/ui'

import { CustomRadio } from './CustomRadio'

const WRAPPERS = {
    full: styled(Box)`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    `,
    compact: styled(Box)`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: ${(props) => props.theme.colors.grey[200]};
        padding: ${(props) => props.theme.space[1]};
        border-radius: 4px;
    `,
}
type Props = Omit<BoxProps, 'onChange'> & {
    options: { value: string; label: string; icon?: ReactNode }[]
    isDisabled?: boolean
    onChange: (value: any) => void
    customRadioStyles?: React.CSSProperties
    selectedOption?: string
    bgChecked?: string
    bgNotChecked?: string
    textColor?: string
    children?: React.ReactNode
    variant?: 'full' | 'compact'
}

export const CustomRadioGroup: FC<Props> = ({
    options,
    selectedOption,
    isDisabled,
    onChange,
    bgChecked,
    bgNotChecked,
    variant = 'full',
    ...boxProps
}) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        onChange,
        value: selectedOption,
        isDisabled,
    })

    const groupProps = getRootProps()

    const Wrapper = WRAPPERS[variant]

    return (
        // @ts-expect-error
        <Wrapper {...groupProps} {...boxProps}>
            {options.map(({ value, label, icon }) => {
                const radioProps = getRadioProps({ value })

                return (
                    // @ts-expect-error
                    <CustomRadio
                        variant={variant}
                        key={value}
                        label={label}
                        icon={icon}
                        data-testid={`radio-button-group.radio-${value}`}
                        bgChecked={bgChecked}
                        bgNotChecked={bgNotChecked}
                        {...radioProps}
                    />
                )
            })}
        </Wrapper>
    )
}
