import { useMemo } from 'react'
import { useFormContext, UseFormReturn } from 'react-hook-form'

export function isFormDirty(context: UseFormReturn<any>) {
    return Object.keys(context.formState.dirtyFields).length > 0
}

export default function useIsFormDirty(): boolean {
    const { formState } = useFormContext()

    const hasChanges = useMemo(() => {
        const isDirty = Object.keys(formState.dirtyFields).length > 0
        if (isDirty) {
            console.log({ dirtyFields: formState.dirtyFields })
        }
        return isDirty
    }, [formState])

    return hasChanges
}
