import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { queryClient } from 'data/hooks/_helpers'
import { useUpdateAccount } from 'data/hooks/accounts'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidatePages } from 'data/hooks/pages'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { invalidateViews } from 'data/hooks/views'
import { REACT_QUERY } from 'data/utils/constants'
import { fetchWithAuth } from 'data/utils/utils'
import { validateSubdomain } from 'data/utils/validateSubdomain'

import { ConfirmationModal, Flex, Input, Text } from 'v2/ui'
import {
    FEATURES,
    isFeatureLocked,
    ProtectedFeature,
    ProtectedFeatureIndicator,
} from 'v2/ui/utils/ProtectedFeature'
import useRequestConfirmModal from 'v2/ui/utils/useRequestConfirmModal'

import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

import { importUrl } from '../../../data/api/bundlesApi'
import { ExpandSection } from '../../../legacy/v1/ui'
import { isUrlValid } from '../../../utils/utils'
import ImportUpload from '../../bundles/ImportUpload'
import {
    BaseUrlInput,
    Divider,
    FormFrame,
    SettingLabel,
    SettingRow,
} from '../WorkspaceSettingsModalUi'

import { DeleteWorkspace } from './DeleteWorkspace'
import { ImportCompletedModal } from './ImportCompletedModal'
import { updateAccountUrl } from './updateAccountUrl'

type WorkspaceModalGeneralSettingsProps = {
    formRef: any
    children?: React.ReactNode
    onClose: () => void
}

const importUploadedFile = async (url: string, account_id: string | undefined) => {
    return importUrl(url, account_id)
}

export default function WorkspaceModalGeneralSettings({
    formRef,
    children,
    onClose,
}: WorkspaceModalGeneralSettingsProps) {
    const { workspaceAccount, selectedStack } = React.useContext(AppContext)
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const {
        onShowConfirmation: onRequestUrlChange,
        showModal: showUrlChangeRequest,
        onAllow: onAllowUrlChange,
        onDeny: onDenyUrlChange,
    } = useRequestConfirmModal()
    const { user, hasRight } = useAccountUserContext()
    const apiToken = user?.api_token
    const disabled = !hasRight(Rights.ManageSettings)
    const { name, base_url, custom_base_url } = workspaceAccount || {}
    const subdomain = base_url ? base_url.substring(0, base_url.indexOf('.')) : ''
    const [
        isWorkspaceCustomDomainUpgradeModalVisible,
        setIsWorkspaceCustomDomainUpgradeModalVisible,
    ] = useState<boolean>(false)

    const [newStack, setNewStack] = useState<StackDto | null>()
    const history = useHistory()

    const [isImportCompletedModalOpen, setIsImportCompletedModalOpen] = useState<boolean>(false)
    const [isImportCompletedDisabled, setIsImportCompletedDisabled] = useState<boolean>(false)

    const isSupportLogin = localStorage.getItem('support_login') ?? false

    const handleImportCompletedModalClose = () => {
        setIsImportCompletedDisabled(true)
        Promise.all([
            queryClient.invalidateQueries(REACT_QUERY.roles.listName),
            refetchStacks(),
            invalidateViews(),
            invalidatePages(),
            invalidateNavigation(),
            invalidateFeatures(),
        ]).then(() => {
            onClose()
            setIsImportCompletedModalOpen(false)
            if (newStack) {
                history.push(getAfterCreateRedirect(newStack))
            }
        })
    }

    const getAfterCreateRedirect = (stack: StackDto) => {
        let url = getUrl(Urls.Home, stack)

        return url
    }

    const checkWorkspaceCustomBaseUrl = async (value: string) => {
        // if no change or user has cleared the field, return true
        if (value === workspaceAccount?.custom_base_url || value === '') return

        // If custom URL is NOT valid, return error message.
        if (!isUrlValid(value)) return 'This URL is not valid'
        //stack/name?customBaseUrl= endpoint will check if there are any existing
        // accounts and stacks that use provided url
        //N.B. The Stack URL endpoint is also valid for checking custom base URLs on accounts
        const url = `stack/name/?customBaseUrl=${encodeURIComponent(value)}`
        return await fetchWithAuth(url)
            .then((response) => {
                if (response.status >= 400) {
                    return false
                } else
                    return response.json().then((json) => {
                        return json && json.custom_base_url === value
                    })
            })
            .catch(() => {
                return false
            })
            .then((result) => result || 'This URL is already taken')
    }

    return (
        <Form
            onSubmit={(values) => {
                const nextBaseUrl =
                    values.subdomain && `${values.subdomain}.${settings.WORKSPACE_DOMAIN}`
                // require explicit user confirmation if url is changing
                if (nextBaseUrl && nextBaseUrl !== workspaceAccount?.base_url) {
                    return onRequestUrlChange().then(() =>
                        updateAccountUrl(updateAccount, workspaceAccount, values, apiToken)
                    )
                } else {
                    return updateAccountUrl(updateAccount, workspaceAccount, values, apiToken)
                }
            }}
            options={{
                defaultValues: { name, subdomain, custom_base_url },
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame title="General" formRef={formRef} padding={null} paddingFooter={null}>
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Workspace Name</SettingLabel>}
                    rightSide={
                        <FormField
                            as={Input}
                            name="name"
                            formRef={formRef}
                            placeholder={name}
                            variant="settings"
                            disabled={disabled}
                            required
                        ></FormField>
                    }
                />
                <Divider dense />
                <SettingRow
                    leftSide={<SettingLabel>Customize URL</SettingLabel>}
                    rightSide={
                        <FormField
                            as={BaseUrlInput}
                            name="subdomain"
                            formRef={formRef}
                            placeholder={subdomain}
                            variant="settings"
                            required
                            registerOptions={{
                                validate: {
                                    asyncValidate: (value) =>
                                        base_url ? validateSubdomain(base_url, value) : false,
                                },
                            }}
                            flexShrink={1}
                            flexGrow={1}
                            domain={settings.WORKSPACE_DOMAIN}
                            disabled={disabled}
                        />
                    }
                />
                <Divider dense />
                <SettingRow
                    leftSide={
                        <Flex>
                            <SettingLabel>Custom workspace domain</SettingLabel>
                            <ProtectedFeatureIndicator
                                feature={FEATURES.workspaceCustomDomain}
                                onClick={() => setIsWorkspaceCustomDomainUpgradeModalVisible(true)}
                            />
                        </Flex>
                    }
                    rightSide={
                        !isFeatureLocked(FEATURES.workspaceCustomDomain, selectedStack) ? (
                            <FormField
                                as={Input}
                                name="custom_base_url"
                                formRef={formRef}
                                placeholder="enter your custom domain"
                                variant="settings"
                                required={false}
                                registerOptions={{
                                    validate: { asyncValidate: checkWorkspaceCustomBaseUrl },
                                }}
                            ></FormField>
                        ) : (
                            <Text variant="appSettingsBody" ml={2}>
                                Available with a Pro plan or above
                            </Text>
                        )
                    }
                />
                {(!isFeatureLocked(FEATURES.bundles, selectedStack) || isSupportLogin) && (
                    <>
                        <Divider dense />

                        <ExpandSection
                            mb="10px"
                            always
                            // @ts-expect-error
                            heading={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Import an App
                                </div>
                            }
                            text={null}
                        >
                            <>
                                <Text>
                                    Import a .json app file and add a new app to your workspace. You
                                    can import a feature from your app settings. Please do not
                                    import an app with more that 30 records - use the import CSV
                                    feature instead.
                                </Text>
                                <ImportUpload
                                    onSuccess={(value: Record<any, any>) => {
                                        importUploadedFile(
                                            value.filesUploaded[0].url,
                                            workspaceAccount?._sid
                                        ).then((stack: any) => {
                                            const typedStack = stack as StackDto
                                            setNewStack(typedStack)
                                            setIsImportCompletedModalOpen(true)
                                        })
                                    }}
                                />
                            </>
                        </ExpandSection>

                        <ImportCompletedModal
                            isOpen={isImportCompletedModalOpen}
                            isDisabled={isImportCompletedDisabled}
                            onClose={() => {
                                handleImportCompletedModalClose()
                            }}
                        />
                    </>
                )}

                <DeleteWorkspace isInModal prefix={<Divider dense />} />
                <ProtectedFeature
                    feature={FEATURES.workspaceCustomDomain}
                    showModal={isWorkspaceCustomDomainUpgradeModalVisible}
                    onModalClosed={() => setIsWorkspaceCustomDomainUpgradeModalVisible(false)}
                />
            </FormFrame>
            <ConfirmationModal
                isOpen={showUrlChangeRequest}
                title="Confirm URL change"
                onClose={onDenyUrlChange}
                onConfirm={onAllowUrlChange}
                details="Are you sure that you wish to update the workspace URL? Any links to your
            previous url will no longer work."
                zIndex={1500}
            />
            {children}
        </Form>
    )
}
