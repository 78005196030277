import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { withUser } from 'data/wrappers/WithUser'
import publicAsset from 'utils/publicAsset'

import { Flex, Text } from 'v2/ui'

const WorkspaceAuth = ({ user, isLoggedIn, isStudioUser, userActions }) => {
    const [loginFailed, setLoginFailed] = useState()
    const { workspaceAccount } = useAppContext()

    const { isFetching: loadingMetadata } = useInitialMetadata()
    let query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    useEffect(() => {
        // If we have a token in the URL, try to authenticate with it.
        const token = query.token || query.api_token
        if (token) {
            userActions.logInStudioUserByApiToken(token).then((token) => {
                if (!token) {
                    setLoginFailed(true)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Don't redirect if we're still re-fetching the meta data
    // after authenticating the user
    if (!loadingMetadata && isLoggedIn && isStudioUser) {
        if (query.r) {
            return <Redirect to={query.r} />
        }
        if (workspaceAccount) {
            // If the profile info hasn't been set send them there
            // (unless the ignoreProfile param is set)
            if (user?.membership_options?.profile_pending && !query.ignoreProfile) {
                return <Redirect to={getUrl(Urls.EditProfile)} />
            } else {
                return <Redirect to={getUrl(Urls.Root)} />
            }
        } else {
            return <Redirect to={getUrl(Urls.Stacks)} />
        }
    }

    if (loginFailed) {
        if (workspaceAccount) {
            return (
                <Flex width="100%" height="100%" column justify="center">
                    <Text>You do not have access to this workspace.</Text>
                </Flex>
            )
        } else {
            return (
                <Flex width="100%" height="100%" column justify="center">
                    <Text>This login link has expired.</Text>
                </Flex>
            )
        }
    }

    return (
        <Flex width="100%" height="100vh" column justify="center">
            <img
                alt=""
                src={publicAsset('/static/media/Spinner.svg')}
                style={{
                    marginLeft: 0,
                    marginRight: 8,
                    height: 64,
                }}
            />
        </Flex>
    )
}
export default withUser(WorkspaceAuth)
