import * as React from 'react'

import { useDashboardContext } from 'v2/views/Dashboard/context'

import { useLegacyMetrics } from 'data/hooks/metrics/legacyMetrics'
import { useObject } from 'data/hooks/objects'

import { Icon } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Flex from 'ui/deprecated/atoms/Flex'

import { ChartLabel } from './recharts/ui'
const colors = stackerTheme().colors

const RechartsPie = React.lazy(() => import('./recharts/Pie'))
/**
 * @param {ChartParameters} param0
 */
export default function PieChart({ config, display, dataGrid }) {
    const { width } = useDashboardContext()
    const { data: metrics } = useLegacyMetrics(config, { suspense: true, retry: 0 })
    const { object: chartObject } = useObject(config.object_sid)
    return (
        <>
            <ChartLabel
                style={{
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingRight: 30,
                    margin: '0 -10px',
                    minHeight: 32,
                    borderBottom: '1px solid rgba(0,0,0,0.05)',
                }}
                className="ellipsis"
            >
                {display.label}
            </ChartLabel>
            {metrics.data.length > 0 ? (
                <RechartsPie
                    key={dataGrid.h + '-' + dataGrid.w}
                    config={config}
                    metrics={metrics}
                    display={display}
                    chartObject={chartObject}
                    width={dataGrid.w * ((width - 50) / 4)}
                    height={dataGrid.h * 110 - 20}
                    options={{
                        ...matrix[`${dataGrid.w}-${dataGrid.h}`],
                        ...(display.maxItems ? { maxItems: display.maxItems } : {}),
                    }}
                    compact={dataGrid.w === 1}
                    {...matrix[`${dataGrid.w}-${dataGrid.h}`]}
                    legacy
                />
            ) : (
                <Flex
                    column
                    style={{ justifyContent: 'center', height: '100%', position: 'relative' }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: 'auto',
                            width: 106,
                        }}
                    >
                        <Icon icon="pie" style={{ fontSize: 100, color: colors.gray[200] }} />
                    </div>
                    <div
                        style={{
                            color: colors.gray[700],
                            position: 'relative',
                            textAlign: 'center',
                            fontWeight: 500,
                        }}
                    >
                        No data to display
                    </div>
                </Flex>
            )}
        </>
    )
}

export const matrix = {
    '1-2': {
        legend: 'bottom',
        showLabels: false,
        innerRadius: 30,
        outerRadius: 50,
        maxItems: 5,
    },
    '2-2': {
        legend: false,
        showLabels: true,
        innerRadius: 40,
        outerRadius: 70,
        maxItems: 10,
    },
    '1-3': {
        legend: 'bottom',
        showLabels: false,
        innerRadius: 50,
        outerRadius: 80,
        maxItems: 10,
    },
    '2-3': {
        legend: false,
        showLabels: true,
        innerRadius: 65,
        outerRadius: 100,
        maxItems: 10,
    },
}

PieChart.gridParams = {
    minW: 1,
    maxW: 2,
    defaultW: 2,
    maxH: 3,
    minH: 2,
}
