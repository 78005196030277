/* eslint-disable simple-import-sort/imports -- Disabled to avoid circular dependencies, remove when file is removed from .freezircularDeps */

import React from 'react'
import TextAttribute from './TextAttribute'

const inputProps = {
    inputMode: 'number',
}
const validRegEx = /[^0-9.]*/g
const extraPeriods = /(\..*?)\./g
const NumberAttribute = (props) => {
    const massageInput = (value) => {
        // Check if negative
        const negative = value && value.charAt(0) == '-'
        // Strip all non numeric and non-decimal characters
        value = value.replace(validRegEx, '')
        // strip all but the first decimal period
        value = value.replace(extraPeriods, '$1')
        return `${negative ? '-' : ''}${value}`
    }

    return (
        <TextAttribute massageInput={massageInput} inputProps={inputProps} {...props}>
            {props.children}
        </TextAttribute>
    )
}

export default NumberAttribute
