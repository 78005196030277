/* eslint-disable */
/* Code Quality: Not audited */

import React, { createContext, useContext, memo } from 'react'
import { context as blockActions } from './broadcast/Context'

function createOwnContext(name) {
    const context = createContext()

    const OldProvider = context.Provider

    context.Provider = (props) => {
        return <OldProvider {...props} />
    }
    context.Provider.displayName = 'ContextProvider_' + name

    return context
}

export const urls = createOwnContext('urls')
export const user = createOwnContext('user')
export const url = createOwnContext('url')
export const query = createOwnContext('query')
export const deviceType = createOwnContext('deviceType')
export const propertyStore = createOwnContext('propertyStore')
export const editor = createOwnContext('editor')
export const actions = createOwnContext('actions')
export const direction = createOwnContext('direction')
export const block = createOwnContext('block')
export const record = createOwnContext('record')
export const tree = createOwnContext('tree')
export const input = createOwnContext('input')
export const object = createOwnContext('object')
export const app = createOwnContext('app')
export const translation = createOwnContext('translation')
export const language = createOwnContext('language')
export const theme = createOwnContext('theme')

/**
 *
 * @param {{children: (props: BlockContext) => any}} props
 */
function _BlockContextConsumer({ children, only, exclude }) {
    // console.count("BlockContextConsumer")
    function useConditionalContext(name, context) {
        if ((only && !only.includes(name)) || (exclude && exclude.includes(name))) return

        return useContext(context)
    }
    const context = {
        user: useConditionalContext('user', user),
        url: useConditionalContext('url', url),
        query: useConditionalContext('query', query),
        deviceType: useConditionalContext('deviceType', deviceType),
        propertyStore: useConditionalContext('propertyStore', propertyStore),
        editor: useConditionalContext('editor', editor),
        actions: useConditionalContext('actions', actions),
        direction: useConditionalContext('direction', direction),
        block: useConditionalContext('block', block),
        record: useConditionalContext('record', record),
        tree: useConditionalContext('tree', tree),
        input: useConditionalContext('input', input),
        object: useConditionalContext('object', object),
        app: useConditionalContext('app', app),
        translations: useConditionalContext('translations', translation),
        language: useConditionalContext('language', language),
        theme: useConditionalContext('theme', theme),
        broadcast: useConditionalContext('broadcast', blockActions),
    }
    return children(context)
}

export const BlockContextConsumer = memo(_BlockContextConsumer)

export function BlockContextProvider({ children, data, tree: treeInstance }) {
    return (
        <app.Provider value={data.app}>
            <object.Provider value={data.object}>
                <input.Provider value={data.input}>
                    <record.Provider value={data.record}>
                        <actions.Provider value={data.actions}>
                            <user.Provider value={data.user}>
                                <url.Provider value={data.url}>
                                    <query.Provider value={data.query}>
                                        <deviceType.Provider value={data.deviceType}>
                                            <editor.Provider value={data.editor}>
                                                <direction.Provider value={data.direction}>
                                                    <tree.Provider value={treeInstance}>
                                                        <block.Provider value={data.block}>
                                                            {children}
                                                        </block.Provider>
                                                    </tree.Provider>
                                                </direction.Provider>
                                            </editor.Provider>
                                        </deviceType.Provider>
                                    </query.Provider>
                                </url.Provider>
                            </user.Provider>
                        </actions.Provider>
                    </record.Provider>
                </input.Provider>
            </object.Provider>
        </app.Provider>
    )
}

//export const DragDropConsumer = _DragDropConsumer
