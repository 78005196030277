import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
    FeedContext,
    FlatFeed,
    Gallery,
    LoadMorePaginator,
    StreamContext,
} from 'react-activity-feed'
import { FileIcon } from 'react-file-utils'

import { Spinner, Switch } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import get from 'lodash/get'

import AppUserContext, { Rights } from 'app/AppUserContext'
import { useAppUsers } from 'data/hooks/users/main'
import { withUser } from 'data/wrappers/WithUser'
import useMentions from 'features/collaboration/Mentions'
import { useStreamAppContext } from 'utils/StreamApp'
import { sanitizeURL } from 'utils/utils'

import {
    Avatar,
    Box,
    Button,
    ConfirmationModal,
    Container,
    Flex,
    Icon,
    RichTextarea,
    Text,
} from 'v2/ui'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { checkIsCollaborationActivityDisabled } from './checkIsCollaborationActivityDisabled'
import CreatePostForm from './CreatePostForm'
import useFeedClient from './useFeedClient'
import withStream from './withStream'

import 'react-activity-feed/dist/index.css'
import './StreamStyles.css'

export const CollaborationPanelUnwrapped = memo(
    ({ id, relatedTo, onItemsLoaded, isStudioUser, isImpersonating }) => {
        const client = useFeedClient(id)
        const { data: users, isLoading: isUsersLoading } = useAppUsers()
        const [hasStreamError, setHasStreamError] = useState(false)
        const [hasAppUserError, setHasAppUserError] = useState(false)
        const [isFollowing, setIsFollowing] = useState(false)
        const [deleteModal, setDeleteModal] = useState() // Contains the activity id to delete
        const [deletedActivities, setDeletedActivities] = useState([])
        const { isCurrentTokenValid, getStreamToken, streamToken } = useStreamAppContext()

        const memoizedUsers = useMemo(() => {
            if (!users && !isUsersLoading) {
                setHasAppUserError(true)
            } else {
                setHasAppUserError(false)
            }
            return users
        }, [users, isUsersLoading])

        const getIsFollowing = () => {
            if (!client.isReady) return
            client
                .getIsFollowingRecord()
                .then((r) => {
                    setIsFollowing(r)
                })
                .catch((ex) => {
                    onError(ex)
                })
        }

        // refetch the following status when the stream token changes
        useEffect(() => {
            if (!hasStreamError && !hasAppUserError) {
                getIsFollowing()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [client, streamToken, hasStreamError, hasAppUserError])

        // This will clear the error state when the stream token has changed
        useEffect(() => {
            if (streamToken && hasStreamError) {
                setHasStreamError(false)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [streamToken])

        const follow = () => {
            if (!isFollowing) {
                toggleFollow()
            }
        }
        const toggleFollow = () => {
            client.setIsFollowingRecord(!isFollowing)
            setIsFollowing(!isFollowing)
        }

        const onError = (e) => {
            // Token has expired so try to get a new one
            if ((e?.status_code === 403 && e?.code === 17) || !isCurrentTokenValid()) {
                getStreamToken()
            } else {
                setHasStreamError(true)
            }
        }

        const handleLoadData = (c, f, u, options) => {
            return client.getActivity({ ...options, limit: 25 }).catch((ex) => {
                // check if we need to get a new token
                onError(ex?.error)
                // we need to return a rejection otherwise the feed component
                // throws an error
                return Promise.reject(ex)
            })
        }

        const handleAddActivity = (activity) => {
            return client.addActivity(activity)
        }

        const deleteModalComponent = () => {
            return (
                <ConfirmationModal
                    isOpen={deleteModal}
                    title="Delete this message"
                    onClose={() => setDeleteModal(undefined)}
                    onConfirm={() => {
                        client.removeActivity(deleteModal)
                        const da = deletedActivities
                        da.push(deleteModal)
                        setDeletedActivities(da)
                        setDeleteModal(undefined)
                    }}
                    details="Are you sure you want to delete this message?"
                />
            )
        }

        if (hasStreamError || hasAppUserError) {
            // For stream errors(hasStreamError), we want to allow users to retry connecting to the feed
            // If the issue is that we cannot connect to the app users list, do not allow them to click the retry button
            // Instead, direct them to suppport
            return (
                <Container mt={4} p={8}>
                    {hasAppUserError ? (
                        <Flex column>
                            <Text>An error occurred while loading activity.</Text>
                        </Flex>
                    ) : (
                        <Flex column>
                            <Text>An error occurred connecting to the feed.</Text>
                            <Button
                                mt={4}
                                variant="sm"
                                onClick={() => {
                                    getStreamToken(true)
                                }}
                            >
                                Retry
                            </Button>
                        </Flex>
                    )}
                </Container>
            )
        }

        if (!client.isReady || isUsersLoading) {
            return (
                <Container mt={4} p={8}>
                    <Flex column>
                        <Spinner color="gray.400" />
                    </Flex>
                </Container>
            )
        }

        return (
            <Box mt={'20px'}>
                <Flex position="absolute" right={0} top={0} mt={3}>
                    <Text mr={2} title="Receive notifications any new activity on this record.">
                        Follow
                    </Text>

                    <Switch
                        size="sm"
                        isChecked={isFollowing}
                        onChange={toggleFollow}
                        aria-label="Receive notifications any new activity on this record."
                    />
                </Flex>
                <CreatePostForm
                    feedGroup="record"
                    doRequest={handleAddActivity}
                    client={client.client}
                    relatedTo={relatedTo}
                    onSuccess={follow} //Automatically follow on post
                />

                <Container>
                    <FlatFeed
                        feedGroup="record"
                        userId={client.encryptedFeedId}
                        doFeedRequest={handleLoadData}
                        Notifier={InstantLoad}
                        options={{ reactions: { recent: true } }}
                        notify
                        Placeholder={() => null}
                        LoadingIndicator={() => (
                            <Container p={8}>
                                <Flex column>
                                    <Spinner color="gray.400" />
                                </Flex>
                            </Container>
                        )}
                        Paginator={(props) => (
                            <CustomPaginator onItemsLoaded={onItemsLoaded} {...props} />
                        )}
                        Activity={(props) => {
                            if (deletedActivities.includes(props.activity.id)) return ''
                            else
                                return (
                                    <ActivityItem
                                        key={props.activity.id}
                                        users={memoizedUsers}
                                        removeActivity={() => setDeleteModal(props.activity.id)}
                                        isStudioUser={isStudioUser}
                                        isImpersonating={isImpersonating}
                                        {...props}
                                    />
                                )
                        }}
                    />
                </Container>
                {deleteModalComponent()}
            </Box>
        )
    }
)

const InstantLoad = ({ adds, onClick }) => {
    const isFirstLoad = useRef(true)
    const prevAdds = useRef()

    useEffect(() => {
        if (!isFirstLoad.current) prevAdds.current = adds
    }, [adds])

    // The flatfeed calls this multiple times in quick succession.
    // We should make sure to check to see if adds is different
    // otherwise it can trigger a feed refresh multiple times
    const newAdds = !isEqual(prevAdds.current, adds)

    useEffect(() => {
        if (adds && adds.length > 0 && newAdds) {
            onClick()
        }
    }, [adds, onClick, newAdds])

    useEffect(() => {
        isFirstLoad.current = false
    }, [])

    return null
}

const ActivityItem = ({ activity, removeActivity, isStudioUser, isImpersonating, users }) => {
    const { actor, author } = activity
    const timeDisplay = useDisplayTimeFrom(activity.time)
    const { onToggleReaction, onToggleChildReaction } = useContext(FeedContext)

    const authorUser = users.find((x) => x.user_id === author)
    return (
        <Flex
            borderBottom="1px solid"
            borderColor="grey.100"
            p={[2, 2, 3, 5]}
            align="start"
            wrap="noWrap"
        >
            <Flex column>
                <Avatar
                    src={authorUser?.avatar || get(actor, 'data.profileImage')}
                    size="sm"
                    mr={2}
                />
            </Flex>
            <Box flexGrow={1} minWidth={0}>
                <Flex>
                    <Text variant="feedHeader" flexGrow={1} fontWeight="bold">
                        {authorUser?.name || get(actor, 'data.name')}
                    </Text>
                    <Text variant={'feedTimestamp'}>{timeDisplay}</Text>
                </Flex>

                <ActivityBody activity={activity} users={users} />
                <Flex>
                    <LikeButton
                        activity={activity}
                        onToggleReaction={onToggleReaction}
                        onToggleChildReaction={onToggleChildReaction}
                    />
                    <TrashButton
                        activity={activity}
                        isStudioUser={isStudioUser}
                        isImpersonating={isImpersonating}
                        removeActivity={removeActivity}
                    />

                    {/* <Button
                        variant="reactionButton"
                        onClick={handleCreateReply}
                        ml={1}
                        title="reply to this post"
                    >
                        <Icon icon="comment" size="smmd" color="grey.200" />

                        <Box fontWeight="bold" ml={2}>
                            Reply
                        </Box>
                    </Button> */}
                </Flex>
            </Box>
        </Flex>
    )
}

const TrashButton = ({ activity, removeActivity, isStudioUser, isImpersonating }) => {
    const handleClick = () => {
        //Default onRemoveActivity function doesn't have enough permissions, we need to do this on the backend
        removeActivity()
        return null
    }
    const streamContext = useContext(StreamContext)

    const { hasRight } = useContext(AppUserContext)

    const isAdmin = isStudioUser && !isImpersonating && hasRight(Rights.Admin.Any)
    if (isAdmin || activity.actor.id === streamContext.client.currentUser.id) {
        return (
            <Button ml="auto" variant="reactionButton" onClick={handleClick} title="Delete post">
                <Icon icon="trash" size="smmd" color="grey.200" />
            </Button>
        )
    } else {
        return ''
    }
}

const LikeButton = ({ activity, reaction, onToggleReaction, onToggleChildReaction }) => {
    const handleClick = (...args) => {
        if (reaction && onToggleChildReaction) {
            return onToggleChildReaction(...args)
        }
        return onToggleReaction(...args)
    }

    const target = reaction || activity

    return (
        <ToggleReactionButton
            kind="like"
            activeIcon="likeSolid"
            inactiveIcon="like"
            count={target.count}
            own_reactions={target.own_reactions}
            target={target}
            onToggle={handleClick}
        />
    )
}

const ToggleReactionButton = ({ kind, activeIcon, inactiveIcon, target, onToggle }) => {
    const streamContext = useContext(StreamContext)
    const getNotificationOptions = () => {
        const userToNotify = target.actor.id
        if (userToNotify === streamContext.client.currentUser.id) return {}
        else
            return {
                targetFeeds: ['notification:' + userToNotify],
            }
    }

    const handleClick = () => {
        if (checkIsCollaborationActivityDisabled()) return
        return onToggle(kind, target, {}, getNotificationOptions())
    }

    const userHasReacted =
        target.own_reactions && target.own_reactions[kind] && target.own_reactions[kind].length

    const count = target.reaction_counts && target.reaction_counts[kind]
    const isActive = count > 0
    return (
        <Button
            variant="reactionButton"
            onClick={handleClick}
            title={`${userHasReacted ? 'un-' + kind : kind} this post`}
        >
            <Icon
                icon={isActive ? activeIcon : inactiveIcon}
                size="smmd"
                color={userHasReacted ? 'brand.300' : 'grey.200'}
            />
            {count > 0 && (
                <Box fontWeight="bold" ml={2}>
                    {count}
                </Box>
            )}
        </Button>
    )
}

const ActivityBody = ({ activity, users }) => {
    const { attachments } = activity
    const mentions = useMentions(users)
    const plugins = useMemo(() => [mentions], [mentions])

    let body
    if (activity.object && activity.object !== '{}') {
        try {
            const content = JSON.parse(activity.object)
            body = <RichTextarea readOnly value={content} plugins={plugins} />
        } catch (e) {
            body = <Text>{activity.object.toString()}</Text>
        }
    } else if (activity.text) {
        body = <Text>{activity.text}</Text>
    }

    return (
        <Box>
            {body}
            {Boolean(activity.image) && activity.image !== undefined ? (
                <div style={{ padding: '8px 0' }}>
                    <Gallery images={[activity.image]} />
                </div>
            ) : null}

            {attachments && attachments.images && Boolean(attachments.images.length) && (
                <div style={{ padding: '8px 0' }}>
                    <Gallery images={attachments.images} />
                </div>
            )}

            {attachments && attachments.files && Boolean(attachments.files.length) && (
                <ol className="raf-activity__attachments">
                    {attachments.files.map(({ name, url, mimeType }, i) => (
                        <a href={sanitizeURL(url)} download key={i}>
                            <li
                                className="raf-activity__file"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                <FileIcon mimeType={mimeType} filename={name} /> {name}
                            </li>
                        </a>
                    ))}
                </ol>
            )}
        </Box>
    )
}

const CustomPaginator = ({ onItemsLoaded, ...props }) => {
    useEffect(() => {
        if (onItemsLoaded) {
            onItemsLoaded(props.children.length, props.hasNextPage)
        }
    }, [onItemsLoaded, props.children, props.hasNextPage])
    return <LoadMorePaginator {...props} />
}

export default withStream(withUser(CollaborationPanelUnwrapped))
