import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import { useObjectFromId } from 'features/actions/helpers'
import Filters, { formatFilters } from 'features/records/components/RecordFilters'
import { queryStringSuffixMap } from 'features/utils/filtersToQueryDict'
import { getFilterFieldFromName } from 'features/utils/filterToField'

import CheckboxForm from 'ui/forms/CheckboxForm'
import { RenderInlineElement } from 'ui/forms/ui'

export default function FiltersForm({
    label,
    name,
    subtitle = '',
    objectId,
    defaultValue,
    required = false,
    errorMessages = {},
    canInheritFilters,
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const { object } = useObjectFromId(objectId)

    if (!object) {
        return null
    }

    const error = get(errors, name)

    return (
        <RenderInlineElement
            label={label}
            error={error}
            errorMessages={errorMessages}
            subtitle={subtitle}
        >
            {canInheritFilters && (
                <CheckboxForm
                    name="config.inherit_filters_from_view_id"
                    label="Use the filters from the list?"
                />
            )}
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <div
                        style={{
                            background: 'white',
                            margin: '5px -10px -10px',
                            padding: 10,
                            borderRadius: 3,
                            boxShadow: '0 0 0 2px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Filters
                            object={object}
                            fields={object.fields}
                            value={filtersToValue(field.value, object)}
                            onChange={(filters) => {
                                const filtersFormattedForQuery = formatFilters(filters)

                                field.onChange(filtersFormattedForQuery)
                            }}
                        />
                    </div>
                )}
            />
        </RenderInlineElement>
    )
}

// reverse `formatFilter()`: get filter and turns them into "raw filters", the format <Filter is expecting
export function filtersToValue(filters, object) {
    if (!filters || !object) {
        return []
    }
    return filters.map((filter) => ({
        _id: Math.random(),
        field: getFilterFieldFromName(object, filter.target),
        options: {
            operator: 'AND',
            option: filter.option || getOperationNameFromFilter(filter.operation),
            value: filter.value,
        },
    }))
}
function getOperationNameFromFilter(operation) {
    if (!operation) {
        return 'is'
    }
    const name = Object.entries(queryStringSuffixMap).find(
        ([_, key]) => key === `__${operation}`
    )[0]

    return name
}
