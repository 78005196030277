import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export const LinkListActions = ({ children }) => {
    return <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{children}</div>
}
