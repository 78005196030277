import React from 'react'

import { useTheme } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'

import { Box, ConditionalWrapper, Tooltip } from 'v2/ui'

const Icon = ({ icon, size, label, svg, labelPlacement = 'left', style, ...props }) => {
    const theme = useTheme()

    const FaIcon = (props) => {
        if (!icon) return null
        const iconDef = get(theme, `stackerIcons.${icon}`)
        return (
            <span style={{ fontSize: size, ...style }}>
                <FontAwesomeIcon icon={iconDef} {...props} />
            </span>
        )
    }

    return (
        <ConditionalWrapper
            condition={label}
            wrapper={(children) => (
                <Tooltip placement={labelPlacement} label={label}>
                    {children}
                </Tooltip>
            )}
        >
            {icon && <FaIcon />}
            {svg && <Box as={svg.type} size={size} fill={props.fill} />}
        </ConditionalWrapper>
    )
}

export default React.memo(Icon)
