import { TIME_PERIOD_FILTERS } from '.'

type GetPeriodFilterProps = {
    value: string | undefined
    fieldApiName: string
    previousTimePeriod?: boolean
}

export const getTimePeriodFilters = ({
    value,
    fieldApiName,
    previousTimePeriod = false,
}: GetPeriodFilterProps): ChartFilter[] => {
    const timePeriodFilter = TIME_PERIOD_FILTERS.find((option) => option.value === value)
    return (
        (previousTimePeriod
            ? timePeriodFilter?.previousPeriod(fieldApiName)
            : timePeriodFilter?.currentPeriod(fieldApiName)) ?? []
    )
}
