import React from 'react'

import publicAsset from 'utils/publicAsset'

import { Box, Flex, Text } from 'v2/ui'
import { AppBuild } from 'v2/ui/svgs'

const WorkspaceNotReady = () => {
    return (
        <Flex height="100%" alignItems="stretch">
            <Flex
                column
                backgroundColor="#d8d8d8"
                width="80px"
                height="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex column alignItems="center">
                    <Box
                        backgroundColor="#fff"
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        mt="16px"
                    />
                    <Box
                        backgroundColor="#f5f5f5"
                        width="56px"
                        height="56px"
                        borderRadius="8px"
                        mt="36px"
                    />
                    <Box
                        backgroundColor="#fff"
                        width="48px"
                        height="48px"
                        borderRadius="8px"
                        mt="10px"
                    />
                    <Box
                        backgroundColor="#fff"
                        width="48px"
                        height="48px"
                        borderRadius="8px"
                        mt="10px"
                    />
                </Flex>
                <Flex column alignItems="center">
                    <Box
                        backgroundColor="#fff"
                        width="28px"
                        height="28px"
                        borderRadius="50%"
                        mt="16px"
                    />
                    <Box
                        backgroundColor="#fff"
                        width="28px"
                        height="28px"
                        borderRadius="50%"
                        mt="16px"
                    />
                    <Box
                        backgroundColor="#fff"
                        width="28px"
                        height="28px"
                        borderRadius="50%"
                        mt="16px"
                        mb="24px"
                    />
                </Flex>
            </Flex>

            <Flex align="center" flexGrow={1}>
                <Flex column width="100%">
                    <AppBuild style={{ maxWidth: '100%' }} />
                    <Text fontSize="lg" fontWeight="bold" mt={8} textAlign="center">
                        Creating your Stacker workspace
                    </Text>
                    <Text fontSize="mdlg" color="gray.500" mt={3} mb={12} textAlign="center">
                        It will be ready soon!
                    </Text>

                    <img
                        alt=""
                        src={publicAsset('/static/media/Spinner.svg')}
                        style={{
                            marginLeft: 0,
                            marginRight: 8,
                            height: 64,
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default WorkspaceNotReady
