import React from 'react'

import { Box, Text } from 'v2/ui'

function formatCount(count: number): string {
    return count === 1 ? `${count} record` : `${count} records`
}

type DataListBlockDataCounterProps = React.ComponentPropsWithoutRef<typeof Box> & {
    count: number
}

export const DataListBlockDataCounter: React.FC<DataListBlockDataCounterProps> = ({
    count,
    ...props
}) => {
    const formattedCount = formatCount(count)

    return (
        <Box {...props}>
            <Text
                color="brand.400"
                fontWeight="bold"
                px={2}
                py={1}
                backgroundColor="brand.100"
                rounded={5}
                whiteSpace="nowrap"
            >
                {formattedCount}
            </Text>
        </Box>
    )
}
