// Note: this only really works if array items are unique as
// [3,3,2,2], [3,3,3,2] would be returned as true due to
// comparing sets
const isArrayEqual = (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false
    if (a.length !== b.length) return false
    let aSet = new Set(a)
    let bSet = new Set(b)
    if (aSet.size !== bSet.size) return false
    if ([...bSet].some((item) => !aSet.has(item))) return false
    return true
}

export default isArrayEqual
