import React from 'react'

import { withObject } from 'data/wrappers/WithObject'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import CoverImage from 'features/pages/blocks/settings/attributes/items/form/CoverImage'
import { canBeHeaderField } from 'utils/fieldUtils'

import { Box, Dropdown, Flex, Text } from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

type RecordHeaderControlsProps = {
    object: ObjectDto
    headerValue: {
        heading?: string
        subheading?: string | null
    }
    onChangeHeader: (object: { heading?: string; subheading?: string | null }) => void
    profileValue: string
    onChangeProfile: (value: string) => void
}

export const RecordHeaderControlsInternal = ({
    object,
    headerValue = {},
    onChangeHeader,
    profileValue,
    onChangeProfile,
}: RecordHeaderControlsProps) => {
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    let { fields } = object
    fields = sortByFieldsOrder(fields as FieldDto[]).filter(
        (field) => !field.connection_options.is_disabled
    )

    // In case the field previously chosen as the header/subheader has been disabled
    const headingField = fields.find((f) => f.api_name === headerValue.heading)
    if (!headerValue.heading || !headingField) {
        headerValue.heading = fields.find((f) => f.is_primary)?.api_name
    }
    const subheadingField = fields.find((f) => f.api_name === headerValue.subheading)
    if (!subheadingField) {
        headerValue.subheading = null
    }

    const options = fields.filter(canBeHeaderField).map((field) => ({
        value: field.api_name,
        label: field.label,
    }))

    return (
        <Flex>
            <Box flex={1}>
                <Box my={4}>
                    <label>
                        <Text variant="paletteSectionLabel">Heading</Text>
                        <Dropdown
                            adminTheme
                            options={options}
                            value={headerValue.heading}
                            onChange={(heading: string) =>
                                onChangeHeader({ ...headerValue, heading })
                            }
                            isClearable={false}
                        />
                    </label>
                </Box>
                <Box mt={4}>
                    <label>
                        <Text variant="paletteSectionLabel">Subheading</Text>
                        <Dropdown
                            adminTheme
                            options={options}
                            value={headerValue.subheading}
                            onChange={(subheading: string) =>
                                onChangeHeader({ ...headerValue, subheading })
                            }
                            isClearable={true}
                        />
                    </label>
                </Box>
                <CoverImage
                    objectId={object._sid}
                    onChange={onChangeProfile}
                    value={profileValue}
                    showImageDisplayOptions={false}
                    label="Profile image"
                />
            </Box>
        </Flex>
    )
}

export const RecordHeaderControls = withObject(RecordHeaderControlsInternal)

type RecordHeaderTitleProps = {
    record: {
        _primary: string
        _permissions: {
            may_update: boolean
            may_update_fields: string[]
        }
    }
    object: {
        _sid: string
        fields: [
            {
                api_name: string
                is_primary: boolean
                _sid: string
                connection_options: {
                    is_disabled: boolean
                    read_only: boolean
                }
            }
        ]
    }
    config: {
        recordHeader?: {
            heading?: string
            subheading?: string
        }
    }
    titleOverride?: string
    editing: boolean
    setValue: (key: string, value: any) => void
    setValid: (key: string, value: any) => void
    showErrors: boolean
    valid: boolean
    titleOnly?: boolean
    profileLayout?: boolean
}

export const RecordHeaderTitle = ({
    record,
    object,
    config,
    titleOverride,
    editing = false,
    showErrors,
    setValue,
    setValid,
    valid,
    titleOnly = false,
    profileLayout,
    ...styleProps
}: RecordHeaderTitleProps) => {
    const headingField =
        object.fields.find(
            (f) => f.api_name === config?.recordHeader?.heading && !f.connection_options.is_disabled
        ) || object.fields.find((f) => f.is_primary)
    let heading
    if (titleOverride) {
        heading = <Text as="h2">{titleOverride}</Text>
    } else if (!headingField) {
        heading = <Text as="h2">Record title</Text>
    } else {
        const headingEditable =
            !headingField.connection_options?.read_only &&
            record?._permissions?.may_update &&
            record?._permissions?.may_update_fields.includes(headingField.api_name)

        heading = (
            <Box
                maxWidth="100%"
                mb={editing ? 4 : 2}
                textAlign={profileLayout && (!editing || !headingEditable) ? 'center' : 'left'}
                data-testid="record-header-title"
            >
                <AttributeDisplay
                    objectId={object._sid}
                    fieldId={headingField._sid}
                    record={record}
                    editing={editing && headingEditable}
                    setValue={setValue}
                    setValid={setValid}
                    valid={valid}
                    showErrors={showErrors}
                    width="100%"
                    hideLabel={!editing || !headingEditable}
                    variant="heading"
                    readOnly={!headingEditable}
                />
            </Box>
        )
    }

    const subheadingField = object.fields.find(
        (f) => f.api_name === config?.recordHeader?.subheading && !f.connection_options.is_disabled
    )
    let subheading = <></>
    if (subheadingField && !titleOnly) {
        const subheadingEditable =
            !subheadingField.connection_options?.read_only &&
            record?._permissions?.may_update &&
            record?._permissions?.may_update_fields.includes(subheadingField.api_name)
        subheading = (
            <Box
                color={V4DesignSystem.colors.gray[600]}
                mt={editing ? 4 : 2}
                mr={2}
                maxWidth="100%"
                textAlign={profileLayout && (!editing || !subheadingEditable) ? 'center' : 'left'}
            >
                <AttributeDisplay
                    objectId={object._sid}
                    fieldId={subheadingField._sid}
                    record={record}
                    editing={editing}
                    setValue={setValue}
                    setValid={setValid}
                    valid={valid}
                    showErrors={showErrors}
                    width="100%"
                    hideLabel={!editing || !subheadingEditable}
                    variant="subheading"
                    readOnly={!subheadingEditable}
                />
            </Box>
        )
    }

    return (
        <Box flexGrow={1} flexShrink={1} {...styleProps}>
            {heading}
            {subheading}
        </Box>
    )
}
