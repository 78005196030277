import React from 'react'

import { SCHEMA_ERROR_AT_SERVICE } from 'features/admin/data-connector/ImportErrors'

import DATA_PROVIDERS from '../dataProviderConfig'

import { AirtableSchemaSyncServiceError } from './AirtableSchemaSyncServiceError'
import { SchemaSyncError } from './SchemaSyncError'

type AirtableSchemaSyncErrorProps = Omit<
    React.ComponentPropsWithoutRef<typeof SchemaSyncError>,
    'provider'
> & {
    provider?: typeof DATA_PROVIDERS['airtable']
}

export const AirtableSchemaSyncError: React.FC<AirtableSchemaSyncErrorProps> = ({
    provider = DATA_PROVIDERS['airtable'],
    error,
    details,
    ...props
}) => {
    switch (error) {
        case SCHEMA_ERROR_AT_SERVICE:
            return <AirtableSchemaSyncServiceError />
        default:
            return (
                <SchemaSyncError provider={provider} error={error} details={details} {...props} />
            )
    }
}
