import React from 'react'

import styled from '@emotion/styled'
import { variant } from 'styled-system'

import Box from './Box'
import ContainerLabel from './ContainerLabel'

const StyledBox = styled(Box)(
    {},
    variant({
        variants: {
            default: {},
            highlighted: {
                borderTop: '0.375rem solid',
                borderColor: 'brand.300',
            },
        },
    })
)

type ContainerProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'onChange'> & {
    title?: string
    titleIsEditable?: boolean
    buttons?: React.ReactNode
    search?: React.ReactNode
    onChange?: (value: string) => void
    borderStyle?: 'shadow' | 'solid'
    bg?: string
    spacing?: string | number
}

const Container: React.FC<ContainerProps> = ({
    title,
    titleIsEditable,
    bg = 'container.bgColor',
    children,
    buttons,
    search,
    onChange,
    borderStyle = 'shadow',
    ...props
}) => {
    const showTitle = () => {
        if (!title && !buttons) return null
        return (
            <ContainerLabel
                isEditable={titleIsEditable}
                value={title}
                buttons={buttons}
                search={search}
                onChange={onChange}
            />
        )
    }

    return (
        <StyledBox
            as="section"
            mb={4}
            boxShadow={borderStyle === 'shadow' ? 'sm' : undefined}
            borderStyle={borderStyle === 'solid' ? 'solid' : 'none'}
            borderColor={borderStyle === 'solid' ? 'userInterface.neutral.300' : 'transparent'}
            borderWidth="1px"
            rounded="lg"
            bg={bg}
            maxWidth="100%"
            pl={0}
            pr={0}
            {...props}
        >
            {showTitle()}
            {children}
        </StyledBox>
    )
}

export default React.memo(Container)
