import React, { FC } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'

import { AggregationEditor } from '../common/AggregationEditor'

import { DataPanel } from './panels/DataPanel'
import { DisplayPanel } from './panels/DisplayPanel'
import { FiltersPanel } from './panels/FiltersPanel'

type ChartEditorProps = {
    chart: ChartBlockAttributes
    onChange: (
        chart: Pick<ChartBlockAttributes, 'objectId'> & Partial<ChartBlockAttributes>
    ) => void
    currentObject: ObjectDto
    context: ViewLayoutContext
}

export const ChartEditor: FC<ChartEditorProps> = ({ chart, onChange, currentObject, context }) => {
    if (!chart) {
        return null
    }

    return (
        <AggregationEditor
            aggregationWidget={chart}
            dataPanel={
                <DataPanel
                    chart={chart}
                    onChange={onChange}
                    currentObject={currentObject}
                    context={context}
                />
            }
            displayPanel={<DisplayPanel chart={chart} onChange={onChange} />}
            filtersPanel={<FiltersPanel chart={chart} onChange={onChange} />}
        />
    )
}
