import React, { useState } from 'react'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type SolidDeleteProps = {
    color?: string
    size?: number
    hoverColor?: string
}

const SolidDelete: React.VFC<SolidDeleteProps> = ({
    color = colors.userInterface.neutral[700],
    hoverColor = colors.userInterface.neutral[1000],
    size = 16,
}) => {
    const [currentColor, setCurrentColor] = useState<string>(color)

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={(size / 16) * 17}
            viewBox="0 0 16 17"
            fill="none"
            onMouseEnter={() => {
                setCurrentColor(hoverColor)
            }}
            onMouseLeave={() => {
                setCurrentColor(color)
            }}
        >
            <g clipPath="url(#tlkq1fm14a)">
                <path
                    d="M2.889 15.16c0 .977.8 1.777 1.778 1.777h7.11c.979 0 1.779-.8 1.779-1.777V4.493H2.889V15.16zM14.444 1.826h-3.11l-.89-.889H6l-.889.89H2v1.777h12.444V1.826z"
                    fill={currentColor}
                />
            </g>
            <defs>
                <clipPath id="tlkq1fm14a">
                    <path fill="#fff" transform="translate(0 .938)" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SolidDelete
