/* Code Quality: Not audited */

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import Color from 'color'
import PropTypes from 'prop-types'

import { withStack } from 'data/wrappers/WithStacks'
import { isExternal } from 'utils/utils'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import Icon from './Icon'
import ScreenReaderText from './ScreenReaderText'

const StackColorButton = (props) => {
    // This button is coloured basedon the stack's theme.
    // It also looks better than the normal button

    // Work out how the button should be coloured
    const brandColor =
        (props.color !== 'stacker' &&
            props.stackOptions.theme &&
            props.stackOptions.theme.brandColor) ||
        props.theme.brandColor
    let buttonColor = props.color
    let borderColor = props.borderColor || props.color

    if (props.color === 'success') {
        buttonColor = props.theme.successColor
        borderColor = props.theme.successColor
    } else if (props.color === 'danger') {
        buttonColor = props.theme.dangerColor
        borderColor = props.theme.dangerColor
    } else if (props.color === 'secondary' || props.secondary) {
        buttonColor = props.theme.buttonFontColor
        borderColor = props.theme.buttonBorderColor
    } else {
        buttonColor = brandColor
        borderColor = brandColor
    }

    const darkenColor = (color) => {
        try {
            return Color(color).darken(0.1).string()
        } catch (e) {
            return color
        }
    }

    // Depending on whether we're primary or not, we'll colour differently
    let fontColor
    let backgroundColor
    let hoverColor
    let hoverTextColor
    let borderHoverColor

    if (props.primary) {
        fontColor = 'white'
        hoverTextColor = 'white'
        backgroundColor = buttonColor
        hoverColor = darkenColor(buttonColor)
        borderHoverColor = darkenColor(borderColor)
    } else {
        fontColor = buttonColor
        backgroundColor = 'white'
        hoverColor = '#FAFAFA'
        hoverTextColor = darkenColor(buttonColor)
        borderHoverColor = darkenColor(borderColor)
    }

    if (props.color === 'clear') {
        backgroundColor = 'rgba(0,0,0,0)'
        borderColor = 'rgba(0,0,0,0)'
        hoverTextColor = buttonColor
        borderHoverColor = backgroundColor
    }

    const newProps = { ...props }
    //allows us to have an anchor, internal link or button styled as a button
    const getElementType = () => {
        if (props.isAnchor) {
            if (isExternal(props.href)) {
                return 'a'
            }
            newProps.to = props.href
            return Link
        }
        return 'button'
    }

    const Button = styled(getElementType())`
        // Set up some functional stuff
        cursor: ${props.isDisabled ? 'unset' : 'pointer'};
        user-select: none;
        tab-index: 0;

        // What the text looks like
        font-family: ${(props) => props.theme.buttonFontFamily || props.theme.fontFamily};
        font-size: ${(props) => (props.style && props.style.fontSize) || '13px'};
        color: ${fontColor};
        line-height: 2.7em;
        font-weight: bold;

        // Layout stuff
        text-align: center;
        padding: 0px 1.1em;
        background-color: ${backgroundColor};

        // Borders
        border-radius: 0.2875em;
        border-style: solid;
        border-width: 1px;
        border-color: ${borderColor};

        // Let us disable it
        ${props.isDisabled &&
        `
            opacity: 0.5;`}

        // It can be round
            ${props.round &&
        `
        height: 30px;
        width: 30px;
        padding: 6px 9px;
        font-size: 17px;
        `}

        &:active {
            background-color: ${hoverColor};
        }

        &:focus {
            outline: none;
        }

        ${props.noRadius &&
        `
            border-radius: 0px;
            `};

        &:hover {
            background-color: ${hoverColor};
            border-color: ${borderHoverColor};
            color: ${hoverTextColor};
        }
    `
    let label = props.children || ''

    if (props.icon) {
        label = (
            <>
                <Icon margin="none" icon={props.icon} weight={props.iconWeight} />
                {label && <span style={{ width: 8, display: 'inline-block' }} />}
                {label}
            </>
        )
    }

    return (
        <Button
            role="button"
            disabled={props.isDisabled}
            tabIndex={0}
            {...newProps}
            onClick={!props.isDisabled && props.onClick}
        >
            {label}
            {props.screenReaderText && (
                <ScreenReaderText>{props.screenReaderText}</ScreenReaderText>
            )}
        </Button>
    )
}
StackColorButton.displayName = 'Button'

StackColorButton.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['primary', 'success', 'brand', 'danger', 'clear']),
}

export default withStack(withTheme(StackColorButton))
