/* Code Quality: Not audited */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { boolean, text } from 'features/pages/blocks/settings/attributes/items/primitives'
import { Text, UpdatingContentEditable } from 'legacy/v1/ui'

export default class StringBlockType_v2 extends Component {
    state = { edits: { edit: null, callback: null } }

    render() {
        const UnpaddedText = (props) => <Text margin="none" padding="none" {...props} />
        const { attrs, computedStyle, updateAttributes, selected, evaluateFormulas } = this.props

        const linkWrapper = (children) => {
            const link = evaluateFormulas(attrs.link)
            if ((link.indexOf('//') >= 0 && link.indexOf('//') < 8) || link.startsWith('mailto:')) {
                //Absolute link
                return (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={link} target={attrs.new_tab ? '_blank' : null}>
                        {children}
                    </a>
                )
            } else {
                //Relative link
                return (
                    <Link target={attrs.new_tab ? '_blank' : null} to={link}>
                        {children}
                    </Link>
                )
            }
        }
        const wrapLink = attrs.link && !this.props.context.editor.isEditing ? linkWrapper : (a) => a
        const style = computedStyle
        if (selected) {
            style.textTransform = 'none'
        }
        return wrapLink(
            <UpdatingContentEditable
                tagName={UnpaddedText}
                style={style}
                content={
                    selected
                        ? this.state.edit || attrs.text
                        : evaluateFormulas(this.state.edit || attrs.text, {
                              shouldTranslate: !attrs.dontTranslate,
                          })
                }
                editable={selected}
                multiLine={true}
                styled
                onChange={(_, value) => this.saveEditsUntilUnselected(value, updateAttributes)}
                onBlur={this.onBlur}
            />
        )
    }

    onBlur = () => {
        if (this.state.edits.edit) {
            this.state.edits.callback('text', this.state.edits.edit)
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.edits = { edit: null, callback: null }
        }
    }

    UNSAFE_componentWillUpdate() {
        this.onBlur()
    }

    saveEditsUntilUnselected(edit, callback) {
        // Mutate directly so we don't update
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.edits.edit = edit
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.edits.callback = callback
    }
}

StringBlockType_v2.attributes = [
    text({ field: 'text', label: 'Text' }),
    text({ field: 'link', label: 'Link to page' }),
    boolean({ field: 'new_tab', label: 'Open link in new tab?' }),
]

if (localStorage.getItem('enableTranslation') === 'on')
    StringBlockType_v2.attributes.push(
        boolean({ field: 'dontTranslate', label: 'Exclude from translation' })
    )
