// @ts-strict-ignore
import formatDateForCalendarData, { CalendarDate } from './formatDateForCalendarData'

export type CalData = {
    _primary: string
    link: (row: any) => void
    mayUpdate: boolean
    mayDelete: boolean
    [x: string]: any
}

export type CalFormattedData = {
    id: string
    sid: string
    subject: string
    start: Date
    end: Date
    isAllDay: boolean
    link: (data) => void
    mayUpdate: boolean
    mayDelete: boolean
}

// Translates records into calendar events
export default function getCalendarData(
    stackerData: CalData[],
    startDate: string,
    endDate?: string | undefined,
    title: string = '_primary'
): CalFormattedData[] {
    let data: CalFormattedData[] = []

    // Skip if no start date field is selected
    if (!startDate) {
        return []
    }

    stackerData.forEach(function (item) {
        let startCD: CalendarDate = formatDateForCalendarData(item[startDate])
        let endCD: CalendarDate = formatDateForCalendarData(
            endDate ? item[endDate] : undefined,
            true
        )

        const { _sid: id, link, mayUpdate, mayDelete } = item
        const subject = item[title]
        let start
        let end
        let isAllDay = false

        // Skip 1: Start is not provided
        if (startCD.empty) {
            return
        }

        // Skip 2: Start is greater than end
        if (!startCD.empty && !endCD.empty && startCD.value > endCD.value) {
            return
        }

        if (endCD.empty) {
            // Case 1: Only start DATE is provided
            // Result: All day event
            if (!startCD.isDateTime) {
                start = startCD.value
                end = startCD.value
                isAllDay = true
            }
            // Case 2: Only start DATETIME is provided
            // Result: One hour event
            else {
                start = startCD.value
                end = new Date(start)
                end.setMinutes(start.getMinutes() + 60)
            }
        } else {
            // Case 3: Start and end both are DATETIME
            // Result: Normal event
            start = startCD.value
            end = endCD.value

            // We do not need times for a date only field, so set events as all day
            // If either the start or end date do not contain times, then set as all day
            if (!startCD.isDateTime || startCD.isDateTime !== endCD.isDateTime) {
                isAllDay = true
            }
        }

        data.push({
            id,
            sid: id,
            subject: subject,
            start,
            end,
            isAllDay,
            link,
            mayUpdate,
            mayDelete,
        })
    })

    return data
}
