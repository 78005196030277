import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getCachedObject } from 'data/hooks/objects/objectOperations'
import { withObject } from 'data/wrappers/WithObject'
import CheckboxAttribute from 'features/pages/blocks/settings/attributes/items/form/Checkbox'

import { Text } from 'v2/ui'

const RelatedListAddButtonConfigurator = ({ object, listType, ...props }) => {
    const field = object.fields.find((f) => f._sid === props.fieldId)

    // Because we're inside a withObject() already, we know that the redux
    // cache has all the objects/fields loaded, so we can just useSelector()
    // to pull out the symmetric link field
    const symmetricField = useMemo(() => {
        if (props.isExternalField) return field
        if (!field) return null
        const targetObject = getCachedObject(field?.options.lookup_target)

        return (
            targetObject &&
            targetObject.fields.find((f) => {
                const airtable_match =
                    f.connection_options.airtable_column_id &&
                    f.connection_options.airtable_column_id ===
                        field.connection_options.airtable_symmetric_column_id
                const simpleconn_match =
                    f.connection_options.simpleconn_field &&
                    f.connection_options.simpleconn_field ===
                        field.connection_options.simpleconn_symmetric_column_id
                return airtable_match || simpleconn_match
            })
        )
    }, [field, props.isExternalField])

    if (listType != 'all' && (!field || !symmetricField)) return null

    // Check if the field is eligible for an "Add new" button.
    const fieldRelType = get(field, 'connection_options.airtable_relation_type')
    const allowAddNewButton =
        listType == 'all' ||
        (!get(symmetricField, 'connection_options.is_disabled') &&
            (props.isExternalField || ['many-many', 'many-one'].includes(fieldRelType)))
    return (
        <>
            <CheckboxAttribute {...props} disabled={!allowAddNewButton} />
            {!allowAddNewButton && (
                <WarningMessage>New records cannot be created via this field.</WarningMessage>
            )}
        </>
    )
}

RelatedListAddButtonConfigurator.propTypes = {
    objectId: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
}

export default withObject(RelatedListAddButtonConfigurator)

const WarningMessage = styled(Text)`
    opacity: 80%;
    font-size: 0.8rem;
    margin: -0.8rem 1rem 0 1rem;
`
