import React, { useCallback } from 'react'

import { Modal as ModalComponent } from 'v2/ui'
import { Modal, useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

import { CreateEmptyTableModalContent } from './CreateEmptyTableModalContent'

const MODAL_KEY = 'CreateEmptyTable'

export type CreateEmptyTableModalData = {
    onConfirm?: (modal: Modal<CreateEmptyTableModalData>) => Promise<void>
}

export const useCreateEmptyTableModal = (data: CreateEmptyTableModalData = {}) => {
    const { show } = useModalDeclaration<CreateEmptyTableModalData>(
        MODAL_KEY,
        CreateEmptyTableModal
    )

    return useCallback(() => show(data), [show, data])
}

const CreateEmptyTableModal: React.FC<{}> = () => {
    const modal = useModalToggle<CreateEmptyTableModalData>(MODAL_KEY)
    const { isOpen, toggle, data } = modal

    const onConfirm = async () => {
        data.onConfirm?.(modal)
        toggle()
    }

    const onCancel = () => {
        toggle()
    }

    return (
        <ModalComponent
            isOpen={isOpen}
            onClose={onCancel}
            body={
                <CreateEmptyTableModalContent
                    onConfirm={onConfirm}
                    isOpen={isOpen}
                    onClose={onCancel}
                />
            }
            showCloseButton={false}
            headerVariant="smallCentered"
            bodyStyle={{ p: 0 }}
            size="28rem"
        />
    )
}
