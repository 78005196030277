import * as React from 'react'
import { useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useObjects } from 'data/hooks/objects'
import { useStackPages } from 'data/hooks/pages'
import { useUpdateStack } from 'data/hooks/stacks'
import getAutocompleteClasses from 'features/admin/settings/appearance/getAutocompleteClasses'
import IconPickerForm from 'features/workspace/forms/IconPicker'
import { UploadIcon } from 'features/workspace/WorkspaceModalAppearanceSettings'

import { Banner, Box, Button, Text } from 'v2/ui'
import {
    FEATURES,
    isFeatureLocked,
    ProtectedFeature,
    ProtectedFeatureIndicator,
} from 'v2/ui/utils/ProtectedFeature'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import Form from 'ui/forms/Form'
import { FormField } from 'ui/forms/FormField'

import CodeEditor from '../admin/settings/appearance/CodeEditor'
import { useAppSettings } from '../workspace/AdminSideTray/hooks/useAppSettings'
import ColorPickerForm, { defaultColor } from '../workspace/forms/ColorPicker'
import {
    CollapsableSection,
    Divider,
    FormFrame,
    SettingLabel,
} from '../workspace/WorkspaceSettingsModalUi'

import MigrateCssModal from './CustomerAccess/MigrateCssModal'
import { getUserFieldOptions } from './Permissions/PermissionRuleForm/getUserFieldOptions'
import { UserFieldsDropdown } from './Permissions/PermissionRuleForm/UserFieldsDropdown'

const LOGO_FIELD_TYPES = ['string', 'url', 'multi_file']

// @TODO: use margin/padding instead of a component
function Spacer({ style = {} }) {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}

export default function AppSettingsModalAppearanceSettings({ formRef, children }) {
    const { selectedStack, workspaceAccount } = useAppContext()
    const { mutateAsync: updateStack } = useUpdateStack()
    const [isGlobalCustomCSSUpgradeModalVisible, setIsGlobalCustomCSSUpgradeModalVisible] =
        useState(false)
    const [showUpgrade, setShowUpgrade] = useState(false)

    const isCustomerAccessCustomCssLocked = isFeatureLocked(FEATURES.customCss, selectedStack)

    const [isMigrateModalOpen, setIsMigrateModalOpen] = useState(false)
    const handleMigrateCss = () => setIsMigrateModalOpen(true)

    const { open: openAppSettings } = useAppSettings()

    const handleGoToCustomerAccessAppearance = () => {
        openAppSettings({ page: { name: 'customer_access', meta: { section: 'appearance' } } })
    }

    const { data: allObjects } = useObjects()
    const objects = allObjects.filter((object) => object.stack_id === selectedStack._sid)
    const userObjectId = selectedStack?.options?.data_mapping?.user_object
    const userObject = objects.find((object) => object._sid === userObjectId)
    const clientLogo = selectedStack.options?.data_mapping?.logo_field
    const clientLogosLocked = isFeatureLocked(FEATURES.clientLogos, selectedStack)
    const { pages } = useStackPages()

    return (
        <>
            <Form
                onSubmit={(data) => {
                    const { custom_css, logo_field, ...theme } = data
                    const data_mapping = selectedStack.options?.data_mapping
                    if (logo_field) {
                        data_mapping['logo_field'] = logo_field.split(',')
                    } else {
                        delete data_mapping['logo_field']
                    }
                    return updateStack({
                        id: selectedStack._sid,
                        patch: {
                            options: {
                                ...selectedStack.options,
                                data_mapping,
                                custom_css,
                                theme,
                            },
                        },
                    })
                }}
                options={{
                    defaultValues: {
                        brandColor: defaultColor,
                        custom_css: selectedStack.options?.custom_css,
                        logo_field: clientLogo ? clientLogo.join(',') : null,
                        ...selectedStack.options?.theme,
                    },
                }}
                style={{ flex: 1 }}
                resetOnSuccess
            >
                <FormFrame title="Appearance" formRef={formRef}>
                    <Flex>
                        <ColorPickerForm
                            name="brandColor"
                            required
                            label="Color"
                            showCustom={selectedStack.options?.allow_custom_brand_color}
                        />
                        <Spacer />
                        <IconPickerForm name="icon" label="App Icon" />
                    </Flex>
                    <UploadIcon disabled={false} isSmall={true} />

                    <>
                        <Flex style={{ alignItems: 'center', ...V4DesignSystem.h4 }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Client Logos
                                    <ProtectedFeatureIndicator
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setShowUpgrade(true)
                                        }}
                                        feature={FEATURES.clientLogos}
                                        ml={3}
                                        display="inline"
                                    />
                                </div>
                                <Text size="sm" mt={2} mr={2} maxWidth="400px">
                                    Display a different logo for each user by selecting a field
                                    which contains an image attachment or a URL.
                                </Text>
                                {clientLogosLocked && (
                                    <Text size="sm" mt={2}>
                                        Available with an Enterprise plan.
                                    </Text>
                                )}
                            </div>

                            <ProtectedFeature
                                feature={FEATURES.clientLogos}
                                showModal={showUpgrade}
                                onModalClosed={() => setShowUpgrade(false)}
                            />

                            {!clientLogosLocked && (
                                <FormField
                                    as={UserFieldsDropdown}
                                    name="logo_field"
                                    controlled
                                    style={{ width: '250px' }}
                                    useSids={true}
                                    isClearable={true}
                                    placeholder="Choose a field..."
                                    usePortal={true}
                                    userFieldDropdownOptions={getUserFieldOptions(
                                        [],
                                        userObject,
                                        objects,
                                        LOGO_FIELD_TYPES,
                                        undefined,
                                        undefined,
                                        true
                                    )}
                                />
                            )}
                        </Flex>

                        <Divider />
                    </>

                    {isCustomerAccessCustomCssLocked ||
                    workspaceAccount?.optional_features?.sync_stacker_users ? (
                        <CollapsableSection
                            header={
                                <Flex>
                                    <SettingLabel>Custom CSS</SettingLabel>
                                    <ProtectedFeatureIndicator
                                        mt="3px"
                                        feature={FEATURES.globalCustomCss}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setIsGlobalCustomCSSUpgradeModalVisible(true)
                                        }}
                                    />
                                </Flex>
                            }
                        >
                            {!isFeatureLocked(FEATURES.globalCustomCss, selectedStack) ? (
                                <Box border="1px solid #ccc" mt={1}>
                                    <FormField
                                        as={CodeEditor}
                                        name="custom_css"
                                        formRef={formRef}
                                        language="css"
                                        autocompleters={getAutocompleteClasses(allObjects, pages)}
                                        controlled
                                    />
                                </Box>
                            ) : (
                                <Text variant="appSettingsBody" mt="5px">
                                    Available with a Pro plan or above
                                </Text>
                            )}
                        </CollapsableSection>
                    ) : (
                        <Banner icon="info">
                            <Flex style={{ alignItems: 'center' }}>
                                <Text>
                                    By activating Custom CSS, your current{' '}
                                    <Button
                                        variant="clear"
                                        color="clear"
                                        size="sm"
                                        style={{
                                            padding: 0,
                                            textDecoration: 'underline',
                                            lineHeight: 1,
                                            color: '#2980b9',
                                        }}
                                        onClick={handleGoToCustomerAccessAppearance}
                                    >
                                        Customer Access CSS
                                    </Button>{' '}
                                    will be copied and migrated here
                                </Text>
                                <Button
                                    variant="adminSecondary"
                                    buttonSize="smDense"
                                    style={{ padding: '5px', marginLeft: '5px' }}
                                    onClick={handleMigrateCss}
                                >
                                    Migrate CSS
                                </Button>
                            </Flex>
                        </Banner>
                    )}
                </FormFrame>
                <ProtectedFeature
                    feature={FEATURES.globalCustomCss}
                    showModal={isGlobalCustomCSSUpgradeModalVisible}
                    onModalClosed={() => setIsGlobalCustomCSSUpgradeModalVisible(false)}
                />
                {children}
            </Form>
            <MigrateCssModal
                isOpen={isMigrateModalOpen}
                onClose={(e) => {
                    e.preventDefault()
                    setIsMigrateModalOpen(false)
                }}
            />
        </>
    )
}
