export const getShortFieldName = (field) => {
    const fieldSplit = field.api_name.split('__', 2)
    let shortName = fieldSplit.length > 1 ? fieldSplit[1] : fieldSplit[0]
    // weird scenario where the table name has special characters so ends
    // up as just the prefix
    if (!shortName) {
        shortName = field.api_name
    }
    return shortName
}
