// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

import { Connection, useWorkspaceAuthMetadata } from 'data/hooks/workspaceAuthMetadata'
import { Icon8 } from 'legacy/v1/ui'

import { Box, Button, LoadingScreen } from 'v2/ui'

import { useAuth0Redirect } from '../useAuth0Redirect'

export default function Auth0LoginScreen() {
    const { data: auth0_settings, isLoading } = useWorkspaceAuthMetadata()
    const connections: Connection[] = auth0_settings?.connections || []
    return (
        <LoadingScreen isLoading={isLoading}>
            {connections.map((connection) => (
                <LoginButton key={connection.name} connection={connection} />
            ))}
        </LoadingScreen>
    )
}

function getProviderDisplayName(connection: Connection) {
    if (connection.display_name) {
        return connection.display_name
    }

    switch (connection.name) {
        case 'google-oauth2':
            return 'Google'
        default:
            return null
    }
}

function getProviderIcon(connection: Connection) {
    if (connection.icon_url) {
        return (
            <img
                src={connection.icon_url}
                width="24"
                height="24"
                alt="Logo"
                style={{ verticalAlign: 'middle' }}
            />
        )
    }
    switch (connection.name) {
        case 'google-oauth2':
            return (
                // @ts-expect-error
                <Icon8 icon="google-logo" size={48} displaySize="24" iconStyle="color" noMargin />
            )
        default:
            return null
    }
}
const StyledButton = styled(Button)`
    width: 100%;
    color: #333;
    background-color: #efefef;
    border: 1px solid #ccc;
    padding: 6px;
    margin-bottom: 12px;
    font-size: 1rem;
    &:hover {
        text-decoration: none;
        background-color: #ccc;
    }
`

const StyledLoginButton = ({ children, icon, ...props }) => (
    <StyledButton {...props}>
        {icon && (
            <Box borderRadius="5px" p="2px">
                {icon}
            </Box>
        )}
        <Box flexGrow={1} mx={4}>
            {children}
        </Box>
    </StyledButton>
)

const LoginButton: React.FC<{ connection: Connection }> = ({ connection }) => {
    const loginWithAuth0 = useAuth0Redirect()
    const providerName = getProviderDisplayName(connection)
    const icon = getProviderIcon(connection)
    const text = providerName ? `Continue with ${providerName}` : 'Log in'

    const handleClick = () => {
        loginWithAuth0({ connection: connection.name })
    }
    return (
        <StyledLoginButton icon={icon} onClick={handleClick}>
            {text}
        </StyledLoginButton>
    )
}
