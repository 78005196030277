/**
 * Converts Airtable markdown into markdown.
 *
 * Two cases to handle:
 * 1. Add dash to Airtable checkbox to convert to GFM. '[ ] Checkbox' -> '- [ ] Checkbox'.
 * 2. Add escape character to GFM checkbox. '- [ ] Checkbox' -> '- \\[ ] Checkbox'.
 */
export function convertAirtableToMd(markdown) {
    if (!markdown) return markdown
    // Case 1: '[ ] test' and '[x] test'.
    const AIRTABLE_WITH_CONTENT = /^\s*\[(x|[ ])\][ ]{1,}/g
    const AIRTABLE_WITHOUT_CONTENT = /^\s*\[(x|[ ])\]$/g
    const AIRTABLE_CHECKED_PATTERN = /^\s*\[x\]/
    const AIRTABLE_UNCHECKED_PATTERN = /^\s*\[ \]/
    // Case 2: '- [ ] ' and '- [x] '.
    const GFM_CHECKBOX_PATTERN = /^\s*- \[(x|[ ])\][ ]{1,}/g
    const GFM_CHECKED_PATTERN = /^\s*- \[x\]/
    const GFM_UNCHECKED_PATTERN = /^\s*- \[ \]/
    // Match indentation in front.
    const IDENTATION_PATTERN = /^\s*/

    const lines = markdown
        ?.split('\n')
        .map((line) => {
            const indentationMatches = line.match(IDENTATION_PATTERN)
            const indentation = indentationMatches.length ? indentationMatches[0] : ''

            // Url encode urls
            // Looks for [text](url) and encodes the url
            line = line.replace(/(\[.*?\]\()([^\)]*?)\)/g, function (string, c1, c2) {
                // Replace escape characters as we are encoding urls so don't need them
                const url = c2.replaceAll('\\', '')
                return `${c1}${encodeURI(url)})`
            })

            // Case 1: Add dash to Airtable checkbox with or without content.
            if (line.match(AIRTABLE_WITH_CONTENT) || line.match(AIRTABLE_WITHOUT_CONTENT)) {
                // Add a whitespace to end of string if checkbox has no content.
                let isWithoutContent = line.match(AIRTABLE_WITHOUT_CONTENT) ? ' ' : ''
                line = line
                    .replace(AIRTABLE_CHECKED_PATTERN, `${indentation}- [x]${isWithoutContent}`)
                    .replace(AIRTABLE_UNCHECKED_PATTERN, `${indentation}- [ ]${isWithoutContent}`)
            }
            // Case 2: Add escape character to GFM checkbox.
            else if (line.match(GFM_CHECKBOX_PATTERN)) {
                line = line
                    .replace(GFM_CHECKED_PATTERN, `${indentation}- \\[x]`)
                    .replace(GFM_UNCHECKED_PATTERN, `${indentation}- \\[ ]`)
            }

            return line
        })
        .join('\n')
    return lines
}

/**
 * Converts markdown into Airtable markdown.
 * It also replaces &nbsp; with whitespace.
 *
 * Two cases to handle:
 * 1. Removes dash in GFM checkbox. '- [ ] Checkbox' -> '[ ] Checkbox'.
 * 2. Add escape character to AT syntax ones because these are not checkbox when built in GFM. '[ ] Checkbox' -> '\[ ] Checkbox'.
 */
export function convertMdToAirtable(markdown) {
    // Case 1: '- [ ]' and '- [x]'.
    const UNORDERED_CHECKBOX = /^\s*- \[(x|[ ])\][ ]{0,}/g
    const UNORDERED_CHECKED = /^\s*- \[x\]/
    const UNORDERED_UNCHECKED = /^\s*- \[ \]/
    // Case 2: '[ ] test' and '[x] test'.
    const AIRTABLE_CHECKBOX_PATTERN = /^\s*\[(x|[ ])\]/g
    const AIRTABLE_CHECKED_PATTERN = /^\s*\[x\]/
    const AIRTABLE_UNCHECKED_PATTERN = /^\s*\[ \]/
    // Match indentation in front.
    const IDENTATION_PATTERN = /^\s*/
    // Detect &nbsp;
    const NBSP_PATTERN = /(\u00a0)/g

    const lines = markdown
        ?.split('\n')
        .map((line) => {
            // Replace &nbsp; added in HTML (replaceSpacesInDelta) for turndown.
            line = line.replace(NBSP_PATTERN, ' ')

            const indentationMatches = line.match(IDENTATION_PATTERN)
            const indentation = indentationMatches.length ? indentationMatches[0] : ''

            // Removes dash in GFM checkbox to convert it into AT syntax.
            if (line.match(UNORDERED_CHECKBOX)) {
                // Get number of spaces in front.
                line = line
                    .replace(UNORDERED_CHECKED, `${indentation}[x]`)
                    .replace(UNORDERED_UNCHECKED, `${indentation}[ ]`)
            }
            // Airtable syntax in GFM is not a checkbox. Thus, add escape character.
            else if (line.match(AIRTABLE_CHECKBOX_PATTERN)) {
                line = line
                    .replace(AIRTABLE_CHECKED_PATTERN, `${indentation}\\[x]`)
                    .replace(AIRTABLE_UNCHECKED_PATTERN, `${indentation}\\[ ]`)
            }

            return line
        })
        .join('\n')
    //airtable stated that they always add trailing \n
    return lines?.length > 0 ? lines + '\n' : lines
}

/**
 * Checks if a string is of the specified type (paragraph, header, etc).
 */
function isOfType(string, type) {
    // Block types
    const UNORDERED_LIST = /^\s*-[ ]{1,}/g
    const ORDERED_LIST = /^\s*(?!0)[0-9]+\.[ ]{1,}/g
    const CHECKBOX_PATTERN = /^\s*- \[(x|[ ])\][ ]{1,}/g
    const BLOCKQUOTE_PATTERN = /^\s*>[ ]{1,}/g
    const HEADER_PATTERN = /^\s*#{1,}[ ]{1,}/g
    const START_CODE_BLOCK = /^(```)/g
    const END_CODE_BLOCK = /(```)$/g

    if (type === 'paragraph') {
        return (
            !string.match(UNORDERED_LIST) &&
            !string.match(ORDERED_LIST) &&
            !string.match(BLOCKQUOTE_PATTERN) &&
            !string.match(HEADER_PATTERN) &&
            !(string.match(START_CODE_BLOCK) && string.match(END_CODE_BLOCK))
        )
    } else if (type === 'blockquote') {
        return Boolean(string.match(BLOCKQUOTE_PATTERN))
    } else if (type === 'unorderedList') {
        return Boolean(string.match(UNORDERED_LIST))
    } else if (type === 'orderedList') {
        return Boolean(string.match(ORDERED_LIST))
    } else if (type === 'checkbox') {
        return Boolean(string.match(CHECKBOX_PATTERN))
    } else if (type === 'codeBlock') {
        return string.match(START_CODE_BLOCK) && string.match(END_CODE_BLOCK)
    } else if (type === 'header') {
        return Boolean(string.match(HEADER_PATTERN))
    }
    return false
}

/**
 * Replace spaces in the start and end with '&nbsp;'
 * as md-to-quill-delta handles space incorrectly
 * and sometimes collapses whitespace.
 */
function replaceStartAndEndSpaceWithNbsp(line) {
    const START_WHITESPACE = /^[ ]{1,}/
    const END_WHITESPACE = /[ ]{1,}$/
    // Regex for the prefix of block types.
    const BLOCKQUOTE_PREFIX = /^\s*>[ ]/
    const CHECKBOX_PREFIX = /^\s*- \[(x|[ ])\][ ]/
    const UNORDERED_LIST_PREFIX = /^\s*-[ ]/
    const ORDERED_LIST_PREFIX = /^\s*(?!0)[0-9]+\.[ ]/
    const HEADER_PREFIX = /^\s*#{1,}[ ]/

    // Line cannot be empty.
    if (line.trim() !== '') {
        let prefix = ''
        let content = line
        if (isOfType(line, 'blockquote')) {
            prefix = line.match(BLOCKQUOTE_PREFIX)[0]
            content = line.replace(BLOCKQUOTE_PREFIX, '')
        } else if (isOfType(line, 'checkbox')) {
            prefix = line.match(CHECKBOX_PREFIX)[0]
            content = line.replace(CHECKBOX_PREFIX, '')
        } else if (isOfType(line, 'unorderedList')) {
            prefix = line.match(UNORDERED_LIST_PREFIX)[0]
            content = line.replace(UNORDERED_LIST_PREFIX, '')
        } else if (isOfType(line, 'orderedList')) {
            prefix = line.match(ORDERED_LIST_PREFIX)[0]
            content = line.replace(ORDERED_LIST_PREFIX, '')
        } else if (isOfType(line, 'header')) {
            prefix = line.match(HEADER_PREFIX)[0]
            content = line.replace(HEADER_PREFIX, '')
        }
        // Default case handles paragraph, header and code-block.
        const startMatches = content.match(START_WHITESPACE)
        const startNbsp = startMatches ? '\u00a0'.repeat(startMatches[0].length) : ''

        return prefix + content.replace(START_WHITESPACE, startNbsp).replace(END_WHITESPACE, '')
    }

    return line
}

/**
 * Append '\n' to certain block types to allow correct grouping of elements in unified.
 * Always replace start and end of lines with '&nbsp;'.
 *
 * Case 1: If line is a block-quote, append '\n'.
 * Case 2: If line is an unordered or ordered list AND the next line is not a list item, append '\n'.
 * Case 3 (Special case): If line is a paragraph and next line is an ordered list, append '\n'.
 * Case 4: If line.trim() is empty, add &nbsp; as unified will ignore empty lines.
 */
export function addNewLineForBlockTypes(markdown) {
    // If markdown is an empty string, we don't need to process it.
    if (!markdown || markdown === '') {
        return ''
    }
    const markdownSplit = markdown.split('\n')
    const lastIndex = markdownSplit.length - 1

    const lines = markdownSplit
        .map((line, index) => {
            // Replace start and end of paragraph with &nbsp;
            line = replaceStartAndEndSpaceWithNbsp(line)

            if (index !== lastIndex) {
                let nextLine = markdownSplit[index + 1]
                // Case 1: Blockquotes.
                if (isOfType(line, 'blockquote')) {
                    return line + '\n'
                }
                // Case 2: Unordered and ordered list.
                else if (isOfType(line, 'unorderedList') || isOfType(line, 'orderedList')) {
                    // If next line is NOT a list item, add a new line.
                    if (
                        !isOfType(nextLine, 'unorderedList') &&
                        !isOfType(nextLine, 'orderedList')
                    ) {
                        return line + '\n'
                    }
                }
                // Case 3 (Special case): Current line is a paragraph and followed by ordered list.
                else if (isOfType(line, 'paragraph') && isOfType(nextLine, 'orderedList')) {
                    // If paragraph is empty, add '&nbsp;\n'.
                    if (line.trim() === '') {
                        return '&nbsp;\n'
                    }
                    return line + '\n'
                }
                // Case 4: Unified ignores empty lines and lines with only whitespaces.
                // Checked trimmed line then add &nbsp;. ('\n'-> '&nbsp;\n')
                if (line.trim() === '') {
                    return '&nbsp;'
                }
            }
            return line
        })
        .join('\n')

    return lines
}

/**
 * After passing thru md-to-quill-delta, we will remove the added '&nbsp;' which was added in addNewLineForBlockTypes.
 * Also replace '&nbsp;' with space which was added in addNewLineForBlockTypes.
 */
export function removeAddedNbsp(ops) {
    // [ { insert: 'Test\n \n \n \n \nTest2\n' } ]
    // We will need \u00a0 as &nbsp; have have the corresponding character with code 160.
    const NEW_LINE_SPACE_PATTERN = /(\r\n|\r|\n)(\u00a0)/g
    const SPACE_PATTERN = /(\r\n|\r|\n)(\u00a0)(\r\n|\r|\n)/

    // Detect &nbsp;
    const NBSP_PATTERN = /(\u00a0)/g

    // Replace '\n&nbsp;\n' with '\n\n' and &nbsp; with space.
    let newOps = ops.map((line) => {
        if (typeof line.insert !== 'string') return line
        const matches = line.insert.match(NEW_LINE_SPACE_PATTERN)
        if (matches?.length) {
            // Replace all occurences of '\n&nbsp;\n' with '\n\n'.
            matches.forEach((_) => {
                line.insert = line.insert.replace(SPACE_PATTERN, '\n\n')
            })
        }
        // Replace &nbsp; added in addNewLineForBlockTypes with space.
        line.insert = line.insert.replace(NBSP_PATTERN, ' ')
        return line
    })
    return newOps
}

export function replaceSpacesWithNbsp(html) {
    const SPACE_PATTERN = /[ ]/g
    return html.replace(SPACE_PATTERN, '\u00a0')
}

/**
 * This function does 2 things:
 * 1. Replace spaces in delta before passing to quill-delta-to-html.
 *    This is to prevent spaces from being collapsed in turndown.
 * 2. This method also adds a space before a single new line to fix a special case
 *    in quill-delta-to-html where block type, new line, block type produces 2 new line.
 */
export function processDeltaBeforeConverter(ops) {
    const SINGLE_NEW_LINE = /^\n(?!\n)./
    return ops.map((item) => {
        let content = item.insert
        if (typeof content !== 'string') return { ...item, insert: content }
        // Add a whitespace to content with a single new line and has contents. ('\nContent')
        if (content.match(SINGLE_NEW_LINE)) {
            content = content.replace(/^\n/, ' \n')
        }
        content = replaceSpacesWithNbsp(content)
        return { ...item, insert: content }
    })
}
