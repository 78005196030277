import React from 'react'

import { ModalOverlay, Spinner } from '@chakra-ui/react'

const LoadingOverlay = ({ isOpen }) => {
    if (!isOpen) return null

    return (
        <ModalOverlay display="flex" alignItems="center" justifyContent="center">
            <Spinner size="xl" />
        </ModalOverlay>
    )
}

export default LoadingOverlay
