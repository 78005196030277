import React, { forwardRef } from 'react'

import { Collapse as ChakraCollapse } from '@chakra-ui/react'

import Box from './Box'
type Props = {
    isOpen: boolean
    isOpenDelay?: number
    duration?: number
    [x: string]: any
}

const Collapse: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
    ({ isOpen, isOpenDelay = 0, duration = undefined, ...props }, ref) => {
        return (
            <ChakraCollapse
                ref={ref}
                in={isOpen}
                transition={{
                    enter: { duration, delay: isOpenDelay },
                    exit: { duration },
                }}
                style={{
                    overflow: isOpen ? 'initial' : 'hidden',
                }}
            >
                <Box {...props} />
            </ChakraCollapse>
        )
    }
)

export default React.memo(Collapse)
