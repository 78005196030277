import React, { useCallback } from 'react'

import { useEditModeListContext } from 'features/admin/edit-mode/ListView/EditModeListContextProvider'

import { EditableBlockWrapper, EditableBlockWrapperProps } from '../Common/EditableBlockWrapper'

/**
 * Provides a wrapper for a layout block which can be edited.
 * Handles showing the dashed edit border, can be extended to show
 * edit toolbar, etc.
 *
 **/

type ListRecordWrapperProps = Omit<EditableBlockWrapperProps, 'children'> & {
    recordId: string
    children:
        | React.ReactNode
        | ((args: { isActive?: boolean; lastHoveredRecordId?: string }) => React.ReactNode)
}
export const ListRecordWrapper: React.FC<ListRecordWrapperProps> = ({ children }) => {
    return <>{typeof children === 'function' ? children({}) : children}</>
}

export const BlockContainer: React.FC<ListRecordWrapperProps> = ({
    recordId,
    highlight,
    children,
    ...props
}) => {
    const { lastHoveredRecordId, setLastHoveredRecordId, activeEditorFieldId } =
        useEditModeListContext()

    const handleHoverStart = useCallback(
        () => setLastHoveredRecordId(recordId),
        [recordId, setLastHoveredRecordId]
    )

    return (
        <EditableBlockWrapper
            {...props}
            highlight={highlight || !!activeEditorFieldId}
            onHoverStart={handleHoverStart}
        >
            {({ isHovered }) =>
                typeof children === 'function'
                    ? children({
                          isActive: isHovered || lastHoveredRecordId === recordId,
                          lastHoveredRecordId,
                      })
                    : children
            }
        </EditableBlockWrapper>
    )
}
