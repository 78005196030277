import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import store from 'app/store'
import { useFetchAuthenticatedUser } from 'data/hooks/users/main'
import { buildUrl } from 'data/utils/utils'
import { useSessionContext } from 'features/supertokens/supertokens'
import { getRedirectParam } from 'utils/utils'

import { LoadingSplash } from 'v2/ui'

import { Authentication } from './Authentication'

export const AuthenticationWrapper: React.FC = ({ children }) => {
    const state = store.getState()
    const studioUser = state?.user?.studioUser
    const {
        isAuthenticated: isAuth0Authenticated,
        getAccessTokenSilently,
        isLoading: auth0IsLoading,
    } = useAuth0()
    const [isAuth0Initialized, setIsAuth0Initialized] = useState(false)

    const supertokensContext = useSessionContext()
    const supertokensUserId = 'userId' in supertokensContext ? supertokensContext.userId : undefined
    Authentication.isSupertokensAuthenticated = !!supertokensUserId

    const {
        isLoading: isUserLoading,
        isError: loadingUserFailed,
        error,
    } = useFetchAuthenticatedUser(
        (isAuth0Authenticated && isAuth0Initialized) || !!supertokensUserId
    )

    Authentication.getAccessTokenSilently = getAccessTokenSilently

    useEffect(() => {
        if (isAuth0Authenticated) {
            Authentication.isAuth0Authenticated = isAuth0Authenticated
            setIsAuth0Initialized(true)
        }
    }, [isAuth0Authenticated, getAccessTokenSilently])

    useEffect(() => {
        // When the user fetch fails with 409, it means the user has signed in with a different provider
        // but the same email address. This results in two Auth0 profiles. The back end has caught this and
        // merged the two profiles in Auth0. We need to send the user to the login flow again, with prompt: none
        // so we'll get the new access token from the merged user.
        // Remove the ts-ignore when the user hooks have been converted to TS
        // @ts-ignore
        if (error && error.code === 409) {
            const redirectTo = getRedirectParam()
            window.location.assign(
                buildUrl(`${window.location.origin}/login`, { r: redirectTo, prompt: 'none' })
            )
        }
    }, [error, getAccessTokenSilently])

    if (loadingUserFailed && !isUserLoading) {
        return <HardFailure />
    }
    // If auth0 is still loading, or we have an auth0 session but our shared Authentication object
    // hasn't been initialized yet via the useEffect above,
    // or we are still waiting for our initial load of the Stacker user record.
    if (
        auth0IsLoading ||
        (isAuth0Authenticated && !isAuth0Initialized) ||
        (isUserLoading && !studioUser)
    ) {
        return <LoadingSplash />
    }

    return <>{children}</>
}

const HardFailure = () => {
    const auth0 = useAuth0()

    const handleClick = () => {
        auth0.logout({ returnTo: `${window.location.origin}/logout` })
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
            }}
        >
            <div style={{ marginBottom: '24px' }}>
                Your session is no longer valid. Please log out and back in again.
            </div>
            <button onClick={handleClick}>Logout</button>
        </div>
    )
}
