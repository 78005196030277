import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Frame from 'features/core/Frame'

import { useAppContext } from '../../app/AppContext'
import { getUrl, Urls, WorkspaceUrls } from '../../app/UrlService'
import { Template } from '../../data/hooks/templates'

import TemplateLibraryModal from './TemplateLibraryModal'

const TemplateLibraryPage = () => {
    const history = useHistory()
    const { workspaceAccount, lastVisitedStack } = useAppContext()

    const { templateId } = useParams<{ templateId?: string }>()
    const onClose = () => {
        history.push(getUrl(Urls.Home, lastVisitedStack))
    }
    const onTemplateSelectionChange = (template: Template | null) => {
        if (!template) {
            history.push(getUrl(WorkspaceUrls.TemplateLibrary))
        } else {
            history.push(getUrl(`${WorkspaceUrls.TemplateLibrary}/${template.auto_id}`))
        }
    }

    const onInstallComplete = (installedStack: StackDto) => {
        history.push(getUrl(Urls.Home, installedStack))
    }

    return (
        <Frame>
            {workspaceAccount && (
                <TemplateLibraryModal
                    showCloseButton={true}
                    accountId={workspaceAccount._sid}
                    templateId={templateId}
                    onInstallComplete={onInstallComplete}
                    onTemplateSelectionChange={onTemplateSelectionChange}
                    onClose={onClose}
                />
            )}
        </Frame>
    )
}
export default TemplateLibraryPage
