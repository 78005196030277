import { useQuery } from 'data/hooks/_helpers'

const ENDPOINT = 'account/auth-providers'

const getQueryKey = (accountSid?: string) => {
    return ['providers', accountSid]
}

export const useAuthProviders = (accountSid?: string) => {
    return useQuery<AuthProvider[]>(
        getQueryKey(accountSid),
        `${ENDPOINT}?account_sid=${accountSid}`,
        { enabled: !!accountSid },
        {
            bypassPreviewAndImpersonation: true,
            bypassMatchingStackCheck: true,
            bypassQueryDeps: true,
        }
    )
}
