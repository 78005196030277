import { useMemo } from 'react'

import { getPreviousBreadcrumbUrl } from 'v2/views/utils/breadcrumbsUtils'
import { useBreadcrumbItems } from 'v2/views/utils/useBreadcrumbItems'

import { getUrl } from 'app/UrlService'

export function useCreateViewParentUrl(feature: FeatureDto) {
    const breadcrumbItems = useBreadcrumbItems()

    return useMemo(() => {
        const previousBreadcrumbUrl = getPreviousBreadcrumbUrl(breadcrumbItems)
        if (previousBreadcrumbUrl.length > 0) {
            return previousBreadcrumbUrl
        }

        return getUrl(feature.url)
    }, [feature.url, breadcrumbItems])
}
