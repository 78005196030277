import { useQuery } from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useWorkspaceAuth'
const ENDPOINT = 'workspace-auth-metadata/'

export type Connection = {
    name: string
    display_name: string
    icon_url: string
}

export function useWorkspaceAuthMetadata() {
    return useQuery<{ connections: Connection[] }>(LIST_NAME, ENDPOINT, {})
}
