import React from 'react'

import { Flex } from 'v2/ui'

export const LinkPreviewCard = ({ children }) => {
    return (
        <>
            {React.Children.map(children, (child, index) => {
                const isFirst = index === 0
                return child ? <Flex mt={isFirst ? 0 : 3}>{child}</Flex> : null
            })}
        </>
    )
}
