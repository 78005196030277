// @ts-strict-ignore
import moment from 'moment'

import { isSubscriptionCancelled } from 'features/workspace/stackerUserUtils'

import { BILLFLOW_SCRIPT_ATTR, FeatureRequirementsMap, TIERS } from './types'

/**
 * Loading script to enable Billflow external widget
 */
export const loadScript = () => {
    const script = document.createElement('script')
    script.setAttribute(BILLFLOW_SCRIPT_ATTR, 'true')
    script.src = 'https://js.billflow.io/billflow-embed.js'
    script.async = true
    script.type = 'text/javascript'
    var x = document.getElementsByTagName('script')[0]
    x.parentNode?.insertBefore(script, x)
}

/**
 * Remove scripts associated with the Billflow external widget
 * @param billingFlowElementId ElementID of container embedding Billflow widget
 */
export const removeScripts = (billingFlowElementId: string) => {
    ;(window as any).servicebotSettings = {}
    const billflowNode = document.getElementById(billingFlowElementId)
    if (billflowNode) billflowNode.innerHTML = ''
    const billflow_nodes = document.querySelectorAll(
        `script[${BILLFLOW_SCRIPT_ATTR}], style[billflow-style]`
    )
    if (billflow_nodes) {
        for (let node of Array.from(billflow_nodes)) {
            node.remove()
        }
    }
}

export const launchIntercom = (e) => {
    e.preventDefault()
    return (window as any).Intercom('showNewMessage')
}

/**
 * Get next tier
 * @param currentTier Current pricing tier
 * @returns Next tier
 */
export const getNextTier = (currentTier) => {
    const search = Object.entries(TIERS).find(([, value]) => value === TIERS[currentTier] + 1)
    return search ? search[0] : null
}

/**
 * Getting the most expensive tier in the list
 * @param tiers List of pricing tiers
 * @returns Most expensive tiers in the list
 */
export const getMaxTier = (tiers) => {
    tiers.sort(function (a, b) {
        return TIERS[b] - TIERS[a]
    })
    return tiers[0]
}

/**
 * Calculates the reasons why a plan might be disabled based on features usage
 * @param reasonParts Feature vs requirements configuration
 * @param featureUsage Features usage of current user
 * @param isTierRequested True if tier requested, false otherwise
 * @returns Reasons why a plan might be disabled
 */
export const getAllowedPlansReason = (
    reasonParts: FeatureRequirementsMap,
    featureUsage,
    isTierRequested
) => {
    featureUsage = featureUsage ?? []

    const parts = new Set()
    for (let i = 0; i < featureUsage.length; i++) {
        const feature = featureUsage[i]
        if (!feature.is_skip_tier_restriction) {
            parts.add(reasonParts[feature.name](feature))
        }
    }
    const partsArray = [...Array.from(parts)].filter((part) => part)
    const minTierReason =
        partsArray.length > 0
            ? `because you have ${[
                  ...partsArray.slice(0, -2),
                  partsArray.slice(-2).join(' and '),
              ].join(', ')}`
            : null
    const requiredTierReason = isTierRequested
        ? 'because of the plan you have expressed interest in'
        : null

    if (!minTierReason && !requiredTierReason) return ''
    return `Some plans are disabled ${[minTierReason, requiredTierReason]
        .filter((reason) => reason)
        .join(' or ')}.`
}

const dateFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
})

export function formatSubscriptionCancelDate(date?: string): string {
    if (!date) return 'Cancelled'

    const dateObj = new Date(date)
    if (!isFinite(dateObj.getTime())) return 'Cancelled'

    const formattedDate = dateFormat.format(dateObj)

    const today = new Date()
    const isFuture = moment(date).diff(today, 'minutes') > 0

    if (isFuture) return `Cancels on ${formattedDate}`

    return `Cancelled on ${formattedDate}`
}

export function shouldDisplayCancelDate(workspaceAccount: Account): boolean {
    if (isSubscriptionCancelled(workspaceAccount)) return true

    return Boolean(workspaceAccount?.subscription_end_date)
}
