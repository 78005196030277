import * as React from 'react'
import { useState } from 'react'

import { TabList, TabPanels } from '@chakra-ui/react'

import { useViewFromId } from 'data/hooks/views'

import { Tab, TabPanel, Tabs } from 'v2/ui'

import Button from 'ui/deprecated/atoms/Button'
import Flex from 'ui/deprecated/atoms/Flex'
import DropdownForm from 'ui/forms/DropdownForm'
import Form from 'ui/forms/Form'
import InputForm from 'ui/forms/InputForm'
import ObjectPickerForm from 'ui/forms/ObjectPickerForm'
import SubmitButton from 'ui/forms/SubmitButton'
import { Spacer } from 'ui/forms/ui'

import { AggrTypeSelectorForm } from './forms/AggrTypeSelector'
import { CHART_TYPE_GROUP_TYPE_MAP, ChartTypeSelectorForm } from './forms/ChartTypeSelector'
import FieldPickerForm from './forms/FieldPickerForm'
import FiltersForm from './forms/FiltersForm'
import {
    chartAbbreviationOptions,
    chartDecimalPlacesOptions,
    getChartTypeLabel,
    getFieldAggrTypeFilter,
    getFieldGroupTypeFilter,
} from './AddAChart'
import { useDashboardContext } from './context'

export default function EditChart({ item, onRequestClose }) {
    const { updateChart, mainObjectId, viewId } = useDashboardContext()
    const view = useViewFromId(viewId)

    const [tabIndex, setTabIndex] = useState(0)

    if (!item) {
        return null
    }

    return (
        <Form
            onSubmit={({ config, display, chart_type }) =>
                updateChart(item.id, {
                    chart_type,
                    config: {
                        ...item.config,
                        ...config,
                        inherit_filters_from_view_id:
                            view?.type === 'list' &&
                            mainObjectId === config.object_sid &&
                            config.inherit_filters_from_view_id
                                ? view?._sid
                                : undefined,
                        group: {
                            ...config.group,
                            type: CHART_TYPE_GROUP_TYPE_MAP[chart_type],
                        },
                        aggr: {
                            ...item.config.aggr,
                            ...config.aggr,
                        },
                    },
                    display: { ...item.display, ...display },
                })
            }
            onSuccess={() => onRequestClose()}
            options={{
                defaultValues: item,
            }}
            defaultValues={item.config}
        >
            {({ watch }) => {
                /**
                 * @type {METRICS_QUERY_REQ}
                 */
                const values = watch()
                const onListViewAndSameTableSelected =
                    view?.type === 'list' && mainObjectId === values.config.object_sid

                return (
                    <>
                        <div
                            style={{
                                maxHeight: 600,
                                minHeight: 300,
                                height: 'calc( 100vh - 100px )',
                                marginTop: '-1.5rem',
                            }}
                        >
                            <Tabs index={tabIndex} onChange={setTabIndex} variant="admin">
                                <TabList
                                    mt={[3, 3, 3, 6]}
                                    style={{ borderBottom: '1px solid #EEE' }}
                                >
                                    <Tab>Setup</Tab>
                                    <Tab>Filters</Tab>
                                    <Tab>Display Options</Tab>
                                </TabList>
                                <TabPanels pt={1}>
                                    <TabPanel>
                                        <InputForm name="display.label" label="Label" />
                                        <Spacer />
                                        <ObjectPickerForm
                                            name="config.object_sid"
                                            label="Table (this can't be changed)"
                                            required
                                            disabled
                                            style={{ opacity: '0.5' }}
                                        />
                                        <Spacer />
                                        <ChartTypeSelectorForm
                                            name="chart_type"
                                            label="Chart Type"
                                            required
                                        />
                                        {values.chart_type !== 'Number' && (
                                            <>
                                                <FieldPickerForm
                                                    label="Group by"
                                                    name="config.group.field_sid"
                                                    subtitle={`Select field to group by for ${getChartTypeLabel(
                                                        values.chart_type
                                                    )}`}
                                                    filter={getFieldGroupTypeFilter(
                                                        CHART_TYPE_GROUP_TYPE_MAP[values.chart_type]
                                                    )}
                                                    objectId={values.config.object_sid}
                                                    required
                                                />
                                            </>
                                        )}
                                        <Spacer />
                                        <AggrTypeSelectorForm
                                            name="config.aggr.type"
                                            label="Aggregate"
                                            required
                                        />
                                        <Spacer />
                                        {values?.config?.object_sid &&
                                            values.config.aggr.type &&
                                            values.config.aggr.type !== 'count' && (
                                                <FieldPickerForm
                                                    label="Field"
                                                    name="config.aggr.field_sid"
                                                    filter={getFieldAggrTypeFilter(
                                                        values.config.aggr.type
                                                    )}
                                                    objectId={values.config.object_sid}
                                                    required
                                                />
                                            )}
                                    </TabPanel>
                                    <TabPanel>
                                        <FiltersForm
                                            objectId={values.config.object_sid}
                                            name="config.filters"
                                            label="Filters"
                                            subtitle="Only include records in the result which pass these filters"
                                            canInheritFilters={onListViewAndSameTableSelected}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        {values.chart_type !== 'Number' && (
                                            <>
                                                <InputForm
                                                    name="display.maxItems"
                                                    label="Number of items to display"
                                                    subtitle="If left empty, we automatically optimise for the best readability"
                                                    pattern={/^([2-9]|1[0-2])$/}
                                                    type="number"
                                                    min={2}
                                                    max={12}
                                                    errorMessages={{
                                                        pattern: 'A number between 2 and 12',
                                                    }}
                                                />
                                                <Spacer />
                                            </>
                                        )}
                                        <DropdownForm
                                            name="display.decimalPlaces"
                                            label="Rounding"
                                            subtitle="Round fractional results, e.g. show 2.499 as 2.5"
                                            options={chartDecimalPlacesOptions}
                                        />
                                        <Spacer />
                                        <DropdownForm
                                            name="display.abbreviation"
                                            label="Abbreviation"
                                            subtitle="Abbreviate large results, e.g. show 1200 as 1.2k"
                                            options={chartAbbreviationOptions}
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                            <Spacer />
                            <Spacer />
                            <Spacer />
                        </div>
                        <Flex style={{ justifyContent: 'flex-end' }}>
                            <Button type="secondary" onClick={() => onRequestClose()}>
                                Cancel
                            </Button>
                            <div style={{ width: 15 }} />
                            <SubmitButton requireChanges>Done</SubmitButton>
                        </Flex>
                    </>
                )
            }}
        </Form>
    )
}
