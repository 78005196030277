import * as Sentry from '@sentry/react'

import {
    getCurrentUserProfileRecordId,
    getCurrentUserRecordField,
    getCurrentUserRecordId,
} from 'data/wrappers/withUserUtils'

import { encodeCommas } from './escapeCommas'

export const currentUserOptions: readonly FilterOperation[] = [
    'isCurrentUser',
    'isNotCurrentUser',
    'containsCurrentUser',
    'doesNotContainCurrentUser',
]

export const userFieldOptions: readonly FilterOperation[] = [
    'isCurrentUserField',
    'isNotCurrentUserField',
    'containsCurrentUserField',
    'doesNotContainCurrentUserField',
]

export const queryStringSuffixMap: Record<FilterOperation, string> = {
    equals: '',
    notEquals: '__neq',
    lessThan: '__lt',
    equalsOrLessThan: '__lte',
    greaterThan: '__gt',
    equalsOrGreaterThan: '__gte',
    is: '',
    isnt: '__neq',
    listIs: '__listeq',
    listIsnt: '__listneq',
    oneOf: '__in',
    noneOf: '__notin',
    startsWith: '__startswith',
    endsWith: '__endswith',
    contains: '__contains',
    containsAny: '__containsany',
    containsNone: '__containsnone',
    "doesn't contain": '__notcontains',
    isEmpty: '__isempty',
    isNotEmpty: '__isnotempty',
    sameDay: '',
    beforeDate: '__lt',
    afterDate: '__gt',
    isInBetween: '',
    withinNext: '__withinnext',
    withinLast: '__withinlast',
    isCurrentUser: '',
    isNotCurrentUser: '__neq',
    containsCurrentUser: '__containsany',
    doesNotContainCurrentUser: '__containsnone',
    containsCurrentUserProfile: '__containsany',
    doesNotContainCurrentUserProfile: '__containsnone',
    isCurrentUserField: '',
    isNotCurrentUserField: '__neq',
    containsCurrentUserField: '__containsany',
    doesNotContainCurrentUserField: '__containsnone',
    isContextRecordField: '',
    isNotContextRecordField: '__neq',
    containsContextRecordField: '__containsany',
    doesNotContainContextRecordField: '__containsnone',
    appearsIn: '__appearsin',
}

const emptyValueOptions = new Set<FilterOperation>(['isEmpty', 'isNotEmpty'])

function isArrayOpValue(operation: FilterOperation) {
    if (!operation) {
        return false
    }

    const op = queryStringSuffixMap[operation]

    return op === '__containsany' || op === '__containsnone'
}

export const preprocessFilterValue = (
    objectId: string | undefined,
    operation: FilterOperation,
    value: Filter['options']['value'] = ''
) => {
    let newValue = value

    // For the current user filters, send through the current user
    // sid and the corresponding modifier
    if (currentUserOptions.includes(operation)) {
        newValue =
            (!!objectId && getCurrentUserProfileRecordId(objectId)) || getCurrentUserRecordId()
    }

    // For the current user filters, send through the current user
    // sid and the corresponding modifier
    if (userFieldOptions.includes(operation) && !Array.isArray(newValue)) {
        newValue = getCurrentUserRecordField(newValue)
    }

    if (isArrayOpValue(operation) && !Array.isArray(newValue)) {
        newValue = [newValue]
    }

    return newValue
}

export type QueryDict = { filters: string[] }

export const filtersToQueryDict = (filters: Filter[] = []): QueryDict => {
    const flattenedFilters = filters.reduce<Filter[]>((agg, filter) => {
        if (filter.options?.option === 'isInBetween') {
            agg.push({
                ...filter,
                options: {
                    ...filter.options,
                    option: 'afterDate',
                    value: filter.options?.value?.[0],
                },
            })
            agg.push({
                ...filter,
                options: {
                    ...filter.options,
                    option: 'beforeDate',
                    value: filter.options?.value?.[1],
                },
            })
        } else {
            agg.push(filter)
        }
        return agg
    }, [])

    return flattenedFilters.reduce<QueryDict>(
        (agg, filter) => {
            let { value, option } = filter.options

            value = preprocessFilterValue(
                filter.field?.options?.lookup_target,
                filter.options?.option,
                filter.options?.value
            )

            if (option && !queryStringSuffixMap.hasOwnProperty(option)) {
                Sentry.captureMessage(`Unexpected filter operation encountered ${option}`)
            }

            // some filters don't require a value, so just set these to true so that
            // they are still sent
            if (emptyValueOptions.has(option)) {
                value = 'true'
            }

            // Don't encode the commas when we use an internal filter
            if (!filter.field?.api_name?.startsWith('_')) {
                value = encodeCommas(value)
            }

            if (value) {
                const modifier = queryStringSuffixMap[option]
                const encodedModifier = modifier ? `_${modifier}` : ''
                const serializedValue = value.toString()

                const field = filter.field?.api_name ?? ''
                const encodedFilter = `${encodeURIComponent(
                    field
                )}${encodedModifier}=${encodeURIComponent(serializedValue)}`

                agg['filters'].push(encodedFilter)
            }
            return agg
        },
        { filters: [] }
    )
}
