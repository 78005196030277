import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'
import { queryClient } from 'data/hooks/_helpers'
import { refetchAccounts } from 'data/hooks/accounts'
import { invalidateFeatures } from 'data/hooks/features'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidatePages } from 'data/hooks/pages'
import { invalidateStacks, refetchStacks } from 'data/hooks/stacks/stackOperations'
import { invalidateViews } from 'data/hooks/views'
import { REACT_QUERY } from 'data/utils/constants'
import { orderAlphabeticallyStacks } from 'data/utils/utils'
import { withStacks } from 'data/wrappers/WithStacks'
import CreateNewAppButton from 'features/AppBar/NewAppButton'
import Frame from 'features/core/Frame'

import { Flex, Text } from 'v2/ui'
import { AppBuild } from 'v2/ui/svgs'

import WorkspaceNotReady from './WorkspaceNotReady'

const LOGGING = !settings.IS_PROD

const isWorkspaceSetupComplete = (workspaceAccount) => {
    return !workspaceAccount?.setup_data?.demo_apps_being_created
}

const useIsWorkspaceReady = (workspaceAccount) => {
    const [pollCount, setPollCount] = useState(0)
    const [workspaceReady, setWorkspaceReady] = useState(isWorkspaceSetupComplete(workspaceAccount))

    const refreshCache = async () => {
        await Promise.all([
            queryClient.invalidateQueries(REACT_QUERY.roles.listName),
            invalidateFeatures(),
            invalidateViews(),
            invalidateNavigation(),
            invalidatePages(),
            invalidateStacks(),
        ])

        await refetchStacks()
    }

    useEffect(() => {
        // if workspace setup not complete, poll for the account data again in 1 second
        //
        // note slow the polling down to every 5 seconds after ~2m
        //
        // stop altogether after ~30m, in case in the backend means the client polls forever
        if (!isWorkspaceSetupComplete(workspaceAccount) && pollCount < 1000) {
            setTimeout(
                async () => {
                    if (LOGGING) {
                        console.log(`Waiting for workspace to be ready (${pollCount})`)
                    }

                    await refetchAccounts()
                    setPollCount((pollCount) => pollCount + 1)
                },
                pollCount < 120 ? 1000 : 5000
            )
        }
        // if account setp complete, if workspaceReady is still set false,
        // then clear cache and set workspaceReady true
        else if (!workspaceReady) {
            if (LOGGING) {
                console.log(`Workspace ready\n\n`)
            }

            // the demo apps are being created async and some may
            // have been created already when this page loaded,
            // so the cache may be partially filled with only some of the stacks
            // to make sure there isn't flicker & that the top app is selected
            // by default we need to reload the latest data
            // (which we are now sure is there since demo_apps_being_created has been set false)
            // into the cache before setting workspaceReady to true and loading the workspace
            refreshCache().then(() => {
                setWorkspaceReady(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollCount])

    return workspaceReady
}

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const WorkspacePageStyledContent = styled(Flex)`
    flex-grow: 1;
    height: 100%;
    animation: ${fadeInAnimation} 0.4s ease-in;
`

const WorkspaceHome = ({ stacks, workspaceAccount }) => {
    const { isLoading } = useContext(AppContext)
    const { user } = useAppUserContext()
    const isWorkspaceReady = useIsWorkspaceReady(workspaceAccount)

    const { hasRight } = useAccountUserContext()
    const hasCreateRights = hasRight('create_apps')

    if (isLoading || !stacks || !workspaceAccount) {
        return null
    }

    if (!isWorkspaceReady) {
        return <WorkspaceNotReady />
    }

    const accountStacks = stacks ? orderAlphabeticallyStacks(stacks, workspaceAccount._sid) : []

    if (accountStacks.length && accountStacks[0].url_slug) {
        return <Redirect to={`/${accountStacks[0].url_slug}`} />
    }

    const isGuestUser = user?.membership_options?.role === 'guest'
    const isMemberRole = user?.membership_options?.role === 'member'
    const isAuth0OpenSignupUser = !!user?.options?.via_auth0_open_signup

    return (
        <Frame>
            <WorkspacePageStyledContent>
                <Flex height="100%" align="center" width="100%">
                    <Flex column width="100%">
                        <AppBuild style={{ maxWidth: '100%' }} />
                        {isGuestUser || (isMemberRole && !hasCreateRights) ? (
                            isAuth0OpenSignupUser ? (
                                <>
                                    <Text fontSize="lg" color="gray.500" mt={3} textAlign="center">
                                        Welcome!
                                    </Text>
                                    <Text
                                        fontSize="mdlg"
                                        color="gray.500"
                                        mt={3}
                                        textAlign="center"
                                    >
                                        No apps have been shared with you yet.
                                    </Text>
                                    <Text
                                        fontSize="mdlg"
                                        color="gray.500"
                                        mt={3}
                                        mb={12}
                                        textAlign="center"
                                    >
                                        Please contact your workspace administrator.
                                    </Text>
                                </>
                            ) : (
                                <Text
                                    fontSize="mdlg"
                                    color="gray.500"
                                    mt={3}
                                    mb={12}
                                    textAlign="center"
                                >
                                    No apps have been shared with you yet.
                                </Text>
                            )
                        ) : (
                            <>
                                <Text fontSize="lg" fontWeight="bold" mt={8} textAlign="center">
                                    Welcome to your Stacker workspace!
                                </Text>
                                <Text
                                    fontSize="mdlg"
                                    color="gray.500"
                                    mt={3}
                                    mb={4}
                                    textAlign="center"
                                >
                                    Let&apos;s create your first app
                                </Text>
                                <CreateNewAppButton variant="adminPrimary" buttonSize="md">
                                    <span>Create an app</span>
                                </CreateNewAppButton>
                            </>
                        )}
                    </Flex>
                </Flex>
            </WorkspacePageStyledContent>
        </Frame>
    )
}
export default withStacks(WorkspaceHome)
