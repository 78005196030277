import getSymmetricField from 'v2/views/utils/getSymmetricField'

// Checks if we've added/removed items from a lookup/multi lookup field
// Adds the added record ids to a list so that we can then refresh
// the cache for those records after saving

const getUpdatedRecords = (fields, prevRecord, updatedRecord) => {
    const lookupFields = fields.filter((field) => ['lookup', 'multi_lookup'].includes(field.type))

    let recordsAdded = []
    let recordsRemoved = []
    let updatedRecordsIds = []
    let symmetricFields = []

    if (!lookupFields) return { recordsAdded, recordsRemoved, updatedRecordsIds, symmetricFields }

    lookupFields.forEach((field) => {
        let previousFieldValue = prevRecord?.[field.api_name]
        let updatedFieldValue = updatedRecord?.[field.api_name]
        const symmetricField = getSymmetricField(field)
        if (!symmetricField) return

        if (!Array.isArray(previousFieldValue)) {
            previousFieldValue = previousFieldValue ? [previousFieldValue] : []
        }

        if (!Array.isArray(updatedFieldValue)) {
            updatedFieldValue = updatedFieldValue ? [updatedFieldValue] : []
        }

        // New items added to the field
        const newItems = updatedFieldValue.filter((record) => !previousFieldValue.includes(record))

        // Items emoved from the field
        const removedItems = previousFieldValue.filter(
            (record) => !updatedFieldValue.includes(record)
        )

        newItems.forEach((record) => {
            recordsAdded.push({ record_sid: record, field: symmetricField })
            if (!updatedRecordsIds.includes(record)) updatedRecordsIds.push(record)
        })

        removedItems.forEach((record) => {
            recordsRemoved.push({ record_sid: record, field: symmetricField })
            if (!updatedRecordsIds.includes(record)) updatedRecordsIds.push(record)
        })

        // This just builds up a list of symmetric field names that we
        // will update so that we can omit them from the redux updates
        // if necessary
        if (newItems.length || removedItems.length) {
            symmetricFields.push(symmetricField.api_name)
        }
    })

    return { recordsAdded, recordsRemoved, updatedRecordsIds, symmetricFields }
}

export default getUpdatedRecords
