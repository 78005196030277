import React, { useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import { useAppUsers } from 'data/hooks/users/main'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'

import { Icon, Link, Modal, Text } from 'v2/ui'
import FeatureBanner from 'v2/ui/components/FeatureBanner'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { useModalDeclaration, useModalToggle } from 'v2/ui/utils/useModalToggle'

import Button from 'ui/deprecated/atoms/Button'
import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { useAppSettings } from '../workspace/AdminSideTray/hooks/useAppSettings'

import AppSharingSettings from './ManageAppSharingSettings'

const MODAL_KEY = 'AppSharingSettings'
export const useAppSharingSettingsModal = () =>
    useModalDeclaration(MODAL_KEY, AppSharingSettingsModal)

const AppSharingSettingsModal = () => {
    const { selectedStack } = useAppContext()
    const { isOpen, toggle } = useModalToggle(MODAL_KEY)
    const { data: users } = useAppUsers({ enabled: !!isOpen })
    const customers = users?.filter((user) => user.type === 'customer')
    const customerAccessEnabled = selectedStack?.options?.enable_external_access
    const formRef = useRef({})
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const { open: openAppSettings } = useAppSettings()

    // check for unsaved changes, then close the modal
    function onClose() {
        saveChanges(toggle)
    }

    function openCustomerAccess() {
        openAppSettings({ page: { name: 'customer_access' } })
        toggle()
    }

    return (
        <Modal
            isOpen={isOpen}
            showCloseButton={false}
            size={'700px'}
            height={'600px'}
            onClose={onClose}
            noPadding
        >
            <Flex column style={{ height: '100%' }}>
                <Flex
                    style={{
                        padding: '10px',
                        paddingBottom: '5px',
                        alignItems: 'center',
                        backgroundColor: V4DesignSystem.colors.gray[10],
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'left',
                            marginLeft: '10px',
                            ...V4DesignSystem.h2,
                        }}
                    >
                        <span className={ONBOARDING_CLASSES.APP_SETTINGS_USERS_SHARING_MODAL}>
                            Add users
                        </span>
                    </div>
                    <Button onClick={onClose} type="link">
                        <Icon icon="x" color={V4DesignSystem.colors.gray[400]} />
                    </Button>
                </Flex>

                <Flex
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 0,
                        overflow: 'hidden',
                    }}
                >
                    <AppSharingSettings formRef={formRef}>
                        {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                    </AppSharingSettings>
                </Flex>

                {customerAccessEnabled ? (
                    <FeatureBanner
                        mx="20px"
                        mb="20px"
                        mt="-10px"
                        title={
                            customers?.length
                                ? `Customer Access (${customers.length})`
                                : 'Customer Access'
                        }
                        onClick={openCustomerAccess}
                        cursor="pointer"
                        subtitle={
                            <>
                                <Text mr={2} display="inline">
                                    Customers can access the app on
                                </Text>
                                <Icon
                                    icon="world"
                                    size="sm"
                                    color="adminBrand"
                                    display="inline"
                                    mr={1}
                                />
                                <Link
                                    display="inline"
                                    href={`//${
                                        selectedStack.custom_base_url || selectedStack.base_url
                                    }`}
                                >
                                    {selectedStack.custom_base_url || selectedStack.base_url}
                                </Link>
                            </>
                        }
                        actions={[
                            {
                                icon: 'arrowRight',
                                variant: 'iconButton',
                                color: 'gray.400',
                                fontSize: 'md',
                                py: 2,
                                px: 4,
                                mr: -2,
                                buttonSize: 'unset',
                                onClick: openCustomerAccess,
                            },
                        ]}
                    />
                ) : (
                    <FeatureBanner
                        mx={'20px'}
                        icon="world"
                        mb="20px"
                        mt="-10px"
                        title="Customer Access"
                        subtitle={'Allow customers to access this app on a custom domain.'}
                        actions={[
                            {
                                content: 'Setup',
                                variant: 'adminSecondaryV4',
                                onClick: openCustomerAccess,
                            },
                        ]}
                    />
                )}
            </Flex>
        </Modal>
    )
}

export default AppSharingSettingsModal
