import React from 'react'

import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import { Flex } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

const AttributeContainer = (props) => {
    const isSupportLoginPermitted = useIsSupportLoginPermitted()

    return (
        <Flex {...props} className={isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK}></Flex>
    )
}

export default AttributeContainer
