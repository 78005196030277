import React from 'react'

import { Icon } from 'v2/ui'
import { SolidSearch } from 'v2/ui/svgs'

import { InlineFilterInput } from './InlineFilterInput'

type InlineFilterMultiValueInputSearchProps = React.ComponentPropsWithRef<
    typeof InlineFilterInput
> & {}

export const InlineFilterMultiValueInputSearch = React.forwardRef<
    HTMLInputElement,
    InlineFilterMultiValueInputSearchProps
>(({ placeholder = 'Search…', ...props }, ref) => {
    return (
        <InlineFilterInput
            ref={ref}
            placeholder={placeholder}
            rightAdorner={
                <Icon svg={<SolidSearch />} size="1rem" fill="userInterface.neutral.600" />
            }
            aria-label="Search"
            {...props}
        />
    )
})
