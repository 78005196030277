import React from 'react'

import { useRoleListOptions } from 'data/hooks/roleHelpers'

import { Dropdown } from 'v2/ui'
const RolePicker = ({ placeholder, idField = '_sid', ...props }) => {
    const { data: options } = useRoleListOptions(idField)

    return (
        <Dropdown
            placeholder={placeholder || 'select role'}
            clearable={false}
            options={options}
            usePortal={true}
            adminTheme
            {...props}
        />
    )
}
export default RolePicker
